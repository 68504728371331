import { useValidation } from "../../_hooks/useValidation";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CapsuleButton, MainPageWithButtons, SegmentedControl } from "../../_components";
import { GroupNameComponent } from "../_components/GroupNameComponent";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../_reducers/app.reducer";
import { PermissionSelectorComponent2 } from "../_components/PermissionSelectorComponent2";
import { AccessSelectorWithRooms } from "../_components/AccessSelectorWithRooms";
import { SubinvitationsSelectorComponent } from "../_components/SubinvitationsSelectorComponent";
import { GroupDurationSelector } from "../_components/GroupDurationSelector";
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";
import { INVITATION_GROUP_TYPES, INVITATION_GROUP_TYPES_TRANSLATED } from "../../_constants";
import { Invitation } from "../../_models/Invitation";
import { LOCATION_PERMISSIONS } from "../../_constants/permissions.constants";

export function NewInvitationGroup({ cardCode = undefined, availabeGroupTypes = [INVITATION_GROUP_TYPES.LIMITED, INVITATION_GROUP_TYPES.UNLIMITED] }) {

    const { handleSetValid, isAllValid, forceWarnings } =
        useValidation();

    const dispatch = useDispatch();
    
    const onChangeGroupType = useCallback(type => {
        dispatch(invitationGroupActions.changeGroupType(type));
        if(type === INVITATION_GROUP_TYPES.LIMITED){
            dispatch(invitationGroupActions.changeInvitationPermission(LOCATION_PERMISSIONS.GUEST))
        }
    }, [dispatch])

    //INITIALIZATION
    useEffect(() => {
        dispatch(invitationGroupActions.createLocal());
        onChangeGroupType(availabeGroupTypes[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

        
    const rooms = useSelector(Selectors.getRooms);
    const locationInvitationConfig = useSelector(Selectors.getLocationInvitationConfig);
    
    const handleSetValidName = useCallback(
        (isValid) => {
            handleSetValid("NAME", isValid);
        },
        [handleSetValid],
    );
    const { t } = useTranslation();
    const handleSetValidRooms = useCallback(
        (isValid) => {
            handleSetValid("ROOMS", isValid);
        },
        [handleSetValid],
    );

    const newGroup = useSelector(Selectors.getEditableInvitationGroup);
    let invitation = Invitation();
    invitation = newGroup?.invitation;


    const canCreateSuperguests = locationInvitationConfig.canCreateSuperguests;
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    
    

    const handleSaveNew = async () => {
        if (!isAllValid) return;

        await dispatch(
            invitationGroupActions.saveNewSelectedToServer(
                cardCode,
                locationUid,
            )
        );

        dispatch(invitationGroupActions.changeSecondaryView(null))
    };

    function getGroupTypeOptions() {
        return availabeGroupTypes.map(value => ({
            label: INVITATION_GROUP_TYPES_TRANSLATED[value],
            value
        }))
    }
    const groupTypeSelector = () => (
        <div className="my-2 px-2">
            <SegmentedControl
                defaultSelected={getGroupTypeOptions()[1]}
                onSelect={onChangeGroupType}
                segments={getGroupTypeOptions()}
            />
        </div>
    );

    
    const content = () => (
        <div className="c-grid">
            {groupTypeSelector()}
            <div className="">
                <h3 className="text-left">{t('common.name')}</h3>
                <GroupNameComponent
                    name={newGroup?.name}
                    isEditable
                    setIsValid={handleSetValidName}
                    forceWarnings={forceWarnings}
                />
            </div>
            {newGroup?.groupType === INVITATION_GROUP_TYPES.UNLIMITED && (
                <>
                    <PermissionSelectorComponent2 mode='invitation_group' isEditable />

                    <SubinvitationsSelectorComponent
                        canCreateSuperguests={canCreateSuperguests}
                        isEditable
                        isNew
                        invitation={invitation}
                        mode='invitation_group'
                    />
                </>
            )}
            <AccessSelectorWithRooms
                isNew
                isEditable
                rooms={rooms}
                invitation={invitation}
                forceShowWarnings={forceWarnings}
                setIsValid={handleSetValidRooms}
            />
            {newGroup?.groupType === INVITATION_GROUP_TYPES.LIMITED && <GroupDurationSelector forceEnabled />}
        </div>
    );

    return (
        invitation ? 
        <MainPageWithButtons
            size="SMALL"
            content={content()}
            buttons={[
                <CapsuleButton
                    text={t("global_save")}
                    onClick={handleSaveNew}
                    isEnabled={isAllValid}
                />,
            ]}
            pageTitle={t("mod_invitations_new_group")}
        /> : null
    );
}