export const ColorHelper = {
    hexToRgb,
    rgbToHex,

    rgbToHsl,
    hslToRgb,

    setCustomColorsFromHue,
}

function setCustomColorsFromHue(hex) {
    if (!hex)
        hex = "#00a1e6";

    const rgb = ColorHelper.hexToRgb(hex);
    const [hue] = ColorHelper.rgbToHsl(...rgb);

    setColorPrimary(cssHslString(hue, 100, 45));
    setColorPrimaryLight1(cssHslString(hue, 100, 78));
    setColorPrimaryLight2(cssHslString(hue, 100, 95));
    setColorPrimaryDark1(cssHslString(hue, 100, 36));
    
    function cssHslString(h, l, s) {
        return `hsl(${h}, ${l}%, ${s}%)`
    }
    
    function setColorPrimary(cssHsl) {
        setGlobalCssVariable("--colorPrimary", cssHsl);
    }

    function setColorPrimaryLight1(cssHsl) {
        setGlobalCssVariable("--colorPrimaryLight_1", cssHsl);
    }

    function setColorPrimaryLight2(cssHsl) {
        setGlobalCssVariable("--colorPrimaryLight_2", cssHsl);
    }
    function setColorPrimaryDark1(cssHsl) {
        setGlobalCssVariable("--colorPrimaryDark_1", cssHsl);
    }

    function setGlobalCssVariable(name, value) {
        document.documentElement.style.setProperty(name, value);
    }
}

function hexToRgb(hex) {
    return hex
        .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => '#' + r + r + g + g + b + b,
        )
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))
    ;
}

function rgbToHex(r, g, b) {
    const fullHex = [r, g, b].map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    }).join('');

    return "#" + fullHex;
}

function hslToRgb(h, s, l) {
    var r, g, b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [
        Math.round(r * 255),
        Math.round(g * 255),
        Math.round(b * 255),
    ];

    function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
    }
}

function rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                break;
        }
        h /= 6;
    }

    return [Math.round(h * 359), s * 100, l * 100];
}