import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

import { CapsuleButton } from "../_components";

const fn = () => {};

export function CustomCardElement({
    setIsRequesting = fn,
    onAddCard = async (stripe, cardElement) => {},
} = {}) {

    //LIBRARIES
    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();

    //ACTION HANDLERS
    async function handlePay() {
        const cardElement = elements.getElement(CardElement);

        if (!stripe || !cardElement)
            return;

        setIsRequesting(true);
        await onAddCard(stripe, elements.getElement(CardElement));
        setIsRequesting(false);
    }

    //VIEW
    return (
        <div className="
            d-flex
            no-gutters
            align-items-center
            
            rounded border
            px-2
            py-1
        ">
            <CardElement
                className="w-100"
                // options={{
                //     iconStyle: 'solid',
                //     style: {
                //         base: {
                //             iconColor: '#c4f0ff',
                //             color: '#fff',
                //             fontWeight: 500,
                //             fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                //             fontSize: '16px',
                //             fontSmoothing: 'antialiased',
                //             ':-webkit-autofill': {color: '#fce883'},
                //             '::placeholder': {color: '#87bbfd'},
                //         },
                //         invalid: {
                //             iconColor: '#ffc7ee',
                //             color: '#ffc7ee',
                //         },
                //     },
                // }}
            />
            <div>
                <CapsuleButton
                    text={t("mod_payments_add_card")}
                    isEnabled={!!stripe}
                    onClick={handlePay}
                    size={'SMALL'}
                    fillStyle={'FILLED'}
                />
            </div>
        </div>
    );
}