import { Product } from "./Product";

export function Entry({
    uid,
    amount,
    discount,
    product = Product(),

    grossPrice,
    vat,
    netPrice,
} = {}) {
    return {
        uid,
        amount,
        discount,
        product,

        grossPrice,
        vat,
        netPrice,
    };
}