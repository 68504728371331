import { Mobile } from "./Mobile";

export function AuditLog({
    id,

    userUid,
    userEmail,
    code,
    datetime,
    path,
    content,
    type,
    
    mobile = Mobile(),
} = {}) {
    return {
        id,
        
        userUid,
        userEmail,
        code,
        datetime,
        path,
        content,
        type,

        mobile,
    };
}