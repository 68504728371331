import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from '../_components';
import { ContentSpace } from '../_components';
import { alertsActions } from '../_actions';
import { AppAlert } from '../_models/AppAlert';
import { ListedGroup } from '../_components/ListedGroup';
import { Selectors } from '../_reducers/app.reducer';

const testIDs = {
    root: "alerts-module",
};

AlertsModule.testIDs = testIDs;

export function AlertsModule() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let alerts = [AppAlert()];
    alerts = useSelector(Selectors.getAlerts);

    const devices = useSelector(Selectors.getSelectedLocationDevices);

    //INITIALIZATION
    useEffect(() => {
        intialize();
        
        async function intialize() {
            await dispatch(alertsActions.getAlerts(devices));
        }
    }, [dispatch, devices]);
    
    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    dataTestId={testIDs.root}
                    pageTitle={t("global_alerts")}
                    content={alertsContent()}
                />
            }
        />
    );

    function alertsContent() {
        return alerts.length === 0
            ? noAlertsMessage()
            : alertsList()
        ;
    }

    function noAlertsMessage() {
        return (
            <div className="my-2 text-center">
                {t("alerts_none_found_hint")}
            </div>
        );
    }

    function alertsList() {
        return (
            <div className="pt-2">
                <ListedGroup
                    elements={alerts.map(alertObject)}
                    testID={"alert-list"}
                />
            </div>
        );
    }

    function alertObject(alert = AppAlert()) {
        return (
            <div
                className={`
                    d-flex
                    justify-content-between

                    py-2
                    px-2
                    c-cursor-default
                    align-items-center
                `}
            >
                <div className="font-weight-bold flex-shrink-0">
                    {alert.type}
                </div>
                <span className="ml-3"/>
                <div className="text-right">
                    {alert.message}
                </div>
            </div>
        );
    }
}
