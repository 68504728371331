import { URL_CONSTANTS } from "../_constants";
import { ServiceHelper } from "./ServiceHelper";

export const SmoobuService = {
    listApartments,
}

function listApartments(uid: string) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INTEGRATION_SMOOBU_APARTMENTS,
        {
            uid: uid
        }
    );
}