import { NULL_MAIL } from '../../_constants';
import { useTranslation } from 'react-i18next';
import { Invitation } from '../../_models/Invitation';
import { useMemo } from 'react';
import ToolTipWrapper from '../../_components/ToolTipWrapper';

export function SourceAndRecipientComponent({
    invitation = Invitation(),
}) {

    //LIBRARIES
    const { t } = useTranslation();

    const emailOrCode = useMemo(() => {
        if (invitation.email !== NULL_MAIL) return invitation.email;

        return (
            <ToolTipWrapper tooltip={t("global_click_to_copy")}>
                <span
                    className="cp"
                    onClick={() =>
                        navigator.clipboard.writeText(invitation.code)
                    }
                >
                    {invitation.code}
                </span>
            </ToolTipWrapper>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitation.code, invitation.email]);
    //VIEW
    if (invitation.newInvitation)
        return null;

    return (
        <>
        {source()}
        {recipient()}
        </>
    );

    function source() {
        return readOnlyData(
            t("global_user"),
            emailOrCode,
            invitation.userName ?? t('mod_invitations_pending_assignment'),
        );
    }

    function recipient() {
        return readOnlyData(
            t("invitations.invitedBy"),
            invitation.userSrcEmail,
            invitation.userSrcName
        );
    }

    function readOnlyData(title, email, name) {
        return (
            <div className='mt-3'>
                <h3 className='mb-2 c-text-capitalize-first'>
                    {title}
                </h3>
                <div className="d-flex justify-content-between flex-wrap">
                    <div>
                        {email}
                    </div>
                    <div>
                        {name}
                    </div>
                </div>
            </div>
        );
    }
}
