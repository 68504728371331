import { PMS_USER_ACTION_TYPES } from "PMS/_actionTypes/PMS_USER_ACTION_TYPES";
import { PMSChatFilter } from "../_models/PMSChat";

export interface PMSChatState {
    filter: PMSChatFilter;
    isRequesting: any;
}

const initialState: PMSChatState = {
    filter: {},
    isRequesting: false,
};

let getRootState = state => state as PMSChatState;

export class PMSChatSelector {
    static getPMSChatFilter = state => getRootState(state).filter;
    static getPMSChatIsRequesting = state => getRootState(state).isRequesting;

    static setRootStateFn = (rootStateFn = state => state) => getRootState = rootStateFn;
}

export function chats(state=initialState, action): PMSChatState {
    return state;
}