import React from "react";
import { useTranslation } from "react-i18next";

import { SingleMobile } from "../UserMobilesModule/SingleMobile";
import { Mobile } from "../_models/Mobile";

MobileDataComp.ChangedPermissionModel = ({
    hasBeenModified = false,
    timesModified = 0,
    name = "",
} = {}) => ({
    hasBeenModified,
    timesModified,
    name,
});

MobileDataComp.CurrentPermissionModel = ({
    name = "",
    isCorrect = false,
} = {}) => ({
    name,
    isCorrect,
});

export function MobileDataComp({
    mobile = Mobile(),
    canSeeAllData = true,
    isThereMobile = false,
    isUpToDate = false,
    changedPermissions = [] || [MobileDataComp.ChangedPermissionModel()],
    currentPermissions = [] || [MobileDataComp.CurrentPermissionModel()],
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div>
            <div className="font-weight-bold">
                {t("mod_users_status_mobile_diagnostics")}
            </div>
            <hr className="m-0"/>
            <div className="mt-1"/>
            {content()}
            <div className="mt-1"/>
            {solutionsSection()}
            {additionalMobileData()}
        </div>
    );

    function additionalMobileData() {
        if (!isThereMobile)
            return;

        if (!canSeeAllData)
            return;

        return (
            <SingleMobile
                mobile={mobile}
                showPermissions={false}
            />
        );
    }

    function content() {
        if (!isThereMobile)
            return noMobileBlock();

        return currentPermissionsSection();
    }

    function noMobileBlock() {
        return singleBlockElement(
            t("mod_users_status_no_mobile"),
            false,
        );
    }

    function currentPermissionsSection() {
        return (
            <div className="d-flex no-gutters flex-wrap align-items-stretch">
                {buildBlockElements()}
            </div>
        );

        function buildBlockElements() {
            let blockElements = currentPermissions.map(
                (permission = MobileDataComp.CurrentPermissionModel(), index) =>
                singleBlockElement(permission.name, permission.isCorrect, index)
            );

            blockElements.unshift(
                singleBlockElement(
                    t("mod_users_status_mobile_updated"),
                    isUpToDate,
                )
            );

            return blockElements;
        }
    }

    function solutionsSection() {
        if (!isThereMobile)
            return logInMessage();

        if (!isUpToDate)
            return updateAppMessage();

        if (!areCurrentPermissionsOk())
            return goToAppMessage();
            
        return null;
            
        function solutionMessage(text) {
            return (
                <div className="
                    border
                    border-danger
                    rounded

                    text-center

                    px-1 py-2
                ">
                    <span className="font-weight-bold">
                        {`${t("mod_users_status_solution")}: `}
                    </span>
                    {text}
                </div>
            );
        }

        function goToAppMessage() {
            return solutionMessage(
                t("mod_users_status_open_app")
            );
        }

        function updateAppMessage() {
            return solutionMessage(
                t("mod_users_status_update_app")
            );
        }

        function logInMessage() {
            return solutionMessage(
                t("mod_users_status_login_to_app")
            );
        }
    }

    function singleBlockElement(text = "NO TEXT", isOk = false, key) {
        return (
            <div
                key={text}
                className={`
                    col-4
                    text-center
                    p-1
                `}
            >
                <div className={`
                    h-100

                    border
                    rounded
                    px-1

                    d-flex
                    align-items-center
                    justify-content-center

                    ${isOk
                        ? "c-bg-light-green"
                        : "c-bg-light-red"
                    }
                `}>
                    {text}
                </div>
            </div>
        );
    }

    //HELPER FUNCTIONS
    function areCurrentPermissionsOk() {
        const isAnyPermissionWrong = currentPermissions.some(permission => !permission.isCorrect);
        return !isAnyPermissionWrong;
    }
}