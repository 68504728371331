import { BASE_PAGE_SIZE, InvitationFilter, PaginatedItems } from "../_modelsTS/Pagination";
import { INVITATIONS_ACTION_TYPES } from "../_actionTypes/INVITATIONS_ACTION_TYPES";
import * as _ from "lodash";

export interface InvitationsState {
    selected: any;
    invitations: PaginatedItems;
    validated?: boolean | null;
    filter: InvitationFilter,
    isRequesting: boolean;
    pending: any[];
    newInvitationDraft: any;
    subinvitations: any[];
    publicUrl: any;
}

const initialState: InvitationsState = {
    selected: {},
    invitations: {
        items: [],
        offset: 0,
        page_size: BASE_PAGE_SIZE,
        total_count: 0
    },
    validated: null,
    filter: {},
    isRequesting: true,
    pending: [],
    newInvitationDraft: null,
    subinvitations: [],
    publicUrl: null
};

let getRootState = state => state as InvitationsState;

export class InvitationsSelector {

    static getInvitations           = state => getRootState(state).invitations;
    static getInvitationSelected    = state => getRootState(state).selected;
    static getInvitationSelectedUid = state => getRootState(state).selected && getRootState(state).selected.uid;
    static getInvitationAccessLinkSelected = state => getRootState(state).publicUrl;
    static getInvitationValidated   = state => getRootState(state).validated;
    static getInvitationFilter      = state => getRootState(state).filter;
    static getIsRequestingInvitations = state => getRootState(state).isRequesting;
    static getInvitationNewDraft    = state => getRootState(state).newInvitationDraft;  
    static getInvitationFromListByUid = (state, invitationUid) => getRootState(state).invitations.items.find(invitation => invitation.uid === invitationUid);


    static setRootStateFn = (rootStateFn = state => state) => getRootState = rootStateFn;

}

export function invitations(state= initialState, action): InvitationsState {

    const isEdited = (updatedInvitation) => {
        return !_.isEqual(_.omit(updatedInvitation, 'edited'), _.omit(state.invitations.items.find(invitation => invitation.uid === updatedInvitation.uid), 'edited'))
    }

    let selectedUpdated = {};

    switch (action.type) {

        case INVITATIONS_ACTION_TYPES.INVITATIONS_VALIDATE:
            return { ...state,
                validated: action.invitation,
            };
        
        case INVITATIONS_ACTION_TYPES.INVITATIONS_CHANGE_OFFSET:
            return {
                ...state,
                invitations: {
                    ...state.invitations,
                    offset: action.offset
                }
            }
        
        case INVITATIONS_ACTION_TYPES.INVITATIONS_LIST_SUCCESS:
            const { items, offset, page_size, total_count } = action.invitations;
            return { ...state,
                invitations: {
                    items: items,
                    offset: offset ?? 0,
                    page_size: page_size ?? state.invitations.page_size,
                    total_count
                }, 
                isRequesting: false
            };
        
        case INVITATIONS_ACTION_TYPES.INVITATIONS_RESET_PAGINATION:
            return {
                ...state,
                invitations: initialState.invitations
            }

        case INVITATIONS_ACTION_TYPES.INVITATIONS_LIST_FAILURE:
            return initialState;

        case INVITATIONS_ACTION_TYPES.INVITATIONS_SELECT:

            return { ...state,
                selected: {
                    ...(state.invitations.items?.find((invitation: any) =>
                        invitation?.uid === action.invitationUid
                    ) ?? {}),
                    edited: false,
                },
            };

        case INVITATIONS_ACTION_TYPES.INVITATIONS_ACCESS_LINK_SELECT:
            return { ...state,
                publicUrl: action.publicUrl
            };

        case INVITATIONS_ACTION_TYPES.CREATE_LOCAL_INVITATION:
            return { ...state,
                newInvitationDraft: action.invitation,
            };
        case INVITATIONS_ACTION_TYPES.CREATE_LOCAL_ACCESS_LINK:
            return { ...state,
                newInvitationDraft: action.invitation,
            };
        case INVITATIONS_ACTION_TYPES.DELETE_INVITATION_DRAFT:
            return { ...state,
                newInvitationDraft: {},
            };
        
        case INVITATIONS_ACTION_TYPES.INVITATIONS_DELETE_SUCCESS:
            return { ...state,
                selected: {},
            };


        case INVITATIONS_ACTION_TYPES.INVITATIONS_UPDATE_SUCCESS:

            selectedUpdated = {
                ...state.selected,
                ...action.invitation,
            }

            return { 
                ...state,
                selected: { 
                    ...selectedUpdated,
                    edited: isEdited(selectedUpdated),
                },
            };

        case INVITATIONS_ACTION_TYPES.INVITATIONS_UPDATE_SELECTED_LOCALLY:

            selectedUpdated = {
                ...state.selected,
                ...action.selectedModified
            };

            return {
                ...state,
                selected: {
                    ...selectedUpdated,
                    edited: isEdited(selectedUpdated),
                },
            };

        case INVITATIONS_ACTION_TYPES.INVITATIONS_UPDATE_NEW_DRAFT_LOCALLY:
            return { ...state,
                newInvitationDraft: { ...state.newInvitationDraft,
                    ...action.selectedModified,
                    edited: true,
                },
            };

            
        case INVITATIONS_ACTION_TYPES.INVITATIONS_CHANGE_FILTER:
            return { ...state,
                filter: { ...state.filter,
                    ...action.filter,
                },
            };
        
        case INVITATIONS_ACTION_TYPES.PUSH_FULL_INVITATION_TO_SELECTED:
            return { ...state,  
                selected: action.selected,
            };

        case INVITATIONS_ACTION_TYPES.INVITATIONS_SET_REQUESTING_STATUS: 
            return {
                ...state,
                isRequesting: action.status
            }

        case INVITATIONS_ACTION_TYPES.CHANGE_WEEKDAY_SLOTS:
            
            selectedUpdated = { ...state.selected,
                timeSlots:{
                        ...state.selected.timeSlots,
                        ...action.weekDays
                    }
            };

            return {
                ...state,
                selected: {
                    ...selectedUpdated,
                    edited: isEdited(selectedUpdated),
                }
            }
        
        case INVITATIONS_ACTION_TYPES.DELETE_WEEKDAY_TIMESLOT:

            delete state.selected.timeSlots[action.weekDay];            
            return state;

        case INVITATIONS_ACTION_TYPES.CHANGE_WEEKDAY_SLOTS_ON_NEW:
            return {
                ...state,
                newInvitationDraft: {
                    ...state.newInvitationDraft,
                    timeSlots:{
                        ...state.newInvitationDraft.timeSlots,
                        ...action.weekDays
                    }
                }
            }
    
        case INVITATIONS_ACTION_TYPES.DELETE_WEEKDAY_TIMESLOT_ON_NEW:

            delete state.newInvitationDraft.timeSlots[action.weekDay];            
            return state;

        case INVITATIONS_ACTION_TYPES.INVITATIONS_DELETE_ALL_WEEKDAYS_ON_NEW:
            return {
                ...state,
                newInvitationDraft: {
                    ...state.newInvitationDraft,
                    timeSlots: null
                }
            }

        case INVITATIONS_ACTION_TYPES.INVITATIONS_DELETE_ALL_WEEKDAYS_ON_SELECTED:

            selectedUpdated = {
                ...state.selected,
                timeSlots: null
            }

            return {
                ...state,
                selected: {
                    ...selectedUpdated,
                    edited: isEdited(selectedUpdated),
                }
            }

        default:
            return state;
    }
}

