import { Integration, IntegrationCompany, LocalEditableIntegration } from "../../_modelsTS/Integration";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CapsuleButton } from "../../_components";
import { useDispatch, useSelector } from "react-redux";
import { integrationsActions } from "../../_actions/integrations.actions";
import { Selectors } from "../../_reducers/app.reducer";
import { Icons } from "../../_assets";
import ToolTipWrapper from "../../_components/ToolTipWrapper";

type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onDelete: (index: number) => void;
    index: number;
}

export function BookiproIntegrationInput({ 
    integration, 
    onDelete, 
    index, 
}: IntegrationInputProps) {

    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    // GLOBAL STATE
    const locationUid = useSelector(Selectors.getSelectedLocationUid) ?? '';
    const rooms = useSelector(Selectors.getRooms);

    // INITIALIZATION
    const onValidate = useCallback(async () => {
     
        const editedIntegration: Integration = {
            apikey: locationUid,
            company: IntegrationCompany.BOOKIPRO,
            type: integration.type,
            uid: integration.uid,
            createPublicUrl: false
        }
        await dispatch(integrationsActions.createIntegration(locationUid, editedIntegration));
            
    }, [dispatch, integration.company, integration.type, integration.uid, locationUid, t]);

    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            { integration.apikey === undefined &&
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <div>
                        <p>Permitir a&nbsp;<strong>Bookipro</strong>:</p>
                        <ul>
                        <li>Obtener dispositivos de la localizaci&oacute;n</li>
                        <li>Ver registros de actividad de los dispositivos de la localizaci&oacute;n</li>
                        <li>Crear, modificar y eliminar invitaciones a la localizaci&oacute;n</li>
                        </ul>
                    </div>
                </div>
            }
            { integration.apikey === undefined && 
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <CapsuleButton
                            size="SMALL"
                            isEnabled={true}
                            onClick={onValidate}
                            text={t("global_allow")}
                        />
                    </div>
                </div>
            }
            { integration.apikey  && 
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    {
                        rooms.map(room => 
                            buildRoomUi(room)
                        )
                    }
                    
                </div>
            }
            { integration.apikey &&
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <CapsuleButton
                            onClick={() => onDelete(index)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            style="DANGER"
                            size="SMALL"
                            icon={Icons.trash}
                        />
                    </div>
                </div>
            }
        </div>
    );

    function buildRoomUi(room) {
        let roomUid = room.uid;
        let roomName = room.name;
        
        return (
            <ToolTipWrapper
                key={roomUid}
                tooltipProps={getTooltipProps(roomUid)}
                tooltip={getTooltip({name:roomName})}
            >
                <div
                    key={roomUid}
                    onClick={() => handleCopyToClipboard(roomUid)}
                    className="c-hover-240 rounded-lg c-bg-grey-250"
                >
                    <div
                        className="rounded-lg px-3 p-2 "
                    >
                        <h6 className="font-weight-bold m-0">{roomName}</h6>
                        {Uid(roomUid)}
                    </div>
                </div>
            </ToolTipWrapper>
        );
    }

    function handleCopyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    function getTooltipProps(uid) {
        return {
            delayShow: 0,
            delayHide: 300,
            afterShow: () => handleCopyToClipboard(uid),
            event: "click",
            eventOff: "mouseout",
        };
    }

    function getTooltip({ name }) {
        return `${name}: ${t("global_uid_copied")}`
    }

    function Uid(uid) {
        return (
            <small className="font-italic text-secondary d-block">{uid}</small>
        );
    }
}