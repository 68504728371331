import { useTranslation } from 'react-i18next';
import { IsoStringDatePicker } from "./IsoStringDatePicker";

export function IsoStartEndDatePicker({
    startIsoDate,
    endIsoDate,
    handleStartDateChange,
    handleEndDateChange,
    timezone,
    dateOnly,
    maxDate,
}) {

    const { t } = useTranslation();

    return (
        <div className={`
            flex-grow-1
            d-flex
            justify-content-between
        `}>
            {datePicker(
                t("common.from"),
                startIsoDate,
                handleStartDateChange,
                IsoStringDatePicker.popperPlacementOptions().bottom,
            )}
            <div className="ml-2"/>
            {datePicker(
                t("common.to"),
                endIsoDate,
                handleEndDateChange,
                IsoStringDatePicker.popperPlacementOptions().bottom,
            )}
        </div>
    );

    function datePicker(
        name,
        isoDate,
        handleDateChange,
        popperPlacement,
    ) {
        return (
            <div className="full-width-datepicker">
                <IsoStringDatePicker
                text={name}
                isoString={isoDate}
                popperPlacement={popperPlacement}
                timezone={timezone}
                onChange={handleDateChange}
                dateOnly={dateOnly}
                maxDate={maxDate}
            />
            </div>
        );
    }
}