import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { devicesActions } from '../_actions';

import { ContentSpace } from '../_components';
import { DEVICES_SECONDARY_VIEWS } from '../_constants';
import { DeviceLogsPage } from './DeviceLogsPage';
import { DevicesMainPage } from './DevicesMainPage';
import { DeviceConfigPage } from './DeviceConfigPage';
import { Selectors } from '../_reducers/app.reducer';
import { RoomConfig } from './RoomConfig';

export function DevicesModule() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const secondaryView = useSelector(Selectors.getDevicesModuleSecondaryView);
    
    //CLEAN-UP
    useEffect(() => {
        return () => {
            dispatch(devicesActions.changeSecondaryView(null));
        };
    }, [dispatch]);


    //ACTION HANDLERS
    const onGoBack = useCallback(
        () => {
            dispatch(devicesActions.changeSecondaryView(null))
        }
        , [dispatch]);

    //VIEW
    return (
        <ContentSpace
            mainContent={<DevicesMainPage />}
            detailedContent={getComponentForView(secondaryView)}
            hasPadding={false} 
            onGoBack={onGoBack}
        />
    );

   

    function getComponentForView(view) {
        switch (view) {
            case DEVICES_SECONDARY_VIEWS.LOGS:
                return <DeviceLogsPage />;

            case DEVICES_SECONDARY_VIEWS.DEVICE_CONFIG:
                return <DeviceConfigPage />;
            
            case DEVICES_SECONDARY_VIEWS.ROOM_CONFIG:
                return <RoomConfig />

            default:
                return null;
        }
    }
}