import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MainPageWithButtons } from "../_components";
import { Device } from "../_models/Device";
import { DeviceCommonConfigPage } from "./DeviceCommonConfigPage";
import { DeviceFunctionality } from "../_helpers/DeviceFunctionality";
import { EditButtons } from "../_components/EditButtons";
import { devicesActions } from "../_actions";
import { DeviceAdvancedConfig } from "./DeviceAdvancedConfig";
import { appPermissions } from "../_constants/permissions.constants";
import { Selectors } from "../_reducers/app.reducer";
import { DEVICE_TYPES } from "../_constants";

export function DeviceConfigPage() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let device          = Device();
    device              = useSelector(Selectors.getDeviceConfig);

    let devices         = [Device()];
    devices             = useSelector(Selectors.getSelectedLocationDevices);
    const rooms         = useSelector(Selectors.getRooms);
    const permission    = useSelector(Selectors.getPermissionSelectedLocation);

    useEffect(() => {
        if (device?.type === DEVICE_TYPES.Brain ||
            DeviceFunctionality.supportsRemoteOTA(device)
        ) {
            dispatch(devicesActions.getDevice(device.uid, device.type)).then(
                () => dispatch(devicesActions.selectDeviceConfig(device.uid)),
            );
        }
        return () => {
            dispatch(devicesActions.selectDeviceConfig(null));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    //VIEW
    return (
        <MainPageWithButtons
            dataTestId={"device-config-view"}
            pageTitle={t("webapp_global_settings")}
            content={content()}
            size={'SMALL'}
            buttons={EditButtons({
                isEditing: device?.isEdited,
                handleDiscard: handleDiscardSelectedDeviceChanges,
                handleSave: handleSaveSelectedDevice,
            })}
        />
    );

    function content() {
        if (!device)
            return "DEVICE NOT FOUND";

        const canEditDeviceSettings = appPermissions.canUserEditDeviceSettings(permission);
        const canEditDeviceRoom = appPermissions.canUseEditDeviceRoom(permission);

        const canViewAdvancedConfig = appPermissions.canUserViewAdvancedDeviceSettings(permission);
        const canEditAdvancedConfig = appPermissions.canUserEditAdvancedDeviceSettings(permission);

        return (
            <div className="d-flex flex-column h-100">
                <DeviceCommonConfigPage
                    device={device}
                    rooms={rooms}
                    handleChangeName={handleChangeDeviceName}
                    handleChangeRoom={handleChangeDeviceRoom}
                    canEditDeviceRoom={canEditDeviceRoom}
                    canEditDeviceSettings={canEditDeviceSettings}
                />
                <DeviceAdvancedConfig
                    className="mt-4"
                    device={device}
                    devices={devices}
                    canViewAdvancedConfig={canViewAdvancedConfig}
                    canEditAdvancedConfig={canEditAdvancedConfig}
                />
            </div>
        );
    }

    //ACTION HANDLERS
    function handleChangeDeviceName(name) {
        dispatch(devicesActions.updateSelectedDeviceName(name));
    }

    function handleChangeDeviceRoom(roomUid) {
        dispatch(devicesActions.updateSelectedDeviceRoom(roomUid));
    }

    function handleDiscardSelectedDeviceChanges() {
        dispatch(devicesActions.discardSelectedDeviceChanges());
    }

    function handleSaveSelectedDevice() {
        dispatch(devicesActions.saveSelectedDevice());
    }
}