import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { ContentSpaceGeneric } from "../../Common/_components/ContentSpaceGeneric";
import { useScreenWidth } from "../../Common/_hooks/useScreenWidth";
import { SidebarContainer } from "../../Common/_components/SidebarContainer";
import { UI_CONSTANTS } from "../../Common/_constants/UI_CONSTANTS";

const fn = () => {};
const action = () => () => {};

export function WiredContentSpace({
    testID,
    getComponentForModuleId = fn,
    selectModuleAction = action,
    SidebarComp
} = {}) {

    //LIBRARIES
    const screenWidth = useScreenWidth();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const selectedModuleId = useSelector(Selectors.getSelectedModuleId);
    const enabledModulesIds = useSelector(Selectors.getEnabledModulesIds);

    //ACTION HANDLERS
    const handleSelectModule = useCallback(moduleId => {
        dispatch(selectModuleAction(moduleId));
    }, [dispatch, selectModuleAction]);

    //DEVIRED STATE
    const SelectedModuleComponent = useMemo(() =>
        getComponentForModuleId(selectedModuleId)
    , [selectedModuleId, getComponentForModuleId]);
    const isHoverMenu = screenWidth < UI_CONSTANTS.CUSTOM_BREAKPOINTS.XL;

    return (
        <ContentSpaceGeneric testID={testID}>
            <SidebarContainer {...{ isHoverMenu }}>
                <SidebarComp {...{
                    selectedModuleId,
                    enabledModulesIds,
                    handleSelectModule,
                }} />
            </SidebarContainer>
            <div className="flex-fill overflow-auto">
                <SelectedModuleComponent />
            </div>
        </ContentSpaceGeneric>
    );
}