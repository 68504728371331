import { URL_CONSTANTS } from "../_constants";
import { ServiceHelper } from "./ServiceHelper";

export const RoomsService = {
    createRoom,
    updateRoom,
    deleteRoom,
}

async function createRoom({ type, name, loc_uid }) {
    return ServiceHelper.postRequest(URL_CONSTANTS.ROOM_CREATE, {
        loc_uid,
        name,
        type,
    });
}

async function updateRoom({ type, name, uid }) {
    return ServiceHelper.postRequest(URL_CONSTANTS.ROOM_UPDATE, {
        uid,
        name,
        type
    });
}

async function deleteRoom(uid: string) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.ROOM_REMOVE,
        {
            uid
        },
    );
}