import { ServerBarrier } from "../_modelsTS/Barrier"
import { URL_CONSTANTS } from "../_constants"
import { ServiceHelper } from "./ServiceHelper"

export const BarriersService = {
    getInfo,
    updateInfo
}

function getInfo(loc_uid: string): Promise<ServerBarrier> {
    return ServiceHelper.getRequest(URL_CONSTANTS.BARRIERS_INFO, {
        loc_uid
    })
}

function updateInfo(barrier: Partial<ServerBarrier>,loc_uid: string): Promise<ServerBarrier> {
    return ServiceHelper.postRequest(URL_CONSTANTS.BARRIERS_INFO, {
        loc_uid,
        ...barrier
    })
}