import React from "react";
import { SwitchGeneric } from "./SwitchGeneric";

export function SwitchOnOff({
    deviceStatus,
    remote,
    handleSendAction = actionStatus => {},
}) {
    //VIEW
    return (
        <SwitchGeneric
            deviceStatus={deviceStatus}
            handleSendAction={handleSendAction}
            remote={remote}
            leftButtonName={"off"}
            rightButtonName={"on"}

            leftSideStatus={0}
            rightSideStatus={1}

            offStatus={0}
        />
    );
}