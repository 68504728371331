import { uiAlertActions } from "../../Everou/_actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { useGlobalNotification } from "../_hooks/useGlobalNotification";

export function WiredUiAlerts() {

    const { notify } = useGlobalNotification();
    const uiAlert = useSelector(Selectors.getCurrentAlert);
    const dispatch = useDispatch();

    useEffect(() => {
        if(uiAlert) { 
            const alertType = uiAlert.type;
            notify(uiAlert.message, alertType, () => dispatch(uiAlertActions.clear()));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiAlert]);

    return <ToastContainer />;
}