import { BleActionData } from "../_models/BleActionData";
import { GenericMapperFactory } from "./MapperFactory";

export const BleActionDataMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverBleActionData) {
    const message = (serverBleActionData && serverBleActionData.message) || {};

    return BleActionData({
        confirmed:      serverBleActionData.confirmed,
        actionUid:      serverBleActionData.action_uid,
        action:         message.action,
        packet:         message.packet,
        operationCode:  message.operaton_code,
        bleId:          message.ble_id,
        bleIdOrigin:    message.ble_id_origin,
    });
}