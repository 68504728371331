import { GenericMapperFactory } from "./MapperFactory";
import { EverouDeveloper } from "../_models/EverouDeveloper";

export const EverouDeveloperMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverDev) {
    return EverouDeveloper({
        uid: serverDev.uid,
        email: serverDev.email,
        name: serverDev.name,
    })
}