import React from "react";

import { Room } from "../../_models/Room";
import { ListOfRemovableItems } from "../../../Common/_components/ListOfRemovableItems";

export function MultipleRoomViewer({
    selectedRooms = [] || [Room()],
}) {

    //VIEW
    return (
        <ListOfRemovableItems
            isReadOnly
            items={selectedRooms.map(room =>
                ListOfRemovableItems.buildItem({
                    id: room.uid,
                    name: room.name,
                })
            )}
        />
    );
}