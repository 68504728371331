import { SmoobuService } from "../_services/smoobu.service";
import { genericActions } from "./generic.actions"
import { SmoobuStore } from "../_stores/SmoobuStore";


export const smoobuActions = {
    listApartments,
}

function listApartments(uid: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverSmoobuApartments = await SmoobuService.listApartments(uid);
            dispatch(SmoobuStore.actionListItemsSuccess(serverSmoobuApartments))
        }
    }
}