import React, { useState, useRef  } from 'react';

export function GenericCustomDropdown({
    isReadOnly = false,
    testid,
    selectedOptionFn = () => {},
    optionsListFn = (isOpen) => {},
    relativeToExternalParent = false,
    onBlur = () => {},
    setUp = () => {},
}) {

    //LOCAL VARIABLES
    const [isOpen, setIsOpen] = useState(false);
    const timeoutFnRef= useRef(() => {});
    const dropdownRef= useRef(() => {});
    const optionsRef= useRef(() => {});
    const isOptionFocusedRef = useRef(false);

    //VIEW
    return (
        <div
            onFocus={onFocusDrop}
            ref={dropdownRef}
            data-testid={testid}
            tabIndex="0"
            onBlur={onBlurDrop}
            className={`
                w-100
                ${isReadOnly ? 'cna' : 'cp'}
                ${!relativeToExternalParent && "position-relative"}
            `}
        >
            <div
                tabIndex="0"
                onClick={onClickSelected}
                className={"c-no-focus-outline"}
            >
                {selectedOptionFn()}
            </div>
            <div
                ref={optionsRef}
                tabIndex="0"
                onFocus={onFocusOptions}
                onBlur={onBlurOptions}
                onClick={onBlurOptions}
            >
                {optionsListFn(isOpen)}
            </div>
        </div>
    );

    //LOCAL HANDLERS
    function onClickSelected() {
        if (isReadOnly)
            return;

        clearTimeout(timeoutFnRef.current);
        setIsOpen(!isOpen);
    }

    function onFocusOptions() {
        isOptionFocusedRef.current = true;
    }

    function onBlurOptions() {
        isOptionFocusedRef.current = false;
        onBlurDrop();
    }

    function onBlurDrop() {
        timeoutFnRef.current = setTimeout(onBlurCleanUp, 0);

        function onBlurCleanUp() {
            if (isOptionFocusedRef.current)
                return;

            setIsOpen(false);
            onBlur();

            //So it doesn't stay focused on finish
            dropdownRef.current && dropdownRef.current.blur();
            optionsRef.current && optionsRef.current.blur();
        }
    }

    function onFocusDrop() {
        if (isOpen)
            return;
            
        setUp();
    }
}