export class LocalStorageHelper {

    static saveState(state) {
        try {
            localStorage.setItem(STORED_FIELDS.STATE, JSON.stringify(state));
        } catch (error) {
            console.error("Error saving state to localStorage", error);
        }
    }

    static combineState(state) {
        try {
            const currentState = LocalStorageHelper.loadState() || {};
            const combinedState = {
                ...currentState,
                ...state,
            };
            LocalStorageHelper.saveState(combinedState);
        } catch (error) {
            console.error("Error combining state to localStorage", error);
        }
    }

    static loadState() {
        try {
            const serializedState = localStorage.getItem(STORED_FIELDS.STATE);
            if (serializedState === null){
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (error) {
            console.error("Error loading state from localStorage", error);
            return undefined;
        }
    }

    static removeState() {
        localStorage.removeItem(STORED_FIELDS.STATE);
    }
    
    static loadMachineUid() {
        return localStorage.getItem(STORED_FIELDS.MACHINE_UID);
    }

    static saveMachineUid(uid) {
        localStorage.setItem(STORED_FIELDS.MACHINE_UID, uid);
    }
}

const STORED_FIELDS = {
    MACHINE_UID: 'machine_uid',
    STATE: 'state',
};
