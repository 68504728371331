import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
    filterDisabled,
    nullValues,
} from '../_constants';

import { LogsHelper, DeviceHelper, TimeHelper } from '../_helpers';
import { LogsTable } from './LogsTable';
import { CustomDropdown } from "../_components/CustomDropdown";
import { Device } from "../_models/Device";
import { User } from "../_models/User";
import { Selectors } from "../_reducers/app.reducer";
import { GenericLogsPage } from "../_components/GenericLogsPage";
import { deviceLogsActions } from "../_actions/deviceLogs.actions";

const devicesArray = [Device()];

export function DeviceLogsColumn() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);
    const timezone      = useSelector(Selectors.getTimezone);
    const users         = useSelector(Selectors.getUsers);
    let devices         = devicesArray;
    devices             = useSelector(Selectors.getSelectedLocationDevices);
    
    const logs              = useSelector(Selectors.getDeviceLogs);
    const isRequesting      = useSelector(Selectors.getDeviceLogsIsRequesting);
    const isRequestingFirst = useSelector(Selectors.getDeviceLogsIsRequestingFirst);
    const nextPage          = useSelector(Selectors.getDeviceLogsNextPage);
    
    const filterType        = useSelector(Selectors.getDeviceLogsFilterType);
    const filterStatus      = useSelector(Selectors.getDeviceLogsFilterTypeStatus);
    const filterUser        = useSelector(Selectors.getDeviceLogsFilterUser);

    const { begin, end }    = useSelector(Selectors.getDeviceLogsDateFilter);

    //LOCAL STATE
    const [selectedDevices, setSelectedDevices] = useState([]);

    //ACTION HANDLERS
    const handleNextPage                    = () => dispatch(deviceLogsActions.getDeviceLogsNextPage(nextPage, isRequesting));
    const handleLogsDateStartChange         = date => dispatch(deviceLogsActions.setFilterDateBegin(date));
    const handleLogsDateEndChange           = date => dispatch(deviceLogsActions.setFilterDateEnd(date));
    const handleChangeLogsFilterUser        = value => dispatch(deviceLogsActions.setFilterUser(value));
    const handleChangeLogsFilterTypeStatus  = value => dispatch(deviceLogsActions.setFilterTypeStatus(value));
    const handleChangeLogsFilterType        = value => dispatch(deviceLogsActions.setFilterType(value));

    //INITIALIZATION
    useEffect(() => {
        initialize();

        async function initialize() {
            dispatch(deviceLogsActions.setFilterDateBeginEnd(
                TimeHelper.localizedStartOfTodayIsoString(timezone),
                TimeHelper.localizedEndOfTodayIsoString(timezone),
            ));

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid]);

    //STATE UPDATE
    useEffect(() => {
        updateSelectedDevices();

        function updateSelectedDevices() {
            const newSelectedDevices = devices.filter(device => device.selected);

            setSelectedDevices(prevSelectedDevices =>
                DeviceHelper.haveSelectedDevicesChanged(
                    prevSelectedDevices,
                    newSelectedDevices,
                )
                    ? newSelectedDevices
                    : prevSelectedDevices
            );
        }
    }, [devices]);

    useEffect(() => {
        if (!begin || !end)
            return;

        dispatch(deviceLogsActions.getDeviceLogsFirstPage());
    }, [dispatch,
        selectedDevices,
        begin, end,
        filterType, filterUser, filterStatus,
    ]);

    //VIEW
    return (
        <GenericLogsPage
            isThereLogs={logs.length !== 0}
            otherFilters={otherFilters()}

            isRequesting={isRequesting}
            requestingFirst={isRequestingFirst}
            nextPage={nextPage}

            startIsoString={begin}
            endIsoString={end}
            timezone={timezone}
            
            content={
                <LogsTable
                    devices={devices}
                    logs={logs}
                    isSmall={false}
                    timezone={timezone}
                />
            }

            handleNextPage={handleNextPage}
            handleLogsDateStartChange={handleLogsDateStartChange}
            handleLogsDateEndChange={handleLogsDateEndChange}
        />
    );

    function otherFilters() {
        return (
            <div className="
                flex-shrink-0
                d-flex
                no-gutters

                flex-wrap
            ">
                <div className="col">
                    {typeFilter()}
                    {statusFilter()}
                </div>
                <div className="pl-2"/>
                <div className="col">
                    {userFilter()}
                </div>
            </div>
        );
    }

    function userFilter() {
        return (
            dropdown(
                t("common.user"),
                buildUiUserFilterOptions(users),
                handleChangeLogsFilterUser,
                filterUser,
            )
        );
    }

    function typeFilter() {
        return (
            dropdown(
                t("common.type"),
                buildUiTypeFilterOptions(devices),
                handleChangeLogsFilterType,
                filterType,
                false
            )
        );
    }

    function statusFilter() {
        if (!isStatusFilterAvailable())
            return;

        const options = buildUiLogStatusFilterOptions();

        if (options.length === 1)
            return;

        return (
            <>
            {dropdown(
                t("common.status"),
                options,
                handleChangeLogsFilterTypeStatus,
                filterStatus,
                false
            )}
            </>
        );
    }

    function dropdown(listTitle, options, onChange, value, isSearchable) {
        return (
            <CustomDropdown
                title={listTitle}
                options={options}
                onChange={onChange}
                value={value}
                isSearchable={isSearchable}
            />
        );
    }

    function buildUiLogStatusFilterOptions() {
        const currentUiLogType = filterType;
        const statusesObjectForType = LogsHelper.getStatusesForUiLogType(currentUiLogType);

        if (typeof statusesObjectForType === "string") {
            return [
                CustomDropdown.buildOption(
                    LogsHelper.getStatusLabelFromLog(
                        currentUiLogType,
                        statusesObjectForType,
                    ),
                )
            ];
        }

        const uiStatuses = [];
        for (let statusId in statusesObjectForType) {
            const uiStatus = CustomDropdown.buildOption(
                statusId,
                LogsHelper.getStatusLabelFromLog(currentUiLogType, statusId),
            );
            uiStatuses.push(uiStatus);
        }

        if (uiStatuses.length !== 1)
            uiStatuses.unshift(allOption());

        return uiStatuses;
    }

    function buildUiTypeFilterOptions(devices) {
        const logTypes = LogsHelper.getUiLogTypesFromDeviceTypes(devices);
        const uiTypes =
            logTypes
            .map(logType =>
                CustomDropdown.buildOption(
                    logType,
                    LogsHelper.getUiLogTypeName(logType),
                )
            )
            .sort((a, b) => a.label.localeCompare(b.label))
        ;

        if (uiTypes.length !== 1)
            uiTypes.unshift(allOption());

        return uiTypes;
    }

    function buildUiUserFilterOptions(users = [] || [User()]) {
        const uiUsers =
            users
            .map(user =>
                CustomDropdown.buildOption(
                    user.uid,
                    user.email,
                )
            )
            .sort((a, b) => a.label.localeCompare(b.label))
        ;

        uiUsers.unshift(CustomDropdown.buildOption(
            nullValues.user,
        ));

        if (uiUsers.length !== 1)
            uiUsers.unshift(allOption());

        return uiUsers;
    }

    function allOption() {
        return CustomDropdown.buildOption(
            filterDisabled.number,
            filterDisabled.all,
        );
    }

    function isStatusFilterAvailable() {
        return filterType !== filterDisabled.number;
    }
}