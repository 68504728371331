import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { invitationsActions } from "../_actions";
import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { Invitation } from "../_models/Invitation";
import { NULL_MAIL } from "../_constants";

export function DeleteSuperguestInvitationGlobalPopUp({
    invitation = Invitation(),
}) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //ACTION HANDLERS
    function handleDeleteInvitation() {
        dispatch(invitationsActions.deleteSubinvitation(invitation.uid));
    }

    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={
                t("invitations.deleteInvitationQuestion",
                { invitation: emailOrUserName(invitation) },
            )}
            body={t("invitations.deleteSubinvitationDescription")}
            onAccept={handleDeleteInvitation}
        />
    );

    function emailOrUserName() {
        if (invitation.email !== NULL_MAIL)
            return invitation.email;

        return invitation.userName;
    }
}