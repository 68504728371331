import { INVITATION_PERIODS, INVITATION_TYPES, LOCAL_INVITATION_STATUS } from "../_constants";
import { LOCATION_PERMISSIONS } from "../_constants/permissions.constants";
import { Room } from "./Room";

export function Invitation({
    monday = false,
    tuesday = false,
    wednesday = false,
    thursday = false,
    friday = false,
    saturday = false,
    sunday = false,
    createdOn = '',
    enabled = true,
    code = '',
    objectUid = '',
    uid = '',
    locationUid = '',
    locationName = '',

    type = INVITATION_TYPES.LOCATION,
    status = null,

    allDay = false,

    userName = null,
    userUid = null,
    email = "",
    
    permissionType = LOCATION_PERMISSIONS.GUEST,
    deviceName = null,
    emails = [],
    avatar = null,

    periodType = INVITATION_PERIODS.ALWAYS,
    begin = null,
    end = null,

    newInvitation = false,
    edited = false,

    userSrcName = null,
    userSrcEmail = null,
    allowsSubinvitations = false,
    subinvitations = null,

    subinvitationsLeft = null,

    localStatus = LOCAL_INVITATION_STATUS.UNDEFINED,

    roomName = null,
    roomType = null,

    invitationGroupUid = null,

    isSubinvitation = false,

    superuser = false,
    sat = false,
    url = "",

    rooms = [] || [Room()],

    publicUrl = '',

    createPublicUrl = false,

    timeSlots = null

} = {}) {

    return {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday, 
        createdOn,
        enabled,
        code,
        objectUid,
        uid,
        locationUid,
        locationName,

        type,
        status,

        allDay,
        userName,
        userUid,
        permissionType,
        deviceName,

        roomName,
        roomType,

        avatar,
        email,
        emails,
        begin,
        end,
        periodType,

        newInvitation,
        edited,

        userSrcName,
        userSrcEmail,
        allowsSubinvitations,
        subinvitations,

        subinvitationsLeft,
        localStatus,

        invitationGroupUid,

        isSubinvitation,

        superuser,
        sat,
        url,

        rooms,

        publicUrl,

        createPublicUrl,

        timeSlots
    };
}

export interface InvitationQueryParams {
    loc_uid: string;
    page_size?: number;
    offset: number;
    email?: string;
    group_uid?: string;
}

export enum WeekDaysEnum {
    MONDAY = '0',
    TUESDAY = '1',
    WEDNESDAY = '2',
    THURSDAY = '3',
    FRIDAY = '4',
    SATURDAY = '5', 
    SUNDAY = '6'
}
export interface TimeSlot {
    start: string,
    end: string
}

export type WeekDaysSlots = { [key in WeekDaysEnum]?: TimeSlot[] }


