import { Integration, IntegrationCompany, IntegrationReservationStatusLabel, integrationsByCompany, LinkType } from "../../Everou/_modelsTS/Integration";
import { IntegrationApp } from "../../Everou/_models/IntegrationApp";
import { useTranslation } from "react-i18next";
import { ContentSpace, CustomCard, MainPageWithButtons } from "../../Everou/_components";
import { PMSAvaibookIntegration } from "./platforms/PMSAvaibookIntegration";
import { PMSHostifyIntegration } from "./platforms/PMSHostifyIntegration";
import { PMSKrossBookingIntegration } from "./platforms/PMSKrossBookingIntegration";
import { ThreeAngularIntegration } from "../../Everou/IntegrationsModule/platforms/ThreeAngularIntegration";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { integrationsActions } from "../../Everou/_actions/integrations.actions";
import { useMemo } from "react";
import { IntegrationStore } from "../../Everou/_stores/IntegrationStore";
import { ReadOnlyDataRow } from "../../Everou/_components";
import { IntegrationsColumn } from "../../Everou/IntegrationsModule/components/IntegrationsColumn";
import { PMSSmoobuIntegration } from "./platforms/PMSSmoobuIntegration";
import { PMSIntegrationsActions } from "../_actions/PMSIntegrationsActions";
import { PMSAvantioIntegration } from "./platforms/PMSAvantioIntegration";
import { PMSOctorateIntegration } from "./platforms/PMSOctorateIntegration";
import { PMSAvaibookNewIntegration } from "./platforms/PMSAvaibookNewIntegration";

export function PMSIntegrationsModule() {

    // INITIALIZATION
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const integrations = useSelector(Selectors.getPMSIntegrations);
    const avaibookIntegrations = integrationsByCompany(integrations, IntegrationCompany.AVAIBOOK);
    const hostifyIntegrations = integrationsByCompany(integrations, IntegrationCompany.HOSTIFY);
    const octorateIntegrations = integrationsByCompany(integrations, IntegrationCompany.OCTORATE);
    const krossBookingIntegrations = integrationsByCompany(integrations, IntegrationCompany.KROSS_BOOKING);
    const threeAngularIntegrations = integrationsByCompany(integrations, IntegrationCompany.THREE_ANGULAR);
    const smoobuIntegrations = integrationsByCompany(integrations, IntegrationCompany.SMOOBU);
    const avantioIntegrations = integrationsByCompany(integrations, IntegrationCompany.AVANTIO);

    const currentIntegrationApps = [
        IntegrationApp({id:0, company:IntegrationCompany.AVAIBOOK, link: LinkType.ICAL, installed:avaibookIntegrations[0].uid}),
        IntegrationApp({id:1, company:IntegrationCompany.HOSTIFY, link: LinkType.WEBHOOK, installed:hostifyIntegrations[0].uid}),
        IntegrationApp({id:2, company:IntegrationCompany.OCTORATE, link: LinkType.WEBHOOK, installed:octorateIntegrations[0].uid}),
        IntegrationApp({id:3, company:IntegrationCompany.KROSS_BOOKING, link: LinkType.API_ENDPOINT, installed:krossBookingIntegrations[0].uid}),
        IntegrationApp({id:4, company:IntegrationCompany.THREE_ANGULAR, link: LinkType.DEVELOPERS, installed: threeAngularIntegrations[0].uid}),
        IntegrationApp({id:5, company:IntegrationCompany.SMOOBU, link: LinkType.WEBHOOK, installed:smoobuIntegrations[0].uid}),
        IntegrationApp({id:5, company:IntegrationCompany.AVANTIO, link: LinkType.ICAL, installed:avantioIntegrations[0].uid}),
    ]


    const secondaryView = useSelector(Selectors.getIntegrationsModuleSecondaryView);
    const selectedIntegration: Integration = useSelector(IntegrationStore.selectorGetSelectedItem);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        dispatch(PMSIntegrationsActions.listIntegrations());
        return () => { closeViewFn(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const closeViewFn = useCallback(() => {
        dispatch(integrationsActions.changeSecondaryView(null));
        dispatch(IntegrationStore.actionSelectItem(null));
    }, [dispatch]);

    const detailedContent = useMemo(() => {
        if(!selectedIntegration) {
            switch(secondaryView) {
                case IntegrationCompany.AVANTIO:
                    return (
                        <PMSAvantioIntegration />
                    );
                    
                case IntegrationCompany.AVAIBOOK:
                    return (
                        <PMSAvaibookNewIntegration />
                    );

                case IntegrationCompany.HOSTIFY:
                    return (
                        <PMSHostifyIntegration />
                    )

                case IntegrationCompany.KROSS_BOOKING:
                    return (
                        <PMSKrossBookingIntegration />
                    )

                /*case IntegrationCompany.THREE_ANGULAR:
                    return (
                        <ThreeAngularIntegration
                            groups={groupsByType}
                            integrations={threeAngularIntegrations}
                        />
                    )*/
                case IntegrationCompany.OCTORATE:
                    return (
                        <PMSOctorateIntegration />
                    )

                case IntegrationCompany.SMOOBU:
                    return (
                        <PMSSmoobuIntegration />
                    )
    
            }
            return null;
        }

        if(selectedIntegration.reservations?.length === 0) {
            return <MainPageWithButtons
                //dataTestId={testIDs.root}
                pageTitle={t("mod_integrations_reservations_title")}
                content={
                    <div className="c-h-100 d-flex justify-content-center align-items-center">
                        {t('mod_integrations_reservations_not_yet')}
                    </div>
                }
                buttons={undefined}
            />

        }

        return <MainPageWithButtons
                    //dataTestId={testIDs.root}
                    pageTitle={t("mod_integrations_reservations_title")}
                    content={
                        <div className="py-4 c-grid">
                            {selectedIntegration.reservations?.map(( reservation, index ) =>
                            <CustomCard banner={
                                IntegrationReservationStatusLabel[reservation.status]
                            } key={reservation.uid}>
                                <ReadOnlyDataRow
                                    key={reservation.uid + '1'}
                                    name={t('global_email')}
                                    data={reservation.email} />
                                <ReadOnlyDataRow
                                    key={reservation.uid + '2'}
                                    name={t('mod_integrations_reservation_id')}
                                    data={reservation.reservationId} />
            
                                <ReadOnlyDataRow
                                    key={reservation.uid + '3'}
                                    name={t('mod_integrations_reservation_start')}
                                    data={reservation.reservationStart} />
            
                                <ReadOnlyDataRow
                                    key={reservation.uid + '4'}
                                    hasSeparators={false}
                                    name={t('mod_integrations_reservation_end')}
                                    data={reservation.reservationEnd} />
            
                            </CustomCard>
                            )}
                        </div>
                    }
                    buttons={undefined}
                />
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryView, selectedIntegration?.reservations])

    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    content={integrationsContent()}
                    pageTitle={t("mod_integrations_title")}
                />
            }
            onGoBack={closeViewFn}
            detailedContent={
                detailedContent
            }
        />

    );

    function integrationsContent() {
        return (
            <div className="c-default-grid">
                <IntegrationsColumn
                    integrations={currentIntegrationApps}
                />
            </div>
        );
    }
}
