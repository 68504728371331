import React from "react";

import { InlineEditButtons } from "../_components/InlineEditButtons";
import { TimeHelper } from "../_helpers";

type EditableTimeProps = {
    localDay: Date;
    time?: Date;
    setTime: (time: Date) => void;
    timezone: string;
    handleConfirmChanges: (time?: Date) => void;
    handleDiscardChanges: () => void;
};

export function EditableTime({
    localDay,
    time,
    setTime = () => {},

    timezone,

    handleConfirmChanges = () => {},
    handleDiscardChanges = () => {},
}: EditableTimeProps) {
    function localHandleConfirm() {
        handleConfirmChanges(time);
    }

    function localHandleDiscard() {
        handleDiscardChanges();
    }

    //VIEW
    return (
        <>
            <CustomTimeSelector
                {...{
                    localDay,
                    timezone,
                    time,
                    setTime,
                }}
            />
            <div className="ml-2" />
            <InlineEditButtons
                acceptButtonTestID={undefined}
                cancelButtonTestID={undefined}
                {...{
                    handleConfirmChanges: localHandleConfirm,
                    handleDiscardChanges: localHandleDiscard,
                    isFlipped: true,
                }}
            />
        </>
    );
}

function CustomTimeSelector({
    time,
    setTime = () => {},
    localDay,
    timezone,
}: Partial<EditableTimeProps>) {
    return (
        <input
            className="border rounded"
            type="time"
            onChange={handleChange}
            value={TimeHelper.localizeIsoDateToHtmlInput(time, timezone)}
        />
    );

    function handleChange(e) {
        let result = htmlInputToIsoDate(e.target.value, localDay, timezone);
        if (result !== null) {
            setTime(new Date(result));
        }
    }

    function htmlInputToIsoDate(htmlTime, localDay, timezone) {
        if (htmlTime === "") {
            return null;
        }

        const { hours, minutes } = parseHtmlTimeInput(htmlTime);

        return TimeHelper.localDayAndTimeToIso(
            localDay,
            hours,
            minutes,
            timezone,
        );

        function parseHtmlTimeInput(time) {
            const [hours, minutes] = String(time).split(":");
            return {
                hours,
                minutes,
            };
        }
    }
}
