import { LogsHelper } from "../_helpers";

export class WebsocketMapper {

    static serverToLocalStatus(websocketData) {
        const {
            uid,
            status,
            type: deviceType,
        } = websocketData.device_data;
        
        return {
            uid,
            status,
            statusLabel: LogsHelper.getStatusLabelFromDevice(deviceType, status),
        };
    }
    
    static serverToLocalNonStatus(websocketData) {
        return {
            uid: websocketData.device_data.uid,
            statusLabel: LogsHelper.getStatusLabelFromLog(websocketData.log_ui_type),
        };
    }
}