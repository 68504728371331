import React from "react";

import { WaitSpinner } from "./WaitSpinner";

export function AppInitializationWrapper({
    isInitialized = false,
    isSuccessful = false,
    failureMessage = "NO_MESSAGE",
    content = null,
}) {

    //VIEW
    if (!isInitialized)
        return (
            <div className="my-auto">
                <WaitSpinner />
            </div>
        );

    if (!isSuccessful)
        return failureMessageContent();

    return content;

    
    function failureMessageContent() {
        return (
            <div className="
                my-auto
                mx-auto
                
                py-2
                px-3
                
                border
                rounded
                bg-light
            ">
                {failureMessage}
            </div>
        );
    }
}