const TYPES = {
    CARD: "card",
    SEPA: "sepa",
};

PaymentMethod.TYPES = TYPES;

export function PaymentMethod({
    uid,
    locationUid,
    locationName,
    type,
    data = CardDataModel(),
} = {}) {
    return {
        uid,
        locationUid,
        locationName,
        type,
        data,
    };
}

export function CardDataModel({
    brand,
    expMonth,
    expYear,
    last4,
} = {}) {
    return {
        brand,
        expMonth,
        expYear,
        last4,
    };
}