import React from "react";
import { useTranslation } from "react-i18next";

import { SimpleButton } from "../_components/SimpleButton";
import { Reservation } from "../_models/Reservation";

import { TimeHelper } from "../_helpers";

export function SingleReservation({
    reservation = Reservation(),
    handleCancelReservation,
}) {
    //LIBRARIES
    const { t } = useTranslation();

    return (
        <div
            key={reservation.uid}
            className="
                border

                d-flex
                justify-content-between
                align-items-center

                px-2
                py-1
            "
        >
            <div>
                {reservation.userEmail}
            </div>
            {cancelButton()}
        </div>
    );

    function cancelButton() {
        if (TimeHelper.isBeforeNowUtc(reservation.end))
            return null;

        return (
            <SimpleButton
                onClick={() => handleCancelReservation(reservation.uid)}
                text={t("common.cancel")}
                buttonStyle="
                    btn-danger
                    py-1
                    text-capitalize
                "
            />
        );
    }
};