import { createSelector } from "reselect";
import { NO_NEXT_PAGE } from "../../Everou/_constants";

export function GenericCollectionFactory<T>(ID = "", idFieldName, rootStateFn = state => state) {
    
    //ACTION TYPES
    const _typeListItemsRequest = `LIST_ITEMS_REQUEST_${ID}`;
    const _typeListItemsSuccess = `LIST_ITEMS_SUCCESS_${ID}`;

    const _typeGetItemSuccess = `GET_ITEM_SUCCESS_${ID}`;
    const _typeUpdateItemSuccess = `UPDATE_ITEM_SUCCESS_${ID}`;
    const _typeSelectItem = `SELECT_ITEM_${ID}`;
    const _typeDeleteItem = `DELETE_ITEM_${ID}`;
    const _typeDeleteAllItemsSuccess = `DETELE_ALL_ITEMS_${ID}`;

    //HELPERS
    const setRootStateFn = (newRootStateFn = state => state) => rootStateFn = newRootStateFn;

    //SELECTORS
    const selectorGetSelectedId         = state => rootStateFn(state).selectedId;
    const selectorGetItems              = state => rootStateFn(state).items as T[];
    const selectorGetItem               = (state, id) => rootStateFn(state).items.find(item => item[idFieldName] === id) as T;
    const selectorIsRequestingFirstPage = state => rootStateFn(state).isRequestingList && rootStateFn(state).page === 0;
    const selectorIsRequestingList      = state => rootStateFn(state).isRequestingList;
    const selectorGetNextPage           = state => rootStateFn(state).next;

    const selectorGetSelectedItem = createSelector(
        state => rootStateFn(state).items,
        state => rootStateFn(state).selectedId,
        (items, selectedId) => items.find(item => item[idFieldName] === selectedId) || null,
    );
    
    return {
        //REDUCER
        reducerCollection,
    
        //ACTIONS
        actionListItemsRequest,

        actionListItemsSuccess,

        actionGetItemSuccess,
        actionUpdateItemSuccess,
        actionDeleteItem,
        actionDeleteAllItemsSuccess,
        
        actionSelectItem,
    
        //SELECTORS
        selectorGetSelectedId,
        selectorIsRequestingFirstPage,
        selectorIsRequestingList,
        selectorGetNextPage,

        selectorGetSelectedItem,
        selectorGetItems,
        selectorGetItem,
    
        //PRIVATE TYPES
        _typeGetItemSuccess,

        _typeListItemsRequest,
        _typeListItemsSuccess,
        
        _typeSelectItem,
        _typeUpdateItemSuccess,
        _typeDeleteItem,
        _typeDeleteAllItemsSuccess,

        //HELPER
        setRootStateFn,
    };
    
    //ACTIONS
    function actionListItemsSuccess<T>(items: T[] = [], next = NO_NEXT_PAGE) {
        return {
            type: _typeListItemsSuccess,
            items,
            next,
        };
    }

    function actionListItemsRequest(page = 0) {
        return {
            type: _typeListItemsRequest,
            page,
        };
    }
    
    function actionGetItemSuccess(item) {
        return {
            type: _typeGetItemSuccess,
            item,
        };
    }
    
    function actionUpdateItemSuccess(item) {
        return {
            type: _typeUpdateItemSuccess,
            item,
        };
    }
    
    function actionSelectItem(id) {
        return {
            type: _typeSelectItem,
            id,
        };
    }

    function actionDeleteItem(id) {
        return {
            type: _typeDeleteItem,
            id,
        };
    }

    function actionDeleteAllItemsSuccess() {
        return { type: _typeDeleteAllItemsSuccess };
    }

    //REDUCER
    function initialStateFn() {
        return {
            items: [],
            selectedId: null,
            isRequestingList: false,
            page: 0,
            next: NO_NEXT_PAGE,
        };
    }
    
    function reducerCollection(state = initialStateFn(), action) {
        switch (action.type) {
            case _typeListItemsRequest:
                return {
                    ...state,
                    isRequestingList: true,
                    page: action.page,
                };

            case _typeListItemsSuccess:
                return {
                    ...state,
                    items: state.page === 0
                        ? action.items
                        : [
                            ...state.items,
                            ...action.items
                        ],
                    isRequestingList: false,
                    next: action.next,
                };

            case _typeGetItemSuccess:
                return {
                    ...state,
                    items: [
                        ...state.items.filter(item => item[idFieldName] !== action.item[idFieldName]),
                        action.item,
                    ],
                };

            case _typeUpdateItemSuccess:
                return {
                    ...state,
                    items: state.items.map((item: any) =>
                        item[idFieldName] === action.item[idFieldName]
                        ? {
                            ...item,
                            ...action.item,
                        }
                        : item
                    ),
                };

            case _typeSelectItem:
                return {
                    ...state,
                    selectedId: action.id,
                };

            case _typeDeleteItem:
                return {
                    ...state,
                    items: state.items.filter(item => item[idFieldName] !== action.id),
                };

            case _typeDeleteAllItemsSuccess:
                return {
                    ...state,
                    items: [],
                };

            default:
                return state;
        }
    }
}