import { PMSStore } from '../../PMS/PMSStore';
import { combineReducers } from 'redux';

import { BudgetsStore } from "../../Budgets/_reducers/budgetsRootReducer";
import { DevelopersStore } from "../../Developers/DevelopersStore";
import { CommonStore } from "./common.reducer";
import { EverouStore } from "./EverouStore";

EverouStore.setRootStateFn(state => state.everou);
CommonStore.setRootStateFn(state => state.common);
BudgetsStore.setRootStateFn(state => state.budgets);
DevelopersStore.setRootStateFn(state => state.developers);
PMSStore.setRootStateFn(state => state.pms);

export const appReducer = combineReducers({
    budgets: BudgetsStore.reducer,
    everou: EverouStore.reducer,
    common: CommonStore.reducer,
    developers: DevelopersStore.reducer,
    pms: PMSStore.reducer,
});

export const Selectors = {
    ...EverouStore.selectors,
    ...CommonStore.selectors,
    ...PMSStore.selectors,
};