import { PMS_API_URLS } from "../_constants/PMS_API_URLS";
import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { PMSLocationsPaginationQueryParams } from "../_models/Pagination";
import { URL_CONSTANTS } from "../../Everou/_constants";
import { PMSIntegrationCreateServer } from "PMS/_models/PMSIntegration";

export const PMSLocationsService = {
    list,
    getLogs,
    locationInfo,
    reservations,
    createNewLocationTag,
    deleteLocationTag,
    integrations,
    groups,
    createLocationIntegration,
    deleteLocationIntegration,
    updateLocationURLRecommendations,
    updateLocationTravelerDiscount,
    changeProtectStatus,
    changeAutoProtectSetting,
    updateURLVideo,
    createReservation,
    deleteReservation,
    updateReservation,
};

async function list(queryParams: PMSLocationsPaginationQueryParams & {offset?: number}) {
    return ServiceHelper.getRequest(PMS_API_URLS.LOCATIONS, queryParams);
}

async function getLogs(
    locationUid,
    startDate,
    endDate,
    pageSize,
    page
) {
    const params = `\
        ?loc_uid=${locationUid}\
        &begin=${startDate}\
        &end=${endDate}\
        &page_size=${pageSize}\
        &offset=${page}\
    `;
    const paramsNoSpaces = params.replace(/\s/g, '');
    const response = await ServiceHelper.getRequest(URL_CONSTANTS.LOCATION_LOG, paramsNoSpaces);
    
    return {
        logs: response.logs,
        next: response.next,
    };
}

async function locationInfo(uid) {
    const parameters = "?loc_uid=" + uid;
    const response = await ServiceHelper.getRequest(URL_CONSTANTS.LOCATION_INFO, parameters);
    return response;
}

async function reservations(uid) {
    const parameters = "?uid=" + uid;
    return ServiceHelper.getRequest(PMS_API_URLS.RESERVATIONS, parameters);
}

async function createNewLocationTag({tag, locationUid}) {
    return ServiceHelper.postRequest(
        PMS_API_URLS.TAGS,
        {
            loc_uid: locationUid,
            tag: tag,
        },
    );
}

async function deleteLocationTag({tag, locationUid}) {
    return ServiceHelper.deleteRequest(
        PMS_API_URLS.TAGS,
        {
            tag: tag,
            loc_uid: locationUid
        },
    );
}

async function integrations(uid) {
    const parameters = "?loc_uid=" + uid;
    return ServiceHelper.getRequest(PMS_API_URLS.LOCATION_INTEGRATIONS, parameters);
}

async function groups(uid) {
    const parameters = "?loc_uid=" + uid;
    return ServiceHelper.getRequest(PMS_API_URLS.GROUPS, parameters);
}

async function createLocationIntegration(serverIntegration: PMSIntegrationCreateServer) {
    serverIntegration.handler_url = URL_CONSTANTS.URL_API + URL_CONSTANTS.INTEGRATION_HOSTIFY_HANDLER;
    return ServiceHelper.putRequest(PMS_API_URLS.LOCATION_INTEGRATIONS, { ...serverIntegration });
}

async function deleteLocationIntegration(uid: string) {
    return ServiceHelper.deleteRequest(PMS_API_URLS.LOCATION_INTEGRATIONS, { uid });
}

async function updateLocationURLRecommendations({ uid, url_recommendations }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            url_recommendations,
        },
    );
}

async function updateLocationTravelerDiscount({ uid, traveler_discount }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            traveler_discount,
        },
    );
}

async function changeProtectStatus(enabled, locationUid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: locationUid,
            protect: enabled,
        },
    );
}

async function changeAutoProtectSetting(enabled, locationUid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: locationUid,
            protect: enabled,
        },
    );
}

async function updateURLVideo({ uid, external_video_url }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            external_video_url,
        },
    );
}

function createReservation(
    integration_uid: string, 
    group_id: number, 
    emails: string[] = [], 
    begin: string, 
    end: string
) {
    return ServiceHelper.putRequest(
        PMS_API_URLS.RESERVATIONS,
        {
            integration_uid,
            group_id,
            emails,
            begin,
            end
        }
    );
}

function deleteReservation(
    reservation_uid: string
) {
    return ServiceHelper.deleteRequest(
        PMS_API_URLS.RESERVATIONS,
        {
            reservation_uid
        }
    );
}

function updateReservation(
    reservation_uid: string,
    begin: string,
    end: string,
) {
    return ServiceHelper.postRequest(
        PMS_API_URLS.RESERVATIONS,
        {
            reservation_uid,
            begin,
            end
        }
    );
}