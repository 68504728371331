import { BUDGET_STATUS } from "../_constants/budgets.constants";
import { i18n } from "../_translations";

export const BudgetUiHelper = {
    getStatusUiTag,
};

function getStatusUiTag(status) {
    const TAG_FOR_STATUS = {
        [BUDGET_STATUS.DRAFT]: i18n.t("mod_budgets_status_draft"),
        [BUDGET_STATUS.SENT]: i18n.t("mod_budgets_status_sent"),
        [BUDGET_STATUS.CLOSED]: i18n.t("mod_budgets_status_closed"),
        [BUDGET_STATUS.CANCELLED]: i18n.t("mod_budgets_status_cancelled"),
    };

    return TAG_FOR_STATUS[status] || "UNKNOWN STATUS";
}