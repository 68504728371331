import { BudgetUser } from "../_models/BudgetUser";
import { BridgeUser } from "../../Common/_models/BridgeUser";

export class BudgetUserMapper {
    static serverToLocal(bridgeUser = BridgeUser(), serverEmployee) {
        return BudgetUser({
            apikey: bridgeUser.apikey,
            email: bridgeUser.email,
            name: bridgeUser.name,
            
            uid: serverEmployee.uid,
            distributorUid: serverEmployee.distributor_uid,
        });
    }
}