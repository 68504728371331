import { Integration, LocalEditableIntegration } from "../../../Everou/_modelsTS/Integration";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatePublicUrlToggle from "../../../Everou/InvitationsModule/CreatePublicUrlToggle";
import { CapsuleButton, CustomNumberInput } from "../../../Everou/_components";
import { useDispatch } from "react-redux";
import { MIN_TIME, TIME_FORMAT } from "../../../Everou/_constants";
import { useError } from "../../../Everou/_hooks/useError";
import { Icons } from "../../../Everou/_assets";
import TimePicker from "rc-time-picker";
import { TimeHelper } from "../../../Everou/_helpers";
import moment from "moment";
import SendInvitationURLToggle from "../../../Everou/IntegrationsModule/components/SendInvitationURLToggle";
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";

type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onChangeInputs?: (...args: any) => void | any;
    onToggleEditable: (...args: any) => void | any;
    onSetInitialStatus: (...args: any) => void | any;
    onDelete: (index: number) => void;
    index: number;
}

export function PMSKrossBookingIntegrationInput({ 
    integration, 
    onDelete, 
    index, 
    onToggleEditable,
}: IntegrationInputProps) {

    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    // GLOBAL STATE
    const [apikeyValue, setApikeyValue] = useState(integration.apikey ?? '');
    const [hotelIDValue, setHotelIDValue] = useState(integration.hotel_id ?? '');
    const [usernameValue, setUsernameValue] = useState(integration.username ?? '');
    const [createPublicUrl, setCreatePublicUrl] = useState(integration.createPublicUrl);
    const [sendInvitationURL, setSendInvitationURL] = useState(integration.sendInvitationURL);
    const [daysForInvitationCreate, setDaysForInvitationCreate] = useState(integration.daysForInvitationCreate ?? 1);
    const { errorMessage, setError, hasError, disableError } = useError();

    const [checkIn, setCheckIn] = useState(integration.check_in ?? MIN_TIME);
    const [checkOut, setCheckOut] = useState(integration.check_out ?? MIN_TIME);
    const timeNoSecondsFormat = TimeHelper.getTimeFormats().TIME_NO_SECONDS;

    const getHourAndMinutesFormat = useCallback((time: string) => moment(time, timeNoSecondsFormat), []);

    const updateDaysForInvitationCreation = (daysForInvitationCreate : number) => {
        setDaysForInvitationCreate(daysForInvitationCreate);
    };

    // INITIALIZATION
    useEffect(() => { 
        setApikeyValue(integration.apikey ?? '');
        setHotelIDValue(integration.hotel_id ?? '');
        setUsernameValue(integration.username ?? '');
        setCreatePublicUrl(integration.createPublicUrl);
        setSendInvitationURL(integration.sendInvitationURL);
        setCheckIn(integration.check_in ?? '00:00');
        setCheckOut(integration.check_out ?? '00:00');
        setDaysForInvitationCreate(integration.daysForInvitationCreate ?? 1);
    }, [integration]);

    const canSave = useMemo(() => {
        if(hasError) return false;

        return !!apikeyValue && !!hotelIDValue && !!usernameValue && 
        (
            sendInvitationURL !== integration.sendInvitationURL || 
            createPublicUrl !== integration.createPublicUrl ||
            apikeyValue !== integration.apikey ||
            hotelIDValue !== integration.hotel_id ||
            usernameValue !== integration.username ||
            checkIn !== integration.check_in ||
            checkOut !== integration.check_out ||
            daysForInvitationCreate !== integration.daysForInvitationCreate
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sendInvitationURL, 
        createPublicUrl, 
        apikeyValue, 
        hotelIDValue, 
        usernameValue, 
        hasError,
        checkIn,
        checkOut,
        daysForInvitationCreate
    ]);

    const enableSendInvitationURLToggle = useMemo(() => {
        return createPublicUrl;
    }, [createPublicUrl]);
    
    const onSave = useCallback(async () => {
        const editedIntegration: Integration = {
            apikey: apikeyValue,
            hotel_id: hotelIDValue,
            username: usernameValue,
            company: integration.company,
            type: integration.type,
            uid: integration.uid,
            check_in: checkIn,
            check_out: checkOut,
            createPublicUrl,
            sendInvitationURL,
            daysForInvitationCreate
        }

        try {
            await dispatch(PMSIntegrationsActions.createIntegration(editedIntegration));
        } catch (err: any) {
            setError(err.message)
        }
    }, [
        dispatch, 
        t, 
        setError, 
        integration.company, 
        integration.type, 
        integration.uid, 
        apikeyValue, 
        hotelIDValue, 
        usernameValue, 
        createPublicUrl, 
        sendInvitationURL
    ]);

    return (
        <div className="
            h-100
            d-flex
            no-gutters
            flex-column
        ">
            <div>API Key</div>
            <input
                className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                type="text"
                readOnly={!integration.editable}
                value={apikeyValue}
                placeholder={"API Key"}
                onChange={(e) => {
                    setApikeyValue(e.target.value?.trim());
                    /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                        setError(t('mod_integrations_already_have_integration_with_this_link'));
                    } else {
                        disableError();
                    }*/
                }}
            />
            <div className="mt-2">Hotel ID</div>
            <input
                className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                type="text"
                readOnly={!integration.editable}
                value={hotelIDValue}
                placeholder={"Hotel ID"}
                onChange={(e) => {
                    setHotelIDValue(e.target.value?.trim());
                    /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                        setError(t('mod_integrations_already_have_integration_with_this_link'));
                    } else {
                        disableError();
                    }*/
                }}
            />
            <div className="mt-2">User</div>
            <input
                className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                type="text"
                readOnly={!integration.editable}
                value={usernameValue}
                placeholder={"User"}
                onChange={(e) => {
                    setUsernameValue(e.target.value?.trim());
                    /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                        setError(t('mod_integrations_already_have_integration_with_this_link'));
                    } else {
                        disableError();
                    }*/
                }}
            />
            <div className="mt-2">
                <CreatePublicUrlToggle
                    boldedName={false}
                    initialState={createPublicUrl}
                    readOnly={!integration.editable}
                    onChange={setCreatePublicUrl}
                />
                { enableSendInvitationURLToggle &&
                    <div className="mt-2 mb-2 cp d-flex align-items-center justify-content-between">
                        <div>Días de antelación para crear el Link App</div>
                        <CustomNumberInput 
                            value={daysForInvitationCreate}
                            min={1}
                            max={5}
                            onChange={updateDaysForInvitationCreation}
                        />
                    </div>
                }
                { enableSendInvitationURLToggle && 
                    <div className="mt-2">
                        <SendInvitationURLToggle
                            boldedName={false}
                            initialState={sendInvitationURL}
                            readOnly={!integration.editable}
                            onChange={setSendInvitationURL}
                        />
                    </div>
                }
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="c-text-capitalize-first">
                    Check In
                </div>
                <TimePicker
                    disabled={!integration.editable}
                    showSecond={false}
                    minuteStep={5}
                    value={getHourAndMinutesFormat(checkIn)}
                    format={TIME_FORMAT}
                    onChange={(value) =>
                        setCheckIn(
                            value
                                ? value?.format(TIME_FORMAT)
                                : MIN_TIME,
                        )
                    }
                    />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="c-text-capitalize-first">
                    Check Out
                </div>
                <TimePicker
                    disabled={!integration.editable}
                    showSecond={false}
                    minuteStep={5}
                    value={getHourAndMinutesFormat(checkOut)}
                    format={TIME_FORMAT}
                    onChange={(value) =>
                        setCheckOut(
                            value
                                ? value?.format(TIME_FORMAT)
                                : MIN_TIME,
                        )
                    }
                />
            </div>
            <div className="mt-2 d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    {integration.uid &&
                        <CapsuleButton
                            size="SMALL"
                            classNameExtra="mr-2"
                            style={!integration.editable ? 'PRIMARY' : 'INFO'}
                            onClick={() => onToggleEditable(index)}
                            text={t(!integration.editable ?  "global_edit" : 'global_cancel')}
                        />
                    }
                    {integration.uid &&
                        <CapsuleButton
                            onClick={() => onDelete(index)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            style="DANGER"
                            size="SMALL"
                            icon={Icons.trash}
                        />
                    }
                    <CapsuleButton
                        size="SMALL"
                        isEnabled={canSave}
                        onClick={onSave}
                        text={t("global_save")}
                    />
                </div>
            </div>
            {errorMessage && (
                <div className="text-danger mr-2">{errorMessage}</div>
            )}
        </div>
    );
}