import { InvitationsSecondaryViewStore } from "../_stores/InvitationsSecondaryViewStore";

export const INVITATIONS_ACTION_TYPES = {
    CHANGE_SECONDARY_VIEW: InvitationsSecondaryViewStore.ACTION_TYPES.SET,

    INVITATIONS_LIST_REQUEST: "INVITATIONS_LIST_REQUEST",
    INVITATIONS_LIST_SUCCESS: "INVITATIONS_LIST_SUCCESS",
    INVITATIONS_LIST_FAILURE: "INVITATIONS_LIST_FAILURE",

    CREATE_LOCAL_INVITATION: "INVITATIONS_CREATE_LOCAL_INVITATION",
    CREATE_LOCAL_ACCESS_LINK: "INVITATIONS_CREATE_LOCAL_ACCESS_LINK",

    INVITATIONS_CREATE_REQUEST: "INVITATIONS_CREATE_REQUEST",
    INVITATIONS_CREATE_SUCCESS: "INVITATIONS_CREATE_SUCCESS",
    INVITATIONS_CREATE_FAILURE: "INVITATIONS_CREATE_FAILURE",

    INVITATIONS_SELECT: "INVITATIONS_SELECT",
    INVITATIONS_ACCESS_LINK_SELECT: "INVITATIONS_ACCESS_LINK_SELECT",

    INVITATIONS_UPDATE_REQUEST: "INVITATIONS_UPDATE_REQUEST",
    INVITATIONS_UPDATE_SUCCESS: "INVITATIONS_UPDATE_SUCCESS",
    INVITATIONS_UPDATE_FAILURE: "INVITATIONS_UPDATE_FAILURE",

    INVITATIONS_DELETE_SUCCESS: "INVITATIONS_DELETE_SUCCESS",
    INVITATIONS_DELETE_FAILURE: "INVITATIONS_DELETE_FAILURE",

    INVITATIONS_UPDATE_SELECTED_LOCALLY: "INVITATIONS_UPDATE_SELECTED_LOCALLY",
    
    INVITATIONS_VALIDATE: "INVITATIONS_VALIDATE",
    
    INVITATIONS_CHANGE_FILTER: "INVITATIONS_CHANGE_FILTER",

    PUSH_FULL_INVITATION_TO_SELECTED:
        "INVITATIONS_PUSH_FULL_INVITATION_TO_SELECTED",

    INVITATIONS_UPDATE_NEXT_OFFSET: "INVITATIONS_UPDATE_NEXT_OFFSET",
    INVITATIONS_SET_REQUESTING_STATUS: 'INVITATIONS_SET_REQUESTING_STATUS',
    INVITATIONS_CHANGE_OFFSET: 'INVITATIONS_CHANGE_OFFSET',

    INVITATIONS_RESET_PAGINATION: "INVITATIONS_RESET_PAGINATION",

    
    CHANGE_WEEKDAY_SLOTS: "CHANGE_WEEKDAY_SLOTS",
    DELETE_WEEKDAY_TIMESLOT: "DELETE_WEEKDAY_TIMESLOT",
    INVITATIONS_DELETE_ALL_WEEKDAYS_ON_SELECTED: "INVITATIONS_DELETE_ALL_WEEKDAYS_ON_SELECTED",
    
    // ON NEW
    CHANGE_WEEKDAY_SLOTS_ON_NEW: "CHANGE_WEEKDAY_SLOTS_ON_NEW",
    DELETE_WEEKDAY_TIMESLOT_ON_NEW: "DELETE_WEEKDAY_TIMESLOT_ON_NEW",
    INVITATIONS_UPDATE_NEW_DRAFT_LOCALLY: "INVITATIONS_UPDATE_NEW_DRAFT_LOCALLY",
    INVITATIONS_DELETE_ALL_WEEKDAYS_ON_NEW: "INVITATIONS_DELETE_ALL_WEEKDAYS_ON_NEW",
    DELETE_INVITATION_DRAFT: "DELETE_INVITATION_DRAFT"
};