import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { DEVELOPERS_API_URLS } from "../_constants/DEVELOPERS_API_URLS";

export const DevOauthService = {
    get,
    updateAppName,
    updateRedirectUris,
};

async function get() {
    return await ServiceHelper.getRequest(
        DEVELOPERS_API_URLS.OAUTH
    );
}

async function updateAppName(appName) {
    return await ServiceHelper.postRequest(
        DEVELOPERS_API_URLS.OAUTH,
        {
            name: appName,
        }
    );
}

async function updateRedirectUris(redirectUris) {
    return await ServiceHelper.postRequest(
        DEVELOPERS_API_URLS.OAUTH,
        {
            redirect_uris: redirectUris,
        }
    );
}