import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { ListedGroup, MainPageWithButtons, CapsuleButton, SimpleListElement } from "../_components";
import { BudgetsActions } from "../_actions/BudgetsActions";
import { Budget } from "../_models/Budget";
import { BUDGETS_MODULE_VIEWS } from "./BUDGETS_MODULE_VIEWS";
import { BudgetUiHelper } from "./BudgetUiHelper";
import { FILTER_DISABLED_ALL } from "../../Everou/_constants";
import { BudgetStatusDropdown } from "./BudgetStatusDropdown";
import { CustomDropdown } from "../../Common/_components/CustomDropdown";
import { Client } from "../_models/Client";

export function BudgetsListView({
    budgets = [] || [Budget()],
    clients = [] || [Client()],
    selectedBudgetUid = null,
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [statusFilter, setStatusFilter] = useState(FILTER_DISABLED_ALL.key);
    const [clientUidFilter, setClientFilter] = useState(FILTER_DISABLED_ALL.key);

    const filteredBudgets = useMemo(() =>
        budgets
        .filter(budget => statusFilter === FILTER_DISABLED_ALL.key || budget.status === statusFilter)
        .filter(budget => clientUidFilter === FILTER_DISABLED_ALL.key || budget.client.uid === clientUidFilter)
        .sort((a, b) => a.ref.localeCompare(b.ref))
    , [budgets, statusFilter, clientUidFilter]);

    //ACTION HANDLERS
    function handleClickBudget(uid) {
        dispatch(BudgetsActions.selectedBudget(uid));
    }

    function handleClickNewBudget() {
        dispatch(BudgetsActions.changeSecondaryView(BUDGETS_MODULE_VIEWS.NEW_BUDGET));
    }
    
    //VIEW
    return (
        <MainPageWithButtons
            content={content()}
            pageTitle={t("mod_budgets_title")}
            buttons={buttons()}
            size={'SMALL'}
        />
    );

    function content() {
        return budgets.length
            ? budgetsList()
            : noBudgetsMessage()
        ;
    }

    function buttons() {
        return [
            <CapsuleButton
                text={t("mod_budgets_add_budget")}
                onClick={handleClickNewBudget}
            />
        ];
    }

    function noBudgetsMessage() {
        return (
            <div className="mt-2 text-center">
                {t("mod_budgets_no_items_found")}
            </div>
        );
    }

    function budgetsList() {
        return (
            <div className="h-100 d-flex flex-column pt-2">
                {filtersSection()}
                <div className="mt-2"/>
                <div className="flex-fill overflow-auto">
                    {filteredBudgetsSection()}
                </div>
            </div>
        );

        function filteredBudgetsSection() {
            return filteredBudgets.length
                ? <ListedGroup
                    elements={filteredBudgets.map(singleBudget)}
                />
                : noBudgetsMessage()
            ;
        }

        function filtersSection() {
            return (
                <div className="d-flex">
                    <BudgetStatusDropdown
                        status={statusFilter}
                        setStatus={setStatusFilter}
                        showAllOption
                    />
                    <div className="ml-1"/>
                    {clientUidFilterFn()}
                </div>
            );

            function clientUidFilterFn() {
                return (
                    <CustomDropdown
                        title={t("global_client")}
                        value={clientUidFilter}
                        options={buildClientFilterOptions()}
                        onChange={setClientFilter}
                    />
                );

                function buildClientFilterOptions() {
                    return [
                        CustomDropdown.buildOption(
                            FILTER_DISABLED_ALL.key,
                            FILTER_DISABLED_ALL.value,
                        ),
                        ...clients.map(client => 
                            CustomDropdown.buildOption(client.uid, client.name)
                        ),
                    ];
                }
            }
        }
    }

    function singleBudget(budget = Budget()) {
        return (
            <SimpleListElement
                isSelected={budget.uid === selectedBudgetUid}
                onClick={() => handleClickBudget(budget.uid)}
                text={textFn()}
            />
        );

        function textFn() {
            return (
                <div>
                    <div className="d-flex justify-content-between">
                        <div>
                            {budget.ref}
                        </div>
                        <div>
                            {BudgetUiHelper.getStatusUiTag(budget.status)}
                        </div>
                    </div>
                    <div>
                        {`${t("global_client")}: ${budget.client.name}`}
                    </div>
                </div>
            );
        }
    }
}