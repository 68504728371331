import React from "react";
import { useTranslation } from "react-i18next";

export function NoTriggersPage() {
    const { t } = useTranslation();

    return (
        <div className="
            c-bg-grey-240
            border
            rounded
            mt-2
            p-2

            text-center
        ">
            {t("triggers.youDontHaveAutomaticActions")}
        </div>
    );
}