import { DeviceFilter } from '../_models/DeviceFilter';
import { FILTER_ACTION_TYPES, deviceFilterModes } from '../_constants';
import { Selectors } from '../_reducers/app.reducer';

export const filterActions = {
    createFilterCategories,

    changeMode,
    toggleCategory,
    selectAllCategories,
};

function createFilterCategories(devices, rooms) {
    return {
        type: FILTER_ACTION_TYPES.CREATE_FILTER_CATEGORIES,
        devices,
        rooms,
    };
}

function changeMode(newFilterMode) {
    return (dispatch, getState) => {
        dispatch({
            type: FILTER_ACTION_TYPES.CHANGE_FILTER_MODE,
            newFilterMode,
            categories: getCategoriesForFilterMode(),
        });
        dispatch(filterActions.selectAllCategories());

        function getCategoriesForFilterMode() {
            switch (newFilterMode) {
                case deviceFilterModes.CATEGORIES:
                    return Selectors.getDeviceFilterDeviceCategories(getState());
    
                case deviceFilterModes.ROOMS:
                    return (Selectors.getRooms(getState()) as any[]).map(({ uid, name, type, devicesAmount }) => DeviceFilter({
                        id: uid,
                        name,
                        devicesAmount,
                        type
                    })
                );
    
                case deviceFilterModes.DEVICES_TYPES:
                    return Selectors.getDeviceFilterDeviceTypes(getState());
    
                default:
                    return [];
            }
        }
    };
}

function toggleCategory(filterCategoryId, isAllCategoriesSelected, categories) {
    return dispatch => {

        if (isLastSelectedCategory())
            return dispatch(filterActions.selectAllCategories());

        dispatch({
            type: FILTER_ACTION_TYPES.TOGGLE_CATEGORY,
            filterCategoryId,
        });
    };

    function isLastSelectedCategory() {
        return (
            !isAllCategoriesSelected
            && categories.filter(category => category.active).length === 1
            && categories.find(category => category.active).id === filterCategoryId
        );
    }
}

function selectAllCategories() {
    return (dispatch) => {
        dispatch({
            type: FILTER_ACTION_TYPES.SELECT_ALL_CATEGORIES,
        });
    }
}