import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { Icons } from "../../Everou/_assets";
import { UiSidebarButton } from "./UiSidebarButton";

export function SidebarContainer({
    isHoverMenu : isSmallWindow = false,
    children,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const ref = useRef();
    const [forceExpanded, setForceExpanded] = useState(true);
    const [autoExpanded, setAutoExpanded] = useState(false);

    const isHoverMenu = isSmallWindow || !forceExpanded;
    const isExpanded = forceExpanded || autoExpanded;

    //INITIALIZATION
    useEffect(() => {
        setForceExpanded(!isSmallWindow);
        setAutoExpanded(false);
    }, [isSmallWindow]);

    function toggleExpandedMobileFn() {
        if (!isSmallWindow)
            return;

        setAutoExpanded(!isExpanded);
    }

    function toggleExpandedFn() {
        if (isSmallWindow)
            return;

        setForceExpanded(foreceExpanded => !foreceExpanded);
    }

    function handleShowSidebar() {
        if (forceExpanded)
            return;

        setAutoExpanded(true);
    }

    function handleHideSidebar() {
        if (forceExpanded)
            return;

        setAutoExpanded(false);
    }

    //VIEW
    return (
        <div
            ref={ref}
            onMouseEnter={() => !isSmallWindow && handleShowSidebar()}
            onMouseLeave={() => !isSmallWindow && handleHideSidebar()}
        >
            {isHoverMenu &&
                <div className="
                    c-compressed-sidebar-width
                    flex-shrink-0
                "/>
            }

            <div className={`
                h-100
                d-flex
                c-z-1050

                ${isHoverMenu &&
                    "w-100 position-absolute c-no-pointer-events"
                }
            `}>
                <div className={`
                    d-flex
                    flex-column
                    
                    ${isExpanded
                        ? "c-expanded-sidebar-width"
                        : "c-compressed-sidebar-width"
                    }
                    
                    c-bd-right-190

                    c-all-pointer-events
                    c-transitionWidth

                    bg-white
                `}>
                    <div className="flex-fill overflow-auto"
                        onClick={() => isSmallWindow && handleShowSidebar()}
                    >
                        {children}
                    </div>
                    <hr className="m-0" />
                    {isSmallWindow
                        ? toggleExpandMobile()
                        : toggleForceExpandButton()
                    }
                    
                </div>

                {darkOverlay()}
            </div>
        </div>
    );

    function toggleExpandMobile() {
        return <UiSidebarButton {...{
            icon: isExpanded ? Icons.previousIcon : Icons.nextIcon,
            onClick: toggleExpandedMobileFn,
            text: t("global_hide"),
        }}/>;
    }

    function toggleForceExpandButton() {
        return <UiSidebarButton {...{
            text: forceExpanded ? t("global_hide") : t("global_fix"),
            icon: forceExpanded ? Icons.previousIcon : Icons.nextIcon,
            onClick: toggleExpandedFn,
        }}/>;
    }

    function darkOverlay() {
        if (!(isSmallWindow && isExpanded))
            return;

        return (
            <div
                onClick={handleHideSidebar}
                className="
                    flex-fill
                    bg-dark

                    c-z-1050
                    c-opacity-50
                    c-all-pointer-events
                "
            />
        );
    }
}