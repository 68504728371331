import { codeService } from "../_services/code.service";
import { CODE_TYPES } from "../_constants/code.constants";
import { globalActions } from "./global.actions";
import { invitationCardActions } from "./invitationCard.actions";
import { subscriptionsActions } from "./subscriptions.actions";
import { genericActions } from "./generic.actions";
import { GLOBAL_POPUPS } from "../_constants";
import { invitationsActions } from "./invitations.actions";

export const codeActions = {
    //REMOTE
    manageCode,
};

function manageCode(code) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const { type } = await codeService.getType(code);

            switch (type) {
                case CODE_TYPES.SUBSCRIPTION: {
                    await dispatch(subscriptionsActions.addSubscriptionCodeGlobally(code))
                    return;
                }

                case CODE_TYPES.INVITATION_CARD: {
                    await dispatch(invitationCardActions.manageInvitationCardGlobal(code));
                    return;
                }

                case CODE_TYPES.NFC_CARD: {
                    await dispatch(invitationCardActions.manageNfcCardCodeGlobal(code));
                    return;
                }

                case CODE_TYPES.STANDARD_INVITATION: {
                    await manageStandardInvitation();
                    return;
                }

                default:
                    return;
            }
        }

        async function manageStandardInvitation() {
            const success = await dispatch(invitationsActions.validateInvitation(code));

            if (!success)
                return;

            dispatch(globalActions.showPopUp(GLOBAL_POPUPS.ACCEPT_INVITATION));
        }
    };
}