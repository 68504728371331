import React from "react";

export function RangeInputComponent({
    isInt = true,
    isDecimalPercentage = false,
    title,
    value,
    onChange = () => {},
    min = 0,
    max = 100,
    step = 1,
}) {

    //VIEW
    return (
        <div className="
            d-flex
            no-gutters
            justify-content-between
            align-items-center
        ">
            {text()}
            <div className="ml-2"/>
            <div>
                {inputField()}
            </div>
        </div>
    );

    function text() {
        return (
            <div>
                {title}
            </div>
        );
    }

    function inputField() {
        return (
            <input
                onClick={e => e.target.select()}
                onChange={e => onLocalChange(e.target.value)}
                type={"number"}
                min={minFn()}
                max={maxFn()}
                value={noLeadingZeroesValue()}
                step={stepFn()}
                className="
                    rounded
                    border
                    text-right
                "
            />
        );

        function noLeadingZeroesValue() {
            const value = valueFn();
            return Number(value) === 0
                ? String(value).replace(/^0+/,'0')
                : String(value).replace(/^0+/,'')
            ;
        }

        function valueFn() {
            if (isDecimalPercentage) {
                return Math.round(value * 100);
            }

            if (isInt) {
                return Math.round(value);
            }

            return value;
        }

        function minFn() {
            return isDecimalPercentage
                ? min * 100
                : min
            ;
        }

        function maxFn() {
            return isDecimalPercentage
                ? max * 100
                : max
            ;
        }

        function stepFn() {
            return isDecimalPercentage
                ? step * 100
                : step
            ;
        }
    }

    function onLocalChange(input) {
        const outValue = isDecimalPercentage
            ? input / 100
            : input
        ;

        if (outValue > max || outValue < min)
            return;

        onChange(Number(outValue));
    }
}