export function LogModel({
    logId,
    deviceUid,

    userName,
    userEmail,
    
    logType,
    logTypeName,

    status,
    statusLabel,

    datetime,
} = {}) {
    return {
        logId,
        deviceUid,

        userName,
        userEmail,

        logType,
        logTypeName,

        status,
        statusLabel,

        datetime,
    };
}