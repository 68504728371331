import { useCallback } from "react";
import { Icons } from "../../../Everou/_assets";
import { CapsuleButton } from "../../../Everou/_components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

type ReservationPublicUrlProps = {
    publicUrl: string;
};

export function ReservationPublicUrl({
    publicUrl
}: ReservationPublicUrlProps) {

    const dispatch = useDispatch();
    const { t } = useTranslation();


    const handleCopyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(publicUrl);
    }, [publicUrl]);


    //VIEW
    return (
        <>
            <h3 className="mt-1 mb-2">{t("invitation_public_url")}</h3>

            <div className="d-flex align-items-center justify-content-between position-relative">
                <small style={{ maxWidth: '300px' }} className={"mr-2 w-75 c-line-height-1"}>
                    {publicUrl ? (
                        <a
                            className="d-block w-100 text-secondary"
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            }}
                            rel="noreferrer"
                            href={publicUrl}
                            target="_blank"
                        >
                            {publicUrl}
                        </a>
                    ) : (
                        t("invitation_link_generate_info")
                    )}
                </small>
                <div className="d-flex align-items-center">{buttons()}</div>
            </div>
        </>
    );




    function buttons() {
        return (
            <>
                <CapsuleButton
                    size="SMALL"
                    onClick={handleCopyToClipboard}
                    icon={Icons.copy}
                    tooltip={t('global_copy')}
                    boostedToolTip
                />
                <div className="ml-2"></div>
            </>
        );
    }
}
