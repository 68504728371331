import React from "react";

export function WaitSpinner() {
    return (
        <div className="
            w-100
            pt-2
            text-center
            h-100 
            d-flex
            align-items-center
            justify-content-center
        ">
            <div className="spinner-border c-text-primary"/>
        </div>
    );
}