import {
    SUBSCRIPTIONS_ACTION_TYPES,
    SUBSCRIPTION_STATUS,
    ACTIVATE_SUBSCRIPTION_RESULTS,
    SUBSCRIPTION_TYPES,
} from "../_constants/subscriptions.constants";
import { genericActions } from "./generic.actions";
import { subscriptionsService } from "../_services/subscriptions.service";
import { SubscriptionMapper } from "../_mappers/SubscriptionMapper";
import { i18n } from "../_translations/i18n";
import { globalActions } from "./global.actions";
import { GLOBAL_POPUPS } from "../_constants";
import { Selectors } from "../_reducers/app.reducer";
import { Subscription } from "../_models/Subscription";
import { appPermissions } from "../_constants/permissions.constants";
import { LocationModel } from "../_models/LocationModel";
import { SubscriptionsSecondaryViewStore } from "../_stores/SubscriptionsSecondaryViewStore";
import { locationInfoActions } from "./locationInfo.actions";
import { EverouModulesActions } from "../_stores/ModulesStore/EverouModulesActions";

export const subscriptionsActions = {
    //LOCAL
    changeSecondaryView: SubscriptionsSecondaryViewStore.actionSet,
    changeDevice,
    changeLocation,
    selectSubscription,
    discardChanges,
    updateSubscription,

    //REMOTE
    saveChangesToServer,
    getSubscriptions,
    addSubscriptionCodeGlobally,

    validateCode,
    activate,
    enableAutomatedPayment,
    disableAutomatedPayment,
    create
};

function addSubscriptionCodeGlobally(code) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!canAddSubscriptionsToSomeLocationFn()) {
                dispatch(showNoOwnerLocationPopUp());
                return;
            }
        
            await dispatch(subscriptionsActions.validateCode(code, true));
            dispatch(globalActions.showPopUp(GLOBAL_POPUPS.ADD_SUBSCRIPTION));
            return;
        
            function showNoOwnerLocationPopUp() {
                return globalActions.showPopUp(
                    GLOBAL_POPUPS.GENERIC_NOTIFICATION,
                    { body: i18n.t("mod_cards_no_location_owned_message") },
                );
            }

            function canAddSubscriptionsToSomeLocationFn() {
                let locations = [LocationModel()];
                locations = Selectors.getLocations(getState());
                return locations.some(location =>
                    appPermissions.canUserActivateSubscriptions(location.userType)
                );
            }
        }
    }
}

function enableAutomatedPayment(subscriptionCode) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverSubscription = await subscriptionsService.enableAutomatedPayment(
                subscriptionCode,
            );

            dispatch(updateSubscription(
                SubscriptionMapper.serverToLocal(serverSubscription)
            ));
        }
    }
}

function disableAutomatedPayment(subscriptionCode) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverSubscription = await subscriptionsService.disableAutomatedPayment(
                subscriptionCode,
            );

            dispatch(updateSubscription(
                SubscriptionMapper.serverToLocal(serverSubscription)
            ));
        }
    }
}


function getSubscriptions(locationUid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const permission = Selectors.getPermissionSelectedLocation(getState());
            if (!appPermissions.canUserSeeSubscriptions(permission))
                return;

            const serverSubscriptions = await subscriptionsService.getSubscriptions(
                locationUid || Selectors.getSelectedLocationUid(getState()),
            );

            const localSubscriptions =
                SubscriptionMapper.allServerToLocal(serverSubscriptions)
            ;

            dispatch(getSubscriptionsSuccess(localSubscriptions));

            ////
            function getSubscriptionsSuccess(subscriptions) {
                return {
                    type: SUBSCRIPTIONS_ACTION_TYPES.GET_SUBSCRIPTIONS,
                    subscriptions,
                };
            }
        }
    };
}

function selectSubscription(uid) {
    return dispatch => {
        dispatch({
            type: SUBSCRIPTIONS_ACTION_TYPES.SELECT_SUBSCRIPTION,
            uid,
        });
    }
}

function changeDevice(deviceUid) {
    return {
        type: SUBSCRIPTIONS_ACTION_TYPES.UPDATE_SELECTED,
        updatedFields: {
            deviceUid,
        },
    };
}

function changeLocation(locationUid) {
    return {
        type: SUBSCRIPTIONS_ACTION_TYPES.UPDATE_SELECTED,
        updatedFields: {
            locationUid,
        },
    };
}

function saveChangesToServer() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            let selected = Subscription();
            selected = Selectors.getSelectedSubscription(getState());
            await subscriptionsService.updateSubscription(
                selected.code,
                selected.deviceUid,
            );

            dispatch({
                type: SUBSCRIPTIONS_ACTION_TYPES.SAVE_CHANGES_TO_SERVER_SUCCESS,
            });
        }
    };
}

function discardChanges() {
    return (dispatch, getState) => {
        dispatch(selectSubscription(
            Selectors.getSelectedSubscription(getState()).uid,
        ));
    };
}


function validateCode(code, rethrow = false) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            const serverSubscription = await subscriptionsService.validate(code);
            const localSubscription = SubscriptionMapper.serverToLocal(serverSubscription);
            validateStatus(localSubscription.status);
            localSubscription.validated = true;
            
            dispatch(success(localSubscription));
            return true;
        }
    };

    function validateStatus(status) {
        if (status === SUBSCRIPTION_STATUS.ACTIVE)
            throw new Error(i18n.t("subscriptions.subscriptionInUseErr")); 
    }

    function success(validated) {
        return {
            type: SUBSCRIPTIONS_ACTION_TYPES.VALIDATE_SUCCESS,
            validated,
        };
    }
}

function activate() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            let subscription = Subscription();
            subscription = Selectors.getSelectedSubscription(getState());
            validateSubscriptionFields(subscription);

            const response = await subscriptionsService.activate({
                code: subscription.code,
                deviceUid: subscription.deviceUid,
                locationUid: subscription.locationUid,
            });

            const localSubscription = SubscriptionMapper.serverToLocal(response.subscription);
            dispatch(activationSuccessPopUp(message(response.result)));
            await dispatch(subscriptionsActions.getSubscriptions());
            await dispatch(locationInfoActions.getLocationInfo(
                Selectors.getSelectedLocationUid(getState())
            ));
            dispatch(subscriptionsActions.selectSubscription(localSubscription.uid));
            dispatch(EverouModulesActions.buildModules());
        }

        function validateSubscriptionFields(subscription = Subscription()) {
            switch (subscription.type) {
                case SUBSCRIPTION_TYPES.UNLIMITED_AND_RESERVATIONS:
                    return validateUnlimited();

                default:
                    return;
            }

            function validateUnlimited() {
                if (!subscription.deviceUid)
                    throw (i18n.t("subscriptions.youMustSelectADevice"));
            }
        }
    };

    function message(result) {
        switch (result) {
            case ACTIVATE_SUBSCRIPTION_RESULTS.EXTENDED_EXISTING:
                return i18n.t("subscription_activation_time_extended");

            case ACTIVATE_SUBSCRIPTION_RESULTS.CREATED_NEW:
                return i18n.t("subscription_activation_activated_successfully");

            default:
                return "UNKNOWN RESULT";
        }
    }

    function activationSuccessPopUp(message) {
        return (
            globalActions.showPopUp(
                GLOBAL_POPUPS.GENERIC_NOTIFICATION,
                { body: message },
            )
        );
    }
}

function create({ type, locationUid }) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            const response = await subscriptionsService.create({
                type,
                locationUid,
            });

            const localSubscription = SubscriptionMapper.serverToLocal(
                response,
            );

            await dispatch(subscriptionsActions.getSubscriptions());
            await dispatch(
                locationInfoActions.getLocationInfo(
                    Selectors.getSelectedLocationUid(getState()),
                ),
            );

            dispatch(
                subscriptionsActions.selectSubscription(localSubscription.uid),
            );
            dispatch(EverouModulesActions.buildModules());
        }
    };
}

function updateSubscription(subscription) {
    return {
        type: SUBSCRIPTIONS_ACTION_TYPES.UPDATE_SUBSCRIPTION_SUCCESS,
        subscription,
    };
}