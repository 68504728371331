import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { CapsuleButton, ContentSpace, ListedGroup, MainPageWithButtons } from "../../Common/_components";
import { DevelopersStore } from "../DevelopersStore";
import { DevOauth } from "../_models/DevOauth";
import { Icons } from "../../Common/_assets/icons/Icons";
import { DevOauthActions } from "../_actions/DevOauthActions";
import { GenericTextArea } from "../../Common/_components/GenericTextArea";
import { TextInputComponent } from "../../Common/_components";

const devOauth = DevOauth();

export function DevOauthModule() {
    
    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    let oauthData = devOauth;
    oauthData = useSelector(DevelopersStore.selectors.getOauth);

    //LOCAL STATE
    const [redirectUris, setRedirectUris] = useState("");
    const [isUrisEdited, setUrisIsEdited] = useState(false);

    const [name, setName] = useState("");
    const [isNameEdited, setNameIsEdited] = useState(false);

    //ACTION HANDLERS
    //function handleCopyToClipboard(text) {
    //    navigator.clipboard.writeText(text);
    //}

    //INTIALIZATION
    useEffect(() => {
        dispatch(DevOauthActions.getOauthData());
    }, [dispatch]);

    useEffect(() => {
        if (oauthData != null) {
            setRedirectUris(oauthData.redirect_uris);
            setUrisIsEdited(false);
            setName(oauthData.name);
            setNameIsEdited(false);
        }
    }, [oauthData]);

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={"OAUTH2 Data"}
                    content={content()}
                />
            }
        />
    );

    function content() {
        return (
            <div>
                <div className="mt-2"/>
                <ListedGroup
                    headerText={"App Data"}
                    elements={ui()}
                />
            </div>
        );

        function ui() {
            if (oauthData == null) {
                return [
                    <div className="text-center my-2">
                        {t("mod_dev_tokens_none_found")}
                    </div>
                ];
            }
            
            return [
                <div className="h-100 d-flex no-gutters flex-column">
                    <AppNameInput />
                    <RedirectUrisInput />
                    <ClientIdToken
                        {...{
                            clientId: oauthData.client_id,
                        }}
                    />
                    <ClientSecretToken
                        {...{
                            clientSecret: oauthData.client_secret,
                        }}
                    />

                </div>
            ];
        }

        function AppNameInput() {

            //LIBRARIES
            //const { t } = useTranslation();
            const dispatch = useDispatch();
        
            //ACTION HADLERS
            function handleUpdateName(name) {
                setNameIsEdited(true);
                setName(name);
            }

            function handleResetName() {
                setNameIsEdited(false);
                setName(oauthData.name);
            }

            function handleSaveName() {
                dispatch(DevOauthActions.updateAppName(name));
            }
        
            return (
                <DataWrapper hasSeparator={false}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            App Name
                        </div>
                        <TextInputComponent
                            placeholder={undefined}
                            value={name}
                            onChange={handleUpdateName}
                            type={'text'}
                            isEditable={true}
                        />
                        {isNameEdited &&
                            <div className="flex-shrink-0 d-flex justify-content-end align-items-start">
                                <div className="ml-2"/>
                                <CapsuleButton
                                    icon={Icons.cross}
                                    size={'SMALL'}
                                    onClick={handleResetName}
                                    textUppercase={false}
                                    style={`DANGER`}
                                />
                                <div className="ml-2"/>
                                <CapsuleButton
                                    icon={Icons.check}
                                    size={'SMALL'}
                                    onClick={handleSaveName}
                                    textUppercase={false}
                                />
                            </div>
                        }
                    </div>
                </DataWrapper>
            );
        }

        function RedirectUrisInput() {
            
            //LIBRARIES
            //const { t } = useTranslation();
        
            //ACTION HADLERS
            function handleUpdateRedirectUris(redirectUris) {
                setUrisIsEdited(true);
                setRedirectUris(redirectUris);
            }

            function handleResetRedirectUris() {
                setUrisIsEdited(false);
                setRedirectUris(oauthData.redirect_uris);
            }

            function handleSaveRedirectUris() {
                dispatch(DevOauthActions.updateRedirectUris(redirectUris));
            }
        
            return (
                <DataWrapper hasSeparator={false}>
                    <div
                        className="d-flex align-items-center justify-content-between"
                    >
                        <div>
                            Redirect URLs
                        </div>
                        <GenericTextArea {...{
                            text: redirectUris,
                            handleEditText: handleUpdateRedirectUris,
                            placeholder: undefined
                        }}/>
                        {isUrisEdited &&
                            <div className="flex-shrink-0 d-flex justify-content-end align-items-start">
                                <div className="ml-2"/>
                                <CapsuleButton
                                    icon={Icons.cross}
                                    size={'SMALL'}
                                    onClick={handleResetRedirectUris}
                                    textUppercase={false}
                                    style={`DANGER`}
                                />
                                <div className="ml-2"/>
                                <CapsuleButton
                                    icon={Icons.check}
                                    size={'SMALL'}
                                    onClick={handleSaveRedirectUris}
                                    textUppercase={false}
                                />
                            </div>
                        }
                    </div>
                </DataWrapper>
            );
        }

        function DataWrapper({
            children,
            hasSeparator = true,
            key,
        }) {
            return (
                <div key={key}>
                    <div className="p-1">
                        {children}
                    </div>
                    {hasSeparator && <hr className="m-0"/>}
                </div>
            );
        }
        
        function ClientIdToken({ clientId }) {
            return (
                <div className="d-flex p-1 justify-content-between align-items-center">
                    <div className="d-flex align-align-items-end">
                        <div>Client ID</div>
                        <div className="ml-2" />
                        <div className="bg-light border rounded px-1 text-break">
                            {clientId}
                        </div>
                        <div className="ml-2" />
                    </div>
                </div>
            )
        }

        function ClientSecretToken({ clientSecret }) {
            return (
                <div className="d-flex p-1 justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div>Client Secret</div>
                        <div className="ml-2" />
                        <div className="bg-light border rounded px-1 text-break">
                            {clientSecret}
                        </div>
                        <div className="ml-2" />
                    </div>
                </div>
            )
        }
    }
}