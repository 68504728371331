import { DEVICE_TYPES } from "./devices.constants";
import { i18n } from "../_translations/i18n";

export const APP_LOG_TYPES = {
    DEVICE:     "DEVICE_LOGS",
    PROTECT:    "PROTECT_LOGS",
    AUDIT:      "AUDIT_LOGS",
    USER:       "USER_LOGS",
};

export const AUDIT_LOG_CODES = {
    OK: "OK",
    ERROR: "ERROR",
};

export const AUDIT_LOG_TYPES = {
    UNDEFINED:  "UNDEFINED",
    BRAIN:      "BRAIN",
    CAMERA:     "CAMERA",
    DEVICE:     "DEVICE",
    INVITATION: "INVITATION",
    LOCATION:   "LOCATION",
    MOBILE:     "MOBILE",
    ROOM:       "ROOM",
    TRIGGER:    "TRIGGER",
    USER:       "USER",
    WIDGET:     "WIDGET",
};

export const filterDisabled = { all: i18n.t("common.all"), number: -1 };
export const nullValues = { user: i18n.t("common.none") };

export const NO_NEXT_PAGE = -1;

// unused
export const SERVER_LOG_TYPES = {
//     NO_TYPE                                 : 0,
//     WRITE_OPEN_CLOSE_LOCK                   : 1,
//     READ_OPEN_CLOSE_LOCK                    : 2,
//     TEMPERATURE_HUMIDITY                    : 3,
//     ACCELEROMETER                           : 4,
//     VIBRATION                               : 5,
//     BATTERY                                 : 6,
//     LOCATING                                : 7,
//     PAIRING_FREQ                            : 8,
//     TX_POWER                                : 9,
//     WRITE_MULTIPLE_OPEN_CLOSE               : 10,
//     READ_MULTIPLE_OPEN_CLOSE                : 11,
//     TIMER                                   : 12,
//     CONSUMPTION                             : 13,
//     MOISTURE                                : 14,
//     FLOOD                                   : 15,
//     WRITE_MULTIPLE_OPEN_CLOSE_ONE           : 18,
//     PERIODIC_READ_OPEN_CLOSE                : 22,
//     PERIODIC_READ_FLOOD                     : 23,
//     READ_MULTIPLE_OPEN_CLOSE_ONE            : 24,
//     ACCELEROMETER_SENSIBILITY               : 25,
//     RELAY_ACTION_DELAYED                    : 26,
//     DELETE_ALL_USERS                        : 29,
//     READ_OPEN_CLOSE_LOCK_AUTOMATIC          : 30,
//     COMMUNICATION_BLE                       : 31,
//     RESET_FACTORY                           : 32,
//     BUTTON_PRESSED                          : 33,
//     THERMOSTAT_STATE_WRITE                  : 35,
//     MOVEMENT                                : 36,
//     THERMOSTAT_STATE_READ                   : 38,
//     READ_OPEN_CLOSE_GOWAY_LOCK              : 39,
//     LOCATION_PROTECT_MANUAL                 : 200,
//     LOCATION_PROTECT_LOCALIZATION           : 201,
//     LOCATION_PROTECT_PERIOD                 : 202,
//     LOCATION_PROTECT_TRIGGER                : 203,
//     BUTTON_PRESSED_TAMPER                   : 210,
//     WRITE_MULTIPLE_OPEN_CLOSE_STOP          : 212,
//     READ_MULTIPLE_OPEN_CLOSE_STOP           : 213,
//     WRITE_MULTIPLE_OPEN_CLOSE_GARAGE_DOOR   : 214,
//     READ_MULTIPLE_OPEN_CLOSE_GARAGE_DOOR    : 215,
//     TEST                                    : 225,
//     ERROR                                   : 240,
    BRAIN_VIDEO                             : 256,
//     BRAIN_INTERNET_STATUS                   : 257,
//     BRAIN_PAIRING_MODE                      : 258,
//     BRAIN_BUTTON_PRESSED                    : 259,
//     BRAIN_FACTORY_RESET                     : 260,
//     BRAIN_BATTERY                           : 261,
//     BRAIN_MOVEMENT_DETECTED                 : 262,
//     BRAIN_VISION_VOICE_CALLING              : 263,
//     BRAIN_POWER                             : 264,
//     BRAIN_INHIBITION                        : 265,
//     BRAIN_INTERNET_STATUS_HIDDEN            : 266,
//     BRAIN_KERNEL_PANIC_DETECTED             : 267,
//     BRAIN_REBOOT_DETECTED                   : 268,
//     BRAIN_NETWORK_CHANGED                   : 269,
//     BRAIN_INTERNET_STATUS_CANCEL            : 270,
//     BRAIN_UPLOAD_LOGS                       : 271,
//     BRAIN_BATTERY_FAULT                     : 272,
};

export function uiLogTypesForDeviceType(deviceType) {
    switch (deviceType) {
        case DEVICE_TYPES.Switcher:
        case DEVICE_TYPES.Plug30:
        case DEVICE_TYPES.Plug30Security:
        case DEVICE_TYPES.Plug:
        case DEVICE_TYPES.PlugSecurity:
        case DEVICE_TYPES.DoubleSwitcher:
        case DEVICE_TYPES.Lighting:
        case DEVICE_TYPES.DoublePlug:
        case DEVICE_TYPES.MultiSwitcher:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.ON_OFF,
                UI_LOG_TYPES.DELAY_ON_OFF,
            ];
        case DEVICE_TYPES.Movement:
        case DEVICE_TYPES.MovementSafeCooking:
        case DEVICE_TYPES.MovementSafeAccess:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BUTTON_PRESSED_TAMPER,

                UI_LOG_TYPES.MOVEMENT,
            ];
        case DEVICE_TYPES.Roller:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,

                UI_LOG_TYPES.OPEN_CLOSE_STOP,
                UI_LOG_TYPES.DELAY_OPEN_CLOSE_STOP,
            ];
        case DEVICE_TYPES.PlugFlood:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,

                UI_LOG_TYPES.ON_OFF_PLUG_FLOOD,
                UI_LOG_TYPES.DELAY_ON_OFF_PLUG_FLOOD,
            ];
        case DEVICE_TYPES.GarageDoor:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,

                UI_LOG_TYPES.ON_OFF,
                UI_LOG_TYPES.ENTRY_EXIT,
                UI_LOG_TYPES.DELAY_ON_OFF,
                UI_LOG_TYPES.BEACON_DETECTED,
            ];
        case DEVICE_TYPES.Brain:
            return [
                UI_LOG_TYPES.INTERNET_STATUS,

                UI_LOG_TYPES.MOVEMENT,
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.VIDEO,
                UI_LOG_TYPES.BRAIN_POWER,
                UI_LOG_TYPES.INHIBITION,
                UI_LOG_TYPES.REBOOT_DETECTED,
                UI_LOG_TYPES.BATTERY_FAULT,
            ];
        case DEVICE_TYPES.Flood:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BUTTON_PRESSED_TAMPER,

                UI_LOG_TYPES.FLOOD,
            ];
        case DEVICE_TYPES.Security:
            return [
                UI_LOG_TYPES.VIBRATIONS,
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BUTTON_PRESSED_TAMPER,

                UI_LOG_TYPES.OPEN_CLOSE,
            ];
        case DEVICE_TYPES.GowayLock:
            return [
                UI_LOG_TYPES.VIBRATIONS,
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BUTTON_PRESSED_TAMPER,

                UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK,

                UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK_EMERGENCY,
            ];
        
        case DEVICE_TYPES.EasyParking:
        case DEVICE_TYPES.EasyParkingBarreras:
        case DEVICE_TYPES.EasyAccessContact:
        case DEVICE_TYPES.Elevator:
        case DEVICE_TYPES.EasyParkingBarrerasNFC:
        case DEVICE_TYPES.EasyAccessContactNFC:
        case DEVICE_TYPES.EasyAccessContactNFCWifi:
            return [
                UI_LOG_TYPES.ON_OFF,
                UI_LOG_TYPES.ENTRY_EXIT,
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BEACON_DETECTED,
                UI_LOG_TYPES.UI_TYPE_VERSION_UPDATED,
                UI_LOG_TYPES.REBOOT_DETECTED,
            ];

        case DEVICE_TYPES.EasyBaliza:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BEACON_DETECTED,
            ];
        case DEVICE_TYPES.Clockin:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BEACON_DETECTED,
                UI_LOG_TYPES.CLOCKIN
            ];
        case DEVICE_TYPES.ClockinNFCWifi:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BEACON_DETECTED,
                UI_LOG_TYPES.CLOCKIN,
                UI_LOG_TYPES.UI_TYPE_VERSION_UPDATED,
                UI_LOG_TYPES.REBOOT_DETECTED,
            ];

        case DEVICE_TYPES.LIGHT_EFR32:
            return [
                UI_LOG_TYPES.ON_OFF,
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.BEACON_DETECTED,
            ];
        case DEVICE_TYPES.EnergySavingAC:
            return [
                UI_LOG_TYPES.DELETE_ALL_USERS,
                UI_LOG_TYPES.ON_OFF,
                UI_LOG_TYPES.DELAY_ON_OFF,
            ];
        default:
            return [];
    }
}

export const UI_LOG_TYPES = {
    OPEN_CLOSE:                         1,
    ON_OFF:                             2,
    OPEN_CLOSE_STOP:                    3,
    ENTRY_EXIT:                         4,
    ON_OFF_PLUG_FLOOD:                  5,
    MOVEMENT:                           6,
    OPEN_CLOSE_GOWAY_LOCK:              7,
    VIBRATIONS:                         8,
    FLOOD:                              9,
    DELETE_ALL_USERS:                   10,
    BUTTON_PRESSED_TAMPER:              11,
    VIDEO:                              12,
    INTERNET_STATUS:                    13,
    BRAIN_POWER:                        14,
    INHIBITION:                         15,
    REBOOT_DETECTED:                    16,
    BATTERY_FAULT:                      17,
    DELAY_ON_OFF:                       18,
    DELAY_OPEN_CLOSE_STOP:              19,
    DELAY_ON_OFF_PLUG_FLOOD:            20,
    BEACON_DETECTED:                    21,
    OPEN_CLOSE_GOWAY_LOCK_EMERGENCY:    22,

    LOCATION_PROTECT_MANUAL:            23,
    LOCATION_PROTECT_LOCALIZATION:      24,
    LOCATION_PROTECT_PERIOD:            25,
    LOCATION_PROTECT_TRIGGER:           26,

    UI_TYPE_CLOCKIN:                    27,
    UI_TYPE_VERSION_UPDATED:            28,

    ERROR:                              240,
};

export const LOG_ERROR_CODES = {
    ERROR_BATTERY_LOW:      17,
};

export const UI_LOG_TYPES_WITHOUT_STATUS = [
    UI_LOG_TYPES.VIBRATIONS,
    UI_LOG_TYPES.DELETE_ALL_USERS,
    UI_LOG_TYPES.BUTTON_PRESSED_TAMPER,
    UI_LOG_TYPES.VIDEO,
    UI_LOG_TYPES.BRAIN_POWER,
    UI_LOG_TYPES.INHIBITION,
    UI_LOG_TYPES.REBOOT_DETECTED,
    UI_LOG_TYPES.BATTERY_FAULT,

    UI_LOG_TYPES.DELAY_ON_OFF,
    UI_LOG_TYPES.DELAY_OPEN_CLOSE_STOP,
    UI_LOG_TYPES.DELAY_ON_OFF_PLUG_FLOOD,
    UI_LOG_TYPES.ENTRY_EXIT,
    UI_LOG_TYPES.UI_TYPE_CLOCKIN,
    UI_LOG_TYPES.UI_TYPE_VERSION_UPDATED,
];

export const UI_LOG_TYPES_WITH_STATUS = [
    UI_LOG_TYPES.OPEN_CLOSE,
    UI_LOG_TYPES.ON_OFF,
    UI_LOG_TYPES.OPEN_CLOSE_STOP,
    UI_LOG_TYPES.ON_OFF_PLUG_FLOOD,
    UI_LOG_TYPES.MOVEMENT,
    UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK,
    UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK_EMERGENCY,
    UI_LOG_TYPES.FLOOD,
    UI_LOG_TYPES.INTERNET_STATUS,
];

export const UI_LOG_TYPES_NAMES = {
    [UI_LOG_TYPES.OPEN_CLOSE]:                      i18n.t("logTypes.openClose"),
    [UI_LOG_TYPES.ON_OFF]:                          i18n.t("logTypes.onOff"),
    [UI_LOG_TYPES.OPEN_CLOSE_STOP]:                 i18n.t("logTypes.openCloseStop"),
    [UI_LOG_TYPES.ENTRY_EXIT]:                      i18n.t("logTypes.entryExit"),
    [UI_LOG_TYPES.ON_OFF_PLUG_FLOOD]:               i18n.t("logTypes.openCloseFlood"),
    [UI_LOG_TYPES.MOVEMENT]:                        i18n.t("logTypes.movement"),
    [UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK]:           i18n.t("logTypes.gowayLock"),
    [UI_LOG_TYPES.VIBRATIONS]:                      i18n.t("logTypes.vibrations"),
    [UI_LOG_TYPES.FLOOD]:                           i18n.t("logTypes.flood"),
    [UI_LOG_TYPES.DELETE_ALL_USERS]:                i18n.t("logTypes.resetAccess"),
    [UI_LOG_TYPES.BUTTON_PRESSED_TAMPER]:           i18n.t("logTypes.tamper"),
    [UI_LOG_TYPES.VIDEO]:                           i18n.t("logTypes.video"),
    [UI_LOG_TYPES.INTERNET_STATUS]:                 i18n.t("logTypes.internetConnection"),
    [UI_LOG_TYPES.BRAIN_POWER]:                     i18n.t("logTypes.batteryStatus"),
    [UI_LOG_TYPES.INHIBITION]:                      i18n.t("logTypes.inhibition"),
    [UI_LOG_TYPES.REBOOT_DETECTED]:                 i18n.t("logTypes.reboot"),
    [UI_LOG_TYPES.BATTERY_FAULT]:                   i18n.t("logTypes.battery"),
    [UI_LOG_TYPES.DELAY_ON_OFF]:                    i18n.t("logTypes.delayOnOff"),
    [UI_LOG_TYPES.DELAY_OPEN_CLOSE_STOP]:           i18n.t("logTypes.delayOpenCloseStop"),
    [UI_LOG_TYPES.DELAY_ON_OFF_PLUG_FLOOD]:         i18n.t("logTypes.delayOnOffFlood"),
    [UI_LOG_TYPES.BEACON_DETECTED]:                 i18n.t("logTypes.beaconDetected"),
    [UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK_EMERGENCY]: i18n.t("logTypes.gowayLockEmergency"),

    [UI_LOG_TYPES.LOCATION_PROTECT_MANUAL]:         i18n.t("logTypes.protectManual"),
    [UI_LOG_TYPES.LOCATION_PROTECT_LOCALIZATION]:   i18n.t("logTypes.protectProximity"),
    [UI_LOG_TYPES.LOCATION_PROTECT_PERIOD]:         i18n.t("logTypes.protectPeriod"),
    [UI_LOG_TYPES.LOCATION_PROTECT_TRIGGER]:        i18n.t("logTypes.protectTrigger"),

    [UI_LOG_TYPES.UI_TYPE_CLOCKIN]:                 i18n.t("logTypes.clockin"),
    [UI_LOG_TYPES.UI_TYPE_VERSION_UPDATED]:         i18n.t("logTypes.versionUpdated"),
};

export const PROTECT_UI_LOG_TYPES = [
    UI_LOG_TYPES.LOCATION_PROTECT_LOCALIZATION,
    UI_LOG_TYPES.LOCATION_PROTECT_MANUAL,
    UI_LOG_TYPES.LOCATION_PROTECT_PERIOD,
    UI_LOG_TYPES.LOCATION_PROTECT_TRIGGER,
];

export const LOCAL_STATUS = {
    CLOSED:                             "CLOSED",
    OPEN:                               "OPEN",

    LIGHT_OFF:                          "LIGHT_OFF",
    LIGHT_ON:                           "LIGHT_ON",

    OFF:                                "OFF",
    ON:                                 "ON",

    ROLLER_STOPPED:                     "ROLLER_STOPPED",
    ROLLER_OPENED:                      "ROLLER_OPENED",
    ROLLER_CLOSED:                      "ROLLER_CLOSED",

    ENTRY:                              "ENTRY",
    EXIT:                               "EXIT",

    FLOOD_OPEN:                         "FLOOD_OPEN",
    FLOOD_CLOSED:                       "FLOOD_CLOSED",

    MOVEMENT_DETECTED:                  "MOVEMENT_DETECTED",

    CLOSED_LOCKED:                      "CLOSED_LOCKED",
    CLOSED_UNLOCKED:                    "CLOSED_UNLOCKED",
    OPENED_LOCKED:                      "OPENED_LOCKED",
    OPENED_UNLOCKED:                    "OPENED_UNLOCKED",
    LATCH_REMOVED:                      "LATCH_REMOVED",

    EMERGENCY_OPENING:                  "EMERGENCY_OPENING",
    VIBRATIONS:                         "VIBRATIONS",
    RESETED_PERMISSIONS:                "RESETED_PERMISSIONS",
    VIDEO_RECORDED:                     "VIDEO_RECORDED",
    REBOOT:                             "REBOOT",
    BATTERY_FAULT:                      "BATTERY_FAULT",

    IDLE:                               "IDLE",
    FLOOD:                              "FLOOD",

    COVER_CLOSED:                       "COVER_CLOSED",
    COVER_OPENED:                       "COVER_OPENED",

    DISCONNECTED:                       "DISCONNECTED",
    CONNECTED:                          "CONNECTED",

    SECONDARY_BATTERY:                  "SECONDARY_BATTERY",
    AC_POWER:                           "AC_POWER",

    INHIBITION_STOPPED:                 "INHIBITION_STOPPED",
    POSSIBLE_INHIBITION:                "POSSIBLE_INHIBITION",

    REQUEST_DELAYED_OFF:                "REQUEST_DELAYED_OFF",
    REQUEST_DELAYED_ON:                 "REQUEST_DELAYED_ON",
    REQUEST_DELAYED_STOP:               "REQUEST_DELAYED_STOP",
    REQUEST_DELAYED_OPEN:               "REQUEST_DELAYED_OPEN",
    REQUEST_DELAYED_CLOSE:              "REQUEST_DELAYED_CLOSE",
    REQUEST_DELAYED_FLOOD_ON:           "REQUEST_DELAYED_FLOOD_ON",
    REQUEST_DELAYED_FLOOD_OFF:          "REQUEST_DELAYED_FLOOD_OFF",

    BEACON_EXECUTE:                     "BEACON_EXECUTE",
    BEACON_ASK:                         "BEACON_ASK",
    BEACON_IGNORE:                      "BEACON_IGNORE",
    BEACON_BLE_DISABLED:                "BEACON_BLE_DISABLED",
    BEACON_RETRY:                       "BEACON_RETRY",
    BEACON_ASK_ALWAYS:                  "BEACON_ASK_ALWAYS",
    BEACON_WRONG_MEAN:                  "BEACON_WRONG_MEAN",
    BEACON_IOS_FIRST:                   "BEACON_IOS_FIRST",
    BEACON_ACTIVITY_PERMISSION_OFF:     "BEACON_ACTIVITY_PERMISSION_OFF",
    BEACON_PHOTOCELL_EXIT:              "BEACON_PHOTOCELL_EXIT",
    BEACON_LIMITED_FREE_USAGE:          "BEACON_LIMITED_FREE_USAGE",
    BEACON_LIMITED_BARRIER:             "BEACON_LIMITED_BARRIER",
    BEACON_NO_RESERVATION:              "BEACON_NO_RESERVATION",
    BEACON_IGNORE_SETTING_WALKING:      "BEACON_IGNORE_SETTING_WALKING",
    BEACON_IGNORE_SETTING_VEHICLE:      "BEACON_IGNORE_SETTING_VEHICLE",
    BEACON_ASK_WALKING:                 "BEACON_ASK_WALKING",
    BEACON_ASK_VEHICLE:                 "BEACON_ASK_VEHICLE",
    BEACON_MAX_CONNECTIONS_REACHED:     "BEACON_MAX_CONNECTIONS_REACHED",
    BEACON_SERVICE_RESET:     "BEACON_SERVICE_RESET",
    BEACON_VOICE_ASSISTANT:     "BEACON_VOICE_ASSISTANT",
    BEACON_WIDGET:     "BEACON_WIDGET",

    PROTECT_DISABLED:                   "PROTECT_DISABLED",
    PROTECT_ENABLED:                    "PROTECT_ENABLED",

    //ADDITONAL STATUS FOR TRIGGERS
    BUTTON_PRESSED:                     "BUTTON_PRESSED",

    BRAIN_RECORD_VIDEO:                 "BRAIN_RECORD_VIDEO",
    BRAIN_SIREN:                        "BRAIN_SIREN",
    
    OPEN_GARAGE_DOOR:                   "OPEN_GARAGE_DOOR",

    CLOCKIN:                            "CLOCKIN",
    VERSION_UPDATED:                    "VERSION_UPDATED",
};

export const LOCAL_STATUS_FOR_UI_LOG_TYPE = {
    [UI_LOG_TYPES.OPEN_CLOSE]: {
        0: LOCAL_STATUS.CLOSED,
        1: LOCAL_STATUS.OPEN,
    },
    [UI_LOG_TYPES.ON_OFF]: {
        0: LOCAL_STATUS.OFF,
        1: LOCAL_STATUS.ON,
    },
    [UI_LOG_TYPES.OPEN_CLOSE_STOP]: {
        0: LOCAL_STATUS.ROLLER_STOPPED,
        1: LOCAL_STATUS.ROLLER_OPENED,
        2: LOCAL_STATUS.ROLLER_CLOSED,
    },
    [UI_LOG_TYPES.ENTRY_EXIT]: {
        2: LOCAL_STATUS.ENTRY,
        3: LOCAL_STATUS.EXIT,
    },
    [UI_LOG_TYPES.ON_OFF_PLUG_FLOOD]: {
        0: LOCAL_STATUS.FLOOD_OPEN,
        1: LOCAL_STATUS.FLOOD_CLOSED,
    },
    [UI_LOG_TYPES.MOVEMENT]: LOCAL_STATUS.MOVEMENT_DETECTED,

    [UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK]: {
        0: LOCAL_STATUS.CLOSED_LOCKED,
        1: LOCAL_STATUS.CLOSED_UNLOCKED,
        2: LOCAL_STATUS.OPENED_LOCKED,
        3: LOCAL_STATUS.OPENED_UNLOCKED,
        5: LOCAL_STATUS.LATCH_REMOVED,
    },
    [UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK_EMERGENCY]: {
        3: LOCAL_STATUS.EMERGENCY_OPENING,
    },
    [UI_LOG_TYPES.VIBRATIONS]: LOCAL_STATUS.VIBRATIONS,

    [UI_LOG_TYPES.FLOOD]: {
        0: LOCAL_STATUS.IDLE,
        1: LOCAL_STATUS.FLOOD,
    },
    [UI_LOG_TYPES.DELETE_ALL_USERS]: LOCAL_STATUS.RESETED_PERMISSIONS,

    [UI_LOG_TYPES.BUTTON_PRESSED_TAMPER]: {
        0: LOCAL_STATUS.COVER_CLOSED,
        1: LOCAL_STATUS.COVER_OPENED,
    },
    [UI_LOG_TYPES.VIDEO]: LOCAL_STATUS.VIDEO_RECORDED,

    [UI_LOG_TYPES.INTERNET_STATUS]: {
        0: LOCAL_STATUS.DISCONNECTED,
        1: LOCAL_STATUS.CONNECTED,
    },
    [UI_LOG_TYPES.BRAIN_POWER]: {
        0: LOCAL_STATUS.SECONDARY_BATTERY,
        1: LOCAL_STATUS.AC_POWER,
    },
    [UI_LOG_TYPES.INHIBITION]: {
        0: LOCAL_STATUS.INHIBITION_STOPPED,
        1: LOCAL_STATUS.POSSIBLE_INHIBITION,
    },
    [UI_LOG_TYPES.REBOOT_DETECTED]: LOCAL_STATUS.REBOOT,

    [UI_LOG_TYPES.BATTERY_FAULT]: LOCAL_STATUS.BATTERY_FAULT,

    [UI_LOG_TYPES.DELAY_ON_OFF]: {
        0: LOCAL_STATUS.REQUEST_DELAYED_OFF,
        1: LOCAL_STATUS.REQUEST_DELAYED_ON,
    },
    [UI_LOG_TYPES.DELAY_OPEN_CLOSE_STOP]: {
        0: LOCAL_STATUS.REQUEST_DELAYED_STOP,
        1: LOCAL_STATUS.REQUEST_DELAYED_OPEN,
        2: LOCAL_STATUS.REQUEST_DELAYED_CLOSE,
    },
    [UI_LOG_TYPES.DELAY_ON_OFF_PLUG_FLOOD]: {
        0: LOCAL_STATUS.REQUEST_DELAYED_FLOOD_ON,
        1: LOCAL_STATUS.REQUEST_DELAYED_FLOOD_OFF,
    },
    [UI_LOG_TYPES.BEACON_DETECTED]: {
        1: LOCAL_STATUS.BEACON_EXECUTE,
        2: LOCAL_STATUS.BEACON_ASK,
        3: LOCAL_STATUS.BEACON_IGNORE,
        4: LOCAL_STATUS.BEACON_BLE_DISABLED,
        5: LOCAL_STATUS.BEACON_RETRY,
        6: LOCAL_STATUS.BEACON_ASK_ALWAYS,
        7: LOCAL_STATUS.BEACON_WRONG_MEAN,
        8: LOCAL_STATUS.BEACON_IOS_FIRST,
        9: LOCAL_STATUS.BEACON_ACTIVITY_PERMISSION_OFF,
        10: LOCAL_STATUS.BEACON_PHOTOCELL_EXIT,
        11: LOCAL_STATUS.BEACON_LIMITED_FREE_USAGE,
        12: LOCAL_STATUS.BEACON_LIMITED_BARRIER,
        13: LOCAL_STATUS.BEACON_NO_RESERVATION,
        14: LOCAL_STATUS.BEACON_IGNORE_SETTING_WALKING,
        15: LOCAL_STATUS.BEACON_IGNORE_SETTING_VEHICLE,
        16: LOCAL_STATUS.BEACON_ASK_WALKING,
        17: LOCAL_STATUS.BEACON_ASK_VEHICLE,
        18: LOCAL_STATUS.BEACON_MAX_CONNECTIONS_REACHED,
        19: LOCAL_STATUS.BEACON_SERVICE_RESET,
        20: LOCAL_STATUS.BEACON_VOICE_ASSISTANT,
        21: LOCAL_STATUS.BEACON_WIDGET,
    },

    [UI_LOG_TYPES.LOCATION_PROTECT_LOCALIZATION]: {
        0: LOCAL_STATUS.PROTECT_DISABLED,
        1: LOCAL_STATUS.PROTECT_ENABLED,
    },
    [UI_LOG_TYPES.LOCATION_PROTECT_MANUAL]:{
        0: LOCAL_STATUS.PROTECT_DISABLED,
        1: LOCAL_STATUS.PROTECT_ENABLED,
    },
    [UI_LOG_TYPES.LOCATION_PROTECT_PERIOD]:  {
        0: LOCAL_STATUS.PROTECT_DISABLED,
        1: LOCAL_STATUS.PROTECT_ENABLED,
    },
    [UI_LOG_TYPES.LOCATION_PROTECT_TRIGGER]: {
        0: LOCAL_STATUS.PROTECT_DISABLED,
        1: LOCAL_STATUS.PROTECT_ENABLED,
    },
    [UI_LOG_TYPES.UI_TYPE_CLOCKIN]: LOCAL_STATUS.CLOCKIN,
    [UI_LOG_TYPES.UI_TYPE_VERSION_UPDATED]: LOCAL_STATUS.VERSION_UPDATED,
};