import React from "react";
import { useTranslation } from "react-i18next";

import { Icons } from "../../_assets";
import { MainPageWithButtons } from "../../_components";

export function InvitationGroupWizardPage({
    handleSelectNewGroup = () => {},
    handleSelectExistingGroup = () => {},
} = {}) {
    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("invitation_group_wizard_title")}
            content={content()}
        />
    );

    function content() {
        return (
            <div>
                {option(
                    handleSelectNewGroup,
                    t("invitation_group_wizard_option_new")
                )}
                {option(
                    handleSelectExistingGroup,
                    t("invitation_group_wizard_option_existing")
                )}
                <hr className="m-0" />
            </div>
        );
    }

    function option(onClick, text) {
        return (
            <div
                key={text}
                onClick={onClick}
                className="
                    d-flex
                    justify-content-between
                    align-items-center

                    rounded
                    border

                    my-2
                    pl-2

                    c-hover-bg-primary-light-2
                    c-cursor-default
                "
            >
                {text}
                <img
                    alt="arrow_icon"
                    src={Icons.arrowRight}
                    className="c-img-2rem"
                />
            </div>
        );
    }
}