import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { WEBAPP_URLS } from '../_constants';
import { Selectors } from '../_reducers/app.reducer';
import { BudgetsEverouBridge } from '../BudgetsEverouBridge';
import { SUBDOMAIN_IDS } from '../../SUBDOMAIN_IDS';
import { DevelopersEverouBridge } from "../DevelopersEverouBridge";
import { StoreManager } from '../StoreManager';
import { PMSEverouBridge } from '../PMSEverouBridge';

import * as Sentry from "@sentry/react";

export function PrivateRoute({
    host,
    component: Component,
    ...rest
}) {

    return <Route 
        {...rest}
        render={props =>
            isLoggedIn()
            ? loadComponent(props)
            : toLogin(props)
        }
    />;

    function isLoggedIn() {
        return Selectors.getEverouUser(StoreManager.getStore().getState()) !== null;
    }

    function toLogin(props) {
        return (
            <Redirect
                to={{
                    pathname: WEBAPP_URLS.LOGIN,
                    state: {
                        from: props.location,
                    },
                }}
            />
        );
    }

    function setSentryUser() {
        const state = StoreManager.getStore().getState();
        if (state !== null) {
            Sentry.setUser({ email: state.common.authentication.user.email});
        }
    }

    function loadComponent(props) {
        setSentryUser();

        switch (host) {
            case SUBDOMAIN_IDS.BUDGETS:
                return <BudgetsEverouBridge {...props} />;

            case SUBDOMAIN_IDS.DEVELOPERS:
                return <DevelopersEverouBridge {...props} />;

            case SUBDOMAIN_IDS.PMS:
            case SUBDOMAIN_IDS.RENTAL:
                return <PMSEverouBridge {...props} />;

            default:
                return <Component {...props} />;
        }

    }
}