import { getFirstCharsFromStrings } from "../../Common/_utils/utils";
import accountIcon from "../_assets/appIcons/account_circle-grey-24px.svg";

type CircleAvatarProps = {
    srcImg?: string | null;
    names?: [string] | [string, string | null | undefined];
    style?: React.CSSProperties;
    className?: string;
    color?: string;
    bgColor?: string;
    borderColor?: string;
    textColor?: string;
};

export function CircleAvatar({ srcImg, names, style, className, color: bgColor = 'rgb(200, 200, 200)', borderColor, textColor = 'white'  }: CircleAvatarProps) {

    if(!borderColor) {
        borderColor = bgColor;
    }

    if (srcImg) {
        return (
            <img
                style={{borderColor: borderColor + ' !important', ...style}}
                className={`
                    ${className ?? ""}
                    border
                    c-h-2rem
                    c-w-2rem
                    rounded-circle
                    flex-shrink-0
                `}
                src={srcImg}
                alt={"avatar"}
            />
        );
    }

    let filteredNames = names?.filter((name) => !!name);

    if (filteredNames?.length) {

        return (
            <div
                style={{ padding: "2px", borderColor: bgColor + ' !important', ...style }}
                className={`
                    ${className ?? ""}
                    border
                    c-h-2rem
                    c-w-2rem
                    flex-shrink-0
                    rounded-circle
                `}
            >
                <div
                    style={{ backgroundColor: bgColor }}
                    className="
                    text-uppercase 
                    h-100 
                    w-100 
                    d-flex 
                    align-items-center
                    justify-content-center 
                    rounded-circle
                    "
                >
                    <span style={{top: '1px', color: textColor }} className="c-line-height-1 position-relative">{getFirstCharsFromStrings(filteredNames)}</span>
                </div>
            </div>
        );
    }

    return (
        <img
            style={{ borderColor: borderColor + ' !important', ...style }}
            className={`
                ${className ?? ""}
                border
                c-h-2rem
                c-w-2rem
                rounded-circle
                flex-shrink-0
            `}
            src={accountIcon}
            alt={"circle img"}
        />
    );
}
