import React, { useState } from "react";

export function ImgWithPlaceHolder({
    placeholeImg,
    expectedImg,
    className,
}) {
    const [loaded, setLoaded] = useState(false);

    return (
        <img
            alt="img"
            onLoad={handleLoaded}
            src={image()}
            className={className}
        ></img>
    );

    function image() {
        return loaded
            ? expectedImg
            : placeholeImg;
    }

    function handleLoaded() {
        setLoaded(true);
    }
}