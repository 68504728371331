import { GenericMapperFactory } from "./MapperFactory";
import { PaymentMethod, CardDataModel } from "../_models/PaymentMethod";

export const PaymentMethodMapper = GenericMapperFactory(paymentMethodServerToLocal);

function paymentMethodServerToLocal(serverPaymentMethod) {
    return PaymentMethod({
        uid:            serverPaymentMethod.uid,
        locationUid:    serverPaymentMethod.loc_uid,
        locationName:   serverPaymentMethod.loc_name,
        type:           serverPaymentMethod.type,

        data: CardDataModel({
            brand:      serverPaymentMethod.data.brand,
            expMonth:   serverPaymentMethod.data.exp_month,
            expYear:    serverPaymentMethod.data.exp_year,
            last4:      serverPaymentMethod.data.last4,
        }),
    })
}