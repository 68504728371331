import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { invitationsActions } from "../_actions";
import { NULL_MAIL } from "../_constants";
import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { Invitation } from "../_models/Invitation";
import { Selectors } from "../_reducers/app.reducer";

export function DeleteInvitationGlobalPopUp({
    invitation = Invitation(),
    isSuperguest = false,
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        offset,
        page_size,
        search_term
    } = useSelector(Selectors.getInvitations);

    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    const invitationFilter = useSelector(Selectors.getInvitationFilter);

    //ACTION HANDLERS
    function handleDeleteInvitation() {
        isSuperguest 
            ? dispatch(invitationsActions.deleteSubinvitation(invitation.uid))
            : dispatch(invitationsActions.deleteInvitation(invitation.uid,
                async () => {
                    return await dispatch(invitationsActions.listInvitations({ loc_uid: locationUid, page_size, offset, search_term, ...invitationFilter }));
                }
            ))
        ;
    }

    const message = invitation.invitationGroupUid
        ? t("invitation_delete_from_group_confirmation")
        : t("invitations.deleteInvitationDescription")
    ;

    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={t(
                "invitations.deleteInvitationQuestion",
                { invitation: emailOrUserName() }
            )}
            body={message}
            onAccept={handleDeleteInvitation}
        />
    );

    function emailOrUserName() {
        return invitation.email !== NULL_MAIL
            ? invitation.email
            : invitation.userName
        ;
    }
}