import React from 'react';
import { Icon } from "../_modelsTS/Icon";

type ColumnButtonProps = {
    handleClick?: (...args: any) => {} | void,
    active?: Boolean,
    name: any,
    icon?: Icon,
    testId?: string,
    itemsAmount?: any;
}

export function ColumnButton({
    handleClick = () => {},
    active,
    name,
    icon,
    testId,
    itemsAmount
}: ColumnButtonProps) {

    return (
        <div
            onClick={handleClick}
            test-id={testId}
            className={`
                py-2
                px-3

                c-font-weight-medium
                c-cursor-default
                d-flex
                cp
                c-transition-all
            
                ${
                    active
                        ? "c-bg-primary-light-2 c-text-primary font-weight-bold"
                        : "c-hover-bg-primary-light-2 c-bg-grey-250"
                }
            `}
        >
            {icon?.src && (
                <div className="d-flex align-items-center">
                    <img
                        className="c-img-1rem c-min-h-1 c-min-w-1 mr-2"
                        src={icon.src}
                        alt={icon.alt}
                    />
                </div>
            )}
            <div className="d-flex justify-content-between w-100">
                {name}
                {!isNaN(itemsAmount) ? (
                    <div style={{ opacity: 0.7, marginLeft: "8px" }}>
                        {itemsAmount}
                    </div>
                ) : null}
            </div>
        </div>
    );
}
