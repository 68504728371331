import { User } from "./User";
import { Mobile } from "./Mobile";

export function UserMobiles({
    user = User(),
    mobiles = [] || [Mobile()],
} = {}) {
    return {
        uid: user.uid,
        user,
        mobiles,
    };
}