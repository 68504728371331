import { PMS_LOCATIONS_ACTION_TYPES } from "../_actionTypes/PMS_LOCATIONS_ACTION_TYPES";
import { BASE_PAGE_SIZE } from "../../Everou/_modelsTS/Pagination";
import { PMSLocationFilter, PMSLocationsPaginatedItems } from "../_models/Pagination";
import { PMS_DEVICES_ACTION_TYPES } from "../_actionTypes/PMS_DEVICES_ACTION_TYPES";
import { PMSLocationsStore } from "../_stores/PMSLocationsStore";

export interface PMSLocationsState {
    filter: PMSLocationFilter;
    locations: PMSLocationsPaginatedItems;
    selected: any;
    locationInfo: any;
    devicesByRoom: any;
    customization: any;
    isUploadingImage: any;
    isRequestingLocations: any;
    newReservationDraft: any;
}

const initialState: PMSLocationsState = {
    filter: {},
    locations: {
        items: [],
        offset: 0,
        page_size: BASE_PAGE_SIZE,
        total_count: 0
    },
    selected: {},
    locationInfo: {},
    devicesByRoom: {},
    customization: {},
    isUploadingImage: false,
    isRequestingLocations: false,
    newReservationDraft: null,
};

let getRootState = state => state as PMSLocationsState;

export class PMSLocationsSelector {
    static getPMSLocationsFilter    = state => getRootState(state).filter;
    static getPMSLocations          = state => getRootState(state).locations;
    static getPMSLocationSelectedUid = state => getRootState(state).selected && getRootState(state).selected.uid;
    static getPMSLocationSelectedName = state => getRootState(state).selected && getRootState(state).selected.name;
    static getPMSLocationSelectedTimezone = state => getRootState(state).selected && getRootState(state).selected.timezone;
    static getPMSLocationInfo = state => getRootState(state).locationInfo;
    static getPMSLocationDevices = state => getRootState(state).locationInfo && getRootState(state).locationInfo.devices;
    static getPMSLocationRooms = state => getRootState(state).locationInfo && getRootState(state).locationInfo.rooms;
    static getPMSLocationCustomization = state => getRootState(state).customization;
    static getPMSCustomizationIsUploading = state => getRootState(state).isUploadingImage;
    static getPMSLocationsIsRequesting = state => getRootState(state).isRequestingLocations;
    static getPMSReservationNewDraft = state => getRootState(state).newReservationDraft;

    static setRootStateFn = (rootStateFn = state => state) => getRootState = rootStateFn;
}

export function locations(state=initialState, action): PMSLocationsState {

    /*const isEdited = (updatedInvitation) => {
        return !_.isEqual(_.omit(updatedInvitation, 'edited'), _.omit(state.invitations.items.find(invitation => invitation.uid === updatedInvitation.uid), 'edited'))
    }*/

    let selectedUpdated = {};

    switch (action.type) {
        case PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_LIST_SUCCESS:
            const { items, offset, page_size, total_count } = action.locations;
            return { ...state,
                locations: {
                    items: items,
                    offset: offset ?? 0,
                    page_size: page_size ?? state.locations.page_size,
                    total_count
                },
                isRequestingLocations: false
            };

        case PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_SELECT:
            return {
                ...state,
                selected: {
                    ...(state.locations.items?.find((location: any) => 
                        location?.uid === action.locationUid
                    ) ?? {}),
                    edited: false,
                },
            };

        case PMS_LOCATIONS_ACTION_TYPES.LOCATION_INFO_GET_SUCCESS:
            return {
                ...state,
                locationInfo: action.locationInfo,
            };

        case PMS_DEVICES_ACTION_TYPES.PMS_DEVICES_SHOW:
            return {
                ...state,
                devicesByRoom: action.devicesUids
            };

        case PMS_LOCATIONS_ACTION_TYPES.LOCATION_CUSTOMIZATION_GET_SUCCESS:
            return {
                ...state,
                customization: action.locationCustomization
            };

        case PMS_LOCATIONS_ACTION_TYPES.LOCATION_CUSTOMIZATION_IS_UPLOADING:
            return {
                ...state,
                isUploadingImage: action.isUploadingImage
            };

        case PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_SET_REQUESTING_STATUS:
            return {
                ...state,
                isRequestingLocations: action.status
            };

        case PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_LIST_FAILURE:
            return {
                ...state,
                isRequestingLocations: false
            };

        case PMS_LOCATIONS_ACTION_TYPES.CREATE_LOCAL_RESERVATION:
            return {
                ...state,
                newReservationDraft: action.invitation,
            };

        case PMS_LOCATIONS_ACTION_TYPES.CREATE_LOCAL_RESERVATION:
            return {
                ...state,
                newReservationDraft: {},
            };

        case PMS_LOCATIONS_ACTION_TYPES.RESERVATION_UPDATE_NEW_DRAFT_LOCALLY:
            return {
                ...state,
                newReservationDraft: {
                    ...state.newReservationDraft,
                    ...action.selectedModified,
                    edited:true,
                },
            };

        case PMS_LOCATIONS_ACTION_TYPES.RESERVATION_UPDATE_SELECTED_LOCALLY:
            selectedUpdated = {
                ...state.selected,
                ...action.selectedModified
            };

            return {
                ...state,
                selected: {
                    ...selectedUpdated,
                    //edited: isTypePredicateNode(selectedUpdated),
                },
            };
            
        default:
            return state;
    }
}