import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { EmptyGenericIntegration, EmptyHostifyIntegration, IntegrationCompany, integrationsByCompany, LocalEditableIntegration } from "../../../Everou/_modelsTS/Integration";
import { CapsuleButton, MainPageWithButtons } from "../../../Everou/_components";
import { removeFromArr } from "../../../Common/_utils/utils";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../../Everou/_reducers/app.reducer";
import { PMSHostifyIntegrationInput } from "../_components/PMSHostifyIntegrationInput";
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";
import { PMSOctorateIntegrationInput } from "../_components/PMSOctorateIntegrationInput";

export function PMSOctorateIntegration() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const serverIntegrations = useSelector(Selectors.getPMSIntegrations);
    const serverEditableIntegrations = serverIntegrations.map(integration => ({ ...integration, editable: false }))

    const [integrationsLocal, setIntegrationsLocal ] = useState<LocalEditableIntegration[]>([]);

    useEffect(() => {
        const updatedIntegrations = integrationsByCompany(serverIntegrations, IntegrationCompany.OCTORATE);
        setIntegrationsLocal(updatedIntegrations);
    }, [serverIntegrations, setIntegrationsLocal]);

    const canAddOnMore = useMemo(() => integrationsLocal.length === 0 || !!integrationsLocal[integrationsLocal.length - 1].uid
    , [integrationsLocal]);

    const addIntegration = useCallback(() => {
        if(canAddOnMore) {
            setIntegrationsLocal([...integrationsLocal, EmptyGenericIntegration])
        }
    }, [canAddOnMore, integrationsLocal]);

    const onDeleteIntegration = (index: number) => {
        const integration = integrationsLocal[index];
        if(!integration.uid) {
            setIntegrationsLocal(removeFromArr<LocalEditableIntegration>(index, integrationsLocal))
        } else {
            dispatch(PMSIntegrationsActions.deleteIntegration(integration.uid))
            setIntegrationsLocal(removeFromArr<LocalEditableIntegration>(index, integrationsLocal))
        }
    }

    const toggleEditable = (index: number) => {
        const updatedIntegrations = integrationsLocal.map((integration, maxIndex) => index === maxIndex ? ({ ...integration, editable: !integration.editable}) : integration);
        setIntegrationsLocal(updatedIntegrations);
    }

    const onSetInitialStatus = (index: number) => {
        const updatedIntegrations = integrationsLocal.map((integration, maxIndex) => index === maxIndex ? serverEditableIntegrations[index] : integration)
        setIntegrationsLocal(updatedIntegrations)
    }

    const body = (
        <div className="c-grid c-grid--small rounded-bottom">
            {integrationsLocal.map((integration, index) => (
                <PMSOctorateIntegrationInput
                    key={index}
                    onDelete={index => onDeleteIntegration(index)}
                    index={index}
                    integration={integration}
                    onSetInitialStatus={index => onSetInitialStatus(index)}
                    onToggleEditable={index => toggleEditable(index)}
                />
            ))}
            
        </div>
    );

    return (
        <MainPageWithButtons
            //dataTestId={testIDs.root}
            pageTitle={t("mod_integrations_octorate_title")}
            content={body}
        />
    );
}

