import React from "react";
import { useSelector } from 'react-redux';

import { Subscription } from "../_models/Subscription";
import { Selectors } from "../_reducers/app.reducer";
import { DeviceSelectorGeneric } from "./DeviceSelectorGeneric";
import { appPermissions } from "../_constants/permissions.constants";
import { SUBSCRIPTION_STATUS } from "../_constants/subscriptions.constants";

export function DeviceSelectorLocal({
    selected = Subscription(),
}) {

    //GLOBAL STATE
    const devices       = useSelector(Selectors.getSelectedLocationDevices);
    const permission    = useSelector(Selectors.getPermissionSelectedLocation);

    //LOCAL STATE
    const isAdding = selected.status === SUBSCRIPTION_STATUS.NEW;
    const canEdit = isAdding
        ? appPermissions.canUserActivateSubscriptions(permission)
        : appPermissions.canUserEditSubscriptions(permission)
    ;

    //VIEW
    return <DeviceSelectorGeneric
        canEdit={canEdit}
        selectedSubscription={selected}
        devices={devices}
    />;  
}