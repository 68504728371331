import { Children, cloneElement } from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

type ToolTipWrapperProps = {
    tooltipProps?: TooltipProps;
    children?: any;
    tooltip: any;
};

// @params children must be wrapped, not array of elements
export default function ToolTipWrapper({
    tooltipProps,
    children,
    tooltip,
}: ToolTipWrapperProps) {
    
    if(!tooltip) return children;

    tooltipProps = {
        effect: "solid",
        delayShow: 200,
        className: "px-2 py-0 bg-dark",
        id: uuidv4(),
        ...tooltipProps,
    };

    const clonedChildren = Children.map(children, (child) =>
        cloneElement(child, { "data-tip": true, "data-for": tooltipProps?.id }),
    );

    return (
        <>
            {clonedChildren}
            {<ReactTooltip {...tooltipProps}>{tooltip}</ReactTooltip>}
        </>
    );
}
