import { PMSLocation } from "../_models/PMSLocation";
import { DeviceMapper } from "../../Everou/_mappers";
import { PMSReservationMapper } from "./PMSReservationMapper";

export class PMSLocationsMapper {
    static mapAllServerToLocal(serverPMSLocations = []) {
        return serverPMSLocations.map(this.mapServerToLocal);
    }

    static mapServerToLocal(
        {
            uid,
            name,
            timezone,
            allows_customization,
            protect_without_subscription,
            protect,
            reservations,
            devices,
            tags,
            vacation_rental_plan,
            url_recommendations,
            traveler_discount,
            external_video_url,
        }
    ) {
        return PMSLocation({
            uid,
            name,
            timezone,
            customization: allows_customization,
            protect: protect_without_subscription,
            protectStatus: protect,
            reservations: PMSReservationMapper.allServerToLocal(reservations),
            devices: devices.map(serverDevice => DeviceMapper.serverToLocal(serverDevice)),
            tags: tags.map(tag => tag.tag),
            vacationRentalPlan: vacation_rental_plan,
            urlRecommendations: url_recommendations,
            travelerDiscount: traveler_discount,
            externalVideoUrl: external_video_url
        });
    }
}

