import ReconnectingWebSocket from 'reconnecting-websocket';
import { URL_CONSTANTS, WEBSOCKETS_MESSAGE_TYPE, WEBSOCKETS_CONFIGURATION } from '../_constants';
import { websocketsActions } from '../_actions';

import queryString from 'query-string';
import { StoreManager } from '../StoreManager';

export const websocketService = {
    setUp,
    reconnect,
};

function setUp(machineUid, apikey) {
    if (process.env.NODE_ENV === "test")
        return;

    if (window.webSocket !== undefined)
        return;

    if (!machineUid || !apikey)
        return; 

    const webSocket = createWebSocket(machineUid, apikey);
    window.webSocket = webSocket;
    addOnMessageEvents(webSocket);
    addRestOfEvents(webSocket);
}

function reconnect(machineUid, apikey) {
    if (window.webSocket !== undefined) {
        window.webSocket.close();
        window.webSocket = undefined;
    }
    websocketService.setUp(machineUid, apikey);
}

function createWebSocket(machineUid, apikey) {
    var url_params = {
        uid: machineUid,
        api_key: apikey,
        keepalive: WEBSOCKETS_CONFIGURATION.KEEP_ALIVE,
    };

    const webSocket = new ReconnectingWebSocket(URL_CONSTANTS.URL_WEBSOCKET + queryString.stringify(url_params));
    webSocket.reconnectionDelayGrowFactor = 1; // how fast the reconnection delay grows   
    return webSocket;
}

function addOnMessageEvents(webSocket) {
    webSocket.onmessage = evt => {
        const data = JSON.parse(evt.data);
        switch (data.action) {
            case WEBSOCKETS_MESSAGE_TYPE.PONG:
                console.log("pong");
                pingTimeout(webSocket);
                break;

            case WEBSOCKETS_MESSAGE_TYPE.GPIO_ORDER:
                StoreManager.getStore().dispatch(websocketsActions.gpioOrderReceived(data.log_data));
                break;

            case WEBSOCKETS_MESSAGE_TYPE.ERROR:
                StoreManager.getStore().dispatch(websocketsActions.error("Websocket error: " + data.message));
                break;

            default:
                console.warn("websocket message:", data);
                break;
        }
    };
}

function addRestOfEvents(webSocket) {
    webSocket.onopen = () => {
        StoreManager.getStore().dispatch(websocketsActions.connected());
        ping(webSocket);
    };

    webSocket.onclose = () => {
        console.warn("websocket disconnected");
    };

    webSocket.onerror = (event) => {
        console.error("websocket error", event);
    };
}

function ping(webSocket) {
    webSocket.send(JSON.stringify({ 'action': WEBSOCKETS_MESSAGE_TYPE.PING }));
    console.log("ping");
}

function pingTimeout(webSocket) {
    setTimeout(
        () => ping(webSocket),
        WEBSOCKETS_CONFIGURATION.TIMEOUT_PING
    );
}