import { CardMapper } from "../../Everou/_mappers/CardMapper";
import { NfcInfo } from "../_models/NfcInfo";

export class NfcInfoMapper {
    static serverToLocal(serverNfcInfo) {
        return NfcInfo({
            ...CardMapper.serverToLocal(serverNfcInfo),
            userEmail:              serverNfcInfo.user_email,
            userLastName:           serverNfcInfo.user_last_name,
            userName:               serverNfcInfo.user_name,
        })
    }
}