import { VIDEO_ACTION_TYPES } from "../_constants/video.constants";

const initialState = {
    brainUid: undefined,
    videoLogs: [],
    isConverting: false,
    filter: {
        date: {
            start: null,
            end: null,
        },
    },

    next: -1,
    isRequesting: false,
    isRequestingFirst: false,
};

export class VideoSelectors {
    static getNextPage =           state => state.next;          
    static getLogs =               state => state.videoLogs;      
    static getDateFilter =         state => state.filter.date;          
    static getBrainUid =           state => state.brainUid;          
    static getIsConverting =       state => state.isConverting;              
    static getIsRequesting =       state => state.isRequesting;              
    static getIsRequestingFirst =  state => state.isRequestingFirst;                  
}

export function video(state = initialState, action) {
    switch (action.type) {
        

        case VIDEO_ACTION_TYPES.CHANGE_FILTER_DATE:
            return { ...state,
                filter: {
                    date: { ...state.filter.date,
                        ...action.date,
                    },
                }
            };

        case VIDEO_ACTION_TYPES.SELECT_BRAIN:
            return { ...state,
                brainUid: action.brainUid,
            };

        case VIDEO_ACTION_TYPES.SET_IS_CONVERTING:
            return { ...state,
                isConverting: action.isConverting,
            };

        case VIDEO_ACTION_TYPES.LIST_VIDEOS_REQUEST:
            return { ...state,
                isRequesting: true,
                isRequestingFirst: action.isRequestingFirst,
            };

        case VIDEO_ACTION_TYPES.LIST_VIDEOS:
            return { ...state,
                videoLogs: state.isRequestingFirst
                    ? action.videoLogs
                    : [...state.videoLogs, ...action.videoLogs]
                ,

                isRequesting: false,
                isRequestingFirst: false,
                next: action.next,
            };

        case VIDEO_ACTION_TYPES.LIST_VIDEOS_FAILURE:
            return { ...state,
                videoLogs: [],
                
                isRequesting: false,
                isRequestingFirst: false,
                next: -1,
            };

        default:
            return state;
    }
}