import { ClockinUser } from "../_models/ClockinUser";
import { OtherUser } from "../_models/OtherUser";
import { GenericMapperFactory } from "./MapperFactory";

export const ClockinUserMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverClockinUser) {
    return ClockinUser({
        uid:            serverClockinUser.user_data.uid,
        isValid:        serverClockinUser.is_valid,
        workMinutes:    serverClockinUser.work_minutes,
        user:           OtherUser({
            uid: serverClockinUser.user_data.uid,
            name: serverClockinUser.user_data.name,
            lastName: serverClockinUser.user_data.last_name,
            email: serverClockinUser.user_data.email,
            avatar: serverClockinUser.user_data.avatar,
        }),
        settings:       ClockinUserSettingsMapper.serverToLocal(serverClockinUser.user_clockin_settings),
    });
}

export const ClockinUserSettingsMapper = GenericMapperFactory(
    settingsServerToLocal,
);

function settingsServerToLocal(serverSettings) {
    return ClockinUser.ClockinUserSettings({
        allowsRemoteClockins: serverSettings.allows_remote_clockins,
        hasFlexibleClockins: serverSettings.has_flexible_clockins,
    });
}