import L from 'leaflet';

export const MapHelper = {
    createMap,
    removeMap,
}

function removeMap(map) {
    map.remove();
}

function createMap({ id, latitude, longitude, radius }) {
    if (latitude === undefined || latitude === null)
        return;

    const map = createMap(id , latitude, longitude);
    disableMapHandlers(map);
    addDotToMap(map, radius);

    if (radius)
        addCircleToMap(map, radius);

    return map;

    ////
    function createMap(mapDomId, latitude, longitude) {
        return L.map(mapDomId, {
            center: [latitude, longitude],
            zoomControl: false,
            zoom: 16,
            layers: [
                L.tileLayer(
                    'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
                    { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' }
                ),
            ],
        });
    }

    function disableMapHandlers(map) {
        map._handlers.forEach(handler => {
            handler.disable();
        });
    }

    function addDotToMap(map) {
        L.circle(
            [latitude, longitude],
            {
                color: '#009fdf',
                fillColor: '#009fdf',
                fillOpacity: 0.5,
                radius: 10,
                weight: 1,
                interactive: false,
            },
        ).addTo(map);
    }

    function addCircleToMap(map, radius) {
        L.circle(
            [latitude, longitude],
            {
                color: '#009fdf',
                fillColor: '#009fdf',
                fillOpacity: 0.5,
                radius: radius,
                weight: 1,
                interactive: false,
            },
        ).addTo(map);
    }
}