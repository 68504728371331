import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { CapsuleButton } from "../_components/CapsuleButton";
import { technicalServiceActions } from "../_actions/technicalService.actions";
import { Selectors } from "../_reducers/app.reducer";
import { appPermissions } from "../_constants/permissions.constants";

export function TechnicalServiceModule() {
    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);
    const permission    = useSelector(Selectors.getPermissionSelectedLocation);
    const code          = useSelector(Selectors.getSatCode);

    //LOCAL STATE
    const canRequestSat = appPermissions.canRequestSat(permission);
    const isSatEnabled = !!code;
    const satPhoneNumber = t('global_phone_number_sat');

    //INITIALIZATION
    useEffect(() => {
        dispatch(technicalServiceActions.checkIsSatEnabled());
    }, [dispatch, locationUid]);

    //VIEW
    if (!canRequestSat)
        return null;
    
    return isSatEnabled
        ? enabled()
        : disabled()
    ;

    function disabled() {
        return (
            <div className="
                d-flex
                justify-content-center
                p-2
            ">
                {enableSatButton()}
            </div>
        );
    }

    function enabled() {
        return (
            <div className="
                d-flex
                flex-column
                border
                rounded
                p-2
                my-2
            ">
                {titleFn()}
                {satEnabledStatusMessage()}
                <div className="mt-2"/>
                <div className="d-flex justify-content-center">
                    {disableSatButton()}
                </div>
            </div>
        );
    }

    function titleFn() {
        return (
            <div className="
                text-center
                font-weight-bold
                c-new-ft-l

                my-auto
            ">
                {t("location_info_sat_title")}
            </div>
        );
    }

    function enableSatButton() {
        return (
            <CapsuleButton
                text={t("location_info_sat_request_sat")}
                onClick={handleRequestTechnicalSupport}
            />
        );
    }

    function disableSatButton() {
        return (
            <CapsuleButton
                text={t("location_info_sat_dialog_cancel_request")}
                onClick={handleDisableTechnicalSupport}
                style={`DANGER`}
            />
        );
    }

    function satEnabledStatusMessage() {
        if (!isSatEnabled)
            return;

        return (
            <div className="text-center">
                {t("location_info_sat_dialog_pw",
                {
                    phoneNumber: satPhoneNumber,
                    code,
                },
                )}
            </div>
        );
    }

    //ACTION HANDLERS
    function handleRequestTechnicalSupport() {
        dispatch(technicalServiceActions.requestSat());
    }

    function handleDisableTechnicalSupport() {
        dispatch(technicalServiceActions.disableSat());
    }
}