import { AUDIT_LOGS_ACTION_TYPES } from "../_constants/auditLogs.constants";
import { FILTER_DISABLED_ALL, filterDisabled } from "../_constants";

const initialState = {
    filterPresets: [],
    selectedPreset: FILTER_DISABLED_ALL.key,
    otherFilters: {
        paths: [],
        type: filterDisabled.number,
        actionPath: filterDisabled.number,
        user: filterDisabled.number,
    },
};

export function auditLogsFilters(state = initialState, action) {
    switch (action.type) {
        case AUDIT_LOGS_ACTION_TYPES.GET_FILTER_PRESETS_SUCCESS:
            return { ...state,
                filterPresets: action.filterPresets,
            };

        case AUDIT_LOGS_ACTION_TYPES.CREATE_FILTER_PRESET_SUCCESS:
            return { ...state,
                filterPresets: [ ...state.filterPresets,
                    action.filterPreset,
                ],
                selectedPreset: action.filterPreset.uid,
            };
            
        case AUDIT_LOGS_ACTION_TYPES.REMOVE_FILTER_PRESET_SUCCESS:
            return { ...state,
                filterPresets: state.filterPresets.filter(filterPreset => 
                    filterPreset.uid !== action.uid
                ),
                selectedPreset: FILTER_DISABLED_ALL.key,
            };

        case AUDIT_LOGS_ACTION_TYPES.SELECT_FILTER_PRESET:
            return { ...state,
                selectedPreset: action.uid,
            };
            
        case AUDIT_LOGS_ACTION_TYPES.UPDATE_GENERIC_FILTER: {
            return {
                ...state,
                otherFilters: {
                    ...state.otherFilters,
                    ...action.updatedObj,
                },
            };
        }

        default:
            return state;
    }
}