import React from "react";
import { useTranslation } from "react-i18next";

import missingIcon from '../_assets/icons/device-icon-missing.png';

DevicesDataTable.DeviceDataRowModel = ({
    deviceName,
    automaticOpen,
    automaticExit,
    manualOpen,
    isOk,
    statusImg,
    hasNoUses = false,
} = {}) => ({
    deviceName,
    automaticOpen,
    automaticExit,
    manualOpen,
    isOk,
    statusImg,
    hasNoUses,
});

DevicesDataTable.DeviceDataTheadModel = ({
    text,
    isVisible = true,
} = {}) => ({
    text,
    isVisible,
});

export function DevicesDataTable({
    canSeeAllData = false,
    deviceDataRowModels = [] || [DevicesDataTable.DeviceDataRowModel()],
    theadModels = [] || [DevicesDataTable.DeviceDataTheadModel()],
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div>
            <div className="font-weight-bold">
                {t("mod_users_status_devices_diagnostics")}
            </div>
            
            <hr className="m-0"/>
            <div className="mt-1"/>

            <table className="w-100 border rounded">
                <thead className="c-bg-grey-230">
                    <tr>
                        {theadModels.map(theadFromModel)}
                    </tr>
                </thead>
                <tbody>
                    {deviceRows()}
                </tbody>
            </table>
        </div>
    );

    function theadFromModel(theadModel = DevicesDataTable.DeviceDataTheadModel(), index) {
        if (!theadModel.isVisible)
            return;
        
        return (
            <th key={index}
                className={`
                    ${index !== 0 && "text-center"}
                `}
            >
                {theadModel.text}
            </th>
        );
    }

    function deviceRows() {
        return (
            deviceDataRowModels
            .sort((a, b) => a.deviceName.localeCompare(b.deviceName))
            .map(deviceRow)
        );
    }

    function deviceRow(deviceDataRowModel = DevicesDataTable.DeviceDataRowModel(), index) {
        return (
            <tr key={index}
                className={`
                    text-center
                    border-top

                    ${!deviceDataRowModel.isOk && "c-bg-light-red"}
                    ${deviceDataRowModel.hasNoUses && "c-bg-light-yellow"}
            `}>
                <td className="text-left">
                    {deviceDataRowModel.deviceName}
                </td>
                {canSeeAllData &&
                    <>
                    <td>
                        {deviceDataRowModel.manualOpen}
                    </td>
                    <td>
                        {deviceDataRowModel.automaticOpen}
                    </td>
                    <td>
                        {deviceDataRowModel.automaticExit}
                    </td>
                    </>
                }

                <td>
                    <img
                        src={deviceDataRowModel.statusImg || missingIcon}
                        className="c-img-1-5rem"
                        alt="status-img"
                    />
                </td>
            </tr>
        );
    }
}