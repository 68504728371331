import { ACCESS_TYPE, URL_CONSTANTS } from '../_constants';
import { ServiceHelper } from './ServiceHelper';

interface UpdateDeviceServerParams {
    uid: string;
    notification?: boolean;
    description?: string;
    room_uid?: string;
    access_type?: ACCESS_TYPE;
    is_barrier?: boolean;
    remote?: boolean;
    restrict_exit?: boolean;
}

export const deviceService = {
    sendActionToDevice,
    sendNoStatusActionToDevice,

    updateBrain,
    updateDevice,

    actionRequest,
    actionConfirm,

    getDeviceInfo,
    getBrainInfo,

    getDeviceFirmwareVersions,
    updateDeviceFirmwareVersion,

    rebootDevice
};

async function getDeviceInfo(uid: string) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.DEVICE_INFO,
        { uid },
    );
}

async function getBrainInfo(uid) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.BRAIN_INFO,
        { uid },
    );
}

async function sendActionToDevice(uid, action) {
    const bodyObject = {
        uid,
        action,
    };
    const response = await ServiceHelper.postRequest(URL_CONSTANTS.DEVICE_ACTION, bodyObject);
    return response.action_uid;
}

function sendNoStatusActionToDevice(uid, thermostat_enabled, thermostat_automatic, thermostat_mode, temperature_manual) {
    const bodyObject = {
        uid,
        thermostat_enabled,
        thermostat_automatic,
        thermostat_mode,
        temperature_manual,
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.DEVICE_NOSTATUS_ACTION, bodyObject);
}

function updateDevice({
    uid,
    notification,
    description,
    room_uid,
    access_type,
    is_barrier,
    remote,
    restrict_exit
}: UpdateDeviceServerParams) {
    return ServiceHelper.postRequest(URL_CONSTANTS.DEVICE_UPDATE, {
        uid,
        notification,
        desc: description,
        room_uid,
        access_type,
        is_barrier,
        remote,
        restrict_exit
    });
}

function updateBrain({
    uid,
    notification,
    description,
    room_uid,
}: UpdateDeviceServerParams) {
    return ServiceHelper.postRequest(URL_CONSTANTS.BRAIN_UPDATE, {
        uid,
        notification,
        desc: description,
        room_uid,
    });
}

function getDeviceFirmwareVersions({ uid }) {
    return ServiceHelper.getRequest(URL_CONSTANTS.DEVICE_UPDATE_LIST, { uid });
}

function updateDeviceFirmwareVersion(uid, version) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.DEVICE_UPDATE_FIRMWARE,
        {
            uid,
            version,
        },
        false
    );
}

function rebootDevice(uid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.DEVICE_REBOOT,
        {
            uid,
        },
        false
    );
}


async function actionRequest(deviceUid, action) {
    return await ServiceHelper.postRequest( 
        URL_CONSTANTS.DEVICE_ACTION_REQUEST,
        {
            uid: deviceUid,
            action,
        },
    );
}


async function actionConfirm(deviceUid, actionUid, packet) {
    return await ServiceHelper.postRequest(
        URL_CONSTANTS.DEVICE_ACTION_CONFIRM,
        {
            uid: deviceUid,
            action_uid: actionUid,
            packet,
        },
    );
}