import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Subscription } from "../_models/Subscription";
import { devicesActions } from "../_actions";
import { Selectors } from "../_reducers/app.reducer";
import { DeviceSelectorGeneric } from "./DeviceSelectorGeneric";
import { LocationSelectorComp } from "../_components/LocationSelectorComp";
import { appPermissions } from "../_constants/permissions.constants";
import { LocationModel } from "../_models/LocationModel";
import { subscriptionsActions } from "../_actions/subscriptions.actions";

export function DeviceSelectorGlobal({
    selected = Subscription(),
}) {
    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const globalSelectedLocationUid = useSelector(Selectors.getSelectedLocationUid);

    //LOCAL STATE
    const [selectedLocationUid, setSelectedLocationUid] = useState(globalSelectedLocationUid);

    //GLOBAL STATE
    const locations                 = useSelector(Selectors.getLocations);
    const devices                   = useSelector(state => Selectors.getLocationDevices(state, selectedLocationUid));
    let selectedLocation            = LocationModel();
    selectedLocation                = useSelector(state => Selectors.getLocation(state, selectedLocationUid));

    //SIDE EFFECTS
    useEffect(() => {
        dispatch(devicesActions.getDevicesFromLocation(selectedLocationUid));
        dispatch(subscriptionsActions.changeDevice(null));
    }, [dispatch, selectedLocationUid]);

    const hasPermissionInSelectedLocation = appPermissions.canUserActivateSubscriptions(selectedLocation.userType);

    //VIEW
    return (
        <div>
            <LocationSelectorComp
                onSelectLocation={setSelectedLocationUid}
                selectedLocationUid={selectedLocationUid}
                locations={locations}
            />
            {hasPermissionInSelectedLocation
                ? <DeviceSelectorGeneric
                    canEdit={true}
                    selectedSubscription={selected}
                    devices={devices}
                />
                : noPermissionMessage()
            }
        </div>
    );

    function noPermissionMessage() {
        return (
            <div className="text-right">
                {t("global_no_permission_message")}
            </div>
        );
    }
}