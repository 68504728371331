import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Invitation } from "../../_models/Invitation";
import { LOCATION_PERMISSIONS } from "../../_constants/permissions.constants";
import { SimpleToggle } from "../../_components";
import { invitationsActions } from "../../_actions";  
import { CustomNumberInput } from "../../../Common/_components/CustomNumberInput";
import { ALLOWED_SUBINVITATIONS } from "../../_constants";
import { Accordion, useAccordionButton } from 'react-bootstrap';
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";

const TEST_IDS = {
    TOGGLE: "subinvitations-selector-toggle",
    INPUT: "subinvitations-selector-input",
};

type SubinvitationsSelectorComponentProps = {
    canCreateSuperguests?: boolean,
    invitation?: any; // To be types asap
    isEditable?: boolean;
    isNew?: boolean;
    mode?: 'invitation' | 'invitation_group';
}

SubinvitationsSelectorComponent.TEST_IDS = TEST_IDS;

export function SubinvitationsSelectorComponent({
    canCreateSuperguests = false,
    invitation = Invitation(),
    isEditable = false,
    isNew = false,
    mode = 'invitation'
}: SubinvitationsSelectorComponentProps) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    const { allowsSubinvitations, subinvitations } = invitation;

    //ACTION HANDLERS
    function handleToggleAllowsSubinvitations() {
        const action = mode === 'invitation' ? invitationsActions.toggleAllowsSubinvitations : invitationGroupActions.toggleAllowsSubinvitations;
        dispatch(action());
    }

    function handleChangeSubinvitations(subinvitations) {
        const action = mode === 'invitation' ? invitationsActions.changeSubinvitations : invitationGroupActions.changeSubinvitations;
        dispatch(action(subinvitations));
    }

    if (
        !canCreateSuperguests
        || (!isNew && !allowsSubinvitations)
        || invitation.permissionType !== LOCATION_PERMISSIONS.GUEST
    )
        return null;

    const activeKey = allowsSubinvitations ? '0' : null;
    
    return (
        <Accordion activeKey={activeKey}>
            <SubinvitationsToggle
                eventKey='0'
                handleChange={handleToggleAllowsSubinvitations}
                checked={allowsSubinvitations}
                readOnly={!isEditable}
            />
            <Accordion.Collapse eventKey='0'>
                <>{numberOfSubinvitationsSelector()}</>
            </Accordion.Collapse>
        </Accordion>
    );

    function numberOfSubinvitationsSelector() {
        return (
            <div
                className="
                d-flex 
                align-items-center
                justify-content-between
                mt-3
            "
            >
                    <div className="text-secondary font-italic mb-1">
                        {t("invitations.numberOfUsers")}
                    </div>
                    <CustomNumberInput
                        testId={TEST_IDS.INPUT}
                        onChange={ammount => handleChangeSubinvitations(ammount)}
                        min={ALLOWED_SUBINVITATIONS.MIN}
                        max={ALLOWED_SUBINVITATIONS.MAX}
                        value={subinvitations}
                    />
            </div>
        );
    }
}

function SubinvitationsToggle({ eventKey, handleChange, checked, readOnly }) {

    const { t } = useTranslation();

    const decoratedOnClick = useAccordionButton(eventKey, handleChange);

    return (
        <div
            className="
            d-flex
            justify-content-between
            align-items-center
        ">
            <div className="font-weight-bold">
                {t("invitations.canInviteOtherUsers")}
            </div>
            <div>
                <SimpleToggle
                    testID={TEST_IDS.TOGGLE}
                    handleChange={decoratedOnClick}
                    checked={checked}
                    readOnly={readOnly}
                    ariaControls={'subinvitations-input'}
                />
            </div>
        </div>
    );
}