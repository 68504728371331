import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CapsuleButton, ContentSpace, MainPageWithButtons } from "../_components";
import { PaymentMethodSelector } from "../_components/PaymentMethodSelector";
import { LocationBillingDataView } from "./LocationBillingDataView";
import { HistoticOrdersListView } from "./HistoricOrdersListView";
import { useDispatch } from "react-redux";
import { OrdersActions } from "../_actions/OrdersActions";
import { Selectors } from "../_reducers/app.reducer";
import { Section } from "../../Common/_components/Section";
import { CustomCard } from "../_components";
import { AllOrders } from "../_modelsTS/Order";
import { LocationBillingDataActions } from "../_actions/LocationBillingDataActions";
import { ReadOnlyDataRow } from "../_components/ReadOnlyDataRow";
import { BillingData } from "../_modelsTS/BillingData";
import { SingleOrderView } from "./SingleOrderView";

export function BillingModule() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const billingData: BillingData = useSelector(
        Selectors.getLocationBillingData,
    );
    const [detailedContent, setDetailedContent] = useState<any>(null);
    const [orders]: AllOrders[] = useSelector(Selectors.getOrders);
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    const historicOrders = orders?.historic;
    const upcomingOrder = orders?.upcoming;

    //INITIALIZATION
    useEffect(() => {
        dispatch(LocationBillingDataActions.getBillingData(locationUid));
        dispatch(OrdersActions.listOrders(locationUid));
        setDetailedContent(null);
    }, [dispatch, locationUid]);

    const historicOrdersView = useMemo(() => {
        return !!historicOrders?.length ? (
            <MainPageWithButtons
                pageTitle={t("mod_billing_last_receipts")}
                size='SMALL'
                content={
                    <HistoticOrdersListView historicOrders={historicOrders} />
                }
            />
        ) : null;
    }, [historicOrders, t]);

    
    const billingInformation = useMemo(
        () => (
            <MainPageWithButtons
                pageTitle={t("mod_billing_data_title")}
                size="SMALL"
                content={<LocationBillingDataView billingData={billingData} />}
            />
        ),
        [billingData, t],
    );

    //VIEW
    return (
        <ContentSpace
            mainContent={mainContent()}
            detailedContent={detailedContent}
            onGoBack={() => setDetailedContent(null)}
        />
    );
    function mainContent() {
        return (
            <MainPageWithButtons
                pageTitle={t("mod_billing_title")}
                content={content()}
                size='LARGE'
            />
        );

        function billingDataTable() {
            if (billingData) {
                return (
                    <div>
                        <ReadOnlyDataRow
                            name={t("mod_billing_data_name")}
                            data={billingData.name}
                        />
                        <ReadOnlyDataRow
                            name={t("mod_billing_data_tax_number")}
                            data={billingData.taxNumber}
                        />
                        <ReadOnlyDataRow
                            name={t("mod_billing_data_street_address")}
                            data={billingData.streetAddress}
                        />
                        <ReadOnlyDataRow
                            name={t("mod_billing_data_postal_code")}
                            data={billingData.postalCode}
                        />
                        <ReadOnlyDataRow
                            name={t("mod_billing_data_city")}
                            data={billingData.city}
                        />
                        <ReadOnlyDataRow
                            name={t("mod_billing_data_state")}
                            data={billingData.state}
                        />
                        <ReadOnlyDataRow
                            name={t("mod_billing_data_country")}
                            data={billingData.country}
                        />
                        <ReadOnlyDataRow
                            hasSeparators={false}
                            name={t("mod_billing_data_phone_number")}
                            data={billingData.phoneNumber}
                        />
                    </div>
                );
            }
            return (
                <div className="c-h-100 text-center my-2">
                    {t("mod_billing_not_billing_data_configured")}
                </div>
            );
        }

        function content() {
            return (
                <div
                    className="pt-4"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "32px",
                    }}
                >
                    <CustomCard>
                        <Section title={t("mod_billing_data_title")}>
                            {billingDataTable()}
                            <div className="d-flex justify-content-end mt-2">
                                <CapsuleButton
                                    onClick={() =>
                                        setDetailedContent(billingInformation)
                                    }
                                    text={t("global_edit")}
                                />
                            </div>
                        </Section>
                    </CustomCard>
                    <div className="c-grid">
                        <CustomCard className="c-rounded-lg c-h-min border pt-4 p-3">
                            <PaymentMethodSelector />
                        </CustomCard>

                        {upcomingOrder && (
                            <SingleOrderView
                                order={upcomingOrder}
                                buttons={
                                    !!historicOrders?.length
                                        ? [
                                              <CapsuleButton
                                                  key={"0"}
                                                  text={t(
                                                      "mod_billing_view_last_receipts",
                                                  )}
                                                  onClick={() =>
                                                      setDetailedContent(
                                                          historicOrdersView,
                                                      )
                                                  }
                                              />,
                                          ]
                                        : []
                                }
                            />
                        )}
                    </div>
                </div>
            );
        }
    }
}
