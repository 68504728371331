import { TimeHelper } from "../_helpers";
import { Clockin } from "../_modelsTS/Clockin";

export class ClockinMapper {
    static allServerToLocal(serverClockins = []) {
        return serverClockins.map(this.serverToLocal);
    }

    static serverToLocal(serverClockin): Clockin {

        const dateString = TimeHelper.serverStringToIsoString(serverClockin.date);
        const modifiedOnString = TimeHelper.serverStringToIsoString(serverClockin.modified_on);

        return {
            uid:        serverClockin.uid,
            date:       dateString ? new Date(dateString) : undefined,
            type:       serverClockin.type,
            deviceName: serverClockin.device_name,
            roomName:   serverClockin.room_name,
            notes:      serverClockin.notes,
            editDate:   modifiedOnString ? new Date(modifiedOnString) : undefined,
            editUserName:   serverClockin.modified_by_email,
            alert:      serverClockin.alert,
        };
    }
}