import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { PMS_API_URLS } from "../_constants/PMS_API_URLS";

export const PMSCustomizationService = {
    get,
    update,
    uploadLogo,
    uploadBackground,
};

async function get() {
    return await ServiceHelper.getRequest(
        PMS_API_URLS.CUSTOMIZATION_TEMPLATE
    );
}

async function update(accentColor, headerColor) {
    return await ServiceHelper.postRequest(
        PMS_API_URLS.CUSTOMIZATION_TEMPLATE,
        {
            accent_color: accentColor,
            header_color: headerColor,
        },
    );
}

async function uploadLogo(image) {
    await ServiceHelper.putImageRequest(
        PMS_API_URLS.CUSTOMIZATION_UPLOAD_LOGO,
        image,
    );
}

async function uploadBackground(image) {
    await ServiceHelper.putImageRequest(
        PMS_API_URLS.CUSTOMIZATION_UPLOAD_BACKGROUND,
        image,
    );
}