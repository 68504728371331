import ToolTipWrapper from "../../_components/ToolTipWrapper";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { invitationGroupActions } from "../../_actions";
import { Icons } from "../../_assets";
import { CapsuleButton, CaptionText, CustomAccordion } from "../../_components";
import { INVITATION_CARD_TYPES, INVITATION_CARD_TYPES_TRANSLATIONS } from "../../_constants";
import { CardPack } from "../../_models/CardPack";

export function SingleCardPack({ cardPack = CardPack() }) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState(false);

    const isNfcCardPack = cardPack.cardType === INVITATION_CARD_TYPES.NFC;

    return (
        <CustomAccordion
            key={cardPack.uid}
            onToggle={setIsOpened}
            header={
                <div
                    key={cardPack.uid}
                    className="
                    d-flex
                    justify-content-between
                    align-items-center
                "
                >
                    <div className="d-flex">
                        {!isNfcCardPack && <img
                            style={{ marginLeft: -4 }}
                            alt={"arrow_img"}
                            src={Icons.arrowDown}
                            className={`
                                c-transition
                                ${!isOpened && "c-rotate-ccw-90"}
                            `}
                        />}
                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {t("invitation_group_info_pack_name_pw", {
                                name: cardPack.name,
                            })}
                            <CaptionText className="c-text-capitalize-first ml-2">
                                {INVITATION_CARD_TYPES_TRANSLATIONS[
                                    cardPack.cardType
                                ].toLowerCase()}
                            </CaptionText>
                        </div>
                    </div>
                    <div>
                        {t("invitation_group_info_pack_count_pw", {
                            availableNum: remainingCards(),
                            totalNum: cardPack.totalCards,
                        })}
                    </div>
                </div>
            }
            body={
                !isNfcCardPack && (
                    <div className="c-bg-grey-240 rounded-lg px-3 py-3 my-3">
                        {!!cardPack.unusedCards?.length && (
                            <>
                                <div className="mb-2 pl-1">
                                    {t("mod_invitations_unused_cards")}
                                </div>
                                <div
                                    style={{
                                        gap: 8,
                                        gridTemplateColumns: "repeat(2,1fr)",
                                    }}
                                    className="c-grid"
                                >
                                    {cardPack.unusedCards?.map(
                                        ({ cardCode, shareUrl }) => {
                                            return (
                                                <ToolTipWrapper
                                                    tooltipProps={{
                                                        delayShow: 0,
                                                    }}
                                                    key={cardCode}
                                                    tooltip={t(
                                                        "mod_invitations_click_card_to_copy_share_url",
                                                    )}
                                                >
                                                    <div
                                                        className="cp rounded border p-2 text-center c-hover-box-shadow c-transition bg-white"
                                                        onClick={() =>
                                                            navigator.clipboard.writeText(
                                                                shareUrl,
                                                            )
                                                        }
                                                        key={cardCode}
                                                    >
                                                        {cardCode}
                                                    </div>
                                                </ToolTipWrapper>
                                            );
                                        },
                                    )}
                                </div>
                            </>
                        )}
                        <div className="d-flex justify-content-end align-items-center mt-3">
                            <CapsuleButton
                                onClick={() => {
                                    disassociateCardPack(cardPack.uid);
                                }}
                                style="DANGER"
                                size="SMALL"
                                text={t("mod_invitations_disassociate_pack")}
                            />
                        </div>
                    </div>
                )
            }
        />
    );

    async function disassociateCardPack(cardPackUid: string) {
        dispatch(
            invitationGroupActions.disassociatePackFromInvitationGroup(
                cardPackUid,
            ),
        );
    }

    function remainingCards() {
        return cardPack.totalCards - cardPack.usedCards;
    }
}