import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from "../_components";
import { UserLog } from "../_models/UserLog";
import { UserLogsUiHelper } from "../_helpers/UserLogsUiHelper";
import { ListedGroup } from "../_components/ListedGroup";
import { TimeHelper } from "../_helpers";
import { getUserLogIcon } from "../_assets/userLogsIcons";
import { userLogsActions } from "../_actions/userLogs.actions";
import { CapsuleButton } from "../_components/CapsuleButton";
import { USER_LOG_TYPES } from "../_constants/userLogs.constants";
import { MapHelper } from "../SettingsModule/MapHelper";
import { Selectors } from "../_reducers/app.reducer";
import { GenericLogsPage } from "../_components/GenericLogsPage";

export function SelectedUserLogs() {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    let logs                = [UserLog()];
    logs                    = useSelector(Selectors.getUserLogs);

    let selectedUserLog     = UserLog();
    selectedUserLog         = useSelector(Selectors.getUserLogsSelected);

    const selectedUserUid   = useSelector(Selectors.getUserLogsSelectedUid);
    const timezone          = useSelector(Selectors.getTimezone);
    const nextPage          = useSelector(Selectors.getUserLogsNextPage);
    const isRequesting      = useSelector(Selectors.getUserLogsIsRequesting);
    const isRequestingFirst = useSelector(Selectors.getUserLogsIsRequestingFirst);
    const { begin, end }    = useSelector(Selectors.getUserLogsDateFilter);

    //ACTION HANDLERS
    const handleRefreshLogs = () => dispatch(userLogsActions.getUserLogsFirstPage());
    const handleNextPage = () => dispatch(userLogsActions.getUserLogsNextPage(nextPage, isRequesting));
    const handleLogsDateStartChange = date =>
        dispatch(userLogsActions.setDateFilter({ begin: date }))
    ;
    const handleLogsDateEndChange = date =>
        dispatch(userLogsActions.setDateFilter({ end: date }))
    ;

    //LOCAL STATE
    const prevSelectedUserLogUid = useRef();
    const map = useRef();

    //ACTION HANDLERS
    function handleSelectUserLog(uid) {
        dispatch(userLogsActions.selectUserLog(
            uid,
            selectedUserLog,
            logs,
        ));
    }

    function handleHideLogDetails() {
        dispatch(userLogsActions.resetSelectedUserLog());
    }

    //INITIALIZATION
    useEffect(() => {
        initialize();
        
        function initialize() {
            dispatch(userLogsActions.setDateFilter({
                begin: TimeHelper.localizedStartOfTodayIsoString(timezone),
                end: TimeHelper.localizedEndOfTodayIsoString(timezone),
            }));
        }
    }, [dispatch, timezone]);

    //UPDATE
    useEffect(() => {
        if (!selectedUserUid)
            return;
        
        dispatch(userLogsActions.getUserLogsFirstPage());
    }, [dispatch, selectedUserUid, begin, end]);

    useEffect(() => {
        if (!selectedUserLog) {
            prevSelectedUserLogUid.current = undefined;
            return;
        }

        if (selectedUserLog.uid === prevSelectedUserLogUid.current)
            return;

        prevSelectedUserLogUid.current = selectedUserLog.uid;

        dispatch(userLogsActions.getUserLogAddress(selectedUserLog));
        recreateMap();
        return;
        
        function recreateMap() {
            if (map.current)
            MapHelper.removeMap(map.current)

            map.current = MapHelper.createMap({
                id: "location-map",
                latitude: selectedUserLog.latitude,
                longitude: selectedUserLog.longitude,
            });
        }

    }, [dispatch, selectedUserLog]);

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("mod_positioning_activity_title")}
            content={content()}
        />
    );

    function content() {
        return (
            <div className="h-100 d-flex flex-column">
                <GenericLogsPage
                    isThereLogs={logs.length !== 0}
                    content={userLogsList()}

                    nextPage={nextPage}
                    timezone={timezone}
                    isRequesting={isRequesting}
                    requestingFirst={isRequestingFirst}

                    startIsoString={begin}
                    endIsoString={end}

                    handleNextPage={handleNextPage}
                    handleLogsDateStartChange={handleLogsDateStartChange}
                    handleLogsDateEndChange={handleLogsDateEndChange}
                    handleRefreshLogs={handleRefreshLogs}
                />
                {selectedLogDetails()}
            </div>
        );
    }

    function userLogsList() {
        return (
            <div>
                <ListedGroup
                    elements={
                        logs.map(singleLog)
                    }
                />
            </div>
        );

        function singleLog(userLog = UserLog(), index) {
            return (
                <div
                    key={index}
                    className={`
                        px-2
                        py-1
                        
                        d-flex
                        justify-content-between
                        align-items-center

                        ${isSelectedLogFn() &&
                            "c-bg-primary-light-2"
                        }
                    `}
                >
                    <div className="d-flex align-items-center">
                        <img
                            className="c-img-1-5rem"
                            alt={"user_log_icon"}
                            src={getUserLogIcon(userLog.logType)}
                        />
                        <span className="ml-2"/>
                        <div>
                            {UserLogsUiHelper.typeServerToUi(userLog)}
                            <div>
                                {TimeHelper.isoStringToLocalTimezoneFormat(userLog.datetime)}
                            </div>
                        </div>
                    </div>
                    {button()}
                    
                </div>
            );

            function button() {
                if (isSelectedLogFn()) {
                    return hideButton();
                }

                return detailsButton();

                function detailsButton() {
                    if (!doesTypeHaveButton())
                        return;

                    return (
                        <CapsuleButton
                            text={t("mod_positioning_show_position")}
                            fillStyle={'OUTLINED'}
                            onClick={() => handleSelectUserLog(userLog.uid)}
                        />
                    );

                    function doesTypeHaveButton() {
                        const typesWithButton = [
                            USER_LOG_TYPES.EVENT_POSITION,
                            USER_LOG_TYPES.EVENT_LOCATION_ARRIVING,
                            USER_LOG_TYPES.EVENT_LOCATION_LEAVING,
                        ];
            
                        return typesWithButton.includes(userLog.logType);
                    }
                }

                function hideButton() {
                    return (
                        <CapsuleButton
                            text={t("mod_positioning_hide_position")}
                            fillStyle={'OUTLINED'}
                            onClick={handleHideLogDetails}
                        />
                    );
                }
            }

            function isSelectedLogFn() {
                return selectedUserLog && (userLog.uid === selectedUserLog.uid);
            }
        }
    }

    function selectedLogDetails() {
        if (!selectedUserLog)
            return;

        return (
            <div className="
                h-50
                flex-shrink-0
                
                d-flex
                flex-column
            ">
                <hr className="m-0"/>
                <div className="mt-1"/>
                {selectedUserLog.local_address}
                <div className="mt-1"/>
                <div className="h-100" id="location-map" />
            </div>
        );
    }
}