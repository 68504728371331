import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { VideoLog } from "../_models/VideoLog";
import { Device } from "../_models/Device";
import { DEVICE_TYPES } from "../_constants";
import { videoActions } from "../_actions/video.actions";
import { libstreamWrapper } from "./libstreamjsWrapper";
import { ContentSpace, MainPageWithButtons } from "../_components";

import { VideoPlayer } from "./VideoPlayer";
import { TimeHelper } from "../_helpers";

import playImage from "../_assets/images/play-image.png";
import videoPlaceholder from "../_assets/images/default-video-thumbnail.png";
import { GenericPaginatedScroll } from "../_components/GenericPaginatedScroll";
import { ImgWithPlaceHolder } from "../_components/ImgWithPlaceholder";
import { IsoStartEndDatePicker } from "../_components/IsoStartEndDatePicker";
import { Selectors } from "../_reducers/app.reducer";

export function VideoModule() {

    //DEPENDENCIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const nextPage          = useSelector(Selectors.getVideoNextPage);
    const videoLogs         = useSelector(Selectors.getVideoLogs);
    const date              = useSelector(Selectors.getVideoDateFilter);
    const brainUid          = useSelector(Selectors.getVideoBrainUid);
    const isConverting      = useSelector(Selectors.getVideoIsConverting);
    const isRequesting      = useSelector(Selectors.getVideoIsRequesting);
    const isRequestingFirst = useSelector(Selectors.getVideoIsRequestingFirst);

    const devices           = useSelector(Selectors.getSelectedLocationDevices);
    const timezone          = useSelector(Selectors.getTimezone);
    const locationUid       = useSelector(Selectors.getSelectedLocationUid);

    //LOCAL STATE
    const [selectedVideoLog, setSelectedVideoLog] = useState({});

    //ACTION HANDLERS
    const handleBrainClick = useCallback(brainUid => {
        dispatch(videoActions.selectBrain(brainUid));
    }, [dispatch]);
    
    const handleLogsDateStartChange = useCallback(startDate => 
        dispatch(videoActions.changeVideoFilterDate({ start: startDate }))
    , [dispatch]);

    const handleLogsDateEndChange = useCallback(endDate => 
        dispatch(videoActions.changeVideoFilterDate({ end: endDate }))
    , [dispatch]);

    const handleGetNextPage = useCallback(() => 
        dispatch(videoActions.listVideosNextPage())
    , [dispatch]);

    function handleThumbnailClick(videoLog) {
        if (isConverting)
            return;

        setSelectedVideoLog(videoLog);
    };

    //INITIALIZATION
    useEffect(() => {
        initialize();

        async function initialize() {
            libstreamWrapper.registerLibstreamModule();
            setInitialTimeRange();
            await dispatch(videoActions.selectFirstBrain());
        }

        function setInitialTimeRange() {
            dispatch(videoActions.changeVideoFilterDate({
                start: TimeHelper.getStartOfDayInTimezoneIso(
                    TimeHelper.getOneMonthAgoIsoString(),
                    timezone,
                ),
                end: TimeHelper.getEndOfDayInTimezoneIso(
                    TimeHelper.getTodayIsoString(),
                    timezone,
                ),
            }));
        }
    }, [dispatch, timezone, locationUid]);

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("video.videos")}
                    content={mainPage()}
                />
            }
        />
    );

    function mainPage() {
        return (
            <div className="
                h-100    
                d-flex
                no-gutters
            ">
                {brainList(devices, brainUid)}
                {videoColumn()}
            </div>
        );
    }

    function brainList(devices, brainUid) {
        return (
            <div className="
                    col-3

                    d-flex
                    no-gutters
                    flex-column

                    border-right
                    overflow-auto
                ">
                <div className="d-flex no-gutters flex-column">
                    <div className="
                        text-center
                        c-ft-m
                    ">
                        BRAINS
                    </div>
                    <hr className="m-0" />
                    <div className="
                        overflow-auto
                    ">
                        {buildBrainButtonList(devices, brainUid)}
                    </div>
                </div>
            </div>
        );
    }

    function buildBrainButtonList(devices = [Device()], brainUid) {
        const brains = devices.filter(device => device.type === DEVICE_TYPES.Brain);
        return brains.map(brain => brainButton(brain, brainUid));
    }

    function brainButton(brainDevice = Device(), brainUid) {
        return (
            <div key={brainDevice.uid}>
                <div
                    onClick={() => handleBrainClick(brainDevice.uid)}
                    className={`
                        mx-2
                        cp
                        my-1
                        p-0
                        rounded
                        pl-2
                        py-1
                        ${brainUid === brainDevice.uid
                            ? "c-bg-grey-230"
                            : "c-hover-240"
                        }
                    `}
                >
                    {brainDevice.description}
                </div>
                <hr className="m-0 mx-2" />
            </div>
        );
    }

    function videoColumn() {
        return (
            <div className="
                col

                d-flex
                flex-column
                no-gutters

                overflow-auto
            ">
                <VideoPlayer videoLog={selectedVideoLog}/>
                {recordingsTitle()}
                <hr className="m-0"/>
                <div className="my-1" />
                <div className="mx-2">
                    {datePickers()}
                </div>
                <div className="my-1" />
                <hr className="m-0"/>
                {videoThumbnails()}
            </div>
        );
    }

    function recordingsTitle() {
        return (
            <div className="
                text-center
                c-ft-l
                my-1

                text-uppercase
            ">
                {t("video.recordings")}
            </div>
        );
    }

    function datePickers() {
        return (
            <IsoStartEndDatePicker
                timezone={timezone}
                startIsoDate={date.start}
                endIsoDate={date.end}
                handleStartDateChange={handleLogsDateStartChange}
                handleEndDateChange={handleLogsDateEndChange}
            />
        );
    }

    function videoThumbnails() {

        return (
            <GenericPaginatedScroll
                content={content()}
                handleNextPage={handleGetNextPage}
                isRequesting={isRequesting}
                isRequestingFirst={isRequestingFirst}
                isThereContent={videoLogs.length !== 0}
                clasStyleVar={GenericPaginatedScroll.styles.VERTICAL_WRAP}
                
                noContentMessage={t("mod_video_no_videos")}
                getNextPageString={t("mod_video_get_more_videos")}
                noMoreContentString={t("mod_video_no_more_videos")}
                isLastPage={nextPage === -1}
            />
        );

        function content() {
            return videoLogs.map(videoLog => videoThumbnail(videoLog));
        }
    }

    function videoThumbnail(videoLog = VideoLog()) {
        return (
            <div
                key={videoLog.logId}
                onClick={() => handleThumbnailClick(videoLog)}
                className="
                    col-sm-6
                    col-md-4

                    d-flex
                    no-gutters
                    flex-column

                    p-1
                "
            >
                <div className="
                    position-relative
                    d-flex
                    no-gutters
                    justify-content-center
                ">
                    {videoThumbnail()}
                    {playOverlay()}
                    {hoverOverlay()}
                    {infoFooter()}
                </div>
            </div>
        );
        
        function videoThumbnail() {
            return <ImgWithPlaceHolder
                expectedImg={videoLog.videoThumbnail}
                placeholeImg={videoPlaceholder}
                className="col align-self-center"
            />
        }

        function playOverlay() {
            if (selectedVideoLog.logId !== videoLog.logId)
                return;

            return (
                <img
                    alt="video play icon"
                    className="
                        position-absolute
                        align-self-center
                    "
                    height={60}
                    src={playImage}
                />
            );
        }

        function hoverOverlay() {
            return (
                <div className="
                    position-absolute
                    w-100 h-100

                    c-hover-250
                    c-opacity-50
                " />
            );
        }

        function infoFooter() {
            return (
                <div className="
                    col
                    position-absolute
                    align-self-end

                    c-opacity-75
                    pl-1
                    c-bg-white
                ">
                    {TimeHelper.localizeIsoStringToFormat(
                        videoLog.datetime,
                        timezone,
                        TimeHelper.getTimeFormats().DATE,
                    )}
                </div>
            );
        }
    }
}