import { ReservationsSecondaryViewStore } from "../_stores/ReservationsSecondaryViewStore";

export const RESERVATION_ACTION_TYPES = {
    SELECT: "RESERVATION_SELECT",
    UPDATE_SELECTED: "RESERVATION_UPDATE_SELECTED",
    OVERWRITE_CONFIGURATION: "RESERVATION_OVERWRITE_CONFIGURATION",
    OVERWRITE_ALL_CONFIGURATIONS: "RESERVATION_OVERWRITE_ALL_CONFIGURATIONS",

    OVERWRITE_RESERVATIONS: "RESERVATION_OVERWRITE_RESERVATIONS",
    ADD_RESERVATION: "RESERVATIONS_ADD_RESERVATION",
    DELETE_RESERVATION: "RESERVATIONS_DELETE_RESERVATION",

    SET_SECONDARY_VIEW: ReservationsSecondaryViewStore.ACTION_TYPES.SET,

    UPDATE_SELECTED_SCHEDULE_DATE: "RESERVATION_UPDATE_SELECTED_SCHEDULE_DATE",
    UPDATE_SELECTED_SLOT_TIME: "RESERVATION_UPDATE_SELECTED_SLOT_TIME",
};

export const RESERVATION_SECONDARY_VIEWS = {
    SETTINGS: "SETTINGS",
    SCHEDULE: "SCHEDULE",
    SLOT_MANAGEMENT: "SLOT_MANAGEMENT",
};