export type StatusIndicatorStatusType = 'success' | 'warning' | 'error' | 'disabled';
type size = 'SMALL' | 'MEDIUM' | 'LARGE' | number;

export function StatusIndicator({
    status,
    size = "SMALL",
    className = ''
}: {
    status: StatusIndicatorStatusType;
    size?: size;
    className?: string;
}) {

    let color;

    switch (status) {
        case "success":
            color = "hsl(197, 100%, 45%)";
            break;
        case "warning":
            color = "orange";
            break;
        case "error":
            color = "red";
            break;
        case "disabled":
            color = "gray";
            break;
        default:
            color = "gray";
            break;
    }

    if (typeof size !== "number") {
        switch (size) {
            case "SMALL":
                size = 8;
                break;
            case "MEDIUM":
                size = 20;
                break;
            case "LARGE":
                size = 24;
                break;
            default:
                size = 8;
                break;
        }
    }

    return (
        <div
            style={{ backgroundColor: color, height: size, width: size }}
            className={`${className} rounded-circle border`}
        />
    );
}