import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseWidget } from "./BaseWidget";
import { LogsTable } from "../DevicesModule/LogsTable";
import { Selectors } from "../_reducers/app.reducer";
import { deviceLogsActions } from "../_actions/deviceLogs.actions";
import { TimeHelper } from "../_helpers";

export function WidgetLogs({
    editable,
    widget,
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const devices   = useSelector(Selectors.getSelectedLocationDevices);
    const logs      = useSelector(Selectors.getDeviceLogs);
    const timezone  = useSelector(Selectors.getTimezone);

    //INITIALIZATION
    useEffect(() => {
        initialize();

        async function initialize() {
            dispatch(deviceLogsActions.setFilterDateBeginEnd(
                TimeHelper.localizedStartOfTodayIsoString(timezone),
                TimeHelper.localizedEndOfTodayIsoString(timezone),
            ));
            await dispatch(deviceLogsActions.getDeviceLogsFirstPage());
        }
    }, [dispatch, timezone]);

    const { widgetId } = widget;

    return (
        <BaseWidget
            widgetId={widgetId}
            editable={editable}
            title={t("logs.logs")}
            content={
                <LogsTable
                    devices={devices}
                    logs={logs}
                    isSmall={true}
                    timezone={timezone}
            />}
        />
    );
}