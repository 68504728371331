import { Selectors } from "../../Everou/_reducers/app.reducer";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ColumnButton } from "./_components/ColumnButton";

type PMSChatFilterProps = {
    onChangeFilter: (...args: any) => void;
    className?: string;
}

export function PMSChatFilter({
    onChangeFilter,
    className = ''
}: PMSChatFilterProps) {
    // LIBRARIES
    const reservations = useSelector(Selectors.getPMSLocationReservations);
    const [chats, setChats] = useState<any>([]);

    const toggleChat = (chat) => {
        let updatedChats = chats;
        if (chats?.includes(chat)) {
            updatedChats = updatedChats.filter(c => c !== chat);
        } else {
            updatedChats = [...updatedChats, chat];
        }

        setChats(updatedChats);
        const newFilter = {
            filter: updatedChats,
        };
        onChangeFilter(newFilter);
    }

    return (
        <div
            className={`${className} c-h-min w-100 c-z-index-100 py-2`}
        >
            <h3 className='ml-1'>Emails</h3>
            <div className="overflow-auto
                c-rounded-big
                c-bg-grey-250
                c-bd-220">
                {buildChats().map((option) => (
                    <ColumnButton
                        name={option.name}
                        handleClick={() => toggleChat(option.value)}
                        active={chats.includes(option.value)}
                    />
                ))}
            </div>

        </div>
    )

    function buildChats() {
        let chats = new Array();
        reservations.forEach(element => {
            chats.push({
                name: element.email,
                value: element.uid
            });
        });

        return chats;
    }
}