export function LocationModel({
    userType,
    name,
    uid,
    invitationUid,
    allowsSubinvitations,
    timezone
} = {}) {
    return {
        userType,
        name,
        uid,
        invitationUid,
        allowsSubinvitations,
        timezone
    };
}