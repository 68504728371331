const baseUrl = "/pms";

export const PMS_API_URLS = {
    LOCATIONS:                          baseUrl + "/locations",
    USER:                               baseUrl + "/user",
    RESERVATIONS:                       baseUrl + "/reservations",
    INTEGRATIONS:                       baseUrl + "/integrations",
    TAGS:                               baseUrl + "/tags",
    LOCATION_INTEGRATIONS:              baseUrl + "/location_integrations",
    KROSS_BOOKING_PROPERTIES:           baseUrl + "/kross_booking/properties",
    KROSS_BOOKING_ROOMS:                baseUrl + "/kross_booking/rooms",
    GROUPS:                             baseUrl + "/location_groups",
    HOSTIFY_LISTINGS:                   baseUrl + "/hostify/listings",
    SMOOBU_PROPERTIES:                  baseUrl + "/smoobu/properties",
    CUSTOMIZATION_TEMPLATE:             baseUrl + "/customization_template",
    CUSTOMIZATION_UPLOAD_LOGO:          baseUrl + "/customization_upload_logo",
    CUSTOMIZATION_UPLOAD_BACKGROUND:    baseUrl + "/customization_upload_background",
    CHAT:                               baseUrl + "/chat",
    OCTORATE_TOKEN:                     baseUrl + "/octorate/token",
    OCTORATE_ACCOMMODATIONS:            baseUrl + "/octorate/accommodations",
    OCTORATE_USER_INFO:                 baseUrl + "/octorate/user_info",
    AVAIBOOK_ACCOMMODATIONS:            baseUrl + "/avaibook/accommodations",
}