import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AuditLogsHelper, AUDIT_PATHS_PARENTS } from "../_helpers/AuditLogsHelper";
import { CapsuleButton } from "../_components/CapsuleButton";
import { LocalAuditPathModel } from "../_models/LocalAuditPathModel";
import { TextInputComponent } from "../../Common/_components/TextInputComponent";
import { AuditFilterPreset } from "../_models/AuditFilterPreset";
import { auditLogsActions } from "../_actions/auditLogs.actions";
import { CustomDropdown } from "../_components/CustomDropdown";
import { filterDisabled, FILTER_DISABLED_ALL, GLOBAL_POPUPS } from "../_constants";

import deleteIcon from "../_assets/appIcons/delete-icon.svg";
import { globalActions } from "../_actions";

export function AuditLogsPathFilter({
    paths = [],
    typeFilter,
    actionPathFilter,
    locationUid,
    filterPresets = [] || [AuditFilterPreset()],
    selectedPreset,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation(); 
    const dispatch = useDispatch();

    //LOCAL STATE
    const [newPresetName, setNewPresetName] = useState("");
    const isPresetSelected = selectedPreset !== FILTER_DISABLED_ALL.key;

    //ACTION HANDLERS
    function handleChangeFilterType(type) {
        dispatch(auditLogsActions.changeFilterAuditType(type));
    }
    
    function handleChangeActionFilter(actionPath) {
        dispatch(auditLogsActions.changeFilterAuditActionPath(actionPath));
    }

    function handleAddPathFilter() {
        dispatch(auditLogsActions.addPathFilter(typeFilter, actionPathFilter));
    }

    function handleRemovePath(path) {
        dispatch(auditLogsActions.removePathFilter(path));
    }

    function handleCreateNewPreset() {
        dispatch(auditLogsActions.createAuditLogsFilterPreset(
            locationUid,
            AuditFilterPreset({
                name: newPresetName,
                auditPaths: paths,
            })
        ));

        setNewPresetName("");
    }

    function handleSelectPreset(uid) {
        dispatch(auditLogsActions.selectFilterPreset(uid, filterPresets));
    }

    function handleDeletePreset() {
        dispatch(globalActions.showPopUp(
            GLOBAL_POPUPS.REMOVE_AUDIT_FILTER_PRESET,
            {
                locationUid,
                uid: selectedPreset,
            },
        ));
    }

    //VIEW
    return (
        <div>
            <hr className="m-0 my-2"/>
            {filterPresetsSection()}
            <div className="mt-2"/>
            {addManualFilterSection()}
            {selectedPathsSection()}
            <div className="mt-2"/>
            {createNewPresetSection()}
        </div>
    );

    function addManualFilterSection() {
        return (
            <>
            <div className="
                d-flex
                align-items-center

                no-gutters
            ">
                <div className="col d-flex">
                    {typeFilterFn()}
                </div>
                <div className="col d-flex">
                    {actionFilter()}
                </div>
            </div>
            <div className="d-flex mt-2">
                <CapsuleButton
                    text={t("global_add_filter")}
                    onClick={handleAddPathFilter}
                />
            </div>
            </>
        );
    }

    function filterPresetsSection() {
        return (
            <div className="
                d-flex
                no-gutters
                align-items-center

                mt-2
            ">
                <div className="col-6">
                    {presetSelector()}
                </div>
                <div className="ml-2"/>
                {deletePresetButton()}
            </div>
        );

        function presetSelector() {
            return (
                <div className="flex-fill">
                    <CustomDropdown
                        options={buildOptions()}
                        topOptions={topOptions()}
                        sortAlphabetically={true}
                        value={selectedPreset}
                        onChange={handleSelectPreset}
                        title={t("webapp_audit_load_preset")}
                    />
                </div>
            );

            function buildOptions() {
                return filterPresets.map(preset => 
                    CustomDropdown.buildOption(
                        preset.uid,
                        preset.name,
                    )
                );
            }

            function topOptions() {
                return [
                    CustomDropdown.buildOption(
                        FILTER_DISABLED_ALL.key,
                        t("global_none"),
                    )
                ];
            }
        }

        function deletePresetButton() {
            if (!isPresetSelected)
                return;

            return (
                <CapsuleButton
                    text={t("webapp_global_remove")}
                    onClick={handleDeletePreset}
                    style={`DANGER`}
                />
            );
        }
    }

    function selectedPathsSection() {
        if (!paths.length)
            return;

        return (
            <div className="border rounded mt-2 px-2 py-1">
                {paths.map(singlePath)}
            </div>
        );

        function singlePath(path = LocalAuditPathModel(), index) {
            return (
                <div key={index}
                    className="
                        d-flex
                        my-1
                    "
                >
                    {deleteButtonFn()}
                    <span className="ml-1"/>
                    {typeName()}
                    {actionPathName()}
                </div>
            );

            function deleteButtonFn() {
                return (
                    <div className="
                        c-hover-240
                        rounded
                        px-1
                    ">
                        <img
                            className="c-img-1rem"
                            alt="delete-icon"
                            onClick={() => handleRemovePath(path.path)}
                            src={deleteIcon}
                        />
                    </div>
                );
            }

            function typeName() {
                return (
                    <span className="border rounded px-2">
                        {AuditLogsHelper.getNewParentPathUiValue(path.type)}
                    </span>
                );
            }
    
            function actionPathName() {
                if (path.actionPath === filterDisabled.number)
                    return null;
    
                return (
                    <>
                    <span className="ml-1">
                        {"->"}
                    </span>
                    <span className="border rounded px-2 ml-1">
                        {AuditLogsHelper.getAuditLogDescription(path.actionPath)}
                    </span>
                    </>
                );
            }
        }
    }

    function createNewPresetSection() {
        if (!paths.length)
            return;

        return (
            <div className="
                d-flex
                align-items-center
            ">
                {inputNameField()}
                <div className="pl-2"/>
                {createPresetButton()}
            </div>
        );

        function inputNameField() {
            return (
                <div>
                    <TextInputComponent
                        placeholder={t("webapp_audit_preset_name")}
                        value={newPresetName}
                        onChange={setNewPresetName}
                    />
                </div>
            );
        }

        function createPresetButton() {
            return (
                <div>
                    <CapsuleButton
                        text={t("webapp_audit_new_preset")}
                        onClick={handleCreateNewPreset}
                    />
                </div>
            );
        }
    }

    function allOption() {
        return CustomDropdown.buildOption(
            filterDisabled.number,
            filterDisabled.all,
        );
    }

    function typeFilterFn() {
        return (
            <CustomDropdown
                title={t("webapp_global_type")}
                topOptions={[allOption()]}
                options={typeFilterOptions()}
                onChange={handleChangeFilterType}
                value={typeFilter || filterDisabled.number}
                sortAlphabetically={true}
            />
        );

        function typeFilterOptions() {
            const result = 
                Object.values(AUDIT_PATHS_PARENTS)
                .map(type =>
                    CustomDropdown.buildOption(
                        type,
                        AuditLogsHelper.getNewParentPathUiValue(type),
                    )
                )
            ;
            return result;
        }
    }

    function actionFilter() {
        if (typeFilter === filterDisabled.number)
            return null;

        return (
            <>
            <span className="ml-1"/>
            <CustomDropdown
                title={t("global_action")}
                options={actionFilterOptions()}
                topOptions={[allOption()]}
                onChange={handleChangeActionFilter}
                value={actionPathFilter || filterDisabled.number}
                sortAlphabetically={true}
            />
            </>
        );

        function actionFilterOptions() {
            const result = 
                AuditLogsHelper.getPathsForParent(typeFilter)
                .map(path =>
                    CustomDropdown.buildOption(
                        path,
                        AuditLogsHelper.getAuditLogDescription(path),
                    )
                )
            ;
            return result;
        }
    }
}