import React from 'react';

import { CapsuleButton } from "./CapsuleButton";
import { Icons } from '../../Everou/_assets';

const RemovableItem = ({ id, name } = {}) => ({
    id,
    name
});

ListOfRemovableItems.buildItem = RemovableItem;

export function ListOfRemovableItems({
    isReadOnly = false,
    items = [] || [RemovableItem()],
    handleDeleteItem = () => {},
}) {

    //VIEW
    if (!items.length)
        return null;

    return (
        <div className="
            my-2
            px-2

            border
            rounded

            c-mh-20rem

            overflow-auto
        ">

            {items.map(singleItem)}
        </div>
    );

        function singleItem(item = RemovableItem()) {
        return (
            <div
                data-testid={item.id}
                key={item.id}
                className="
                    d-flex
                    justify-content-between
                    align-items-center

                    border
                    rounded

                    p-1
                    pl-2

                    my-2

                    bg-light
                "
            >
                <div>
                    {item.name}
                </div>

                <CapsuleButton
                    isVisible={!isReadOnly}
                    onClick={() => handleDeleteItem(item.id)}

                    icon={Icons.trash}
                    style={`DANGER`}
                    size={'SMALL'}
                />
            </div>
        );
    }
}