import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { invitationsActions } from '../_actions';
import { ListedGroup } from '../_components';
import { INVITATION_TYPES } from '../_constants';
import { TimeStringHelper } from '../_helpers/TimeStringHelper';
import { Invitation } from '../_models/Invitation';
import { Selectors } from '../_reducers/app.reducer';

const invitationArray = [Invitation()];

export function MyInvitationsComponent() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let invitations = invitationArray;
    invitations = useSelector(Selectors.getMyInvitations);
    const timezone = useSelector(Selectors.getTimezone);

    //INITIALIZATION
    useEffect(() => {
        dispatch(invitationsActions.getMyInvitations());
    }, [dispatch]);

    //VIEW

    if (!invitations.length)
        return null;

    return (
        <div>
            <ListedGroup
                elements={invitations.map(singleInvitationUi)}
                headerText={t("invitations.invitations")}
            />
        </div>
    );

    function singleInvitationUi(invitation = Invitation()) {
        return (
            <div className="
                d-flex
                no-gutters
                justify-content-between
                p-1
            ">
                <div className="col-6">
                    <div className="d-flex flex-column">
                        <div>
                            {t("global_type")}: {getWhere(invitation)}
                        </div>
                        <div className="c-text-capitalize-first">
                            {t("invitations.invitedBy")} {invitation.userSrcEmail}
                        </div>
                    </div>
                </div>
                <div className="col-6 text-right">
                    {periodString(invitation, timezone)}
                </div>
            </div>
        );
    }

    function getWhere(invitation = Invitation()) {
        if (invitation.type === INVITATION_TYPES.LOCATION)
            return t("global_location");
        
        if (invitation.type === INVITATION_TYPES.ROOM)
            return t("global_room") + " - " + invitation.roomName;
    }

    function periodString(invitation, timezone) {
        return TimeStringHelper.buildInvitationTimeString(
            invitation,
            timezone,
        );
    }
}