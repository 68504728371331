import { GenericMapperFactory } from "../../Everou/_mappers/MapperFactory";
import { DevOauth } from "../_models/DevOauth";

export const DevOauthMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverOauth) {
    return DevOauth({
        client_id:    serverOauth.client_id,
        name:   serverOauth.name,
        redirect_uris:  serverOauth.redirect_uris,
        client_secret: serverOauth.client_secret,
    });
}