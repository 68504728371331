import { HistoryHelper } from "../Everou/_helpers";
import { BudgetsModules } from "./BudgetsModules";
import { CommonStore } from "../Everou/_reducers/common.reducer";
import { StoreManager } from "../Everou/StoreManager";

export const BudgetsStoreSubscriber = {
    subscribe: SubscribeBudgetsToStore,
} 

function SubscribeBudgetsToStore() {
    StoreManager.subscribeFnToStore(persistBudgetsSelectedModuleToUrl);
}

function persistBudgetsSelectedModuleToUrl(getState) {
    HistoryHelper.pushToUrl(
        BudgetsModules.getUrlForModule(
            CommonStore.selectors.getSelectedModuleId(getState())
        )
    );
}