import { GenericLogsPage } from "../../Everou/_components/GenericLogsPage";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PMSLocationsActions } from "../_actions/PMSLocationsActions";
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { LogModel } from "../../Everou/_models/LogModel";
import { LogsTable } from "../../Everou/DevicesModule/LogsTable";
import { TimeHelper } from "../../Everou/_helpers";

export function PMSLocationLogs() {

    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // GLOBAL STATE
    let logs = [LogModel()];
    logs = useSelector(Selectors.getPMSLocationLogs);

    const nextPage = useSelector(Selectors.getPMSLocationLogsNextPage);
    const { begin, end } = useSelector(Selectors.getPMSLocationLogsDateFilter);
    const isRequesting = useSelector(Selectors.getPMSLocationLogsIsRequesting);
    const isRequestingFirst = useSelector(Selectors.getPMSLocationLogsIsRequestingFirst);
    const timezone = useSelector(Selectors.getPMSLocationSelectedTimezone);
    const locationUid = useSelector(Selectors.getPMSLocationSelectedUid);
    const devices = useSelector(Selectors.getPMSLocationDevices);

    // ACTION HANDLERS
    const handleRefreshLogs = () => dispatch(PMSLocationsActions.getLocationLogsFirstPage());
    const handleNextPage = () => dispatch(PMSLocationsActions.getLocationLogsNextPage(nextPage, isRequesting));
    const handleLogsDateStartChange = date => dispatch(
        PMSLocationsActions.setDateFilter({
            begin: date,
        })
    );
    const handleLogsDateEndChange = date => dispatch(
        PMSLocationsActions.setDateFilter({
            end: date,
        })
    );

    //INITIALIZATION
    useEffect(() => {
        intialization();

        async function intialization() {
            dispatch(PMSLocationsActions.setDateFilter({
                begin: TimeHelper.localizedStartOfTodayIsoString(timezone),
                end: TimeHelper.localizedEndOfTodayIsoString(timezone),
            }));
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid]);

    useEffect(() => {
        dispatch(PMSLocationsActions.getLocationLogsFirstPage());
    }, [dispatch, begin, end]);

    return (
        <GenericLogsPage
            handleNextPage={handleNextPage}
            handleLogsDateStartChange={handleLogsDateStartChange}
            handleLogsDateEndChange={handleLogsDateEndChange}
            handleRefreshLogs={handleRefreshLogs}

            startIsoString={begin}
            endIsoString={end}

            isRequesting={isRequesting}
            requestingFirst={isRequestingFirst}
            isThereLogs={logs.length !== 0}
            timezone={timezone}
            nextPage={nextPage}

            content={
                <LogsTable
                    devices={devices}
                    logs={logs}
                    isSmall={false}
                    timezone={timezone}
                />
            }
        />
    )
}