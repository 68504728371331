import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { BaseClientFormMainPage } from "./BaseClientFormMainPage";
import { ClientActions } from "../_actions/ClientActions";
import { CapsuleButton } from "../_components";
import { Client } from "../_models/Client";

export function EditClientView({
    client = Client(),
}) {
    
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //CLEAN UP
    useEffect(() => {
        return () => dispatch(ClientActions.selectClient(null));
    }, [dispatch]);

    //VIEW
    return (
        <BaseClientFormMainPage
            title={t("mod_clients_details_title")}
            onSave={handleSave}
            client={client}
            extraButtons={buttons()}
        />
    );

    function buttons() {
        return [
            <CapsuleButton
                text={t("webapp_global_remove")}
                onClick={handleDelete}
                style={`DANGER`}
            />
        ];
    }
    
    //ACTION HANLDERS
    function handleSave({
        uid,
        name,
        address,
        phone,
        email,
        cif,
    }) {
        dispatch(ClientActions.updateClient({
            uid,
            name,
            address,
            phone,
            email,
            cif,
        }));
    }

    function handleDelete() {
        dispatch(ClientActions.deleteClient(client.uid));
        dispatch(ClientActions.changeSecondaryView(null));
    }
}