import { ModulesActionsFactory } from "../Common/_stores/ModulesStore/ModulesActionsFactory"
import { DevelopersModules } from "./DevelopersModules"
import { DevelopersStore } from "./DevelopersStore";

export const genericModulesActions = ModulesActionsFactory({
    defaultAppModule: DevelopersModules.DEFAULT_MODULE,
});

export const DevelopersModulesActions = {
    selectModule: genericModulesActions.selectModule,
    setUpModules,
    _setUpModules: genericModulesActions.setUpModules,
};

function setUpModules() {
    return (dispatch, getState) => {
        dispatch(DevelopersModulesActions._setUpModules(
            DevelopersModules.getEnabledModuleIds(),
            DevelopersModules.getModuleIdForUrl(
                DevelopersStore.selectors.getInitialUrl(getState())
            ),
        ));
    }
}