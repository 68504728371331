import { DASHBOARD_ACTION_TYPES } from "../_constants/dashboard.constants";

const initialState = {
    selectedGroupId: null,
    settings: {
        visible: false,
        edited: false,
        componentName: null,
        props: {},
    },
    widgets: [],
    groups: [],
};

export class DashboardSelectors {
    static getGroups =          state => state.groups;
    static getSelectedGroupId = state => state.selectedGroupId;
    static getWidgets =         state => state.widgets;
    static getSettings =        state => state.settings;
}

export function dashboard(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_ACTION_TYPES.GET_GROUPS:
            return { ...state,
                groups: action.groups,
            };

        case DASHBOARD_ACTION_TYPES.SELECT_GROUP:
            return { ...state,
                selectedGroupId: action.groupId,
            };

        case DASHBOARD_ACTION_TYPES.GET_WIDGETS_SUCCESS:
            return { ...state,
                widgets: action.widgets
            };

        case DASHBOARD_ACTION_TYPES.SHOW_SETTINGS:
            return { ...state,
                settings: {
                    visible: true,
                    edited: false,
                    componentName: action.componentName,
                    props: action.props,
                },
            };

        case DASHBOARD_ACTION_TYPES.HIDE_SETTINGS:
            return { ...state,
                settings: {
                    visible: false,
                    edited: false,
                    componentName: null,
                    props: {},
                },
            };

        case DASHBOARD_ACTION_TYPES.SAVE_TEMPORARY_SETTINGS:
            return { ...state,
                settings: { ...state.settings,
                    edited: true,
                    props: {...state.settings.props,
                        ...action.props,
                    },
                },
            };

        default:
            return state;
    }
}