import { useEffect, useMemo, useState } from 'react';
import { CapsuleButton, CaptionText, MainPageWithButtons } from "../_components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../_reducers/app.reducer';
import { CustomDropdown } from '../_components/CustomDropdown';
import { ROOM_TYPES, ROOM_TYPES_NAMES, ROOM_ERRORS } from '../_constants/room.constants';
import { Room } from '../_models/Room';
import { devicesActions, roomsActions } from '../_actions';
import { useError } from '../_hooks/useError';
import { RoomsService } from '../_services/rooms.service';
import { capitalizeFirst } from '../../Common/_utils/utils';


export function RoomConfig() {

    const dispatch = useDispatch();
    const  { t } = useTranslation();

    let room = useSelector(Selectors.getSelectedRoom) ?? Room({
        type: ROOM_TYPES.NO_TYPE,
        name: "",
        devicesAmount: 0,
        uid: "",
    });

    const [type, setType] = useState(room?.type);
    const [name, setName] = useState(room?.name);
    const [hasChanges, setHasChanges] = useState(false);

    const { errorMessage, setError } = useError();
    
    useEffect(() => {
        setType(room.type);
        setName(room.name);
        setHasChanges(!room?.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room?.uid]);

    const options = useMemo(() => 
        Object.values(ROOM_TYPES).map(value => CustomDropdown.buildOption(value, capitalizeFirst(ROOM_TYPES_NAMES[value])))
    , []);

    useEffect(() => { 
        room?.uid && setHasChanges(room?.name !== name || room?.type !== type) 
    } , [name, type, room]);

    const nameSection = useMemo(
        () => (
            <div>
                <div className="d-flex justify-content-between">
                    <h3>{t("global_name")}</h3>
                    <div className="w-50">
                        <input
                            className="px-3 c-py-2px"
                            value={name}
                            placeholder={t("mod_devices_room_name_placeholder")}
                            onChange={(e) => setName(e.target.value)}
                            aria-label={"description"}
                        />
                    </div>
                </div>
                {!name && <CaptionText className='text-right mr-1 mt-1'>
                    {t("mod_devices_room_takes_type_name_default")}
                </CaptionText>}
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name],
    );

    const typeSection = useMemo(
        () => (
            <div className="d-flex justify-content-between">
                <h3>{t("global_type")}</h3>
                <div className='w-50'>
                    <CustomDropdown
                        sortAlphabetically
                        mode={"to-left"}
                        value={type}
                        isSearchable={false}
                        onChange={setType}
                        options={options}
                    />
                </div>
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options, type],
    );

    const content = (
        <div className="d-flex flex-column justify-content-between h-100">
            <div className="c-grid c-grid--small pt-4">
                {nameSection}
                {typeSection}
            </div>
            {!room?.devicesAmount && room?.uid && deleteButton()}
        </div>
    );

    const onSave = () => {
        dispatch(roomsActions.createRoom(type, name, '', room => {
            dispatch(roomsActions.selectRoomConfig(room.uid));
        }))
    };

    const onUpdate = () => {
        const updatedRoom = Room({ uid: room.uid, type, name, devicesAmount: room?.devicesAmount });
        dispatch(roomsActions.updateRoom(updatedRoom));
        dispatch(roomsActions.selectRoomConfig(updatedRoom.uid));
    };    
    
    function deleteButton() {
        const onDelete = async () => {
            try {
                await RoomsService.deleteRoom(room.uid);
                dispatch(roomsActions.deleteLocalRoom(room.uid));
                dispatch(roomsActions.selectRoomConfig(null));
                dispatch(devicesActions.changeSecondaryView(null));
            } catch (error: any) {
                if(error.message.includes(ROOM_ERRORS.ROOM_ALREADY_IN_GROUP)) {
                    setError(t('global_error') + ': ' + t("mod_devices_room_already_in_group"));
                } else {
                    setError(error.message);
                }
            }
        };

        return (
            <div>
                <div className="text-danger text-right mb-2">{errorMessage}</div>
                <div className="d-flex justify-content-end">
                    <CapsuleButton
                        text={t("common.delete")}
                        onClick={onDelete}
                        size={"SMALL"}
                        style={`DANGER`}
                    />
                </div>
            </div>
        );
    }

    const buttons = useMemo(() => {

        const onClick = room?.uid ? onUpdate : onSave;

        return [<CapsuleButton onClick={onClick} size='SMALL' isVisible={hasChanges} text={t('global_save')} />]

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room?.uid, onUpdate, onSave])

    return (
        <MainPageWithButtons
            size='SMALL'
            pageTitle={
                room?.uid
                    ? t("mod_devices_room_config")
                    : t("global_create_room")
            }
            content={content}
            buttons={buttons}
        />
    );
}