import { INVITATION_CARD_ACTION_TYPES } from "../_constants/invitationCard.constants";

const initialState = {
    card: null,
};

export class CardsSelectors {
    static getCard(state) {
        return state.card;
    }
}

export function invitationCards(state = initialState, action) {
    switch (action.type) {
        case INVITATION_CARD_ACTION_TYPES.GET_CARD_SUCCESS:
            return { ...state,
                card: action.card,
            };
        
        case INVITATION_CARD_ACTION_TYPES.REMOVE_SELECTED_CARD:
            return {
                ...state,
                card: null
            }
        
        default:
            return state;
    }
}