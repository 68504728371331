export const DownloadHelper = {
    saveBlobWithName,
    openBlobInNewWindow,
    openLinkInNewWindow,
    openOrDownloadBlob,
};

function openOrDownloadBlob(blob, isDownload = false, downloadName = "FILE") {
    isDownload
        ? DownloadHelper.saveBlobWithName(blob, downloadName)
        : DownloadHelper.openBlobInNewWindow(blob)
    ;
}

function saveBlobWithName(blob, fileName = "FILE") {
    saveFileUrlWithName(urlFromBlob(blob), fileName);
}

function openBlobInNewWindow(blob) {
    window.open(urlFromBlob(blob), '_blank');
}

function urlFromBlob(blob) {
    return window.URL.createObjectURL(blob);
}

function openLinkInNewWindow(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

function saveFileUrlWithName(fileUrl, fileName = "FILE") {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}