import { AuditFilterPreset } from "../_models/AuditFilterPreset";

export class AuditFilterPresetMapper {
    static allServerToLocal(serverFilterPresets = []) {
        return serverFilterPresets.map(serverToLocal);
    }

    static serverToLocal(serverFilterPreset) {
        return serverToLocal(serverFilterPreset);
    }

    static localToSever(localFilterPreset) {
        const { uid, ...rest } = localFilterPreset;
        return {
            uid,
            data: rest,
        };
    }
}

function serverToLocal(serverFilterPreset) {
    return AuditFilterPreset({
        uid: serverFilterPreset.uid,
        ...serverFilterPreset.data,
    });
}