import { useState } from "react";
import { ContentSpace } from "../_components";
import { User } from "../_modelsTS/User";
import { UserConfigView } from "./UserConfigView";
import { UsersListView } from "./UsersListView";

export function UsersModule() {

    const [detailedContent, setDetailedContent] = useState<any>(null);

    const onSelectUser = (user: User) => {
        setDetailedContent(<UserConfigView user={user}></UserConfigView>);
    };

    return (
        <ContentSpace
            mainContent={<UsersListView onSelectUser={onSelectUser} />}
            onGoBack={() => {
                setDetailedContent(null);
            }}
            detailedContent={detailedContent}
        />
    );
}
