import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const usersStatusService = {
    getUsersStatus,
    getUserStatusData,

    getUserStatusLogs,
};

function getUsersStatus(locationUid, begin, end) {
    const parameters =
        "?loc_uid=" + locationUid
        + (begin ? ("&begin=" + begin) : "")
        + (end ? ("&end=" + end) : "")
    ;

    return ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_USERS_STATUS,
        parameters,
    );
}

function getUserStatusData({ locationUid, userUid, begin, end }) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_USERS_STATUS_DATA,
        "?loc_uid=" + locationUid
        +"&user_uid=" + userUid
        + (begin ? ("&begin=" + begin) : "")
        + (end ? ("&end=" + end) : "")
    );
}

async function getUserStatusLogs({ locationUid, userUid, begin, end }) {
    const responseData = await ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_USER_STATUS_LOGS,
        "?loc_uid=" + locationUid
        +"&user_uid=" + userUid
        + (begin ? ("&begin=" + begin) : "")
        + (end ? ("&end=" + end) : "")
    );

    return Array.isArray(responseData) ? responseData : [];
}