export const UiPriceHelper = {
    getProductPrices,
    getTotalPriceString
};

function getProductPrices(price, items, invitationsLeft) {
    let paidItems = Math.max(items - invitationsLeft, 0);

    return {
        unitPrice: getTotalPriceString(price),
        totalPrice: getTotalPriceString(price, paidItems),
    };
}

function getTotalPriceString(price, amount = 1) {
    return fixedPriceString(price && (price * amount));
}

function fixedPriceString(value) {
    //return (value ? value.toFixed(2) : "UNDEFINED") + " €";
    return (value ? value.toFixed(2) : "0.00") + " €";
}