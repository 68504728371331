export function GenericMapperFactory(objServerToLocalFn) {
    return {
        serverToLocal,
    };

    function serverToLocal(serverData) {
        return Array.isArray(serverData)
            ? arrayServerToLocal(serverData)
            : objServerToLocal(serverData)
        ;
    }
    
    function arrayServerToLocal(serverArray = []) {
        return serverArray.map(objServerToLocalFn);
    }

    function objServerToLocal(serverObj) {
        return objServerToLocalFn(serverObj);
    }
}