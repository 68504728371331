import { combineReducers } from 'redux';
import { ClockinStore } from './ClockinStore';
import { ClockinDateFilterStore } from './ClockinDateFilterStore';
import { ClockinUserUidStore } from './ClockinUserUidStore';
import { ClockinUsersStore } from "./ClockinUsersStore";
import { ClockinFiltersStore } from "./ClockinFiltersStore";

export const clockinReducer = combineReducers({
    clockins:       ClockinStore.reducerCollection,
    dateFilter:     ClockinDateFilterStore.reducer,
    userUid:        ClockinUserUidStore.reducer,
    clockinUsers:   ClockinUsersStore.reducerCollection,
    filter:         ClockinFiltersStore.reducer,
});

let getRootState = state => state;

export const ClockinSelectors = {
    getDateFilter:      state => ClockinDateFilterStore.selectorGetBeginEnd(getRootState(state).dateFilter),
    getClockins:        state => ClockinStore.selectorGetItems(getRootState(state).clockins),
    getSelectedUserUid: state => getRootState(state).userUid,
    getClockinUsers:    state => ClockinUsersStore.selectorGetItems(getRootState(state).clockinUsers),
    getClockinsFilter:  state => getRootState(state).filter,

    setRootStateFn: (rootStateFn = state => state) => getRootState = rootStateFn,
};
