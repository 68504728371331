import React, { useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseWidget } from "./BaseWidget";
import { NULL_MAIL, invitationPeriodUiValues, invitationPermissionUiValues, invitationStatusUiValues } from "../_constants";
import { invitationsActions } from "../_actions";
import { Selectors } from "../_reducers/app.reducer";

export function WidgetInvitations({
    widget,
    editable,
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const invitations = useSelector(Selectors.getInvitations);

    //GLOBAL STATE
    useEffect(() => {
        initialize();

        async function initialize() {
            await dispatch(invitationsActions.listInvitations());
        }
    }, [dispatch]);

    //VIEW
    let { widgetId } = widget;
    return (
        <BaseWidget
            widgetId={widgetId}
            editable={editable}
            title={t("invitations.invitations")}
            content={content(invitations)}
        />
    );

    function content(invitations) {
        return (
            <table className="
                table
                c-table-fixed
                table-sm
            ">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">{t("common.user")}</th>
                        <th scope="col">{t("common.period")}</th>
                        <th scope="col">{t("common.permission")}</th>
                        <th scope="col">{t("common.status")}</th>
                    </tr>
                </thead>
                <tbody className="text-break text-leftX">
                    {invitations.map(createSingleInvitationRow)}
                </tbody>
            </table>
        );
    }

    function createSingleInvitationRow(invitation) {
        const {
            uid,
            userName,
            email,
            status,
            periodType,
            permissionType,
        } = invitation;

        return (
            <tr key={uid}>
                <td>{userNameOrEmail(userName, email)}</td>
                <td>{invitationPeriodUiValues[periodType]}</td>
                <td>{invitationPermissionUiValues[permissionType]}</td>
                <td>{invitationStatusUiValues[status]}</td>
            </tr>
        );
    }

    function userNameOrEmail(userName, email) {
        return (email !== NULL_MAIL) ? email : userName; 
    }
}