import { Integration, LocalEditableIntegration } from "../../_modelsTS/Integration";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatePublicUrlToggle from "../../InvitationsModule/CreatePublicUrlToggle";
import { CapsuleButton, Selector } from "../../_components";
import { useDispatch, useSelector } from "react-redux";
import { integrationsActions } from "../../_actions/integrations.actions";
import { Selectors } from "../../_reducers/app.reducer";
import { INVITATION_TYPES } from "../../_constants";
import { useError } from "../../_hooks/useError";
import { Icons } from "../../_assets";
import { IntegrationStore } from "../../_stores/IntegrationStore";
import { IntegrationsHelper } from "../../_helpers/IntegrationsHelper";



type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onChangeInputs?: (...args: any) => void | any;
    onToggleEditable: (...args: any) => void | any;
    onSetInitialStatus: (...args: any) => void | any;
    groups: { [key: number]: any[]; }; // To be typed asap
    onDelete: (index: number) => void;
    index: number;
    linkMode?: 'link' | 'ical';
}

export function IntegrationInput({ integration, onDelete, index, onSetInitialStatus, groups, linkMode = 'link', onToggleEditable }: IntegrationInputProps) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const buildOptions = useCallback((availableGroups: any[] = []) => {
            return availableGroups.map((group) => ({ value: group.id, label: group.name, isDisabled: group.isDisabled })) ?? [];
    }, []);

    const integrations = useSelector(IntegrationStore.selectorGetItems);

    const selectedIntegrationUid: string = useSelector(IntegrationStore.selectorGetSelectedId);

    const buildGroupGroupedOptions = useCallback((groups: { [key: number]: any[] }) => {
        return [
            {
                label: t("global_typeof_location"),
                options: buildOptions(groups[INVITATION_TYPES.LOCATION]),
            },
            {
                label: t("global_typeof_room"),
                options: buildOptions(groups[INVITATION_TYPES.ROOM]),
            },
        ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allGroups = useMemo(() => [...(groups[INVITATION_TYPES.LOCATION] ?? []), ...(groups[INVITATION_TYPES.ROOM]) ?? []], [groups]);
    const baseIntegration = useMemo(() => integration, [integration]);
    const [linkValue, setLinkValue] = useState(integration.link ?? '');
    const [availableGroups, setAvailableGroups] = useState(buildGroupGroupedOptions(groups));
    const integrationGroups = integration.groups || [];
    const [selectedGroupsLocal, setSelectedGroupsLocal] = useState(integrationGroups.map(({id}) => ({value: id})) ?? []);
    const [createPublicUrl, setCreatePublicUrl] = useState(integration.createPublicUrl);
    const { errorMessage, setError, hasError, disableError } = useError();
    const locationUid = useSelector(Selectors.getSelectedLocationUid) ?? '';

    const onSeeReservations = useCallback(async (integrationUid) => {
        await dispatch(integrationsActions.listReservations(locationUid, integrationUid));
        dispatch(IntegrationStore.actionSelectItem(integrationUid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUid]);


    const onChangeGroups = useCallback(selectedGroups => {
        if(selectedGroups.length === 0) {
            setAvailableGroups(buildGroupGroupedOptions(groups));
        } else {
            const firstSelectedGroup = selectedGroups[0];
            const accessType = allGroups.find(({id}) => firstSelectedGroup.value === id)?.invitation.type;
            const isLocationType = accessType === INVITATION_TYPES.LOCATION;
            const availableGroups = { [INVITATION_TYPES.LOCATION]: groups[INVITATION_TYPES.LOCATION].map(group => ({ ...group, isDisabled: firstSelectedGroup.id !== group.id })),
                [INVITATION_TYPES.ROOM]: groups[INVITATION_TYPES.ROOM].map(group => ({...group, isDisabled: isLocationType}))
            }
            setAvailableGroups(buildGroupGroupedOptions(availableGroups));
        }
        setSelectedGroupsLocal(selectedGroups);
    }, [buildGroupGroupedOptions, groups, allGroups]);

    
    useEffect(() => { 
        setLinkValue(integration.link ?? '');
        setCreatePublicUrl(integration.createPublicUrl);

        const selected = buildOptions(allGroups.filter(({ id }) => integrationGroups.map(({id}) => id).includes(id)));
        onChangeGroups(selected);
     }, [allGroups, buildOptions, groups, integration, onChangeGroups]);


    const canSave = useMemo(() => {

        if(hasError) return false;

        const baseGroups = baseIntegration.groups || [];

        return (!!linkValue && ((linkValue !== baseIntegration.link) ||
            !baseGroups.every(({id}) =>
                selectedGroupsLocal.map(({ value }) => value).includes(id),
            ) || createPublicUrl !== integration.createPublicUrl 
        ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseIntegration.groups, baseIntegration.link, createPublicUrl, integrations, integration.createPublicUrl, linkValue, selectedGroupsLocal]);
    

    const onSave = useCallback(async () => {

        if(!linkValue) {
            setError(t('mod_integrations_link_required'));
            return;
        }

        const editedIntegration: Integration = {
            link: linkValue,
            company: integration.company,
            createPublicUrl,
            groups: selectedGroupsLocal.map(({ value }) => ({ id: value })),
            type: integration.type,
            uid: integration.uid
        }
        if(locationUid) {
            try {
                await dispatch(integrationsActions.createIntegration(locationUid, editedIntegration));
            } catch (err: any) {
                setError(err.message)
            }
        }
    }, [createPublicUrl, dispatch, setError, integration.company, integration.type, integration.uid, linkValue, locationUid, selectedGroupsLocal, t])


    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            <div
                className="col-span-full"
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "3fr 2fr",
                }}
            >
                <input
                    className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                    type="text"
                    readOnly={!integration.editable}
                    value={linkValue}
                    placeholder={t(linkMode === 'link' ? "global_link" : "global_ical_link")}
                    onChange={(e) => {
                        setLinkValue(e.target.value?.trim());
                        if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                            setError(t('mod_integrations_already_have_integration_with_this_link'));
                        } else {
                            disableError();
                        }
                    }}
                />
            </div>
            <div
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "1fr 1fr",
                }}
                className="col-span-full d-flex justify-content-between"
            >
                <div className="c-min-w-40">

                <Selector
                    hideSelectedOptions={false}
                    placeholder={t("mod_invitations_select_group")}
                    isMulti
                    value={selectedGroupsLocal}
                    onChange={(selectedGroups: any) =>
                        onChangeGroups(selectedGroups)
                    }
                    isDisabled={!integration.editable}
                    options={availableGroups}
                    />
                    </div>
                <CreatePublicUrlToggle
                    boldedName={false}
                    initialState={createPublicUrl}
                    readOnly={!integration.editable}
                    onChange={setCreatePublicUrl}
                />
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {integration.uid &&
                        <CapsuleButton
                            isEnabled={integration.uid !== selectedIntegrationUid}
                            onClick={() => onSeeReservations(integration.uid)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            size="SMALL"
                            text={t('mod_integrations_see_reservations')}
                        />
                    }
                </div>
                <div className="d-flex">
                    {
                        !!integration.uid &&
                        <CapsuleButton
                            size="SMALL"
                            classNameExtra="mr-2"
                            style={!integration.editable ? 'PRIMARY' : 'INFO'}
                            onClick={() => integration.editable ? onSetInitialStatus(index) : onToggleEditable(index)}
                            text={t(!integration.editable ?  "global_edit" : 'global_cancel')}
                        />
                    }
                    {(integration.editable || !integration.uid) &&
                        <CapsuleButton
                            onClick={() => onDelete(index)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            style="DANGER"
                            size="SMALL"
                            icon={Icons.trash}
                        />
                    }
                    <CapsuleButton
                        size="SMALL"
                        isEnabled={canSave}
                        onClick={onSave}
                        text={t("global_save")}
                    />
                </div>
            </div>
                    {errorMessage && (
                        <div className="text-danger mr-2">{errorMessage}</div>
                    )}
        </div>
    );
}