import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";

import { EntriesActions } from "../_actions/EntriesActions";
import { Entry } from "../_models/Entry";
import { TableEntryFields } from "./TableEntryFields";
import debounce from 'lodash/debounce';

export function EditableEntryRow({
    entry = Entry(),
    products,
    isNameVisible = false,
}) {

    //LIBRARIES
    const dispatch = useDispatch();

    //LOCAL STATE
    const isMounting = useRef(true);

    const [productType, setProductType] = useState(entry && entry.product && entry.product.productType);
    const [amount, setAmount] = useState(entry && entry.amount);
    const [price, setPrice] = useState(entry && entry.product.price);
    const [discount, setDiscount] = useState(entry && entry.discount);
    const [name, setName] = useState(setInitialName(entry));

    function setInitialName(entry = Entry()) {
        if (!entry)
            return "-";

        return entry.product.isNameEditable
            ? entry.product.name
            : "-"
        ;
    }

    const entryUid = entry.uid;

    //ACTION HANDLERS
    function handleDeleteEntry() {
        dispatch(EntriesActions.deleteEntry(entry.uid));
    }

    const debouncedUpdateEntry = useMemo(() => {
        return debounce(updateEntry, 500);

        function updateEntry({
            entryUid,
            amount,
            discount,
            price,
            name,
            productType,
        }) {
            dispatch(EntriesActions.updateEntry({
                uid: entryUid,
                amount,
                discount,
                productPrice: price,
                productName: name,
                productType,
            }));
        }
        
    }, [dispatch]);

    //SYNCHRONIZATION
    useEffect(() => {
        if (isMounting.current || !entryUid)
            return;

        debouncedUpdateEntry({
            entryUid,
            amount,
            discount,
            price,
            name,  
        });

    }, [
        debouncedUpdateEntry,

        entryUid,
        amount,
        discount,
        price,
        name,
    ]);

    //PRODUCT TYPE MUST BE UPDATED INDEPENDENTLY
    //TO ENSURE OTHER FIELD RESETTING
    useEffect(() => {
        if (isMounting.current || !entryUid)
            return;

        debouncedUpdateEntry({
            entryUid,
            productType,
        });
    }, [
        debouncedUpdateEntry,

        entryUid,
        productType,
    ]);

    useEffect(() => {
        if (isMounting.current)
            isMounting.current = false;
    }, []);

    //VIEW
    return (
        <TableEntryFields
            isArchived={!entry.product.uid}
            onDeleteEntry={handleDeleteEntry}
            products={products}

            isNameVisible={isNameVisible}
            isNameEditable={entry.product.isNameEditable}
            customName={name}
            name={entry.product.name}
            setName={setName}

            productType={productType}
            setProductType={setProductType}

            amount={amount}
            setAmount={setAmount}

            discount={discount}
            setDiscount={setDiscount}

            unitGrossPrice={price}
            isPriceEditable={entry.product.isPriceEditable}
            setPrice={setPrice}
            
            grossPrice={entry.grossPrice}
            totalVat={entry.vat}
            totalNetPrice={entry.netPrice}
        />
    );
}