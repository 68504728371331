import React, { useRef, useEffect } from "react";
import { CapsuleButton } from "./CapsuleButton";

GenericPaginatedScroll.styles = {
    VERTICAL_NO_WRAP: "VERTICAL_NO_WRAP",
    VERTICAL_WRAP: "VERTICAL_WRAP",
};

export function GenericPaginatedScroll({
    content,
    handleNextPage,

    isRequesting,
    isRequestingFirst,
    isThereContent,
    isLastPage = false,

    clasStyleVar,
    
    noContentMessage,
    getNextPageString = "GET NEXT PAGE PLACEHOLDER",
    noMoreContentString = "NO MORE CONTENT PLACEHOLDER",
}) {

    //LOCAL STATE
    const containerRef = useRef();

    //UPDATES
    useEffect(() => {
        requestNextIfPageNotFull();
    });

    function requestNextIfPageNotFull() {
        if (isPageNotFull())
            handleNextPage();

        function isPageNotFull() {
            return containerRef.current.scrollHeight === containerRef.current.clientHeight;
        }
    }

    //VIEW
    return (
        <div
            onScroll={handleScrollEnd}
            ref={containerRef} 
            className={`
                w-100
                h-100

                d-flex

                overflow-auto

                ${classStyle()}
            `}>
            {contentFn()}
            {footerElement()}
        </div>
    );

    function footerElement() {
        if (isRequesting)
            return spinner();

        if (!isThereContent)
            return noContentFound();

        if (isLastPage)
            return lastPageMessage();
        
        return getNextPageButton();
    }

    function classStyle() {
        switch (clasStyleVar) {
            case GenericPaginatedScroll.styles.VERTICAL_WRAP:
                return "flex-wrap";
                
            case GenericPaginatedScroll.styles.VERTICAL_NO_WRAP:
            default:
                return "flex-column";
        }
    }

    function contentFn() {
        if (isRequestingFirst || !isThereContent)
            return null;

        return content;
    }

    function noContentFound() {
        return (
            <span className="
                w-100
                pt-2
                text-center
            ">
                {noContentMessage}
            </span>
        );
    }

    function spinner() {
        return (
            <div className="
                w-100
                pt-2
                text-center
            ">
                <div className="spinner-border c-text-primary"/>
            </div>
        );
    }

    function lastPageMessage() {
        return (
            <div className="w-100 d-flex my-2">
                <div className="mx-auto">
                    {noMoreContentString}
                </div>
            </div>
        );
    }

    function getNextPageButton() {
        return (
            <div className="w-100 d-flex my-2">
                <div className="mx-auto">
                    <CapsuleButton
                        text={getNextPageString}
                        onClick={handleNextPage}
                        size={'SMALL'}
                    />
                </div>
            </div>
        );
    }

    //HANLDERS
    function handleScrollEnd() {
        if (isEndOfScroll())
            handleNextPage();

        function isEndOfScroll() {
            return (
                containerRef.current.scrollHeight - containerRef.current.scrollTop
                === containerRef.current.clientHeight
            );
        }
    }
}