import { PMSServerChatMessage, PMSChatMessage } from "../_models/PMSChat";

export class PMSChatMapper {
    static allServerToLocal(serverMessages: PMSServerChatMessage[]) {
        return serverMessages.map(this.serverToLocal);
    }

    static serverToLocal({
        created_on,
        message,
        email
    }: PMSServerChatMessage): PMSChatMessage {
        return {
            date: created_on,
            message,
            email
        };
    }
}