import React from "react";
import { useTranslation } from "react-i18next";

import { PROTECT_STATES } from "../_constants";
import { deviceIconForType } from "../_assets";
import { GenericSingleDevice } from "./GenericSingleDevice";
import { ProtectMessage } from "./ProtectMessage";

import protectOffIcon from "../_assets/appIcons/icon_protect_off_black.svg";
import { ListedGroup } from "../_components/ListedGroup";

export function IncorrectDevices({ incorrectDevices }) {
    const { t } = useTranslation();

    return (
        incorrectDevices.length !== 0
        ? incorrectDevicesDiv()
        : <ProtectMessage
            icon={protectOffIcon}
            textA={t("protect.everythingIsInOrder")}
            textB={t("protect.protectModeCanBeEnabled")}
        />
    );

    function incorrectDevicesDiv() {
        return (
            <div className="
                d-flex
                no-gutters
                flex-column
                
                mb-2
                overflow-auto
            ">
                <ListedGroup
                    headerText={t("protect.devicesInIcorrectState")}
                    elements={uiDevices()}
                />
            </div>
        );
    }

    function uiDevices() {
        return (
            incorrectDevices
            .sort((a, b) => a.description.localeCompare(b.description))
            .map(device => GenericSingleDevice({
                key: device.uid,
                icon: deviceIconForType(device.type),
                mainText: device.description,
                secondaryText: `${t("common.in")} "${device.room_name}"`,
                statusText: statusMessage(device),
            }))
        );
    }

    function statusMessage(device) {
        switch (device.protectState) {
            case PROTECT_STATES.OBSOLETE:
                return (
                    t("alerts.connectionLost")
                );

            case PROTECT_STATES.WRONG_STATUS:
                return (
                    device.statusLabel
                );
            default:
                return "UNKNOWN STATE";
        }
    }

}