import { Selectors } from '../../_reducers/app.reducer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { invitationsActions } from '../../_actions';
import { CapsuleButton } from '../../_components/CapsuleButton';
import {
    INVITATION_STATUS,
    NULL_MAIL,
} from '../../_constants';
import { Invitation } from '../../_models/Invitation';

const fn = () => {};

export function EditInvitationButtonsComponent({
    invitation = Invitation(),
    isEdited,
    isAllValid = false,
    onSaveExtra = fn,
    sendButtonTestID,
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    const isSuperguest = useSelector(Selectors.getIsSupeguest);

    const {
        email,
        uid,
        status,
        newInvitation,
    } = invitation;

    //ACTION HANDLERS
    function handleResendInvitation(uid) {
        dispatch(invitationsActions.resendInvitation(uid));
    }

    function isValidated() {
        onSaveExtra();

        if (!isAllValid)
            console.error("field not valid");

        return isAllValid;
    }

    function handleUpdateSelectedInvitation() {
        if (!isValidated())
            return;

        dispatch(invitationsActions.updateServerInvitation(invitation));
    }

    function handleSendNewInvitation() {
        if (!isValidated())
            return;

        dispatch(invitationsActions.sendNewInvitation(invitation, locationUid, isSuperguest));
    }

    function handleDiscardChangesToInvitation() {
        dispatch(invitationsActions.discardLocalChanges());
    }

    //VIEW
    return isEdited
        ? editingButtons()
        : [resendButton()]
    ;

    function editingButtons() {
        return ([
            newInvitation
                ? sendButton()
                : saveButton()
            ,
            discardButton(),
        ]);

        //
        function sendButton() {
            return (
                <CapsuleButton
                    testId={sendButtonTestID}
                    text={t("common.send")}
                    isEnabled={isAllValid}
                    onClick={handleSendNewInvitation}
                />
            );
        }

        function saveButton() {
            return (
                <CapsuleButton
                    isEnabled={isAllValid}
                    text={t("common.save")}
                    onClick={handleUpdateSelectedInvitation}
                />
            );
        }
        
        function discardButton() {
            return (
                <CapsuleButton
                    text={t("common.discard")}
                    onClick={handleDiscardChangesToInvitation}
                />
            );
        }
    }
    
    function resendButton() {
        return status === INVITATION_STATUS.REQUESTED && email !== NULL_MAIL
            ? <CapsuleButton
                text={t("common.resend")}
                onClick={() => handleResendInvitation(uid)}
            />
            : null
        ;
    }
}