import React from "react";

export function ValidationWarnings({
    showWarnings = true,
    warningTexts = [],
}) {
    if (!warningTexts.length || !showWarnings)
        return null;

    return (
        <div className="c-z-1060 position-absolute top-100">
            {getUiWarnings()}
        </div>
    );

    function getUiWarnings() {
        return warningTexts.map((warning, index) =>
            <SingleWarning text={warning} key={index} />
        );
    }
}

function SingleWarning({ text }) {
    return (
        <div className="
            c-new-ft-s
            text-danger

            bg-white
            rounded
            c-box-shadow
            border
            border-danger

            px-2
        ">
            {text}
        </div>
    );
}