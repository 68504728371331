import React from "react";
import { useTranslation } from "react-i18next";
import { SegmentedControl } from "../../../Common/_components";
import { invitationTypeUiValues, INVITATION_TYPES } from "../../_constants";
import { Invitation } from "../../_models/Invitation";
import { InvitationsHelper } from "../../_helpers";
import { Room } from "../../_models/Room";
import { RoomSelector } from "./RoomSelector";
import { useAccordionButton } from "react-bootstrap";

const testIDs = {
    root: "access-selector",
    accessDropdown: "access-selector-dropdown",
};

AccessSelectorComponent.testIDs = testIDs;

export function AccessSelectorComponent({
    rooms = [] || [Room()],
    invitation = Invitation(),
    isReadOnly = false,
    roomsOnly = false,
    onChangeInvitationType,
    onSelectRoom
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const decoratedOnClick = useAccordionButton(0);

    //VIEW
    const { deviceName, type, objectUid } = invitation;

    return (
        <div className={`${isReadOnly ? 'd-flex justify-content-between' : ''}`} data-testid={testIDs.root}>
            <h3>{t("global_access_to")}</h3>

            {isReadOnly ? readOnlyData() : accessSelector()}
        </div>
    );

    function readOnlyData() {
        return (
            <div className="text-right">
                {invitationTypeUiValues[type]}
                {": "}
                {deviceName}
            </div>
        );
    }

    function accessSelector() {

        const getUiAvailableInvitationTypes = (roomsOnly) => {
            const availableInvitationTypes =
                InvitationsHelper.getAvailableInvitationTypes(roomsOnly);

            return availableInvitationTypes.map((invitationType) => ({
                label: invitationTypeUiValues[invitationType],
                value: invitationType,
                onClick:
                    type === INVITATION_TYPES.ROOM
                        ? decoratedOnClick
                        : () => {},
            }));
        };

        return (
            <>
                <div className="ml-2" />
                <SegmentedControl
                    onSelect={onChangeInvitationType}
                    segments={getUiAvailableInvitationTypes(roomsOnly)}
                />

                {type === INVITATION_TYPES.ROOM && (
                    <>
                        <div className="ml-2 mt-2" />
                        <div className="mb-2">
                            <RoomSelector
                                rooms={rooms}
                                selectedRoomUid={!!objectUid ? objectUid : rooms[0].uid}
                                onSelectRoom={onSelectRoom}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }

    
}
