export function GenericArrayFactory(ID) {
    const typeUpdate = "ARRAY_UPDATE_" + ID;
    const typeSelectItem = "ARRAY_SELECT_ITEM_" + ID;

    const intialState = {
        items: [],
        selected: null,
        hydrated: false,
    };

    return {
        reducer,
        typeUpdate,
        typeSelectItem,
        actionUpdate,
        actionSelectItem,
        selectorGetArray,
        selectorGetSelected,
    };

    function selectorGetArray(state) {
        return state.items;
    }

    function selectorGetSelected(state) {
        return state.selected;
    }

    function actionUpdate(items = []) {
        return {
            type: typeUpdate,
            items,
        };
    }

    function actionSelectItem(selected) {
        return {
            type: typeSelectItem,
            selected,
        };
    }

    function reducer(state = intialState, action) {
        if (!state.hydrated) {
            state = {
                ...intialState,
                ...state,
                hydrated: true,
            };
        }

        switch (action.type) {
            case typeUpdate:
                return {
                    ...state,
                    items: action.items,
                };

            case typeSelectItem:
                return {
                    ...state,
                    selected: action.selected,
                };

            default:
                return state;
        }
    }
}