import { Integration, IntegrationCompany, IntegrationReservationStatusLabel, integrationsByCompany, LinkType } from "../_modelsTS/Integration";
import { IntegrationApp } from "../_models/IntegrationApp";
import { useTranslation } from "react-i18next";
import { ContentSpace, CustomCard, MainPageWithButtons } from "../_components";
import { AvaibookIntegration } from "./platforms/AvaibookIntegration";
import { HostifyIntegration } from "./platforms/HostifyIntegration";
import { KrossBookingIntegration } from "./platforms/KrossBookingIntegration";
import { ThreeAngularIntegration } from "./platforms/ThreeAngularIntegration";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { invitationGroupActions } from "../_actions/invitationGroup.actions";
import { Selectors } from "../_reducers/app.reducer";
import { integrationsActions } from "../_actions/integrations.actions";
import { useMemo } from "react";
import { INVITATION_TYPES } from "../_constants";
import { IntegrationStore } from "../_stores/IntegrationStore";
import { ReadOnlyDataRow } from "../_components";
import { IntegrationsColumn } from "./components/IntegrationsColumn";
import { SmoobuIntegration } from "./platforms/SmoobuIntegration";
import { AvantioIntegration } from "./platforms/AvantioIntegration";
import { BookiproIntegration } from "./platforms/BookiproIntegration";

export function IntegrationsModule() {

    // INITIALIZATION
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    const integrations = useSelector(Selectors.getIntegrations);
    const avaibookIntegrations = integrationsByCompany(integrations, IntegrationCompany.AVAIBOOK);
    const hostifyIntegrations = integrationsByCompany(integrations, IntegrationCompany.HOSTIFY);
    const octorateIntegrations = integrationsByCompany(integrations, IntegrationCompany.OCTORATE);
    const krossBookingIntegrations = integrationsByCompany(integrations, IntegrationCompany.KROSS_BOOKING);
    const threeAngularIntegrations = integrationsByCompany(integrations, IntegrationCompany.THREE_ANGULAR);
    const smoobuIntegrations = integrationsByCompany(integrations, IntegrationCompany.SMOOBU);
    const avantioIntegrations = integrationsByCompany(integrations, IntegrationCompany.AVANTIO);
    const bookiproIntegrations = integrationsByCompany(integrations, IntegrationCompany.BOOKIPRO);

    const currentIntegrationApps = [
        IntegrationApp({id:0, company:IntegrationCompany.AVAIBOOK, link: LinkType.ICAL, installed:avaibookIntegrations[0].uid}),
        IntegrationApp({id:1, company:IntegrationCompany.HOSTIFY, link: LinkType.WEBHOOK, installed:hostifyIntegrations[0].uid}),
        IntegrationApp({id:2, company:IntegrationCompany.OCTORATE, link: LinkType.WEBHOOK, installed:octorateIntegrations[0].uid}),
        IntegrationApp({id:3, company:IntegrationCompany.KROSS_BOOKING, link: LinkType.API_ENDPOINT, installed:krossBookingIntegrations[0].uid}),
        IntegrationApp({id:4, company:IntegrationCompany.THREE_ANGULAR, link: LinkType.DEVELOPERS, installed: threeAngularIntegrations[0].uid}),
        IntegrationApp({id:5, company:IntegrationCompany.SMOOBU, link: LinkType.WEBHOOK, installed:smoobuIntegrations[0].uid}),
        IntegrationApp({id:6, company:IntegrationCompany.AVANTIO, link: LinkType.ICAL, installed:avantioIntegrations[0].uid}),
        IntegrationApp({id:4, company:IntegrationCompany.BOOKIPRO, link: LinkType.DEVELOPERS, installed: bookiproIntegrations[0].uid}),
    ]


    const groups = useSelector(Selectors.getInvitationGroups);
    const groupsByType = useMemo<{ [key: number]: any[] }>(() => {
        const res =  groups.reduce((acc: { [key: number]: any[] }, curr) => {
           return {
               ...acc,
               [curr.invitation.type]: [...acc[curr.invitation.type], curr]
           } 
        }, { [INVITATION_TYPES.LOCATION]: [], [INVITATION_TYPES.ROOM]: [] });
        return res;
    }, [groups]);

    const secondaryView = useSelector(Selectors.getIntegrationsModuleSecondaryView);
    const selectedIntegration: Integration = useSelector(IntegrationStore.selectorGetSelectedItem);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (locationUid) {
            dispatch(integrationsActions.listIntegrations(locationUid));
            dispatch(invitationGroupActions.getGroupsForLocation(locationUid));
        }
        return () => { closeViewFn(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid])

    const closeViewFn = useCallback(() => {
        dispatch(integrationsActions.changeSecondaryView(null));
        dispatch(IntegrationStore.actionSelectItem(null));
    }, [dispatch]);

    const detailedContent = useMemo(() => {
        if(!selectedIntegration) {
            switch(secondaryView) {
                case IntegrationCompany.AVAIBOOK:
                    return (
                        <AvaibookIntegration
                            groups={groupsByType}
                            integrations={avaibookIntegrations}
                        />
                    );

                case IntegrationCompany.HOSTIFY:
                    return (
                        <HostifyIntegration
                            groups={groupsByType}
                            integrations={hostifyIntegrations}
                        />
                    )

                case IntegrationCompany.KROSS_BOOKING:
                    return (
                        <KrossBookingIntegration
                            groups={groupsByType}
                            integrations={krossBookingIntegrations}
                        />
                    );

                case IntegrationCompany.THREE_ANGULAR:
                    return (
                        <ThreeAngularIntegration
                            groups={groupsByType}
                            integrations={threeAngularIntegrations}
                        />
                    );

                case IntegrationCompany.SMOOBU:
                    return (
                        <SmoobuIntegration
                            groups={groupsByType}
                            integrations={smoobuIntegrations}
                        />
                    );

                case IntegrationCompany.AVANTIO:
                    return (
                        <AvantioIntegration
                            groups={groupsByType}
                            integrations={avantioIntegrations}
                        />
                    );

                case IntegrationCompany.BOOKIPRO:
                    return (
                        <BookiproIntegration
                            groups={groupsByType}
                            integrations={threeAngularIntegrations}
                        />
                    );
    
            }
            return null;
        }

        if(selectedIntegration.reservations?.length === 0) {
            return <MainPageWithButtons
                //dataTestId={testIDs.root}
                pageTitle={t("mod_integrations_reservations_title")}
                content={
                    <div className="c-h-100 d-flex justify-content-center align-items-center">
                        {t('mod_integrations_reservations_not_yet')}
                    </div>
                }
                buttons={undefined}
            />

        }

        return <MainPageWithButtons
                    //dataTestId={testIDs.root}
                    pageTitle={t("mod_integrations_reservations_title")}
                    content={
                        <div className="py-4 c-grid">
                            {selectedIntegration.reservations?.map(( reservation, index ) =>
                            <CustomCard banner={
                                IntegrationReservationStatusLabel[reservation.status]
                            } key={reservation.uid}>
                                <ReadOnlyDataRow
                                    key={reservation.uid + '1'}
                                    name={t('global_email')}
                                    data={reservation.email} />
                                <ReadOnlyDataRow
                                    key={reservation.uid + '2'}
                                    name={t('mod_integrations_reservation_id')}
                                    data={reservation.reservationId} />
            
                                <ReadOnlyDataRow
                                    key={reservation.uid + '3'}
                                    name={t('mod_integrations_reservation_start')}
                                    data={reservation.reservationStart} />
            
                                <ReadOnlyDataRow
                                    key={reservation.uid + '4'}
                                    hasSeparators={false}
                                    name={t('mod_integrations_reservation_end')}
                                    data={reservation.reservationEnd} />
            
                            </CustomCard>
                            )}
                        </div>
                    }
                    buttons={undefined}
                />
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryView, selectedIntegration?.reservations])

    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    content={integrationsContent()}
                    pageTitle={t("mod_integrations_title")}
                />
            }
            onGoBack={closeViewFn}
            detailedContent={
                detailedContent
            }
        />

    );

    function integrationsContent() {
        return (
            <div className="c-default-grid">
                <IntegrationsColumn
                    integrations={currentIntegrationApps}
                />
            </div>
        );
    }
}
