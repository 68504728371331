import ffmpegjs from "./ffmpeg-mp4";

export const ffmpegWrapper = {
    rawToMp4,
};

function rawToMp4(rawVideoArrayBuffer, rawAudioArrayBuffer, callbackFn) {
    ffmpegjs({
        MEMFS: [
            { name: "audioFile", data: rawAudioArrayBuffer },
            { name: "videoFile", data: rawVideoArrayBuffer },
        ],

        arguments: [
        "-framerate", "30",
        "-i", "videoFile",
        
        "-f", "s16le",
        "-ar", "16000",
        "-i", "audioFile",
        
        // "-af", "asetrate=16000*0.85",
        // "-af", "atempo=0.86",
        // "-af", "asetrate=44100*0.5,aresample=44100",

        "-c:v", "copy",
        "-c:a", "aac",
        // "-b:a", "128k",
        "output.mp4"
        ],

        // Ignore stdin read requests.
        stdin: function () { },
        onfilesready: (e) => { callbackFn(e.MEMFS[0].data); },
    });
}