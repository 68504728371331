import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    ContentSpace, MainPageWithButtons
} from "../../Common/_components";
import { deviceIconForType } from "../../Everou/_assets";
import ToolTipWrapper from "../../Everou/_components/ToolTipWrapper";
import { DevelopersStore } from "../DevelopersStore";
import { DevRoomsActions } from "../_actions/DevRoomsActions";
import { DevRoom } from "../_models/DevRoom";


export function DevLocationsModule() {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    //GLOBAL STATE
    const rooms = useSelector(DevelopersStore.selectors.getRooms);
    const locations = getLocationsInfo(rooms);

    //ACTION HANDLERS
    function handleCopyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    //INITIALIZATION
    useEffect(() => {
        dispatch(DevRoomsActions.list());
    }, [dispatch]);

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    size={"X_LARGE"}
                    pageTitle={t("mod_dev_locations_title")}
                    content={content()}
                />
            }
        />
    );

    function content() {
        if(!rooms.length) {
            return noLocationsMessage()
        }
        return (
            <div
                style={{ display: "grid", rowGap: "12px" }}
                className="w-100 pr-2 py-3"
            >
                {locations.map(LocationUi)}
            </div>
        );
    }

    function LocationUi({ uid, name, timezone, rooms }) {
        return (
            <ToolTipWrapper
                key={uid}
                tooltipProps={getTooltipProps(uid)}
                tooltip={getTooltip({name})}
            >
                <div
                    key={uid}
                    className="c-hover-250 w-100 cp mb-4 rounded-lg"
                >
                    <div
                        className="d-flex justify-content-between mb-2 rounded-lg px-4 pt-3 pb-1"
                    >
                        <div>
                            <h4 className="font-weight-bold mr-2 m-0">
                                {name}
                            </h4>
                            {Uid(uid)}
                        </div>
                        <small className="text-secondary d-block">
                            {timezone}
                        </small>
                    </div>

                    <div
                        className="px-4 py-2"
                        style={{ display: "grid", rowGap: "12px" }}
                    >
                        {rooms.map(RoomUi)}
                    </div>
                </div>
            </ToolTipWrapper>
        );
    }

    function RoomUi({ uid, name, devices }) {
        return (
            <ToolTipWrapper
                key={uid}
                tooltipProps={getTooltipProps(uid)}
                tooltip={getTooltip({name})}
            >
                <div
                    key={uid}
                    onClick={() => handleCopyToClipboard(uid)}
                    className="c-hover-240 rounded-lg c-bg-grey-250"
                >
                    <div
                        className="rounded-lg px-3 p-2 "
                    >
                        <h6 className="font-weight-bold m-0">{name}</h6>
                        {Uid(uid)}
                    </div>
                    <div className="my-3 px-3">
                        {devices?.length ? (
                            DevicesWrapper(devices)
                        ) : (
                            <p className="text-secondary font-italic">
                                {t("mod_dev_no_devices_in_room")}
                            </p>
                        )}
                    </div>
                </div>
            </ToolTipWrapper>
        );
    }

    function DevicesWrapper(devices) {
        return (
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns:
                        "repeat(auto-fill, minmax(350px, 1fr))",
                    gap: "12px",
                }}
            >
                {devices.map((device) => (
                    <DeviceCard
                        key={device.uid}
                        id={device.uid}
                        device={device}
                    />
                ))}
            </div>
        );
    }

    function DeviceCard({ device }) {
        const { uid, name, type } = device;
        return (
            <ToolTipWrapper
                key={uid}
                tooltipProps={getTooltipProps(uid)}
                tooltip={getTooltip(device)}
            >
                <div
                    style={{ background: "white" }}
                    className={`c-hover-box-shadow p-4 rounded-lg w-100 h-100 cp rounded-lg d-flex justify-content-between`}
                >
                    <div className="d-flex align-items-center">
                        <div className="mr-2">
                            {
                                <img
                                    className="c-img-3rem"
                                    src={deviceIconForType(type)}
                                    alt="device type icon"
                                />
                            }
                        </div>
                        <div>
                            <div className="mb-1">{name}</div>
                            {Uid(uid)}
                        </div>
                    </div>
                </div>
            </ToolTipWrapper>
        );
    }

    function Uid(uid) {
        return (
            <small className="font-italic text-secondary d-block">{uid}</small>
        );
    }

    function getTooltipProps(uid) {
        return {
            delayShow: 0,
            delayHide: 300,
            afterShow: () => handleCopyToClipboard(uid),
            event: "click",
            eventOff: "mouseout",
        };
    }

    function getTooltip({ name }) {
        return `${name}: ${t("global_uid_copied")}`
    }

    function getLocationsInfo(rooms = [] || [DevRoom()]) {
        let locations = {};

        rooms.forEach(
            ({
                locationUid,
                locationTimezone,
                locationName,
                devices,
                uid,
                name,
            }) => {
                const roomLocation = locations[locationUid];

                roomLocation
                    ? (locations = {
                          ...locations,
                          [locationUid]: {
                              ...locations[locationUid],
                              rooms: [
                                  ...locations[locationUid].rooms,
                                  { uid, name, devices },
                              ],
                          },
                      })
                    : (locations = {
                          ...locations,
                          [locationUid]: {
                              uid: locationUid,
                              name: locationName,
                              timezone: locationTimezone,
                              rooms: [{ uid, name, devices }],
                          },
                      });
            },
        );

        return Object.values(locations);
    }

    function noLocationsMessage() {
        return (
            <div className="text-center mt-2">
                {t("mod_dev_locations_no_locations_message")}
            </div>
        );
    }
}
