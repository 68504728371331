export const USER_LOGS_ACTION_TYPES = {
    GET_FRIENDS: "USER_LOGS_GET_FRIENDS",
    CHANGE_SELECTED_USER_UID: "USER_LOGS_CHANGE_SELECTED_USER_UID",
    SELECT_USER_LOG: "USER_LOGS_SELECT_USER_LOG",
};

export const USER_LOG_TYPES = {
    NO_TYPE:                    0,
    EVENT_POSITION:             1,
    
    EVENT_LOCATION_ARRIVING:    6,
    EVENT_BEGIN_SHARE_POSITION: 7,
    EVENT_END_SHARE_POSITION:   8,
    EVENT_LOCATION_LEAVING:     9,
    
    EVENT_VEHICLE_ENTER:        18,
    EVENT_WALK_ENTER:           19,
    EVENT_STILL_ENTER:          20,
};

export const LOCATION_SHARE_STATUSES = {
    NO_SHARING: "NO_SHARING",
    YOU_SHARE: "YOU_SHARE",
    THEY_SHARE: "THEY_SHARE",
    BOTH_SHARE: "BOTH_SHARE",
};