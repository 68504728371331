import { UserStatusMobile } from "../_models/UserStatusMobile";

export class UserStatusMobileMapper {
    static serverToLocal(serverUserStatusMobile) {
        if (!serverUserStatusMobile)
            return null;

        return UserStatusMobile({
            isUpToDate: !serverUserStatusMobile.outdated_app,
            activityStatusPermissionChanges:
                serverUserStatusMobile.activity_status_permission_changes,
            batteryOptimizerPermissionChanges:
                serverUserStatusMobile.battery_optimizer_permission_changes,
            blePermissionChanges:
                serverUserStatusMobile.ble_permission_changes,
            locationPermissionChanges:
                serverUserStatusMobile.location_permission_changes,
            locationServicePermissionChanges:
                serverUserStatusMobile.location_service_permission_changes,
            notificationsPermissionChanges:
                serverUserStatusMobile.notifications_permission_changes,
            bleScanPermissionChanges: serverUserStatusMobile.bluetooth_scan_permission_changes,
            backgroundLocationPermissionChanges: serverUserStatusMobile.background_location_permission_changes
        });
    }
}