import { LocationTrigger, TimeTrigger, ProtectTrigger, DeviceTrigger, Trigger, TriggerAction } from "../_models/Triggers";
import { TimeHelper, DeviceHelper } from "../_helpers";
import { TRIGGER_TYPES } from "../_constants/triggers.constants";

export class TriggerMapper {
    static allServerToLocal(serverTriggers) {
        return serverTriggers.map(this.serverToLocal);
    }

    static serverToLocal(serverTrigger) {
        const triggerType = getTriggerType(serverTrigger);
        const localTrigger = mapCommon(serverTrigger);
        localTrigger.type = triggerType;
        localTrigger.parameters = mapParameters(
            serverTrigger[triggerType],
            triggerType,
        );

        return localTrigger;


        ////
        function getTriggerType(serverTrigger) {
            const triggerTypesArray = Object.values(TRIGGER_TYPES);
            for (let i = 0; i < triggerTypesArray.length; i++) {
                const triggerType = triggerTypesArray[i];
                if (serverTrigger[triggerType] !== null)
                    return triggerType;
            }
        }

        function mapCommon(serverTrigger) {
            return Trigger({
                ...serverTrigger,
                locationUid: serverTrigger.loc_uid,
                action: mapTriggerAction(serverTrigger.action),
            });
        }

        function mapTriggerAction(serverTriggerAction) {
            const {
                type,
                device_type,
                room_uid,
                device_uid,
                toggle,
                status,
                timeout,
                delay,
                user_name,
            } = serverTriggerAction;
            return TriggerAction({
                type,
                deviceType: device_type,
                roomUid: room_uid,
                deviceUid: device_uid,
                toggle,
                status,
                timeout,
                delay,
                userName: user_name,
                localStatus: DeviceHelper.getTriggerSideBDeviceLocalStatus(device_type, status),
            });
        }

        function mapParameters(serverParameters, triggerType) {
            switch (triggerType) {
                case (TRIGGER_TYPES.TIME): {
                    return TimeTrigger({
                        ...serverParameters,
                        periodType: serverParameters.period_type,
                        eventType: serverParameters.event_type,
                        begin: TimeHelper.serverStringToIsoString(serverParameters.begin),
                    });
                }

                case (TRIGGER_TYPES.LOCATION): {
                    const {
                        type,
                        remote,
                        user_type,
                        room_uid,
                        device_uid,
                        user_uid,
                        action_type,
                    } = serverParameters;

                    return LocationTrigger({
                        type,
                        remote,
                        actionType: action_type,
                        deviceUid: device_uid,
                        roomUid: room_uid,
                        userType: user_type,
                        userUid: user_uid,
                    });
                }

                case (TRIGGER_TYPES.PROTECT):
                    return ProtectTrigger({
                        type: serverParameters.type,
                    });
                
                case (TRIGGER_TYPES.DEVICE): {
                    const {
                        type,
                        device_type,
                        room_uid,
                        device_uid,
                        status,
                        temperature,
                        humidity,
                        operator,
                        aggregator,
                    } = serverParameters;
                    
                    return DeviceTrigger({
                        type,
                        aggregator,
                        temperature,
                        humidity,
                        operator,
                        status,
                        localStatus: DeviceHelper.getTriggerSideADeviceLocalStatus(device_type, status),
                        deviceType: device_type,
                        deviceUid: device_uid,
                        roomUid: room_uid,
                    });
                }

                default:
                    return null;
            }
        }
    }
}