import everouIcon from './icon_baintex_logo_h_white.svg';
import everouIconVerticalWhite from './logo_baintex_name_white_v1.svg';
import nextIcon from './next-icon.png';
import previousIcon from './previous-icon.svg';
import search from "./icon_search.svg";
import missing from './device-icon-missing.png';
import people from './people-black-18dp.svg';
import files from './file_copy-black-18dp.svg';
import devices from './icon_module_devices.svg';
import gear from './icon_module_settings.svg';
import trash from './delete-icon.svg';
import check from './icon_check.svg';
import cross from './icon_cross.svg';
import at from './at-icon.svg';
import lock from './lock-icon.svg';
import paintPalette from './color_lens-24px.svg';
import addUsers from './group_add-24px.svg';

import arrowDown from './icon_arrow_down.svg';
import arrowRight from './icon_arrow_right.svg';
import arrowUp from './icon_arrow_up.svg';

import enter from './icon_enter.png';
import exit from './icon_exit.png';
import edit from './edit-black-18dp.svg';
import add from './add-icon.svg';
import remove from './minus-icon.jsx';
import clock from './access_time-black-18dp.svg';
import pdf from './pdf-24px.svg';

import google from './google_g_logo.svg';
import apple from './apple_logo.svg';
import home from './home-icon.svg';
import bluetooth from './icon_bluetooth.svg';

import clockinsEnter from './icon_entrada.png';
import clockinsExit from './icon_salida.png';

import invitations from './icon_module_invitations.svg';
import help from './icon_module_help.svg';
import buildCircle from './build_circle-black-18dp.svg';
import calendar from './calendar.svg';
import dashboard from "./icon_module_dashboard.svg";
import alerts from "./icon_module_alerts.svg";

import editLocation from "./edit_location-24px.svg";
import bulletedList from "./format_list_bulleted-24px.svg";

import openFullscreen from "./open_in_full-black-18dp.svg";
import closeFullscreen from "./close_fullscreen-black-18dp.svg";

import notes from "./notes-black-18dp.svg";
import receipt from "./receipt_long-black-18dp.svg";

import protect from "./icon_module_protect.svg";
import code from "./code-black-18dp.svg";
import copy from "./content_copy-black-18dp.svg";
import menuBook from "./menu_book-black-18dp.svg";
import key from "./vpn_key-black-18dp.svg";
import refresh from "./refresh-icon-24px.svg";

import email_send from "./icon_email_send.png";
import info from "./icon_info.svg"
import locker_lock from './locker_lock.svg';
import locker_unlock from './locker_unlock.svg';
import pause from './icon-pause-24.jsx';
import camera from './icon-camera.jsx';

import wifi_connection from './icon-connection.png';
import wifi_no_connection from './icon-no-connection.png';

import battery from './icon-battery.png';
import battery_low from './icon-battery-empty.png';

import icon_no_reservations from './icon-reservations.png';
import icon_reservations from './icon-reservations-ok.png';

import icon_whatsapp from './icon_whatsapp.png';
import icon_gmail from './icon_gmail.png';

import icon_reservations_new from './icon_reservations.png';
import icon_chat from './icon_chat.png';

export const Icons = {
    everouIcon,
    everouIconVerticalWhite,

    arrowRight,
    arrowDown,
    arrowUp,

    nextIcon,
    previousIcon,
    search,
    missing,
    people,
    files,
    camera,
    devices,
    gear,
    trash,

    edit,
    check,
    cross,

    enter,
    exit,

    clockinsEnter,
    clockinsExit,

    add,
    remove,
    clock,
    pdf,
    at,
    lock,
    paintPalette,
    addUsers,
    home,

    apple,
    google,

    bluetooth,

    invitations,
    help,
    buildCircle,
    calendar,
    dashboard,
    alerts,

    editLocation,
    bulletedList,

    openFullscreen,
    closeFullscreen,

    notes,
    receipt,

    info,

    protect,
    
    code,
    copy,
    menuBook,
    key,

    refresh,
    email_send,

    locker_lock,
    locker_unlock,
    pause,

    wifi_connection,
    wifi_no_connection,

    battery,
    battery_low,

    icon_no_reservations,
    icon_reservations,

    icon_whatsapp,
    icon_gmail,

    icon_reservations_new,
    icon_chat,
};