import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { TimeHelper, DeviceHelper } from "../_helpers";
import { logIcon } from "../_assets";
import { nullValues } from "../_constants";
import { protectActions } from "../_actions/protect.actions";
import { GenericSingleDevice } from "./GenericSingleDevice";
import { ProtectMessage } from "./ProtectMessage";

import protectOnIcon from "../_assets/appIcons/icon_protect_on.svg";
import { ListedGroup } from "../_components/ListedGroup";

export function TriggeredLogs({ triggeredLogs, devices, timezone }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {(
        async () => await dispatch(protectActions.getTriggeredLogs())
    )()},
    [dispatch, devices]);

    return (
        triggeredLogs.length !== 0
        ? triggeredLogsDiv()
        : <ProtectMessage
            icon={protectOnIcon}
            textA={t("protect.protectModeEnabled")}
            textB={t("protect.ifThereIsAnyIncident")}
        />
    );

    function triggeredLogsDiv() {
        return (
            <div className="
                d-flex
                no-gutters
                flex-column
                
                mb-2
                overflow-auto
            ">
                <ListedGroup
                    headerText={t("protect.protectHasDetected")}
                    elements={triggeredLogsBuilder()}
                />
            </div>
        );
    }

    function triggeredLogsBuilder() {
        return (
            triggeredLogs
            .map(log => {
                return GenericSingleDevice({
                    key: log.logId,
                    icon: logIcon(
                        log.status,
                        DeviceHelper.getTypeForUidFromDevices(log.deviceUid, devices),
                    ),
                    mainText: DeviceHelper.getNameForUidFromDevices(
                        log.deviceUid,
                        devices,
                    ),
                    secondaryText: secondaryStringBuilder(t, timezone),
                    statusText: log.statusLabel,
                });

                function secondaryStringBuilder(t, timezone) {
                    let dateAndUserString = ` ${t("common.at")} `;
                    dateAndUserString += TimeHelper.localizeIsoStringToFormat(
                        log.datetime,
                        timezone,
                        TimeHelper.getTimeFormats().TIME_NO_SECONDS,
                    );

                    if (log.userName === nullValues.user)
                        return dateAndUserString;

                    dateAndUserString += ` ${t("common.by")} `;
                    dateAndUserString += log.userName;
        
                    return dateAndUserString;
                }
            })
        );
    }
}