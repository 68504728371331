import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { devicesActions } from "../_actions";
import { DeviceUiHelper } from "../_helpers";

import { Device } from "../_models/Device";
import { EverouBleService } from "../_services/EverouBleService";

export function SingleBleDevice({
    isSendingActionToAnyDevice = false,
    device = Device(),
    globalSetUpFn = () => {},
    globalCleanUpFn = () => {},
} = {}) {

    //LOCAL STATE
    const [isSendingAction, setIsSendingAction] = useState(false);
    const [retries, setRetries] = useState(0);
    const [uiBleError, setUiBleError] = useState(null);

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HANDLERS
    function sendBleActionToDevice(actionCode, device = Device()) {
        EverouBleService.sendBleActionAutoOrManual({
            isBleInProgress: isSendingActionToAnyDevice,
            actionCode,
            device,

            setUp,
            onError,
            cleanUp,
            onSuccess,
            onRetry,
        });

        function setUp() {
            globalSetUpFn();

            setUiBleError(null);
            setRetries(0);
            setIsSendingAction(true);
        }

        function onError(errorMessage) {
            console.error(errorMessage);
            errorMessage && setUiBleError(errorMessage);
            cleanUp();
        }

        function cleanUp() {
            globalCleanUpFn();

            setIsSendingAction(false);
        }

        function onRetry(retries) {
            setRetries(retries);
        }

        async function onSuccess() {
            await dispatch(devicesActions.getDevice(device.uid));
        }
    }

    //VIEW
    return (
        <div className="d-flex justify-content-between px-2">
            <div className="d-flex flex-column position-relative">
                <div className="mt-2 mb-3">
                    {uiBleError && errorMessageUi()}
                    {device.description}
                </div>
            </div>

            <div className="d-flex align-items-center">
                {isSendingAction
                    ? Spinner()
                    : DeviceButton(device)
                }
                {retries !== 0 && retryCounter()}
            </div>
        </div>
    );

    function DeviceButton(device) {
        return DeviceUiHelper.getUiButtonForDevice(
            device,
            sendBleActionToDevice,
        );
    }

    function Spinner () {
        return (
            <div className="my-auto">
                <div className="spinner-border spinner-border-sm" />
            </div>
        );
    }

    function retryCounter() {
        return (
            <div className="ml-1">
                {retries}
            </div>
        );
    }

    function errorMessageUi() {
        return (
            <div className="
                pt-3
                text-danger
                position-absolute
                text-nowrap
            ">
                {t("global_error") + uiBleError}
            </div>
        );
    }
}