export function BudgetUser({
    apikey,
    uid,
    name,
    email,
    distributorUid,
} = {}) {
    return {
        apikey,
        uid,
        name,
        email,
        distributorUid,
    };
}