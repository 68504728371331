export function DevRoom({
    uid,
    name,
    locationUid,
    locationName,
    locationTimezone,
    devices
} = {}) {
    return {
        uid,
        name,
        locationUid,
        locationName,
        locationTimezone,
        devices
    };
}