import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const clockinService = {
    listClockIns,
    listClockinUsers,

    createClockIn,

    updateClockIn,
    deleteClockIn,

    updateUserSettings,

    getPdfBlobUserReport,
    getPdfBlobSummaryReport,
    getCSVBlobSummaryReport,
    getExcelBlobSummaryReport,
};

async function getPdfBlobUserReport(locationUid, userUid, begin, end) {
    return await ServiceHelper.getPdfBlobRequest(
        URL_CONSTANTS.CLOCKINS_REPORT,
        {
            loc_uid: locationUid,
            user_uid: userUid,
            begin,
            end,
            summary: false,
        },
    );
}

async function getPdfBlobSummaryReport(locationUid, begin, end) {
    return await ServiceHelper.getPdfBlobRequest(
        URL_CONSTANTS.CLOCKINS_REPORT,
        {
            loc_uid: locationUid,
            begin,
            end,
            summary: true,
        },
    );
}

async function getCSVBlobSummaryReport(locationUid, begin, end) {
    return await ServiceHelper.getCSVBlobRequest(
        URL_CONSTANTS.CLOCKINS_REPORT_EXPORT,
        {
            loc_uid: locationUid,
            begin,
            end,
            csv: true,
        },
    );
}

async function getExcelBlobSummaryReport(locationUid, begin, end) {
    return await ServiceHelper.getExcelBlobRequest(
        URL_CONSTANTS.CLOCKINS_REPORT_EXPORT,
        {
            loc_uid: locationUid,
            begin,
            end,
            xlsx: true,
        },
    );
}

async function updateUserSettings(locationUid, userUid, allowsRemoteClockins, hasFlexibleClockins) {
    return await ServiceHelper.putRequest(
        URL_CONSTANTS.CLOCKINS_USERS_SETTINGS,
        {
            loc_uid: locationUid,
            user_uid: userUid,
            allows_remote_clockins: allowsRemoteClockins,
            has_flexible_clockins: hasFlexibleClockins,
        },
    );
}

async function listClockIns(locationUid, begin, end, userUid) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.CLOCKINS,
        {
            loc_uid: locationUid,
            user_uid: userUid ? userUid : undefined,
            begin,
            end,
        },
    );
}

async function listClockinUsers(locationUid, begin, end) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.CLOCKINS_USERS,
        {
            loc_uid: locationUid,
            begin,
            end,
        },
    );
}

async function createClockIn(locationUid, userUid, date) {
    return await ServiceHelper.postRequest(
        URL_CONSTANTS.CLOCKINS,
        {
            loc_uid: locationUid,
            user_uid: userUid,
            date,
        },
    );
}

async function updateClockIn(uid, date, notes) {
    return await ServiceHelper.putRequest(
        URL_CONSTANTS.CLOCKINS + "/" + uid,
        {
            date,
            notes,
        },
    );
}

async function deleteClockIn(uid) {
    return await ServiceHelper.deleteRequest(
        URL_CONSTANTS.CLOCKINS + "/" + uid,
    );
}