import { genericActions } from "../_helpers/ActionErrorWrapper";

import { DistributorsService } from "../_services/DistributorsService";
import { DistributorMapper } from "../_mappers/DistributorMapper";
import { DISTRIBUTOR_ACTION_TYPES } from "../_constants/distributor.constants";
import { BudgetUser } from "../_models/BudgetUser";

export const DistributorActions = {
    createDistributor,
    getDistributor,
    updateDistributor,

    uploadAvatar,
};

function updateDistributor({
    uid,
    name,
    cif,
    email,
    phone,
    notes,
}) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverDistributor = await DistributorsService.updateDistributor({
                uid,
                name,
                cif,
                email,
                phone,
                notes,
            });

            dispatch(getDistributorSuccess(
                DistributorMapper.serverToLocal(serverDistributor)
            ));
        }
    }
}

function createDistributor({
    name,
    cif,
    email,
    phone,
    notes,
}) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverDistributor = await DistributorsService.createDistributor({
                name,
                cif,
                email,
                phone,
                notes,
            });

            dispatch(getDistributorSuccess(
                DistributorMapper.serverToLocal(serverDistributor)
            ));
        }
    }
}

function getDistributor(budgetUser = BudgetUser()) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!(budgetUser && budgetUser.distributorUid)) {
                console.warn("NO BUDGET USER OR NO DISTRIBUTOR");
                return;
            }

            const serverDistributor = await DistributorsService.getDistributor(budgetUser.distributorUid);
            const localDistributor = DistributorMapper.serverToLocal(serverDistributor);
            dispatch(getDistributorSuccess(localDistributor));
            return localDistributor;
        }
    }
}

function getDistributorSuccess(distributor) {
    return {
        type: DISTRIBUTOR_ACTION_TYPES.GET_DISTRIBUTOR_SUCCESS,
        distributor, 
    };
}

function uploadAvatar(file) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverDistributor = await DistributorsService.uploadAvatar(file);
            dispatch(getDistributorSuccess(
                DistributorMapper.serverToLocal(serverDistributor)
            ));
        }
    };
}