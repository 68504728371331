import { LocalEditableIntegration, KrossBookingProperty, IntegrationCompany, HostifyListing, SmoobuApartment, OctorateAccommodation, AvaibookAccommodation, LinkType } from "../../../Everou/_modelsTS/Integration";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Selector, CapsuleButton, SimpleToggle } from "../../../Everou/_components"
import { Icons } from "../../../Everou/_assets";
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";
import { PMSKrossBookingStore } from "../../_stores/PMSKrossBookingStore";
import { Selectors } from "../../../Everou/_reducers/app.reducer";
import { PMSIntegrationCreateServer } from "../../_models/PMSIntegration";
import { PMSLocationsActions } from "../../_actions/PMSLocationsActions";
import { PMSHostifyStore } from "../../_stores/PMSHostifyStore";
import { PMSSmoobuStore } from "../../_stores/PMSSmoobuStore";
import { PMSOctorateStore } from "../../_stores/PMSOctorateStore";
import { WaitSpinner } from "../../../Common/_components/WaitSpinner";
import { PMSAvaibookStore } from "../../_stores/PMSAvaibookStore";


type IntegrationConfigInputProps = ({
    integration: LocalEditableIntegration;
    integrationTemplates: any[];
    groups: any[]; // To be typed asap
    onDelete: (index: number) => void;
    index: number;
})

export function IntegrationConfigInput({
    integration,
    integrationTemplates,
    index,
    groups,
    onDelete,
}: IntegrationConfigInputProps) {

    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // GLOBAL STATE
    const location = useSelector(Selectors.getPMSLocationInfo);

    const locationRooms = useSelector(Selectors.getPMSLocationRooms);
    const buildAvailableLocationRooms = useCallback(
        (availableLocationRooms: any[] = []) => {
            return availableLocationRooms.map(
                (room) => (
                    { 
                        value: room.uid, 
                        label: room.name, 
                        isDisabled: false
                    }
                )
            );
    }, []);
    const [availableLocationRooms, setAvailableLocationRooms] = useState(buildAvailableLocationRooms(locationRooms));
    const [selectedLocationRoomsLocal, setSelectedLocationRoomsLocal] = useState([]);

    const buildAvailablePMS = useCallback((availableIntegrationTemplates: any[] = []) => {
        return availableIntegrationTemplates.map(
            (template) => (
                { 
                    value: template.uid, 
                    label: template.company, 
                    isDisabled: false 
                }
            )
        ) ?? [];
    }, []);

    const [selectedPMSLocal, setSelectedPMSLocal] = useState(integration.company === IntegrationCompany.GENERIC ? undefined : 
        { 
            value:integration.uid, 
            label:integration.company, 
            isDisabled: false 
        } ?? undefined
    );
    useEffect(() => {
        setSelectedPMSLocal(
            integration.company === IntegrationCompany.GENERIC ? undefined : 
            { 
                value:integration.uid, 
                label:integration.company, 
                isDisabled: false 
            } ?? undefined
        );
    }, [integration]);
    const [availablePMS, setAvailablePMS] = useState(buildAvailablePMS(integrationTemplates));

    const onChangePMS = useCallback(selectedPMS => {
        setSelectedPMSLocal(selectedPMS);
        if (selectedPMS.label === IntegrationCompany.KROSS_BOOKING) {
            dispatch(PMSIntegrationsActions.listKrossBookingProperties(selectedPMS.value));

        } else if (selectedPMS.label === IntegrationCompany.HOSTIFY) {
            dispatch(PMSIntegrationsActions.listHostifyListings(selectedPMS.value));

        } else if (selectedPMS.label === IntegrationCompany.SMOOBU) {
            dispatch(PMSIntegrationsActions.listSmoobuProperties(selectedPMS.value));
        
        } else if (selectedPMS.label === IntegrationCompany.OCTORATE) {
            dispatch(PMSIntegrationsActions.listOctorateAccommodations(selectedPMS.value));

        } else if (selectedPMS.label === IntegrationCompany.AVAIBOOK) {
            dispatch(PMSIntegrationsActions.listAvaibookAccommodations(selectedPMS.value));

        }

    }, [dispatch]);

    const [enableProtectGuestLocal, setEnableProtectGuestLocal] = useState(integration.enableProtectGuest);

    useEffect(() => {
        setAvailableLocationRooms(buildAvailableLocationRooms(locationRooms));
    }, [locationRooms]);

    // region KROSS BOOKING
    const buildKrossBookingPropertyOptions = useCallback((availableKrossBookingProperties: any[] = []) => {
        return availableKrossBookingProperties.map((property) => ({ value: property.id_property, label: property.name, isDisabled: false })) ?? [];
    }, []);

    const buildKrossBookingRoomOptions = useCallback((availableKrossBookingRooms: any[] = []) => {
        return availableKrossBookingRooms.map((room) => ({ value: room.id_room, label: room.number, isDisabled: false })) ?? [];
    }, []);

    const krossBookingProperties = useSelector(PMSKrossBookingStore.selectorGetItems);
    const selectedKrossBookingProperty : KrossBookingProperty = useSelector(PMSKrossBookingStore.selectorGetSelectedItem) ?? {
        id:integration.host_id, 
        name:integration.host_name
    };
    const [selectedKrossBookingPropertyLocal, setSelectedKrossBookingPropertyLocal] = useState(
        { 
            value:integration.host_id, 
            label:integration.host_name, 
            isDisabled: false 
        } ?? undefined
    );
    const [availableKrossBookingProperties, setAvailableKrossBookingProperties] = useState(buildKrossBookingPropertyOptions(krossBookingProperties));

    const onChangeKrossBookingProperty = useCallback(selectedKrossBookingProperty => {
        setSelectedKrossBookingPropertyLocal(selectedKrossBookingProperty);
        dispatch(PMSKrossBookingStore.actionSelectItem(selectedKrossBookingProperty.value));
        if (selectedPMSLocal && selectedKrossBookingProperty !== undefined) {
            dispatch(PMSIntegrationsActions.listKrossBookingRooms(
                selectedPMSLocal.value, 
                selectedKrossBookingProperty.value
            ));
        }
    }, [dispatch, selectedPMSLocal]);

    const [selectedKrossBookingRoomLocal, setSelectedKrossBookingRoomLocal] = useState(
        { 
            value:integration.room_id, 
            label:integration.room_name, 
            isDisabled: false 
        } ?? undefined
    );
    const [availableKrossBookingRooms, setAvailableKrossBookingRooms] = useState(buildKrossBookingRoomOptions(selectedKrossBookingProperty?.rooms));

    const onChangeKrossBookingRoom = useCallback(selectedRoom => {
        setSelectedKrossBookingRoomLocal(selectedRoom);
    }, []);

    useEffect(() => {
        setAvailableKrossBookingProperties(buildKrossBookingPropertyOptions(krossBookingProperties));
    }, [krossBookingProperties, buildKrossBookingPropertyOptions]);

    useEffect(() => {
        if (!!selectedKrossBookingProperty && !!selectedKrossBookingProperty.rooms) {
            setAvailableKrossBookingRooms(buildKrossBookingRoomOptions(selectedKrossBookingProperty.rooms));
        }
    }, [buildKrossBookingRoomOptions, selectedKrossBookingProperty]);
    // endregion

    // region HOSTIFY
    const buildHostifyPropertyOptions = useCallback((availableProperties: any[] = []) => {
        return availableProperties.map(
            (property) => (
                { 
                    value: property.id, 
                    label: property.nickname, 
                    isDisabled: false 
                }
            )
        ) ?? [];
    }, []);

    const hostifyProperties = useSelector(PMSHostifyStore.selectorGetItems);
    const selectedHostifyProperty : HostifyListing = useSelector(PMSHostifyStore.selectorGetSelectedItem);

    const [selectedHostifyPropertyLocal, setSelectedHostifyPropertyLocal] = useState(
        { 
            value:integration.host_id, 
            label:integration.host_name, 
            isDisabled: false 
        } ?? undefined
    );
    const [availableHostifyProperties, setAvailableHostifyProperties] = useState(buildHostifyPropertyOptions(hostifyProperties));

    const onChangeHostifyProperty = useCallback(selectedProperty => {
        setSelectedHostifyPropertyLocal(selectedProperty);
        dispatch(PMSHostifyStore.actionSelectItem(selectedProperty.value));
    }, [dispatch]);

    useEffect(() => {
        setAvailableHostifyProperties(buildHostifyPropertyOptions(hostifyProperties));
    }, [hostifyProperties, buildHostifyPropertyOptions]);
    // endregion

    // region SMOOBU
    const buildSmoobuPropertyOptions = useCallback((availableProperties: any[] = []) => {
        return availableProperties.map(
            (property) => (
                { 
                    value: property.id, 
                    label: property.name, 
                    isDisabled: false 
                }
            )
        ) ?? [];
    }, []);

    const smoobuProperties = useSelector(PMSSmoobuStore.selectorGetItems);
    const selectedProperty : SmoobuApartment = useSelector(PMSSmoobuStore.selectorGetSelectedItem) ?? {
        id:integration.host_id, 
        name:integration.host_name
    };
    const [selectedSmoobuPropertyLocal, setSelectedSmoobuPropertyLocal] = useState(
        { 
            value:integration.host_id, 
            label:integration.host_name, 
            isDisabled: false 
        } ?? undefined
    );
    const [availableSmoobuProperties, setAvailableSmoobuProperties] = useState(buildSmoobuPropertyOptions(smoobuProperties));

    const onChangeSmoobuProperty = useCallback(selectedProperty => {
        setSelectedSmoobuPropertyLocal(selectedProperty);
        dispatch(PMSSmoobuStore.actionSelectItem(selectedProperty.value));
    }, [dispatch]);

    useEffect(() => {
        setAvailableSmoobuProperties(buildSmoobuPropertyOptions(smoobuProperties));
    }, [smoobuProperties, buildSmoobuPropertyOptions]);
    // endregion

    // region iCAL
    const [selectedIcalLinkLocal, setSelectedIcalLinkLocal] = useState(
        { 
            value:integration.uid, 
            label:integration.link, 
            isDisabled: false 
        } ?? undefined
    );

    const onChangeIcalLink = useCallback(selectedIcalLink => {
        setSelectedIcalLinkLocal(selectedIcalLink);
    }, []);

    const buildAvailableIcalLinks = useCallback((availableIntegrationTemplates: any[] = []) => {
        return availableIntegrationTemplates.map(
            (template) => (
                { 
                    value: template.uid, 
                    label: template.company, 
                    isDisabled: false 
                }
            )
        ) ?? [];
    }, []);
    // endregion

    // region AVANTIO
    const [availableAvantioIcalLinks, setAvailableAvantioIcalLinks] = useState(buildAvailableIcalLinks(integrationTemplates));

    const buildAvailableAvantioIcalLinks = useCallback((availableTemplates: any[] = []) => {
        return availableTemplates.filter(template => template.company === IntegrationCompany.AVANTIO).map(
            (template) => (
                { 
                    value: template.uid, 
                    label: template.link, 
                    isDisabled: false 
                }
            )
        ) ?? [];
    }, []);

    useEffect(() => {
        setAvailableAvantioIcalLinks(buildAvailableAvantioIcalLinks(integrationTemplates));
    }, [integrationTemplates, buildAvailableAvantioIcalLinks]);
    // endregion

    // region AVAIBOOK

    // region old
    const [availableAvaibookIcalLinks, setAvailableAvaibookIcalLinks] = useState(buildAvailableIcalLinks(integrationTemplates));

    const buildAvailableAvaibookIcalLinks = useCallback((availableTemplates: any[] = []) => {
        return availableTemplates.filter(template => template.company === IntegrationCompany.AVAIBOOK).map(
            (template) => (
                { 
                    value: template.uid, 
                    label: template.link, 
                    isDisabled: false 
                }
            )
        ) ?? [];
    }, []);

    useEffect(() => {
        setAvailableAvaibookIcalLinks(buildAvailableAvaibookIcalLinks(integrationTemplates));
    }, [integrationTemplates, buildAvailableAvaibookIcalLinks]);
    // endregion

    // region new
    const buildAvaibookAccommodationsOptions = useCallback((availableProperties: any[] = []) => {
        return availableProperties.map(
            (property) => (
                { 
                    value: property.id, 
                    label: property.name, 
                    isDisabled: false 
                }
            )
        ) ?? [];
    }, []);

    const avaibookAccommodations = useSelector(PMSAvaibookStore.selectorGetItems);
    const selectedAvaibookAccommodation : AvaibookAccommodation = useSelector(PMSAvaibookStore.selectorGetSelectedItem);

    const [selectedAvaibookAccommodationLocal, setSelectedAvaibookAccommodationLocal] = useState(
        { 
            value:integration.host_id, 
            label:integration.host_name, 
            isDisabled: false 
        } ?? undefined
    );
    const [availableAvaibookAccommodations, setAvailableAvaibookAccommodations] = useState(buildAvaibookAccommodationsOptions(avaibookAccommodations));

    const onChangeAvaibookAccommodations = useCallback(selectedProperty => {
        setSelectedAvaibookAccommodationLocal(selectedProperty);
        dispatch(PMSAvaibookStore.actionSelectItem(selectedProperty.value));
    }, [dispatch]);

    useEffect(() => {
        setAvailableAvaibookAccommodations(buildAvaibookAccommodationsOptions(avaibookAccommodations));
    }, [avaibookAccommodations, buildAvaibookAccommodationsOptions]);
    // endregion

    // endregion

    // region OCTORATE
    const buildOctorateAccomodationOptions = useCallback((availableProperties: any[] = []) => {
        return availableProperties.map((property) => ({ value: property.id, label: property.name, isDisabled: false })) ?? [];
    }, []);

    const octorateAccomodations = useSelector(PMSOctorateStore.selectorGetItems);
    const selectedOctorateAccomodation : OctorateAccommodation = useSelector(PMSOctorateStore.selectorGetSelectedItem) ?? {id:integration.host_id, name:integration.host_name};
    const [selectedOctorateAccomodationLocal, setSelectedOctorateAccomodationLocal] = useState({ value:integration.host_id, label:integration.host_name, isDisabled: false } ?? undefined);
    const [availableOctorateAccomodations, setAvailableOctorateAccomodations] = useState(buildOctorateAccomodationOptions(octorateAccomodations));

    const onChangeOctorateAccomodation = useCallback(selectedProperty => {
        setSelectedOctorateAccomodationLocal(selectedProperty);
        dispatch(PMSOctorateStore.actionSelectItem(selectedProperty.value));
    }, [dispatch]);

    useEffect(() => {
        setAvailableOctorateAccomodations(buildOctorateAccomodationOptions(octorateAccomodations));
    }, [octorateAccomodations, buildOctorateAccomodationOptions]);
    // endregion

    const onChangeRoomsGroup = useCallback(selectedRooms => {
        setSelectedLocationRoomsLocal(selectedRooms);
    }, []);

    const setToggleProtect = useCallback(value => {
        setEnableProtectGuestLocal(value);
    }, [setEnableProtectGuestLocal]);

    const [isRequestingForSave, setRequestSave] = useState(false);

    const onSave = useCallback(async () => {
        if (!selectedPMSLocal)
            return;

        let editedIntegration: PMSIntegrationCreateServer;
        
        if (selectedPMSLocal.label  === IntegrationCompany.KROSS_BOOKING) {
            if (!selectedKrossBookingPropertyLocal || !selectedKrossBookingRoomLocal)
                return;

            editedIntegration = {
                uid: selectedPMSLocal.value,
                host_id: selectedKrossBookingPropertyLocal.value,
                host_name: selectedKrossBookingPropertyLocal.label,
                room_id: selectedKrossBookingRoomLocal.value,
                room_name: selectedKrossBookingRoomLocal.label,
                rooms: selectedLocationRoomsLocal.map(({value}) => value),
                enable_protect_guest:enableProtectGuestLocal
            };

        } else if (selectedPMSLocal.label === IntegrationCompany.HOSTIFY) {
            if (!selectedHostifyPropertyLocal)
                return;

            editedIntegration = {
                uid: selectedPMSLocal.value,
                host_id: selectedHostifyPropertyLocal.value,
                host_name: selectedHostifyPropertyLocal.label,
                rooms: selectedLocationRoomsLocal.map(({value}) => value),
                enable_protect_guest:enableProtectGuestLocal
            };

        } else if (selectedPMSLocal.label === IntegrationCompany.SMOOBU) {
            if (!selectedSmoobuPropertyLocal)
                return;

            editedIntegration = {
                uid: selectedPMSLocal.value,
                host_id: selectedSmoobuPropertyLocal.value,
                host_name: selectedSmoobuPropertyLocal.label,
                rooms: selectedLocationRoomsLocal.map(({value}) => value),
                enable_protect_guest:enableProtectGuestLocal
            };

        } else if (selectedPMSLocal.label === IntegrationCompany.AVANTIO) {
            if (!selectedSmoobuPropertyLocal)
                return;

            editedIntegration = {
                uid: selectedPMSLocal.value,
                link: selectedIcalLinkLocal.label,
                rooms: selectedLocationRoomsLocal.map(({value}) => value),
                enable_protect_guest:enableProtectGuestLocal
            };

        } else if (selectedPMSLocal.label === IntegrationCompany.OCTORATE) {
            if (!selectedSmoobuPropertyLocal)
                return;

            editedIntegration = {
                uid: selectedPMSLocal.value,
                host_id: selectedOctorateAccomodationLocal.value,
                host_name: selectedOctorateAccomodationLocal.label,
                rooms: selectedLocationRoomsLocal.map(({value}) => value),
                enable_protect_guest:enableProtectGuestLocal
            };

        } else if (selectedPMSLocal.label === IntegrationCompany.AVAIBOOK) {
            if (!selectedAvaibookAccommodationLocal)
                return;

            editedIntegration = {
                uid: selectedPMSLocal.value,
                host_id: selectedAvaibookAccommodationLocal.value,
                host_name: selectedAvaibookAccommodationLocal.label,
                integration_type: LinkType.API_ENDPOINT,
                rooms: selectedLocationRoomsLocal.map(({value}) => value),
                enable_protect_guest:enableProtectGuestLocal
            };

        } else {
            return;
        }

        setRequestSave(true);

        await dispatch(PMSLocationsActions.addLocationIntegration(editedIntegration));

        setRequestSave(false);

    }, [
        dispatch, 
        selectedPMSLocal, 
        selectedLocationRoomsLocal, 
        selectedKrossBookingPropertyLocal, 
        selectedKrossBookingRoomLocal, 
        enableProtectGuestLocal,
        selectedHostifyPropertyLocal,
        selectedIcalLinkLocal.label,
        selectedOctorateAccomodationLocal.label,
        selectedOctorateAccomodationLocal.value,
        selectedSmoobuPropertyLocal
    ]);

    const canSave = useMemo(() => {
        return !isRequestingForSave && selectedPMSLocal !== undefined && (
            (
                selectedPMSLocal.label === IntegrationCompany.HOSTIFY &&
                selectedHostifyPropertyLocal !== undefined &&
                selectedHostifyPropertyLocal.value !== undefined
            ) ||
            (
                selectedPMSLocal.label === IntegrationCompany.KROSS_BOOKING &&
                selectedKrossBookingPropertyLocal &&
                selectedKrossBookingRoomLocal !== undefined &&
                selectedKrossBookingRoomLocal.value !== undefined
            ) ||
            (
                selectedPMSLocal.label === IntegrationCompany.SMOOBU &&
                selectedSmoobuPropertyLocal !== undefined &&
                selectedSmoobuPropertyLocal.value !== undefined
            ) ||
            (
                selectedPMSLocal.label === IntegrationCompany.AVANTIO &&
                selectedIcalLinkLocal !== undefined &&
                selectedIcalLinkLocal.value !== undefined
            ) ||
            (
                selectedPMSLocal.label === IntegrationCompany.OCTORATE &&
                selectedOctorateAccomodationLocal !== undefined &&
                selectedOctorateAccomodationLocal.value !== undefined
            ) ||
            (
                selectedPMSLocal.label === IntegrationCompany.AVAIBOOK &&
                selectedIcalLinkLocal !== undefined &&
                selectedIcalLinkLocal.value !== undefined
            )
        ) && selectedLocationRoomsLocal.length > 0;
    }, [
        isRequestingForSave, 
        selectedPMSLocal, 
        selectedHostifyPropertyLocal, 
        selectedKrossBookingPropertyLocal, 
        selectedKrossBookingRoomLocal, 
        selectedSmoobuPropertyLocal, 
        selectedLocationRoomsLocal, 
        selectedIcalLinkLocal,
        selectedOctorateAccomodationLocal
    ]);

    // INITIALIZATION
    useEffect(() => {
        setAvailablePMS(buildAvailablePMS(integrationTemplates));
    }, [integrationTemplates, buildAvailablePMS]);

    useEffect(() => {
        if (integration.company === IntegrationCompany.KROSS_BOOKING) {
            setSelectedKrossBookingPropertyLocal(
                { 
                    value:integration.host_id, 
                    label:integration.host_name, 
                    isDisabled: false 
                }
            );
            setSelectedKrossBookingRoomLocal(
                { 
                    value:integration.room_id, 
                    label:integration.room_name, 
                    isDisabled: false 
                }
            );

        } else if (integration.company === IntegrationCompany.HOSTIFY) {
            setSelectedHostifyPropertyLocal(
                { 
                    value:integration.host_id, 
                    label:integration.host_name, 
                    isDisabled: false 
                }
            );

        } else if (integration.company === IntegrationCompany.SMOOBU) {
            setSelectedSmoobuPropertyLocal(
                { 
                    value:integration.host_id, 
                    label:integration.host_name, 
                    isDisabled: false 
                }
            );

        } else if (integration.company === IntegrationCompany.AVANTIO || 
            integration.company === IntegrationCompany.AVAIBOOK) {
            setSelectedIcalLinkLocal(
                { 
                    value:integration.uid, 
                    label:integration.link, 
                    isDisabled: false 
                }
            );

        }

        setEnableProtectGuestLocal(integration.enableProtectGuest);

        if (integration.groups?.length) {
            for (var i=0; i<integration.groups.length; i++) {
                let integrationGroup = integration.groups[i];
                let group = groups.filter(group => group.id === integrationGroup.id);
                if (group.length) {
                    let rooms = group[0].invitation.rooms.map(
                        (room) => (
                            { 
                                value: room.uid, 
                                label: room.name, 
                                isDisabled: false
                            }
                        )
                    );
                    setSelectedLocationRoomsLocal(rooms);
                }
            }
        }
    }, [integration, groups]);


    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            <div
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                    className="col-span-full d-flex justify-content-between"
                >
                <div className="c-min-w-40">
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_pms_select_pms_placeholder")}
                        value={selectedPMSLocal}
                        onChange={(selectedPMS: any) =>
                            onChangePMS(selectedPMS)
                        }
                        isDisabled={!integration.editable}
                        options={availablePMS}
                    />
                </div>
            </div>

            {hostifyInput()}
            {krossBookingInput()}
            {smoobuInput()}
            {avantioInput()}
            {octorateInput()}
            {avaibookInput()}
            
        </div>
    );

    function hostifyInput() {
        if (selectedPMSLocal?.label !== IntegrationCompany.HOSTIFY) {
            return null;
        }

        return (
            <div>
               <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_property")}
                        value={selectedHostifyPropertyLocal}
                        onChange={(selectedHostifyProperty: any) =>
                            onChangeHostifyProperty(selectedHostifyProperty)
                        }
                        isDisabled={!integration.editable || availableHostifyProperties.length === 0}
                        options={availableHostifyProperties}
                        />
                </div>
                {footerInput()}
            </div>
        )
    }

    function krossBookingInput() {
        if (selectedPMSLocal?.label !== IntegrationCompany.KROSS_BOOKING) {
            return null;
        }

        return (
            <div>
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_property")}
                        value={selectedKrossBookingPropertyLocal}
                        onChange={(selectedKrossBookingProperty: any) =>
                            onChangeKrossBookingProperty(selectedKrossBookingProperty)
                        }
                        isDisabled={availableKrossBookingProperties.length === 0}
                        options={availableKrossBookingProperties}
                        />
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_room")}
                        value={selectedKrossBookingRoomLocal}
                        onChange={(selectedRoom: any) =>
                            onChangeKrossBookingRoom(selectedRoom)
                        }
                        isDisabled={availableKrossBookingRooms.length === 0}
                        options={availableKrossBookingRooms}
                        />
                </div>
                
                {footerInput()}
            </div>
        )
    }

    function smoobuInput() {
        if (selectedPMSLocal?.label !== IntegrationCompany.SMOOBU) {
            return null;
        }

        return (
            <div>
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_property")}
                        value={selectedSmoobuPropertyLocal}
                        onChange={(selectedProperty: any) =>
                            onChangeSmoobuProperty(selectedProperty)
                        }
                        isDisabled={availableSmoobuProperties.length === 0}
                        options={availableSmoobuProperties}
                        />
                </div>
                {footerInput()}
            </div>
        )
    }

    function avantioInput() {
        if (selectedPMSLocal?.label !== IntegrationCompany.AVANTIO) {
            return null;
        }

        return (
            <div>
               <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_ical_url")}
                        value={selectedIcalLinkLocal}
                        onChange={(selectedIcalUrl: any) =>
                            onChangeIcalLink(selectedIcalUrl)
                        }
                        isDisabled={!integration.editable || availableAvantioIcalLinks.length === 0}
                        options={availableAvantioIcalLinks}
                        />
                </div>
                {footerInput()}
            </div>
        )
    }

    function octorateInput() {
        if (selectedPMSLocal?.label !== IntegrationCompany.OCTORATE) {
            return null;
        }

        return (
            <div>
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_property")}
                        value={selectedOctorateAccomodationLocal}
                        onChange={(selectedProperty: any) =>
                            onChangeOctorateAccomodation(selectedProperty)
                        }
                        isDisabled={availableOctorateAccomodations.length === 0}
                        options={availableOctorateAccomodations}
                        />
                </div>
                {footerInput()}
            </div>
        )
    }

    function avaibookInput() {
        if (selectedPMSLocal?.label !== IntegrationCompany.AVAIBOOK) {
            return null;
        }

        return (
            <div>
               <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_ical_url")}
                        value={selectedAvaibookAccommodationLocal}
                        onChange={(selectedAccomodation: any) =>
                            onChangeAvaibookAccommodations(selectedAccomodation)
                        }
                        isDisabled={!integration.editable || availableAvaibookAccommodations.length === 0}
                        options={availableAvaibookAccommodations}
                        />
                </div>
                {footerInput()}
            </div>
        )
    }

    function footerInput() {
        return (
            <div className="mt-4">
                <div className="c-min-w-40">
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_room")}
                        isMulti
                        value={selectedLocationRoomsLocal}
                        onChange={(selectedRooms: any) =>
                            onChangeRoomsGroup(selectedRooms)
                        }
                        isDisabled={!integration.editable}
                        options={availableLocationRooms}
                    />
                </div>
                {location.vacationRentalPlan === 3 &&
                    <div className="mt-2 mb-2 cp d-flex align-items-center justify-content-between">
                        <div className="position-relative">
                            {t("mod_pms_enable_guest_protect_title")}
                        </div>
                        <div className="ml-2"></div>
                        <SimpleToggle
                            readOnly={!integration.editable}
                            checked={enableProtectGuestLocal}
                            handleChange={({ detail: { value } }) => {
                                setToggleProtect(value);
                            }}
                        ></SimpleToggle>
                    </div>
                }
                <div className="mt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        {(integration.uid) &&
                            <CapsuleButton
                                onClick={() => onDelete(index)}
                                classNameExtra="mr-2"
                                // eslint-disable-next-line react/style-prop-object
                                style="DANGER"
                                size="SMALL"
                                icon={Icons.trash}
                            />
                        }
                        {!integration.uid &&
                            <CapsuleButton
                                size="SMALL"
                                isEnabled={canSave}
                                onClick={onSave}
                                text={t("global_save")}
                            />
                        }
                    </div>
                    {isRequestingForSave && <WaitSpinner />}
                </div>
            </div>
            
        )
    }


}