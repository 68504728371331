import { ServiceHelper } from './ServiceHelper';
import { URL_CONSTANTS } from "../_constants";

export const dashboardService = {
    getWidgetsFromGroup,
    createWidget,
    updateWidget,
    deleteWidget,

    getGroups,
    createGroup,
    updateGroup,
    deleteGroup,
};

function getWidgetsFromGroup(groupId) {
    const parameters = "?group_uid=" + groupId;
    return ServiceHelper.getRequest(URL_CONSTANTS.WIDGET_LIST, parameters);
}

function createWidget(groupId, widgetType, settings, order) {
    const body = {
        group_uid: groupId,
        type: widgetType,
        settings: settings,
        order: order,
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.WIDGET_CREATE, body);
}

function updateWidget(widgetId, settings, order) {
    const body = {
        uid: widgetId,
        settings: settings,
        order: order,
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.WIDGET_UPDATE, body);
}

function deleteWidget(widgetId) {
    const body = {
        uid: widgetId,
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.WIDGET_DELETE, body);
}


function getGroups(locationUid) {
    const parameters = "?loc_uid=" + locationUid;
    return ServiceHelper.getRequest(URL_CONSTANTS.WIDGET_GROUP_LIST, parameters);
}

function createGroup(locationUid, name, order) {
    const body = {
        loc_uid: locationUid,
        name: name,
        order,
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.WIDGET_GROUP_CREATE, body);
}

function updateGroup(groupUid, name, order) {
    const body = {
        uid: groupUid,
        name: name,
        order,
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.WIDGET_GROUP_UPDATE, body);
}

function deleteGroup(groupId) {
    const body = {
        uid: groupId,
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.WIDGET_GROUP_DELETE, body);
}