import {
    getCategoryForDeviceType,
    infoCellForDeviceType,
} from "../_constants";
import { LogsHelper } from "../_helpers/LogsHelper";
import { TimeHelper } from "../_helpers/TimeHelper";
import { Device } from "../_models/Device";
import { DeviceHelper } from "../_helpers";

export class DeviceMapper {
    static serverToLocal(serverDevice, locationName, roomName) {
        return Device({
            bluetoothId: serverDevice.bluetooth_id,
            battery: serverDevice.battery,
            uid: serverDevice.uid,
            description: descriptionServerToLocal(serverDevice.desc),
            loc_uid: serverDevice.loc_uid,
            room_uid: serverDevice.room_uid,
            kit_uid: serverDevice.kit_uid,
            status: statusServerToLocal(serverDevice.status),
            type: serverDevice.type,
            timer: serverDevice.timer,
            update: serverDevice.update,
            originalType: serverDevice.original_type,
            version: serverDevice.version,
            lastActivity: serverDevice.last_activity ? TimeHelper.serverStringToIsoString(serverDevice.last_activity) : null,
            location_name: locationName,
            room_name: roomName,
            category: getCategoryForDeviceType(serverDevice.type),
            cellType: infoCellForDeviceType(serverDevice.type),
            statusLabel: LogsHelper.getStatusLabelFromDevice(serverDevice.type, statusServerToLocal(serverDevice.status)),
            notification: serverDevice.notification,
            accessUsageCount: serverDevice.access_usage_count,
            isControlAccessDisabled: serverDevice.disable_control_access,

            accessType: serverDevice.access_type,

            accessEntryWalk:    serverDevice.access_entry_walk,
            accessEntryVehicle: serverDevice.access_entry_vehicle,
            accessExitWalk:     serverDevice.access_exit_walk,
            accessExitVehicle:  serverDevice.access_exit_vehicle,

            txPower: serverDevice.tx_power,
            accessLevel: serverDevice.access_level,
            extender: serverDevice.extender,
            remote: serverDevice.remote,
            beacon: serverDevice.beacon,
            isBarrier: serverDevice.is_barrier,

            installationNotes: serverDevice.installation_notes,

            localStatus: DeviceHelper.getLocalStatusForDevice(
                serverDevice.type,
                serverDevice.status,
            ),

            accessUsageNotification:    serverDevice.access_usage_notification,
            updateVersion:              serverDevice.update_version,
            isNfcCardsSynchronized:     serverDevice.synchronized_nfc_cards,
            isCalibrated:               serverDevice.calibrated,

            wlan_ssid: DeviceHelper.getWLanSSIDForDevice(serverDevice.type, serverDevice.network_parameters),
            wifi_rssi: DeviceHelper.getWifiRssiForDevice(serverDevice.type, serverDevice.network_parameters),
            wlan_status: DeviceHelper.getWLanStatusForDevice(serverDevice.type, serverDevice.network_parameters),
            eth_status: DeviceHelper.getEthernetStatusForDevice(serverDevice.type, serverDevice.network_parameters),
            conn_status: DeviceHelper.getConnectionStatusForDevice(serverDevice.type, serverDevice.network_parameters),
                
            streamMinutesRemaining: serverDevice.stream_minutes_remaining && Math.trunc(serverDevice.stream_minutes_remaining),

            versionWifi: serverDevice.version_wifi,
            versionBle: serverDevice.version_ble,

            updating: serverDevice.updating,
            updateStatus: serverDevice.update_status,
            updatePercent: serverDevice.update_percent,

            restrictExit: serverDevice.restrict_exit,

            // GowayLock 
            knockTimeout: serverDevice.knock_timeout,
            lockPower: serverDevice.lock_power,
            isIrEnabled: serverDevice.is_ir_enabled
        });
    }

    static serverLocationinfoToLocalDevices(serverLocationInfo) {
        const localDevices = [];
        const locationName = serverLocationInfo.name;
        serverLocationInfo.rooms.forEach(room => {
            const roomName = room.name;
            localDevices.push(
                ...room.devices
                .map(serverDevice =>
                    DeviceMapper.serverToLocal(
                        serverDevice,
                        locationName,
                        roomName,
                    )
                )
            );
        });
        
        return localDevices;
    }
}

function descriptionServerToLocal(serverDescription) {
    return serverDescription.replace(/\u00a0/g, " ");
}

function statusServerToLocal(serverStatus) {
    let localStatus = serverStatus;
    if (localStatus === null)
        localStatus = 0;

    return localStatus;
}