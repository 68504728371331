import { UserLog } from "../_models/UserLog";

export class UserLogMapper {
    static allServerToLocal(serverUserLogs = []) {
        return serverUserLogs.map(serverToLocal);
    }
}

function serverToLocal(serverUserLog) {
    return UserLog({
        datetime: serverUserLog.datetime,
        latitude: serverUserLog.latitude,
        longitude: serverUserLog.longitude,
        logType: serverUserLog.log_type,
        locationName: serverUserLog.location_name,
        uid: serverUserLog.log_id,
    });
}