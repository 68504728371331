import { ORDER_STATUS_UI } from "../_constants";
import { AllOrders, HistoricOrder, ServerUpcomingOrder, UpcomingOrder, ServerAllOrders, ServerHistoricOrder, OrderLine } from "../_modelsTS/Order";

export class AllOrdersMapper {
    static serverToLocal(allServerOrder: ServerAllOrders): AllOrders {
        return {
            historic: allServerOrder.historic.map(order => OrderMapper.serverToLocalHistoric(order)),
            upcoming: OrderMapper.serverToLocalUpcoming(allServerOrder.upcoming)
        }
    }
}

export class OrderLineMapper {
    static serverToLocal(line: OrderLine) {
        return { ...line, price: parseFloat((line.price / 100).toFixed(2)) }
    }
}
export class OrderMapper {

    static serverToLocalUpcoming({
        lines,
        next_payment_attempt,
        period_start,
        period_end,
        status,
        tax,
        total,
        metadata
    }: ServerUpcomingOrder): UpcomingOrder {
        return {
            status: ORDER_STATUS_UI[status],
            tax,
            total: parseFloat((total/100).toFixed(2)),
            metadata,
            lines: lines.map(OrderLineMapper.serverToLocal),
            periodEnd: new Date(period_end * 1000),
            periodStart: new Date(period_start * 1000),
            nextPaymentAttempt: next_payment_attempt ? new Date(next_payment_attempt * 1000) : null,
        }
    }

    static serverToLocalHistoric({
        attempt_count,
        attempted,
        created,
        currency,
        due_date,
        hosted_invoice_url,
        invoice_pdf,
        lines,
        metadata,
        next_payment_attempt,
        number,
        paid,
        period_start,
        period_end,
        status,
        tax,
        total,
    }: ServerHistoricOrder): HistoricOrder {
        return {
            attempted,
            currency,
            number,
            paid,
            attemptCount: attempt_count,
            created: new Date(created * 1000),
            dueDate: new Date(due_date * 1000),
            hostedInvoiceUrl: hosted_invoice_url,
            invoicePdf: invoice_pdf,
            ...this.serverToLocalUpcoming({
                lines, next_payment_attempt, period_end, period_start, status, tax,
                total, metadata
            })
        }
    }

}
