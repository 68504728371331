import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { BaseGlobalPopUp } from "../_components/BaseGlobalPopUp";
import { PopUpStore } from "../_stores/PopUpStore";

type CommonGlobalPopUpProps = {
    acceptButtonTestID?: string;

    title?: string;
    body?: any;
    onAccept?: (...args: any) => any | void;
    onCancel?: (...args: any) => any | void;
    acceptButtonName?: string;
    cancelButtonName?: string;

    closeOnAccept?: boolean;
    hideAccept?: boolean;

    overflowVisible?: boolean;
    limitWidth?: boolean;
    disableAccept?: boolean;
};

export function CommonGlobalPopUp({
    acceptButtonTestID,

    title,
    body,
    onAccept = () => {},
    onCancel = async () => {},
    acceptButtonName,
    cancelButtonName,

    closeOnAccept = true,
    hideAccept,

    overflowVisible,
    limitWidth,
    disableAccept
}: CommonGlobalPopUpProps) {
    //LIBRARIES
    const dispatch = useDispatch();

    //LOCAL STATE
    const [isProcessing, setIsProcessing] = useState(false);

    //ACTION HANDLERS
    async function handleCancel() {
        setIsProcessing(true);
        await onCancel();
        setIsProcessing(false);
        dispatch(PopUpStore.actionHide());
    }

    function handleAccept() {
        if (closeOnAccept) dispatch(PopUpStore.actionHide());

        onAccept();
    }


    //VIEW
    return (
        <BaseGlobalPopUp
            acceptButtonTestID={acceptButtonTestID}
            title={title}
            body={body}
            onAccept={handleAccept}
            onCancel={handleCancel}
            acceptButtonName={acceptButtonName}
            cancelButtonName={cancelButtonName}
            hideAccept={hideAccept}
            overflowVisible={overflowVisible}
            limitWidth={limitWidth}
            areControlsBlocked={isProcessing}
            testID={undefined}
            disableAccept={disableAccept}
        />
    );
}
