import { ReservationConfig } from "../_models/ReservationConfig";
import { TimeHelper } from "./TimeHelper";

export const ReservationsHelper = {
    isIsoDateExcluded,
};

function isIsoDateExcluded(isoDate, timezone, reservationConfig = ReservationConfig()) {
    const localizedIsoDateStart = TimeHelper.getStartOfDayInTimezoneIso(isoDate, timezone);
    return reservationConfig.excludedDays
        .some(excludedLocalIsoDate => {
            const excludedLocalizedIsoDateStart = TimeHelper.removeTimezoneFromIsoString(
                excludedLocalIsoDate,
                timezone,
            );
            return TimeHelper.areSameDay(
                localizedIsoDateStart,
                excludedLocalizedIsoDateStart,
            )
        })
    ;
}