import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { EmptyGenericIntegration } from "../../../Everou/_modelsTS/Integration";
import { Selectors } from "../../../Everou/_reducers/app.reducer";
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";
import { IntegrationConfigInput } from "./IntegrationConfigInput";
import { PMSLocationsActions } from "../../_actions/PMSLocationsActions";
import { removeFromArr } from "../../../Common/_utils/utils";

export function IntegrationConfig() {
    
    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // GLOBAL STATE
    const locationUid = useSelector(Selectors.getPMSLocationSelectedUid);
    const integrationTemplates = useSelector(Selectors.getPMSIntegrations);
    let locationIntegrations = [];
    locationIntegrations = useSelector(Selectors.getPMSLocationIntegrations);
    const [integrations, setIntegrations] = useState(buildIntegrations(locationIntegrations));
   
    const locationGroups = useSelector(Selectors.getPMSLocationGroups);
    
    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSLocationsActions.getIntegrations());
        dispatch(PMSLocationsActions.getGroupsForLocation());

        // Integration Templates
        dispatch(PMSIntegrationsActions.listIntegrations());
    }, [dispatch, locationUid]);

    useEffect(() => {
        setIntegrations(buildIntegrations(locationIntegrations));
    }, [locationIntegrations]);
    

    const onDeleteIntegration = (index) => {
        const integration = integrations[index];
        if(!integration.uid) {
            let updatedIntegrations = removeFromArr(index, integrations);
            setIntegrations(buildIntegrations(updatedIntegrations));
        } else {
            dispatch(PMSLocationsActions.deleteLocationIntegration(integration.uid))
            let updatedIntegrations = removeFromArr(index, integrations);
            setIntegrations(buildIntegrations(updatedIntegrations));
        }
    };

    return (
        <div className="c-grid c-grid--small rounded-bottom">
            {integrations.map((integration, index) => (
                <IntegrationConfigInput
                    key={integration.uid}
                    groups={locationGroups}
                    integrationTemplates={integrationTemplates}
                    index={index}
                    integration={integration}
                    onDelete={index => onDeleteIntegration(index)}
                />
            ))}
        </div>
    );


    function buildIntegrations(locationIntegrations) {
        if (!locationIntegrations?.length) {
            locationIntegrations = [EmptyGenericIntegration];
        }

        return locationIntegrations;
    }

}