import { ServiceHelper2 } from "../../Common/_helpers/ServiceHelper2";
import { BUDGETS_API_URLS } from "../_constants/url.constants";

export const ProductsService = {
    listProducts,
};

async function listProducts() {
    return await ServiceHelper2.getRequest(
        BUDGETS_API_URLS.PRODUCTS,
    );
}