import React from "react";

export function InputBox({
    inputTestID,
    imgSrc,
    placeholder,
    value,
    handleChange = () => {},
    isReadOnly = false,
} = {}) {
    return (
        <div className="
            d-flex
            c-bd-primary
            rounded
            align-items-center
        ">
            <img
                alt="input icon"
                className="c-img-2rem"
                src={imgSrc}
            />
            <input
                data-testid={inputTestID}
                readOnly={isReadOnly}
                name={placeholder}
                placeholder={placeholder}
                type="text"
                className="
                    w-100
                    
                    py-1
                    px-2

                    c-input
                    c-placeholder-light

                    text-light
                    bg-transparent
                    border-0
                "
                value={value}
                onChange={handleChange}
            />
        </div>
    );
}