import React from "react";


type INPUT_TYPES = 'text' | 'number';

const emptyComponent = () => null;

export interface TextInputComponentProps {
    testID?: string;
    title?: string;
    value?: string;
    onChange?: (...args: any) => void;
    placeholder?: string;
    isEditable?: boolean;
    WarningsComponent?: any;
    type?: INPUT_TYPES;   
    className?: string;
}

export function TextInputComponent({
    testID,

    className = '',
    value,
    onChange = () => {},
    placeholder,
    isEditable = true,
    WarningsComponent = emptyComponent,

    type = 'text',
}: TextInputComponentProps) {

    //VIEW
    return (
            <div className={`${className} d-flex align-items-center position-relative`}>
                {inputField()}
                <WarningsComponent />
            </div>
    );


    function inputField() {
        return (
            <input
                data-testid={testID}
                onChange={e => onChange(e.target.value)}
                type={type}
                value={value || ""}
                placeholder={placeholder}
                className="
                    w-100
                    
                    px-2
                    py-1
                    rounded
                    border
                "
                readOnly={!isEditable}
            />
        );
    }
}