import { SubscriptionsSecondaryViewStore } from "../_stores/SubscriptionsSecondaryViewStore";
import { i18n } from "../_translations/i18n";


export const SubscriptionUtils = {
    getLabelForType,
    getDescriptionForType,
    getLabelForStatus,
    getTagForRenewalPeriod
}

export const SUBSCRIPTIONS_ACTION_TYPES = {
    CHANGE_SECONDARY_VIEW: SubscriptionsSecondaryViewStore.ACTION_TYPES.SET,

    GET_SUBSCRIPTIONS:              "SUBSCRIPTIONS_GET_SUBSCRIPTIONS",
    SELECT_SUBSCRIPTION:            "SUBSCRIPTIONS_SELECT_SUBSCRIPTION",
    UPDATE_SELECTED:                "SUBSCRIPTIONS_UPDATE_SELECTED",
    SAVE_CHANGES_TO_SERVER_SUCCESS: "SUBSCRIPTIONS_SAVE_SELECTED_TO_SERVER_SUCCESS",
    VALIDATE_SUCCESS:               "SUBSCRIPTIONS_VALIDATE_SUCCESS",
    UPDATE_SUBSCRIPTION_SUCCESS:    "UPDATE_SUBSCRIPTION_SUCCESS",
};

export const SUBSCRIPTION_TYPES = {
    UNLIMITED_AND_RESERVATIONS: 1,
    CLOCKINS: 2,
    PROTECT: 3
};

export const SUBSCRIPTION_STATUS = {
    NEW:            1,
    ACTIVE:         2,
    CANCELLED:      3,
    EXPIRED:        4,
};

export const SUBSCRIPTION_RENEWAL_PERIODS = {
    MONTHLY:    1,
    YEARLY:     2,
};

export const SUBSCRIPTIONS_SECONDARY_VIEWS = {
    DETAILS: "DETAILS",
    ADD: "ADD",
    NEW_SUBSCRIPTION_CONFIG: "NEW_SUBSCRIPTION_CONFIG",
};

export const ACTIVATE_SUBSCRIPTION_RESULTS = {
    CREATED_NEW:        0,
    EXTENDED_EXISTING:  1,
};


function getLabelForType(type) {
    const labelForType = {
        [SUBSCRIPTION_TYPES.UNLIMITED_AND_RESERVATIONS]: i18n.t("subscription_type_description_unlimited_uses_and_reservations"),
        [SUBSCRIPTION_TYPES.CLOCKINS]: i18n.t("subscription_type_description_clockins"),
        [SUBSCRIPTION_TYPES.PROTECT]: i18n.t("subscription_protect_mode_title"),
    };

    return labelForType[type] || "UNKNOWN TYPE";
}

function getDescriptionForType(type) {
    const descriptionLabel = {
        [SUBSCRIPTION_TYPES.UNLIMITED_AND_RESERVATIONS]: i18n.t("subscription_type_description_unlimited_uses_and_reservations"),
        [SUBSCRIPTION_TYPES.CLOCKINS]: null,
        [SUBSCRIPTION_TYPES.PROTECT]: i18n.t("subscription_protect_mode_description"),
    };
    return  descriptionLabel[type] !== undefined ? descriptionLabel[type] : 'UNKNOWN TYPE';
}

function getLabelForStatus(status) {
    const labelForStatus = {
        [SUBSCRIPTION_STATUS.NEW]: i18n.t("common.new"),
        [SUBSCRIPTION_STATUS.ACTIVE]:  i18n.t("common.active"),
        [SUBSCRIPTION_STATUS.CANCELLED]: i18n.t("common.cancelled"),
        [SUBSCRIPTION_STATUS.EXPIRED]: i18n.t("common.expired"),
    };

    return  labelForStatus[status] || "UNKNOWN STATUS";
}

function getTagForRenewalPeriod(renewalPeriod) {
    const TAG_FOR_RENEWAL_PERIOD = {
        [SUBSCRIPTION_RENEWAL_PERIODS.MONTHLY]: i18n.t("global_monthly"),
        [SUBSCRIPTION_RENEWAL_PERIODS.YEARLY]:  i18n.t("global_yealy"),
    };

    return TAG_FOR_RENEWAL_PERIOD[renewalPeriod] || "UNKNOW PERIOD";
}