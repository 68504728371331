import { useRef } from "react";
import { Icons } from "../../_assets/icons/Icons";
import "./styles.scss";

type CustomSearchBarProps = {
    disabled?: boolean;
    onChange?: (value) => void;
    placeholder?: string;
    className?: string;
    style?: React.CSSProperties;
    dynamicPlacerHolder?: string;
    type?: "text" | "number";
    value?: string;
};

export function CustomSearchBar({
    disabled = false,
    onChange = () => {},
    placeholder,
    className = '',
    style,
    value,
    type = "text",
}: CustomSearchBarProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const setFocus = () => {
        inputRef.current && inputRef.current?.focus();
    };

    return (
        <div className={`${className} search-box`}>
            <input
                ref={inputRef}
                type={type}
                style={style}
                value={value}
                className={`input-search`}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
                autoFocus
                autoComplete='off'
            />
            <button className="search-button" onClick={() => setFocus()}>
                <img className="c-img-1rem" src={Icons.search} alt="search" />
            </button>
        </div>
    );
}
