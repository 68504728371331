import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { MainPageWithButtons } from "../_components";
import { Subscription } from "../_models/Subscription";
import { SubscriptionStatusAndDuration } from "./SubscriptionStatusAndDuration";
import { deviceIconForType, Icons } from "../_assets";
import { DeviceHelper } from "../_helpers";
import { subscriptionsActions } from "../_actions/subscriptions.actions";
import { SubscriptionUtils, SUBSCRIPTION_TYPES } from "../_constants/subscriptions.constants";

import subscriptionsBigIcon from "../_assets/appIcons/subscriptions-icon-big.png";
import { ListedGroup } from "../_components/ListedGroup";
import { Selectors } from "../_reducers/app.reducer";

const testIDs = {
    root: "subscriptions-main-page",
    listContainer: "subscriptions-list-container",
    listedSubscriptionElement: "subscriptions-list-element",
};

SubscriptionsMainPage.testIDs = testIDs;

export function SubscriptionsMainPage() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    //GLOBAL STATE
    const timezone      = useSelector(Selectors.getTimezone);
    const subscriptions = useSelector(Selectors.getSubscriptions);
    const selected      = useSelector(Selectors.getSelectedSubscription);
    const devices       = useSelector(Selectors.getSelectedLocationDevices);
    
    //ACTION HANDLERS
    function handleSelectSubscription(subscriptionUid) {
        dispatch(subscriptionsActions.selectSubscription(subscriptionUid));
    }

    //VIEW
    return (
        <MainPageWithButtons
            dataTestId={testIDs.root}
            pageTitle={t("common.subscriptions")}
            content={mainContent()}
        />
    );

    function mainContent() {
        return (
            <div className="
                h-100
                d-flex
                flex-column
                overflow-auto

                pt-2
            ">
                {subscriptions.length === 0
                    ? noSubscriptionsPlaceholder()
                    : subcriptionsList()
                }
            </div>
        );
    }

    function noSubscriptionsPlaceholder() {
        return (
            <div className="
                d-flex
                flex-column
                no-gutters
                justify-content-center

                align-items-center
            ">
                <img
                    src={subscriptionsBigIcon}
                    alt={"subscriptions icon big"}
                    className="
                        col-3
                        c-opacity-50
                    "
                />
                <div className="text-center">
                    {t("subscriptions.noSubscriptionsFound")}
                </div>
            </div>
        );
    }

    function subcriptionsList() {
        return <ListedGroup
            testID={testIDs.listContainer}
            elements={subscriptions.map(buildSubscription)}
        />;
    }

    function buildSubscription(subscription = Subscription()) {
        return (
            <div
                data-testid={testIDs.listedSubscriptionElement}
                onClick={() => handleSelectSubscription(subscription.uid)}
                key={subscription.uid}
                className={`
                    d-flex
                    justify-content-between

                    px-2
                    py-1

                    cp

                    ${selected && (selected.uid === subscription.uid)
                        ? "c-bg-primary-light-2"
                        : "c-hover-240"
                    }
                `}
            >
                <div
                    data-testid={subscription.type}
                    className="d-flex align-items-center"
                >
                    {subscriptionIcon()}
                    <div className="pl-2" />
                    {subscriptionData()}
                </div>
                <SubscriptionStatusAndDuration {...{
                    subscription,
                    timezone,
                    t,
                }}/>
            </div>
        );

        function subscriptionIcon() {
            return (
                <img
                    className="c-h-1-7rem"
                    src={getIconForSubscription(subscription, devices)}
                    alt={"subs_icon"}
                />
            );
        }

        function subscriptionData() {
            return (
                <div>
                    <div>
                        {title()}
                    </div>
                    <div className="
                        c-ft-2xs
                        text-secondary
                        c-text-capitalize-first
                    ">
                        {subtitle()}
                    </div>
                </div>
            );

            function title() {
                switch (subscription.type) {
                    case SUBSCRIPTION_TYPES.UNLIMITED_AND_RESERVATIONS:
                        return getDeviceName();

                    case SUBSCRIPTION_TYPES.CLOCKINS:
                    case SUBSCRIPTION_TYPES.PROTECT:
                        return subscription.typeLabel;

                    default:
                        return "UNKNOWN TYPE";
                }
            }

            function subtitle() {
                return SubscriptionUtils.getDescriptionForType(subscription.type)
            }

            function getDeviceName() {
                if (subscription.deviceUid === null)
                    return t("subscriptions.noDeviceAssigned");
                
                return DeviceHelper.getNameForUidFromDevices(
                    subscription.deviceUid,
                    devices,
                );
            }
        }
    }

    //HELPERS
    function getIconForSubscription(subscription = Subscription(), devices = []) {
        switch (subscription.type) {
            case SUBSCRIPTION_TYPES.UNLIMITED_AND_RESERVATIONS:
                return getDeviceIconFromUid(subscription.deviceUid, devices);
                
            case SUBSCRIPTION_TYPES.CLOCKINS:
                return Icons.clock;

            case SUBSCRIPTION_TYPES.PROTECT:
                return Icons.protect

            default:
                return Icons.missing;
        }

        function getDeviceIconFromUid(deviceUid, devices) {
            return deviceIconForType(
                DeviceHelper.getTypeForUidFromDevices(
                    deviceUid,
                    devices,
                ),
            );
        }
    }
}