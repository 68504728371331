import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    authenticationActions,
    locationActions,
    websocketsActions,
} from '../_actions';
import { globalActions } from '../_actions';
import { URL_CONSTANTS } from '../_constants';
import { ServiceWorkerHelper } from '../_helpers/ServiceWorkerHelper';
import { Header } from './Header';
import { AppContent } from './AppContent';

import './app.scss';
import { NoLocationsView } from './NoLocationsView';
import { WaitSpinner } from '../_components/WaitSpinner';
import { PendingInvitationsModule } from '../PendingInvitationsModule/PendingInvitationsModule';
import { Selectors } from '../_reducers/app.reducer';
import { LocationCustomization } from '../_models/LocationCustomization';
import { ColorHelper } from '../_helpers/ColorHelper';
import { EverouPopUps } from "../GlobalPopUps/AppGlobalPopUpsConfig";
import { WiredPopUpRenderer } from "../../Common/PopUps/WiredPopUpRenderer";
import { WiredUiAlerts } from "../../Common/UiAlerts/WiredUiAlerts";
import { AppWrapper } from "../../Common/_components/AppWrapper";
import { EverouStoreSubscriptions } from '../EverouStoreSubscriptions';
import 'rc-time-picker/assets/index.css';

const testIDs = {
    loadingLocationMarker: "loading-location-marker",
};

App.testIDs = testIDs;

export function App() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const preselectedLocationUid    = useSelector(Selectors.getPreselectedLocationUid);
    const validatedLocationUid      = useSelector(Selectors.getValidatedLocationUid);
    const isAppInitialized          = useSelector(Selectors.getIsAppInitialized);

    let customization               = LocationCustomization();
    customization                   = useSelector(Selectors.getLocationCustomization);

    //LOCAL STATE
    const isValidatingFirstLocation = !!(preselectedLocationUid && !validatedLocationUid);
    const [isLoadingLocation, setIsLoadingLocation] = useState(false);

    //INITIALIZATION
    useEffect(() => {
        EverouStoreSubscriptions.subscribe();
        setUpServiceWorker();
        dispatch(websocketsActions.setUpWebsocket());
        dispatch(globalActions.setAppInitialized());

        async function setUpServiceWorker() {
            const webPushSubscription = await ServiceWorkerHelper.registerWorkerAndSubscribePush(
                "custom-service-worker.js",
                URL_CONSTANTS.PUSH_SERVER_KEY,
            );
            await dispatch(authenticationActions.updateSystemInfo(webPushSubscription));
        }

    }, [dispatch]);

    useEffect(() => {
        if (!isAppInitialized)
            return;

        setIsLoadingLocation(true);
        getLocationsAndValidate();

        async function getLocationsAndValidate() {
            const locations = await dispatch(locationActions.list());
            if (!locations.length)
                setIsLoadingLocation(false);
            
            dispatch(locationActions.validateLocation(
                preselectedLocationUid,
            ));
        }
    }, [dispatch, isAppInitialized, preselectedLocationUid]);

    useEffect(() => {
        ColorHelper.setCustomColorsFromHue(customization && customization.accentColor);
    }, [customization]);

    //VIEW
    return (
        <AppWrapper>
            <WiredPopUpRenderer
                getComponentFn={EverouPopUps.getPopUp}
            />
            {isLoadingLocation &&
                <div className="position-absolute bg-light m-1 text-light"
                    data-testid={App.testIDs.loadingLocationMarker}
                    style={{
                        height: 3,
                        width: 3,
                        borderRadius: "50%",
                    }}
                />
            }
            <Header
                color={customization && customization.headerColor}
                logo={customization && customization.logo}
                isLoadingLocation={isLoadingLocation}
            />
            <WiredUiAlerts />
            <PendingInvitationsModule />
            {content()}
        </AppWrapper>
    );

    function content() {
        if (isValidatingFirstLocation)
            return loadingFirstContentView();

        if (!validatedLocationUid)
            return <NoLocationsView />;
            
        return <AppContent
            validatedLocationUid={validatedLocationUid}
            setIsLoadingLocation={setIsLoadingLocation}
        />;
    }

    function loadingFirstContentView() {
        return (
            <div className="
                h-100
                w-100

                d-flex
                align-items-center
                justify-content-center
            ">
                <WaitSpinner />
            </div>
        );
    }
}