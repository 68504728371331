import { EntrypointService } from "../../_services/EntrypointService";
import { BaseEntrypointStore } from "./BaseEntrypointStore";
import { genericActions } from "../../_helpers/ActionErrorWrapper";

export const EntrypointActions = {
    getEntrypointHost,

    _setEntrypoint: BaseEntrypointStore.actionSet,
};

function getEntrypointHost(subdomain) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const host = await EntrypointService.getEntryPoint(subdomain);
            dispatch(EntrypointActions._setEntrypoint(host));
        }
    }
}