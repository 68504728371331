import { DeviceMapper } from "./DeviceMapper";
import { UserStatusDeviceData } from "../_models/UserStatusDeviceData";
import { UserStatusUsageDataMapper } from "./UserStatusUsageDataMapper";

export class UserStatusDeviceDataMapper {
    static allServerToLocal(serverDevicesData = []) {
        return serverDevicesData.map(serverToLocal);
    }
}

function serverToLocal(serverDeviceData) {
    return UserStatusDeviceData({
        deviceInfo: DeviceMapper.serverToLocal(serverDeviceData.device_info),
        usageData: UserStatusUsageDataMapper.serverToLocal(serverDeviceData.usage_data),
    });
}