import { USER_LOGS_ACTION_TYPES } from "../_constants/userLogs.constants";

const initialstate = {
    friends: [],
    selectedUserUid: null,
    selectedUserLog: null,
};

export function userLogs(state = initialstate, action) {
    switch (action.type) {
        case USER_LOGS_ACTION_TYPES.GET_FRIENDS:
            return {...state,
                friends: action.friends,
            };

        case USER_LOGS_ACTION_TYPES.CHANGE_SELECTED_USER_UID:
            return {...state,
                selectedUserUid: action.selectedUserUid,
            };

        case USER_LOGS_ACTION_TYPES.SELECT_USER_LOG:
            return {...state,
                selectedUserLog: action.selectedUserLog,
            };
        
        default:
            return state;
    }
}