import { AUDIT_LOG_TYPES, filterDisabled, AUDIT_LOG_CODES, LOCAL_STATUS, DEVICE_TYPES, UI_LOG_TYPES } from "../_constants";
import { i18n } from "../_translations/i18n";
import { AuditLog } from "../_models/AuditLog";
import { LogsHelper } from "./LogsHelper";
import { DeviceLogsUiHelper } from "./DeviceLogsUiHelper";
import { DeviceHelper } from "./DeviceHelper";
import { Device } from "../_models/Device";

export class AuditLogsHelper {
    static getTypeUiName(typeCode) {
        const stringMapping = {
            [AUDIT_LOG_TYPES.UNDEFINED]:    "UNDEFINED",
            [AUDIT_LOG_TYPES.BRAIN]:        "brain",
            [AUDIT_LOG_TYPES.CAMERA]:       i18n.t("common.camera"),
            [AUDIT_LOG_TYPES.DEVICE]:       i18n.t("common.device"),
            [AUDIT_LOG_TYPES.INVITATION]:   i18n.t("common.invitation"),
            [AUDIT_LOG_TYPES.LOCATION]:     i18n.t("common.location"),
            [AUDIT_LOG_TYPES.MOBILE]:       i18n.t("common.mobile"),
            [AUDIT_LOG_TYPES.ROOM]:         i18n.t("common.room"),
            [AUDIT_LOG_TYPES.TRIGGER]:      i18n.t("common.triggers"),
            [AUDIT_LOG_TYPES.USER]:         i18n.t("common.user"),
            [AUDIT_LOG_TYPES.WIDGET]:       "widget",
        };

        return stringMapping[typeCode];
    }

    static sliceUrlFromSlash(string, slashPosition) {
        return string && string.slice(
            getPositionOfAtFrom("/", slashPosition, string)
        );
    }

    static getAuditLogDescription(auditLogPath, isServerPath) {
        if (isServerPath)
            auditLogPath = AuditLogsHelper.sliceUrlFromSlash(auditLogPath, 3);

        return getUiValueForPath(auditLogPath);
    }

    static getNewParentPathUiValue(parentPath) {
        const stringMapping = {
            [AUDIT_PATHS_PARENTS.BRAIN]:        i18n.t("global_brain"),
            [AUDIT_PATHS_PARENTS.CAMERA]:       i18n.t("common.camera"),
            [AUDIT_PATHS_PARENTS.DEVICE]:       i18n.t("common.device"),
            [AUDIT_PATHS_PARENTS.INVITATION]:   i18n.t("common.invitation"),
            [AUDIT_PATHS_PARENTS.LOCATION]:     i18n.t("common.location"),
            [AUDIT_PATHS_PARENTS.ROOM]:         i18n.t("common.room"),
            [AUDIT_PATHS_PARENTS.TRIGGER]:      i18n.t("common.triggers"),
        };

        return stringMapping[parentPath];
    }

    static getPathsForParent(parentPath) {
        return getPathsForParent(parentPath);
    }

    static getPath(type, actionPath) {
        if (type !== filterDisabled.number
            && actionPath !== filterDisabled.number) {
            return actionPath;
        }

        if (type !== filterDisabled.number
            && actionPath === filterDisabled.number) {
            return type;
        }

        return;
    }

    static getAuditLogDeviceStatusLabel = getAuditLogDeviceStatusLabel;
}

function getAuditLogDeviceStatusLabel(auditLog = AuditLog(), devices = [] || [Device()]) {
    if (auditLog.code !== AUDIT_LOG_CODES.OK)
        return null;

    if (!isBluetoothActionAuditLog())
        return null;

    if (auditLog.content.beacon_detected_status)
        return beaconStatus(auditLog.content.beacon_detected_status);

    if (!auditLog.content.status)
        return null;

    return getGarageDoorStatusLabel(auditLog.content.status) || null;
    
    function isBluetoothActionAuditLog() {
        const slicedPath = AuditLogsHelper.sliceUrlFromSlash(auditLog.path, 3);
        return slicedPath === AUDIT_PATHS_FULL.DEVICE_ACTION_BLUETOOTH;
    }

    function beaconStatus(status) {
        return DeviceLogsUiHelper.getLocalStatusLabel(
            LogsHelper.getLocalStatus(
                UI_LOG_TYPES.BEACON_DETECTED,
                status,
            )
        );
    }

    function getGarageDoorStatusLabel(status) {
        if (!isDeviceGarageDoor(auditLog.content.uid))
            return null;

        const statuses = {
            1: LOCAL_STATUS.OPEN,
            2: LOCAL_STATUS.ENTRY,
            3: LOCAL_STATUS.EXIT,
        };

        return DeviceLogsUiHelper.getLocalStatusLabel(statuses[status]);

        function isDeviceGarageDoor(deviceUid) {
            const deviceType = DeviceHelper.getTypeForUidFromDevices(
                deviceUid,
                devices,
            );

            return deviceType === DEVICE_TYPES.GarageDoor;
        }
    }
}

export const AUDIT_PATHS_PARENTS = {
    BRAIN:      "/brain",
    CAMERA:     "/camera",
    DEVICE:     "/device",
    INVITATION: "/invitation",
    LOCATION:   "/location",
    ROOM:       "/room",
    TRIGGER:    "/trigger",
};

export const AUDIT_PATHS_FULL = {
    BRAIN_LINK:             "/brain/link",                     
    BRAIN_PAIRING:          "/brain/mode/add/device",          
    BRAIN_REBOOT:           "/brain/reboot",                   
    BRAIN_REBOOT_BLE:       "/brain/reboot/ble",               
    BRAIN_REMOVE:           "/brain/unlink",                   
    BRAIN_UPDATE:           "/brain/update",                   
    BRAIN_UPDATE_FIRMWARE:  "/brain/update/firmware",   

    CAMERA_VIDEO_RECORD:    "/camera/record/video",            
    CAMERA_VIDEO_DELETE:    "/camera/video/delete",

    DEVICE_ACTION_REMOTE:   "/device/action",                  
    DEVICE_ACTION_BLUETOOTH:"/device/action_mobile",           
    DEVICE:                 "/device/change_consumption_state",
    DEVICE_LINK:            "/device/link",                    
    DEVICE_UNLINK:          "/device/unlink",                  
    DEVICE_UPDATE:          "/device/update",     

    INVITATION_ACCEPT:      "/invitation/accept",              
    INVITATION_BULK_REQUEST:"/invitation/bulk_request",        
    INVITATION_CANCEL:      "/invitation/cancel",              
    INVITATION_REMOVE:      "/invitation/delete",              
    INVITATION_REQUEST:     "/invitation/request",             
    INVITATION_UPDATE:      "/invitation/update",   

    LOCATION_CREATE:        "/location/create",                
    LOCATION_PROTECT_MUTE:  "/location/protect/mute",          
    LOCATION_REMOVE:        "/location/remove",                
    LOCATION_UPDATE:        "/location/update",                
    LOCATION_USER_UPDATE:   "/location/user/update",           
    LOCATION_ZONE_ENTER:    "/location/zone/enter",            
    LOCATION_ZONE_EXIT:     "/location/zone/exit",

    ROOM_CREATE:            "/room/create",                    
    ROOM_DELETE:            "/room/remove",

    TRIGGER_CREATE:         "/trigger/create",                 
    TRIGGER_REMOVE:         "/trigger/delete",                 
    TRIGGER_UPDATE:         "/trigger/update",                 
};

function getPathsForParent(parentPath) {
    return Object.values(AUDIT_PATHS_FULL).filter(string => {
        return getBaseString(string) === parentPath;
    });

    function getBaseString(string) {
        return string.slice(
            0,
            getPositionOfAtFrom("/", 2, string)
        );
    }
}

function getPositionOfAtFrom(symbol, position, string) {
    let lastIndex = 0;
    for (let i = 0; i < position; i++) {
        if (i !== 0)
            lastIndex++;

        lastIndex = string.indexOf(symbol, lastIndex);
    }
    return lastIndex;
}

function getUiValueForPath(auditLogPath) {
    const DESCRIPTION_FOR_PATH = {
        [AUDIT_PATHS_FULL.BRAIN_LINK]:                      i18n.t("webapp_global_add"),
        [AUDIT_PATHS_FULL.BRAIN_PAIRING]:                   i18n.t("webapp_global_pairing"),
        [AUDIT_PATHS_FULL.BRAIN_REBOOT]:                    i18n.t("webapp_global_reboot"),
        [AUDIT_PATHS_FULL.BRAIN_REBOOT_BLE]:                i18n.t("webapp_audit_reboot_ble"),
        [AUDIT_PATHS_FULL.BRAIN_REMOVE]:                    i18n.t("webapp_global_remove"),
        [AUDIT_PATHS_FULL.BRAIN_UPDATE]:                    i18n.t("webapp_global_configure"),
        [AUDIT_PATHS_FULL.BRAIN_UPDATE_FIRMWARE]:           i18n.t("webapp_audit_update_firmware"),
        [AUDIT_PATHS_FULL.CAMERA_VIDEO_RECORD]:             i18n.t("webapp_global_record"),
        [AUDIT_PATHS_FULL.CAMERA_VIDEO_DELETE]:             i18n.t("webapp_global_remove"),
        [AUDIT_PATHS_FULL.DEVICE_ACTION_REMOTE]:            i18n.t("webapp_audit_remote_action"),
        [AUDIT_PATHS_FULL.DEVICE_ACTION_BLUETOOTH]:         i18n.t("webapp_audit_bluetooth_action"),
        [AUDIT_PATHS_FULL.DEVICE]:                          i18n.t("webapp_audit_change_consumption_state"),
        [AUDIT_PATHS_FULL.DEVICE_LINK]:                     i18n.t("webapp_global_add"),
        [AUDIT_PATHS_FULL.DEVICE_UNLINK]:                   i18n.t("webapp_global_remove"),
        [AUDIT_PATHS_FULL.DEVICE_UPDATE]:                   i18n.t("webapp_global_configure"),
        [AUDIT_PATHS_FULL.INVITATION_ACCEPT]:               i18n.t("webapp_global_accept"),
        [AUDIT_PATHS_FULL.INVITATION_BULK_REQUEST]:         i18n.t("webapp_audit_send_with_email"),
        [AUDIT_PATHS_FULL.INVITATION_CANCEL]:               i18n.t("webapp_global_cancel"),
        [AUDIT_PATHS_FULL.INVITATION_REMOVE]:               i18n.t("webapp_global_remove"),
        [AUDIT_PATHS_FULL.INVITATION_REQUEST]:              i18n.t("webapp_audit_send_generic"),
        [AUDIT_PATHS_FULL.INVITATION_UPDATE]:               i18n.t("webapp_global_configure"),
        [AUDIT_PATHS_FULL.LOCATION_CREATE]:                 i18n.t("webapp_global_create"),
        [AUDIT_PATHS_FULL.LOCATION_PROTECT_MUTE]:           i18n.t("webapp_audit_mute_protect"),
        [AUDIT_PATHS_FULL.LOCATION_REMOVE]:                 i18n.t("webapp_global_remove"),
        [AUDIT_PATHS_FULL.LOCATION_UPDATE]:                 i18n.t("webapp_global_configure"),
        [AUDIT_PATHS_FULL.LOCATION_USER_UPDATE]:            i18n.t("webapp_audit_configure_user"),
        [AUDIT_PATHS_FULL.LOCATION_ZONE_ENTER]:             i18n.t("webapp_global_enter"),
        [AUDIT_PATHS_FULL.LOCATION_ZONE_EXIT]:              i18n.t("webapp_global_exit"),
        [AUDIT_PATHS_FULL.ROOM_CREATE]:                     i18n.t("webapp_global_create"),
        [AUDIT_PATHS_FULL.ROOM_DELETE]:                     i18n.t("webapp_global_remove"),
        [AUDIT_PATHS_FULL.TRIGGER_CREATE]:                  i18n.t("webapp_global_create"),
        [AUDIT_PATHS_FULL.TRIGGER_REMOVE]:                  i18n.t("webapp_global_remove"),
        [AUDIT_PATHS_FULL.TRIGGER_UPDATE]:                  i18n.t("webapp_global_configure"),
    };

    return DESCRIPTION_FOR_PATH[auditLogPath] || "UNDEFINED PATH";
}