import { i18n } from "../../Common/_translations";
import { KrossBookingToken } from "./KrossBooking";

export enum IntegrationCompany {
    AVAIBOOK = 'Avaibook',
    HOSTIFY = 'Hostify',
    OCTORATE = 'Octorate',
    GENERIC = 'Generic',
    KROSS_BOOKING = 'Kross Booking',
    THREE_ANGULAR = 'Three Angular',
    SMOOBU = 'Smoobu',
    AVANTIO = 'Avantio',
    BOOKIPRO = 'Bookipro',
}

export enum LinkType {
    API_ENDPOINT,
    ICAL,
    LINK,
    WEBHOOK,
    DEVELOPERS,
}

interface IntegrationGroup {
    name?: string;
    type?: number;
    id: number;
}

export interface ServerReservation {
    id: number;
    uid: string;
    status: number;
    reservation_id: string;
    email: string;
    reservation_start: Date;
    reservation_end: Date;
    created_on: Date;
    modified_on: Date;
    integration: number;
}

export interface Reservation extends Omit<ServerReservation, 'created_on' | 'modified_on' | 'reservation_id' | 'reservation_start' | 'reservation_end'>{
    reservationId: string;
    reservationStart: Date;
    reservationEnd: Date;
    createdOn: Date;
    modifiedOn: Date;
}
export interface ServerIntegration {
    id: number,
    uid: string,
    company: IntegrationCompany;
    integration_type: LinkType;
    invitation_group: IntegrationGroup[];
    link?: string;
    create_public_url?: boolean;
    created_on: Date;
    modified_on: Date;
    apikey?: string;
    host_id?: string|number;
    handler_url?: string;
    hotel_id?:string;
    api_endpoint_token?: string;
    room_id?:number;
    host_name?:string;
    room_name?:string;
    check_in?:string;
    check_out?:string;
    username?:string;
    send_invitation_url?:boolean;
    send_invitation_url_from_pms?:boolean;
    enable_protect_guest?:boolean;
    days_for_invitation_create?:number;
}

export interface ServerIntegrationSend extends Omit<ServerIntegration, 'invitation_group' | 'uid' | 'created_on' | 'id' | 'modified_on'> {
    invitation_group?: number[];
    uid?: string;
}

export interface Integration extends Omit<ServerIntegration, 'uid' | 'invitation_group' | 'integration_type' | 'create_public_url' | 'id' | 'modified_on' | 'created_on'> {
    uid: string;
    type: number;
    groups?: IntegrationGroup[];
    createPublicUrl?: boolean;
    reservations?: Reservation[];
    createdOn?: Date;
    modifiedOn?: Date;
    sendInvitationURL?: boolean;
    sendInvitationURLFromPMS?: boolean;
    enableProtectGuest?: boolean;
    daysForInvitationCreate?: number;
}

export type LocalEditableIntegration = Integration & { editable: boolean };

export interface KrossBookingIntegration {
    uid: string;
    properties?: KrossBookingProperty[]
}

export interface KrossBookingProperty {
    id_property: number,
    name: string,
    city?: string,
    rooms?: KrossBookingRoom[]
}

export interface HostifyListing {
    id: number,
    nickname: string
}

export interface SmoobuApartment {
    id: number,
    name: string
}

export interface KrossBookingRoom {
    id_room: number,
    number: string
}

export interface OctorateAccommodation {
    id: number,
    name: string
}

export interface AvaibookAccommodation {
    id: number,
    name: string
}

export const EmptyGenericIntegration: LocalEditableIntegration = { type: LinkType.LINK, createPublicUrl: false, link: '', uid: '', company: IntegrationCompany.GENERIC, groups: [], editable: true } 
export const EmptyAvaibookIntegration: LocalEditableIntegration = { type: LinkType.API_ENDPOINT, createPublicUrl: false, link: '', uid: '', company: IntegrationCompany.AVAIBOOK, groups: [], editable: true } 
export const EmptyHostifyIntegration: LocalEditableIntegration = { type: LinkType.WEBHOOK, createPublicUrl: false, uid: '', company: IntegrationCompany.HOSTIFY, groups: [], editable: true } 
export const EmptyKrossBookingIntegration: LocalEditableIntegration = { type: LinkType.LINK, createPublicUrl: true, uid: '', company: IntegrationCompany.KROSS_BOOKING, groups: [], editable: true } 
export const EmptyThreeAngularIntegration: LocalEditableIntegration = { type: LinkType.LINK, createPublicUrl: false, uid: '', company: IntegrationCompany.THREE_ANGULAR, groups: [], editable: true } 
export const EmptySmoobuIntegration: LocalEditableIntegration = { type: LinkType.WEBHOOK, createPublicUrl: false, uid: '', company: IntegrationCompany.SMOOBU, groups: [], editable: true }
export const EmptyAvantioIntegration: LocalEditableIntegration = { type: LinkType.ICAL, createPublicUrl: false, uid: '', company: IntegrationCompany.AVANTIO, groups: [], editable: true }
export const EmptyBookiproIntegration: LocalEditableIntegration = { type: LinkType.LINK, createPublicUrl: false, uid: '', company: IntegrationCompany.BOOKIPRO, groups: [], editable: true } 


export const integrationsByCompany = (integrations: Integration[] = [], integrationCompany: IntegrationCompany): LocalEditableIntegration[] => {

    let res = integrations.filter(({ company }) => company === integrationCompany).map(integration => ({...integration, editable: false}));

    if (!res?.length) {
        switch (integrationCompany) {
            case IntegrationCompany.AVAIBOOK: res = [EmptyAvaibookIntegration]; break;
            case IntegrationCompany.HOSTIFY: res = [EmptyHostifyIntegration]; break;
            case IntegrationCompany.KROSS_BOOKING: res = [EmptyKrossBookingIntegration]; break;
            case IntegrationCompany.SMOOBU: res = [EmptySmoobuIntegration]; break;
            case IntegrationCompany.AVANTIO: res = [EmptyAvantioIntegration]; break;
            default: res = [EmptyGenericIntegration]; break;
        }
    }
    
    return res;

}

export const IntegrationReservationStatus = {
    STATUS_PENDING: 0,
    STATUS_CONFIRMED: 1,
    STATUS_ACTIVE: 2,
    STATUS_CANCELLED: 3 
}

export const IntegrationReservationStatusLabel = {
    [IntegrationReservationStatus.STATUS_PENDING]: i18n.t('mod_integrations_reservation_pending'),
    [IntegrationReservationStatus.STATUS_CONFIRMED]: i18n.t('mod_integrations_reservation_confirmed'),
    [IntegrationReservationStatus.STATUS_ACTIVE]: i18n.t('mod_integrations_reservation_active'),
    [IntegrationReservationStatus.STATUS_CANCELLED]: i18n.t('mod_integrations_reservation_cancelled')
}