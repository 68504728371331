import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Title } from "../_components/Title";
import { Selectors } from "../_reducers/app.reducer";
import { ColumnButton } from "../_components";
import { devicesActions } from "../_actions";
import { Device } from "../_models/Device";
import { DeviceFunctionality } from "../_helpers/DeviceFunctionality";
import { deviceIconForType } from "../_assets";
import { Icon } from "../_modelsTS/Icon";

export function DeviceDevicesSelector() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const devices = useSelector(Selectors.getSelectedLocationDevices);
    const isAllDevicesSelected = useSelector(Selectors.getIsAllDevicesSelected);

    return (
        <>
            <Title text={t("devices.devices")}></Title>

            <div
                className="
                my-1
                mr-2
                overflow-auto

                rounded
                c-bd-200
            "
            >
                {buildDevicesButtons()}
            </div>
        </>
    );

    function buildDevicesButtons() {
        return [buildButtonAll(isAllDevicesSelected), ...devicesButtons()];
    }

    function buildButtonAll(active) {
        return (
            <ColumnButton
                key="all"
                name={t("common.all")}
                handleClick={handleselectAllVisibleDevices}
                active={active}
            />
        );
    }

    function devicesButtons() {
        return devices
            .filter((device) =>
                DeviceFunctionality.supportsActivity(device.type),
            )
            .filter((device) => device.show)
            .sort((a, b) => {
                a = a.description.toUpperCase();
                b = b.description.toUpperCase();
                return a < b ? -1 : a > b ? 1 : 0;
            })
            .map(singleDevice);
    }

    function singleDevice({ uid, description, selected, type } = Device()) {
        const icon: Icon = { src: deviceIconForType(type), alt: "device icon" };
        return (
            <ColumnButton
                key={uid}
                name={description}
                handleClick={() => handleDeviceClick(uid)}
                active={!isAllDevicesSelected && selected}
                icon={icon}
            />
        );
    }

    //ACTION HANDLERS
    function handleDeviceClick(deviceUid) {
        dispatch(devicesActions.toggleSelectDevice(deviceUid));
    }

    function handleselectAllVisibleDevices() {
        dispatch(devicesActions.selectAllVisibleDevices());
    }
}
