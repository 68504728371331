import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { devicesActions, filterActions, locationInfoActions } from '../_actions';
import { CapsuleButton, SegmentedControl } from '../_components';
import { deviceFilterModes, DEVICES_SECONDARY_VIEWS, filterModeNames, filterModesTypesId } from '../_constants';
import { appPermissions } from '../_constants/permissions.constants';
import { Selectors } from '../_reducers/app.reducer';
import { RoomsStore } from '../_stores/RoomStore';


export function DeviceFilterSelector() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const filterMode = useSelector(Selectors.getDeviceFilterFilterMode);

    const locationPermission = useSelector(Selectors.getPermissionSelectedLocation);
   
    const onCreateRoom = useCallback(() => {
        dispatch(RoomsStore.actionSelectItem(null));
        dispatch(devicesActions.changeSecondaryView(DEVICES_SECONDARY_VIEWS.ROOM_CONFIG));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const canCreateGroup = useMemo(() => filterMode ===
                deviceFilterModes.ROOMS && appPermissions.canCreateRooms(locationPermission), [filterMode, locationPermission]);

    return (
        <div className="d-flex">
            {canCreateGroup && (
                <CapsuleButton
                    classNameExtra='mr-2'
                    text={t("global_create_room")}
                    onClick={onCreateRoom}
                />
            )}
            <SegmentedControl
                preventDefaultSelect
                defaultSelected={{
                    value: filterMode,
                }}
                onSelect={handleChangeFilterMode}
                segments={segments()}
            />
        </div>
    );

    function segments() {
        return Object.keys(deviceFilterModes).map((filterMode) => ({
            label: filterModeNames[filterMode],
            value: filterMode,
        }));
    }


    function handleChangeFilterMode(filterMode, saveOnServer = true) {
        dispatch(filterActions.changeMode(filterMode));
        if (saveOnServer) {
            try {
                dispatch(
                    locationInfoActions.updateInterfaceType(
                        filterModesTypesId[filterMode],
                    ),
                );
            } catch {}
        }
    }
}
