import React from "react";
import { useDispatch } from 'react-redux';

import { BaseWidget } from "./BaseWidget";
import addIcon from "../_assets/appIcons/add-icon.svg";
import { dashboardActions } from "../_actions/dashboard.actions";
import { SETTINGS_WINDOWS } from "../_constants/dashboard.constants";

export function WidgetAdd() {
    //LIBRARIES
    const dispatch = useDispatch();

    //VIEW
    return (
        <BaseWidget
            content={content()}
            widgetStyle="rounded c-bd-dashed"
        />
    );

    function content() {
        return (
            <div className="
                h-100

                d-flex
                justify-content-center
                align-items-center
            ">
                <div
                    className="
                        rounded
                        c-hover-240
                        c-bd-220
                    "
                    onClick={handleOpenSettings}
                >
                    <img className="c-img-3rem" src={addIcon} alt="add icon"/>
                </div>
            </div>
        );
    }

    function handleOpenSettings() {
        dispatch(dashboardActions.showSettings(SETTINGS_WINDOWS.ADD_WIDGET));
    }
}