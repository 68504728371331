import React from "react";

import adocFile from "../_docs/getting_started.adoc";
import { AdocContent } from "./AdocContent";

export function GettingStarted() {

    //VIEW
    return (
        <div className="h-100 bg-light p-3 mx-auto">
            <div className="
                mx-auto
                h-100
                c-mw-70rem
                c-min-w-20rem
                
                px-4
                
                overflow-auto
                
                bg-white
                border
                rounded
                c-box-shadow-centered
            ">
                <AdocContent adocFile={adocFile} />
            </div>
        </div>
    );
}