import React from "react";
import { useDispatch, useSelector } from 'react-redux';

import { PopUp } from "../_widgets/PopUp";
import { FullHeightSettings } from "../_components/FullHeightSettings";
import { dashboardActions, globalActions } from "../_actions";
import { GLOBAL_POPUPS } from "../_constants";
import { Selectors } from "../_reducers/app.reducer";

export function GroupSettings({ name, id }) {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const settings = useSelector(Selectors.getDashboardSettings);
    const edited = settings.edited;

    //ACTION HANDLERS
    function handleDeleteGroup() {
        dispatch(globalActions.showPopUp(GLOBAL_POPUPS.DELETE_WIDGET_GROUP, { id }));
    }

    function handleChangeName(name) {
        dispatch(dashboardActions.saveTemporarySettings({ name }));
    }

    function handleCloseSettings() {
        dispatch(dashboardActions.hideSettings());
    }

    function handleSaveSettings() {
        dispatch(dashboardActions.saveGroupSettings());
    }

    //VIEW
    return (
        <PopUp
            handleClose={handleCloseSettings}
            content={
                <FullHeightSettings
                    title={"Group settings"}
                    handleClose={handleCloseSettings}
                    handleSaveSettings={handleSaveSettings}
                    edited={edited}
                    content={content()}
                />
            }
        />
    );

    function content() {
        return (
            <div className="h-100 mt-2 d-flex align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                    <span className="pr-2">Name</span>
                    <input className="pl-1" onChange={e => handleChangeName(e.target.value)} value={name}/>
                </div>
                <div className="pr-4"/>
                <div className="d-flex justify-content-center">
                    <span
                        onClick={handleDeleteGroup}
                        className="c-cursor-default px-2 py-1 btn-danger rounded"
                    >
                        Delete group
                    </span>
                </div>
            </div>
        );
    }
}