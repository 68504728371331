export function DeviceFilter({
    id,
    name,
    devicesAmount,
    type
}) {
    return {
        id,
        name,
        devicesAmount,
        type
    };
}