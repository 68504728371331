import { BUDGET_USER_ACTION_TYPES } from "../_constants/budgetUser.constants";

const initialState = {
    budgetUser: null,
};

export function budgetUserReducer(state = initialState, action) {
    switch (action.type) {
        case BUDGET_USER_ACTION_TYPES.BUDGET_USER_GET_SUCCESS:
            return {
                ...state,
                budgetUser: action.budgetUser,
            };
        
        default:
            return state;
    }
}