import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ContentSpace, MainPageWithButtons } from "../_components";
import { mobilesActions } from "../_actions/mobiles.actions";
import { UserMobiles } from "../_models/UserMobiles";

import { MobilesListPage } from "./MobilesListPage";
import { MobilesHelper } from "../_helpers/MobilesHelper";
import { CircleAvatar } from "../_components/CircleAvatar";
import { Selectors } from "../_reducers/app.reducer";
import { CustomDropdown } from "../_components/CustomDropdown";
import { UiMobileHelper } from "./UiMobileHelper";
import { FILTER_DISABLED_ALL } from "../_constants";

const userMobilesArray = [UserMobiles()];

export function UserMobilesModule() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);
    const selectedId    = useSelector(Selectors.getSelectedMobileId);

    let allUsersMobiles = userMobilesArray;
    allUsersMobiles     = useSelector(Selectors.getMobiles);

    //LOCAL STATE
    const [mobileTypeFilter, setMobileTypeFilter] = useState(FILTER_DISABLED_ALL.value);
    const [mobileVersionFilter, setMobileVersionFilter] = useState(FILTER_DISABLED_ALL.value);
    
    const mobileTypes = UiMobileHelper.getUniqueTypesFromMobiles(allUsersMobiles);
    
    const filteredVersions = useMemo(() =>
        UiMobileHelper.getFilteredVersions(allUsersMobiles, mobileTypeFilter)
    , [allUsersMobiles, mobileTypeFilter]);
    
    const isTypeFilterEnabled = mobileTypeFilter !== FILTER_DISABLED_ALL.value;
    const isVersionFilterEnabled = mobileVersionFilter !== FILTER_DISABLED_ALL.value;

    //ACTION HANDLERS
    const handleSelectUser = useCallback((userUid) => {
        dispatch(mobilesActions.selectUserMobiles(userUid));
    }, [dispatch]);

    const handleGoBack = useCallback(() => {
        dispatch(mobilesActions.selectUserMobiles(null));
    }, [dispatch]);

    function handleSetMobileTypeFilter(type) {
        setMobileTypeFilter(type);
        setMobileVersionFilter(FILTER_DISABLED_ALL.value);
    }

    //DERIVED STATE
    const filteredUserMobiles = useMemo(() =>
        allUsersMobiles
        .filter(userMobile =>
            isTypeFilterEnabled
            ? userMobile.mobiles.some(mobile => mobile.type === mobileTypeFilter)
            : true
        )
        .filter(userMobile =>
            isVersionFilterEnabled
            ? userMobile.mobiles.some(mobile => mobile.appVersion === mobileVersionFilter)
            : true
        )
    , [
        allUsersMobiles,
        mobileTypeFilter,
        mobileVersionFilter,
        isTypeFilterEnabled,
        isVersionFilterEnabled,
    ]);
    
    //INITIALIZATION
    useEffect(() => {
        initialize();

        async function initialize() {
            await dispatch(mobilesActions.getUserMobilesForCurrentLocation());
        }
    }, [dispatch, locationUid]);
    
    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    size={'SMALL'}
                    pageTitle={t("temp_webapp_user_mobiles_module_name")}
                    content={content()}
                />
            }
            detailedContent={
                <MobilesListPage {...{
                    mobileTypeFilter,
                    isTypeFilterEnabled,

                    mobileVersionFilter,
                    isVersionFilterEnabled,
                }}/>
            }
            isDetailedFolioEnabled={!!selectedId}
            onGoBack={handleGoBack}
        />
    );

    function content() {
        return (
            <div className="h-100 d-flex flex-column">
                <div className="mt-2"/>
                {filters()}
                <div className="mt-2"/>
                <div className="
                    border
                    rounded
                    overflow-auto
                ">
                    {usersList()}
                </div>
            </div>
        );
    }

    function filters() {
        return (
            <div className="d-flex align-items-center">
                <CustomDropdown
                    title={t("global_type")}
                    options={typeOptions()}
                    value={mobileTypeFilter}
                    onChange={handleSetMobileTypeFilter}
                />
                <div className="ml-1"/>
                <CustomDropdown
                    title={t("global_version")}
                    options={versionOptions()}
                    value={mobileVersionFilter}
                    onChange={setMobileVersionFilter}
                    topOptions={[CustomDropdown.buildOption(FILTER_DISABLED_ALL.value)]}
                    sortAlphabetically
                />
            </div>
        );

        function typeOptions() {
            return [
                CustomDropdown.buildOption(FILTER_DISABLED_ALL.value),
                ...mobileTypes.map(type => CustomDropdown.buildOption(type))
            ];
        }

        function versionOptions() {
            return filteredVersions
            .map(versionObj => CustomDropdown.buildOption(
                versionObj.version,
                `${versionObj.type} ${versionObj.version}`,
            ));
        }
    }

    function usersList() {
        return filteredUserMobiles
            .sort((a = UserMobiles(), b = UserMobiles()) =>
                a.user.email.localeCompare(b.user.email)
            )
            .map(singleUserMobiles)
        ;
    }

    function singleUserMobiles(userMobiles = UserMobiles(), index) {
        return (
            <div
                data-testid="user-mobile"
                onClick={() => handleSelectUser(userMobiles.uid)}
                key={userMobiles.uid}
                className={`
                    d-flex
                    justify-content-between
                    align-items-center
    
                    ${index !== 0 &&
                        "border-top"
                    }
                    p-2
                
                    c-cursor-default
                    
                    ${userMobiles.uid === selectedId
                        ? "c-bg-primary-light-2"
                        : "c-hover-bg-grey"
                    }
                `}
            >
                <div className="d-flex">
                    <CircleAvatar srcImg={userMobiles.user.avatar} names={[userMobiles.user.name, userMobiles.user.lastName]}/>
                    <div className="pl-2"/>
                    <div className="d-flex flex-column">
                        {userName()}
                    </div>
                </div>

                {wrongState()}
            </div>
        );

        function wrongState() {
            if (!hasDeviceInIncorrectState())
                return;

            return (
                <div className="
                    text-danger
                    text-right
                ">
                    {t("temp_webapp_mobile_in_incorrect_state")}
                </div>
            );
        }

        function hasDeviceInIncorrectState() {
            return MobilesHelper.isAnyMobileInIncorrectState(userMobiles.mobiles);
        }

        function userName() {
            return (
                <div className="
                    d-flex
                    flex-column
                    c-line-height-1rem
                ">
                    <div>
                        {userMobiles.user.email}
                    </div>
                    <div>
                        {userMobiles.user.name}
                    </div>
                </div>
            );
        }
    }
}