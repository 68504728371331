import React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export type STYLES_TYPE = 'PRIMARY' | 'DANGER' | 'INFO'; 

type SIZES_TYPE = 'SMALL' | 'MEDIUM';

type FILL_STYLES_TYPE = 'FILLED' | 'OUTLINED';

export type CapsuleButtonProps = {
        testId?: string,
        text?: string,
        onClick?: (any) => {} | void,
        isVisible?:boolean,
        isEnabled?:boolean,
        style?: STYLES_TYPE,
        classNameExtra?: string,
        icon?: string,
        isSelected?:boolean,
        size?: SIZES_TYPE,
        fillStyle?: FILL_STYLES_TYPE,
        textUppercase?: boolean,
        tooltip?: string,
        boostedToolTip?: boolean,
        tooltipProps?: TooltipProps,
        children?: JSX.Element | null,
} 

export function CapsuleButton({
    testId,
    text,
    onClick = () => {},
    isVisible = true,
    isEnabled = true,
    style = 'PRIMARY',
    icon,
    classNameExtra,
    isSelected,
    size = 'MEDIUM',
    fillStyle = 'OUTLINED',
    textUppercase = true,
    tooltip,
    boostedToolTip = false,
    tooltipProps,
    children
}: CapsuleButtonProps) {
    if (!isVisible)
        return null;

    if(boostedToolTip) {
        tooltipProps = {
            effect: 'solid',
            delayShow: 200,
            className: 'px-2 py-0 bg-dark',
            id: uuidv4(),
            ...tooltipProps,
        }
    }

    const button = (tooltipProps?: TooltipProps) => {
        let dataTip: {'data-tip'?: boolean, 'data-for'?: string} = {}

        if(tooltipProps) {
            dataTip = {
                'data-tip': true,
                'data-for': tooltipProps.id
            }
        }

        return (
            <>
                <button
                    data-testid={testId}
                    onClick={onClickLocal}
                    style={{lineHeight: 'var(--baseFontSize)',padding: getPadding()}}
                    title={boostedToolTip ? "" : tooltip ?? text}
                    {...dataTip}
                    className={`
            d-flex
            align-items-center
            justify-content-center
            
            ${
                icon && !text
                    ? "rounded-circle p-1"
                    : "c-rounded-capsule "
            }
            
            ${textUppercase && "text-uppercase"}
            c-font-weight-medium
            
            ${btnColor()}
            
            text-truncate
            ${!isEnabled ? 'cna' : ''}
            ${ classNameExtra }
            `}
                >
                    {iconUi()}
                    {icon && text && <span className="ml-2" />}
                    {text}
                    {children}
                </button>
          
                {tooltipProps && <ReactTooltip {...tooltipProps}>
                    {tooltip ?? text}
                </ReactTooltip>}
            </>
        );
    };

    return tooltipProps ? button(tooltipProps) : button();


    function iconUi() {
        if (!icon)
            return;

        return (
            <img
                src={icon}
                alt={(text || "header") + "_icon"}
                className={iconSize()}
            />
        );

        function iconSize() {
            switch (size) {
                case 'SMALL':
                    return "c-img-1rem";

                default:
                    return "c-img-1-5rem";
            }
        }
    }

    function getPadding() {
        if (icon && !text) return
        switch (size) {
            case 'SMALL':
                return '0.25rem 0.5rem';

            default: 
                return '0.5rem 1rem 0.46rem 1rem';
        }
    }

    function btnColor() {
        if (!isEnabled)
            return "text-secondary border-secondary border";

        switch (fillStyle) {
            case 'FILLED':
                return filledColor();
                
            case 'OUTLINED':
            default:
                return outlinedColor();
        }
    }

    function outlinedColor() {
        switch (style) {
            case 'DANGER':
                return outlinedDanger();

            case 'PRIMARY':
                return outlinedPrimary();
            case 'INFO':
            default:
                return outlinedInfo();
        }

        function outlinedInfo() {
            return `border border-secondary text-secondary c-bg-grey-250 c-hover-bg-grey ${!isSelected ? 'c-bg-grey-250' : 'bg-white'}`;
        }

        function outlinedDanger() {
            return isSelected
                ? "c-bd-red text-danger c-bg-light-red"
                : "c-bd-red text-danger bg-white c-hover-bg-red"
            ;
        }

        function outlinedPrimary() {
            return isSelected
                ? "c-bd-primary c-text-primary c-bg-primary-light-2"
                : "c-bd-primary c-text-primary bg-white c-hover-bg-primary-light-2"
            ;
        }
    }

    function filledColor() {
        switch (style) {
            case 'DANGER':
                return solidDanger();

            case 'PRIMARY':
            default:
                return solidPrimary();
        }

        function solidDanger() {
            return isSelected
                ? "c-bd-red text-danger bg-white"
                : "c-bd-red text-white bg-danger c-hover-bg-white c-hover-text-red"
            ;
        }

        function solidPrimary() {
            return isSelected
                ? "c-bd-primary c-text-primary bg-white"
                : "c-bd-primary text-white c-bg-primary c-hover-bg-white c-hover-text-primary"
            ;
        }
    }
    
    function onClickLocal(e) {
        if (!isEnabled)
            return;

        onClick(e);
    }
}

