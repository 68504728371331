import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { CommonPopUps } from "../../Everou/GlobalPopUps/AppGlobalPopUpsConfig";

import { PopUpRenderer } from "../../Everou/_components";
import { Selectors } from "../../Everou/_reducers/app.reducer";

const fn = () => {};

export function WiredPopUpRenderer({
    getComponentFn = fn,
} = {}) {

    //GLOBAL STATE
    const popUp = useSelector(Selectors.getPopUp);

    const wiredGetPopUp = useCallback(popUpId => 
        getComponentFn(popUpId) || CommonPopUps.getPopUp(popUpId)
    , [getComponentFn]);

    //VIEW
    return (
        <PopUpRenderer {...{
            popUp,
            getComponentFn: wiredGetPopUp,
        }}/>
    );
}