export const BUDGETS_API_URLS = {
    EMPLOYEE:       "/employee",
    DISTRIBUTORS:   "/distributors",
    PRODUCTS:       "/products",
    BUDGETS:        "/budgets",
    PDF_BUDGETS:    "/pdf/budgets",
    SEND_BUDGET_PDF:"/send/pdf",
    CLIENTS:        "/clients",
    ENTRIES:        "/entries",

    DISTRIBUTOR_AVATAR: "/distributors/avatar",
};