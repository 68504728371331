import { Friend } from "../_models/Friend";

export class FriendMapper {
    static allServerToLocal(serverFriends = []) {
        return serverFriends.map(serverToLocal);
    }
}

function serverToLocal(serverFriend) {
    return Friend({
        uid: serverFriend.uid,
        avatar: serverFriend.avatar,
        email: serverFriend.email,
        shareDest: serverFriend.share_dest,
        shareFrom: serverFriend.share_from,
    });
}