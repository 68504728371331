let rootState = state => state;

export class DeviceFilterSelectors {
    static setRootState(fn = state => state) {
        rootState = fn;
    }

    static getDeviceFilters(state) {
        return rootState(state);
    }

    static getTypes(state) {
        return rootState(state).deviceTypes;
    }

    static getDeviceCategories(state) {
        return rootState(state).deviceCategories;
    }

    static getRooms(state) {
        return rootState(state).rooms;
    }

    static getIsAllCategoriesSelected(state) {
        return rootState(state).isAllCategoriesSelected;
    }

    static getCategories(state) {
        return rootState(state).categories;
    }

    static getFilterMode(state) {
        return rootState(state).filterMode;
    }
}