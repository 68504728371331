import React from "react";

export function LogoHeader({
    color,
    logoSrc,
    content,
}) {

    //VIEW
    return (
        <div
            style={{
                backgroundColor: color || "#202020",
            }}
            className={`
                d-flex
                no-gutters
                align-items-center
                justify-content-between
                c-z-index-full
                p-2
            `}
        >
            {logoFn()}
            {content}
        </div>
    );

    function logoFn() {
        return (
            <div className="
                ml-2
                col-3
                d-flex

                align-items-center
            ">
                <img
                    src={logoSrc}
                    alt="header_logo"
                    className="c-mh-5rem"
                />
            </div>
        );
    }
}