import { Selectors } from "../_reducers/app.reducer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BaseAppGlobalPopUp } from "../GlobalPopUps/BaseAppGlobalPopUp";
import { PaymentMethodSelector } from "../_components";
import { subscriptionsActions } from "../_actions/subscriptions.actions";
import {
    SubscriptionUtils,
    SUBSCRIPTION_RENEWAL_PERIODS,
    SUBSCRIPTION_TYPES,
} from "../_constants/subscriptions.constants";
import { useCallback, useEffect } from "react";
import { TimeHelper } from "../_helpers";
import { GLOBAL_POPUPS } from "../_constants";
import { globalActions } from "../_actions";
import { PRODUCT_TYPE } from "../_modelsTS/product";

export function ProtectSubscriptionConfirmPopUp() {
    const { t } = useTranslation();
    const paymentMethod = useSelector(Selectors.getPaymentMethod);
    const dispatch = useDispatch();
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    const timezone = useSelector(Selectors.getTimezone);
    const currentDate = new Date();

    const protectSubscriptionInfo = {
        ...useSelector((state) =>
            Selectors.getProductInfo(state, PRODUCT_TYPE.PROTECT_SUBSCRIPTION),
        ),
        renewalDate: new Date(
            currentDate.setFullYear(currentDate.getFullYear() + 1),
        ),
        period: SUBSCRIPTION_RENEWAL_PERIODS.YEARLY,
    };

    const protectSubscription = useSelector(Selectors.getSubscriptions)?.find(
        ({ type }) => type === SUBSCRIPTION_TYPES.PROTECT,
    );

    //EFFECTS
    useEffect(() => {
        dispatch(subscriptionsActions.getSubscriptions());
    }, [dispatch, locationUid]);

    // SELECT PROTECT SUBSCRIPTION
    useEffect(() => {
        if (protectSubscription) {
            dispatch(
                subscriptionsActions.selectSubscription(
                    protectSubscription.uid,
                ),
            );

            dispatch(subscriptionsActions.changeLocation(locationUid));
        }
    }, [dispatch, protectSubscription, locationUid]);

    const reOpenModal = useCallback(() => {
        dispatch(
            globalActions.showPopUp(GLOBAL_POPUPS.PROTECT_SUSCRIPTION_CONFIRM),
        );
    }, [dispatch]);

    const subscribe = useCallback(() => {
        if (protectSubscription) {
            dispatch(
                subscriptionsActions.enableAutomatedPayment(
                    protectSubscription.code,
                ),
            );
        } else {
            dispatch(
                subscriptionsActions.create({
                    type: SUBSCRIPTION_TYPES.PROTECT,
                    locationUid,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        locationUid,
        protectSubscription?.code,
        protectSubscription?.status,
    ]);

    const content = (
        <div className="c-w-30rem pt-2 px-2">
            <div className="text-left">
                <div>
                    <div className="d-flex justify-content-between">
                        <b>{t("subscription_renovation_period")}:</b>
                        <span>
                            {SubscriptionUtils.getTagForRenewalPeriod(
                                protectSubscriptionInfo.period,
                            )}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                        <b>{t("subscription_renovation_date")}:</b>
                        <span>
                            {TimeHelper.localizeIsoStringToFormat(
                                protectSubscriptionInfo.renewalDate,
                                timezone,
                                TimeHelper.getTimeFormats().DATE_NO_SECONDS,
                            )}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between mt-1">
                        <b>{t("global_price")}:</b>
                        <span>{protectSubscriptionInfo.price}€</span>
                    </div>
                </div>
                <hr className="mt-3 mb-2" />
                <PaymentMethodSelector
                    afterDeleteCard={reOpenModal}
                    hideInfoText={paymentMethod}
                    infoText={t("mod_payments_method_required")}
                />
            </div>
        </div>
    );

    return (
        <BaseAppGlobalPopUp
            title={t(
                protectSubscription
                    ? "subscription_renewal_title"
                    : "subscription_add_title",
            )}
            body={content}
            acceptButtonName={t(
                protectSubscription ? "global_renew" : "common.add",
            )}
            onAccept={subscribe}
            disableAccept={!paymentMethod}
        ></BaseAppGlobalPopUp>
    );
}
