//SELECTORS
const PopUpSelectors = {
    getPopUp: state => state,
};

//ACTION TYPES
const POP_UP_ACTION_TYPES = {
    SHOW_POP_UP: "SHOW_POP_UP",
    HIDE_POP_UP: "HIDE_POP_UP",
};

//ACTIONS
function showPopUp(componentName, props) {
    return {
        type: POP_UP_ACTION_TYPES.SHOW_POP_UP,
        componentName,
        props,
    };
}

function hidePopUp() {
    return {
        type: POP_UP_ACTION_TYPES.HIDE_POP_UP,
    };
}

//REDUCER
const initialState = {
    isVisible: false,
    componentName: null,
    props: {},
};

function popUpReducer(state = initialState, action) {

    switch (action.type) {
        case POP_UP_ACTION_TYPES.SHOW_POP_UP:
            return { ...state,
                isVisible: true,
                componentName: action.componentName,
                props: action.props,
            };

        case POP_UP_ACTION_TYPES.HIDE_POP_UP:
            return { ...state,
                isVisible: false,
                componentName: null,
                props: {},
            };

        default:
            return state;
    }
}

export const PopUpStore = {
    reducer: popUpReducer,

    selectorGetPopUp: PopUpSelectors.getPopUp,
    
    actionShow: showPopUp,
    actionHide: hidePopUp,

    _typeShow: POP_UP_ACTION_TYPES.SHOW_POP_UP,
    _typeHide: POP_UP_ACTION_TYPES.HIDE_POP_UP,
};