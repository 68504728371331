export function UserStatusLog({
    type,
    status,
    datetime,
} = {}) {
    return {
        type,
        status,
        datetime,
    };
}