import { useCallback, useEffect, useState } from "react";
import { Icons } from "../../../Everou/_assets";
import { CapsuleButton } from "../../../Everou/_components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PMSLocationsActions } from "../../_actions/PMSLocationsActions";
import { Selectors } from "../../../Everou/_reducers/app.reducer";
import { CustomModal } from "../../../Everou/_components/CustomModal";
import { NewReservationInput } from "./NewReservationInput";
import { EmptyGenericIntegration } from "../../../Everou/_modelsTS/Integration";


export function CreateReservationInput() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const locationUid = useSelector(Selectors.getPMSLocationSelectedUid);
    const locationInfo = useSelector(Selectors.getPMSLocationInfo);

    const locationIntegrations = useSelector(Selectors.getPMSLocationIntegrations);
    const [integrations, setIntegrations] = useState(buildIntegrations(locationIntegrations));

    const [showModalCreateReservation, setShowModalCreateReservation] = useState(false);

    const deleteReservationDraft = () => {
        dispatch(PMSLocationsActions.deleteReservationDraft());
    }

    const toggleAccessLinkCreationShow = show => {   
        dispatch(PMSLocationsActions.createLocalReservation(locationUid));
        setShowModalCreateReservation(true);
    }

    async function createReservation() {
        await dispatch(PMSLocationsActions.createReservation());
        //fetchData(locationInfo.uid, groupUid, offset);
    };

    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSLocationsActions.getIntegrations());
    }, [dispatch, locationUid]);

    useEffect(() => {
        setIntegrations(buildIntegrations(locationIntegrations));
    }, [locationIntegrations]);

    // VIEW
    return (
        <>
            <div className="mt-2 d-flex align-items-center position-relative">
                <div className="d-flex align-items-center">{buttons()}</div>
                <p className={"mr-2 c-line-height-1"}>
                    {t("mod_pms_add_reservation")}
                </p>
            </div>
            <CustomModal
                show={showModalCreateReservation}
                centered
                onHide={() => setShowModalCreateReservation(false)}
                title={t("invitations.createAccessLink")}
                content={
                    <NewReservationInput
                        timezone={locationInfo.timezone}
                        integrations={integrations}
                    />
                }
                footer={
                    <>
                        <CapsuleButton
                            style="DANGER"
                            text={t("global_cancel")}
                            onClick={() => {
                                deleteReservationDraft();
                                setShowModalCreateReservation(false);
                            }}
                        />
                        <CapsuleButton
                            isEnabled={true}
                            text={t("global_create")}
                            onClick={() => {
                                createReservation();
                                setShowModalCreateReservation(false);
                            }}
                        />
                    </>
                    }
                />
        </>
    );

    function buttons() {
        return (
            <>
                <CapsuleButton
                    size="SMALL"
                    onClick={() => {
                        toggleAccessLinkCreationShow(true)
                    }}
                    icon={Icons.add}
                    tooltip={t('global_add')}
                    boostedToolTip
                />
                <div className="ml-2"></div>
            </>
        );
    }

    function buildIntegrations(locationIntegrations) {
        if (!locationIntegrations?.length) {
            locationIntegrations = [EmptyGenericIntegration];
        }

        return locationIntegrations;
    }
}
