import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { WidgetFavDevices } from "../_widgets/WidgetFavDevices";
import { WidgetLogs } from "../_widgets/WidgetLogs";
import { WidgetAlerts } from "../_widgets/WidgetAlerts";
import { WidgetInvitations } from "../_widgets/WidgetInvitations";
import { dashboardActions } from "../_actions/dashboard.actions";
import { DashboardHelper } from "../_helpers";
import { WIDGET_TYPES, SETTINGS_WINDOWS } from "../_constants";
import { WidgetAdd } from "../_widgets/WidgetAdd";

import optionsIcon from "../_assets/appIcons/settings-icon.svg";
import { GroupSettings } from "./GroupSettings";
import { WidgetAddSettings } from "../_widgets/WidgetAddSettings";
import { WidgetSettingsComponent } from "../_widgets/WidgetSettingsComponent";
import { appPermissions } from "../_constants/permissions.constants";
import { Selectors } from "../_reducers/app.reducer";

function getComponentForSelectedSettings(componentName) {
    const settingsWindowsComponents = {
        [SETTINGS_WINDOWS.GROUP]: GroupSettings,
        [SETTINGS_WINDOWS.ADD_WIDGET]: WidgetAddSettings,
        [SETTINGS_WINDOWS.WIDGET]: WidgetSettingsComponent,
    };

    return settingsWindowsComponents[componentName];
};

export function Dashboard() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid       = useSelector(Selectors.getSelectedLocationUid);
    const permission        = useSelector(Selectors.getPermissionSelectedLocation);
    const selectedGroupId   = useSelector(Selectors.getDashboardSelectedGroupId);
    const groups            = useSelector(Selectors.getDashboardGroups);
    const widgets           = useSelector(Selectors.getDashboardWidgets);

    const {
        visible: isSettingsVisible,
        componentName: settingsComponentName,
        props: settingsProps,
    }                       = useSelector(Selectors.getDashboardSettings);

    //INITIALIZATION
    useEffect(() => {
        dispatch(dashboardActions.getGroups());
    }, [dispatch, locationUid]);

    //UPDATES
    useEffect(() => {
        if (selectedGroupId === null)
            selectFirstGroup();

        if (!groups.find(group => group.id === selectedGroupId))
            selectFirstGroup();

        //
        function selectFirstGroup() {
            if (!groups.length)
                return;

            dispatch(dashboardActions.selectGroup(groups[0].id));
        }
        
    }, [dispatch, groups, selectedGroupId]);

    useEffect(() => {
        if (!selectedGroupId)
            return;

        dispatch(dashboardActions.getWidgetsFromSelectedGroup());
    }, [dispatch, selectedGroupId]);
    

    //VIEW
    const SelectedSettings = getComponentForSelectedSettings(settingsComponentName);
    const canUserEdit = appPermissions.canUserEditDashboard(permission);

    return (
        <div className="h-100 w-100 position-relative">
            {isSettingsVisible && (SelectedSettings !== null) &&
                <div className="
                    position-absolute
                    w-100
                    h-100
                ">
                    <SelectedSettings {...settingsProps}/>
                </div>
            }

            <div className="h-100 d-flex flex-column">
                <div className="
                    w-100
                    flex-shrink-0

                    d-flex
                    overflow-auto
                    
                    bg-light
                ">
                    {groups.map(group => groupTab(group, selectedGroupId, canUserEdit))}

                    {canUserEdit && <>
                        {addGroupTab()}
                    </>}
                    
                    <div className="flex-grow-1 bg-light border-bottom" />
                </div>
                <div className="d-flex flex-wrap overflow-auto">
                    {widgets.map(widget =>
                        loadWidget(widget, canUserEdit, permission)
                    )}

                    {canUserEdit && selectedGroupId !== null &&
                    <WidgetAdd />
                    }
                </div>
            </div>
        </div>
    );

    function loadWidget(widget, editable, locationPermission) {
        if(!DashboardHelper.doesUserHavePermissionForWidget(widget.widgetType, locationPermission))
            return;

        let SelectedWidget = null;
        switch(widget.widgetType) {

            case WIDGET_TYPES.DEVICES:
                SelectedWidget = WidgetFavDevices;
                break;

            case WIDGET_TYPES.ALERTS:
                SelectedWidget = WidgetAlerts;
                break;

            case WIDGET_TYPES.INVITATIONS:
                SelectedWidget = WidgetInvitations;
                break;

            case WIDGET_TYPES.LOGS:
                SelectedWidget = WidgetLogs;
                break;

            default:
                return null;
        }

        return (
            <SelectedWidget
                editable={editable}
                key={widget.widgetId}
                widget={widget}
            />
        );
    }

    function groupTab(group, selectedGroupId, canUserEdit) {
        const { name, order, id } = group;
        const isSelected = group.id === selectedGroupId;

        return (
            <div
                key={order}
                className={`
                    d-flex
                    align-items-center

                    mb-0
                    px-2 py-1
                    border-right

                    text-nowrap

                    ${isSelected
                        ? "bg-white"
                        : "btn-light border-bottom"
                    }
                `}
                onClick={() => handleSelectGroup(selectedGroupId, id)}
            >
                {isSelected && canUserEdit && <>
                    <img
                        className="btn-light rounded c-img-1-5rem"
                        src={optionsIcon}
                        onClick={() => handleOpenGroupSettings(id, name)}
                        alt={"options icon"}
                    />
                    <span className="pr-2" />
                </>}

                <span
                    className="p-0 c-cursor-default"
                >
                    {name}
                </span>

            </div>
        );
    }

    function addGroupTab() {
        return (
            <label
                className={`
                    btn-light
                    c-ft-m
                    mb-0 p-1 px-3
                    
                    border-right
                    border-bottom
                `}
                onClick={handleCreateGroup}
            >
                +
            </label>
        );
    }


    //ACTION HANDLERS
    function handleSelectGroup(currentGroup, groupId) {
        if (currentGroup === groupId)
            return;

        dispatch(dashboardActions.selectGroup(groupId));
    }

    function handleCreateGroup() {
        dispatch(dashboardActions.createGroup());
    }

    function handleOpenGroupSettings(id, name) {
        dispatch(
            dashboardActions.showSettings(
                SETTINGS_WINDOWS.GROUP,
                {
                    id,
                    name,
                }
            )
        );
    }
}