import { genericActions } from "./generic.actions";
import { invitationGroupService } from "../_services/invitationGroups.service";
import { InvitationGroupMapper } from "../_mappers/InvitationGroupMapper";
import { invitationsActions } from "./invitations.actions";
import { InvitationGroup } from "../_models/InvitationGroup";
import { Invitation } from "../_models/Invitation";
import { INVITATIONS_SECONDARY_VIEWS, INVITATION_TYPES, GLOBAL_POPUPS, INVITATION_GROUP_TYPES, INVITATION_PERIODS, ALLOWED_SUBINVITATIONS} from "../_constants";
import { globalActions } from "./global.actions";
import { i18n } from "../_translations/i18n";
import { Selectors } from "../_reducers/app.reducer";
import { BaseInvitationGroupsStore } from "../_stores/InvitationGroupsStore/BaseInvitationGroupsStore";
import { InvitationGroupStore } from "../_stores/InvitationGroupsStore/InvitationGroupStore";
import { Card } from "../_models/Card";
import { cardTypeToGroupType } from "../_constants/invitationCard.constants";
import { InvitationGroupsSecondaryViewStore } from "../_stores/InvitationGroupsSecondaryViewStore";
import { LOCATION_PERMISSIONS } from "../_constants/permissions.constants";
import { TimeHelper } from "../_helpers";
import { InvitationsMapper } from "../_mappers";
import { INVITATIONS_ACTION_TYPES } from "../_actionTypes/INVITATIONS_ACTION_TYPES";

export const invitationGroupActions = {
    //REMOTE
    removeInvitationGroup,
    getGroupsCurrentLocation,
    getGroupsForLocation,
    associatePackToInvitationGroup,
    disassociatePackFromInvitationGroup,
    saveEditableToServer,
    saveNewSelectedToServer,
    addUsersToGroup,
    addEmailsToGroup,
    addLinkToGroup,

    //STORE
    _setLocationGroups: BaseInvitationGroupsStore.actions.setArrayWithId,

    //LOCAL
    createLocal,
    renameEditable,
    changeDuration,
    changeGroupType,
    changeRooms,
    changeInvitationType,
    selectInvitationGroup: InvitationGroupStore.actions.selectAndSetEditable,
    selectGroup,
    selectGroupByUid,
    changeInvitationPermission,
    changeSecondaryView: InvitationGroupsSecondaryViewStore.actionSet,
    toggleAllowsSubinvitations,                      
    discardChangesToSelected,
    //HELPER FNS (not actions, should relocate)
    validateInvitationGroupFields,
    validateNewInvitationFields,
    changeSubinvitations
};

function changeInvitationPermission(permissionType, publicUrl?) {
    return (dispatch, getState) => {

        let invitation = Selectors.getEditableInvitationGroup(getState()).invitation;

        invitation  = {
            ...invitation,
            permissionType,
            allowsSubinvitations: permissionType === LOCATION_PERMISSIONS.GUEST && invitation.allowsSubinvitations,
            subinvitations: null,
        };

        if (publicUrl) {
            invitation = {...invitation, periodType: INVITATION_PERIODS.TEMPORARY }
        }

        if (permissionType === LOCATION_PERMISSIONS.INSTALLER) {
            invitation = {
                ...invitation,
                periodType: INVITATION_PERIODS.TEMPORARY,
                begin: TimeHelper.getTodayIsoString(),
                end: TimeHelper.createNowPlus3DaysIso()
            }
        }

        
        if (permissionType === LOCATION_PERMISSIONS.ADMIN) {
            invitation = {
                ...invitation, 
                type: INVITATION_TYPES.LOCATION,
                periodType: INVITATION_PERIODS.ALWAYS 
                
            }
        }

        dispatch(updateEditableLocally({ invitation }));
    };
}

function discardChangesToSelected() {
    return (dispatch, getState) => {
        let invitationGroup = Selectors.getSelectedInvitationGroup(getState());
        dispatch(updateEditableLocally(invitationGroup));
    }
}

function changeInvitationType(invitationType) {
    return (dispatch, getState) => {

        let invitation = Selectors.getEditableInvitationGroup(getState()).invitation;
        invitation = { ...invitation, type: invitationType };

        dispatch(updateEditableLocally({ invitation }));

    };
}

function toggleAllowsSubinvitations() {
    return (dispatch, getState) => {
        
        let invitation = Selectors.getEditableInvitationGroup(getState()).invitation;
        const allowsSubinvitations = !invitation?.allowsSubinvitations;

        invitation = {
            ...invitation,
            allowsSubinvitations,
            subinvitations: allowsSubinvitations ? 1 : null,
            periodType: INVITATION_PERIODS.ALWAYS,
            enabled: true,
        };

        dispatch(updateEditableLocally({ invitation }));
    };
}

function changeSubinvitations(subinvitations) {
    return (dispatch, getState) => {
        
        let invitation = Selectors.getEditableInvitationGroup(getState()).invitation;

        const minInvitations = ALLOWED_SUBINVITATIONS.MIN;
        const maxInvitations = ALLOWED_SUBINVITATIONS.MAX;
        
        subinvitations = parseInt(subinvitations);
        
        if (isNaN(subinvitations))
        subinvitations = minInvitations;
        
        if (subinvitations < minInvitations)
        subinvitations = minInvitations;
        
        if (subinvitations > maxInvitations)
        subinvitations = maxInvitations;
        
        invitation = {
            ...invitation,
            subinvitations
        };
        dispatch(updateEditableLocally({ invitation }));
    };
}

function changeRooms(rooms) {
    return (dispatch, getState) => {
        const invitation = { ...Selectors.getEditableInvitationGroup(getState()).invitation, rooms };
        dispatch(updateEditableLocally({ invitation }))
    }
}

function removeInvitationGroup(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(action));

        async function action() {
            await invitationGroupService.removeInvitationGroup(uid);
            await dispatch(getGroupsCurrentLocation(true));
        }
    }
}

function changeGroupType(groupType) {
    return updateEditableLocally({ groupType });
}

function changeDuration(duration) {
    return updateEditableLocally({ duration });
}

function getGroupsCurrentLocation(rethrow = false) {
    return async (dispatch, getState) => {
        await dispatch(invitationGroupActions.getGroupsForLocation(
            Selectors.getSelectedLocationUid(getState()),
            rethrow,
        ));
    };
}

function getGroupsForLocation(locationUid, rethrow = false) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            const serverGroups = await invitationGroupService.getInvitationGroups(
                locationUid
            );
            const localGroups = InvitationGroupMapper.allServerToLocal(serverGroups);
            dispatch(invitationGroupActions._setLocationGroups(
                locationUid,
                localGroups,
            ));
            return localGroups;
    }
    }
}

function associatePackToInvitationGroup(cardCode, invitationGroupUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await invitationGroupService.associatePackToInvitationGroup(
                cardCode,
                invitationGroupUid,
            );

            await dispatch(invitationGroupActions.getGroupsCurrentLocation(true));
            dispatch(invitationsActions.changeSecondaryView(null));

            dispatch(showCardConfiguredPopUp());
        }
    }
}

function disassociatePackFromInvitationGroup(cardPackUid: string, invitationGroupUid?: string) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            await invitationGroupService.disassociatePackFromInvitationGroup(
                cardPackUid
            );

            if(!invitationGroupUid) {
                invitationGroupUid = Selectors.getSelectedInvitationGroup(getState()).uid;
            }

            const serverGroup = await invitationGroupService.getInvitationGroup(invitationGroupUid);
            const localGroup = InvitationGroupMapper.serverToLocal(serverGroup);

            let allGroups = Selectors.getInvitationGroups(getState());
            allGroups = allGroups.map(group => { 
                if (group.uid === invitationGroupUid) {
                    return localGroup;
                }
                return group;
            });

            dispatch(invitationGroupActions._setLocationGroups(allGroups));
            dispatch(invitationGroupActions.selectInvitationGroup(localGroup));

        }
    }
}

function renameEditable(name) {
    return updateEditableLocally({ name });
}

function updateEditableLocally(newSelected) {
    return InvitationGroupStore.actions.updateEditableGroup(
        newSelected,
    );
}

function saveEditableToServer() {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            let localInvitationGroup = InvitationGroup();
            localInvitationGroup = Selectors.getEditableInvitationGroup(getState());
            validateInvitationGroupFields(localInvitationGroup);

            const serverInvitationGroup = await invitationGroupService.updateInvitationGroup({
                invitationGroupUid: localInvitationGroup.uid,
                name:               localInvitationGroup.name,
                roomUids:           (localInvitationGroup.invitation.rooms as any[]).map(room => room.uid),
                permissionType:     localInvitationGroup.invitation.permissionType,
            });

            await dispatch(invitationGroupActions.getGroupsCurrentLocation(true));
            dispatch(invitationGroupActions.selectGroup(
                InvitationGroupMapper.serverToLocal(serverInvitationGroup)
            ));
        }
    };
}

function validateInvitationGroupFields(invitationGroup = InvitationGroup()) {
    if (!invitationGroup.name)
        throw new Error(i18n.t("invitation_group_error_invalid_name"));
}

function validateNewInvitationFields(invitation = Invitation()) {
    if (invitation.type === INVITATION_TYPES.ROOM
        && !invitation.rooms.length
    ) throw new Error(i18n.t("invitation_group_error_invalid_room"));
}


function selectGroupByUid(invitationGroupUid) {
    return (dispatch, getState) => {
        const invitationGroup =
            Selectors.getInvitationGroups(getState())
            .find(group => group.uid === invitationGroupUid)
        ;
        dispatch(invitationGroupActions.selectGroup(invitationGroup));
    };
}

function selectGroup(invitationGroup) {
    return dispatch => {
        dispatch(
            invitationGroupActions.selectInvitationGroup(
                invitationGroup
            )
        );
        dispatch(invitationsActions.changeSecondaryView(
            INVITATIONS_SECONDARY_VIEWS.INVITATION_GROUP_CONFIG,
        ))
    }
}


function createLocal(newInvitationGroup = InvitationGroup()) {

    return dispatch => {
        dispatch(pushGroupToEditable(newInvitationGroup));
    };

    function pushGroupToEditable(invitationGroup) {
        return InvitationGroupStore.actions.setEditable(
            invitationGroup,
        );
    }
}

function saveNewSelectedToServer(cardCode, locationUid) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            let invitationGroup = InvitationGroup();
            invitationGroup = Selectors.getEditableInvitationGroup(getState());

            let invitation = Invitation();
            invitation = invitationGroup?.invitation;

            let invitationCard = Card();
            invitationCard = Selectors.getSelectedInvitationCard(getState());

            if (!invitationGroup || !invitationGroup.invitation)
                return;

            validateInvitationGroupFields(invitationGroup);
            validateNewInvitationFields(invitationGroup?.invitation);

            const serverInvitationGroup = await invitationGroupService.createInvitationGroup({
                cardCode,
                name:                   invitationGroup.name,
                roomUids:               (invitation.rooms as any[]).map(room => room.uid),
                allowsSubinvitations:   invitation.allowsSubinvitations,
                subinvitations:         invitation.subinvitations,

                locationUid,
                permissionType:         invitation.permissionType,
                type:                   invitation.type,
                groupType:              invitationCard ? cardTypeToGroupType(invitationCard.type) : invitationGroup.groupType,

                duration: mapDurationLocalToServer(
                    invitationGroup.duration,
                    invitation.allowsSubinvitations,
                    invitationGroup.groupType
                ),
            });

            
            if (cardCode)
                dispatch(showCardConfiguredPopUp());
            await dispatch(invitationGroupActions.getGroupsCurrentLocation(locationUid));
            return InvitationGroupMapper.serverToLocal(serverInvitationGroup);
        }

         function mapDurationLocalToServer(duration, allowsSubinvitations, groupType) {
            
            if (allowsSubinvitations) return undefined;

            if (groupType === INVITATION_GROUP_TYPES.UNLIMITED) return undefined;

            return duration;
        }
    }
}

function showCardConfiguredPopUp() {
    return globalActions.showPopUp(
        GLOBAL_POPUPS.GENERIC_NOTIFICATION,
            { body: i18n.t("invitation_group_card_pack_assigned_dialog") },
        );
}

function addUsersToGroup(groupUid: string, emails: string[], createPublicUrl = false) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(InvitationGroupStore.actions.setIsRequestingAddUsers())
            await invitationGroupService.addUsers(
                groupUid,
                emails,
                createPublicUrl,
                "",
                ""
            );
        }
    };
}

function addEmailsToGroup(groupUid: string) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            const localInvitation = Selectors.getInvitationNewDraft(getState());
            const serverInvitation = InvitationsMapper.mapNewLocalToServer(localInvitation);

            dispatch(InvitationGroupStore.actions.setIsRequestingAddUsers())
            await invitationGroupService.addUsers(
                groupUid,
                serverInvitation.emails,
                serverInvitation.create_public_url,
                serverInvitation.begin,
                serverInvitation.end
            );
        }
    };
}

function addLinkToGroup(groupUid: string) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            const localInvitation = Selectors.getInvitationNewDraft(getState());
            const serverInvitation = InvitationsMapper.mapNewLocalToServer(localInvitation);

            dispatch(InvitationGroupStore.actions.setIsRequestingAddUsers())
            let invitations = await invitationGroupService.addUsers(
                groupUid,
                serverInvitation.emails,
                serverInvitation.create_public_url,
                serverInvitation.begin,
                serverInvitation.end
            );

            let mappedInvitations = InvitationsMapper.mapAllServerToLocal(invitations);
            if (serverInvitation.create_public_url) {
                dispatch(selectInvitationAccessLink(mappedInvitations[0].publicUrl));                
            }
        }
    };
}

function selectInvitationAccessLink(publicUrl) {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_ACCESS_LINK_SELECT,
        publicUrl,
    };
}