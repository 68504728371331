import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ContentSpace, MainPageWithButtons } from "../_components";
import { triggersActions } from "../_actions/triggers.actions";
import { Trigger, DeviceTrigger, TriggerAction } from "../_models/Triggers";
import { TRIGGER_TYPES, TRIGGER_EFFECT_ACTION_TYPES } from "../_constants/triggers.constants";
import { deviceStatusIcon } from "../_assets/deviceStatusIcon";
import { deviceIconForType } from "../_assets";

import usersIcon from "../_assets/appIcons/users-icon-24px.svg";
import timeIcon from "../_assets/appIcons/time-icon-24px.svg";
import protectIcon from "../_assets/appIcons/icon_protect_generic_black.svg";
import notificationIcon from "../_assets/appIcons/notifications-icon.svg";
import arrowRightIcon from '../_assets/appIcons/arrow-right.svg';
import missingIcon from '../_assets/icons/device-icon-missing.png';

import { TriggerDescriptionHelper } from "./TriggerDescriptionHelper";
import { deviceTypeName } from "../_constants";
import { NoTriggersPage } from "./NoTriggersPage";
import { ListedGroup } from "../_components/ListedGroup";
import { Selectors } from "../_reducers/app.reducer";

export function TriggersModule() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);
    const triggers      = useSelector(Selectors.getTriggers);
    const devices       = useSelector(Selectors.getSelectedLocationDevices);
    const users         = useSelector(Selectors.getUsers);
    const locations     = useSelector(Selectors.getLocations);
    const timezone      = useSelector(Selectors.getTimezone);

    //INITIALIZATION
    useEffect(() => {
        getFromServer();
        
        async function getFromServer() {
            await Promise.all([
                dispatch(triggersActions.getTriggers()),
            ]);
        }
    },
    [dispatch, locationUid]);

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("common.triggers")}
                    content={content()}
                />
            }
        />
    );

    function content() {
        if (triggers.length === 0)
            return <NoTriggersPage />

        return triggersPage();
    }

    function triggersPage() {
        return (
            <div className="
                h-100
                d-flex
                no-gutters
                flex-column
            ">
                <div className="
                    col
                    overflow-auto
                ">
                    {triggersSection(
                        t("common.location"),
                        buildTriggerOfType(TRIGGER_TYPES.LOCATION),
                    )}
                    {triggersSection(
                        t("triggers.timeTrigger"),
                        buildTriggerOfType(TRIGGER_TYPES.TIME),
                    )}
                    {triggersSection(
                        t("protect.protect"),
                        buildTriggerOfType(TRIGGER_TYPES.PROTECT),
                    )}

                    {buildDeviceTriggers()}

                    {triggersSection(
                        t("status.disabled"),
                        buildDisabledTriggers(),
                    )}
                </div>
                <hr className="m-0"/>
            </div>
        );
    }

    function buildTriggerOfType(triggerType) {
        return (
            triggers
            .filter(trigger =>
                trigger.enabled
                && trigger.type === triggerType
            )
            .map(buildTrigger)
        );
    }

    function buildDisabledTriggers() {
        return (
            triggers
            .filter(trigger =>
                !trigger.enabled
            )
            .map(buildTrigger)
        );
    }

    function buildDeviceTriggers() {
        return buildUiTriggers(
            separatedByType(
                onlyDeviceTriggers()
            )
        );
        
        ////
        function onlyDeviceTriggers() {
            return triggers
                .filter(trigger =>
                    trigger.enabled
                    && trigger.type === TRIGGER_TYPES.DEVICE
                );
        }

        function separatedByType(triggers = [Trigger()]) {
            const sparatedDevices = {};
            triggers.forEach(trigger => {
                let parameters = DeviceTrigger();
                parameters = trigger.parameters;
                const deviceType = deviceTypeName(parameters.deviceType);
                if (!sparatedDevices[deviceType]) {
                    sparatedDevices[deviceType] = [];
                }
                sparatedDevices[deviceType].push(trigger);
            });

            return sparatedDevices;
        }

        function buildUiTriggers(separatedDevices) {
            const result = [];
            for (const [deviceType, triggers] of Object.entries(separatedDevices)) {
                result.push(
                    triggersSection(
                        deviceType,
                        triggers.map(buildTrigger),
                        deviceType,
                    )
                );
            }
            return result;
        }
    }

    function triggersSection(title, content = [], key) {
        if (content.length === 0)
            return null;

        return (
            <div
                key={key}
                className="my-2"
            >
                <ListedGroup
                    headerText={title}
                    elements={content}
                />
            </div>
        );
    }

    function buildTrigger(trigger = Trigger()) {
        return (
            <div
                data-testid={"single-trigger"}
                key={trigger.uid}
                className="
                    d-flex
                
                    px-2
                    py-1
                "
            >
                <div className="my-auto">
                    {icons(trigger)}
                </div>
                <div className="ml-2"/>
                <div className="
                    d-flex
                    flex-column
                ">
                    {title(trigger)}
                    {description(trigger)}
                </div>
            </div>
        );
    }

    function title(trigger = Trigger()) {
        if (!trigger.name)
            return null;

        return (
            <div className="font-weight-bold c-text-capitalize-first">
                <u>
                    {trigger.name}
                </u>
            </div>
        );
    }
    
    function icons(trigger = Trigger()) {
        return (
            <div className="
                d-flex
                align-items-center

                px-2
                py-1

                c-bg-grey-240
                border
                c-rounded-capsule
            ">
                <img
                    alt="icon A"
                    src={getIconA(trigger)}
                    className="
                        c-h-1-5rem
                        c-w-1-5rem
                        c-contain
                    "
                />
                <img
                    alt="arrow icon"
                    src={arrowRightIcon}
                    className="
                        c-h-1-5rem
                        c-w-1-5rem
                        c-contain
                    "
                />
                <img
                    alt="icon B"
                    src={getIconB(trigger)}
                    className="
                        c-h-1-5rem
                        c-w-1-5rem
                        c-contain
                    "
                />
            </div>
        );
    }

    function description(trigger = Trigger()) {
        return (
            <div>
                <div className="c-text-capitalize-first">
                    {partA(trigger) + ","}
                </div>
                <div>
                    {partB(trigger.action) + "."}
                </div>
            </div>
        );

        ////
        function partA(trigger = Trigger()) {
            switch (trigger.type) {
                case TRIGGER_TYPES.PROTECT:
                    return TriggerDescriptionHelper.protectDescriptionA();

                case TRIGGER_TYPES.TIME:
                    return TriggerDescriptionHelper.timeDescriptionA(
                        trigger,
                        timezone,
                    );

                case TRIGGER_TYPES.LOCATION:
                    return TriggerDescriptionHelper.locationDescriptionA(
                        trigger,
                        users,
                        locations,
                        devices,
                    );
    
                case TRIGGER_TYPES.DEVICE:
                    return TriggerDescriptionHelper.deviceDescriptionA(
                        trigger.parameters,
                        devices,
                    );
    
                default:
                    return "UNKNOWN TRIGGER TYPE";
            }
        }

        function partB(action = TriggerAction()) {
            switch (action.type) {
                case TRIGGER_EFFECT_ACTION_TYPES.BRAIN:
                case TRIGGER_EFFECT_ACTION_TYPES.DEVICE:
                    return TriggerDescriptionHelper.deviceDescriptionB(
                        trigger.action,
                        devices,
                    );

                case TRIGGER_EFFECT_ACTION_TYPES.NOTIFICATION:
                    return TriggerDescriptionHelper.notificationDescriptionB(
                        trigger.action.userName
                    );

                case TRIGGER_EFFECT_ACTION_TYPES.PROTECT:
                    return TriggerDescriptionHelper.protectDescriptionB(
                        trigger
                    );

                default:
                    return "UNDEFINED EFFECT";
            }
        }
    }

    function getIconA(trigger = Trigger()) {
        switch (trigger.type) {
            case TRIGGER_TYPES.PROTECT:
                return protectIcon;
            case TRIGGER_TYPES.TIME:
                return timeIcon;
            case TRIGGER_TYPES.LOCATION:
                return usersIcon;
            case TRIGGER_TYPES.DEVICE:
                return getDeviceIcon(
                    trigger.parameters.localStatus,
                    trigger.parameters.deviceType,
                );

            default:
                return missingIcon;
        }
    }

    function getIconB(trigger = Trigger()) {
        switch (trigger.action.type) {
            case TRIGGER_EFFECT_ACTION_TYPES.NOTIFICATION:
                return notificationIcon;

            case TRIGGER_EFFECT_ACTION_TYPES.PROTECT:
                return protectIcon;

            case TRIGGER_EFFECT_ACTION_TYPES.DEVICE:
            case TRIGGER_EFFECT_ACTION_TYPES.BRAIN:
                return getDeviceIcon(
                    trigger.action.localStatus,
                    trigger.action.deviceType,
                );

            default:
                return missingIcon;
        }
    }

    function getDeviceIcon(localStatus, deviceType) {
        let deviceIcon = deviceStatusIcon(localStatus);
        if (!deviceIcon)
            deviceIcon = deviceIconForType(deviceType);

        return deviceIcon;
    }
}