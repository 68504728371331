export function Reservation({
    uid,

    begin,
    end,

    userUid,
    userName,
    userEmail,
    userAvatar,

    roomUid,
} = {}) {
    return {
        uid,

        begin,
        end,

        userUid,
        userName,
        userEmail,
        userAvatar,

        roomUid,
    };
}