import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddPackConfirmationGlobalPopUp } from "../../GlobalPopUps/AddPackConfirmationGlobalPopUp";
import { NewInvitationGroup } from "../../InvitationsModule/InvitationGroupsModule/NewInvitationGroup";
import { invitationGroupActions } from "../../_actions";
import { WaitSpinner } from "../../_components/WaitSpinner";
import { cardTypeToGroupType } from '../../_constants';
import { InvitationCardHelper } from "../../_helpers/InvitationCardHelper";
import { Card } from "../../_models/Card";
import { Selectors } from "../../_reducers/app.reducer";
import { InvitationGroupWizardAddPackPage } from "./InvitationGroupWizardAddPackPage";
import { InvitationGroupWizardPage } from "./InvitationGroupWizardPage";


const PAGES = {
    NEW_OR_EXISTING_GROUP_PROMPT:   "NEW_OR_EXISTING_GROUP_PROMPT",
    NEW_GROUP_CONFIG:               "NEW_GROUP_CONFIG",
    EXISTING_GROUP_SELECT:          "EXISTING_GROUP_SELECT",
    ADD_PACK_TO_EXISTING_GROUP_CONFIRMATION:  "ADD_PACK_TO_EXISTING_GROUP_CONFIRMATION",
    ADD_PACK_TO_NEW_GROUP_CONFIRMATION: "ADD_PACK_TO_NEW_GROUP_CONFIRMATION"
};

function DummyWrapper({ children }) {
    return (
        <>
            {children}
        </>
    );
}

export function AddPackWizard({
    locationUid,
    BaseWrapper = DummyWrapper,
    ExistingGroupsWrapper = DummyWrapper,
} = {}) {

    //LIBRARIES
    const dispatch = useDispatch();
    
    //GLOBAL STATE
    const currentGroups = useSelector(Selectors.getInvitationGroups);
    let card            = Card();
    card                = useSelector(Selectors.getSelectedInvitationCard);

    //LOCAL STATE
    const [isInitialized, setIsInitialized] = useState(false);
    const [currentPage, setCurrentPage] = useState(PAGES.NEW_OR_EXISTING_GROUP_PROMPT);
    const [groups, setGroups] = useState(currentGroups);
    const [selectedGroup, setSelectedGroup] = useState(null);

    //ACTION HANDLERS
    const handleGoToExistingGroup = useCallback(() => {
        setCurrentPage(PAGES.EXISTING_GROUP_SELECT);
    }, []);

    const handleGoToNewGroup = useCallback(() => {
        setCurrentPage(PAGES.NEW_GROUP_CONFIG);
    }, []);

    const handleSelectInvitationGroup = useCallback(group => {
        setSelectedGroup(group);
        setCurrentPage(PAGES.ADD_PACK_TO_EXISTING_GROUP_CONFIRMATION);
    }, []);

    //INITIALIZATION
    useEffect(() => {
        getLocationData();
        async function getLocationData() {
            const groups = await Promise.resolve(
                dispatch(
                    invitationGroupActions.getGroupsForLocation(locationUid),
                ),
            );

            const compatibleGroups = InvitationCardHelper.getGroupsCompatibleWithCard(groups, card.type);
            setGroups(compatibleGroups);

            if (!compatibleGroups.length)
                setCurrentPage(PAGES.NEW_GROUP_CONFIG);
                
            setIsInitialized(true);
        }
    }, [dispatch, locationUid, card]);

    //VIEW
    if (!isInitialized)
        return (
            <BaseWrapper>
                <WaitSpinner />
            </BaseWrapper>
        );
    
    switch (currentPage) {
        case PAGES.NEW_OR_EXISTING_GROUP_PROMPT:
            return (
                <BaseWrapper>
                    <InvitationGroupWizardPage
                        handleSelectExistingGroup={handleGoToExistingGroup}
                        handleSelectNewGroup={handleGoToNewGroup}
                    />
                </BaseWrapper>
            );

        case PAGES.EXISTING_GROUP_SELECT:
            return (
                <ExistingGroupsWrapper>
                    <InvitationGroupWizardAddPackPage
                        invitationGroups={groups}
                        handleSelectInvitationGroup={handleSelectInvitationGroup}
                    />
                </ExistingGroupsWrapper>
            );

        case PAGES.NEW_GROUP_CONFIG:
            return (
                <BaseWrapper>
                    <NewInvitationGroup
                        cardCode={card.code}
                        availabeGroupTypes={[cardTypeToGroupType(card.type)]}
                    />
                </BaseWrapper>
            );

        case PAGES.ADD_PACK_TO_EXISTING_GROUP_CONFIRMATION:
            return (
                <AddPackConfirmationGlobalPopUp
                    cardCode={card.code}
                    invitationGroupName={selectedGroup.name}
                    invitationGroupUid={selectedGroup.uid}
                />
            );
        case PAGES.ADD_PACK_TO_NEW_GROUP_CONFIRMATION:
            return (
                <AddPackConfirmationGlobalPopUp
                    cardCode={card.code}
                    invitationGroupName={selectedGroup?.name}
                    locationUid={locationUid}
                />
            );

        default:
            return (
                <div>
                    {"PAGE NOT FOUND"}
                </div>
            );
    }
}