//CONSTANTS
export const GENERIC_UI_ALERT_TYPES = {
    SUCCESS: "success",
    ERROR: "error",
};

export function GenericUiAlertFactory(GENERIC_ID, rootStateFn = state => state) {

    //ACTION TYPES
    const typeShowAlert = "UI_ALERT_SHOW_" + GENERIC_ID;
    const typeClearAlert = "UI_ALERT_CLEAR_" + GENERIC_ID;

    //ACTIONS
    function actionSuccessAlert(message) {
        return {
            type: typeShowAlert,
            message,
            alertType: GENERIC_UI_ALERT_TYPES.SUCCESS,
        };
    }
    
    function actionErrorAlert(message) {
        return {
            type: typeShowAlert,
            message,
            alertType: GENERIC_UI_ALERT_TYPES.ERROR,
        };
    }
    
    function actionClearAlert() {
        return {
            type: typeClearAlert,
        };
    }

    //SELECTORS
    function selectorGetCurrentAlert(state) {
        return rootStateFn(state);
    }
    
    //REDUCER
    const initialState = {
        type: null,
        message: null,
    };

    function reducerUiAlert(state = initialState, action) {
        switch (action.type) {
            case typeShowAlert: {
                return {
                    ...state,
                    message: action.message,
                    type: action.alertType,
                };
            }
    
            case typeClearAlert: {
                return { ...state,
                    message: null,
                };
            }
    
            default:
                return state;
        }
    }

    //EXPORT
    return {
        //REDUCER
        reducerUiAlert,

        //ACTIONS
        actionSuccessAlert,
        actionClearAlert,
        actionErrorAlert,

        //TYPES
        typeShowAlert,
        typeClearAlert,
        
        //SELECTORS
        selectorGetCurrentAlert,
    };
};