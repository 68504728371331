export const AUTHENTICATION_ACTION_TYPES = {
    UPDATE_VERSION: "UPDATE_VERSION",
    
    LOGIN_CODE_REQUEST: 'LOGIN_CODE_REQUEST',
    LOGIN_CODE_SUCCESS: 'LOGIN_CODE_SUCCESS',
    LOGIN_CODE_FAILURE: 'LOGIN_CODE_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    
    LOGOUT: 'LOGOUT',

    AUTH_UPDATE_USER_SUCCESS: 'AUTH_UPDATE_USER_SUCCESS',
    SET_NEW_USER: 'AUTH_SET_NEW_USER',

    SET_APIKEY_UPDATE_DATE: "AUTH_SET_APIKEY_UPDATE_DATE",

    SET_CODE_SENT: "AUTH_SET_CODE_SENT",
};

export const LOGIN_CODE_TYPES = {
    EMAIL:  0,
    APPLE:  1,
    GOOGLE: 2,
};