export function Client({
    uid,
    name,
    address,
    phone,
    email,
    cif,
} = {}) {
    return {
        uid,
        name,
        address,
        phone,
        email,
        cif,
    };
}