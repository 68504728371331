import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    ContentSpace, MainPageWithButtons
} from "../../Common/_components";
import { PMSLocationsActions } from "../_actions/PMSLocationsActions";
import { CustomSearchBar } from "../../Everou/_components";
import _ from "lodash";
import { PMSLocationFilter, PMSLocationsPaginationQueryParams } from "../_models/Pagination";
import { Selectors } from '../../Everou/_reducers/app.reducer';
import { Paginator } from '../../Common/_components/Paginator';
import { PMSLocationsFilter } from "./PMSLocationsFilter";
import { PMSLocationView } from "./_components/PMSLocationView";
import { PMSLocationDetail } from "./PMSLocationDetail";
import { PMS_LOCATIONS_SECONDARY_VIEWS } from "../_constants/pmslocations.constants";
import { PMSUserActions } from "../_actions/PMSUserActions";


export function PMSLocationsModule() {

    // LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // GLOBAL STATE
    const {
        offset,
        items: locations,
        page_size,
        total_count
    } = useSelector(Selectors.getPMSLocations);
    const selectedLocationUid = useSelector(Selectors.getPMSLocationSelectedUid);
    const locationFilter = useSelector(Selectors.getPMSLocationsFilter);
    const listRef = useRef<HTMLDivElement>(null);
    const secondaryView = useSelector(Selectors.getPMSLocationsSecondaryView);

    const isRequesting = useSelector(Selectors.getPMSLocationsIsRequesting);

    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSLocationsActions.getPMSLocations({offset}));
        dispatch(PMSUserActions.getTags());
    }, [dispatch]);

    //ACTION HANDLERS
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [debounce, setDebounce] = useState<any>(null);

    const getLocations = useCallback(
        (filters: PMSLocationFilter & PMSLocationsPaginationQueryParams = {}) => {
            dispatch(
                PMSLocationsActions.getPMSLocations({
                    offset,
                    page_size,
                    search_term: filters.search_term,
                    ...filters,
                }),
            );
        },
        [dispatch, offset, page_size]
    );

    const onChangeFilters = useCallback((filters: PMSLocationFilter) => {
        dispatch(PMSLocationsActions.changeFilter(filters));
        getLocations({...filters, offset: 0, page_size: 24});
    }, [dispatch, getLocations]);

    const onChangePage = async offset => {
        if (listRef) {
            listRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        dispatch(PMSLocationsActions.changeOffset(offset));
        await getLocations({ ...locationFilter, offset});
    };

    function onSearchByText(search_term: string) {
        debounce && clearTimeout(debounce);
        search_term = search_term.trim();
        setSearchTerm(search_term);
        const debounceLocal = setTimeout(() => {
            const filters = {
                ...locationFilter,
                search_term,
                offset:0
            };
            onChangeFilters(filters);
        }, 500);
        setDebounce(debounceLocal);
    };

    const handleSelectPMSLocation = (locationUid) => {
        dispatch(PMSLocationsActions.selectPMSLocation(locationUid));
    };

    const closeViewFn = useCallback(() => {
        dispatch(PMSLocationsActions.selectPMSLocation(null))
    }, [dispatch]);


    //CLEAN UP
    useEffect(() => {
        return closeViewFn;
    }, [closeViewFn]);

    //VIEW
    return (
        <ContentSpace
            mainContent={mainContent()}
            detailedContent={detailedContent()}
            onGoBack={closeViewFn}
        />
    );

    function mainContent() {
        return (
            <MainPageWithButtons
                    size={"X_LARGE"}
                    pageTitle={t("mod_dev_locations_title")}
                    content={content()}
                    buttons={[
                        <CustomSearchBar
                            onChange={e => {
                                onSearchByText(e.target.value);
                            }}
                            value={searchTerm}
                            className="w-100"
                            placeholder={t("mod_pms_search_placeholder")}
                        />
                    ]}
                />
        );
    }

    function content() {
        return (
            <>
                <div className="c-filtered-layout pl-1">
                    <PMSLocationsFilter
                        filter={locationFilter}
                        onChangeFilter={(filter) => {
                            onChangeFilters({ ...locationFilter, ...filter });
                        }}
                        className="c-sticky-top"
                    />
                    { isRequesting ? spinner() : list() }
                </div>
            </>
        );
    }

    function list() {
        if (total_count === 0) {
            return <div className='d-flex align-items-center justify-content-center'><h3>{t('mod_pms_locations_empty_message')}</h3></div>;
        }

        return (
            <div className="d-flex flex-column-reverse h-100">
                {(total_count ?? 0) > (page_size ?? 0) && (
                    <div className="bg-white pt-3 py-2">
                        <Paginator
                            pageSize={page_size ?? 0}
                            total={total_count ?? 0}
                            pageOffset={offset}
                            onChangePage={onChangePage}
                        />
                    </div>
                )}
                <div className="position-relative h-100">
                    <div className="position-absolute top-0 left-0 w-100 overflow-auto h-100">
                        <div ref={listRef} className="c-default-grid">
                            {locations?.map((location) => (
                                <PMSLocationView
                                    key={location.uid}
                                    location={location}
                                    isSelected={
                                        location.uid === selectedLocationUid
                                    }
                                    handleSelectPMSLocation={() =>
                                        handleSelectPMSLocation(location.uid)
                                    }
                                 />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function detailedContent() {
        switch (secondaryView) {
            case PMS_LOCATIONS_SECONDARY_VIEWS.LOCATION_INFO:
                return(
                    <PMSLocationDetail />
                );
            
            default:
                return null;
        }
    }

    function spinner() {
        return (
            <div className="
                w-100
                pt-2
                text-center
            ">
                <div className="spinner-border c-text-primary"/>
            </div>
        );
    }

}