import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { MainPageWithButtons, CapsuleButton } from "../_components";
import { Client } from "../_models/Client";
import { BaseClientForm } from "./BaseClientForm";

export function BaseClientFormMainPage({
    client = Client(),
    onSave = () => {},
    title,
    extraButtons = [],
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [userParams, setUserParams] = useState({});
    const [forceShowWarnings, setForceShowWarnings] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    
    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={title}
            content={baseForm()}
            buttons={createButtons()}
        />
    );

    function baseForm() {
        return (
            <BaseClientForm
                key={client.uid}
                forceShowWarnings={forceShowWarnings}
                setIsAllValid={setIsFormValid}
                client={client}
                setParams={setUserParams}
            />
        );
    }

    function createButtons() {
        return [
            <CapsuleButton
                onClick={handleSave}
                text={t("global_save")}
            />,
            ...extraButtons,
        ];
    }

    //ACTION HANDLERS
    function handleSave() {
        if (!isFormValid) {
            setForceShowWarnings(true);
            return;
        }

        onSave({
            uid: client && client.uid,
            ...userParams,
        });
    }
}
