import { PaymentMethodsActions } from "./PaymentMethodsActions";
import { genericActions } from "./generic.actions";

export const StripeActions = {
    confirmCardSetup: confirmCardSetup2,
    confirmSepa,
};

function confirmSepa(stripe, ibanElement, name, email) {
    return async dispatch => {
        if (!ibanElement || !name || !email)
            return;

        await dispatch(genericConfirmation(stripe, confirmationFn));
    }

    async function confirmationFn(setupIntentClientSecret) {
        return await stripe.confirmSepaDebitSetup(
            setupIntentClientSecret,
            {
                payment_method: {
                    sepa_debit: ibanElement,
                    billing_details: {
                        name,
                        email,
                    },
                },
            },
        );
    }
}

function confirmCardSetup2(stripe, cardElement) {
    return async dispatch => {
        if (!cardElement)
            return;

        await dispatch(genericConfirmation(stripe, confirmationFn));
    }

    async function confirmationFn(setupIntentClientSecret) {
        return await stripe.confirmCardSetup(
            setupIntentClientSecret,
            {
                payment_method: {
                    card: cardElement,
                },
            },
        );
    }
}

function genericConfirmation(stripe, confirmationFn) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!stripe)
                return;

            const { setupIntentId, setupIntentClientSecret } =
                await dispatch(PaymentMethodsActions.createSetupIntent(true));

            const result = await confirmationFn(setupIntentClientSecret);

            if (result.error)
                throw Error("Stripe - " + result.error.message)

            await dispatch(PaymentMethodsActions.confirmSetupIntent(setupIntentId));
        }
    }
}