import { LocalStorageHelper } from "./LocalStorageHelper";

export class MachineUidHelper {
    static getMachineUid() {
        let machineUid = LocalStorageHelper.loadMachineUid();
        if (machineUid === null) {
            machineUid = guidGenerator();
            LocalStorageHelper.saveMachineUid(machineUid);
        }
        return machineUid;
    }
}

function guidGenerator() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}