export function PMSLocation({
    uid,
    name,
    timezone,
    customization,
    protect,
    protectStatus,
    reservations,
    devices,
    tags,
    vacationRentalPlan,
    urlRecommendations,
    travelerDiscount,
    externalVideoUrl,
} = {}) {
    return {
        uid,
        name,
        timezone,
        customization,
        protect,
        protectStatus,
        reservations,
        devices,
        tags,
        vacationRentalPlan,
        urlRecommendations,
        travelerDiscount,
        externalVideoUrl,
    };
}