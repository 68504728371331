import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { BudgetsActions } from "../_actions/BudgetsActions";
import { BudgetsBaseGlobalPopUp } from "../_components";

export function RemoveBudgetPopUp({
    uid,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HANDLERS
    function handleRemoveBudget() {
        dispatch(BudgetsActions.deleteBudget(uid));
        dispatch(BudgetsActions.changeSecondaryView(null));
    }

    //VIEW
    return (
        <BudgetsBaseGlobalPopUp
            onCancel={handleRemoveBudget}
            title={t("mod_budgets_remove_confirmation_title")}
            body={t("mod_budgets_remove_confirmation_body")}
            cancelButtonName={t("webapp_global_remove")}
            acceptButtonName={t("global_cancel")}
        />
    );
}