import { KrossBookingProperty, KrossBookingRoom } from "Everou/_modelsTS/Integration";
import { KrossBookingToken } from "../_modelsTS/KrossBooking";

export class KrossBookingMapper {
    static serverToLocal(serverAuth): KrossBookingToken {
        return {
            auth_token: serverAuth.auth_token,
            auth_token_expire: serverAuth.auth_token_expire
        };
    }
    
    static serverListProperties(serverData: KrossBookingProperty[]) {
        return serverData.map(this.serverPropertyToLocal);
    }
    
    static serverPropertyToLocal({
        id_property,
        name,
        city
    }: KrossBookingProperty): KrossBookingProperty {
        return {
            id_property,
            name,
            ...(!!city && { city })
        }
    }

    static serverListRoomToLocal(serverData: KrossBookingRoom[]) {
        return serverData.map(this.serverRoomToLocal);
    }

    static serverRoomToLocal({
        id_room,
        number
    }): KrossBookingRoom {
        return {
            id_room,
            number
        }
    }
}
    

