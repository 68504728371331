import { HistoryHelper, LocalStorageHelper } from "./_helpers";
import { MachineUidHelper } from "./_helpers/MachineUIdHelper";

export const PersistedStateLoader = {
    load: loadPersistedState,
};

function loadPersistedState() {
    
    let persistedFromLocalStorage = {
        everou: {},
        common: {},
        ...LocalStorageHelper.loadState(),
    };

    const persistedFromUrl = {
        common: {
            authentication: {
                url: HistoryHelper.getLoginData(),
            },
            initialUrl: {
                value: HistoryHelper.getCurrentUrl(),
            },
        },

        everou: {
            global: {
                preselectedLocationUid: HistoryHelper.getLocationUid(),
            },
            filter: {
                filterMode: HistoryHelper.getDeviceFilterMode(),
            },
            devices: {
                markedDevice: HistoryHelper.getDeviceUid(),
            },
        },
    };
    
    removeUndefined(persistedFromUrl);
    removeEmpty(persistedFromUrl);

    const persistedCombined = {
        ...persistedFromLocalStorage,
        ...persistedFromUrl,

        common: {
            ...persistedFromLocalStorage.common,
            ...persistedFromUrl.common,
            authentication: {
                ...getAuthenticationStore(),
                ...getAuthenticationUrl(),
                machineUid: MachineUidHelper.getMachineUid(),
            },
        },
        
        everou: {
            ...persistedFromLocalStorage.everou,
            ...persistedFromUrl.everou,
            global: {
                ...getGlobalStore(),
                ...getGlobalUrl(),
            },
        },
    };

    return persistedCombined;

    function getGlobalStore() {
        if (persistedFromLocalStorage
            && persistedFromLocalStorage.everou
            && persistedFromLocalStorage.everou.global
        ) {
            return persistedFromLocalStorage.everou.global;
        }

        return {};
    }

    function getGlobalUrl() {
        if (persistedFromUrl
            && persistedFromUrl.everou
            && persistedFromUrl.everou.global
        ) {
            return persistedFromUrl.everou.global;
        }

        return {};
    }

    function getAuthenticationStore() {
        if (persistedFromLocalStorage
            && persistedFromLocalStorage.common
            && persistedFromLocalStorage.common.authentication)
        {
            return persistedFromLocalStorage.common.authentication;
        }

        return {};
    }

    function getAuthenticationUrl() {
        if (persistedFromUrl
            && persistedFromUrl.common
            && persistedFromUrl.common.authentication)
        {
            return persistedFromUrl.common.authentication;
        }

        return {};
    }

    function removeUndefined(object) {
        Object.keys(object).forEach(key => {
            if (object[key] && typeof object[key] === "object")
                removeUndefined(object[key]);
            else if (object[key] === undefined) 
                delete object[key];
        })
    }
    
    function removeEmpty(object) {
        Object.keys(object).forEach((key) => {
            
            if (typeof object[key] !== "object"
                || object[key] === null
                || object[key] === undefined
            )   return;
    
            if (Object.keys(object[key]).length > 0)
                removeEmpty(object[key]);
    
            if (Object.keys(object[key]).length === 0)
                delete object[key];
        })
    }
}
