import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "../GlobalPopUps/BaseAppGlobalPopUp";
import { PaymentMethodsActions } from "../_actions/PaymentMethodsActions";

export function RemoveCardPopUp({ uid, afterDeleteCard } = {}) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    //ACTION HANDLERS
    async function handleRemoveCard() {
        dispatch(PaymentMethodsActions.deleteMethod(uid)).then(() => afterDeleteCard());
    }

    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={t("mod_payments_remove_method_title")}
            body={t("mod_payments_remove_method_message")}
            cancelButtonName={t("global_accept")}
            acceptButtonName={t("global_cancel")}
            onCancel={handleRemoveCard}
        />
    );
}