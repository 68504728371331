import React from "react";
import { useTranslation } from 'react-i18next';

import { EmptyGlobalPopUp } from "./EmptyGlobalPopUp";
import { CapsuleButton } from "../../Everou/_components/CapsuleButton";

type BaseGlobalPopUpProps = {
    testID?: string,
    acceptButtonTestID?: string,
    
    title?: string,
    body?: any,
    onAccept?: (...args: any) => {} | void,
    onCancel?: (...args: any) => {} | void,
    acceptButtonName?: string,
    cancelButtonName?: string,

    hideAccept?: boolean,

    overflowVisible?: boolean,
    limitWidth?: boolean,

    areControlsBlocked?:boolean,
    disableAccept?: boolean
}

export function BaseGlobalPopUp({
    testID,
    acceptButtonTestID,
    
    title,
    body,
    onAccept,
    onCancel,
    acceptButtonName,
    cancelButtonName,

    hideAccept = false,

    overflowVisible = true,
    limitWidth=true,

    areControlsBlocked = false,
    disableAccept = false
}: BaseGlobalPopUpProps) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <EmptyGlobalPopUp content={content()} />
    );

    function content() {
        return (
            <div data-testid={testID}
                className={`
                    mh-100

                    d-flex
                    flex-column
                `}
            >
                {titleFn()}
                <hr className="w-100 my-2"/>
                {bodyFn()}
                <hr className="w-100 my-2"/>
                <div className="
                    d-flex
                    justify-content-around
                "
                >
                    {!hideAccept &&
                        acceptButton({
                            isDisabled: areControlsBlocked || disableAccept,
                        })}
                    {cancelButton()}
                </div>
            </div>
        );
    }

    function titleFn() {
        return (
            <div className="
                font-weight-bold
                text-center
                c-new-ft-l
                text-break

                c-text-capitalize-first
            ">
                {title}
            </div>
        );
    }

    function bodyFn() {
        return (
            <div className={`
                ${!overflowVisible && "overflow-auto p-1"}

                text-center
                text-break
                ${limitWidth && "c-mw-40rem"}
            `}>
                {body}
            </div>
        );
    }

    function acceptButton({ isDisabled }) {
        return (
            <CapsuleButton
                testId={acceptButtonTestID}
                text={acceptButtonName || t("common.accept")}
                onClick={onAccept}
                isEnabled={!isDisabled}
            />
        );
    }

    function cancelButton() {
        return (
            <CapsuleButton 
                text={cancelButtonName || t("common.cancel")}
                onClick={onCancel}
                style={`DANGER`}
                isEnabled={!areControlsBlocked}
            />
        );
    }
}