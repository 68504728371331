import * as _ from 'lodash';

type OrderLineType = 'recurring' | 'one_time';
type Currency = 'eur' | string;
export type OrderStatus = 'paid' | 'draft' | 'uncollectible' | 'void' | string;

export interface OrderLine {
    description: string;
    price: number;
    quantity: 0;
    type: OrderLineType;
}

export interface Order {
    lines: OrderLine[];
    metadata: any;
    periodStart: Date;
    periodEnd: Date;
    nextPaymentAttempt: Date | null;
    status: OrderStatus;
    tax: number | null;
    total: number;
}

export interface ServerOrder {
    metadata: any;
    lines: OrderLine[];
    next_payment_attempt: number | null;
    period_start: number;
    period_end: number;
    status: OrderStatus;
    tax: number | null;
    total: number;
}

export interface UpcomingOrder extends Order {}

export interface HistoricOrder extends Order {
    attemptCount: number;
    attempted: boolean;
    created: Date;
    currency: Currency;
    dueDate: Date;
    hostedInvoiceUrl: string;
    invoicePdf: string;
    number: string;
    paid: boolean;
}

export interface ServerUpcomingOrder extends ServerOrder {}

export interface ServerHistoricOrder extends ServerOrder {
    attempt_count: number;
    attempted: boolean;
    created: number;
    currency: Currency;
    due_date: number;
    hosted_invoice_url: string;
    invoice_pdf: string;
    number: string;
    paid: boolean;
}

export interface AllOrders {
    historic: HistoricOrder[];
    upcoming: UpcomingOrder;
}

export interface ServerAllOrders {
   historic: ServerHistoricOrder[];
   upcoming: ServerUpcomingOrder; 
}


export const isHistoricOrder = (order: UpcomingOrder | HistoricOrder): order is HistoricOrder => {
    return _.has(order, 'paid');
}