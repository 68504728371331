import { i18n } from "../_translations/i18n";

import {
    MOBILE_TYPES, MOBILE_PERMISSION_STATES,
} from "../_constants/mobiles.constants";
import { MobilesHelper } from "../_helpers/MobilesHelper";
import { Mobile } from "../_models/Mobile";
import { SingleMobile } from "./SingleMobile";
import { UserMobiles } from "../_models/UserMobiles";

export const UiMobileHelper = {
    isAppDataVisible,
    isPermissionDataVisible,
    mobileToUiPermissions,
    mobileToUiDeviceData,
    mobileToUiAppData,

    getUniqueTypesFromMobiles,
    getFilteredVersions,
};

function getFilteredVersions(userMobiles = [] || [UserMobiles()], mobileType) {

    return getFilteredVersions(
        getSeparatedVersionsFromMobiles(userMobiles),
        mobileType,
    );

    function getFilteredVersions(groupedVersions, mobileType) {
        if (!Object.values(MOBILE_TYPES).includes(mobileType))
            return groupedVersions;

        return groupedVersions.filter(versionObj => versionObj.type === mobileType);
    }
}

function getUniqueTypesFromMobiles(userMobiles = [] || [UserMobiles()]) {
    const typesDuplicates = userMobiles.reduce((types, userMobile) =>
        [
            ...types,
            ...userMobile.mobiles.map(mobile => mobile.type)
        ]
    , []);

    return [...new Set(typesDuplicates)];
}

function getSeparatedVersionsFromMobiles(userMobiles = [] || [UserMobiles()]) {
    const androidSet = new Set();
    const iOsSet = new Set();
    const webSet = new Set();

    userMobiles.forEach(userMobile => {
        userMobile.mobiles.forEach(mobile => {
            let setToAdd;
            
            switch (mobile.type) {
                case MOBILE_TYPES.ANDROID:
                    setToAdd = androidSet;
                    break;

                case MOBILE_TYPES.IOS:
                    setToAdd = iOsSet;
                    break;

                case MOBILE_TYPES.WEB:
                    setToAdd = webSet;
                    break;

                default:
                    break;
            }

            setToAdd && setToAdd.add(mobile.appVersion);
        })
    });

    return [
        ...[...androidSet].map(version => VersionObj(version, MOBILE_TYPES.ANDROID)),
        ...[...iOsSet].map(version => VersionObj(version, MOBILE_TYPES.IOS)),
        ...[...webSet].map(version => VersionObj(version, MOBILE_TYPES.WEB)),
    ];

    function VersionObj(version, type) {
        return { version, type };
    }
}

function isAppDataVisible(mobile = Mobile()) {
    return mobile.type !== MOBILE_TYPES.WEB;
}

function isPermissionDataVisible(mobile = Mobile()) {
    return mobile.type !== MOBILE_TYPES.WEB;
}

function mobileToUiPermissions(mobile = Mobile()) {
    const permissions = [
        dataToUiObject(
            i18n.t("temp_webapp_mobile_ble_permission"),
            mobile.blePermission,
        ),
        dataToUiObject(
            i18n.t("temp_webapp_mobile_activity_permission"),
            mobile.activityStatusPermission,
        ),
        dataToUiObject(
            i18n.t("temp_webapp_mobile_location_permission"),
            mobile.locationPermission,
        ),
    ];

    if (isLocationServicePermissionVisible(mobile)) {
        permissions.push(
            dataToUiObject(
                i18n.t("temp_webapp_mobile_location_service_permission"),
                mobile.locationServicePermission,
            ),
        );
    }

    permissions.push(
        dataToUiObject(
            i18n.t("temp_webapp_mobile_notification_permission"),
            mobile.notificationsPermission,
        ),
    );

    if (isBatteryPermissionVisible(mobile)) {
        permissions.push(
            dataToUiObject(
                i18n.t("temp_webapp_mobile_battery_permission"),
                mobile.batteryPermission,
            ),
        );
    }

    return permissions;


    //
    function dataToUiObject(text, permission) {
        return SingleMobile.UiDataModel(
            text,
            getPermissionStateUiString(permission),
            MobilesHelper.isMobilePermissionStateInvalid(permission),
        );

        function getPermissionStateUiString(permissionState) {
            const permissionCodeToUi = {
                [MOBILE_PERMISSION_STATES.DISABLED]: i18n.t("temp_webapp_global_incorrect"),
                [MOBILE_PERMISSION_STATES.ENABLED]: i18n.t("temp_webapp_global_correct"),
                [MOBILE_PERMISSION_STATES.NOT_REQUESTED]: i18n.t("temp_webapp_global_incorrect"),
                [MOBILE_PERMISSION_STATES.NOT_REQUIRED]: i18n.t("temp_webapp_mobile_state_not_required"),
                [MOBILE_PERMISSION_STATES.WHILE_USING_APP]: i18n.t("mobile_permission_state_while_using_app"),
            };
        
            return permissionCodeToUi[permissionState] || "STATE NOT DEFINED";
        }
    }

    function isLocationServicePermissionVisible(mobile = Mobile()) {
        return mobile.type === MOBILE_TYPES.ANDROID;
    }

    function isBatteryPermissionVisible(mobile = Mobile()) {
        return mobile.type === MOBILE_TYPES.ANDROID;
    }
}

function mobileToUiDeviceData(mobile = Mobile()) {
    return [
        dataToUiObject(
            i18n.t("temp_webapp_mobile_type"),
            mobile.type,
        ),
        dataToUiObject(
            i18n.t("temp_webapp_mobile_operating_system_description"),
            mobile.osVersionDescription,
        ),
        dataToUiObject(
            i18n.t("temp_webapp_mobile_model"),
            mobile.modelVersion,
        ),
        dataToUiObject(
            i18n.t("temp_webapp_mobile_app_version"),
            mobile.appVersion,
        ),
    ];

    function dataToUiObject(name, value) {
        return SingleMobile.UiDataModel(
            name,
            value,
            false,
        );
    }
}

function mobileToUiAppData(mobile = Mobile()) {
    return SingleMobile.UiDataModel(
        i18n.t("mobile_is_up_to_date"),
        isUpToDateString(),
        !mobile.isUpToDate,
    );

    function isUpToDateString() {
        return mobile.isUpToDate
            ? i18n.t("global_yes")
            : i18n.t("global_no")
        ;
    }
}