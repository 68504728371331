import React, { useState } from "react";

export function FloatRangeComponent({
    value = "",
    onChange = () => {},
    title,

    min = 0,
    max,
} = {}) {

    const [localValue, setLocalValue] = useState(value);

    //VIEW
    return (
        <div className="
            d-flex
            no-gutters
            justify-content-between
            align-items-center
        ">
            {title &&
                <div>
                    <div>
                        {title}
                    </div>
                    <div className="ml-2"/>
                </div>
            }
            <input
                onClick={e => e.target.select()}
                onChange={e => onLocalChange(e.target.value)}
                value={localValue}

                type={"text"}

                min={min}
                max={max}

                className="
                    rounded
                    border
                    text-right

                    c-w-4rem
                    ml-auto
                "
            />
        </div>
    );

    function onLocalChange(value) {
        const NUMBER_DOT_COMMA = /^[\d,.]*$/;
        const hasMoreThanOneDotOrComma = (occurrences(value, /,/g) + occurrences(value, /\./g)) > 1;
        if (!NUMBER_DOT_COMMA.test(value) || hasMoreThanOneDotOrComma)
            return;

        setLocalValue(value);

        const number = Number(value.replace(',', '.'));
        if (isNaN(number))
            return;

        const limitedNumber = applyLimits(number);
        
        if (!isLastCommaOrDot())
            setLocalValue(limitedNumber);

        onChange(limitedNumber);
        return;


        function occurrences(string, regex) {
            return (string.match(regex) || []).length;
        }

        function isLastCommaOrDot() {
            const lastItem = value.substr(value.length -1)
            return lastItem === "," || lastItem === ".";
        }
    }

    function applyLimits(number) {
        if (number > max)
            return max;

        if (number < min)
            return min;

        return number;
    }
}