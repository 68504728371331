import React, { useState } from 'react';

import { useScreenWidth } from '../_hooks/useScreenWidth';
import { UI_CONSTANTS } from '../_constants/UI_CONSTANTS';
import { Icons } from '../_assets/icons/Icons';

type ContentSpaceProps = {
    mainContent: JSX.Element,
    detailedContent?: JSX.Element | null,
    onGoBack?: any,
    isDetailedFolioEnabled?: boolean,
    hasFullscreenMode?: boolean,
}
export function ContentSpace({
    mainContent,
    detailedContent,
    onGoBack,
    isDetailedFolioEnabled,
    hasFullscreenMode = false,
}: ContentSpaceProps) {

    //LOCAL STATE
    const MAX_WIDTH = UI_CONSTANTS.CUSTOM_BREAKPOINTS.XL;
    const isDetailedVisible = isDetailedVisibleFn();
    const screenWidth = useScreenWidth();
    const [isFullscreen, setIsFullscreen] = useState(false);

    //ACTION HANDLER
    function handleToggleFullScreen() {
        setIsFullscreen(isFullscreen => !isFullscreen);
    }

    //VIEW
    return (
        <div className={`
            w-100
            h-100

            d-flex
            overflow-auto

            py-3
            ${!hasFullscreenMode && "pr-3"}

            c-bg-grey-240
        `}>
            {mainPack()}
            {secondaryPack()}
        </div>
    );

    function mainPack() {
        if (hideMainPage())
            return;

        return (
            <>
            <div className={"ml-3"}/>
            {mainContent}
            </>
        );

        function hideMainPage() {
            if (isFullscreen)
                return true;

            return isDetailedVisible && screenWidth < MAX_WIDTH;
        }
    }

    function secondaryPack() {
        if (!isDetailedVisible)
            return;

        return (
            <>
            {isFullscreen
                ? <div className={"ml-3"}/>
                : goBackButton()
            }
            {detailedContent}
            {fullscreenButton()}
            </>
        );
    }

    function fullscreenButton() {
        if (!hasFullscreenMode)
            return null;

        return (
            <div>
                <img
                    onClick={handleToggleFullScreen}
                    className="
                        mx-1
                        p-1
                        c-img-1-5rem
                        
                        c-box-shadow-centered
                        c-hover-240
                        rounded
                        bg-white
                    "
                    src={isFullscreen
                        ? Icons.closeFullscreen
                        : Icons.openFullscreen
                    }
                    alt="fullscreen_icon"
                />
            </div>
        );
    }

    function goBackButton() {
        return (
            <div
                data-testid={"go-back-button"}
                onClick={onGoBack}
                className="
                    flex-shrink-0
                    d-flex
                    align-items-center
                    c-hover-230
                    cp
                "
            >
                <div className="
                    mx-1

                    bg-white
                    rounded-circle
                    c-box-shadow-centered
                    c-hover-230
                ">
                    <img
                        className="c-img-2rem"
                        src={Icons.previousIcon}
                        alt="back icon"
                    />  
                </div>
            </div>
        );
    }

    //HELPERS
    function isDetailedVisibleFn() {
        if (isDetailedFolioEnabled === undefined)
            return Boolean(detailedContent);

        return isDetailedFolioEnabled;
    }
}