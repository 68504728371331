import { URL_CONSTANTS } from "../_constants";
import { ServiceHelper } from "./ServiceHelper";

export const HostifyService = {
    listListings,
}

function listListings(integrationUID: string) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INTEGRATION_HOSTIFY_LIST_LISTINGS,
        {
            uid: integrationUID
        }
    );
}