export const geocodingService = {
    reverseGeocoding,
};

async function reverseGeocoding(lat, lon) {
    const response = await fetch(
        "https://nominatim.openstreetmap.org/reverse"
        + "?format=json"
        + "&lat=" + lat
        + "&lon=" + lon
        + "&zoom=18"
        + "&addressdetails=1"
        ,
        {
            method: 'GET',
        },
    );

    const body = await response.json();

    return {
        road: body.address.road,
        village: body.address.village,
        town: body.address.town,
    };
}