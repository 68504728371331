export function UserStatusUsageData({
    isWorkingProperly = false,
    manualOpen = 0,
    automaticExit = 0,
    automaticOpen = 0,
} = {}) {
    return {
        isWorkingProperly,
        manualOpen,
        automaticExit,
        automaticOpen,
    };
}