import { usersStatusService } from "../_services/usersStatus.service";
import { UserStatusMapper } from "../_mappers/UserStatusMapper";
import { USERS_STATUS_ACTION_TYPES } from "../_constants/usersStatus.constants";
import { UserStatusDataMapper } from "../_mappers/UserStatusDataMapper";
import { genericActions } from "./generic.actions";
import { UserStatusLogsMapper } from "../_mappers/UserStatusLogMapper";
import { UsersStatusDateFilterStore } from "../_stores/UsersStatusDateFilterStore";

export const usersStatusActions = {
    //REMOTE
    getUsersStatus,
    getUserStatusData,
    getUserStatusLogs,

    //LOCAL
    changeDateFilter,
    selectUserUid,
    changeDateRanges: UsersStatusDateFilterStore.actionSetBeginEnd,
};

function getUsersStatus({ locationUid, begin, end, }) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(
            asyncFn,
        ));

        async function asyncFn() {
            dispatch(request());

            const serverUsersStatus = await usersStatusService.getUsersStatus(
                locationUid,
                begin,
                end,
            );

            const localUsersStatus = UserStatusMapper.allServerToLocal(serverUsersStatus);

            dispatch(success(localUsersStatus));
        }
    };

    function request() {
        return {
            type: USERS_STATUS_ACTION_TYPES.REQUEST_USERS_STATUS,
        };
    }

    function success(usersStatus) {
        return {
            type: USERS_STATUS_ACTION_TYPES.GET_USERS_STATUS_SUCCESS,
            usersStatus, 
        };
    }
}

function getUserStatusData({ userUid, locationUid, begin, end, }) {
    return async dispatch => {

        return await dispatch(genericActions.genericAsyncAction(
            asyncFn,
        ));

        async function asyncFn() {
            dispatch(request());
            const serverUserStatusData = await usersStatusService.getUserStatusData({
                userUid,
                locationUid,
                begin,
                end,
            });

            const localUserStatusData = UserStatusDataMapper.serverToLocal(serverUserStatusData);
            dispatch(success(localUserStatusData));
        }
    };

    function request() {
        return {
            type: USERS_STATUS_ACTION_TYPES.REQUEST_USER_STATUS_DATA,
        };
    }

    function success(userStatusData) {
        return {
            type: USERS_STATUS_ACTION_TYPES.GET_USER_STATUS_DATA_SUCCESS,
            userStatusData, 
        };
    }
}

function getUserStatusLogs({ userUid, locationUid, begin, end, }) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncFn));

        async function asyncFn() {
            dispatch(request());

            const serverUserStatusLogs = await usersStatusService.getUserStatusLogs({
                locationUid,
                userUid,
                begin,
                end,
            });

            const localUserStatusLogs = UserStatusLogsMapper.allServerToLocal(
                serverUserStatusLogs,
            );
            dispatch(success(localUserStatusLogs));
        }
    };

    function request() {
        return {
            type: USERS_STATUS_ACTION_TYPES.GET_USER_STATUS_LOGS_REQUEST,
        };
    }

    function success(logs) {
        return {
            type: USERS_STATUS_ACTION_TYPES.GET_USER_STATUS_LOGS_SUCCESS,
            userStatusLogs: logs,
        };
    }
}

function selectUserUid(uid) {
    return {
        type: USERS_STATUS_ACTION_TYPES.SELECT_USER,
        selectedUserUid: uid,
    };
}

function changeDateFilter(dateFilter, timezone) {
    return dispatch => {
        dispatch(updateDateFilter());

        dispatch(usersStatusActions.changeDateRanges(
            UserStatusMapper.mapDateFilterToBeginEnd(
                dateFilter,
                timezone,
            )
        ));
    };

    function updateDateFilter() {
        return {
            type: USERS_STATUS_ACTION_TYPES.CHANGE_DATE_FILTER,
            dateFilter,
        };
    }
}