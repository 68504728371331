import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const protectService = {
    updateProtectParameter,
    muteProtect,
    getTriggeredLogs,
};

function updateProtectParameter(
    {
        locationUid,
        isEnabled,
        protectOnEnter,
        protectOnExit,
        protectSchedule,
        protectScheduleEnd,
        protectScheduleBegin,
    }
) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: locationUid,
            protect: isEnabled,
            protect_on_enter: protectOnEnter,
            protect_on_exit: protectOnExit,
            protect_schedule: protectSchedule,
            protect_schedule_end: protectScheduleEnd,
            protect_schedule_begin: protectScheduleBegin,
        },
    );
}

function muteProtect(locationUid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_PROTECT_MUTE,
        {
            loc_uid: locationUid,
        },
    );
}

async function getTriggeredLogs(locationUid) {
    const response = await ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_LOG,
        `?loc_uid=${locationUid}`+
        "&offset=0"+
        "&group=1"+
        "&protect=true"
        ,
    )
    return response.logs;
}
