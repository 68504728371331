import { i18n } from "../_translations/i18n";

import { LOCAL_STATUS } from "../_constants";

export class DeviceLogsUiHelper {

    static getLocalStatusLabel(localStatus) {
        return getLocalStatusLabel(localStatus);
    }
}

function getLocalStatusLabel(localStatus) {
    return LOG_STATUS_LABELS[localStatus] || "LOCAL STATUS NOT FOUND";
}

const LOG_STATUS_LABELS = {
    [LOCAL_STATUS.CLOSED]:                             i18n.t("status.closed"),
    [LOCAL_STATUS.OPEN]:                               i18n.t("status.open"),
    
    [LOCAL_STATUS.OFF]:                                i18n.t("status.off"),
    [LOCAL_STATUS.ON]:                                 i18n.t("status.on"),
    
    [LOCAL_STATUS.ROLLER_STOPPED]:                     i18n.t("status.rollerStoped"),
    [LOCAL_STATUS.ROLLER_OPENED]:                      i18n.t("status.rollerOpened"),
    [LOCAL_STATUS.ROLLER_CLOSED]:                      i18n.t("status.rollerClosed"),
    
    [LOCAL_STATUS.ENTRY]:                              i18n.t("status.entry"),
    [LOCAL_STATUS.EXIT]:                               i18n.t("status.exit"),
    
    [LOCAL_STATUS.FLOOD_OPEN]:                         i18n.t("status.floodOpen"),
    [LOCAL_STATUS.FLOOD_CLOSED]:                       i18n.t("status.floodClose"),
    
    [LOCAL_STATUS.MOVEMENT_DETECTED]:                  i18n.t("status.movementDetected"),
    
    [LOCAL_STATUS.CLOSED_LOCKED]:                      i18n.t("status.closedLocked"),
    [LOCAL_STATUS.CLOSED_UNLOCKED]:                    i18n.t("status.closedUnlocked"),
    [LOCAL_STATUS.OPENED_LOCKED]:                      i18n.t("status.openedLocked"),
    [LOCAL_STATUS.OPENED_UNLOCKED]:                    i18n.t("status.openedUnlocked"),
    [LOCAL_STATUS.LATCH_REMOVED]:                      i18n.t("status.latchRemoved"),
    [LOCAL_STATUS.EMERGENCY_OPENING]:                  i18n.t("status.emergencyOpening"),
    [LOCAL_STATUS.VIBRATIONS]:                         i18n.t("status.vibrations"),
    
    [LOCAL_STATUS.RESETED_PERMISSIONS]:                i18n.t("status.resetAccess"),
    [LOCAL_STATUS.VIDEO_RECORDED]:                     i18n.t("status.videoRecorded"),
    [LOCAL_STATUS.REBOOT]:                             i18n.t("status.rebootDetected"),
    [LOCAL_STATUS.BATTERY_FAULT]:                      i18n.t("status.batteryFault"),
    
    [LOCAL_STATUS.IDLE]:                               i18n.t("status.idle"),
    [LOCAL_STATUS.FLOOD]:                              i18n.t("status.flood"),
    [LOCAL_STATUS.COVER_CLOSED]:                       i18n.t("status.coverClosed"),
    [LOCAL_STATUS.COVER_OPENED]:                       i18n.t("status.converOpened"),
    
    [LOCAL_STATUS.DISCONNECTED]:                       i18n.t("status.disconnected"),
    [LOCAL_STATUS.CONNECTED]:                          i18n.t("status.connected"),
    [LOCAL_STATUS.SECONDARY_BATTERY]:                  i18n.t("status.secondaryBattery"),
    [LOCAL_STATUS.AC_POWER]:                           i18n.t("status.acPower"),
    
    [LOCAL_STATUS.INHIBITION_STOPPED]:                 i18n.t("status.inhibitionStopped"),
    [LOCAL_STATUS.POSSIBLE_INHIBITION]:                i18n.t("status.possibleInhibition"),
    
    [LOCAL_STATUS.REQUEST_DELAYED_OFF]:                i18n.t("status.requestDelayedOff"),
    [LOCAL_STATUS.REQUEST_DELAYED_ON]:                 i18n.t("status.requestDelayedOn"),
    [LOCAL_STATUS.REQUEST_DELAYED_STOP]:               i18n.t("status.requestDelayedStop"),
    [LOCAL_STATUS.REQUEST_DELAYED_OPEN]:               i18n.t("status.requestDelayedOpen"),
    [LOCAL_STATUS.REQUEST_DELAYED_CLOSE]:              i18n.t("status.requestDelayedClose"),
    [LOCAL_STATUS.REQUEST_DELAYED_FLOOD_OFF]:          i18n.t("status.requestDelayedOff"),
    [LOCAL_STATUS.REQUEST_DELAYED_FLOOD_ON]:           i18n.t("status.requestDelayedOn"),
    
    [LOCAL_STATUS.BEACON_EXECUTE]:                     i18n.t("log_beacon_detected_execute"),
    [LOCAL_STATUS.BEACON_ASK]:                         i18n.t("log_beacon_detected_ask"),
    [LOCAL_STATUS.BEACON_IGNORE]:                      i18n.t("log_beacon_detected_ignore_wait"),
    [LOCAL_STATUS.BEACON_BLE_DISABLED]:                i18n.t("log_beacon_detected_ble_disabled"),
    [LOCAL_STATUS.BEACON_RETRY]:                       i18n.t("log_beacon_detected_retry"),
    [LOCAL_STATUS.BEACON_ASK_ALWAYS]:                  i18n.t("log_beacon_detected_ask_always"),
    [LOCAL_STATUS.BEACON_WRONG_MEAN]:                  i18n.t("log_beacon_detected_ask_wrong_transport"),
    [LOCAL_STATUS.BEACON_IOS_FIRST]:                   i18n.t("log_beacon_detected_ask_ios_first_time"),
    [LOCAL_STATUS.BEACON_ACTIVITY_PERMISSION_OFF]:     i18n.t("log_beacon_detected_activity_permissions_off"),
    [LOCAL_STATUS.BEACON_PHOTOCELL_EXIT]:              i18n.t("log_beacon_detected_switcher_photocell"),
    [LOCAL_STATUS.BEACON_LIMITED_FREE_USAGE]:          i18n.t("log_beacon_detected_limited_free_usage"),
    [LOCAL_STATUS.BEACON_LIMITED_BARRIER]:             i18n.t("log_beacon_detected_limited_barrier"),
    [LOCAL_STATUS.BEACON_NO_RESERVATION]:              i18n.t("log_beacon_detected_limited_no_reservation"),
    [LOCAL_STATUS.BEACON_IGNORE_SETTING_WALKING]:      i18n.t("log_beacon_detected_ignore_setting_walking"),
    [LOCAL_STATUS.BEACON_IGNORE_SETTING_VEHICLE]:      i18n.t("log_beacon_detected_ignore_setting_vehicle"),
    [LOCAL_STATUS.BEACON_ASK_WALKING]:                 i18n.t("log_beacon_detected_ask_walking"),
    [LOCAL_STATUS.BEACON_ASK_VEHICLE]:                 i18n.t("log_beacon_detected_ask_vehicle"),
    [LOCAL_STATUS.BEACON_MAX_CONNECTIONS_REACHED]:     i18n.t("log_beacon_detected_max_connections_reached"),
    [LOCAL_STATUS.BEACON_SERVICE_RESET]:               i18n.t("log_beacon_detected_android_reset_service"),
    [LOCAL_STATUS.BEACON_VOICE_ASSISTANT]:             i18n.t("log_beacon_detected_voice_assistant"),
    [LOCAL_STATUS.BEACON_WIDGET]:                      i18n.t("log_beacon_detected_widget"),

    [LOCAL_STATUS.PROTECT_DISABLED]:                   i18n.t("status.disabled"),
    [LOCAL_STATUS.PROTECT_ENABLED]:                    i18n.t("status.enabled"),

    [LOCAL_STATUS.CLOCKIN]:                            i18n.t("status.clockin"),
    [LOCAL_STATUS.VERSION_UPDATED]:                    i18n.t("status.version_updated"),
}