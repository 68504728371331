import React from "react";
import { i18n } from "../_translations/i18n";

import { TimeHelper } from "./TimeHelper";
import { weekdayUiValues, INVITATION_PERIODS, WEEKDAYS_STRING_TO_NUMBER } from "../_constants";
import { WeekdaysModel } from "../_models/WeekdaysModel";
import { Invitation } from "../_models/Invitation";

export class TimeStringHelper {
    static daysOfWeekString(weekdays = WeekdaysModel()) {
        return daysOfWeekString(WeekdaysModel(weekdays));
    }

    static buildReservationConfigString(allDay, isoBegin, isoEnd, weekdays = WeekdaysModel()) {
        return buildReservationConfigString(allDay, isoBegin, isoEnd, weekdays);
    }

    static buildInvitationTimeString(invitation = Invitation(), timezone) {
        return buildInvitationTimeString(invitation, timezone);
    }
}

function buildInvitationTimeString(invitation = Invitation(), timezone) {
    switch (invitation.periodType) {
        case INVITATION_PERIODS.ALWAYS:
            return i18n.t("invitations.always");

        case INVITATION_PERIODS.TEMPORARY:
            return buildTemporaryString(
                invitation.begin,
                invitation.end,
                timezone,
            );

        case INVITATION_PERIODS.RECURRENT:
            return buildRecurrentString(
                WeekdaysModel(invitation),
                invitation.allDay,
                invitation.begin,
                invitation.end,
            );

        default:
            return "Unknown period";
    }
}

function buildReservationConfigString(allDay, isoBegin, isoEnd, weekdays = WeekdaysModel()) {

    return TimeStringHelper.daysOfWeekString(weekdays) + " " + hoursString();

    function hoursString() {
        if (allDay)
            return i18n.t("global_all_day");

        return (
            i18n.t("common.from")
            + " "
            + TimeHelper.isoStringToUiString(
                isoBegin,
                TimeHelper.getTimeFormats().TIME_NO_SECONDS,
            )
            + " "
            + i18n.t("common.to")
            + " "
            + TimeHelper.isoStringToUiString(
                isoEnd,
                TimeHelper.getTimeFormats().TIME_NO_SECONDS,
            )
        );
    }

}

function buildTemporaryString(beginIsoString, endIsoString, timezone) {
    return (
        <>
        <div>
        {
            i18n.t("common.from") +
            " " +
            TimeHelper.localizeIsoStringToFormat(
                beginIsoString,
                timezone,
                TimeHelper.getTimeFormats().DATE_NO_SECONDS,
            )
        }
        </div>
        <div>
        {
            i18n.t("common.to") +
            " " +
            TimeHelper.localizeIsoStringToFormat(
                endIsoString,
                timezone,
                TimeHelper.getTimeFormats().DATE_NO_SECONDS,
            )
        }
        </div>
        </>
    );
}

function buildRecurrentString(weekdays = WeekdaysModel(), allDay, begin, end) {
    if (allDay)
        return daysOfWeekString(weekdays);
    
    return daysOfWeekFromTo(weekdays, begin, end);
}

function daysOfWeekFromTo(weekdays, begin, end) {
    return (
        <>
        <div>
            {daysOfWeekString(weekdays)}
        </div>
        <div>
            <span>
            {
                i18n.t("common.from") +
                " " +
                TimeHelper.isoStringToUiString(begin, TimeHelper.getTimeFormats().TIME_NO_SECONDS)
            }
            </span>
            <span className="pl-2 text-lowercase">
            {
                i18n.t("common.to") +
                " " +
                TimeHelper.isoStringToUiString(end, TimeHelper.getTimeFormats().TIME_NO_SECONDS)
            }
            </span>
        </div>
        </>
    );
}

function daysOfWeekString(weekdays = WeekdaysModel()) {
    if (isEveryday(weekdays))
        return i18n.t("common.everyday");

    if (isOnlyWeekDays(weekdays))
        return i18n.t("common.workDays");

    if (isOnlyWeekends(weekdays))
        return i18n.t("common.weekends");

    return singleWeekDays(weekdays);


    ////
    function isEveryday(weekdays = WeekdaysModel()) {
        return !Object.values(weekdays).includes(false);
    }

    function singleWeekDays(weekdays = WeekdaysModel()) {
        let resultString = "";

        Object.entries(weekdays)
        .filter(([, isEnabled]) => isEnabled)
        .forEach(([weekday], index, array) => {
            if (array.length > 1 && index === array.length - 1) {
                resultString += ` ${i18n.t("common.and")} `;
            }
            else if (index !== 0) {
                resultString += ", ";
            }

                resultString += weekdayUiValues[WEEKDAYS_STRING_TO_NUMBER[weekday]];
        });

        return i18n.t("common.onWeekdays") + " " + resultString;
    }

    function isOnlyWeekDays(weekdays = WeekdaysModel()) {
        if (
            (
                weekdays.monday &&
                weekdays.tuesday &&
                weekdays.wednesday &&
                weekdays.thursday &&
                weekdays.friday
            )
            &&
            (
                !weekdays.saturday &&
                !weekdays.sunday
            )
        ) {
            return true;
        }

        return false;
    }

    function isOnlyWeekends(weekdays = WeekdaysModel()) {
        if (
            (
                !weekdays.monday &&
                !weekdays.tuesday &&
                !weekdays.wednesday &&
                !weekdays.thursday &&
                !weekdays.friday
            )
            &&
            (
                weekdays.saturday &&
                weekdays.sunday
            )
        ) {
            return true;
        }

        return false;
    }
}