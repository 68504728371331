import { combineReducers } from "redux";
import { PMSUserStore } from './_stores/PMSUserStore';
import { CommonStore } from '../Everou/_reducers/common.reducer';
import { PMSLocationsSelector, locations } from './_reducers/locations.reducer';
import { PMSLocationsSecondaryViewStore } from "./_stores/PMSLocationsSecondaryViewStore";
import { PMSLocationDetailModeSelectors } from "./_selectors/PMSLocationDetailModeSelectors";
import { viewMode } from "./_reducers/viewMode.reducer";
import { PMSLocationLogsCollection } from "./_stores/PMSLocationLogsCollection";
import { PMSLocationLogsDateFilter } from "./_stores/PMSLocationLogsDateFilter";
import { PMSLocationReservationsCollection } from "./_stores/PMSLocationReservationsCollection";
import { PMSIntegrationsCollection } from "./_stores/PMSIntegrationsCollection";
import { PMSLocationIntegrationsCollection } from "./_stores/PMSLocationIntegrationsCollection";
import { PMSKrossBookingStore } from "./_stores/PMSKrossBookingStore";
import { PMSLocationGroupsCollection } from "./_stores/PMSLocationGroupsCollection";
import { PMSLocationRoomsCollection } from "./_stores/PMSLocationRoomsCollection";
import { PMSHostifyStore } from "./_stores/PMSHostifyStore";
import { PMSSmoobuStore } from "./_stores/PMSSmoobuStore";
import { PMSOctorateStore } from "./_stores/PMSOctorateStore";
import { PMSCustomizationTemplateStore } from "./_stores/PMSCustomizationTemplateStore";
import { PMSChatSelector, chats } from "./_reducers/chat.reducer";
import { PMSChatMessagesCollection } from "./_stores/PMSChatMessagesCollection";
import { PMSTagsCollection } from "./_stores/PMSTagsCollection";
import { PMSIntegrationsSelector, pmsIntegrations } from "./_reducers/integrations.reducer";
import { PMSAvaibookStore } from "./_stores/PMSAvaibookStore";

// ROOT STATE
let getRootState = state => state;

function setRootStateFn(getRootStateFn = state => state) {
    getRootState = getRootStateFn;
};

// REDUCER
const reducer = combineReducers({
    pmsUser: PMSUserStore.reducer,
    locations,
    tags: PMSTagsCollection.reducerCollection,
    chats,
    viewMode,
    secondaryViews: combineReducers({
        locations: PMSLocationsSecondaryViewStore.reducer,
    }),
    locationLogs: combineReducers({
        logs: PMSLocationLogsCollection.reducerCollection,
        dateFilter: PMSLocationLogsDateFilter.reducer,
    }),
    locationReservations: combineReducers({
        reservations: PMSLocationReservationsCollection.reducerCollection,
    }),
    integrations: PMSIntegrationsCollection.reducerCollection,
    locationIntegrations: PMSLocationIntegrationsCollection.reducerCollection,
    krossBooking: PMSKrossBookingStore.reducerCollection,
    locationGroups: PMSLocationGroupsCollection.reducerCollection,
    locationRooms: PMSLocationRoomsCollection.reducerCollection,
    hostify: PMSHostifyStore.reducerCollection,
    smoobu: PMSSmoobuStore.reducerCollection,
    octorate: PMSOctorateStore.reducerCollection,
    avaibook: PMSAvaibookStore.reducerCollection,
    customizationTemplate: PMSCustomizationTemplateStore.reducer,
    chatMessages: combineReducers({
        messages: PMSChatMessagesCollection.reducerCollection,
    }),
    pmsIntegrations
});

// SELECTORS
PMSUserStore.setRootStateFn(state => getRootState(state).pmsUser);
PMSLocationsSelector.setRootStateFn(state => getRootState(state).locations);
PMSLocationDetailModeSelectors.setRootState(state => getRootState(state).viewMode);
const getSecondaryViewState = state => getRootState(state).secondaryViews;
PMSLocationsSecondaryViewStore.setRootStateFn(state => getSecondaryViewState(state).locations);
PMSKrossBookingStore.setRootStateFn(state => getRootState(state).krossBooking);
PMSHostifyStore.setRootStateFn(state => getRootState(state).hostify);
PMSSmoobuStore.setRootStateFn(state => getRootState(state).smoobu);
PMSOctorateStore.setRootStateFn(state => getRootState(state).octorate);
PMSAvaibookStore.setRootStateFn(state => getRootState(state).avaibook);
PMSCustomizationTemplateStore.setRootStateFn(state => getRootState(state).customizationTemplate);
PMSChatSelector.setRootStateFn(state => getRootState(state).chats);
PMSIntegrationsSelector.setRootStateFn(state => getRootState(state).pmsIntegrations);


const selectors = {
    getPMSUser                      : PMSUserStore.selectorGet,
    tagsState                       : state => getRootState(state).tags,
    getTags                         : state => PMSTagsCollection.selectorGetItems(selectors.tagsState(state)),
    getPMSLocations                 : PMSLocationsSelector.getPMSLocations,
    getPMSLocationsFilter           : PMSLocationsSelector.getPMSLocationsFilter,
    getPMSLocationSelectedUid       : PMSLocationsSelector.getPMSLocationSelectedUid,
    getPMSLocationSelectedName      : PMSLocationsSelector.getPMSLocationSelectedName,
    getPMSLocationSelectedTimezone  : PMSLocationsSelector.getPMSLocationSelectedTimezone,
    getPMSLocationDevices           : PMSLocationsSelector.getPMSLocationDevices,
    getPMSLocationRooms             : PMSLocationsSelector.getPMSLocationRooms,
    getPMSLocationsSecondaryView    : PMSLocationsSecondaryViewStore.selectorGet,
    getPMSLocationDetailViewMode    : PMSLocationDetailModeSelectors.getPMSLocationDetailViewMode,
    locationLogsRootState           : state => getRootState(state).locationLogs,
    locationLogsState               : state => selectors.locationLogsRootState(state).logs,
    getPMSLocationLogsNextPage      : state => PMSLocationLogsCollection.selectorGetNextPage(selectors.locationLogsState(state)),
    getPMSLocationLogsDateFilter    : state => PMSLocationLogsDateFilter.selectorGetBeginEnd(selectors.locationLogsRootState(state).dateFilter),
    getPMSLocationLogsIsRequesting  : state => PMSLocationLogsCollection.selectorIsRequestingList(selectors.locationLogsState(state)),
    getPMSLocationLogsIsRequestingFirst : state => PMSLocationLogsCollection.selectorIsRequestingFirstPage(selectors.locationLogsState(state)),
    getPMSLocationLogs              : state => PMSLocationLogsCollection.selectorGetItems(selectors.locationLogsState(state)),
    getPMSLocationInfo              : PMSLocationsSelector.getPMSLocationInfo,
    locationReservationsRootState   : state => getRootState(state).locationReservations,
    locationReservationsState       : state => selectors.locationReservationsRootState(state).reservations,
    getPMSLocationReservations      : state => PMSLocationReservationsCollection.selectorGetItems(selectors.locationReservationsState(state)),
    getPMSChatFilter                : PMSChatSelector.getPMSChatFilter,
    getPMSChatIsRequesting          : PMSChatSelector.getPMSChatIsRequesting,
    chatMessagesRootState           : state => getRootState(state).chatMessages,
    chatMessagesState               : state => selectors.chatMessagesRootState(state).messages,
    getPMSChatMessages              : state => PMSChatMessagesCollection.selectorGetItems(selectors.chatMessagesState(state)),
    integrationsState               : state => getRootState(state).integrations,
    getPMSIntegrations              : state => PMSIntegrationsCollection.selectorGetItems(selectors.integrationsState(state)),
    locationIntegrationsState       : state => getRootState(state).locationIntegrations,
    getPMSLocationIntegrations      : state => PMSLocationIntegrationsCollection.selectorGetItems(selectors.locationIntegrationsState(state)),
    getPMSLocationCustomization     : PMSLocationsSelector.getPMSLocationCustomization,
    getPMSCustomizationIsUploading  : PMSLocationsSelector.getPMSCustomizationIsUploading,
    getPMSLocationsIsRequesting     : PMSLocationsSelector.getPMSLocationsIsRequesting,
    locationGroupsState             : state => getRootState(state).locationGroups,
    getPMSLocationGroups            : state => PMSLocationGroupsCollection.selectorGetItems(selectors.locationGroupsState(state)),
    getPMSReservationNewDraft       : PMSLocationsSelector.getPMSReservationNewDraft,
    getPMSCustomizationTemplate     : PMSCustomizationTemplateStore.selectorGet,
    getPMSOctorateUser              : PMSIntegrationsSelector.getPMSOctorateUser,
    ...CommonStore.selectors,
};

// EXPORT
export const PMSStore = {
    setRootStateFn,
    reducer,
    selectors,
};