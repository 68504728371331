import { Integration, ServerReservation } from "../_modelsTS/Integration";
import { IntegrationStore } from "../_stores/IntegrationStore";
import { IntegrationMapper, IntegrationReservationMapper } from "../_mappersTS/IntegrationMapper";
import { IntegrationService } from "../_services/integrations.service"
import { genericActions } from "./generic.actions";
import { IntegrationsSecondaryViewStore } from "../_stores/IntegrationsSecondaryViewStore";
import { URL_CONSTANTS } from "../_constants";

export const integrationsActions = {
    listIntegrations,
    createIntegration,
    deleteIntegration,
    listReservations,
    changeSecondaryView: IntegrationsSecondaryViewStore.actionSet,
    selectIntegrationCompany,
}

function listIntegrations(locationUid: string) {
    return async (dispatch) => {
        
            return await dispatch(genericActions.genericAsyncAction(asyncAction));

            async function asyncAction() {
                const serverIntegrations = await IntegrationService.listIntegrations(locationUid);
                const integrations = IntegrationMapper.allServerToLocal(serverIntegrations);

                dispatch(IntegrationStore.actionListItemsSuccess<Integration>(integrations))
                return integrations
            }

    }
}

function createIntegration(locationUid: string, localIntegration: Integration) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, null, true));

         async function asyncAction() {
            const serverIntegration = IntegrationMapper.localToServer(localIntegration);
            serverIntegration.handler_url = URL_CONSTANTS.URL_API + URL_CONSTANTS.INTEGRATION_HOSTIFY_HANDLER;
            const integration = await IntegrationService.createIntegration(locationUid, serverIntegration);

            dispatch(listIntegrations(locationUid));

            return integration;
        }
    }
}

function deleteIntegration(locationUid: string, integrationUid: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

         async function asyncAction() {
                await IntegrationService.deleteIntegration(locationUid, integrationUid);
                dispatch(IntegrationStore.actionDeleteItem(integrationUid));
        }
    }
}

function listReservations(locationUid: string, integrationUid: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

         async function asyncAction() {
                const serverReservations: ServerReservation[] = await IntegrationService.listReservations(locationUid, integrationUid);
                const reservations = IntegrationReservationMapper.allServerToLocal(serverReservations);
                dispatch(IntegrationStore.actionUpdateItemSuccess({ uid: integrationUid, reservations }))
                return reservations;
        }
    }
}

function selectIntegrationCompany(company) {
    return dispatch => {
        dispatch(integrationsActions.changeSecondaryView(company));
        dispatch(selectIntegrationCompany(company));
    }

    function selectIntegrationCompany(company) {

    }
}