import React from "react";
import { useTranslation } from 'react-i18next';

import { CustomDropdown } from "./CustomDropdown";
import { LocationModel } from "../_models/LocationModel";

export function LocationSelectorComp({
    dropDownTestID,
    selectedLocationUid = null,
    onSelectLocation = (locationUid) => {},
    locations = [] || [LocationModel()],
    readOnly = false
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div>
        {textWithDropdown(t("common.location"), dropdownFn())}
        </div>
    );

    
    function dropdownFn() {
        return <CustomDropdown
            testid={dropDownTestID}
            options={buildOptions()}
            value={selectedLocationUid}
            onChange={handleSelectLocation}
            isReadOnly={readOnly}
        />;
    }

    function buildOptions() {
        return locations.map(location =>
            CustomDropdown.buildOption(
                location.uid,
                location.name,
            )
        );
    }

    function textWithDropdown(title, dropdown) {
        return (
            <div
                style={{ display: "grid", gridTemplateColumns: "1fr auto" }}
                className="

                px-1
                mt-2
            "
            >
                <div>{title}</div>
                {dropdown}
            </div>
        );
    }

    //ACTION HANDLERS
    function handleSelectLocation(locationUid) {
        onSelectLocation(locationUid);
    }
}
