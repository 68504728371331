export function VideoLog({
    videoFragments = [VideoFragment()],
    videoThumbnail,
    logId,
    datetime,
} = {}) {
    return {
        videoFragments,
        videoThumbnail,
        logId,
        datetime,
    };
}

export function VideoFragment({
    videoIndex,
    videoUid,
} = {}) {
    return {
        videoIndex,
        videoUid,
    };
}