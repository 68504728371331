export function Subscription({
    uid,

    deviceUid,
    locationUid,

    type,
    typeLabel,
    status,
    statusLabel,
    code,
    begin,
    end,
    validated,

    roomName,

    renewalPeriod,
    isAutomatedPayment = false,
    price = null,
} = {}) {
    return {
        uid,

        deviceUid,
        locationUid,
        
        type,
        typeLabel,
        status,
        statusLabel,
        code,
        begin,
        end,
        validated,

        roomName,

        renewalPeriod,
        isAutomatedPayment,
        price,
    };
}