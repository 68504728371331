let rootState = state => state;

export class PMSLocationDetailModeSelectors {
    static setRootState(fn = state => state) {
        rootState = fn;
    }

    static getPMSLocationDetailViewMode(state) {
        return rootState(state).viewMode;
    }
}