import React from 'react';
import { useTranslation } from 'react-i18next';

import { GenericCustomDropdown } from '../_components/GenericCustomDropdown';
import { LOCATION_PERMISSIONS } from '../_constants/permissions.constants';
import { LocationModel } from '../_models/LocationModel';

import { invitationPermissionUiValues } from '../_constants';
import { CapsuleButton } from '../_components/CapsuleButton';
import { Icons } from '../_assets';

const testIds = {
    dropdown: "locations-dropdown",
    addLocationButton: "add-location-button",
    currentLocationButton: "current-location-button",
};

LocationsDropdown.testIds = testIds;

export function LocationsDropdown({
    locations = [] || [LocationModel()],
    handleLocationClick,
    selectedUid,
    selectedName,
    handleAddLocation,
}) {

    //LIBRARIES
    const { t } = useTranslation();
    
    //VIEW
    return (
        <GenericCustomDropdown
            testid={testIds.dropdown}
            selectedOptionFn={selectedLocation}
            optionsListFn={locationsDropDown}
            relativeToExternalParent={true}
        />
    );

    function selectedLocation() {
        return (
            <div
                data-testid={testIds.currentLocationButton}
                className="
                    d-flex
                    no-gutters
                    align-items-center
                    justify-content-between

                    overflow-auto

                    text-center
                    pl-3
                    pr-2
                    py-1
                    cp
                    c-cursor-default
                    c-bg-grey-250
                    c-hover-220
                    c-rounded-capsule
                "
            >
                <div className="
                    text-truncate
                    font-weight-bold
                    text-uppercase
                ">
                    {selectedName || t("locations.selectALocation")}
                </div>

                <div>
                    <div className="ml-1"/>
                    <img
                        src={Icons.arrowDown}
                        alt="dropdown icon"
                        className="c-img-1-5rem"
                    />
                </div>
            </div>
        );
    }
    
    function locationsDropDown(isOpen = false) {
        return (
            <div className={`

                c-mh-30rem
                c-custom-dropdown

                d-flex
                flex-column

                position-absolute
                
                mt-2
                px-2
                pb-2

                rounded
                c-box-shadow
                bg-white

                overflow-hidden
                c-z-index-full
                ${vScale()}
                c-transition-all
            `}>
                {addLocationButton()}
                <div className="overflow-auto">
                    {locationsButtons()}
                </div>
            </div>
        );

        function vScale() {
            return isOpen
                ? "c-v-scale-100 c-opacity-100"
                : "c-v-scale-0 c-opacity-0"
            ;
        }
    }

    function addLocationButton() {
        return (
            <div className="
                d-flex
                justify-content-between
                align-items-center
                py-2
            ">
                {locationsTitle()}
                <CapsuleButton
                    testId={testIds.addLocationButton}
                    text={t("webapp_global_create")}
                    onClick={handleAddLocation}
                />
            </div>
        );

        function locationsTitle() {
            return (
                <div className="
                    pl-2
                    font-weight-bold
                    text-uppercase
                ">
                    {t("common.locations")}
                </div>
            );
        }
    }

    function locationsButtons() {
        return (
            sortLocations(locations)
            .map((location, index) =>
                uiSingleLocation(
                    index,
                    location.name,
                    invitationPermissionUiValues[location.userType],
                    () => handleLocationClick(location),
                    location.uid === selectedUid,
                )
            )
        );

        function sortLocations(locations = [LocationModel()]) {
            const allSortedLocations = [];

            const orderedPermissions = [
                LOCATION_PERMISSIONS.OWNER,
                LOCATION_PERMISSIONS.ADMIN,
                LOCATION_PERMISSIONS.INSTALLER,
                LOCATION_PERMISSIONS.GUEST,
                LOCATION_PERMISSIONS.NO_ACCESS,
            ];

            for (let i = 0; i < orderedPermissions.length; i++) {
                const sortedLocations =
                    locations
                    .filter(location =>
                        location.userType === orderedPermissions[i]
                    )
                    .sort((a = LocationModel(), b = LocationModel()) =>
                        a.name.localeCompare(b.name)
                    )
                ;

                allSortedLocations.push(...sortedLocations);
            }

            return allSortedLocations;
        }
    }

    function uiSingleLocation(index, tag, permission, onClick, isSelected) {
        return (
            <div
                key={index}>
                <hr className="m-0 mx-1"/>
                <div
                    data-testid={tag}
                    onClick={onClick}
                    className={`
                        d-flex
                        justify-content-between
                        no-gutters
                        
                        c-hover-240

                        font-weight-normal
                        
                        cp
                        
                        px-2
                        py-1
                        mx-1

                        ${isSelected && "c-bg-grey-230"}
                    `}
                >
                    <div>
                        {tag}
                    </div>
                    <div>
                        {permission}
                    </div>
                </div>
            </div>
        );
    }
}