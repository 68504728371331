import { integrationsActions } from '../../_actions/integrations.actions';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IntegrationApp } from '../../_models/IntegrationApp';
import { CustomCard } from '../../_components';
import { integrationLogoForCompany } from '../../_assets/integrationsLogo';

type SingleDeviceProps = {
    integration: any,
    selectedIntegrationId?: string,
    status: any,
}

export function SingleIntegration({
    integration = IntegrationApp(),
    selectedIntegrationId,
    status,
}: SingleDeviceProps) {

    //LIBRARIES
    const dispatch = useDispatch();
    const isSelected = useMemo(
        () => selectedIntegrationId === integration.id,
        [selectedIntegrationId, integration.id],
    );
   
    //VIEW
    return (
        <CustomCard
            onClick={() => {
                openIntegrationDetails();
            }}
            className="cp"
            selected={isSelected}
            banner={showStatus()}
        >
            <div
                data-testid="single-integration"
                className={`
                d-flex
                no-gutters
                align-items-center
            `}
            >
                {logo()}
            </div>
        </CustomCard>
    );


    function logo() {
        return (
            <img
            className="c-img w-100"
            src={integrationLogoForCompany(integration.company)}
            alt="device type icon"
            />
        );
    }

    function showStatus() {
        if (status == null) 
            return null;

        return (
            <div
                className="
                    text-right
                    c-line-height-1rem
                "
            >
                { status }
            </div>
        );
    }

    //ACTION HANDLERS
    function openIntegrationDetails() {
        return dispatch(integrationsActions.changeSecondaryView(integration.company));
    }
}