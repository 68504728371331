import { UserStatusLog } from "../_models/UserStatusLog";

export class UserStatusLogsMapper {
    static allServerToLocal(serverUserStatusLogs = []) {
        return serverUserStatusLogs.map(serverToLocal);
    }
}

function serverToLocal(serverUserStatusLog) {
    return UserStatusLog({
        type: serverUserStatusLog.type,
        datetime: serverUserStatusLog.datetime,
        status: serverUserStatusLog.status,
    });
}