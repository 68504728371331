import React from "react";
import { useState, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { ClockinActions } from "../_stores/ClockinStore/ClockinActions";
import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { CapsuleButton } from "../../Everou/_components/CapsuleButton";

export function ExportClockinsPopUp({
    locationUid,
    begin,
    end
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [isRequesting, setIsRequesting] = useState(null);

    //ACTION HANDLERS
    const actionWrapper = useCallback(async asyncFn => {
        setIsRequesting(true);
        await asyncFn();
        setIsRequesting(false);
    }, []);

    const handleExportCSV = useCallback(async () => {
        actionWrapper(() => dispatch(ClockinActions.downloadCSVReport(locationUid, begin, end)))
    }, 
        [dispatch, actionWrapper, locationUid, begin, end]
    );

    const handleExportExcel = useCallback(async () => {
        actionWrapper(() => dispatch(ClockinActions.downloadExcelReport(locationUid, begin, end)))
    }, 
        [dispatch, actionWrapper, locationUid, begin, end]
    );

    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={title()}
            body={body()}
            cancelButtonName={t("common.dismiss")}
            hideAccept={true}
        />
    );

    function title() {
        return t("global_export");
    }

    function body() {
        return (
            <div>
                {t("mod_clockins_export_description")}
                <div className="
                    d-flex
                    justify-content-around
                    pt-2
                ">
                    {csvButton()}
                    {excelButton()}
                </div>
            </div>
        );
    }

    function csvButton() {
        return (
            <CapsuleButton
                text={t("CSV")}
                onClick={handleExportCSV}
                isEnabled={!isRequesting}
            />
        );
    }

    function excelButton() {
        return (
            <CapsuleButton
                text={t("EXCEL")}
                onClick={handleExportExcel}
                isEnabled={!isRequesting}
            />
        );
    }
}