export function UserLog({
    uid,
    datetime,
    logType,
    latitude,
    longitude,
    locationName,
    
    local_address,
} = {}) {
    return {
        uid,
        datetime,
        logType,
        latitude,
        longitude,
        locationName,

        local_address,
    };
}