export function GenericHashArraysStoreFactory(INSTANCE_ID, itemIdKeyName) {

    const ACTION_TYPES = {
        SET_SELECTED_ITEM_ID:       "SET_SELECTED_ID_" + INSTANCE_ID,
        SET_SELECTED_ARRAY_ID:      "SET_SELECTED_ARRAY_ID_" + INSTANCE_ID,
        SET_SELECTED_ARRAY_ITEMS:   "SET_SELECTED_ARRAY_ITEMS_" + INSTANCE_ID,
        SET_ARRAY_WITH_ID:          "SET_ARRAY_WITH_ID_" + INSTANCE_ID,
    };

    //ACTIONS
    function setSelectedItemId(id) {
        return {
            type: ACTION_TYPES.SET_SELECTED_ITEM_ID,
            id,
        };
    }

    function setArrayWithId(id, items) {
        return {
            type: ACTION_TYPES.SET_ARRAY_WITH_ID,
            id,
            items,
        };
    }

    function setSelectedArrayItems(items) {
        return {
            type: ACTION_TYPES.SET_SELECTED_ARRAY_ITEMS,
            items,
        };
    }

    function setSelectedArrayId(id) {
        return {
            type: ACTION_TYPES.SET_SELECTED_ARRAY_ID,
            id,
        };
    }

    //SELECTORS
    function getSelectedItem(state, arrayId) {
        arrayId = arrayId || (state && state.selectedArrayId);

        if (!arrayId)
            return null;

        const selectedMap = state.hashMap[arrayId];

        if (!selectedMap)
            return null;

        return selectedMap.find(item =>
            item[itemIdKeyName] === state.selectedItemId
        );
    }

    function getArrayWithId(state, id) {
        return state.hashMap[id] || [];
    }

    function getSelectedArray(state) {
        return state.hashMap[state.selectedArrayId] || [];
    }

    //REDUCER
    const initialState = {
        selectedItemId: null,
        selectedArrayId: null,
        hashMap: {},
    };

    function reducer(state = initialState, action) {
        switch (action.type) {

            case ACTION_TYPES.SET_SELECTED_ARRAY_ID: {
                return {
                    ...state,
                    selectedArrayId: action.id,
                };
            }

            case ACTION_TYPES.SET_ARRAY_WITH_ID: {
                return {
                    ...state,
                    hashMap: {
                        ...state.hashMap,
                        [action.id]: action.items,
                    },
                };
            }

            case ACTION_TYPES.SET_SELECTED_ARRAY_ITEMS: {
                return {
                    ...state,
                    hashMap: {
                        ...state.hashMap,
                        [state.selectedArrayId]: action.items,
                    },
                };
            }

            case ACTION_TYPES.SET_SELECTED_ITEM_ID: {
                return {
                    ...state,
                    selectedItemId: action.id,
                };
            }
            
            default:
                return state;
        }
    }
    
    //FULL STORE
    return {
        reducer,
        actions: {
            setSelectedItemId,
            setArrayWithId,
            setSelectedArrayItems,
            setSelectedArrayId
        },
        selectors: {
            getArrayWithId,
            getSelectedItem,
            getSelectedArray,
        },
        ACTION_TYPES,
    };
}