import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericCustomDropdown } from './GenericCustomDropdown';
import { Icons } from '../_assets/icons/Icons';

import {
    FILTER_DISABLED_ALL,
} from '../../Everou/_constants';

CustomDropdown.buildOption = (value, label): BuildOptionType => {
    if (label === undefined)
        label = value;

    return {
        value,
        label,
    };
}

type BuildOptionType = {
    label: string;
    value: string;
}

type CustomDropdownProps = {
    title?: string,
    testIDSelectedOption?: string | number, 
    testid?: string | number,
    options?: BuildOptionType[],
    topOptions?: BuildOptionType[]
    onChange?: (...args: any) => {} | void,
    value?: any,
    isReadOnly?: boolean,
    sortAlphabetically?: boolean,
    isSearchable?: boolean;
    mode?: 'to-left' | 'to-right'
}

const fn = () => {};

export function CustomDropdown({
    title,
    testIDSelectedOption,
    testid,
    options = [],
    topOptions = [],
    
    onChange = fn,
    value: selectedValue,
    isReadOnly = false,
    sortAlphabetically = false,
    isSearchable = true,
    mode = 'to-right'
}: CustomDropdownProps) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [isSearching, setIsSearching] = useState(false);
    const [filter, setFilter] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const pendingLetterRef = useRef("");
    const hideSelectedOption = false;
    const hideIfSingleOption = false;

    const keypressHandler = useCallback((e) => {
        pendingLetterRef.current = e.key;
        setIsSearching(true);
    },[]);

    const keydownHandler = useCallback((e) => {
        if (e.key === "Escape" && inputRef.current) {
            inputRef.current?.blur();
        }
    },[]);

    //EFFECTS
    useEffect(() => {
        if (!isSearching)
            return;
        
        inputRef.current?.focus();
        inputRef.current?.click();
        setFilter(pendingLetterRef.current);
    }, [isSearching]);

    //VIEW
    return (
        <GenericCustomDropdown
            isReadOnly={isReadOnly}
            testid={testid}
            selectedOptionFn={selectedOptionFn}
            optionsListFn={listOptionsBuilder}
            onBlur={onBlurCleanUp}
            setUp={onFocusSetUp}
        />
    );

    function selectedOptionFn() {
        const selectedOption = getSelectedOption();
        return (
            <div
                data-testid={testIDSelectedOption}
                style={{ maxWidth: "100%" }}
                className={`
                    h-100
                    border
                    rounded
                    ${isReadOnly && "c-bg-grey-240"}
                `}
            >
                <div
                    data-testid={selectedOption?.value}
                    className="d-flex w-100 pl-2 justify-content-between"
                >
                    <div className="d-flex w-100">
                        {title && titleFn()}
                        <div className="ml-2" />
                        {selectedElementFn()}
                    </div>
                    <div className="ml-1" />
                    <div
                        className="d-flex w-auto"
                        style={{ justifySelf: "end" }}
                    >
                        {!isReadOnly && isSearchable && (
                            <>
                                {searchButton()}
                                <div className="ml-1" />
                            </>
                        )}
                        {customArrow()}
                    </div>
                </div>
            </div>
        );

        function getSelectedOption() {
            if (selectedValue === undefined)
                return undefined;

            return [...options, ...topOptions].find(option => option.value === selectedValue);
        }

        function selectedElementFn() {
            if (isSearching) {
                return (
                    <input
                        ref={inputRef}
                        onChange={(e) => setFilter(e.target.value)}
                        type="text"
                        value={filter}
                        placeholder={t("global_search_placeholder")}
                        className="
                            w-100
                            p-0
                            
                            c-no-focus-outline
                            border-0
                        "
                    />
                );
            }

            return (
                <span className="font-weight-bold my-auto text-left w-100">
                    {selectedOption?.label ?? "UNDEFINED"}
                </span>
            );
        }

        function titleFn() {
             return (
                <label className="my-auto text-nowrap">
                    {title}:
                </label>
            );
        }

        function searchButton() {
            return (
                <div
                    onClick={handleClickSearch}
                    className={`
                        ${isSearching && "c-bg-grey-210 "}
                        c-hover-220
                        my-auto
                        px-1
                        rounded
                    `}
                >
                    <img
                        className="c-img-1_5rem"
                        src={Icons.search}
                        alt={"search_img"}
                    />
                </div>
            );
        }

        function customArrow() {
            if (isReadOnly)
                return;
    
            return (
                <div
                    className="
                        c-bg-grey-240
                        rounded-right
                        c-hover-220
    
                        d-flex
                        align-items-center
                    "
                >
                    <img
                        className="
                            c-img-1-5rem
                        "
                        src={Icons.arrowDown}
                        alt={"arrow_icon"}
                    />
                </div>
            );
        }
    }

    function listOptionsBuilder(isOpen) {
        return (
            <div
                className={`
                    ${mode === 'to-left' ? 'right-0' : 'left-0' }
                    c-custom-dropdown
                    position-absolute
                    
                    bg-white
                    rounded
                    mt-1

                    ${vScale()}
                    overflow-auto
                    c-transition-all
                    
                    c-box-shadow

                    c-mh-20rem
                    border
                `}
                style={{width: "max-content" }}
            >
                {optionsFn()}
            </div>
        );

        function vScale() {
            return isOpen
                ? "c-v-scale-100 c-opacity-100"
                : "c-v-scale-0 c-opacity-0"
            ;
        }
        
        function optionsFn() {
            if (options === undefined)
                return;

            if (hideIfSingleOption &&
                (options.length + topOptions.length) === 1 && 
                selectedValue)
                    return;


            const combinedOptions = (hideSelectedOption)
            ? filterSelected(combineOptions())
            : combineOptions();

            const filteredBySearchOptions = filterBySearch(combinedOptions);

            return (
                filteredBySearchOptions
                .map(({label, value}) =>
                    <div
                        data-testid={value}
                        key={value}
                        onClick={() => onClickOption(value)}
                        className={`px-2 py-1 c-hover-240 ${value === selectedValue ? "c-bg-grey-240" : ""}`}
                    >
                        {label}
                    </div>
                )
            );
        }

        function filterSelected(options: BuildOptionType[] = []) {
            if (isSearching)
                return options;
            
            return options.filter(option => option.value !== selectedValue);
        }

        function filterBySearch(options: BuildOptionType[] = []) {
            if (!isSearching || !filter)
                return options;

            return options.filter(option => option.label.toLowerCase().includes(filter.toLowerCase()));
        }

        function combineOptions() {
            let combinedOptions = [...options];

            if (sortAlphabetically) {
                combinedOptions = combinedOptions.sort(function(a, b) {
                    if (a.label === FILTER_DISABLED_ALL.value) {
                        return -1; 
                    }
                    else if (b.label === FILTER_DISABLED_ALL.value) {
                        return 1;
                    }
                    else {
                        return a.label.localeCompare(b.label)
                    }
                });
            }
    
            if (topOptions.length) {
                combinedOptions.unshift(...topOptions);
            }

            return combinedOptions;
        }
    }

    //LOCAL HANLDERS
    function handleClickSearch(e) {
        e.stopPropagation();
        if (isSearching) {
            resetSearch();
            return;
        }

        setIsSearching(true);
    }

    function resetSearch() {
        setIsSearching(false);
        setFilter("");
        pendingLetterRef.current = "";
    }

    //EXTERNAL HANDLERS
    function onClickOption(value) {
        onChange(value);
    }

    function onBlurCleanUp() {
        if (!isSearchable) return;
        resetSearch();
        window.removeEventListener("keypress", keypressHandler);
        window.removeEventListener("keydown", keydownHandler);
    }

    function onFocusSetUp() {
        if (!isSearchable) return;
        window.addEventListener("keypress", keypressHandler);
        window.addEventListener("keydown", keydownHandler);
    }
}