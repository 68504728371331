import { URL_CONSTANTS } from "../_constants";
import { ServiceHelper } from "./ServiceHelper";

export const EverouDevelopersService = {
    list,
    remove,
    add,
};

function getBaseUrl(locationUid) {
    return "/locations/" + locationUid;
}

async function list(locationUid) {
    return await ServiceHelper.getRequest(
        getBaseUrl(locationUid) + URL_CONSTANTS.DEVELOPERS
    );
}

async function remove(locationUid, uid) {
    await ServiceHelper.deleteRequest(getBaseUrl(locationUid) + URL_CONSTANTS.DEVELOPERS + "/" + uid);
}

async function add(locationUid, email) {
    return await ServiceHelper.postRequest(
        getBaseUrl(locationUid) + URL_CONSTANTS.DEVELOPERS,
        { email },
    );
}