import Module from "./libstream-2.2.3r1";

export const libstreamWrapper = {
    registerLibstreamModule,
    rawToArrays,
    isReady,
};

function isReady() {
    return window.libStream !== undefined;
}

function registerLibstreamModule() {
    if (window.libStream !== undefined) {
        console.log("libstream already loaded");
        return;
    }

    Module().then((libStream) => {
        window.libStream = libStream;
        console.log("module loaded", window.libStream);
    });
}

function rawToArrays(arrayBuffers) {
    const Module = window.libStream;
    const processRawStream = Module.cwrap('process_raw_stream', 'number', ['number']);

    arrayBuffers.forEach(arrayBuffer => {
        putContainerDataIntoFiles(Module, arrayBuffer)
    });
    
    return getDataFromFiles(Module);

    ////
    function putContainerDataIntoFiles(Module, arrayBuffer) {
        const uInt8Array = new Uint8Array(arrayBuffer);
        const bufferPtr = Module._malloc(uInt8Array.byteLength);
        Module.HEAPU8.set(uInt8Array, bufferPtr);

        const resultStatus = processRawStream(bufferPtr);
        console.warn("LIBSTREAM RESULT STATUS", resultStatus);

        Module._free(bufferPtr);
    }

    function getDataFromFiles(Module) {
        const binaryVideoData = Module.FS.readFile("/videoOutFile", { encoding: "binary" });
        const binaryAudioData = Module.FS.readFile("/audioOutFile", { encoding: "binary" });

        Module.FS.unlink("/videoOutFile");
        Module.FS.unlink("/audioOutFile");

        return {
            video: binaryVideoData,
            audio: binaryAudioData,
        };
    }
}