import React from "react";
import { useTranslation } from 'react-i18next';

import { GoogleLogin } from "react-google-login";
import { GOOLGE_CLIENT_ID } from "../_constants/url.constants";
import { Icons } from "../_assets";
import { SimpleCapsuleButton } from "../../Common/_components/SimpleCapsuleButton";

export function GoogleLoginModule({
    handleLogin = (token, email) => {},
} = {}) {
    //ACTION HANDLERS
    function handleGetToken(serverResponse) {
        const responseObj = ResponseObj(serverResponse);
        handleLogin(responseObj.tokenId, responseObj.email);
    }

    function handleFailedToGetToken(response) {
        console.error("FAILED TO GET GOOGLE LOGIN", response);
    }

    function ResponseObj(serverObj) {
        return {
            tokenId: serverObj.tokenId,
            email: serverObj.profileObj && serverObj.profileObj.email,
        };
    }

    //VIEW
    return (
        <GoogleLogin
            render={CustomGoogleLoginButton}
            clientId={GOOLGE_CLIENT_ID}
            onSuccess={handleGetToken}
            onFailure={handleFailedToGetToken}
        />
    );
}

function CustomGoogleLoginButton({
    onClick = () => {},
    disabled,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <SimpleCapsuleButton onClick={onClick}>
            <img
                src={Icons.google}
                alt="gloogle_icon"
                className="c-img-1rem my-auto"
            />
            <div className="ml-2"/>
            <div className="text-dark c-font-weight-medium">
                {t("login_with_google")}
            </div>
        </SimpleCapsuleButton>
    );
}