import { User } from "./User";

export function UserStatus({
    user = User(),
    status = 0,
} = {}) {
    return {
        user,
        status,
    };
}