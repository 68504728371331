import { DEVICE_TYPES, LOCAL_STATUS_FOR_UI_LOG_TYPE, UI_LOG_TYPES } from "../_constants";

import closedIcon from './icons/device-icon-reed-closed.png';
import openicon from './icons/device-icon-reed-opened.png';

import offIcon from './icons/device-icon-plug-off@3x.png';
import offLightbulbIcon from './icons/device-icon-light-off@3x.png';
import onLightbulbIcon from './icons/device-icon-light-on@3x.png';
import onIcon from './icons/device-icon-plug-on@3x.png';

import stoppedRollerIcon from './icons/device-icon-blind@3x.png';
import openedRollerIcon from './icons/device-icon-blind-up@3x.png';
import closedRollerIcon from './icons/device-icon-blind-down@3x.png';

import entryIcon from './icons/icon_enter.png';
import exitIcon from './icons/icon_exit.png';

import plugFloodClosedIcon from './icons/icon_plug_flood_off.png';

import floodIcon from './icons/device-icon-humedad@3x.png';

import coverClosedIcon from './icons/device-icon-lock-close@3x.png';
import coverOpenedIcon from './icons/device-icon-lock-open@3x.png';

import disconnectedIcon from './icons/device-icon-brain-off@3x.png';
import connectedIcon from './icons/device-icon-brain-on@3x.png';

import secondaryPowerIcon from './icons/device-icon-plug-off@3x.png';
import acPowerIcon from './icons/device-icon-plug-on@3x.png';

import inhibitionIcon from './icons/icon_bluetooth.png';

import gowayLockOpenIcon from './icons/log_icon_lock_open_blue.png';
import gowayLockOpenLockedIcon from './icons/log_icon_lock_closed_blue.png';
import gowayLockClosedUnlockedIcon from './icons/log_icon_lock_open_grey.png';
import gowayLockClosedLockedIcon from './icons/log_icon_lock_closed_grey.png'

import movementIcon from './icons/device-icon-brain-movement@3x.png';
import vibrationsIcon from './icons/device-icon-senser@3x.png';

import rebootIcon from './icons/device-icon-standby@3x.png';
import batteryFaultIcon from './icons/icon_battery_log.png';
import videoIcon from './icons/device-icon-activecam@3x.png';
import placeholderIcon from './icons/device-icon-missing.png';
import { deviceIconForType } from "./deviceIcons";

import beaconIcon from './icons/log_icon_beacon.png';
import clockinIcon from './icons/log_icon_beacon.png';
import deviceUpdatedIcon from './icons/icon_device_updated_log.svg';

import protectIcon from './icons/icon-protect@3x.png';
import protectOffIcon from './icons/icon-protect-off@3x.png';

const lightbulbTypes = [
    DEVICE_TYPES.Switcher,
    DEVICE_TYPES.DoubleSwitcher,
    DEVICE_TYPES.Lighting,
    DEVICE_TYPES.MultiSwitcher,
];

export function logIcon(localStatus, deviceType) {
    switch (localStatus) {
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE][0]:
            return closedIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE][1]:
            return openicon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.ON_OFF][0]: 
            return lightbulbTypes.includes(deviceType) ? offLightbulbIcon : offIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.ON_OFF][1]:
            return lightbulbTypes.includes(deviceType) ? onLightbulbIcon : onIcon;
        
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_STOP][0]:
            return stoppedRollerIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_STOP][1]:
            return openedRollerIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_STOP][2]:
            return closedRollerIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.ENTRY_EXIT][2]:
            return entryIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.ENTRY_EXIT][3]:
            return exitIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.ON_OFF_PLUG_FLOOD][0]:
            return floodIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.ON_OFF_PLUG_FLOOD][1]:
            return plugFloodClosedIcon;        

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.MOVEMENT]:
            return movementIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK][0]:
            return gowayLockClosedLockedIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK][1]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK][5]:
            return gowayLockClosedUnlockedIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK][2]:
            return gowayLockOpenLockedIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK][3]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.OPEN_CLOSE_GOWAY_LOCK_EMERGENCY][3]:
            return gowayLockOpenIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.VIBRATIONS]:
        return vibrationsIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.FLOOD][0]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.FLOOD][1]:
            return floodIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.DELETE_ALL_USERS]:
            return deviceIconForType(deviceType);

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BUTTON_PRESSED_TAMPER][0]:
            return coverClosedIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BUTTON_PRESSED_TAMPER][1]:
            return coverOpenedIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.VIDEO]:
        return videoIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.INTERNET_STATUS][0]:
            return disconnectedIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.INTERNET_STATUS][1]:
            return connectedIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BRAIN_POWER][0]:
            return secondaryPowerIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BRAIN_POWER][1]:
            return acPowerIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.INHIBITION][0]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.INHIBITION][1]:
            return inhibitionIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.REBOOT_DETECTED]:
            return rebootIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BATTERY_FAULT]:
            return batteryFaultIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][1]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][2]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][3]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][4]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][5]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][6]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][7]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][8]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][9]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][10]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][11]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][12]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][13]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][14]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][15]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][16]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][17]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][18]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][19]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][20]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.BEACON_DETECTED][21]:
            return beaconIcon;
        
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_LOCALIZATION][0]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_MANUAL][0]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_PERIOD][0]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_TRIGGER][0]:
            return protectOffIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_LOCALIZATION][1]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_MANUAL][1]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_PERIOD][1]:
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.LOCATION_PROTECT_TRIGGER][1]:
            return protectIcon;

        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.UI_TYPE_CLOCKIN]:
            return clockinIcon;
        case LOCAL_STATUS_FOR_UI_LOG_TYPE[UI_LOG_TYPES.UI_TYPE_VERSION_UPDATED]:
            return deviceUpdatedIcon;

        default:
            return placeholderIcon;
    }
}