import { URL_CONSTANTS } from '../_constants';
import { ServiceHelper } from './ServiceHelper';

export const deviceLogsService = {
    getLocationDevicesLogs,
    logFilterTypes,
    buildLogsDeviceFilter,
};

function buildLogsDeviceFilter(filterType, contentArray) {
    return {
        filterType,
        contentArray,
    };
}

function logFilterTypes() {
    return {
        deviceTypes: "device_types",
        deviceOriginalTypes: "device_original_types",
        rooms: "rooms",
    };
}

async function getLocationDevicesLogs(
    locationUid,
    devicesUids,
    brainsUids,
    startDate,
    endDate,
    pageSize,
    page,
    status,
    uiLogType,
    userUid,
    deviceFilterObject,
) {
    const params =
        buildParams(
            locationUid,
            devicesUids,
            brainsUids,
            startDate,
            endDate,
            pageSize,
            page,
            status,
            uiLogType,
            userUid,
            deviceFilterObject
        )
    ;
    const response = await ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_LOG,
        params,
    );

    return {
        logs: response.logs,
        next: response.next,
    };
}

function buildParams(
    locationUid,
    devicesUids,
    brainsUids,
    startDate,
    endDate,
    pageSize,
    page,
    status,
    uiLogType,
    userUid,
    deviceFilterObject
) {

    const filterParams = filterLocalToServer(deviceFilterObject);
    const brainsParam = arrayToQuery(brainsUids, "brains");
    const devicesParam = arrayToQuery(devicesUids, "devices");
    const uiLogTypeParam = uiLogType === null ? `` : `&log_ui_type=${uiLogType}`;
    const statusParam = status === null ? `` : `&status=${status}`;
    const userUidParam = userUidParamBuilder(userUid);

    const params = `\
        ?loc_uid=${locationUid}\
        &begin=${startDate}\
        &end=${endDate}\
        ${devicesParam}\
        ${brainsParam}\
        &offset=${page}\
        &page_size=${pageSize}\
        ${statusParam}\
        ${uiLogTypeParam}\
        ${userUidParam}\
        ${filterParams}\
    `;
    
    const paramsNoSpaces = params.replace(/\s/g, '');
    return paramsNoSpaces;
}

function filterLocalToServer(deviceFilterObject) {
    if (deviceFilterObject === undefined)
        return "";

    return arrayToQuery(deviceFilterObject.contentArray, deviceFilterObject.filterType);
}

function userUidParamBuilder(userUid) {
    let userUidParam = `&user_uid=`;
    switch (userUid) {
        case undefined:
            return ``;
        
        case null:
            return userUidParam;

        default:
            return userUidParam + userUid;
    }
}

function arrayToQuery(array, name) {
    if (array === undefined)
        return "";

    let queryArray="";
    array.forEach(element => {
        const elementString = `&${name}=${element}`;
        queryArray += elementString;
    });

    return queryArray;
}