import { LocationModel } from "../_models/LocationModel";
import { LOCATION_PERMISSIONS } from "../_constants/permissions.constants";

export class LocationMapper {

    static mapAllServerToLocal(serverLocations) {
        const localLocations = [];
        for (const serverUserType in serverLocations) {
            const userTypeLocations = serverLocations[serverUserType];

            userTypeLocations.forEach(serverLocation => {

                for (const j in localLocations) {
                    if (localLocations[j].uid === serverLocation.loc_uid)
                        localLocations.splice(j, 1);
                }

                localLocations.push(
                    locationServerToLocal(serverLocation, serverUserType)
                );
            });
        }

        return localLocations;
    }
}

function locationServerToLocal(serverLocation, serverUserType) {
    const {
        name,
        loc_uid,
        invitation_uid,
        allows_subinvitations,
        timezone
    } = serverLocation;

    return LocationModel({
        userType: serverToLocalUserType(serverUserType),
        name,
        uid: loc_uid,
        invitationUid: invitation_uid,
        allowsSubinvitations: allows_subinvitations,
        timezone
    });

    function serverToLocalUserType(serverUserType) {
        switch(serverUserType) {
            case "owner":
                return LOCATION_PERMISSIONS.OWNER;
                
            case "admin":
                return LOCATION_PERMISSIONS.ADMIN;

            case "installer":
                return LOCATION_PERMISSIONS.INSTALLER;

            case "guest":
                return LOCATION_PERMISSIONS.GUEST;

            case "no_access":
                return LOCATION_PERMISSIONS.NO_ACCESS;

            default:
                return -1;
        }
    }
}