import React from 'react';
import { render } from 'react-dom';
import * as Sentry from "@sentry/react";
import { WebApp } from "./Everou/WebApp";
import { VersionHelper } from './Everou/_helpers/VersionHelper';
import { SENTRY_DSN } from "./Everou/_constants/url.constants";

if (SENTRY_DSN !== "" && SENTRY_DSN !== "DEFAULT") {
    Sentry.init({
        dsn: SENTRY_DSN,
        release: "everou-webapp@"+ VersionHelper.getAppVersion(),
        integrations: [],
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
    });
}

render(
    <WebApp />,
    document.getElementById('app'),
);
