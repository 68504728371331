import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { auditLogsActions } from "../_actions/auditLogs.actions";

export function RemoveAuditFilterPresetGlobalPopUp({
    locationUid,
    uid,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <BaseAppGlobalPopUp
            title={t("webapp_audit_remove_preset")}
            body={body()}
            acceptButtonName={t("global_accept")}
            cancelButtonName={t("global_cancel")}
            onAccept={handleDelete}
        />
    );

    function body() {
        return t("webapp_audit_remove_preset_desc");
    }

    function handleDelete() {
        dispatch(auditLogsActions.removeAuditLogsFilterPreset(
            locationUid,
            uid,
        ));
    }
}