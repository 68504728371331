import { Device } from '../_models/Device';
import { i18n } from "../_translations/i18n";

import {
    deviceFilterModes,
    deviceTypeName,
    getCategoryForDeviceType,
    DEVICE_TYPES,
    LOCAL_STATUS,
    PROTECT_STATES,
    DEVICES_WITH_HIDDEN_STATUS,
    PROXIMITY_SIZES,
    DEVICE_CATEGORIES,
    filterModeParameters,
} from '../_constants';
import { TimeHelper } from './TimeHelper';
import { VersionHelper } from './VersionHelper';
import { DeviceLogsUiHelper } from './DeviceLogsUiHelper';
import { DeviceFilter } from '../_models/DeviceFilter';
import { DeviceFunctionality } from './DeviceFunctionality';
import { sortAlphabeticallyBy } from '../../Common/_utils/utils';
import {
    getTxSizesForDeviceType,
    getExtenderProximitySize,
    getContactProximitySize,
    DEVICE_TYPES_ABLE_TO_BARRIERS
} from "../_constants/devices.constants";

export class DeviceHelper {
    static getDeviceRoomNameFromDevices(deviceUid, devices = [] || [Device()]) {
        const device = devices.find((device) => device.uid === deviceUid);
        return device && device.room_name;
    }

    static getTriggerSideADeviceLocalStatus(deviceType, status) {
        return getTriggerSideADeviceLocalStatus(deviceType, status);
    }

    static getTriggerSideBDeviceLocalStatus(deviceType, status) {
        return getTriggerSideBDeviceLocalStatus(deviceType, status);
    }

    static getLocalStatusForDevice(deviceType, status) {
        return getLocalStatusForDevice(deviceType, status);
    }

    static getWLanSSIDForDevice(deviceType, networkParameters) {
        if (!DeviceFunctionality.supportsWlanNetwork(deviceType)) return "";

        if (networkParameters === null || networkParameters === undefined)
            return "";
        if (
            networkParameters.interfaces === null ||
            networkParameters.interfaces === undefined
        )
            return "";
        if (networkParameters.interfaces.wlan === undefined) return "";

        return networkParameters.interfaces.wlan.ssid;
    }

    static getWifiRssiForDevice(deviceType, networkParameters) {
        if (!DeviceFunctionality.supportsWlanNetwork(deviceType)) return "";

        if (networkParameters === null || networkParameters === undefined)
            return "";
        if (
            networkParameters.interfaces === null ||
            networkParameters.interfaces === undefined
        )
            return "";
        if (networkParameters.interfaces.wlan === undefined) return "";

        return networkParameters.interfaces.wlan.rssi;
    }

    static getWLanStatusForDevice(deviceType, networkParameters) {
        if (!DeviceFunctionality.supportsWlanNetwork(deviceType)) return "";

        if (networkParameters === null || networkParameters === undefined)
            return "";
        if (
            networkParameters.interfaces === null ||
            networkParameters.interfaces === undefined
        )
            return "";
        if (networkParameters.interfaces.wlan === undefined) return "";

        if (networkParameters.interfaces.wlan.active) return "Conectado";
        else return "Desconectado";
    }

    static getEthernetStatusForDevice(deviceType, networkParameters) {
        if (!DeviceFunctionality.supportsEthernetNetwork(deviceType)) return "";

        if (networkParameters === null || networkParameters === undefined)
            return "";
        if (
            networkParameters.interfaces === null ||
            networkParameters.interfaces === undefined
        )
            return "";
        if (networkParameters.interfaces.eth === undefined) return "";

        if (networkParameters.interfaces.eth.active) return "Conectado";
        else return "Desconectado";
    }

    static getConnectionStatusForDevice(deviceType, networkParameters) {
        if (!DeviceFunctionality.supportsNetwork(deviceType)) return "";

        if (networkParameters === null || networkParameters === undefined)
            return "";

        if (networkParameters.connection_status) return "Conectado";
        else return "Desconectado";
    }

    static getDeviceUidsOfCategory(
        allDevices = [],
        allCategories = [],
        deviceFilterMode,
    ) {
        const selectedCategoriesIds = allCategories
            .filter((category) => category.active)
            .map((category) => category.id);
        return allDevices
            .filter((device) =>
                selectedCategoriesIds.includes(
                    device[filterModeParameters[deviceFilterMode]],
                ),
            )
            .map((device) => device.uid);
    }

    static hasDeviceChildOfType(parentDevice, allDevices, deviceType) {
        const children = getDeviceChildren(parentDevice, allDevices);
        return children.some((device) => device.type === deviceType);
    }

    static getProximitySize(device = Device()) {
        if (device.extender === undefined) return "MISSING DATA";

        return getSizeForValue(
            device.txPower,
            getTxSizesForDeviceType(device.type) || getLegacySizes(),
        );

        function getSizeForValue(txPower, [small, medium, large]) {
            const SIZE_FOR_VALUE = {
                [small]: PROXIMITY_SIZES.SMALL,
                [medium]: PROXIMITY_SIZES.MEDIUM,
                [large]: PROXIMITY_SIZES.LARGE,
            };

            return SIZE_FOR_VALUE[txPower] || PROXIMITY_SIZES.CUSTOM;
        }

        function getLegacySizes() {
            return device.extender
                ? getExtenderProximitySize()
                : getContactProximitySize();
        }
    }

    static haveSelectedDevicesChanged(prevSelectedDevices, newSelectedDevices) {
        if (hasNumberOfSelectedDevicesChanged() || hasNewDeviceBeenSelected()) {
            return true;
        }

        ////
        function hasNumberOfSelectedDevicesChanged() {
            return newSelectedDevices.length !== prevSelectedDevices.length;
        }

        function hasNewDeviceBeenSelected() {
            for (let i = 0; i < newSelectedDevices.length; i++) {
                const isAlreadySelected = prevSelectedDevices.some(
                    (previousDevice) =>
                        previousDevice.uid === newSelectedDevices[i].uid,
                );

                if (!isAlreadySelected) return true;
            }

            return false;
        }
    }

    static isDeviceStatusVisible(device = Device()) {
        if (DEVICES_WITH_HIDDEN_STATUS.includes(device.type)) return false;

        if (
            device.statusLabel !==
                DeviceLogsUiHelper.getLocalStatusLabel(
                    LOCAL_STATUS.VIBRATIONS,
                ) &&
            device.isControlAccessDisabled
        ) {
            return false;
        }

        return true;
    }

    static isDeviceConnectionStatusVisible(device = Device()) {
        if (!DeviceFunctionality.supportsNetwork(device.type)) return false;

        return true;
    }

    static isThereBrain(devices = []) {
        return devices.some((device) => device.type === DEVICE_TYPES.Brain || device.type === DEVICE_TYPES.EasyIntegrations);
    }

    static getUnlimitedUsesSubscriptionCompatibleDevices(
        devices = [] || [Device()],
    ) {
        return devices.filter((device) =>
            DeviceFunctionality.supportsUnlimitedUsesSubscription(device.type),
        );
    }

    static getDeviceProtectState(device = Device(), isThereBrain) {
        if (!device.notification) return PROTECT_STATES.OK;

        if (device.isControlAccessDisabled) return PROTECT_STATES.OK;

        if (this.isDeviceInfoObsolete(device, isThereBrain))
            return PROTECT_STATES.OBSOLETE;

        if (!isDeviceStatusValid()) return PROTECT_STATES.WRONG_STATUS;

        return PROTECT_STATES.OK;

        function isDeviceStatusValid() {
            switch (device.type) {
                case DEVICE_TYPES.Movement:
                    return true;

                case DEVICE_TYPES.PlugFlood:
                    return device.localStatus === LOCAL_STATUS.FLOOD_OPEN;

                case DEVICE_TYPES.Roller:
                    return device.localStatus === LOCAL_STATUS.ROLLER_CLOSED;

                case DEVICE_TYPES.GowayLock:
                    return device.localStatus === LOCAL_STATUS.CLOSED_LOCKED;

                case DEVICE_TYPES.Brain:
                    return device.localStatus === LOCAL_STATUS.CONNECTED;

                default:
                    return device.status !== 1;
            }
        }
    }

    static isDeviceInfoObsolete(device = Device(), isThereBrain) {
        if (!isThereBrain) return false;

        if (!doesDeviceSupportLastActivity(device.originalType)) return false;

        if (!DeviceFunctionality.supportsLostSignalAlert(device.type))
            return false;

        if (!device.lastActivity) return false;

        if (!VersionHelper.isVersionHigherThan(device.version, "2.6.9"))
            return false;

        return TimeHelper.isIsoDateBeforeOneHourAgo(device.lastActivity);

        function doesDeviceSupportLastActivity(originalType) {
            return originalType !== DEVICE_TYPES.Movement;
        }
    }

    static filterProtectDevices(devices = [Device()]) {
        const protectDevices = [];
        const nonProtectDevices = [];

        devices
            .filter((device) =>
                DeviceFunctionality.supportsProtect(device.type),
            )
            .forEach((device) => {
                if (device.notification) {
                    protectDevices.push(device);
                    return;
                }

                nonProtectDevices.push(device);
            });

        return {
            protectDevices,
            nonProtectDevices,
        };
    }

    static getUniqueDeviceTypesFromDevices(allDevices = [Device()]) {
        const allDeviceTypes = allDevices.map((device) => device.type);
        const uniqueDeviceTypes = [...new Set(allDeviceTypes)];
        return uniqueDeviceTypes;
    }

    static getTypeForUidFromDevices(uid, allDevices = [Device()]) {
        const device = allDevices.find((device) => device.uid === uid);

        if (device === undefined) return;

        return device.type;
    }

    static getNameForUidFromDevices(uid, allDevices = [Device()]) {
        const device = allDevices.find((device) => device.uid === uid);

        if (device === undefined) return;

        return device.description;
    }

    static groupDevicesByKits(allDevices = [Device()]) {
        var kitDevices = [];
        for (var deviceIndex in allDevices) {
            const device = allDevices[deviceIndex];
            device.children = getDeviceChildren(device, allDevices);
            kitDevices.push(device);
        }
        return kitDevices;
    }

    static isAbleToBarriers({ type } = Device()) {
        return DEVICE_TYPES_ABLE_TO_BARRIERS.includes(type);
    }

    static getDevicesNoDuplicates(devices = [Device()], group) {
        return devices.filter(
            (device) =>
                !(
                    isChild(device) &&
                    isSameCategoryAsParent(device, devices, group)
                ),
        );

        ////
        function isChild(device = Device()) {
            return device.kit_uid !== device.uid;
        }

        function isSameCategoryAsParent(
            child = Device(),
            allDevices = [Device()],
            group,
        ) {
            const parent = allDevices.find(
                (device) => device.uid === child.kit_uid,
            );

            if (!parent) {
                // console.warn("NO PARENT FOR CHILD", child);
                return false;
            }

            return child[group] === parent[group];
        }
    }

    static getAllFilterCategoriesFromDevices(
        devices = [Device()],
        deviceFilterMode,
    ) {
        let filterCategories = {};

        switch (deviceFilterMode) {
            case deviceFilterModes.DEVICES_TYPES:
                devices.forEach((device) => {
                    filterCategories = fillWithCategory(
                        filterCategories,
                        device.originalType,
                        deviceTypeName(device.originalType),
                    );
                });
                break;

            case deviceFilterModes.CATEGORIES:
                devices.forEach((device) => {
                    const categoryId = getCategoryForDeviceType(device.type);
                    const categoryName = getDeviceCategoryName(categoryId);
                    filterCategories = fillWithCategory(
                        filterCategories,
                        categoryId,
                        categoryName,
                    );
                });
                break;

            default:
                break;
        }

        return sortAlphabeticallyBy(Object.values(filterCategories), ["name"]);

        function fillWithCategory(filterCategories, id, name) {
            return {
                ...filterCategories,
                [id]: DeviceFilter({
                    id,
                    name,
                    devicesAmount:
                        (filterCategories[id]?.devicesAmount ?? 0) + 1,
                }),
            };
        }
    }
}

function getDeviceCategoryName(deviceCategory) {
    switch (deviceCategory) {
        case DEVICE_CATEGORIES.Security:
            return i18n.t("global_device_category_security");

        case DEVICE_CATEGORIES.Doors:
            return i18n.t("global_device_category_accesses");

        case DEVICE_CATEGORIES.Climate:
            return i18n.t("global_device_category_climate");

        case DEVICE_CATEGORIES.Lights:
            return i18n.t("global_device_category_lighting");

        case DEVICE_CATEGORIES.OnOff:
            return i18n.t("global_device_category_onoff");

        case DEVICE_CATEGORIES.DEVELOP:
            return "DEVELOP";
            
        default:
            return "UNDEFINED CATEGORY";
    }
}

function getDeviceChildren(parentDevice = Device(), allDevices = [Device()]) {
    const children = allDevices.filter(item =>
        item.kit_uid === parentDevice.uid
        && item.uid !== parentDevice.uid
    );
    return children;
}

function getLocalStatusForDevice(deviceType, status) {
    switch (deviceType) {
        case DEVICE_TYPES.Brain: {
            const localStatus = {
                0: LOCAL_STATUS.DISCONNECTED,
                1: LOCAL_STATUS.CONNECTED,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.Switcher: 
        case DEVICE_TYPES.DoubleSwitcher: 
        case DEVICE_TYPES.Lighting: 
        case DEVICE_TYPES.MultiSwitcher: {
            const localStatus = {
                0: LOCAL_STATUS.LIGHT_OFF,
                1: LOCAL_STATUS.LIGHT_ON,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.DoublePlug:
        case DEVICE_TYPES.Plug:
        case DEVICE_TYPES.PlugSecurity:   
        case DEVICE_TYPES.Plug30:
        case DEVICE_TYPES.Plug30Security:
        {
            const localStatus = {
                0: LOCAL_STATUS.OFF,
                1: LOCAL_STATUS.ON,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.Roller:
        {
            const localStatus = {
                0: LOCAL_STATUS.ROLLER_STOPPED,
                1: LOCAL_STATUS.ROLLER_OPENED,
                2: LOCAL_STATUS.ROLLER_CLOSED,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.Flood:
        {
            const localStatus = {
                0: LOCAL_STATUS.IDLE,
                1: LOCAL_STATUS.FLOOD,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.PlugFlood:
        {
            const localStatus = {
                0: LOCAL_STATUS.FLOOD_OPEN,
                1: LOCAL_STATUS.FLOOD_CLOSED,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.Security:
        {
            const localStatus = {
                0: LOCAL_STATUS.CLOSED,
                1: LOCAL_STATUS.OPEN,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.GowayLock:
        {
            const localStatus = {
                0: LOCAL_STATUS.CLOSED_LOCKED,
                1: LOCAL_STATUS.CLOSED_UNLOCKED,
                2: LOCAL_STATUS.OPENED_LOCKED,
                3: LOCAL_STATUS.OPENED_UNLOCKED,
                5: LOCAL_STATUS.LATCH_REMOVED,
            };
            return localStatus[status];
        }

        case DEVICE_TYPES.EasyAccess:
        case DEVICE_TYPES.EasyParking:
        case DEVICE_TYPES.EasyParkingWifi:
        case DEVICE_TYPES.EasyBaliza:
        case DEVICE_TYPES.EasyParkingBarreras:
        case DEVICE_TYPES.EasyParkingBarrerasNFC:
        case DEVICE_TYPES.EasyAccessContact:
        case DEVICE_TYPES.EasyAccessContactNFC:
        case DEVICE_TYPES.EasyAccessContactNFCWifi:
        case DEVICE_TYPES.Clockin:
        case DEVICE_TYPES.ClockinNFCWifi:
        case DEVICE_TYPES.Elevator:
        case DEVICE_TYPES.LIGHT_EFR32:
        case DEVICE_TYPES.EasyIntegrations:
        case DEVICE_TYPES.GarageDoor:
        {
            const localStatus = {
                1: LOCAL_STATUS.OPEN_GARAGE_DOOR,
            };
            
            return localStatus[status];
        }

        default:
            return undefined;
    }
}

function getTriggerSideBDeviceLocalStatus(deviceType, status) {
    let returnStatus = getTriggerBSpecificStatus(deviceType, status);
    if (returnStatus)
        return returnStatus;

    return DeviceHelper.getLocalStatusForDevice(deviceType, status);

    //
    function getTriggerBSpecificStatus(deviceType, status) {
        switch (deviceType) {
            case DEVICE_TYPES.Flood:
            {
                const localStatus = {
                    3: LOCAL_STATUS.BUTTON_PRESSED,
                };
                return localStatus[status];
            }
    
            case DEVICE_TYPES.Security:
            {
                const localStatus = {
                    2: LOCAL_STATUS.VIBRATIONS,
                    3: LOCAL_STATUS.BUTTON_PRESSED,
                };
                return localStatus[status];
            }
    
            case DEVICE_TYPES.LIGHT_EFR32:
            {
                const localStatus = {
                    0: LOCAL_STATUS.OFF,
                    1: LOCAL_STATUS.ON,
                };
                return localStatus[status];
            }

            case DEVICE_TYPES.GowayLock:
            {
                const localStatus = {
                    4: LOCAL_STATUS.VIBRATIONS,
                };
                return localStatus[status];
            }
    
            case DEVICE_TYPES.Movement:
            {
                const localStatus = {
                    1: LOCAL_STATUS.MOVEMENT_DETECTED,
                    3: LOCAL_STATUS.BUTTON_PRESSED,
                };
                return localStatus[status];
            }
    
            case DEVICE_TYPES.Brain:
            {
                const localStatus = {
                    1: LOCAL_STATUS.BRAIN_SIREN,
                    2: LOCAL_STATUS.BRAIN_RECORD_VIDEO,
                };
                return localStatus[status];
            }
    
            default:
                return undefined;
        }
    }
}

function getTriggerSideADeviceLocalStatus(deviceType, status) {
    if (deviceType === DEVICE_TYPES.Brain) {
        const localStatus = {
            1: LOCAL_STATUS.MOVEMENT_DETECTED,
        };

        return localStatus[status];
    }

    return getTriggerSideBDeviceLocalStatus(deviceType, status);
}