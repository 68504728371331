import { genericActions } from "../../Everou/_actions/generic.actions";
import { PMSLocationsMapper } from "../_mappers/PMSLocationsMapper";
import { PMSLocationsService } from "../_services/PMSLocationsService";
import { PMS_LOCATIONS_ACTION_TYPES } from "../_actionTypes/PMS_LOCATIONS_ACTION_TYPES";
import { PMSLocationsPaginationQueryParams, PMSLocationsPaginatedItems } from "../_models/Pagination";
import { PMS_LOCATIONS_SECONDARY_VIEWS } from "../_constants/pmslocations.constants";
import { PMSLocationsSecondaryViewStore } from "../_stores/PMSLocationsSecondaryViewStore";
import { GLOBAL_POPUPS, INVITATION_PERIODS, INVITATION_TYPES, NO_NEXT_PAGE } from "../../Everou/_constants";
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { PMSLocationLogsDateFilter } from "../_stores/PMSLocationLogsDateFilter";
import { PMSLocationLogsCollection } from "../_stores/PMSLocationLogsCollection";
import { InvitationsMapper, LogsMapper } from "../../Everou/_mappers";
import { PMSLocationInfoMapper } from "../_mappers/PMSLocationInfoMapper";
import { PMSLocationReservationsCollection } from "../_stores/PMSLocationReservationsCollection";
import { IntegrationMapper } from "../../Everou/_mappersTS/IntegrationMapper";
import { customizationService } from "../../Everou/_services/customization.service";
import { LocationCustomizationMapper } from "../../Everou/_mappers/LocationCustomizationMapper";
import { locationService } from "../../Everou/_services";
import { PMSLocationIntegrationsCollection } from "../_stores/PMSLocationIntegrationsCollection";
import { InvitationGroupMapper } from "../../Everou/_mappers/InvitationGroupMapper";
import { PMSLocationGroupsCollection } from "../_stores/PMSLocationGroupsCollection";
import { PMSIntegrationCreateServer } from "../_models/PMSIntegration";
import { PMSReservationMapper } from "../_mappers/PMSReservationMapper";
import { Invitation } from "../../Everou/_models/Invitation";
import { globalActions } from "../../Everou/_actions";

export const PMSLocationsActions = {
    getPMSLocations,
    changeFilter,
    changeOffset,

    changeSecondaryView: PMSLocationsSecondaryViewStore.actionSet,
    selectPMSLocation,

    getLocationLogsFirstPage,
    getLocationLogsNextPage,
    getLocationLogsPage,
    setDateFilter: PMSLocationLogsDateFilter.actionSetBeginEnd,
    listLogsRequest: PMSLocationLogsCollection.actionListItemsRequest,
    listLogsSuccess: PMSLocationLogsCollection.actionListItemsSuccess,

    getLocationInfo,

    getReservations,
    reservationsRequest: PMSLocationReservationsCollection.actionListItemsRequest,
    reservationsSuccess: PMSLocationReservationsCollection.actionListItemsSuccess,

    getCustomization,
    updateCustomization,
    uploadLogo,
    uploadBackground,

    updateLocationName,

    createNewLocationTag,
    deleteLocationTag,

    getIntegrations,
    integrationsRequest: PMSLocationIntegrationsCollection.actionListItemsRequest,
    integrationsSuccess: PMSLocationIntegrationsCollection.actionListItemsSuccess,

    getGroupsForLocation,
    groupsRequest: PMSLocationGroupsCollection.actionListItemsRequest,
    groupsSuccess: PMSLocationGroupsCollection.actionListItemsSuccess,

    addLocationIntegration,
    deleteLocationIntegration,

    updateURLRecommendations,
    updateTravelerDiscount,

    uploadVideo,

    changeProtectStatus,
    changeAutoProtectSetting,

    updateURLVideo,

    createLocalReservation,
    deleteReservationDraft,
    changeReservationBeginTime,
    changeReservationEndTime,
    updateSelectedReservationLocally, // Generic selected invitation modifier
    updateNewReservationLocally,
    setReservationEmail,
    changeReservationGroup,

    createReservation,

    deleteReservationUi,
    deleteReservation,

    updateReservation,
};

function changeFilter(filter) {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_CHANGE_FILTER,
        filter
    };
}

function getPMSLocations(query: PMSLocationsPaginationQueryParams = { offset: 0}) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(
            getLocations,
            () => { dispatch(_listPMSLocationsFailure()); dispatch(setIsRequesting(false));},
        ));

        async function getLocations() {
            dispatch(setIsRequesting(true));
            let res = await PMSLocationsService.list({ ...query });
            res.locations = PMSLocationsMapper.mapAllServerToLocal(res.locations);
            dispatch(_listPMSLocationsSuccess({ 
                items: res.locations ?? [], 
                offset: query.offset,
                page_size: query.page_size,
                total_count: res.total_count ?? 0
            }));
            dispatch(setIsRequesting(false));
        }
    }
}

function setIsRequesting(status: boolean = true) {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_SET_REQUESTING_STATUS,
        status
    }
}

function _listPMSLocationsSuccess(locations: Partial<PMSLocationsPaginatedItems>) {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_LIST_SUCCESS,
        locations
    };
}

function _listPMSLocationsFailure() {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_LIST_FAILURE,
    }
}

function changeOffset(offset) {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_CHANGE_OFFSET,
        offset
    };
}

function selectPMSLocation(locationUid) {
    return dispatch => {
        const selectedView = locationUid
            ? PMS_LOCATIONS_SECONDARY_VIEWS.LOCATION_INFO
            : null
        ;

        dispatch(PMSLocationsActions.changeSecondaryView(selectedView));
        dispatch(selectLocation(locationUid));
    }

    function selectLocation(locationUid) {
        return {
            type: PMS_LOCATIONS_ACTION_TYPES.LOCATIONS_SELECT,
            locationUid
        };
    }
}

function getLocationLogsFirstPage() {
    return async dispatch => {
        await dispatch(getLogsPage(0));
    };
}

function getLogsPage(page = 0) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (Selectors.getPMSLocationSelectedUid(getState()) === null)
                return;
            
            await dispatch(PMSLocationsActions.getLocationLogsPage(page));
            return;
        }
    }
}

function getLocationLogsNextPage(nextPage = NO_NEXT_PAGE, isRequesting = false) {
    return async dispatch => {
        if (isRequesting || nextPage === NO_NEXT_PAGE)
            return;

        await dispatch(getLogsPage(nextPage));
    };
}

function getLocationLogsPage(page = 0) {
    return async (dispatch, getState) => {
        dispatch(PMSLocationsActions.listLogsRequest(page));
        const { begin, end } = Selectors.getPMSLocationLogsDateFilter(getState());
        const { logs, next } = await PMSLocationsService.getLogs(
            Selectors.getPMSLocationSelectedUid(getState()),
            begin,
            end,
            25,
            page,
        );

        const localLogs = LogsMapper.mapAllServerToLocal(logs);
        dispatch(PMSLocationsActions.listLogsSuccess(localLogs, next));

        return {
            localLogs,
            next
        };
    }
}

function getLocationInfo() {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverLocationInfo = await PMSLocationsService.locationInfo(
                Selectors.getPMSLocationSelectedUid(getState())
            );

            const localLocationInfo = PMSLocationInfoMapper.mapServerToLocal(serverLocationInfo);
            localLocationInfo.devices = PMSLocationInfoMapper.serverLocationInfoToLocalDevices(serverLocationInfo);
            dispatch(success(localLocationInfo));
            return localLocationInfo;
        }
    };

    function success(locationInfo) {
        return {
            type: PMS_LOCATIONS_ACTION_TYPES.LOCATION_INFO_GET_SUCCESS,
            locationInfo,
        };
    }
}

function getReservations() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (Selectors.getPMSLocationSelectedUid(getState()) === null)
                return;
            
            await dispatch(loadReservations());
            return;
        }
        
    }
}

function loadReservations() {
    return async (dispatch, getState) => {
        dispatch(PMSLocationsActions.reservationsRequest());

        const serverReservations = await PMSLocationsService.reservations(
            Selectors.getPMSLocationSelectedUid(getState())
        );
        const localReservations = PMSReservationMapper.allServerToLocal(serverReservations);
        dispatch(PMSLocationsActions.reservationsSuccess(localReservations));

        return localReservations;
    }
}

function getCustomization() {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const response = await customizationService.getLocationInfo(
                Selectors.getPMSLocationSelectedUid(getState())
            );

            const localCustomization = LocationCustomizationMapper.serverToLocal(response);
            dispatch(success(localCustomization));
            return localCustomization;
        };

        function success(locationCustomization) {
            return {
                type: PMS_LOCATIONS_ACTION_TYPES.LOCATION_CUSTOMIZATION_GET_SUCCESS,
                locationCustomization
            }
        };
    }
}

function updateCustomization(accentColor, headerColor) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const locationUid = Selectors.getPMSLocationSelectedUid(getState());
            const response = await customizationService.update(
                locationUid,
                accentColor,
                headerColor,
            );

            const localCustomization = LocationCustomizationMapper.serverToLocal(response);
            dispatch(success(localCustomization));
            return localCustomization;
        }

        function success(locationCustomization) {
            return {
                type: PMS_LOCATIONS_ACTION_TYPES.LOCATION_CUSTOMIZATION_GET_SUCCESS,
                locationCustomization
            }
        };
    };
}

function uploadLogo(image) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const locationUid = Selectors.getPMSLocationSelectedUid(getState());
            dispatch(setIsUploadingImage(true));
            await customizationService.uploadLogo(locationUid, image);
            dispatch(setIsUploadingImage(false));

            return true;
        }

        function setIsUploadingImage(isUploadingImage) {
            return {
                type: PMS_LOCATIONS_ACTION_TYPES.LOCATION_CUSTOMIZATION_IS_UPLOADING_SET,
                isUploadingImage
            }
        }
    };
}

function uploadBackground(image) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const locationUid = Selectors.getPMSLocationSelectedUid(getState());
            dispatch(setIsUploadingImage(true));
            await customizationService.uploadBackground(locationUid, image);
            dispatch(setIsUploadingImage(false));

            return true;
        }

        function setIsUploadingImage(isUploadingImage) {
            return {
                type: PMS_LOCATIONS_ACTION_TYPES.LOCATION_CUSTOMIZATION_IS_UPLOADING_SET,
                isUploadingImage
            }
        }
    };
}

function updateLocationName(name, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.updateLocationName({
                uid: locationUid,
                name,
            });
            await dispatch(getPMSLocations());
            await dispatch(getLocationInfo());
        }
    }
}

function createNewLocationTag(tag, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSLocationsService.createNewLocationTag({
                tag,
                locationUid
            });
            await dispatch(getPMSLocations());
        }
    }
}

function deleteLocationTag(tag, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSLocationsService.deleteLocationTag({
                tag,
                locationUid
            });
            await dispatch(getPMSLocations());
        }
    }
}

function getIntegrations() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (Selectors.getPMSLocationSelectedUid(getState()) === null)
                return;
            
            await dispatch(loadIntegrations());
            return;
        }
        
    }
}

function loadIntegrations() {
    return async (dispatch, getState) => {
        dispatch(PMSLocationsActions.integrationsRequest());

        const serverIntegrations = await PMSLocationsService.integrations(
            Selectors.getPMSLocationSelectedUid(getState())
        );
        const localIntegrations = IntegrationMapper.allServerToLocal(serverIntegrations);
        dispatch(PMSLocationsActions.integrationsSuccess(localIntegrations));

        return localIntegrations;
    }
}

function getGroupsForLocation() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (Selectors.getPMSLocationSelectedUid(getState()) === null)
                return;
            
            await dispatch(loadGroups());
            return;
        }
        
    }
}

function loadGroups() {
    return async (dispatch, getState) => {
        dispatch(PMSLocationsActions.groupsRequest());

        const serverGroups = await PMSLocationsService.groups(
            Selectors.getPMSLocationSelectedUid(getState())
        );
        const localGroups = InvitationGroupMapper.allServerToLocal(serverGroups);
        dispatch(PMSLocationsActions.groupsSuccess(localGroups));

        return localGroups;
    }
}

function addLocationIntegration(serverIntegration: PMSIntegrationCreateServer) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, null, true));

        async function asyncAction() {
            const integration = await PMSLocationsService.createLocationIntegration(serverIntegration);

            dispatch(getIntegrations());

            return integration;
        }
    }
}

function deleteLocationIntegration(integrationUid: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, null, true));

        async function asyncAction() {
            await PMSLocationsService.deleteLocationIntegration(integrationUid);
            dispatch(PMSLocationIntegrationsCollection.actionDeleteItem(integrationUid));
        }
    }
}

function updateURLRecommendations(url, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSLocationsService.updateLocationURLRecommendations({
                uid: locationUid,
                url_recommendations: url,
            });
            await dispatch(getPMSLocations());
            await dispatch(getLocationInfo());
        }
    }
}

function updateTravelerDiscount(travelerDiscount, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSLocationsService.updateLocationTravelerDiscount({
                uid: locationUid,
                traveler_discount: travelerDiscount,
            });
            await dispatch(getPMSLocations());
            await dispatch(getLocationInfo());
        }
    }
}

function uploadVideo(video, locationUid) {
    return async dispatch => {
        return dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.uploadVideo({
                locationUid,
                video
            });
            await dispatch(getLocationInfo());
        }
    }
}

function changeProtectStatus(enabled, locationUid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const isProtectOn = !Selectors.getLocationInfo(getState()).isProtectOn;
            await PMSLocationsService.changeProtectStatus(
                enabled,
                locationUid
            );

            dispatch(getPMSLocations());
            dispatch(getLocationInfo());
        }
    }
}

function changeAutoProtectSetting(enabled, locationUid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            await PMSLocationsService.changeAutoProtectSetting(
                enabled,
                locationUid
            );

            dispatch(getPMSLocations());
            dispatch(getLocationInfo());
        }
    }
}

function updateURLVideo(url, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSLocationsService.updateURLVideo({
                uid: locationUid,
                external_video_url: url,
            });
            await dispatch(getPMSLocations());
            await dispatch(getLocationInfo());
        }
    }
}

function createLocalReservation(locationUid) {
    return (dispatch, getState) => {
        const invitation = Invitation({
            newInvitation: true,
            type: INVITATION_TYPES.LOCATION,
            objectUid: locationUid,
            createPublicUrl: true
        });
        dispatch(createDraftReservation(invitation));
    };
}

function createDraftReservation(invitation) {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.CREATE_LOCAL_RESERVATION,
        invitation,
    };
}

function deleteReservationDraft() {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.DELETE_LOCAL_RESERVATION
    }
}

function updateNewReservationLocally(selectedModified) {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.RESERVATION_UPDATE_NEW_DRAFT_LOCALLY,
        selectedModified,
    };
}

function updateSelectedReservationLocally(selectedModified) {
    return {
        type: PMS_LOCATIONS_ACTION_TYPES.RESERVATION_UPDATE_SELECTED_LOCALLY,
        selectedModified,
    };
}

function changeReservationBeginTime(startMoment, isNew = false) {

    const dispatcher = isNew ? updateNewReservationLocally : updateSelectedReservationLocally;

    return dispatch => {
        dispatch(dispatcher({ begin: startMoment }));
    };
}

function changeReservationEndTime(end, isNew = false) {

    const dispatcher = isNew ? updateNewReservationLocally : updateSelectedReservationLocally;

    return dispatch => {
        dispatch(dispatcher({ end, periodType: !!end ? INVITATION_PERIODS.TEMPORARY : INVITATION_PERIODS.ALWAYS }));
    };
}

function setReservationEmail(emails: string[] = []) {
    return updateNewReservationLocally({ emails });
}

function changeReservationGroup(integration_uid: string, groups: any[] = []) {
    return updateNewReservationLocally({ integration_uid, groups });
}

function createReservation() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            const localReservation = Selectors.getPMSReservationNewDraft(getState());
            const integration_uid = localReservation.integration_uid;
            const group_id = localReservation.groups[0].id;
            const serverInvitation = InvitationsMapper.mapNewLocalToServer(localReservation);

            await PMSLocationsService.createReservation(
                integration_uid,
                group_id,
                serverInvitation.emails,
                serverInvitation.begin,
                serverInvitation.end
            );
            await dispatch(getReservations());
        }
    };
}

function deleteReservationUi(reservation: any) {
    return dispatch => { 
        dispatch(globalActions.showPopUp(
            GLOBAL_POPUPS.DELETE_RESERVATION,
            { reservation }
        ));
    }
}

function deleteReservation(reservation_uid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            await PMSLocationsService.deleteReservation(
                reservation_uid
            );
            await dispatch(getReservations());
        }
    };
}

function updateReservation(reservation_uid, begin, end) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSLocationsService.updateReservation(
                reservation_uid,
                begin,
                end
            );
            await dispatch(getReservations());
        }
    };
}