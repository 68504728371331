import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Clockin, CLOCKIN_TYPES } from "../_modelsTS/Clockin";
import { Icons } from '../_assets';
import { TimeHelper } from '../_helpers/TimeHelper';
import { CapsuleButton } from '../_components';
import { EditableTime } from './EditableTime';
import { GenericTextArea } from "../../Common/_components/GenericTextArea";
import { ClockinActions } from "../_stores/ClockinStore/ClockinActions";

type SingleClockinProps = {
    clockin: Clockin;
    canEdit?: boolean;
    handleDeleteClockin: (uid: string) => void;
    handleUpdateClockin: (uid: string, time: string) => void;
    timezone: string;
    localDay: Date;
}

export function SingleClockin({
    clockin,
    canEdit = true,
    handleDeleteClockin = () => {},
    handleUpdateClockin = () => {},
    timezone,
    localDay,
}: SingleClockinProps) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [isEditingNotes, setIsEditingNotes] = useState(!!clockin.notes);
    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [editableTime, setEditableTime] = useState(clockin.date);
    const isEditVisible = canEdit && isHovering;
    
    const uiTime = TimeHelper.localizeIsoStringToFormat(
        clockin.date,
        timezone,
        TimeHelper.getTimeFormats().TIME,
    );

    const uiDay = date => TimeHelper.localizeIsoStringToFormat(
        date,
        timezone,
        TimeHelper.getTimeFormats().DATE_ONLY_DAY,
    );

    const isEdited = (clockin.editDate && clockin.editUserName);
    const userEditName = clockin.editUserName;

    const uiEditDate = TimeHelper.localizeIsoStringToFormat(
        clockin.editDate,
        timezone,
        TimeHelper.getTimeFormats().DATE_NO_TIME,
    );

    const isFraudulent = clockin.alert;

    //LOCAL ACTION HANDLERS
    function handleDiscardChanges() {
        setIsEditing(false);
    }

    function handleStartEditing() {
        setEditableTime(clockin.date);
        setIsEditing(true);
    }

    function handleConfirmChanges(newTime) {
        handleUpdateClockin(clockin.uid, newTime);
        setIsEditing(false);
    }

    function handleStartHover() {
        setIsHovering(true);
    }

    function handleEndHover() {
        setIsHovering(false);
    }

    //VIEW
    return (
        <div 
            onMouseLeave={handleEndHover}
            onMouseEnter={handleStartHover}
            className="
                c-hover-245
                c-cursor-default
                border-bottom
            "
        >
            <div 
                key={clockin.uid}
                className="
                    d-flex
                    justify-content-between
                    align-items-center
                    py-1
                    pr-2
                "
            >
                <div className="
                    d-flex
                    align-items-center
                ">
                    <div className="ml-2"/>
                    {typeIcon()}
                    <div className="ml-2"/>

                    {isEditing
                        ? <EditableTime
                            {...{
                                handleConfirmChanges,
                                handleDiscardChanges,
                                time: editableTime,
                                setTime: setEditableTime,
                                timezone,
                                localDay,
                            }}
                        />
                        : uiTime
                    }
                    <div className="ml-2"/>
                    {isEditVisible && !isEditing &&
                        editAndRemoveButtons()
                    }
                    {!isEditVisible && clockin.date?.getDate() !== localDay.getDate() && <small className="text-secondary font-italic mr-2">{uiDay(clockin.date)}</small>}
                </div>
                <div className="text-right">
                    {deviceName(clockin.roomName, clockin.deviceName)}
                </div>
            </div>

            {isFraudulent &&
                <div className="
                    text-left
                    text-danger
                    pl-3
                    pb-1
                ">
                    {t("mod_clockins_fraudulent")}
                </div>
            }

            {isEdited &&
                <div className="
                    text-left
                    pl-3
                    pb-1
                ">
                    {
                    t("mod_clockins_edited_by_pw",
                    {
                        mail: userEditName,
                    },) + " " + uiEditDate
                    }
                </div>
            }

            <NotesSection {...{
                clockin,
                isEditingNotes,
                canEdit
            }}/>

        </div>
    );

    function deviceName(roomName, deviceName) {
        if (!roomName || !deviceName) {
            return (
                <div className="c-font-weight-medium d-inline">
                    {t("mod_clockins_manual_clockin")}
                </div>
            );
        }

        return (
            <div className="c-ft-line-height-1rem d-inline">
                <div className="c-font-weight-medium">
                    {roomName}
                </div>
                <div>
                    {deviceName}
                </div>
            </div>
        );
    }

    function typeIcon() {
        return (
            <img
                className="c-img-1-25rem my-1"
                alt={"clockin_img"}
                src={getClockingTypeIcon(clockin.type)}
            />
        );
    }

    function editAndRemoveButtons() {
        return (
            <>
            {deleteButton()}
            <div className="ml-2"/>
            {editButton()}
            <div className="ml-2"/>
            {editNotesButton()}
            </>
        );
    }

    function editButton() {
        return <CapsuleButton
            icon={Icons.edit}
            size={'SMALL'}
            onClick={handleStartEditing}
        />;
    }

    function deleteButton() {
        return <CapsuleButton
            icon={Icons.trash}
            size={'SMALL'}
            onClick={() => handleDeleteClockin(clockin.uid)}
            style={`DANGER`}
        />;
    }

    function editNotesButton() {
        return <CapsuleButton
            icon={Icons.notes}
            size={'SMALL'}
            onClick={() => setIsEditingNotes(isEditing => !isEditing)}
        />;
    }

    //HELPERS
    function getClockingTypeIcon(type) {
        switch (type) {
            case CLOCKIN_TYPES.ENTRY:
                return Icons.clockinsEnter;

            case CLOCKIN_TYPES.EXIT:
                return Icons.clockinsExit;

            default:
                return Icons.missing;
        }
    }
}

type NotesSectionProps = {
    clockin: Clockin;
    isEditingNotes: boolean;
    canEdit: boolean;
}

function NotesSection({
    clockin,
    isEditingNotes = false,
    canEdit
}: NotesSectionProps) {

    //LIBRARIES
    const dispatch = useDispatch();

    //LOCAL STATE
    const [notes, setNotes] = useState(clockin.notes);
    const [isEdited, setIsEdited] = useState(false);

    //ACTION HANDLERS
    function handleSaveNotes() {
        dispatch(ClockinActions.updateClockIn(clockin.uid, undefined, notes));
        setIsEdited(false);
    }

    function handleChangeNotes(notes) {
        setIsEdited(true);
        setNotes(notes);
    }

    function resetNotes() {
        setIsEdited(false);
        setNotes(clockin.notes);
    }

    //VIEW
    if (!isEditingNotes)
        return null;

    if (!canEdit && notes)
    {
        return (
            <div className="
                text-left
                pl-3
                pb-1
            ">
                {notes}
            </div>
        );
    }

    return (
        <div className="pb-2 pl-1 pr-2 d-flex">
            <GenericTextArea
                text={notes}
                handleEditText={handleChangeNotes} placeholder={undefined}            />
            {isEdited &&
                <div className="flex-shrink-0 d-flex justify-content-end align-items-start">
                    <div className="ml-2"/>
                    <CapsuleButton
                        icon={Icons.cross}
                        size={'SMALL'}
                        onClick={resetNotes}
                        textUppercase={false}
                        style={`DANGER`}
                    />
                    <div className="ml-2"/>
                    <CapsuleButton
                        icon={Icons.check}
                        size={'SMALL'}
                        onClick={handleSaveNotes}
                        textUppercase={false}
                    />
                </div>
            }
        </div>
    );
}