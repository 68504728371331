import React from "react";
import { connect } from "react-redux";

import { dashboardActions } from "../_actions";
import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";

class DeleteWidgetGlobalPopUp extends React.Component {

    handleDeleteWidget(widgetId) {
        this.props.dispatch(dashboardActions.deleteWidget(widgetId));
    }

    render() {
        const { widgetId } = this.props;
        return (
            <BaseAppGlobalPopUp
                title="Delete widget?"
                body="This action is not reversible"
                onAccept={() => this.handleDeleteWidget(widgetId)}
            />
        );
    }
}

const connectedDeleteWidgetPopUp = connect()(DeleteWidgetGlobalPopUp);
export { connectedDeleteWidgetPopUp as DeleteWidgetGlobalPopUp };