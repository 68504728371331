import { useState } from "react";

export function useDebounce() {
   
    const [debounced, setDebounced] = useState<any>(null);

    const debounce = (cb: (...args: any) => void, timeout = 500) => {

        debounced && clearTimeout(debounced);
        setDebounced(setTimeout(cb, timeout))

    };

    return {
        debounce
    } as const;

}