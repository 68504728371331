import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { reservationActions } from "../_actions/reservation.actions";

export function UpdateReservationConfigGlobalPopUp() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <BaseAppGlobalPopUp
            title={t("common.modifySettings")}
            body={body()}
            acceptButtonName={t("common.accept")}
            cancelButtonName={t("common.cancel")}
            onAccept={handleDelete}
        />
    );

    function body() {
        return t("reservations.allReservationsFromTodayOnwardsRemoved");
    }

    function handleDelete() {
        dispatch(reservationActions.saveReservationConfig());
    }
}