import React from "react";
import { useDispatch } from "react-redux";

import { dashboardActions } from "../_actions";
import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";

export function DeleteGroupGlobalPopUp({ id }) {

    //LIBRARIES
    const dispatch = useDispatch();

    //ACTION HANDLERS
    function handleDeleteGroup() {
        dispatch(dashboardActions.deleteGroup(id));
    }

    //VIEW
    return (
        <BaseAppGlobalPopUp
            title="Delete group?"
            body="All widgets in the group will be deleted as well. This action is not reversible"
            onAccept={handleDeleteGroup}
        />
    );
}