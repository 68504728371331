import { TimeHelper } from "../_helpers";
import { ReservationSlotModel } from "./ReservationSlotModel";
import { Reservation } from "../_models/Reservation";
import { ReservationConfig } from "../_models/ReservationConfig";

export function slotBuilder({
    reservationConfig = ReservationConfig(),
    selectedDate,
    reservations = [Reservation()],
    timezone,
    userUid,
}) {
    var reservationsBySlot = {};
    for (var i = 0; i < reservations.length; i++) {
        var reservation = reservations[i];
    
        if (reservationsBySlot[reservation.begin]) {
            reservationsBySlot[reservation.begin].push(reservation);
        } else {
            reservationsBySlot[reservation.begin] = [reservation];
        }
    }

    return TimeHelper.isBeforeToday(selectedDate)
        ? buildExistingReservationSlots()
        : buildAllSlots()
    ;

    function buildExistingReservationSlots() {
        //return reservations.map(makeSlotFromReservation);

        var keys = Object.keys(reservationsBySlot);
        if (keys.length === 0) return [];

        return keys.map(function(key, index) {
            var reservations = reservationsBySlot[key];
            return makeSlotFromReservation(reservations);
        });

        function makeSlotFromReservation(reservations = [Reservation()]) {
            var firstRerservation = reservations[0];

            var userHasReservation = reservations.some(aReservation => 
                aReservation.userUid === userUid
            );

            return ReservationSlotModel({
                uid: generateId(32),
                hasPassed: hasAlreadyPassed(firstRerservation.end),
                isAvailable: isAvailable(firstRerservation.begin, firstRerservation.end, reservationConfig),
                isYours: userHasReservation,
                reservations: reservations,
                utcBegin: firstRerservation.begin,
                utcEnd: firstRerservation.end,
            });
        }
    }

    function buildAllSlots() {
        const ranges = getRanges(
            reservationConfig.allDay,
            selectedDate,
            timezone,
            reservationConfig.begin,
            reservationConfig.end,
        );
        
        return ranges.reduce((slots, { begin, end, isFillerRange }) => 
            [
                ...slots,
                ...buildSlotsForRange(
                    isFillerRange,
                    reservationConfig.blockMinutes,
                    begin,
                    end,
                ),
            ]
        , []);
    }

    function getRanges(allDay, selectedDate, timezone, reservationBegin, reservationEnd) {
        
        if (allDay)
            return allDayRanges();

        if (TimeHelper.isIsoABeforeB(reservationEnd, reservationBegin))
            return fragmentedRanges();

        return conventionalRanges();

        
        function fragmentedRanges() {
            const rangeA = {
                begin: TimeHelper.copyMinutesToDestination(reservationBegin, getDayStart()),
                end: applyTimeToSelectedDay(reservationEnd),
            };

            const rangeB = {
                begin: applyTimeToSelectedDay(reservationBegin),
                end: getNextDayStart(),
            };

            const notReservableRange = {
                begin: rangeA.end,
                end: rangeB.begin,
                isFillerRange: true,
            };

            return [
                rangeA,
                notReservableRange,
                rangeB,
            ];
        }
        
        function allDayRanges() {
            return [{
                begin: getDayStart(),
                end: getNextDayStart(),
            }];
        }
        
        function conventionalRanges() {
            return [{
                begin: applyTimeToSelectedDay(reservationBegin),
                end: applyTimeToSelectedDay(reservationEnd),
            }];
        }

        //HELPERS
        function getDayStart() {
            return TimeHelper.getStartOfDayInTimezoneIso(
                selectedDate,
                timezone,
            );
        }

        function getNextDayStart() {
            return TimeHelper.getNextDayStartInTimezoneIso(
                selectedDate,
                timezone,
            );
        }

        function applyTimeToSelectedDay(time) {
            return TimeHelper.removeTimezoneFromIsoString(
                TimeHelper.applyTimeToDate(
                    time,
                    selectedDate,
                ),
                timezone,
            );
        }
    }

    function buildSlotsForRange(
        isFillerRange,
        slotSizeMinutes,
        rangeBegin,
        rangeEnd,
    ) {
        return isFillerRange
            ? buildFillerSlot(
                rangeBegin,
                rangeEnd,
            )
            : buildConventionalSlots(
                slotSizeMinutes,
                rangeBegin,
                rangeEnd,
            )
        ;
    }

    function dec2hex (dec) {
        return dec.toString(16).padStart(2, "0")
    }
      
    // generateId :: Integer -> String
    function generateId (len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, dec2hex).join('')
    }

    function buildFillerSlot(
        rangeBegin,
        rangeEnd,
    ) {
        return [
            ReservationSlotModel({
                uid: generateId(32),
                isReservable: false,
                utcBegin: rangeBegin,
                utcEnd: rangeEnd,
            })
        ];
    }

    function buildConventionalSlots(
        slotSizeMinutes,
        rangeBegin,
        rangeEnd,
    ) {
        const numberOfSlots = TimeHelper.absDifferenceInMinutes(rangeBegin, rangeEnd) / slotSizeMinutes;

        const slotArray = [];
        for (let i = 0; i < numberOfSlots; i++) {
            const utcBegin = TimeHelper.addMinutesToIsoString(rangeBegin, slotSizeMinutes * i);
            const utcEnd = TimeHelper.addMinutesToIsoString(utcBegin, slotSizeMinutes);
            slotArray.push(
                ReservationSlotModel({
                    utcBegin,
                    utcEnd,
                    isAvailable: isAvailable(utcBegin, utcEnd, reservationConfig),
                    isYours: isYours(utcBegin),
                    reservations: setReservations(utcBegin),
                    hasPassed: hasAlreadyPassed(utcEnd),
                    isHappening: isHappening(utcBegin, utcEnd),
                })
            );
        }

        return slotArray;
    }

    function isHappening(utcBegin, utcEnd) {
        return (
            TimeHelper.isBeforeNowUtc(utcBegin)
            && !TimeHelper.isBeforeNowUtc(utcEnd)
        );
    }

    function isAvailable(begin, end, config = ReservationConfig()) {
        var reservationsInSlot = reservationsBySlot[begin];

        return !isAlreadyReserved(reservationsInSlot) && !hasAlreadyPassed(end);

        function isAlreadyReserved(reservationsInSlot) {
            return reservationsInSlot && (reservationsInSlot.length === config.numberOfUsers);
        }
    }

    function hasAlreadyPassed(time) {
        return TimeHelper.isBeforeNowUtc(time);
    }

    function isYours(begin) {
        const yourReservations = reservations.filter(reservation => 
            reservation.userUid === userUid
        );

        if (yourReservations.length === 0)
            return false;

        return yourReservations.some(reservation => 
            reservation.begin === begin
        );
    }

    function setReservations(begin) {
        return reservationsBySlot[begin];
    }
}