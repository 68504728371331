import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ClockinActions } from "../_stores/ClockinStore/ClockinActions";
import { BaseAppGlobalPopUp } from "../GlobalPopUps/BaseAppGlobalPopUp";

export function DeleteClockinPopUp({ uid } = {}) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={t("mod_clockin_remove_title")}
            body={t("mod_clockin_remove_message")}
            acceptButtonName={t("global_cancel")}
            cancelButtonName={t("webapp_global_remove")}
            onCancel={handleDelete}
        />
    );

    function handleDelete() {
        dispatch(ClockinActions.deleteClockIn(uid));
    }
}
