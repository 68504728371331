import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deviceIconForType } from '../_assets';
import { DeviceUiHelper } from '../_helpers/DeviceUiHelper';
import { DeviceHelper } from '../_helpers';
import { devicesActions } from '../_actions';
import { Device } from '../_models/Device';
import { deviceFilterModes, DEVICES_SECONDARY_VIEWS } from '../_constants';
import { CustomCard } from '../_components';
import { Selectors } from '../_reducers/app.reducer';

type SingleDeviceProps = {
    device: any,
    selectedConfigDeviceUid?: string,
}

export function SingleDevice({
    device = Device(),
    selectedConfigDeviceUid
}: SingleDeviceProps) {

    //LIBRARIES
    const dispatch = useDispatch();
    const isSelected = useMemo(
        () => selectedConfigDeviceUid === device.uid,
        [selectedConfigDeviceUid, device?.uid],
    );

    const filterMode = useSelector(Selectors.getDeviceFilterFilterMode);
   
    //VIEW
    return (
        <div
            style={{
                boxShadow: !!device.children.length && filterMode === deviceFilterModes.ROOMS ? 'inset 4px 0px 0px 0px rgb(220,220,220)' : 'none',
            }}
            className="c-rounded-1-2rem"
        >
            <CustomCard
                onClick={() => {
                    openDeviceDetails();
                }}
                className="cp"
                selected={isSelected}
                banner={status()}
            >
                <div
                    data-testid="single-device"
                    className={`
                    d-flex
                    no-gutters
                    align-items-center
                `}
                >
                    {deviceIcon()}
                    <div className="d-flex justify-content-between w-100 ml-2">
                        {deviceDescription()}
                        {deviceButton()}
                    </div>
                </div>
            </CustomCard>

            {!!device.children.length && filterMode === deviceFilterModes.ROOMS && (
                <div className="pl-3 py-2 c-default-grid">
                    {device.children.map((device) => (
                        <SingleDevice selectedConfigDeviceUid={selectedConfigDeviceUid} key={device.uid} device={device} />
                    ))}
                </div>
            )}
        </div>
    );


    function deviceIcon() {
        return (
            <img
            className="c-img-2rem"
            src={deviceIconForType(device.type)}
            alt="device type icon"
            />
        );
    }

    function deviceDescription() {
        return (
            <div className="
                c-line-height-1rem
                mr-2
            ">
                {device.description}
            </div>
        );
    }



    function status() {
        if (!DeviceHelper.isDeviceStatusVisible(device) &&
            !DeviceHelper.isDeviceConnectionStatusVisible(device))
            return null;

        let value = "";
        if (DeviceHelper.isDeviceStatusVisible(device))
        {
            value = device.statusLabel;
        }
        else if (DeviceHelper.isDeviceConnectionStatusVisible(device))
        {
            value = device.conn_status;
        }

        return (
            <div
                className="
                    text-right
                    c-line-height-1rem
                "
            >
                {value}
            </div>
        );
    }

    function deviceButton() {
        return (
            <div onClick={e => e.stopPropagation()}>
                {DeviceUiHelper.getUiButtonForDevice(device, handleActionButton)}
            </div>
        );
    }

    //ACTION HANDLERS
    function openDeviceDetails() {
        dispatch(devicesActions.selectDeviceConfig(device.uid));
        return dispatch(devicesActions.changeSecondaryView(DEVICES_SECONDARY_VIEWS.DEVICE_CONFIG));
    }

    function handleActionButton(deviceActionStatus, device) {
        dispatch(
            devicesActions.sendActionToDevice(deviceActionStatus, device)
        );
    }
}