import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { authenticationActions } from '../_actions';
import { HistoryHelper } from '../_helpers';

import { AlreadyLoggedInPage } from './AlreadyLoggedInPage';
import { BaseLoginPage } from './BaseLoginPage';

import { ShowCodePage } from './ShowCodePage';
import { Selectors } from '../_reducers/app.reducer';
import { EmailLogin } from './EmailLogin';
import { GoogleLoginModule } from '../GoogleLoginModule/GoogleLoginModule';
import { AppleLoginModule } from '../AppleLoginModule/AppleLoginModule';
import { EmailCodeInputPage } from './EmailCodeInputPage';
import { useReloadOnValidUser } from '../_hooks/useReloadOnValidUser'; 

const testIDs = {
    initialEmailInput: "initial-email-input",
    intialEmailSendButton: "initial-email-send",

    codeEmailInput: "code-email-input",
    codeInput:"code-input",
    sendCodeButton: "send-code-button",
};

LoginPage.testIDs = testIDs;

export function LoginPage({
    isOauth,
} = {}) {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const loginEmail                = useSelector(Selectors.getLoginEmail);
    const codeType                  = useSelector(Selectors.getLoginCodeType);
    const codeSent                  = useSelector(Selectors.getCodeSent);
    const user                      = useSelector(Selectors.getEverouUser);
    const isNewUser                 = useSelector(Selectors.getIsNewUser);
    const { email, code, secret }   = useSelector(Selectors.getLoginUrlData);

    //LOCAL STATE
    const [inputEmail, setInputEmail]       = useState(loginEmail || "");
    const [showOnlyCode, setShowOnlyCode]   = useState(false);

    //ACTION HANDLERS
    const handleNewUser = useCallback(() => {
        dispatch(authenticationActions.setIsNewUser(false));
        HistoryHelper.goToRegister();
    }, [dispatch]);

    function handleGoogleLogin(token, email) {
        dispatch(authenticationActions.loginWithGoogle(token, email));
    }

    function handleAppleLogin(token, email) {
        dispatch(authenticationActions.loginWithApple(token, email));
    }

    function handleEmailLogin(email, code) {
        dispatch(authenticationActions.loginWithEmail(email, code));
    }

    function handleRequestEmailCode(email) {
        dispatch(authenticationActions.requestCode(email));
    }

    function handleCloseCodeInput() {
        dispatch(authenticationActions.setCodeSent(false));
    }
    
    function authenticatedOauthRedirectURL(user) {
        const redirectOauthURL = HistoryHelper.getOauthData();
        const authenticatedRedirectOauthURL = redirectOauthURL + "&api_key=" + user.apikey;
        return authenticatedRedirectOauthURL;
    }

    //INITIALIZATION
    useReloadOnValidUser(user, codeType, codeSent);
    useEffect(() => {
        if (!(email && code && secret))
            return;

        codeSent
            ? dispatch(authenticationActions.loginWithEmail(email, code, secret))
            : setShowOnlyCode(true)
        ;
    }, [dispatch, codeSent, email, code, secret]);

    useEffect(() => {
        if (isNewUser)
            handleNewUser();
    }, [isNewUser, handleNewUser]);

    if (isOauth) 
    {
        if (user) {
            window.location.href = authenticatedOauthRedirectURL(user);
            return null;
        }
    }

    //VIEW
    if (showOnlyCode)
        return <ShowCodePage />

    if (codeSent)
        return <EmailCodeInputPage
            codeInputTestID={testIDs.codeInput}
            sendCodeButtonTestID={testIDs.sendCodeButton}

            email={inputEmail}
            handleGoBack={handleCloseCodeInput}
            handleLogin={handleEmailLogin}
            handleRequestCode={handleRequestEmailCode}
            handleEditEmail={setInputEmail}
        />;

    if (user)
        return <AlreadyLoggedInPage />;

    return <BaseLoginPage content={content()}/>;

    function content() {
        return (
            <div className="
                d-flex
                no-gutters
                flex-column


                mt-3
            ">
                <GoogleLoginModule
                    handleLogin={handleGoogleLogin}
                />
                <div className="mt-3"/>
                <AppleLoginModule
                    handleLoginSuccess={handleAppleLogin}
                />
                <div className="d-flex w-100 my-4">
                    <div className="col c-bd-bottom-140 my-auto"/>
                    <div className="mx-2 c-new-ft-l pb-1 ">o</div>
                    <div className="col c-bd-bottom-140 my-auto"/>
                </div>
                <EmailLogin
                    inputTestID={testIDs.initialEmailInput}
                    buttonTestID={testIDs.intialEmailSendButton}
                    email={inputEmail}
                    setEmail={setInputEmail}
                    handleRequestCode={handleRequestEmailCode}
                />
            </div>
        );
    }
}