import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ValidatedTextInput } from "../../../Common/_components/ValidatedTextInput";
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";

const fn = () => {};

const TEST_IDS = {
    input: "group-name-input",
};

GroupNameComponent.TEST_IDS = TEST_IDS;

type GroupNameComponentProps = {
    name?: string;
    setIsValid?: (...args: any) => void;
    isEditable?: boolean;
    forceWarnings?: boolean;
}

export function GroupNameComponent({
    name,
    setIsValid = fn,
    isEditable = false,
    forceWarnings = false,
}: GroupNameComponentProps) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //HANDLERS
    const handleChangeName = useCallback(newName => {
        dispatch(invitationGroupActions.renameEditable(newName));
    }, [dispatch]);

    //VIEW
    return (
        <ValidatedTextInput
            testID={TEST_IDS.input}
            
            setValue={handleChangeName}
            setIsValid={setIsValid}
            value={name}

            isRequired

            placeholder={t("invitation_group_common_name_placeholder")}
            title={t("global_name")}
            isEditable={isEditable}
            forceShowWarnings={forceWarnings}
        />
    );
}