import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { Reservation } from "../_models/Reservation";
import { PMSLocationsActions } from "../../PMS/_actions/PMSLocationsActions";

export function DeleteReservationGlobalPopUp({
    reservation = Reservation(),
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HANDLERS
    function handleDeleteReservation() {
        dispatch(PMSLocationsActions.deleteReservation(reservation.uid));
    }
    
    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={t(
                "invitations.deleteInvitationQuestion",
                { invitation: reservation.email }
            )}
            body={t("invitations.deleteInvitationDescription")}
            onAccept={handleDeleteReservation}
        />
    );
}