import { IntegrationStore } from "../_stores/IntegrationStore";
import { KrossBookingMapper } from "../_mappersTS/KrossBookingMapper";
import { KrossBookingService } from "../_services/krossBookin.service";
import { genericActions } from "./generic.actions"
import { KrossBookingStore } from "../_stores/KrossBookingStore";


export const krossBookingActions = {
    authenticate,
    listProperties,
    listRooms,
}

function authenticate(apikey: string, username: string, password: string, hotelID: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const krossBookingTokenServer = await KrossBookingService.authenticate(
                apikey,
                username,
                password,
                hotelID
            );
            
            const localKrossBookingToken = KrossBookingMapper.serverToLocal(krossBookingTokenServer)
            return localKrossBookingToken;
        }
    }
}

function listProperties(uid: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverKrossBookingProperties = await KrossBookingService.listProperties(uid);
            dispatch(KrossBookingStore.actionListItemsSuccess(serverKrossBookingProperties));
        }
    }
}

function listRooms(uid: string, host_id: number) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverKrossBookingRooms = await KrossBookingService.listRooms(uid, host_id);
            const rooms = KrossBookingMapper.serverListRoomToLocal(serverKrossBookingRooms);
            dispatch(KrossBookingStore.actionUpdateItemSuccess({id_property:host_id, rooms}));
        }
    }
}