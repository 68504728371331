import { Selectors } from '../../Everou/_reducers/app.reducer';
import { useSelector } from 'react-redux';
import { PMSLocationFilter } from '../_models/Pagination';
import { ColumnButton } from './_components/ColumnButton';
import { useEffect, useState } from 'react';

type PMSLocationsFilterProps = {
    onChangeFilter: (...args: any) => void;
    className?: string;
    filter: PMSLocationFilter;
}

export function PMSLocationsFilter({
    onChangeFilter,
    filter,
    className = ''
}: PMSLocationsFilterProps) {

    // LIBRARIES
    const locations = useSelector(Selectors.getPMSLocations);
    const userTags = useSelector(Selectors.getTags);
    const [tags, setTags] = useState<any>([]);

    const toggleTag = (tag) => {
        let updatedTags = tags;
        if (tags?.includes(tag)) {
            updatedTags = updatedTags.filter(t => t !== tag);
        } else {
            updatedTags = [...updatedTags, tag];
        }

        setTags(updatedTags);
        const newFilter = {
            tag_filter: updatedTags,
        };
        onChangeFilter(newFilter);
    };

    return (
        <div
            className={`${className} c-h-min w-100 c-z-index-100 py-2`}
        >
            <h3 className='ml-1'>TAGS</h3>
            <div className="overflow-auto
                c-rounded-big
                c-bg-grey-250
                c-bd-220">
                {userTags.map((option) => (
                    <ColumnButton
                        name={option.tag}
                        handleClick={() => toggleTag(option.tag)}
                        active={tags.includes(option.tag)}
                    />
                ))}
            </div>

        </div>
    )

    function buildPermissionsOptions() {
        let filterTags = new Array();
        var i;
        for (i=0; i<locations.items.length; i++) {
            const location = locations.items[i];
            var j;
            for (j=0; j<location.tags.length; j++) {
                if (filterTags.indexOf(location.tags[j]) < 0) {
                    filterTags.push(location.tags[j]);
                }
            }
        }

        return filterTags;
    }
}