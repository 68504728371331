import React from "react";

export function GenericSingleDevice({ key, icon, mainText, secondaryText, statusText }) {
    return (
        <div key={key}
            className="
                d-flex
                justify-content-between
                align-items-center

                p-2
                rounded
            "
        >
            <div className="
                d-flex
                align-items-center
            ">
                <img
                    src={icon}
                    alt="device icon"
                    className="c-img-1-5rem"
                />
                <div className="
                    ml-2
                ">
                    {mainText}
                </div>
                <div className="
                    ml-1
                    text-secondary
                    c-ft-2xs
                ">
                    {secondaryText}
                </div>
            </div>
            <div className="text-danger text-right">
                {statusText}
            </div>
        </div>
    );
}