import { HistoryHelper } from "../Everou/_helpers";
import { CommonStore } from "../Everou/_reducers/common.reducer";
import { StoreManager } from "../Everou/StoreManager";
import { PMSModules } from "./PMSModules";

export const PMSStoreSubscriptions = {
    subscribe,
};

function subscribe() {
    StoreManager.subscribeFnToStore(persistPMSSelectedModuleToUrl);
}

function persistPMSSelectedModuleToUrl(getState) {
    HistoryHelper.pushToUrl(
        PMSModules.getUrlForModule(
            CommonStore.selectors.getSelectedModuleId(getState())
        )
    );
}