import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FILTER_DISABLED_ALL } from "../_constants";
import { SUBSCRIPTION_STATUS } from "../_constants/subscriptions.constants";
import { Subscription } from "../_models/Subscription";
import { Device } from "../_models/Device";
import { CustomDropdown } from "../_components/CustomDropdown";
import { DeviceHelper } from "../_helpers";
import { ReadOnlyDataRow } from "../_components/ReadOnlyDataRow";
import { subscriptionsActions } from "../_actions/subscriptions.actions";

const testIDs = {
    dropdown: "generic-device-selector-dropdown",
};

DeviceSelectorGeneric.testIDs = testIDs;

export function DeviceSelectorGeneric({
    devices = [] || [Device()],
    selectedSubscription = Subscription(),
    canEdit = false,
}) {
    
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const subscriptionDevices = DeviceHelper.getUnlimitedUsesSubscriptionCompatibleDevices(devices);

    //VIEW
    return subscriptionDevices.length === 0 
        ? noCompatibleDevicesMessage()
        : devicesContent()
    ;

    function noCompatibleDevicesMessage() {
        return (
            <div className="text-right">
                {t("subscription_no_compatible_devices_found")}
            </div>
        );
    }

    function devicesContent() {
        return (
            <div>
                {deviceSelector(selectedSubscription)}
                <hr className="m-0 mt-2"/>
                {roomName()}
            </div>
        );
    }

    function deviceSelector(subscription = Subscription()) {
        const dropdown = <CustomDropdown
            testid={testIDs.dropdown}
            options={buildAvailableDevices(subscription)}
            onChange={handleChangeDevice}
            value={subscription.deviceUid || FILTER_DISABLED_ALL.key}
            isReadOnly={
                !canEdit
                || subscription.status === SUBSCRIPTION_STATUS.EXPIRED
            }
        />;

        return textWithDropdown(t("common.device"), dropdown);
    }

    function textWithDropdown(title, dropdown) {
        return (
            <div className="
                d-flex
                justify-content-between
                align-items-center

                px-1
                mt-2
            ">
                <div>
                    {title}
                </div>
                <span className="ml-2"/>
                {dropdown}
            </div>
        );
    }
    
    function buildAvailableDevices(subscription = Subscription()) {
        const availableDevices = [];

        if (subscription.deviceUid === null) {
            availableDevices.push(CustomDropdown.buildOption(
                FILTER_DISABLED_ALL.key,
                t("subscriptions.noDeviceAssigned"),
            ));
        }

        if (!subscriptionDevices)
            return;

        availableDevices.push(
            ...subscriptionDevices.map(device =>
                CustomDropdown.buildOption(
                    device.uid,
                    device.description,
                )
            )
        );

        return availableDevices;
    }

    function roomName() {
        if (!selectedSubscription.deviceUid)
            return;

        return readOnlyRow(
            t("common.room"),
            getRoomName(),
        );

        function getRoomName() {
            if (selectedSubscription.status === SUBSCRIPTION_STATUS.NEW || selectedSubscription.edited)
                return DeviceHelper.getDeviceRoomNameFromDevices(selectedSubscription.deviceUid, devices);

            return selectedSubscription.roomName;
        }
    }

    function readOnlyRow(title, value) {
        return <ReadOnlyDataRow
            data={value}
            name={title}
        />;
    }
    
    //ACTION HANDLERS
    function handleChangeDevice(deviceUid) {
        dispatch(subscriptionsActions.changeDevice(deviceUid));
    }
}