import { EverouDeveloperMapper } from "../_mappers/EverouDeveloperMapper";
import { EverouDevelopersService } from "../_services/EverouDevelopersService";
import { EverouDevelopersStore } from "../_stores/EverouDevelopersStore";
import { genericActions } from "./generic.actions";

export const EverouDevelopersActions = {
    list,
    remove,
    add,
};

const _listSuccess = EverouDevelopersStore.actionListItemsSuccess;
const _removeSuccess = EverouDevelopersStore.actionDeleteItem;
const _addSuccess = EverouDevelopersStore.actionGetItemSuccess;

function list(locationUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(_listSuccess(
                EverouDeveloperMapper.serverToLocal(
                    await EverouDevelopersService.list(locationUid)
                )
            ));
        }
    };
}

function remove(locationUid, uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await EverouDevelopersService.remove(locationUid, uid);
            dispatch(_removeSuccess(uid));
        }
    };
}

function add(locationUid, email) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(_addSuccess(
                await EverouDevelopersService.add(
                    locationUid,
                    email,
                )
            ));
        }
    };
}