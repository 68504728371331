import { Invitation } from "../_models/Invitation";
import { SubinvitationGroup } from "../_models/SubinvitationGroup";

export class SubinvitationGroupMapper {

    static invitationsToSubinvitations(localInvitations = [Invitation()]) {
        const localSubinvitations = [];
        localInvitations.forEach(invitation => {
            if (invitation.allowsSubinvitations) {
                localSubinvitations.push(
                    buildLocalSubinvitation(
                        invitation,
                        localInvitations,
                    )
                );
            }
        });

        return localSubinvitations;
    }
}

function buildLocalSubinvitation(main = Invitation(), invitations = [Invitation()]) {
    const sent = [];
    
    invitations
    .filter(invitation => !invitation.allowsSubinvitations)
    .forEach(invitation => {
        if (invitation.objectUid === main.objectUid)
            sent.push(invitation);
    })

    return SubinvitationGroup({
        main,
        sent,
    });
}