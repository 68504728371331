import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Paginator } from '../../Common/_components/Paginator';
import { invitationsActions } from '../_actions';
import { CustomSearchBar, MainPageWithButtons } from '../_components';
import { CapsuleButton } from '../_components/CapsuleButton';
import { WaitSpinner } from '../_components/WaitSpinner';
import {
    INVITATIONS_SECONDARY_VIEWS
} from '../_constants';
import { LocationInfo } from '../_models/LocationInfo';
import { InvitationFilter, PaginationQueryParams } from '../_modelsTS/Pagination';
import { Selectors } from '../_reducers/app.reducer';
import { InvitationsFilters } from './_components/InvitationsFilters';
import { SingleInvitation } from './_components/SingleInvitation';
import { NewInvitation } from './NewInvitation';
import { LocationInvitationConfig } from '../_models/LocationInvitationConfig';
import { CustomModal } from '../_components/CustomModal';
import { useValidation } from '../_hooks/useValidation';
import { NewAccessLink } from './NewAccessLink';
import { Icons } from "../_assets";

const INVITATIONS_LEFT_STATUS = {
    UNLIMITED:  "UNLIMITED",
    REMAINING:  "REMAINING",
    CONSUMED:   "CONSUMED",
};

const TEST_IDS = {
    invitationsListView: "invitations-list-view",
    createInvitatonButton: "invitations-list-view-create-invtation-button",
    singleInvitationsGroup: "invitations-list-view-single-invitations-group",
    createGroupButton: "invitations-list-view-create-group-button",
    GROUP_SETTINGS_BUTTON: "invitations-list-group-settings-button",
    DELETE_GROUP_BUTTON: "invitations-list-delete-group-button",
    createLinkButton: "invitations-list-view-create-link-button",
};

InvitationsListView.testIDs = TEST_IDS;

export function InvitationsListView({
    hasUnlimitedInvitations = false,
    totalInvitations = 0,
    invitationsLeft = 0,
    rooms,
    canInvite,
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const selectedInvitationUid = useSelector(Selectors.getInvitationSelectedUid)
    const secondaryView = useSelector(
        Selectors.getInvitationsModuleSecondaryView,
    );
    const selectedAccessLink        = useSelector(Selectors.getInvitationAccessLinkSelected);

    const handleCopyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(selectedAccessLink);
    }, [selectedAccessLink]);

    const handleSendPublicUrlByWhatsApp = useCallback(() => {
        let url = "https://wa.me/?text=" + encodeURIComponent(selectedAccessLink);
        window.open(url, '_blank');
    }, [selectedAccessLink]);

    const handleSendPublicUrlByGmail = useCallback(() => {
        let url = "https://mail.google.com/mail?view=cm&tf=0&body=" + encodeURIComponent(selectedAccessLink);
        window.open(url, '_blank');
    }, [selectedAccessLink]);

    let locationInfo                = LocationInfo();
    locationInfo                    = useSelector(Selectors.getLocationInfo);
    let locationInvitationConfig    = LocationInvitationConfig();
    locationInvitationConfig        = useSelector(Selectors.getLocationInvitationConfig);

    const {
        offset,
        items: invitations,
        page_size,
        total_count
    } = useSelector(Selectors.getInvitations);

    const canCreateSuperguests  = locationInvitationConfig.canCreateSuperguests;
    const isRequesting = useSelector(Selectors.getIsRequestingInvitations);

    const { isAllValid } = useValidation();

    // GET PAGE SIZE FROM INVITATIONS
    const listRef = useRef<HTMLDivElement>(null);

    const invitationFilter = useSelector(Selectors.getInvitationFilter);
    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    //ACTION HANDLERS
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [debounce, setDebounce] = useState<any>(null);
    const [showModalNewInvitation, setShowModalNewInvitation] = useState(false);
    const [showModalAccessLink, setShowModalAccessLink] = useState(false);
    const [showModalAccessLinkCreated, setShowModalAccessLinkCreated] = useState(false);

    useEffect(() => {
        setShowModalAccessLinkCreated(selectedAccessLink !== null);
    }, [selectedAccessLink]);

    const handleSelectInvitation = (invitationUid) => {
        dispatch(invitationsActions.selectInvitation(invitationUid));
    };

    const getInvitations = useCallback(
        (filters: InvitationFilter & PaginationQueryParams = {}) => {
            dispatch(
                invitationsActions.listInvitations({
                    loc_uid: locationUid ?? "",
                    offset,
                    page_size,
                    search_term: filters.search_term,
                    ...filters,
                }),
            );
        },
        [dispatch, locationUid, offset, page_size],
    );
    
    const onChangeFilters = useCallback((filters: InvitationFilter) => {
        dispatch(invitationsActions.changeFilter(filters));
        dispatch(invitationsActions.resetPagination());
        getInvitations({...filters, offset: 0, page_size: 24});
    }, [dispatch, getInvitations]);

    const onChangePage = async offset => {
        if (listRef) {
            listRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        dispatch(invitationsActions.changeOffset(offset));
        await getInvitations({ ...invitationFilter, offset });
    };

    function onSearchByText(search_term: string) {
        debounce && clearTimeout(debounce);
        search_term = search_term.trim();
        setSearchTerm(search_term);
        const debounceLocal = setTimeout(() => {
            const filters = {
                ...invitationFilter,
                search_term,
                offset: 0 
            };
            onChangeFilters(filters);
        }, 500);
        setDebounce(debounceLocal);
    }

    const createInvitations = () => {
        dispatch(invitationsActions.sendNewInvitation(locationUid));
    }
    const deleteInvitationDraft = () => {
        dispatch(invitationsActions.deleteInvitationDraft())
    }

    const createAccessLink = () => {
        dispatch(invitationsActions.sendNewInvitation(locationUid));
    }

    useEffect(() => {
        setSearchTerm('');
        onChangeFilters({ status: [], permission_type: [], search_term: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUid]);

    //VIEW
    return (
        <MainPageWithButtons
            dataTestId={TEST_IDS.invitationsListView}
            pageTitle={(total_count ?? 0) + ' ' +  t("invitations.invitations")}
            pageSubtitle={getRemainingInvitationsText()}
            content={content()}
            size={"UNLIMITED"}
            buttons={[
                <CustomSearchBar
                    onChange={e => {
                        onSearchByText(e.target.value);
                    }}
                    value={searchTerm}
                    className="w-100"
                    placeholder={t("mod_invitations_search_placeholder")}
                />,
                createInvitationButton(),
                createAccessLinkButton()
            ]}
        />
    );

    function getInvitationsLeftStatus() {
        if (hasUnlimitedInvitations) return INVITATIONS_LEFT_STATUS.UNLIMITED;

        if (totalInvitations === 0) {
            return INVITATIONS_LEFT_STATUS.CONSUMED;
        }

        return invitationsLeft === 0
            ? INVITATIONS_LEFT_STATUS.CONSUMED
            : INVITATIONS_LEFT_STATUS.REMAINING;
    }

    function getRemainingInvitationsText() {
        const leftStatus = getInvitationsLeftStatus();

        if (
            [
                INVITATIONS_LEFT_STATUS.REMAINING,
                INVITATIONS_LEFT_STATUS.CONSUMED,
            ].includes(leftStatus)
        ) {
            return (
                t("invitations.availableInvitations") +
                ": " +
                invitationsLeft +
                "/" +
                totalInvitations
            );
        }

        return "";
    }

    function createInvitationButton() {
        switch (getInvitationsLeftStatus()) {
            case INVITATIONS_LEFT_STATUS.UNLIMITED:
                return button(t("invitations.newInvitation"), true);

            case INVITATIONS_LEFT_STATUS.CONSUMED:
                return button(t("mod_invitations_none_left"), false);

            case INVITATIONS_LEFT_STATUS.REMAINING:
                return canInvite
                    ? button(t("invitations.newInvitation"))
                    : null;

            default:
                return button("UNKNOWN STATUS", false);
        }

        function button(text, isEnabled?) {
            return (
                <CapsuleButton
                    testId={TEST_IDS.createInvitatonButton}
                    key={"new_invitation"}
                    text={text}
                    onClick={() => { 
                        dispatch(invitationsActions.createLocalInvitation());
                        setShowModalNewInvitation(true);
                    }}
                    isSelected={
                        secondaryView ===
                        INVITATIONS_SECONDARY_VIEWS.CREATE_INVITATION
                    }
                    isEnabled={isEnabled}
                />
            );
        }
    }

    function createAccessLinkButton() {
        switch (getInvitationsLeftStatus()) {
            case INVITATIONS_LEFT_STATUS.UNLIMITED:
                return button(t("invitations.createAccessLink"), true);

            case INVITATIONS_LEFT_STATUS.CONSUMED:
                return button(t("mod_invitations_none_left"), false);

            case INVITATIONS_LEFT_STATUS.REMAINING:
                return canInvite
                    ? button(t("invitations.createAccessLink"))
                    : null;

            default:
                return button("UNKNOWN STATUS", false);
        }

        function button(text, isEnabled?) {
            return (
                <CapsuleButton
                    testId={TEST_IDS.createLinkButton}
                    key={"new_access_link"}
                    text={text}
                    onClick={() => { 
                        dispatch(invitationsActions.createLocalAccessLink());
                        setShowModalAccessLink(true);
                    }}
                    isSelected={
                        secondaryView ===
                        INVITATIONS_SECONDARY_VIEWS.CREATE_ACCESS_LINK_INVITATION
                    }
                    isEnabled={isEnabled}
                />
            );
        }
    }

    function list() {
        if(total_count === 0) {
            return <div className='d-flex align-items-center justify-content-center'><h3>{t('mod_invitations_no_invitations_yet')}</h3></div>;   
        }

        return (
            <div className="d-flex flex-column-reverse h-100">
                {(total_count ?? 0) > (page_size ?? 0) && (
                    <div className="bg-white pt-3 py-2">
                        <Paginator
                            pageSize={page_size ?? 0}
                            total={total_count ?? 0}
                            pageOffset={offset}
                            onChangePage={onChangePage}
                        />
                    </div>
                )}
                <div className="position-relative h-100">
                    <div className="position-absolute top-0 left-0 w-100 overflow-auto h-100">
                        <div ref={listRef} className="c-default-grid">
                            {invitations?.map((invitation) => (
                                <SingleInvitation
                                    key={invitation.uid}
                                    handleSelectInvitation={() =>
                                        handleSelectInvitation(invitation.uid)
                                    }
                                    isSelected={
                                        invitation.uid === selectedInvitationUid
                                    }
                                    invitation={invitation}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function content() {
        return (
            <>
                <div className="c-filtered-layout pl-1">
                    <InvitationsFilters
                        filter={invitationFilter}
                        onChangeFilters={(filter) => {
                            onChangeFilters({ ...invitationFilter, ...filter });
                        }}
                        className="c-sticky-top"
                    />
                    {isRequesting ? <WaitSpinner /> : list()}
                </div>
                <CustomModal
                    show={showModalNewInvitation}
                    centered
                    onHide={() => setShowModalNewInvitation(false)}
                    title={t("invitations.newInvitation")}
                    content={
                        <NewInvitation
                            canCreateSuperguests={canCreateSuperguests}
                            timezone={locationInfo.timezone}
                            locationInfo={locationInfo}
                            rooms={locationInfo.rooms}
                            isSuperguest={locationInfo.isSuperguest}
                        />
                    }
                    footer={
                        <>
                            <CapsuleButton
                                style="DANGER"
                                text={t("global_cancel")}
                                onClick={() => {
                                    deleteInvitationDraft();
                                    setShowModalNewInvitation(false);
                                }}
                                />
                            <CapsuleButton
                                isEnabled={isAllValid}
                                text={t("global_create")}
                                onClick={() => {
                                    createInvitations();
                                    setShowModalNewInvitation(false);
                                }}
                            />
                        </>
                    }
                />
                <CustomModal
                    show={showModalAccessLink}
                    centered
                    onHide={() => setShowModalAccessLink(false)}
                    title={t("invitations.createAccessLink")}
                    content={
                        <NewAccessLink
                            timezone={locationInfo.timezone}
                            rooms={locationInfo.rooms}
                            showAccessType={true}
                        />
                    }
                    footer={
                        <>
                            <CapsuleButton
                                style="DANGER"
                                text={t("global_cancel")}
                                onClick={() => {
                                    deleteInvitationDraft();
                                    setShowModalAccessLink(false);
                                }}
                                />
                            <CapsuleButton
                                isEnabled={isAllValid}
                                text={t("global_create")}
                                onClick={() => {
                                    createAccessLink();
                                    setShowModalAccessLink(false);
                                }}
                            />
                        </>
                    }
                />
                <CustomModal
                    show={showModalAccessLinkCreated}
                    centered
                    onHide={() => setShowModalAccessLinkCreated(false)}
                    title={t("invitation_public_url_share")}
                    content={
                        <div>
                            <div className="d-flex align-items-center justify-content-between position-relative" style={{ maxWidth: '400px'}}>
                                <p>{t("invitation_public_url_share_description")}</p>
                            </div>
                            <br />
                            <div className="d-flex align-items-center justify-content-between position-relative">
                                <small style={{ maxWidth: '300px' }} className={"mr-2 w-75 c-line-height-1"}>
                                    <a
                                        className="d-block w-100 text-secondary"
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                        }}
                                        rel="noreferrer"
                                        href={selectedAccessLink}
                                        target="_blank"
                                    >
                                        {selectedAccessLink}
                                    </a>
                                </small>
                                <div className="d-flex align-items-center">{buttons()}</div>
                            </div>
                        </div>
                    }
                    footer={
                        <>
                            <CapsuleButton
                                isEnabled={true}
                                text={t("global_ok")}
                                onClick={() => {
                                    setShowModalAccessLinkCreated(false);
                                    dispatch(invitationsActions.selectInvitationAccessLink(null));
                                }}
                            />
                        </>
                    }
                />
            </>
        );
    }

    function buttons() {
        return (
            <>
                <CapsuleButton
                    size="SMALL"
                    onClick={handleCopyToClipboard}
                    icon={Icons.copy}
                    tooltip={t('global_copy')}
                    boostedToolTip
                />
                <div className="ml-2"></div>
                <CapsuleButton
                    size="SMALL"
                    onClick={handleSendPublicUrlByWhatsApp}
                    // eslint-disable-next-line react/style-prop-object
                    icon={Icons.icon_whatsapp}
                    tooltip={t('global_send_whatsapp')}
                    boostedToolTip
                />
                <div className="ml-2"></div>
                <CapsuleButton
                    size="SMALL"
                    onClick={handleSendPublicUrlByGmail}
                    // eslint-disable-next-line react/style-prop-object
                    icon={Icons.icon_gmail}
                    tooltip={t('global_send_gmail')}
                    boostedToolTip
                />
            </>
        );
    }
}
