import { GLOBAL_ACTION_TYPES, GLOBAL_POPUPS } from "../_constants";
import { popUpActions } from "../_stores/PopUpStore/popUpActions";

export const globalActions = {
    showPopUpLogOut: () => popUpActions.showPopUp(GLOBAL_POPUPS.LOGOUT),
    showPopUpLogOutAll: () => popUpActions.showPopUp(GLOBAL_POPUPS.LOGOUT_ALL),
    showPopUp: popUpActions.showPopUp,
    hidePopUp: popUpActions.hidePopUp,

    resetGlobalState,

    setAppInitialized,
    setPreselectedLocationUid,
    setValidatedLocationUid,
};

function resetGlobalState() {
    return {
        type: GLOBAL_ACTION_TYPES.GLOBAL_RESET_STATE,
    };
}

function setAppInitialized() {
    return {
        type: GLOBAL_ACTION_TYPES.SET_APP_INITALIZED,
    };
}

function setPreselectedLocationUid(locationUid) {
    return {
        type: GLOBAL_ACTION_TYPES.SET_PRESELECTED_LOCATION_UID,
        preselectedLocationUid: locationUid,
    };
}

function setValidatedLocationUid(locationUid) {
    return {
        type: GLOBAL_ACTION_TYPES.SET_VALIDATED_LOCATION_UID,
        validatedLocationUid: locationUid,
    };
}