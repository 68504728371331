import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { HuePicker, SketchPicker } from "react-color";

import {
    MainPageWithButtons,
    ContentSpace,
    FileUploadComponent,
} from "../_components";
import { CustomizationStore } from "../_stores/CustomizationStore/CustomizationStore";
import { LocationCustomization } from "../_models/LocationCustomization";
import { Selectors } from "../_reducers/app.reducer";
import { WaitSpinner } from "../_components/WaitSpinner";

export function CustomizationModule() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let locationCustomization = LocationCustomization();
    locationCustomization = useSelector(Selectors.getLocationCustomization);
    const isUploading = useSelector(Selectors.getCustomizationIsUploading);

    //LOCAL STATE
    const [accentColor, setAccentColor] = useState(
        (locationCustomization && locationCustomization.accentColor)
        || "#000000"
    );

    const [headerColor, setHeaderColor] = useState(
        (locationCustomization && locationCustomization.headerColor)
        || "#000000"
    );

    //ACTION HANDLERS
    function handleUploadLogo(logo) {
        dispatch(CustomizationStore.actions.uploadLogo(logo));
    }

    function handleUploadBackground(logo) {
        dispatch(CustomizationStore.actions.uploadBackground(logo));
    }

    function handleChangeAccentColor(hexColor) {
        dispatch(CustomizationStore.actions.update(hexColor));
    }
    function handleChangeHeaderColor(hexColor) {
        dispatch(CustomizationStore.actions.update(undefined, hexColor));
    }

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("mod_customization_title")}
                    content={content()}
                />
            }
        />
    );

    function content() {
        return (
            <div>
                <div className="mt-1"/>
                {headerColorSelectorSection()}
                <div className="mt-3"/>
                {accentColorSelectorSection()}
                <div className="mt-3"/>
                
                {imageUploadSection(
                    t("mod_customization_location_logo"),
                    locationCustomization && locationCustomization.logo,
                    handleUploadLogo,
                    isUploading,
                )}

                <div className="mt-3"/>

                {imageUploadSection(
                    t("mod_customization_login_background"),
                    locationCustomization && locationCustomization.loginBackground,
                    handleUploadBackground,
                    isUploading,
                )}
            </div>
        );
    }

    function headerColorSelectorSection() {
        return (
            <div>
                {titleFn(t("mod_customization_header_color"))}
                <div className="d-flex justify-content-center">
                    <SketchPicker
                        color={headerColor}
                        onChangeComplete={color => handleChangeHeaderColor(color.hex)}
                        onChange={color => setHeaderColor(color.hex)}
                        presetColors={[]}
                        disableAlpha
                    />
                </div>
            </div>
        );
    }

    function accentColorSelectorSection() {
        return (
            <div>
                {titleFn(t("mod_customization_accent_color"))}
                <div className="mt-1"/>
                <div className="d-flex justify-content-center my-2 cp">
                    <HuePicker
                        color={accentColor}
                        onChangeComplete={color => handleChangeAccentColor(color.hex)}
                        onChange={color => setAccentColor(color.hex)}
                        width={"90%"}
                    />
                </div>
                <div className="d-flex c-h-3rem py-2">
                    {colorSwatch("c-bg-primary")}
                    {colorSwatch("c-bg-primary-light-1")}
                    {colorSwatch("c-bg-primary-light-2")}
                </div>
            </div>
        );

        function colorSwatch(backgroundCssClass) {
            return (
                <div className={`
                    col
                    border
                    rounded
                    mx-2
                    ${backgroundCssClass}
                `}/>
            );
        }
    }

    function imageUploadSection(title, img, handleUpload, isUploading) {
        return (
            <div>
                {titleFn(title)}
                {img &&
                    <>
                    <div className="mt-1"/>
                    <div className="
                        d-flex
                        justify-content-center
                        align-items-center
                    ">
                        {
                            isUploading
                            ? <WaitSpinner />
                            : <img
                                className="
                                    mw-100
                                    c-mh-15rem

                                    rounded
                                    border
                                    bg-light
                                    p-2
                                "
                                alt="ditributor_avatar"
                                src={img}
                            />
                        }
                    </div>
                    </>
                }
                <div className="mt-1"/>
                <FileUploadComponent
                    onUpload={handleUpload}
                    isUploading={isUploading}
                />
            </div>
        );
    }

    function titleFn(title) {
        return (
            <div className="font-weight-bold text-uppercase">
                {title}
            </div>
        );
    }
}