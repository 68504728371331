import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { DEVELOPERS_API_URLS } from "../_constants/DEVELOPERS_API_URLS";

export const DevTokensService = {
    list,
    remove,
    add,
};

const serviceUrl = DEVELOPERS_API_URLS.TOKENS;

async function list() {
    return await ServiceHelper.getRequest(serviceUrl);
}

async function remove(uid) {
    await ServiceHelper.deleteRequest(serviceUrl + "/" + uid);
}

async function add() {
    return await ServiceHelper.postRequest(serviceUrl);
}