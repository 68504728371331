import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { DEVELOPERS_API_URLS } from "../_constants/DEVELOPERS_API_URLS";

export const DevRoomsService = {
    list,
};

async function list() {
    return await ServiceHelper.getRequest(
        DEVELOPERS_API_URLS.ROOMS,
    );
}