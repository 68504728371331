import { ServerIntegrationSend } from "../../Everou/_modelsTS/Integration"
import { ServiceHelper } from "../../Everou/_services/ServiceHelper"
import { PMS_API_URLS } from "../_constants/PMS_API_URLS"

export const PMSOctorateService = {
    token,
    listAccommodations,
    userInfo,
}

async function token(code: string) {
    return ServiceHelper.postRequest(
        PMS_API_URLS.OCTORATE_TOKEN,
        {
            code: code,
        }
    );
}

function listAccommodations(uid: string) {
    return ServiceHelper.getRequest(
        PMS_API_URLS.OCTORATE_ACCOMMODATIONS,
        {
            uid: uid
        }
    );
}

function userInfo(uid: string) {
    return ServiceHelper.getRequest(
        PMS_API_URLS.OCTORATE_USER_INFO,
        {
            uid: uid
        }
    );
}