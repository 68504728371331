import { TimeHelper } from '../_helpers/TimeHelper';
import {
    INVITATION_PERIODS,
    INVITATION_PERIODS_LEGACY,
    NULL_MAIL
} from '../_constants';
import { Invitation, WeekDaysSlots } from '../_models/Invitation';
import { RoomMapper } from "./RoomMapper";
import { InvitationsHelper } from '../_helpers';
export class InvitationsMapper {

    static mapAllServerToLocal(serverInvitations = []) {
        return serverInvitations.map(this.mapServerToLocal);
    }
    
    static mapServerToLocal(
        {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            created_on,
            enabled,
            code,
            uid,
            object_uid,
            loc_uid,
            location_name,

            type,
            status,
            
            all_day,
            permission_type,
            device_name,
            
            user_id,
            user_name,
            user_email,
            user_avatar,

            begin,
            end,
            period_type,

            user_src_name,
            user_src_email,
            allows_subinvitations,
            subinvitations,

            subinvitations_left,

            room_name,
            room_type,
            invitation_group_uid,
            is_subinvitation,

            superuser,
            sat,
            url,

            rooms,

            public_url,

            time_slots
        }
    ) {

        return Invitation({
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            createdOn: TimeHelper.serverStringToIsoString(created_on),
            enabled,
            code,
            uid,
            objectUid: object_uid, 
            locationUid: loc_uid,
            locationName: location_name,

            type,
            status,

            allDay: all_day,
            permissionType: permission_type,
            deviceName: device_name,
            
            userUid: user_id,
            userName: user_name,
            email: mapEmailServerToLocal(user_email),
            avatar: user_avatar,
            
            begin: mapDateServerToLocal(begin, period_type) as any,
            end: mapDateServerToLocal(end, period_type) as any,
            periodType: mapPeriodTypeServerToLocal(period_type, end),

            newInvitation: false,
            edited: false,

            userSrcEmail: user_src_email,
            userSrcName: user_src_name,
            
            allowsSubinvitations: allows_subinvitations,
            subinvitations,
            
            subinvitationsLeft: subinvitations_left,

            localStatus: InvitationsHelper.localStatusFromServerStatus(status, enabled),

            roomName: room_name,
            roomType: room_type,

            invitationGroupUid: invitation_group_uid,

            isSubinvitation: is_subinvitation,

            superuser,
            sat,
            url,

            rooms: rooms && RoomMapper.serverToLocal(rooms),

            publicUrl: public_url,

            timeSlots: period_type === INVITATION_PERIODS_LEGACY.RECURRENT ? InvitationsHelper.getTimeSlotsFromRecurrentLegacy({ begin, end, monday, thursday, tuesday, wednesday, friday, saturday, sunday }) : mapTimeSlotsServerToLocal(time_slots) as any 
        });
    }

    static mapNewLocalToServer(localInvitation) {
        const {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,

            enabled,
            
            objectUid,

            status,
            type,
            
            allDay,
            userName,
            permissionType,
            emails,
            begin,
            end,

            allowsSubinvitations,
            subinvitations,

            createPublicUrl,
            timeSlots

        } = localInvitation;

        let serverInvitation = {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            
            enabled,
            uid: objectUid,
            status,

            all_day: allDay,
            user_name: userName,
            permission_type: permissionType,
            period_type: INVITATION_PERIODS.TEMPORARY,
            emails: mapEmailsLocalToServer(emails),
            type,
            end,
            
            allows_subinvitations: allowsSubinvitations,
            subinvitations,
            
            create_public_url: createPublicUrl,
            
        }

        return {
            ...serverInvitation,
            ...(!!begin && { begin }),
            ...(!!timeSlots && { time_slots: mapTimeSlotsLocalToServer(timeSlots) })
        };
    }

    static mapUpdatedLocalToServer(localInvitation = Invitation()) {

        let serverInvitation = {
            uid: localInvitation.uid,

            monday: localInvitation.monday,
            tuesday: localInvitation.tuesday,
            wednesday: localInvitation.wednesday,
            thursday: localInvitation.thursday,
            friday: localInvitation.friday,
            saturday: localInvitation.saturday,
            sunday: localInvitation.sunday,

            enabled: localInvitation.enabled,

            permission_type: localInvitation.permissionType,
            period_type: INVITATION_PERIODS.TEMPORARY,
            all_day: localInvitation.allDay,

            begin: localInvitation.begin,
            end: localInvitation.end,
            time_slots: localInvitation.timeSlots ? mapTimeSlotsLocalToServer(localInvitation.timeSlots) : []
        };

        return serverInvitation;
    }

}



function mapEmailsLocalToServer(emails = []) {
    return emails.filter(email => email !== "");
}

function mapTimeSlotsLocalToServer(weekDaysTimeSlots: WeekDaysSlots | null) {

    if (!weekDaysTimeSlots) {
        return null;
    }

    const res = Object.entries(weekDaysTimeSlots).map(([weekday, ranges]) => {
        const res2 = ranges.map(({ start, end }) => ({
            start,
            end,
            weekday
        }))
        return res2
    })
    return res.flat();
}

function mapTimeSlotsServerToLocal(slots: { start: string, end: string, weekday: number | string }[]) {

    if (!slots?.length) return null;

    let res = {};

    slots.forEach(({ start, end, weekday }) => {
        res = {
            ...res,
            [weekday]: InvitationsHelper.orderTimeSlots([...(res[weekday] ?? []), { start, end }])
        }
    })

    return res;
}

function mapEmailServerToLocal(serverEmail) {
    if (serverEmail === null)
        return NULL_MAIL;
        
    return serverEmail;
}

function mapDateServerToLocal(serverUtcDate, periodType) {

    if(periodType === INVITATION_PERIODS_LEGACY.RECURRENT) {
        return null;
    }

    if(!serverUtcDate) return
    return TimeHelper.serverStringToIsoString(serverUtcDate);
}


function mapPeriodTypeServerToLocal(periodType, end) {

    if([INVITATION_PERIODS_LEGACY.RECURRENT, INVITATION_PERIODS_LEGACY.ALWAYS].includes(periodType))
        return INVITATION_PERIODS.ALWAYS;

    if (periodType === INVITATION_PERIODS.TEMPORARY && !end) { 
        return INVITATION_PERIODS.ALWAYS;
    }
    
    return INVITATION_PERIODS.TEMPORARY;
}