import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { authenticationActions } from "../_actions";

export function LogOutAllGlobalPopUp() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <BaseAppGlobalPopUp
            title={t("logOutPopUpAll.title")}
            body={t("logOutPopUpAll.description")}
            onAccept={handleLogOutAll}
            acceptButtonName={t("logOutPopUpAll.acceptButton")}
        />
    );

    function handleLogOutAll() {
        dispatch(authenticationActions.logOutAll());
    }
}