import { URL_CONSTANTS, API_ERROR_CODES} from "../_constants/url.constants";
import { authenticationActions } from "../_actions";
import { EXCEPTIONS, MIME_TYPES } from "../_constants";
import { EverouErrorMapper } from "../_mappers/EverouErrorMapper";
import { BudgetsErrorMapper } from "../../Budgets/_mappers/BudgetsErrorMapper";
import { Selectors } from "../_reducers/app.reducer";
import { StoreManager } from "../StoreManager";
import { HistoryHelper } from "../_helpers";


function globalApiErrorMapper(code, info) {
    return EverouErrorMapper.getMessageFromCode(code, info)
        || BudgetsErrorMapper.getMessageFromCode(code)
        || undefined
    ;
}

export const ServiceHelper = serviceHelperFactory(
    URL_CONSTANTS.URL_API,
    () => Selectors.getApikey(StoreManager.getStore().getState()),
    () => StoreManager.getStore().dispatch(authenticationActions.localLogOut()),
    globalApiErrorMapper,
);

export function serviceHelperFactory(
    serviceUrl,
    getApikeyFn = () => {},
    onInvalidApikeyFn = () => {},
    errorMapper = (code, info): any | void => {},
) {
    return {
        serviceUrl,
        getRequestWithApikeyParam,
        getRequest,
        getPdfBlobRequest,
        getCSVBlobRequest,
        getExcelBlobRequest,
        postRequest,
        postImageRequest,
        putRequest,
        putImageRequest,
        deleteRequest,
        putVideoRequest,
    };

    async function getRequestWithApikeyParam(endPoint, parameters = "") {
        return await customFetch(
            endPoint,
            serviceUrl + endPoint + parameters + `&api_key=${getApikeyFn()}`,
            { method: 'GET' },
        );
    }

    async function getPdfBlobRequest(endPoint, parameters = "") {
        const blob = await ServiceHelper.getRequest(endPoint, parameters);
        return new Blob([blob], { type: MIME_TYPES.PDF });
    }

    async function getCSVBlobRequest(endPoint, parameters = "") {
        const blob = await ServiceHelper.getRequest(endPoint, parameters);
        return new Blob([blob], { type: MIME_TYPES.CSV });
    }

    async function getExcelBlobRequest(endPoint, parameters = "") {
        const blob = await ServiceHelper.getRequest(endPoint, parameters);
        return new Blob([blob], { type: MIME_TYPES.XLSX });
    }

    async function getRequest(endPoint, parameters?, isPublicApi = false) {
        const finalParams = typeof parameters === "object"
            ? paramStringFromObj(parameters)
            : parameters ?? ''
        ;

        const response = await customFetch(
            endPoint,
            serviceUrl + endPoint + finalParams,
            getRequestOptions(),
        );

        return await handleResponse(response);

        function getRequestOptions() {
            return {
                method: 'GET',
                headers: requestHeaders(isPublicApi),
            };
        }
    }
    
    function paramStringFromObj(queryObj) {

        if(!(Object.keys(queryObj)?.length > 0)) return '';

        const res = Object.entries(queryObj).reduce((acc, [key, value]) => {

            let param = '';

            const addToQueryParam = (key, value) => { if(![null, undefined, ''].includes(value)) { param += `${key}=${encodeURIComponent(value)}&`; }} 

            if (Array.isArray(value)) {
                if (value.length) {
                    value.forEach(val => addToQueryParam(key, val));
                }
            } else {
                   addToQueryParam(key, value);
            }
            return acc + param;
        }, '')

        return '?' + res.substring(0, res.length - 1);
    }

    async function postRequest(
        endPoint,
        contentObject,
        isPublicApi = false
    ) {

        const response = await customFetch(
            endPoint,
            serviceUrl + endPoint,
            postRequestOptions(contentObject, isPublicApi),
        );
        
        return await handleResponse(response);

        function postRequestOptions(content, isPublicApi) {
            return {
                headers: requestHeaders(isPublicApi),
                method: "POST",
                body: JSON.stringify(content),
            };
        }
    }

    async function postImageRequest(endPoint, image) {
        const response = await customFetch(
            endPoint,
            serviceUrl + endPoint,
            {
                headers: requestHeaders(false, MIME_TYPES.IMAGE_JPEG),
                method: "POST",
                body: image,
            },
        );

        return await handleResponse(response);
    }

    async function putImageRequest(endPoint, image) {
        const response = await customFetch(
            endPoint,
            serviceUrl + endPoint,
            {
                headers: requestHeaders(false, MIME_TYPES.IMAGE_JPEG),
                method: "PUT",
                body: image,
            },
        );

        return await handleResponse(response);
    }

    async function putVideoRequest(endPoint, video) {
        const response = await customFetch(
            endPoint,
            serviceUrl + endPoint,
            {
                headers: requestHeaders(false, MIME_TYPES.VIDEO_MP4),
                method: "PUT",
                body: video,
            },
        );

        return await handleResponse(response);
    }

    async function putRequest(endPoint, bodyObject) {
        const response = await customFetch(
            endPoint,
            serviceUrl + endPoint,
            putRequestOptions(bodyObject),
        );

        return await handleResponse(response);

        function putRequestOptions(bodyObject) {
            return {
                headers: requestHeaders(),
                method: 'PUT',
                body: JSON.stringify(bodyObject),
            };
        }
    }

    async function deleteRequest(endPoint, objParams, body: any = null) {

        if(objParams) {
            const params = paramStringFromObj(objParams);
            endPoint = endPoint + params;
        }
    
        const response = await customFetch(
            endPoint,
            serviceUrl + endPoint,
            deleteRequestOptions(),
        );

        return await handleResponse(response);

        function deleteRequestOptions() {
            return {
                headers: requestHeaders(),
                method: 'DELETE',
                body: JSON.stringify(body)
            };
        }
    }

    function requestHeaders(isPublicApi = false, contentType = MIME_TYPES.JSON) {
        
        const apikey = isPublicApi
            ? URL_CONSTANTS.API_KEY
            : "Basic " + getApikeyFn()
        ;

        return {
            'Authorization': apikey,
            'Content-Type': contentType,
            'Everou-Referer': HistoryHelper.getCurrentUrlFullNoParams(),
        };
    }

    async function handleResponse(response) {
        const contentType = response.headers.get("Content-Type");
    
        switch (contentType) {
            case MIME_TYPES.PDF:
            case MIME_TYPES.CSV:
            case MIME_TYPES.XLSX:
                return manageBlob();
    
            default:
            case MIME_TYPES.JSON:
                return manageJson();
        }
    
        async function manageJson() {
            const text = await response.text();
            const data = text && JSON.parse(text);
    
            if (!response.ok) {
                if (data.error_code === API_ERROR_CODES._102_INVALID_APIKEY)
                    onInvalidApikeyFn();
                
                const error = new Error(getErrorMessage(data, response));
                error.name = data.error_code;
                return Promise.reject(error);
            }
        
            return data;
        }
    
        async function manageBlob() {
            return await response.blob();
        }
    }

    function getErrorMessage(data, response) {
        const code = data.error_code;
        const info = data.error_info;
    
        if (!code)
            return response.statusText + (info || "");

        return errorMapper(code, info)
            || `UNDEFINED ERROR CODE ${code}: ${info && JSON.stringify(info)}`
        ;
    }
}

async function customFetch(localEndPoint, fullEndPoint, options) {
    // console.warn("start", localEndPoint);
    const requestId = RacingCallsManager.setLastId(localEndPoint);
    const response = await fetch(fullEndPoint, options);
    RacingCallsManager.throwErrorIfExpired(requestId, localEndPoint);
    return response;
}

class RacingCallsManager {
    static _ignoredUrls = [
        URL_CONSTANTS.LOCATION_INFO,
        URL_CONSTANTS.CAMERA_VIDEO,
    ];

    static _urlIdDictionary = {};
    
    static setPendingRequests(calls) {
        RacingCallsManager._urlIdDictionary = calls;
    }
    

    static setLastId(endPoint) {
        if (this.isIgnoredUrl(endPoint))
            return;

        if (this._urlIdDictionary[endPoint] === undefined)
            return this._urlIdDictionary[endPoint] = 0;
    
        return ++this._urlIdDictionary[endPoint];
    }
    
    static throwErrorIfExpired(requestId, endPoint) {
        // console.warn("finished endpoint", endPoint);
        if (this.isIgnoredUrl(endPoint))
            return;

        if (requestId !== this.getLastId(endPoint)) {
            // console.warn("race condition for", endPoint);
            throw new Error(EXCEPTIONS.REQUEST_EXPIRED);
        }

        delete this._urlIdDictionary[endPoint];
    }

    static getLastId(endPoint) {
        return this._urlIdDictionary[endPoint];
    }

    static isIgnoredUrl(endPoint) {
        return this._ignoredUrls.includes(endPoint);
    }

    static async waitUntilRequestsAreDone(timeout = 1000) {
        let timedOut = false;
        return new Promise((resolve, reject) => {
            // console.warn("Waiting for pending requests");
            const timeoutFn = setTimeout(() => {
                timedOut = true;
                reject("timeout waiting for pending requests");
            }, timeout);

            checkDelayed(resolve, timeoutFn);
        });

        async function checkDelayed(resolve, timeoutFn) {
            // console.warn("requests", Object.keys(RacingCallsManager._urlIdDictionary));
            if (timedOut) {
                console.warn("timed out");
                return;
            }

            if (isDone()) {
                clearTimeout(timeoutFn);
                // console.warn("finished pending requests");
                return resolve();
            }

            setTimeout(() => checkDelayed(resolve, timeoutFn), 100);
        }

        function isDone() {
            return !Object.keys(RacingCallsManager._urlIdDictionary).length;
        }
    }
}

export const ServiceHelperTesting = {
    customFetch,
    waitUntilRequestsAreDone: RacingCallsManager.waitUntilRequestsAreDone,
    setPendingRequests: RacingCallsManager.setPendingRequests,
};