import { TECHNICAL_SERVICE_ACTION_TYPES } from "../_constants/technicalService.constants";

const initialState = {
    code: null,
    invitationUid: null,
};

export class TechnicalServiceSelectors {
    static getSatInvitationUid(state) {
        return state.invitationUid;
    }

    static getSatCode(state) {
        return state.code;
    }
}

export function technicalService(state = initialState, action) {
    switch (action.type) {

        case TECHNICAL_SERVICE_ACTION_TYPES.ENABLE_SAT:
            return { ...state,
                code: action.code,
                invitationUid: action.invitationUid,
            };

        case TECHNICAL_SERVICE_ACTION_TYPES.DISABLE_SAT:
            return { ...state,
                code: null,
                invitationUid: null,
            };
        
        default:
            return state;
    }
}