//https://docs.google.com/document/d/1vhxAa5l29ab6BFlyJbNTvZTLonk_yOwnwbKTf3dWKD4/edit
(function setTestingEnv() {
    if (process.env.NODE_ENV === "test") {
        if (process.env.RUN_E2E_TESTS) {
            window._env_ = { ...process.env };
            return;
        }

        window._env_ = {
            URL_API: "http://testing-baintex.com",
        };
    }
})();

export const API_ERROR_CODES = {
    _102_INVALID_APIKEY: 102,
    _205_USER_NOT_EXIST: 205,
};

export const WEBAPP_URLS = {
    ROOT:           "/",

    LOGIN:          "/login",
    REGISTER:       "/register",
    OAUTH:          "/oauth",
    OAUTH_OCTORATE: "/oauth_octorate",
    
    PRIVACY_POLICY: "/privacy-policy",
    TERMS:          "/terms",
};

export const EVEROU_URLS = {
    DASHBOARD:      "/dashboard",
    OPTIONS:        "/options",
    DEVICES:        "/devices",
    ALERTS:         "/alerts",
    INVITATIONS:    "/invitations",
    INVITATIONS_GROUPS: '/invitations-groups',
    INVITAITONS_CREATE: '/invitations-create',
    INVITATIONS_USERS:"/users",
    LOGS:           "/logs",
    VIDEO:          "/video",
    PROTECT:        "/protect",
    HELP:           "/help",
    SUBSCRIPTIONS:  "/subscriptions",
    TRIGGERS:       "/triggers",
    AUDIT:          "/audit",
    RESERVATIONS:   "/reservations",
    USERS_MOBILES:  "/users-mobiles",
    USER_LOGS:      "/user-logs",
    USERS_STATUS:   "/users-status",
    CLOCKINS:       "/clockins",
    CLOCKINS_REPORTS: "/clockins-reports",
    CUSTOMIZATION:  "/customization",
    BILLING:        "/billing",
    DEVELOPERS:     "/developers",
    OTA:            "/ota",
    INTEGRATIONS:   '/integrations',
    BARRIERS:       '/barriers'
};

export const GOOLGE_CLIENT_ID = window._env_.GOOGLE_CLIENT_ID;
export const APPLE_CLIENT_ID = window._env_.APPLE_CLIENT_ID;
export const STRIPE_ID = window._env_.STRIPE_ID;
export const SENTRY_DSN = window._env_.SENTRY_DSN;
export const OCTORATE_CLIENT_ID = window._env_.OCTORATE_CLIENT_ID;
export const OCTORATE_REDIRECT_URI = window._env_.OCTORATE_REDIRECT_URI;

export const URL_CONSTANTS = {
    TEST_API_URL:       window._env_.TEST_API_URL,

    PRIVACY_POLICY:     window._env_.PRIVACY_POLICY_URL,
    TERMS_OF_USE:       window._env_.TERMS_OF_USE_URL,

    PUSH_SERVER_KEY:    window._env_.PUSH_SERVER_KEY,
    API_KEY:            window._env_.API_KEY,
    URL_API:            window._env_.URL_API,
    URL_WEBSOCKET:      window._env_.WEBSOCKET_URL,

    CAMERA_VIDEO:               "/camera/video",

    UPDATE_SYSTEM_INFO:         "/mobile/update",
    APIKEY_REFRESH:             "/user/login/refresh",

    USER_REQUEST_CODE:          '/user/request/code',
    USER_REGISTER_CODE:         '/user/register/code',
    USER_LOGIN_CODE:            '/user/login/code',
    USER_LOGOUT:                '/user/logout',
    USER_VALIDATE_CODE:         '/user/validate/code',
    USER_INFO:                  '/user/info',

    USER_FRIENDS:               '/user/friends',
    USER_LOGS:                  '/user/logs',
    
    LOCATION_LIST:              '/locations',
    LOCATION_REMOVE:            '/location/remove',
    LOCATION_INFO:              '/location/info/new',
    LOCATION_LOG:               '/location/log',
    LOCATION_PROTECT_LOG:       '/location/protect/log',
    LOCATION_UPDATE:            '/location/update',
    LOCATION_PRODUCT_PRICES:    '/location/products/prices',
    LOCATION_PROTECT_MUTE:      '/location/protect/mute',
    LOCATION_USER_POSITION:     '/location/user/position',
    LOCATION_USERS:             '/location/users',
    LOCATION_CREATE:            '/location/create',
    LOCATION_INVITATION_SETTINGS_INFO:   '/location/invitation/settings/info',

    LOCATION_VIDEO_UPLOAD:      '/location/upload/video',

    LOCATION_USERS_STATUS:      '/location/users/status',
    LOCATION_USERS_STATUS_DATA: '/location/user/status/data',
    LOCATION_USER_STATUS_LOGS:  '/location/user/status/logs',
    LOCATION_USER_UPDATE:       '/location/user/update',
    LOCATION_BILLING_DATA_INFO:     '/location/billing/info',
    LOCATION_BILLING_DATA_UPDATE:   '/location/billing/update',
    LOCATION_BILLING_DATA_DELETE:   '/location/billing/delete',

    DEVICE_NOSTATUS_ACTION:     '/device/nostatus/action',
    DEVICE_ACTION:              '/device/action',
    DEVICE_ACTION_REQUEST:      "/device/action/request",
    DEVICE_ACTION_CONFIRM:      "/device/action/confirm",
    DEVICE_LOG:                 "/device/log", 
    DEVICE_UPDATE:              '/device/update',
    DEVICE_INFO:                '/device/info',
    DEVICE_UPDATE_LIST:         '/device/update/list',
    DEVICE_UPDATE_FIRMWARE:     '/device/update/firmware',
    DEVICE_REBOOT:              '/device/reboot',

    BRAIN_LOG:                  "/brain/log",
    BRAIN_UPDATE:               '/brain/update',
    BRAIN_INFO:                 '/brain/info',

    INVITATION_INFO:            "/invitation/info",
    INVITATION_LIST:            "/invitation/list",
    INVITATION_UPDATE:          "/invitation/update",
    INVITATION_GENERATE_PUBLIC_URL:   "/invitation/link/generate",
    INVITATION_DELETE_PUBLIC_URL:     "/invitation/link/delete",
    INVITATION_SEND_PUBLIC_URL_MAIL:       "/invitation/link/send",
    INVITATION_REQUEST:         "/invitation/request",
    INVITATION_BULK_REQUEST:    "/invitation/bulk_request",
    INVITATION_DELETE:          "/invitation/delete",
    INVITATION_VALIDATE:        "/invitation/validate",
    INVITATION_ACCEPT:          "/invitation/accept",
    INVITATION_CANCEL:          "/invitation/cancel",
    INVITATION_SEND:            "/invitation/send",
    INVITATION_LIST_PENDING:    "/invitation/list/pending",
    INVITATION_SUBINVITATIONS:  '/invitation/subinvitations',
    INVITATION_SAT:             '/invitation/sat',
    
    MY_INVITATIONS:             "/myinvitations",

    INVITATION_GROUP_INFO:      "/invitation/group/info",
    INVITATION_GROUP_REMOVE:    "/invitation/group/remove",
    INVITATION_GROUP_LIST:      "/invitation/group/list",
    INVITATION_GROUP_ASSOCIATE: "/invitation/group/associate",
    INVITATION_GROUP_DISASSOCIATE: "/invitation/group/disassociate",
    INVITATION_GROUP_UPDATE:    "/invitation/group/update",
    INVITATION_GROUP_CREATE:    "/invitation/group/create",
    INVITATION_GROUP_ADD_USERS:  "/invitation/group/users/add",

    WIDGET_GROUP_LIST:          "/widgetgroup/list",
    WIDGET_GROUP_CREATE:        "/widgetgroup/create",
    WIDGET_GROUP_UPDATE:        "/widgetgroup/update",
    WIDGET_GROUP_DELETE:        "/widgetgroup/delete",

    WIDGET_LIST:                "/widget/list",
    WIDGET_CREATE:              "/widget/create",
    WIDGET_UPDATE:              "/widget/update",
    WIDGET_DELETE:              "/widget/delete",

    SUBSCRIPTION_LIST:          "/subscription/list",
    SUBSCRIPTION_UPDATE:        "/subscription/update",
    SUBSCRIPTION_INFO:          "/subscription/info",
    SUBSCRIPTION_ACTIVATE:      "/subscription/activate",
    SUBSCRIPTION_CREATE:        "/subscription/create",
    SUBSCRIPTION_AUTOMATE_RENEWAL: "/subscription/automate/renewal",

    TRIGGER_LIST:               "/trigger/list",

    AUDIT_LOGS_LIST:            "/auditlogs/list",
    AUDIT_LOGS_PRESET_LIST:     "/auditlogs/preset/list",
    AUDIT_LOGS_PRESET_CREATE:   "/auditlogs/preset/create",
    AUDIT_LOGS_PRESET_REMOVE:   "/auditlogs/preset/remove",

    RESERVATION_CONFIGURATION_CREATE:   "/reservation/configuration/create",
    RESERVATION_CONFIGURATION_UPDATE:   "/reservation/configuration/update",
    RESERVATION_CONFIGURATION_LIST:     "/reservation/configuration/list",
    RESERVATION_CONFIGURATION_REMOVE:   "/reservation/configuration/remove",
    RESERVATION_LIST:                   "/reservation/list",
    RESERVATION_CREATE:                 "/reservation/create",
    RESERVATION_DELETE:                 "/reservation/remove",

    CARD_INFO:                  "/card/info",
    CARD_INVITATION:            "/card/invitation",

    LOCATION_MOBILES:           "/location/mobiles",

    CODE_TYPE:                  "/code/type",

    NFC_INFO:                   "/card/nfc/info",

    CLOCKINS:                   "/clockins",
    CLOCKINS_USERS:             "/clockins/users",
    CLOCKINS_USERS_SETTINGS:    "/clockins/users/settings",
    CLOCKINS_REPORT:            "/clockins/report",
    CLOCKINS_REPORT_EXPORT:     "/clockins/report/export",

    CUSTOMIZATION_UPLOAD_LOGO:          "/location/customization/upload/logo",
    CUSTOMIZATION_UPLOAD_BACKGROUND:    "/location/customization/upload/login",
    CUSTOMIZATION_UPDATE:               "/location/customization/update",
    CUSTOMIZATION_LOCATION_INFO:        "/location/customization/info",
    
    CUSTOMIZATION_LOGIN_INFO:           "/login/customization/info",

    PAYMENT_METHODS:                         "/payments/payment_methods",
    PAYMENT_METHODS_CREATE_SETUP_INTENT:     "/payments/payment_methods/create_setup_intent",
    PAYMENT_METHODS_CONFIRM_SETUP_INTENT:    "/payments/payment_methods/confirm_setup_intent",

    ORDERS: "/invoices",
    DEVELOPERS: "/developers",

    INTEGRATIONS: '/integration',
    INTEGRATION_INFO: '/integration/info',
    INTEGRATION_HOSTIFY_HANDLER: '/webhooks/hostify',
    INTEGRATION_HOSTIFY_LIST_LISTINGS: '/hostify/listings/list',
    INTEGRATION_KROSS_BOOKING_AUTH: '/kross_booking/get_auth',
    INTEGRATION_KROSS_BOOKING_PROPERTIES: '/kross_booking/properties',
    INTEGRATION_KROSS_BOOKING_ROOMS: '/kross_booking/rooms',
    INTEGRATION_SMOOBU_APARTMENTS: '/smoobu/apartments',

    BARRIERS_INFO:  '/location/user/parking',
    ROOM_CREATE: '/room/create',
    ROOM_UPDATE: '/room/update',
    ROOM_LIST: '/rooms',
    ROOM_REMOVE: '/room/remove',
};