import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { ContentSpace } from "../_components";
import { userLogsActions } from "../_actions/userLogs.actions";

import { SelectedUserLogs } from "./SelectedUserLogs";
import { UserLogsMainPage } from "./UserLogsMainPage";
import { Selectors } from "../_reducers/app.reducer";

export function UserLogsModule() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const selectedUserUid = useSelector(Selectors.getUserLogsSelectedUid);

    //ACTION HANDLERS
    const handleGoBack = useCallback(() => {
        dispatch(userLogsActions.changeSelectedUserUid(null));
    }, [dispatch]);

    //INITIALIZATION
    useEffect(() => {
        initialize();
        
        function initialize() {
            dispatch(userLogsActions.changeSelectedUserUid(null));
            dispatch(userLogsActions.getFriends());
        }
    }, [dispatch]);

    //VIEW
    return (
        <ContentSpace
            mainContent={<UserLogsMainPage />}
            detailedContent={detailedContent()}
            onGoBack={handleGoBack}
        />
    );

    function detailedContent() {
        if (!selectedUserUid)
            return;

        return <SelectedUserLogs />;
    }
}