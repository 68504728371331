export function GenericFilterStoreFactory(ID, initialState = {}) {

    const _typeChangeGenericFilter = "CHANGE_GENERIC_FILTER_" + ID;

    function actionChangeGenericFilter(filterObj = {}) {
        return {
            type: _typeChangeGenericFilter,
            filterObj,
        };
    }
    
    function reducer(state = initialState, action) {
        switch (action.type) {

            case _typeChangeGenericFilter:
                return {
                    ...state,
                    ...action.filterObj,
                };
            
            default:
                return state;
        }
    }

    return {
        actionChangeGenericFilter,
        reducer,
        _typeChangeGenericFilter,
    };
}