import { genericActions } from "./generic.actions";
import { technicalServiceService } from "../_services/technicalService.service";
import { InvitationsMapper } from "../_mappers";
import { invitationsService } from "../_services";
import { TECHNICAL_SERVICE_ACTION_TYPES } from "../_constants/technicalService.constants";
import { Invitation } from "../_models/Invitation";
import { Selectors } from "../_reducers/app.reducer";
import { appPermissions } from "../_constants/permissions.constants";

export const technicalServiceActions = {
    checkIsSatEnabled,
    requestSat,
    disableSat,
};

function disableSat() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            if (! await dispatch(technicalServiceActions.checkIsSatEnabled()))
                return;

            const invitationUid = Selectors.getSatInvitationUid(getState());

            if (!invitationUid)
                return;

            await invitationsService.deleteInvitation(invitationUid);
            dispatch(success());
        }
    }

    function success() {
        return {
            type: TECHNICAL_SERVICE_ACTION_TYPES.DISABLE_SAT,
        };
    }
}

function requestSat() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (await dispatch(
                technicalServiceActions.checkIsSatEnabled()
            ))
                return;

            const serverInvitation = await technicalServiceService.requestSatInvitation(
                Selectors.getSelectedLocationUid(getState())
            );
            const satInvitation = InvitationsMapper.mapServerToLocal(serverInvitation);
            dispatch(getSatInvitationSuccess(satInvitation));
        }
    }
}

function checkIsSatEnabled() {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!appPermissions.canRequestSat(
                Selectors.getPermissionSelectedLocation(getState())
            )) return false;

            const serverInvitations =
                await invitationsService.getInvitationsList({
                    loc_uid: Selectors.getSelectedLocationUid(getState()),
                    sat: true,
                });

            const invitations = InvitationsMapper.mapAllServerToLocal(serverInvitations);
            const satInvitation = invitations.find(invitation => invitation.sat);

            if (!satInvitation)
                return false;
            
            dispatch(getSatInvitationSuccess(satInvitation));
            return true;
        }
    }
}

function getSatInvitationSuccess(satInvitation = Invitation()) {
    const code = extractCodeFromUrl(satInvitation.url);
    const invitationUid = satInvitation.uid;

    return {
        type: TECHNICAL_SERVICE_ACTION_TYPES.ENABLE_SAT,
        code,
        invitationUid,
    };
}

function extractCodeFromUrl(url = "") {
    const splittedArray = url.split("/");
    return splittedArray.length && splittedArray[splittedArray.length - 1];
}