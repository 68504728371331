import React from "react";
import { useTranslation } from 'react-i18next';

import { Product } from "../_models/Product";
import { CustomDropdown } from "../../Common/_components/CustomDropdown";
import {
    CapsuleButton,
    RangeInputComponent,
    TextInputComponent,
} from "../_components";
import { FloatRangeComponent } from "../../Common/_components/FloatRangeComponent";

export function NewEntryFields({
    products = [] || [Product()],

    isPriceEditable = false,
    isNameEditable = false,

    name = "",
    setName = () => {},

    price,
    setPrice = () => {},

    productType,
    setProductType = () => {},

    amount,
    setAmount = () => {},

    discount,
    setDiscount = () => {},

    saveButtonName,
    onSave,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div>
            {table()}
            <div className="mt-1"/>
            <div className="w-100 d-flex">
                {saveButton()}
            </div>
        </div>
    );

    function table() {
        return (
            <table className="w-100 table-sm pl-3">
                <thead className="w-100 border-left">
                    <tr className="w-100 d-flex no-gutters text-uppercase">
                        {biggerHeader(t("global_type"))}
                        {isNameEditable && genericHeader(t("global_name"))}
                        {genericHeader(t("global_amount"))}
                        {isPriceEditable && genericHeader(t("mod_budgets_unit_gross_price"))}
                        {genericHeader(t("global_discount"))}
                    </tr>
                </thead>

                <tbody className="w-100 text-right border-left">
                    <tr className="w-100 d-flex no-gutters">
                        {biggerEntry(productTypeFn())}
                        {isNameEditable && genericEntry(productNameFn())}
                        {genericEntry(amountFn())}
                        {isPriceEditable && genericEntry(priceFn())}
                        {genericEntry(discountFn())}
                    </tr>
                </tbody>
            </table>
        );

        function biggerHeader(text) {
            return (
                <th className="
                    col-4
                    px-1
    
                    border-right
                    border-top
                    border-bottom
                ">
                    {text}
                </th>
            );
        }
    
        function genericHeader(text) {
            return (
                <th className="
                    col-2
                    
                    px-1
                    text-right
    
                    border-right
                    border-top
                    border-bottom
                ">
                    {text}
                </th>
            );
        }
    
        function biggerEntry(content) {
            return (
                <th className="col-4 border-right border-bottom px-1">
                    {content}
                </th>
            );
        }
    
        function genericEntry(content) {
            return (
                <th className="col-2 border-right border-bottom px-1">
                    {content}
                </th>
            );
        }
    }


    function productNameFn() {
        return (
            <TextInputComponent
                value={name}
                onChange={setName}
            />
        );
    }

    function amountFn() {
        return (
            <RangeInputComponent
                value={amount}
                onChange={setAmount}

                min={1}
                max={10000}
                step={1}
            />
        );
    }

    function priceFn() {
        return (
            <FloatRangeComponent
                value={price}
                onChange={setPrice}
                max={10000}
            />
        );
    }


    function productTypeFn() {
        return (
            <CustomDropdown
                options={options()}
                value={productType}
                onChange={setProductType}
                sortAlphabetically
            /> 
        );

        function options() {
            return products.map(product => 
                CustomDropdown.buildOption(
                    product.productType,
                    product.name,
                )
            );
        }
    }

    function discountFn() {
        return (
            <RangeInputComponent
                onChange={setDiscount}
                min={0}
                max={1}
                isDecimalPercentage={true}
                value={discount}
                step={0.01}
            />
        );
    }

    function saveButton() {
        if (!onSave)
            return null;

        return (
            <div>
                <CapsuleButton
                    text={saveButtonName}
                    onClick={onSave}
                    size={'SMALL'}
                />
            </div>
        );
    }
}