import {
    EXISTING_GROUP_TYPES_COMPATIBLE_WITH_CARD_TYPE,
    INVITATION_CARD_TYPES,
    NEW_GROUP_TYPE_FOR_CARD_TYPE,
} from "../_constants/invitationCard.constants";
import { InvitationGroup } from "../_models/InvitationGroup";

export const InvitationCardHelper = {
    getGroupsCompatibleWithCard,
    canBeDisassociated,
    getNewGroupType,
};

function getGroupsCompatibleWithCard(groups = [] || [InvitationGroup()], cardType) {
    const compatibleGroupTypes = getExistingCompatibleGroupTypes(cardType);
    return groups.filter(group => compatibleGroupTypes.includes(group.groupType));
}

function getNewGroupType(cardType) {
    return NEW_GROUP_TYPE_FOR_CARD_TYPE[cardType] || undefined;
}

function getExistingCompatibleGroupTypes(cardType) {
    return EXISTING_GROUP_TYPES_COMPATIBLE_WITH_CARD_TYPE[cardType] || [];
}

function canBeDisassociated(cardType) {
    return [INVITATION_CARD_TYPES.UNLIMITED, INVITATION_CARD_TYPES.NFC].includes(cardType);
}