import React from "react";

export function ContentSpaceGeneric({ testID, children }) {

    //VIEW
    return (
        <div data-testid={testID}
            className="
                h-100
                w-100
                
                d-flex
                position-relative
                overflow-hidden
            "
        >
            {children}
        </div>
    );
}