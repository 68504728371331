import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { WEBAPP_URLS } from '../_constants';

export const history = createBrowserHistory();

export class HistoryHelper {

    static getSubdomain() {
        const domains = window.location.hostname.split('.');
        return domains.length > 1
            ? domains[0]
            : undefined
        ;
    }

    static getLoginData() {
        return {
            email: getFromQuery(QUERY_VALUES.EMAIL),
            code: getFromQuery(QUERY_VALUES.CODE),
            secret: getFromQuery(QUERY_VALUES.SECRET),
        };
    }

    static getOauthData() {
        return getFromQuery(QUERY_VALUES.NEXT);
    }

    static getCurrentUrlFull() {
        return document.location.href;
    }

    static getCurrentUrlFullNoParams() {
        return document.location.origin + document.location.pathname;
    }

    static getCurrentUrl() {
        return history.location.pathname;
    }

    static pushToUrl(endPoint) {
        history.push(endPoint);
    }

    static updateDeviceQuery(deviceUid, locationUid, deviceFilterMode) {
        updateMultiple({
            [QUERY_VALUES.DEVICE_UID]: deviceUid,
            [QUERY_VALUES.LOCATION_UID]: locationUid,
            [QUERY_VALUES.DEVICE_FILTER_MODE]: deviceFilterMode,
        });
    }

    static getDeviceUid() {
        return getFromQuery(QUERY_VALUES.DEVICE_UID);
    }

    static getDeviceFilterMode() {
        return getFromQuery(QUERY_VALUES.DEVICE_FILTER_MODE);
    }

    static getLocationUid() {
        return getFromQuery(QUERY_VALUES.LOCATION_UID);
    }

    static goToRegister() {
        pushUrlAndParam(WEBAPP_URLS.REGISTER);
    }

    static goToRoot() {
        pushUrlAndParam(WEBAPP_URLS.ROOT);
    }

    static resetUrl() {
        history.push(
            WEBAPP_URLS.ROOT,
            {
                search: undefined,
            },
        )
    }
}

function pushUrlAndParam(endPoint, paramName, paramValue) {
    history.push(
        {
            pathname: endPoint,
            search: queryString.stringify({
                [paramName]: paramValue,
            }),
        },
    )
}

function updateMultiple(object) {
    const query = queryString.parse(history.location.search);
    const newQuery = {
        ...query,
        ...object,
    }
    history.push({
        search:  queryString.stringify(newQuery),
    })
}

function getFromQuery(parameterName) {
    const parsedQuery = queryString.parse(history.location.search);
    return parsedQuery[parameterName];
}

export const QUERY_VALUES = {
    DEVICE_FILTER_MODE: "filter",
    DEVICE_UID: "device",
    LOCATION_UID: "location",
    EMAIL: "email",
    CODE: "login_code",
    SECRET: "login_secret",
    NEXT: "next",
};