export function Card({
    code,
    state,
    cardPackUid,
    invitationGroupUid,
    invitationGroupName,
    type,
    maxDuration,
    isConfiguration
} = {}) {
    return {
        code,
        state,
        cardPackUid,
        invitationGroupUid,
        invitationGroupName,
        type,
        maxDuration,
        isConfiguration
    };
}