import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { WeekdaysModel } from "../_models/WeekdaysModel";
import { ReservationConfig } from "../_models/ReservationConfig";
import { reservationActions } from "../_actions/reservation.actions";
import { SimpleDataBlock } from "../_components/SimpleDataBlock";
import { globalActions } from "../_actions";
import { GLOBAL_POPUPS } from "../_constants";
import { CapsuleButton } from "../_components/CapsuleButton";
import { MainPageWithButtons, WeekAndTimeSelector } from "../_components";
import { Selectors } from "../_reducers/app.reducer";
import { CalendarDaysSelector } from "../_components/CalendarDaysSelector";

const testIDs = {
    configView: "reservations-config-view",
    saveButton: "reservations-config-view-save-button",
    scheduleButtom: "reservations-config-view-schedule-button",
    capacityInput: "reservations-config-capacity-input",
};

ReservationConfigView.testIDs = testIDs;

export function ReservationConfigView() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let reservationConfig = ReservationConfig();
    reservationConfig = useSelector(Selectors.getReservationSelected);

    //VIEW
    return (
        <MainPageWithButtons
            dataTestId={testIDs.configView}
            size={'MEDIUM'}
            pageTitle={titleText()}
            content={reservationConfiguration()}
            buttons={[
                deleteButton(),
                ...editButtons(),
                goToScheduleButton(),
            ]}
        />
    );

    function titleText() {
        return t("settings.settings") + " - " + reservationConfig.roomName;
    }

    function deleteButton() {
        if (reservationConfig.isEdited)
            return null;

        return (
            <CapsuleButton
                text={t("common.delete")}
                style={`DANGER`}
                isVisible={isDeleteButtonVisible()}
                onClick={handleDeleteConfig}
            />
        );
        
        function isDeleteButtonVisible() {
            if (!reservationConfig.isSetUp)
                return false;

            return true;
        }
    }

    function goToScheduleButton() {
        if (reservationConfig.isEdited)
            return null;

        if (!reservationConfig.isSetUp)
            return null;
            
        return (
            <CapsuleButton
                testId={testIDs.scheduleButtom}
                onClick={handleGoToSchedule}
                text={t("common.schedule")}
            />
        );
    }

    function editButtons() {
        if (!reservationConfig.isEdited)
            return [];

        return [
            discardButton(),
            saveButton(),
        ];
        

        //
        function saveButton() {
            if (!isVisible())
                return null;

            return (
                <CapsuleButton
                    testId={ReservationConfigView.testIDs.saveButton}
                    onClick={handleSaveConfig}
                    text={text()}
                />
            );

            ////
            function text() {
                return reservationConfig.isSetUp
                    ? t("common.save")
                    : t("common.create");
            }

            function isVisible() {
                if (!reservationConfig.monday
                    && !reservationConfig.tuesday
                    && !reservationConfig.wednesday
                    && !reservationConfig.thursday
                    && !reservationConfig.friday
                    && !reservationConfig.saturday
                    && !reservationConfig.sunday
                ) return false;
        
                if (!reservationConfig.allDay && !(reservationConfig.begin && reservationConfig.end))
                    return false;
        
                return true;
            }
        }

        function discardButton() {
            return (
                <CapsuleButton
                    onClick={handleDiscardConfig}
                    text={t("common.discard")}
                />
            );
        }
    }

    function reservationConfiguration() {
        return (
            <div className="h-200 d-flex flex-column">
                <div className="mt-2"/>
                <SimpleDataBlock
                    title={t("common.room")}
                    value={reservationConfig.roomName}
                />
                <div className="mt-2"/>
                <WeekAndTimeSelector
                    allDay={reservationConfig.allDay}
                    begin={reservationConfig.begin}
                    end={reservationConfig.end}
                    weekdays={WeekdaysModel(reservationConfig)}

                    handleEndDateChange={handleEndDateChange}
                    handleStartDateChange={handleBeginDateChange}
                    handleToggleAllDay={handleToggleAllDay}
                    handleToggleWeekDay={handleToggleWeekDay}
                    timeIntervals={30}
                />
                <div className="mt-2"/>
                {roomCapacitySection()}
                <div className="mt-3"/>
                {excludedDaysSection()}
            </div>
        );
    }

    function roomCapacitySection() {
        return (
            <div className="d-flex flex-column overflow-auto">
                <div className="font-weight-bold text-uppercase">
                    {t("mod_reservations_capacity")}
                </div>
                <div className="border-top"/>
                <div className="overflow-auto">
                    <div className="
                        d-flex
                        justify-content-between

                        mt-2
                    ">
                        {t("invitations.numberOfUsers")}
                        <input
                            data-testid={testIDs.capacityInput}
                            onChange={(e) => handleChangeCapacity(e.target.value)}
                            value={reservationConfig.numberOfUsers}
                            type="number"
                            readOnly={false}
                            className="
                                rounded
                                border
                                text-right
                            "
                        />
                    </div>
                </div>
            </div>
        );
    }

    function excludedDaysSection() {
        return (
            <div className="d-flex flex-column overflow-auto">
                <div className="font-weight-bold text-uppercase">
                    {t("mod_reservations_excluded_days")}
                </div>
                <div className="border-top"/>
                <div className="overflow-auto">
                    <CalendarDaysSelector
                        selectedDates={reservationConfig.excludedDays}
                        handleToggleDate={handleToggleExcludedDay}
                    />
                </div>
            </div>
        );
    }
    
    //ACTION HANDLERS
    function handleToggleExcludedDay(excludedDay) {
        dispatch(reservationActions.toggleExcludedDay(excludedDay));
    }

    function handleGoToSchedule() {
        dispatch(reservationActions.selectScheduleView());
    }

    function handleEndDateChange(end) {
        dispatch(reservationActions.updateSelectedDate({ end }));
    }

    function handleBeginDateChange(begin) {
        dispatch(reservationActions.updateSelectedDate({ begin }));
    }
    
    function handleToggleWeekDay(weekday) {
        dispatch(reservationActions.toggleSelectedWeekday(weekday));
    }

    function handleSaveConfig() {
        if (reservationConfig.isSetUp) {
            dispatch(globalActions.showPopUp(GLOBAL_POPUPS.UPDATE_RESERVATION_CONFIG))
            return;
        }

        dispatch(reservationActions.saveReservationConfig());
    }

    function handleDeleteConfig() {
        dispatch(globalActions.showPopUp(GLOBAL_POPUPS.DELETE_RESERVATION_CONFIG));
    }

    function handleDiscardConfig() {
        dispatch(reservationActions.discardChanges());
    }

    function handleToggleAllDay() {
        dispatch(reservationActions.toggleAllday());
    }

    function handleChangeCapacity(capacity) {
        dispatch(reservationActions.updateCapacity(capacity));
     }
}