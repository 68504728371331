import { UserStatusUsageData } from "../_models/UserStatusUsageData";

export class UserStatusUsageDataMapper {
    static serverToLocal(serverUserStatusUsageData) {
        return UserStatusUsageData({
            isWorkingProperly: serverUserStatusUsageData.is_working_properly,
            manualOpen: serverUserStatusUsageData.manual_open,
            automaticExit: serverUserStatusUsageData.automatic_exit,
            automaticOpen: serverUserStatusUsageData.automatic_open,
        });
    }
}