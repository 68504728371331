import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_POPUPS } from "../_constants";
import { globalActions } from "../_actions";
import { ContentSpace } from "../_components";
import { ProtectLogs } from "./ProtectLogs";
import { ProtectSettings } from "./ProtectSettings";
import { ProtectUsers } from "./ProtectUsers";
import { ProtectNoBrainPage } from "./ProtectNoBrainPage";
import { ProtectMainPage } from "./ProtectMainPage";
import { PROTECT_SECONDARY_VIEWS } from "../_constants/protect.constants";
import { protectActions } from "../_actions/protect.actions";
import { Selectors } from "../_reducers/app.reducer";
import { LocationInfo } from "../_models/LocationInfo";
import { useTranslation } from "react-i18next";
import { InfoContent } from "../_components/InfoContent";
import { MainPageWithButtons, CapsuleButton } from "../../Common/_components";
import subscriptionsBigIcon from "../_assets/appIcons/subscriptions-icon-big.png";
import { appPermissions } from "../_constants/permissions.constants";
import { PaymentMethodsActions } from "../_actions/PaymentMethodsActions";
import { SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPES } from "../_constants/subscriptions.constants";
import { DeviceHelper } from "../_helpers";

export function ProtectModule() {
    const dispatch = useDispatch();


    // LIBS
    const { t } = useTranslation();

    //GLOBAL STATE
    const devices       = useSelector(Selectors.getSelectedLocationDevices);
    let locationInfo    = LocationInfo();
    locationInfo        = useSelector(Selectors.getLocationInfo);
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);
    const triggeredLogs = useSelector(Selectors.getProtectTriggeredLogs);
    const secondaryView = useSelector(Selectors.getProtectModuleSecondaryView);
    const paymentMethod = useSelector(Selectors.getPaymentMethod);
    const permission    = useSelector(Selectors.getPermissionSelectedLocation);
    const canManagePayments =
        appPermissions.canManageSubscriptionPayments(permission);
    
    const protectSubscription = useSelector(Selectors.getSubscriptions)?.find(
        ({ type }) => type === SUBSCRIPTION_TYPES.PROTECT,
    );

    //INITIALIZATION
    useEffect(() => {
        if (!canManagePayments || paymentMethod) return;

        dispatch(PaymentMethodsActions.list());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, canManagePayments, locationUid]);


    //ACTION HANDLERS
    const onGoBack = useCallback(() => {
        dispatch(protectActions.setSecondaryView(null))
    }, [dispatch]);

    const handleSubscribe = useCallback(
        () =>
            dispatch(
                globalActions.showPopUp(
                    GLOBAL_POPUPS.PROTECT_SUSCRIPTION_CONFIRM,
                ),
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [locationUid, paymentMethod],
    );

    //VIEW
    if (locationInfo.hasProtectSubscription || protectSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE) {
        return DeviceHelper.isThereBrain(devices) ? (
            fullProtectPage()
        ) : (
            <ProtectNoBrainPage />
        );
    } else {
        return contentNotSubscribed();
    }

    function contentNotSubscribed() {
        const info = <InfoContent
                icon={{ src: subscriptionsBigIcon }}
                title={t('subscription_protect_not_subscribed_title')}
                buttons={[
                    <CapsuleButton
                        key={'global_subscribe'}
                        text={t('global_subscribe')}
                        onClick={handleSubscribe}
                    />,
                ]}
            >
                <div className="text-center">
                    {t('subscription_protect_not_subscribed_description')}
                </div>
            </InfoContent>

        return <ContentSpace
                mainContent={
                    <MainPageWithButtons
                        pageTitle={t("protect.protect")}
                        content={info}
                    />
                }
            />
    }

    function fullProtectPage() {
        return (
            <ContentSpace
                mainContent={
                    <ProtectMainPage
                        devices={devices}
                        locationInfo={locationInfo}
                        triggeredLogs={triggeredLogs}
                    />
                }
                detailedContent={detailedContent()}
                isDetailedFolioEnabled={
                    secondaryView !== null
                }
                onGoBack={onGoBack}
            />
        );
    }

    function detailedContent() {
        switch (secondaryView) {
            case PROTECT_SECONDARY_VIEWS.LOGS:
                return <ProtectLogs />;

            case PROTECT_SECONDARY_VIEWS.SETTINGS:
                return <ProtectSettings />;

            case PROTECT_SECONDARY_VIEWS.USERS:
                return <ProtectUsers />;

            default:
                return null;
        }
    }
}