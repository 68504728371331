import React, { useCallback, useState, useEffect } from 'react';
import { ValidationHelper } from '../../Everou/_helpers';

import { ValidationWarnings } from "../_components/ValidationWarnings";

const fn = () => {};
const array = [];

export function useValidationInput({
    isRequired = false,
    setIsValid = fn,
    handleSetValue = fn,
    forceWarnings = false,
    warningFns = array,
    value,
}) {
    const [warnings, setWarnings] = useState([]);
    const [showWarnings, setShowWarnings] = useState(false);

    const handleSetWiredValue = useCallback(newValue => {
        setShowWarnings(true);
        handleSetValue(newValue);
    }, [handleSetValue]);

    useEffect(() => {
        if (!isRequired && !value) {
            setWarnings([]);
            return;
        }

        setWarnings(getWarnings(value, warningFns, isRequired));
    }, [isRequired, warningFns, value]);

    useEffect(() => {
        setIsValid(!warnings.length);
    }, [setIsValid, warnings]);

    return {
        handleSetWiredValue,
        WarningsComponent: () => <ValidationWarnings
            showWarnings={forceWarnings || showWarnings}
            warningTexts={warnings}
        />,
        warnings,
    };
}

function getWarnings(value, warningFns = [], isRequired) {
    const allWarningFns = [...warningFns];
    isRequired && allWarningFns.push(ValidationHelper.UiValidationFns.required);
    
    return allWarningFns.reduce((warnings, warningFn) => {
        const warning = warningFn(value);
        warning && warnings.push(warning);
        return warnings;
    }, []);
}