function CardInPack({ cardCode, shareUrl } = {}) {
    return {
        cardCode,
        shareUrl,
    };
}

export function CardPack({
    uid,
    name,
    totalCards,
    usedCards,
    cardType,
    unusedCards = [] || [CardInPack()]
} = {}) {
    return {
        uid,
        name,
        totalCards,
        usedCards,
        cardType,
        unusedCards
    };
}