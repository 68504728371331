import { CSSProperties, useEffect, useState } from "react";


type PaginatorProps = {
    total: number;
    pageOffset?: number;
    pageSize: number;
    onChangePage: ( page: number ) => void;
}


const button: CSSProperties = {
    cursor: 'pointer',
    padding: '4px 8px 4px 8px',
}

export function Paginator({ total, pageSize, pageOffset = 0, onChangePage }: PaginatorProps) {

    const getTotalPages = (total, pageSize) => total ? Math.ceil(total / (pageSize ?? 1)) : 1;
    const [totalPages, setTotalPages] = useState(0) 
    const [currentPage, setCurrentPage] = useState(0);
    const showLeftArrow = currentPage > 0;
    const showRightArrow = currentPage < totalPages - 1;

    const increment = () => {
        if (currentPage === totalPages) {
            setTotalPages(totalPages + 1);
        }
        const next = currentPage + 1;
        onChangePage(next);
        setCurrentPage(next);
    }

    const decrement = () => {
        const prev = currentPage - 1;
        onChangePage(prev);
        setCurrentPage(prev)};

    useEffect(() => setCurrentPage(pageOffset), [pageOffset, pageSize, total]);
    useEffect(() => { setTotalPages(getTotalPages(total, pageSize)) }, [pageSize, total]);
    
    return <div className="d-flex justify-content-center">
        <div className="border c-rounded-big d-flex">

            { showLeftArrow && <div onClick={decrement} className="c-hover-240 c-rounded-big-left" style={button}>{'<'}</div> }
                <div className={`${!showLeftArrow ? 'c-rounded-big-left' : ''} ${!showRightArrow ? 'c-rounded-big-right' : ''} c-hover-240`} style={button}>{currentPage + 1}</div>
            { showRightArrow && <div onClick={increment} className="c-hover-240 c-rounded-big-right" style={button}>{'>'}</div> }
        </div>
    </div>
}