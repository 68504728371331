export function LocationCustomization({
    logo,
    loginBackground,
    accentColor,
    headerColor,
} = {}) {
    return {
        logo,
        loginBackground,
        accentColor,
        headerColor,
    };
}