import React from 'react';

import { InputBox } from './InputBox';
import { CapsuleButton } from '../_components/CapsuleButton';
import { useTranslation } from 'react-i18next';

import emailIcon from '../_assets/customIcons/at-icon.svg';

export function EmailLogin({
    inputTestID,
    buttonTestID,
    email = "",
    setEmail = () => {},
    handleRequestCode = () => {},
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL HANDLERS
    function handleChangeEmail(e) {
        setEmail(e.target.value);
    }

    function handleSubmit() {
        if (email)
            handleRequestCode(email);
    }

    //VIEW
    return (
        <div>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
                <InputBox
                    inputTestID={inputTestID}
                    value={email}
                    handleChange={handleChangeEmail}
                    imgSrc={emailIcon}
                    placeholder={t("global_email")}
                />

                {/* input needed for enter key to work */}
                <input type="submit" className="d-none"/>
            </form>

            <div className="mt-3"/>

            <CapsuleButton
                testId={buttonTestID}
                text={t("login_with_email")}
                onClick={handleSubmit}
                fillStyle={'FILLED'}
                textUppercase={false}
                classNameExtra='w-100'
            />
        </div>
    );
}