import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from "../_components";
import { ReservationConfig } from "../_models/ReservationConfig";
import { reservationActions } from "../_actions/reservation.actions";
import { getRoomTypeIcon } from "../_assets/roomTypeIcons";
import { TimeStringHelper } from "../_helpers/TimeStringHelper";
import { WeekdaysModel } from "../_models/WeekdaysModel";
import { TimeHelper } from "../_helpers";

import subscriptionsBigIcon from "../_assets/appIcons/subscriptions-icon-big.png";
import { ListedGroup } from "../_components/ListedGroup";
import { Selectors } from "../_reducers/app.reducer";


const testIDs = {
    roomsView: "reservation-rooms-view",
    listedReservationRoom: "listed-reservation-room",
    listedRoomConfigured: "listed-reservation-room-set-up",
};

ReservationsRooms.testIDs = testIDs;

export function ReservationsRooms() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);
    let selected        = ReservationConfig();
    selected            = useSelector(Selectors.getReservationSelected);

    let configurations  = [ReservationConfig()];
    configurations      = useSelector(Selectors.getReservationConfigurations);
    const timezone      = useSelector(Selectors.getTimezone);
    
    //INITIALIZATION
    useEffect(() => {
        dispatch(reservationActions.getConfigurations());
    },
    [dispatch, locationUid]);

    //ACTION HANDLERS
    function handleSelectConfig(configuration = ReservationConfig()) {
        dispatch(reservationActions.updateSelectedScheduleDate(TimeHelper.getTodayIsoString()));
        dispatch(reservationActions.selectReservationConfig(configuration));
    }

    //VIEW
    return (
        <MainPageWithButtons
            dataTestId={testIDs.roomsView}
            pageTitle={t("common.reservations")}
            content={content()}
        />
    );

    function content(){
        return configurations.length === 0
            ? noReservationsPlaceholder()
            : roomList()
        ;
    }

    function roomList(){
        return (
            <div className="mt-2">
                <ListedGroup
                    headerText={t("reservations.availableRooms")}
                    elements={configurations.map(singleRoomConfig)}
                />
            </div>
        );
    }

    function noReservationsPlaceholder() {
        return (
            <div className="
                d-flex
                flex-column
                no-gutters
                justify-content-center

                align-items-center
            ">
                <img
                    src={subscriptionsBigIcon}
                    alt={"subscriptions icon big"}
                    className="
                        col-3
                        c-opacity-50
                    "
                />
                <div className="text-center">
                    {t("reservations.reservationsNotEnabled")}
                </div>
            </div>
        );
    }

    function singleRoomConfig(configuration = ReservationConfig()) {
        return (
            <div
                data-testid={testIDs.listedReservationRoom}
                onClick={() => handleSelectConfig(configuration)}
                key={configuration.roomUid}
                className={`
                    p-2
                    cp
                    ${selected && selected.roomUid === configuration.roomUid
                        ? "c-bg-primary-light-2"
                        : "c-hover-240"
                    }
                `}
            >
                {roomName()}
                {message()}
                {nextReservation()}
            </div>
        );

        function roomName() {
            return (
                <div className="d-flex">
                    <img
                        className="c-img-1-5rem"
                        src={getRoomTypeIcon(configuration.roomType)}
                        alt={"options icon"}
                    />
                    <div className="ml-1"/>
                    <div className="font-weight-bold text-capitalize">
                        {configuration.roomName.toString()}
                    </div>
                </div>
            );
        }

        function message() {
            return configuration.isSetUp
                ? setUpMessage()
                : notSetUpMessage();

            function notSetUpMessage() {
                return (
                    <div className="text-capitalize">
                        {t("common.notConfigured")}
                    </div>
                );
            }

            function setUpMessage() {
                return (
                    <div data-testid={testIDs.listedRoomConfigured}
                        className="c-text-capitalize-first">
                        {TimeStringHelper.buildReservationConfigString(
                            configuration.allDay,
                            configuration.begin,
                            configuration.end,
                            WeekdaysModel(configuration),
                        )}
                    </div>
                );
            }
        }

        function nextReservation() {
            const pendingReservation = configuration.pendingReservation;
            if (!pendingReservation)
                return null;

            return (
                <div className="
                    c-text-capitalize-first
                    c-text-primary
                ">
                    {t("reservations.youHaveBooked")}
                    {" "}
                    {TimeHelper.localizeIsoStringToFormat(
                        pendingReservation.begin,
                        timezone,
                        "LLLL",
                    )}
                </div>
            );
        }
    }
}