import React, { useState, useEffect } from 'react';

import { TimeHelper } from '../_helpers';
import { LocalizedDatePicker } from './LocalizedDatePicker';

export function CalendarDaysSelector({
    handleToggleDate = (isoDate) => {},
    selectedDates = [],
} = {}) {

    //HACK TO STOP CALENDAR FROM JUMPING AROUND ON SELECT DATE
    //No date is really needed but is required to prevent aberrant behaviour
    const [date, setDate] = useState();
    useEffect(() => {
        if (date)
            setDate();
    }, [date]);

    //VIEW
    return (
        <div className="c-calendar-wrapper">
            <LocalizedDatePicker
                selected={date}
                onChange={setDate}
                
                monthsShown={12}
                inline

                onSelect={handleSelect}
                highlightDates={processedHighlightedDates()}
            />
        </div>
    );

    function processedHighlightedDates() {
        return selectedDates.map(jsDate =>
            TimeHelper.isoDateToLocalJsDate(jsDate)
        );
    }

    function handleSelect(localJsDate) {
        handleToggleDate(localJsDateToIsoDate(localJsDate));
    }

    function localJsDateToIsoDate(jsDate) {
        return new Date(
            Date.UTC(
                jsDate.getFullYear(),
                jsDate.getMonth(),
                jsDate.getDate(),
            )
        ).toISOString();
    }
}