import { useTranslation } from "react-i18next";
import ToolTipWrapper from "./ToolTipWrapper";

type SwitcherButtonProps = {
    icon?: string | null;
    size: 'SMALL' | 'MEDIUM';
    className?: string;
    onClick?: () => void;
    remote?: boolean;
}

export function SwitcherButton({ icon, size = "MEDIUM", className = '', onClick = () => {}, remote }: SwitcherButtonProps) {

        const { t } = useTranslation();

        return (
            <ToolTipWrapper tooltip={remote ? false : t('mod_devices_must_enable_remote_access_to_action_device')}>
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        onClick();
                    }}
                    style={{
                        border: "2px solid white",
                        boxShadow: remote
                            ? "0 0 0 2px var(--colorPrimary)"
                            : "0 0 0 2px var(--colorGray220)",
                    }}
                    className={`${className} ${
                        size === "MEDIUM" ? "c-img-2rem" : "c-img-1-5rem"
                    } ${
                        remote
                            ? "c-bg-primary c-hover-scale-1-1x cp"
                            : "c-bg-grey-220 cna"
                    }  rounded-circle d-flex align-items-center c-transition-all justify-content-center`}
                >
                    {icon && (
                        <img
                            style={{ filter: "invert(1)" }}
                            className={`${
                                size === "MEDIUM"
                                    ? "c-img-1-25rem"
                                    : "c-img-0-6rem"
                            }`}
                            alt="action"
                            src={icon}
                        />
                    )}
                </div>
            </ToolTipWrapper>
        );
    };

