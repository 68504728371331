import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { devicesActions, globalActions, locationActions } from '../_actions';
import { MainPageWithButtons, ContentSpace, SimpleToggle, FileUploadComponent } from '../_components';
import { GLOBAL_POPUPS } from '../_constants';
import { LocationInfo } from '../_models/LocationInfo';
import "leaflet/dist/leaflet.css";
import { appPermissions } from '../_constants/permissions.constants';
import { CapsuleButton } from '../_components/CapsuleButton';
import { locationInfoActions } from '../_actions/locationInfo.actions';
import { TimeStringHelper } from '../_helpers/TimeStringHelper';
import { ReadOnlyDataRow } from '../_components/ReadOnlyDataRow';
import { MapHelper } from './MapHelper';
import { TechnicalServiceModule } from '../TechnicalServiceModule/TechnicalServiceModule';
import { Selectors } from '../_reducers/app.reducer';
import { ValidatedTextInput } from '../../Common/_components/ValidatedTextInput';
import { InlineEditButtons } from '../_components/InlineEditButtons';
import { MyInvitationsComponent } from './MyInvitationsComponent';
import { ValidationHelper} from '../_helpers';
import { EverouModulesActions } from '../_stores/ModulesStore/EverouModulesActions';
import { BARRIER_MODES } from '../_constants/barriers.constants';
import { WaitSpinner } from "../_components/WaitSpinner";

const TEST_IDS = {
    nameSection: "location-config-name-section",
    nameSaveButton: "location-config-name-save-button",
    nameInput: "location-config-name-input",

    companyNameSection: "location-config-company-name-section",
    companyNameSaveButton: "location-config-company-name-save-button",
    companyNameInput: "location-config-company-name-input",

    companyContactEmailSection: "location-config-company-contact-email-section",
    companyContactEmailSaveButton: "location-config-company-contact-email-save-button",
    companyContactEmailInput: "location-config-company-contact-email-input",

    companyLegalIdSection: "location-config-company-legal-id-section",
    companyLegalIdSaveButton: "location-config-company-legal-id-save-button",
    companyLegalIdInput: "location-config-company-legal-id-input",

    companyAdditionalInfoSection: "location-config-company-additional-info-section",
    companyAdditionalInfoSaveButton: "location-config-company-additional-info-save-button",
    companyAdditionalInfoInput: "location-config-company-additional-info-input",
};

SettingsModule.TEST_IDS = TEST_IDS;

export function SettingsModule() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let locationInfo    = LocationInfo();
    locationInfo        = useSelector(Selectors.getLocationInfo);
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);
    const [isBarrierEnabled, setIsBarrierEnabled] = useState(locationInfo.isBarrierEnabled);
    //LOCAL STATE
    const [map, setMap] = useState();

    const permission    = useSelector(Selectors.getPermissionSelectedLocation);
    const canViewCompanyData = appPermissions.canViewLocationCompanyData(
        permission,
    );
    
    const canToggleBarrierSystem = appPermissions.canManageBarriers(
        permission,
    );

    const canAddVacationPromotionalVideo = appPermissions.canManageVacationPromotional(
        permission,
    );

    const invitation = useMemo(() => locationInfo?.invitationData, [locationInfo?.invitationData])

    //ACTION HANDLERS
    const handleRemoveCurrentLocation = useCallback(() => {
        dispatch(globalActions.showPopUp(GLOBAL_POPUPS.DELETE_LOCATION, { location: locationInfo }));
    }, [dispatch, locationInfo]);

    const onChangeBarrierMode = useCallback(async enabled => {
        try {
            setIsBarrierEnabled(enabled);
            await dispatch(locationActions.updateBarrierMode(enabled ? BARRIER_MODES.ENABLED : BARRIER_MODES.DISABLED, locationUid));
            await dispatch(devicesActions.getDevicesFromLocation(locationUid));
            dispatch(EverouModulesActions.buildModules());
        } catch {
            setIsBarrierEnabled(!enabled);
        }
    }, [dispatch, locationUid]);

    useEffect(() => {
        setIsBarrierEnabled(locationInfo.isBarrierEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[locationUid])

    function handleUploadVideo(video) {
        dispatch(locationActions.uploadVideo(video, locationInfo.uid));
    }

    //INITIALIZATION
    useEffect(() => {
        initialize();

        async function initialize() {
            if (map)
                map.remove();

            const newMap = MapHelper.createMap({
                id: "map",
                latitude: locationInfo.latitude,
                longitude: locationInfo.longitude,
                radius: locationInfo.radius,
            });

            setMap(newMap);

            if(!invitation) {
                await dispatch(locationInfoActions.getCreationInvitation(
                    locationInfo.invitationUid,
                ));
            } 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid]);


    //VIEW
    return (
        <ContentSpace
            mainContent={mainContent()}
        />
    );
    
    function mainContent() {
        return (
            <MainPageWithButtons
                pageTitle={t("settings.locationSettings")}
                content={content()}
                buttons={[
                    <CapsuleButton
                        testId={"cancel-invitation-button"}
                        text={appPermissions.canUserDeleteLocation(locationInfo.locationPermission)
                            ? t("settings.deleteLocation")
                            : t("settings.cancelInvitation")
                        }
                        onClick={handleRemoveCurrentLocation}
                        style={`DANGER`}
                    />
                ]}
            />
        );
    }

    function content() {
        const yes = t("global_yes");
        const no = t("global_no");
        return (
            <div
                className="
                h-100
                d-flex
                no-gutters
                flex-column
            "
            >
                {invitation && invitationInfo()}
                <NameInput
                    {...{
                        key: locationInfo.locationName,
                        initialValue: locationInfo.locationName,
                        locationUid,
                    }}
                />
                {dataRow(t("common.timezone"), locationInfo.timezone)}
                {dataRow(
                    t("settings.notificationsEnabled"),
                    locationInfo.isNotificationActive ? yes : no,
                )}
                {dataRow(
                    t("settings.shareData"),
                    locationInfo.shareData ? yes : no,
                )}

                {locationInfo.latitude
                    ? dataRow(
                          t("settings.detectionZone"),
                          `${locationInfo.radius}m`,
                      )
                    : dataRow(
                          t("settings.detectionZone"),
                          t("common.notConfigured"),
                      )}

                {dataRow("Id", locationInfo.locationId)}
                {canToggleBarrierSystem && (dataRow(
                    t("mod_barriers_title"),
                    <SimpleToggle
                        handleChange={({ detail: { value } }) =>
                            onChangeBarrierMode(value)
                        }
                        checked={isBarrierEnabled}
                    />,
                ))}

                {canAddVacationPromotionalVideo && 
                <>
                    <div className="mt-3" />
                    {imageUploadSection(
                        "Video explicativo",
                        handleUploadVideo,
                        false,
                        locationInfo.videoName,
                        locationInfo.videoUrl
                    )}
                </>
                }

                {canViewCompanyData && (
                    <>
                        <div className="mt-3" />

                        <div
                            className="
                        c-new-ft-m
                        text-uppercase
                        font-weight-bold
                        pt-2
                    "
                        >
                            {t("mod_clockins_company_settings")}
                        </div>

                        <CompanyNameInput
                            {...{
                                key: locationUid + "_company_name",
                                initialValue: locationInfo.companyName,
                                locationUid,
                            }}
                        />
                        <CompanyContactEmailInput
                            {...{
                                key: locationUid + "_company_contact_email",
                                initialValue: locationInfo.companyContactEmail,
                                locationUid,
                            }}
                        />
                        <CompanyLegalIdInput
                            {...{
                                key: locationUid + "_company_legal_id",
                                initialValue: locationInfo.companyCIF,
                                locationUid,
                            }}
                        />
                        <CompanyAdditionalInfoInput
                            {...{
                                key: locationUid + "_company_additional_info",
                                initialValue:
                                    locationInfo.companyAdditionalInfo,
                                locationUid,
                            }}
                        />

                        <div
                            className="
                        pt-2
                    "
                        >
                            {t("mod_clockin_user_disclaimer")}
                        </div>
                    </>
                )}

                <div className="mt-3" />

                <MyInvitationsComponent key={locationUid} />

                <TechnicalServiceModule />

                {locationInfo.latitude && (
                    <>
                        <div className="mt-3" />
                        <div
                            id="map"
                            className="
                        flex-fill
                        d-flex
                        flex-column
                        border
                    "
                        />
                    </>
                )}
            </div>
        );
    }

    function invitationInfo() {

        return (
            <div data-testid="invitation-info">
                {sourceUserEmail()}
                {periodString()}
            </div>
        );

        ////
        function sourceUserEmail() {
            return dataRow(
                t("invitations.invitedBy"),
                invitation.userSrcEmail,
            );
        }

        function periodString() {
            return dataRow(
                t("common.period"),
                data(),
            );

            function data() {
                return TimeStringHelper.buildInvitationTimeString(
                    invitation,
                    locationInfo.timezone,
                );
            }
        }
    }

    function dataRow(name, data) {
        return <ReadOnlyDataRow
            name={name}
            data={data}
        />;
    }
}

function NameInput({ initialValue, locationUid }) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HADLERS
    function handleSave(text) {
        dispatch(locationActions.updateLocationName(text, locationUid));
    }

    return (
        <DataWrapper hasSeparator={false}>
            <div
                data-testid={TEST_IDS.nameSection}
                className="d-flex align-items-center justify-content-between"
            >
                <div>
                    {t("global_name")}
                </div>
                <InlineValidatedTextInput
                    inputTestID={TEST_IDS.nameInput}
                    saveButtonTestID={TEST_IDS.nameSaveButton}
                    handleSave={handleSave}
                    initialValue={initialValue}
                    required={true}
                />
            </div>
        </DataWrapper>
    );
}

function CompanyNameInput({ initialValue, locationUid }) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HADLERS
    function handleSave(text) {
        dispatch(locationActions.updateCompanyName(text, locationUid));
    }

    return (
        <DataWrapper>
            <div
                data-testid={TEST_IDS.companyNameSection}
                className="d-flex align-items-center justify-content-between"
            >
                <div>
                    {t("mod_clockins_company_name")}
                </div>
                <InlineValidatedTextInput
                    inputTestID={TEST_IDS.companyNameInput}
                    saveButtonTestID={TEST_IDS.companyNameSaveButton}
                    handleSave={handleSave}
                    initialValue={initialValue}
                    required={false}
                />
            </div>
        </DataWrapper>
    );
}

function CompanyContactEmailInput({ initialValue, locationUid }) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HADLERS
    function handleSave(text) {
        dispatch(locationActions.updateCompanyContactEmail(text, locationUid));
    }

    return (
        <DataWrapper>
            <div
                data-testid={TEST_IDS.companyContactEmailSection}
                className="d-flex align-items-center justify-content-between"
            >
                <div>
                    {t("mod_clockins_company_contact_email")}
                </div>
                <InlineValidatedMailInput
                    inputTestID={TEST_IDS.CompanyContactEmailInput}
                    saveButtonTestID={TEST_IDS.companyContactEmailSaveButton}
                    handleSave={handleSave}
                    initialValue={initialValue}
                    required={false}
                />
            </div>
        </DataWrapper>
    );
}

function CompanyLegalIdInput({ initialValue, locationUid }) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HADLERS
    function handleSave(text) {
        dispatch(locationActions.updateCompanyLegalId(text, locationUid));
    }

    return (
        <DataWrapper>
            <div
                data-testid={TEST_IDS.companyLegalIdSection}
                className="d-flex align-items-center justify-content-between"
            >
                <div>
                    {t("mod_clockins_company_legal_id")}
                </div>
                <InlineValidatedTextInput
                    inputTestID={TEST_IDS.companyLegalIdInput}
                    saveButtonTestID={TEST_IDS.companyLegalIdSaveButton}
                    handleSave={handleSave}
                    initialValue={initialValue}
                    required={false}
                />
            </div>
        </DataWrapper>
    );
}

function CompanyAdditionalInfoInput({ initialValue, locationUid }) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HADLERS
    function handleSave(text) {
        dispatch(locationActions.updateCompanyAdditionalInfo(text, locationUid));
    }

    return (
        <DataWrapper>
            <div
                data-testid={TEST_IDS.companyAdditionalInfoSection}
                className="d-flex align-items-center justify-content-between"
            >
                <div>
                    {t("mod_clockins_company_additional_info")}
                </div>
                <InlineValidatedTextInput
                    inputTestID={TEST_IDS.companyAdditionalInfoInput}
                    saveButtonTestID={TEST_IDS.companyAdditionalInfoSaveButton}
                    handleSave={handleSave}
                    initialValue={initialValue}
                    required={false}
                />
            </div>
        </DataWrapper>
    );
}

function InlineValidatedTextInput({
    inputTestID,
    saveButtonTestID,

    handleSave,
    initialValue,

    required,
} = {}) {

    //LOCAL STATE
    const [text, setText] = useState(initialValue);
    const [isValid, setIsValid] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    
    //OVERRIDING

    useEffect(() => {
        setIsEdited(text !== initialValue);
    }, [text, initialValue]);

    //ACTION HADLERS
    function handleDiscardChanges() {
        setText(initialValue);
    }

    function handleSaveChanges() {
        handleSave(text);
    }

    return (
        <div className="d-flex">
            {isEdited &&
                <InlineEditButtons
                    acceptButtonTestID={saveButtonTestID}
                    isEnabled={isValid}
                    handleDiscardChanges={handleDiscardChanges}
                    handleConfirmChanges={handleSaveChanges}
                />
            }
            <ValidatedTextInput
                isRequired={required}
                isEditable

                testID={inputTestID}
                value={text}
                setValue={setText}
                setIsValid={setIsValid}
            />
        </div>
    );
}

function InlineValidatedMailInput({
    inputTestID,
    saveButtonTestID,

    handleSave,
    initialValue,

    required,
} = {}) {

    //LOCAL STATE
    const [text, setText] = useState(initialValue);
    const [isValid, setIsValid] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    
    //OVERRIDING

    useEffect(() => {
        setIsEdited(text !== initialValue);
    }, [text, initialValue]);

    //ACTION HADLERS
    function handleDiscardChanges() {
        setText(initialValue);
    }

    function handleSaveChanges() {
        handleSave(text);
    }

    function checkTextIsValidMail() {
        if  (!text || ValidationHelper.ValidationFns.isEmailValid(text)) setIsValid(true);
        else setIsValid(false);
    }

    return (
        <div className="d-flex">
            {isEdited &&
                <InlineEditButtons
                    acceptButtonTestID={saveButtonTestID}
                    isEnabled={isValid}
                    handleDiscardChanges={handleDiscardChanges}
                    handleConfirmChanges={handleSaveChanges}
                />
            }
            <ValidatedTextInput
                isRequired={required}
                isEditable

                testID={inputTestID}
                value={text}
                setValue={setText}
                setIsValid={checkTextIsValidMail}
            />
        </div>
    );
}

function imageUploadSection(title, handleUpload, isUploading, videoName, videoUrl) {
    return (
        <div>
            {titleFn(title)}
            {videoName &&
                <>
                    <div className="mt-1"/>
                    <div className="
                        d-flex
                        justify-content-center
                        align-items-center
                    ">
                        <a href={videoUrl}>{videoName}</a>
                    </div>
                </>
            }
            <div className="mt-1"/>
            <FileUploadComponent
                onUpload={handleUpload}
                isUploading={isUploading}
                fileType='video/mp4'
            />
        </div>
    );
}

function titleFn(title) {
    return (
        <div className="font-weight-bold text-uppercase">
            {title}
        </div>
    );
}

export function DataWrapper({
    children,
    hasSeparator = true,
    key,
}) {
    return (
        <div key={key}>
            <div className="p-1">
                {children}
            </div>
            {hasSeparator && <hr className="m-0"/>}
        </div>
    );
}