import { VideoLog, VideoFragment } from "../_models/VideoLog";
import { TimeHelper } from "../_helpers";

export class VideoLogMapper {

    static allServerToLocal(serverVideoLogs) {
        return serverVideoLogs.map(this.serverToLocal);
    }
    
    static serverToLocal(serverVideoLog) {
        return (
            VideoLog({
                logId: serverVideoLog.log_id,
                videoThumbnail: serverVideoLog.video_thumbnail,
                datetime: TimeHelper.serverStringToIsoString(serverVideoLog.datetime),
                videoFragments: serverVideoLog.video_fragments.map(fragment =>
                    VideoFragment({
                        videoIndex: fragment.video_index,
                        videoUid: fragment.video_uid,
                    })
                ),
            })
        );
    }
}