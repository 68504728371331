import { Selectors } from "../../../Everou/_reducers/app.reducer";
import { ModulesStore } from "./ModulesStore";

const _selectModuleStore = ModulesStore.actionSelectItem;
const _setEnabledModuleIds = ModulesStore.actionUpdate;

export function ModulesActionsFactory({
    defaultAppModule,
}) {
    return {
        selectModule: readySelectModule,
        setUpModules,
        unselectAllModules,
        forceSelectModule: _selectModuleStore,
    };

    function unselectAllModules() {
        return setUpModules([], null);
    }

    function readySelectModule(moduleId) {
        return selectModule(moduleId, defaultAppModule);
    }

    function setUpModules(enabledModuleIds, selectedModuleId) {
        return dispatch => {
            dispatch(_setEnabledModuleIds(enabledModuleIds));
            dispatch(readySelectModule(selectedModuleId));
        };
    }
}


function selectModule(moduleId, defaultAppModule) {
    return (dispatch, getState) => {
        const enabledModulesIds = Selectors.getEnabledModulesIds(getState());
        const currentyleSelectedModule = Selectors.getSelectedModuleId(getState());
        
        const selectedModuleId =
            getSelectedModule()
            || getDefaultModule()
            || getFirstActiveModule()
        ;

        if (selectedModuleId === currentyleSelectedModule)
            return;

        dispatch(_selectModuleStore(selectedModuleId));
        return;

        function getSelectedModule() { return isModuleEnabled(moduleId) && moduleId }
        function getDefaultModule() { return isModuleEnabled(defaultAppModule) && defaultAppModule }
        function getFirstActiveModule() { return enabledModulesIds[0] }
        function isModuleEnabled(moduleId) { return enabledModulesIds.includes(moduleId) }
    };
}