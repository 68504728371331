import { combineReducers } from "redux";
import { BaseEditableInvitationGroupStore } from "./BaseEditableInvitationGroupStore";
import { BaseInvitationGroupsStore } from "./BaseInvitationGroupsStore";
import { GenericFieldFactory } from "../../../Common/_generics/GenericFieldFactory";
import { InvitationGroupsSecondaryViewStore } from "../InvitationGroupsSecondaryViewStore";

const AddUsersGroupUidStore = GenericFieldFactory("ADD_USERS_GROUP_UID", null);
const SelectedGroupStore = GenericFieldFactory('SELECT_GROUP', null);

export const InvitationGroupStore = {
    reducer: combineReducers({
        addUsersGroupUid: AddUsersGroupUidStore.reducer,
        selectedGroup: SelectedGroupStore.reducer, 
        editableGroup: BaseEditableInvitationGroupStore.reducer,
        groupsByLocation: BaseInvitationGroupsStore.reducer,
    }),
    selectors: {
        getAddUsersGroupUid: state => AddUsersGroupUidStore.selectorGet(state.addUsersGroupUid),

        getEditable: state => BaseEditableInvitationGroupStore.selectors.get(state.editableGroup),
        
        getInvitationGroupsForLocation: (state, locationUid) => BaseInvitationGroupsStore.selectors.getArrayWithId(state.groupsByLocation, locationUid),
        getSelected: state => SelectedGroupStore.selectorGet(state.selectedGroup),

    },
    actions: {
        setAddUsersGroupUid: AddUsersGroupUidStore.actionSet,
        setIsRequestingAddUsers: AddUsersGroupUidStore.actionRequest,
        setSecondaryView: InvitationGroupsSecondaryViewStore.actionSet,
        selectAndSetEditable,
        updateEditableGroup: BaseEditableInvitationGroupStore.actions.updateObject,
        setEditable: BaseEditableInvitationGroupStore.actions.setObject,
        setSelected: SelectedGroupStore.actionSet,
        _setSelectedId: BaseInvitationGroupsStore.actions.setSelectedItemId,
    },
};


function selectAndSetEditable(group) {
    return dispatch => {
        dispatch(InvitationGroupStore.actions.setSelected(group));
        dispatch(InvitationGroupStore.actions._setSelectedId(group?.uid));
        dispatch(InvitationGroupStore.actions.setEditable(group));
    };
}