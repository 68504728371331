import { ServiceHelper2 } from "../_helpers/ServiceHelper2";
import { COMMON_URLS } from "../_constants/COMMON_URLS";

export const EntrypointService = {
    getEntryPoint,
};

async function getEntryPoint(subdomain) {
    const response = await ServiceHelper2.getRequest(
        COMMON_URLS.WEBAPP_ENTRY_POINT,
        {
            subdomain,
        },
        true,
    );

    return response.host;
}