import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import { Client } from "../_models/Client";
import { MinimalClientForm } from "../ModuleClients/MinimalClientForm";
import { ClientActions } from "../_actions/ClientActions";
import { CapsuleButton } from "../_components";

export function BudgetClientSection({
    client = Client(),
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [isEditingClient, setIseditingClient] = useState(false);
    
    //VIEW
    if (!client)
        return null;

    return isEditingClient
        ? editableClient()
        : readOnlyClient()
    ;

    function editableClient() {
        return (
            <div>
                <MinimalClientForm
                    client={client}
                    onSave={handleSaveClientChanges}
                />
            </div>
        );
    }

    function readOnlyClient() {
        return (
            <div>
                {singleDataEntry(
                    t("global_name") + ": ",
                    client.name,
                )}
                {singleDataEntry(
                    t("global_cif") + ": ",
                    client.cif,
                )}
                {singleDataEntry(
                    t("global_address") + ": ",
                    client.address,
                )}
                {singleDataEntry(
                    t("global_phone") + ": ",
                    client.phone,
                )}
                {singleDataEntry(
                    t("global_email") + ": ",
                    client.email,
                )}
                <div className="mt-1"/>
                <div className="d-flex">
                    <CapsuleButton
                        text={t("global_edit")}
                        onClick={() => setIseditingClient(true)}
                        size={'SMALL'}
                    />
                </div>
            </div>
        );

        function singleDataEntry(name, value) {
            return (
                <div className="d-flex">
                    <div>
                        {name}
                    </div>
                    <div className="ml-2"/>
                    <div>
                        {value}
                    </div>
                </div>
            );
        }
    }

    //ACTION HANDLERS
    function handleSaveClientChanges(clientParams) {
        setIseditingClient(false);
        dispatch(ClientActions.updateClient({
            uid: client.uid,
            ...clientParams,
        }));
    }
}
