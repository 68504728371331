import { SUBSCRIPTIONS_ACTION_TYPES } from "../_constants/subscriptions.constants";
import { Subscription } from "../_models/Subscription";
import { createSelector } from "reselect";

const initialState = {
    items: [],
    selected: null,
};

export function subscriptions(state = initialState, action) {
    switch (action.type) {

        case SUBSCRIPTIONS_ACTION_TYPES.GET_SUBSCRIPTIONS:
            return { ...state,
                items: action.subscriptions,
            };

        case SUBSCRIPTIONS_ACTION_TYPES.SELECT_SUBSCRIPTION:
            return { ...state,
                selected: state.items.find(subscription =>
                    subscription.uid === action.uid
                ) || null,
            };

        case SUBSCRIPTIONS_ACTION_TYPES.UPDATE_SELECTED:
            return { ...state,
                selected: { ...state.selected,
                    ...action.updatedFields,
                    edited: true,
                },
            };

        case SUBSCRIPTIONS_ACTION_TYPES.SAVE_CHANGES_TO_SERVER_SUCCESS:
            const updated =  {
                ...state.selected,
                edited: undefined,
            };
            
            return { ...state,
                items: state.items.map(subscription => 
                    subscription.uid === updated.uid
                    ? updated
                    : subscription
                ),
                selected: updated,
            };

        case SUBSCRIPTIONS_ACTION_TYPES.VALIDATE_SUCCESS:
            return { ...state,
                selected: action.validated,
            };

        case SUBSCRIPTIONS_ACTION_TYPES.UPDATE_SUBSCRIPTION_SUCCESS:
            return { ...state,
                selected: state.selected.uid === action.subscription.uid
                    ? action.subscription
                    : state.selected
                ,
                items: state.items.map(subscription =>
                    subscription.uid === action.subscription.uid
                    ?  action.subscription
                    : subscription
                ),
            };

        default:
            return state;
    }
}

let getRootState = state => state;

export class SubscriptionSelectors {
    static getSelectedSubscription = createSelector(
        state => getRootState(state).selected,
        selected => selected && { ...selected, ...Subscription(selected) },
    );

    static getSubscriptions = state => getRootState(state).items;
    
    static setRootStateFn = (rootStateFn = state => state) => getRootState = rootStateFn;
}