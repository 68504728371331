import React from "react";

import { UserManager } from "./App/UserManager";
import { Developers } from "../Developers/Developers";

export function DevelopersEverouBridge() {

    //VIEW
    return (
        <UserManager>
            <Developers />
        </UserManager>
    );
}