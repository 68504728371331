export function PMSUser({
    uid,
    name,
    email,
} = {}) {
    return {
        uid,
        name,
        email,
    };
}