import { Product, PRODUCT_TYPE } from "../_modelsTS/product";
import { createSelector } from "reselect";
import { LOCATION_INFO_ACTION_TYPES } from "../_constants";
import { LocationInfo } from "../_models/LocationInfo";
import { LocationInvitationConfig } from "../_models/LocationInvitationConfig";

//SELECTORS
let rootState = state => state;

export class OptionsSelectors {
    static setRootState = (fn = state => state) => rootState = fn;

    static getPermissionSelectedLocation = state => rootState(state).locationPermission;
    static getLocationInfo  = state => rootState(state);
    static getTimezone      = state => rootState(state).timezone;
    static getRooms         = state => rootState(state).rooms;
    static getIsSupeguest   = state => rootState(state).isSuperguest;
    static getInterfaceType = state => rootState(state).interfaceType;
    static getProductInfo   = (state, productType: PRODUCT_TYPE): Product => rootState(state).productsInfo?.find(({ type }) => type === productType);

    static getLocationInvitationConfig = createSelector(
        state => rootState(state),
        locationInfo => LocationInvitationConfig(locationInfo),
    );
}

//REDUCER
const initialState = LocationInfo();

export function locationInfoReducer(state = initialState, action) {
    switch (action.type) {

        case LOCATION_INFO_ACTION_TYPES.UPDATE_LOCATION_INFO:
            return { ...state,
                ...action.locationInfo,
            };

        case LOCATION_INFO_ACTION_TYPES.UPDATE_PROTECT_PARAMETER:
            return { ...state,
                ...action.parameter,
            };

        default:
            return state;
    }
}