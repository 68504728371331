import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from "../_components";
import { UserMobiles } from "../_models/UserMobiles";
import { SingleMobile } from "./SingleMobile";
import { Selectors } from "../_reducers/app.reducer";

export function MobilesListPage({
    isTypeFilterEnabled = false,
    isVersionFilterEnabled = false,
    mobileTypeFilter,
    mobileVersionFilter,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //GLOBAL STATE
    let selected = UserMobiles();
    selected = useSelector(Selectors.getSelectedMobile);

    if (!selected)
        return null;

    const mobiles = selected.mobiles
        .filter(mobile =>
            isTypeFilterEnabled
            ? mobile.type === mobileTypeFilter
            : true
        )
        .filter(mobile => 
            isVersionFilterEnabled
            ? mobile.appVersion === mobileVersionFilter
            : true
        )
    ;

    //VIEW
    return (
        <MainPageWithButtons
            dataTestId="mobiles-list"
            size={'SMALL'}
            pageTitle={t("temp_webapp_user_mobiles_list_title")}
            content={content()}
        />
    );

    function content() {
        return (
            <div>
                {mobilesFn()}
            </div>
        );
    }

    function mobilesFn() {
        if (mobiles.length === 0)
            return noMobiles();

        return mobiles.map((mobile, index) => 
            <SingleMobile
                key={index}
                mobile={mobile}
            />
        );
    }

    function noMobiles() {
        return (
            <div className="text-center my-2">
                {t("temp_webapp_mobile_user_does_not_have_active_mobiles")}
            </div>
        );
    }
}