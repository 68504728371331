import { useTranslation } from "react-i18next";
import { CaptionText } from "../../../Common/_components";
import { CardPackHelper } from "../../_helpers/CardPackHelper";
import { CardPack } from "../../_models/CardPack";
import { SingleCardPack } from "./SingleCardPack";


export function CardPackList({ cardPacks = [] || [CardPack()] }) {
    
    //LIBRARIES
    const { t } = useTranslation();

    const cardsAmountByType = CardPackHelper.getAmountOfCards(cardPacks);

    //VIEW
    return (
        <>
            {title()}
            {cardsAmount()}
            <div className="rounded c-bd-220 mt-3 py-2 px-3 c-grid c-grid--small">
                {cardPacks?.map((cardPack, index) => (
                    <SingleCardPack key={index} cardPack={cardPack} />
                ))}
            </div>
        </>
    );

    function title() {
        return <h3>{t("invitation_group_info_packs_header")}</h3>;
    }

    function cardsAmount() {
        let text = "";
        if (!isNaN(cardsAmountByType.totalUserCards)) {
            text = `${t("mod_cards_total_user_cards")}: ${
                cardsAmountByType.totalUserCards
            }`;
        }
        if (!isNaN(cardsAmountByType.totalNFCCards)) {
            if (text) {
                text += ", ";
            }
            text += `${t("mod_cards_total_nfc_cards")}: ${
                cardsAmountByType.totalNFCCards
            }`;
        }

        if (!text) return null;
        return <CaptionText>{text}</CaptionText>;
    }
}


