import { useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

interface CustomModalProps extends ModalProps {
    show: boolean;
    content: any;
    title?: any;
    footer?: any;
    className?: string;
    onHide: () => void;
}

export function CustomModal({ show, content, title, footer, className = 'responsive-modal', onHide, ...modalProps }: CustomModalProps) {

    const [showModal, setShowModal] = useState(show);

    useEffect(() => {
        setShowModal(show);
    }, [show])

    return <Modal
        show={showModal}
        {...modalProps}
        className={`${className}`}
        style={{ zIndex: 9999 }}
        onHide={() => onHide()}
    >
        {title && <Modal.Header>
            <Modal.Title>
                {title}
            </Modal.Title>
        </Modal.Header>}
        {content && <Modal.Body>
            {content}</Modal.Body>}
        {footer && <Modal.Footer>
            {footer}
        </Modal.Footer>}
    </Modal>

}