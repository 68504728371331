import React from "react";

export function SimpleListElement({
    testID,
    text,
    onClick,
    isSelected,
}) {
    return (
        <div
            data-testid={testID}
            onClick={onClick}
            className={`
                py-1
                px-2
                
                cp

                ${isSelected
                    ? "c-bg-primary-light-2"
                    : "c-hover-250"
                }
            `}
        >
            {text}
        </div>
    );
}