import React from "react";
import { useTranslation } from "react-i18next";

import { Product } from "../_models/Product";
import { CustomDropdown } from "../../Common/_components/CustomDropdown";
import { RangeInputComponent, CapsuleButton, TextInputComponent } from "../_components";
import { Icons } from "../_assets";
import { FloatRangeComponent } from "../../Common/_components/FloatRangeComponent";

export function TableEntryFields({
    isArchived = false,

    unitGrossPrice,

    isPriceEditable = false,
    isNameEditable = false,

    isNameVisible: isCustomNameVisible = false,
    customName = "-",
    name = "",
    setName = () => {},
    
    grossPrice,
    setPrice = () => {}, 
    
    totalVat,
    totalNetPrice,

    products = [] || [Product()],

    productType,
    setProductType = () => {},

    amount,
    setAmount = () => {},

    discount,
    setDiscount = () => {},

    onDeleteEntry = () => {},
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <tr>
            {isArchived
                ? archivedProductName()
                : <td>{productTypeFn()}</td>
            }
            
            {isCustomNameVisible &&
                <td>{customProductNameFn()}</td>
            }
            <td>{amountFn()}</td>

            <td>{getUnitPrice()}</td>
            
            <td>{discountFn()}</td>

            <td>{grossPrice && grossPrice.toFixed(2)}</td>
            <td>{totalVat.toFixed(2)}</td>
            <td>{totalNetPrice.toFixed(2)}</td>
            
            <td>
                <div className="
                    d-flex
                    align-items-center
                    justify-content-center
                ">
                    {<CapsuleButton
                        onClick={onDeleteEntry}
                        icon={Icons.trash}
                        style={`DANGER`}
                        size={'SMALL'}
                    />}
                </div>
            </td>
        </tr>
    );

    function archivedProductName() {
        return (
            <td className="
                pl-3
                d-flex
                justify-content-between
                align-items-center
                m-0
                border-0
            ">
                <div className="font-weight-bold">
                    {`${name} `}
                </div>
                <div className="my-auto text-danger">
                    {`${t("mod_budgets_entry_archived")}`}
                </div>
            </td>
        );
    }

    function productTypeFn() {
        return (
            <CustomDropdown
                options={options()}
                value={productType}
                onChange={setProductType}
                sortAlphabetically
            /> 
        );

        function options() {
            return products.map(product => 
                CustomDropdown.buildOption(
                    product.productType,
                    product.name,
                )
            );
        }
    }

    function customProductNameFn() {
        return (
            <TextInputComponent
                value={customName}
                isEditable={isNameEditable}
                onChange={setName}
            />
        );
    }

    function amountFn() {
        return (
            <RangeInputComponent
                value={amount}
                onChange={setAmount}
                min={1}
                max={10000}
                step={1}
            />
        );
    }

    function discountFn() {
        return (
            <RangeInputComponent
                onChange={setDiscount}
                min={0}
                max={1}
                isDecimalPercentage={true}
                value={discount}
                step={0.01}
            />
        );
    }

    function getUnitPrice() {
        return isPriceEditable
            ? <FloatRangeComponent
                max={10000}
                value={unitGrossPrice}
                onChange={setPrice}
            />
            : unitGrossPrice.toFixed(2)
        ;
    }
}