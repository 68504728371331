import { IntegrationCompany } from '../_modelsTS/Integration'

import logo_avaibook from './images/logo_avaibook.png'
import logo_hostify from './images/logo_hostify.png'
import logo_octorate from './images/logo_octorate.png'
import logo_kross_booking from './images/logo_kross_booking.png'
import logo_three_angular from './images/logo_three_angular.png'
import logo_smoobu from './images/logo_smoobu.jpg'
import logo_avantio from './images/logo_avantio.png'
import logo_bookipro from './images/logo_bookipro.png'

export function integrationLogoForCompany(company) {
    switch (company) {
        case IntegrationCompany.AVAIBOOK:
            return logo_avaibook;

        case IntegrationCompany.HOSTIFY:
            return logo_hostify;

        case IntegrationCompany.OCTORATE:
            return logo_octorate;

        case IntegrationCompany.KROSS_BOOKING:
            return logo_kross_booking;

        case IntegrationCompany.THREE_ANGULAR:
            return logo_three_angular;

        case IntegrationCompany.SMOOBU:
            return logo_smoobu;

        case IntegrationCompany.AVANTIO:
            return logo_avantio;

        case IntegrationCompany.BOOKIPRO:
            return logo_bookipro;

        default:
            return logo_avaibook;
    }
}