import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import protectOnIcon from "../_assets/appIcons/icon_protect_on.svg";
import protectOffIcon from "../_assets/appIcons/icon_protect_off.svg";

import { LocationModel } from "../_models/LocationModel";
import { GLOBAL_POPUPS } from "../_constants";
import { globalActions, locationActions } from "../_actions";
import { LocationInfo } from "../_models/LocationInfo";
import { CapsuleButton } from "../_components/CapsuleButton";
import { LocationsDropdown } from "../LocationsModule/LocationsDropdown";
import { LogoHeader } from "../_components";
import { Icons } from "../../Common/_assets/icons/Icons";
import { Selectors } from "../_reducers/app.reducer";
import { User } from "../_models/User";
import { WiredUserDropdown } from "./WiredUserDropdown";

const testIDs = {
    addCodeButton: "header-add-code-button",
};

Header.testIDs = testIDs;

export function Header({
    logo,
    color,
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let user            = User();
    user                = useSelector(Selectors.getEverouUser);

    let locationInfo    = LocationInfo();
    locationInfo        = useSelector(Selectors.getLocationInfo);
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);

    const selectedName  = locationInfo?.locationName;
    const isThereBrain  = locationInfo?.isThereBrain;
    const isProtectOn   = locationInfo?.isProtectOn;

    let locations       = [LocationModel()];
    locations           = useSelector(Selectors.getLocations);


    //ACTION HANDLERS
    function handleAddLocation() {
        dispatch(globalActions.showPopUp(GLOBAL_POPUPS.ADD_LOCATION));
    }

    function handleLocationClick(location = LocationModel()) {
        dispatch(locationActions.selectLocationFromHeader(location));
    }

    function handleAddCard() {
        dispatch(globalActions.showPopUp(GLOBAL_POPUPS.INPUT_CARD_CODE));
    }

    //VIEW
    return (
        <LogoHeader
            color={color}
            logoSrc={logo || Icons.everouIcon}
            content={content()}
        />
    );

    function content() {
        const name = (user.isSat ? "SAT - " : "") + user.name;

        return (
            <>
                {centerElements()}
                {user && <WiredUserDropdown
                    user={{...user, name}}
                />}
            </>
        );
    }

    function centerElements() {
        return (
            <div className="
                col-6
                col-lg-5
                col-xl-3

                d-flex
                no-gutters
                align-items-center
                justify-content-center
                position-relative
            ">
                {protectIcon()}
                <div className="ml-1"/>
                <div>
                    <LocationsDropdown
                        locations={locations}
                        handleLocationClick={handleLocationClick}
                        handleAddLocation={handleAddLocation}
                        selectedName={selectedName}
                        selectedUid={locationUid}
                    />
                </div>
                <div className="ml-2"/>
                {addCardButton()}
                <div className="ml-2"/>
            </div>
        );
        
        function addCardButton() {
            return (
                <div>
                    <CapsuleButton
                        testId={testIDs.addCodeButton}
                        text={t("temp_webapp_add_pack")}
                        onClick={handleAddCard}
                    />
                </div>
            );
        }
    }

    function protectIcon() {
        if (!isThereBrain)
            return;

        return (
            <img 
                className="c-h-2-5rem"
                src={isProtectOn ? protectOnIcon : protectOffIcon}
                alt="protect icon"
            />
        );
    }
}