import { User } from '../_modelsTS/User';
import { LOCATION_ACTION_TYPES } from '../_constants';

type LocationState = {
    list: any[];
    hydrated?: boolean;
    selected?: string | null;
    adding: boolean;
    usersInside: any[];
    users: User[]
}

const initialState: LocationState = {
    list: [],
    adding: false,
    selected: null,
    usersInside: [],
    users: [],
};

let rootState = state => state as LocationState;

export class LocationSelectors {
    
    static setRootState(fn = state => state) {
        rootState = fn;
    };

    static getLocations(state) {
        return rootState(state).list;
    }

    static getSelectedLocationUid(state) {
        return rootState(state).selected;
    }

    static getLocation(state, locationUid) {
        return rootState(state).list.find(location => location.uid === locationUid);
    }

    static getUsers(state) {
        return rootState(state).users;
    }

    static getUsersInside(state) {
        return rootState(state).usersInside;
    }
}

export function locations(state: LocationState = initialState, action) {

    if (!state.hydrated) {
        state = {
            ...initialState,
            ...state,
            hydrated: true,
        };
    }

    switch (action.type) {
        case LOCATION_ACTION_TYPES.LOCATIONS_LIST_SUCCESS:
            return { ...state,
                list: action.locations,
                adding: false,
            };

        case LOCATION_ACTION_TYPES.LOCATIONS_ADD:
            return { ...state,
                adding: true,
            };

        case LOCATION_ACTION_TYPES.LOCATIONS_STOP_ADDING:
            return { ...state,
                adding: false,
            };

        case LOCATION_ACTION_TYPES.LOCATIONS_REMOVE_SUCCESS:
            return { ...state,
                list: state.list.filter(location => location.uid !== action.uid),
            };

        case LOCATION_ACTION_TYPES.LOCATIONS_SELECT:
            return { ...state,
                selected: action.locationUid,
                adding: false,
            };

        case LOCATION_ACTION_TYPES.LOCATIONS_LIST_FAILURE:
            return { ...state,
                list: [],
            };

        case LOCATION_ACTION_TYPES.GET_USERS_IN_LOCATION_SUCCESS:
            return { ...state,
                usersInside: action.usersInside,
            };

        case LOCATION_ACTION_TYPES.GET_LOCATION_USERS:
            return { ...state,
                users: action.users,
            };

        default:
            return state;
    }
}