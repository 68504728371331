import { combineReducers } from 'redux';

import { locations, LocationSelectors } from './locations.reducer';
import { devices, DeviceSelectors } from './devices.reducer';
import { filter } from './filter.reducer';
import { locationInfoReducer, OptionsSelectors } from './locationInfo.reducer';
import { invitations, InvitationsSelector } from './invitations.reducer';
import { alerts } from './alerts.reducer';
import { global, GlobalSelectors } from './global.reducer';
import { dashboard, DashboardSelectors } from './dashboard.reducer';
import { video, VideoSelectors } from './video.reducer';
import { protect, ProtectSelectors } from './protect.reducer';
import { subscriptions, SubscriptionSelectors } from './subscriptions.reducer';
import { triggers, TriggersSelectors } from './triggers.reducer';
import { reservations } from './reservations.reducer';
import { userLogs } from './userLogs.reducer';
import { usersStatus } from './usersStatus.reducer';
import { auditLogsFilters } from './auditLogsFilters.reducer';
import { invitationCards, CardsSelectors } from './invitationCards.reducer';
import { technicalService, TechnicalServiceSelectors } from './technicalService.reducer';
import { PendingInvitationsStore } from '../_stores/PendingInvitationsStore';
import { MobilesStore } from '../_stores/MobilesStore';
import { DeviceFilterSelectors } from '../_selectors/DeviceFilterSelectors';
import { clockinReducer, ClockinSelectors } from '../_stores/ClockinStore/clockinReducer';
import { ProtectLogsCollection } from '../_stores/ProtectLogsCollection';
import { AuditLogsCollection } from '../_stores/AuditLogsCollection';
import { UserLogsCollection } from '../_stores/UserLogsCollection';
import { DeviceLogsCollection } from '../_stores/DeviceLogsCollection';
import { ProtectLogsDateFilter } from '../_stores/ProtectLogsDateFilter';
import { AuditLogsDateFilterStore } from '../_stores/AuditLogsDateFilterStore';
import { UserLogsDateFilterStore } from '../_stores/UserLogsDateFilterStore';
import { DeviceLogsDateFilterStore } from '../_stores/DeviceLogsDateFilterStore';
import { DevicesLogsFiltersStore } from '../_stores/DevicesLogsFiltersStore';
import { CustomizationStore } from '../_stores/CustomizationStore/CustomizationStore';
import { InvitationGroupStore } from '../_stores/InvitationGroupsStore/InvitationGroupStore';
import { PaymentMethodCollectionStore } from "../_stores/PaymentMethodsStore/PaymentMethodCollectionStore";
import { ClockinsSecondaryViewStore } from '../_stores/ClockinsSecondaryViewStore';
import { ProtectSecondaryViewStore } from '../_stores/ProtectSecondaryViewStore';
import { ReservationsSecondaryViewStore } from '../_stores/ReservationsSecondaryViewStore';
import { SubscriptionsSecondaryViewStore } from '../_stores/SubscriptionsSecondaryViewStore';
import { InvitationsSecondaryViewStore } from '../_stores/InvitationsSecondaryViewStore';
import { InvitationGroupsSecondaryViewStore } from '../_stores/InvitationGroupsSecondaryViewStore';
import { DevicesSecondaryViewStore } from '../_stores/DevicesSecondaryViewStore';
import { OrdersStore } from '../_stores/OrdersStore';
import { UsersStatusDateFilterStore } from "../_stores/UsersStatusDateFilterStore";
import { EverouDevelopersStore } from "../_stores/EverouDevelopersStore";
import { MyInvitationsStore } from '../_stores/MyInvitationsStore/MyInvitationsStore';
import { LocationBillingDataStore } from '../_stores/LocationBillingDataStore';
import { IntegrationStore } from '../_stores/IntegrationStore';
import { BarrierStore } from '../_stores/BarriersStore/BarriersStore';
import { RoomsStore } from '../_stores/RoomStore';
import { IntegrationsSecondaryViewStore } from '../_stores/IntegrationsSecondaryViewStore';
import { KrossBookingStore } from '../_stores/KrossBookingStore';
import { SmoobuStore } from '../_stores/SmoobuStore';
import { HostifyStore } from '../_stores/HostifyStore';

const combinedReducers = combineReducers({
    global,
    locations,
    filter,
    deviceLogs: combineReducers({
        logs: DeviceLogsCollection.reducerCollection,
        dateFilter: DeviceLogsDateFilterStore.reducer,
        filters: DevicesLogsFiltersStore.reducer,
    }),
    protect,
    protectLogs: combineReducers({
        logs: ProtectLogsCollection.reducerCollection,
        dateFilter: ProtectLogsDateFilter.reducer,
    }),
    locationInfo: locationInfoReducer,
    alerts,
    dashboard,
    video,
    subscriptions,
    triggers,
    reservations,
    barrier: BarrierStore.reducer,
    mobiles: MobilesStore.reducerCollection,
    usersStatus: combineReducers({
        legacy: usersStatus,
        dateFilter: UsersStatusDateFilterStore.reducer,
    }),
    userLogsStore: combineReducers({
        uiData: userLogs,
        logs: UserLogsCollection.reducerCollection,
        dateFilter: UserLogsDateFilterStore.reducer,
    }),
    auditLogs: combineReducers({
        logs: AuditLogsCollection.reducerCollection,
        filters: auditLogsFilters,
        dateFilter: AuditLogsDateFilterStore.reducer,
    }),
    technicalService,
    cards: invitationCards,
    invitations,
    invitationGroups: InvitationGroupStore.reducer,
    myInvitations: MyInvitationsStore.reducerCollection,
    pendingInvitations: PendingInvitationsStore.reducerCollection,
    clockins: clockinReducer,
    locationCustomization: CustomizationStore.reducer,
    paymentMethods: PaymentMethodCollectionStore.reducerCollection,
    locationBillingData: LocationBillingDataStore.reducerCollection,
    orders: OrdersStore.reducerCollection,
    developers: EverouDevelopersStore.reducerCollection,
    integrations: IntegrationStore.reducerCollection,
    rooms: RoomsStore.reducerCollection,
    krossBooking: KrossBookingStore.reducerCollection,
    smoobu: SmoobuStore.reducerCollection,
    hostify: HostifyStore.reducerCollection,
    secondaryViews: combineReducers({
        devices:        DevicesSecondaryViewStore.reducer,
        invitations:    InvitationsSecondaryViewStore.reducer,
        invitationGroups: InvitationGroupsSecondaryViewStore.reducer,
        subscriptions:  SubscriptionsSecondaryViewStore.reducer,
        reservations:   ReservationsSecondaryViewStore.reducer,
        protect:        ProtectSecondaryViewStore.reducer,
        clockins:       ClockinsSecondaryViewStore.reducer,
        integrations:   IntegrationsSecondaryViewStore.reducer,
    }),

    //DUMMY REDUCER
    devices: state => state || null,
});

const everouReducer = (state, action) => ({
    ...combinedReducers(state, action),
    devices: {
        ...devices(
            state && state.devices,
            action,
            state && state.locations && state.locations.selected,
        ),
    },
});

const setEverouStoreStateFn = newStateFn => getStateFn = newStateFn;

let getStateFn = state => state;

//SET UP SELECTORS ROOT STATE
LocationSelectors.setRootState(state => getStateFn(state).locations);
DeviceFilterSelectors.setRootState(state => getStateFn(state).filter);
PendingInvitationsStore.setRootStateFn(state => getStateFn(state).pendingInvitations);
MobilesStore.setRootStateFn(state => getStateFn(state).mobiles);
OrdersStore.setRootStateFn(state => getStateFn(state).orders);
IntegrationStore.setRootStateFn(state => getStateFn(state).integrations);
RoomsStore.setRootStateFn(state => getStateFn(state).rooms);
EverouDevelopersStore.setRootStateFn(state => getStateFn(state).developers);
MyInvitationsStore.setRootStateFn(state => getStateFn(state).myInvitations);
BarrierStore.setRootStateFn(state => getStateFn(state).barrier);
GlobalSelectors.setRootStateFn(state => getStateFn(state).global);
InvitationsSelector.setRootStateFn(state => getStateFn(state).invitations);
SubscriptionSelectors.setRootStateFn(state => getStateFn(state).subscriptions);
ClockinSelectors.setRootStateFn(state => getStateFn(state).clockins);
CustomizationStore.selectors.setRootStateFn(state => getStateFn(state).locationCustomization);
PaymentMethodCollectionStore.setRootStateFn(state => getStateFn(state).paymentMethods);
LocationBillingDataStore.setRootStateFn(state => getStateFn(state).locationBillingData);
KrossBookingStore.setRootStateFn(state => getStateFn(state).krossBooking);
SmoobuStore.setRootStateFn(state => getStateFn(state).smoobu)
HostifyStore.setRootStateFn(state => getStateFn(state).hostify)

const getSecondaryViewState = state => getStateFn(state).secondaryViews;
OptionsSelectors.setRootState(state => getStateFn(state).locationInfo);

DevicesSecondaryViewStore.setRootStateFn        (state => getSecondaryViewState(state).devices);
InvitationsSecondaryViewStore.setRootStateFn    (state => getSecondaryViewState(state).invitations);
InvitationGroupsSecondaryViewStore.setRootStateFn    (state => getSecondaryViewState(state).invitationGroups);
SubscriptionsSecondaryViewStore.setRootStateFn  (state => getSecondaryViewState(state).subscriptions);
ReservationsSecondaryViewStore.setRootStateFn   (state => getSecondaryViewState(state).reservations);
ProtectSecondaryViewStore.setRootStateFn        (state => getSecondaryViewState(state).protect);
ClockinsSecondaryViewStore.setRootStateFn       (state => getSecondaryViewState(state).clockins);
IntegrationsSecondaryViewStore.setRootStateFn   (state => getSecondaryViewState(state).integrations);

const EverouSelectors = {

    //GLOBAL
    getIsAppInitialized         : GlobalSelectors.getIsAppInitialized,
    getPreselectedLocationUid   : GlobalSelectors.getPreselectedLocationUid,
    getValidatedLocationUid     : GlobalSelectors.getValidatedLocationUid,

    //INVITATIONS
    getInvitations              : InvitationsSelector.getInvitations,
    getInvitationSelected       : InvitationsSelector.getInvitationSelected,
    getInvitationNewDraft       : InvitationsSelector.getInvitationNewDraft,
    getInvitationSelectedUid    : InvitationsSelector.getInvitationSelectedUid,
    getInvitationValidated      : InvitationsSelector.getInvitationValidated,
    getInvitationFilter         : InvitationsSelector.getInvitationFilter,
    getIsRequestingInvitations  : InvitationsSelector.getIsRequestingInvitations,
    getInvitationAccessLinkSelected : InvitationsSelector.getInvitationAccessLinkSelected,
    getInvitationFromListByUid   : (state, invitationUid) => InvitationsSelector.getInvitationFromListByUid(state, invitationUid),
    //INVITATIONS OTHER
    getSelectedInvitationCard    : state => CardsSelectors.getCard(getStateFn(state).cards),
    getPendingInvitations        : PendingInvitationsStore.selectorGetItems,

    //MY INVITATIONS
    getMyInvitations            : MyInvitationsStore.selectorGetItems,
    getMyInvitationSelected     : MyInvitationsStore.selectorGetSelectedItem,

    //INVITATION GROUPS
    invitationGroupsState                : state => getStateFn(state).invitationGroups,

    getInvitationGroupsForLocation       : (state, locationUid) => InvitationGroupStore.selectors.getInvitationGroupsForLocation(EverouSelectors.invitationGroupsState(state), locationUid),
    getInvitationGroupByUid              : (state, locationUid, groupUid) =>InvitationGroupStore.selectors.getInvitationGroupsForLocation(EverouSelectors.invitationGroupsState(state), locationUid).find(({uid}) => uid === groupUid),  
    getInvitationGroups                  : state => InvitationGroupStore.selectors.getInvitationGroupsForLocation(EverouSelectors.invitationGroupsState(state), EverouSelectors.getSelectedLocationUid(state)),
    getEditableInvitationGroup           : state => InvitationGroupStore.selectors.getEditable(EverouSelectors.invitationGroupsState(state)),
    getSelectedInvitationGroup           : state => InvitationGroupStore.selectors.getSelected(EverouSelectors.invitationGroupsState(state)),

    getAddUsersInvitationGroupUid        : state => InvitationGroupStore.selectors.getAddUsersGroupUid(EverouSelectors.invitationGroupsState(state)),

    //SECONDARY VIEWS
    getDevicesModuleSecondaryView       : DevicesSecondaryViewStore.selectorGet,
    getInvitationsModuleSecondaryView   : InvitationsSecondaryViewStore.selectorGet,
    getInvitationGroupsModuleSecondaryView   : InvitationGroupsSecondaryViewStore.selectorGet,
    getSubscriptionsModuleSecondaryView : SubscriptionsSecondaryViewStore.selectorGet,
    getReservationsModuleSecondaryView  : ReservationsSecondaryViewStore.selectorGet,
    getProtectModuleSecondaryView       : ProtectSecondaryViewStore.selectorGet,
    getClockinsModuleSecondaryView      : ClockinsSecondaryViewStore.selectorGet,
    getIntegrationsModuleSecondaryView  : IntegrationsSecondaryViewStore.selectorGet,    

    //SUBSCRIPTIONS
    getSelectedSubscription  : SubscriptionSelectors.getSelectedSubscription,
    getSubscriptions         : SubscriptionSelectors.getSubscriptions,

    //DEVICES
    devicesState                 : state => getStateFn(state).devices,
    getSelectedLocationDevices   : state => DeviceSelectors.getLocationDevices(EverouSelectors.devicesState(state), getStateFn(state).locations.selected),
    getLocationDevices           : (state, locationUid) => DeviceSelectors.getLocationDevices(EverouSelectors.devicesState(state), locationUid),
    
    getDeviceConfig              : state => DeviceSelectors.getDeviceConfig(EverouSelectors.devicesState(state)),
    getMarkedDevice              : state => DeviceSelectors.getMarkedDevice(EverouSelectors.devicesState(state)),
    getIsAllDevicesSelected      : state => DeviceSelectors.getIsAllDevicesSelected(EverouSelectors.devicesState(state)),
    getDevicesLastAction         : state => DeviceSelectors.getLastAction(EverouSelectors.devicesState(state)),

    //LOCATIONS
    getLocations             : LocationSelectors.getLocations,
    getLocation              : LocationSelectors.getLocation,
    getSelectedLocationUid   : LocationSelectors.getSelectedLocationUid,
    getUsers                 : LocationSelectors.getUsers,
    getUsersInside           : LocationSelectors.getUsersInside,

    //LOCATION INFO
    getPermissionSelectedLocation    : OptionsSelectors.getPermissionSelectedLocation,
    getTimezone                      : OptionsSelectors.getTimezone,
    getLocationInfo                  : OptionsSelectors.getLocationInfo,
    getProductInfo                         : OptionsSelectors.getProductInfo,
    getLocationInvitationConfig      : OptionsSelectors.getLocationInvitationConfig,
    getIsSupeguest                   : OptionsSelectors.getIsSupeguest,
    getLocationInterfaceType         : OptionsSelectors.getInterfaceType,

    //SAT SERVICE
    getSatInvitationUid  : state => TechnicalServiceSelectors.getSatInvitationUid(getStateFn(state).technicalService),
    getSatCode           : state => TechnicalServiceSelectors.getSatCode(getStateFn(state).technicalService),

    //PROTECT LOGS
    protectLogsRootState             : state => getStateFn(state).protectLogs,

    protectLogsState                 : state => EverouSelectors.protectLogsRootState(state).logs,
    getProtectLogs                   : state => ProtectLogsCollection.selectorGetItems(EverouSelectors.protectLogsState(state)),
    getProtectLogsIsRequesting       : state => ProtectLogsCollection.selectorIsRequestingList(EverouSelectors.protectLogsState(state)),
    getProtectLogsIsRequestingFirst  : state => ProtectLogsCollection.selectorIsRequestingFirstPage(EverouSelectors.protectLogsState(state)),
    getProtectLogsNextPage           : state => ProtectLogsCollection.selectorGetNextPage(EverouSelectors.protectLogsState(state)),
    
    getProtectLogsDateFilter         : state => ProtectLogsDateFilter.selectorGetBeginEnd(EverouSelectors.protectLogsRootState(state).dateFilter),

    //PROTECT TRIGGERED LOGS
    getProtectTriggeredLogs          : state => ProtectSelectors.getTriggeredLogs(getStateFn(state).protect),

    //USER LOGS
    usersLogsState               : state => getStateFn(state).userLogsStore.logs,
    getUserLogs                  : state => UserLogsCollection.selectorGetItems(EverouSelectors.usersLogsState(state)),
    getUserLogsIsRequesting      : state => UserLogsCollection.selectorIsRequestingList(EverouSelectors.usersLogsState(state)),
    getUserLogsIsRequestingFirst : state => UserLogsCollection.selectorIsRequestingFirstPage(EverouSelectors.usersLogsState(state)),
    getUserLogsNextPage          : state => UserLogsCollection.selectorGetNextPage(EverouSelectors.usersLogsState(state)),

    getUserLogsDateFilter        : state => UserLogsDateFilterStore.selectorGetBeginEnd(getStateFn(state).userLogsStore.dateFilter),
    
    //AUDIT LOGS
    auditLogsState                   : state => getStateFn(state).auditLogs.logs,
    getAuditLogs                     : state => AuditLogsCollection.selectorGetItems(EverouSelectors.auditLogsState(state)),
    getAuditLogsIsRequesting         : state => AuditLogsCollection.selectorIsRequestingList(EverouSelectors.auditLogsState(state)),
    getAuditLogsIsRequestingFirst    : state => AuditLogsCollection.selectorIsRequestingFirstPage(EverouSelectors.auditLogsState(state)),
    getAuditLogsNextPage             : state => AuditLogsCollection.selectorGetNextPage(EverouSelectors.auditLogsState(state)),
    getAuditLogsSelected             : state => AuditLogsCollection.selectorGetSelectedItem(EverouSelectors.auditLogsState(state)),
    getAuditLogsSelectedUid          : state => AuditLogsCollection.selectorGetSelectedId(EverouSelectors.auditLogsState(state)),

    getAuditLogsDateFilter           : state => AuditLogsDateFilterStore.selectorGetBeginEnd(getStateFn(state).auditLogs.dateFilter),
    
    //AUDIT LOGS FILTER
    auditFiltersState            : state => getStateFn(state).auditLogs.filters,
    getAuditLogsFilterPresets    : state => EverouSelectors.auditFiltersState(state).filterPresets,
    getAuditLogsSelectedPreset   : state => EverouSelectors.auditFiltersState(state).selectedPreset,
    getAuditLogsFilters          : state => EverouSelectors.auditFiltersState(state).otherFilters,

    //DEVICE LOGS
    deviceLogsRootState              : state => getStateFn(state).deviceLogs,
    deviceLogsState                  : state => EverouSelectors.deviceLogsRootState(state).logs,
    getDeviceLogs                    : state => DeviceLogsCollection.selectorGetItems(EverouSelectors.deviceLogsState(state)),
    getDeviceLogsIsRequesting        : state => DeviceLogsCollection.selectorIsRequestingList(EverouSelectors.deviceLogsState(state)),
    getDeviceLogsIsRequestingFirst   : state => DeviceLogsCollection.selectorIsRequestingFirstPage(EverouSelectors.deviceLogsState(state)),
    getDeviceLogsNextPage            : state => DeviceLogsCollection.selectorGetNextPage(EverouSelectors.deviceLogsState(state)),
    
    //DEVICE LOGS FILTERS
    getDeviceLogsDateFilter          : state => DeviceLogsDateFilterStore.selectorGetBeginEnd(EverouSelectors.deviceLogsRootState(state).dateFilter),
    
    deviceLogsFilterState            : state => EverouSelectors.deviceLogsRootState(state).filters,
    getDeviceLogsFilterUser          : state => EverouSelectors.deviceLogsFilterState(state).user,
    getDeviceLogsFilterTypeStatus    : state => EverouSelectors.deviceLogsFilterState(state).status,
    getDeviceLogsFilterType          : state => EverouSelectors.deviceLogsFilterState(state).type,
    
    //MOBILES
    getMobiles               : MobilesStore.selectorGetItems,
    getSelectedMobileId      : MobilesStore.selectorGetSelectedId,
    getSelectedMobile        : MobilesStore.selectorGetSelectedItem,

    //DEVICE FILTERS
    getDeviceFilters                         : DeviceFilterSelectors.getDeviceFilters,
    getDeviceFilterDeviceTypes               : DeviceFilterSelectors.getTypes,
    getDeviceFilterDeviceCategories          : DeviceFilterSelectors.getDeviceCategories,
    getDeviceFilterRooms                     : DeviceFilterSelectors.getRooms,
    getDeviceFilterIsAllCategoriesSelected   : DeviceFilterSelectors.getIsAllCategoriesSelected,
    getDeviceFilterCategories                : DeviceFilterSelectors.getCategories,
    getDeviceFilterFilterMode                : DeviceFilterSelectors.getFilterMode,

    //ALERTS
    getAlerts : state => getStateFn(state).alerts,

    //USERS STATUS
    usersStatusRootState             : state => getStateFn(state).usersStatus,
    usersStatusLegacyState           : state => EverouSelectors.usersStatusRootState(state).legacy,

    getUsersStatus                   : state => EverouSelectors.usersStatusLegacyState(state).items,
    getUsersStatusIsRequesting       : state => EverouSelectors.usersStatusLegacyState(state).isRequestingUsersStatus,
    getUsersStatusSelectedUserUid    : state => EverouSelectors.usersStatusLegacyState(state).selectedUserUid,

    getUserStatusData                : state => EverouSelectors.usersStatusLegacyState(state).userStatusData,
    getUserStatusDataIsRequesting    : state => EverouSelectors.usersStatusLegacyState(state).isRequestingUserStatusData,

    getUserStatusLogs                : state => EverouSelectors.usersStatusLegacyState(state).userStatusLogs,
    getUserStatusLogsIsRequesting    : state => EverouSelectors.usersStatusLegacyState(state).isRequestingUserStatusLogs,
    
    getUsersStatusDateFilter         : state => EverouSelectors.usersStatusLegacyState(state).dateFilter,

    getUsersStatusDateRanges         : state => EverouSelectors.usersStatusRootState(state).dateFilter,

    //USER LOGS UI STATE
    usersLogsUiState         : state => getStateFn(state).userLogsStore.uiData,
    getFriends               : state => EverouSelectors.usersLogsUiState(state).friends,
    getUserLogsSelected      : state => EverouSelectors.usersLogsUiState(state).selectedUserLog,
    getUserLogsSelectedUid   : state => EverouSelectors.usersLogsUiState(state).selectedUserUid,

    //RESERVATIONS
    reservationsState                   : state => getStateFn(state).reservations,
    getReservations                     : state => EverouSelectors.reservationsState(state).reservations,
    getReservationConfigurations        : state => EverouSelectors.reservationsState(state).configurations,
    getReservationSelected              : state => EverouSelectors.reservationsState(state).selected,
    getReservationSelectedScheduleDate  : state => EverouSelectors.reservationsState(state).selectedScheduleDate,
    getReservationSelectedSlot          : state => EverouSelectors.reservationsState(state).selectedSlot,

    //DASHBOARD
    dashboardState                   : state => getStateFn(state).dashboard,
    getDashboardGroups               : state => DashboardSelectors.getGroups(EverouSelectors.dashboardState(state)),
    getDashboardSelectedGroupId      : state => DashboardSelectors.getSelectedGroupId(EverouSelectors.dashboardState(state)),
    getDashboardWidgets              : state => DashboardSelectors.getWidgets(EverouSelectors.dashboardState(state)),
    getDashboardSettings             : state => DashboardSelectors.getSettings(EverouSelectors.dashboardState(state)),

    //TRIGGERS
    getTriggers : state => TriggersSelectors.getTriggers(getStateFn(state).triggers),

    //VIDEO
    videoState                   : state => getStateFn(state).video,
    getVideoNextPage             : state => VideoSelectors.getNextPage(EverouSelectors.videoState(state)),        
    getVideoLogs                 : state => VideoSelectors.getLogs(EverouSelectors.videoState(state)),    
    getVideoDateFilter           : state => VideoSelectors.getDateFilter(EverouSelectors.videoState(state)),        
    getVideoBrainUid             : state => VideoSelectors.getBrainUid(EverouSelectors.videoState(state)),        
    getVideoIsConverting         : state => VideoSelectors.getIsConverting(EverouSelectors.videoState(state)),            
    getVideoIsRequesting         : state => VideoSelectors.getIsRequesting(EverouSelectors.videoState(state)),            
    getVideoIsRequestingFirst    : state => VideoSelectors.getIsRequestingFirst(EverouSelectors.videoState(state)),   
    
    //CLOCKINS
    getClockins                  : ClockinSelectors.getClockins,
    getClockinsDateFilter        : ClockinSelectors.getDateFilter,
    getClockinsSelectedUserUid   : ClockinSelectors.getSelectedUserUid,
    getClockinsUsers             : ClockinSelectors.getClockinUsers,
    getClockinsFilter            : ClockinSelectors.getClockinsFilter,

    //CUSTOMIZATION
    getCustomizationStore        : CustomizationStore.selectors.getStore,
    getLocationCustomization     : CustomizationStore.selectors.getLocationCustomization,
    getCustomizationIsUploading  : CustomizationStore.selectors.getIsUploadingImage,

    //PAYMENT METHODS
    getPaymentMethod                 : state => PaymentMethodCollectionStore.selectorGetItems(state)[0],
    getPaymentMethodsIsRequesting    : PaymentMethodCollectionStore.selectorIsRequestingList,

    //LOCATION BILLING DATA
    getLocationBillingData           : state => LocationBillingDataStore.selectorGetItems(state)[0],

    //ORDERS
    getOrders           : OrdersStore.selectorGetItems,
    getSelectedOrder    : OrdersStore.selectorGetSelectedItem,
    getSelectedOrderUid : OrdersStore.selectorGetSelectedId,

    //DEVELOPERS
    getDevelopers       : EverouDevelopersStore.selectorGetItems,

    //INTEGRATIONS
    getIntegrations           : IntegrationStore.selectorGetItems,
    getSelectedIntegration    : IntegrationStore.selectorGetSelectedItem,
    getSelectedIntegrationUid : IntegrationStore.selectorGetSelectedId,
    //KROSS BOOKING
    getData             : KrossBookingStore.selectorGetItem,
    getSmoobuData       : SmoobuStore.selectorGetItem,
    getHostifyData      : HostifyStore.selectorGetItem,

    //BARRIERS
    getBarrier          : BarrierStore.selectors.getBarrier,
    // ROOMS
    getRooms                         : RoomsStore.selectorGetItems,
    getSelectedRoom                  : RoomsStore.selectorGetSelectedItem,
    getSelectedRoomUid               : RoomsStore.selectorGetSelectedId,
}

export const EverouStore = {
    setRootStateFn: setEverouStoreStateFn,
    selectors: EverouSelectors,
    reducer: everouReducer,
};