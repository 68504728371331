import { RemoveBudgetPopUp } from "./ModuleBudgets/RemoveBudgetPopUp";

const IDS = {
    DELETE_BUDGET: "DELETE_BUDGET",
};

function getPopUp(ID) {
    const componentForPopUpId = {
        [IDS.DELETE_BUDGET]: RemoveBudgetPopUp,
    };
    
    return componentForPopUpId[ID];
}

export const BudgetsPopUps = {
    IDS,
    getPopUp,
};