import { Barrier } from "../../_modelsTS/Barrier";
import { barriersActions, BARRIERS_ACTIONS_TYPES } from "./BarriersActions";

export type BarrierErrors = {
    devicesMisconfigured?: boolean;
    capacityError?: boolean;
}

export type BarrierStoreType = Barrier & { errors?: BarrierErrors, isLoading: boolean };

const initialState: BarrierStoreType  = {
    capacity: 0,
    numUsersInsideBarriers: 0,
    errors: {},
    usersInsideBarriers: [],
    isLoading: true
}

//ROOT STATE
let getRootState = state => state;

function setRootStateFn(getRootStateFn = state => state) {
    getRootState = getRootStateFn;
}

export const reducer = (state= initialState, action: { type: BARRIERS_ACTIONS_TYPES, [k: string]: any }) => {

    switch (action.type) {
        case BARRIERS_ACTIONS_TYPES.UPDATE_FIELDS:
            state = {
                ...state,
                ...action.barrier
            }
            break;
        
        case BARRIERS_ACTIONS_TYPES.UPDATE_ERRORS:
            state = {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.errors
                }
            }
            break;
        case BARRIERS_ACTIONS_TYPES.UPDATE_LOADING:
            state = {
                ...state,
                isLoading: action.isLoading
            }
            break;
        default:
            break;
    }

return state;

}

export const selectors = {
    getBarrier: (state): BarrierStoreType => getRootState(state)
}



export const BarrierStore = {
    reducer,
    actions: barriersActions,
    selectors,
    setRootStateFn
};