import { NfcService } from "../../Everou/_services/NfcService";
import { NfcInfoMapper } from "../_mappers/NfcInfoMapper";
import { genericActions } from "../../Everou/_actions/generic.actions";

export const NfcActions = {
    getNfcInfo,
};

function getNfcInfo(cardCode, rethrow = false) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            if (!cardCode)
                throw new Error("NO CARD CODE");

            const serverNfcInfo = await NfcService.getCardInfo(cardCode);
            return NfcInfoMapper.serverToLocal(serverNfcInfo);
        }
    };
}