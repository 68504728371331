import { ModulesActionsFactory } from "../../Common/_stores/ModulesStore/ModulesActionsFactory";
import { BudgetsModules } from "../BudgetsModules";

const modulesActions = ModulesActionsFactory({
    defaultAppModule: BudgetsModules.DEFAULT_MODULE,
});

export const BudgetsModulesActions = {
    //PUBLIC
    selectModuleId: modulesActions.selectModule,
    setUpModules,

    _setUpModulesGeneric: modulesActions.setUpModules,
};

function setUpModules(hasDistributor = false, url) {
    return BudgetsModulesActions._setUpModulesGeneric(
        BudgetsModules.getEnabledModuleIds(hasDistributor),
        getInitialModuleId(hasDistributor, url),
    );

    function getInitialModuleId(hasDistributor, url) {
        return hasDistributor
            ? BudgetsModules.getModuleIdForUrl(url)
            : BudgetsModules.IDS.DISTRIBUTOR
        ;
    }
}