import React, { Children, PureComponent, ReactChild } from "react";

type Props = {
  width: number;
  height: number;
  clientId: string;
  redirectUri: string;
  title: string;
  onClose: () => any;
  children?: ReactChild;
};

export default class OctorateOauthPopup extends PureComponent<Props> {
  static defaultProps = {
    onClose: () => {},
    width: 500,
    height: 500,
    title: "",
  };

  externalWindow: any;
  codeCheck: any;

  componentWillUnmount() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }

  createPopup = () => {
    const { redirectUri, clientId, title, width, height, onClose } = this.props;
    const url = "https://admin.octorate.com/octobook/identity/oauth.xhtml?client_id="+clientId+"&redirect_uri="+encodeURIComponent(redirectUri);
    console.log(url);
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,width=${width},height=${height},top=${top},left=${left}`;

    this.externalWindow = window.open(url, title, windowFeatures);

    /*const storageListener = () => {
      console.log("RECEIVED");
      this.props.onClose();
      this.externalWindow.close();
      window.removeEventListener("octorate", storageListener);
    };

    window.addEventListener("octorate", storageListener);*/
  };

  render() {
    return <div onClick={this.createPopup}>{this.props.children}</div>;
  }
}