import { GenericMapperFactory } from "./MapperFactory";
import { LocationInvitationConfig } from "../_models/LocationInvitationConfig";

export const LocationInvitationConfigMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverObj) {
    return LocationInvitationConfig({
        invitationsAllowed:     serverObj.invitations_allowed,
        invitationsLeft:        serverObj.invitations_left,
        canCreateSuperguests:   serverObj.can_create_superguests,
    });
}