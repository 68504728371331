import { genericActions } from "./generic.actions";
import { AuthenticationHelper } from "../_helpers";
import { FileHelper } from "../_helpers/FileHelper";

export const emailsActions = {
    processSingleEmail,
    processEmailsFile,
};

function processSingleEmail(email) {
    return dispatch => {
        return dispatch(genericActions.genericAsyncAction(asyncAction));

        function asyncAction() {
            AuthenticationHelper.validateEmails([email]);
            return email;
        }
    };
}

function processEmailsFile(emailsCsvFile) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const emails = await FileHelper.processCsvFile(emailsCsvFile);
            AuthenticationHelper.validateEmails(emails);
            return emails;
        }
    };
}