import { Selectors } from "../../_reducers/app.reducer";
import { useDispatch, useSelector } from "react-redux";
import { CapsuleButton, CaptionText, CustomCard, MainPageWithButtons } from "../../../Common/_components";
import { useTranslation } from "react-i18next";
import { invitationPermissionUiValues } from "../../_constants";
import { useCallback, useMemo } from "react";
import { appPermissions } from "../../_constants/permissions.constants";
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";
import { TimeHelper } from "../../_helpers";
import { INVITATION_GROUP_SECONDARY_VIEWS } from "../../_constants/invitationsGroup.constants";

const TEST_IDS = {
    createGroupButton: "invitations-list-view-create-group-button",
};

export function InvitationGroupsList() {

    const locationPermission = useSelector(
        Selectors.getPermissionSelectedLocation,
    );

    const selectedGroup = useSelector(Selectors.getSelectedInvitationGroup);
    //const locationInfo = useSelector(Selectors.getLocationInfo);
    const dispatch = useDispatch();

    const canCreateGroups =
        appPermissions.canUserManageInvitationGroups(locationPermission);

    const handleCreateGroup = useCallback(() => {
            dispatch(invitationGroupActions.selectInvitationGroup(null));
            dispatch(invitationGroupActions.changeSecondaryView(INVITATION_GROUP_SECONDARY_VIEWS.NEW_INVITATION_GROUP))
        }, [dispatch]) 
    
    const selectGroup = (group) => {
        dispatch(invitationGroupActions.changeSecondaryView(INVITATION_GROUP_SECONDARY_VIEWS.INVITATION_GROUP_CONFIG))
        dispatch(invitationGroupActions.selectInvitationGroup(group));
    }

    const groups = useSelector(Selectors.getInvitationGroups);
    const { t } = useTranslation();
    
    const createGroupButton = useMemo(
        () => (
            <CapsuleButton
                key={0}
                testId={TEST_IDS.createGroupButton}
                onClick={handleCreateGroup}
                text={t("mod_invitations_add_group")}
            />
        ),
        [handleCreateGroup, t],
    );

    const content = (
        <div className="c-default-grid py-4">
            {groups?.map((group) => (
                <CustomCard key={group.uid} selected={group.uid === selectedGroup?.uid} onClick={() => selectGroup(group)}>
                    <h3>{group.name}</h3>
                    <div className="d-flex justify-content-between">
                        <CaptionText>
                            {
                                group.duration ? TimeHelper.durationServerToUi(group.duration) : t('invitations.always')
                            }
                        </CaptionText>
                        <CaptionText>
                            {
                                invitationPermissionUiValues[
                                    group.invitation.permissionType
                                ]
                            }
                        </CaptionText>
                    </div>
                </CustomCard>
            ))}
        </div>
    );

    const noGroupsContent = useMemo(
        () => (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <CaptionText>
                    {t("mod_invitations_group_no_groups_yet")}
                </CaptionText>
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <MainPageWithButtons
            size="LARGE"
            buttons={(canCreateGroups) ? [createGroupButton] : []}
            pageTitle={t("global_groups")}
            content={groups?.length ? content : noGroupsContent}
        />
    );
}