import { ReservationConfig } from "../_models/ReservationConfig";
import { TimeHelper } from "../_helpers";
import { ReservationMapper } from "./ReservationMapper";

export class ReservationConfigMapper {
    static allServerToLocal(serverReservationConfigs) {
        return serverReservationConfigs.map(mapSingle);

        function mapSingle(serverConfiguration) {
            return ReservationConfigMapper.serverToLocal(
                getServerReservationConfig(),
                serverConfiguration.reservation_pending,
            );

            function getServerReservationConfig() {
                return serverConfiguration.room_configuration
                    ? {
                        ...serverConfiguration.room_configuration,
                        room_type: serverConfiguration.room_type,
                    }
                    : {
                        room_uid: serverConfiguration.room_uid,
                        room_name: serverConfiguration.room_name,
                        room_type: serverConfiguration.room_type,
                        isSetUp: false,
                    }
                ;
            }
        }
    }

    static serverToLocal(serverReservationConfig, serverPendingReservation) {
        const {
            room_type,
            room_uid,
            room_name,

            block_minutes,
            limit_minutes,

            all_day,
            begin,
            end,

            monday,
            tuesday,
            thursday,
            wednesday,
            friday,
            saturday,
            sunday,

            isSetUp,

            excluded_days,

            number_of_users,
        } = serverReservationConfig;

        return ReservationConfig({
            isSetUp,

            roomType: room_type,
            roomUid: room_uid,
            roomName: room_name,

            blockMinutes: block_minutes,
            limitMinutes: limit_minutes,

            allDay: all_day,
            begin: TimeHelper.serverStringToIsoString(begin),
            end: TimeHelper.serverStringToIsoString(end),

            monday,
            tuesday,
            thursday,
            wednesday,
            friday,
            saturday,
            sunday,

            pendingReservation: serverPendingReservation
                ? ReservationMapper.serverToLocal(serverPendingReservation)
                : null
            ,

            excludedDays: excluded_days && excluded_days.map(TimeHelper.serverStringToIsoString),

            numberOfUsers: number_of_users,
        });
    }
}