import { appPermissions } from "../_constants/permissions.constants";
import { LocationModel } from "../_models/LocationModel";
import { LOCATION_PERMISSIONS } from '../_constants/permissions.constants';

export const LocationHelper = {
    getLocationsUserCanAddPacksTo,
    hasAccessToLocation
};

function getLocationsUserCanAddPacksTo(locations = [] || [LocationModel()]) {
    return locations.reduce((locations, location) =>
        appPermissions.canUserManageInvitationGroups(location.userType)
        ? [...locations, location]
        : locations
    , []);
}

function hasAccessToLocation(locations, selectedLocationUid) {
    return (
        locations.find((location) => location.uid === selectedLocationUid)
            ?.userType !== LOCATION_PERMISSIONS.NO_ACCESS
    );
}