import { Product } from "../_models/Product";

export class ProductMapper {
    static allServerToLocal(serverProducts = []) {
        return serverProducts.map(this.serverToLocal);
    }

    static serverToLocal(serverProduct) {
        return Product({
            uid: serverProduct.uid,
            description: serverProduct.description,
            name: serverProduct.name,
            photoUrl: serverProduct.photo_url,
            price: serverProduct.price,
            productType: serverProduct.type,
            isPriceEditable: serverProduct.price_editable,
            isNameEditable: serverProduct.name_editable,
        });
    }
}

