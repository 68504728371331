import { useEffect, useMemo, useRef, useState } from "react";
import { SegmentedControl } from ".";
import { CaptionText } from "../../Common/_components/CaptionText";
import { TimeHelper } from "../_helpers";
import { LocalizedDatePicker } from "./LocalizedDatePicker";
import { useTranslation } from "react-i18next";

type IsoRangeDatePickerProps = {
    onChangeStart?: (date: Date | null) => void;
    onChangeEnd?: (date: Date | null) => void;
    begin?: Date | string | null;
    end?: Date | string | null;
    beginPlaceHolder?: string;
    endPlaceHolder?: string;
    startReadOnly?: boolean;
    endReadOnly?: boolean;
    className?: string;
    timezone?: string;
    readOnly?: boolean;
    minDate?: Date;
    maxDate?: Date;
    placeHolder?: string;
    timeInterval?: number;
    position?: "top" | "bottom";
    showTimeSelect?:boolean;
};

export function IsoRangeDatePicker({
    onChangeStart,
    onChangeEnd,
    beginPlaceHolder,
    endPlaceHolder,
    begin,
    end,
    startReadOnly,
    endReadOnly,
    className,
    timezone,
    readOnly,
    minDate,
    maxDate,
    placeHolder,
    timeInterval = 15,
    position = 'bottom',
    showTimeSelect = true,
}: IsoRangeDatePickerProps) {

    const componentRef = useRef<any>(null);
    const { t } = useTranslation();
    
    const [startDatePickerOpened, setStartDatePickerOpened] = useState(false);
    const [endDatePickerOpened, setEndDatePickerOpened] = useState(false);
    
    const startDate = useMemo(() => begin ? new Date(begin) : null, [begin]);
    const endDate = useMemo(() => end ? new Date(end) : null, [end]);

    useEffect(() => {

        function handleClickOutside(event) {
            if (
                componentRef.current &&
                !componentRef.current.contains(event.target)
            ) {
                setStartDatePickerOpened(false);
                setEndDatePickerOpened(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [componentRef]);


    const startDatePicker = () => {
        const onChangeStartDate = (date: Date) => {
            if (readOnly || startReadOnly) return;
            onChangeStart && onChangeStart(date);
            if(endDate && date.getTime() >= endDate.getTime()) {
                onChangeEnd && onChangeEnd(null);
            }
        };

        return (
            <LocalizedDatePicker
                onChange={onChangeStartDate}
                readOnly={readOnly}
                minDate={
                    minDate &&
                    TimeHelper.isoDateToLocalJsDate(minDate, timezone)
                }
                maxDate={
                    maxDate &&
                    TimeHelper.isoDateToLocalJsDate(maxDate, timezone)
                }
                className={`${className ?? ""} rounded p-2`}
                selected={startDate}
                showPopperArrow={false}
                calendarStartDay={1}
                showMonthDropdown
                showYearDropdown
                showTimeSelect={showTimeSelect}
                dropdownMode="select"
                timeIntervals={timeInterval}
                inline
            />
        );
   
    };

    const endDatePicker = () => {


        const onChangeEndDate = (date) => {
            if (readOnly || endReadOnly) return;
            onChangeEnd && onChangeEnd(date);
        };

        const calculatedMinDate = (startDate &&
                        TimeHelper.isoDateToLocalJsDate(startDate, timezone)) ??
                    (minDate &&
                        TimeHelper.isoDateToLocalJsDate(minDate, timezone))

        const calculatedMaxDate = maxDate &&
                    TimeHelper.isoDateToLocalJsDate(maxDate, timezone)
    

        return (
            <LocalizedDatePicker
                onChange={onChangeEndDate}
                readOnly={readOnly}
                minDate={calculatedMinDate}
                maxDate={calculatedMaxDate}
                className={`${className ?? ""} rounded p-2`}
                selected={endDate}
                showPopperArrow={false}
                calendarStartDay={1}
                showMonthDropdown
                showYearDropdown
                showTimeSelect={showTimeSelect}
                dropdownMode="select"
                timeIntervals={timeInterval}
                inline
            />
        );
    };

    const getSegments = () => {

        const DateUILabel = (date, mode: "begin" | "end") => {

            if (!date) {
                return (
                    <div className="c-text-capitalize-first h-100 d-flex align-items-center justify-content-center ">
                        {t(mode === 'begin' ? (beginPlaceHolder ?? 'common.from') : (endPlaceHolder ?? 'common.to'))}
                    </div>
                );
            }

            return (
                <div className="px-3 pt-2 pb-1">
                    <div className="c-w-fit text-left">
                        <div className="d-flex justify-content-between">

                            <CaptionText
                                color="inherit"
                                className="text-capitalize c-line-height-1"
                            >
                                {t(
                                    mode === "begin"
                                        ? "common.from"
                                        : "common.to",
                                )}
                            </CaptionText>
                            {showTimeSelect && (<CaptionText
                                color="inherit"
                                className="text-capitalize text-right c-line-height-1"
                            >
                                {TimeHelper.localizeIsoStringToFormat(
                                    date,
                                    timezone,
                                    "HH:mm",
                                )}
                                h
                            </CaptionText>
                            )}
                        </div>
                        {TimeHelper.localizeIsoStringToFormat(
                            date,
                            timezone,
                            "dddd DD,  MMMM YYYY",
                        )}
                    </div>
                </div>
            );
        };

        if (!(startDate || endDate)) {
            return [
                {
                    label: placeHolder,
                    onClick: () => {
                        setEndDatePickerOpened(false);
                        setStartDatePickerOpened(true);
                    },
                },
            ];
        } else {
            return [
                {
                    label: DateUILabel(startDate, "begin"),
                    onClick: () => {
                        setEndDatePickerOpened(false);
                        setStartDatePickerOpened(true);
                    },
                },
                {
                    label: DateUILabel(endDate, "end"),
                    onClick: () => {
                        setStartDatePickerOpened(false);
                        setEndDatePickerOpened(true);
                    },
                },
            ];
        }
    };

    return (
        <div ref={componentRef}>
            <SegmentedControl
                preventDefaultSelect={true}
                readOnly={readOnly}
                segments={getSegments()}
            >
                {!(startDate || startDate) && (
                    <CaptionText>{placeHolder ?? ""}</CaptionText>
                )}
                {(startDatePickerOpened || endDatePickerOpened) && (
                    <div
                        style={{
                            bottom:
                                position === "top" ? "calc(100% + 6px)" : "",
                        }}
                        className={`${
                            startDatePickerOpened ? "left-0" : "right-0"
                        } position-absolute mt-2 c-z-index-full`}
                    >
                        {startDatePickerOpened && startDatePicker()}
                        {endDatePickerOpened && endDatePicker()}
                    </div>
                )}
            </SegmentedControl>
        </div>
    );
}
