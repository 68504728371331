import { StoreManager } from "./StoreManager";
import { EVEROU_URLS, WEBAPP_URLS } from "./_constants";
import { HistoryHelper, LocalStorageHelper } from "./_helpers";
import { EverouModules } from "./_modules/EverouModules";
import { Selectors } from "./_reducers/app.reducer";

export const EverouStoreSubscriptions = {
    subscribe,
};

function subscribe() {
    StoreManager.subscribeFnToStore(persistEverouDataToLocalStorage);
    StoreManager.subscribeFnToStore(persistEverouSelectedModuleToUrl);
    StoreManager.subscribeFnToStore(persistEverouDataToUrl);
}

function persistEverouSelectedModuleToUrl(getState) {
    HistoryHelper.pushToUrl(
        EverouModules.getUrlForModule(
            Selectors.getSelectedModuleId(getState())
        )
    );
}

function persistEverouDataToLocalStorage(getState) {
    LocalStorageHelper.combineState({
        everou: {
            global: {
                preselectedLocationUid: Selectors.getSelectedLocationUid(getState()),
            },
            filter: {
                filterMode: Selectors.getDeviceFilterFilterMode(getState()),
            },
            locationCustomization: Selectors.getCustomizationStore(getState()),
        }
    })
}

function persistEverouDataToUrl(getState) {
    const url = HistoryHelper.getCurrentUrl();

    if (!isPersistedUrl(url))
        return;

    switch (url) {
        case EVEROU_URLS.DEVICES:
        case EVEROU_URLS.LOGS: {
            HistoryHelper.updateDeviceQuery(
                Selectors.getMarkedDevice(getState()),
                Selectors.getSelectedLocationUid(getState()),
                Selectors.getDeviceFilterFilterMode(getState()),
            );
            break;
        }
        default:
            HistoryHelper.updateDeviceQuery(
                undefined,
                Selectors.getSelectedLocationUid(getState()),
                undefined,
            );
            break;
    }

    function isPersistedUrl(url) {
        const nonPersistedUrls = [
            WEBAPP_URLS.LOGIN,
            WEBAPP_URLS.REGISTER,
        ];

        return !nonPersistedUrls.includes(url);
    }
}