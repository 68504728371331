import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { ContentSpace } from "../_components";
import { subscriptionsActions } from "../_actions/subscriptions.actions";
import { SubscriptionsMainPage } from "./SubscriptionsMainPage";
import { SubscriptionAddEditView } from "./SubscriptionAddEditView";
import { SubscriptionsAddPage } from "./SubscriptionsAddPage";
import { SUBSCRIPTIONS_SECONDARY_VIEWS, SUBSCRIPTION_STATUS } from "../_constants/subscriptions.constants";
import { Selectors } from "../_reducers/app.reducer";
import { SubscriptionAddEditViewGeneric } from "./SubscriptionAddEditViewGeneric";
import { Subscription } from "../_models/Subscription";

export function SubscriptionsModule() {
    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid           = useSelector(Selectors.getSelectedLocationUid);
    const secondaryView         = useSelector(Selectors.getSubscriptionsModuleSecondaryView);
    let selectedSubscription    = Subscription();
    selectedSubscription        = useSelector(Selectors.getSelectedSubscription);


    //LOCAL STATE
    const selectedSubscriptionUid = selectedSubscription && selectedSubscription.uid;
    
    //ACTION HANDLERS
    const handleGoBack = useCallback(() => {
        dispatch(subscriptionsActions.changeSecondaryView(null));
    }, [dispatch]);

    //EFFECTS
    useEffect(() => {
        dispatch(subscriptionsActions.getSubscriptions());
    }, [dispatch, locationUid]);

    useEffect(() => {
        if (!selectedSubscriptionUid)
            return;

        const view = selectedSubscription.status === SUBSCRIPTION_STATUS.NEW
            ? SUBSCRIPTIONS_SECONDARY_VIEWS.NEW_SUBSCRIPTION_CONFIG
            : SUBSCRIPTIONS_SECONDARY_VIEWS.DETAILS
        ;

        dispatch(subscriptionsActions.changeSecondaryView(view));
    }, [dispatch, selectedSubscriptionUid, selectedSubscription]);

    //VIEW
    return (
        <ContentSpace
            mainContent={<SubscriptionsMainPage />}
            detailedContent={detailedContent()}
            isDetailedFolioEnabled={secondaryView}
            onGoBack={handleGoBack}
        />
    );

    function detailedContent() {
        switch (secondaryView) {
            case SUBSCRIPTIONS_SECONDARY_VIEWS.DETAILS:
                return <SubscriptionAddEditView
                    onGoBack={handleGoBack}
                    mode={SubscriptionAddEditViewGeneric.MODES.EDIT}
                />;

            case SUBSCRIPTIONS_SECONDARY_VIEWS.NEW_SUBSCRIPTION_CONFIG:
                return <SubscriptionAddEditView
                    onGoBack={handleGoBack}
                    mode={SubscriptionAddEditViewGeneric.MODES.ADD}
                />;

            case SUBSCRIPTIONS_SECONDARY_VIEWS.ADD:
                return <SubscriptionsAddPage />;
            
            default:
                return null;
        }
    }
}