export function GenericFieldFactory(valueId, initialValue = null, getRootState = state => state) {
    
    //ACTION TYPES
    const ACTION_TYPES = {
        SET: "SET_" + valueId,
        REQUEST: "REQUEST_" + valueId,
    };

    function setRootStateFn(rootStateFn = state => state) {
        getRootState = rootStateFn;
    }

    //ACTION
    function actionSet(value) {
        return {
            type: ACTION_TYPES.SET,
            value,
        };
    };

    function actionRequest() {
        return {
            type: ACTION_TYPES.REQUEST
        };
    };

    //SELECTORS
    function selectorGet(state) {
        return getRootState(state).value;
    }

    function selectorGetIsRequesting(state) {
        return getRootState(state).isRequesting;
    }

    function selectorGetStore(state) {
        return getRootState(state);
    }

    //REDUCER
    const initialState = {
        value: initialValue,
        isRequesting: false,
    };

    function reducer(state = initialState, action = {}) {

        if (!state.hydrated) {
            state = {
                ...initialState,
                ...state,
                hydrated: true,
            };
        }
    
        switch (action.type) {
            
            case ACTION_TYPES.REQUEST:
                return {
                    ...state,
                    isRequesting: true
                };

            case ACTION_TYPES.SET:
                return {
                    ...state,
                    value: action.value,
                    isRequesting: false,
                };

            default:
                return state;
        }
    }

    return {
        setRootStateFn,

        actionSet,
        actionRequest,

        selectorGet,
        selectorGetIsRequesting,
        selectorGetStore,

        reducer,
        ACTION_TYPES,
    };
}
