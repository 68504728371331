import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { globalActions } from "../_actions";
import { SubscriptionAddEditViewGeneric } from "./SubscriptionAddEditViewGeneric";
import { CapsuleButton } from "../_components/CapsuleButton";
import { subscriptionsActions } from "../_actions/subscriptions.actions";
import { EmptyGlobalPopUp } from "../_components";

const testIDs = {
    root: "add-subscription-pop-up",
    activateButton: "add-subscription-pop-up-activate-button",
    cancelButton: "add-subscription-pop-up-cancel-button",
};

AddSubscriptionPopUp.testIDs = testIDs;

export function AddSubscriptionPopUp() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    return (
        <EmptyGlobalPopUp
            content ={content()}
        />
    );

    function content() {
        return (
            <div
                data-testid={testIDs.root}
                className="my-2">
                {header()}
                <hr className="m-0 mb-1 mt-2"/>
                <SubscriptionAddEditViewGeneric
                    isAddingSubscriptionGlobally={true}
                    mode={SubscriptionAddEditViewGeneric.MODES.ADD}
                />
            </div>
        );
    }

    function header() {
        return (
            <div className="
                d-flex
                justify-content-between
                align-items-center

            ">
                <div className="
                    ml-1
                
                    c-new-ft-l
                    font-weight-bold
                    text-uppercase
                ">
                    {t("subscription_add_title")}
                </div>
                <div className="ml-2"/>
                <div className="d-flex">
                    <CapsuleButton
                        testId={testIDs.activateButton}
                        text={t("global_activate")}
                        onClick={handleActivate}
                    />
                    <span className="ml-2" />
                    <CapsuleButton
                        testId={testIDs.cancelButton}
                        text={t("global_cancel")}
                        style={`DANGER`}
                        onClick={handleCancel}
                    />
                </div>
            </div>
        );
    }

    //ACTION HANLDERS
    function handleCancel() {
        dispatch(globalActions.hidePopUp());
    }

    function handleActivate() {
        dispatch(subscriptionsActions.activate());
    }
}