import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const protectLogsService = {
    getProtectLogs,
};

async function getProtectLogs(
    locationUid,
    startDate,
    endDate,
    pageSize,
    page
) {
    const params = `\
        ?loc_uid=${locationUid}\
        &begin=${startDate}\
        &end=${endDate}\
        &page_size=${pageSize}\
        &offset=${page}\
    `;
    const paramsNoSpaces = params.replace(/\s/g, '');
    const response = await ServiceHelper.getRequest(URL_CONSTANTS.LOCATION_PROTECT_LOG, paramsNoSpaces);
    
    return {
        logs: response.logs,
        next: response.next,
    };
}