export enum LOCATION_PERMISSIONS_ENUM {
    NO_ACCESS = -1,
    OWNER = 0,
    ADMIN = 1,
    GUEST = 2,
    INSTALLER = 3,
}

export const LOCATION_PERMISSIONS = {
    NO_ACCESS: LOCATION_PERMISSIONS_ENUM.NO_ACCESS,
    OWNER:LOCATION_PERMISSIONS_ENUM.OWNER,
    ADMIN:LOCATION_PERMISSIONS_ENUM.ADMIN,
    GUEST:LOCATION_PERMISSIONS_ENUM.GUEST,
    INSTALLER:LOCATION_PERMISSIONS_ENUM.INSTALLER,
};


const UnifiedPermissions = {
    //INVITATIONS
    viewInvitations,                //USED

    createAdminInvitations,         //USED
    createInstallerInvitations,     //USED
    createGuestInvitations,         //USED

    mightManageSubinvitations,

    deleteInvitations,              //USED
    editInvitations,                //USED

    manageInvitationGroups,         //USED
    addPaidInvitationsToGroups,     //USED

    //BOOKINGS
    manageBookings,                 //USED
    createBookings,                 //USED

    //SUBSCRIPTIONS
    viewSubscriptions,              //USED
    addSubscriptions,               //USED
    editSubscriptions,              //USED
    manageSubscriptionPayments,

    //TRIGGERS
    viewTriggers,                   //USED
    createTriggers,
    deleteTriggers,
    editTriggers,

    //LOCATIONS
    editLocationCoordinates,
    editLocationNotificationPermission,
    editLocations,
    editMainSortingMode,
    requestSAT,                     //USED
    deleteLocations,                //USED
    cancelLocationInvitations,
    viewLocationStats,
    viewLocationUsers,
    viewLocationCompanyData,
    addDevelopers,                  //USED

    //TRANSFER
    transferLocations,

    //DEVICES
    addDevices,
    viewDeviceSettings,             //USED
    editDeviceSettings,             //USED
    viewAdvancedDeviceSettings,     //USED
    editAdvancedDeviceSettings,     //USED
    editDeviceRoom,                 //USED
    deleteDevices,
    mustCheckBarrierPermissions,
    viewDeviceActivity,             //USED
    viewDeviceAlerts,
    viewDeviceConsumption,
    updateDeviceFirmware,
    syncDeviceClock,

    //PROTECT
    manageProtect,                  //USED

    //ALERTS
    receiveDeviceUpdateAvailableAlerts,     //USED
    receiveDeviceClockAlerts,
    receiveBrainConnectionAlerts,           //USED
    receiveDeviceBatteryAlerts,             //USED
    receiveLowAutomaticOpenningsLeftAlerts, //USED
    receiveSystemPermissionsAlerts,

    //ROOMS
    createRooms,
    editDeleteRoom,

    //BLE
    mustDeleteBLEKey,

    //DHASBOARD
    viewDashbaord,                  //USED
    editDashboard,                  //USED

    //AUDIT LOGS
    viewAuditLogs,                  //USED

    //MOBILES
    viewMobiles,                    //USED

    //USERS STATUS
    viewUsersStatus,                //USED

    //CLOCKINS
    manageClockins,                 //USED

    //CUSTOMIZATION
    editCustomization,              //USED

    //BILLING
    manageBilling,                  //USED
};

function manageBilling(permission) {
    return [
        LOCATION_PERMISSIONS.OWNER,
    ].includes(permission);
}

function editCustomization(permission) {
    return [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ].includes(permission);
}

function manageClockins(permission) {
    return [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ].includes(permission);
}

function viewInvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}


function createAdminInvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
    ];
    return allowed.includes(permission);
}

function createInstallerInvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
    ];
    return allowed.includes(permission);
}

function createGuestInvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
    ];
    return allowed.includes(permission);
}

function mightManageSubinvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.GUEST,
    ];
    return allowed.includes(permission);
}

function deleteInvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
    ];
    return allowed.includes(permission);
}

function editInvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
    ];
    return allowed.includes(permission);
}

function manageInvitationGroups(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function addPaidInvitationsToGroups(permission) {
    return [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
    ].includes(permission);
}

function manageBookings(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function createBookings(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.GUEST,
    ];
    return allowed.includes(permission);
}

function viewSubscriptions(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function addSubscriptions(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editSubscriptions(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function manageSubscriptionPayments(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
    ];
    return allowed.includes(permission);
}

function viewTriggers(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function createTriggers(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function deleteTriggers(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editTriggers(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editLocationCoordinates(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editLocationNotificationPermission(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editLocations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editMainSortingMode(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
        LOCATION_PERMISSIONS.GUEST,
    ];
    return allowed.includes(permission);
}

function requestSAT(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function deleteLocations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
    ];
    return allowed.includes(permission);
}

function cancelLocationInvitations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
        LOCATION_PERMISSIONS.GUEST,
    ];
    return allowed.includes(permission);
}

function viewLocationStats(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function viewLocationUsers(permission) {
    return [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ].includes(permission);
}

function viewLocationCompanyData(permission) {
    return [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ].includes(permission);
}

function addDevelopers(permission) {
    return [
        LOCATION_PERMISSIONS.OWNER,
    ].includes(permission);
}

function transferLocations(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
    ];
    return allowed.includes(permission);
}

function addDevices(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function viewDeviceSettings(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editDeviceSettings(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function viewAdvancedDeviceSettings(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editAdvancedDeviceSettings(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editDeviceRoom(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function deleteDevices(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function mustCheckBarrierPermissions(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.GUEST,
    ];
    return allowed.includes(permission);
}

function viewDeviceActivity(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function viewDeviceAlerts(permission) {
    return (
        receiveDeviceUpdateAvailableAlerts(permission)
        || receiveDeviceClockAlerts(permission)
        || receiveBrainConnectionAlerts(permission)
        || receiveDeviceBatteryAlerts(permission)
        || receiveLowAutomaticOpenningsLeftAlerts(permission)
    );
}

function viewDeviceConsumption(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function updateDeviceFirmware(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function syncDeviceClock(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function manageProtect(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function receiveDeviceUpdateAvailableAlerts(permission) {
    return UnifiedPermissions.updateDeviceFirmware(permission);
}

function receiveDeviceClockAlerts(permission) {
    return UnifiedPermissions.syncDeviceClock(permission);
}

function receiveBrainConnectionAlerts(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function receiveDeviceBatteryAlerts(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function receiveLowAutomaticOpenningsLeftAlerts(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function receiveSystemPermissionsAlerts(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
        LOCATION_PERMISSIONS.GUEST,
    ];
    return allowed.includes(permission);
}

function createRooms(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editDeleteRoom(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function mustDeleteBLEKey(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.GUEST,
    ];
    return allowed.includes(permission);
}

function viewDashbaord(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function editDashboard(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
    ];
    return allowed.includes(permission);
}

function viewAuditLogs(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function viewUsersStatus(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

function viewMobiles(permission) {
    const allowed = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER,
    ];
    return allowed.includes(permission);
}

export const appPermissions = {

    //------LEGACY
    canUserSeeAllDevices,

    //-------UNIFIED

    //SUBSCRIPTIONS
    canUserSeeSubscriptions: UnifiedPermissions.viewSubscriptions,
    canUserActivateSubscriptions: UnifiedPermissions.addSubscriptions,
    canUserEditSubscriptions: UnifiedPermissions.editSubscriptions,
    canManageSubscriptionPayments: UnifiedPermissions.manageSubscriptionPayments,

    //DEVICES
    canUserViewDeviceActivity: UnifiedPermissions.viewDeviceActivity,
    canUserEditDeviceSettings: UnifiedPermissions.editDeviceSettings,
    canUserViewAdvancedDeviceSettings: UnifiedPermissions.viewAdvancedDeviceSettings,
    canUserEditAdvancedDeviceSettings: UnifiedPermissions.editAdvancedDeviceSettings,
    canUseEditDeviceRoom: UnifiedPermissions.editDeviceRoom,

    canUserViewDeviceSettings: UnifiedPermissions.viewDeviceSettings,

    //LOCATION
    canViewLocationUsers: UnifiedPermissions.viewLocationUsers,
    canViewLocationCompanyData: UnifiedPermissions.viewLocationCompanyData,
    canAddDevelopers: UnifiedPermissions.addDevelopers,


    //INVITATIONS
    canUserSeeInvitations,
    canUserManageInvitationGroups: UnifiedPermissions.manageInvitationGroups,
    canUserDeleteInvitations,
    canUserCreateInvitations,
    canUserEditInvitations,
    canSeePublicUrl,
    canAddPaidInvitationsToGroups: UnifiedPermissions.addPaidInvitationsToGroups,

    canUserDeleteLocation: UnifiedPermissions.deleteLocations,

    //RESERVATIONS
    canCreateBookings: UnifiedPermissions.createBookings,
    canUserConfigureReservations: UnifiedPermissions.manageBookings,

    //TRIGGERS
    canUserViewTriggers: UnifiedPermissions.viewTriggers,

    //ALERTS
    canUserViewAlerts,

    //DASHBOARD
    canViewDashboard: UnifiedPermissions.viewDashbaord,
    canUserEditDashboard: UnifiedPermissions.editDashboard,
    canUserUpdateOtaVersion,
    //USERS STATUS
    canUserSeeUsersStatus,

    //MOBILES
    canUserSeeMobiles,

    //AUDIT LOGS
    canUserSeeAuditLogs,

    // PAYMENTS
    canMakePayments,

    //REQUEST SAT
    canRequestSat: UnifiedPermissions.requestSAT,

    //CLOCKINS
    canManageClockins: UnifiedPermissions.manageClockins,

    //CUSTOMIZATION
    canManageCustomization: UnifiedPermissions.editCustomization,

    //BILLING
    canManageBilling: UnifiedPermissions.manageBilling,

    //PROTECT
    canManageProtect: UnifiedPermissions.manageProtect,


    // INTEGRATIONS
    canManageIntegrations,

    // BARRIERS
    canManageBarriers,
    // ROOMS
    canCreateRooms,

    // VACATION
    canManageVacationPromotional,
};

function canManageIntegrations(permission) {
    return permission === LOCATION_PERMISSIONS.OWNER;
}

function canUserSeeAllDevices(userPermission) {
    const allowedUsers = [
        LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.ADMIN,
        LOCATION_PERMISSIONS.INSTALLER,
    ];

    // Guest might also see all devices if is invited to whole location

    return allowedUsers.includes(userPermission);
}

function canCreateRooms(permission) {
    return [LOCATION_PERMISSIONS.OWNER, LOCATION_PERMISSIONS.INSTALLER].includes(permission);
}


function canUserViewAlerts(permission) {
    return (
        UnifiedPermissions.receiveDeviceBatteryAlerts(permission)
        || UnifiedPermissions.receiveDeviceUpdateAvailableAlerts(permission)
        || UnifiedPermissions.receiveBrainConnectionAlerts(permission)
        || UnifiedPermissions.receiveLowAutomaticOpenningsLeftAlerts(permission)
    );
}

function canUserSeeUsersStatus(permission) {
    return UnifiedPermissions.viewUsersStatus(permission);
}

function canUserSeeMobiles(permission, isSat) {
    if (!isSat)
        return false;

    return UnifiedPermissions.viewMobiles(permission);
}

function canUserSeeAuditLogs(permission, isSat) {
    if (!isSat)
        return false;

    return UnifiedPermissions.viewAuditLogs(permission);
}


function canUserEditInvitations(permission, isSuperguest) {
    if (isSuperguest) return false;

    return UnifiedPermissions.editInvitations(permission);
}

function canSeePublicUrl(permission, isSuperguest) {
    if (isSuperguest)
        return false;

    return canUserEditInvitations(permission, isSuperguest) || permission === LOCATION_PERMISSIONS.INSTALLER;
}

function canUserUpdateOtaVersion(permission, isSuperuser, isSat) {
    return (
        [LOCATION_PERMISSIONS.INSTALLER, LOCATION_PERMISSIONS.OWNER].includes(
            permission,
        ) &&
        (isSuperuser || isSat)
    );
}

function canUserSeeInvitations(permission, isSuperguest) {
    if (isSuperguest)
        return true;

    return UnifiedPermissions.viewInvitations(permission);
}

function canUserDeleteInvitations(permission, isSuperguest) {
    if (isSuperguest)
        return true;

    return UnifiedPermissions.deleteInvitations(permission);
}

function canUserCreateInvitations(permission) {
    return (
        UnifiedPermissions.createAdminInvitations(permission)
        || UnifiedPermissions.createInstallerInvitations(permission)
        || UnifiedPermissions.createGuestInvitations(permission)
    );
}


function canMakePayments(permission) {
    return [LOCATION_PERMISSIONS.OWNER, LOCATION_PERMISSIONS.ADMIN].includes(permission);
}

function canManageBarriers(permission) {
    return [LOCATION_PERMISSIONS.OWNER, LOCATION_PERMISSIONS.ADMIN, LOCATION_PERMISSIONS.INSTALLER].includes(permission);
}

function canManageVacationPromotional(permission) {
    return [LOCATION_PERMISSIONS.OWNER,
        LOCATION_PERMISSIONS.INSTALLER].includes(permission);
}