import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { dashboardActions } from "../_actions/dashboard.actions";
import { PopUp } from "./PopUp";
import { FullHeightSettings } from "../_components/FullHeightSettings";
import { Selectors } from "../_reducers/app.reducer";

export function BaseWidgetSettings({ title, content }) {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const dashboardSettings = useSelector(Selectors.getDashboardSettings);
    const edited = dashboardSettings.edited;

    //ACTION HANDLERS
    function handleCloseSettings() {
        dispatch(dashboardActions.hideSettings());
    }

    function handleSaveSettings() {
        dispatch(dashboardActions.saveSelectedWidgetSettings());
    }

    //VIEW
    return (
        <PopUp
            isFullHeight
            handleClose={handleCloseSettings}
            content={
                <FullHeightSettings
                    title={title}
                    handleClose={handleCloseSettings}
                    content={content}
                    handleSaveSettings={handleSaveSettings}
                    edited={edited}
                />
            }
        />
    );
}