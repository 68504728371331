import { useCallback } from "react";
import { useDispatch} from "react-redux";

import { INVITATION_TYPES } from "../../_constants";
import { Invitation } from "../../_models/Invitation";
import { AccessSelectorComponent2 } from "./AccessSelectorComponent2";
import { MultipleRoomSelector } from "./MultipleRoomSelector";
import { MultipleRoomViewer } from "./MultipleRoomViewer";
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";

export function AccessSelectorWithRooms({
    isNew = false,
    invitation = Invitation(),
    rooms,
    isEditable = false,
    setIsValid,
    forceShowWarnings
} = {}) {

    //LIBRARIES
    const dispatch = useDispatch();

    //HANDLERS
    const handleChangeRooms = useCallback(
        (rooms) => {
            dispatch(invitationGroupActions.changeRooms(rooms));
        },
        [dispatch],
    );

    //VIEW
    return (
        <div>
        <AccessSelectorComponent2
            isReadOnly={!isNew}
            type={invitation.type}
            permissionType={invitation.permissionType}
        />
        <div className="mt-1"/>
        {roomSelector()}
        </div>
    );

    function roomSelector() {
        if (invitation.type !== INVITATION_TYPES.ROOM)
            return null;

        return (
            <>
            <div className="mt-2"/>
            {isEditable
                ? <MultipleRoomSelector
                    rooms={rooms}
                    onChangeRooms={handleChangeRooms}
                    selectedRooms={invitation.rooms}
                    forceShowWarnings={forceShowWarnings}
                    setIsValid={setIsValid}
                />
                : <MultipleRoomViewer
                    rooms={rooms}
                    selectedRooms={invitation.rooms}
                />
            }
            </>
        );
    }
}