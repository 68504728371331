import { DevOauthStore } from "../_stores/DevOauthStore";
import { genericActions } from "../../Everou/_actions/generic.actions";
import { DevOauthMapper } from "../_mappers/DevOauthMapper";
import { DevOauthService } from "../_services/DevOauthService";

export const DevOauthActions = {
    getOauthData,
    updateAppName,
    updateRedirectUris,
};

function getOauthData() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(DevOauthStore.actionSet(
                DevOauthMapper.serverToLocal(
                    await DevOauthService.get()
                )
            ))
        }
    };
}

function updateAppName(appName) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(DevOauthStore.actionSet(
                DevOauthMapper.serverToLocal(
                    await DevOauthService.updateAppName(appName)
                )
            ))
        }
    };
}

function updateRedirectUris(redirectUris) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(DevOauthStore.actionSet(
                DevOauthMapper.serverToLocal(
                    await DevOauthService.updateRedirectUris(redirectUris)
                )
            ))
        }
    };
}