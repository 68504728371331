import { Entry } from "../_models/Entry"
import { ProductMapper } from "./ProductMapper";

export class EntryMapper {
    static allServerToLocal(serverEntries = []) {
        return serverEntries.map(serverToLocal);
    }

    static serverToLocal(serverEntry = {}) {
        return serverToLocal(serverEntry);
    }
}

function serverToLocal(serverEntry) {
    return Entry({
        ...serverEntry,

        grossPrice: serverEntry.gross_price,
        netPrice: serverEntry.net_price,
        product: ProductMapper.serverToLocal(serverEntry.product),
    });
}