import { PdfIcons } from "../../Common/_assets/pdf_icons/PdfIcons";
import { PDFs } from "../../Common/_assets/pdf/PDFs";
import { InfoDocument } from "../_models/InfoDocument";

export const documents = [
    InfoDocument({
        fileUrl: PDFs.ditributorGuide,
        
        icon: PdfIcons.ditributorGuide,
        name: "Guía para distribuidores",
    }),
    InfoDocument({
        fileUrl: PDFs.products,

        icon: PdfIcons.products,
        name: "Catálogo de productos",
    }),
];