import React, { useCallback } from "react";

import { SectionsSidebar } from "../../Common/_components/SectionsSidebar";
import { WiredContentSpace } from "./WiredContentSpace";

export function WiredContentWithSectionsSidebar({
    testID,
    sections = [],
    getComponentForModuleId,
    getModuleById,
    selectModuleAction,
}) {

    const SidebarComp = useCallback(({
        selectedModuleId,
        handleSelectModule,
        enabledModulesIds,
    }) => <SectionsSidebar {...{
            sections,
    
            selectedModuleId,
            handleSelectModule,
            enabledModulesIds,
            getModuleById,
        }}/>
    , [sections, getModuleById]);

    //VIEW
    return (
        <WiredContentSpace {...{
            testID,
            SidebarComp,
            getComponentForModuleId,
            selectModuleAction,
        }}/>
    );
}