export function LocalAuditPathModel({
    type,
    actionPath,
    path,
} = {}) {
    return {
        type,
        actionPath,
        path,
    };
}