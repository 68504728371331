import { genericActions } from "../../Everou/_actions/generic.actions";
import { DevUserMapper } from "../_mappers/DevUserMapper";
import { DevUserService } from "../_services/DevUserService";
import { DevUserStore } from "../_stores/DevUserStore";

export const DevUserActions = {
    getUser,
};

function getUser() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(DevUserStore.actionSet(
                DevUserMapper.serverToLocal(
                    await DevUserService.get()
                )
            ))
        }
    };
}