import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { CustomNumberInput } from '../../../Common/_components';
import { SegmentedControl, SimpleToggle } from "../../_components";
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";
import { TimeHelper } from "../../_helpers";
import { CaptionText } from "../../../Common/_components/CaptionText";

export const GroupDurationSelectorTesting = {
    maxDurationFn,
    localToServerDuration,
    limitAmount,
};

//CONSTANTS
GroupDurationSelector.TIME_PERIODS = {
    DAYS: "DAYS",
    MONTHS: "MONTHS",
};

const TEST_IDS = {
    ROOT: "group-duration-selector",
    DROPDOWN: "group-duration-selector-dropdown",
    TOGGLE: "group-duration-selector-toggle",
    TIME_INPUT: "group-duration-selector-time-input",
};

GroupDurationSelector.TEST_IDS = TEST_IDS;

export function GroupDurationSelector({
    isVisible = true,
    forceEnabled = false,
    maxDuration = undefined
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const minDuration = 1;
    const defaultPeriod = (maxDuration !== undefined && maxDuration < 30) 
    ? GroupDurationSelector.TIME_PERIODS.DAYS
    : GroupDurationSelector.TIME_PERIODS.MONTHS;

    const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
    const [selectedAmount, setSelectedAmount] = useState(limitAmount(minDuration, maxDuration, defaultPeriod, maxDurationFn(defaultPeriod)));
    const [isDurationEnabled, setIsDurationEnabled] = useState(false);

    //HANDLERS
    const handleChangeAmount = useCallback((amount, period) => {
        setSelectedAmount(limitAmount(minDuration, maxDuration, period, amount));
    }, [maxDuration]);

    const handleChangePeriodType = useCallback(period => {
        setSelectedPeriod(period);
        handleChangeAmount(maxDurationFn(period), period);
    }, [handleChangeAmount]);

    const handleToggleIsDurationEnabled = useCallback(() => {
        const newIsDurationEnabled = !isDurationEnabled;
        setIsDurationEnabled(newIsDurationEnabled);

        newIsDurationEnabled
            ? handleChangePeriodType(GroupDurationSelector.TIME_PERIODS.MONTHS)
            : dispatch(invitationGroupActions.changeDuration(undefined))
        ;
    }, [dispatch, handleChangePeriodType, isDurationEnabled]);

    //EFFECTS
    useEffect(() => {
        if (!isDurationEnabled)
            return;

        dispatch(invitationGroupActions.changeDuration(
            localToServerDuration(selectedAmount, selectedPeriod)
        ));
    }, [dispatch, selectedAmount, selectedPeriod, isDurationEnabled]);

    //INTIIALIZATION
    useEffect(() => {
        if (!forceEnabled)
            return;

        setIsDurationEnabled(true);
    }, [forceEnabled]);

    //VIEW
    if (!isVisible)
        return null;

    return (
        <div data-testid={TEST_IDS.ROOT}>
            {durationToggle()}
            <div className="mt-1" />
            {isDurationEnabled && (
                <>
                    {durationSelector()}
                    
                </>
            )}
        </div>
    );

    function durationToggle() {
        return (
            <div className="d-flex justify-content-between">
                <h3>{t("invitation_group_limit_duration")}</h3>

                {!forceEnabled &&
                    <SimpleToggle
                        testID={TEST_IDS.TOGGLE}
                        checked={isDurationEnabled}
                        handleChange={handleToggleIsDurationEnabled}
                    />
                }
            </div>
        );
    }

    function durationSelector() {
        return (
            <>
                <div className="d-flex mt-2 align-items-center justify-content-between">
                    <div className="w-100 mr-2">{periodTypeSelector()}</div>
                    <div className="c-w-min">{timeAmountSelector()}</div>
                </div>
                <CaptionText className="mt-1">
                    {t("invitation_group_duration_hint")}
                </CaptionText>
            </>
        );
    }

    function periodTypeSelector() {
        return (
            <SegmentedControl
                className="w-100 h-min"
                segments={segmentsFn()}
                onSelect={handleChangePeriodType}
                defaultSelected={{ value: GroupDurationSelector.TIME_PERIODS.MONTHS }}
                testId={TEST_IDS.DROPDOWN}
                readOnly={maxDuration !== undefined && maxDuration < 30}
            />
        );
        function segmentsFn() {
            if (maxDuration !== undefined && maxDuration < 30)
                return [
                    {
                        value: GroupDurationSelector.TIME_PERIODS.DAYS,
                        label: t("global_days"),
                    },
                ];

            return [
                {
                    value: GroupDurationSelector.TIME_PERIODS.DAYS,
                    label: t("global_days"),
                },
                {
                    value: GroupDurationSelector.TIME_PERIODS.MONTHS,
                    label: t("global_months"),
                },
            ];
        }
    }
    
    function timeAmountSelector() {
        return (
            <CustomNumberInput
                onChange={value => handleChangeAmount(value, selectedPeriod)}
                value={selectedAmount}
                min={0}
                max={maxDurationFn(selectedPeriod)}
                testId={TEST_IDS.TIME_INPUT}
            />
        );
    }


}

function maxDurationFn(period, maxDuration) 
{
    const maxMonths = (maxDuration === undefined) ? 12 : Math.floor(maxDuration/30);
    const maxDays = (maxDuration === undefined) ? 30 : Math.min(maxDuration, 30);

    return period === GroupDurationSelector.TIME_PERIODS.DAYS 
    ? maxDays 
    : maxMonths;
}

function localToServerDuration(amount, periodType) {
    switch (periodType) {
        case GroupDurationSelector.TIME_PERIODS.DAYS:
            return TimeHelper.durationDaysToIso(amount);
            
        case GroupDurationSelector.TIME_PERIODS.MONTHS:
            return TimeHelper.durationMonthsToIso(amount);

        default:
            return undefined;
    }
}

function limitAmount(minDuration, maxDuration, period, amount) {
    return Math.max(minDuration, Math.min(maxDurationFn(period, maxDuration), amount));
}