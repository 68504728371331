import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { DevelopersGlobalPopUp } from "../_components";
import { DevTokensActions } from "../_actions/DevTokensActions";

export function RemoveTokenPopUp({
    uid,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HANDLERS
    function handleRemoveToken() {
        dispatch(DevTokensActions.remove(uid));
    }

    //VIEW
    return (
        <DevelopersGlobalPopUp
            onCancel={handleRemoveToken}
            title={t("mod_dev_tokens_remove_confirmation_title")}
            body={t("global_cannot_undo")}
            cancelButtonName={t("webapp_global_remove")}
            acceptButtonName={t("global_cancel")}
        />
    );
}