import { genericActions } from "../../Everou/_actions/generic.actions";
import { DevRoomsMapper } from "../_mappers/DevRoomsMapper";
import { DevRoomsService } from "../_services/DevRoomsService";
import { DevRoomsStore } from "../_stores/DevRoomsStore";

export const DevRoomsActions = {
    list,
};

function list() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncFn));

        async function asyncFn() {
            dispatch(DevRoomsStore.actionListItemsSuccess(
                DevRoomsMapper.serverToLocal(
                    await DevRoomsService.list()
                )
            ));
        }
    };
}