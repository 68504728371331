import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const invitationCardService = {
    getCardInfo,
    acceptCardInvitation,
};

function getCardInfo(cardCode) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.CARD_INFO,
        "?card_code=" + cardCode,
    );
}

async function acceptCardInvitation(cardCode) {
    const response = await ServiceHelper.postRequest(
        URL_CONSTANTS.CARD_INVITATION,
        {
            card_code: cardCode,
        },
    );

    return {
        invitations: response.invitations,
        state: response.state,
    };
}