import { authenticationActions } from './_actions';

export const TabSynchronizationManager = {
    subscribeSynchronizationToStorage,
};

function subscribeSynchronizationToStorage(_store) {
    window.addEventListener("storage", e => {
        if (e.key !== "state")
            return;
        
        const oldValue = e.oldValue;
        const newValue = e.newValue;

        //CHECK STORE DELETED BY LOG-OUT ON ANOTHER TAB
        if (newValue === null) {
            window.location.reload();
            return;
        }

        if (!oldValue || !newValue)
            return;

        updateApikeyUpdateDate(oldValue, newValue, _store);
        updateApikeyOnOtherTabChange(oldValue, newValue, _store);
    });
}

function updateApikeyOnOtherTabChange(oldValue, newValue, _store) {
    try {
        const oldUser = getUserFromStoreObject(JSON.parse(oldValue));
        const newUser = getUserFromStoreObject(JSON.parse(newValue));
        if (!shouldUpdateApikey(oldUser, newUser))
            return;

        _store.dispatch(
            authenticationActions.updateUser(newUser)
        );
    } catch (e) {
        console.error("error synching apikey between tabs", e);
    }

    function getUserFromStoreObject(storeObject) {
        return (
            storeObject.common
            && storeObject.common.authentication
            && storeObject.common.authentication.user
        )
    }

    function shouldUpdateApikey(oldUser, newUser) {
        if (!newUser || !newUser.apikey)
            return false;

        if (!oldUser)
            return true;

        if (oldUser.apikey !== newUser.apikey)
            return true;

        return false;
    }
}

function updateApikeyUpdateDate(oldValue, newValue, _store) {
    try {
        const oldDate = getDateFromStoreString(oldValue);
        const newDate = getDateFromStoreString(newValue);
        if (!shouldUpdateDate(oldDate, newDate))
            return;

        _store.dispatch(
            authenticationActions.setApikeyUpdateDate(newDate)
        );
    } catch (e) {
        console.error("error synching apikey update date between tabs", e);
    }

    function getDateFromStoreString(storeString) {
        const storeObject = JSON.parse(storeString);
        return (
            storeObject.common
            && storeObject.common.authentication
            && storeObject.common.authentication.lastApikeyUpdate
        )
    }

    function shouldUpdateDate(oldDate, newDate) {
        if (newDate === undefined)
            return false;

        if (oldDate !== newDate)
            return true;

        return false;
    }
}