import { UserStatusData } from "../_models/UserStatusData";
import { UserStatusMobileMapper } from "./UserStatusMobileMapper";
import { UserStatusDeviceDataMapper } from "./UserStatusDeviceDataMapper";
import { MobileMapper } from "./MobileMapper";

export class UserStatusDataMapper {
    static serverToLocal(serverUserStatus) {
        return UserStatusData({
            mobile: MobileMapper.serverToLocal(
                serverUserStatus.mobile,
            ),
            mobileChanges: UserStatusMobileMapper.serverToLocal(
                serverUserStatus.mobile_changes,
            ),
            devicesData: UserStatusDeviceDataMapper.allServerToLocal(
                serverUserStatus.devices_data,
            ),
        });
    }
}


