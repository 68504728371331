import { CaptionText } from "../../../Common/_components";
import React from "react";
import { useTranslation } from 'react-i18next';

import { TimeHelper } from "../../_helpers";

export function GroupDurationRepresentation({ duration } = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    if (!duration)
        return null;

    //VIEW
    return (
        <div>
            <div className="d-flex justify-content-between">
                <h3 className="mb-0">{t("invitation_group_duration")}</h3>
                <div>{TimeHelper.durationServerToUi(duration)}</div>
            </div>
            <CaptionText>{t("invitation_group_duration_hint")}</CaptionText>
        </div>
    );
}