import { SegmentedControl } from '../../Common/_components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../Everou/_reducers/app.reducer';
import { PMSLocationDetailViewModes } from '../_constants/pmsLocationsViewMode.constants';
import { viewModeNames } from '../_constants/pmsLocationsViewMode.constants';
import { viewModeActions } from '../_actions/viewMode.actions';


export function PMSLocationDetailSelector() {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const viewMode = useSelector(Selectors.getPMSLocationDetailViewMode);

    return (
        <div className="d-flex">
            <SegmentedControl
                preventDefaultSelect
                defaultSelected={{
                    value: viewMode
                }}
                onSelect={handleChangeViewMode}
                segments={segments()}
            />
        </div>
    );

    function segments() {
        return Object.keys(PMSLocationDetailViewModes).map((viewMode) => ({
            label: viewModeNames[viewMode],
            value: viewMode,
        }));
    }

    function handleChangeViewMode(viewMode) {
        dispatch(viewModeActions.changeMode(viewMode));
    }
}