import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DeviceLogsColumn } from "../DevicesModule/DeviceLogsColumn";
import { FiltersColumn } from "../DevicesModule/FiltersColumn";
import { devicesActions, locationActions } from "../_actions";
import { ContentSpace, MainPageWithButtons } from "../_components";
import { Selectors } from "../_reducers/app.reducer";
import { CapsuleButton } from '../_components/CapsuleButton';
import { Icons } from "../_assets";
import { deviceLogsActions } from '../_actions/deviceLogs.actions';


export function DeviceLogsModule() {
    
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const filterMode                = useSelector(Selectors.getDeviceFilterFilterMode);
    const categories                = useSelector(Selectors.getDeviceFilterCategories);
    
    const markedDevice              = useSelector(Selectors.getMarkedDevice);
    const devices                   = useSelector(Selectors.getSelectedLocationDevices);
    const rooms                     = useSelector(Selectors.getRooms);
    const locationUid               = useSelector(Selectors.getSelectedLocationUid);
    const handleRefreshLogs         = () => dispatch(deviceLogsActions.getDeviceLogsFirstPage());


    //INITIALIZATION
    useEffect(() => {
        dispatch(devicesActions.initializeDevices(
            markedDevice,
            filterMode,
            devices,
            rooms,
        ));
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid]);

    useEffect(() => {
        dispatch(locationActions.getLocationUsers(locationUid));
    }, [dispatch, locationUid]);

    //UPDATES
    useEffect(() => {
        dispatch(devicesActions.showDevicesOfSelectedCategories(
            devices,
            categories,
            filterMode,
        ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, categories]);

    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    size={'LARGE'}
                    pageTitle={t("logs.logs")}
                    content={logsContent()}
                    buttons={[<CapsuleButton
                        icon={Icons.refresh}
                        onClick={handleRefreshLogs}
                    />]}
                />
            }
        />
    );

    function logsContent() {
        return (
            <div className="
                h-100
                d-flex
                no-gutters
            ">
                <div className="col-3">
                    <FiltersColumn
                        showDeviceFilter={true}
                    />
                </div>
                <div className="ml-2"/>
                <DeviceLogsColumn />
            </div>
        );
    }

    
}