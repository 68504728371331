import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { InlineEditButtons } from "../../Everou/_components/InlineEditButtons";
import { ValidatedTextInput } from "../../Common/_components/ValidatedTextInput";
import { ReadOnlyDataRow, FileUploadComponent, SimpleToggle } from "../../Everou/_components";
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { LocationCustomization } from "../../Everou/_models/LocationCustomization";
import { HuePicker, SketchPicker } from "react-color";
import { WaitSpinner } from "../../Everou/_components/WaitSpinner";
import { PMSLocationsActions } from "../_actions/PMSLocationsActions";
import { WithContext as ReactTags } from "react-tag-input";
import { IntegrationConfig } from "./_components/IntegrationConfig";

export function PMSLocationConfiguration() {

    // LIBRARIES
    const {t} = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid = useSelector(Selectors.getPMSLocationSelectedUid);
    const locationInfo = useSelector(Selectors.getPMSLocationInfo);
    const locations = useSelector(Selectors.getPMSLocations);

    const [tags, setTags] = useState([]);

    let locationCustomization = LocationCustomization();
    locationCustomization = useSelector(Selectors.getPMSLocationCustomization);
    const isUploading = useSelector(Selectors.getPMSCustomizationIsUploading);

    const [accentColor, setAccentColor] = useState((locationCustomization && locationCustomization.accentColor) || "#000000");
    const [headerColor, setHeaderColor] = useState((locationCustomization && locationCustomization.headerColor) ||"#000000");

    const [travelerDiscountLocal, setTravelerDiscountLocal] = useState(locationInfo.travelerDiscount);

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    // INITIALIZATION
    useEffect(() => {
        var i=0;
        for (i=0; i<locations.items.length; i++) {
            if (locations.items[i].uid === locationUid) {
                setTags(locations.items[i].tags);
                return;
            }
        }
    }, [locations, locationUid])
    useEffect(() => {
        dispatch(PMSLocationsActions.getCustomization());
        
    }, [dispatch, locationUid]);

    useEffect(() => {
        setAccentColor(locationCustomization.accentColor || "#000000");
        setHeaderColor(locationCustomization.headerColor || "#000000");
    }, [locationCustomization]);

    //ACTION HANDLERS
    function handleUploadLogo(logo) {
        dispatch(PMSLocationsActions.uploadLogo(logo));
    }

    function handleUploadBackground(logo) {
        dispatch(PMSLocationsActions.uploadBackground(logo));
    }

    function handleChangeAccentColor(hexColor) {
        dispatch(PMSLocationsActions.updateCustomization(hexColor));
    }
    function handleChangeHeaderColor(hexColor) {
        dispatch(PMSLocationsActions.updateCustomization(undefined, hexColor));
    }

    function handleUploadVideo(video) {
        dispatch(PMSLocationsActions.uploadVideo(video, locationInfo.uid));
    }

    //{locationInfo.allowsCustomization && customizationContent()}
    return (
        <div
            className="
                h-100
                d-flex
                no-gutters
                flex-column
            "
        >
            <NameInput
                {...{
                    key: locationInfo.name,
                    initialValue: locationInfo.name,
                    locationUid,
                }}
            />
            {dataRow(t("common.timezone"), locationInfo.timezone)}
            {dataRow("Id", locationInfo.locationId)}
            {tagsSection()}
            {locationInfo.hasProtectSubscription && protectSection()}
            {servicesSection(locationInfo.vacationRentalPlan)}
            {integrationsSection()}
        </div>
    )

    function NameInput({ initialValue, locationUid }) {

        //LIBRARIES
        const { t } = useTranslation();
        const dispatch = useDispatch();
    
        //ACTION HADLERS
        function handleSave(text) {
            dispatch(PMSLocationsActions.updateLocationName(text, locationUid));
        }
    
        return (
            <DataWrapper hasSeparator={false}>
                <div
                    className="d-flex align-items-center justify-content-between"
                >
                    <div>
                        {t("global_name")}
                    </div>
                    <InlineValidatedTextInput
                        handleSave={handleSave}
                        initialValue={initialValue}
                        required={true}
                    />
                    
                </div>
            </DataWrapper>
        );
    }

    function dataRow(name, data) {
        return <ReadOnlyDataRow
            name={name}
            data={data}
        />;
    }

    function DataWrapper({
        children,
        hasSeparator = true,
        key,
    }) {
        return (
            <div key={key}>
                <div className="p-1">
                    {children}
                </div>
                {hasSeparator && <hr className="m-0"/>}
            </div>
        );
    }

    function InlineValidatedTextInput({
        inputTestID,
        saveButtonTestID,
    
        handleSave,
        initialValue,
    
        required,
    } = {}) {
    
        //LOCAL STATE
        const [text, setText] = useState(initialValue);
        const [isValid, setIsValid] = useState(false);
        const [isEdited, setIsEdited] = useState(false);
        
        //OVERRIDING
    
        useEffect(() => {
            setIsEdited(text !== initialValue);
        }, [text, initialValue]);
    
        //ACTION HADLERS
        function handleDiscardChanges() {
            setText(initialValue);
        }
    
        function handleSaveChanges() {
            handleSave(text);
        }
    
        return (
            <div className="d-flex">
                {isEdited &&
                    <InlineEditButtons
                        acceptButtonTestID={saveButtonTestID}
                        isEnabled={isValid}
                        handleDiscardChanges={handleDiscardChanges}
                        handleConfirmChanges={handleSaveChanges}
                    />
                }
                <ValidatedTextInput
                    isRequired={required}
                    isEditable
    
                    testID={inputTestID}
                    value={text}
                    setValue={setText}
                    setIsValid={setIsValid}
                />
            </div>
        );
    }

    function customizationContent() {
        return (
            <div>
                <div className="mt-1"/>
                {headerColorSelectorSection()}
                <div className="mt-3"/>
                {accentColorSelectorSection()}
                <div className="mt-3"/>
                
                {imageUploadSection(
                    t("mod_customization_location_logo"),
                    locationCustomization && locationCustomization.logo,
                    handleUploadLogo,
                    isUploading,
                )}

                <div className="mt-3"/>

                {imageUploadSection(
                    t("mod_customization_login_background"),
                    locationCustomization && locationCustomization.loginBackground,
                    handleUploadBackground,
                    isUploading,
                )}
            </div>
        );
    }

    function headerColorSelectorSection() {
        return (
            <div>
                {titleFn(t("mod_customization_header_color"))}
                <div className="d-flex justify-content-center">
                    <SketchPicker
                        color={headerColor}
                        onChangeComplete={color => handleChangeHeaderColor(color.hex)}
                        onChange={color => setHeaderColor(color.hex)}
                        presetColors={[]}
                        disableAlpha
                    />
                </div>
            </div>
        );
    }

    function accentColorSelectorSection() {
        return (
            <div>
                {titleFn(t("mod_customization_accent_color"))}
                <div className="mt-1"/>
                <div className="d-flex justify-content-center my-2 cp">
                    <HuePicker
                        color={accentColor}
                        onChangeComplete={color => handleChangeAccentColor(color.hex)}
                        onChange={color => setAccentColor(color.hex)}
                        width={"90%"}
                    />
                </div>
            </div>
        );
    }

    function imageUploadSection(title, img, handleUpload, isUploading) {
        return (
            <div>
                {titleFn(title)}
                {img &&
                    <>
                    <div className="mt-1"/>
                    <div className="
                        d-flex
                        justify-content-center
                        align-items-center
                    ">
                        {
                            isUploading
                            ? <WaitSpinner />
                            : <img
                                className="
                                    mw-100
                                    c-mh-15rem

                                    rounded
                                    border
                                    bg-light
                                    p-2
                                "
                                alt="ditributor_avatar"
                                src={img}
                            />
                        }
                    </div>
                    </>
                }
                <div className="mt-1"/>
                <FileUploadComponent
                    onUpload={handleUpload}
                    isUploading={isUploading}
                />
            </div>
        );
    }

    function titleFn(title) {
        return (
            <div className="font-weight-bold text-uppercase">
                {title}
            </div>
        );
    }

    function tagsSection() {
        return (
            <div>
                {title("TAGS")}
                <div
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                    className="mt-2 border c-rounded-big bg-white"
                >
                    <ReactTags
                        tags={tags?.map((tag) => ({ id: tag, text: tag }))}
                        delimiters={[KeyCodes.comma, KeyCodes.enter]}
                        handleDelete={handleDeleteTag}
                        handleAddition={handleAddTag}
                        handleInputBlur={handleOnBlurTag}
                        placeholder="Tags"
                        allowDragDrop={false}
                        inputFieldPosition="top"
                    />
                </div>
            </div>
        );
    }

    function title(title) {
        return (
            <div
                className="
                c-new-ft-l
                text-uppercase
                font-weight-bold
                py-2
            "
            >
                {title}
            </div>
        );
    }

    function handleAddTag({text}) {
        if (text)
            dispatch(PMSLocationsActions.createNewLocationTag(text, locationUid));
    }

    function handleDeleteTag(i) {
        const tag = tags[i];
        dispatch(PMSLocationsActions.deleteLocationTag(tag, locationUid));
    }

    function handleOnBlurTag(text) {
        handleAddTag({text});
    }

    function protectSection() {
        return (
            <div>
                {title("ALARMA")}
                <div className="mt-2 mb-2 cp d-flex align-items-center justify-content-between">
                    <div className="position-relative">
                        Activar alarma
                    </div>
                    <div className="ml-2"></div>
                    <SimpleToggle
                        checked={locationInfo.isProtectOn}
                        handleChange={({ detail: { value } }) => {
                            handleProtect(value);
                        }}
                    ></SimpleToggle>
                </div>
                {locationInfo.vacationRentalPlan === 3 &&
                    <div className="mt-2 mb-2 cp d-flex align-items-center justify-content-between">
                        <div className="position-relative">
                            Activar alarma automáticamente cuando no haya reservas
                        </div>
                        <div className="ml-2"></div>
                        <SimpleToggle
                            checked={locationInfo.protectAutoEnabledWithoutReservations}
                            handleChange={({ detail: { value } }) => {
                                handleEnableAutoProtect(value);
                            }}
                        ></SimpleToggle>
                    </div>
                }
            </div>
        );
    }


    function handleProtect(enabled) {
        dispatch(PMSLocationsActions.changeProtectStatus(enabled, locationUid));
    }

    function handleEnableAutoProtect(enabled) {
        dispatch(PMSLocationsActions.changeAutoProtectSetting(enabled, locationUid));
    }

    function integrationsSection() {
        return (
            <div>
                {title("INTEGRACIONES")}
                <IntegrationConfig />
            </div>
        );
        
    }

    function servicesSection() {
        function handleSaveURL(text) {
            dispatch(PMSLocationsActions.updateURLRecommendations(text, locationUid));
        }

        function handleSaveTravelerDiscount(value) {
            setTravelerDiscountLocal(value);
            dispatch(PMSLocationsActions.updateTravelerDiscount(value, locationUid));
        }

        function handleSaveVideoURL(text) {
            dispatch(PMSLocationsActions.updateURLVideo(text, locationUid));
        }

        return (
            <div>
                {title("SERVICIOS PARA HUÉSPEDES")}
                {locationInfo.vacationRentalPlan > 1 &&
                    <div className="mt-2 mb-2 cp d-flex align-items-center justify-content-between">
                        <div className="position-relative">
                            {t("mod_pms_location_service_recommendations_setting_title")}
                        </div>
                        <InlineValidatedTextInput
                            handleSave={handleSaveURL}
                            initialValue={locationInfo.urlRecommendations}
                            required={true}
                        />
                    </div>
                }
                {locationInfo.vacationRentalPlan === 3 &&
                    <div className="mt-2 mb-2 cp d-flex align-items-center justify-content-between">
                        <div className="position-relative">
                            {t("mod_pms_location_service_traveler_discount_title")}
                        </div>
                        <div className="ml-2"></div>
                        <SimpleToggle
                            checked={travelerDiscountLocal}
                            handleChange={({ detail: { value } }) => {
                                handleSaveTravelerDiscount(value);
                            }}
                        ></SimpleToggle>
                    </div>
                }
                {locationInfo.vacationRentalPlan === 3 &&
                    <>
                        <div className="mt-3" />
                        {videoUploadSection(
                            "Video explicativo",
                            handleUploadVideo,
                            false,
                            locationInfo.videoName,
                            locationInfo.videoUrl
                        )}
                        <div className="mt-2 mb-2 d-flex cp align-items-center justify-content-between">
                            <div className="position-relative">
                                Video URL
                            </div>
                            <InlineValidatedTextInput
                                handleSave={handleSaveVideoURL}
                                initialValue={locationInfo.externalVideoUrl}
                                required={true}
                            />
                        </div>
                        
                    </>
                }
            </div>
        )
    }

    function videoUploadSection(title, handleUpload, isUploading, videoName, videoUrl) {
        return (
            <div>
                {titleFn(title)}
                {videoName &&
                    <>
                        <div className="mt-1"/>
                        <div className="
                            d-flex
                            justify-content-center
                            align-items-center
                        ">
                            <a href={videoUrl}>{videoName}</a>
                        </div>
                    </>
                }
                <div className="mt-1"/>
                <FileUploadComponent
                    onUpload={handleUpload}
                    isUploading={isUploading}
                    fileType='video/*'
                />
            </div>
        );
    }

    function titleFn(title) {
        return (
            <div className="font-weight-bold text-uppercase">
                {title}
            </div>
        );
    }
}