import { useError } from "../_hooks/useError";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removeFromArr } from "../../Common/_utils/utils";
import { invitationsActions } from "../_actions";
import { Icons } from "../_assets";
import {
    CapsuleButton,
    CaptionText,
    ReadOnlyDataRow,
    SimpleToggle
} from "../_components";
import { weekdayUiValues, MIN_TIME, MAX_TIME, TIME_FORMAT } from "../_constants";
import { InvitationsHelper, TimeHelper } from "../_helpers";
import { TimeSlot, WeekDaysEnum, WeekDaysSlots } from "../_models/Invitation";

const timeNoSecondsFormat = TimeHelper.getTimeFormats().TIME_NO_SECONDS;

type TimeRangeInputProps = {
    slot?: TimeSlot;
    onAddSlot?: (start: string, end: string) => void;
    onRemoveSlot?: () => void;
    onToggleAllDay?: (allDay?: boolean) => void;
    isAllDay?: boolean;
    isEditable?: boolean;
};

function TimeRangeInput({
    slot = { start: MIN_TIME, end: MIN_TIME },
    onAddSlot,
    onRemoveSlot,
    onToggleAllDay,
    isAllDay,
    isEditable = true,
}: TimeRangeInputProps) {


    const { t } = useTranslation();
    const [start, setStart] = useState(slot.start);
    const [end, setEnd] = useState(slot.end);
    
    const getHourAndMinutesFormat = useCallback((time: string) => moment(time, timeNoSecondsFormat), []);
    const stringifyTime = useCallback((time: string) => getHourAndMinutesFormat(time).format(timeNoSecondsFormat),[getHourAndMinutesFormat]);

    const { setError, errorMessage } = useError();

    useEffect(() => {
        setStart(slot.start);
    }, [slot.start]);

    useEffect(() => {
        setEnd(slot.end);
    }, [slot.end]);

    return (
        <div>
            {isEditable && (
                <ReadOnlyDataRow
                    name={t("common.allDay")}
                    hasSeparators={false}
                    data={
                        <SimpleToggle
                            size="X_SMALL"
                            checked={isAllDay}
                            handleChange={({ detail: { value } }) => {
                                onToggleAllDay && onToggleAllDay(value);
                            }}
                        />
                    }
                />
            )}
            {!isAllDay && (
                <>
                    <div className="d-flex align-items-center justify-content-between position-relative px-1 pt-2 pb-1">
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                width: "100%",
                            }}
                        >
                            <div className="d-flex align-items-center justify-content-between pr-2">
                                <div className="c-text-capitalize-first mr-2">
                                    {t("common.from")}
                                </div>
                                {isEditable ? (
                                    <TimePicker
                                        showSecond={false}
                                        minuteStep={5}
                                        value={getHourAndMinutesFormat(start)}
                                        format={TIME_FORMAT}
                                        onChange={(value) =>
                                            setStart(
                                                value
                                                    ? value?.format(TIME_FORMAT)
                                                    : MIN_TIME,
                                            )
                                        }
                                    />
                                ) : (
                                    <CaptionText>
                                        {stringifyTime(start)}h
                                    </CaptionText>
                                )}
                            </div>
                            <div className="d-flex align-items-center justify-content-between pr-2">
                                <div className="c-text-capitalize-first mr-2">
                                    {t("common.to")}
                                </div>
                                {isEditable ? (
                                    <TimePicker
                                        showSecond={false}
                                        minuteStep={5}
                                        value={getHourAndMinutesFormat(end)}
                                        format={TIME_FORMAT}
                                        onChange={(value) =>
                                            setEnd(
                                                value
                                                    ? value?.format(TIME_FORMAT)
                                                    : MIN_TIME,
                                            )
                                        }
                                    />
                                ) : (
                                    <CaptionText>
                                        {stringifyTime(end)}h
                                    </CaptionText>
                                )}
                            </div>
                        </div>

                        <div>
                            {onAddSlot && (
                                <CapsuleButton
                                    onClick={() => {
                                        try {
                                            onAddSlot(start, end);
                                        } catch (e: any) {
                                            setError(e.message);
                                        }
                                    }}
                                    size={"SMALL"}
                                    icon={Icons.add}
                                />
                            )}
                            {onRemoveSlot && (
                                <CapsuleButton
                                    onClick={onRemoveSlot}
                                    size={"SMALL"}
                                    style={"DANGER"}
                                    icon={Icons.trash}
                                />
                            )}
                        </div>
                    </div>
                    {errorMessage && (
                        <CaptionText className="text-danger">
                            {errorMessage}
                        </CaptionText>
                    )}
                </>
            )}
        </div>
    );
}

type TimeSlotsSelectorProps = {
    weekDaysTimeSlots?: WeekDaysSlots;
    onChange: (args: WeekDaysSlots) => void;
    isNew?: boolean;
};

export function TimeSlotsSelector({
    weekDaysTimeSlots,
    onChange,
    isNew = true,
}: TimeSlotsSelectorProps) {

    const [activeIndex, setActiveIndex] = useState<number | null>(0);
    const days = useMemo(() => Object.values(WeekDaysEnum), []);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getSlotsFromWeekDay = (weekDay: WeekDaysEnum) =>
        weekDaysTimeSlots ? weekDaysTimeSlots[weekDay] ?? [] : [];

    const onAddSlot = (day: WeekDaysEnum, start: string, end: string) => {
        const slots = getSlotsFromWeekDay(day);
        if(InvitationsHelper.canAddSlot(slots, { start, end })) {
            onChange({ [day]: InvitationsHelper.orderTimeSlots([...slots, { start, end }]) });
        } 
    };

    const onRemoveSlot = (day: WeekDaysEnum, index: number) => {
        const slots = getSlotsFromWeekDay(day);
        const updatedSlots = removeFromArr<TimeSlot>(index, slots);
        onChange({ [day]: updatedSlots });
    };

    const hasSlots = (day: WeekDaysEnum) =>
        weekDaysTimeSlots && !!weekDaysTimeSlots[day]?.length;  
     
    // Hooks
    const updateSlotsDispatcher = useMemo(() => isNew ? invitationsActions.updateWeekdayTimeSlotsOnNew : invitationsActions.updateWeekdayTimeSlotsOnSelected, [isNew]);
    const deleteSlotsDispatcher = useMemo(() => isNew ? invitationsActions.deleteWeekdayFromInvitationOnNew : invitationsActions.deleteWeekdayFromInvitationOnSelected, [isNew]);


    // Content
    const accordionPanel = (
        <Accordion defaultActiveKey={WeekDaysEnum.MONDAY}>
            {days.map((day, index) => (
                <Accordion.Item key={day+index} eventKey={index.toString()}>
                    <Accordion.Header
                        onClick={() => {
                            setActiveIndex(
                                index === activeIndex ? null : index,
                            );
                        }}
                    >
                        <div
                            className={`${
                                weekDaysTimeSlots &&
                                weekDaysTimeSlots[day]?.length
                                    ? "c-bg-primary text-white"
                                    : "c-bg-grey-240"
                            } pl-2 pr-3 py-2 rounded-lg text-left d-flex justify-content-between align-items-center c-transition`}
                        >
                            <div className="d-flex align-items-center justify-content-between mr-4">
                                <img
                                    alt={"arrow_img"}
                                    src={Icons.arrowDown}
                                    className={`
                                    c-transition
                                    ${
                                        !(activeIndex === index) &&
                                        "c-rotate-ccw-90"
                                    }
                                    ${hasSlots(day) && "c-invert-full"}
                                    `}
                                />
                                {weekdayUiValues[day]}
                            </div>
                            {hasSlots(day) && (
                                <CaptionText className="text-right text-white c-text-capitalize-first">
                                    {InvitationsHelper.timeRangesToString(
                                        weekDaysTimeSlots![day],
                                    )}
                                </CaptionText>
                            )}
                        </div>
                    </Accordion.Header>
                    <Accordion.Body className="mb-2 shadow rounded-lg px-3 py-2">
                        <>
                            <TimeRangeInput
                                onAddSlot={(start, end) => {
                                    onAddSlot(day, start, end);
                                }}
                                isAllDay={InvitationsHelper.isAllDay(weekDaysTimeSlots && weekDaysTimeSlots[day])}
                                onToggleAllDay={(active) => {
                                    onToggleAllDay(day, active)
                                }}
                            />
                            {slotsInputsForDay(
                                day,
                                weekDaysTimeSlots ? weekDaysTimeSlots[day] : [],
                            )}
                        </>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    ); 

    return accordionPanel;

    function onToggleAllDay(day: WeekDaysEnum, active?: boolean) {
        active
            ? dispatch(
                updateSlotsDispatcher({
                    [day]: [
                        {
                            start: MIN_TIME,
                            end: MAX_TIME,
                        },
                    ],
                }),
            )
            : dispatch(
                deleteSlotsDispatcher(day)
            );
    }

    function slotsInputsForDay(day: WeekDaysEnum, slots?: TimeSlot[]) {
        if (!slots || !slots.length) {
            return (
                <div
                    key={day + JSON.stringify(slots)}
                    style={{ placeContent: "center", display: "grid" }}
                    className="h-100 p-2 my-2"
                >
                    <CaptionText
                        className="position-relative"
                    >
                        {t("mod_invitations_not_slots_yet_for_weekday", {
                            weekday: weekdayUiValues[day],
                        })}
                    </CaptionText>
                </div>
            );
        }
        if(InvitationsHelper.isAllDay(slots)) return null;
        return slots.map((slot, index) => (
            <div key={slot.start + slot.end + index} className="mt-2">
                <TimeRangeInput
                    slot={slot}
                    isEditable={false}
                    onRemoveSlot={() => onRemoveSlot(day, index)}
                />
            </div>
        ));
    }
}
