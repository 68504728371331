import { DISTRIBUTOR_ACTION_TYPES } from "../_constants/distributor.constants";

const initialState = {
    distributor: null,
};

export class DistributorSelectors {
    static getDistributor(state) {
        return state.distributor;
    }
}

export function distributorReducer(state = initialState, action) {
    switch (action.type) {
        case DISTRIBUTOR_ACTION_TYPES.GET_DISTRIBUTOR_SUCCESS:
            return {
                ...state,
                distributor: action.distributor,
            };

        default:
            return state;
    }
}