import { URL_CONSTANTS } from '../_constants';
import { ServiceHelper } from './ServiceHelper';
import { TimeHelper, ValidationHelper } from '../_helpers';
import { UaHelper } from '../_helpers/UaHelper';
import { MOBILE_TYPES } from '../_constants/mobiles.constants';

export const authenticationService = {
    register,
    requestCode,
    login,
    refreshUser,
    updateSystemInfo,
    logOut,
    logOutAll,
    validateCode,
    getUserInfo,
};

async function getUserInfo() {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.USER_INFO,
    );
}

async function validateCode({ email, code, secret }) {
    return await ServiceHelper.postRequest(
        URL_CONSTANTS.USER_VALIDATE_CODE,
        {
            email,
            code,
            secret,
        },
        true,
    );
}

function refreshUser() {
    return ServiceHelper.postRequest(URL_CONSTANTS.APIKEY_REFRESH);
}

function register(email, codeType, code) {
    if (email)
        validationSignIn(email);
        
    return ServiceHelper.postRequest(
        URL_CONSTANTS.USER_REGISTER_CODE,
        {
            mail: email,
            code,
            code_type: codeType,
            timezone: TimeHelper.getCurrentTimezone(),
            locale: UaHelper.getLocale(),
            type: MOBILE_TYPES.WEB,
        },
        true,
    );
}

function requestCode(username) {
    const bodyObject = {
        ...validationSignIn(username),
        locale: UaHelper.getLocale(),
    };
    return ServiceHelper.postRequest(URL_CONSTANTS.USER_REQUEST_CODE, bodyObject, true);
}

function login({
    email,
    code,
    codeType,

    machineUid,
    timezone,
    locale,
    appVersion,
    os,
    browser,
    secret,
} = {}) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.USER_LOGIN_CODE,
        {
            mail: email,
            code,
            code_type: codeType,
            type: 'web',
    
            unique_id: machineUid,

            timezone,
            locale,
            version: appVersion,
            os_version: os,
            model_version: browser,
            secret,
        },
        true,
    );
}

function validationSignIn(username) {
    if (ValidationHelper.ValidationFns.isEmailValid(username))
        return { mail: username };

    throw new Error('Invalid email format');
}

function updateSystemInfo(
    timezone,
    locale,
    appVersion,
    os,
    browser,
    webPushSubscription,
) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.UPDATE_SYSTEM_INFO,
        {
            timezone,
            locale,
            version: appVersion,
            model_version: browser,
            os_version: os,
            web_push_subscription: webPushSubscription,
        },
    );
}

function logOut() {
    return ServiceHelper.postRequest(URL_CONSTANTS.USER_LOGOUT);
}

function logOutAll() {
    return ServiceHelper.postRequest(URL_CONSTANTS.USER_LOGOUT, { all: 1 });
}