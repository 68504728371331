import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from "../_components";
import { AuditLog } from "../_models/AuditLog";
import { TimeHelper, DeviceHelper } from "../_helpers";
import { AuditLogsHelper } from "../_helpers/AuditLogsHelper";
import { AUDIT_LOG_TYPES, AUDIT_LOG_CODES } from "../_constants";
import { Device } from "../_models/Device";
import { SimpleDataBlock } from "../_components/SimpleDataBlock";
import { SingleMobile } from "../UserMobilesModule/SingleMobile";
import { i18n } from "../_translations/i18n";
import { Selectors } from "../_reducers/app.reducer";

export function AuditDetails() {
    //LIBRARIES
    const { t } = useTranslation();

    //GLOBAL STATE
    let auditLog    = AuditLog();
    auditLog        = useSelector(Selectors.getAuditLogsSelected);
    let devices     = [Device()];
    devices         = useSelector(Selectors.getSelectedLocationDevices);
    const timezone  = useSelector(Selectors.getTimezone);

    //VIEW
    if (!auditLog)
        return null;

    return (
        <MainPageWithButtons
            size={'SMALL'}
            pageTitle={t("common.details")}
            content={content()}
        />
    );

    function content() {
        return (
            <div>
                <SimpleDataBlock
                    title={t("common.logId")}
                    value={auditLog.id}
                />
                <SimpleDataBlock
                    title={t("common.user")}
                    value={auditLog.userEmail}
                />
                <SimpleDataBlock
                    title={t("common.code")}
                    value={auditLog.code}
                />
                <SimpleDataBlock
                    title={t("common.date")}
                    value={TimeHelper.localizeIsoStringToFormat(
                        auditLog.datetime,
                        timezone,
                    )}
                />
                <SimpleDataBlock
                    title={t("common.path")}
                    value={AuditLogsHelper.sliceUrlFromSlash(auditLog.path, 3)}
                />
                
                {deviceName()}
                {deviceStatus()}
                {json()}
                <hr className="m-0 my-2"/>
                {mobileData()}
            </div>
        );
    }

    function deviceName() {
        if (auditLog.type !== AUDIT_LOG_TYPES.DEVICE
            && auditLog.type !== AUDIT_LOG_TYPES.BRAIN
        )   return null;

        const deviceName = DeviceHelper.getNameForUidFromDevices(
            getDeviceUidFromLogContent(auditLog),
            devices,
        ) || i18n.t("global_device_not_found");

        return (
            <SimpleDataBlock
                title={t("common.device")}
                value={deviceName}
            />
        );
        
        function getDeviceUidFromLogContent(auditLog = AuditLog()) {
            return auditLog.code === AUDIT_LOG_CODES.ERROR
                ? getUidFromError()
                : getUidFromSuccess()
            ;

            function getUidFromSuccess() {
                return auditLog.content.uid;
            }

            function getUidFromError() {
                return auditLog.content.request
                    && auditLog.content.request.uid
                ;
            }
        }
    }

    function deviceStatus() {
        const statusLabel = AuditLogsHelper.getAuditLogDeviceStatusLabel(
            auditLog,
            devices,
        );

        if (!statusLabel)
            return;

        return (
            <SimpleDataBlock
                title={t("global_status")}
                value={statusLabel}
            />
        );
    }

    function json() {
        return (
            <div className="
                my-1
            ">
                <div className="
                    text-uppercase
                    font-weight-bold
                ">
                    {t("common.content") + ":"}
                </div>

                <pre className="
                    m-0
                    p-2

                    bg-light
                    border
                    rounded
                ">
                    {JSON.stringify(
                        auditLog.content,
                        null,
                        4,
                    )}
                </pre>
            </div>
        );
    }

    function mobileData() {
        if (!auditLog.mobile.type)
            return;

        return (
            <div>
                <div className="
                    text-uppercase
                    font-weight-bold
                ">
                    {t("common.device") + ":"}
                </div>
                <SingleMobile
                    mobile={auditLog.mobile}
                />
            </div>            
        );
    }
}