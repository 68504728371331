import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    invitationPermissionUiValues,
    invitationPermissionDescriptions,
} from '../../_constants';
import { invitationsActions } from '../../_actions';
import { LOCATION_PERMISSIONS } from '../../_constants/permissions.constants';
import { CaptionText } from '../../../Common/_components/CaptionText';
import { SegmentedControl } from '../../../Common/_components';
import { invitationGroupActions } from '../../_actions/invitationGroup.actions';

const TEST_IDS = {
    SELECTED: "permission-selector-2-selected",
};

PermissionSelectorComponent2.TEST_IDS = TEST_IDS;

type PermissionSelectorComponent2Props = {
    permissionType?: number; // to be typed asap
    isEditable: boolean;
    mode?: 'invitation' | 'invitation_group'
}

export function PermissionSelectorComponent2({
    permissionType,
    isEditable = false,
    mode = 'invitation'
}: PermissionSelectorComponent2Props) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HANDLERS
    const handleChangePermission = useCallback(permission => {
        const action = mode === 'invitation' ? invitationsActions : invitationGroupActions;
        dispatch(action.changeInvitationPermission(permission));
    }, [dispatch, mode]);

    //VIEW
    return (
        <div>
            <h3>
                {t("common.typeOfUser")}
            </h3>
            {permissionSelector()}
           <CaptionText className='mt-1'>{invitationPermissionDescriptions[permissionType!]}</CaptionText>
        </div>
    );

    function permissionSelector() {
        const segments = getUiAvailablePermissions();
        return <SegmentedControl defaultSelected={{ value: permissionType }} onSelect={handleChangePermission} readOnly={!isEditable} segments={segments}></SegmentedControl>
    }


    function getUiAvailablePermissions() {
        const availablePermissions = isEditable
            ? [
                LOCATION_PERMISSIONS.GUEST,
                LOCATION_PERMISSIONS.ADMIN,
                LOCATION_PERMISSIONS.INSTALLER,
            ]
            : [permissionType]
        ;
        return availablePermissions.map(element =>({value: element, label: invitationPermissionUiValues[element!]})
        );
    }
}