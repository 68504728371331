import { i18n } from "../_translations/i18n";

export const FILTER_ACTION_TYPES = {
    CHANGE_FILTER_MODE: "CHANGE_FILTER_MODE",
    TOGGLE_CATEGORY: "TOGGLE_CATEGORY",
    SELECT_ALL_CATEGORIES: "SELECT_ALL_CATEGORIES",
    CREATE_FILTER_CATEGORIES: "CREATE_FILTER_CATEGORIES",
};

export const deviceFilterModes = {
    //DEVICES_TYPES: "DEVICES_TYPES",
    ROOMS: "ROOMS",
    CATEGORIES: "CATEGORIES",
};

export const filterModesTypesId = {
    [deviceFilterModes.CATEGORIES]: 0,
    [deviceFilterModes.ROOMS]: 1,
    [deviceFilterModes.DEVICES_TYPES]: 2
}

export const filterTypesToModes = {
    0: deviceFilterModes.CATEGORIES,
    1: deviceFilterModes.ROOMS,
    2: deviceFilterModes.DEVICES_TYPES
}

export const filterModeParameters = {
    [deviceFilterModes.DEVICES_TYPES]: 'originalType',
    [deviceFilterModes.ROOMS]: 'room_uid',
    [deviceFilterModes.CATEGORIES]: 'category',
};

export const filterModeNames = {
    //[deviceFilterModes.DEVICES_TYPES]: i18n.t("global_types"),
    [deviceFilterModes.ROOMS]: i18n.t("devices.rooms"),
    [deviceFilterModes.CATEGORIES]: i18n.t("devices.categories"),
};