import { ProtectSecondaryViewStore } from "../_stores/ProtectSecondaryViewStore";

export const PROTECT_ACTION_TYPES = {
    GET_TRIGGERED_LOGS: "PROTECT_GET_TRIGGERED_LOGS",
    SET_SECONDARY_VIEW: ProtectSecondaryViewStore.ACTION_TYPES.SET,
};

export const PROTECT_SECONDARY_VIEWS = {
    SETTINGS: "SETTINGS_PAGE",
    LOGS: "LOGS_PAGE",
    USERS: "USERS_PAGE",
};