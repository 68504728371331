import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RoomSelector } from "./RoomSelector";
import { CapsuleButton } from "../../_components";
import { Room } from "../../_models/Room";
import { ListOfRemovableItems } from "../../../Common/_components/ListOfRemovableItems";
import { useValidationInput } from "../../../Common/_hooks/useValidationInput";
import { ValidationHelper } from "../../_helpers";
import {orderBy} from 'lodash';

const TEST_IDS = {
    ROOT: "multiple-room-selector",
    ADD_ROOM_BUTTON: "multiple-room-selector-add-button",
};

MultipleRoomSelector.TEST_IDS = TEST_IDS;

export function MultipleRoomSelector({
    rooms = [] || [Room()],
    selectedRooms = [] || [Room()],
    onChangeRooms = rooms => {},
    forceShowWarnings = false,
    setIsValid = () => {},
}) {

        //LIBRARIES
    const { t } = useTranslation();
    
    //LOCAL STATE
    const [selectedRoomUid, setSelectedRoomUid] = useState(rooms[0].uid);
    const [onceEdited, setOnceEdited] = useState(!!selectedRooms.length);

    const {
        WarningsComponent,
        handleSetWiredValue,
    } = useValidationInput({
        value: selectedRooms.length,
        forceWarnings: forceShowWarnings,
        handleSetValue: onChangeRooms,
        setIsValid,
        warningFns: useMemo(
            () => [ValidationHelper.UiValidationFns.required],
            [],
        ),
    });

    const isRoomInInvitationsRooms = (selectedRooms, roomUid) => {
        return selectedRooms.some(room => room.uid === roomUid);
    }

    const handleChangeRooms = handleSetWiredValue;

    //LOCAL HANDLERS
    function handleAddRoomToList() {

        if (isRoomInInvitationsRooms(selectedRooms, selectedRoomUid))
        return;
        
        !onceEdited && setOnceEdited(true);

        handleChangeRooms(orderBy([
            ...selectedRooms,
            rooms.find(room => room.uid === selectedRoomUid),
        ], ['type']));
    }
    
    function handleRemoveRoom(roomUid) {
        handleChangeRooms(
            selectedRooms.filter(room => room.uid !== roomUid)
        );
    }

    //VIEW
    return (
        <div data-testid={TEST_IDS.ROOT}>
            <div className="d-flex align-items-center">
                <RoomSelector
                    rooms={rooms}
                    selectedRoomUid={selectedRoomUid}
                    onSelectRoom={setSelectedRoomUid}
                />
                <div className="ml-2" />
                <div>
                    <CapsuleButton
                        isEnabled={
                            !isRoomInInvitationsRooms(
                                selectedRooms,
                                selectedRoomUid,
                            )
                        }
                        testId={TEST_IDS.ADD_ROOM_BUTTON}
                        onClick={handleAddRoomToList}
                        text={t("add_room")}
                        size={"SMALL"}
                    />
                </div>
            </div>
            {selectedRooms.length > 0 && (
                <ListOfRemovableItems
                    handleDeleteItem={handleRemoveRoom}
                    items={selectedRooms.map((room) =>
                        ListOfRemovableItems.buildItem({
                            id: room.uid,
                            name: room.name,
                        }),
                    )}
                />
            )}
            {selectedRooms.length === 0 && onceEdited ? (
                <div className="text-danger ml-2 mt-2">
                    {t("mod_invitations_should_at_least_add_one_room")}
                </div>
            ) : null}
            <WarningsComponent />
        </div>
    );
}