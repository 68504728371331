import { Invitation } from "./Invitation";

export function SubinvitationGroup({
    main = Invitation(),
    sent = [Invitation()],
} = {}) {

    return {
        main,
        sent,
    };
}