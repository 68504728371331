import { UserStatus } from "../_models/UserStatus";
import { OtherUserMapper } from "./OtherUserMapper";
import { USERS_STATUS_DATE_FILTER_PERIODS } from "../_constants/usersStatus.constants";
import { TimeHelper } from "../_helpers";

export class UserStatusMapper {
    static allServerToLocal(serverUsersStatus) {
        return serverUsersStatus.map(serverTolocal);
    }

    static mapDateFilterToBeginEnd(dateFilter, timezone) {
        return {
            begin: mapDateFilterToBegin(dateFilter, timezone),
            end: TimeHelper.getTodayIsoString(),
        };
    }
}

function serverTolocal(serverUserStatus) {
    return UserStatus({
        user: OtherUserMapper.serverToLocal(serverUserStatus.user),
        status: serverUserStatus.status,
    });
}

function mapDateFilterToBegin(dateFilter, timezone) {
    switch (dateFilter) {
        case USERS_STATUS_DATE_FILTER_PERIODS.TODAY:
            return TimeHelper.localizedStartOfTodayIsoString(timezone);

        case USERS_STATUS_DATE_FILTER_PERIODS.LAST_WEEK:
            return TimeHelper.getWeeksAgoIso(1);

        case USERS_STATUS_DATE_FILTER_PERIODS.LAST_2_WEEKS:
            return TimeHelper.getWeeksAgoIso(2);
        
        case USERS_STATUS_DATE_FILTER_PERIODS.LAST_MONTH:
            return TimeHelper.getOneMonthAgoIsoString();

        default:
            return undefined;
    }
}