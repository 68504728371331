import React from "react";
import { Virtuoso } from "react-virtuoso";

type ListedGroupProps = {
    headerText?: string;
    elements?: any[];
    testID?: string;
    subtext?: string;
    buttons?: any[];
    isInnerScrollEnabled?: boolean;
    color?: string;
    wrappedElements?: any;
    optimize?: boolean;
    itemUi?: (...args: any) => any,
    selectableItems?: boolean
}


export function ListedGroup({
    headerText,
    elements = [],
    testID,
    subtext,
    buttons = [],
    isInnerScrollEnabled = false,
    color,
    wrappedElements,
    optimize,
    itemUi,
    selectableItems = false
}: ListedGroupProps) {
    return (
        <div
            data-testid={testID}
            className="
                h-100
                w-100

                d-flex
                flex-column
            "
        >
            {header()}
            {elementList()}
        </div>
    );

    function header() {
        if (!headerText && !subtext && !buttons.length)
            return;

        return (
            <div className={`
                d-flex

                px-2

                ${subtext
                    ? "py-1"
                    : "py-2"
                }

                c-bg-grey-240
                font-weight-bold

                
                rounded-top
                border
            `}
            style={{
                backgroundColor: color,
            }}
            >
                <div className="c-text-capitalize-first">
                    {headerText}
                    {subtextFn()}
                </div>
                <div className="flex-fill"/>
                {buildButtons()}
            </div>
        );
    }

    function buildButtons() {
        return buttons.map((button, index) =>
            <div
                key={index}
                className={`
                    ${index !== 0 &&
                        "ml-2"
                    }
                    my-auto
                `}
            >
                {button}
            </div>
        );
    }

    function subtextFn() {
        if (!subtext)
            return;
        
        return (
            <div className="
                font-weight-normal
            ">
                {subtext}
            </div>
        );
    }

    function elementList() {
        return (
            <div style={{...(optimize && {height: '100%'})}} className={`
                ${!headerText && "border-top rounded-top"}
                rounded-bottom
                border-left
                border-right
                border-bottom
                
                ${isInnerScrollEnabled && "overflow-auto"}
            `}
            >
                {list()}
            </div>
        );
    }


    function list() {
        if (optimize) {
            return virtuosedList();
        } else {
            return (
                wrappedElements ?? elements?.map(itemUi ?? defaultItemUi)
            );
        }
    }
    
    function virtuosedList() {
        const mapper = itemUi ?? defaultItemUi;
        return (
            <Virtuoso
                style={{height: '100%'}}
                totalCount={elements.length}
                itemContent={(index) => mapper(elements[index], index)}
            ></Virtuoso>
        );
    }

    function defaultItemUi(element, index) {
        return (
            <div
                key={index}
                className={`
                    ${index !== 0 && "border-top"} ${selectableItems && 'cp'}
                `}
            >
                {element}
            </div>
        );
    }
}