import { BUDGETS_API_URLS } from "../_constants/url.constants";
import { ServiceHelper2 } from "../_globalHelpers";

export const DistributorsService = {
    getDistributor,
    createDistributor,
    updateDistributor,
    uploadAvatar,
};

async function getDistributor(uid) {
    const data = await ServiceHelper2.getRequest(
        BUDGETS_API_URLS.DISTRIBUTORS + "/" + uid,
    );
    
    return data;
}

async function createDistributor({
    name,
    cif,
    phone,
    email,
    notes,
}) {
    return await ServiceHelper2.postRequest(
        BUDGETS_API_URLS.DISTRIBUTORS,
        {
            name,
            cif,
            phone,
            email,
            notes,
        },
    )
}

async function updateDistributor({
    uid,
    name,
    cif,
    phone,
    email,
    notes,
}) {
    return await ServiceHelper2.putRequest(
        BUDGETS_API_URLS.DISTRIBUTORS + "/" + uid,
        {
            name,
            cif,
            phone,
            email,
            notes,
        },
    )
}

async function uploadAvatar(file) {
    return await ServiceHelper2.postImageRequest(
        BUDGETS_API_URLS.DISTRIBUTOR_AVATAR,
        file,
    );
}