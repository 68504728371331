import { GLOBAL_ACTION_TYPES } from "../_constants";

const initialState = {
    isAppInitialized: false,
    preselectedLocationUid: null,
    validatedLocationUid: null,
};

let getRootState = state => state;

export class GlobalSelectors {
    static getIsAppInitialized =        state => getRootState(state).isAppInitialized;
    static getPreselectedLocationUid =  state => getRootState(state).preselectedLocationUid;
    static getValidatedLocationUid =    state => getRootState(state).validatedLocationUid;

    static setRootStateFn = setRootStateFn;
}

function setRootStateFn(rootStateFn = state => state) {
    getRootState = rootStateFn;
}

export function global(state = initialState, action) {

    if (!state.hydrated) {
        state = {
            ...initialState,
            ...state,
            hydrated: true,
        };
    }

    switch (action.type) {
        case GLOBAL_ACTION_TYPES.SET_APP_INITALIZED:
            return { ...state,
                isAppInitialized: true,
            };

        case GLOBAL_ACTION_TYPES.SET_PRESELECTED_LOCATION_UID:
            return { ...state,
                preselectedLocationUid: action.preselectedLocationUid,
            };

        case GLOBAL_ACTION_TYPES.SET_VALIDATED_LOCATION_UID:
            return { ...state,
                validatedLocationUid: action.validatedLocationUid,
            };

        default:
            return state;
    }
}