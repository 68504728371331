import { StoreManager } from "../../StoreManager";
import { devicesActions } from "../../_actions";
import { CapsuleButton, CapsuleButtonProps } from "..";
import { i18n } from "../../_translations/i18n";
import { popUpActions } from '../../_stores/PopUpStore/popUpActions';
import { GLOBAL_POPUPS } from "../../_constants";

type RebootDeviceButtonProps = {
    uid: string;
    name?: string;
    requiresConfirmation?: boolean
};

export function RebootDeviceButton({
    uid,
    name,
    isEnabled,
    requiresConfirmation = false
}: RebootDeviceButtonProps & Partial<CapsuleButtonProps>) {

    const dispatch = StoreManager.getStore().dispatch;
    const reboot = () => dispatch(devicesActions.reboot(uid)); 

    const onClick = requiresConfirmation
    ? () => dispatch(
        popUpActions.showPopUp(GLOBAL_POPUPS.BASE_APP_GLOBAL, {
            title: name,
            body: i18n.t('device_reboot_confirmation'),
            onCancel: reboot,
            cancelButtonName: i18n.t("webapp_global_reboot"),
            acceptButtonName: i18n.t("global_cancel"),
            limitWidth: true,
        }),
    )
    : reboot;


    return (
        <CapsuleButton
            text={i18n.t("webapp_global_reboot")}
            // eslint-disable-next-line react/style-prop-object
            style={"DANGER"}
            size="SMALL"
            isEnabled={isEnabled}
            onClick={onClick}
            classNameExtra={"flex-grow-0"}
        ></CapsuleButton>
    );
}
