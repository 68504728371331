import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SingleDevice } from './SingleDevice';
import { DeviceHelper } from '../_helpers';
import { Device } from '../_models/Device';
import { Selectors } from '../_reducers/app.reducer';
import { useTranslation } from 'react-i18next';
import { deviceFilterModes, DEVICES_SECONDARY_VIEWS, filterModeParameters } from '../_constants';
import { devicesActions } from '../_actions';
import { roomsActions } from '../_actions';
import { getRoomTypeIcon } from '../_assets/roomTypeIcons';
import { CaptionText } from '../_components';

type CategoryProps = {
    icon?: any;
    title: string,
    options?: React.ReactNode,
    content?: React.ReactNode;
    id: string;
}

function Category({ title, icon, options, content, id }: CategoryProps) {
    return (
        <div className="p-3 mb-3 c-bg-grey-245 c-rounded-big">
            <div className={`${icon ? 'pr-2' : 'px-2'} mb-3 d-flex justify-content-between align-content-center`}>
                <div className="d-flex align-items-center">
                    {icon && (
                        <img
                            src={icon}
                            className="mr-2 c-img-1-25rem"
                            alt={title}
                        />
                    )}
                    <h3 className="c-line-height-1 mb-0">{title}</h3>
                </div>
                {options}
            </div>
            {content}
        </div>
    );
}

export function DevicesColumn({
    filterModeParameter,
    activeCategories,
    devices = [] || [Device()],
}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    //GLOBAL STATE
    let selectedDeviceConfig    = Device();
    selectedDeviceConfig        = useSelector(Selectors.getDeviceConfig);


    //LOCAL STATE
    const groupedDevices = useMemo(
        () =>
            DeviceHelper.getDevicesNoDuplicates(
                DeviceHelper.groupDevicesByKits(devices),
                filterModeParameter,
            ),
        [devices, filterModeParameter],
    );

    const openRoomConfig = useCallback(roomUid => {
        dispatch(roomsActions.selectRoomConfig(roomUid))
        dispatch(devicesActions.changeSecondaryView(DEVICES_SECONDARY_VIEWS.ROOM_CONFIG));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isCategoryRoom = useMemo(() => filterModeParameter ===
                        filterModeParameters[deviceFilterModes.ROOMS], [filterModeParameter]);

    //VIEW
    return (
        <div
            className="
            w-100
            h-100

            d-flex
            flex-column
        "
        >
            {devices?.length > 0 ? (
                <div className="overflow-auto c-masonry">{buildDevices()}</div>
            ) : (
                <CaptionText
                    style={{ height: "90%" }}
                    className="d-flex align-items-center justify-content-center"
                >
                    {t("main_menu_no_devices_title")}
                </CaptionText>
            )}
        </div>
    );


    function buildDevices() {
        return (
            activeCategories
            .sort((a, b) => a.devicesAmount > b.devicesAmount ? -1 : 1)
            .map(activeCategory =>
                buildUiCategory(activeCategory.id, activeCategory.name, activeCategory.type)
            )
        );
    }


    function buildUiCategory(categoryId, categoryName, categoryType?) {
        const uiDevices = buildUiDevices(categoryId);
        return (
            <Category
                key={categoryId + '_' + categoryName}
                id={categoryId}
                title={categoryName}
                icon={isCategoryRoom && getRoomTypeIcon(categoryType)}
                options={
                    isCategoryRoom && (
                        <div onClick={() => openRoomConfig(categoryId) } className='cp font-weight-bold d-flex align-items-center justify-content-center'>
                          &middot;&middot;&middot;
                        </div>
                    )
                }
                content={<div className="c-default-grid">{uiDevices}</div>}
            />
        );
    }

    function buildUiDevices(categoryId) {
 
        const filteredDevices = groupedDevices
        .filter(device => device[filterModeParameter] === categoryId);


        if(!filteredDevices.length) {
            return [<div key={categoryId + '_no_devices'} className='d-flex jusity-content-center align-items-center pt-2 pb-3 text-secondary'><span className='mx-auto'>{t('mod_devices_without_devices')}</span></div>];
        }

        return (
            filteredDevices
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((device) =>
                <SingleDevice
                    key={device.uid}
                    device={device}
                    selectedConfigDeviceUid={
                        selectedDeviceConfig && selectedDeviceConfig.uid
                    }
                />
            )
        );
    }

}