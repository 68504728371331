import { AppModulesFactory } from "../Common/AppModulesFactory";
import { i18n } from "../Common/_translations";
import { Icons } from "../Everou/_assets";
import { ModuleModel } from "../Everou/_models";
import { PMSLocationsModule } from "./ModuleLocations/PMSLocationsModule";
import { PMSIntegrationsModule } from "./ModuleIntegrations/PMSIntegrationsModule";
import { PMSReservationsModule } from "./ModuleReservations/PMSReservationsModule";
import { PMSCustomizationModule } from "./ModuleCustomization/PMSCustomizationModule";
import { PMSChatModule } from "./ModuleChat/PMSChatModule";

const MODULE_IDS = {
    LOCATIONS:      "LOCATIONS",
    INTEGRATIONS:   "INTEGRATIONS",
    RESERVATIONS:   "RESERVATIONS",
    CUSTOMIZATION:  "CUSTOMIZATION",
    CHAT:           "CHAT",
};

const URLS = {
    LOCATIONS:      "/locations",
    INTEGRATIONS:   "/integrations",
    RESERVATIONS:   "/reservations",
    CUSTOMIZATION:  "/customization",
    CHAT:           "/chat",
};

const MODULES = [
    ModuleModel({
        moduleId: MODULE_IDS.LOCATIONS,
        icon: Icons.home,
        isEnabled: true,
        uiName: i18n.t("mod_dev_locations_title"),
        url: URLS.LOCATIONS,
    }),
    ModuleModel({
        moduleId: MODULE_IDS.INTEGRATIONS,
        icon: Icons.devices,
        isEnabled: true,
        uiName: i18n.t("mod_integrations_title"),
        url: URLS.INTEGRATIONS,
    }),
    ModuleModel({
        moduleId: MODULE_IDS.RESERVATIONS,
        icon: Icons.icon_reservations_new,
        isEnabled: true,
        uiName: i18n.t("mod_pms_reservations"),
        url: URLS.RESERVATIONS,
    }),
    ModuleModel({
        moduleId: MODULE_IDS.CUSTOMIZATION,
        icon: Icons.paintPalette,
        isEnabled: true,
        uiName: i18n.t("mod_pms_customization"),
        url: URLS.CUSTOMIZATION,
    }),
    ModuleModel({
        moduleId: MODULE_IDS.CHAT,
        icon: Icons.icon_chat,
        isEnabled: true,
        uiName: i18n.t("mod_pms_chat"),
        url: URLS.CHAT,
    }),
];

const componentForModuleDictionary = {
    [MODULE_IDS.LOCATIONS]:     PMSLocationsModule,
    [MODULE_IDS.INTEGRATIONS]:  PMSIntegrationsModule,
    [MODULE_IDS.RESERVATIONS]:  PMSReservationsModule,
    [MODULE_IDS.CUSTOMIZATION]: PMSCustomizationModule,
    [MODULE_IDS.CHAT]:          PMSChatModule,
};

function getEnabledModuleIds() {
    return MODULES
        .filter(module => module.isEnabled)
        .map(module => module.moduleId)
    ;
}

export const PMSModules = AppModulesFactory.makeModules({
    ALL_MODULES: MODULES,
    getEnabledModuleIds,
    componentForModuleDictionary,
    DEFAULT_MODULE: MODULE_IDS.LOCATIONS,
});