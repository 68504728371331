import { RESERVATION_ACTION_TYPES } from "../_constants/reservation.constants";
import { TimeHelper } from "../_helpers";

const initialState = {
    selected: null,
    configurations: [],
    reservations: [],
    selectedScheduleDate: TimeHelper.getTodayIsoString(),
    selectedSlot: null,
};

export function reservations(state = initialState, action) {
    switch (action.type) {

        case RESERVATION_ACTION_TYPES.SELECT:
            return { ...state,
                selected: action.selected,
            };

        case RESERVATION_ACTION_TYPES.UPDATE_SELECTED:
            return { ...state,
                selected: { ...state.selected,
                    ...action.selected,
                },
            };

        case RESERVATION_ACTION_TYPES.OVERWRITE_CONFIGURATION: 
            return { ...state,
                configurations: state.configurations.map(config => 
                    config.roomUid === action.configuration.roomUid
                    ? action.configuration
                    : config
                ),
            };

        case RESERVATION_ACTION_TYPES.OVERWRITE_ALL_CONFIGURATIONS:
            return { ...state,
                configurations: action.configurations, 
            };

        case RESERVATION_ACTION_TYPES.OVERWRITE_RESERVATIONS:
            return { ...state,
                reservations: action.reservations,
            };

        case RESERVATION_ACTION_TYPES.ADD_RESERVATION:
            return { ...state,
                reservations: [ ...state.reservations,
                    action.reservation,
                ],
            };
            
        case RESERVATION_ACTION_TYPES.DELETE_RESERVATION:
            return { ...state,
                reservations: state.reservations.filter(reservation =>
                    reservation.uid !== action.uid
                ),
            };

        case RESERVATION_ACTION_TYPES.UPDATE_SELECTED_SCHEDULE_DATE:
            return { ...state,
                selectedScheduleDate: action.selectedScheduleDate,
            };

        case RESERVATION_ACTION_TYPES.UPDATE_SELECTED_SLOT_TIME:
            return { ...state,
                selectedSlot: action.selectedSlot,
            };
            
        default:
            return state;
    }
}