import { Selectors } from "../_reducers/app.reducer";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { devicesActions } from "../_actions";
import { ReadOnlyDataRow, SimpleToggle, Title } from "../_components";
import { ACCESS_TYPE } from "../_constants";
import { barriersActions } from "../_stores/BarriersStore/BarriersActions";
import { DeviceHelper } from '../_helpers/DeviceHelper';

function DevicesGroup({
    title,
    devices,
    actionButtons,
    options
}: {
    title: string;
    devices: any[];
    options?: any
    actionButtons: { label: string; action: (device: any) => void }[];
}) {

    const CustomToggle = forwardRef(({ children, onClick }: any, ref: any) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </div>
    ));

    const { t } = useTranslation();

    return (
        <div
            style={{ display: "grid", gap: 12 }}
            className="c-bg-grey-240 rounded-lg px-3 pb-3 pt-2"
        >   
        <div className="d-flex justify-content-between">
            <h3 className="mt-2 mb-0">{title}</h3>
            {options} 
        </div>
            {devices?.length ? (
                <div style={{ display: "grid", gap: 12 }}>
                    {devices.map((device) => (
                        <div
                            key={device.uid}
                            className="bg-white rounded-lg px-3 py-2 d-flex justify-content-between align-items-center"
                        >
                            <span>{device.description}</span>

                            <Dropdown>
                                <Dropdown.Toggle
                                    as={CustomToggle}
                                    id="custom-devices-dropdown"
                                >
                                    <div onClick={ev => ev.preventDefault()} className="cp d-flex align-items-center font-weight-bold">
                                        &middot; &middot; &middot;
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ margin: 0 }} align='end'>
                                    {actionButtons.map((button, index) => (
                                        <Dropdown.Item
                                            eventKey={
                                                index +
                                                "_actionButton_" +
                                                device.uid
                                            }
                                            key={
                                                index +
                                                "_actionButton_" +
                                                device.uid
                                            }
                                            onClick={() => {
                                                button.action(device);
                                            }}
                                        >
                                            {button.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center">
                    {t("mod_devices_without_devices")}
                </div>
            )}
        </div>
    );
}

export function BarrierDevices() {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const locationDevices = useSelector(Selectors.getSelectedLocationDevices);
    const barrier = useSelector(Selectors.getBarrier);
    const devicesAbleToBarriers = useMemo(
        () => locationDevices.filter(DeviceHelper.isAbleToBarriers),
        [locationDevices],
    );

    const [entryDevices, setEntryDevices] = useState(devicesAbleToBarriers.filter(device => device.isBarrier && device.accessType === ACCESS_TYPE.ENTRY));
    const [exitDevices, setExitDevices] = useState(devicesAbleToBarriers.filter(device => device.isBarrier && device.accessType === ACCESS_TYPE.EXIT));
    const [restOfDevices, setRestOfDevices] = useState(devicesAbleToBarriers.filter(device => !device.isBarrier && [ACCESS_TYPE.ENTRY, ACCESS_TYPE.EXIT].includes(device.accessType)))

    const [restrictExits, setRestrictExits] = useState(!!exitDevices.some(device => device.restrictExit));
    
    const properlyConfigured = useMemo(() => !barrier.isLoading && !barrier.errors?.devicesMisconfigured, [barrier.isLoading, barrier.errors?.devicesMisconfigured]);

    const restrictAccessToBarriers = async (devices: any[], restrictExit: boolean) => {
        await Promise.all(devices.map(async device => { dispatch(devicesActions.updateRestrictExit(device.uid, restrictExit)) } ));       
    }

    useEffect(() => {
        setEntryDevices(devicesAbleToBarriers.filter(device => device.isBarrier && device.accessType === ACCESS_TYPE.ENTRY));
        setExitDevices(devicesAbleToBarriers.filter(device => device.isBarrier && device.accessType === ACCESS_TYPE.EXIT));
        setRestOfDevices(devicesAbleToBarriers.filter(device => !device.isBarrier && [ACCESS_TYPE.ENTRY, ACCESS_TYPE.EXIT].includes(device.accessType)))
    }, [devicesAbleToBarriers]);

    const updateRestrictExit = async (devices: any[], restrictExit: boolean) => {
            if (devices?.length) {
                await restrictAccessToBarriers(devices, restrictExit);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

    const shadowUpdateRestrictExit = useCallback(async (devices, restrictExit) => {
        try {
            await updateRestrictExit(devices, restrictExit);
        } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const markAsEntryButton = {
        label: t("mod_devices_add_as_entry_device"),
        action: (device) => {
            try {
                const onUpdate = async (updatedDevice) => {
                    setEntryDevices([...entryDevices, updatedDevice]);
                    setRestOfDevices(
                        restOfDevices.filter(
                            (d) => d.uid !== updatedDevice.uid,
                        ),
                    );
                    setExitDevices(
                        exitDevices.filter((d) => d.uid !== updatedDevice.uid),
                    );
                    try {
                        await dispatch(barriersActions.getInfo());
                    } catch {}
                };
                dispatch(
                    devicesActions.updateDeviceBarrierStatusRemote({
                        deviceUid: device.uid,
                        accessType: ACCESS_TYPE.ENTRY,
                        isBarrier: true,
                        onUpdate,
                    }),
                );
            } catch {
                // do nothing
            }
        },
    };

    const markAsExitButton = {
        label: t("mod_devices_add_as_exit_device"),
        action: device => {
            try {
                const onUpdate = async (updatedDevice) => {
                    const updatedDevices = [...exitDevices, updatedDevice];
                    setExitDevices(updatedDevices);
                    setRestOfDevices(
                        restOfDevices.filter(
                            (d) => d.uid !== updatedDevice.uid,
                        ),
                    );
                    setEntryDevices(entryDevices.filter((d) => d.uid !== updatedDevice.uid));
                    try {
                        await dispatch(barriersActions.getInfo());
                    } catch {}
                };

                dispatch(
                    devicesActions.updateDeviceBarrierStatusRemote( {
                        deviceUid: device.uid,
                        accessType: ACCESS_TYPE.EXIT,
                        isBarrier: true,
                        onUpdate,
                        restrictExit: restrictExits,
                    })
                );
            } catch {
                // do nothing
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    
    const removeFromBarriersButton = {
        label: t("mod_devices_remove_from_barriers"),
        action: (device) => {
            try {
                const onUpdate = async (updatedDevice) => {
                    setRestOfDevices([...restOfDevices, updatedDevice]);
                    const udpatedExitDevices = exitDevices.filter((d) => d.uid !== updatedDevice.uid);
                    setEntryDevices(
                        entryDevices.filter((d) => d.uid !== updatedDevice.uid),
                    );
                    setExitDevices(udpatedExitDevices);
                    try {
                        await dispatch(barriersActions.getInfo());
                    } catch {}
                };
                dispatch(
                    devicesActions.updateDeviceBarrierStatusRemote({
                        deviceUid: device.uid,
                        accessType: device.accessType,
                        isBarrier: false,
                        onUpdate,
                    })
                );
            } catch {
                // do nothing
            }
        },
    };
    
        return (
            <div className="mt-2">
                <Title text={t("devices.devices")} />
                <div className="mt-3" style={{ display: "grid", gap: 12 }}>
                    <DevicesGroup
                        title={t("mod_devices_entry_devices")}
                        devices={entryDevices}
                        actionButtons={[
                            markAsExitButton,
                            removeFromBarriersButton,
                        ]}
                    />
                    <DevicesGroup
                        title={t("mod_devices_exit_devices")}
                        devices={exitDevices}
                        options={
                            properlyConfigured && (
                                <ReadOnlyDataRow
                                    name={t("mod_barriers_restrict_exits")}
                                    data={
                                        <SimpleToggle
                                            className="ml-2"
                                            checked={restrictExits}
                                            handleChange={({
                                                detail: { value },
                                            }) => {
                                                try {
                                                    setRestrictExits(value);
                                                    updateRestrictExit(
                                                        exitDevices,
                                                        value,
                                                    );
                                                } catch {
                                                    setRestrictExits(!value);
                                                }
                                            }}
                                        />
                                    }
                                />
                            )
                        }
                        actionButtons={[
                            markAsEntryButton,
                            removeFromBarriersButton,
                        ]}
                    />
                    <DevicesGroup
                        title={t(
                            "mod_devices_available_not_included_in_barrier",
                        )}
                        devices={restOfDevices}
                        actionButtons={[markAsEntryButton, markAsExitButton]}
                    />
                </div>
            </div>
        );
}
