import React, { useCallback } from "react";

import { ModuleModel } from "../../Everou/_models";
import { MenuSidebar } from "../_components";
import { WiredContentSpace } from "../../Everou/App/WiredContentSpace";

export function WiredContentWithSidebar({
    getComponentForModuleId = () => {},
    getModulesForIds = () => [],
    selectModuleAction,
} = {}) {

    const SidebarComp = useCallback(({
        selectedModuleId,
        enabledModulesIds,
        handleSelectModule,
    }) => <MenuSidebar {...{
            sidebarButtons: moduleModelsToSidebarButtons({
                selectedModuleId,
                handleSelectModule,
                enabledAppModules: getModulesForIds(enabledModulesIds),
            }),
        }}/>
    , [getModulesForIds]);

    //VIEW
    return (
        <WiredContentSpace {...{
            SidebarComp,
            getComponentForModuleId,
            selectModuleAction,
        }}/>
    );
}

function moduleModelsToSidebarButtons({
    selectedModuleId,
    handleSelectModule = moduleId => {},
    moduleIdsDown = [],
    enabledAppModules = [] || [ModuleModel()],
} = {}) {
    return enabledAppModules.map(module =>
        MenuSidebar.UiSidebarButtonModel({
            icon: module.icon,
            isDown: moduleIdsDown.includes(module.moduleId),
            tag: module.uiName,
            selected: module.moduleId === selectedModuleId,
            onClick: () => handleSelectModule(module.moduleId),
        })
    );
}