import { URL_CONSTANTS } from "../_constants";
import { ServiceHelper } from "./ServiceHelper";

export const KrossBookingService = {
    authenticate,
    listProperties,
    listRooms,
}


function authenticate(apikey: string, username: String, password: String, hotelID: string) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INTEGRATION_KROSS_BOOKING_AUTH,
        {
            apikey: apikey,
            username: username,
            password: password,
            hotel_id: hotelID
        }
    );
}

function listProperties(uid: string) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INTEGRATION_KROSS_BOOKING_PROPERTIES,
        {
            uid: uid
        }
    );
}

function listRooms(uid: string, host_id: number) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INTEGRATION_KROSS_BOOKING_ROOMS,
        {
            uid: uid,
            host_id: host_id
        }
    );
}