import { useTranslation } from "react-i18next";
import { PMSLocation } from "../../_models/PMSLocation";
import { CustomCard, CaptionText } from "../../../Everou/_components";
import { Icons } from "../../../Everou/_assets";
import { DEVICE_TYPES } from "../../../Everou/_constants";


export function PMSLocationView({
    location = PMSLocation(),
    handleSelectPMSLocation = () => {},
    isSelected = false,
}) {
    const { t } = useTranslation();

    return (
        <CustomCard
            data-testid={location.uid}
            selected={isSelected}
            onClick={handleSelectPMSLocation}
            key={location.uid}
            banner={protectStatus()}
            className="h-100"
            bannerBackground={protectStatusBackground()}
        >
            <div className="c-line-height-1 ml-2 c-mt-05 w-100">
                <div>
                    <div className="d-flex justify-content-between">
                        <h3 className="c-line-height-1 mb-0">{location.name}</h3>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <small style={{color: location.reservations.length? "#009FDF": "#828282"}}>{location.reservations? location.reservations.length:0}</small>
                        <img
                            className={`${
                                "c-img-1-25rem"
                            }`}
                            src={location.reservations.length > 0? Icons.icon_reservations: Icons.icon_no_reservations}
                        />
                        {hasWifiDevices() &&
                            <div className="pl-2">
                                {disconnectedDevices() ?
                                    <img
                                        className={`${
                                            "c-img-1-25rem"
                                        }`}
                                        src={Icons.wifi_no_connection}
                                    />
                                    :
                                    <img
                                        className={`${
                                            "c-img-1-25rem"
                                        }`}
                                        src={Icons.wifi_connection}
                                    />
                                }
                                
                            </div>
                        }
                        {hasLockDevices() &&
                            getLockBattery()   
                        }
                    </div>
                </div>
            </div>
        </CustomCard>
    );

    function disconnectedDevices() {
        var i;
        for (i=0; i<location.devices.length; i++) {
            const device = location.devices[i];
            switch (device.type) {
                case DEVICE_TYPES.Brain:
                case DEVICE_TYPES.EasyAccessContactNFCWifi:
                case DEVICE_TYPES.EasyParkingWifi:
                case DEVICE_TYPES.EasyIntegrations:
                    if (device.conn_status === "Desconectado")
                        return true;
            }
        }
        return false;
    }

    function hasWifiDevices() {
        var i;
        for (i=0; i<location.devices.length; i++) {
            const device = location.devices[i];
            switch (device.type) {
                case DEVICE_TYPES.Brain:
                case DEVICE_TYPES.EasyAccessContactNFCWifi:
                case DEVICE_TYPES.EasyParkingWifi:
                case DEVICE_TYPES.EasyIntegrations:
                    return true;
            }
        }
        return false;
    }

    function hasLockDevices() {
        var i;
        for (i=0; i<location.devices.length; i++) {
            const device = location.devices[i];
            switch (device.type) {
                case DEVICE_TYPES.GowayLock:
                    return true;
            }
        }
        return false;
    }

    function getLockBattery() {
        var i, battery = 100;
        for (i=0; i<location.devices.length; i++) {
            const device = location.devices[i];
            switch (device.type) {
                case DEVICE_TYPES.GowayLock:
                    const deviceBattery = device.battery? device.battery : 0;
                    if (deviceBattery <= battery)
                        battery = deviceBattery;
                    break;
            }
        }
        return (
            <div className="pl-2">
                <small style={{color: battery<=30? "red": "#828282"}}>{battery}%</small>
                <img
                    className={`${"c-img-1-25rem"}`}
                    src={battery <= 30 ? Icons.battery_low:Icons.battery}
                    />
            </div> 
            
        )
    }

    function protectStatus() {
        if (!location.protect)
            return null;

        let value = t("mod_pms_protect_disabled");
        if (location.protectStatus)
            value = t("mod_pms_protect_enabled");

        return (
            <div
                className="
                    text-right
                    c-line-height-1rem
                "
            >
                {value}
            </div>
        );
    }

    function protectStatusBackground() {
        return location.protectStatus? "#009FDF":"";
    }
}