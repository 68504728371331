import React from "react";
import { useTranslation } from "react-i18next";
import { GenericPaginatedScroll } from "./GenericPaginatedScroll";
import { IsoStartEndDatePicker } from "./IsoStartEndDatePicker";


export function GenericLogsPage({
    nextPage,
    isRequesting,
    requestingFirst,

    content,
    
    otherFilters,

    startIsoString,
    endIsoString,
    timezone,

    handleNextPage = () => {},
    handleLogsDateStartChange = (date) => {},
    handleLogsDateEndChange = (date) => {},

    isThereLogs = false,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div
            className="
                h-100
                w-100
                mh-100

                d-flex
                no-gutters
                flex-column
                
                overflow-auto
            "
        >
            <div className="mt-2"/>
            <div className="
                w-100
                d-flex
                align-items-center
                justify-content-between
                flex-wrap
            ">
                <IsoStartEndDatePicker
                    startIsoDate={startIsoString}
                    endIsoDate={endIsoString}
                    handleStartDateChange={handleLogsDateStartChange}
                    handleEndDateChange={handleLogsDateEndChange}
                    timezone={timezone}
                />
            </div>

            <div className="my-2">
                {otherFiltersSection()}
            </div>
            <GenericPaginatedScroll
                content={content}
                handleNextPage={handleNextPage}
                isRequesting={isRequesting}
                isRequestingFirst={requestingFirst}
                isThereContent={isThereLogs}
                isLastPage={nextPage === -1}
                
                noContentMessage={t("logs.noLogsFound")}
                getNextPageString={t("logs_load_more_logs")}
                noMoreContentString={t("logs_no_more_logs")}
            />
        </div>
    );

    function otherFiltersSection() {
        if (!otherFilters)
            return;
        
        return (
            <>
                {otherFilters}
            </>
        );
    }

}