import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { CapsuleButton } from "../_components";
import { BaseClientForm } from "./BaseClientForm";

export function MinimalClientForm({
    client,
    onSave = (clientParams) => {},
}) {
    //LIBRARIES
    const { t } = useTranslation();

    //FORM STATE
    const [isAllValid, setIsAllValid] = useState(false);
    const [forceShowWarnings, setForceShowWarnings] = useState(false);
    const [clientParams, setClientParams] = useState({});

    //VIEW
    return (
        <div>
            <BaseClientForm
                client={client}
                forceShowWarnings={forceShowWarnings}
                setIsAllValid={setIsAllValid}
                setParams={setClientParams}
            />
            <div className="mt-2"/>
            <div className="d-flex justify-content-end">
                <CapsuleButton
                    text={t("global_save")}
                    onClick={handleCreateClientAndBudget}
                />
            </div>
        </div>
    )

    //ACTION HANDLERS
    function handleCreateClientAndBudget() {
        setForceShowWarnings(true);
        
        if (!isAllValid)
            return;

        onSave(clientParams);
    }
}