import { Card } from "../_models/Card";

export class CardMapper {
    static serverToLocal(serverCard) {
        return Card({
            code:                   serverCard.code,
            state:                  serverCard.state,
            cardPackUid:            serverCard.card_pack_uid,
            invitationGroupName:    serverCard.invitation_group_name,
            invitationGroupUid:     serverCard.invitation_group_uid,
            type:                   serverCard.type,
            maxDuration:            serverCard.max_limited_time,
            isConfiguration:        serverCard.is_configuration
        });
    }
}