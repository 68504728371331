import { Selectors } from "../../_reducers/app.reducer";
import { BarriersService } from "../../_services/barriers.service";
import { BarrierMapper } from "../../_mappersTS/BarrierMapper";
import { Barrier, BarrierUpdate } from "../../_modelsTS/Barrier";
import { BarrierErrors } from "./BarriersStore";

const BARRIERS_ERROR_CODES = {
    DEVICES_MISCONFIGURED: 688,
    CAPACITY_ERROR: 683
}


export enum BARRIERS_ACTIONS_TYPES {
    UPDATE_FIELDS = 'UPDATE_FIELDS',
    UPDATE_ERRORS = 'UPDATE_ERRORS',
    UPDATE_LOADING = 'UPDATE_LOADING'
}

export const barriersActions = {
    //PUBLIC REMOTE
    getInfo,
    updateInfo,
    updateErrors,
    setIsLoading
};

function setIsLoading(isLoading: boolean) {
    return {
        type: BARRIERS_ACTIONS_TYPES.UPDATE_LOADING,
        isLoading
    }
}

function updateLocally(barrier: Barrier) {
    return {
        type: BARRIERS_ACTIONS_TYPES.UPDATE_FIELDS,
        barrier
    }
}

function updateErrors(errors: BarrierErrors) {
    return {
        type: BARRIERS_ACTIONS_TYPES.UPDATE_ERRORS,
        errors
    }
}

function removeAllErrors() {
    return dispatch => {
        dispatch(updateErrors({ devicesMisconfigured: false, capacityError: false }));
    }
}

function handleErrors(error) {
    return dispatch => {
        const errors: BarrierErrors  = {
                capacityError: error.name === BARRIERS_ERROR_CODES.CAPACITY_ERROR,
                devicesMisconfigured: error.name === BARRIERS_ERROR_CODES.DEVICES_MISCONFIGURED
            }
        dispatch(updateErrors(errors));
    }
}

function getInfo(loc_uid = '') {
    return async (dispatch, getState) => {

        if(!loc_uid) {
            loc_uid = Selectors.getSelectedLocationUid(getState())!;
        }

        try {
            const server_info = await BarriersService.getInfo(loc_uid);
            const info = BarrierMapper.serverToLocal(server_info);
            dispatch(removeAllErrors());
            dispatch(updateLocally(info));
        } catch (error: any) {
            dispatch(handleErrors(error));
        }
    }
}

function updateInfo(barrierUpdate: BarrierUpdate, loc_uid = '') {
    return async (dispatch, getState) => {
        
        if(!loc_uid) {
            loc_uid = Selectors.getSelectedLocationUid(getState())!;
        }
        try {
            let server_barrier = BarrierMapper.localUpdateToServer(barrierUpdate);
            const server_info = await BarriersService.updateInfo(server_barrier, loc_uid);
            const info = BarrierMapper.serverToLocal(server_info);   
            dispatch(removeAllErrors());
            dispatch(updateLocally(info));
        } catch (error: any) {
            dispatch(handleErrors(error));
        }
 
    }
}