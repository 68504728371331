import { WidgetFavDevicesSettings } from "../_widgets/WidgetFavDevicesSettings";
import { WIDGET_TYPES } from "../_constants/dashboard.constants";
import { appPermissions } from "../_constants/permissions.constants";

export const DashboardHelper = {
    doesUserHavePermissionForWidget,
    settingsModuleForWidgetType,
};

function doesUserHavePermissionForWidget(widgetType, locationPermission) {
    return isWidgetVisible(widgetType, locationPermission);
}

function isWidgetVisible(widgetType, locationPermission) {
    switch (widgetType) {
        case WIDGET_TYPES.INVITATIONS:
            return appPermissions.canUserSeeInvitations(locationPermission);

        case WIDGET_TYPES.ALERTS:
            return appPermissions.canUserViewAlerts(locationPermission);

        case WIDGET_TYPES.LOGS:
            return appPermissions.canUserViewDeviceActivity(locationPermission);

        case WIDGET_TYPES.DEVICES:
            return true;

        default:
            return false;
    }
}

function settingsModuleForWidgetType(widget) {
    switch (widget) {
        case WIDGET_TYPES.DEVICES:
            return WidgetFavDevicesSettings;

        default:
            return null;
    }
}