import { genericActions } from "../_helpers/ActionErrorWrapper";

import { ClientsService } from "../_services/ClientsService";
import { ClientMapper } from "../_mappers/ClientMapper";
import { ClientCollection } from "../_generics/ClientCollection";
import { Client } from "../_models/Client";
import { ClientsSecondaryViewStore } from "../_stores/ClientsSecondaryViewStore";

export const ClientActions = {
    //LOCAL
    selectClient: ClientCollection.actionSelectItem,
    changeSecondaryView: ClientsSecondaryViewStore.actionSet,

    //REMOTE
    createAndSelectClient,
    createClient,
    listClients,
    updateClient,
    deleteClient,

    //PRIVATE
    _getClientSuccess: ClientCollection.actionGetItemSuccess,
    _listClientsSuccess: ClientCollection.actionListItemsSuccess,
    _updateClientSuccess: ClientCollection.actionUpdateItemSuccess,
    _deleteClientSuccess: ClientCollection.actionDeleteItem,
};

function listClients() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverClients = await ClientsService.listClients();
            dispatch(ClientActions._listClientsSuccess(
                ClientMapper.allServerToLocal(serverClients)
            ));
        }
    }
}

function updateClient({
    uid,
    address,
    cif,
    email,
    name,
    phone,
}) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverClient = await ClientsService.updateClient({
                uid,
                address,
                cif,
                email,
                name,
                phone,
            });

            dispatch(ClientActions._updateClientSuccess(
                ClientMapper.serverToLocal(serverClient)
            ))
        }
    }
}

function createAndSelectClient({
    address,
    cif,
    email,
    name,
    phone,
} = {}) {
    return async dispatch => {
        const clientParams = arguments[0];
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            let client = Client();
            client = await dispatch(ClientActions.createClient(clientParams, true));
            dispatch(ClientActions.selectClient(client.uid));
        }
    };
}

function createClient(
    {
        address,
        cif,
        email,
        name,
        phone,
    } = {},
    rethrow = false,
) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            const serverClient = await ClientsService.createClient({
                address,
                cif,
                email,
                name,
                phone,
            });

            const client = ClientMapper.serverToLocal(serverClient);
            dispatch(ClientActions._getClientSuccess(client));
            return client;
        }
    }
}

function deleteClient(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await ClientsService.deleteClient(uid);
            dispatch(ClientActions._deleteClientSuccess(uid));
        }
    }
}