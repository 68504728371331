import { ReadOnlyDataRow } from "../../../Everou/_components";
import { CustomCard } from "../../../Common/_components";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IsoRangeDatePicker } from "../../../Everou/_components/IsoRangeDatePicker";
import { TimeHelper } from "../../../Everou/_helpers";
import { ReservationPublicUrl } from "./InvitationPublicUrl";
import { EditReservationButtons } from "./EditReservationButtons";
import { useCallback, useEffect, useState } from "react";
import { PMSLocationsActions } from "../../_actions/PMSLocationsActions";

export function ReservationInput( {
    reservation
}) {

    // LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // GLOBAL STATE
    const [editable, setEditable] = useState(false);
    const timezone = TimeHelper.getCurrentTimezone();

    const [startDate, setStartDate] = useState(TimeHelper.serverStringToIsoString(reservation.reservationStart));
    const [endDate, setEndDate] = useState(TimeHelper.serverStringToIsoString(reservation.reservationEnd));

    useEffect(() => {
        setStartDate(reservation.reservationStart);
        setEndDate(reservation.reservationEnd);
    }, [reservation]);

    const updateEdit = useCallback(() => {
        setEditable(!editable);
        setStartDate(TimeHelper.serverStringToIsoString(reservation.reservationStart));
        setEndDate(TimeHelper.serverStringToIsoString(reservation.reservationEnd));
    }, [setEditable, editable]);

    const handleStartDate = useCallback((date) => {
        setStartDate(date);
    }, [setStartDate]);

    const handleEndDate = useCallback((date) => {
        setEndDate(date);
    }, [setEndDate]);
    

    const save = useCallback(() => {
        dispatch(PMSLocationsActions.updateReservation(
            reservation.uid,
            startDate,
            endDate
        ));

        setEditable(false);
    }, [dispatch, reservation, startDate, endDate]);

    // VIEW
    return (
        <CustomCard className="mb-3" key={reservation.uid}>
            <ReadOnlyDataRow
                key={reservation.uid + '1'}
                name={t('global_email')}
                data={reservation.email} />
            <ReadOnlyDataRow
                key={reservation.uid + '2'}
                name={t('mod_integrations_reservation_id')}
                data={reservation.reservationId} />
            <div className="mt-1">
                <IsoRangeDatePicker
                    onChangeStart={handleStartDate}
                    onChangeEnd={handleEndDate}
                    timezone={timezone}
                    begin={startDate}
                    end={endDate}
                    readOnly={!editable}
                    minDate={new Date()}
                />
            </div>
            {reservation.url && 
                <ReservationPublicUrl
                    publicUrl={reservation.url} 
                />
            }

            <EditReservationButtons
                reservation={reservation}
                showSaveButton={editable}
                handleEdit={updateEdit}
                handleSave={save}
            />                    
        </CustomCard>
    )

}