export const WEBSOCKETS_MESSAGE_TYPE = {
    ERROR:      -1,  
    PING:       11,
    PONG:       12,
    GPIO_ORDER: 300,
};

export const WEBSOCKETS_CONFIGURATION = {
    TIMEOUT_PING:   120000,
    KEEP_ALIVE:     120,
};
