import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { locationActions } from "../_actions";
import { BaseInputCodeGlobalPopUp } from "../GlobalPopUps/BaseInputCodeGlobalPopUp";

const testIDs = {
    locationNameInput: "new-location-name-input",
    createLocationButton: "new-location-create-button",
};

CreateLocationGlobalPopUp.testIDs = testIDs;

export function CreateLocationGlobalPopUp() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [name, setName] = useState("");

    //VIEW
    return (
        <BaseInputCodeGlobalPopUp
            inputTestID={testIDs.locationNameInput}
            buttonTestID={testIDs.createLocationButton}

            headerTitle={t("create_location_title")}
            buttonText={t("webapp_global_create")}
            onChangeInput={setName}
            onClickButton={handleCreateLocation}
            placeholder={t("create_location_name_placeholder")}
            value={name}
        />
    );

    //ACTION HANDLERS
    function handleCreateLocation() {
        dispatch(locationActions.createLocation(name));
    }
}