import { i18n } from "../_translations/i18n";
import { ValidationHelper } from "./";

export class AuthenticationHelper {

    static validateEmails(emails) {
        if (emails.length === 0)
            throw new Error(i18n.t("errors.noEmailAddress"));

        emails.forEach(email => {
            if (!ValidationHelper.ValidationFns.isEmailValid(email))
                throw new Error(i18n.t("errors.emailNotValid", { email }));
        });
    }

    static processValidationEmail(email = "") {
        return email.replace(/\s/g, '+');
    }
};