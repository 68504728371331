export class FileHelper {

    static async processCsvFile(csvFile) {
        const csvString = await this.readFileAsTextAsync(csvFile);
        return getArrayFromCsvString(csvString);
    }

    static async readFileAsTextAsync(inputFile) {
        const temporaryFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            temporaryFileReader.onerror = () => {
                temporaryFileReader.abort();
                reject("Problem parsing input file");
            };

            temporaryFileReader.onload = () => {
                resolve(temporaryFileReader.result);
            };

            temporaryFileReader.readAsText(inputFile);
        });
    }
}

function getArrayFromCsvString(commaSeparatedString) {
    const commaSeparatedLines = commaSeparatedString.split('\n');
    const emails = commaSeparatedLines.reduce((emails, line) => 
    [...emails, ...line.split(",")]
    , []);

    return removeEmpty(removeSpaces(emails));
    
    function removeSpaces(emails) {
        return emails.map(email => email.replace(/\s+/g, ""));
    }

    function removeEmpty(emails) {
        return emails.filter(email => email ? true : false );
    }
}