import { Selectors } from "../../Everou/_reducers/app.reducer";
import { useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ContentSpace, CustomCard, MainPageWithButtons } from "../../Common/_components";
import { IntegrationReservationStatusLabel } from "../../Everou/_modelsTS/Integration";
import { ReadOnlyDataRow } from "../../Everou/_components";
import { PMSLocationsActions } from "../_actions/PMSLocationsActions";
import { PMSLocationFilter } from "../_models/Pagination";
import { PMSLocationsPaginationQueryParams } from "../_models/Pagination";
import { Paginator } from "../../Everou/_components";
import { PMSLocationColumn } from "./_components/PMSLocationColumn";

export function PMSReservationsModule() {

    // INITIALIZATION
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // GLOBAL STATE
    const {
        offset,
        items: locations,
        page_size,
        total_count
    } = useSelector(Selectors.getPMSLocations);

    const listRef = useRef<HTMLDivElement>(null);

    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSLocationsActions.getPMSLocations({offset}));
    }, [dispatch]);


    // ACTION HANDLERS
    const getLocations = useCallback(
        (filters: PMSLocationFilter & PMSLocationsPaginationQueryParams = {}) => {
            dispatch(
                PMSLocationsActions.getPMSLocations({
                    offset,
                    page_size,
                    search_term: filters.search_term,
                    ...filters,
                }),
            );
        },
        [dispatch, offset, page_size]
    );

    const onChangePage = async offset => {
        if (listRef) {
            listRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        dispatch(PMSLocationsActions.changeOffset(offset));
        await getLocations({offset});
    };

    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("mod_pms_reservations")}
                    size={"X_LARGE"}
                    content={
                        content()
                    }
                />
            }
        />
    )

    function content() {
        if (total_count == 0) {
            return <div className='d-flex align-items-center justify-content-center'><h3>{t('mod_pms_locations_empty_message')}</h3></div>;
        }

        return (
            <div className="d-flex flex-column-reverse h-100">
                {(total_count ?? 0) > (page_size ?? 0) && (
                    <div className="bg-white pt-3 py-2">
                        <Paginator
                            pageSize={page_size ?? 0}
                            total={total_count ?? 0}
                            pageOffset={offset}
                            onChangePage={onChangePage}
                        />
                    </div>
                )}
                <div className="position-relative h-100">
                    <div className="position-absolute top-0 left-0 w-100 overflow-auto h-100">
                        <div ref={listRef} >
                            <PMSLocationColumn
                                locations={locations}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}