import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const LocationBillingDataService = {
    getBillingData,
    deleteBilingData,
    updateBillingData,
};

async function getBillingData(locationUid) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_BILLING_DATA_INFO,
        { loc_uid: locationUid },
    );
}

async function updateBillingData(locationUid, name, taxNumber, streetAddress, postalCode, city, state, country, phoneNumber) {
    return await ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_BILLING_DATA_UPDATE,
        {
            loc_uid: locationUid,
            name: name,
            tax_number: taxNumber,
            street_address: streetAddress,
            postal_code: postalCode,
            city: city,
            state: state,
            country: country,
            phone_number: phoneNumber
        },
    );
}

async function deleteBilingData(locationUid) {
    await ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_BILLING_DATA_DELETE,
        { loc_uid: locationUid },
    );
}
