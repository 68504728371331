import { genericActions } from "../../Everou/_actions/generic.actions"
import { PMSIntegrationsCollection } from "../_stores/PMSIntegrationsCollection";
import { PMSIntegrationsService } from "../_services/PMSIntegrationsService";
import { IntegrationMapper } from "../../Everou/_mappersTS/IntegrationMapper";
import { Integration } from "../../Everou/_modelsTS/Integration";
import { PMSKrossBookingService } from "../_services/PMSKrossBookingService";
import { PMSKrossBookingStore } from "../_stores/PMSKrossBookingStore";
import { KrossBookingMapper } from "../../Everou/_mappersTS/KrossBookingMapper";
import { PMSHostifyService } from "../_services/PMSHostifyService";
import { PMSHostifyStore } from "../_stores/PMSHostifyStore";
import { PMSSmoobuStore } from "../_stores/PMSSmoobuStore";
import { PMSSmoobuService } from "../_services/PMSSmoobuService";
import { PMSOctorateService } from "../_services/PMSOctorateService";
import { PMS_INTEGRATIONS_ACTION_TYPES } from "../_actionTypes/PMS_INTEGRATIONS_ACTION_TYPES";
import { PMSOctorateStore } from "../_stores/PMSOctorateStore";
import { PMSAvaibookStore } from "../_stores/PMSAvaibookStore";
import { PMSAvaibookService } from "../_services/PMSAvaibookService";

export const PMSIntegrationsActions = {
    listIntegrations,
    listIntegrationsRequest: PMSIntegrationsCollection.actionListItemsRequest,
    listIntegrationsSuccess: PMSIntegrationsCollection.actionListItemsSuccess,

    createIntegration,
    deleteIntegration,

    listKrossBookingProperties,
    listKrossBookingRooms,

    listHostifyListings,

    listSmoobuProperties,

    listOctorateAccommodations,
    octorateToken,
    getOctorateUserInfo,

    listAvaibookAccommodations,
}

function listIntegrations() {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(PMSIntegrationsActions.listIntegrationsRequest());
            const serverIntegrations = await PMSIntegrationsService.list();
            const localIntegrations = IntegrationMapper.allServerToLocal(serverIntegrations);
            dispatch(PMSIntegrationsActions.listIntegrationsSuccess(localIntegrations));

            return localIntegrations;
        }
    }
}

function createIntegration(localIntegration: Integration) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, null, true));

        async function asyncAction() {
            const serverIntegration = IntegrationMapper.localToServer(localIntegration);
            const integration = await PMSIntegrationsService.create(serverIntegration);

            dispatch(listIntegrations());

            return integration;
        }
    }
}

function deleteIntegration(integrationUid: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSIntegrationsService.deleteIntegration(integrationUid);
            dispatch(PMSIntegrationsCollection.actionDeleteItem(integrationUid));
        }
    }
}

function listKrossBookingProperties(integrationUid: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverKrossBookingProperties = await PMSKrossBookingService.listProperties(integrationUid);
            dispatch(PMSKrossBookingStore.actionListItemsSuccess(serverKrossBookingProperties));
        }
    }
}

function listKrossBookingRooms(uid: string, host_id: number) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverKrossBookingRooms = await PMSKrossBookingService.listRooms(uid, host_id);
            const rooms = KrossBookingMapper.serverListRoomToLocal(serverKrossBookingRooms);
            dispatch(PMSKrossBookingStore.actionUpdateItemSuccess({id_property:host_id, rooms}));
        }
    }
}

function listHostifyListings(integrationUID: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const listings = await PMSHostifyService.listListings(integrationUID);
            dispatch(PMSHostifyStore.actionListItemsSuccess(listings));
        }
    }
}

function listSmoobuProperties(integrationUID: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const listings = await PMSSmoobuService.listProperties(integrationUID);
            dispatch(PMSSmoobuStore.actionListItemsSuccess(listings));
        }
    }
}

function octorateToken(code: any) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, null, true));

        async function asyncAction() {
            await PMSOctorateService.token(code);
            return dispatch(listIntegrations);
        }
    }
}

function listOctorateAccommodations(integrationUID: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const listings = await PMSOctorateService.listAccommodations(integrationUID);
            dispatch(PMSOctorateStore.actionListItemsSuccess(listings));
        }
    }
}

function getOctorateUserInfo(integrationUID: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const userInfo = await PMSOctorateService.userInfo(integrationUID);
            dispatch(getOctorateUserInfoSuccess(userInfo));
        }

        function getOctorateUserInfoSuccess(userInfo) {
            return {
                type: PMS_INTEGRATIONS_ACTION_TYPES.GET_OCTORATE_USER_INFO_SUCCESS,
                userInfo
            };
        }
    }
}

function listAvaibookAccommodations(integrationUID: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const listings = await PMSAvaibookService.listAccommodations(integrationUID);
            dispatch(PMSAvaibookStore.actionListItemsSuccess(listings));
        }
    }
}