import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseWidget } from "./BaseWidget";
import { alertsActions } from "../_actions";
import alertsIcon from "../_assets/appIcons/notifications-icon.svg";
import { Selectors } from "../_reducers/app.reducer";

export function WidgetAlerts({
    widget,
    editable,
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const alerts = useSelector(Selectors.getAlerts);

    //INITIALIZATION
    useEffect(() => {
        dispatch(alertsActions.getAlerts());
    }, [dispatch]);

    const { widgetId } = widget;

    //VIEW
    return (
        <BaseWidget
            widgetId={widgetId}
            editable={editable}
            title={t("global_alerts")}
            content={content(alerts)}
        />
    );

    function content(alerts) {
        return alerts.map(singleAlert);
    }

    function singleAlert(alert, index) {
        return (
            <div
                key={index}
                className="
                    d-flex
                    align-items-center

                    bg-white
                    border rounded
                    p-1 my-1
                "
            >
                <img
                    className="
                        c-img-1-5rem
                    "
                    src={alertsIcon}
                    alt="alert icon"
                />

                <span className="pl-2">
                    {alert.message}
                </span>
            </div>
        );
    }
}

