import { ServerIntegrationSend } from "../_modelsTS/Integration"
import { URL_CONSTANTS } from "../_constants"
import { ServiceHelper } from "./ServiceHelper"

export const IntegrationService = {
    listIntegrations,
    createIntegration,
    deleteIntegration,
    listReservations,
}

function listIntegrations(loc_uid: string) {
    return ServiceHelper.getRequest(URL_CONSTANTS.INTEGRATIONS, { loc_uid })
}

function createIntegration(loc_uid: string, serverIntegration: ServerIntegrationSend) {
    return ServiceHelper.putRequest(URL_CONSTANTS.INTEGRATIONS, { ...serverIntegration, loc_uid })
}

function deleteIntegration(loc_uid: string, uid: string) {
    return ServiceHelper.deleteRequest(URL_CONSTANTS.INTEGRATIONS, null, { loc_uid, uid });
}


function listReservations(loc_uid: string, uid: string) {
    return ServiceHelper.getRequest(URL_CONSTANTS.INTEGRATION_INFO, { loc_uid, uid });
}

