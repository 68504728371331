import { LOCAL_STATUS } from "../_constants";

import offLightbulbIcon from './icons/device-icon-light-off@3x.png';
import onLightbulbIcon from './icons/device-icon-light-on@3x.png';

import offIcon from './icons/device-icon-plug-off@3x.png';
import onIcon from './icons/device-icon-plug-on@3x.png';

import stoppedRollerIcon from './icons/device-icon-blind@3x.png';
import openedRollerIcon from './icons/device-icon-blind-up@3x.png';
import closedRollerIcon from './icons/device-icon-blind-down@3x.png';

import floodIcon from './icons/device-icon-humedad@3x.png';
import plugFloodClosedIcon from './icons/icon_plug_flood_off.png';

import closedIcon from './icons/device-icon-reed-closed.png';
import openicon from './icons/device-icon-reed-opened.png';

import gowayLockOpenIcon from './icons/log_icon_lock_open_blue.png';
import gowayLockOpenLockedIcon from './icons/log_icon_lock_closed_blue.png';
import gowayLockClosedUnlockedIcon from './icons/log_icon_lock_open_grey.png';
import gowayLockClosedLockedIcon from './icons/log_icon_lock_closed_grey.png'
import pressButtonIcon from "./appIcons/touch-icon-24px.svg";
import recordIcon from './icons/device-icon-activecam@3x.png';
import sirenIcon from "./appIcons/volume_up-24px.svg";
import vibrationsIcon from './icons/device-icon-senser@3x.png';

export function deviceStatusIcon(localStatus) {
    switch (localStatus) {

        //START TRIGGER STATUS
        case LOCAL_STATUS.BRAIN_RECORD_VIDEO:
            return recordIcon;
        case LOCAL_STATUS.BRAIN_SIREN:
            return sirenIcon;

        case LOCAL_STATUS.BUTTON_PRESSED:
            return pressButtonIcon;

        case LOCAL_STATUS.VIBRATIONS:
            return vibrationsIcon;
        //END TRIGGER STATUS

        case LOCAL_STATUS.LIGHT_OFF:
            return offLightbulbIcon;
        case LOCAL_STATUS.LIGHT_ON:
            return onLightbulbIcon;

        case LOCAL_STATUS.OFF:
            return offIcon;
        case LOCAL_STATUS.ON:
            return onIcon;

        case LOCAL_STATUS.ROLLER_STOPPED:
            return stoppedRollerIcon;
        case LOCAL_STATUS.ROLLER_OPENED:
            return openedRollerIcon;
        case LOCAL_STATUS.ROLLER_CLOSED:
            return closedRollerIcon;
        
        case LOCAL_STATUS.IDLE:
        case LOCAL_STATUS.FLOOD:
        case LOCAL_STATUS.FLOOD_OPEN:
            return floodIcon;

        case LOCAL_STATUS.FLOOD_CLOSED:
            return plugFloodClosedIcon;

        case LOCAL_STATUS.CLOSED:
            return closedIcon;
        case LOCAL_STATUS.OPEN:
            return openicon;

        case LOCAL_STATUS.CLOSED_LOCKED:
            return gowayLockClosedLockedIcon;
        case LOCAL_STATUS.CLOSED_UNLOCKED:
            return gowayLockClosedUnlockedIcon;
        case LOCAL_STATUS.OPENED_UNLOCKED:
            return gowayLockOpenIcon;
        case LOCAL_STATUS.OPENED_LOCKED:
            return gowayLockOpenLockedIcon;

        default:
            return undefined;
    }
}