import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BaseAppGlobalPopUp } from "../../GlobalPopUps/BaseAppGlobalPopUp";
import { PopUpAddPackLocationSelector } from "./PopUpAddPackLocationSelector";
import { AddPackWizard } from "./AddPackWizard";
import { invitationCardActions } from "../../_actions/invitationCard.actions";

const PAGES = {
    NOTIFICATION:   "NOTIFICATION",
    LOCATION:       "LOCATION",
    WIZARD:         "WIZARD",
};

const testIDs = {
    addNewPackConfirmationButon: "add-pack-unconfigured-confirmation-button",
};

AddPackWizardPopUp.testIDs = testIDs;

export function AddPackWizardPopUp() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [currentPage, setCurrentPage] = useState(PAGES.NOTIFICATION);
    const [locationUid, setLocationUid] = useState(null);

    //ACTION HANDLERS
    function handleAcceptNofication() {
        setCurrentPage(PAGES.LOCATION);
    }

    function handleSelectLocation(locationUid) {
        setLocationUid(locationUid);
        setCurrentPage(PAGES.WIZARD);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => cleanUpCards, [dispatch])

    function cleanUpCards() {
        dispatch(invitationCardActions.removeSelectedCard())
    } 
    //VIEW
    switch (currentPage) {
        case PAGES.NOTIFICATION:
            return <BaseAppGlobalPopUp
                acceptButtonTestID={testIDs.addNewPackConfirmationButon}
                onAccept={handleAcceptNofication}
                body={t("mod_cards_not_configured")}
                closeOnAccept={false}
            />;

        case PAGES.LOCATION:
            return <PopUpAddPackLocationSelector
                handleAcceptLocation={handleSelectLocation}
            />;

        case PAGES.WIZARD:
            return (
                <AddPackWizard
                    locationUid={locationUid}
                    BaseWrapper={BaseAppGlobalPopUpWrapper}
                    ExistingGroupsWrapper={BaseAppGlobalPopUpNoOverflowWrapper}
                />
            );
        
        default:
            return <BaseAppGlobalPopUp
                body={"PAGE NOT FOUND"}
            />
    }
}

function BaseAppGlobalPopUpWrapper({ children } = {}) {
    return (
        <BaseAppGlobalPopUp
            body={children}
            hideAccept
            limitWidth={false}
        />
    );
}

function BaseAppGlobalPopUpNoOverflowWrapper({ children } = {}) {
    return (
        <BaseAppGlobalPopUp
            body={children}
            hideAccept
            limitWidth={false}
            overflowVisible={false}
        />
    );
}