import { TimeHelper } from "../../Everou/_helpers";

export function GenericDateFilterFactory(ID) {
    const ACTION_TYPES = {
        SET_BEGIN_END: "SET_BEGIN_END_" + ID,
    };

    //Actions
    function actionSetBeginEnd({ begin, end } = {}) {
        return {
            type: ACTION_TYPES.SET_BEGIN_END,
            begin,
            end,
        };
    }

    //Reducer
    const initialState = {
        begin: null,
        end: null,
    };

    function reducer(state = initialState, action) {
        switch (action.type) {

            case ACTION_TYPES.SET_BEGIN_END: {
                const combined = combineDates();
                return {
                    ...state,
                    begin: TimeHelper.isIsoAAfterB(combined.begin, combined.end)
                        ? combined.end
                        : combined.begin
                    ,
                    end: combined.end,
                };
          
                function combineDates() {
                    return {
                        begin: action.begin !== undefined
                            ? action.begin
                            : state.begin
                        ,
                        end: action.end !== undefined
                            ? action.end
                            : state.end
                        ,
                    };
                }
            }

            default:
                return state;
        }
    }

    //Selectors
    const selectorGetBeginEnd = state => state;

    //Output
    return {
        reducer,
        actionSetBeginEnd,
        selectorGetBeginEnd,
        _typeSetBeginEnd: ACTION_TYPES.SET_BEGIN_END,
    };
}