import { combineReducers } from 'redux';

import { budgetUserReducer } from './budgetUserReducer';
import { distributorReducer, DistributorSelectors } from './distributorReducer';
import { ProductsCollection } from '../_generics/ProductsCollection';
import { BudgetCollection } from '../_generics/BudgetCollection';
import { EntryCollection } from '../_generics/EntryCollection';
import { ClientCollection } from '../_generics/ClientCollection';
import { CommonStore } from "../../Everou/_reducers/common.reducer";
import { ClientsSecondaryViewStore } from '../_stores/ClientsSecondaryViewStore';
import { ProductsSecondaryViewStore } from '../_stores/ProductsSecondaryViewStore';
import { BudgetsSecondaryViewStore } from '../_stores/BudgetsSecondaryViewStore';


//STATE
let getRootState = state => state;

function setRootStateFn(rootStateFn = state => state) {
    getRootState = rootStateFn;
}

//REDUCER
const reducer = combineReducers({
    budgetUser: budgetUserReducer,
    distributor: distributorReducer,
    clients: ClientCollection.reducerCollection,
    products: ProductsCollection.reducerCollection,
    budgets: BudgetCollection.reducerCollection,
    entries: EntryCollection.reducerCollection,

    secondaryViews: combineReducers({
        clients: ClientsSecondaryViewStore.reducer,
        products: ProductsSecondaryViewStore.reducer,
        budgets: BudgetsSecondaryViewStore.reducer,
    }),
});


//SELECTORS

//SET SELECTOR ROOT STATES
const getSecondaryViewsState      = state => getRootState(state).secondaryViews;
const getBudgetUserState          = state => getRootState(state).budgetUser;
const getDistributorState         = state => getRootState(state).distributor;

EntryCollection.setRootStateFn(     state => getRootState(state).entries);
BudgetCollection.setRootStateFn(    state => getRootState(state).budgets);
ProductsCollection.setRootStateFn(  state => getRootState(state).products);
ClientCollection.setRootStateFn(    state => getRootState(state).clients);

ClientCollection.setRootStateFn(    state => getRootState(state).clients);

ClientsSecondaryViewStore.setRootStateFn(state => getSecondaryViewsState(state).clients);
ProductsSecondaryViewStore.setRootStateFn(state => getSecondaryViewsState(state).products);
BudgetsSecondaryViewStore.setRootStateFn(state => getSecondaryViewsState(state).budgets);

const budgetSelectorsOnly = {
    //BUGDET USER
    getBugetUser            : getBudgetUserState,

    //DITRIBUTOR
    getDistributor          : state => DistributorSelectors.getDistributor(getDistributorState(state)),

    //CLIENTS
    getClient               : ClientCollection.selectorGetItem,
    getClients              : ClientCollection.selectorGetItems,
    getSelectedClientUid    : ClientCollection.selectorGetSelectedId,
    getSelectedClient       : ClientCollection.selectorGetSelectedItem,

    //PRODUCTS
    getProducts             : ProductsCollection.selectorGetItems,
    getSelectedProduct      : ProductsCollection.selectorGetSelectedItem,
    getSelectedProductUid   : ProductsCollection.selectorGetSelectedId,

    //BUDGETS
    getBudgets              : BudgetCollection.selectorGetItems,
    getSelectedBudget       : BudgetCollection.selectorGetSelectedItem,
    getSelectedBudgetUid    : BudgetCollection.selectorGetSelectedId,

    //ENTRIES
    getEntries              : EntryCollection.selectorGetItems,

    //SECONDARY VIEWS
    getClientsModuleSecondaryView   : ClientsSecondaryViewStore.selectorGet,
    getProductsModuleSecondaryView  : ProductsSecondaryViewStore.selectorGet,
    getBudgetsModuleSecondaryView   : BudgetsSecondaryViewStore.selectorGet,
};

const selectors = {
    ...CommonStore.selectors,
    ...budgetSelectorsOnly,
};


//STORE EXPORT
export const BudgetsStore = {
    selectors,
    reducer,
    setRootStateFn,
};