import React from "react";
import { useTranslation } from "react-i18next";

const testIDs = {
    root: "no-locations-view",
}

NoLocationsView.testIDs = testIDs;

export function NoLocationsView() {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div
            data-testid={testIDs.root}
            className="
                h-100
                w-100

                d-flex
                align-items-center
                justify-content-center
            "
        >
            <div className="text-center">
                <div className="c-new-ft-l font-weight-bold">
                    {t("no_locations_title_pw")}
                </div>
                <div className="">
                    {t("no_locations_message_pw")}
                </div>
            </div>
        </div>
    );
}