export class WidgetMapper {

    static serverToLocal(serverWidget) {
        return {
            widgetId: serverWidget.uid,
            widgetType: serverWidget.type,
            settings: serverWidget.settings,
        };
    }

}