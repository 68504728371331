import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import defaultBackgoundImg from '../_assets/images/login_background.jpg';
import { Icons } from '../../Common/_assets/icons/Icons';
import { LocationCustomization } from '../_models/LocationCustomization';
import { Selectors } from '../_reducers/app.reducer';
import { CustomizationStore } from '../_stores/CustomizationStore/CustomizationStore';
import { HistoryHelper } from '../_helpers';
import { WiredUiAlerts } from "../../Common/UiAlerts/WiredUiAlerts";

export function BaseLoginPage({ content }) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let customization = LocationCustomization();
    customization = useSelector(Selectors.getLocationCustomization);

    const customizedBgImg = customization && customization.loginBackground;

    //INITIALIZATION
    useEffect(() => {
        if (customization)
            return;

        const subdomain = HistoryHelper.getSubdomain();
        if (!subdomain)
            return;

        dispatch(CustomizationStore.actions.getLoginInfo(subdomain));
    }, [dispatch, customization]);

    //VIEW
    return (
        <div className="
            vh-100
            d-flex
            no-gutters
            flex-column
            overflow-auto
        ">
            <WiredUiAlerts />
            {basePage()}
            {darkOverlay()}
            {bgImage()}
        </div>
    );

    function bgImage() {
        return (
            <div
                style={{
                    backgroundImage: `url("${customizedBgImg || defaultBackgoundImg}")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    filter: "grayscale(100%)",
                }}

                className="
                    c-z--2
                    position-absolute
                    vh-100
                    vw-100
                "
            />
        );
    }

    function darkOverlay() {
        if (!customizedBgImg)
            return;

        return (
            <div className="
                c-z--1
                position-absolute
                vh-100
                vw-100
                
                bg-dark
                c-opacity-75
            "/>
        )
    }

    function basePage() {
        return (
            <div className="
                flex-fill
                c-z-1050

                d-flex
                flex-column
                no-gutters

                align-items-center
                justify-content-center
                
                py-3
                
                bg-transparent
                text-white
            ">
                {everouLogo()}
                <div className="mt-4"/>
                {welcomeText()}
                <div className="mt-3"/>
                {content}
                </div>
        );
    }

    function everouLogo() {
        return (
            <img
                className="c-h-8rem"
                alt="everou logo"
                src={
                    (customization && customization.logo)
                    || Icons.everouIconVerticalWhite
                }
            />
        );
    }

    function welcomeText() {
        return (
            <div className="
                font-weight-light
                c-ft-l
                c-text-capitalize-first

                text-center
            ">
                {t("login_welcome_message")}
            </div>
        );
    }
}