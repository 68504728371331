import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { AuthenticationSelectors } from '../_selectors/AuthenticationSelectors';
import { PopUpSelectors, popUpReducer } from '../_stores/PopUpStore/popUpReducer';
import { InitialUrlStore } from '../../Common/_stores/InitialUrlStore';
import { UiAlertsStore } from "../../Common/_stores/UiAlertsStore";
import { BaseEntrypointStore } from "../../Common/_stores/EntrypointStore/BaseEntrypointStore";
import { ModulesStore } from '../../Common/_stores/ModulesStore/ModulesStore';

const commonReducer = combineReducers({
    initialUrl: InitialUrlStore.reducer,
    authentication,
    uiAlert: UiAlertsStore.reducerUiAlert,
    popUp: popUpReducer,
    entrypoint: BaseEntrypointStore.reducer,
    modules: ModulesStore.reducer,
});

//SET UP SELECTORS ROOT STATE
let rootStateFn = state => state;
const setCommonStoreStateFn = newStateFn => rootStateFn = newStateFn;

AuthenticationSelectors.setRootState(state => rootStateFn(state).authentication);

const CommonSelectors = {

    //AUTHENTICATION
    getLoginUrlData      : AuthenticationSelectors.getLoginUrlData,
    getIsNewUser         : AuthenticationSelectors.getIsNewUser,
    getCodeSent          : AuthenticationSelectors.getCodeSent,
    getVersion           : AuthenticationSelectors.getVersion,
    getMachineUid        : AuthenticationSelectors.getMachineUid,
    getLaskApikeyUpdate  : AuthenticationSelectors.getLaskApikeyUpdate,
    getIsAuthCodeSent    : AuthenticationSelectors.getIsAuthCodeSent,
    
    getEverouUser        : AuthenticationSelectors.getEverouUser,
    getApikey            : AuthenticationSelectors.getApikey,
    getUserUid           : AuthenticationSelectors.getUserUid,
    getUserEmail         : AuthenticationSelectors.getUserEmail,

    getLoginCode         : AuthenticationSelectors.getLoginCode,
    getLoginCodeType     : AuthenticationSelectors.getLoginCodeType,
    getLoginEmail        : AuthenticationSelectors.getLoginEmail,

    //MODULES
    modulesState            : state => rootStateFn(state).modules,
    getEnabledModulesIds    : state => ModulesStore.selectorGetArray(CommonSelectors.modulesState(state)),
    getSelectedModuleId     : state => ModulesStore.selectorGetSelected(CommonSelectors.modulesState(state)),

    //POP-UP
    getPopUp            : state => PopUpSelectors.getPopUp(rootStateFn(state).popUp),

    //UI ALERT
    getCurrentAlert     : state => UiAlertsStore.selectorGetCurrentAlert(rootStateFn(state).uiAlert),

    //INITIAL URL
    getInitialUrl       : state => InitialUrlStore.selectorGet(rootStateFn(state).initialUrl),

    //ENTRYPONT
    getEntrypointHost   : state => BaseEntrypointStore.selectorGet(rootStateFn(state).entrypoint),
};

export const CommonStore = {
    setRootStateFn: setCommonStoreStateFn,
    reducer: commonReducer,
    selectors: CommonSelectors,
};