import { PMS_LOCATION_VIEW_MODE_ACTION_TYPES } from "../_constants/pmsLocationsViewMode.constants";

export const viewModeActions = {
    changeMode,
}

function changeMode(newViewMode) {
    return (dispatch, getState) => {
        dispatch({
            type: PMS_LOCATION_VIEW_MODE_ACTION_TYPES.CHANGE_VIEW_MODE,
            newViewMode,
        });
    };
}