import { CustomAccordion, ReadOnlyDataRow, CustomCard, CapsuleButton } from "../_components";
import { TimeHelper } from "../_helpers";
import { UpcomingOrder, HistoricOrder, isHistoricOrder } from "../_modelsTS/Order";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Selectors } from "../_reducers/app.reducer";
import { useState } from "react";
import { Icons } from "../_assets";


type SingleOrderViewProps = {
    order: UpcomingOrder | HistoricOrder
    buttons?: any[];
}

export function SingleOrderView({
    order,
    buttons
}: SingleOrderViewProps) {

    const [isLinesOpened, setIsLinesOpened] = useState(false);

    //LIBRARIES
    const { t } = useTranslation();
    const timezone = useSelector(Selectors.getTimezone)
    const currency = " €";

    const handleDownloadPdf = () => {}

    //VIEW
    return (
        <CustomCard
            banner={isHistoricOrder(order) ? order.status : null}
            className="c-rounded-lg border"
        >
            {!isHistoricOrder(order) && (
                <h3 className="ml-2">{t("mod_billing_next_payment")}</h3>
            )}
            {!isHistoricOrder(order) && (
                <ReadOnlyDataRow
                    name={t("global_date")}
                    data={TimeHelper.localizeIsoStringToFormat(
                        order.nextPaymentAttempt,
                        timezone,
                        TimeHelper.getTimeFormats().DATE_NO_TIME,
                    )}
                />
            )}
            <ReadOnlyDataRow
                name={t("common.period")}
                data={
                    <div>
                        {TimeHelper.localizeIsoStringToFormat(
                            order.periodStart,
                            timezone,
                            TimeHelper.getTimeFormats().DATE_NO_TIME,
                        )}{" "}
                        -{" "}
                        {TimeHelper.localizeIsoStringToFormat(
                            order.periodEnd,
                            timezone,
                            TimeHelper.getTimeFormats().DATE_NO_TIME,
                        )}
                    </div>
                }
            />

            <ReadOnlyDataRow
                name={t("global_price")}
                data={order.total.toFixed(2) + " €"}
            />
            <CustomAccordion
                onClick={() => setIsLinesOpened(!isLinesOpened)}
                header={
                    <ReadOnlyDataRow
                        className="cp"
                        name={t("mod_billing_lines")}
                        data={
                            <img
                                alt={"arrow_img"}
                                src={Icons.arrowDown}
                                className={`
                                c-transition
                                ${!isLinesOpened && "c-rotate-90"}
                            `}
                            />
                        }
                        hasSeparators={false}
                    />
                }
                body={
                    <div className="c-grid c-grid--small mb-1 mt-2">
                        {order.lines.map((line, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        borderLeft:
                                            "2px solid rgb(220,220,220)",
                                    }}
                                    className="ml-1 pl-1"
                                >
                                    <ReadOnlyDataRow
                                        name={t("global_description")}
                                        data={line.description}
                                    />
                                    <ReadOnlyDataRow
                                        name={t("global_price")}
                                        data={line.price + currency}
                                        hasSeparators={false}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            />
            {(buttons?.length || isHistoricOrder(order)) && (
                <div className="d-flex w-100 justify-content-end">
                    {buttons}
                    {isHistoricOrder(order) && (
                        <a className="text-decoration-none" href={order.invoicePdf}>
                            <CapsuleButton
                                onClick={handleDownloadPdf}
                                text={t("global_download")}
                                size='SMALL'
                            />
                        </a>
                    )}
                </div>
            )}
        </CustomCard>
    );
}