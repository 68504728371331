import React from 'react';

type ReadOnlyDataRowProps = {
    name?: string | JSX.Element,
    data: any,
    boldedName?: boolean,
    hasSeparators?: boolean,
    className?: string;
}

export function ReadOnlyDataRow({
    name,
    data,
    boldedName,
    className = '',
    hasSeparators = false,
}: ReadOnlyDataRowProps) {

    return (
        <>
            <div
                style={{ padding: 4 }}
                className={`
                    ${className}
                    d-flex
                    flex-wrap
                    justify-content-between
                `}
            >
                <div
                    className={`${
                        boldedName ? "font-weight-bold" : ""
                    } c-text-capitalize-first text-left c-min-w-40 d-flex align-items-center`}
                >
                    {name}
                </div>
                <div className="text-right text-secondary float-left">{data}</div>
            </div>
            {hasSeparators && <hr className="m-0" />}
        </>
    );
}