import { SUBDOMAIN_IDS } from "../../SUBDOMAIN_IDS";
import { EVEROU_MODULE_IDS } from "../_constants";

export class ModulePresets {

    //PUBLIC
    static getPresetForSubdomainId(subdomainId) {
        if (!subdomainId)
            return;

        return this.#modulePresets.find(preset => preset.subdomainId === subdomainId);
    }
    
    static getPresetIdForSubdomain(subdomain) {
        const modulePreset = this.#modulePresets.find(preset => preset.subdomainId === subdomain)
        return !!modulePreset && modulePreset.id;
    }
    
    static isModuleEnabledInPreset(moduleId, presetId) {
        const modules = this.#getModulesForPreset(presetId);
        if (!modules)
            return true;
    
        return modules.includes(moduleId);
    }

    //PRIVATE
    static #ModulePreset = ({
        id,
        modules = [],
        subdomainId,
        defaultModule,
    } = {}) => ({
        id,
        modules,
        subdomainId,
        defaultModule,
    });

    static #MODULE_PRESET_IDS = {
        CLOCKINS: "CLOCKINS_PRESET",
    };

    static #modulePresets = [
        this.#ModulePreset({
            id: this.#MODULE_PRESET_IDS.CLOCKINS,
            subdomainId: SUBDOMAIN_IDS.EVEROU_CLOCKINS_PRESET,
            modules: [
                EVEROU_MODULE_IDS.CLOCKINS,
                EVEROU_MODULE_IDS.CLOCKINS_REPORTS,
                EVEROU_MODULE_IDS.INVITATIONS,
                EVEROU_MODULE_IDS.SETTINGS
            ],
            defaultModule: EVEROU_MODULE_IDS.CLOCKINS,
        }),
    ];

    static #getModulesForPreset = id => {
        const modulePreset = this.#modulePresets.find(preset => preset.id === id)
        return modulePreset ? modulePreset.modules : null;
    };
};
