function reducer(state = null, action) {
    switch (action.type) {

        case CLOCKINS_USER_UID_ACTION_TYPES.SET_USER_UID:
            return action.uid;
    
        default:
            return state;
    }
}

const actions = {
    setUserUid: uid => ({
        type: CLOCKINS_USER_UID_ACTION_TYPES.SET_USER_UID,
        uid,
    }),
};

const CLOCKINS_USER_UID_ACTION_TYPES = {
    SET_USER_UID: "CLOCKINS_SET_USER_UID",
};

export const ClockinUserUidStore = {
    reducer,
    actions,
};