import React from "react";

import { Mobile } from "../_models/Mobile";
import { UiMobileHelper } from "./UiMobileHelper";

SingleMobile.UiDataModel = (
    name,
    value,
    isInvalid = false,
) => ({
    name,
    value,
    isInvalid,
});

export function SingleMobile({
    mobile = Mobile(),
    showPermissions = true,
}) {
    
    //LIBRARIES
    const isAppDataVisible = UiMobileHelper.isAppDataVisible(mobile);
    const isPermissionDataVisible = UiMobileHelper.isPermissionDataVisible(mobile);

    //PRESENTATION MAPPING
    const uiPermissions = UiMobileHelper.mobileToUiPermissions(mobile);
    const uiDeviceData = UiMobileHelper.mobileToUiDeviceData(mobile);
    const uiMobileAppData = UiMobileHelper.mobileToUiAppData(mobile);

    //VIEW
    return (
        <div
            className="
                border
                rounded

                my-2
                px-2
                py-1
            "
        >
            {deviceData()}
            {mobileAppData()}
            {permissionsData()}
        </div>
    );

    function deviceData() {
        return uiDeviceData.map(uiData => dataField(
            uiData.name,
            uiData.value,
            uiData.isInvalid,
        ));
    }

    function permissionsData() {
        if (!showPermissions)
            return;

        if (!isPermissionDataVisible)
            return;

        return uiPermissions.map(permission =>
            dataField(
                permission.name,
                permission.value,
                permission.isInvalid,
            )
        );
    }

    function mobileAppData() {
        if (!showPermissions)
            return;

        if(!isAppDataVisible)
            return;

        return (
            <div>
                {dataField(
                    uiMobileAppData.name,
                    uiMobileAppData.value,
                    uiMobileAppData.isInvalid,
                )}
            </div>
        );
    }

    function dataField(name, value, isInvalid = false) {
        return (
            <div key={name}
            
            className={`
                d-flex
                justify-content-between

                ${isInvalid &&
                    "text-danger"
                }
            `}>
                <div>
                    {name}:
                </div>
                <div>
                    {value}
                </div>
            </div>
        );
    }
}