import { CardPack } from "../_models/CardPack";

export class CardPackMapper {
    static allServerToLocal(serverCardPacks = []) {
        return serverCardPacks.map(serverToLocal);
    }
}

function serverToLocal(serverCardPack) {
    return CardPack({
        uid: serverCardPack.uid,
        name: serverCardPack.name,
        totalCards: serverCardPack.number_of_cards,
        usedCards: serverCardPack.number_of_cards_used,
        cardType: serverCardPack.card_type,
        unusedCards: serverCardPack.unused_cards?.map((serverCardInPack) => ({ cardCode: serverCardInPack.card_code, shareUrl: serverCardInPack.share_url }))
    });
}