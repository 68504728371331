import React, { useEffect } from "react";

type SIZE_TYPE = "SMALL" | "MEDIUM" | "LARGE" | "X_LARGE" | "UNLIMITED";

type MainPageWithButtonsProps = {
    pageTitle?: string;
    pageSubtitle?: string | JSX.Element;
    buttons?: any[];
    content?: any;
    size?: SIZE_TYPE;
    dataTestId?: string | number;
    onClose?: () => void;
};
const fn = () => {};

export function MainPageWithButtons({
    pageTitle,
    pageSubtitle,
    buttons = [],
    content,
    size = "MEDIUM",
    dataTestId,
    onClose = fn,
}: MainPageWithButtonsProps) {
    //CLEAN UP
    useEffect(() => {
        return onClose;
    }, [onClose]);

    //VIEW
    return (
        <div
            data-testid={dataTestId}
            className={`
            ${setWidth()}

            d-flex
            flex-column
            
            px-4
            pb-4

            c-box-shadow-centered
            rounded
            bg-white
            position-relative
        `}
        >
            {(pageTitle || !!buttons.length) && header()}

            <div
                className="
                flex-fill
                overflow-auto
            "
            >
                {content}
            </div>
        </div>
    );

    function header() {
        return (
            <div className="py-2">
                <div
                    className="
                d-flex
                align-items-center
                
            "
                >
                    {title()}
                    <div className="flex-fill" />
                    <div
                        className="
                    d-flex
                    flex-wrap
                    justify-content-end
                "
                    >
                        {buildButtons()}
                    </div>
                </div>
                {pageSubtitle && (
                    <div className="font-italic">{pageSubtitle}</div>
                )}
            </div>
        );
    }

    function buildButtons() {

        if(!buttons?.length) { return null;}
        
        return buttons.map((button, index) => (
            <div
                key={index}
                className={`
                    ${index !== 0 && "ml-2"}
                    my-1
                    d-flex
                    align-items-center
                `}
            >
                {button}
            </div>
        ));
    }

    function title() {
        return (
            <div
                className="
                c-new-ft-l
                text-uppercase
                font-weight-bold
                py-2
            "
            >
                {pageTitle}
            </div>
        );
    }

    function setWidth() {
        switch (size) {
            case "SMALL":
                return "c-w-30rem c-min-w-30rem";

            case "LARGE":
                return "c-w-60rem c-min-w-40rem";

            case "X_LARGE":
                return "c-w-80rem";

            case "UNLIMITED":
                return "w-100";

            case "MEDIUM":
            default:
                return "c-w-40rem c-min-w-30rem";
        }
    }
}
