import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { LocationSelectorComp } from "../../_components/LocationSelectorComp";
import { LocationHelper } from "../../_helpers/LocationHelper";
import { Selectors } from "../../_reducers/app.reducer";
import { BaseAppGlobalPopUp } from "../../GlobalPopUps/BaseAppGlobalPopUp";
import { appPermissions } from "../../_constants/permissions.constants";
import { LocationModel } from "../../_models/LocationModel";

const TEST_IDS = {
    acceptLocationButton: "accept-selected-location-button",
    dropdown: "location-selection-dropdown",
};

PopUpAddPackLocationSelector.TEST_IDS = TEST_IDS;

export function PopUpAddPackLocationSelector({
    handleAcceptLocation = locationUid => {},
} = {}) {

    //GLOBAL STATE
    const locations = useSelector(Selectors.getLocations);
    
    const globalSelectedLocationUid = useSelector(Selectors.getSelectedLocationUid);
    let globalSelectedLocation      = LocationModel();
    globalSelectedLocation          = useSelector(state => Selectors.getLocation(state, globalSelectedLocationUid));

    const { t } = useTranslation();

    //LOCAL STATE
    const availableLocations = useMemo(() =>
        LocationHelper.getLocationsUserCanAddPacksTo(locations)
    , [locations]);

    const defaultLocationUid = (appPermissions.canUserManageInvitationGroups(globalSelectedLocation.userType)) 
                                ? globalSelectedLocationUid 
                                : availableLocations[0].uid;

    const [locationUid, setLocationUid] = useState(defaultLocationUid);

    //VIEW
    return (
        <BaseAppGlobalPopUp
            acceptButtonTestID={TEST_IDS.acceptLocationButton}

            body={content()}
            title={t("mod_cards_choose_location")}
            closeOnAccept={false}
            onAccept={() => handleAcceptLocation(locationUid)}
        />
    );

    function content() {
        return (
            <LocationSelectorComp
                dropDownTestID={TEST_IDS.dropdown}
                locations={availableLocations}
                onSelectLocation={setLocationUid}
                selectedLocationUid={locationUid}
            />
        );
    }
}