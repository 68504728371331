import { BillingData } from "../_modelsTS/BillingData";
import { GenericMapperFactory } from "../_mappers/MapperFactory";

export const LocationBillingDataMapper = GenericMapperFactory(locationBillingDataServerToLocal);

function locationBillingDataServerToLocal({name, tax_number, street_address, postal_code,city,
state,country,phone_number}) {
    return {
        name,
        city,
        state,
        country,
        streetAddress:  street_address,
        postalCode:     postal_code,
        taxNumber:      tax_number,
        phoneNumber:    phone_number,
    } as BillingData
}