import { useCallback } from "react";
import { Icons } from "../../../Everou/_assets";
import { CapsuleButton } from "../../../Everou/_components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PMSLocationsActions } from "../../_actions/PMSLocationsActions";

type EditReservationButtonsProps = {
    reservation: any;
    showSaveButton: boolean;
    handleEdit: (any) => {} | void;
    handleSave: (any) => {} | void;
};

export function EditReservationButtons({
    reservation,
    showSaveButton,
    handleEdit = () => {},
    handleSave = () => {},
}: EditReservationButtonsProps) {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleDelete = useCallback(() => {
        dispatch(PMSLocationsActions.deleteReservationUi(reservation));
    }, [reservation]);

    //VIEW
    return (
        <>
            <div className="d-flex" style={{paddingRight:"5px"}}>{buttons()}</div>
        </>
    );

    function buttons() {
        return (
            <>
                {showSaveButton &&
                    <> 
                        <CapsuleButton
                            text={t("global_save")}
                            onClick={handleSave}
                        />
                        <div className="ml-2"></div>
                    </>
                }
                
                <CapsuleButton
                    size="SMALL"
                    onClick={handleEdit}
                    icon={Icons.edit}
                    tooltip={t('global_edit')}
                    boostedToolTip
                />
                <div className="ml-2"></div>

                <CapsuleButton
                    size="SMALL"
                    onClick={handleDelete}
                    icon={Icons.trash}
                    tooltip={t('global_delete')}
                    style="DANGER"
                    boostedToolTip
                />
                <div className="ml-2"></div>
            </>
        );
    }
}
