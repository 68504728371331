export const LOCATION_ACTION_TYPES = {
    LOCATIONS_ADD: 'LOCATIONS_ADD',
    LOCATIONS_SELECT: 'LOCATIONS_SELECT',
    LOCATIONS_REMOVE_SUCCESS: 'LOCATIONS_REMOVE_SUCCESS',

    LOCATIONS_LIST_SUCCESS: 'LOCATIONS_LIST_SUCCESS',
    LOCATIONS_LIST_FAILURE: 'LOCATIONS_LIST_FAILURE',

    LOCATIONS_STOP_ADDING: 'LOCATIONS_STOP_ADDING',

    GET_LOCATION_USERS: 'LOCATION_GET_LOCATION_USERS',
    GET_USERS_IN_LOCATION_SUCCESS: 'LOCATION_GET_USERS_IN_LOCATION_SUCCESS',

    PUSH_CREATION_INVITATION_TO_LOCATION: 'INVITATIONS_PUSH_CREATION_INVITATION_TO_LOCATION',
};
