import { i18n } from "../_translations";

export function multiFilter<T>(arr: T[], fields: (keyof T)[], text: string | number) {
    return arr.filter(elem => {
        return fields.some(field => String(elem[field])?.includes(String(text)))
    })
}

export const getFirstCharsFromStrings = (obj: (string | null | undefined)[] | (string | null | undefined)) => {
    if (!obj) return '';
    if (!Array.isArray(obj)) {
        obj = obj.split(' ');
    }
    if (!obj.length) return '';
    return obj.reduce((acc, current) => acc += current?.charAt(0) ?? '', '')?.toUpperCase();
}

export const sortAlphabeticallyBy = (arr: { name: string, [key: string | number]: any }[], field: string) => {
    return arr.sort((a, b) => a[field]?.localeCompare(b[field]));
}

export const spelledList = (arr: (string | number)[]) => {
    return arr.reduce((acc, val, index) => {
        const concatenator = (index === arr.length - 1 ? ` ${i18n.t('global_and')} ` : ', ');
        acc += (concatenator + val);
        return acc
    }).toString();
}

export function removeFromArr<T>(index: number, arr: T[] = []) { 
    return [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];
}

export const removeDuplicates = <T extends string | number | boolean>(arr: T[]): T[] => {
    return [...new Set(arr)];
}

export const capitalizeFirst = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);