import { LocationCustomization } from "../_models/LocationCustomization";

export const LocationCustomizationMapper = {
    serverToLocal,
};

function serverToLocal(serverLocationCustomization) {
    return LocationCustomization({
        logo:               serverLocationCustomization.logo,
        loginBackground:    serverLocationCustomization.login_background,
        accentColor:        serverLocationCustomization.accent_color,
        headerColor:        serverLocationCustomization.header_color,
    })
}