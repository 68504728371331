import { UserStatusMobile } from "./UserStatusMobile";
import { UserStatusDeviceData } from "./UserStatusDeviceData";
import { Mobile } from "./Mobile";
 
export function UserStatusData({
    mobile = Mobile(),
    mobileChanges = UserStatusMobile(),
    devicesData = [] || [UserStatusDeviceData()],
} = {}) {
    return {
        mobile,
        mobileChanges,
        devicesData,
    };
}