import { ServerAllOrders } from "../_modelsTS/Order";
import { AllOrdersMapper } from "../_mappersTS/OrderMapper";
import { OrdersService } from "../_services/OrdersSevice";
import { OrdersStore } from "../_stores/OrdersStore";
import { genericActions } from "./generic.actions";

export const OrdersActions = {

    //Remote
    listOrders,
    selectOrder: OrdersStore.actionSelectItem,
};

const _allOrdersSuccess = OrdersStore.actionGetItemSuccess;

function listOrders(locationUid) {
    return async dispatch => {
        
        try {
            await dispatch(genericActions.genericAsyncAction(asyncAction, (err) => {}, true));
        } catch {}

        async function asyncAction() {
            const serverAllOrdersObj = await OrdersService.listOrders<ServerAllOrders>(locationUid);
            const allOrdersObj = AllOrdersMapper.serverToLocal(serverAllOrdersObj);
            dispatch(_allOrdersSuccess(allOrdersObj));
            return allOrdersObj;
        }
    };
}
