import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContentSpace } from "../_components";
import { reservationActions } from "../_actions/reservation.actions";
import { ReservationConfigView } from "./ReservationConfigView";
import { ReservationsRooms } from "./ReservationsRooms";
import { ReservationSchedule } from "./ResevationSchedule";
import { ReservationSlotManagement } from "./ReservationSlotManagement";
import { RESERVATION_SECONDARY_VIEWS } from "../_constants/reservation.constants";
import { Selectors } from "../_reducers/app.reducer";

export function ReservationsModule() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const secondaryView = useSelector(Selectors.getReservationsModuleSecondaryView);

    //ACTION HANDLERS
    const handleGoBack = useCallback(() => {
        dispatch(reservationActions.selectNullView());
        dispatch(reservationActions.changeSelected(null));
    }, [dispatch]);

    //INITIALIZATION
    useEffect(() => {
        dispatch(reservationActions.selectNullView());
        dispatch(reservationActions.changeSelected(null));
    }, [dispatch]);

    //VIEW
    return (
        <ContentSpace
            mainContent={<ReservationsRooms />}
            detailedContent={detailedContent()}
            isDetailedFolioEnabled={secondaryView !== null}
            onGoBack={handleGoBack}
        />
    );

    function detailedContent() {
        switch (secondaryView) {
            case RESERVATION_SECONDARY_VIEWS.SCHEDULE:
                return <ReservationSchedule />;

            case RESERVATION_SECONDARY_VIEWS.SETTINGS:
                return <ReservationConfigView/>;

            case RESERVATION_SECONDARY_VIEWS.SLOT_MANAGEMENT:
                return <ReservationSlotManagement/>;
                
            default:
                return null;
        }
    }
}