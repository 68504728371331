import { authenticationService } from '../_services';
import { uiAlertActions } from '.';
import {
    HistoryHelper,
    LocalStorageHelper,
    TimeHelper,
    AuthenticationHelper,
    ValidationHelper,
} from '../_helpers';
import { UserMapper } from '../_mappersTS/UserMapper';
import { StoreManager } from '../StoreManager';
import {
    AUTHENTICATION_ACTION_TYPES,
    WEBAPP_URLS,
    LOGIN_CODE_TYPES,
} from '../_constants';
import { API_ERROR_CODES } from "../_constants/url.constants";
import { VersionHelper } from '../_helpers/VersionHelper';
import { UaHelper } from '../_helpers/UaHelper';

import { i18n } from '../_translations/i18n';
import { ServiceWorkerHelper } from '../_helpers/ServiceWorkerHelper';
import { genericActions } from './generic.actions';
import { Selectors } from '../_reducers/app.reducer';

export const authenticationActions = {

    //remote
    requestCode,
    validateCode,
    refreshUser,
    localLogOut,
    logout,
    logOutAll,

    register,

    updateSystemInfo,

    loginWithApple,
    loginWithGoogle,
    loginWithEmail,

    //local
    setCodeSent,
    checkVersion,
    updateUser,
    setIsNewUser,
    setApikeyUpdateDate,

    //private,
    _loginWithPlatformData,
};

function setCodeSent(codeSent) {
    return {
        type: AUTHENTICATION_ACTION_TYPES.SET_CODE_SENT,
        codeSent,
    };
}

function validateCode({ email, code, secret }) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await authenticationService.validateCode({
                email: AuthenticationHelper.processValidationEmail(email),
                code,
                secret,
            });
        }
    };
}

function checkVersion() {
    return async (dispatch, getState) => {

        const localVersion = Selectors.getVersion(getState());
        const newVersion = VersionHelper.getAppVersion();

        if (!localVersion) {
            dispatch(updateLocalVersion(newVersion));
            return;
        }

        if (localVersion === newVersion)
            return;
        
        await wipeAndReload();
        return true;
    };

    function updateLocalVersion(version) {
        return {
            type: AUTHENTICATION_ACTION_TYPES.UPDATE_VERSION,
            version,
        };
    }

    async function wipeAndReload() {
        await ServiceWorkerHelper.unsubscribePushAndWorker();
    
        StoreManager.unsubscribeFromStore();
        wypeStorageKeepingAuthentication();
        
        window.location.reload();
    }

    function wypeStorageKeepingAuthentication() {
        LocalStorageHelper.saveState({
            common: {
                authentication: {
                    ...LocalStorageHelper.loadState().common.authentication,
                    version: undefined,
                },
            },
        });
    }
}

function updateUser(user) {
    return {
        type: AUTHENTICATION_ACTION_TYPES.AUTH_UPDATE_USER_SUCCESS,
        user,
    };
}

function logout() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        await dispatch(localLogOut());

        async function asyncAction() {
            await authenticationService.logOut();
        }
    }
}

function logOutAll() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        await dispatch(localLogOut());

        async function asyncAction() {
            await authenticationService.logOutAll();
        }
    }
}

function localLogOut() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await ServiceWorkerHelper.unsubscribePushAndWorker();
        
            StoreManager.unsubscribeFromStore();
            HistoryHelper.resetUrl();
            LocalStorageHelper.removeState();
            window.location.reload();
        }
    }
}

function updateSystemInfo(webPushSubscription) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await authenticationService.updateSystemInfo(
                TimeHelper.getCurrentTimezone(),
                UaHelper.getLocale(),
                VersionHelper.getAppVersion(),
                UaHelper.getOS(),
                UaHelper.getBrowser(),
                webPushSubscription,
            );
        }
    }
}

function refreshUser() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (Selectors.getEverouUser(getState()) === null)
                return;

            const shouldUpdateApikey = getShouldUpdateApikey();
            if (shouldUpdateApikey) {
                dispatch(authenticationActions.setApikeyUpdateDate());
            }

            if (shouldUpdateApikey) {
                dispatch(authenticationActions.setCodeSent(false));
            }

            const serviceFn = shouldUpdateApikey
                ? authenticationService.refreshUser
                : authenticationService.getUserInfo
            ;

            const serverUser = await serviceFn();
            const localUser = UserMapper.serverToLocal(serverUser);
            dispatch(authenticationActions.updateUser(localUser));
        }

        function getShouldUpdateApikey() {
            const lastApikeyUpdate = Selectors.getLaskApikeyUpdate(getState());
            return (
                !lastApikeyUpdate
                || TimeHelper.havePassedDaysSinceIsoDate(lastApikeyUpdate, 1)
            );
        }
    };
}

function setApikeyUpdateDate(lastApikeyUpdate) {
    return {
        type: AUTHENTICATION_ACTION_TYPES.SET_APIKEY_UPDATE_DATE,
        lastApikeyUpdate,
    };
}

function requestCode(email) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(
            asyncAction,
            manageError,
        ));
        
        async function asyncAction() {
            dispatch(request(email));
            await authenticationService.requestCode(email);
            dispatch(uiAlertActions.success(i18n.t("login.codeSent")));
            dispatch(success());
        }

        function manageError(error) {
            if (error.name === API_ERROR_CODES._205_USER_NOT_EXIST) {
                dispatch(setIsNewUser(true));
                return true;
            }

            dispatch(failure(error.toString()));
        }
    };

    function request(email) {
        return dispatch => {
            dispatch(loginRequest({
                email,
                codeType: LOGIN_CODE_TYPES.EMAIL,
            }));

            dispatch({ type: AUTHENTICATION_ACTION_TYPES.LOGIN_CODE_REQUEST });
        }
    }

    function success() {
        return codeSent();
    }

    function failure(error) {
        return {
            type: AUTHENTICATION_ACTION_TYPES.LOGIN_CODE_FAILURE,
            error,
        };
    }
}

function setIsNewUser(isNewUser) {
    return {
        type: AUTHENTICATION_ACTION_TYPES.SET_NEW_USER,
        isNewUser,
    };
}

function loginWithEmail(email, code, secret) {
    let validatedEmail = AuthenticationHelper.processValidationEmail(email);
    validateEmail(validatedEmail);
    return authenticationActions._loginWithPlatformData({
        email: validatedEmail,
        code,
        secret,
        codeType: LOGIN_CODE_TYPES.EMAIL,
    });

    function validateEmail(email) {
        if (!ValidationHelper.ValidationFns.isEmailValid(email))
            throw new Error('Invalid email format');
    }
}

function loginWithApple(token, email) {
    return authenticationActions._loginWithPlatformData({
        codeType: LOGIN_CODE_TYPES.APPLE,
        code: token,
        email,
    });
}

function loginWithGoogle(token, email) {
    return authenticationActions._loginWithPlatformData({
        codeType: LOGIN_CODE_TYPES.GOOGLE,
        code: token,
        email,
    });
}

function _loginWithPlatformData({ email, code, codeType, secret } = {}) {

    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(
            asyncAction,
            manageError,
        ));
        
        async function asyncAction() {
            dispatch(loginRequest({ email, codeType, code }));
            const serverUser = await authenticationService.login({
                email,
                code,
                codeType,
                secret,

                machineUid: Selectors.getMachineUid(getState()),
                timezone:   TimeHelper.getCurrentTimezone(),
                locale:     UaHelper.getLocale(),
                appVersion: VersionHelper.getAppVersion(),
                os:         UaHelper.getOS(),
                browser:    UaHelper.getBrowser(),
            });
            const localUser = UserMapper.serverToLocal(serverUser);
            dispatch(success(localUser));
        }

        function manageError(error) {
            if (codeType !== LOGIN_CODE_TYPES.EMAIL && error.name === API_ERROR_CODES._205_USER_NOT_EXIST) {
                dispatch(setIsNewUser(true));
                return true;
            }
            dispatch(failure(error.toString()));
        }
    };

    function success(user) {
        return {
            type: AUTHENTICATION_ACTION_TYPES.LOGIN_SUCCESS,
            user,
        };
    }
    function failure(error) {
        return {
            type: AUTHENTICATION_ACTION_TYPES.LOGIN_FAILURE,
            error,
        };
    }
}

function loginRequest({ email, code, codeType }) {
    return {
        type: AUTHENTICATION_ACTION_TYPES.LOGIN_REQUEST,
        email,
        code,
        codeType,
    };
}

function register({ codeType, email, code }) {
    switch (codeType) {
        case LOGIN_CODE_TYPES.EMAIL:
            return registerWithEmail(email);
        
        case LOGIN_CODE_TYPES.GOOGLE:
            return registerWithGoogle(code);
            
        case LOGIN_CODE_TYPES.APPLE:
            return registerWithApple(code);

        default:
            return () => {};
    }
}

function registerWithEmail(email) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            await authenticationService.register(email, LOGIN_CODE_TYPES.EMAIL);
            HistoryHelper.pushToUrl(WEBAPP_URLS.LOGIN);
            dispatch(uiAlertActions.success(i18n.t("login.regitrationSuccessful")));
            dispatch(loginRequest({
                email,
                codeType: LOGIN_CODE_TYPES.EMAIL,
            }));
            dispatch(codeSent());
        }
    };
}

function registerWithGoogle(token) {
    return _automaticRegistrationAndLogin(LOGIN_CODE_TYPES.GOOGLE, token);
}

function registerWithApple(token) {
    return _automaticRegistrationAndLogin(LOGIN_CODE_TYPES.APPLE, token);
}

function _automaticRegistrationAndLogin(codeType, code) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            await authenticationService.register(undefined, codeType, code);
            await dispatch(
                authenticationActions._loginWithPlatformData({ codeType, code })
            );
        }
    };
}

function codeSent() {
    return {
        type: AUTHENTICATION_ACTION_TYPES.LOGIN_CODE_SUCCESS,
    };
}