import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContentSpace } from "../../Common/_components/ContentSpace";
import { ClockinsHistoryView } from "./ClockinsHistoryView";
import { Selectors } from "../_reducers/app.reducer";
import { ClockinActions } from "../_stores/ClockinStore/ClockinActions";

export function ClockinsModule() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    //ACTION HANLDERS
    const handleGoBack = useCallback(() =>
        dispatch(ClockinActions.changeSecondaryView(null))
    , [dispatch]);

    //VIEW
    return (
        <ContentSpace
            key={locationUid}
            mainContent={<ClockinsHistoryView/>}
            onGoBack={handleGoBack}
        />
    );
}