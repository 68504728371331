export function IntegrationApp({
    id,
    company,
    link,
    installed,
} = {}) {
    return {
        id,
        company,
        link,
        installed,
    }
}