import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { PMS_API_URLS } from "../_constants/PMS_API_URLS";

export const PMSHostifyService = {
    listListings,
}

function listListings(uid: string) {
    return ServiceHelper.getRequest(
        PMS_API_URLS.HOSTIFY_LISTINGS,
        {
            uid: uid
        }
    );
}