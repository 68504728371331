export function Trigger({
    uid,
    enabled,
    name,
    action = TriggerAction(),
    type,

    parameters,
    locationUid,
} = {}) {
    return {
        uid,
        enabled,
        name,
        action,
        type,
        locationUid,
    
        parameters,
    };
}

export function TriggerAction({
    type,
    deviceType,
    roomUid,
    deviceUid,
    toggle,
    status,
    localStatus,
    timeout,
    delay,
    userName,
} = {}) {
    return {
        type,
        deviceType,
        roomUid,
        deviceUid,
        toggle,
        status,
        localStatus,
        timeout,
        delay,
        userName,
    };
}

export function LocationTrigger({
    type,
    userType,
    roomUid,
    deviceUid,
    userUid,
    remote,
    actionType,
} = {}) {
    return {
        type,
        userType,
        roomUid,
        deviceUid,
        userUid,
        remote,
        actionType,
    };
}

export function TimeTrigger({
    periodType,
    eventType,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    begin,
} = {}) {
    return {
        periodType,
        eventType,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        begin,
    };
}

export function DeviceTrigger({
    type,
    deviceType,
    roomUid,
    deviceUid,
    status,
    temperature,
    humidity,
    operator,
    aggregator,

    localStatus,
} = {}) {
    return {
        type,
        deviceType,
        roomUid,
        deviceUid,
        status,
        temperature,
        humidity,
        operator,
        aggregator,
        
        localStatus,
    };
}

export function ProtectTrigger({
    type,
} = {}) {
    return {
        type,
    };
}
