import React from 'react';

enum SIZES {
    BIG = "BIG",
    SMALL = "SMALL",
    X_SMALL = "X_SMALL",
};


type SimpleToggleProps = {
    testID?: string;
    handleChange?: (...args: any) => any | {};
    checked?: boolean;
    size?: keyof typeof SIZES;
    readOnly?: boolean,
    ariaControls?:string,
    className?: string,
}

export function SimpleToggle({
    testID,
    handleChange = e => {},
    checked,
    size = SIZES.SMALL,
    readOnly,
    ariaControls,
    className=''
}: SimpleToggleProps) {

    //VIEW
    return (
        <div
            data-testid={testID}
            onClick={handleToggle}
            aria-controls={ariaControls}
            className={`
                ${className}
                c-rounded-capsule
                position-relative

                ${setSize()}

                c-bd-190
                cp
                ${checked && 'c-bg-primary'}
                ${readOnly && 'cna'}
            `}
        >
            <div className={`
                c-transition

                position-absolute
                h-100
                w-50

                rounded-circle
                c-bd-190
                bg-light
                ${checked && 'c-translate-right-100'}
            `}
            />
        </div>
    );

    function setSize() {
        switch (size) {
            default:
            case SIZES.SMALL:
                return "c-w-3rem c-h-1-5rem";

            case SIZES.BIG:
                return "c-w-4rem c-h-2rem";

            case SIZES.X_SMALL:
                return "c-w-2rem c-h-1rem";
        }
    }

    //HANDLERS
    function handleToggle(e) {
        if (readOnly)
            return;
        handleChange(new CustomEvent('SimpleToggle', { detail: {
            value: !checked
        } }));
    }
}