import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { appPermissions } from "../_constants/permissions.constants";
import { LocationSelectorComp } from "../_components/LocationSelectorComp";
import { Subscription } from "../_models/Subscription";
import { Selectors } from "../_reducers/app.reducer";
import { subscriptionsActions } from "../_actions/subscriptions.actions";

const SCOPES = {
    LOCAL: "LOCAL",
    GLOBAL: "GLOBAL",
};
NewSubsConfig.SCOPES = SCOPES;

export function NewSubsConfig({
    subscription = Subscription(),
    scope = SCOPES.LOCAL,
    readOnly = false,
} = {}) {
    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    let locations = useSelector(Selectors.getLocations);

    locations = locations.filter((location) =>
        appPermissions.canUserActivateSubscriptions(location.userType),
    );

    //GLOBAL HANDLERS
    function handleChangeSelectedLocation(locationUid) {
        if (readOnly) return;
        dispatch(subscriptionsActions.changeLocation(locationUid));
    }

    useEffect(() => {
        if (readOnly) return;
        dispatch(subscriptionsActions.changeLocation(locationUid));
    }, [dispatch, locationUid, readOnly]);

    //VIEW
    if (scope === SCOPES.LOCAL) return null;

    return (
        <LocationSelectorComp
            onSelectLocation={handleChangeSelectedLocation}
            selectedLocationUid={subscription.locationUid}
            locations={locations}
            readOnly={readOnly}
        />
    );
}