import { useEffect } from "react";
import { HistoryHelper } from "../_helpers";
import {
    LOGIN_CODE_TYPES,
} from "../_constants";

export function useReloadOnValidUser(user, codeType, codeSent) {
    useEffect(() => {
        if (user) {
            if ((codeType !== null && codeType !== LOGIN_CODE_TYPES.EMAIL) || (codeType === LOGIN_CODE_TYPES.EMAIL && codeSent)) {
                HistoryHelper.goToRoot();
            }
        }
    }, [user, codeType, codeSent]);
}