export const DASHBOARD_ACTION_TYPES = {
    SHOW_SETTINGS: "DASHBOARD_SHOW_SETTINGS",
    HIDE_SETTINGS: "DASHBOARD_HIDE_SETTINGS",
    
    SAVE_TEMPORARY_SETTINGS: "DASHBOARD_SAVE_TEMPORARY_SETTINGS",
    
    GET_WIDGETS_SUCCESS: "DASHBOARD_GET_WIDGETS_SUCCESS",

    GET_GROUPS: "DASHBOARD_GET_GROUPS",
    SELECT_GROUP: "DASHBOARD_SELECT_GROUP",
};

export const WIDGET_TYPES = {
    ADD_WIDGET: 0,
    DEVICES: 1,
    LOGS: 2,
    INVITATIONS: 3,
    ALERTS: 4,
};

export const WDIGET_TYPE_NAMES = {
    [WIDGET_TYPES.ADD_WIDGET]: "Add widget",
    [WIDGET_TYPES.DEVICES]: "Favorite devices",
    [WIDGET_TYPES.LOGS]: "Today's logs",
    [WIDGET_TYPES.INVITATIONS]: "Invitations",
    [WIDGET_TYPES.ALERTS]: "Alerts",
};

export const SETTINGS_WINDOWS = {
    WIDGET: "WIDGET_SETTINGS",
    GROUP: "GROUP_SETTINGS",
    ADD_WIDGET: "ADD_WIDGET_SETTINGS",
};