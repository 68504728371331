import { i18n } from "../_translations";

export const ValidationHelper = {
    UiValidationFns: {
        required:       value => validate(exists(value), "validation_field_required"),
        phone:          value => validate(isPhoneValid(value), "global_warning_phone_not_valid"),
        email:          value => validate(isEmailValid(value), "global_warning_email_not_valid"),
        oneSelected:    value => validate(isOneSelected(value), "global_warning_select_one"),
    },
    ValidationFns: {
        exists,
        isPhoneValid,
        isEmailValid,
        isOneSelected,
    },
};

function validate(result, invalidStringId) {
    return result ? undefined : i18n.t(invalidStringId);
}

function exists(value) {
    return !!value;
}

function isOneSelected(boolArray = []) {
    return boolArray.some(bool => bool === true);
}

function isPhoneValid(phone) {
    const regex = /^.{0,15}$/;
    // const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3}$/im;
    return regex.test(phone);
}

function isEmailValid(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}