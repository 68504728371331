export function PMSRoom({
    uid,
    name,
    type,
    devicesAmount
} = {}) {
    return {
        uid,
        name,
        type,
        devicesAmount
    };
}