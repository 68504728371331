import { Icons } from "../_assets";
import React from "react";
import { SwitchGeneric } from "./SwitchGeneric";

export function SwitchOnOffPause({
    deviceStatus,
    remote,
    handleSendAction = actionStatus => {},
}) {
    //VIEW
    return (
        <SwitchGeneric
            deviceStatus={deviceStatus}
            handleSendAction={handleSendAction}
            
            leftButtonName={"0"}
            rightButtonName={"I"}
            remote={remote}
            leftSideStatus={2}
            centerSideStatus={0}
            rightSideStatus={1}

            offStatus={0}

            centerButtonIcon={Icons.pause}
        />
    );
}