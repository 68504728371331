import React from "react";

import { UiSidebarButton } from "./UiSidebarButton";

MenuSidebar.UiSidebarButtonModel = UiSidebarButtonModel;

function UiSidebarButtonModel({
    tag,
    icon,
    onClick,
    selected,
    isDown = false,
} = {}) {
    return {
        tag,
        icon,
        onClick,
        selected,
        isDown,
    };
}

export function MenuSidebar({
    sidebarButtons = [] || [UiSidebarButtonModel()],
}) {

    //LOCAL STATE
    const topButtons = sidebarButtons.filter(button => !button.isDown);
    const lowerButtons = sidebarButtons.filter(button => button.isDown);

    //VIEW
    return (
        <div className="
            h-100
            
            d-flex
            flex-column

            overflow-auto
        ">
            {topButtons.map(buildUiButtonFromModel)}
            <div className="flex-grow-1" />
            {lowerButtons.map(buildUiButtonFromModel)}
        </div>
    );

    function buildUiButtonFromModel(buttonModel = UiSidebarButtonModel()) {
        return <UiSidebarButton {...{
            key:        buttonModel.tag,

            text:       buttonModel.tag,
            icon:       buttonModel.icon,
            onClick:    buttonModel.onClick,
            isSelected: buttonModel.selected,
            isMarked:   buttonModel.selected,
        }}/>;
    }
}