import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import throttle from 'lodash/throttle';

import rootReducer from './_reducers';
import { TabSynchronizationManager } from './TabSynchronizationManager';
import { PersistedStateLoader } from './PersistedStateLoader';
import { CommonStoreSubscriptions } from '../Common/CommonStoreSubscriptions';

let _store = {};
const unsubscribeFns = [];

export const StoreManager = {
    subscribeFnToStore,
    getStore,
    setStore,
    unsubscribeFromStore,
    setUpStore,
};

function getStore() {
    return _store;
};

function setStore(store) {
    _store = store;
}

function unsubscribeFromStore() {
    unsubscribeFns.forEach(unsubscribeFn => unsubscribeFn());
}

function setUpStore(enableLogger = true) {
    _store = createStore(
        rootReducer,
        PersistedStateLoader.load(),
        applyMiddleware(...getMiddlewares()),
    );
    TabSynchronizationManager.subscribeSynchronizationToStorage(_store);
    CommonStoreSubscriptions.subscribe();
    return _store;

    function getMiddlewares() {
        const middlewares = [thunkMiddleware];
        enableLogger && middlewares.push(createLogger());
        return middlewares;
    }
}

function subscribeFnToStore(subscriptionFn = getState => {}) {
    unsubscribeFns.push(
        _store.subscribe(
            throttle(() => subscriptionFn(_store.getState), 1000)
        )
    );
}