import { FirmwareVersion } from "../_modelsTS/FirmwareVersion";

export interface FirmwareVersionServer {
    version: string;
    release_date: string;
    changelog: { message: string };
    latest_version: boolean;
    public: boolean;
}

export const FirmwareVersionMapper = (
    version: FirmwareVersionServer,
): FirmwareVersion => ({
    version: version.version,
    isPublic: version.public,
    releaseDate: version.release_date,
    changeLog: version.changelog,
    latestVersion: version.latest_version,
});
