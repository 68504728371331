import {
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { STYLES_TYPE } from "../CapsuleButton";
import "./styles.scss";

const DEFAULT_ACTIVE_INDEX  = 0;

export type SegmentItem = {
    label?: any;
    value?: string | number;
    style?: STYLES_TYPE;
    selected?: boolean;
    enabled?: boolean;
    ref?: any;
    onClick?: (...args: any) => void;
};

type SegmentControlProps = {
    defaultSelected?: SegmentItem;
    segments: SegmentItem[];
    onSelect?: (value: any) => void;
    animated?: boolean;
    children?: any;
    className?: string;
    readOnly?: boolean;
    preventDefaultSelect?: boolean;
    allowSingle?: boolean
};

export function SegmentedControl({
    segments,
    defaultSelected,
    onSelect,
    children,
    className = '',
    readOnly,
    preventDefaultSelect = false,
    allowSingle = false,
}: SegmentControlProps) {
    
    const slicerRef = useRef<any>();
    const [isRendered, setIsRendered] = useState(false);

    const segmentsAmount = useMemo(
        () => segments?.length ?? 1,
        [segments.length],
    );

    readOnly = readOnly || (!allowSingle && segmentsAmount === 1);

    const defaultIndex = (() => {
        if (defaultSelected?.value) {
            const index = segments
                ?.map(({ value }) => value)
                .indexOf(defaultSelected?.value);
            if (index === -1) return DEFAULT_ACTIVE_INDEX;
            return index;
        }
        return DEFAULT_ACTIVE_INDEX;
    })();

    const [activeIndex, setActiveIndex] = useState<number>(defaultIndex);

    useEffect(() => setActiveIndex(defaultIndex), [defaultIndex])

    const moveSlicerTo = (activeIndex) => {
        if (slicerRef?.current) {
            const slicerWidth = 100 / segmentsAmount;
            slicerRef.current.style.width = `${slicerWidth}%`;
            slicerRef.current.style.left = `${activeIndex * slicerWidth}%`;
        }
    };

    useEffect(() => {
        const currentIndex = defaultIndex;
        setActiveIndex(currentIndex);
        moveSlicerTo(currentIndex);
        if(preventDefaultSelect) return;
        const segment = segments[currentIndex];
        segment.onClick && segment.onClick(segment.value);
        onSelect && onSelect(segment.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segmentsAmount, readOnly, defaultIndex]);

    useEffect(() => {
        setIsRendered(true);
    }, []);

    return (
        <div className={` ${readOnly ? 'cna' : ''} ${className} position-relative`}>
            <div style={{ gridTemplateColumns: `repeat(${segmentsAmount}, 1fr)` }} className="controls c-bg-grey-240">
                {!readOnly && <div
                    className={`slicer ${isRendered ? "animated" : ""}`}
                    ref={slicerRef}
                ></div>}
                {segments.map(({ label: text, value, onClick }, i) => {
                    const id = 'segmented_control_' + value + "_" + i;
                    return (
                        <div
                            key={id}
                            className={`segment ${
                                !readOnly && i === activeIndex
                                    ? "text-white"
                                    : "segment--inactive"
                            }`}
                            id={id}
                            onClick={() => {
                                if(readOnly) return;
                                if(activeIndex === i && !allowSingle) return;
                                moveSlicerTo(i);
                                setActiveIndex(i);
                                onClick && onClick(value);
                                onSelect && onSelect(value);
                            }}
                        >
                            {text}
                        </div>
                    );
                })}
            </div>
            {children}
        </div>
    );
}
