export function ModuleModel({
    moduleId,
    uiName,
    icon,
    url,
    isEnabled,
    SvgComponent,
} = {}) {
    return {
        moduleId,
        uiName,
        icon,
        url,
        isEnabled,
        SvgComponent,
    };
}