import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleWorkday } from './SingleWorkday';
import { Workday } from '../_modelsTS/Workday';
import { Accordion } from 'react-bootstrap';

type ClockinsDataSectionProps = {
    workdays: Workday[];
    canManageClockins?: boolean;
    timezone: string;
    handleAddClockin: () => void;
    handleDeleteClockin: () => void;
    handleUpdateClockin: () => void;
};

export function ClockinsDataSection({
    workdays,
    canManageClockins,
    timezone,

    handleAddClockin = () => {},
    handleDeleteClockin = () => {},
    handleUpdateClockin = () => {},
}: ClockinsDataSectionProps) {
    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div
            className="
            d-flex
            no-gutters

            flex-column
            overflow-auto
        "
        >
            {!workdays.length ? (
                noContentFound()
            ) : (
                <Accordion alwaysOpen>{workdaysSection()}</Accordion>
            )}
        </div>
    );

    function workdaysSection() {     
        return (
            workdays.map(workday =>
                <SingleWorkday
                    key={workday.day.toString()}
                    {...{
                        workday,
                        handleAddClockin,
                        handleDeleteClockin,
                        handleUpdateClockin,
                        timezone,

                        canEdit: canManageClockins,
                    }}
                />
            )
        );
    }

    function noContentFound() {
        return (
            <span className="
                w-100
                pt-2
                text-center
            ">
                {t("mod_clockins_no_activity")}
            </span>
        );
    }
}