import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Selectors } from "../_reducers/app.reducer";
import { ColumnButton } from "../_components";
import { filterActions } from "../_actions";
import { Category } from "../_models/Category";

export function DeviceGroupSelector({ className }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const categories = useSelector(Selectors.getDeviceFilterCategories);
    const isAllCategoriesSelected = useSelector(
        Selectors.getDeviceFilterIsAllCategoriesSelected,
    );

    return (
        <div className={`${className} d-flex flex-column`}>
            <div
                className="
                overflow-auto
                c-rounded-big
                c-bg-grey-250
                c-bd-220
            "
            >
                {buildGroupButtons()}
            </div>
        </div>
    );

    function buildGroupButtons() {
        return [
            buildButtonAll(isAllCategoriesSelected),
            ...buildCategoriesButtons(),
        ];

        function buildCategoriesButtons() {
            return categories
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((category) =>
                    buildFilterButton(
                        category,
                        () => handleCategoryClick(category.id),
                        isAllCategoriesSelected,
                    ),
                );
        }
    }

    function buildFilterButton(
        filterCategory = Category(),
        handleFilterClick,
        isAllCategoriesSelected,
    ) {
        let { id, name, active, devicesAmount } = filterCategory;
        return (
                <ColumnButton
                    key={id}
                    name={name}
                    itemsAmount={devicesAmount}
                    handleClick={handleFilterClick}
                    active={!isAllCategoriesSelected && active}
                />
        );
    }

    function buildButtonAll(active) {
        return (
                <ColumnButton
                    key="all"
                    name={t("common.all")}
                    handleClick={handleAllCategoriesClick}
                    active={active}
                />
        );
    }

    //ACTION HANDLERS
    function handleAllCategoriesClick() {
        dispatch(filterActions.selectAllCategories());
    }

    function handleCategoryClick(filterCategoryId) {
        dispatch(
            filterActions.toggleCategory(
                filterCategoryId,
                isAllCategoriesSelected,
                categories,
            ),
        );
    }
}
