import React from 'react';
import { useTranslation } from "react-i18next";

import { HistoryHelper } from "../_helpers";
import { WEBAPP_URLS } from "../_constants";
import { CapsuleButton } from '../_components';

export function AlreadyLoggedInPage() {
    const { t } = useTranslation();

    return (
        LoginRegisterBox({
            title: t("login.alreadyLoggedIn"),
            content: content(),
        })
    );

    function LoginRegisterBox({
        title,
        content,
    }) {
        return (
            <div className="
                d-flex justify-content-center
            ">
                <div className="
                    col-6 col-md-4 col-lg-3
                    d-flex flex-column
                    p-0 m-0
                ">
                    <div className="
                        col
                        p-0 m-0 py-5 my-5
                    "/>
                    <div className="
                        col flex-fill
                        d-flex flex-column
                        py-3 border rounded bg-light
                    ">
                        <h2 className="
                            align-self-center
                            mb-3 font-weight-light
                            text-center
                        ">
                            {title}
                        </h2>
                        {content}
                    </div>
                </div>
            </div>
        );
    }

    function content() {
        return (
            <div className="
                    d-flex
                    no-gutters
                    flex-column
                    align-items-center
                ">
                <CapsuleButton
                    text={t("login.goToApp")}
                    onClick={() => HistoryHelper.pushToUrl(WEBAPP_URLS.ROOT)}
                />
            </div>
        );
    }
}