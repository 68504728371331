import { Integration, LocalEditableIntegration } from "../../../Everou/_modelsTS/Integration";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatePublicUrlToggle from "../../../Everou/InvitationsModule/CreatePublicUrlToggle";
import { CapsuleButton } from "../../../Everou/_components";
import { useDispatch, useSelector } from "react-redux";
import { MIN_TIME, TIME_FORMAT } from "../../../Everou/_constants";
import { useError } from "../../../Everou/_hooks/useError";
import { Icons } from "../../../Everou/_assets";
import SendInvitationURLToggle from "../../../Everou/IntegrationsModule/components/SendInvitationURLToggle";
import SendInvitationURLFromPMSToggle from "../../../Everou/IntegrationsModule/components/SendInvitationURLFromPMSToggle";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { TimeHelper } from "../../../Everou/_helpers";
import { PMSIntegrationsCollection } from "../../_stores/PMSIntegrationsCollection";
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";


type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onChangeInputs?: (...args: any) => void | any;
    onToggleEditable: (...args: any) => void | any;
    onDelete: (index: number) => void;
    index: number;
}

export function PMSAvaibookNewIntegrationInput({ 
    integration, 
    onDelete, 
    index,
    onToggleEditable 
}: IntegrationInputProps) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const integrations = useSelector(PMSIntegrationsCollection.selectorGetItems);

    const baseIntegration = useMemo(() => integration, [integration]);
    const [apikeyValue, setApikeyValue] = useState(integration.apikey ?? '');
    const [createPublicUrl, setCreatePublicUrl] = useState(integration.createPublicUrl);
    const [sendInvitationURL, setSendInvitationURL] = useState(integration.sendInvitationURL ?? true);
    const { errorMessage, setError, hasError, disableError } = useError();

    const [checkIn, setCheckIn] = useState(integration.check_in ?? MIN_TIME);
    const [checkOut, setCheckOut] = useState(integration.check_out ?? MIN_TIME);
    const timeNoSecondsFormat = TimeHelper.getTimeFormats().TIME_NO_SECONDS;
    const getHourAndMinutesFormat = useCallback((time: string) => moment(time, timeNoSecondsFormat), []);

    useEffect(() => { 
        setApikeyValue(integration.apikey ?? '');
        setCreatePublicUrl(integration.createPublicUrl);
        setSendInvitationURL(integration.sendInvitationURL ?? true);
        setCheckIn(integration.check_in ?? '00:00');
        setCheckOut(integration.check_out ?? '00:00');
    }, [integration]);

    const enableSendInvitationURLToggle = useMemo(() => {
        return createPublicUrl;
    }, [createPublicUrl]);

    const canSave = useMemo(() => {

        if(hasError) return false;

        return (
            !!apikeyValue && 
            (
                apikeyValue !== baseIntegration.apikey ||
                createPublicUrl !== integration.createPublicUrl || 
                sendInvitationURL !== integration.sendInvitationURL || 
                checkIn !== integration.check_in ||
                checkOut !== integration.check_out
            )
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        baseIntegration.link, 
        createPublicUrl, 
        integrations, 
        integration.createPublicUrl, 
        apikeyValue, 
        sendInvitationURL, 
        checkIn, 
        checkOut
    ]);
    

    const onSave = useCallback(async () => {

        const editedIntegration: Integration = {
            apikey: apikeyValue,
            company: integration.company,
            createPublicUrl,
            type: integration.type,
            uid: integration.uid,
            sendInvitationURL,
            check_in: checkIn,
            check_out: checkOut
        }
        try {
            await dispatch(PMSIntegrationsActions.createIntegration(editedIntegration));
        } catch (err: any) {
            setError(err.message)
        }
    }, [
        createPublicUrl, 
        dispatch, 
        setError, 
        integration.company, 
        integration.type, 
        integration.uid, 
        apikeyValue,
        t, 
        sendInvitationURL,
        checkIn, 
        checkOut
    ])

    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            <div
                className="col-span-full"
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "3fr 2fr",
                }}
            >
                <div>Token</div>
                <input
                    className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                    type="text"
                    readOnly={!integration.editable}
                    value={apikeyValue}
                    placeholder={"Token"}
                    onChange={(e) => {
                        setApikeyValue(e.target.value?.trim());
                        /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                            setError(t('mod_integrations_already_have_integration_with_this_link'));
                        } else {
                            disableError();
                        }*/
                    }}
                />
            </div>
            <div
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "1fr 1fr",
                }}
                className="col-span-full d-flex justify-content-between"
            >
                <div>
                    <CreatePublicUrlToggle
                        boldedName={false}
                        initialState={createPublicUrl}
                        readOnly={!integration.editable}
                        onChange={setCreatePublicUrl}
                    />
                    { enableSendInvitationURLToggle && 
                        <div className="mt-1">
                            <SendInvitationURLToggle
                                boldedName={false}
                                initialState={sendInvitationURL}
                                readOnly={!integration.editable}
                                onChange={setSendInvitationURL}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between pr-2">
                <div className="c-text-capitalize-first mr-2">
                    Check In
                </div>
                <TimePicker
                    disabled={!integration.editable}
                    showSecond={false}
                    minuteStep={5}
                    value={getHourAndMinutesFormat(checkIn)}
                    format={TIME_FORMAT}
                    onChange={(value) =>
                        setCheckIn(
                            value
                                ? value?.format(TIME_FORMAT)
                                : MIN_TIME,
                        )
                    }
                    />
            </div>
            <div className="d-flex align-items-center justify-content-between pr-2">
                <div className="c-text-capitalize-first mr-2">
                    Check Out
                </div>
                <TimePicker
                    disabled={!integration.editable}
                    showSecond={false}
                    minuteStep={5}
                    value={getHourAndMinutesFormat(checkOut)}
                    format={TIME_FORMAT}
                    onChange={(value) =>
                        setCheckOut(
                            value
                                ? value?.format(TIME_FORMAT)
                                : MIN_TIME,
                        )
                    }
                />
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    {integration.uid &&
                        <CapsuleButton
                            size="SMALL"
                            classNameExtra="mr-2"
                            style={!integration.editable ? 'PRIMARY' : 'INFO'}
                            onClick={() => onToggleEditable(index)}
                            text={t(!integration.editable ?  "global_edit" : 'global_cancel')}
                        />
                    }
                    {integration.uid &&
                        <CapsuleButton
                            onClick={() => onDelete(index)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            style="DANGER"
                            size="SMALL"
                            icon={Icons.trash}
                        />
                    }
                    <CapsuleButton
                        size="SMALL"
                        isEnabled={canSave}
                        onClick={onSave}
                        text={t("global_save")}
                    />
                </div>
            </div>
            {errorMessage && (
                <div className="text-danger mr-2">{errorMessage}</div>
            )}
        </div>
    );
}