import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContentSpace } from "../_components";
import { BudgetsStore } from "../_reducers/budgetsRootReducer";
import { ClientsListView } from "./ClientsListView";
import { CLIENTS_MODULE_UI_CONSTANTS } from "./ClientsModuleUIConstants";
import { ClientActions } from "../_actions/ClientActions";
import { EditClientView } from "./EditClientView";
import { NewClientView } from "./NewClientView";

export function ClientsModule() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const clients           = useSelector(BudgetsStore.selectors.getClients);
    const secondaryView     = useSelector(BudgetsStore.selectors.getClientsModuleSecondaryView);
    const selectedClient    = useSelector(BudgetsStore.selectors.getSelectedClient);
    const selectedClientUid = useSelector(BudgetsStore.selectors.getSelectedClientUid);

    //INITIALIZATION
    useEffect(() => {
        dispatch(ClientActions.listClients());
    }, [dispatch]);

    //CLEAN UP
    useEffect(() => {
        return () => dispatch(ClientActions.changeSecondaryView(null));
    }, [dispatch]);

    //SET VIEWS
    useEffect(() => {
        if (!selectedClient)
            return;
        
        dispatch(ClientActions.changeSecondaryView(CLIENTS_MODULE_UI_CONSTANTS.SECONDARY_VIEWS.EDIT_CLIENT));
    }, [dispatch, selectedClient]);

    //ACTION HANDLERS
    const handleGoBack = useCallback(() => {
        dispatch(ClientActions.changeSecondaryView(null));
    }, [dispatch]);

    //VIEW
    return (
        <ContentSpace
            mainContent={<ClientsListView
                clients={clients}
                secondaryView={secondaryView}
                selectedClientUid={selectedClientUid}
            />}
            detailedContent={getSecondaryView()}
            onGoBack={handleGoBack}
        />
    );

    function getSecondaryView() {
        switch (secondaryView) {
            case CLIENTS_MODULE_UI_CONSTANTS.SECONDARY_VIEWS.EDIT_CLIENT:
                return <EditClientView
                    client={selectedClient}
                />;

            case CLIENTS_MODULE_UI_CONSTANTS.SECONDARY_VIEWS.NEW_CLIENT:
                return <NewClientView />;

            default:
                return null;
        }
    }
}