import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { WithContext as ReactTags } from "react-tag-input";
import { emailsActions } from "../../../_actions/emails.actions";
import "./styles.scss";

export function GenericEmailsInput({
    emails = [],
    handleChangeEmails,
    disableInput = false
} = {}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();


    //LOCAL STATE
    const fileInputRef = useRef();


    async function handleEmailFileLocal(e) {
        const emailsCsvFile = e.target.files[0];
        fileInputRef.current.value = "";

        const newEmails = await dispatch(
            emailsActions.processEmailsFile(emailsCsvFile),
        ).then(emails => {setEmailsAndTags(emails)});

        if (!newEmails) return;
    }

    function setEmailsAndTags(validEmails) {
        const emailsUpdated = [
            ...validEmails,
            ...emails,
        ];
        handleChangeEmails(emailsUpdated);
    }

    async function handleAddEmail({ text }) {
        if(disableInput) return;
        if (text) {
            const validEmail = await dispatch(
                emailsActions.processSingleEmail(text),
            );
        
            if (validEmail) {
                setEmailsAndTags([validEmail]);
            }
        }
    }
    async function handleOnBlurEmail(text) {
        handleAddEmail({ text })
    }

    function handleDeleteEmail(i) {
        const emailsUpdated = emails.filter((_, index) => index !== i);
        handleChangeEmails(emailsUpdated);
    }

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    //VIEW
    return (
        <div className={`${disableInput ? "disable_input" : ""}`}>
            {title()}
            <div
                style={{ maxHeight: "300px", overflowY: "scroll" }}
                className="mt-2 border c-rounded-big bg-white"
            >
                <ReactTags
                    tags={emails?.map((email) => ({ id: email, text: email }))}
                    delimiters={[KeyCodes.comma, KeyCodes.enter]}
                    handleDelete={handleDeleteEmail}
                    handleAddition={handleAddEmail}
                    handleInputBlur={handleOnBlurEmail}
                    placeholder={t("common.emailPlaceholder")}
                    allowDragDrop={false}
                    inputFieldPosition="top"
                />
            </div>
        </div>
    );

    function title() {
        return (
            <div className="font-weight-bold d-flex justify-content-between">
                <div>
                    {t("common.emails")}
                    <span className="font-weight-regular">&nbsp;*</span>
                </div>
                {importFromFile()}
            </div>
        );
    }

    function importFromFile() {
        return (
            <label className="my-auto d-flex">
                <div
                    className="
                    c-w-min
                    px-3
                    rounded
                    c-btn-primary
                    c-text-capitalize-first
                    text-nowrap
                    text-center
                    cp
                "
                >
                    {t("invitations.importCsv")}
                </div>
                <input
                    className="d-none"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleEmailFileLocal}
                />
            </label>
        );
    }
}

