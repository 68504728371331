import React, { useState, useCallback, useMemo } from "react";
import { IbanElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

import { CapsuleButton } from "../_components";
import { ValidatedTextInput } from "../../Common/_components/ValidatedTextInput";
import { useValidation } from "../_hooks/useValidation";
import { ValidationHelper } from "../_helpers";

const fn = () => {};

export function CustomIbanElement({
    setIsRequesting = fn,
    onAddIban = async (stripe, ibanElement, name, email) => {},
} = {}) {

    //LIBRARIES
    const stripe = useStripe();
    const { t } = useTranslation();
    const elements = useElements();

    const {
        handleSetValid,
        isAllValid,
    } = useValidation();

    //LOCAL ACTION HANDLERS
    const setIsEmailValid = useCallback(isValid => {
        handleSetValid("EMAIL", isValid);
    }, [handleSetValid]);

    const setIsNameValid = useCallback(isValid => {
        handleSetValid("NAME", isValid);
    }, [handleSetValid]);

    const emailValidationFns = useMemo(() => 
        [ValidationHelper.UiValidationFns.email]
    , []);

    //ACTION HANDLERS
    async function handleAddPaymentMethod() {
        const ibanElement = elements.getElement(IbanElement);
        console.warn("here");

        if (!stripe || !ibanElement) {
            console.warn("Stripe element not found");
            return;
        }

        setIsRequesting(true);
        await onAddIban(stripe, elements.getElement(IbanElement), name, email);
        setIsRequesting(false);
    }

    //LOCAL STATE
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    function inputs() {
        return (
            <div>
                <div className="mt-1" />
                <ValidatedTextInput
                    isRequired

                    value={name}
                    setValue={setName}
                    setIsValid={setIsNameValid}

                    title={t("global_name")}
                    placeholder={t("global_name")}
                />

                <div className="mt-1" />

                <ValidatedTextInput
                    isRequired
                    warningFns={emailValidationFns}

                    value={email}
                    setValue={setEmail}
                    setIsValid={setIsEmailValid}

                    title={t("global_email")}
                    placeholder={t("global_email")}
                />
            </div>
        );
    }
    
    //VIEW
    return (
        <div className="d-flex flex-column">

            <div className="
                d-flex
                no-gutters
                align-items-center
                
                rounded border
                px-2
                py-1
            ">
                <IbanElement
                    className="w-100"
                    options= {{
                        supportedCountries: ['SEPA'],
                    }}
                />
                <div>
                    <CapsuleButton
                        text={t("global_add")}
                        isEnabled={!!stripe && isAllValid}
                        onClick={handleAddPaymentMethod}
                        size={'SMALL'}
                        fillStyle={'FILLED'}
                    />
                </div>
            </div>

            {inputs()}
        </div>
    );
}