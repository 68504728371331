import { EXCEPTIONS } from "../_constants/EXCEPTIONS";

export function GenericErrorWrapperFactory(showAlertAction) {
    return {
        errorCatch,
        genericAsyncAction,
    };

    function errorCatch(error) {
        return dispatch => {
            console.error(error);
    
            if (isSilentError(error))
                return;
    
            dispatch(showAlertAction(error.toString()));
        };
    }
    
    function genericAsyncAction(asyncAction, catchFn, rethrow) {
        return async dispatch => {
            try {
                return await asyncAction();
            } catch (error) {
                if (error.message === EXCEPTIONS.REQUEST_EXPIRED)
                    return;
    
                if (catchFn) {
                    const stopExecution = catchFn(error);
                    if (stopExecution)
                        return;
                }
    
                if (rethrow)
                    throw error;
    
                dispatch(errorCatch(error));
            }
        }
    }
    
    function isSilentError(error) {
        const SILENT_ERRORS = [
            EXCEPTIONS.NO_LOCATION_SELECTED,
        ];
    
        return SILENT_ERRORS.includes(error);
    }
}