export const USERS_STATUS_ACTION_TYPES = {
    //LOCAL
    SELECT_USER: "USERS_STATUS_SELECT_USER",
    CHANGE_DATE_FILTER: "USERS_STATUS_CHANGE_DATE_FILTER",
    
    //REMOTE
    REQUEST_USERS_STATUS: "REQUEST_USERS_STATUS",
    GET_USERS_STATUS_SUCCESS: "GET_USERS_STATUS_SUCCESS",

    REQUEST_USER_STATUS_DATA: "REQUEST_USER_STATUS_DATA",
    GET_USER_STATUS_DATA_SUCCESS: "USERS_STATUS_DATA_SUCCESS",

    GET_USER_STATUS_LOGS_REQUEST: "GET_USER_STATUS_LOGS_REQUEST",
    GET_USER_STATUS_LOGS_SUCCESS: "GET_USER_STATUS_LOGS_SUCCESS",
};

export const USER_STATUS = {
    OK:             0,
    USAGE_NOT_OK:   1,
    NO_USES:        2,
    MOBILE_NOT_OK:  3,
    CANCELLED_BY_USER:  4,
};

export const USERS_STATUS_DATE_FILTER_PERIODS = {
    TODAY: "TODAY",
    LAST_WEEK: "LAST_WEEK",
    LAST_2_WEEKS: "LAST_2_WEEKS",
    LAST_MONTH: "LAST_MONTH",
};