import { uiAlertActions } from "./uiAlert.actions";
import { EXCEPTIONS } from "../_constants";

export const genericActions = {
    errorCatch,
    genericAsyncAction,
};

function errorCatch(error) {
    return dispatch => {
        console.error(error);

        if (isSilentError(error))
            return;

        dispatch(uiAlertActions.error(error.toString()));
    };
}

function genericAsyncAction(asyncAction, catchFn?, rethrow?) {
    return async dispatch => {
        try {
            return await asyncAction();
        } catch (error: any) {
            if (error.message === EXCEPTIONS.REQUEST_EXPIRED)
                return;

            if (catchFn) {
                const stopExecution = catchFn(error);
                if (stopExecution)
                    return;
            }

            if (rethrow)
                throw error;

            dispatch(genericActions.errorCatch(error));
        }
    }
}

function isSilentError(error) {
    const SILENT_ERRORS = [
        EXCEPTIONS.NO_LOCATION_SELECTED,
    ];

    return SILENT_ERRORS.includes(error);
}