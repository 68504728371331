import  { OrderStatus } from '../_modelsTS/Order';
import { i18n } from "../_translations/i18n";

export const ORDER_STATUS = {
    PENDING:    0,
    UNPAID:     1,
    PAID:       2,
};


export const ORDER_STATUS_UI: {[key in OrderStatus]: string} = {
    paid: i18n.t('order_status_paid'),
    draft: i18n.t('mod_budgets_status_draft'),
    uncollectible: i18n.t('mod_billing_could_not_be_paid'), 
    void: '',
}