import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { authenticationActions } from '../_actions';
import { WEBAPP_URLS, LOGIN_CODE_TYPES } from '../_constants';
import { BaseLoginPage } from './BaseLoginPage';
import { InputBox } from './InputBox';
import { CustomLink } from './CustomLink';

import emailIcon from '../_assets/customIcons/at-icon.svg';
import { CapsuleButton } from '../_components/CapsuleButton';
import { Selectors } from '../_reducers/app.reducer';
import { HistoryHelper } from '../_helpers';
import { useReloadOnValidUser } from '../_hooks/useReloadOnValidUser';

const testIDs = {
    root: "register-page",
    registerButton: "register-button",
};

RegisterPage.testIDs = testIDs;

export function RegisterPage() {

    //DEPENDENCIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const code          = useSelector(Selectors.getLoginCode);
    const codeType      = useSelector(Selectors.getLoginCodeType);
    const storedEmail   = useSelector(Selectors.getLoginEmail);
    const user          = useSelector(Selectors.getEverouUser);

    //LOCAL STATE
    const [email, setEmail] = useState(storedEmail || "");
    const localCodeType = codeType === null ? LOGIN_CODE_TYPES.EMAIL : codeType;

    //INITIALIZATION
    useReloadOnValidUser(user);
    useEffect(() => {
        if (!storedEmail && !code)
            HistoryHelper.goToRoot();
    }, [storedEmail, code]);

    //ACTION HANDLERS
    function handleChangeUsername(e) {
        setEmail(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (localCodeType === LOGIN_CODE_TYPES.EMAIL && !email)
            return;

        handleRegister(email);
    }

    function handleRegister() {
        dispatch(authenticationActions.register({
            codeType: localCodeType || LOGIN_CODE_TYPES.EMAIL,
            email,
            code,
        }));
    }

    //VIEW
    return (
        <BaseLoginPage
            content={content()}
        />
    );

    function content() {
        return (
            <div
                data-testid={testIDs.root}
                className="
                    d-flex
                    no-gutters
                    flex-column
                    
                    align-items-center
                "
            >
                <div className="mt-3"/>
                <div className="text-center">
                    {t("login_email_not_exists_dialog")}
                </div>

                {emailInputSection()}

                <div className="mt-2"/>
                {legalNotice()}

                <div className="mt-3"/>
                {registerButton()}

                <div className="mt-4" />
                {linkText()}
                {cancelLink()}
            </div>
        );
    }

    function emailInputSection() {
        if (localCodeType === LOGIN_CODE_TYPES.APPLE)
            return null;
        
        return (
            <>
            <div className="mt-3"/>
            <form onSubmit={handleSubmit}
                className="col flex-grow-0
            ">
                {emailInput()}
                {helpBlock()}
            </form>
            </>
        );
    }

    function linkText() {
        return (
            <div className="c-text-capitalize-first">
                {t("register_already_registered_message")}
            </div>
        );
    }

    function emailInput() {
        return <InputBox
            value={email}
            handleChange={handleChangeUsername}
            
            imgSrc={emailIcon}
            placeholder={t("global_email")}

            isReadOnly={localCodeType !== LOGIN_CODE_TYPES.EMAIL}
        />;
    }

    function helpBlock() {
        if (email)
            return;

        return (
            <div className="help-block">
                {t("login.emailRequired")}
            </div>
        );
    }

    function registerButton() {
        return <CapsuleButton
            testId={testIDs.registerButton}
            onClick={handleSubmit}
            text={t("register_register_button")}
            fillStyle={'FILLED'}
        />;
    }

    function cancelLink() {
        return <CustomLink
            text={t("register_go_to_login")}
            url={WEBAPP_URLS.LOGIN}
        />;
    }

    function legalNotice() {
        return (
            <div className="text-center c-new-ft-s">
                <Trans i18nKey={"register_terms_hint_pw"}>
                    <a className="text-white c-text-underline" href={WEBAPP_URLS.TERMS}>terms link placeholder</a>
                    <a className="text-white c-text-underline" href={WEBAPP_URLS.PRIVACY_POLICY}>privacy link placeholder</a>
                </Trans>
            </div>
        );
    }
}