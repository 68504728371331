import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { FiltersColumn } from './FiltersColumn';
import { DevicesColumn } from './DevicesColumn';

import { appPermissions } from '../_constants/permissions.constants';
import { filterModeParameters } from '../_constants';
import { MainPageWithButtons } from '../_components';
import { devicesActions } from '../_actions';
import { Selectors } from '../_reducers/app.reducer';
import { DeviceFilterSelector } from './DeviceFilterSelector';

export function DevicesMainPage() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const filterMode    = useSelector(Selectors.getDeviceFilterFilterMode);
    const categories    = useSelector(Selectors.getDeviceFilterCategories);
    
    const markedDevice  = useSelector(Selectors.getMarkedDevice);
    const devices       = useSelector(Selectors.getSelectedLocationDevices);
    const rooms         = useSelector(Selectors.getRooms);
    const locationUid   = useSelector(Selectors.getSelectedLocationUid);

    const permission    = useSelector(Selectors.getPermissionSelectedLocation);


    //LOCAL STATE
    const canViewSettings = appPermissions.canUserViewDeviceSettings(
        permission,
    );

    //INITIALIZATION
    useEffect(() => {
        dispatch(devicesActions.initializeDevices(
            markedDevice,
            filterMode,
            devices,
            rooms,
        ));
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid, rooms]);

    useEffect(() => {
        dispatch(devicesActions.showDevicesOfSelectedCategories(
            devices,
            categories,
            filterMode,
        ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, categories]);

    const activeCategories = useMemo(() => categories.filter(category => category.active), [categories])

    //VIEW
    return (
        <MainPageWithButtons
            size={'UNLIMITED'}
            pageTitle={t("devices.devices")}
            content={devicesContent()}
            buttons={[<DeviceFilterSelector />]}
        />
    );


    function devicesContent() {
        return (
            <div
                className="
                c-filtered-layout
                pt-3
            "
            >
                <FiltersColumn />
                <DevicesColumn
                    filterModeParameter={getFilterModeParam(filterMode)}
                    activeCategories={activeCategories}
                    devices={devices}
                    canViewSettings={canViewSettings}
                />
            </div>
        );
    }

    function getFilterModeParam(deviceFilterMode) {
        return filterModeParameters[deviceFilterMode];
    }
}