import React, { useRef, useEffect } from "react";
import { Chart, DoughnutController, ArcElement } from "chart.js";

function DataObj({ number, color, tag, } = {}) {
    return { number, color, tag, };
}

UsersStatusPieChart.DataObj = DataObj;

export function UsersStatusPieChart({
    dataArray = [] || [DataObj()],
}) {
    
    //LOCAL STATE
    const chartRef = useRef();

    useEffect(() => {
        createChart({
            chartRef,
            dataArray,
        });
    }, [dataArray]);

    //VIEW
    return (
        <div>
            <canvas ref={chartRef}/>
        </div>
    );
}

function createChart({
    dataArray,
    chartRef,
}) {
    const total = dataArray.reduce((result, current) => result + current.number, 0);

    Chart.register(DoughnutController, ArcElement);

    return new Chart(
        chartRef.current.getContext('2d'),
        {
            type: "doughnut",
            data: buildFromData(dataArray),
            options: optionsFn(),
        },
    );

    function buildFromData(dataObjs = [] || [DataObj()]) {
        const result = {
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                },
            ],
            labels: [],
        };

        dataObjs.forEach(dataObj => {
            result.datasets[0].data.push(dataObj.number);
            result.datasets[0].backgroundColor.push(dataObj.color);
            result.labels.push(dataObj.tag);
        });

        return result;
    }

    function optionsFn() {
        return {
            legend: {
                position: "right",
            },
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: 40,

            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        const label = data.labels[tooltipItem.index];
                        const number = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        const roundedPercentage = Math.round(number / total * 100);
                        return `${label}: ${roundedPercentage}%`;
                    },
                },
            },
        };
    }
}