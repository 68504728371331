import { ReservationPublicUrl } from "../../ModuleLocations/_components/InvitationPublicUrl";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomCard } from "../../../Common/_components";
import { ReadOnlyDataRow } from "../../../Everou/_components";
import { IntegrationReservationStatusLabel } from "../../../Everou/_modelsTS/Integration";
import { TimeHelper } from "../../../Everou/_helpers";

type CategoryProps = {
    icon?: any;
    title: string,
    options?: React.ReactNode,
    content?: React.ReactNode;
    id: string;
}

function Category({ title, icon, options, content, id }: CategoryProps) {
    return (
        <div className="p-3 mb-3 c-bg-grey-245 c-rounded-big">
            <div className="px-2 mb-3 d-flex justify-content-between align-content-center">
                <div className="d-flex align-items-center">
                    <h3 className="c-line-height-1 mb-0">{title}</h3>
                </div>
                {options}
            </div>
            {content}
        </div>
    );
}

export function PMSLocationColumn({
    locations,
}) {
    const {t} = useTranslation();

   return (
    <div
        className="
        w-100
        h-100

        d-flex
        flex-column
    "
    >
        <div className="overflow-auto c-masonry">{buildReservations()}</div>
    </div>
   );

   function buildReservations() {
        return (
            locations
            .sort((a,b) => a.reservations.length > b.reservations.length ? -1 : 1)
            .map(location => buildUILocation(location))
       );
   }

   function buildUILocation(location) {
       const uiReservations = buildUIReservations(location);
       return (
            <Category 
                key={location.uid}
                title={location.name}
                id={location.uid}
                content={<div className="c-default-grid">{uiReservations}</div>}
            />
       )
   }

   function buildUIReservations(location) {
        if (!location.reservations.length) {
            return [<div key={location.uid + '_no_reservations'} className='d-flex jusity-content-center align-items-center pt-2 pb-3 text-secondary'><span className='mx-auto'>{t('mod_integrations_reservations_not_yet')}</span></div>];
        }

        return (
            location.reservations
            .map((reservation) => 
                <CustomCard banner={
                        IntegrationReservationStatusLabel[reservation.status]
                } key={reservation.uid}>
                    <ReadOnlyDataRow
                        key={reservation.uid + '1'}
                        name={t('global_email')}
                        data={reservation.email} />
                    <ReadOnlyDataRow
                        key={reservation.uid + '2'}
                        name={t('mod_integrations_reservation_id')}
                        data={reservation.reservationId} />
                    <ReadOnlyDataRow
                        key={reservation.uid + '3'}
                        name={t('mod_integrations_reservation_start')}
                        data={TimeHelper.localizeIsoStringToFormat(
                            reservation.reservationStart,
                            location.timezone,
                            "dddd DD,  MMMM YYYY HH:mm",
                        )} />
                    <ReadOnlyDataRow
                        key={reservation.uid + '3'}
                        name={t('mod_integrations_reservation_end')}
                        data={TimeHelper.localizeIsoStringToFormat(
                            reservation.reservationEnd,
                            location.timezone,
                            "dddd DD,  MMMM YYYY HH:mm",
                        )} />
                    {reservation.url && 
                        <ReservationPublicUrl
                            publicUrl={reservation.url} />
                    }
                
                </CustomCard>
            )
        )
   }
}