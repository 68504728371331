import {
    DEVICES_ACTION_TYPES,
} from '../_constants';

const initialState = {
    deviceConfig: null,
    markedDevice: undefined,
    isAllDevicesSelected: true,
    lastAction: {
        actionUid: undefined,
        status: undefined,
        deviceUid: undefined,
        deviceType: undefined,
    },

    devicesByLocationUid: {},
};

export class DeviceSelectors {
    static getLocationDevices       = (state, locationUid) => state.devicesByLocationUid[locationUid] || [];
    static getDeviceConfig          = state => state.deviceConfig;
    static getMarkedDevice          = state => state.markedDevice;
    static getIsAllDevicesSelected  = state => state.isAllDevicesSelected;
    static getLastAction            = state => state.lastAction;
}

export function devices(state = initialState, action, selectedLocationUid) {
    if (!state.hydrated) {
        state = {
            ...initialState,
            ...state,
            hydrated: true,
        };
    }

    switch (action.type) {
        case DEVICES_ACTION_TYPES.GET_DEVICE_SUCCESS: {

            return { ...state,
                devicesByLocationUid: updateSelectedLocationDevice(action.device),
            };
            
        }

        case DEVICES_ACTION_TYPES.SEND_ACTION_SUCCESS:
            return { ...state,
                lastAction: action.deviceAction,
            };

        case DEVICES_ACTION_TYPES.DEVICES_GET_SUCCESS: {
            return {...state,
                devicesByLocationUid: updateLocationDevices(
                    action.devices,
                    action.locationUid,
                ),
            };
        }

        case DEVICES_ACTION_TYPES.DEVICE_REMOTE_OTA_UPDATE_STATUS: {

            const res = {
                ...state,
                devicesByLocationUid: updateVersionOfDeviceInLocation(
                    action.device
                ),
            };
            if (state.deviceConfig?.uid === action.device?.uid) {
                return {
                    ...res,
                    deviceConfig: {
                        ...state.deviceConfig,
                        updating: action.device.updating,
                        updateStatus: action.device.updateStatus,
                        updatePercent: action.device.updatePercent
                    },
                };
            }
            return res;
        }

        case DEVICES_ACTION_TYPES.DEVICES_UPDATE_SINGLE: {
            const updatedSelectedLocationDevices = getSelectedLocationDevices().map(device => 
                device.uid === action.device.uid
                ? { ...device, ...action.device }
                : device
            );
            
            return { ...state,
                devicesByLocationUid: updateSelectedLocationDevices(updatedSelectedLocationDevices),
            };
        }

        case DEVICES_ACTION_TYPES.DEVICES_TOGGLE_SELECT_SINGLE: {
            return { ...state,
                isAllDevicesSelected: false,
                markedDevice: setMarkedDevice(),
                devicesByLocationUid: updateSelectedLocationDevices(setItems()),
            };

            function setItems() {
                if (state.isAllDevicesSelected) {
                    return getSelectedLocationDevices().map(device => 
                        ({ ...device,
                            selected: device.uid === action.deviceUid
                        })
                    );
                }

                return getSelectedLocationDevices().map(device =>
                    device.uid === action.deviceUid
                        ? { ...device, selected: !device.selected }
                        : device
                );
            }

            function setMarkedDevice() {
                const deviceToToggle = getSelectedLocationDevices().find(device =>
                    device.uid === action.deviceUid
                );

                if (!deviceToToggle)
                    return undefined;

                if (state.isAllDevicesSelected || !deviceToToggle.selected) {
                    return deviceToToggle.uid;
                }

                return undefined;
            }
        }

        case DEVICES_ACTION_TYPES.SELECT_ALL_VISIBLE_DEVICES: {

            const updatedSelectedLocationDevices = getSelectedLocationDevices().map(device =>
                ({ ...device,
                    selected: device.show,
                })
            );

            return { ...state,
                markedDevice: undefined,
                isAllDevicesSelected: true,

                devicesByLocationUid: updateSelectedLocationDevices(updatedSelectedLocationDevices),
            };
        }

        case DEVICES_ACTION_TYPES.DEVICES_SHOW: {
            
            const updatedSelectedLocationDevices = getSelectedLocationDevices().map(device =>
                ({ ...device,
                    show: action.devicesUids.includes(device.uid),
                })
            );

            return { ...state,
                devicesByLocationUid: updateSelectedLocationDevices(updatedSelectedLocationDevices),
            };
        }

        case DEVICES_ACTION_TYPES.TOGGLE_DEVICE_FROM_PROTECT: {

            const updatedSelectedLocationDevices = getSelectedLocationDevices().map(device => 
                device.uid === action.deviceUid
                ? { ...device, notification: !device.notification}
                : device
            );

            return { ...state,
                devicesByLocationUid: updateSelectedLocationDevices(updatedSelectedLocationDevices),
            };
        }

        case DEVICES_ACTION_TYPES.SELECT_DEVICE_CONFIG:
            return { ...state,
                deviceConfig: getSelectedLocationDevices().find(device => 
                    device.uid === action.deviceUid,
                ),
            };

        case DEVICES_ACTION_TYPES.SELECTED_DEVICE_UPDATE:
            return { ...state,
                deviceConfig: { ...state.deviceConfig,
                    ...action.device,
                    isEdited: true,
                },
            };

        case DEVICES_ACTION_TYPES.SELECTED_DEVICE_SAVE_SUCCESS: {
            
            const updatedSelectedLocationDevices = getSelectedLocationDevices().map(device =>
                device.uid === action.device.uid
                ? action.device
                : device
            );

            return { ...state,
                devicesByLocationUid: updateSelectedLocationDevices(updatedSelectedLocationDevices),
                deviceConfig: { ...action.device,
                    isEdited: false,
                },
            };
        }

        case DEVICES_ACTION_TYPES.SELECTED_DEVICE_DISCARD:
            return { ...state,
                deviceConfig: {
                    ...getSelectedLocationDevices().find(device =>
                        device.uid === state.deviceConfig.uid
                    ),
                    isEdited: false,
                },
            };

        case DEVICES_ACTION_TYPES.DEVICE_GET_FIRMWARE_VERSIONS_SUCCESS:

            const deviceConfig = {
                ...getSelectedLocationDevices().find(device =>
                    device.uid === state.deviceConfig?.uid
                ),
                firmwareVersions: action.firmwareVersions
            }
            
            const devicesByLocationUid = updateSelectedLocationDevice(deviceConfig);

            return {
                ...state,
                deviceConfig,
                devicesByLocationUid
            }

        default:
            return state;
    }

    function getSelectedLocationDevices()  {
        return DeviceSelectors.getLocationDevices(state, selectedLocationUid);
    }

    function updateSelectedLocationDevices(devices) {
        return {
            ...state.devicesByLocationUid,
            [selectedLocationUid]: devices,
        };
    }

    function updateLocationDevices(devices, locationUid) {
        return {
            ...state.devicesByLocationUid,
            [locationUid]: devices,
        };
    }            
    
    function updateSelectedLocationDevice(newDevice) {
        return {
            ...state.devicesByLocationUid,
            [selectedLocationUid]: [
                ...state.devicesByLocationUid[selectedLocationUid]
                    .filter(device => device.uid !== newDevice.uid),
                newDevice,
            ],
        };
    }

    function updateVersionOfDeviceInLocation(newDevice) {
        
        const device = state.devicesByLocationUid[selectedLocationUid].find(
            (device) => device?.uid === newDevice?.uid,
        );

        return {
            ...state.devicesByLocationUid,
            [selectedLocationUid]: [
                ...state.devicesByLocationUid[selectedLocationUid].filter(
                    (device) => device?.uid !== newDevice?.uid,
                ),
                {
                    ...device,
                    updating: newDevice?.updating,
                    updateStatus: newDevice?.updateStatus,
                    updatePercent: newDevice?.updatePercent,
                },
            ],
        };
    }
}