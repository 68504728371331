import React from "react";
import { SwitchGeneric } from "./SwitchGeneric";

export function SwitchIOInverted({
    deviceStatus,
    remote,
    handleSendAction = actionStatus => {},
}) {
    //VIEW
    return (
        <SwitchGeneric
            deviceStatus={deviceStatus}
            handleSendAction={handleSendAction}
            remote={remote}
            leftButtonName={"0"}
            rightButtonName={"I"}

            leftSideStatus={1}
            rightSideStatus={0}

            offStatus={1}
        />
    );
}