import React, { useMemo, } from "react";
import { useSelector } from 'react-redux';

import { Budgets } from "../Budgets/Budgets";
import { BridgeUserMapper } from "../Common/_mapers/BridgeUserMapper";

import { Selectors } from "./_reducers/app.reducer";
import { UserManager } from "./App/UserManager";

export function BudgetsEverouBridge() {

    //GLOBAL STATE
    const everouUser = useSelector(Selectors.getEverouUser);

    const bridgeUser = useMemo(
        () =>  BridgeUserMapper.fromEverouUser(everouUser),
        [everouUser],
    );

    //VIEW
    return (
        <UserManager>
            <Budgets
                bridgeUser={bridgeUser}
            />
        </UserManager>
    );
}