import { PMSDevice } from "../_models/PMSDevice";
import { PMSRoom } from "../_models/PMSRoom";
import { PMSLocationInfo } from "../_models/PMSLocationInfo";
import { DeviceHelper } from "../../Everou/_helpers";
import { TimeHelper } from "../../Everou/_helpers/TimeHelper";
import { Device } from "../../Everou/_models/Device";
import { infoCellForDeviceType } from "../../Everou/_constants";
import { LogsHelper } from "../../Everou/_helpers";

export class PMSLocationInfoMapper {
    static mapServerToLocal(serverLocationInfo) {
        const {
            timezone,
            name,
            loc_id,
            protect,
            protect_auto_enabled_without_reservations,
            rooms,
            video_name,
            video_url,
            loc_uid,
        } = serverLocationInfo;

        const {
            has_customization,
            has_protect,
            has_protect_subscription,
            vacation_rental_plan,
            url_recommendations,
            traveler_discount,
            external_video_url,
        } = serverLocationInfo.features;

        const {
            invitations_allowed,
            invitations_left,
            can_create_superguest
        } = serverLocationInfo.invitation_settings;

        const {
            permission_type,
            notification
        } = serverLocationInfo.user_settings;

        return PMSLocationInfo({
            timezone: timezoneServerToLocal(timezone),
            name: name,
            locationId: loc_id,
            isProtectOn: protect,
            protectAutoEnabledWithoutReservations:protect_auto_enabled_without_reservations,
            rooms: roomsServerToLocal(rooms),
            uid: loc_uid,
            allowsCustomization: has_customization,
            allowsProtect: has_protect,
            hasProtectSubscription: has_protect_subscription,
            invitationsAllowed: invitations_allowed,
            invitationsLeft: invitations_left,
            canCreateSuperguest: can_create_superguest,
            permission: permission_type,
            isNotificationsActive: notification,
            vacationRentalPlan: vacation_rental_plan,
            urlRecommendations: url_recommendations,
            travelerDiscount: traveler_discount,
            videoName: video_name,
            videoUrl: video_url,
            externalVideoUrl: external_video_url
        }); 

        function timezoneServerToLocal(timezone) {
            if (timezone === null)
                return "UTC";
            
            return timezone;
        }

        function roomsServerToLocal(rooms) {
            return rooms.map(({
                uid,
                name,
                type,
                devices
            }) => PMSRoom ({
                uid,
                name,
                type,
                devicesAmount: devices?.length ?? 0
            }));
        }
    }

    static serverLocationInfoToLocalDevices(serverLocationInfo) {
        const localDevices = [];
        const locationName = serverLocationInfo.name;
        serverLocationInfo.rooms.forEach(room => {
            const roomName = room.name;
            localDevices.push(
                ...room.devices
                .map(serverDevice =>
                    PMSLocationInfoMapper.deviceServerToLocal(
                        serverDevice,
                        locationName,
                        roomName
                    )
                )
            );
        });
        return localDevices;
    }

    static deviceServerToLocal(serverDevice, locationName, roomName) {
        return Device({
            locationName: locationName,
            roomName: roomName,
            bluetoothId: serverDevice.bluetooth_id,
            battery: serverDevice.battery,
            uid: serverDevice.uid,
            description: serverDevice.desc,
            loc_uid: serverDevice.loc_uid,
            room_uid: serverDevice.room_uid,
            kit_uid: serverDevice.kit_uid,
            status: statusServerToLocal(serverDevice.status),
            type: serverDevice.type,
            timer: serverDevice.timer,
            update: serverDevice.update,
            original_type: serverDevice.original_type,
            version: serverDevice.version,
            lastActivity: serverDevice.lastActivity  ? TimeHelper.serverStringToIsoString(serverDevice.last_activity) : null,
            remote: serverDevice.remote,
            isNfcCardsSynchronized: serverDevice.synchronized_nfc_cards,
            isCalibrated: serverDevice.calibrated,
            wlan_ssid: DeviceHelper.getWLanSSIDForDevice(serverDevice.type, serverDevice.network_parameters),
            wifi_rssi: DeviceHelper.getWifiRssiForDevice(serverDevice.type, serverDevice.network_parameters),
            wlan_status: DeviceHelper.getWLanStatusForDevice(serverDevice.type, serverDevice.network_parameters),
            eth_status: DeviceHelper.getEthernetStatusForDevice(serverDevice.type, serverDevice.network_parameters),
            conn_status: DeviceHelper.getConnectionStatusForDevice(serverDevice.type, serverDevice.network_parameters),
            versionWifi: serverDevice.version_wifi,
            versionBle: serverDevice.version_ble,
            cellType: infoCellForDeviceType(serverDevice.type),
            localStatus: DeviceHelper.getLocalStatusForDevice(
                serverDevice.type,
                serverDevice.status,
            ),
            statusLabel: LogsHelper.getStatusLabelFromDevice(serverDevice.type, statusServerToLocal(serverDevice.status)),
        });

        function statusServerToLocal(serverStatus) {
            let localStatus = serverStatus;
            if (localStatus === null)
                localStatus = 0;
        
            return localStatus;
        }
    }
    
}