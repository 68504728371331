export function LocationInvitationConfig({
    canCreateSuperguests,
    invitationsAllowed,
    invitationsLeft,
} = {}) {
    return {
        canCreateSuperguests,
        invitationsAllowed,
        invitationsLeft,
    };
}