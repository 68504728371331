import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const mobilesService = {
    getMobilesFromLocation,
};

function getMobilesFromLocation(locationUid) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_MOBILES,
        { loc_uid: locationUid },
    );
}