import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { PMS_API_URLS } from "../_constants/PMS_API_URLS";

export const PMSUserService = {
    get,
    reservations,
    chat,
    postChat,
    getTags,
};

async function get() {
    return await ServiceHelper.getRequest(
        PMS_API_URLS.USER
    );
}

async function reservations() {
    return ServiceHelper.getRequest(PMS_API_URLS.RESERVATIONS);
}

async function chat(uid) {
    const parameters = "?reservation_uid=" + uid;
    return ServiceHelper.getRequest(PMS_API_URLS.CHAT, parameters);
}

async function postChat(uid, message) {
    return await ServiceHelper.postRequest(
        PMS_API_URLS.CHAT,
        {
            reservation_uid: uid,
            message: message
        }
    );
}

async function getTags() {
    return ServiceHelper.getRequest(PMS_API_URLS.TAGS);
}