import React from "react";

export function ProtectMessage({ icon, textA, textB }) {
    return (
        <div className="
            h-100
            mb-2
            overflow-auto
        ">
            <div className="
                d-flex
                no-gutters
                justify-content-center

                p-3

                border
                rounded
                bg-light
            ">
                <img
                    src={icon}
                    alt="protect icon"
                    className="
                        col-1
                    "
                />
                <div className="
                    my-auto
                    font-weight-bold
                    ml-3
                ">
                    <div>
                        {textA}
                    </div>
                    <div>
                        {textB}
                    </div>
                </div>
            </div>
        </div>
    );
}