import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { authenticationActions } from "../_actions";

export function LogOutGlobalPopUp() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={t("logOutPopUp.title")}
            body={t("logOutPopUp.description")}
            onAccept={handleLogOut}
            acceptButtonName={t("logOutPopUp.acceptButton")}
        />
    );

    function handleLogOut() {
        dispatch(authenticationActions.logout());
    }
}