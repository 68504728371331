import { Card } from "../../Everou/_models/Card";

export function NfcInfo({
    code,
    state,
    cardPackUid,
    invitationGroupUid,
    invitationGroupName,
    type,
    maxDuration,
    isConfiguration,

    userName,
    userLastName,
    userEmail,
} = {}) {
    return {
        ...Card({
            code,
            state,
            cardPackUid,
            invitationGroupUid,
            invitationGroupName,
            type,
            maxDuration,
            isConfiguration
        }),

        userName,
        userLastName,
        userEmail,
    };
}