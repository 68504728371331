export function BleActionData({
    confirmed,     
    actionUid,     
    action,        
    packet,        
    operationCode, 
    bleId,         
    bleIdOrigin,   
} = {}) {
    return {
        confirmed,
        actionUid,
        action,
        packet,
        operationCode,
        bleId,
        bleIdOrigin,
    };
}