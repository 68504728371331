import { Reservation } from "./Reservation";

export function ReservationConfig({
    isSetUp = true,
    isEdited = false,

    roomUid,
    roomName,
    roomType,

    blockMinutes,
    limitMinutes,

    allDay,
    begin,
    end,

    monday,
    tuesday,
    thursday,
    wednesday,
    friday,
    saturday,
    sunday,

    pendingReservation,

    excludedDays = [],

    numberOfUsers,
} = {}) {
    return {
        isSetUp,
        isEdited,
        
        roomUid,
        roomName,
        roomType,

        blockMinutes,
        limitMinutes,

        allDay,
        begin,
        end,

        monday,
        tuesday,
        thursday,
        wednesday,
        friday,
        saturday,
        sunday,

        pendingReservation: pendingReservation
            ? Reservation(pendingReservation)
            : null
        ,

        excludedDays,

        numberOfUsers,
    };
}