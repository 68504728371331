import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { invitationTypeUiValues } from "../_constants";
import { invitationsActions, globalActions } from "../_actions";
import { Invitation } from "../_models/Invitation";
import { CapsuleButton } from "../_components/CapsuleButton";
import { EmptyGlobalPopUp } from '../_components';
import { Selectors } from "../_reducers/app.reducer";

export function AcceptInvitationPopUp() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let validatedInvitation = Invitation();
    validatedInvitation = useSelector(Selectors.getInvitationValidated);

    //VIEW
    return <EmptyGlobalPopUp
        content={content()}
    />;

    function content() {
        return (
            <div>
                {header()}
                <hr className="m-0"/>
                {validatedContent()}
                <hr className="m-0"/>
            </div>
        );
    }

    function header() {
        return (
            <div className="
                d-flex
                no-gutters
                align-items-center
            ">
                <div className="col" />

                <div className="
                    d-flex
                    justify-content-center
                    
                    c-ft-l
                    py-2
                ">
                    {t("locations.addLocation")}
                </div>
                <div
                    onClick={handleCloseWindow}
                    className="
                        col
                        c-cursor-default
                        close
                        text-right
                        align-self-start
                    "
                >
                    <span>&times;</span>
                </div>
            </div>
        );
    }

    function validatedContent() {
        return (
            <div className="py-0 px-2 text-center">
                <div className="my-2">
                    {buildInvitationString()}
                </div>
                <hr className="m-0"/>
                <div className="d-flex no-gutters justify-content-center my-2">
                    <CapsuleButton
                        text={t("common.accept")}
                        onClick={handleAcceptInvitation}
                    />
                    <div className="pl-2" />
                    <CapsuleButton
                        text={t("common.cancel")}
                        onClick={handleCancelInvitation}
                        style={`DANGER`}
                    />
                </div>
            </div>
        );
    }

    function buildInvitationString() {
        const { userSrcEmail, type, deviceName } = validatedInvitation;
        let typeName = invitationTypeUiValues[type];

        return t(
            "invitations.userHasInvitedYou",
            {
                username: userSrcEmail,
                type: typeName,
                device: deviceName,
            }
        );
    }

    //ACTION HANDLERS
    function handleCloseWindow() {
        // dispatch(globalActions.hidePopUp());
        dispatch(invitationsActions.resetValidation());
    }

    function handleAcceptInvitation() {
        dispatch(globalActions.hidePopUp());
        dispatch(invitationsActions.acceptInvitation(
            validatedInvitation.uid,
            validatedInvitation.locationUid,
        ));
    }

    function handleCancelInvitation() {
        dispatch(invitationsActions.cancelInvitation(validatedInvitation.uid));
    }
}