import { appReducer } from './app.reducer';
import { GLOBAL_ACTION_TYPES } from '../_constants';

export default function rootReducer(state, action) {

    switch (action.type) {

        case GLOBAL_ACTION_TYPES.GLOBAL_RESET_STATE: {
            state = {
                common: {
                    initialUrl:             state.common?.initialUrl,
                    authentication:         state.common?.authentication,
                    entrypoint:             state.common?.entrypoint,
                    modules:                state.common?.modules,
                },
                everou: {
                    global:                 state.everou?.global,
                    locations:              state.everou?.locations,
                    locationInfo:           state.everou?.locationInfo,
                    userLogsStore:          state.everou?.userLogsStore,
                    pendingInvitations:     state.everou?.pendingInvitations,
                    locationCustomization:  state.everou?.locationCustomization,
                    filter:                 state.everou?.filter
                },
            };
            break;
        }

        default:
            break;
    }

    return appReducer(state, action);
}