import { OtherUser } from "./OtherUser";

export function ClockinUser({
    uid,
    workMinutes = null,
    isValid = false,
    user = OtherUser(),
    settings = ClockinUserSettings(),
} = {}) {
    return {
        uid,
        workMinutes,
        isValid,
        user,
        settings,
    };
}

ClockinUser.ClockinUserSettings = ClockinUserSettings;

function ClockinUserSettings({ allowsRemoteClockins, hasFlexibleClockins } = {}) {
    return {
        allowsRemoteClockins,
        hasFlexibleClockins,
    };
}