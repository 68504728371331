import React from "react";
import { useTranslation } from "react-i18next";

import { SimpleButton } from "../_components/SimpleButton";
import { ReservationSlotModel } from "./ReservationSlotModel";
import { TimeHelper } from "../_helpers";
import { ReservationConfig } from "../_models/ReservationConfig";

export function SingleSlot({
    slot = ReservationSlotModel(),
    reservationConfig = ReservationConfig(),

    userUid,
    handleCancelReservation,
    handleViewReservations,
    handleMakeReservation,
    canEditReservations,
    timezone,
    alreadyHasReservation,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return slot.isReservable
        ? singleSlotUi(slot)
        : fillerSlotUi(slot)
    ;

    function fillerSlotUi(slot = ReservationSlotModel()) {
        return (
            <div key={slot.uid}
                className={`
                    d-flex
                    justify-content-between
                    align-items-center

                    my-3
                    px-2
                    py-1

                    border
                    rounded

                    bg-light
                `}
            >
                <div className="d-flex">
                    {hourRange(slot)}
                    <div className="ml-2" />
                    <div>
                        - {t("mod_reservations_slot_out_of_schedule")}
                    </div>
                </div>
            </div>
        );
    }

    function hourRange(slot = ReservationSlotModel()) {
        return (
            <div className={`
                font-weight-bold
            `}>
                {localizedTimeString(slot.utcBegin)}
                {" - "}
                {localizedTimeString(slot.utcEnd)}
            </div>
        );

        function localizedTimeString(utcIsoString) {
            return TimeHelper.localizeIsoStringToFormat(
                utcIsoString,
                timezone,
                TimeHelper.getTimeFormats().TIME_NO_SECONDS,
            );
        }
    }

    function singleSlotUi(slot = ReservationSlotModel()) {
        return (
            <div
                key={slot.uid}
                className="
                    border

                    d-flex
                    justify-content-between
                    align-items-center

                    px-2
                    py-1
                "
            >
                <div>
                    {hourRange(slot)}
                    <div className="ml-3"/>
                    {status(slot.isYours, slot.isAvailable, slot.reservations)}
                </div>

                {button(slot)}
            </div>
        );

        function status(isYours, isAvailable, reservations) {
            return (
                <div className={`
                    c-text-capitalize-first
                    ${style()}
                `}>
                    {text()}
                </div>
            );

            function text() {
                return canEditReservations
                    ? adminText()
                    : guestText()
            }

            function adminText() {
                const numberOfReservations = (reservations) ? reservations.length : 0;

                if (isAvailable)
                    return (reservationConfig.numberOfUsers === 1)
                    ? t("common.available")
                    : t(
                        "mod_bookings_number_of_available_places_pw",
                        { 
                            available: (reservationConfig.numberOfUsers - numberOfReservations),
                            total: reservationConfig.numberOfUsers 
                        },
                    );

                if (numberOfReservations !== 0)
                    return (reservationConfig.numberOfUsers === 1)
                    ? t("reservations.bookedBy") + " " + numberOfReservations
                    : t(
                        "mod_bookings_number_of_booked_places_pw",
                        { 
                            booked: numberOfReservations,
                            total: reservationConfig.numberOfUsers 
                        },
                    );

                return t("reservations.neverBooked");
            }

            function guestText() {
                const numberOfReservations = (reservations) ? reservations.length : 0;

                if (isYours)
                    return t("reservations.yourReservation");

                if (!isAvailable)
                    return t("common.notAvailable");

                if (alreadyHasReservation)
                    return t("reservations.availableAfterCurrentReservationEnds");

                return (reservationConfig.numberOfUsers === 1)
                    ? t("common.available")
                    : t(
                        "mod_bookings_number_of_available_places_pw",
                        { 
                            available: (reservationConfig.numberOfUsers - numberOfReservations),
                            total: reservationConfig.numberOfUsers 
                        },
                    );
            }

            function style() {
                if (isYours)
                    return "c-text-primary";

                return !isAvailable ? "text-danger" : "";
            }
        }

        function button(slot = ReservationSlotModel()) {
            if (slot.hasPassed)
                return (slot.reservations && canEditReservations)
                ? viewButton()
                : null;

            if (slot.isYours && slot.isHappening)
                return null;

            if (slot.isYours)
                return cancelButton();

            if (slot.reservations && canEditReservations) {
                return viewButton();
            }

            if (alreadyHasReservation || !slot.isAvailable)
                return null;

            return bookButton();

            function cancelButton() {
                var yourReservations = slot.reservations.filter(reservation => 
                    reservation.userUid === userUid
                );

                if (yourReservations.length === 0)
                    return null;
                
                var yourReservation = yourReservations[0];
                
                return (
                    <SimpleButton
                        onClick={() => handleCancelReservation(yourReservation.uid)}
                        text={t("common.cancel")}
                        buttonStyle="
                            btn-danger
                            py-1
                            text-capitalize
                        "
                    />
                );
            }

            function viewButton() {
                return (
                    <SimpleButton
                        onClick={() => handleViewReservations(slot)}
                        text={t("mod_bookings_view_reservations_pw")}
                        buttonStyle="
                            c-btn-secondary
                            py-1
                            text-capitalize
                        "
                    />
                );
            }

            function bookButton() {
                if(canEditReservations)
                    return null;

                return (
                    <SimpleButton
                        onClick={() => handleMakeReservation(slot.utcBegin, slot.utcEnd)}
                        text={t("reservations.makeReservation")}
                        buttonStyle="
                            c-btn-primary
                            py-1
                            text-capitalize
                        "
                    />
                );
            }
        }
    }
}