import React from 'react';

import { PopUpModel } from "../../Common/_models/PopUpModel";

export function PopUpRenderer({
    popUp = PopUpModel(),
    getComponentFn = () => {},
} = {}) {

    //VIEW
    if (!popUp.isVisible)
        return null;

    const PopUp = getComponentFn(popUp.componentName);

    if (!PopUp)
        return null;
    
    return <PopUp {...popUp.props} />;
}