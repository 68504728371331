import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { NewEntryFields } from "./NewEntryFields";
import { EntriesActions } from "../_actions/EntriesActions";
import { Product } from "../_models/Product";

export function NewEntry({
    budgetUid,
    products = [] || [Product()],
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [productType, setProductType] = useState(products.length ? products[0].productType : undefined);
    const [amount, setAmount] = useState(1);
    const [price, setPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [name, setName] = useState(getProductName(productType, products));

    useEffect(() => {
        setPrice(getProductPrice(productType, products));
        setName(getProductName(productType, products));
    }, [productType, products]);

    //VIEW
    return (
        <NewEntryFields
            products={products}

            isNameEditable={getIsProductNameEditable(productType, products)}
            name={name}
            setName={setName}

            isPriceEditable={getIsProductPriceEditable(productType, products)}
            price={price}
            setPrice={setPrice}

            productType={productType}
            setProductType={handleSetProductType}

            amount={amount}
            setAmount={setAmount}

            discount={discount}
            setDiscount={setDiscount}

            saveButtonName={t("mod_budgets_add_entry")}
            onSave={handleCreateEntry}
        />
    );

    function getIsProductPriceEditable(productType, products = [] || [Product()]) {
        const product = products.find(product => product.productType === productType);
        return (product && product.isPriceEditable) || false;
    }

    function getIsProductNameEditable(productType, products = [] || [Product()]) {
        const product = products.find(product => product.productType === productType);
        return (product && product.isNameEditable) || false;
    }

    function getProductName(productType, products = [] || [Product()]) {
        const product = products.find(product => product.productType === productType);
        return (product && product.name) || "NO NAME FOUND";
    }

    function getProductPrice(productType, products = [] || [Product()]) {
        const product = products.find(product => product.productType === productType);
        return (product && product.price) || 0;
    }

    //LOCAL HANDLERS
    function handleSetProductType(productType) {
        setProductType(productType);
        setPrice(0);
        setAmount(1);
        setDiscount(0);
    }

    //ACTION HANLDERS
    function handleCreateEntry() {
        if (!budgetUid)
            return;
        
        dispatch(EntriesActions.createEntry({
            budgetUid,
            amount,
            discount,
            productType,
            productPrice: price,
            productName: name,
        }))
    }
}