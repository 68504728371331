import { genericActions } from "./generic.actions";
import { reservationService } from "../_services/reservation.service";
import { ReservationConfigMapper } from "../_mappers/ReservationConfigMapper";
import { RESERVATION_ACTION_TYPES, RESERVATION_SECONDARY_VIEWS } from "../_constants/reservation.constants";
import { ReservationConfig } from "../_models/ReservationConfig";
import { TimeHelper } from "../_helpers";
import { ReservationMapper } from "../_mappers/ReservationMapper";
import { appPermissions } from "../_constants/permissions.constants";
import { Selectors } from "../_reducers/app.reducer";
import { LocationInfo } from "../_models/LocationInfo";
import { ReservationsSecondaryViewStore } from "../_stores/ReservationsSecondaryViewStore";

export const reservationActions = {
    //REMOTE
    getConfigurations,
    saveReservationConfig,
    deleteSelectedReservationConfig,
    getReservations,
    makeReservation,
    cancelReservation,

    //LOCAL
    changeSelected,
    discardChanges,

    selectReservationConfig,
    toggleAllday,
    updateSelectedDate,
    toggleSelectedWeekday,
    toggleExcludedDay,
    updateCapacity,
    updateSelectedScheduleDate,
    selectSlot,
    
    selectNullView,
    selectScheduleView,
    selectSettingsView,
    selectSlotManagementView,

    //exposed for testing
    updateConfigsAndSelected,
    updateSelected,
};

const setSecondaryView = ReservationsSecondaryViewStore.actionSet;

function deleteSelectedReservationConfig() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const configuration = Selectors.getReservationSelected(getState());
            await reservationService.deleteConfiguration(configuration.roomUid);

            dispatch({
                type: RESERVATION_ACTION_TYPES.OVERWRITE_CONFIGURATION,
                configuration: newReservationConfig(
                    configuration.roomUid,
                    configuration.roomName,
                ),
            })
            dispatch(selectNullView());
            dispatch(changeSelected(null));
        }
    };
}

function selectReservationConfig(reservationConfig = ReservationConfig()) {
    return dispatch => {
        let selectViewAction = selectScheduleView;
        let selectConfig = reservationConfig;

        if (!reservationConfig.isSetUp) {
            selectViewAction = selectSettingsView;
            selectConfig = newReservationConfig(
                reservationConfig.roomUid,
                reservationConfig.roomName,
            );
        }

        dispatch(changeSelected(selectConfig));
        dispatch(selectViewAction());
    };
}

function newReservationConfig(roomUid, roomName) {
    return ReservationConfig({
        isSetUp: false,
        isEdited: true,

        roomUid,
        roomName,

        blockMinutes: 60,
        limitMinutes: 60,

        allDay: false,
        begin: null,
        end: null,

        monday: false,
        tuesday: false,
        thursday: false,
        wednesday: false,
        friday: false,
        saturday: false,
        sunday: false,

        numberOfUsers: 1,
    });
}

function toggleSelectedWeekday(weekday) {
    return (dispatch, getState) => {
        dispatch(updateSelected(
            {
                [weekday]: !Selectors.getReservationSelected(getState())[weekday],
            }
        ));
    };
}

function toggleAllday() {
    return (dispatch, getState) => {

        dispatch(reservationActions.updateSelected(
            {
                allDay: !Selectors.getReservationSelected(getState()).allDay,
            }
        ));
    };
}

function toggleExcludedDay(excludedDay) {
    return (dispatch, getState) => {
        let reservationConfig = ReservationConfig();
        reservationConfig = Selectors.getReservationSelected(getState());
        const currentDays = reservationConfig.excludedDays;

        const result = currentDays.includes(excludedDay)
            ? currentDays.filter(day => day !== excludedDay)
            : [...currentDays, excludedDay]
        ;

        dispatch(reservationActions.updateSelected({ excludedDays: result }));
    };
}

function updateCapacity(capacity) {
    return (dispatch, getState) => {

        const minCapacity = 1;
        //const maxCapacity = 100;

        capacity = parseInt(capacity);

        if (isNaN(capacity))
            capacity = minCapacity;
        
        if (capacity < minCapacity)
            capacity = minCapacity;

        //if (capacity > macCapacity)
        //    capacity = maxCapacity;
        
        dispatch(reservationActions.updateSelected(
            {
                numberOfUsers: capacity,
            }
        ));
    };
}

function updateSelectedDate({ begin, end }) {
    return (dispatch, getState) => {
        dispatch(
            updateSelected(
                prepareUpdatedObj(
                    checkMinutes(
                        checkNewValues({
                            begin,
                            end,
                            prevBegin: Selectors.getReservationSelected(getState()).begin,
                            prevEnd: Selectors.getReservationSelected(getState()).end,
                        })
                    )
                )
            )
        );
    };

    function checkNewValues({ begin, end, prevBegin, prevEnd }) {
        return {
            begin: begin ? begin : null,
            end: end ? end : null,
            prevBegin,
            prevEnd,
        };
    }

    function checkMinutes({ begin, end, prevBegin, prevEnd }) {
        return {
            begin: begin || copyMinutes(end, prevBegin),
            end: end || copyMinutes(begin, prevEnd),
        };

        function copyMinutes(origin, destination) {
            if (!destination)
                return null;

            return TimeHelper.copyMinutesToDestination(origin, destination);
        }
    }

    function prepareUpdatedObj({ begin, end }) {
        const updatedObj = {};
        if (begin)
            updatedObj.begin = begin;
    
        if (end)
            updatedObj.end = end;

        return updatedObj;
    }
}

function saveReservationConfig() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(myAsyncFn));

        async function myAsyncFn() {
            const modifiedLocalConfig = Selectors.getReservationSelected(getState());
            const saveToServer = modifiedLocalConfig.isSetUp
                ? updateExistingConfig
                : saveNewConfig
            ;

            const readyLocalConfig = await saveToServer(modifiedLocalConfig);
            readyLocalConfig.isEdited = false;
            dispatch(changeSelected(readyLocalConfig));
            dispatch(updateConfiguration(readyLocalConfig));
            await dispatch(reservationActions.getConfigurations());
        }

        ////
        async function saveNewConfig(localConfig) {
            return ReservationConfigMapper.serverToLocal(
                await reservationService.createReservationConfiguration(localConfig)
            );
        }

        async function updateExistingConfig(localConfig) {
            await reservationService.updateReservationConfig(localConfig);
            return localConfig;
        }

        function updateConfiguration(configuration) {
            return {
                type: RESERVATION_ACTION_TYPES.OVERWRITE_CONFIGURATION,
                configuration,
            };
        }
    };
}

function getConfigurations(locationUid) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            let locationInfo = LocationInfo();
            locationInfo = Selectors.getLocationInfo(getState());

            if (!locationInfo.hasReservations)
                return;

            const serverReservationConfigs = await reservationService.getConfigurations(
                locationUid || Selectors.getSelectedLocationUid(getState()),
            );

            const localReservationConfigs = ReservationConfigMapper.allServerToLocal(
                serverReservationConfigs,
            );

            dispatch(success(localReservationConfigs));
        }
    }

    function success(configurations) {
        return {
            type: RESERVATION_ACTION_TYPES.OVERWRITE_ALL_CONFIGURATIONS,
            configurations,
        };
    }
}

function getReservations(roomUid, dateIsoString) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const timezone = Selectors.getTimezone(getState());
            const serverReservations = await reservationService.getReservations(
                roomUid,
                TimeHelper.getStartOfDayInTimezoneIso(dateIsoString, timezone),
                TimeHelper.getEndOfDayInTimezoneIso(dateIsoString, timezone),
            );
            const localReservations = ReservationMapper.allServerToLocal(serverReservations);
            
            dispatch(success(localReservations));
        }
    };

    function success(reservations) {
        return {
            type: RESERVATION_ACTION_TYPES.OVERWRITE_RESERVATIONS,
            reservations,
        };
    }
}

function makeReservation({ begin, end, roomUid, userUid }) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverResevation = await reservationService.createReservation({
                begin,
                end,
                roomUid,
                userUid,
            });

            const localReservation = ReservationMapper.serverToLocal(serverResevation);
            dispatch(success(localReservation));
            await dispatch(reservationActions.updateConfigsAndSelected())
        }
    };

    function success(reservation) {
        return {
            type: RESERVATION_ACTION_TYPES.ADD_RESERVATION,
            reservation,
        };
    }
}

function updateConfigsAndSelected() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await dispatch(reservationActions.getConfigurations());

            dispatch(changeSelected(
                Selectors.getReservationConfigurations(getState())
                .find(config =>
                    config.roomUid === Selectors.getReservationSelected(getState()).roomUid
                )
            ))
        }
    };
}

function discardChanges() {
    return (dispatch, getState) => {
        let reservationConfigs = [ReservationConfig()];
        reservationConfigs = Selectors.getReservationConfigurations(getState());
        
        let selectedConfig = ReservationConfig();
        selectedConfig = Selectors.getReservationSelected(getState());

        const originalReservation = reservationConfigs.find(config =>
            config.roomUid === selectedConfig.roomUid
        );

        dispatch(changeSelected(originalReservation));
    };
}

function cancelReservation(uid) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await reservationService.deleteReservation(uid);
            dispatch({
                type: RESERVATION_ACTION_TYPES.DELETE_RESERVATION,
                uid,
            });
            await dispatch(reservationActions.updateConfigsAndSelected());
        }
    };
}

function changeSelected(selected) {
    return {
        type: RESERVATION_ACTION_TYPES.SELECT,
        selected,
    };
}

function updateSelected(updatedObj) {
    return {
        type: RESERVATION_ACTION_TYPES.UPDATE_SELECTED,
        selected: {
            ...updatedObj,
            isEdited: true,
        },
    };
}

function updateSelectedScheduleDate(date) {
    return {
        type: RESERVATION_ACTION_TYPES.UPDATE_SELECTED_SCHEDULE_DATE,
        selectedScheduleDate: date
    };
}

function selectSlot(slot) {
    return {
        type: RESERVATION_ACTION_TYPES.UPDATE_SELECTED_SLOT_TIME,
        selectedSlot: slot
    };
}

function selectScheduleView() {
    return setSecondaryView(RESERVATION_SECONDARY_VIEWS.SCHEDULE);
}

function selectSettingsView() {
    return (dispatch, getState) => {
        if (!appPermissions.canUserConfigureReservations(
            Selectors.getPermissionSelectedLocation(getState())
        )) return;

        dispatch(setSecondaryView(RESERVATION_SECONDARY_VIEWS.SETTINGS));
    };
}

function selectSlotManagementView() {
    return (dispatch, getState) => {
        if (!appPermissions.canUserConfigureReservations(
            Selectors.getPermissionSelectedLocation(getState())
        )) return;

        dispatch(setSecondaryView(RESERVATION_SECONDARY_VIEWS.SLOT_MANAGEMENT));
    };
}

function selectNullView() {
    return setSecondaryView(null);
}