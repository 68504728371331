import { genericActions } from "./generic.actions";
import { PaymentMethodCollectionStore } from "../_stores/PaymentMethodsStore/PaymentMethodCollectionStore";
import { PaymentMethodsService } from "../_services/PaymentMethodsService";
import { PaymentMethodMapper } from "../_mappers/PaymentMethodMapper";
import { Selectors } from "../_reducers/app.reducer";

export const PaymentMethodsActions = {
    //REMOTE
    list,
    deleteMethod,
    createSetupIntent,
    confirmSetupIntent,
    
    //STORE
    _listMethodsRequest:    PaymentMethodCollectionStore.actionListItemsRequest,
    _listMethodsSuccess:    PaymentMethodCollectionStore.actionListItemsSuccess,
    _getMethodSuccess:      PaymentMethodCollectionStore.actionGetItemSuccess,
    _deleteLocalMethod:     PaymentMethodCollectionStore.actionDeleteItem,
};

function list() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(PaymentMethodsActions._listMethodsRequest());
            const serverMethods = await PaymentMethodsService.list(
                Selectors.getSelectedLocationUid(getState())
            );
            dispatch(PaymentMethodsActions._listMethodsSuccess(
                PaymentMethodMapper.serverToLocal(serverMethods)
            ));
        }
    };
}

function deleteMethod(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PaymentMethodsService.deleteMethod(uid);
            dispatch(PaymentMethodsActions._deleteLocalMethod(uid));
        };
    };
}

function createSetupIntent(rethrow = false) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            return await PaymentMethodsService.createSetupIntent(
                Selectors.getSelectedLocationUid(getState())
            );
        }
    };
}

function confirmSetupIntent(setupIntentId) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverPaymentMethod = await PaymentMethodsService.confirmSetupIntent(
                setupIntentId,
                Selectors.getSelectedLocationUid(getState()),
            );
            dispatch(PaymentMethodsActions._getMethodSuccess(
                PaymentMethodMapper.serverToLocal(serverPaymentMethod)
            ));
        }
    };
}