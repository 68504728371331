import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { EverouDevelopersActions } from "../_actions/EverouDevelopersActions";
import { BaseAppGlobalPopUp } from "../GlobalPopUps/BaseAppGlobalPopUp";

export function RemoveDeveloperPopUp({
    locationUid,
    uid,
} = {}) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    //ACTION HANDLERS
    const handleRemoveDeveloper = useCallback(() => {
        dispatch(EverouDevelopersActions.remove(locationUid, uid));
    }, [dispatch, locationUid, uid]);
    
    //VIEW
    return <BaseAppGlobalPopUp
        cancelButtonName={t("webapp_global_remove")}
        onCancel={handleRemoveDeveloper}
        acceptButtonName={t("global_cancel")}
        title={t("mod_developers_remove")}
        body={t("global_cannot_undo")}
    />;
}