import { DEVICE_TYPES } from '../_constants';

import thermometerIcon from './icons/device-icon-thermometer.png';
import doorIcon from './icons/device-icon-access@2x.png';
import missingIcon from './icons/device-icon-missing.png';
import plugIcon from './icons/device-icon-plug@3x.png';
import lightIcon from './icons/device-icon-light@3x.png';
import lockIcon from './icons/device-icon-easy-lock@3x.png';
import garageDoorIcon from './icons/device-icon-garage@3x.png';
import blindIcon from './icons/device-icon-blind@3x.png';
import thermostatIcon from './icons/device-icon-thermostat-v2@3x.png';
import brainIcon from './icons/device-icon-brain@3x.png';
import humidityIcon from './icons/device-icon-humedad@3x.png';
import movementIcon from './icons/device-icon-brain-movement@3x.png';
import beaconIcon from './icons/log_icon_beacon.png';
import switchIcon from './icons/icon_easy-access4.png';

export function deviceIconForType(deviceType) {
    switch (deviceType) {
        case DEVICE_TYPES.Climate:
            return thermometerIcon;
        
        case DEVICE_TYPES.EasyAccess:
        case DEVICE_TYPES.EasyAccessContact:
        case DEVICE_TYPES.EasyAccessContactNFC:
        case DEVICE_TYPES.EasyAccessContactNFCWifi:    
        case DEVICE_TYPES.ControlAccess:
        case DEVICE_TYPES.Security:
        case DEVICE_TYPES.Elevator:
        case DEVICE_TYPES.EasyIntegrations:
            return doorIcon;

        case DEVICE_TYPES.Plug:
        case DEVICE_TYPES.Plug30:
        case DEVICE_TYPES.Plug30Security:
        case DEVICE_TYPES.DoublePlug:
        case DEVICE_TYPES.PlugFlood:
        case DEVICE_TYPES.EnergySavingAC:
            return plugIcon;

        case DEVICE_TYPES.Switcher:
        case DEVICE_TYPES.MultiSwitcher:
        case DEVICE_TYPES.DoubleSwitcher:
        case DEVICE_TYPES.Lighting:
        case DEVICE_TYPES.LIGHT_EFR32:
            return lightIcon;

        case DEVICE_TYPES.GowayLock:
            return lockIcon;

        case DEVICE_TYPES.EasyParking:
        case DEVICE_TYPES.EasyParkingWifi:
        case DEVICE_TYPES.EasyParkingBarreras:
        case DEVICE_TYPES.EasyParkingBarrerasNFC:
        case DEVICE_TYPES.GarageDoor:    
            return garageDoorIcon;

        case DEVICE_TYPES.EasyBaliza:
        case DEVICE_TYPES.Clockin:
        case DEVICE_TYPES.ClockinNFCWifi:
            return beaconIcon;

        case DEVICE_TYPES.Roller:
            return blindIcon;

        case DEVICE_TYPES.Thermostat:
        case DEVICE_TYPES.PlugSecurity:
            return thermostatIcon;

        case DEVICE_TYPES.Brain:
            return brainIcon;

        case DEVICE_TYPES.Flood:
            return humidityIcon;

        case DEVICE_TYPES.Movement:
        case DEVICE_TYPES.MovementSafeCooking:
        case DEVICE_TYPES.MovementSafeAccess:
            return movementIcon;

        case DEVICE_TYPES.EasyAccess4:
            return switchIcon;

        default:
            return missingIcon;
    }
}