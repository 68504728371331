import { PMSStore } from "./PMSStore";
import { PMSUser } from "./_models/PMSUser";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { CapsuleButton, LogoHeader } from "../Common/_components";
import { Icons } from "../Common/_assets/icons/Icons";
import { WiredPopUpRenderer } from "../Common/PopUps/WiredPopUpRenderer";
import { WiredUserDropdown } from "../Everou/App/WiredUserDropdown";
import { WiredUiAlerts } from "../Common/UiAlerts/WiredUiAlerts";
import { WiredContentWithSidebar } from "../Common/WiredContent/WiredContentWithSidebar";
import { AppWrapper } from "../Common/_components/AppWrapper";
import { PMSStoreSubscriptions } from "./PMSStoreSubscriptions";
import { PMSModulesActions } from "./PMSModulesActions";
import { PMSUserActions } from "./_actions/PMSUserActions";
import { PMSModules } from "./PMSModules";
import { AppInitializationWrapper } from "../Common/_components/AppInitializationWrapper";
import { RentalPopUps } from "../Everou/GlobalPopUps/AppGlobalPopUpsConfig";


const pmsUser = PMSUser()

export function PMS() {
    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    let user = pmsUser;
    user = useSelector(PMSStore.selectors.getPMSUser);

    //LOCAL STATE
    const [isInitialized, setIsInitialized] = useState(false);

    //INITIALIZATION
    useEffect(() => {
        initialization();
        
        async function initialization() {
            PMSStoreSubscriptions.subscribe();

            dispatch(PMSModulesActions.setUpModules());
            await dispatch(PMSUserActions.getUser());
            setIsInitialized(true);
        }
    }, [dispatch]);

    //VIEW
    return (
        <AppWrapper>
            <WiredPopUpRenderer
                getComponentFn={RentalPopUps.getPopUp}
            />
            <LogoHeader
                content={headerContent()}
                logoSrc={Icons.everouIcon}
            />
            <WiredUiAlerts />
            <AppInitializationWrapper
                isInitialized={isInitialized}
                isSuccessful={!!user}
                failureMessage={t("mod_dev_not_developer_message")}
                content={
                    <WiredContentWithSidebar
                        getComponentForModuleId={PMSModules.getComponentForModuleId}
                        getModulesForIds={PMSModules.getModulesForIds}
                        selectModuleAction={PMSModulesActions.selectModule}
                    />
                }
            />
        </AppWrapper>
    );

    function headerContent() {
        return (
            <>
            {user && <WiredUserDropdown user={user} />}
            </>
        )
    }

}