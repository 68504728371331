import { i18n } from "../Common/_translations";

import { AppModulesFactory } from "../Common/AppModulesFactory";
import { Icons } from "../Everou/_assets";
import { ModuleModel } from "../Everou/_models";
import { GettingStarted } from "./ModuleGettingStarted/GettingStarted";
import { DevLocationsModule } from "./ModuleLocations/DevLocationsModule";
import { DevTokensModule } from "./ModuleTokens/DevTokensModule";
import { DevOauthModule } from "./ModuleOauth/DevOauthModule";

const MODULE_IDS = {
    GETTING_STARTED:    "GETTING_STARTED",
    LOCATIONS:          "LOCATIONS",
    TOKENS:             "TOKENS",
    OAUTH:              "OAUTH",
};

const URLS = {
    GETTING_STARTED:    "/getting-started",
    LOCATIONS:          "/dev-locations",
    TOKENS:             "/dev-tokens",
    OAUTH:              "/dev-oauth",
};

const MODULES = [
    ModuleModel({
        moduleId: MODULE_IDS.GETTING_STARTED,
        icon: Icons.menuBook,
        isEnabled: true,
        uiName: i18n.t("mod_dev_getting_started_title"),
        url: URLS.GETTING_STARTED,
    }),
    ModuleModel({
        moduleId: MODULE_IDS.LOCATIONS,
        icon: Icons.editLocation,
        isEnabled: true,
        uiName: i18n.t("mod_dev_locations_title"),
        url: URLS.LOCATIONS,
    }),
    ModuleModel({
        moduleId: MODULE_IDS.TOKENS,
        icon: Icons.key,
        isEnabled: true,
        uiName: i18n.t("mod_dev_tokens_title"),
        url: URLS.TOKENS,
    }),
    ModuleModel({
        moduleId: MODULE_IDS.OAUTH,
        icon: Icons.people,
        isEnabled: true,
        uiName: i18n.t("mod_dev_oauth_title"),
        url: URLS.OAUTH,
    }),
];

const componentForModuleDictionary = {
    [MODULE_IDS.GETTING_STARTED]:   GettingStarted,
    [MODULE_IDS.LOCATIONS]:         DevLocationsModule,
    [MODULE_IDS.TOKENS]:            DevTokensModule,
    [MODULE_IDS.OAUTH]:             DevOauthModule,
};

function getEnabledModuleIds() {
    return MODULES
        .filter(module => module.isEnabled)
        .map(module => module.moduleId)
    ;
}

export const DevelopersModules = AppModulesFactory.makeModules({
    ALL_MODULES: MODULES,
    getEnabledModuleIds,
    componentForModuleDictionary,
    DEFAULT_MODULE: MODULE_IDS.GETTING_STARTED,
});