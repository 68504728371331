import { ServiceHelper2 } from "../_globalHelpers";
import { BUDGETS_API_URLS } from "../_constants/url.constants";

export const EmployeeService = {
    getEmployee,
};

async function getEmployee() {
    const data = await ServiceHelper2.getRequest(
        BUDGETS_API_URLS.EMPLOYEE,
    );
    
    return {
        uid: data.uid,
        distributor_uid: data.distributor_uid,
    };
}