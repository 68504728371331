import { USER_LOG_TYPES, LOCATION_SHARE_STATUSES } from "../_constants/userLogs.constants";
import { i18n } from "../_translations/i18n";
import { UserLog } from "../_models/UserLog";

export class UserLogsUiHelper {
    static typeServerToUi(userLog = UserLog()) {
        switch (userLog.logType) {
            case USER_LOG_TYPES.EVENT_BEGIN_SHARE_POSITION:
                return i18n.t("mod_positioning_user_log_begin_share_position_pw");

            case USER_LOG_TYPES.EVENT_END_SHARE_POSITION:
                return i18n.t("mod_positioning_user_log_end_share_position_pw");

            case USER_LOG_TYPES.EVENT_LOCATION_ARRIVING:
                return i18n.t(
                    "mod_positioning_user_log_arriving_pw",
                    {
                        location: locationString(userLog),
                    },
                );

            case USER_LOG_TYPES.EVENT_LOCATION_LEAVING:
                return i18n.t(
                    "mod_positioning_user_log_leaving_pw",
                    {
                        location: locationString(userLog),
                    },
                );

            case USER_LOG_TYPES.EVENT_POSITION:
                return i18n.t("mod_positioning_user_log_position_pw");

            case USER_LOG_TYPES.EVENT_STILL_ENTER:
                return i18n.t("mod_positioning_user_log_still_enter_pw");
                
            case USER_LOG_TYPES.EVENT_VEHICLE_ENTER:
                return i18n.t("mod_positioning_user_log_vehicle_enter_pw");
                
            case USER_LOG_TYPES.EVENT_WALK_ENTER:
                return i18n.t("mod_positioning_user_log_walk_enter_pw");

            default:
                return "NOT DEFINED";
        }
        
        function locationString(userLog = UserLog()) {
            return userLog.locationName;
        }
    }

    static stringForShareStatus(shareStatus) {
        switch (shareStatus) {
            case LOCATION_SHARE_STATUSES.NO_SHARING:
                return i18n.t("mod_positioning_status_no_sharing");

            case LOCATION_SHARE_STATUSES.BOTH_SHARE:
                return i18n.t("mod_positioning_status_both_share");

            case LOCATION_SHARE_STATUSES.YOU_SHARE:
                return i18n.t("mod_positioning_status_you_share");

            case LOCATION_SHARE_STATUSES.THEY_SHARE:
                return i18n.t("mod_positioning_status_they_share");

            default:
                return "UNDEFINED_SHARE_STATUS";
        }
    }
}