import { StatusIndicator } from '../../_components/StatusIndicator';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CaptionText, CustomCard } from "../../_components";
import { CircleAvatar } from "../../_components/CircleAvatar";
import { invitationLocalStatusUiValues, invitationPermissionUiValues, INVITATION_PERIODS, LOCAL_INVITATION_STATUS_TO_STATUS_INDICATOR, NULL_MAIL } from "../../_constants";
import { TimeHelper } from '../../_helpers';
import { Invitation } from "../../_models/Invitation";
import { InvitationGroup } from '../../_models/InvitationGroup';
import { Selectors } from "../../_reducers/app.reducer";

export function SingleInvitation({
    invitation = Invitation(),
    handleSelectInvitation = () => {},
    isSelected = false,
}) {

    const { t } = useTranslation()

    const timezone = useSelector(Selectors.getTimezone);
    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    let group = InvitationGroup();
    group = useSelector((state) =>
        Selectors.getInvitationGroupByUid(
            state,
            locationUid,
            invitation.invitationGroupUid,
        ),
    );
    
    return (
        <CustomCard
            data-testid={invitation.email}
            selected={isSelected}
            onClick={handleSelectInvitation}
            key={invitation.uid}
            className="h-100"
            banner={
                invitation.permissionType !== null
                    ? invitationPermissionUiValues[invitation.permissionType]
                    : ""
            }
            banner2={
                invitation.periodType === INVITATION_PERIODS.TEMPORARY
                    ? periodString()
                    : null
            }
        >
            <div className="d-flex position-relative pr-0">
                <CircleAvatar
                    names={[invitation.userName ?? ""]}
                    srcImg={invitation.avatar}
                />
                <div className="c-line-height-1 ml-2 c-mt-05 w-100">
                    <div>
                        <div className="d-flex justify-content-between">
                            {invitation?.userName ? (
                                <div className="mb-1">
                                    {invitation.userName}{" "}
                                </div>
                            ) : invitation?.publicUrl ? (
                                <div className="mb-1">
                                    {t("invitation_public_url")}
                                </div>
                            ) : (
                                <div className="mb-1">
                                    {t("mod_invitations_pending_assignment")}
                                </div>
                            )}
                            <div className="d-flex align-items-center c-h-min">
                                <CaptionText>
                                    {
                                        invitationLocalStatusUiValues[
                                            invitation?.localStatus
                                        ]
                                    }
                                </CaptionText>
                                    <StatusIndicator
                                        className='ml-1'
                                        status={
                                            LOCAL_INVITATION_STATUS_TO_STATUS_INDICATOR[
                                                invitation.localStatus
                                            ]
                                        }
                                    />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <CaptionText>
                                {invitation.email !== NULL_MAIL
                                    ? invitation.email
                                    : invitation.code}
                            </CaptionText>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                        {group?.name ? (
                            <CaptionText className="mb-1">
                                {t("global_group")}: {group?.name}
                            </CaptionText>
                        ) : invitation.roomName ? (
                            <CaptionText className="mb-1">
                                {t("global_room")}: {invitation.roomName}
                            </CaptionText>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            </div>
        </CustomCard>
    );


    function periodString() {
        
        let periodString = TimeHelper.localizeIsoStringToFormat(invitation.begin, timezone, TimeHelper.getTimeFormats().DATE_NO_TIME);

        if (invitation.end) {
            const end = TimeHelper.localizeIsoStringToFormat(invitation.end, timezone, TimeHelper.getTimeFormats().DATE_NO_TIME);
            periodString = `${periodString} - ${end}`;
        } else {
            periodString = `${periodString} - ${t("global_forever")}`;
        }

        return periodString;
    }
}