import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const invitationGroupService = {
    removeInvitationGroup,
    getInvitationGroup,
    getInvitationGroups,
    associatePackToInvitationGroup,
    disassociatePackFromInvitationGroup,
    updateInvitationGroup,
    addUsers,

    createInvitationGroup,
};

function removeInvitationGroup(uid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.INVITATION_GROUP_REMOVE,
        { invitation_group_uid: uid },
    );
}

function getInvitationGroup(invitationGroupUid) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INVITATION_GROUP_INFO,
        "?invitation_group_uid=" + invitationGroupUid,
    );
}

function getInvitationGroups(locationUid) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INVITATION_GROUP_LIST,
        "?loc_uid=" + locationUid,
    );
}

function associatePackToInvitationGroup(cardCode, invitationGroupUid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.INVITATION_GROUP_ASSOCIATE,
        {
            card_code: cardCode,
            invitation_group_uid: invitationGroupUid,
        },
    );
}

function disassociatePackFromInvitationGroup(cardPackUid: string) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.INVITATION_GROUP_DISASSOCIATE,
        {
            card_pack_uid: cardPackUid,
        },
    );
}

function updateInvitationGroup({
    invitationGroupUid,
    name,
    roomUids,

    permissionType,
}) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.INVITATION_GROUP_UPDATE,
        {
            invitation_group_uid: invitationGroupUid,
            name,
            room_uids: roomUids,
            permission_type: permissionType,
        },
    );
}

function createInvitationGroup({
    name,
    cardCode,
    allowsSubinvitations,
    subinvitations,
    duration,
    roomUids,

    locationUid,
    type,
    permissionType,
    groupType,
}) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.INVITATION_GROUP_CREATE,
        {
            name,
            card_code: cardCode,
            allows_subinvitations: allowsSubinvitations,
            subinvitations,
            invitation_duration: duration,
            room_uids: roomUids,

            loc_uid: locationUid,
            type,
            permission_type: permissionType,
            group_type: groupType,
        },
    )
}

function addUsers(groupUid: string, emails: string[] = [], createPublicUrl: boolean, begin: string, end: string) {
    if (begin !== null && end !== null) {
        return ServiceHelper.postRequest(
            URL_CONSTANTS.INVITATION_GROUP_ADD_USERS,
            {
                invitation_group_uid: groupUid,
                emails,
                create_public_url: createPublicUrl,
                begin,
                end
            },
        );
    } else {
        return ServiceHelper.postRequest(
            URL_CONSTANTS.INVITATION_GROUP_ADD_USERS,
            {
                invitation_group_uid: groupUid,
                emails
            },
        );
    }
    
}