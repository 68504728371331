import {
    INVITATION_TYPES,
    INVITATION_PERIODS,
    INVITATIONS_SECONDARY_VIEWS,
    GLOBAL_POPUPS,
} from "../_constants";
import { invitationsService } from "../_services";
import { TimeHelper } from "../_helpers/TimeHelper";
import { uiAlertActions } from "./uiAlert.actions";
import { AuthenticationHelper } from "../_helpers/AuthenticationHelper";
import { EXCEPTIONS } from "../_constants/Exceptions";
import { InvitationsMapper } from "../_mappers";
import { InvitationsHelper } from "../_helpers";
import { LOCATION_PERMISSIONS } from "../_constants/permissions.constants";
import { Invitation, WeekDaysEnum, WeekDaysSlots } from "../_models/Invitation";
import { globalActions } from "./global.actions";
import { i18n } from "../_translations/i18n";
import { genericActions } from "./generic.actions";
import { Selectors } from "../_reducers/app.reducer";
import { PendingInvitationsStore } from "../_stores/PendingInvitationsStore";
import { locationInfoActions } from "./locationInfo.actions";
import { INVITATIONS_ACTION_TYPES } from "../_actionTypes/INVITATIONS_ACTION_TYPES";
import { InvitationsSecondaryViewStore } from "../_stores/InvitationsSecondaryViewStore";
import { MyInvitationsStore } from "../_stores/MyInvitationsStore/MyInvitationsStore";
import { ALLOWED_SUBINVITATIONS } from '../_constants/invitations.constants';
import { BASE_PAGE_SIZE, InvitationQueryParams, PaginatedItems } from "../_modelsTS/Pagination";

export const invitationsActions = {

    //REMOTE
    getMyInvitations,
    listInvitations,
    resendInvitation,
    getSubinvitations,
    validateInvitation,
    acceptInvitation,
    cancelInvitation,
    sendNewInvitation,
    updateServerInvitation,
    deleteInvitation,
    deleteSubinvitation,
    getInvitation,
    deleteInvitationPublicUrl,
    createInvitationPublicUrl,
    mailInvitationPublicUrl,
    setEnabledServer,
    //PRIVATE STORE UPDATES
    //EXPOSED FOR TESTING
    _listInvitationsSuccess,

    //REMOTE PUBLIC PENDING INVITATIONS
    listPending,
    acceptPending,
    cancelPending,

    //PRIVATE PENDING INVITATIONS
    _listPendingSuccess: PendingInvitationsStore.actionListItemsSuccess,
    _deletePendingSuccess: PendingInvitationsStore.actionDeleteItem,


    //LOCAL
    deleteInvitationUi,
    changeSecondaryView: InvitationsSecondaryViewStore.actionSet,
    resetPagination,
    clearValidatedInvitation,

    createLocalSubinvitation,
    createLocalInvitation,
    createLocalAccessLink,
    selectInvitation,
    selectInvitationAccessLink,
    discardLocalChanges,

    changeObjectUid,
    changeInvitationType,
    toggleInvitationActive,
    changeInvitationPermission,
    changeInvitationPeriod,
    toggleWeekDay,
    toggleAllDay,
    changeBeginTime,
    changeEndTime,
    toggleAllowsSubinvitations,
    changeSubinvitations,
    changeOffset,
    changeRooms,
    setEmails,
    changeFilter,
    resetValidation,

    pushInvitationToSelected,
    updateSelectedLocally, // Generic selected invitation modifier
    updateNewLocally,
    updateWeekdayTimeSlotsOnSelected,
    deleteWeekdayFromInvitationOnSelected,
    updateWeekdayTimeSlotsOnNew,
    deleteWeekdayFromInvitationOnNew,

    deleteAllWeekdaysOnNew,
    deleteAllWeekdaysOnSelected,
    deleteInvitationDraft,

    setIsRequesting,

    //Exposed for testing
    getGenericInvitations,
};

function setIsRequesting(status: boolean = true) {
   return {
       type: INVITATIONS_ACTION_TYPES.INVITATIONS_SET_REQUESTING_STATUS,
       status
   } 
}

function deleteAllWeekdaysOnNew() {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_DELETE_ALL_WEEKDAYS_ON_NEW 
    }
}
function deleteAllWeekdaysOnSelected() {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_DELETE_ALL_WEEKDAYS_ON_SELECTED 
    }
}

function deleteInvitationDraft() {
    return {
        type: INVITATIONS_ACTION_TYPES.DELETE_INVITATION_DRAFT
    }
}

function updateWeekdayTimeSlotsOnSelected(weekDays: WeekDaysSlots) {
    return {
        type: INVITATIONS_ACTION_TYPES.CHANGE_WEEKDAY_SLOTS,
        weekDays
    }
}

function deleteWeekdayFromInvitationOnSelected(weekDay: WeekDaysEnum) {
    return {
        type: INVITATIONS_ACTION_TYPES.DELETE_WEEKDAY_TIMESLOT,
        weekDay
    }
}

function updateWeekdayTimeSlotsOnNew(weekDays: WeekDaysSlots) {
    return {
        type: INVITATIONS_ACTION_TYPES.CHANGE_WEEKDAY_SLOTS_ON_NEW,
        weekDays
    }
}

function deleteWeekdayFromInvitationOnNew(weekDay: WeekDaysEnum) {
    return {
        type: INVITATIONS_ACTION_TYPES.DELETE_WEEKDAY_TIMESLOT_ON_NEW,
        weekDay
    }
}

 

function getMyInvitations() {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverInvitations = await invitationsService.getMyInvitations(
                Selectors.getSelectedLocationUid(getState()) as string
            );

            const myInvitations = InvitationsMapper.mapAllServerToLocal(serverInvitations)
            dispatch(
                MyInvitationsStore.actionListItemsSuccess(myInvitations)
            );

            return myInvitations;
        }
    }
}

function deleteInvitationUi(invitation = Invitation(), isSuperguest) {
    return dispatch => {

        isSuperguest
            ? handleDeleteSuperguestInvitation(invitation)
            : handleDeleteInvitation(invitation)
        ;

        function handleDeleteSuperguestInvitation(invitation) {
            dispatch(globalActions.showPopUp(
                GLOBAL_POPUPS.DELETE_SUPERGUEST_INVITATION,
                { invitation },
            ));
        }
    
        function handleDeleteInvitation(invitation) {
            dispatch(globalActions.showPopUp(
                GLOBAL_POPUPS.DELETE_INVITATION,
                { invitation, isSuperguest },
            ));
        }
    }
}

function setEnabledServer(invitationUid, isEnabled) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await invitationsService.updateInvitation({
                uid: invitationUid,
                enabled: isEnabled,
            });

            await dispatch(invitationsActions.getInvitation(invitationUid));
        }
    }
}

function setEmails(emails: string[] = []) {
    return updateNewLocally({ emails });
}


function resetPagination() {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_RESET_PAGINATION  
    }
}

function resendInvitation(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await invitationsService.resendInvitation(uid);
            dispatch(uiAlertActions.success(i18n.t("invitations.invitationSent")));
        }
    };
}

function listInvitations(query: InvitationQueryParams = { offset: 0, page_size: BASE_PAGE_SIZE }) {
    return async (dispatch, getState) => {

        if (!query.loc_uid) {
            query = {
                ...query,
                loc_uid: Selectors.getSelectedLocationUid(getState()) ?? ''
            }
        }

        if(!query.loc_uid) {
            dispatch(_listInvitationsFailure())
        }

        await dispatch(genericActions.genericAsyncAction(
            getInvitations,
            () => { dispatch(_listInvitationsFailure()); dispatch(setIsRequesting(false)); }
        ));


        async function getInvitations() {
                dispatch(setIsRequesting());
                let res = await invitationsService.getInvitationsList({ ...query });
                res.invitations = InvitationsMapper.mapAllServerToLocal(res.invitations);
                dispatch(invitationsActions._listInvitationsSuccess({ items: res.invitations ?? [], offset: query.offset, page_size: query.page_size, total_count: res.total_count ?? 0 }))
                dispatch(setIsRequesting(false));
        }

    };
}


function getSubinvitations() {
    return async dispatch => {
        return await dispatch(getGenericInvitations(
            invitationsService.getSubinvitations,
        ));
    };
}

function getGenericInvitations(invitationsServiceFn) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(
            asyncAction,
            manageError,
        ));

        async function asyncAction() {
            const locationUid = Selectors.getSelectedLocationUid(getState());
            if (locationUid === null)
                throw new Error(EXCEPTIONS.NO_LOCATION_SELECTED);

            const serverInvitations = await invitationsServiceFn(locationUid);
            const localInvitations = InvitationsMapper.mapAllServerToLocal(serverInvitations);
            dispatch(_listInvitationsSuccess({ items: localInvitations }));
        }

        function manageError() {
            dispatch(_listInvitationsFailure());
        }
    };

}

function changeOffset(offset) {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_CHANGE_OFFSET,
        offset
    };
}

function _listInvitationsFailure() {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_LIST_FAILURE,
    };
}

function _listInvitationsSuccess(invitations: Partial<PaginatedItems>) {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_LIST_SUCCESS,
        invitations,
    };
}

function updateSelectedLocally(selectedModified) {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_UPDATE_SELECTED_LOCALLY,
        selectedModified,
    };
}


function updateNewLocally(selectedModified) {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_UPDATE_NEW_DRAFT_LOCALLY,
        selectedModified,
    };
}


function discardLocalChanges() {
    return (dispatch, getState) => {
        dispatch(selectInvitation(Selectors.getInvitationSelected(getState()).uid));
    };
}

function createLocalInvitation() {
    return (dispatch, getState) => {

        const selectedLocationUid = Selectors.getSelectedLocationUid(getState()) ?? '';

        const invitation = Invitation({
            newInvitation: true,
            type: INVITATION_TYPES.LOCATION,
            objectUid: selectedLocationUid
        });
        dispatch(createInvitation(invitation));
    };
}

function createLocalAccessLink() {
    return (dispatch, getState) => {

        const selectedLocationUid = Selectors.getSelectedLocationUid(getState()) ?? '';

        const invitation = Invitation({
            newInvitation: true,
            type: INVITATION_TYPES.LOCATION,
            objectUid: selectedLocationUid,
            createPublicUrl: true
        });
        dispatch(createAccessLink(invitation));
    };
}

function createLocalSubinvitation(mainInvitation = Invitation()) {
    return dispatch => {
        const subinvitation = Invitation({
            objectUid: mainInvitation.objectUid,
            type: mainInvitation.type,

            permissionType: LOCATION_PERMISSIONS.GUEST,
            newInvitation: true,
        });
        dispatch(createInvitation(subinvitation));
        dispatch(
            invitationsActions.changeSecondaryView(
                INVITATIONS_SECONDARY_VIEWS.CREATE_INVITATION,
            )
        );
    };
}

function createInvitation(invitation) {
    return {
        type: INVITATIONS_ACTION_TYPES.CREATE_LOCAL_INVITATION,
        invitation,
    };
}

function createAccessLink(invitation) {
    return {
        type: INVITATIONS_ACTION_TYPES.CREATE_LOCAL_ACCESS_LINK,
        invitation,
    };
}

function clearValidatedInvitation() {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_VALIDATE,
        invitation: null,
    };
}

function validateInvitation(invitationCode) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (invitationCode === "")
                return;

            const serverInvitation = await invitationsService.validateInvitation(invitationCode);
            const localInvitation = InvitationsMapper.mapServerToLocal(serverInvitation);
            dispatch({
                type: INVITATIONS_ACTION_TYPES.INVITATIONS_VALIDATE,
                invitation: localInvitation,
            });

            return true;
        }
    };
}

function acceptInvitation(invitationUid, locationUid) {
    return async dispatch => {
        try {
            await invitationsService.acceptInvitation(invitationUid);
            dispatch(globalActions.setPreselectedLocationUid(locationUid));
        } catch (error: any) {
            dispatch(uiAlertActions.error(error.toString()));
        } finally {
            dispatch(resetValidation());
        }
    };
}

function cancelInvitation(uid) {
    return async dispatch => {
        try {
            await invitationsService.cancelInvitation(uid);
        } catch (error: any) {
            dispatch(uiAlertActions.error(error.toString()));
        } finally {
            dispatch(resetValidation());
        }
    };
}

function resetValidation() {
    return dispatch => {
        dispatch(invitationsActions.clearValidatedInvitation());
        dispatch(globalActions.hidePopUp());
    };
}

function sendNewInvitation(loc_uid, isSuperguest = false) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction, manageError));

        async function asyncAction() {

            const localInvitation = Selectors.getInvitationNewDraft(getState());
            const serverInvitation = InvitationsMapper.mapNewLocalToServer(localInvitation);
            
            validateServerInvitationFields(
                serverInvitation.emails,
                Selectors.getUserEmail(getState()),
            );

            validateServerAccessLinkFields(
                serverInvitation.create_public_url,
                serverInvitation.begin,
                serverInvitation.end
            )
            
            let invitations = await invitationsService.requestInvitations(serverInvitation);
            let mappedInvitations = InvitationsMapper.mapAllServerToLocal(invitations);

            if(!isSuperguest) {
                dispatch(listInvitations({ loc_uid, offset: 0, page_size: 24 }));
            } else {
                dispatch(getSubinvitations());
            }

            dispatch(selectInvitation(null));
            
            if (serverInvitation.create_public_url) {
                dispatch(selectInvitationAccessLink(mappedInvitations[0].publicUrl));                
            }
            await dispatch(getLocationInfoIfLimitedInvitations());
        }

        function manageError(error) {
            dispatch(invitationSendFailure(error.toString()));
        }
    };
    
    function invitationSendFailure(error) {
        return {
            type: INVITATIONS_ACTION_TYPES.INVITATIONS_CREATE_FAILURE,
            error,
        };
    }
}

function getLocationInfoIfLimitedInvitations() {
    return async (dispatch, getState) => {

        if (hasLimitedInvitationsFn())
            await dispatch(locationInfoActions.updateCurrentLocationInvitationConfig());

        function hasLimitedInvitationsFn() {
            return !InvitationsHelper.hasUnlimitedInvitations(
                Selectors.getLocationInfo(getState()),
            );
        }
    };
}

function validateServerInvitationFields(emails, userEmail) {
    AuthenticationHelper.validateEmails(emails);

    emails.forEach(email => {
        if (email === userEmail)
            throw new Error("Can't invite yourself, " + email);
    });
}

function validateServerAccessLinkFields(createPublicUrl, begin, end) {
    if (createPublicUrl && (!!!begin || !!!end)) {
        throw new Error(i18n.t("mod_invitations_error_invalid_date_access_link_creation"));
    }
}

function updateServerInvitation(localInvitation) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction, manageError));

        async function asyncAction() {
            const serverInvitation = InvitationsMapper.mapUpdatedLocalToServer(
                localInvitation,
            );
            await invitationsService.updateInvitation(serverInvitation);
            dispatch(success(localInvitation));
            await dispatch(listInvitations());
            dispatch(selectInvitation(localInvitation.uid));
        }

        function manageError(error) {
            dispatch(failure(error));
        }
    };
    
    function success(localInvitation) {
        return {
            type: INVITATIONS_ACTION_TYPES.INVITATIONS_UPDATE_SUCCESS,
            invitation: localInvitation,
        };
    }

    function failure(error) {
        return {
            type: INVITATIONS_ACTION_TYPES.INVITATIONS_UPDATE_FAILURE,
            error,
        };
    }
}

function deleteInvitationWrapper(invitationUid, callbackAction?) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction, manageError));

        async function asyncAction() {
            await invitationsService.deleteInvitation(invitationUid);
            await dispatch(getLocationInfoIfLimitedInvitations());

            dispatch(success());
            dispatch(invitationsActions.changeSecondaryView(null));
            if(callbackAction) {
                await dispatch(callbackAction);
            }
        }

        function manageError(error) {
            dispatch(failure(error));
        }
    };

    function success() {
        return {
            type: INVITATIONS_ACTION_TYPES.INVITATIONS_DELETE_SUCCESS,
        };
    }

    function failure(error) {
        return {
            type: INVITATIONS_ACTION_TYPES.INVITATIONS_DELETE_FAILURE,
            error,
        };
    }
}

function deleteInvitation(invitationUid, onDelete = () => {}) {
    return async dispatch => {
        return await dispatch(deleteInvitationWrapper(
            invitationUid,
            onDelete
        ));
    }
}

function deleteSubinvitation(invitationUid) {
    return async dispatch => {
        return await dispatch(deleteInvitationWrapper(
            invitationUid,
            invitationsActions.getSubinvitations(),
        ));
    }
}

function selectInvitation(invitationUid) {
    return dispatch => {
        const selectedView = invitationUid
            ? INVITATIONS_SECONDARY_VIEWS.EDIT_INVITATION
            : null
        ;

        dispatch(invitationsActions.changeSecondaryView(selectedView));
        dispatch(selectInvitation(invitationUid));
    }

    function selectInvitation(invitationUid) {
        return {
            type: INVITATIONS_ACTION_TYPES.INVITATIONS_SELECT,
            invitationUid,
        };
    }
}

function selectInvitationAccessLink(publicUrl) {
    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_ACCESS_LINK_SELECT,
        publicUrl,
    };
}


function changeInvitationType(invitationType, isNew = false) {
    return (dispatch, getState) => {
        const selectedModified = { type: invitationType };

        dispatch((isNew ? updateNewLocally : updateSelectedLocally)(selectedModified));

        if (invitationType === INVITATION_TYPES.LOCATION) {
            dispatch(changeObjectUid(Selectors.getSelectedLocationUid(getState()), isNew));
        }
        
        if (invitationType === INVITATION_TYPES.ROOM) {
            dispatch(changeInvitationPermission(LOCATION_PERMISSIONS.GUEST, isNew));
            dispatch(changeObjectUid((Selectors.getRooms(getState())[0] as any).uid, isNew));
        }
    };
}

function changeObjectUid(objectUid, isNew = false) {
    const dispatcher = isNew ? updateNewLocally : updateSelectedLocally;
    return dispatch => {
        dispatch(dispatcher(
            {
                objectUid,
            }
        ));
    };
}

function changeBeginTime(startMoment, isNew = false) {

    const dispatcher = isNew ? updateNewLocally : updateSelectedLocally;

    return dispatch => {
        dispatch(dispatcher({ begin: startMoment }));
    };
}

function changeEndTime(end, isNew = false) {

    const dispatcher = isNew ? updateNewLocally : updateSelectedLocally;

    return dispatch => {
        dispatch(dispatcher({ end, periodType: !!end ? INVITATION_PERIODS.TEMPORARY : INVITATION_PERIODS.ALWAYS }));
    };
}


function toggleAllDay() {
    return (dispatch, getState) => {
        dispatch(updateSelectedLocally({
            allDay: !Selectors.getInvitationSelected(getState()).allDay
        }));
    };
}

function toggleWeekDay(weekDay) {
    return (dispatch, getState) => {
        dispatch(updateSelectedLocally(
            { [weekDay]: !Selectors.getInvitationSelected(getState())[weekDay] }
        ));
    };
}

function changeInvitationPeriod(periodType, isNew = false) {
    return (dispatch, getState) => {

        const selector = isNew ? Selectors.getInvitationNewDraft : Selectors.getInvitationSelected;
        const currentPeriodType = selector(getState()).periodType;

        if (currentPeriodType !== periodType) {
            dispatch((isNew ? updateNewLocally : updateSelectedLocally)({ periodType }));
        }

        if (periodType === INVITATION_PERIODS.TEMPORARY) {
            dispatch(changeBeginTime(TimeHelper.getTodayIsoString(), isNew));
            dispatch(changeEndTime(TimeHelper.getTomorrowIsoString(), isNew));
        }
    };
}

function changeInvitationPermission(permissionType, publicUrl?, isNew = false) {
    return (dispatch, getState) => {
        
        const selector = isNew ? Selectors.getInvitationNewDraft : Selectors.getInvitationSelected;
        const currentAllowsSubinvitations = selector(getState()).allowsSubinvitations;
        
        let selectedModified: any = {
            permissionType,
            allowsSubinvitations: permissionType === LOCATION_PERMISSIONS.GUEST && currentAllowsSubinvitations,
            subinvitations: null,
            end: null
        };

        if (publicUrl) {
            selectedModified = { ...selectedModified, periodType: INVITATION_PERIODS.TEMPORARY }
        }

        if (permissionType === LOCATION_PERMISSIONS.INSTALLER) {
            selectedModified = {
                ...selectedModified,
                periodType: INVITATION_PERIODS.TEMPORARY,
                begin: TimeHelper.getTodayIsoString(),
                end: TimeHelper.createNowPlus3DaysIso(),
                type: INVITATION_TYPES.LOCATION,
                objectUid: Selectors.getSelectedLocationUid(getState())
            }
        }

        if(permissionType === LOCATION_PERMISSIONS.ADMIN) { 
            selectedModified = {
                ...selectedModified,
                periodType: INVITATION_PERIODS.ALWAYS,
                begin: selector(getState()).createdOn,
                end: null,
                type: INVITATION_TYPES.LOCATION,
                objectUid: Selectors.getSelectedLocationUid(getState())
            }
        }


        dispatch((isNew ? updateNewLocally : updateSelectedLocally)(selectedModified));

    };
}

function toggleInvitationActive() {
    return (dispatch, getState) => {
        const enabled = !Selectors.getInvitationSelected(getState()).enabled;
        const selectedModified = {
            enabled,
            localStatus: InvitationsHelper.localStatusFromServerStatus(
                Selectors.getInvitationSelected(getState()).status,
                enabled,
            ),
        };
        dispatch(updateSelectedLocally(selectedModified));
    };
}

function toggleAllowsSubinvitations() {
    return (dispatch, getState) => {
        const allowsSubinvitations = !Selectors.getInvitationNewDraft(getState()).allowsSubinvitations;
        const selectedModified = {
            allowsSubinvitations,
            subinvitations: allowsSubinvitations ? 1 : null,
            periodType: INVITATION_PERIODS.ALWAYS,
            enabled: true,
        };
        dispatch(updateNewLocally(selectedModified));
    };
}

function changeSubinvitations(subinvitations) {
    return dispatch => {
        const minInvitations = ALLOWED_SUBINVITATIONS.MIN;
        const maxInvitations = ALLOWED_SUBINVITATIONS.MAX;

        subinvitations = parseInt(subinvitations);

        if (isNaN(subinvitations))
            subinvitations = minInvitations;
        
        if (subinvitations < minInvitations)
            subinvitations = minInvitations;

        if (subinvitations > maxInvitations)
            subinvitations = maxInvitations;

        dispatch(updateNewLocally({ subinvitations }));
    };
}


function changeFilter(filter) {

    return {
        type: INVITATIONS_ACTION_TYPES.INVITATIONS_CHANGE_FILTER,
        filter
    };
}

function changeRooms(rooms) {
    return dispatch => {
        dispatch(updateSelectedLocally({ rooms }));
    };
}

function listPending() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverPending = await invitationsService.listPending();
            const localPending = InvitationsMapper.mapAllServerToLocal(serverPending);
            dispatch(invitationsActions._listPendingSuccess(localPending));
        }
    };
}

function acceptPending(invitationUid, locationUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await invitationsService.acceptInvitation(invitationUid);
            dispatch(invitationsActions._deletePendingSuccess(invitationUid));
            dispatch(globalActions.setPreselectedLocationUid(locationUid));
        }
    };
}

function cancelPending(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await invitationsService.cancelInvitation(uid);
            dispatch(invitationsActions._deletePendingSuccess(uid));
        }
    };
}

function pushInvitationToSelected(invitationObj) {
    return {
        type: INVITATIONS_ACTION_TYPES.PUSH_FULL_INVITATION_TO_SELECTED,
        selected: invitationObj,
    };
}

function deleteInvitationPublicUrl(invitation) {
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await invitationsService.deleteInvitationPublicUrl(invitation);
            invitation = { ...invitation, publicUrl: null }
            dispatch(pushInvitationToSelected(invitation));
        }
    };
}

function createInvitationPublicUrl(invitation){
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverInvitation =
                await invitationsService.createInvitationPublicUrl(invitation);
            const localInvitation =
                InvitationsMapper.mapServerToLocal(serverInvitation);
            dispatch(pushInvitationToSelected(localInvitation));
        }
    };
}

function mailInvitationPublicUrl(invitation) {
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await invitationsService.mailInvitationPublicUrl(invitation);
        }
    };
}


function getInvitation(invitationUid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (Selectors.getPermissionSelectedLocation(getState()) === LOCATION_PERMISSIONS.OWNER)
                return;

            const serverInvitation = await invitationsService.getInvitation(invitationUid);
            const localInvitation = InvitationsMapper.mapServerToLocal(serverInvitation);
            dispatch(pushInvitationToSelected(localInvitation));
        }
    };
}