import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";
import { invitationTypeUiValues, INVITATION_TYPES } from "../../_constants";
import { SegmentedControl } from "../../../Common/_components";
import { LOCATION_PERMISSIONS } from "../../_constants/permissions.constants";

const TEST_IDS = {
    DROPDOWN: "access-selector-2-dropdown",
};

AccessSelectorComponent2.TEST_IDS = TEST_IDS;

export function AccessSelectorComponent2({
    type,    
    isReadOnly,
    permissionType
}) {
    
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HANDLERS
    function handleChangeInvitationType(type) {
        dispatch(invitationGroupActions.changeInvitationType(type));
    }

    //VIEW
    return (
        <div className={`${isReadOnly ? 'd-flex justify-content-between' : ''}`}>
            <h3 className="text-left">{t("global_access_to")}</h3>
            {isReadOnly
                ? readOnlyData()
                : accessSelector()
            }
        </div>
    );

    function readOnlyData() {
        return (
            <div>
                {invitationTypeUiValues[type]}
            </div>
        );
    }

    function accessSelector() {
        const segments = invitationTypeOptions()
        return <SegmentedControl onSelect={handleChangeInvitationType} readOnly={permissionType === LOCATION_PERMISSIONS.ADMIN} defaultSelected={{ value: type }} testId={TEST_IDS.DROPDOWN} segments={segments} />
    }

    function invitationTypeOptions() {

        let availableInvitationTypes = [
            INVITATION_TYPES.LOCATION
        ];

        if(![LOCATION_PERMISSIONS.ADMIN, LOCATION_PERMISSIONS.INSTALLER].includes(permissionType)) {
            availableInvitationTypes = [...availableInvitationTypes, INVITATION_TYPES.ROOM]
        }

        return availableInvitationTypes.map(element =>
            ({ value: element, label: invitationTypeUiValues[element] })
        );
    }
}
