import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { locationActions } from "../_actions";
import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { LocationInfo } from "../_models/LocationInfo";
import { appPermissions } from "../_constants/permissions.constants";

export function DeleteLocationGlobalPopUp({
    location: locationInfo = LocationInfo(),
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const canDeleteLocation = appPermissions.canUserDeleteLocation(locationInfo.locationPermission);
    const locationName = locationInfo.locationName;

    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={title()}
            body={body()}
            acceptButtonName={acceptButtonName()}
            cancelButtonName={t("common.dismiss")}
            onAccept={handleDeleteLocation}
        />
    );

    function title() {
        const string = canDeleteLocation
            ? "settings.deleteLocationQuestion"
            : "settings.cancelInvitationQuestion"
        ;
        
        return t(string, { location: locationName });
    }

    function body() {
        return canDeleteLocation
            ? t("settings.deleteLocationDescription")
            : t("settings.cancelInvitationDescription")
        ;
    }

    function acceptButtonName() {
        return canDeleteLocation
            ? t("settings.deleteLocation")
            : t("settings.cancelInvitation")
        ;
    }

    //ACTION HANDLERS
    function handleDeleteLocation() {
        dispatch(locationActions.removeSelectedLocation());
    }
}