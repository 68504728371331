import React from 'react';

import { UserManager } from "./UserManager";
import { App } from "./App";

export function WrappedApp() {
    return (
        <UserManager>
            <App />
        </UserManager>
    );
}