import { multiFilter } from "../../Common/_utils/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MainPageWithButtons } from "../../Common/_components";
import { CaptionText } from "../../Common/_components/CaptionText";
import { CustomCard } from "../../Common/_components/CustomCard";
import { CircleAvatar } from "../_components/CircleAvatar";
import { User } from "../_modelsTS/User";
import { Selectors } from "../_reducers/app.reducer";
import { CustomSearchBar } from "../../Common/_components/CustomSearchBar";
import { invitationPermissionUiValues } from "../_constants";
import { locationActions } from "../_actions";

type UserListViewProps = {
    onSelectUser: (user: User) => void;
};

export function UsersListView({ onSelectUser = () => {} }: UserListViewProps) {
    
    const { t } = useTranslation();
    const users: User[] = useSelector(Selectors.getUsers);
    const [filteredUsers, setFilteredUsers] = useState<User[] | null>(null);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    useEffect(() => {setFilteredUsers(users); setSelectedUser(null)}, [users]);

    const dispatch = useDispatch();
     const locationUid = useSelector(Selectors.getInvitationSelectedUid);
    useEffect(() => {dispatch(locationActions.getLocationUsers(locationUid))}, [dispatch, locationUid])

    const content = (
        <div className="py-2 mt-2 c-default-grid">
            {filteredUsers?.map((user) => (
                <CustomCard
                    selected={selectedUser?.uid === user.uid}
                    banner={
                        user.permissionType &&
                        invitationPermissionUiValues[user.permissionType]
                    }
                    onClick={() => {
                        setSelectedUser(user);
                        onSelectUser(user);
                    }}
                    key={user.uid}
                >
                    <div className="d-flex position-relative">
                        <CircleAvatar
                            names={[user.name, user.lastName]}
                            srcImg={user.avatar}
                        ></CircleAvatar>
                        <div className="c-line-height-1 ml-2 c-mt-05 w-100">
                            <div className="font-weight-bold">
                                {user.name} {user.lastName}
                            </div>
                            <div className="d-flex justify-content-between w-100 mt-1">
                                <CaptionText>{user.email}</CaptionText>
                            </div>
                        </div>
                        {user.isNFCCard && <small className="px-2 font-weight-bold c-bd-110 c-text-110" style={{paddingTop: '3px', lineHeight: '9px',paddingBottom: '1px', position: 'absolute', top: '-12px', right: '-4px', fontSize: '9px', borderRadius: '9999px'}}>NFC</small>}
                    </div>
                </CustomCard>
            ))}
        </div>
    );

    function onFilter({ target: { value: text } }) {
        if (!text) setFilteredUsers(users);
        setFilteredUsers(
            multiFilter<User>(
                users,
                [
                    "name",
                    "email",
                    "clockinLegalId",
                    "clocingAdditonalInfo",
                    "lastName",
                ],
                text,
            ),
        );
    }

    return (
        <MainPageWithButtons
            pageTitle={t("global_users")}
            size={"LARGE"}
            content={content}
            buttons={[
                <CustomSearchBar
                    disabled={!users?.length}
                    onChange={onFilter}
                    placeholder={t('mod_invitations_search_placeholder')}
                />,
            ]}
        />
    );
}
