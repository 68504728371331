export class WidgetGroupMapper {

    static serverToLocal(serverGroup) {
        return {
            id: serverGroup.uid,
            name: serverGroup.name,
            order: serverGroup.order,
        };
    }

}