import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { deviceFilterModes } from "../../Everou/_constants";
import { PMSDevicesColumn } from "./_components/DevicesColumn";

export function PMSLocationDevices() {
    
    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // GLOBAL STATE
    const devices = useSelector(Selectors.getPMSLocationDevices);
    const rooms = useSelector(Selectors.getPMSLocationRooms);

    // ACTION HANDLERS


    // INITIALIZATION
    return (
        <PMSDevicesColumn
            filterModeParameter={deviceFilterModes.ROOMS}
            activeCategories={rooms}
            devices={devices}
            canViewSettings={false}
        />
    );
    
}