import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { subscriptionsActions } from "../_actions/subscriptions.actions";
import { MainPageWithButtons } from "../_components";
import { InputCodeValidation } from "../_components/InputCodeValidation";

export function SubscriptionsAddPage() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    return <MainPageWithButtons
        size={'SMALL'}
        pageTitle={t("subscriptions.addSubscription")}
        content={content()}
    />;

    function content() {
        return (
            <div>
                <InputCodeValidation
                    placeholder={t("common.code")}
                    text={t("common.code")}
                    handleValidateCode={handleValidateCode}
                />
                <hr className="m-0"/>
            </div>
        );
    }

    //ACTION HANDLERS
    function handleValidateCode(code) {
        dispatch(subscriptionsActions.validateCode(code));
    }
}