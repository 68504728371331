import { Invitation } from "./Invitation";
import { CardPack } from "../_models/CardPack";

export function InvitationGroup({
    id,
    uid,
    cardPacks = [] || [CardPack()],
    name,
    invitation = Invitation(),
    duration,
    groupType,
    invitationNetPrice,
} = {}) {
    return {
        id,
        uid,
        cardPacks,
        name,
        invitation,
        duration,
        groupType,
        invitationNetPrice,
    };
}