import { useTranslation } from "react-i18next";
import { IntegrationProps } from "./IntegrationProps";
import { IntegrationInput } from "../components/IntegrationInput";
import { useCallback, useEffect, useState } from "react";
import {
    EmptyAvantioIntegration,
    LocalEditableIntegration,
} from "../../_modelsTS/Integration";
import { CapsuleButton, MainPageWithButtons } from "../../_components";
import { removeFromArr } from "../../../Common/_utils/utils";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { integrationsActions } from "../../_actions/integrations.actions";
import { Selectors } from "../../_reducers/app.reducer";

export function AvantioIntegration({
    integrations,
    groups,
}: IntegrationProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const serverIntegrations = useSelector(Selectors.getIntegrations);
    const serverEditableIntegrations = serverIntegrations.map(
        (integration) => ({ ...integration, editable: false }),
    );

    const [integrationsLocal, setIntegrationsLocal] =
        useState<LocalEditableIntegration[]>(integrations);

    const locationUid = useSelector(Selectors.getSelectedLocationUid) ?? "";

    useEffect(() => {
        if (!integrations) return;
        let updatedIntegrations = integrations;
        if (integrations.length < integrationsLocal.length) {
            const lastIntegration =
                integrationsLocal[integrationsLocal.length - 1];
            if (!lastIntegration.uid) {
                updatedIntegrations = [...updatedIntegrations, lastIntegration];
            }
        }
        setIntegrationsLocal(updatedIntegrations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations]);

    const canAddOnMore = useMemo(
        () =>
            integrationsLocal.length === 0 ||
            !!integrationsLocal[integrationsLocal.length - 1].uid,
        [integrationsLocal],
    );

    const addIntegration = useCallback(() => {
        if (canAddOnMore) {
            setIntegrationsLocal([
                ...integrationsLocal,
                EmptyAvantioIntegration,
            ]);
        }
    }, [canAddOnMore, integrationsLocal]);

    const onDeleteIntegration = (index: number) => {
        const integration = integrationsLocal[index];
        if (!integration.uid) {
            setIntegrationsLocal(
                removeFromArr<LocalEditableIntegration>(index, integrations),
            );
        } else {
            if (!locationUid) return;
            dispatch(
                integrationsActions.deleteIntegration(
                    locationUid,
                    integration.uid,
                ),
            );
        }
    };

    const toggleEditable = (index: number) => {
        const updatedIntegrations = integrationsLocal.map(
            (integration, maxIndex) =>
                index === maxIndex
                    ? { ...integration, editable: !integration.editable }
                    : integration,
        );
        setIntegrationsLocal(updatedIntegrations);
    };

    const onSetInitialStatus = (index: number) => {
        const updatedIntegrations = integrationsLocal.map(
            (integration, maxIndex) =>
                index === maxIndex
                    ? serverEditableIntegrations[index]
                    : integration,
        );
        setIntegrationsLocal(updatedIntegrations);
    };

    const body = (
        <div className="c-grid c-grid--small rounded-bottom">
            {integrationsLocal.map((integration, index) => (
                <IntegrationInput
                    key={index}
                    groups={groups}
                    onSetInitialStatus={(index) => onSetInitialStatus(index)}
                    onDelete={(index) => onDeleteIntegration(index)}
                    index={index}
                    onToggleEditable={(index) => toggleEditable(index)}
                    linkMode="ical"
                    integration={integration}
                />
            ))}
        </div>
    );

    return (
        <MainPageWithButtons
            //dataTestId={testIDs.root}
            pageTitle={t("mod_integrations_avantio_title")}
            content={body}
            buttons={[
                <CapsuleButton
                    isEnabled={canAddOnMore}
                    onClick={addIntegration}
                    text={t("global_add")}
                    size="SMALL"
                />,
            ]}
        />
    );
}
