import { DEVICE_TYPES } from "../_constants";
import { Device } from "../_models/Device";
import { i18n } from "../_translations/i18n";
import { DeviceHelper } from "./DeviceHelper";
import { AppAlert } from "../_models/AppAlert";
import { DeviceFunctionality } from "./DeviceFunctionality";

export class AlertsHelper {
    static getAlertsFromDevices(devices) {
        return [
            ...getLowBatteryAlertsFromDevices(devices),
            ...getUpdateAlertsFromDevices(devices),
            ...getConnectionLostAlertsFromDevices(devices),
            ...getSubscriptionsAlerts(devices),
            ...getNFCListUpdateAlerts(devices),
            ...getNotCalibratedAlerts(devices),
        ];  
    }
}

function getLowBatteryAlertsFromDevices(devices = [] || [Device()]) {
    return devices
        .filter(device =>
            device.battery !== null
            && isDeviceBatteryCritical(device)
        )
        .map(device =>
            buildAlertObject(
                `${device.battery}% - Battery of device ${device.description} is very low`,
                i18n.t("alerts_type_battery_description"),
            )
        )
    ;

    function isDeviceBatteryCritical(device = Device()){
        return device.battery < 20;
    }
}

function getUpdateAlertsFromDevices(devices = [] || [Device()]) {
    return devices
        .filter(device => device.update || device.updateVersion)
        .map(device => 
            buildAlertObject(
                i18n.t("alerts.updateAvailableMessage", { device: device.description }),
                i18n.t("alerts_type_update_description"),
            )
        )
    ;
}

function getConnectionLostAlertsFromDevices(devices = [] || [Device()]) {
    return devices
        .filter(device => {
            const isThereBrain = devices.some(device => device.type === DEVICE_TYPES.Brain || device.type === DEVICE_TYPES.EasyIntegrations);
            return DeviceHelper.isDeviceInfoObsolete(device, isThereBrain);
        })
        .map(device => 
            buildAlertObject(
                i18n.t("alerts.connectionLostMessage", { device: device.description }),
                i18n.t("alerts_type_connection_description"),
            )
        )
    ;
}

function getSubscriptionsAlerts(devices = [] || [Device()]) {
    return devices
        .filter(device =>
            DeviceFunctionality.supportsRemainingUses(device.type)
            && device.accessUsageNotification
        )
        .map(device => 
            buildAlertObject(
                i18n.t("subscriptions.runningOutOfOpeningsFor") + device.description,
                i18n.t("alerts_type_low_automatic_opennings_description"),
            )
        )
    ;
}

function getNFCListUpdateAlerts(devices = [] || [Device()]) {
    return devices
        .filter(device =>
            DeviceFunctionality.supportsNFCListUpdate(device.type)
            && !device.isNfcCardsSynchronized
        )
        .map(device =>
            buildAlertObject(
                i18n.t(
                    "alert_unsynchronized_nfc_list_device_pw",
                    { device: device.description },
                ),
                i18n.t("alerts_type_unsynchronized_nfc_list"),
            )
        )
    ;
}

function getNotCalibratedAlerts(devices = [] || [Device()]) {
    return devices
        .filter(device =>
            DeviceFunctionality.supportsCalibrationAlert(device.type)
            && !device.isCalibrated
        )
        .map(device =>
            buildAlertObject(
                i18n.t(
                    "alert_calibration_pw",
                    { device: device.description },
                ),
                i18n.t("alerts_type_calibration"),
            )
        )
    ;
}

function buildAlertObject(message, type) {
    return AppAlert({
        type,
        message,
    });
}