export const BASE_PAGE_SIZE = 24;

export interface LocationQueryParam {
    loc_uid?: string | null;
}

export interface PaginationQueryParams {
    page_size?: number;
    offset?: number; // Means page, not item
    total_count?: number;
}


export interface PaginatedItems extends Partial<PaginationQueryParams> {
    items: any[];
}

export interface InvitationFilter {
    permission_type?: number[]; // To be typed asap with enum
    status?: number[]; // Idemd-flex justify-content-between
    search_term?: string;
}
export interface InvitationQueryParams extends InvitationFilter, LocationQueryParam, PaginationQueryParams {
    email?: string;
    group_uid?: string;
    loc_uid?: string;
}