import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icons } from "../_assets";
import { ListedGroup } from "../_components/ListedGroup";
import { SimpleToggle } from "../_components";
import { CircleAvatar } from "../_components/CircleAvatar";
import { TimeHelper } from "../_helpers";
import { ClockinUser } from "../_models/ClockinUser";

const OPTIMIZING_OFFSET = 500;

type UserColumnProps = {
    users?: any[];
    handleSelectUser?: (...args: any) => any | void;
    selectedUid?: string;
    handleToggleAllowRemoteAccess?: (...args: any) => any | void;
    handleToggleFlexibleClockins?: (...args: any) => any | void;
};

export function UsersColumn({
    users = [] || [ClockinUser()],
    handleSelectUser,
    selectedUid,
    handleToggleAllowRemoteAccess,
    handleToggleFlexibleClockins,
}: UserColumnProps) {
    const optimize = users?.length > OPTIMIZING_OFFSET;
    //VIEW
    return (
        <ListedGroup
            elements={users}
            itemUi={userUi}
            optimize={optimize}
        />
    );

    function userUi(user, index) {
        return (
            <SingleUserUi
                clockinUser={user}
                key={index}
                extraClasses={
                    index !== 0 && users.length > OPTIMIZING_OFFSET
                        ? "border-top"
                        : ""
                }
                selectedUid={selectedUid}
                handleSelectUser={handleSelectUser}
                handleToggleAllowRemoteAccess={handleToggleAllowRemoteAccess}
                handleToggleFlexibleClockins={handleToggleFlexibleClockins}
            />
        );
    }
}

function SingleUserUi({
    clockinUser = ClockinUser(),
    handleSelectUser = (uid) => {},
    selectedUid,
    extraClasses,
    handleToggleAllowRemoteAccess = (clockinUser) => {},
    handleToggleFlexibleClockins = (clockinUser) => {},
}) {
    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    function toggleSettings() {
        setIsSettingsVisible((isVisible) => !isVisible);
    }

    const user = clockinUser.user;
    const workTimeUi =
        TimeHelper.minutesToTimeString(clockinUser.workMinutes) || "0h";
    const changeRemoteAccess = () => handleToggleAllowRemoteAccess(clockinUser);
    const changeFlexibleClockins = () =>
        handleToggleFlexibleClockins(clockinUser);

    return (
        <div
            onClick={() => handleSelectUser(user.uid)}
            className={`
                d-flex
                py-1
                pl-2
                pr-1
                align-items-center
                cp
                ${
                    user.uid === selectedUid
                        ? "c-bg-primary-light-2"
                        : "c-hover-240"
                }
                ${extraClasses}
            `}
            title={user.email}
        >
            {userDataSection()}
            <div className={`'ml-2'}`}></div>
        </div>
    );

    function userDataSection() {
        let name = user.name;
        if (name === undefined || name === "") {
            name = "-";
        } else {
            if (user.lastName) {
                name += " " + user.lastName;
            }
        }
        return (
            <div className="w-100 d-flex no-gutters">
                <div>
                    <CircleAvatar srcImg={user.avatar} names={[user.name, user.lastName]} />
                </div>
                <div className="ml-2" />
                <div className="col justify-content-center d-flex flex-column overflow-hidden">
                    <div className="d-flex align-items-center justify-content-between overflow-hidden">
                        <div className="display-block text-truncate mr-2">
                            <span style={{ fontSize: "90%" }}>{name}</span>
                            <br />
                            <span>{user.email}</span>
                        </div>
                        <div className="d-flex flex-shrink-0">
                            {timeData()}
                            {optionsArrow()}
                        </div>
                    </div>
                    {settingsSection()}
                </div>
            </div>
        );
    }

    function timeData() {
        return (
            <div className="my-auto text-right">
                {!clockinUser.isValid && (
                    <div className="text-danger">
                        {t("mod_clockins_invalid")}
                    </div>
                )}
                <div className="text-nowrap">{workTimeUi}</div>
            </div>
        );
    }

    function optionsArrow() {
        return (
            <img
                alt="arrow img"
                className="c-img-1-5rem my-auto"
                src={isSettingsVisible ? Icons.arrowUp : Icons.arrowDown}
                onClick={(e) => {
                    e.stopPropagation();
                    toggleSettings();
                }}
            />
        );
    }

    function settingsSection() {
        if (!isSettingsVisible) return null;

        return (
            <div>
                <div
                    className="
                    d-flex
                    align-items-center
                    py-1
                    border-top
                "
                    onClick={(e) => e.stopPropagation()}
                >
                    <SimpleToggle
                        checked={clockinUser.settings.allowsRemoteClockins}
                        size="X_SMALL"
                        handleChange={changeRemoteAccess}
                    />
                    <div className="ml-1" />
                    <div className="text-secondary c-line-height-1">
                        {t("mod_clockins_remote_clockins")}
                    </div>
                </div>
                <div
                    className="
                    d-flex
                    align-items-center
                    py-1
                    border-top
                "
                    onClick={(e) => e.stopPropagation()}
                >
                    <SimpleToggle
                        checked={clockinUser.settings.hasFlexibleClockins}
                        size="X_SMALL"
                        handleChange={changeFlexibleClockins}
                    />
                    <div className="ml-1" />
                    <div className="text-secondary c-line-height-1">
                        {t("mod_clockins_flexible_clockins")}
                    </div>
                </div>
            </div>
        );
    }
}
