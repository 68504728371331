import { GenericFilterStoreFactory } from '../../Common/_generics/GenericFilterStoreFactory';
import { GENERIC_IDS } from '../_constants/generics.constants';
import { filterDisabled } from '../_constants';

const initialState = {
    user: filterDisabled.number,
    type: filterDisabled.number,
    status: filterDisabled.number,
};

export const DevicesLogsFiltersStore = GenericFilterStoreFactory(GENERIC_IDS.DEVICE_LOGS, initialState);