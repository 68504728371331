import { Budget } from "../_models/Budget";
import { ClientMapper } from "./ClientMapper";
import { EntryMapper } from "./EntryMapper";

export class BudgetMapper {
    static allServerToLocal(serverBudgets = []) {
        return serverBudgets.map(this.serverToLocal);
    }

    static serverToLocal(serverBudget) {
        return Budget(
            {
                uid:                serverBudget.uid,
                ref:                serverBudget.ref,
                date:               serverBudget.date,

                client:             serverBudget.client ? ClientMapper.serverToLocal(serverBudget.client) : null,
                entries:            serverBudget.entries ? EntryMapper.allServerToLocal(serverBudget.entries) : null,
                totalGrossPrice:    serverBudget.total_gross_price,
                totalVat:           serverBudget.total_vat,
                totalNetPrice:      serverBudget.total_net_price,

                notes:              serverBudget.notes,
                status:             serverBudget.status,
                vatApplied:         serverBudget.vat_applied,
            }
        );
    }
}