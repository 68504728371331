import { PMSReservation, PMSServerReservation } from "../_models/PMSIntegration";

export class PMSReservationMapper {
    static allServerToLocal(serverIntegrations: PMSServerReservation[]) {
        return serverIntegrations.map(this.serverToLocal);
    }

    static serverToLocal({ 
        status, 
        reservation_end, 
        reservation_id, 
        reservation_start, 
        email, 
        uid, 
        created_on, 
        modified_on, 
        id, 
        integration,
        url
    }: PMSServerReservation): PMSReservation {
        return { 
            id, 
            integration, 
            status, 
            uid, 
            email, 
            reservationEnd: reservation_end, 
            reservationStart: reservation_start, 
            reservationId: reservation_id, 
            createdOn: created_on, 
            modifiedOn: modified_on,
            url: url
        };
    }

}