import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const technicalServiceService = {
    requestSatInvitation,
};

async function requestSatInvitation(locationUid) {
    return await ServiceHelper.postRequest(
        URL_CONSTANTS.INVITATION_SAT,
        {
            loc_uid: locationUid,
        },
    );
}