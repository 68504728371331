export function Friend({
    uid,
    email,
    avatar,
    shareFrom,
    shareDest,
} = {}) {
    return {
        uid,
        email,
        avatar,
        shareFrom,
        shareDest,
    };
}