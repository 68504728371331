import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Title } from "../_components/Title";
import { globalActions } from "../_actions";
import { CapsuleButton } from "../_components/CapsuleButton";
import { EmptyGlobalPopUp } from "../_components";

const testIDs = {
    root: "generic-confirmation-pop-up",
    button: "generic-confirmation-pop-up-button",
};

BaseNotificationGlobalPopUp.testIDs = testIDs;

export function BaseNotificationGlobalPopUp({
    title,
    body,
    onOk
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    return (
        <EmptyGlobalPopUp content={content()} />
    );

    function content() {
        return (
            <div
                data-testid={testIDs.root}
                className="d-flex flex-column"
            >   
                {title && <Title className="text-center w-100 mb-1" text={title} />}
                <hr className="w-100 m-0"/>
                {bodyFn()}
                <hr className="w-100 m-0"/>
                <div className="mt-3"/>
                <div className="
                    d-flex
                    justify-content-center
                ">
                    {okButton()}
                </div>
            </div>
        );
    }

    function bodyFn() {
        return (
            <div className="
                py-2
                text-center
                text-break
            ">
                {body}
            </div>
        );
    }

    function okButton() {
        return (
            <CapsuleButton
                testId={testIDs.button}
                text={t("global_ok")}
                onClick={handleOk}
            />
        );
    }

        //ACTION HANDLERS
        async function handleOk() {
            if(onOk){
                onOk();
            }
            dispatch(globalActions.hidePopUp());
        }
}
