import { URL_CONSTANTS } from "../_constants";
import { ServiceHelper } from "./ServiceHelper";

export const OrdersService = {
    listOrders
};

async function listOrders<T>(locationUid): Promise<T> {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.ORDERS,
        { loc_uid: locationUid },
    );
}
