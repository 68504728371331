import { ColumnButton } from '../../_components';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    invitationLocalStatusUiValues, invitationPermissionUiValues, LOCAL_INVITATION_STATUS
} from '../../_constants';
import { LOCATION_PERMISSIONS } from '../../_constants/permissions.constants';
import { InvitationsHelper } from '../../_helpers';
import { InvitationFilter } from '../../_modelsTS/Pagination';

type InvitationsFiltersProps = {
    onChangeFilters: (...args: any) => void;
    style?: CSSProperties;
    className?: string;
    filter: InvitationFilter
}


export function InvitationsFilters({
    onChangeFilters,
    filter,
    className = ''
}: InvitationsFiltersProps) {
    
    //LIBRARIES
    const { t } = useTranslation();
    
    const [permissions, setPermissions] = useState<any>([]);
    const [status, setStatus] = useState<any>([]);

    useEffect(() => { 
        if(filter?.status) {
            setStatus(filter.status); 
        } 
        if(filter?.permission_type) {
            setPermissions(filter.permission_type); 
        }
    }, [filter])

    const togglePermission = (permissionOption: string | number) => {
        let updatedPermission = permissions;
        if (permissions?.includes(permissionOption)) {
            updatedPermission = updatedPermission.filter(perm => perm !== permissionOption);
        } else {
            updatedPermission = [...updatedPermission, permissionOption];
        }
    
        setPermissions(updatedPermission);
        const newFilter = {
            ...filter,
            permission_type: updatedPermission,
        };
        onChangeFilters(newFilter);
    }

    const toggleStatus = (statusOption: string | number) => {
        let updatedStatus = status;
        if (status?.includes(statusOption)) {
            updatedStatus = updatedStatus.filter(stat => stat !== statusOption);
        } else {
            updatedStatus = [...updatedStatus, statusOption];
        }

        setStatus(updatedStatus);
        const newFilter = {
            ...filter,
            status: updatedStatus,
        };
        onChangeFilters(newFilter);
    };

    //VIEW
    return (
        <div
            className={`${className} c-h-min w-100 c-z-index-100 py-2`}
        >
            <h3 className='ml-1'>{t("common.permission")}</h3>
            <div className="overflow-auto
                c-rounded-big
                c-bg-grey-250
                c-bd-220">
                {buildPermissionsOptions().map((option) => (
                    <ColumnButton
                        key={option.label}
                        active={permissions.includes(option.value)}
                        name={option.label}
                        handleClick={() => togglePermission(option.value)}
                    />
                ))}
            </div>
            <h3 className="mt-4 ml-1">{t("common.status")}</h3>
            <div className="overflow-auto
                c-rounded-big
                c-bg-grey-250
                c-bd-220">
                {buildStatusOptions().map((option) => (
                    <ColumnButton
                        key={option.label}
                        active={status.includes(option.value)}
                        name={option.label}
                        handleClick={() => toggleStatus(option.value)}
                    />
                ))}
            </div>
        </div>
    );

    

        function buildPermissionsOptions() {

            const availablePermissions = [
                LOCATION_PERMISSIONS.ADMIN,
                LOCATION_PERMISSIONS.INSTALLER,
                LOCATION_PERMISSIONS.GUEST,
            ];

            return availablePermissions.map(permission => ({ label: invitationPermissionUiValues[permission], value: permission }))
            
        }
        
        function buildStatusOptions() {
            return statusOptions().map(localStatus => ({label: invitationLocalStatusUiValues[localStatus], value: InvitationsHelper.localStatusToQueryFilter(localStatus) }))
            function statusOptions() {
                return Object.keys(LOCAL_INVITATION_STATUS).filter(
                    (status) =>
                        LOCAL_INVITATION_STATUS[status] !==
                        LOCAL_INVITATION_STATUS.UNDEFINED,
                );
            }
        }


    }    
