import React from 'react';

export function SimpleCapsuleButton({
    children,
    onClick = () => {},
}  = {}) {

    //VIEW
    return (
        <div
            onClick={onClick}
            className="
                d-flex
                justify-content-center
                align-items-center

                bg-white

                border rounded-pill

                py-1

                c-hover-220
                c-cursor-default
            "
        >
            {children}
        </div>
    );
}