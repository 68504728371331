import { useEffect, useCallback, useState, useRef } from "react";
import { CapsuleButton, ContentSpace, MainPageWithButtons } from "../../Everou/_components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../Everou/_reducers/app.reducer";
import { PMSUserActions } from "../_actions/PMSUserActions";
import { PMSChatFilter } from "./PMSChatFilter";
import { Icons } from "../../Everou/_assets";

export function PMSChatModule() {

    // INITIALIZATION
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // GLOBAL STATE
    const chatFilter = useSelector(Selectors.getPMSChatFilter);
    const isRequesting = useSelector(Selectors.getPMSChatIsRequesting);

    const chatMessages = useSelector(Selectors.getPMSChatMessages);

    const user = useSelector(Selectors.getPMSUser);

    const [message, setMessage] = useState("");
    const [reservationUid, setReservationUid] = useState("");

    const handleSendMessage = useCallback(() => {
        dispatch(PMSUserActions.postChat(reservationUid, message));
    }, [dispatch, message, reservationUid]);

    const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSUserActions.getReservations());
    }, [dispatch]);

    useEffect(() => {
        scrollToBottom();
    }, [chatMessages]);

    const onChangeFilters = useCallback((filters) => {
        dispatch(PMSUserActions.changeChatFilter(filters));
        setReservationUid(filters.filter[0]);
        if (filters.filter[0]) {
            dispatch(PMSUserActions.getChat(filters.filter[0]));
        }
    }, [dispatch, setReservationUid]);

    const handleInfo = useCallback(() => {

    }, []);

    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("mod_pms_chat")}
                    size={"X_LARGE"}
                    content={
                        content()
                    }
                    buttons={buttons()}
                />
            }
        />
    )

    function content() {
        return(
            <div className="c-filtered-layout pl-1">
                <PMSChatFilter
                    onChangeFilter={(filter) => {
                        onChangeFilters({ ...chatFilter, ...filter });
                    }}
                    className="c-sticky-top"
                    />
                { isRequesting ? spinner : list() }

            </div>
        );
    }

    function list() {
        if (!!!chatMessages || chatMessages.length == 0) {
            return (
                <span className="
                    w-100
                    pt-2
                    text-center
                ">
                    {t('mod_pms_chat_messages_not_yet')}
                </span>
            )
        }

        return (
            <div>
                {chatMessages?.map(( message, index ) =>
                    <div>
                        {chat(message)}
                    </div>
                )}
                {footer()} 
            </div>
        )
    }

    function spinner() {
        return (
            <div className="
                w-100
                pt-2
                text-center
            ">
                <div className="spinner-border c-text-primary"/>
            </div>
        );
    }

    function chat(message) {
        return (
            <div className="pb-4">
				<div>
					<div className="text-muted small text-nowrap mt-2">{message.date}</div>
				</div>
                
                {user.email === message.email ? 
                (
                    <div className="flex-shrink-1 c-bg-grey-250 rounded py-2 px-3 ml-3">
                        <div className="font-weight-bold mb-1">{message.email}</div>
                            {message.message}
                        </div>
                ) :
                (
                    <div className="flex-shrink-1 c-bg-primary-light-2 rounded py-2 px-3 ml-3">
                        <div className="font-weight-bold mb-1">{message.email}</div>
                            {message.message}
                        </div>
                )}
            </div>
        );
    }

    function footer() {
        return (
            <div className="flex-grow-0 py-3 px-4 border-top" ref={messagesEndRef}>
			    <div className="input-group">
					<input 
                        onChange={e => setMessage(e.target.value)}
                        type="text" 
                        maxLength={512}
                        className="form-control"
                        placeholder={t('mod_pms_chat_message_placeholder')}/>
					<button 
                        onClick={handleSendMessage}
                        className="btn btn-primary">{t('mod_budgets_send_button')}</button>
				</div>
			</div>
        )
    }

    function buttons() {
        return [];
        /*if (!!!reservationUid)
            return [];
        return [
            <CapsuleButton
                icon={Icons.info}
                onClick={handleInfo}
            />
        ];*/
    }
}