//import React, { useEffect, useState } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//import { ReservationConfig } from "../_models/ReservationConfig";
import { TimeHelper } from "../_helpers";
import { Reservation } from "../_models/Reservation";
import { MainPageWithButtons } from "../_components";
import { CapsuleButton } from "../_components/CapsuleButton";
import { Selectors } from "../_reducers/app.reducer";
import { reservationActions } from "../_actions/reservation.actions";
import { SingleReservation } from "./SingleReservation";

const testIDs = {
    slotManagementView: "reservations-slot-management-view",
    scheduleButton: "reservations-slot-management-view-schedule-button",
}

ReservationSlotManagement.testIDs = testIDs;

export function ReservationSlotManagement() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //STORE DEPENDENCIES
    const reservations      = useSelector(Selectors.getReservations);

    const timezone          = useSelector(Selectors.getTimezone);

    const selectedSlot = useSelector(Selectors.getReservationSelectedSlot);

    //LOCAL STATE
    let filteredReservations = [];
    filteredReservations = reservations.filter(reservation => 
        reservation.begin === selectedSlot.utcBegin
    );

    //VIEW
    return (
        <MainPageWithButtons
            dataTestId={testIDs.slotManagementView}
            size={'SMALL'}
            pageTitle={t("global_manage")}
            content={content()}
            buttons={[
                <CapsuleButton
                    testId={testIDs.scheduleButton}
                    text={t("common.schedule")}
                    onClick={handleGoToSchedule}
                />
            ]}
        />
    );

    function content() {
        return (
            <div className="d-flex flex-column overflow-auto mt-3">
                <div className="font-weight-bold text-uppercase">
                    {localizedTimeString(selectedSlot.utcBegin)}
                    {" - "}
                    {localizedTimeString(selectedSlot.utcEnd)}
                </div>
                <div className="border-top"/>
                <div className="overflow-auto">
                    <div className="d-flex flex-column h-100">
                        <div className="mt-2"/>
                        <div className="overflow-auto h-100">
                            {buildSlots(filteredReservations)}
                        </div>
                    </div>
                </div>
            </div>
        );

        function localizedTimeString(utcIsoString) {
            return TimeHelper.localizeIsoStringToFormat(
                utcIsoString,
                timezone,
                TimeHelper.getTimeFormats().TIME_NO_SECONDS,
            );
        }
    }

    function buildSlots(filteredReservations = [] || [Reservation()]) {
        return filteredReservations.map(reservation =>
            <SingleReservation
                key={reservation.uid}
                {...{
                    reservation,
                    handleCancelReservation,
                }}
            />
        );
    }

    //STATE UPDATE HANDLERS
    function handleGoToSchedule() {
        dispatch(reservationActions.selectScheduleView());
    }

    function handleCancelReservation(uid) {
        dispatch(reservationActions.cancelReservation(uid));
    }
};