import { Entry } from "./Entry";
import { Client } from "./Client";
import { BUDGET_STATUS } from "../_constants/budgets.constants";

export function Budget({
    uid,
    ref,
    date,
    client = Client(),
    status = BUDGET_STATUS.DRAFT,

    entries = [] || [Entry()],

    totalGrossPrice,
    totalVat,
    totalNetPrice,

    notes,
    vatApplied,
} = {}) {
    return {
        uid,
        ref,
        date,
        client,
        status,
        
        entries,

        totalGrossPrice,
        totalVat,
        totalNetPrice,
        
        notes,
        vatApplied,
    };
}