export const MOBILE_TYPES = {
    IOS: "ios",
    ANDROID: "android",
    WEB: "web",
};

export const MOBILE_PERMISSION_STATES = {
    DISABLED:           0,
    ENABLED:            1,
    NOT_REQUESTED:      2,
    NOT_REQUIRED:       3,
    WHILE_USING_APP:    4,
};