import { Client } from "../_models/Client";

export class ClientMapper {
    static allServerToLocal(serverClients = []) {
        return serverClients.map(this.serverToLocal);
    }

    static serverToLocal(serverClient) {
        return Client(serverClient);
    }
}