import React from "react";

export function PopUp({
    content,
    handleClose,
    isFullHeight,
}) {
    return (
        <>
            <div
                onClick={handleClose}
                className="
                    w-100
                    h-100
                    position-absolute
                    
                    c-z-1050
                    bg-dark
                    c-opacity-75
                "
            />

            <div className={`
                w-100
                mh-100
                position-absolute
                
                d-flex
                no-gutters
                
                c-z-1050
                
                ${isFullHeight
                    ? "h-100"
                    : ""
                }
            `}>
                <div
                    onClick={handleClose}
                    className="col"
                />
                {content}
                <div
                    onClick={handleClose}
                    className="col"
                />
            </div>
        </>
    );
}