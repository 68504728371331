import { ServiceHelper2 } from "../_globalHelpers";
import { BUDGETS_API_URLS } from "../_constants/url.constants";

export const EntriesService = {
    createEntry,
    updateEntry,
    deleteEntry,
};

async function createEntry({
    budgetUid,
    amount,
    discount,
    productType,
    productPrice,
    productName,
}) {
    return await ServiceHelper2.postRequest(
        BUDGETS_API_URLS.ENTRIES,
        {
            budget_uid:     budgetUid,
            amount,
            discount,
            product_type:   productType,
            product_price:  productPrice,
            product_name:   productName,
        },
    );
}

async function updateEntry({
    uid,
    amount,
    discount,
    productType,
    productPrice,
    productName,
}) {
    return await ServiceHelper2.putRequest(
        BUDGETS_API_URLS.ENTRIES + "/" + uid,
        {
            amount,
            discount,
            product_type:   productType,
            product_price:  productPrice,
            product_name:   productName,
        },
    );
}

async function deleteEntry(uid) {
    await ServiceHelper2.deleteRequest(
        BUDGETS_API_URLS.ENTRIES + "/" + uid,
    );
}