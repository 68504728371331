import { GenericMapperFactory } from "../../Everou/_mappers/MapperFactory";
import { DevRoom } from "../_models/DevRoom";

export const DevRoomsMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverRoom) {
    return DevRoom({
        uid:                serverRoom.uid,
        name:               serverRoom.name,
        locationUid:        serverRoom.loc_uid,
        locationName:       serverRoom.loc_name,
        locationTimezone:   serverRoom.loc_timezone,
        devices:            serverRoom.devices 
    });
}