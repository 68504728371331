import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { MainPageWithButtons } from "../_components";
import { AuditLog } from "../_models/AuditLog";
import {
    filterDisabled,
    AUDIT_LOG_CODES,
} from "../_constants";
import { TimeHelper } from "../_helpers";
import { AuditLogsHelper } from "../_helpers/AuditLogsHelper";
import { User } from "../_models/User";
import { CustomDropdown } from "../_components/CustomDropdown";
import { auditLogsActions } from "../_actions/auditLogs.actions";

import { AuditLogsPathFilter } from "./AuditLogsPathFilter";
import { Selectors } from "../_reducers/app.reducer";
import { GenericLogsPage } from "../_components/GenericLogsPage";

export function AuditMainPage() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const logs              = useSelector(Selectors.getAuditLogs);
    const { begin, end }    = useSelector(Selectors.getAuditLogsDateFilter);
    const filters           = useSelector(Selectors.getAuditLogsFilters);
    const timezone          = useSelector(Selectors.getTimezone);
    const locationUid       = useSelector(Selectors.getSelectedLocationUid);
    const isRequesting      = useSelector(Selectors.getAuditLogsIsRequesting);
    const isRequestingFirst = useSelector(Selectors.getAuditLogsIsRequestingFirst);
    const nextPage          = useSelector(Selectors.getAuditLogsNextPage);
    
    let users               = [User()];
    users                   = useSelector(Selectors.getUsers);
    
    const selectedUid       = useSelector(Selectors.getAuditLogsSelectedUid);
    const filterPresets     = useSelector(Selectors.getAuditLogsFilterPresets);
    const selectedPreset    = useSelector(Selectors.getAuditLogsSelectedPreset);
    
    //LOCAL STATE
    const isMounting = useRef(true);

    const {
        user,
        paths,
        type,
        actionPath,
    } = filters;

    //ACTION HANDLERS
    const handleRefreshLogs = () => dispatch(auditLogsActions.getAuditFirstPage());
    const handleNextPage    = () => dispatch(auditLogsActions.getAuditNextPage(nextPage, isRequesting));
    const handleLogsDateStartChange = date =>
        dispatch(auditLogsActions.setDateFilter({ begin: date }))
    ;
    const handleLogsDateEndChange = date =>
        dispatch(auditLogsActions.setDateFilter({ end: date }))
    ;

    function handleChangeFilterUser(userUid) {
        dispatch(auditLogsActions.setFilterUser(userUid));
    }

    function handleSelectLog(uid) {
        dispatch(auditLogsActions.selectLog(uid));
    }

    //INITIALIZATION
    useEffect(() => {
        dispatch(auditLogsActions.setDateFilter({
            begin: TimeHelper.localizedStartOfTodayIsoString(timezone),
            end: TimeHelper.localizedEndOfTodayIsoString(timezone),
        }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, locationUid]);

    useEffect(() => {
        if (isMounting.current) {
            isMounting.current = false;
            return;
        }

        if (!begin || !end)
            return;

        getFromServer();
        
        async function getFromServer() {
            await Promise.all([
                dispatch(auditLogsActions.getAuditFirstPage()),
                dispatch(auditLogsActions.getAuditLogsFilterPresets(
                    locationUid,
                )),
            ]);
        }
    }, [dispatch, begin, end, filters, locationUid]);

    //VIEW
    return (
        <MainPageWithButtons
            size={'LARGE'}
            pageTitle={t("common.audit")}
            content={
                <GenericLogsPage
                    content={table()}
                    otherFilters={otherFilters()}

                    isThereLogs={logs.length !== 0}
                    isRequesting={isRequesting}
                    requestingFirst={isRequestingFirst}
                    nextPage={nextPage}
                    
                    startIsoString={begin}
                    endIsoString={end}

                    timezone={timezone}

                    handleNextPage={handleNextPage}
                    handleLogsDateStartChange={handleLogsDateStartChange}
                    handleLogsDateEndChange={handleLogsDateEndChange}
                    handleRefreshLogs={handleRefreshLogs}
                />
            }
        />
    );

    function otherFilters() {
        return (
            <div>
                {userFilter()}
                <AuditLogsPathFilter
                    paths={paths}
                    typeFilter={type}
                    actionPathFilter={actionPath}

                    locationUid={locationUid}
                    selectedPreset={selectedPreset}
                    filterPresets={filterPresets}
                />
            </div>
        );
    }
   
    function userFilter() {
        return (
            <div className="no-gutters">
                <div className="col-6">
                    <CustomDropdown
                        title={t("common.user")}
                        options={options()}
                        topOptions={[allOption()]}
                        onChange={handleChangeFilterUser}
                        value={user || filterDisabled.number}
                        sortAlphabetically={true}
                    />
                </div>
            </div>
        );

        function options() {
            const result = 
                users
                .map(user => 
                    CustomDropdown.buildOption(
                        user.uid,
                        user.email,
                    )
                )
            ;
            return result;
        }
    }

    function allOption() {
        return CustomDropdown.buildOption(
            filterDisabled.number,
            filterDisabled.all,
        );
    }

    function table() {
        return (
            <table
                className={`
                    mb-0
                    table
                    c-line-height-1rem
                `}
            >
                <thead>
                    <tr>
                        {stickyThead(t("common.code"))}
                        {stickyThead(t("common.type"))}
                        {stickyThead(t("common.description"))}
                        {stickyThead(t("common.date"))}
                        {stickyThead(t("common.user"))}
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log, index) => singleLog(log, index))}
                </tbody>
            </table>
        );

        function stickyThead(name) {
            return(
                <th className="
                    c-sticky-top
                    c-bg-grey-230
                    text-capitalize
                "
                    scope="col"
                >
                    {name}
                </th>
            );
        }

        function singleLog(log = AuditLog(), index) {
            return (
            <tr key={index}
                onClick={() => handleSelectLog(log.id)}
                className={`
                    ${selectedUid === log.id
                        ? "c-bg-primary-light-2"
                        : "c-hover-240"
                    }
                    c-cursor-default
                `}
            >
                <td className={`
                    ${log.code === AUDIT_LOG_CODES.ERROR
                        ? "text-danger"
                        : ""
                    }
                `}>
                    {log.code}
                </td>
                <td>{AuditLogsHelper.getTypeUiName(log.type)}</td>
                <td>{AuditLogsHelper.getAuditLogDescription(log.path, true)}</td>
                <td>{TimeHelper.localizeIsoStringToFormat(
                    log.datetime,
                    timezone,
                    TimeHelper.getTimeFormats().DATE,
                )}</td>
                <td className="text-break">{log.userEmail}</td>
            </tr>
            );
        }
    }
}