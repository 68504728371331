import {
    DEVICE_TYPES,
    deviceFilterModes,
    getTypesForDeviceCategory,
    filterDisabled,
    APP_LOG_TYPES,
} from '../_constants';
import { deviceLogsService } from '../_services';
import { LogsMapper } from '../_mappers';
import { appPermissions } from '../_constants/permissions.constants';
import { DeviceLogsCollection } from "../_stores/DeviceLogsCollection";
import { Selectors } from '../_reducers/app.reducer';
import { DeviceLogsDateFilterStore } from '../_stores/DeviceLogsDateFilterStore';
import { DevicesLogsFiltersStore } from '../_stores/DevicesLogsFiltersStore';
import { logsActions } from '.';

export const deviceLogsActions = {
    //REMOTE
    getDeviceLogsFirstPage: () => logsActions.getFirstPage(APP_LOG_TYPES.DEVICE),
    getDeviceLogsNextPage: (nextPage, isRequesting) => logsActions.getNextPage(nextPage, isRequesting, APP_LOG_TYPES.DEVICE),
    getDeviceLogsFromServer,

    //LOCAL
    setDateFilter: DeviceLogsDateFilterStore.actionSetBeginEnd,
    listLogsSuccess: DeviceLogsCollection.actionListItemsSuccess,
    listLogsRequest: DeviceLogsCollection.actionListItemsRequest,

    setFilterDateBegin,
    setFilterDateEnd,
    setFilterDateBeginEnd,

    setFilterUser,
    setFilterTypeStatus,
    setFilterType,
};

const setGenericFilter = DevicesLogsFiltersStore.actionChangeGenericFilter;

function setFilterDateBeginEnd(begin, end) {
    return deviceLogsActions.setDateFilter({ begin, end });
}

function setFilterDateBegin(begin) {
    return deviceLogsActions.setDateFilter({ begin });
};

function setFilterDateEnd(end){
    return deviceLogsActions.setDateFilter({ end });
};

function setFilterUser(uid) {
    return setGenericFilter({ user: uid });
}

function setFilterTypeStatus(status) {
    return setGenericFilter({ status });
}

function setFilterType(type) {
    return setGenericFilter({
        type,
        status: filterDisabled.number,
    });
}

function getDeviceLogsFromServer(page) {
    return async (dispatch, getState) => {
        if (!appPermissions.canUserViewDeviceActivity(
            Selectors.getPermissionSelectedLocation(getState())
        ))  return;

        dispatch(deviceLogsActions.listLogsRequest(page));

        const locationUid = Selectors.getSelectedLocationUid(getState());
        const {
            deviceUids,
            brainUids,
            deviceFilterObject,
        } = setDevices(
            Selectors.getIsAllDevicesSelected(getState()),
            Selectors.getDeviceFilterIsAllCategoriesSelected(getState()),
            Selectors.getDeviceFilterFilterMode(getState()),
            Selectors.getSelectedLocationDevices(getState())
                .filter(device => device.selected),
            Selectors.getDeviceFilterCategories(getState())
                .filter(category => category.active)
                .map(category => category.id),
        );
    
        const filterType        = Selectors.getDeviceLogsFilterType(getState());
        const filterStatus      = Selectors.getDeviceLogsFilterTypeStatus(getState());
        const filterUser        = Selectors.getDeviceLogsFilterUser(getState());
        const { begin, end }    = Selectors.getDeviceLogsDateFilter(getState());
        const {
            serverUiLogType,
            serverStatus,
            serverUser,
        } = LogsMapper.mapLogFiltersLocalToServer(
            filterType,
            filterStatus,
            filterUser,
        );
        const pageSize = 25;
    
        const { logs, next } = await deviceLogsService.getLocationDevicesLogs(
            locationUid,
            deviceUids,
            brainUids,
            begin,
            end,
            pageSize,
            page,
            serverStatus,
            serverUiLogType,
            serverUser,
            deviceFilterObject,
        );

        const localLogs = LogsMapper.mapAllServerToLocal(logs);

        dispatch(deviceLogsActions.listLogsSuccess(localLogs, next));

        return {
            localLogs,
            next,
        };
    }
    
    ////
    function setDevices(isAllDevicesSelected, isAllCategoriesSelected, filterMode, selectedDevices, selectedCategoriesIds) {
        if (!isAllDevicesSelected)
            return getSelectedDevicesLogs(selectedDevices);

        if (isAllCategoriesSelected)
            return {};
        
        return getSelectedCategoriesLogs(filterMode, selectedCategoriesIds);
    }

    function getSelectedDevicesLogs(selectedDevices) {
        if (selectedDevices.length > 20)
            throw new Error("Too many devices selected, please select 20 or less");

        const deviceUids = [];
        const brainUids = [];

        selectedDevices.forEach(device => {
            device.type === DEVICE_TYPES.Brain
                ? brainUids.push(device.uid)
                : deviceUids.push(device.uid)
            ;
        });

        return {
            deviceUids,
            brainUids,
        };
    }

    function getSelectedCategoriesLogs(filterMode, selectedCategories) {
        let deviceFilterType = null;
        let contentArray = selectedCategories;
        
        switch (filterMode) {
            case deviceFilterModes.CATEGORIES:
                deviceFilterType = deviceLogsService.logFilterTypes().deviceTypes;
                contentArray = selectedCategories
                    .reduce((types, category) =>
                        [...types, ...getTypesForDeviceCategory(category)]
                    , [])
                break;

            case deviceFilterModes.DEVICES_TYPES:
                deviceFilterType = deviceLogsService.logFilterTypes().deviceOriginalTypes;
                break;

            case deviceFilterModes.ROOMS: {
                if (contentArray.length > 20)
                    throw new Error("Too many rooms selected, please select 20 or less");

                deviceFilterType = deviceLogsService.logFilterTypes().rooms;
                break;
            }

            default:
                deviceFilterType = null;
        }

        return {
            deviceFilterObject: deviceLogsService.buildLogsDeviceFilter(
                deviceFilterType,
                contentArray,
            ),
        };
    }
}