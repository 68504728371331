import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IsoRangeDatePicker } from "../_components/IsoRangeDatePicker";
import { INVITATION_PERIODS_LEGACY } from "../_constants";
import { InvitationsHelper, TimeHelper } from "../_helpers";
import { Selectors } from "../_reducers/app.reducer";

export function NoAccessToLocation() {

    const invitation = useSelector(Selectors.getMyInvitationSelected);
    const timezone          = useSelector(Selectors.getTimezone);
    const { t } = useTranslation();

    if (!invitation) {
        return (
            <div className="text-center my-auto">
                {t("webapp_no_modules_message")}
            </div>
        );
    } else {

        const getTime = (date: Date, format) =>
            TimeHelper.localizeIsoStringToFormat(
                date,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                format,
            );
        
        const getTimedString = ({ begin, end }) => {
            const format = TimeHelper.getTimeFormats().TIME_NO_SECONDS;
            const from = getTime(begin, format);
            const to = getTime(end, format);

        return (
                `${t("common.from")} ${from}` +
                (end ? ` - ${t("common.to")} ${to}` : "")
            );
        };
        

        return (
            <div className="my-auto">
                <div className="my-auto text-center">
                    <div className="mb-1">
                        <div className="mb-2 font-weight-bold">
                            {t("webapp_inaccessible_location_currently")}
                        </div>
                        {((invitation.begin &&
                            invitation.periodType !==
                                INVITATION_PERIODS_LEGACY.RECURRENT)) && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        className="mb-2"
                                        style={{
                                            maxWidth: "100%",
                                            width: 380,
                                            flexShrink: 0,
                                        }}
                                    >
                                        <IsoRangeDatePicker
                                            timezone={timezone}
                                            begin={invitation.begin}
                                            end={invitation.end}
                                            readOnly
                                            endPlaceHolder={t('global_forever')}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>

                    <div className="font-italic text-secondary">
                        {
                            <span className="mt-4 my-auto c-text-capitalize-first">
                                {InvitationsHelper.getTemporaryAccessInfoLabel(
                                    invitation,
                                )?.map((parsedDaySchedule) => (
                                    <div key={parsedDaySchedule}>
                                        {parsedDaySchedule}
                                    </div>
                                ))}
                            </span>
                        }
                        {invitation.periodType ===
                            INVITATION_PERIODS_LEGACY.RECURRENT && (
                            <span className="text-center">
                                {getTimedString(invitation)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
