
import { genericActions } from './generic.actions';
import { Selectors } from '../_reducers/app.reducer';
import { ROOM_TYPES_NAMES } from '../_constants/room.constants';
import { RoomMapper } from '../_mappers/RoomMapper';
import { filterActions } from './filter.actions';
import { RoomsService } from '../_services/rooms.service';
import { RoomsStore } from '../_stores/RoomStore';
import { Room } from '../_models/Room';

export const roomsActions = {
    createRoom,
    updateRoom,
    deleteLocalRoom,
    selectRoomConfig,
};

function selectRoomConfig(uid) { 
    return dispatch => {
        dispatch(RoomsStore.actionSelectItem(uid));
    }
}

function createRoom(type: number, name?: string, locationUid?: string, cb?: (room) => void) {

    if (!name) {
        name = ROOM_TYPES_NAMES[type];
    }

    return async (dispatch, getState) => {
        if (!locationUid) {
            locationUid = Selectors.getSelectedLocationUid(getState()) ?? '';
        }
        return dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const server_room = await RoomsService.createRoom({
                loc_uid: locationUid,
                name,
                type
            });
            const room = RoomMapper.serverToLocal(server_room);
            const rooms = [...Selectors.getRooms(getState()), room];
            const devices = Selectors.getSelectedLocationDevices(getState());

            
            dispatch(RoomsStore.actionListItemsSuccess(rooms));
            dispatch(filterActions.createFilterCategories(devices, rooms));

            cb && cb(room);

            return room;
        }
    }
}

function updateRoom(updatedRoom = Room()) {
    if (!updatedRoom.name) {
            updatedRoom.name = ROOM_TYPES_NAMES[updatedRoom.type];
        }
    
    return async (dispatch, getState) => {
        return dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await RoomsService.updateRoom({
                uid: updatedRoom.uid,
                name: updatedRoom.name,
                type: updatedRoom.type
            });
            
            const rooms = Selectors.getRooms(getState()).map((room: any) => room.uid === updatedRoom.uid ? updatedRoom : room);
            const devices = Selectors.getSelectedLocationDevices(getState());
            dispatch(RoomsStore.actionListItemsSuccess(rooms));
            dispatch(filterActions.createFilterCategories(devices, rooms));
            return rooms;
        }
    }
}

function deleteLocalRoom(uid) {

    return async (dispatch, getState) => {

        return dispatch(genericActions.genericAsyncAction(asyncAction, null, true));

        function asyncAction() {
            
            const rooms = Selectors.getRooms(getState()).filter((room: any) => room.uid !== uid);
            const devices = Selectors.getSelectedLocationDevices(getState());

            dispatch(RoomsStore.actionListItemsSuccess(rooms)); 
            dispatch(filterActions.createFilterCategories(devices, rooms));

        }
    }

}

