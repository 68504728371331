import React, { useEffect, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { CaptionText, MainPageWithButtons } from "../_components";
import { invitationsActions } from "../_actions";
import { SubinvitationGroup } from "../_models/SubinvitationGroup";
import { SubinvitationGroupMapper } from "../_mappers/SubinvitationGroupMapper";
import { SingleInvitation } from "./_components/SingleInvitation";
import { CapsuleButton } from "../_components";
import { Selectors } from "../_reducers/app.reducer";

export function SubinvitationsListView() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid           = useSelector(Selectors.getSelectedLocationUid);
    const selectedInvitationUid = useSelector(Selectors.getInvitationSelectedUid);
    const invitations           = useSelector(Selectors.getInvitations).items;

    //LOCAL STATE
    const subinvitationGroups = useMemo(
        () => SubinvitationGroupMapper.invitationsToSubinvitations(invitations)
    , [invitations]);

    //ACTION HANDLERS

    function handleCreateNewSubinvitation(mainInvitation) {
        dispatch(invitationsActions.createLocalSubinvitation(mainInvitation));
    }

    function handleSelectInvitation(invitationUid) {
        dispatch(invitationsActions.selectInvitation(invitationUid));
    }
    
    //INITIALIZATION
    useEffect(() => {
        getSubinvitations();

        async function getSubinvitations() {
            await dispatch(invitationsActions.getSubinvitations());
        }
    },
    [dispatch, locationUid]);

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("invitations.invitations")}
            size='LARGE'
            content={
            <div className="c-grid">
                {subinvitationGroups.map(buildSubinvitationGroup)}
            </div>      
        } />
    );

    function buildSubinvitationGroup(subinvitationGroup = SubinvitationGroup()) {
        const mainInvitation = subinvitationGroup.main;
        const subinvitationsLeft =
            mainInvitation.subinvitations - subinvitationGroup.sent.length
        ;
        return (
            <div key={mainInvitation.uid} className="my-2">
                <div className="p-2">
                    <div>
                        <div className="d-flex justify-content-between align-content-between">
                            <div>
                                <h3 className="mb-1">
                                    {mainInvitation.deviceName}
                                </h3>
                                <CaptionText>
                                    {remainingInvitationsTag()}
                                </CaptionText>
                            </div>
                            {createNewInvitation(
                                subinvitationsLeft,
                                mainInvitation,
                            )}
                        </div>
                        <div className="py-4 c-default-grid">
                            {invitationsList(subinvitationGroup)}
                        </div>
                    </div>
                </div>
            </div>
        );

        function remainingInvitationsTag() {
            return (
                <div className="d-flex">
                    <div className="text-capitalize">
                        {t("invitations.availableInvitations") + ":"}
                    </div>
                    <div className="pl-1"/>
                    <div className="text-lowercase">
                        {
                            subinvitationsLeft +
                            ` ${t("common.of")} ` +
                            mainInvitation?.subinvitations.toString()
                        }
                    </div>
                </div>
            );
        }

        function invitationsList() {
            return subinvitationGroup.sent.map(invitation =>
                <SingleInvitation
                    key={invitation.uid}
                    invitation={invitation}
                    handleSelectInvitation={() => handleSelectInvitation(invitation.uid)}
                    isSelected={selectedInvitationUid === invitation.uid}
                />
            );
        }
    
        function createNewInvitation() {
            return (
                subinvitationsLeft === 0
                ? noInvitationsLeft()
                : sendNewInvitation(mainInvitation)
            );
        }
    }

    function sendNewInvitation(mainInvitation) {
        return (
            <CapsuleButton
                size='SMALL'
                onClick={() => handleCreateNewSubinvitation(mainInvitation)}
                text={t("invitations.newInvitation")}
            />
        );
    }

    function noInvitationsLeft() {
        return (
            <CaptionText>{t("invitations.allInvitationsInUse")}</CaptionText>
        );
    }
}