import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationActions } from "../_actions";
import { ContentSpace } from "../_components";
import { BARRIER_MODES } from "../_constants/barriers.constants";
import { LocationInfoHelper } from "../_helpers/locationInfo.helper";
import { Selectors } from "../_reducers/app.reducer";
import { barriersActions } from "../_stores/BarriersStore/BarriersActions";
import { BarriersMainPage } from "./BarriersMainPage";
import { BarriersUsers } from "./BarriersUsers";

export function BarriersModule() {

    const dispatch = useDispatch();

    const [showUsers, setShowUsers] = useState(false);

    const barrier = useSelector(Selectors.getBarrier);
    const locationInfo = useSelector(Selectors.getLocationInfo);
    const devices = useSelector(Selectors.getSelectedLocationDevices);

    async function fetchData() {
        dispatch(barriersActions.setIsLoading(true));
        await dispatch(barriersActions.getInfo(locationInfo.uid));
        dispatch(barriersActions.setIsLoading(false));
    }

    const detailedContent = useMemo(() =>
        showUsers && !barrier.errors?.devicesMisconfigured ? <BarriersUsers barrier={barrier} /> : null
    , [showUsers, barrier])

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationInfo.uid]);


    useEffect(() => {
        if(locationInfo.barrierMode === BARRIER_MODES.NOT_CONFIGURED && LocationInfoHelper.isBarriersActive(locationInfo.barrierMode, devices)) {
            dispatch(locationActions.updateBarrierMode(BARRIER_MODES.ENABLED, locationInfo.uid));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationInfo.uid]);

    useEffect(() => {
        if(barrier.errors?.devicesMisconfigured) {
            setShowUsers(false);
        }
    },[barrier.errors?.devicesMisconfigured])

    return (
        <ContentSpace
            detailedContent={detailedContent}
            mainContent={<BarriersMainPage usersVisible={showUsers} setUsersVisible={(visible) => setShowUsers(visible)} />}
            onGoBack={() => setShowUsers(false)}
        />
    );
}