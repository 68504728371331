import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IncorrectDevices } from "./IncorrectDevices";
import { TriggeredLogs } from "./TriggeredLogs";
import { protectActions } from "../_actions/protect.actions";

import { GLOBAL_POPUPS } from "../_constants";
import { globalActions } from "../_actions";
import { PROTECT_SECONDARY_VIEWS } from "../_constants/protect.constants";

import optionsIcon from "../_assets/appIcons/settings-icon.svg";
import usersIcon from "../_assets/appIcons/users-icon-24px.svg";
import { ProtectHelper } from "../_helpers/ProtectHelper";
import { MainPageWithButtons } from "../_components";
import { Selectors } from "../_reducers/app.reducer";
import { CapsuleButton } from "../../Common/_components";

export function ProtectMainPage({ locationInfo, devices, triggeredLogs }) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //GLOBAL STATE
    const incorrectDevices =  ProtectHelper.setIncorrectDevices(devices);
    const secondaryView = useSelector(Selectors.getProtectModuleSecondaryView);

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("protect.protect")}
            buttons={headerButtons()}
            content={content()}
        />
        );
        


    function content() {
        return (
            <div className="
                h-100

                d-flex
                no-gutters
                flex-column
            ">
                <div className="my-1" />
                {toggleProtectButton(locationInfo.isProtectOn)}
                <hr className="mx-0 my-2" />
                {locationInfo.isProtectOn
                    ? <TriggeredLogs
                        triggeredLogs={triggeredLogs}
                        devices={devices}
                        timezone={locationInfo.timezone}
                    />
                    : <IncorrectDevices
                        incorrectDevices={incorrectDevices}
                    />
                }
            </div>
        );
    }

    function headerButtons() {
        return [
            <CapsuleButton
                text={t("logs.logs")}
                onClick={handleShowLogs}
                isSelected={secondaryView === PROTECT_SECONDARY_VIEWS.LOGS}
            />,
            <CapsuleButton
                icon={optionsIcon}
                onClick={handleShowSettings}
                isSelected={secondaryView === PROTECT_SECONDARY_VIEWS.SETTINGS}
            />,
            <CapsuleButton
                icon={usersIcon}
                onClick={handleShowUsers}
                isSelected={secondaryView === PROTECT_SECONDARY_VIEWS.USERS}
            />,
        ];
    }

    function toggleProtectButton(isProtectOn) {
        return (
            <div className="d-flex">
                <CapsuleButton
                    text={isProtectOn
                        ? t("common.disable")
                        : t("common.enable")
                    }
                    style={isProtectOn
                        ? 'DANGER'
                        : 'PRIMARY'
                    }
                    onClick={() => handleToggleProtect(isProtectOn)}
                />
            </div>
        );
    }

    //ACTION HANDLERS
    function handleShowLogs() {
        dispatch(protectActions.setSecondaryView(PROTECT_SECONDARY_VIEWS.LOGS));
    }

    function handleShowSettings() {
        dispatch(protectActions.setSecondaryView(PROTECT_SECONDARY_VIEWS.SETTINGS));
    }

    function handleShowUsers() {
        dispatch(protectActions.setSecondaryView(PROTECT_SECONDARY_VIEWS.USERS));
    }

    function handleToggleProtect(isProtectOn) {
        if (!isProtectOn && incorrectDevices.length !== 0) {
            dispatch(globalActions.showPopUp(GLOBAL_POPUPS.ENABLE_PROTECT));
            return;
        }

        dispatch(protectActions.toggleProtectStatus());
    }
}