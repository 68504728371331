import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { BaseClientFormMainPage } from "./BaseClientFormMainPage";
import { ClientActions } from "../_actions/ClientActions";

export function NewClientView() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    return (
        <BaseClientFormMainPage
            title={t("mod_clients_add_client")}
            onSave={handleSave}
        />
    );

    //ACTION HANLDERS
    function handleSave({
        name,
        address,
        phone,
        email,
        cif,
    }) {
        dispatch(ClientActions.createAndSelectClient({
            name,
            address,
            phone,
            email,
            cif,
        }));
    }
}