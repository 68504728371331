import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { invitationsActions } from "../_actions";
import { invitationTypeUiValues } from "../_constants";
import { Invitation } from "../_models/Invitation";
import { BaseGlobalPopUp } from "../_components";
import { Selectors } from "../_reducers/app.reducer";

const testIDs = {
    root: "pending-invitation",
    acceptButton: "pending-invitation-accept-button",
};

PendingInvitationsModule.testIDs = testIDs;

export function PendingInvitationsModule() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const invitations = useSelector(Selectors.getPendingInvitations);

    useEffect(() => {
        getPending();

        async function getPending() {
            await dispatch(invitationsActions.listPending());
        }
    },
    [dispatch]);

    if (invitations.length === 0)
        return null;

    let invitation = Invitation();
    invitation = invitations[0];

    return (
        <BaseGlobalPopUp
            testID={testIDs.root}
            acceptButtonTestID={testIDs.acceptButton}
            title={t("invitations.pendingInvitation")}
            body={
                t("invitations.userHasInvitedYou",
                    {
                        username: invitation.userSrcName,
                        type: invitationTypeUiValues[invitation.type],
                        device: invitation.deviceName,
                    }
                )
            }
            onAccept={() => handleAcceptInvitation(invitation.uid, invitation.locationUid)}
            onCancel={() => handleCancelInvitation(invitation.uid)}
        />
    );

    function handleAcceptInvitation(invitationUid, locationUid) {
        dispatch(invitationsActions.acceptPending(invitationUid, locationUid));
    }

    function handleCancelInvitation(uid) {
        dispatch(invitationsActions.cancelPending(uid));
    }
}