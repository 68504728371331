import { HostifyService } from "../_services/hostify.service";
import { HostifyStore } from "../_stores/HostifyStore";
import { genericActions } from "./generic.actions"

export const hostifyActions = {
    listListings,
}

function listListings(integrationUID: string) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const listings = await HostifyService.listListings(integrationUID);
            dispatch(HostifyStore.actionListItemsSuccess(listings));
        }
    }
}