import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { LogModel } from "../_models/LogModel";
import { LogsTable } from "../DevicesModule/LogsTable";
import { Device } from "../_models/Device";
import { MainPageWithButtons } from "../_components";
import { Selectors } from "../_reducers/app.reducer";
import { GenericLogsPage } from "../_components/GenericLogsPage";
import { protectActions } from "../_actions/protect.actions";
import { TimeHelper } from "../_helpers";

export function ProtectLogs() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let logs                = [LogModel()];
    logs                    = useSelector(Selectors.getProtectLogs);
    let devices             = [Device()];
    devices                 = useSelector(Selectors.getSelectedLocationDevices);

    const timezone          = useSelector(Selectors.getTimezone);
    const locationUid       = useSelector(Selectors.getSelectedLocationUid);
    const { begin, end }    = useSelector(Selectors.getProtectLogsDateFilter);
    const isRequesting      = useSelector(Selectors.getProtectLogsIsRequesting);
    const isRequestingFirst = useSelector(Selectors.getProtectLogsIsRequestingFirst);
    const nextPage          = useSelector(Selectors.getProtectLogsNextPage);
    
    //ACTION HANDLERS
    const handleRefreshLogs = () => dispatch(protectActions.getProctectLogsFirstPage());
    const handleNextPage = () => dispatch(protectActions.getProctectLogsNextPage(nextPage, isRequesting));
    const handleLogsDateStartChange = date =>
        dispatch(protectActions.setDateFilter({
            begin: date,
        })
    );
    const handleLogsDateEndChange = date =>
        dispatch(protectActions.setDateFilter({
            end: date,
        })
    );

    //INITIALIZATION
    useEffect(() => {
        intialization();

        async function intialization() {
            dispatch(protectActions.setDateFilter({
                begin: TimeHelper.localizedStartOfTodayIsoString(timezone),
                end: TimeHelper.localizedEndOfTodayIsoString(timezone),
            }));
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid]);

    useEffect(() => {
        dispatch(protectActions.getProctectLogsFirstPage());
    }, [dispatch, begin, end]);

    //VIEW
    return (
        <MainPageWithButtons
            size={'UNLIMITED'}
            pageTitle={t("protect.protectLogs")}
            content={
                <GenericLogsPage
                    handleNextPage={handleNextPage}
                    handleLogsDateStartChange={handleLogsDateStartChange}
                    handleLogsDateEndChange={handleLogsDateEndChange}
                    handleRefreshLogs={handleRefreshLogs}

                    startIsoString={begin}
                    endIsoString={end}

                    isRequesting={isRequesting}
                    requestingFirst={isRequestingFirst}
                    isThereLogs={logs.length !== 0}
                    timezone={timezone}
                    nextPage={nextPage}

                    content={
                        <LogsTable
                            devices={devices}
                            logs={logs}
                            isSmall={false}
                            timezone={timezone}
                        />
                    }
                />
            }
        />
    );
}