import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { CapsuleButton } from "./";

export function FileUploadComponent({
    onUpload = (file) => {},
    isUploading = false,
    fileType = 'image/jpeg,image/png'
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(t("global_no_file_selected"));
    
    //VIEW
    return (
        <div className="
            d-flex
            justify-content-between
            align-items-center
            p-1
            border
            rounded
        ">
            <div className="
                d-flex
                align-items-center
            ">
                <label className="
                    py-1
                    px-2
                    my-auto
                    
                    c-bd-primary
                    rounded
                    bg-white
                    c-hover-bg-primary-light-2
                    
                    c-text-primary
                    c-font-weight-medium
                    cp

                ">
                    {t("global_choose_file")}
                    <input
                        type="file"
                        accept={fileType}
                        onChange={handleSetFile}
                        className="d-none"
                    />
                </label>
                <div className="ml-1"/>
                <div>
                    {fileName}
                </div>
            </div>

            <CapsuleButton
                onClick={onSubmitLocal}
                text={t("global_upload")}
                size={'SMALL'}
                isEnabled={!!file && !isUploading}
            />
        </div>
    );

    function handleSetFile(e) {
        const file = e.target.files[0];

        if (!file)
            return;

        setFile(file);
        setFileName(file.name);
    }

    function onSubmitLocal() {
        onUpload(file);
    }
}