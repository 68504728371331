import { InvitationGroup } from "../_models/InvitationGroup";
import { InvitationsMapper } from "./InvitationsMapper";
import { INVITATION_PERIODS } from "../_constants";
import { CardPackMapper } from "./CardPackMapper";
import { orderBy } from "lodash";
export class InvitationGroupMapper {
    static allServerToLocal(serverInvitationGroups = []) {
        return serverInvitationGroups.map(serverToLocal);
    }

    static serverToLocal(serverInvitationGroup) {
        return serverToLocal(serverInvitationGroup);
    }
}

function serverToLocal(serverInvitationGroup) {
    const serverInvitation = serverInvitationGroup.invitation_params;

    return InvitationGroup({
        id:         serverInvitationGroup.id,
        uid:        serverInvitationGroup.uid,
        name:       serverInvitationGroup.name,
        duration:   serverInvitationGroup.invitation_duration,
        groupType:  serverInvitationGroup.group_type,
        cardPacks: CardPackMapper.allServerToLocal(
            serverInvitationGroup.card_packs,
        ),
        invitationNetPrice: serverInvitationGroup.invitation_net_price,

        invitation: InvitationsMapper.mapServerToLocal({
            type:                   serverInvitation.type,
            permission_type:        serverInvitation.permission_type,
            rooms:                  orderBy(serverInvitation.rooms, ['type']),
            loc_uid:                serverInvitation.loc_uid,
            location_name:          serverInvitation.loc_name,
            
            allows_subinvitations:  serverInvitation.allows_subinvitations,
            subinvitations:         serverInvitation.subinvitations,

            user_src_name:          serverInvitation.user_src_name,
            user_src_email:         serverInvitation.user_src_email,

            //HARD-CODED INVITATION PARAMS UNTIL REAL PARAMS ARE RETURNED BY BACKEND
            period_type: INVITATION_PERIODS.ALWAYS,
        }),
    });
}