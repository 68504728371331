import { useMemo } from "react";
import { Icon } from "../_models/Icon";

type InfoContentProps = {
    children: any;
    icon: any;
    title: string;
    buttons: JSX.Element[];
    type?: "info" | "warning";
};

export function InfoContent({
    title,
    icon = Icon(),
    children,
    buttons,
    type = "info",
}: InfoContentProps) {
    const bgColor = type === "info" ? "c-bg-primary-light-2" : "c-bg-light-red";

    const iconElement = useMemo(
        () => <img className="col-3" src={icon.src} alt={icon.alt} />,
        [icon],
    );

    return (
        <div style={{display: 'grid', gap: '16px', borderRadius: '12px'}} className={`p-3 mt-2 border ${bgColor}`}>
            <div className="text-center">
                <div>{iconElement}</div>
                <div className="font-weight-bold ml-2">{title}</div>
            </div>
            <div className="px-1">{children}</div>
            <div className="d-flex justify-content-end">{buttons}</div>
        </div>
    );
}
