import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";

import { STRIPE_ID } from "../_constants/url.constants";
import { CustomCardElement } from "../PaymentsModule/CustomCardElement";
import { Selectors } from "../_reducers/app.reducer";
import { PaymentMethod } from "../_models/PaymentMethod";
import { PaymentMethodsActions } from "../_actions/PaymentMethodsActions";
import { WaitSpinner } from "./WaitSpinner";
import { CapsuleButton } from ".";
import { StripeActions } from "../_actions/StripeActions";
import { appPermissions } from "../_constants/permissions.constants";
import { popUpActions } from "../_stores/PopUpStore/popUpActions";
import { GLOBAL_POPUPS } from "../_constants";
import { CustomIbanElement } from "../PaymentsModule/CustomIbanElement";
import { CustomDropdown } from "../../Common/_components/CustomDropdown";
import { CaptionText } from "../../Common/_components/CaptionText";
import { Section } from "../../Common/_components/Section";

export function PaymentMethodSelector({
    hideIfConfigured = false,
    hideTitle = false,
    hideInfoText = false,
    infoText = '',
    afterDeleteCard = () => {}
}) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    const permission = useSelector(Selectors.getPermissionSelectedLocation);
    const isRequestingMethods = useSelector(Selectors.getPaymentMethodsIsRequesting);
    let paymentMethod = PaymentMethod();
    paymentMethod = useSelector(Selectors.getPaymentMethod);

    //LOCAL STATE
    const stripePromise = useMemo(() => loadStripe(STRIPE_ID), []);
    const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState(false);
    const canManagePayments = appPermissions.canManageSubscriptionPayments(permission);
    const isProcessing = isEditingPaymentMethod || isRequestingMethods;
    const [paymentMethodType, setPaymentMethodType] = useState(PaymentMethod.TYPES.CARD);

    //ACTION HANDLERS
    async function handleAddCard(stripe, cardElement) {
        await dispatch(StripeActions.confirmCardSetup(stripe, cardElement));
    }

    async function handleAddIban(stripe, ibanElement, name, email) {
        await dispatch(StripeActions.confirmSepa(stripe, ibanElement, name, email));
    }

    
    async function handleDeleteCard() {
        dispatch(
            popUpActions.showPopUp(GLOBAL_POPUPS.DELETE_PAYMENT_CARD, {
                uid: paymentMethod.uid,
                afterDeleteCard
            }),
        );
    }

    //INITIALIZATION
    useEffect(() => {
        if (!canManagePayments || paymentMethod)
            return;
            
        dispatch(PaymentMethodsActions.list());
    }, [dispatch, canManagePayments, locationUid, paymentMethod]);

    //VIEW
    if (!canManagePayments)
        return null;

    if (paymentMethod && hideIfConfigured)
        return null;

    return (
        <div data-testid={"manage-payment-method"}>
            {isProcessing && <WaitSpinner />}
                <Section
                    className={`${isProcessing && "d-none"}`}
                    title={
                        hideTitle
                            ? null
                            : t("mod_payments_manage_current_method")
                    }
                >
                    {paymentMethodSection()}
                    {!hideInfoText && (
                        <CaptionText className="mt-2 mb-1 text-secondary font-italic pl-1">
                            {!!infoText && (
                                <span className="d-block">{infoText}</span>
                            )}
                            <span className="d-block">
                                {t("mod_payments_method_used_globally_message")}
                            </span>
                        </CaptionText>
                    )}
                </Section>
        </div>
    );

    function newPaymentMethod() {
        return (
            <div>
                <CustomDropdown
                    options={buildOptions()}
                    value={paymentMethodType}
                    onChange={setPaymentMethodType}
                    title={t("mod_payments_method_type")}
                    sortAlphabetically
                />
                <div className="mt-2" />
                {newSelectedPaymentMethod()}
            </div>
        );

        function buildOptions() {
            return [
                CustomDropdown.buildOption(
                    PaymentMethod.TYPES.CARD,
                    t("mod_payments_method_type_card"),
                ),
                CustomDropdown.buildOption(
                    PaymentMethod.TYPES.SEPA,
                    t("mod_payments_method_type_iban"),
                ),
            ];
        }
    }

    function newSelectedPaymentMethod() {
        switch (paymentMethodType) {
            default:
            case PaymentMethod.TYPES.CARD:
                return addNewCardSection();

            case PaymentMethod.TYPES.SEPA:
                return addNewIbanSection();
        }
    }

    function paymentMethodSection() {
        if (paymentMethod)
            return currentPaymentMethodData();

        return newPaymentMethod();
    }

    function addNewCardSection() {
        return (
            <Elements stripe={stripePromise}>
                <CustomCardElement
                    className="w-100"
                    setIsRequesting={setIsEditingPaymentMethod}
                    onAddCard={handleAddCard}
                />
            </Elements>
        );
    }

    function addNewIbanSection() {
        return (
            <Elements stripe={stripePromise}>
                <CustomIbanElement
                    setIsRequesting={setIsEditingPaymentMethod}
                    onAddIban={handleAddIban}
                />
            </Elements>
        );
    }

    function currentPaymentMethodData() {
        const data = paymentMethod.data;
        return (
            <div className="
                d-flex
                justify-content-between
                align-items-center

                border
                rounded
                pl-2
                p-1
                bg-light
            ">
                <div>
                    <span className="text-capitalize c-font-weight-medium">
                        {data.brand}
                    </span>
                    <span>
                        {` ${t("mod_payments_card_ending_in")} ${data.last4}`}
                    </span>
                </div>
                <CapsuleButton
                    text={t("webapp_global_remove")}
                    onClick={handleDeleteCard}
                    style={`DANGER`}
                    size={'SMALL'}
                />
            </div>
        );
    }
}