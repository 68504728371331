import React from "react";
 
export function ComponentsWrapper({ className = '', componentsArray = [] }) {

    if (!componentsArray.length)
        return null;

    return (
        <div style={{display: 'grid', rowGap: '16px'}} className="pt-2">
            {componentsArray.reduce((result, component, index) => {
                component && result.push(
                    <div key={index}>
                        <div className="px-2">
                            {component}
                        </div>
                    </div>
                );

                return result;
            }, [])}
        </div>
    );
}