import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { authenticationActions } from '../_actions';

export function UserManager({ children } =  {}) {

    //LIBRARIES
    const dispatch = useDispatch();

    //LOCAL STATE
    const [isInitialized, setIsInitialized] = useState(false);

    //INITIALIZATION
    useEffect(() => {
        initialize();

        async function initialize() {
            if (await dispatch(authenticationActions.checkVersion()))
                return;
    
            await dispatch(authenticationActions.refreshUser());
            setIsInitialized(true);
        }
    }, [dispatch]);

    //VIEW
    return isInitialized ? children : null;
}