import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CapsuleButton, CustomNumberInput, MainPageWithButtons, ReadOnlyDataRow } from "../_components";
import { WaitSpinner } from "../_components/WaitSpinner";
import { Selectors } from "../_reducers/app.reducer";
import { barriersActions } from "../_stores/BarriersStore/BarriersActions";
import { BarrierDevices } from "./BarriersDevices";
import { Barrier } from "../_modelsTS/Barrier";
import { useDebounce } from "../_hooks/useDebounce";
import { BarrierStoreType } from "../_stores/BarriersStore/BarriersStore";

const DEFAULT_MAX_CAPACITY = 2147483647;


export function BarriersMainPage({ setUsersVisible, usersVisible }: { setUsersVisible: (value: boolean) => void, usersVisible: boolean }) {

    const { t } = useTranslation(); 
    const dispatch = useDispatch();
    
    const barrier = useSelector(Selectors.getBarrier);


    const { debounce } = useDebounce();

    const [capacity, setCapacity] = useState(barrier.capacity);

    useEffect(() => { setCapacity(barrier?.capacity); }, [barrier?.capacity]);

    
    const updateOnServer = useCallback((barrier: Partial<Barrier>) => {
        dispatch(barriersActions.updateInfo(barrier));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    const updateCapacity = (capacity: number) => {

        if(capacity === barrier.capacity) return;

        if(!capacity) {
            capacity = 0;
        }

        debounce(() => updateOnServer({ capacity }));
        setCapacity(capacity);
    }

    if(!barrier) return null;

    const barrierConfig = () => {
        if (barrier.isLoading) {
            return <div className="my-auto">
            <WaitSpinner />
        </div>
        }

        if (!barrier?.errors?.devicesMisconfigured) {
            return <>
                <ReadOnlyDataRow
                    name={t("mod_barriers_user_inside")}
                    data={barrier.numUsersInsideBarriers}
                />
                <ReadOnlyDataRow
                    name={t("mod_barriers_max_capacity")}
                    data={
                        <CustomNumberInput
                            onBlur={updateCapacity}
                            value={capacity}
                            min={barrier.numUsersInsideBarriers}
                            max={DEFAULT_MAX_CAPACITY}
                            onChange={updateCapacity}
                        />
                    }
                />
               
            </>
        }
        return null;
    }

    const content = (
        <>
            {barrierConfig()}
            <BarrierDevices />
        </>
    );

        return (
            <MainPageWithButtons
                pageTitle={t("mod_barriers_title")}
                pageSubtitle={
                    barrier?.errors?.devicesMisconfigured ? (
                        <div className="text-danger">
                            {t("mod_barriers_not_properly_configured")}
                        </div>
                    ) : (
                        ""
                    )
                }
                content={content}
                buttons={
                    (barrier.usersInsideBarriers && !barrier.errors?.devicesMisconfigured) ? [
                        <CapsuleButton
                            onClick={() => setUsersVisible(!usersVisible)}
                            size="SMALL"
                            text={t(!usersVisible ? "mod_barrier_system_see_users_inside" : 'global_hide')}
                        />,
                    ] : []
                }
                size="MEDIUM"
            />
        );
}