import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78.398"
      height="90.709"
      version="1.1"
      viewBox="0 0 20.743 24"
    >
      <g fill="#000" strokeWidth="0.149" transform="translate(-59.837 -61.248)">
        <path d="M60.366 61.248h5.273a.53.53 45 01.53.53v22.875a.53.53 135 01-.53.53h-5.273a.53.53 45 01-.53-.53V61.777a.53.53 135 01.53-.529z"></path>
        <path d="M74.773 61.262h5.273a.53.53 45 01.529.53v22.875a.53.53 135 01-.53.53h-5.272a.53.53 45 01-.53-.53V61.791a.53.53 135 01.53-.53z"></path>
      </g>
    </svg>
  );
}

export default Icon;