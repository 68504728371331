import React from 'react';

import { GenericEmailsInput } from "./GenericEmailsInput";
import { useValidationInput } from "../../../Common/_hooks/useValidationInput";

export function ValidatedEmailsInput({
    setIsValid,
    forceWarnings,
    emails = [],
    handleChangeEmailsUnwired = (emails) => {},
    disableInput = false
}) {    

    //LOCAL STATE
    const {
        handleSetWiredValue: handleChangeEmails,
        WarningsComponent,
    } = useValidationInput({
        isRequired: true,
        setIsValid,
        forceWarnings,
        handleSetValue: handleChangeEmailsUnwired,
        value: emails.length,
    });

    //VIEW
    return (
        <div className='position-relative'>
            <GenericEmailsInput
                emails={emails}
                handleChangeEmails={handleChangeEmails}
                disableInput={disableInput}
            />
            <div className="mt-1 ml-1 c-absolute w-100">
                <WarningsComponent />
            </div>
        </div>
    );
}