import React from "react";
import { useTranslation } from 'react-i18next';

import { FILTER_DISABLED_ALL } from "../../Everou/_constants";
import { BUDGET_STATUS } from "../_constants/budgets.constants";
import { CustomDropdown } from "../../Common/_components/CustomDropdown";
import { BudgetUiHelper } from "./BudgetUiHelper";

export function BudgetStatusDropdown({
    showAllOption = false,
    status,
    setStatus = () => {},
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <CustomDropdown
            title={t("global_status")}
            value={status}
            onChange={setStatus}
            options={buildStatusFilterOptions()}
        />
    );

    function buildStatusFilterOptions() {
        const options = [];

        if (showAllOption) {
            options.push(
                CustomDropdown.buildOption(
                    FILTER_DISABLED_ALL.key,
                    FILTER_DISABLED_ALL.value
                )
            );
        }

        options.push(...Object.values(BUDGET_STATUS).map(status =>
            CustomDropdown.buildOption(
                status,
                BudgetUiHelper.getStatusUiTag(status),
            )
        ));

        return options;
    }
}