import { GenericMapperFactory } from "../../Everou/_mappers/MapperFactory";
import { DevUser } from "../_models/DevUser";

export const DevUserMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverUser) {
    return DevUser({
        uid:    serverUser.uid,
        name:   serverUser.name,
        email:  serverUser.email,
    });
}