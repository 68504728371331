export function DevOauth({
    client_id,
    name,
    redirect_uris,
    client_secret,
} = {}) {
    return {
        client_id,
        name,
        redirect_uris,
        client_secret
    };
}