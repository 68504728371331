import { Icons } from "../_assets";
import React from "react";
import { SwitcherButton } from "./SwitcherButton";

export function SwitchGeneric({
    handleSendAction = () => {},
    deviceStatus,

    leftSideStatus,
    centerSideStatus,
    rightSideStatus,

    offStatus,
    remote,
    leftButtonIcon = Icons.locker_lock,
    centerButtonIcon,
    rightButtonIcon = Icons.locker_unlock,
} = {}) {
    //LOCAL STATE
 

    //ACTION HANDLERS
    function handleClickLeft() {
        if (leftSideStatus === undefined) return;

        handleSendAction(leftSideStatus);
    }

    function handleClickRight() {
        if (rightSideStatus === undefined) return;

        handleSendAction(rightSideStatus);
    }

    function handleClickCenter() {
        if (centerSideStatus === undefined) return;

        handleSendAction(centerSideStatus);
    }

     return (
         <div className="d-flex align-items-center">
             <SwitcherButton remote={remote} icon={leftButtonIcon} onClick={handleClickLeft} />
             {centerButtonIcon && (
                 <SwitcherButton
                    remote={remote}
                    icon={centerButtonIcon}
                    size="SMALL"
                    className="ml-2"
                    onClick={handleClickCenter}
                 />
             )}
             {
                 <SwitcherButton
                    remote={remote}
                    icon={rightButtonIcon}
                    onClick={handleClickRight}
                    className="ml-2"
                 />
             }
         </div>
     );
     
}
