import React from "react";
import { useTranslation } from 'react-i18next';
import { TextInputComponent } from "../_components";
import { CustomDropdown } from "../_components/CustomDropdown";
import { ReadOnlyDataRow } from "../_components/ReadOnlyDataRow";
import { deviceTypeName, isEasyLock } from "../_constants";
import { DeviceFunctionality } from "../_helpers/DeviceFunctionality";
import { Device } from "../_models/Device";
import { Room } from "../_models/Room";

const BATTERY_CONNECTED_TO_POWER = 255;

export function DeviceCommonConfigPage({
    device = Device(),
    rooms = [] || [Room()],

    handleChangeName = () => {},
    handleChangeRoom = () => {},
    canEditDeviceRoom = false,
    canEditDeviceSettings = false,
}) {
    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const supportsRemainingUses = DeviceFunctionality.supportsRemainingUses(device.type);
    const supportsStreamMinutesRemaining = DeviceFunctionality.supportsStreamMinutesRemaining(device);
    const supportsBattery =             DeviceFunctionality.supportsBattery(device.type);
    //VIEW
    if (!device) {return "DEVICE NOT FOUND";}
    return (
        <div>
            {id()}
            {name()}
            {originalType()}
            {type()}
            <div className="mt-1"/>
            {room()}
            <div className="mt-1"/>
            {versioning()}
            {device.installationNotes && notes()}
            {supportsStreamMinutesRemaining && streamMinutesRemaining()}
            {supportsRemainingUses && remainingAutomaticOpenings()}
            {supportsBattery && battery()}
            {isEasyLock(device) && easyLockConfig()}
        </div>
    );
    
    function battery() {
        let data = '';

        if(device.battery === BATTERY_CONNECTED_TO_POWER) { 
            data = t('mod_device_connected_to_power');
        } else {   
            data = [null, undefined].includes(device.battery)
            ? t("mod_device_no_battery_data")
            : device.battery + "%";
        }
        return (
            <ReadOnlyDataRow name={t("webapp_device_battery")} data={data} />
        );
    }

    function easyLockConfig() {
        return <>
            <ReadOnlyDataRow data={t(device.isCalibrated ? 'global_yes' : 'global_no')} name={t('mod_device_calibrated')} />
        </>
    }

    function name() {
        return (
            <div className="mx-1 my-1">
                <TextInputComponent
                    title={t("webapp_global_name")}
                    value={device.description}
                    onChange={handleChangeName}
                    isEditable={canEditDeviceSettings}
                />
            </div>
        );
    }

    function originalType() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_global_type")}
            data={deviceTypeName(device.originalType)}
        />;
    }

    function type() {
        if (device.type === device.originalType)
            return;

        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_global_function")}
            data={deviceTypeName(device.type)}
        />;
    }

    function room() {
        return (
            <div className="
                d-flex
                justify-content-between
                align-items-center

                mx-1
            ">
                <div>
                    {t("webapp_global_room")}
                </div>
                <div>
                    <CustomDropdown
                        mode='to-left'
                        options={optionsFn()}
                        onChange={handleChangeRoom}
                        sortAlphabetically={true}
                        value={device.room_uid}
                        isReadOnly={!canEditDeviceRoom}
                    />
                </div>
            </div>
        );

        function optionsFn() {
            return rooms.map(room => 
                CustomDropdown.buildOption(
                    room.uid,
                    room.name,
                )
            );
        }
    }

    function id() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_global_id")}
            data={device.bluetoothId}
        />;
    }
    
    function versioning() {
        const versions = [
            {
                label: t("webapp_device_firmware_version"),
                version: device.version,
            },
            {
                label: t("device_setting_version_wifi"),
                version: device.versionWifi,
            },
            {
                label: t("device_setting_version_ble"),
                version: device.versionBle,
            },
        ].filter(({ version }) => version);
        return versions.map(({ label, version }, index) => (
            <ReadOnlyDataRow hasSeparators={false} key={index} name={label} data={version} />
        ));
    }

    function streamMinutesRemaining() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("device_remaining_stream_minutes")}
            data={device.streamMinutesRemaining}
        />
    }

    function notes() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_notes")}
            data={device.installationNotes}
        />;
    }

    function remainingAutomaticOpenings() {
        if (device.accessUsageCount === undefined)
            return "MISSING OPENINGS DATA";

        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_remaining_automatic_openings")}
            data={getString()}
        />;

        function getString() {
            if (device.accessUsageCount === null)
                return t("webapp_global_unlimited");

            return device.accessUsageCount;
        }
    }
}