import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { TranslationFactory } from "./TranslationFactory";

import ca_legacy from "./ca/ca_legacy.json";
import ca_common from "./ca/ca_common.json";

import en_legacy from "./en/en_legacy.json";
import en_common from "./en/en_common.json";

import es_legacy from "./es/es_legacy.json";
import es_common from "./es/es_common.json";

import fr_legacy from "./fr/fr_legacy.json";
import fr_common from "./fr/fr_common.json";

import de_legacy from "./de/de_legacy.json";
import de_common from "./de/de_common.json";

import it_legacy from "./it/it_legacy.json";
import it_common from "./it/it_common.json";

import pt_legacy from "./pt/pt_legacy.json";
import pt_common from "./pt/pt_common.json";

import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import ca from "date-fns/locale/ca";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import pt from "date-fns/locale/pt";

const resources = {
    en: TranslationFactory(en_legacy, en_common),
    es: TranslationFactory(es_legacy, es_common),
    ca: TranslationFactory(ca_legacy, ca_common),
    de: TranslationFactory(de_legacy, de_common),
    fr: TranslationFactory(fr_legacy, fr_common),
    it: TranslationFactory(it_legacy, it_common),
    pt: TranslationFactory(pt_legacy, pt_common),
};

const detectionOptions = {
    order: ['navigator'],
    caches: [],
};

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    detection: detectionOptions,
    resources,
    fallbackLng: "en",
    // lng: "en",
    // lng: "es",
});


function getDateFnLocale() {
    const dateFnsLocales = {
        en,
        es,
        ca,
        de,
        fr,
        it,
        pt,
    }
    
    return dateFnsLocales[getLocaleString()];
}

function getLocaleString() {
    switch (i18n.language) {
        case "es-ES":
            return "es";

        default:
            return i18n.language;
    }
}

export const LocalizationManager = {
    getDateFnLocale,
    getLocaleString,
};

export { i18n };