import { ReadOnlyDataRow } from "../_components/ReadOnlyDataRow";
import { useTranslation } from "react-i18next";
import { CustomCard, MainPageWithButtons } from "../../Common/_components";
import { User } from "../_modelsTS/User";
import QRCode from "react-qr-code";
import { CircleAvatar } from "../_components/CircleAvatar";

type UserConfigViewProps = {
    user: User;
};

export function UserConfigView({ user }: UserConfigViewProps) {
    const { t } = useTranslation();

    const content = (
        <div className="h-100 pt-3 d-flex flex-column">
            <div>
                <div className="d-flex justify-content-between pt-3 px-3 mb-5">
                    <div>
                        <h5 className="font-weight-bold">{user.name}</h5>
                        <h6 className="font-weight-bold">{user.lastName}</h6>
                        <p className="font-italic">{user.email}</p>
                    </div>
                    <CircleAvatar
                        style={{
                            width: "120px",
                            height: "120px",
                            objectFit: "cover",
                            objectPosition: "center center",
                            fontSize: '2.8rem',
                            marginLeft: '1rem'
                        }}
                        srcImg={user.avatar}
                        names={[user.name, user.lastName]}
                    />
                </div>
                <div>
                    <ReadOnlyDataRow
                        boldedName
                        name={t("mod_clockin_info")}
                        data={user.clocingAdditonalInfo}
                    ></ReadOnlyDataRow>
                    <ReadOnlyDataRow
                        boldedName
                        name={t("mod_clockin_nif")}
                        data={user.clockinLegalId}
                    ></ReadOnlyDataRow>
                </div>
            </div>
            {user.isNFCCard && user.nfcCard && (
                <div
                    style={{ paddingBottom: "60px" }}
                    className="d-flex justify-content-center align-items-end h-100"
                >
                    <CustomCard
                        style={{
                            borderRadius: "20px",
                            height: "54mm",
                            width: "85mm",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-center h-100 ml-3">
                            <div className="position-relative h-auto w-auto">
                                <QRCode size={90} value={user.nfcCard?.url} />
                            </div>
                            <small
                                className="ml-2 d-block"
                                style={{
                                    borderBottom: "1px solid #00a1e6",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <span className="text-uppercase text-primary">
                                    {t("global_code")}:{" "}
                                </span>
                                {user.nfcCard.code}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </small>
                        </div>
                    </CustomCard>
                </div>
            )}
        </div>
    );

    return <MainPageWithButtons content={content} size={"SMALL"} />;
}
