import React from "react";
import { useTranslation } from 'react-i18next';

import { MainPageWithButtons, ListedGroup, SimpleListElement } from "../_components";
import { Product } from "../_models/Product";

export function ProductsListView({
    products = [] || [Product()],
    handleClickProduct = () => {},
    selectedProductUid,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("mod_products_title")}
            content={content()}
        />
    );

    function content() {
        return products.length
            ? producstList()
            : noProductsMessage()
        ;

        function producstList() {
            return (
                <div className="mt-2">
                    <ListedGroup
                        elements={products.map(singleProduct)}
                    />
                </div>
            );
        }

        function noProductsMessage() {
            return (
                <div className="mt-2 text-center">
                    {t("mod_products_no_items_found")}
                </div>
            );
        }

        function singleProduct(product = Product()) {
            return (
                <SimpleListElement
                    isSelected={product.uid === selectedProductUid}
                    onClick={() => handleClickProduct(product.uid)}
                    text={product.name}
                />
            );
        }
    }
}