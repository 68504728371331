import { PMSUser } from "../_models/PMSUser";
import { GenericMapperFactory } from "../../Everou/_mappers/MapperFactory";

export const PMSUserMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverUser) {
    return PMSUser({
        uid:    serverUser.uid,
        name:   serverUser.name,
        email:  serverUser.email,
    });
}