import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import { history, HistoryHelper } from '../_helpers';
import { PrivateRoute } from './PrivateRoute';

import { WEBAPP_URLS, URL_CONSTANTS } from '../_constants';
import { LoginPage } from '../LoginModule/LoginPage';
import { RegisterPage } from '../LoginModule/RegisterPage';
import { WrappedApp } from '../App/WrappedApp';
import { Selectors } from '../_reducers/app.reducer';
import { EntrypointActions } from "../../Common/_stores/EntrypointStore/EntrypointActions";
import { SUBDOMAIN_IDS } from '../../SUBDOMAIN_IDS';
import { PMSOctorateRedirectPopup } from '../../PMS/ModuleIntegrations/_components/PMSOctorateRedirectPopup';

export function Routes() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const host = useSelector(Selectors.getEntrypointHost);
    
    //LOCAL STATE
    const [isInitialized, setIsInitialized] = useState(false);

    //INITIALIZATION
    useEffect(() => {
        const subdomain = HistoryHelper.getSubdomain();
        if (!subdomain) {
            setIsInitialized(true);
            return;
        }
        dispatch(EntrypointActions.getEntrypointHost(subdomain));
    }, [dispatch]);

    //EFFECTS
    useEffect(() => {
        if (host) {
            setIsInitialized(true);
            switch (host) {
                case SUBDOMAIN_IDS.BUDGETS:
                    document.title = "Baintex Budgets";
                    break;
    
                case SUBDOMAIN_IDS.DEVELOPERS:
                    document.title = "Baintex Developers Console";
                    break;
    
                case SUBDOMAIN_IDS.PMS:
                case SUBDOMAIN_IDS.RENTAL:
                    document.title = "Baintex Rental App";
                    break;
    
                default:
                    document.title = "Baintex Web App";
            }
        }

    }, [host]);

    if (!isInitialized) {
        return null;
    }

    return (
        <Router history={history}>
            <Switch>
                <Route 
                    path={WEBAPP_URLS.PRIVACY_POLICY} 
                    component={() => { 
                        window.location.href = URL_CONSTANTS.PRIVACY_POLICY;
                        return null;
                    }}
                />
                <Route 
                    path={WEBAPP_URLS.TERMS} 
                    component={() => { 
                        window.location.href = URL_CONSTANTS.TERMS_OF_USE;
                        return null;
                    }}
                />
                <Route
                    exact
                    path={WEBAPP_URLS.LOGIN}
                    component={LoginPage}
                />
                <Route
                    exact
                    path={WEBAPP_URLS.REGISTER}
                    component={RegisterPage}
                />
                <Route
                    exact
                    path={WEBAPP_URLS.OAUTH}
                    render={(props) => (
                        <LoginPage {...props} 
                            isOauth = {true}
                        />
                    )}
                />
                <Route
                    path={WEBAPP_URLS.OAUTH_OCTORATE}
                    render={(props) => (
                        <PMSOctorateRedirectPopup 
                            props={props}
                        />
                    )}
                />
                <PrivateRoute
                    host={host}
                    path={WEBAPP_URLS.ROOT}
                    component={WrappedApp}
                />
            </Switch>
        </Router>
    );
}