import React from "react";
import { useTranslation } from 'react-i18next';

import { CapsuleButton } from "./CapsuleButton";

type EditButtonsProps = {
    isEditing?:boolean,
    canDelete?:boolean,

    handleSave?: any,
    handleDiscard?: any,
    handleDelete?: any,
}
export function EditButtons({
    isEditing = false,
    canDelete = false,

    handleSave,
    handleDiscard,
    handleDelete,
}: EditButtonsProps) {
    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return isEditing
        ? editingButtons()
        : nonEditingButtons()
    ;

    function editingButtons() {
        return [
            saveButton(),
            discardButton(),
        ];

        //
        function saveButton() {
            return (
                <CapsuleButton
                    text={t("common.save")}
                    onClick={handleSave}
                />
            );
        }
        
        function discardButton() {
            return (
                <CapsuleButton
                    text={t("common.discard")}
                    onClick={handleDiscard}
                />
            );
        }
    }

    function nonEditingButtons() {
        return [
            deleteButton(),
        ];

        function deleteButton() {
            if (!canDelete)
                return;

            return (
                <CapsuleButton
                    text={t("common.delete")}
                    onClick={handleDelete}
                    style={`DANGER`}
                />
            );
        }
    }

}