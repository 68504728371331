import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import { Selectors } from "../_reducers/app.reducer";
import { LocationBillingDataActions } from "../_actions/LocationBillingDataActions";
import { ValidatedTextInput } from '../../Common/_components/ValidatedTextInput';
import { CapsuleButton } from "../_components";
import { CustomDropdown } from "../_components/CustomDropdown";
import { BillingData } from '../_modelsTS/BillingData';

type LocationBillingDataViewProps = {
    billingData: BillingData;
}

export function LocationBillingDataView({ billingData }: LocationBillingDataViewProps) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    //LOCAL STATE
    const [name, setName] = useState(billingData?.name);
    const [isNameValid, setIsNameValid] = useState(false);
    
    const [taxNumber, setTaxNumber] = useState(billingData?.taxNumber);
    const [isTaxNumberValid, setIsTaxNumberValid] = useState(false);
    
    const [streetAddress, setStreetAddress] = useState(billingData?.streetAddress);
    const [isStreetAddressValid, setIsStreetAddressValid] = useState(false);
    
    const [postalCode, setPostalCode] = useState(billingData?.postalCode);
    const [isPostalCodeValid, setIsPostalCodeValid] = useState(false);
    
    const [city, setCity] = useState(billingData?.city);
    const [isCityValid, setIsCityValid] = useState(false);
    
    const [state, setState] = useState(billingData?.state);
    const [isStateValid, setIsStateValid] = useState(false);
    
    const defaultCountry = (billingData?.country) || 'ES';
    const [country, setCountry] = useState(defaultCountry);
    const [isCountryValid] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState(billingData?.phoneNumber);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);


    useEffect(() => {
            setName(billingData?.name);
            setTaxNumber(billingData?.taxNumber);
            setStreetAddress(billingData?.streetAddress);
            setPostalCode(billingData?.postalCode);
            setCity(billingData?.city);
            setState(billingData?.state);
            if (billingData?.country) {
                setCountry(billingData.country);
            }
            setPhoneNumber(billingData?.phoneNumber);
    }, [dispatch, billingData]);

    function handleSave() {
        dispatch(LocationBillingDataActions.updateBillingData(locationUid, name, taxNumber, streetAddress, postalCode, city, state, country, phoneNumber));
    }


    const isSaveButtonEnabled = (
        (isNameValid && name?.trim() !== billingData?.name) ||
        (isTaxNumberValid && taxNumber?.trim() !== billingData?.taxNumber) ||
        (isStreetAddressValid && streetAddress?.trim() !== billingData?.streetAddress) ||
        (isPostalCodeValid && postalCode?.trim() !== billingData?.postalCode) ||
        (isCityValid && city?.trim() !== billingData?.city) ||
        (isStateValid && state?.trim() !== billingData?.state) ||
        (isCountryValid && country?.trim() !== billingData?.country) ||
        (isPhoneNumberValid && phoneNumber?.trim() !== billingData?.phoneNumber)
        );
        

    //VIEW
    return (
        <div className='mt-4'>
            <BillingDataInput textName={t("mod_billing_data_name")} testID={"location-billing-data-name"} isRequired={true} initialValue={name} setValue={setName} setIsValid={setIsNameValid}/>
            <BillingDataInput textName={t("mod_billing_data_tax_number")} testID={"location-billing-data-tax-number"} isRequired={true} initialValue={taxNumber} setValue={setTaxNumber} setIsValid={setIsTaxNumberValid}/>
            <BillingDataInput textName={t("mod_billing_data_street_address")} testID={"location-billing-data-street-address"} isRequired={true} initialValue={streetAddress} setValue={setStreetAddress} setIsValid={setIsStreetAddressValid}/>
            <BillingDataInput textName={t("mod_billing_data_postal_code")} testID={"location-billing-data-postal-code"} isRequired={true} initialValue={postalCode} setValue={setPostalCode} setIsValid={setIsPostalCodeValid}/>
            <BillingDataInput textName={t("mod_billing_data_city")} testID={"location-billing-data-city"} isRequired={true} initialValue={city} setValue={setCity} setIsValid={setIsCityValid}/>
            <BillingDataInput textName={t("mod_billing_data_state")} testID={"location-billing-data-state"} isRequired={true} initialValue={state} setValue={setState} setIsValid={setIsStateValid}/>
            <BillingDataSelector textName={t("mod_billing_data_country")} testID={"location-billing-data-country"} initialValue={country} setValue={setCountry} options={countryOptions()} />
            <BillingDataInput textName={t("mod_billing_data_phone_number")} testID={"location-billing-data-phone-number"} isRequired={true} initialValue={phoneNumber} setValue={setPhoneNumber} setIsValid={setIsPhoneNumberValid}/>
            <div className="d-flex justify-content-end mt-3">
                <CapsuleButton
                        testId={"location-billing-data-button-save"}
                        text={t("global_save")}
                        onClick={handleSave}
                        isEnabled={isSaveButtonEnabled}
                />
            </div>
        </div>
    );
}



function countryOptions() {
    return [
        CustomDropdown.buildOption('ES','España'),
    ];
}


function BillingDataInput({textName, testID, initialValue, setValue, setIsValid, isRequired }) {

    return (
        <div>
            <div className="p-1 py-2">
                <div
                    data-testid={testID}
                    className="d-flex align-items-center justify-content-between"
                >
                    <h3>
                        {textName}
                    </h3>
                    <ValidatedTextInput
                        isRequired={isRequired}
                        isEditable
                        testID={testID}
                        value={initialValue}
                        className='c-all-text-right w-50'
                        setValue={setValue}
                        setIsValid={setIsValid}
                    />
                </div>
            </div>
        </div>
    );
}

function BillingDataSelector({textName, testID, initialValue, setValue, options }) {

    return (
        <div
            data-testid={testID}
            className="d-flex align-items-center justify-content-between p-1 my-1"
        >
            <h3>{textName}</h3>
            <div className="c-all-text-right w-50">
                <CustomDropdown
                    isSearchable={false}
                    options={options}
                    onChange={setValue}
                    value={initialValue}
                />
            </div>
        </div>
    );
}
