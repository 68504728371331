import React from "react";
import { useTranslation } from 'react-i18next';

const fn = () => {};

const testIDs = {
    root: "email-input",
    input: "email-input-input",
    acceptButton: "email-input-accept-button",
};

EmailInput.testIDs = testIDs;

export function EmailInput({
    email,
    setEmail = fn,
    handleConfirm = fn,
    buttonName,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <form
            data-testid={testIDs.root}
            onSubmit={e => {
                e.preventDefault();
                handleConfirm();
            }
        }
            className="
                d-flex
                align-items-center
            "
        >
            {inputField()}
            {addButton()}
        </form>
    );

    function inputField() {
        return (
            <input
                data-testid={testIDs.input}
                className="
                    px-2
                    py-1

                    border
                    border-right-0
                    rounded-left
                "
                placeholder={t("common.emailPlaceholder")}
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
        );
    }

    function addButton() {
        return (
            <div
                data-testid={testIDs.acceptButton}
                onClick={handleConfirm}
                className="
                    px-2
                    py-1

                    border
                    cp                    
                    rounded-right
                    c-btn-primary
                    c-text-capitalize-first
                "
            >
                {buttonName || t("global_add")}
            </div>
        );
    }
}