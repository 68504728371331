import { LOCATION_PERMISSIONS } from "./permissions.constants";
import { i18n } from "../_translations/i18n";
import { WeekDaysSlots } from "../_models/Invitation";
import { StatusIndicatorStatusType } from "../_components/StatusIndicator";

export const INVITATIONS_SECONDARY_VIEWS = {
    CREATE_INVITATION:          "CREATE_INVITATION",
    EDIT_INVITATION:            "EDIT_INVITATION",
    INVITATION_GROUP_CONFIG:    "INVITATION_GROUP_CONFIG",
    CREATE_ACCESS_LINK_INVITATION: "CREATE_ACCESS_LINK_INVITATION", 
};

export const INVITATION_FILTER_TYPES = {
    STATUS: "STATUS",
    PERMISSION: "PERMISSION",
};

export const NULL_MAIL = "No email";

export const FILTER_DISABLED_ALL = {
    value: i18n.t("common.all"),
    key: -1,
};

export const FILTER_GROUP_NO_GROUP = 1;

export const INVITATION_PERIODS_LEGACY = { // LEGACY
    ALWAYS: 3,
    TEMPORARY: 2,
    RECURRENT: 1
}

export const INVITATION_PERIODS = { // NEW ONES
    ALWAYS: 3,
    TEMPORARY: 2
}

export const INVITATION_TYPES = {
    LOCATION:       1,
    ROOM:           2,
};

export const INVITATION_STATUS = {
    REQUESTED:          1,
    VALIDATED:          2,
    ACCEPTED:           3,
    CANCELLED:          4,
    EXPIRED:            5,
    ACCEPTED_EXPIRED:   6,
}

export const WeekDays = {
    MONDAY: '0',
    TUESDAY: '1',
    WEDNESDAY: '2',
    THURSDAY: '3',
    FRIDAY: '4',
    SATURDAY: '5',
    SUNDAY: '6'
};

export const WEEKDAYS_STRING_TO_NUMBER = {
    "monday": [WeekDays.MONDAY],
    "tuesday": [WeekDays.TUESDAY],
    "wednesday": [WeekDays.WEDNESDAY],
    "thursday": [WeekDays.THURSDAY],
    "friday": [WeekDays.FRIDAY],
    "saturday": [WeekDays.SATURDAY],
    "sunday": [WeekDays.SUNDAY]
} 

export const LOCAL_INVITATION_STATUS = {
    REQUESTED: "REQUESTED",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    CANCELLED: "CANCELLED",
    UNDEFINED: "UNDEFINED LOCAL STATUS",
    EXPIRED: "EXPIRED"
};

export const LOCAL_INVITATION_STATUS_TO_STATUS_INDICATOR: { [key: string]: StatusIndicatorStatusType} = {
    [LOCAL_INVITATION_STATUS.REQUESTED]: 'disabled',
    [LOCAL_INVITATION_STATUS.ACTIVE]: 'success',
    [LOCAL_INVITATION_STATUS.INACTIVE]: 'warning',
    [LOCAL_INVITATION_STATUS.CANCELLED]: 'error',
    [LOCAL_INVITATION_STATUS.UNDEFINED]: 'warning',
    [LOCAL_INVITATION_STATUS.EXPIRED]: 'warning'
}

export const invitationTypeUiValues = {
    [INVITATION_TYPES.LOCATION]: i18n.t("common.location"),
    [INVITATION_TYPES.ROOM]: i18n.t("common.room"),
};

export const invitationPeriodUiValues = { 
    [INVITATION_PERIODS.ALWAYS]: i18n.t("invitations.always"),
    [INVITATION_PERIODS.TEMPORARY]: i18n.t("invitations.temporary"),
}

export const invitationPermissionUiValues = {
    [LOCATION_PERMISSIONS.OWNER]: i18n.t("global_role_owner"),
    [LOCATION_PERMISSIONS.ADMIN]: i18n.t("global_role_admin"),
    [LOCATION_PERMISSIONS.GUEST]: i18n.t("global_role_guest"),
    [LOCATION_PERMISSIONS.INSTALLER]: i18n.t("global_role_installer"),
    [LOCATION_PERMISSIONS.NO_ACCESS]: i18n.t("common.noAccess"),
};


export const invitationLocalStatusUiValues = {
    [LOCAL_INVITATION_STATUS.REQUESTED]: i18n.t("invitations.requested"),
    [LOCAL_INVITATION_STATUS.ACTIVE]: i18n.t("mod_invitations_status_active"),
    [LOCAL_INVITATION_STATUS.INACTIVE]: i18n.t("mod_invitations_status_inactive"),
    [LOCAL_INVITATION_STATUS.CANCELLED]: i18n.t("mod_invitations_status_cancelled"),
    [LOCAL_INVITATION_STATUS.EXPIRED]: i18n.t("mod_invitations_status_expired")
}

export const invitationStatusUiValues = {
    [INVITATION_STATUS.REQUESTED]: i18n.t("invitations.requested"),
    [INVITATION_STATUS.VALIDATED]: i18n.t("invitations.validated"),
    [INVITATION_STATUS.ACCEPTED]: i18n.t("invitations.accepted"),
    [INVITATION_STATUS.CANCELLED]: i18n.t("invitations.cancelled"),
    [INVITATION_STATUS.EXPIRED]: i18n.t("invitations.expired"),
    [INVITATION_STATUS.ACCEPTED_EXPIRED]: i18n.t("invitations.acceptedExpired"),
};

export const invitationPermissionDescriptions = {
    [LOCATION_PERMISSIONS.ADMIN]: i18n.t("permission_admin_description"),
    [LOCATION_PERMISSIONS.GUEST]: i18n.t("permission_guest_description"),
    [LOCATION_PERMISSIONS.INSTALLER]: i18n.t("permission_installer_description"),
};



export const weekdayUiValues = {
    [WeekDays.MONDAY]: i18n.t("common.monday"),
    [WeekDays.TUESDAY]: i18n.t("common.tuesday"),
    [WeekDays.WEDNESDAY]: i18n.t("common.wednesday"),
    [WeekDays.THURSDAY]: i18n.t("common.thursday"),
    [WeekDays.FRIDAY]: i18n.t("common.friday"),
    [WeekDays.SATURDAY]: i18n.t("common.saturday"),
    [WeekDays.SUNDAY]: i18n.t("common.sunday"),
};

export const ALLOWED_SUBINVITATIONS = {
    MIN: 1,
    MAX: 20
}


export const EXAMPLE_TIME_SLOTS: WeekDaysSlots = {
    [WeekDays.MONDAY]: [{
        start: '09:00',
        end: '17:00',
    }],
    [WeekDays.TUESDAY]: [{
        start: '09:00',
        end: '17:00',
    }],
    [WeekDays.WEDNESDAY]: [{
        start: '09:00',
        end: '17:00',
    }],
    [WeekDays.THURSDAY]: [{
        start: '09:00',
        end: '17:00',
    }],
    [WeekDays.FRIDAY]: [{
        start: '09:00',
        end: '17:00',
    }],
}

export const MIN_TIME = "00:00:00";
export const MIN_TIME_NO_SECONDS = "00:00";
export const MAX_TIME = "23:59:59";
export const MAX_TIME_NO_SECONDS = "23:59";
export const TIME_FORMAT = "HH:mm:ss";
