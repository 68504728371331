import { Mobile } from "../_models/Mobile";

export class MobileMapper {
    static allServerToLocal(serverMobiles = []) {
        return serverMobiles.map(this.serverToLocal);
    }

    static serverToLocal(serverMobile) {
        if (!serverMobile)
            return null;

        return Mobile({
            type: serverMobile.type,
            activityStatusPermission: serverMobile.activity_status_permission,
            appVersion: serverMobile.app_version,
            
            blePermission: serverMobile.ble_permission,
            bleScanPermission: serverMobile.bluetooth_scan_permission,
            backgroundLocationPermission: serverMobile.background_location_permission,
            locationPermission: serverMobile.location_permission,
            locationServicePermission: serverMobile.location_service_permission,
            modelVersion: serverMobile.model_version,
            notificationsPermission: serverMobile.notifications_permission,
            batteryPermission: serverMobile.additional_config,

            osVersion: serverMobile.os_version,
            osVersionDescription: serverMobile.os_version_description,

            isActive: serverMobile.is_active,
            isUpToDate: !serverMobile.outdated_app,
        });
    }
}