import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "../GlobalPopUps/BaseAppGlobalPopUp";
import { invitationGroupActions } from "../_actions/invitationGroup.actions";
import { invitationsActions } from "../_actions";
import { locationInfoActions } from "../_actions";
import { InvitationGroup } from "../_models/InvitationGroup";
import { LocationInfo } from '../_models/LocationInfo';
import { Selectors } from "../_reducers/app.reducer";

type InvitationsCheckOutPopUpProps = {
    creditsConsumed: number;
    paidCredits: number;
    freeInvitations: number;
    createPublicUrl: boolean;
    emails: string[];
    totalPrice: string;
    group: any; // To be types asap when InvitationGroup type exists
    beforeSend?: () => void;
    onSuccess?: () => void;
}

export function InvitationsCheckOutPopUp({
    creditsConsumed,
    paidCredits,
    freeInvitations,
    emails,
    createPublicUrl,
    totalPrice,
    group = InvitationGroup(),
    beforeSend,
    onSuccess
}: InvitationsCheckOutPopUpProps) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    let locationInfo                = LocationInfo();
    locationInfo                    = useSelector(Selectors.getLocationInfo);
    //GLOBAL STATE
    const selectedLocationUid = useSelector(Selectors.getSelectedLocationUid);

    //ACTION HANDLERS
    async function handlePayment() {
        beforeSend && beforeSend();
        await dispatch(invitationGroupActions.addUsersToGroup(
            group.uid,
            emails,
            createPublicUrl
        ));
        dispatch(invitationsActions.changeSecondaryView(null));
        await dispatch(locationInfoActions.getLocationInfo(selectedLocationUid));
        onSuccess && onSuccess();
    }


    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={t("global_checkout")}
            body={body()}
            onAccept={handlePayment}
            acceptButtonName={t('global_pay')}
            limitWidth
        />
    );

    function body() {
        let totalPriceFloat = parseFloat(totalPrice);
        if (isNaN(totalPriceFloat)) {
            totalPriceFloat = 0.0;
        }


        if (!locationInfo.canBuyInvitations) {
            return (
                <div>
                    <div className="text-right">
                        <div className="my-2 text-right">
                            {t("mod_billing_number_of_invitations")}: {emails.length}
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div>
                <div className="text-right">
                    {creditsConsumed > 0 &&
                        <div className="my-2 text-right">
                            {t("mod_billing_credits_consumed")}: {creditsConsumed}
                        </div>
                    }
                    {freeInvitations > 0 &&
                        <div className="my-2 text-right">
                            {t("mod_billing_free_invitations")}: {freeInvitations}
                        </div>
                    }
                    {paidCredits > 0 &&
                        <div className="my-2 text-right">
                            {t("mod_billing_payment_invitations")}: {paidCredits}
                        </div>
                    }
                    <div className="my-2 text-right">
                        {t("mod_billing_price_of_invitation_pw", { price: totalPrice })}
                    </div>
                    <div className="my-2 text-right">
                        {t("mod_billing_number_of_invitations")}: {emails.length}
                    </div>


                    <div className="border-bottom" />

                    <div className="my-2 text-right font-weight-bold">
                        {t("mod_billing_price_of_invitation_total_pw", { price: totalPrice })}
                    </div>

                    <div className="border-bottom mb-2" />

                    <div className="text-secondary">
                        {t("mod_billing_explanation_1")}
                        <br />
                        {t("mod_billing_explanation_2")}
                    </div>
                </div>
            </div>
        );
    }
}