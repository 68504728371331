import { Selectors } from "../../_reducers/app.reducer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";
import { invitationsService } from "../../_services";
import { InvitationsMapper } from "../../_mappers";
import { ReadOnlyDataRow } from "../../_components/ReadOnlyDataRow";
import { CapsuleButton, CaptionText } from "../../../Common/_components";
import { GLOBAL_POPUPS, INVITATION_STATUS, LOCAL_INVITATION_STATUS, LOCATION_PERMISSIONS } from "../../_constants";
import { SimpleToggle, SingleAccordion, Paginator } from "../../_components";
import { GenericEmailsInput } from "../_components/GenericEmailsInput";
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";
import CreatePublicUrlToggle from "../CreatePublicUrlToggle";
import { INVITATION_GROUP_TYPES } from '../../_constants/invitationsGroup.constants';
import { removeDuplicates } from "../../../Common/_utils/utils";
import { UiPriceHelper } from "../../_helpers/UiPriceHelper";
import { popUpActions } from "../../_stores/PopUpStore/popUpActions";
import { InvitationsHelper } from "../../_helpers";
import { InvitationGroupHelper } from "../../_helpers/InvitationGroupHelper";
import { invitationsActions } from '../../_actions';
import { CustomModal } from '../../_components/CustomModal';
import { NewInvitationGroupTemporary } from '../NewInvitationGroupTemporary';
import { useValidation } from '../../_hooks/useValidation';
import { Invitation } from '../../_models/Invitation';
import { NewAccessLink } from "../NewAccessLink";
import { Icons } from "../../_assets";

const PAGE_SIZE = 20;

type ConsumesCreditType = { consumingCredit: string[]; }
type noConsumesCreditType = { noConsumingCredit: string[]; }

type ClassifiedEmails = ConsumesCreditType & noConsumesCreditType;

export function InvitationsGroupToggleList({ group, onGetInvitations }) {

    const groupUid = group.uid;
    const groupType = group.groupType;
    const invitationNetPrice = group.invitationNetPrice;

    const invitation = Invitation();
    invitation.invitationGroupUid = groupUid;
    invitation.permissionType = LOCATION_PERMISSIONS.GUEST;
    invitation.allowsSubinvitations = false;

    // LIBS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const [createPublicUrl, setCreatePublicUrl] = useState(false);
    const locationInfo = useSelector(Selectors.getLocationInfo);
    const allGroups = useSelector(Selectors.getInvitationGroups);
    const invitationsLeft = useSelector(Selectors.getLocationInvitationConfig)?.invitationsLeft;
    const currentUserEmail = useSelector(Selectors.getUserEmail);
    // LOCAL STATE
    const [inputEmails, setInputEmails] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [invitations, setInvitations] = useState<any | null>();
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    
    const containerRef = useRef<HTMLDivElement>(null);
    
    const [classifiedEmails, setClassifiedEmails] = useState<ClassifiedEmails>({ consumingCredit: [], noConsumingCredit: [] })
    
    const [showEmailsInput, setShowEmailsInput] = useState(false);
    const [showAccessLinkCreationInput, setShowAccessLinkCreationInput] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

 
    useEffect(() => {
        setClassifiedEmails({ consumingCredit: [], noConsumingCredit: [] });
    }, [group]);


    const pushToConsumed = (email: string, classifiedEmails: ClassifiedEmails): ClassifiedEmails => {
        return { ...classifiedEmails, consumingCredit: [...classifiedEmails.consumingCredit, email] };
    }
    
    const pushToNoConsumed = (email: string, classifiedEmails: ClassifiedEmails): ClassifiedEmails => {
        return { ...classifiedEmails, noConsumingCredit: [...classifiedEmails.noConsumingCredit, email] };
    }

    const removeFromClassifiedEmails = (emails: string[], classifiedEmails: ClassifiedEmails): ClassifiedEmails => {

        let { consumingCredit, noConsumingCredit } = classifiedEmails;
        consumingCredit = consumingCredit.filter(email => !emails.includes(email));
        noConsumingCredit = noConsumingCredit.filter(email => !emails.includes(email));

        return { consumingCredit, noConsumingCredit }

    }

    const showErrorMessage = (message) => {
            setErrorMessage(message);
            setTimeout(() => setErrorMessage(''), 4000);
    }
    
    const getAlreadyPaidCreditsUsed = (invitationsLeft: number, classifiedEmails: ClassifiedEmails) => {
        return Math.min(invitationsLeft, classifiedEmails.consumingCredit.length);
    }
    
    const getNewPaidCredits = (invitationsLeft: number, classifiedEmails: ClassifiedEmails) => {
        return classifiedEmails.consumingCredit.length - getAlreadyPaidCreditsUsed(invitationsLeft, classifiedEmails);
    }

    const unlimitedInvitations = useMemo(() => {
        return [locationInfo.invitationsLeft, locationInfo.invitationsAllowed].every(x => x === null);

    }, [locationInfo.invitationsLeft, locationInfo.invitationsAllowed]);


    const getEmailOptions = async (
        email: string,
    ) => {

            if (email === currentUserEmail) {
                throw Error(t("mod_invitations_email_already_in_location"));
            }

            let invitationsForEmail =
                await invitationsService.getInvitationsList({
                    search_term: email,
                    offset: 0,
                    page_size: 100,
                    loc_uid: locationInfo.uid,
                });

            invitationsForEmail = InvitationsMapper.mapAllServerToLocal(
                invitationsForEmail.invitations,
            );

            let invitable = true;
            let consumesCredit = true;
            const invitation = invitationsForEmail.find(
                ({ email: invitationEmail }) => email === invitationEmail,
            );

            if (!invitation) return { invitable, consumesCredit };

            consumesCredit = false;
            invitable = InvitationGroupHelper.canInvitationBeAddedToGroup(
                group,
                allGroups,
                invitation,
            );
            return { invitable, consumesCredit };
        
    };

     const hasReachedMaxInputEmails = useCallback((classifiedEmailsLocal) => !unlimitedInvitations &&
        !locationInfo.canBuyInvitations &&
        classifiedEmailsLocal.consumingCredit.length >
        invitationsLeft, [invitationsLeft, locationInfo?.canBuyInvitations, unlimitedInvitations]);
    


    const onAddEmailsFromInput = async (emails: string[] = []) => {

        if (emails.length < inputEmails.length) {

            const differenceAfterRemove = inputEmails.filter(
                (email) => !emails.includes(email),
            );

            setClassifiedEmails(
                removeFromClassifiedEmails(
                    differenceAfterRemove,
                    classifiedEmails,
                ),
            );            
            
            setInputEmails(emails);

        } else {

            emails = removeDuplicates([...emails]);

            try {

                const differenceAfterDelete = emails.filter(
                    (email) => !inputEmails.includes(email),
                );

                await differenceAfterDelete.reduce<
                    Promise<{
                        classifiedEmailsLocal: ClassifiedEmails;
                        inputEmailsLocal: string[];
                    }>
                >(
                    async (acc, email) => {
                        let {
                            classifiedEmailsLocal,
                            inputEmailsLocal,
                        } = await acc;

                        const option = await getEmailOptions(email);
                        const { consumesCredit } = option;

                        classifiedEmailsLocal = consumesCredit
                            ? pushToConsumed(email, classifiedEmailsLocal)
                            : pushToNoConsumed(email, classifiedEmailsLocal);

                        if (hasReachedMaxInputEmails(classifiedEmailsLocal)) {
                            throw Error(
                                t("mod_invitations_max_input_email_reached"),
                            );
                        } else {
                            setClassifiedEmails(classifiedEmailsLocal);
                            inputEmailsLocal = [...inputEmailsLocal, email];
                            setInputEmails(inputEmailsLocal);
                        }

                        return {
                            classifiedEmailsLocal,
                            inputEmailsLocal,
                        };
                    },
                    new Promise((resolve) => {
                        resolve({
                            classifiedEmailsLocal: classifiedEmails,
                            inputEmailsLocal: inputEmails,
                        });
                    }),
                );
            } catch (err: any) {
                showErrorMessage(err.message);
            }
        }
    };

    const sendInvitations = useCallback(async (emails: string[]) => {

        try {
            
            if(!InvitationsHelper.hasUnlimitedInvitations(locationInfo) && locationInfo.canBuyInvitations) {
                
                const newPaidCredits = getNewPaidCredits(invitationsLeft, classifiedEmails); 
                const creditsConsumed = getAlreadyPaidCreditsUsed(invitationsLeft, classifiedEmails);

                dispatch(popUpActions.showPopUp(
                    GLOBAL_POPUPS.INVITATIONS_CHECKOUT,
                    {
                        emails,
                        paidCredits: newPaidCredits,
                        creditsConsumed,
                        freeInvitations: classifiedEmails.noConsumingCredit.length,
                        createPublicUrl,
                        totalPrice: UiPriceHelper.getTotalPriceString(getNewPaidCredits(invitationsLeft, classifiedEmails) * invitationNetPrice),
                        group,
                        beforeSend: () => {
                            setLoading(true);
                            setInputEmails([]);
                            setShowEmailsInput(false);
                        },
                        onSuccess: () => {
                            fetchData(locationInfo.uid, groupUid);
                        }
                    }
                ));
            } else {
                setInputEmails([]);
                setShowEmailsInput(false);
                await dispatch(invitationGroupActions.addUsersToGroup(groupUid, emails, createPublicUrl));
                fetchData(locationInfo.uid, groupUid, offset);
                setCreatePublicUrl(false);
            }
        } finally {
            setLoading(false);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classifiedEmails, createPublicUrl, dispatch, getNewPaidCredits, group, groupUid, invitationNetPrice, invitationsLeft, locationInfo, locationInfo.uid, offset])

    async function addEmailsToGroup() {
        await dispatch(invitationGroupActions.addEmailsToGroup(groupUid));
        fetchData(locationInfo.uid, groupUid, offset);
    };

    const [showModalCreateInvitation, setShowModalCreateInvitation] = useState(false);
    const [showModalCreateAccessLink, setShowModalCreateAccessLink] = useState(false);
    const [showModalAccessLinkCreated, setShowModalAccessLinkCreated] = useState(false);

    const selectedAccessLink = useSelector(Selectors.getInvitationAccessLinkSelected);

    const deleteInvitationDraft = () => {
        dispatch(invitationsActions.deleteInvitationDraft())
    }

    async function createAccessLink() {
        await dispatch(invitationGroupActions.addLinkToGroup(groupUid));
        fetchData(locationInfo.uid, groupUid, offset);
    };

    const handleCopyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(selectedAccessLink);
    }, [selectedAccessLink]);

    const handleSendPublicUrlByWhatsApp = useCallback(() => {
        let url = "https://wa.me/?text=" + encodeURIComponent(selectedAccessLink);
        window.open(url, '_blank');
    }, [selectedAccessLink]);

    const handleSendPublicUrlByGmail = useCallback(() => {
        let url = "https://mail.google.com/mail?view=cm&tf=0&body=" + encodeURIComponent(selectedAccessLink);
        window.open(url, '_blank');
    }, [selectedAccessLink]);

    const { isAllValid } = useValidation();

    const toggleEmailsInputShow = show => {
        //if(!show) {
        //    setInputEmails([]);
        //} 
        //setShowEmailsInput(show);
        
        dispatch(invitationsActions.createLocalInvitation());
        setShowModalCreateInvitation(true);
    }

    const toggleAccessLinkCreationShow = show => {   
        dispatch(invitationsActions.createLocalAccessLink());
        setShowModalCreateAccessLink(true);
    }

    const onSendEmails = useCallback(() => {
        sendInvitations(inputEmails);
    }, [inputEmails, sendInvitations]);
    

    const onChangePage = offsetPage => {
        if(!locationInfo.uid) return;
        setOffset(offsetPage);
        fetchData(locationInfo.uid, groupUid, offsetPage);
    }    

    useEffect(() => {
        if(showEmailsInput && containerRef) {
            containerRef?.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [showEmailsInput])

    useEffect(() => {
        setShowModalAccessLinkCreated(selectedAccessLink !== null);
    }, [selectedAccessLink]);

    useEffect(() => { setClassifiedEmails({consumingCredit: [], noConsumingCredit: []}); setErrorMessage('') }, [group?.uid, showEmailsInput])

    async function handleToggleActiveInvitation(event, invitationUid, isEnabled) {

        event.stopPropagation();
        
        const getUpdatedInvitationsStatus = (invitationUid, isEnabled) => {
            return invitations.map(invitation => {
                if(invitation.uid === invitationUid) {
                    return {...invitation, enabled: isEnabled};
                } 
                return invitation;
            })
        }

        setInvitations(getUpdatedInvitationsStatus(invitationUid, isEnabled));

        try {
            await invitationsService.updateInvitation({
                uid: invitationUid,
                enabled: isEnabled,
            });
        } catch {
            setInvitations(getUpdatedInvitationsStatus(invitationUid, !isEnabled));
        }

    }
    
    async function fetchData(locationUid: string, group_uid = '', offset = 0) {
        if(!locationUid) return;
        setLoading(true);
        setInvitations(null);
        let { invitations, total_count } =
            await invitationsService.getInvitationsList({
                loc_uid: locationInfo.uid,
                group_uid,
                offset,
                page_size: PAGE_SIZE,
            });
        setTotalCount(total_count);
        setLoading(false);
        const mappedInvitations = InvitationsMapper.mapAllServerToLocal(invitations);
        setInvitations(mappedInvitations);
        onGetInvitations(mappedInvitations);
    }
    

    useEffect(() => {
        setOffset(0);
        setInputEmails([]);
        setShowEmailsInput(false);
        setTotalCount(0);
        fetchData(locationInfo.uid, groupUid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupUid, locationInfo.uid]);
    
     function isToggleVisible(status, localStatus) {
         if (status === INVITATION_STATUS.ACCEPTED_EXPIRED) return false;

         return (
             localStatus === LOCAL_INVITATION_STATUS.ACTIVE ||
             localStatus === LOCAL_INVITATION_STATUS.INACTIVE
         );
     }
        
    const invitationList = (invitations) => {
        return <div className="overflow-auto c-min-h-10rem c-mh-15rem px-3 p-2">
        {invitations.map((invitation, index) => {
            const name = (
                <div className="w-100 justify-content-between d-flex align-items-center">
                    {invitation.email}{" "}
                    {isToggleVisible(
                        invitation.status,
                        invitation.localStatus,
                    ) && (
                            <div className="ml-1">
                                <SimpleToggle
                                    checked={invitation.enabled}
                                    size={"X_SMALL"}
                                    handleChange={(event) =>
                                        handleToggleActiveInvitation(
                                            event,
                                            invitation.uid,
                                            !invitation.enabled,
                                        )
                                    }
                                />
                                <div className="ml-2" />
                            </div>
                        )}
                </div>
            );
            const data = (
                <CaptionText className="text-left">
                    {invitation.userName}
                </CaptionText>
            );
            return (
                <ReadOnlyDataRow
                    key={invitation.uid}
                    boldedName
                    className="py-2 d-block flex-column"
                    hasSeparators={index !== invitations.length - 1}
                    name={name}
                    data={data}
                />
            );
        })}
    </div>

    }

    return (
        <>
            <div
                ref={containerRef}
                className={`${showEmailsInput ? "h-100" : "0"}`}
            >
                <div className="d-flex justify-content-between mb-3 ">
                    <h3 className="mb-0 ml-1">
                        {totalCount} {t("invitations.invitations")}
                    </h3>
                    {locationInfo.canAddUsersToGroup && (
                        <div>
                            <CapsuleButton
                                size="SMALL"
                                onClick={() =>
                                    toggleEmailsInputShow(!showEmailsInput)
                                }
                                style={showEmailsInput ? "DANGER" : "PRIMARY"}
                                text={t(
                                    showEmailsInput
                                        ? "global_cancel"
                                        : "global_add",
                                )}
                            />
                            <CapsuleButton
                                size="SMALL"
                                text={t("invitations.createAccessLink")}
                                onClick={() => { 
                                    toggleAccessLinkCreationShow(showAccessLinkCreationInput)
                                }}
                                style={"PRIMARY"}
                            />
                        </div>
                    )}
                </div>
                <SingleAccordion
                    style={{
                        container: {
                            marginTop: "6px",
                            marginBottom: "16px",
                        },
                    }}
                    setOpen={showEmailsInput}
                    body={
                        <div className="c-grid c-grid--small">
                            {groupType === INVITATION_GROUP_TYPES.LIMITED && (
                                <div className="px-2">
                                    <CreatePublicUrlToggle
                                        onChange={(value) =>
                                            setCreatePublicUrl(value)
                                        }
                                    />
                                </div>
                            )}
                            <div className="p-3  border c-rounded-big c-bg-grey-250">
                                <GenericEmailsInput
                                    emails={inputEmails}
                                    handleChangeEmails={onAddEmailsFromInput}
                                />
                                <div className="d-flex justify-content-between pt-2">
                                    <div>
                                        {!InvitationsHelper.hasUnlimitedInvitations(
                                            locationInfo,
                                        ) && (
                                            <div className="pl-1">
                                                <div className="text-secondary">
                                                    {t(
                                                        "mod_billing_remaining_credits",
                                                        {
                                                            credits:
                                                                invitationsLeft -
                                                                getAlreadyPaidCreditsUsed(
                                                                    invitationsLeft,
                                                                    classifiedEmails,
                                                                ),
                                                        },
                                                    )}
                                                </div>
                                                {getNewPaidCredits(
                                                    invitationsLeft,
                                                    classifiedEmails,
                                                ) > 0 && (
                                                    <div className="text-secondary">
                                                        {t(
                                                            "mod_billing_payment_invitations",
                                                        )}
                                                        :{" "}
                                                        {getNewPaidCredits(
                                                            invitationsLeft,
                                                            classifiedEmails,
                                                        )}
                                                    </div>
                                                )}
                                                {locationInfo.canBuyInvitations && (
                                                    <div className="text-secondary">
                                                        {t(
                                                            "mod_billing_price_of_invitation_total_pw",
                                                            {
                                                                price: UiPriceHelper.getTotalPriceString(
                                                                    getNewPaidCredits(
                                                                        invitationsLeft,
                                                                        classifiedEmails,
                                                                    ) *
                                                                        invitationNetPrice,
                                                                ),
                                                            },
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {errorMessage ? (
                                            <div className="text-danger pl-1 pt-1">
                                                {errorMessage}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                    <CapsuleButton
                                        classNameExtra="c-h-min flex-shrink-0"
                                        size="SMALL"
                                        onClick={onSendEmails}
                                        isEnabled={!!inputEmails?.length}
                                        text={t("common.send")}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
                {loading && (
                    <div className="d-flex justify-content-center">
                        <Spinner animation={"border"} />
                    </div>
                )}
                {!loading && !!invitations?.length && (
                    <div className="d-flex flex-column justify-content-between border rounded">
                        {invitationList(invitations)}
                        {totalCount > invitations?.length && (
                            <div className="p-2">
                                <Paginator
                                    total={totalCount}
                                    pageOffset={offset}
                                    pageSize={PAGE_SIZE}
                                    onChangePage={onChangePage}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <CustomModal
                show={showModalCreateInvitation}
                centered
                onHide={() => setShowModalCreateInvitation(false)}
                title={t("invitations.newInvitation")}
                content={
                    <NewInvitationGroupTemporary
                        groupUid={groupUid}
                        timezone={locationInfo.timezone}
                        locationInfo={locationInfo}
                    />
                }
                footer={
                    <>
                        <CapsuleButton
                            style="DANGER"
                            text={t("global_cancel")}
                            onClick={() => {
                                setShowModalCreateInvitation(false);
                            }}
                        />
                        <CapsuleButton
                            isEnabled={isAllValid}
                            text={t("global_create")}
                            onClick={() => {
                                addEmailsToGroup()
                                setShowModalCreateInvitation(false);
                            }}
                        />
                    </>
                }
            />
            <CustomModal
                show={showModalCreateAccessLink}
                centered
                onHide={() => setShowModalCreateAccessLink(false)}
                title={t("invitations.createAccessLink")}
                content={
                    <NewAccessLink
                        timezone={locationInfo.timezone}
                        rooms={locationInfo.rooms}
                        showAccessType={false}
                    />
                }
                footer={
                    <>
                        <CapsuleButton
                            style="DANGER"
                            text={t("global_cancel")}
                            onClick={() => {
                                deleteInvitationDraft();
                                setShowModalCreateAccessLink(false);
                            }}
                        />
                        <CapsuleButton
                            isEnabled={isAllValid}
                            text={t("global_create")}
                            onClick={() => {
                                createAccessLink();
                                setShowModalCreateAccessLink(false);
                            }}
                        />
                    </>
                    }
                />
            <CustomModal
                    show={showModalAccessLinkCreated}
                    centered
                    onHide={() => setShowModalAccessLinkCreated(false)}
                    title={t("invitation_public_url_share")}
                    content={
                        <div>
                            <div className="d-flex align-items-center justify-content-between position-relative" style={{ maxWidth: '400px'}}>
                                <p>{t("invitation_public_url_share_description")}</p>
                            </div>
                            <br />
                            <div className="d-flex align-items-center justify-content-between position-relative">
                                <small style={{ maxWidth: '300px' }} className={"mr-2 w-75 c-line-height-1"}>
                                    <a
                                        className="d-block w-100 text-secondary"
                                        style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                        }}
                                        rel="noreferrer"
                                        href={selectedAccessLink}
                                        target="_blank"
                                    >
                                        {selectedAccessLink}
                                    </a>
                                </small>
                                <div className="d-flex align-items-center">{buttons()}</div>
                            </div>
                        </div>
                    }
                    footer={
                        <>
                            <CapsuleButton
                                isEnabled={true}
                                text={t("global_ok")}
                                onClick={() => {
                                    setShowModalAccessLinkCreated(false);
                                    dispatch(invitationsActions.selectInvitationAccessLink(null));
                                }}
                            />
                        </>
                    }
                />
        </>
    );

    function buttons() {
        return (
            <>
                <CapsuleButton
                    size="SMALL"
                    onClick={handleCopyToClipboard}
                    icon={Icons.copy}
                    tooltip={t('global_copy')}
                    boostedToolTip
                />
                <div className="ml-2"></div>
                <CapsuleButton
                    size="SMALL"
                    onClick={handleSendPublicUrlByWhatsApp}
                    // eslint-disable-next-line react/style-prop-object
                    icon={Icons.icon_whatsapp}
                    tooltip={t('global_send_whatsapp')}
                    boostedToolTip
                />
                <div className="ml-2"></div>
                <CapsuleButton
                    size="SMALL"
                    onClick={handleSendPublicUrlByGmail}
                    // eslint-disable-next-line react/style-prop-object
                    icon={Icons.icon_gmail}
                    tooltip={t('global_send_gmail')}
                    boostedToolTip
                />
            </>
        );
    }
}