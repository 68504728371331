import React from "react";

type GenericTextAreaProps = { 
    text?: string;
    placeholder?: string;
    handleEditText: (text: string) => void;
}
export function GenericTextArea({ text, placeholder, handleEditText }: GenericTextAreaProps) {
    return (
        <textarea
            className="px-2 py-1 w-100 border rounded"
            onChange={e => handleEditText(e.target.value)}
            value={text}
            placeholder={placeholder}
        />
    );
}