import React from "react";
import { useTranslation } from "react-i18next";

import { InvitationGroup } from "../../_models/InvitationGroup";
import { getRoomTypeIcon } from "../../_assets/roomTypeIcons";
import { MainPageWithButtons } from "../../_components";

import { INVITATION_TYPES } from "../../_constants";
import { Icons } from "../../_assets";

export function InvitationGroupWizardAddPackPage({
    invitationGroups = [] || [InvitationGroup()],
    handleSelectInvitationGroup = invitationGroup => {},
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();
    
    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("invitation_group_wizard_add_pack_title")}
            content={invitationGroupsFn()}
        />
    );

    function invitationGroupsFn() {
        return invitationGroups.map(singleInvitationGroup);
    }

    function singleInvitationGroup(invitationGroup = InvitationGroup()) {
        return (
            <div
                key={invitationGroup.uid}
                onClick={() => handleSelectInvitationGroup(invitationGroup)}
                className="
                    d-flex

                    py-2
                    mt-2

                    border
                    rounded

                    c-hover-bg-grey
                    c-cursor-default
                "
            >
                <div className="pl-2"/>
                {groupIcon()}
                <div className="ml-2"/>
                {invitationGroupInfo()}
                {rightIcon()}
            </div>
        );

        function groupIcon() {

            return (
                <img
                    alt={"room_icon"}
                    src={getIcon()}
                    className="
                        c-img-2rem
                        my-auto
                    "
                />
            );

            function getIcon() {
                if (invitationGroup.invitation.type === INVITATION_TYPES.LOCATION)
                    return Icons.home;

                if (invitationGroup.invitation.type === INVITATION_TYPES.ROOM)
                    return getRoomTypeIcon(
                        invitationGroup.invitation.rooms[0].type
                    );
            }
        }

        function invitationGroupInfo() {
            return (
                <div className="
                    my-auto
                    c-line-height-1rem
                ">
                    <div className="
                        font-weight-bold
                    ">
                        {invitationGroup.name}
                    </div>
                    <div>
                        {invitationGroup.invitation.roomName}
                    </div>
                </div>
            );
        }

        function rightIcon() {
            return (
                <img
                    alt={"right_arrow_icon"}
                    src={Icons.arrowRight}
                    className="
                        c-img-2rem
                        my-auto

                        ml-auto
                    "
                />
            );
        }
    }
}