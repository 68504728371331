import { BleActionDataMapper } from "../_mappers/BluetoothActionDataMapper";
import { BleActionData } from "../_models/BleActionData";
import { Device } from "../_models/Device";
import { BluetoothService } from "./BluetoothService";
import { deviceService } from "./device.service";

export const EverouBleService = {
    sendBleActionAutoOrManual,

    bleConnectAndSendActionAuto,
    bleConnectAndSendActionManual,
};


async function sendBleActionAutoOrManual({
    isBleInProgress = false,
    actionCode,

    device = Device(),

    setUp = () => {},
    onError = () => {},
    cleanUp = () => {},
    onSuccess = () => {},
    onRetry = () => {},
} = {}) {

    if (!BluetoothService.isThereBluetooth()) {
        console.error("Bluetooh not available on this machine");
        return null;
    }

    if (!Number.isInteger(actionCode)) {
        console.error("Action code not valid", actionCode);
        return;
    }

    if (isBleInProgress) {
        console.warn("BLE ACTION IN PROGRESS");
        return;
    }
    
    setUp();
    let actionData;
    try {
        actionData = BleActionDataMapper.serverToLocal(
            await deviceService.actionRequest(device.uid, actionCode),
        );
    } catch (e) {
        console.error("Error requesting action", e);
    }

    //PRESERVED IN CASE WE WANT TO USE ACTIONS
    // const actionData = await dispatch(
    //     devicesActions.requestBleAction(device.uid, actionCode)
    // );

    if (!actionData) {
        onError("Failed to get action");
        return;
    }

    if (await setUpAutoConnect(
        actionData,
        device,
        onError,
        cleanUp,
        onSuccess,
        onRetry,
    ))
        return;

    await manualConnect(
        actionData,
        device,
        onError,
        cleanUp,
        onSuccess,
        onRetry,
    );
}

function manualConnect(actionData, device, onError, cleanUp, onSuccess, onRetry) {
    return connectAndSendActionWrapper(
        () => EverouBleService.bleConnectAndSendActionManual({
            actionData,
            device,
            onRetry,
            onError,
        }),
        cleanUp,
        onSuccess,
    );
}

async function connectAndSendActionWrapper(
    bleConnectAndSendActionFn = async () => {},
    cleanUp = () => {},
    onSuccess = async () => {},
) {
    const bleActionSuccess = await bleConnectAndSendActionFn();

    if (bleActionSuccess)
        await onSuccess();

    cleanUp();
}

async function setUpAutoConnect(
    actionData,
    device = Device(),
    onError = () => {},
    cleanUp = () => {},
    onSuccess = () => {},
    onRetry = () => {},
) {
    const cachedDevice = await BluetoothService.getDeviceFromBleCache(device.bluetoothId);

    if (!cachedDevice)
        return false;

    await BluetoothService.subscribeAdvertisementsForDevice(
        cachedDevice,
        onAutoconnect,
    );

    const autoconnectTimeout = setTimeout(abortAutoconnect, 10 * 1000);
    return true;


    function abortAutoconnect() {
        BluetoothService.unsubscribcribeAdvetisementsForDevice(cachedDevice);
        onError("Device not found");
    }

    function onAutoconnect(event) {
        clearTimeout(autoconnectTimeout);

        connectAndSendActionWrapper(
            () => EverouBleService.bleConnectAndSendActionAuto(
                {
                    bleDevice: event.device,
                    actionData,
                    deviceUid: device.uid,
                    onRetry,
                    onError,
                }
            ),
            cleanUp,
            onSuccess,
        );
    }
}


async function bleConnectAndSendActionAuto({
    bleDevice,

    actionData,
    deviceUid,
    onRetry = retryNumber => {},
    onError = errorMessage => {},
} = {}) {

    return bleConnectAndSendAction({
        getCharacteriscticFn: () => BluetoothService.connectAndGetCharac(
            bleDevice,
            onRetry,
        ),

        actionData,
        deviceUid,
        onError,
    });
}

async function bleConnectAndSendActionManual({
    retryNumber = 0,
    actionData,
    device = Device(),
    onRetry = retryNumber => {},
    onError = errorMessage => {},
} = {}) {

    return bleConnectAndSendAction({
        getCharacteriscticFn: () => BluetoothService.connectManuallyAndGetCharac(
            buildBleServiceUuid(device.bluetoothId),
            onRetry,
        ),

        retryNumber,
        actionData,
        deviceUid: device.uid,
        onError,
    });

    function buildBleServiceUuid(bleUid) {
        const lowercaseString = bleUid.toLowerCase();
        const lowercaseArray = lowercaseString.split("");
        const hexPairsArray = lowercaseArray.reduce((result, value, index, array) => {
            if (index % 2 === 0)
              result.push(array[index] + array[index + 1]);
            return result;
        }, []);
    
        hexPairsArray.reverse();
        const uuid = hexPairsArray.reduce((result, value) => result + value, "");
        return uuid + "-0000-1000-8000-00805f9b34fb";
    }
}

async function bleConnectAndSendAction({
    actionData,
    deviceUid,
    onError = errorMessage => {},
    getCharacteriscticFn = async () => {},
} = {}) {

    let charac = {};
    try {
        charac = await getCharacteriscticFn();
        return await sendBleActionToDevice(charac, actionData, deviceUid);
    } catch (e) {

        console.error("Manual connect error:", e, e.message, e.name);

        if (e === "CONNECTION TIMEOUT") {
            handleError("Connection timeout");
            return;
        }

        if (e.message === "User cancelled the requestDevice() chooser.") {
            handleError("User cancelled");
            return;
        }

        if (e.message === "Too many connect retries") {
            handleError("Too many connect retries");
            return;
        }

        handleError("Other error " + e.message);
    }

    function handleError(errorMessage) {
        disconnect(charac);
        onError(errorMessage);
    }
}

async function sendBleActionToDevice(
    charac,
    actionData = BleActionData(),
    deviceUid,
    retry = true,
) {
    try {
        await BluetoothService.writeToCharacterictic(
            charac,
            hexStringToByteArray(buildStringPayload(actionData)),
        );
        console.warn("WRITE SUCCESS");
    } catch (e) {
        console.error("WRITE ERROR", e);
    }

    const readDataView = await BluetoothService.readCharacteristic(charac);
    const packet = getPacketFromReadData(readDataView);
    if (!packet) {
        console.error("read packet empty");
        disconnect(charac);
        return;
    }

    const confirmationData = BleActionDataMapper.serverToLocal(
        await deviceService.actionConfirm(deviceUid, actionData.actionUid, packet)
    );

    //PRESERVED IN CASE WE WANT TO USE ACTIONS
    // const confirmationData = await dispatch(devicesActions.confirmBleAction(
    //     device.uid,
    //     actionData.actionUid,
    //     packet,
    // ));

    if (!confirmationData.confirmed) {
        if (retry) {
            return await sendBleActionToDevice(
                charac,
                confirmationData,
                deviceUid,
                false,
            );
        }
        
        console.error("Ble action confirmation failure");
        disconnect(charac);
        return;
    }

    console.warn("BLE SUCCESS");
    disconnect(charac);
    return true;

    
    function getPacketFromReadData(readDataView) {
        const packet = arrayBuffer2Hex(readDataView.buffer);
        const trimmedPacket = packet.slice(2);
        console.warn("READ HEX", trimmedPacket, trimmedPacket.length);
        return trimmedPacket;
    
        function arrayBuffer2Hex(buffer) {
            return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
        }
    }

    function hexStringToByteArray(string) {
        const noSpacesSting = string.replace(/ /g, "");
        const pairs = noSpacesSting.match(/(..?)/g);
        const intPairs = pairs.map(hexPair => parseInt(hexPair, 16));
        return new Uint8Array(intPairs);
    }

    function buildStringPayload(bleActionData = BleActionData()) {
        return bleActionData.bleIdOrigin + parseHexAction(bleActionData.action) + bleActionData.packet;

        function parseHexAction(hexString) {
            const hexInt = parseInt(hexString, 16);
            return hexInt.toString().length === 2
                ? hexInt
                : "0" + hexInt.toString()
            ;
        }
    }
}

function disconnect(characteristic) {
    BluetoothService.disconnectFromCharacteristic(characteristic);
}