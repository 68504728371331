import { Icons } from "../../Everou/_assets";
import { forwardRef, RefObject } from "react";

type CustomCardProps = {
    children: any;
    className?: string;
    selected?: boolean;
    banner?: any;
    banner2?: any;
    style?: React.CSSProperties;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    ref?: RefObject<any>
    bannerBackground?: string;
    battery?: number;
    wifi_rssi?: number;
};

export const CustomCard = forwardRef(({
    children,
    selected = false,
    className,
    banner,
    banner2,
    style,
    bannerBackground,
    battery,
    wifi_rssi,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: CustomCardProps, ref: any) => {
    console.log(wifi_rssi);
    return (
        <div
            onMouseEnter={onMouseEnter && onMouseEnter}
            onMouseLeave={onMouseLeave && onMouseLeave}
            ref={ref}
            style={{ ...style }}
            className={`${className ?? ""}  ${selected
                    ? "c-z-index-100 c-bd-left-primary-large c-bd-primary"
                    : ""
            } ${!!onClick ? "c-hover-box-shadow cp" : ""} ${
                banner ? "pb-4 pt-3" : "pt-3 pb-4"
            } c-transition-fast c-h-min px-3 c-rounded-1rem position-relative bg-white c-bd-220`}
            onClick={onClick ? onClick : () => {}}
        >
            {!!battery && (<div className=" c-absolute-tr text-center" style={{paddingRight:"5px"}}>
                <small style={{color: battery<=30? "red": "#828282"}}>{battery}%</small>
                <img
                    className={`${"c-img-1-25rem"}`}
                    src={battery <= 30 ? Icons.battery_low:Icons.battery}
                    />
            </div>)}
            {!!wifi_rssi && (<div className=" c-absolute-tr text-center" style={{paddingRight:"5px"}}>
                <img
                    className={`${"c-img-1-25rem"}`}
                    src={wifi_rssi != 1 ? Icons.wifi_connection:Icons.wifi_no_connection}
                    />
            </div>)} 
            <div className="position-relative" style={{paddingTop:"5px"}}>{children}</div>
            {!!banner && (
                <small className=" c-absolute-br text-center">
                    <div
                        style={{
                            minWidth: "80px",
                            fontSize: "10px",
                            display: "flex",
                            flexDirection: "row-reverse",
                        }}
                    >
                        <div style={{ zIndex: 2, paddingTop: '2px', backgroundColor:bannerBackground?bannerBackground:'' }} className="px-4 c-bg-grey-240 c-rounded-tl-1rem c-rounded-br-1rem">{banner}</div>
                        {!!banner2 && (
                            <small
                                style={{
                                    minWidth: "80px",
                                    fontSize: "10px",
                                    whiteSpace: "nowrap",
                                    paddingTop: '2px',
                                    paddingRight: '28px !important',
                                    marginRight: '-12px',
                                    zIndex: 1
                                }}
                                className="c-bg-grey-250 px-4 c-rounded-tl-1rem text-center"
                            >
                                {banner2}
                            </small>
                        )}
                    </div>
                </small>
            )}
        </div>
    );
}) 
