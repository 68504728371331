import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { subscriptionsActions } from "../_actions/subscriptions.actions";

export function EditReservationsSubscriptionGlobalPopUp() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <BaseAppGlobalPopUp
            title={t("subscriptions.changeSubscriptionDevice")}
            body={body()}
            acceptButtonName={t("global_accept")}
            cancelButtonName={t("global_cancel")}
            onAccept={handleSave}
        />
    );

    function body() {
        return t("subscription_update_dialog");
    }

    function handleSave() {
        dispatch(subscriptionsActions.saveChangesToServer());
    }
}