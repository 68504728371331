import { ModulesActionsFactory } from "../Common/_stores/ModulesStore/ModulesActionsFactory";
import { PMSModules } from "./PMSModules";
import { PMSStore } from "./PMSStore";

export const genericModulesActions = ModulesActionsFactory({
    defaultAppModule: PMSModules.DEFAULT_MODULE,
});

export const PMSModulesActions = {
    selectModule: genericModulesActions.selectModule,
    setUpModules,
    _setUpModules: genericModulesActions.setUpModules,
};

function setUpModules() {
    return (dispatch, getState) => {
        dispatch(PMSModulesActions._setUpModules(
            PMSModules.getEnabledModuleIds(),
            PMSModules.getModuleIdForUrl(
                PMSStore.selectors.getInitialUrl(getState())
            ),
        ));
    }
}