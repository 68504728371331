import { Workday } from "../_modelsTS/Workday";
import { TimeHelper } from "./TimeHelper";

export class WorkdayHelper {
    static getTotalWorkMinutesFromWorkdays(workdays: Workday[]) {
        return workdays.reduce((totalMinutes, workday) => {
            const minutes = workday.workMinutes;
            return totalMinutes + (isNaN(minutes) ? 0 : minutes)
        }, 0);
    }

    static getTotalBreakMinutesFromWorkdays(workdays: Workday[]) {
        return workdays.reduce((totalMinutes, workday) => {
            const minutes = workday.breakMinutes;
            return totalMinutes + (isNaN(minutes) ? 0 : minutes)
        }, 0);
    }

    static getWorkingAndBreakHours(workDays: Workday[]) {

        let clockinMinutes = { 
                workingMinutes: this.getTotalWorkMinutesFromWorkdays(workDays), 
                breakMinutes: this.getTotalBreakMinutesFromWorkdays(workDays) 
        };

        return {
            workingHours: TimeHelper.minutesToTimeString(clockinMinutes.workingMinutes),
            breakHours: TimeHelper.minutesToTimeString(clockinMinutes.breakMinutes)
        }
    }
}