import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS, SERVER_LOG_TYPES } from "../_constants";

export const videoService = {
    listVideos,
    getVideo,
    getAllFragments,
};

async function listVideos(brainUid, page, startDate, endDate) {
    if (!startDate)
        startDate = "";

    if (!endDate)
        endDate = "";

    let params = `?\
        uid=${brainUid}\
        &offset=${page}\
        &log_type=${SERVER_LOG_TYPES.BRAIN_VIDEO}\
        &begin=${startDate}\
        &end=${endDate}\
    `;
    
    params = params.replace(/\s/g, '');
    const response = await ServiceHelper.getRequest(URL_CONSTANTS.BRAIN_LOG, params);

    return {
        logs: response.logs,
        next: response.next,
    };
}

async function getAllFragments(videoUids = []) {
    const allFragmentsPromises = [];
    for (let i = 0; i < videoUids.length; i++) {
        allFragmentsPromises.push(getVideo(videoUids[i]));
    }
    return await Promise.all(allFragmentsPromises);
}

async function getVideo(videoUid) {
    const params = `?uid=${videoUid}`;

    const response = await ServiceHelper.getRequestWithApikeyParam(URL_CONSTANTS.CAMERA_VIDEO, params);

    if (!response.ok) {
        const data = await response.json();
        return Promise.reject(
            `Error fetching video: ${response.status}-${response.statusText}` +
            ` / ${data.error_code}-${data.error_info}`
        );
    }

    return response;
}