import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { devicesActions } from "../_actions";
import { Device } from "../_models/Device";
import { DEVICES_ACTIONS_FOR_DEVICE_TYPE } from "../_constants";
import { Selectors } from "../_reducers/app.reducer";

export function EmergencyOpeningGlobalPopUp({ deviceUid }) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    let devices = [Device()];
    devices = useSelector(Selectors.getSelectedLocationDevices);

    //ACTION HANDLERS
    function handleOpenDoor() {
        const gowayDevice = devices.find(device => device.uid === deviceUid);
        dispatch(devicesActions.sendActionToDevice(
            DEVICES_ACTIONS_FOR_DEVICE_TYPE.GOWAY_LOCK.EMERGENCY_OPEN,
            gowayDevice,
        ));
    }

    //VIEW
    return (
        <BaseAppGlobalPopUp
            content={t("erros.emergencyOpeningMessage")}
            title={t("errors.emergencyOpeningQuestion")}
            body={t("errors.emergencyOpeningMessage")}
            onAccept={handleOpenDoor}
            acceptButtonName={t("common.accept")}
            cancelButtonName={t("common.cancel")}
        />            
    );
}