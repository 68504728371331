import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { PMS_API_URLS } from "../_constants/PMS_API_URLS";

export const PMSSmoobuService = {
    listProperties,
}

function listProperties(uid: string) {
    return ServiceHelper.getRequest(
        PMS_API_URLS.SMOOBU_PROPERTIES,
        {
            uid: uid
        }
    );
}