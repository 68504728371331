import { genericActions } from "./generic.actions";
import { protectService } from "../_services/protect.service";
import { TimeHelper } from "../_helpers";
import { LOCATION_INFO_ACTION_TYPES, APP_LOG_TYPES } from "../_constants";
import { uiAlertActions } from "./uiAlert.actions";
import { i18n } from "../_translations/i18n";
import { PROTECT_ACTION_TYPES } from "../_constants/protect.constants";
import { LogsMapper } from "../_mappers";
import { protectLogsService } from "../_services/protectLogs.service";
import { Selectors } from "../_reducers/app.reducer";
import { LocationInfo } from "../_models/LocationInfo";
import { ProtectLogsCollection } from "../_stores/ProtectLogsCollection";
import { ProtectLogsDateFilter } from "../_stores/ProtectLogsDateFilter";
import { logsActions } from ".";
import { ProtectSecondaryViewStore } from "../_stores/ProtectSecondaryViewStore";

export const protectActions = {
    toggleProtectStatus,
    toggleProtectOnEnter,
    toggleProtectOnExit,
    toggleProtectSchedule,
    updateProtectScheduleEnd,
    updateProtectScheduleBegin,

    muteProtect,

    getTriggeredLogs,

    getProctectLogsFirstPage: () => logsActions.getFirstPage(APP_LOG_TYPES.PROTECT),
    getProctectLogsNextPage: (nextPage, isRequesting) => logsActions.getNextPage(nextPage, isRequesting, APP_LOG_TYPES.PROTECT),
    getProtectLogsPage,

    setDateFilter: ProtectLogsDateFilter.actionSetBeginEnd,

    //LOCAL
    setSecondaryView: ProtectSecondaryViewStore.actionSet,
    listLogsSuccess: ProtectLogsCollection.actionListItemsSuccess,
    listLogsRequest: ProtectLogsCollection.actionListItemsRequest,
};

function getProtectLogsPage(page) {
    return async (dispatch, getState) => {
        dispatch(protectActions.listLogsRequest(page));
        const { begin, end } = Selectors.getProtectLogsDateFilter(getState());
        const { logs, next } = await protectLogsService.getProtectLogs(
            Selectors.getSelectedLocationUid(getState()),
            begin,
            end,
            25,
            page,
        );

        const localLogs = LogsMapper.mapAllServerToLocal(logs);

        dispatch(protectActions.listLogsSuccess(localLogs, next));
        
        return {
            localLogs,
            next,
        };
    }
}

function getTriggeredLogs() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncFunction));

        ////
        async function asyncFunction() {
            const serverTriggeredLogs = await protectService.getTriggeredLogs(
                Selectors.getSelectedLocationUid(getState())
            );
            const localTriggeredLogs = LogsMapper.mapAllServerToLocal(serverTriggeredLogs);
            dispatch({
                type: PROTECT_ACTION_TYPES.GET_TRIGGERED_LOGS,
                triggeredLogs: localTriggeredLogs,
            });
        }
    };
}

function toggleProtectStatus() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            const isProtectOn = !Selectors.getLocationInfo(getState()).isProtectOn;
            await protectService.updateProtectParameter({
                locationUid: Selectors.getSelectedLocationUid(getState()),
                isEnabled: isProtectOn,
            });

            dispatch({
                type: LOCATION_INFO_ACTION_TYPES.UPDATE_PROTECT_PARAMETER,
                parameter: {
                    isProtectOn,
                },
            });
        }
    }
}

function toggleProtectOnEnter() {
    return async (dispatch, getState) => {

        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            let locationInfo = LocationInfo();
            locationInfo = Selectors.getLocationInfo(getState());
            const protectOnEnter = !locationInfo.protectOnEnter;

            checkIfLocationSet(locationInfo);

            await protectService.updateProtectParameter({
                locationUid: Selectors.getSelectedLocationUid(getState()),
                protectOnEnter,
            });

            dispatch({
                type: LOCATION_INFO_ACTION_TYPES.UPDATE_PROTECT_PARAMETER,
                parameter: {
                    protectOnEnter,
                },
            });
        }
    }
}

function toggleProtectOnExit() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            let locationInfo = LocationInfo();
            locationInfo = Selectors.getLocationInfo(getState());
            const protectOnExit = !locationInfo.protectOnExit;

            checkIfLocationSet(locationInfo);

            await protectService.updateProtectParameter({
                locationUid: Selectors.getSelectedLocationUid(getState()),
                protectOnExit,
            });

            dispatch({
                type: LOCATION_INFO_ACTION_TYPES.UPDATE_PROTECT_PARAMETER,
                parameter: {
                    protectOnExit,
                },
            });
        };
    }
}

function checkIfLocationSet(locationInfo = LocationInfo()) {
    if (!locationInfo.latitude || !locationInfo.longitude)
        throw i18n.t("protect.optionNotAvailableIfNoCoordinates");
}

function toggleProtectSchedule() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            let locationInfo = LocationInfo();
            locationInfo = Selectors.getLocationInfo(getState());

            const protectScheduleEnabled = !locationInfo.protectScheduleEnabled;
            
            const protectScheduleBegin =
                locationInfo.protectScheduleBegin
                ? locationInfo.protectScheduleBegin
                : TimeHelper.utcStringDayStart()
            ;

            const protectScheduleEnd =
                locationInfo.protectScheduleEnd
                ? locationInfo.protectScheduleEnd
                : TimeHelper.utcStringDayEnd()
            ;

            await protectService.updateProtectParameter({
                locationUid: Selectors.getSelectedLocationUid(getState()),
                protectSchedule: protectScheduleEnabled,
                protectScheduleBegin: protectScheduleBegin,
                protectScheduleEnd: protectScheduleEnd,
            });

            dispatch({
                type: LOCATION_INFO_ACTION_TYPES.UPDATE_PROTECT_PARAMETER,
                parameter: {
                    protectScheduleEnabled,
                    protectScheduleBegin,
                    protectScheduleEnd,
                },
            });
        }
    }
}

function updateProtectScheduleEnd(time) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            await protectService.updateProtectParameter({
                locationUid: Selectors.getSelectedLocationUid(getState()),
                protectScheduleEnd: time,
            });

            dispatch({
                type: LOCATION_INFO_ACTION_TYPES.UPDATE_PROTECT_PARAMETER,
                parameter: {
                    protectScheduleEnd: time,
                },
            });
        }
    }
}

function updateProtectScheduleBegin(time) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            await protectService.updateProtectParameter({
                locationUid: Selectors.getSelectedLocationUid(getState()),
                protectScheduleBegin: time,
            });

            dispatch({
                type: LOCATION_INFO_ACTION_TYPES.UPDATE_PROTECT_PARAMETER,
                parameter: {
                    protectScheduleBegin: time,
                },
            });
        }
    }
}

function muteProtect() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        ////
        async function asyncAction() {
            await protectService.muteProtect(Selectors.getSelectedLocationUid(getState()));
            dispatch(uiAlertActions.success(i18n.t("protect.protectMuted")))
        }
    }
}