import { genericActions } from "../../Everou/_actions/generic.actions";
import { DevTokenMapper } from "../_mappers/DevTokenMapper";
import { DevTokensService } from "../_services/DevTokensService";
import { DevTokensStore } from "../_stores/DevTokensStore";

export const DevTokensActions = {
    list,
    add,
    remove,
};

function list() {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncFn));

        async function asyncFn() {
            dispatch(DevTokensStore.actionListItemsSuccess(
                DevTokenMapper.serverToLocal(
                    await DevTokensService.list()
                )
            ));
        }
    };
}

function add() {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncFn));

        async function asyncFn() {
            dispatch(DevTokensStore.actionGetItemSuccess(
                DevTokenMapper.serverToLocal(
                    await DevTokensService.add()
                )
            ));
        }
    };
}

function remove(uid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncFn));

        async function asyncFn() {
            await DevTokensService.remove(uid);
            dispatch(DevTokensStore.actionDeleteItem(uid));
        }
    };
}