export const PMS_LOCATIONS_ACTION_TYPES = {
    LOCATIONS_CHANGE_FILTER: "LOCATIONS_CHANGE_FILTER",
    LOCATIONS_LIST_SUCCESS: "LOCATIONS_LIST_SUCCESS",
    LOCATIONS_LIST_FAILURE: "LOCATIONS_LIST_FAILURE",
    LOCATIONS_SET_REQUESTING_STATUS: "LOCATIONS_SET_REQUESTING_STATUS",
    LOCATIONS_CHANGE_OFFSET: "LOCATIONS_CHANGE_OFFSET",
    LOCATIONS_SELECT: "LOCATIONS_SELECT",
    LOCATION_INFO_GET_SUCCESS: "LOCATION_INFO_GET_SUCCESS",
    LOCATION_CUSTOMIZATION_GET_SUCCESS: "LOCATION_CUSTOMIZATION_GET_SUCCESS",
    LOCATION_CUSTOMIZATION_IS_UPLOADING_SET: "LOCATION_CUSTOMIZATION_IS_UPLOADING_SET",
    LOCATION_CREATE_LOCAL_RESERVATION: "LOCATION_CREATE_LOCAL_RESERVATION",
    CREATE_LOCAL_RESERVATION: "CREATE_LOCAL_RESERVATION",
    DELETE_LOCAL_RESERVATION: "DELETE_LOCAL_RESERVATION",
    RESERVATION_UPDATE_NEW_DRAFT_LOCALLY: "RESERVATION_UPDATE_NEW_DRAFT_LOCALLY",
    RESERVATION_UPDATE_SELECTED_LOCALLY: "RESERVATION_UPDATE_SELECTED_LOCALLY",
}