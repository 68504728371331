import { LocationInfo } from "../_models/LocationInfo";
import { TimeHelper } from "../_helpers";
import { Room } from "../_models/Room";
import { LocationInfoHelper } from "../_helpers/locationInfo.helper";
import { DeviceMapper } from "./DeviceMapper";
export class LocationInfoMapper {
    static mapServerToLocal(serverLocationInfo) {
        const {
            timezone,
            name,
            lat,
            long,
            accuracy,
            share_data,
            loc_id,
            protect,
            protect_on_enter,
            protect_on_exit,
            protect_schedule_enabled,
            protect_schedule_begin,
            protect_schedule_end,
            protect_schedule_enabled_since,
            barrier_mode,
            rooms,
            video_url,
            video_name,

            loc_uid
        } = serverLocationInfo;
    
        const {
            has_clockins,
            has_customization,
            has_reservations,
            has_protect,
            has_add_users_to_groups,
            has_invitation_payments,
            has_protect_subscription
        } = serverLocationInfo.features;

        const {
            invitations_allowed,
            invitations_left,
            can_create_superguests,
        } = serverLocationInfo.invitation_settings;

        const {
            invitation_uid,
            permission_type,
            is_superguest,
            notification,
            allows_remote_clockins,
            interface_type
        } = serverLocationInfo.user_settings;
    
        const {
            company_legal_id,
            company_name,
            company_additional_info,
            company_contact_email,
        } = serverLocationInfo.clockins_company_info;

        return LocationInfo({
            timezone: timezoneServerToLocal(timezone),
            locationName: name,
            locationId: loc_id,
            invitationUid: invitation_uid,
            locationPermission: permission_type,
            isSuperguest: is_superguest,
            isNotificationActive: notification,
            allowsRemoteClockins: allows_remote_clockins,
            isBarrierEnabled: LocationInfoHelper.isBarriersActive(barrier_mode, DeviceMapper.serverLocationinfoToLocalDevices(
                    serverLocationInfo,
                )), 
            barrierMode: barrier_mode,
            latitude: serverToLocalCoordinate(lat),
            longitude: serverToLocalCoordinate(long),
            radius: accuracy,
            shareData: share_data,

            isProtectOn: protect,
            protectOnEnter: protect_on_enter,
            protectOnExit: protect_on_exit,
            protectScheduleEnabled: protect_schedule_enabled,
            protectScheduleBegin: protect_schedule_begin ? TimeHelper.serverStringToIsoString(protect_schedule_begin) : undefined,
            protectScheduleEnd: protect_schedule_end ? TimeHelper.serverStringToIsoString(protect_schedule_end) : undefined,
            protectScheduleEnabledSince: protect_schedule_enabled_since ? TimeHelper.serverStringToIsoString(protect_schedule_enabled_since) : undefined,
            showProtectLocation: protect_on_enter || protect_on_exit,
            canCreateSuperguests: can_create_superguests,
            allowsCustomization: has_customization,
            hasReservations: has_reservations,
            hasClockins: has_clockins,
            isThereBrain: has_protect,
            canAddUsersToGroup: has_add_users_to_groups,
            canBuyInvitations: has_invitation_payments,
            hasProtectSubscription: has_protect_subscription,
            interfaceType: interface_type,
            rooms: roomsServerToLocal(rooms),

            invitationsAllowed: invitations_allowed,
            invitationsLeft: invitations_left,

            companyName: company_name,
            companyContactEmail: company_contact_email,
            companyCIF: company_legal_id,
            companyAdditionalInfo: company_additional_info,
            videoUrl: video_url,
            videoName: video_name,

            uid: loc_uid
        });

        ////
        function serverToLocalCoordinate(coordinate) {
            if (coordinate === -180)
                return null;
    
            return coordinate;
        }

        function timezoneServerToLocal(timezone) {
            if (timezone === null)
                return "UTC";
            
            return timezone;
        }

        function roomsServerToLocal(rooms) {
            return rooms.map(({ uid, name, type, devices}) =>
                Room({
                    uid,
                    name,
                    type,
                    devicesAmount: devices?.length ?? 0
                }),
            );
        }
    }
}
