import React from 'react';
import { connect } from "react-redux";

import { libstreamWrapper } from './libstreamjsWrapper';
import { ffmpegWrapper } from './ffmpegWrapper';
import { VideoLog } from '../_models/VideoLog';
import { videoActions } from '../_actions/video.actions';

import defaultVideoThumbnail from "../_assets/images/baintex-video-thumbnail.jpg";
import { Selectors } from '../_reducers/app.reducer';

// Class documentation
const classProps = {
    videoLog: VideoLog(),
    dispatch: () => {},
};

class VideoPlayer extends React.Component {

    constructor(props = classProps) {
        super(props);

        this.props = props;

        this.state = {
            isInitialized: false,
        };
        
        this.videoRef = React.createRef();

        this.onFinishConversion = this.onFinishConversion.bind(this);
        this.linkVideoToPlayer = this.linkVideoToPlayer.bind(this);
        this.convertVideo = this.convertVideo.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.videoLog.logId === this.props.videoLog.logId)
            return;

        this.getVideoAndConvert(this.props.videoLog);
    }

    render() {

        const { isConverting } = this.props;

        return (
            <div className="
                position-relative
                c-ratio-16-9
            ">
                {this.state.isInitialized &&
                <video
                    className={`
                        w-100
                        h-100
                        position-absolute

                        ${isConverting
                        ? "invisible"
                        : null
                        }

                    `}
                    ref={this.videoRef}
                    controls
                    poster={defaultVideoThumbnail}
                />
                }

                {(!this.state.isInitialized || isConverting) &&
                    <div className="
                        position-absolute
                        w-100
                        h-100

                        d-flex
                        align-items-center
                        justify-content-center

                        border-bottom
                        overflow-hidden
                    ">
                        <img
                            className="w-100 position-absolute"
                            alt="default video thumbnail"
                            src={defaultVideoThumbnail}
                        />

                        {isConverting &&
                            <div className="c-scale-2x">
                                <div className="spinner-border c-text-primary"/>
                            </div>
                        }

                    </div>
                } 
            </div>
        );
    }

    onFinishConversion(mp4VideoArray) {
        this.linkVideoToPlayer(mp4VideoArray);
        this.props.dispatch(videoActions.setIsConverting(false));
        
        console.warn("FINISHED VIDEO CONVERSION");
    }

    linkVideoToPlayer(mp4VideoArray) {
        const mp4Buffer = Uint8Array.from(mp4VideoArray);
        const dataUri = URL.createObjectURL(new Blob([mp4Buffer], { type: "video/mp4" }));

        if (!this.videoRef.current)
            return;
    
        this.videoRef.current.src = dataUri;
        this.videoRef.current.play();
    }

    async getVideoAndConvert(videoLog = VideoLog()) {
        if (videoLog.logId === undefined)
            return;

        this.props.dispatch(videoActions.setIsConverting(true));
            
        const videoArrayBuffers = await this.props.dispatch(videoActions.getVideo(videoLog));

        if (videoArrayBuffers === undefined)
            return;

        this.convertVideo(videoArrayBuffers);
    }

    convertVideo(arrayBuffers) {
        if (!libstreamWrapper.isReady){
            this.props.dispatch(videoActions.setIsConverting(false));
            console.error("LibStream not ready");
            return;
        }

        this.setState({
            isInitialized: true,
        });
        this.videoRef.current.load();

        const rawVideoArrays = libstreamWrapper.rawToArrays(arrayBuffers);

        ffmpegWrapper.rawToMp4(
            rawVideoArrays.video,
            rawVideoArrays.audio,
            this.onFinishConversion,
        );
    }
}

function mapStateToProps(state) {
    return {
        isConverting: Selectors.getVideoIsConverting(state),
    };
}

const connectedVideoPlayer = connect(mapStateToProps)(VideoPlayer);
export { connectedVideoPlayer as VideoPlayer };