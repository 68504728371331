import { i18n } from "../_translations/i18n";

import { ReactComponent as dashboardSvg } from "../_assets/appIcons/icon_module_dashboard.svg";
import { ReactComponent as alertsSvg } from "../_assets/appIcons/icon_module_alerts.svg";
import { ReactComponent as logsSvg } from "../_assets/appIcons/icon_module_logs.svg";
import { ReactComponent as videoSvg } from "../_assets/appIcons/icon_module_videos.svg";
import { ReactComponent as helpSvg } from "../_assets/appIcons/icon_module_help.svg";
import { ReactComponent as subscriptionsSvg } from "../_assets/appIcons/icon_module_subscriptions.svg";
import { ReactComponent as triggersSvg } from "../_assets/appIcons/icon_module_triggers.svg";
import { ReactComponent as reservationsSvg } from "../_assets/appIcons/icon_module_reservations.svg";
import { ReactComponent as mobilesSvg } from "../_assets/appIcons/icon_module_mobiles.svg";
import { ReactComponent as userLogsSvg } from "../_assets/appIcons/icon_module_user_logs.svg";
import { ReactComponent as usersStatusSvg } from "../_assets/appIcons/icon_module_diagnostics.svg";
import { ReactComponent as billingSvg } from "../_assets/appIcons/icon_module_billing.svg";
import { ReactComponent as customizationSvg } from "../_assets/appIcons/icon_module_customization.svg";
import { ReactComponent as settingsSvg }  from "../_assets/appIcons/icon_module_location.svg";
import { ReactComponent as developersSvg } from "../_assets/appIcons/icon_module_developers.svg";
import { ReactComponent as protectSvg } from "../_assets/appIcons/icon_module_protect.svg";
import { ReactComponent as listSvg } from "../_assets/appIcons/icon_module_list.svg";
import { ReactComponent as reportsSvg } from "../_assets/appIcons/icon_module_report.svg";
import { ReactComponent as bluetoothSvg } from "../_assets/appIcons/icon_module_bluetooth.svg";
import { ReactComponent as auditSvg } from "../_assets/appIcons/icon_module_audit.svg";
import { ReactComponent as integrationsIcon } from '../_assets/appIcons/icon_module_integrations.svg';
import { ReactComponent as groupsIcon } from '../_assets/appIcons/icon_groups.svg';
import { ReactComponent as userIcon } from '../_assets/appIcons/icon_invitations_user.svg';
import { ReactComponent as barriersSvg } from "../_assets/appIcons/icon_module_barriers.svg";

import {
    EVEROU_MODULE_IDS,
    EVEROU_URLS,
} from "../_constants";

import { DevicesModule } from "../DevicesModule";
import { AlertsModule } from "../AlertsModule/AlertsModule";
import { DeviceLogsModule } from "../LogsModule/DeviceLogsModule";
import { InvitationsModule } from "../InvitationsModule/InvitationsModule";
import { appPermissions } from "../_constants/permissions.constants";
import { VideoModule } from "../VideoModule/VideoModule";
import { ProtectModule } from "../ProtectModule/ProtectModule";
import { HelpModule } from "../HelpModule/HelpModule";
import { SubscriptionsModule } from "../SubscriptionsModule/SubscriptionsModule";
import { TriggersModule } from "../TriggersModule/TriggersModule";
import { AuditLogsModule } from "../AuditLogsModule/AuditLogsModule";
import { ReservationsModule } from "../ReservationsModule/ReservationsModule";
import { SettingsModule } from "../SettingsModule/SettingsModule";
import { UserMobilesModule } from "../UserMobilesModule/UserMobilesModule";
import { UserLogsModule } from "../UserLogsModule/UserLogsModule";
import { UsersStatusModule } from "../UsersStatus/UsersStatusModule";
import { Dashboard } from "../HomeModule/Dashboard";
import { ModuleModel } from "../_models";
import { ClockinsModule } from "../ClockinsModule/ClockinsModule";
import { Icons } from "../_assets";
import { LocationInfo } from "../_models/LocationInfo";
import { User } from "../_models/User";
import { CustomizationModule } from "../CustomizationModule/CustomizationModule";
import { BluetoothModule } from "../BluetoothModule/BluetoothModule";
import { DummyModule } from "../DummyModule/DummyModule";
import { ModulePresets } from "./ModulePresets";
import { AppModulesFactory } from "../../Common/AppModulesFactory";
import { BillingModule } from "../BillingModule/BillingModule";
import { DevelopersModule } from "../DevelopersModules/DevelopersModule";
import { ClockinsReportsModule } from "../ClockinsModule/ClockinsReportsModule";
import { OtaModule } from '../OtaModule/OtaModule'
import { UsersModule } from "../UsersModule/UsersModule";
import { IntegrationsModule } from "../IntegrationsModule/IntegrationsModule";
import { InvitationsCreateModule } from "../InvitationsCreateModule";
import { InvitationGroupModule } from "../InvitationsModule/InvitationGroupsModule/InvitationGroupsModule";
import { BarriersModule } from "../BarriersModule/BarriersModule";
import { LocationInfoHelper } from "../_helpers/locationInfo.helper";

const EVEROU_MODULES = [
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.DEVICES,
        uiName: i18n.t("global_list"),
        SvgComponent: listSvg,
        url: EVEROU_URLS.DEVICES,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.LOGS,
        uiName: i18n.t("logs.logs"),
        SvgComponent: logsSvg,
        url: EVEROU_URLS.LOGS,
        isEnabled: false,
    }),
    /*ModuleModel({
        moduleId: EVEROU_MODULE_IDS.OTA,
        uiName: i18n.t("mod_ota_title"),
        SvgComponent: logsSvg,
        url: EVEROU_URLS.OTA,
        isEnabled: true,
    }),*/
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.INVITATIONS,
        uiName: i18n.t("global_list"),
        SvgComponent: listSvg,
        url: EVEROU_URLS.INVITATIONS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.INVITATIONS_CREATE,
        url: EVEROU_URLS.INVITATIONS_CREATE,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.INVITATIONS_GROUPS,
        uiName: i18n.t("global_groups"),
        SvgComponent: groupsIcon,
        url: EVEROU_URLS.INVITATIONS_GROUPS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.INVITATIONS_USERS,
        uiName: i18n.t("temp_webapp_user_mobiles_module_name"),
        SvgComponent: userIcon,
        url: EVEROU_URLS.INVITATIONS_USERS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.PROTECT,
        uiName: i18n.t("protect.protect"),
        SvgComponent: protectSvg,
        url: EVEROU_URLS.PROTECT,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.ALERTS,
        uiName: i18n.t("global_alerts"),
        SvgComponent: alertsSvg,
        url: EVEROU_URLS.ALERTS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.SUBSCRIPTIONS,
        uiName: i18n.t("common.subscriptions"),
        SvgComponent: subscriptionsSvg,
        url: EVEROU_URLS.SUBSCRIPTIONS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.RESERVATIONS,
        uiName: i18n.t("common.reservations"),
        SvgComponent: reservationsSvg,
        url: EVEROU_URLS.RESERVATIONS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.TRIGGERS,
        uiName: i18n.t("common.triggers"),
        SvgComponent: triggersSvg,
        url: EVEROU_URLS.TRIGGERS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.AUDIT_LOGS,
        uiName: i18n.t("common.audit"),
        SvgComponent: auditSvg,
        url: EVEROU_URLS.AUDIT,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.USER_MOBILES,
        uiName: i18n.t("temp_webapp_user_mobiles_module_name"),
        SvgComponent: mobilesSvg,
        url: EVEROU_URLS.USERS_MOBILES,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.VIDEO,
        uiName: i18n.t("modules.video"),
        SvgComponent: videoSvg,
        url: EVEROU_URLS.VIDEO,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.DASHBOARD,
        uiName: i18n.t("modules.dashboard"),
        SvgComponent: dashboardSvg,
        url: EVEROU_URLS.DASHBOARD,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.USERS_STATUS,
        uiName: i18n.t("mod_users_status_title"),
        SvgComponent: usersStatusSvg,
        url: EVEROU_URLS.USERS_STATUS,
        isEnabled: false,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.CLOCKINS,
        uiName: i18n.t("global_list"),
        SvgComponent: listSvg,
        url: EVEROU_URLS.CLOCKINS,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.CLOCKINS_REPORTS,
        uiName: i18n.t("global_report"),
        SvgComponent: reportsSvg,
        url: EVEROU_URLS.CLOCKINS_REPORTS,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.SETTINGS,
        uiName: i18n.t("global_location"),
        SvgComponent: settingsSvg,
        url: EVEROU_URLS.OPTIONS,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.CUSTOMIZATION,
        uiName: i18n.t("mod_customization_title"),
        SvgComponent: customizationSvg,
        url: EVEROU_URLS.CUSTOMIZATION,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.BLUETOOTH,
        uiName: i18n.t("mod_bluetooth_title"),
        SvgComponent: bluetoothSvg,
        url: "/bluetooth",
        isEnabled: false,
    }),

    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.USER_LOGS,
        uiName: i18n.t("mod_positioning_title"),
        SvgComponent: userLogsSvg,
        url: EVEROU_URLS.USER_LOGS,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.HELP,
        uiName: i18n.t("common.help"),
        SvgComponent: helpSvg,
        url: EVEROU_URLS.HELP,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.BILLING,
        uiName: i18n.t("mod_billing_title"),
        SvgComponent: billingSvg,
        url: EVEROU_URLS.BILLING,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.DEVELOPERS,
        uiName: i18n.t("mod_developers_title"),
        SvgComponent: developersSvg,
        url: EVEROU_URLS.DEVELOPERS,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.INTEGRATIONS,
        uiName: i18n.t('mod_integrations_title'),
        SvgComponent: integrationsIcon,
        url: EVEROU_URLS.INTEGRATIONS,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.BARRIERS,
        uiName: i18n.t('mod_barriers_title'),
        SvgComponent: barriersSvg,
        url: EVEROU_URLS.BARRIERS,
        isEnabled: true,
    }),
    ModuleModel({
        moduleId: EVEROU_MODULE_IDS.DUMMY,
        uiName: "Dummy module",
        icon: Icons.trash,
        url: "dummy",
        isEnabled: true,
    })
];

const COMPONENT_FOR_MODULE_ID = {
    [EVEROU_MODULE_IDS.DEVICES]:          DevicesModule,
    [EVEROU_MODULE_IDS.LOGS]:             DeviceLogsModule,
    [EVEROU_MODULE_IDS.INVITATIONS]:      InvitationsModule,
    [EVEROU_MODULE_IDS.INVITATIONS_GROUPS]: InvitationGroupModule,
    [EVEROU_MODULE_IDS.INVITATIONS_CREATE]: InvitationsCreateModule,
    [EVEROU_MODULE_IDS.PROTECT]:          ProtectModule,
    [EVEROU_MODULE_IDS.ALERTS]:           AlertsModule,
    [EVEROU_MODULE_IDS.SUBSCRIPTIONS]:    SubscriptionsModule,
    [EVEROU_MODULE_IDS.RESERVATIONS]:     ReservationsModule,
    [EVEROU_MODULE_IDS.TRIGGERS]:         TriggersModule,
    [EVEROU_MODULE_IDS.AUDIT_LOGS]:       AuditLogsModule,
    [EVEROU_MODULE_IDS.VIDEO]:            VideoModule,
    [EVEROU_MODULE_IDS.DASHBOARD]:        Dashboard,
    [EVEROU_MODULE_IDS.SETTINGS]:         SettingsModule,
    [EVEROU_MODULE_IDS.HELP]:             HelpModule,
    [EVEROU_MODULE_IDS.INVITATIONS_USERS]: UsersModule,
    [EVEROU_MODULE_IDS.USER_MOBILES]:     UserMobilesModule,
    [EVEROU_MODULE_IDS.USER_LOGS]:        UserLogsModule,
    [EVEROU_MODULE_IDS.USERS_STATUS]:     UsersStatusModule,
    [EVEROU_MODULE_IDS.CLOCKINS]:         ClockinsModule,
    [EVEROU_MODULE_IDS.CUSTOMIZATION]:    CustomizationModule,
    [EVEROU_MODULE_IDS.BLUETOOTH]:        BluetoothModule,
    [EVEROU_MODULE_IDS.BILLING]:          BillingModule,
    [EVEROU_MODULE_IDS.DUMMY]:            DummyModule,
    [EVEROU_MODULE_IDS.DEVELOPERS]:       DevelopersModule,
    [EVEROU_MODULE_IDS.CLOCKINS_REPORTS]: ClockinsReportsModule,
    //[EVEROU_MODULE_IDS.OTA]:              OtaModule,
    [EVEROU_MODULE_IDS.INTEGRATIONS]:     IntegrationsModule,
    [EVEROU_MODULE_IDS.BARRIERS]:         BarriersModule
};

function isModuleEnabled({
    moduleId,
    locationInfo,
    isRoomWithReservations,
    isSat,
    isSuperuser,
    preset,
} = {}) {
    if (!ModulePresets.isModuleEnabledInPreset(moduleId, preset))
        return false;

    const locationPermission =         locationInfo.locationPermission;
    const isSuperguest =               locationInfo.isSuperguest;
    const hasClockins =                locationInfo.hasClockins;
    const isLocationCustomizable =     locationInfo.allowsCustomization;
    const isBarrierEnabled =           locationInfo.isBarrierEnabled;

    switch (moduleId) {
        case EVEROU_MODULE_IDS.INVITATIONS:
        case EVEROU_MODULE_IDS.INVITATIONS_USERS:
            return appPermissions.canUserSeeInvitations(locationPermission, isSuperguest);

        case EVEROU_MODULE_IDS.INVITATIONS_GROUPS:
            return appPermissions.canUserManageInvitationGroups(locationPermission);
        
        case EVEROU_MODULE_IDS.BARRIERS:
            return isBarrierEnabled && appPermissions.canManageBarriers(locationPermission);

        case EVEROU_MODULE_IDS.OTA:
            return appPermissions.canUserUpdateOtaVersion(locationPermission, isSuperuser, isSat);

        case EVEROU_MODULE_IDS.INTEGRATIONS:
            return appPermissions.canManageIntegrations(locationPermission);

        case EVEROU_MODULE_IDS.USER_MOBILES:
            return appPermissions.canUserSeeMobiles(locationPermission, isSat);

        case EVEROU_MODULE_IDS.AUDIT_LOGS:
            return appPermissions.canUserSeeAuditLogs(locationPermission, isSat);

        case EVEROU_MODULE_IDS.TRIGGERS:
            return appPermissions.canUserViewTriggers(locationPermission);

        case EVEROU_MODULE_IDS.LOGS:
            return appPermissions.canUserViewDeviceActivity(locationPermission);

        case EVEROU_MODULE_IDS.DASHBOARD:
            //return appPermissions.canViewDashboard(locationPermission);
            return false;

        case EVEROU_MODULE_IDS.VIDEO:
            return appPermissions.canUserViewDeviceActivity(locationPermission);

        case EVEROU_MODULE_IDS.ALERTS:
            return appPermissions.canUserViewAlerts(locationPermission);

        case EVEROU_MODULE_IDS.PROTECT:
            return appPermissions.canManageProtect(locationPermission)

        case EVEROU_MODULE_IDS.RESERVATIONS: {
            /*return (
                appPermissions.canUserConfigureReservations(locationPermission)
                || (isRoomWithReservations && appPermissions.canCreateBookings(locationPermission))
            );*/
            return false;
        }

        case EVEROU_MODULE_IDS.CLOCKINS_REPORTS:
            return hasClockins && appPermissions.canManageClockins(locationPermission);

        case EVEROU_MODULE_IDS.CLOCKINS:
            return hasClockins;

        case EVEROU_MODULE_IDS.SUBSCRIPTIONS:
            //return appPermissions.canUserSeeSubscriptions(locationPermission);
            return false;

        case EVEROU_MODULE_IDS.USERS_STATUS:
            //return appPermissions.canUserSeeUsersStatus(locationPermission);
            return false;

        case EVEROU_MODULE_IDS.CUSTOMIZATION:
            return isLocationCustomizable && appPermissions.canManageCustomization(locationPermission);

        case EVEROU_MODULE_IDS.BLUETOOTH:
            return isSuperuser;

        case EVEROU_MODULE_IDS.DEVELOPERS:
            return appPermissions.canAddDevelopers(locationPermission);

        case EVEROU_MODULE_IDS.USER_LOGS:
        case EVEROU_MODULE_IDS.DEVICES:
        case EVEROU_MODULE_IDS.SETTINGS:
        case EVEROU_MODULE_IDS.HELP:
            return true;

        case EVEROU_MODULE_IDS.BILLING:
            //return appPermissions.canManageBilling(locationPermission);
            return false;

        case EVEROU_MODULE_IDS.DUMMY:
        default:
            return false;
    }
}

function getEnabledModuleIds({
    presetId,
    locationInfo = LocationInfo(),
    reservationsConfigs = [],
    userObj = User(),
} = {}) {
    return EVEROU_MODULES.reduce((enabledModuleIds, module) =>
            isModuleEnabled({
                moduleId:   module.moduleId,
                
                locationInfo: locationInfo, 
                isSat:          userObj.isSat,
                isSuperuser:    userObj.isSuperuser,
                
                isRoomWithReservations: reservationsConfigs.length !== 0,
                preset: presetId,
            })
            ? [...enabledModuleIds, module.moduleId]
            : enabledModuleIds
        , [])
    ;
}

export const EverouModules = {
    ...AppModulesFactory.makeModules({
        ALL_MODULES: EVEROU_MODULES,
        componentForModuleDictionary: COMPONENT_FOR_MODULE_ID,
        DEFAULT_MODULE: EVEROU_MODULE_IDS.DEVICES,
    }),
    getEnabledModuleIds,
    IDS: EVEROU_MODULE_IDS,
};