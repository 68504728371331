export enum CLOCKIN_TYPES {
    ENTRY =  0,
    EXIT =   1,
};

export interface Clockin {
    uid: string;
    date?: Date;
    type: CLOCKIN_TYPES;
    roomName?: string;
    deviceName?: string;
    notes?: string;
    editUserName?: string;
    editDate?: Date;
    alert?: boolean;
}