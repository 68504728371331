import { AUTHENTICATION_ACTION_TYPES } from '../_constants/authentication.constants';
import { TimeHelper } from '../_helpers/TimeHelper';

const initialState = {
    machineUid: null,
    user: null,
    codeSent: false,
    isNewUser: false,
    version: null,
    lastApikeyUpdate: null,

    url: {
        email: null,
        code: null,
        secret: null,
    },

    login: {
        codeType: null,
        code: null,
        email: null,
    },
};

export function authentication(state = initialState, action) {

    if (!state.hydrated) {
        state = {
            ...initialState,
            ...state,
            hydrated: true,
            login: {
                ...initialState.login,
                ...state.login
            },
        };
    }

    switch (action.type) {
        case AUTHENTICATION_ACTION_TYPES.UPDATE_VERSION:
            return { ...state,
                version: action.version,
            };

        case AUTHENTICATION_ACTION_TYPES.LOGIN_CODE_SUCCESS:
            return { ...state,
                codeSent: true,
            };

        case AUTHENTICATION_ACTION_TYPES.SET_CODE_SENT:
            return { ...state,
                codeSent: action.codeSent,
            };
        
        case AUTHENTICATION_ACTION_TYPES.LOGIN_REQUEST:
            return { ...state,
                login: {
                    ...state.login,
                    email: action.email,
                    code: action.code,
                    codeType: action.codeType,
                },
            };

        case AUTHENTICATION_ACTION_TYPES.LOGIN_SUCCESS:
            return { ...state,
                user: action.user,
            };

        case AUTHENTICATION_ACTION_TYPES.AUTH_UPDATE_USER_SUCCESS: {
            return { ...state,
                user: { ...state.user,
                    ...removeUndefinedFields(action.user),
                },
            };

            function removeUndefinedFields(obj = {}) {
                return Object.keys(obj).reduce((accumulator, key) => {
                    if (obj[key] !== undefined)
                        accumulator[key] = obj[key];
                        
                    return accumulator;
                }, {});
            }
        }

        case AUTHENTICATION_ACTION_TYPES.SET_APIKEY_UPDATE_DATE: {
            return { ...state,
                lastApikeyUpdate: setDate(),
            };

            function setDate() {
                return action.lastApikeyUpdate === undefined
                    ? TimeHelper.getTodayIsoString()
                    : action.lastApikeyUpdate
                ;
            }   
        }

        case AUTHENTICATION_ACTION_TYPES.SET_NEW_USER: {
            return { ...state,
                isNewUser: action.isNewUser,
            };
        }

        default:
            return state;
    }
}