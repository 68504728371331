import { Integration, LocalEditableIntegration } from "../../../Everou/_modelsTS/Integration";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatePublicUrlToggle from "../../../Everou/InvitationsModule/CreatePublicUrlToggle";
import { CapsuleButton } from "../../../Everou/_components";
import { useDispatch, useSelector } from "react-redux";
import { useError } from "../../../Everou/_hooks/useError";
import { Icons } from "../../../Everou/_assets";
import { PMSIntegrationsCollection } from "../../_stores/PMSIntegrationsCollection";
import { PMSIntegrationsHelper } from "../_helpers/PMSIntegrationsHelper";
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";



type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onChangeInputs?: (...args: any) => void | any;
    onToggleEditable: (...args: any) => void | any;
    onSetInitialStatus: (...args: any) => void | any;
    onDelete: (index: number) => void;
    index: number;
}

export function PMSAvaibookIntegrationInput({ integration, onDelete, index, onSetInitialStatus, onToggleEditable }: IntegrationInputProps) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const integrations = useSelector(PMSIntegrationsCollection.selectorGetItems);
    const baseIntegration = useMemo(() => integration, [integration]);
    const [linkValue, setLinkValue] = useState(integration.link ?? '');
    const [createPublicUrl, setCreatePublicUrl] = useState(integration.createPublicUrl);
    const { errorMessage, setError, hasError, disableError } = useError();

    useEffect(() => { 
        if (!integration) return;
        setLinkValue(integration.link ?? '');
        setCreatePublicUrl(integration.createPublicUrl);
     }, [integration]);


    const canSave = useMemo(() => {

        if(hasError) return false;

        return (!!linkValue && ((linkValue !== baseIntegration.link) ||
            createPublicUrl !== integration.createPublicUrl 
        ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseIntegration.link, createPublicUrl, integrations, integration.createPublicUrl, linkValue]);
    

    const onSave = useCallback(async () => {

        if(!linkValue) {
            setError(t('mod_integrations_link_required'));
            return;
        }

        const editedIntegration: Integration = {
            link: linkValue,
            company: integration.company,
            createPublicUrl,
            type: integration.type,
            uid: integration.uid
        }
        try {
            await dispatch(PMSIntegrationsActions.createIntegration(editedIntegration));
        } catch (err: any) {
            setError(err.message)
        }
    }, [createPublicUrl, dispatch, setError, integration.company, integration.type, integration.uid, linkValue, t])


    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            <div
                className="col-span-full"
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "3fr 2fr",
                }}
            >
                <input
                    className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                    type="text"
                    readOnly={!integration.editable}
                    value={linkValue}
                    placeholder={t("global_ical_link")}
                    onChange={(e) => {
                        setLinkValue(e.target.value?.trim());
                        if (integrations && 
                            PMSIntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && 
                            e.target.value !== integration.link) {
                            setError(t('mod_integrations_already_have_integration_with_this_link'));
                        } else {
                            disableError();
                        }
                    }}
                />
            </div>
            <div
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "1fr 1fr",
                }}
                className="col-span-full d-flex justify-content-between"
            >
                
                <CreatePublicUrlToggle
                    boldedName={false}
                    initialState={createPublicUrl}
                    readOnly={!integration.editable}
                    onChange={setCreatePublicUrl}
                />
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                    {
                        !!integration.uid &&
                        <CapsuleButton
                            size="SMALL"
                            classNameExtra="mr-2"
                            style={!integration.editable ? 'PRIMARY' : 'INFO'}
                            onClick={() => integration.editable ? onSetInitialStatus(index) : onToggleEditable(index)}
                            text={t(!integration.editable ?  "global_edit" : 'global_cancel')}
                        />
                    }
                    {(integration.uid) &&
                        <CapsuleButton
                            onClick={() => onDelete(index)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            style="DANGER"
                            size="SMALL"
                            icon={Icons.trash}
                        />
                    }
                    <CapsuleButton
                        size="SMALL"
                        isEnabled={canSave}
                        onClick={onSave}
                        text={t("global_save")}
                    />
                </div>
            </div>
                    {errorMessage && (
                        <div className="text-danger mr-2">{errorMessage}</div>
                    )}
        </div>
    );
}