import { StoreManager } from "../Everou/StoreManager";
import { HistoryHelper } from "../Everou/_helpers";
import { CommonStore } from "../Everou/_reducers/common.reducer";
import { DevelopersModules } from "./DevelopersModules";

export const DevelopersStoreSubscriptions = {
    subscribe,
};

function subscribe() {
    StoreManager.subscribeFnToStore(persistBudgetsSelectedModuleToUrl);
}

function persistBudgetsSelectedModuleToUrl(getState) {
    HistoryHelper.pushToUrl(
        DevelopersModules.getUrlForModule(
            CommonStore.selectors.getSelectedModuleId(getState())
        )
    );
}