import { videoService } from "../_services/video.service";
import { VideoLogMapper } from "../_mappers/VideoLogMapper";
import { VIDEO_ACTION_TYPES } from "../_constants/video.constants";
import { VideoLog } from "../_models/VideoLog";
import { DEVICE_TYPES } from "../_constants";
import { genericActions } from "./generic.actions";
import { Selectors } from "../_reducers/app.reducer";

export const videoActions = {
    getVideo,
    
    changeVideoFilterDate,
    selectBrain,
    selectFirstBrain,
    
    setIsConverting,
    
    listVideosNextPage,

    //exposed for testing
    listVideos,
};

function selectFirstBrain() {
    return async (dispatch, getState) => {
        const brain = Selectors.getSelectedLocationDevices(getState())
            .find(device => device.type === DEVICE_TYPES.Brain)
        ;

        if (!brain)
            return;
        
        await dispatch(
            videoActions.selectBrain(brain.uid)
        );
    };
}

function selectBrain(brainUid) {
    return async dispatch => {

        dispatch({
            type: VIDEO_ACTION_TYPES.SELECT_BRAIN,
            brainUid,
        });
        
        await dispatch(videoActions.listVideos(brainUid));
    }
}

function changeVideoFilterDate(date) {
    return async (dispatch, getState) => {

        dispatch({
            type: VIDEO_ACTION_TYPES.CHANGE_FILTER_DATE,
            date,
        });

        await dispatch(videoActions.listVideos(
            Selectors.getVideoBrainUid(getState())
        ));
    };
}

function listVideosNextPage() {
    return async (dispatch, getState) => {
        if (Selectors.getVideoIsRequesting(getState()))
            return;

        const next = Selectors.getVideoNextPage(getState());

        if (next === -1)
            return;

        await dispatch(videoActions.listVideos(
            Selectors.getVideoBrainUid(getState()),
            next,
        ));
    };
}

function listVideos(brainUid, pageNum = 0) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(
            asyncAction,
            catchFn,
        ));

        async function asyncAction() {
            if (!brainUid)
                return;
            
            const { start, end } = Selectors.getVideoDateFilter(getState());

            dispatch(request(pageNum === 0));
            const response = await videoService.listVideos(
                brainUid,
                pageNum,
                start,
                end,
            );

            const videoLogs = VideoLogMapper.allServerToLocal(response.logs);
            dispatch(success(
                videoLogs,
                response.next,
            ));
        }

        function catchFn() {
            dispatch(failure());
        }

        ////
        function request(isRequestingFirst) {
            return {
                type: VIDEO_ACTION_TYPES.LIST_VIDEOS_REQUEST,
                isRequestingFirst,
            };
        }

        function success(videoLogs, next) {
            return {
                type: VIDEO_ACTION_TYPES.LIST_VIDEOS,
                videoLogs,
                next,
            };
        }

        function failure() {
            return {
                type: VIDEO_ACTION_TYPES.LIST_VIDEOS_FAILURE,
            };
        }
    };
}

function getVideo(videoLog = VideoLog()) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const videoUids = [];
            for (let i = 0; i < videoLog.videoFragments.length; i ++) {
                videoUids.push(videoLog.videoFragments[i].videoUid);
            }
            const fragmentResponses = await videoService.getAllFragments(videoUids);

            const fragmentBuffers = [];
            for (let i = 0; i < fragmentResponses.length; i++) {
                fragmentBuffers.push(await fragmentResponses[i].arrayBuffer());
            }

            return fragmentBuffers;
        }
    }
}

function setIsConverting(isConverting) {
    return {
        type: VIDEO_ACTION_TYPES.SET_IS_CONVERTING,
        isConverting,
    };
}