import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const customizationService = {
    uploadLogo,
    uploadBackground,
    update,
    getLoginInfo,
    getLocationInfo,
};

async function uploadLogo(locationUid, image) {
    await ServiceHelper.putImageRequest(
        URL_CONSTANTS.CUSTOMIZATION_UPLOAD_LOGO + "/" + locationUid,
        image,
    );
}

async function uploadBackground(locationUid, image) {
    await ServiceHelper.putImageRequest(
        URL_CONSTANTS.CUSTOMIZATION_UPLOAD_BACKGROUND + "/" + locationUid,
        image,
    );
}

async function update(locationUid, accentColor, headerColor) {
    return await ServiceHelper.postRequest(
        URL_CONSTANTS.CUSTOMIZATION_UPDATE,
        {
            loc_uid: locationUid,
            accent_color: accentColor,
            header_color: headerColor,
        },
    );
}

async function getLoginInfo(subdomain) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.CUSTOMIZATION_LOGIN_INFO,
        {
            customization_subdomain: subdomain,
        },
        true,
    );
}

async function getLocationInfo(locationUid) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.CUSTOMIZATION_LOCATION_INFO,
        {
            loc_uid: locationUid,
        },
    );
}