import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1.1"
      viewBox="0 0 24 24"
    >
      <path
        strokeWidth="0.047"
        d="M9.158 1.605h5.756c.97 0 1.828.62 2.133 1.539l.487 1.461h3.502c1.654 0 3 1.346 3 3v12c0 1.655-1.346 3-3 3h-18c-1.657 0-3-1.345-3-3v-12c0-1.654 1.343-3 3-3h3.501l.488-1.461a2.245 2.245 0 012.133-1.539zm2.878 16.5c2.484 0 4.5-2.015 4.5-4.5 0-2.526-2.016-4.5-4.5-4.5-2.527 0-4.5 1.974-4.5 4.5 0 2.485 1.973 4.5 4.5 4.5z"
      ></path>
    </svg>
  );
}

export default Icon;