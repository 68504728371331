import { SectionsSidebar } from "../../Common/_components/SectionsSidebar";
import { EVEROU_MODULE_IDS } from "../_constants";
import { EverouModules } from "../_modules/EverouModules";
import { i18n } from "../_translations/i18n";

import { ReactComponent as devicesSvg } from "../_assets/appIcons/icon_module_devices.svg";
import { ReactComponent as clockinsSvg } from "../_assets/appIcons/icon_module_clockins.svg";
import { ReactComponent as advancedSvg } from "../_assets/appIcons/icon_module_advanced.svg";
import { ReactComponent as settingsSvg } from "../_assets/appIcons/icon_module_settings.svg";
import { ReactComponent as invitationsSvg } from "../_assets/appIcons/icon_module_invitations.svg";


export const EVEROU_SIDEBAR_SECTION_IDS = {
    DEVICES:        "SECTION_DEVICES",
    SETTINGS:       "SECTION_SETTINGS",
    CLOCKINS:       "SECTION_CLOCKINS",
    INVITATIONS:    "SECTION_INVITATIONS",
    RESERVATIONS:   "SECTION_RESERVATIONS",
    ADVANCED:       "SECTION_ADVANCED",
};

export const everouSidebarSections = [
    SectionsSidebar.SidebarSectionModel({
        text:i18n.t("global_devices"),
        SvgComponent: devicesSvg,
        id: EVEROU_SIDEBAR_SECTION_IDS.DEVICES,
        moduleIds: [
            EVEROU_MODULE_IDS.DEVICES,
            EVEROU_MODULE_IDS.LOGS,
            EVEROU_MODULE_IDS.VIDEO,
            EVEROU_MODULE_IDS.OTA,
        ],
    }),

    EverouModules.getModuleById(EverouModules.IDS.PROTECT),

    SectionsSidebar.SidebarSectionModel({
        text: i18n.t("mod_clockins_title"),
        SvgComponent: clockinsSvg,
        id: EVEROU_SIDEBAR_SECTION_IDS.CLOCKINS,
        moduleIds: [
            EVEROU_MODULE_IDS.CLOCKINS,
            EVEROU_MODULE_IDS.CLOCKINS_REPORTS,
        ],
    }),
    
    EverouModules.getModuleById(EverouModules.IDS.BARRIERS),
    EverouModules.getModuleById(EverouModules.IDS.ALERTS),
    SectionsSidebar.SidebarSectionModel({
        text: i18n.t("invitations.invitations"),
        SvgComponent: invitationsSvg,
        id: EVEROU_SIDEBAR_SECTION_IDS.INVITATIONS,
        moduleIds: [
            EVEROU_MODULE_IDS.INVITATIONS, 
            EVEROU_MODULE_IDS.INVITATIONS_GROUPS,
            EVEROU_MODULE_IDS.INVITATIONS_USERS,
        ],
    }),
    EverouModules.getModuleById(EverouModules.IDS.RESERVATIONS),
    EverouModules.getModuleById(EverouModules.IDS.DASHBOARD),

    SectionsSidebar.SidebarSectionModel({
        text: i18n.t("webapp_global_advanced"),
        SvgComponent: advancedSvg,
        id: EVEROU_SIDEBAR_SECTION_IDS.ADVANCED,
        moduleIds: [
            EVEROU_MODULE_IDS.USER_MOBILES,
            EVEROU_MODULE_IDS.USERS_STATUS,
            EVEROU_MODULE_IDS.AUDIT_LOGS,
            EVEROU_MODULE_IDS.BLUETOOTH,
        ],
    }),

    SectionsSidebar.SidebarSectionModel({
        text: i18n.t("settings.settings"),
        SvgComponent: settingsSvg,
        id: EVEROU_SIDEBAR_SECTION_IDS.SETTINGS,
        moduleIds: [
            EVEROU_MODULE_IDS.SETTINGS,
            EVEROU_MODULE_IDS.CUSTOMIZATION,
            EVEROU_MODULE_IDS.SUBSCRIPTIONS,
            EVEROU_MODULE_IDS.TRIGGERS,
            EVEROU_MODULE_IDS.BILLING,
            EVEROU_MODULE_IDS.DEVELOPERS,
        ],
    }),

    EverouModules.getModuleById(EverouModules.IDS.INTEGRATIONS),
    EverouModules.getModuleById(EverouModules.IDS.DUMMY),
];