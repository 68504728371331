export function LocationInfo({
    timezone = null,
    locationName = null,
    locationId, 
    latitude,
    longitude,
    radius,
    shareData,
    isBarrierEnabled,
    barrierMode,
    isProtectOn,
    protectOnEnter,
    protectOnExit,
    protectScheduleEnabled,
    protectScheduleBegin,
    protectScheduleEnd,
    protectScheduleEnabledSince,

    interfaceType,
    hasClockins = false,
    allowsCustomization,
    hasReservations,
    isThereBrain = false,
    canAddUsersToGroup,
    canBuyInvitations,

    rooms,

    invitationUid,
    invitationData,
    locationPermission = null,
    isSuperguest,
    isNotificationActive,
    allowsRemoteClockins,

    canCreateSuperguests,
    invitationsAllowed,
    invitationsLeft,

    companyName,
    companyContactEmail,
    companyCIF,
    companyAdditionalInfo,
    hasProtectSubscription,

    showProtectLocation,

    videoUrl,
    videoName,

    uid
} = {}) {
    return {
        timezone,
        locationName,
        latitude,
        longitude,
        radius,
        shareData,
        locationId,
        isProtectOn,
        protectOnEnter,
        protectOnExit,
        protectScheduleEnabled,
        protectScheduleBegin,
        protectScheduleEnd,
        protectScheduleEnabledSince,
        interfaceType,
        hasClockins,
        allowsCustomization,
        hasReservations,
        isThereBrain,
        hasProtectSubscription,
        canAddUsersToGroup,
        canBuyInvitations,
        isBarrierEnabled,
        barrierMode,
        rooms,
        
        invitationUid,
        invitationData,
        locationPermission,
        isSuperguest,
        isNotificationActive,
        allowsRemoteClockins,
        
        canCreateSuperguests,
        invitationsAllowed,
        invitationsLeft,

        companyName,
        companyContactEmail,
        companyCIF,
        companyAdditionalInfo,

        // Local, derived field
        showProtectLocation,
        
        videoName,
        videoUrl,

        uid
    };
}