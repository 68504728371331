import { Reservation } from "../_models/Reservation";

export function ReservationSlotModel({
    isReservable = true,

    utcBegin,
    utcEnd,

    isAvailable,
    isYours,
    reservations,
    hasPassed,
    isHappening = false,

    uid,
} = {}) {
    return {
        isReservable,

        utcBegin,
        utcEnd,

        isAvailable,
        isYours,
        reservations: reservations && reservations.map(Reservation),
        hasPassed,
        isHappening,

        uid,
    };
}