import { RemoveTokenPopUp } from "./ModuleTokens/RemoveTokenPopUp";

function getPopUp(ID) {
    const POPUP_FOR_ID = {
        [IDS.REMOVE_TOKEN]: RemoveTokenPopUp,
    };

    return POPUP_FOR_ID[ID];
}

const IDS = {
    REMOVE_TOKEN:   "REMOVE_TOKEN",
};

export const DevelopersPopUps = {
    IDS,
    getPopUp,
};