export function UserStatusMobile({
    isUpToDate = false,
    activityStatusPermissionChanges = 0,
    blePermissionChanges = 0,
    locationPermissionChanges = 0,
    locationServicePermissionChanges = 0,
    notificationsPermissionChanges = 0,
    batteryOptimizerPermissionChanges = 0,
    bleScanPermissionChanges = 0,
    backgroundLocationPermissionChanges = 0,
} = {}) {
    return {
        isUpToDate,
        activityStatusPermissionChanges,
        blePermissionChanges,
        locationPermissionChanges,
        locationServicePermissionChanges,
        notificationsPermissionChanges,
        batteryOptimizerPermissionChanges,
        bleScanPermissionChanges,
        backgroundLocationPermissionChanges
    };
}