import React from "react";

export function CaptionText({
    children,
    style = {},
    className = '',
    color = 'default'
}: {
    children: any;
    style?: React.CSSProperties;
    className?: string;
    color?: string | 'default';
}) {
    if(color === 'default') {
        color = '#6c757d';
    }
    return (
        <div style={{ fontSize: '12px', color, ...(style ?? {}) }} className={`${className} text-left font-italic`}>
            {children}
        </div>
    );
}
