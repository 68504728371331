import React from "react";
import { useTranslation } from "react-i18next";
import { SegmentedControl } from "../../../Common/_components";
import { CaptionText } from "../../../Common/_components/CaptionText";
import {
    invitationPermissionDescriptions,
    invitationPermissionUiValues
} from "../../_constants";
import { InvitationsHelper } from "../../_helpers";
import { Invitation } from "../../_models/Invitation";

export function PermissionSelectorComponent({
    locationPermission,
    invitation = Invitation(),
    isEditable,
    isNew = false,
    onChangePermission
}) {
    //LIBRARIES
    const { t } = useTranslation();
    //ACTION HANDLERS

    //VIEW
    const { type, permissionType } = invitation;
    return (
        <div>
            <div>
                <h3>{t("common.typeOfUser")}</h3>
                {permissionSelector()}
                <div className="mt-1" />
            </div>
            {permissionDescription()}
        </div>
    );

    function permissionSelector() {
        const segments = getUiAvailablePermissionSegments(type, locationPermission);
        return (
            <div className="w-100 mt-2">
                <SegmentedControl defaultSelected={{ value: invitation.permissionType }} preventDefaultSelect={!invitation.newInvitation} readOnly={!isEditable} onSelect={onChangePermission} segments={segments}/>
            </div>
        );
    }

    function permissionDescription() {
        return (
            <CaptionText className="mt-1 ml-1">
                {invitationPermissionDescriptions[permissionType]}
            </CaptionText>
        );
    }

    function getUiAvailablePermissionSegments(currentType, locationPermission, publicUrl) {
        const availablePermissions = isEditable && !publicUrl
            ? InvitationsHelper.getAvailableInvitationPermissions(
                  currentType,
                  locationPermission,
              )
            : [permissionType];
            
        return availablePermissions.map((permission) => ({
            label: invitationPermissionUiValues[permission],
            value: permission
        }));

    }
}
