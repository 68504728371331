import { genericActions } from "../_helpers/ActionErrorWrapper";

import { BUDGET_USER_ACTION_TYPES } from "../_constants/budgetUser.constants";
import { BudgetUserMapper } from "../_mappers/BudgetUserMapper";
import { EmployeeService } from "../_services/EmployeeSevice";
import { BridgeUser } from "../../Common/_models/BridgeUser";

export const BudgetUserActions = {
    getBudgetUser,
};

function getBudgetUser(bridgeUser = BridgeUser()) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(fn));
        
        async function fn() {
            if (!(bridgeUser && bridgeUser.isDistributor)) {
                console.warn("NO BRIDGE OR NOT DISTRIBUTOR");
                return;
            }

            const serverEmployee = await EmployeeService.getEmployee();
            const budgetUser = BudgetUserMapper.serverToLocal(
                bridgeUser,
                serverEmployee,
            );
            dispatch(success(budgetUser));

            return budgetUser;
        }
    };

    function success(budgetUser) {
        return {
            type: BUDGET_USER_ACTION_TYPES.BUDGET_USER_GET_SUCCESS,
            budgetUser,
        };
    }
}