import { i18n } from "./_translations";

import { ModuleModel } from "../Everou/_models";
import { Icons } from "../Common/_assets/icons/Icons";
import { ClientsModule } from "./ModuleClients/ClientsModule";
import { DistributorModule } from "./ModuleDistributor/DistributorModule";
import { ProductsModule } from "./ModuleProducts/ProductsModule";
import { BudgetsModule } from "./ModuleBudgets/BudgetsModule";
import { DocumentationModule } from "./ModuleDocumentation/DocumentationModule";
import { AppModulesFactory } from "../Common/AppModulesFactory";

const URLS = {
    DISTRIBUTOR:    "/distributor",
    BUDGETS:        "/budgets",
    CLIENTS:        "/clients",
    PRODUCTS:       "/products",
    DOCUMENTATION:  "/documentation",
};

const IDS = {
    DISTRIBUTOR: "DISTRIBUTOR",
    CLIENTS: "CLIENTS",
    PRODUCTS: "PRODUCTS",
    BUDGETS: "BUDGETS",
    DOCUMENTATION: "DOCUMENTATION",
};

const componentForModuleDictionary = {
    [IDS.DISTRIBUTOR]:   DistributorModule,
    [IDS.CLIENTS]:       ClientsModule,
    [IDS.PRODUCTS]:      ProductsModule,
    [IDS.BUDGETS]:       BudgetsModule,
    [IDS.DOCUMENTATION]: DocumentationModule,
};

function getEnabledModuleIds(hasDistributor) {
    return hasDistributor
        ? Object.values(BudgetsModules.IDS)
        : [BudgetsModules.IDS.DISTRIBUTOR]
    ;
}

const BUDGETS_MODULES = [
    ModuleModel({
        isEnabled: true,

        icon: Icons.files,
        moduleId: IDS.BUDGETS,
        uiName: i18n.t("mod_budgets_title"),
        url: URLS.BUDGETS,
    }),
    ModuleModel({
        isEnabled: true,

        icon: Icons.people,
        moduleId: IDS.CLIENTS,
        uiName: i18n.t("mod_clients_title"),
        url: URLS.CLIENTS,
    }),
    ModuleModel({
        isEnabled: true,

        icon: Icons.devices,
        moduleId: IDS.PRODUCTS,
        uiName: i18n.t("mod_products_title"),
        url: URLS.PRODUCTS,
    }),
    ModuleModel({
        isEnabled: true,

        icon: Icons.pdf,
        moduleId: IDS.DOCUMENTATION,
        uiName: i18n.t("mod_budgets_docs_title"),
        url: URLS.DOCUMENTATION,
    }),

    ModuleModel({
        isEnabled: true,

        icon: Icons.gear,
        moduleId: IDS.DISTRIBUTOR,
        uiName: i18n.t("mod_distributor_title"),
        url: URLS.DISTRIBUTOR,
    }),
];

export const BudgetsModules = {
    ...AppModulesFactory.makeModules({
        ALL_MODULES: BUDGETS_MODULES,
        componentForModuleDictionary,
        DEFAULT_MODULE: IDS.BUDGETS,
    }),
    getEnabledModuleIds,
    URLS,
    IDS,
};
