import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { PMS_API_URLS } from "../_constants/PMS_API_URLS";

export const PMSKrossBookingService = {
    listProperties,
    listRooms,
}

function listProperties(uid: string) {
    return ServiceHelper.getRequest(
        PMS_API_URLS.KROSS_BOOKING_PROPERTIES,
        {
            uid: uid
        }
    );
}

function listRooms(uid: string, host_id: number) {
    return ServiceHelper.getRequest(
        PMS_API_URLS.KROSS_BOOKING_ROOMS,
        {
            uid: uid,
            host_id: host_id
        }
    );
}