import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Selectors } from '../../_reducers/app.reducer';
import { useTranslation } from 'react-i18next';
import { IntegrationApp } from '../../_models/IntegrationApp';
import { SingleIntegration } from './SingleIntegration';

export function IntegrationsColumn({
    integrations = [] as any || [IntegrationApp()],
}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let selectedIntegrationConfig = IntegrationApp();
    selectedIntegrationConfig = useSelector(Selectors.getSelectedIntegration);
    
    //VIEW
    return (
        buildIntegrations()
    );


    function buildIntegrations() {
        return (
            integrations
            .map((integration) =>
                <SingleIntegration
                    integration={integration}
                    selectedIntegrationId={
                        selectedIntegrationConfig && selectedIntegrationConfig.id
                    }
                    status={integration.installed? t("global_enabled"):undefined}
                />
            )
        );
    }

}