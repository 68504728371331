import UAParser from "ua-parser-js";

export class UaHelper {
    static getOS() {
        const { name, version } = uaObject().getOS();
        return `${name}-${version}`;
    }
    
    static getBrowser() {
        const { name, version } = uaObject().getBrowser();
        return `${name}-${version}`;
    }

    static getLocale() {
        return (
            navigator.languages !== undefined
                ? navigator.languages[0]
                : navigator.language
        );
    }
}

function uaObject() {
    return new UAParser();
}