import { UserMobiles } from "../_models/UserMobiles"
import { MobileMapper } from "./MobileMapper"
import { UserMapper } from "../_mappersTS/UserMapper";
export class UserMobilesMapper {
    static allServerToLocal(serverUserMobiles = []) {
        return serverUserMobiles.map(serverToLocal);
    }
}

function serverToLocal(serverUserMobile) {
    return UserMobiles({
        mobiles: MobileMapper.allServerToLocal(serverUserMobile.mobiles),
        user: UserMapper.serverToLocal(serverUserMobile.user),
    });
}