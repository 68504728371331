import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseWidget } from "./BaseWidget";

import { devicesActions, dashboardActions } from "../_actions";
import { deviceIconForType } from "../_assets";
import { DeviceUiHelper } from "../_helpers";
import { WIDGET_TYPES } from "../_constants";
import { Device } from "../_models/Device";
import { Selectors } from "../_reducers/app.reducer";

const deviceArray = [Device()];

export function WidgetFavDevices({
    widget,
    editable,
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let devices = deviceArray;
    devices = useSelector(Selectors.getSelectedLocationDevices) || deviceArray;

    //LOCAL STATE
    const isIntialRender = useRef(true);

    //UPDATE
    useEffect(() => {
        checkDevicesInWidgetOnDevicesChange();

        function checkDevicesInWidgetOnDevicesChange() {
            if (isIntialRender.current) {
                isIntialRender.current = false;
                return;
            }
    
            dispatch(
                dashboardActions.removeDeletedDevicesFromWidget(widget)
            );
    
        }
    }, [widget, dispatch, devices]);

    //DATA PROCESSING
    let { widgetId, settings } = widget;

    settings = {
        deviceUids: [],
        ...settings,
    };

    //VIEW
    return (
        <BaseWidget
            widgetType={WIDGET_TYPES.DEVICES}
            widgetId={widgetId}
            settings={settings}

            title={t("devices.favoriteDevices")}
            content={content()}
            editable={editable}
        />
    );

    function content() {
        return (
            devices
            .filter(device => settings.deviceUids.includes(device.uid))
            .map(singleDevice)
        );
    }

    function singleDevice(device = Device()) {
        return (
            <div
                key={device.uid}
                className="
                    d-flex
                    align-items-center
                    justify-content-between

                    rounded border
                    bg-white
                    mb-1
                    p-1

                    c-line-height-1rem
                "
            >
                <img
                    className="c-img-1-5rem"
                    src={deviceIconForType(device.type)}
                    alt="device type icon"
                />
                <span className="flex-grow-1 ml-1">
                    {device.description}
                </span>

                <span className="flex-grow-1 text-right mr-2">
                    {device.statusLabel}
                </span>

                <span>
                    {DeviceUiHelper.getUiButtonForDevice(device, handleActionButton)}
                </span>
            </div>
        );
    }

    //ACTION HANDLERS
    function handleActionButton(deviceActionStatus, device) {
        dispatch(devicesActions.sendActionToDevice(deviceActionStatus, device));
    }
}