export function PopUpModel({
    componentName = null,
    isVisible = false,
    props = {},
} = {}) {
    return {
        componentName,
        isVisible,
        props,
    };
}