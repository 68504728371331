import { InvitationQueryParams } from "../_modelsTS/Pagination";
import { URL_CONSTANTS } from "../_constants";
import { ServiceHelper } from './ServiceHelper';

export const invitationsService = {
    getMyInvitations,

    getInvitationsList,
    updateInvitation,
    requestInvitations,
    deleteInvitation,
    validateInvitation,
    acceptInvitation,
    cancelInvitation,
    resendInvitation,
    listPending,
    deleteInvitationPublicUrl,
    createInvitationPublicUrl,
    mailInvitationPublicUrl,
    getInvitation,
    getSubinvitations,
};

async function getMyInvitations(locationUid: string) {
    return ServiceHelper.getRequest(URL_CONSTANTS.MY_INVITATIONS, { loc_uid: locationUid });
}

function resendInvitation(uid: string) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_SEND, { uid });
}

function getInvitationsList(queryParams: InvitationQueryParams & { offset?: number, loc_uid?: string, sat?: boolean }) {
    return ServiceHelper.getRequest(URL_CONSTANTS.INVITATION_LIST, queryParams);
}


function deleteInvitation(invitationUid: string) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_DELETE, { uid: invitationUid });
}

function deleteInvitationPublicUrl({ uid, locationUid }) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_DELETE_PUBLIC_URL, { uid, loc_uid: locationUid });
}

function createInvitationPublicUrl({ uid, locationUid }) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_GENERATE_PUBLIC_URL, { uid, loc_uid: locationUid })
}

function mailInvitationPublicUrl({ uid, locationUid }) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_SEND_PUBLIC_URL_MAIL, { uid, loc_uid: locationUid })
}

function updateInvitation(invitation) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_UPDATE, invitation);
}

function requestInvitations(serverInvitation) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_BULK_REQUEST, serverInvitation);
}

function validateInvitation(code: string) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_VALIDATE, { code });
}

function acceptInvitation(uid: string) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_ACCEPT, { uid });
}

function cancelInvitation(uid: string) {
    return ServiceHelper.postRequest(URL_CONSTANTS.INVITATION_CANCEL, { uid });
}

function listPending() {
    return ServiceHelper.getRequest(URL_CONSTANTS.INVITATION_LIST_PENDING);
}

function getInvitation(uid: string) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INVITATION_INFO,
        "?uid=" + uid,
    );
}

function getSubinvitations(locationUid: string) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.INVITATION_SUBINVITATIONS,
        "?loc_uid=" + locationUid,
    );
}