import React from "react";
import { DeviceDevicesSelector } from "./DeviceDevicesSelector";
import { DeviceGroupSelector } from "./DeviceGroupSelector";

type FiltersColumnProps = {
    showDeviceFilter?: boolean;
    classname?: string;
    style?: React.CSSProperties;
}

export function FiltersColumn({
    showDeviceFilter = false,
 
}: FiltersColumnProps) {
    return (
        <div
            style={{
                position: 'sticky',
                top: 16,
            }}
            className={`
                d-flex
                no-gutters
                flex-column
                overflow-auto
                c-h-min
            `}
        >
            <DeviceGroupSelector className={showDeviceFilter && "c-mh-25"} />

            {showDeviceFilter && (
                <>
                    <div className="mb-2 m-0" />
                    <DeviceDevicesSelector />
                </>
            )}
        </div>
    );
}