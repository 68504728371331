import { useState } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseInputCodeGlobalPopUp } from "./BaseInputCodeGlobalPopUp";
import { globalActions } from "../_actions";
import { codeActions } from "../_actions/code.actions";

const testIDs = {
    codeInput: "global-code-input-field",
    acceptButton: "global-code-input-accept-button",
};

InputCardCodeGlobalPopUp.testIDs = testIDs;

export function InputCardCodeGlobalPopUp() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [code, setCode] = useState("");

    const [hasMediaDeviceConnected, setHasMediaDeviceConnected] = useState(false); 

    navigator?.mediaDevices?.enumerateDevices().then(devices => {
        const hasMediaDeviceConnected = devices.some(device => device.kind === "videoinput");
        setHasMediaDeviceConnected(hasMediaDeviceConnected);
    }).catch(() => setHasMediaDeviceConnected(false));
   
    const hint = hasMediaDeviceConnected ? t('scan_cards_manual_hint_or_scan_with_webcam') : t("scan_cards_manual_hint") ;

    //VIEW
    return (
        <BaseInputCodeGlobalPopUp
            inputTestID={testIDs.codeInput}
            buttonTestID={testIDs.acceptButton}
        
            handleClosePopUp={handleClosePopUp}
            headerTitle={t("scan_cards_manual_title")}
            hint={hint}
            buttonText={t("global_validate")}
            onChangeInput={setCode}
            hasMediaDeviceConnected={hasMediaDeviceConnected}
            onClickButton={handleSendCode}
            placeholder={t("scan_cards_manual_placeholder")}
            value={code}
        />
    );

    //ACTION HANDLERS
    async function handleSendCode() {
        dispatch(codeActions.manageCode(code));
    }

    function handleClosePopUp() {
        dispatch(globalActions.hidePopUp());
    }
}