import React, { useMemo } from 'react';
import { Provider } from 'react-redux';

import { Routes } from './Routing/Routes';
import { StoreManager } from './StoreManager';

import { VersionHelper } from './_helpers/VersionHelper';

import './_translations/i18n';

window.version = VersionHelper.getAppVersion();

export function WebApp({ enableLogger = true }) {
    
    const store = useMemo(() =>
        StoreManager.setUpStore(enableLogger)
    , [enableLogger]);

    if (!store)
        return null;

    return (
        <Provider store={store}>
            <Routes />
        </Provider>
    );
};