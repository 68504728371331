import { TimeHelper } from "../_helpers";
import { AuditLog } from "../_models/AuditLog";
import { AUDIT_LOG_TYPES, filterDisabled, AUDIT_LOG_CODES } from "../_constants";
import { MobileMapper } from "./MobileMapper";
import { AuditLogsHelper } from "../_helpers/AuditLogsHelper";
import { LocalAuditPathModel } from "../_models/LocalAuditPathModel";

export class AuditLogMapper {
    static allServerToLocal(serverLogs) {
        return serverLogs.map(serverToLocal);
    }

    static parametersLocalToServer({
        localLocationUid,
        localBegin,
        localEnd,
        localType,
        localCode,
        localOffset,
        localUser,
        localActionPath,
        localPaths = [],
    }) {
        return {
            locationUid: localLocationUid,
            userUid: mapUserLocalToServer(localUser),
            code: mapCodeLocalToServer(localCode),
            type: mapTypeLocalToServer(localType),
            begin: localBegin,
            end: localEnd,
            offset: localOffset,
            paths: mapPathsLocalToServer(localPaths, localType, localActionPath),
        };


        function mapUserLocalToServer(localUser) {
            if (isDisabled(localUser))
                return;

            return localUser;
        }
        
        function mapCodeLocalToServer(localCode) {
            if (isDisabled(localCode))
                return;

            for (const [key, value] of Object.entries(codeMap)) {
                if (value === localCode)
                    return key; 
            }

            return undefined;
        }
        
        function mapTypeLocalToServer(localType) {
            if (isDisabled(localType))
                return;

            for (const [key, value] of Object.entries(typeMap)) {
                if (value === localType)
                    return key; 
            }
        
            return undefined;
        }

        function isDisabled(value) {
            return Number(value) === filterDisabled.number;
        }
    }
}


function serverToLocal(serverLog) {
    const {
        id,
        
        user_uid,
        user_email,
        code,
        datetime,
        path,
        content,
        type,

        mobile_params,
    } = serverLog;
    
    return AuditLog({
        id,

        type: typeServerToLocal(type),
        userUid: user_uid,
        userEmail: user_email,
        code: codeServerToLocal(code),
        datetime: TimeHelper.serverStringToIsoString(datetime),
        path,
        content: JSON.parse(content),

        mobile: MobileMapper.serverToLocal(mobile_params),
    });
}

function codeServerToLocal(serverCode) {
    return codeMap[serverCode];
}

function typeServerToLocal(serverType) {
    return typeMap[serverType];
}

function mapPathsLocalToServer(localPaths = [] || [LocalAuditPathModel()], localType, localActionPath) {
    if (!localPaths.length)
        return getFromSelection();

    return getFromPaths();

    

    function getFromSelection() {
        const path = AuditLogsHelper.getPath(localType, localActionPath);
    
        if (!path)
            return [];
    
        return [path];
    }

    function getFromPaths() {
        return localPaths.map(localPath => localPath.path);
    }
}

const typeMap = {
    "-1": AUDIT_LOG_TYPES.UNDEFINED,
    0: AUDIT_LOG_TYPES.BRAIN,
    1: AUDIT_LOG_TYPES.CAMERA,
    2: AUDIT_LOG_TYPES.DEVICE,
    3: AUDIT_LOG_TYPES.INVITATION,
    4: AUDIT_LOG_TYPES.LOCATION,
    5: AUDIT_LOG_TYPES.MOBILE,
    6: AUDIT_LOG_TYPES.ROOM,
    7: AUDIT_LOG_TYPES.TRIGGER,
    8: AUDIT_LOG_TYPES.USER,
    9: AUDIT_LOG_TYPES.WIDGET,
};

const codeMap = {
    200: AUDIT_LOG_CODES.OK,
    400: AUDIT_LOG_CODES.ERROR,
};