import { genericActions } from "./generic.actions";
import { invitationCardService } from "../_services/invitationCard.service";
import {
    INVITATION_CARD_ACTION_TYPES,
    CARD_STATES,
    CARD_INVITATION_STATES,
} from "../_constants/invitationCard.constants";
import { CardMapper } from "../_mappers/CardMapper";
import { invitationsActions } from "./invitations.actions";
import { INVITATIONS_SECONDARY_VIEWS, GLOBAL_POPUPS } from "../_constants";
import { globalActions } from "./global.actions";
import { Card } from "../_models/Card";
import { InvitationsMapper } from "../_mappers";
import { i18n } from "../_translations/i18n";
import { invitationGroupService } from "../_services/invitationGroups.service";
import { InvitationGroupMapper } from "../_mappers/InvitationGroupMapper";
import { TimeHelper } from "../_helpers";
import { codeService } from "../_services/code.service";
import { NfcActions } from "../../Budgets/_actions/NfcActions";
import { CODE_TYPES } from "../_constants/code.constants";
import { Invitation } from "../_models/Invitation";
import { Selectors } from "../_reducers/app.reducer";
import { popUpActions } from "../_stores/PopUpStore/popUpActions";
import { appPermissions } from "../_constants/permissions.constants";
import { LocationModel } from "../_models/LocationModel";

export const invitationCardActions = {
    manageInvitationCardGlobal,
    manageNfcCardCodeGlobal,
    
    manageNfcOrInvitationCardLocal,
    
    //exposed for testing
    acceptCardInvitation,
    getCardInfo,
    processConfiguredCard,

    showCardIsFromAnotherLocationDialog,
    showCardAlreadyConfigDialog,
    showCardUsedDialog,
    showNoOwnerLocationDialog,
    showNfcConfiguredDialog,
    showNotConfigurationAllowed,
    removeSelectedCard,
    //PRIVATE
    _storeCardInfo,
};

function manageNfcCardCodeGlobal(code) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            let card = Card();
            card = await dispatch(NfcActions.getNfcInfo(code, true));

            if (card.state === CARD_STATES.USED) {
                dispatch(showNfcConfiguredDialog());
                return;
            }

            if (!card.isConfiguration && !isCardConfigured(card)) {
                dispatch(showNotConfigurationAllowed());
                return;
            }

            dispatch(commonCardFlowAction(card));
        }
    };
}

function showNotConfigurationAllowed() {
    return globalActions.showPopUp(
        GLOBAL_POPUPS.GENERIC_NOTIFICATION,
        { body: i18n.t("mod_cards_not_configuration_allowed") },
    )
}

function showNfcConfiguredDialog() {
    return popUpActions.showPopUp(
        GLOBAL_POPUPS.GENERIC_NOTIFICATION,
        { body: i18n.t("mod_cards_nfc_already_configured") },
    );
}

function manageInvitationCardGlobal(code) {
    return async dispatch => {
        let card = Card();
        card = await dispatch(invitationCardActions.getCardInfo(code));

        if (card.state === CARD_STATES.USED) {
            dispatch(showCardUsedDialog());
            return;
        }

        
        if (!card.isConfiguration && !isCardConfigured(card)) {
            dispatch(showNotConfigurationAllowed());
            return;
        }
        if (isCardConfigured(card)) {
            await dispatch(invitationCardActions.acceptCardInvitation(code));
            return;
        }

        dispatch(commonCardFlowAction(card));
    };

}

function isCardConfigured(card = Card()) {
    return !!card.invitationGroupUid;
}

function commonCardFlowAction(card) {
    return (dispatch, getState) => {
        canUserManageGroupsInAnyLocationFn()
            ? showConfigurePackWizard()
            : dispatch(showNoOwnerLocationDialog())
        ;

        function canUserManageGroupsInAnyLocationFn() {
            let locations = [LocationModel()];
            locations = Selectors.getLocations(getState());
            return locations.some(location => 
                appPermissions.canUserManageInvitationGroups(location.userType)
            );
        }
    
        function showConfigurePackWizard() {
            dispatch(invitationCardActions._storeCardInfo(card));
            dispatch(popUpActions.showPopUp(GLOBAL_POPUPS.ADD_NEW_PACK));
        }
    }
}

function showNoOwnerLocationDialog() {
    return popUpActions.showPopUp(
        GLOBAL_POPUPS.GENERIC_NOTIFICATION,
        { body: i18n.t("mod_cards_no_location_owned_message") },
    );
}

function manageNfcOrInvitationCardLocal(code) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const { type } = await codeService.getType(code);

            switch (type) {
                case CODE_TYPES.NFC_CARD: {
                    await dispatch(processCode(code, NfcActions.getNfcInfo));
                    return;
                }
                case CODE_TYPES.INVITATION_CARD: {
                    await dispatch(processCode(code, invitationCardActions.getCardInfo));
                    return;
                }
        
                default:
                    return;
            }
        }
    }
}

function processCode(code, getInfoAction = (code, rethrow) => {}) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const card = await dispatch(getInfoAction(code, true));
            if (card.state === CARD_STATES.USED)
                return dispatch(showCardUsedDialog());
    
            if (isCardConfigured())
                return dispatch(invitationCardActions.processConfiguredCard(
                    card,
                    Selectors.getSelectedLocationUid(getState()),
                ));
    
            return goToConfigWizard();
    
            function isCardConfigured() {
                return !!card.invitationGroupUid;
            }
    
            function goToConfigWizard() {
                dispatch(invitationCardActions._storeCardInfo(card));
                dispatch(invitationsActions.changeSecondaryView(
                    INVITATIONS_SECONDARY_VIEWS.ADD_PACK_FULL_WIZARD,
                ));
            }
        }
    };
}

function showCardUsedDialog() {
    return globalActions.showPopUp(
        GLOBAL_POPUPS.GENERIC_NOTIFICATION,
        { body: i18n.t("scan_error_already_used") },
    );
}

function processConfiguredCard(card = Card(), selectedLocationUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverInvitationGroup = await invitationGroupService.getInvitationGroup(
                card.invitationGroupUid,
            );
            const localInvitationGroup = InvitationGroupMapper.serverToLocal(serverInvitationGroup);

            if (localInvitationGroup.invitation.locationUid !== selectedLocationUid) {
                dispatch(showCardIsFromAnotherLocationDialog(
                    localInvitationGroup.name,
                    localInvitationGroup.invitation.locationName,
                ));
                return;
            }

            dispatch(showCardAlreadyConfigDialog(
                localInvitationGroup.name,
                localInvitationGroup.uid,
            ));
        }
    }
}

function showCardIsFromAnotherLocationDialog(invitationGroupName, locationName) {
    return globalActions.showPopUp(
        GLOBAL_POPUPS.GENERIC_NOTIFICATION,
        {
            body: i18n.t(
                "invitation_group_list_existing_dialog_other_location_pw",
                {
                    groupName: invitationGroupName,
                    locationName: locationName,
                },
            )
        },
    );
}

function showCardAlreadyConfigDialog(invitationGroupName, invitationGroupUid) {
    return globalActions.showPopUp(
        GLOBAL_POPUPS.CARD_ALREADY_CONFIGURED,
        {
            invitationGroupName,
            invitationGroupUid,
        },
    );
}

function getCardInfo(cardCode) {
    return async () => {
        const serverCard = await invitationCardService.getCardInfo(cardCode);
        return CardMapper.serverToLocal(serverCard);
    };
}

function _storeCardInfo(card) {
    return {
        type: INVITATION_CARD_ACTION_TYPES.GET_CARD_SUCCESS,
        card,
    };
}

function removeSelectedCard() {
    return {
        type: INVITATION_CARD_ACTION_TYPES.REMOVE_SELECTED_CARD
    }
}

function acceptCardInvitation(cardCode) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverResponse = await invitationCardService.acceptCardInvitation(cardCode);
            const localInvitations = InvitationsMapper.mapAllServerToLocal(
                serverResponse.invitations
            );

            dispatch(globalActions.hidePopUp());
            
            const firstInvitation = localInvitations[0];
            dispatch(
                globalActions.showPopUp(GLOBAL_POPUPS.GENERIC_NOTIFICATION, {
                    body: getMessage(serverResponse.state, localInvitations),
                    onOk: async () => {
                        await dispatch(
                            globalActions.setPreselectedLocationUid(
                                firstInvitation.locationUid,
                            ),
                        );
                    },
                }),
            );
        }
    }

    function getMessage(state, invitations = [] || [Invitation()]) {
        const firstInvitation = invitations[0];
        switch (state) {
            case CARD_INVITATION_STATES.NEW:
                return i18n.t(
                    "invitation_accept_new_dialog_pw",
                    {
                        name: invitations.reduce((name, invitation, index) =>
                            name + (index ? ", " : "") + invitation.deviceName, ""
                        ),
                    },
                );

            case CARD_INVITATION_STATES.UPDATED_SUBINVITATIONS:
                return i18n.t(
                    "invitation_accept_subinvitations_updated_dialog_pw",
                    {
                        number: firstInvitation.subinvitations,
                    },
                );

            case CARD_INVITATION_STATES.UPDATED_INVITATION_DURATION:
                return i18n.t(
                    "invitation_accept_duration_updated_dialog_pw",
                    {
                        date: TimeHelper.isoStringToLocalTimezoneFormat(
                            firstInvitation.end,
                        ),
                    },
                );

            default:
                return "unknown invitation state";
        }
    }
}