import { locationService, deviceService } from "../_services";
import { ACCESS_TYPE, DEVICES_ACTION_TYPES, DEVICE_TYPES, DEVICE_UPDATE_REFRESH_INTERVAL } from "../_constants";
import { EXCEPTIONS } from "../_constants/Exceptions";
import { genericActions } from "./generic.actions";
import { Device as DeviceLegacy } from "../_models/Device";
import { DeviceHelper } from "../_helpers";
import { DeviceMapper } from "../_mappers";
import { filterActions } from "./filter.actions";
import { Selectors } from "../_reducers/app.reducer";
import { LocationInfo } from "../_models/LocationInfo";
import { BleActionDataMapper } from "../_mappers/BluetoothActionDataMapper";
import { LocationModel } from "../_models/LocationModel";
import { DevicesSecondaryViewStore } from "../_stores/DevicesSecondaryViewStore";
import { FirmwareVersionMapper } from "../_mappersTS/FirmwareVersionMapper";
import { FirmwareVersion } from "../_modelsTS/FirmwareVersion";
import { Device } from "../_modelsTS/Device";

export const devicesActions = {
    initializeDevices,

    //local
    changeSecondaryView: DevicesSecondaryViewStore.actionSet,

    toggleSelectDevice,
    selectAllVisibleDevices,

    showDevicesOfSelectedCategories,
    selectDeviceConfig,
    updateLocalDevice,
    updateSelectedDeviceName,
    updateSelectedDeviceRoom,
    discardSelectedDeviceChanges,
    updateSelectedDeviceFirmwareVersions,
    updateSelectedDeviceUpdateStatus,

    //remote
    sendActionToDevice,
    getDevicesFromLocation,
    toggleDeviceFromProtect,
    getDevice,
    saveSelectedDevice,
    updateFirmware,
    refreshWhileNot,
    updateDeviceBarrierStatusRemote,
    updateDeviceRemoteAccess,
    updateRestrictExit,

    // OTA Update
    getDeviceFirmwareVersions,
    reboot,

    //remote BLE actions
    requestBleAction,
    confirmBleAction,
};

function getDevice(uid, type, save = true) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const isBrain = type === DEVICE_TYPES.Brain;
            const serverDevice = await (isBrain
                ? deviceService.getBrainInfo(uid)
                : deviceService.getDeviceInfo(uid));
            const device = DeviceMapper.serverToLocal(
                serverDevice,
                getLocationName(),
                getRoomName(),
            );
            if (save) {
                dispatch(getDeviceSuccess(device));
            }
            return device as Device;
        }

        function getDeviceSuccess(device) {
            return {
                type: DEVICES_ACTION_TYPES.GET_DEVICE_SUCCESS,
                device,
            };
        }

        function getRoomName() {
            const rooms = Selectors.getRooms(getState());
            const room: any = rooms.find((room: any) => room.uid);
            return room?.name;
        }

        function getLocationName() {
            let locations = [LocationModel()];
            locations = Selectors.getLocations(getState());
            const location = locations.find((location) => location.uid);
            return location && location.name;
        }
    };
}

function updateDeviceRemoteAccess(deviceUid: string, remote: boolean) {
    return async dispatch => {
        const serverDevice = await deviceService.updateDevice({ remote, uid: deviceUid });
        const localDevice = DeviceMapper.serverToLocal(serverDevice);
        dispatch(updateLocalDevice(localDevice));
    };
}

function updateRestrictExit(deviceUid: string, restrictExit: boolean) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverDevice = await deviceService.updateDevice({ uid: deviceUid, restrict_exit: restrictExit });
            const localDevice = DeviceMapper.serverToLocal(serverDevice);
            dispatch(updateLocalDevice(localDevice));
        }
    };
}

function getDeviceFirmwareVersions(uid) {
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        async function asyncAction() {
            const serverVersions =
                await deviceService.getDeviceFirmwareVersions({ uid });
            const firmwareVersions = serverVersions.map((version) =>
                FirmwareVersionMapper(version),
            );
            dispatch(getDeviceVersionsSuccess(firmwareVersions));
            return firmwareVersions;
        }

        function getDeviceVersionsSuccess(firmwareVersions) {
            return {
                type: DEVICES_ACTION_TYPES.DEVICE_GET_FIRMWARE_VERSIONS_SUCCESS,
                firmwareVersions,
            };
        }
    };
}


function updateSelectedDeviceUpdateStatus(device) {
    return {
        type: DEVICES_ACTION_TYPES.DEVICE_REMOTE_OTA_UPDATE_STATUS,
        device
    }
}


function refreshWhileNot(param: keyof Device, deviceUid: string, interval = DEVICE_UPDATE_REFRESH_INTERVAL) {
    return (dispatch) => {
        const refresh = setInterval(async () => {
            const device: Device = await dispatch(devicesActions.getDevice(deviceUid, null, false));
            dispatch(devicesActions.updateSelectedDeviceUpdateStatus(device));
            if (device && !device[param]) {
                clearInterval(refresh);
            }
        }, interval)
    }
}

function updateFirmware(uid: string, version: string) {
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await deviceService.updateDeviceFirmwareVersion(uid, version);
        }
    };
}


function reboot(uid: string) {
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await deviceService.rebootDevice(uid);
        }
    };
}


function requestBleAction(deviceUid, action) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverData = await deviceService.actionRequest(
                deviceUid,
                action,
            );
            return BleActionDataMapper.serverToLocal(serverData);
        }
    };
}

function confirmBleAction(deviceUid, actionUid, packet) {
    return async () => {
        const serverData = await deviceService.actionConfirm(
            deviceUid,
            actionUid,
            packet,
        );
        return BleActionDataMapper.serverToLocal(serverData);
    };
}

function initializeDevices(markedDevice, filterMode, devices, rooms) {
    return (dispatch) => {
        dispatch(filterActions.createFilterCategories(devices, rooms));
        dispatch(filterActions.changeMode(filterMode));
        dispatch(devicesActions.toggleSelectDevice(markedDevice));
    };
}

function discardSelectedDeviceChanges() {
    return {
        type: DEVICES_ACTION_TYPES.SELECTED_DEVICE_DISCARD,
    };
}

function updateSelectedDeviceName(name) {
    return updateSelectedDevice({
        description: name,
    });
}

function updateSelectedDeviceRoom(roomUid) {
    return updateSelectedDevice({
        room_uid: roomUid,
    });
}
function updateSelectedDeviceFirmwareVersions(firmwareVersions: FirmwareVersion[]) {
    return updateSelectedDevice({
        firmwareVersions
    });
}

function updateSelectedDevice(device) {
    return {
        type: DEVICES_ACTION_TYPES.SELECTED_DEVICE_UPDATE,
        device,
    };
}

function saveSelectedDevice() {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const device = Selectors.getDeviceConfig(getState());
            let locationInfo = LocationInfo();
            locationInfo = Selectors.getLocationInfo(getState());
            const locationName = locationInfo.locationName;
            const rooms = Selectors.getRooms(getState());

            if (!device) return;

            const updateFn =
                device.type === DEVICE_TYPES.Brain
                    ? deviceService.updateBrain
                    : deviceService.updateDevice;
            const serverDevice = await updateFn({
                uid: device.uid,
                description: device.description,
                room_uid: device.room_uid,
            });

            const room: any = rooms.find(
                (room: any) => room.uid === serverDevice.room_uid,
            );
            const roomName = room.name;
            const localDevice = DeviceMapper.serverToLocal(
                serverDevice,
                locationName,
                roomName,
            );
            dispatch(saveDeviceSuccess(localDevice));
        }
    };

    function saveDeviceSuccess(device) {
        return {
            type: DEVICES_ACTION_TYPES.SELECTED_DEVICE_SAVE_SUCCESS,
            device,
        };
    }
}

function selectDeviceConfig(deviceUid) {
    return {
        type: DEVICES_ACTION_TYPES.SELECT_DEVICE_CONFIG,
        deviceUid,
    };
}

function toggleDeviceFromProtect(device = DeviceLegacy()) {
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const updateDeviceServiceFn =
                device.type === DEVICE_TYPES.Brain
                    ? deviceService.updateBrain
                    : deviceService.updateDevice;
            await updateDeviceServiceFn({
                uid: device.uid,
                notification: !device.notification,
            });

            dispatch(success(device.uid));
        }
    };

    function success(deviceUid) {
        return {
            type: DEVICES_ACTION_TYPES.TOGGLE_DEVICE_FROM_PROTECT,
            deviceUid,
        };
    }
}

function selectAllVisibleDevices() {
    return {
        type: DEVICES_ACTION_TYPES.SELECT_ALL_VISIBLE_DEVICES,
    };
}

function showDevicesOfSelectedCategories(
    allDevices = [],
    allCategories = [],
    deviceFilterMode,
) {
    return (dispatch) => {
        const deviceUidsOfCategory = DeviceHelper.getDeviceUidsOfCategory(
            allDevices,
            allCategories,
            deviceFilterMode,
        );

        dispatch(showDevices(deviceUidsOfCategory));
        dispatch(devicesActions.selectAllVisibleDevices());
    };

    function showDevices(devicesUids: any[] = []) {
        return {
            type: DEVICES_ACTION_TYPES.DEVICES_SHOW,
            devicesUids,
        };
    }
}

function sendActionToDevice(actionStatus, device) {
    return async (dispatch) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const { uid, type: deviceType } = device;

            if (deviceType === DEVICE_TYPES.Plug30) {
                await deviceService.sendNoStatusActionToDevice(
                    uid,
                    actionToEnabled(actionStatus),
                    false,
                    0,
                    0,
                );

                return;
            }

            const actionUid = await deviceService.sendActionToDevice(
                uid,
                actionStatus,
            );
            dispatch(
                success({
                    actionUid,
                    status: actionStatus,
                    deviceUid: uid,
                    deviceType,
                }),
            );
        }
    };

    function actionToEnabled(action) {
        return action === 1;
    }

    function success(deviceAction) {
        return {
            type: DEVICES_ACTION_TYPES.SEND_ACTION_SUCCESS,
            deviceAction,
        };
    }
}

function toggleSelectDevice(deviceUid) {
    return (dispatch, getState) => {
        if (!deviceUid || deviceDoesNotExist()) return;

        if (isTogglingOffLastDevice())
            return dispatch(devicesActions.selectAllVisibleDevices());

        return dispatch({
            type: DEVICES_ACTION_TYPES.DEVICES_TOGGLE_SELECT_SINGLE,
            deviceUid,
        });

        //
        function deviceDoesNotExist() {
            return !Selectors.getSelectedLocationDevices(getState()).some(
                (device) => device.uid === deviceUid,
            );
        }

        function isTogglingOffLastDevice() {
            return (
                !Selectors.getIsAllDevicesSelected(getState()) &&
                isLastSelectedDevice(
                    Selectors.getSelectedLocationDevices(getState()),
                    deviceUid,
                )
            );
        }

        function isLastSelectedDevice(devices, selectedUid) {
            return (
                devices.filter((device) => device.selected).length === 1 &&
                devices.find((device) => device.selected).uid === selectedUid
            );
        }
    };
}

function updateLocalDevice(device) {
    return {
        type: DEVICES_ACTION_TYPES.DEVICES_UPDATE_SINGLE,
        device,
    };
}

function getDevicesFromLocation(locationUid) {
    return async (dispatch) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!locationUid) throw EXCEPTIONS.NO_LOCATION_SELECTED;

            const serverLocationInfo = await locationService.getLocationInfo(
                locationUid,
            );
            const localDevices =
                DeviceMapper.serverLocationinfoToLocalDevices(
                    serverLocationInfo,
                );

            dispatch(success(localDevices, locationUid));
            return localDevices
        }
    };

    function success(devices, locationUid) {
        return {
            type: DEVICES_ACTION_TYPES.DEVICES_GET_SUCCESS,
            devices,
            locationUid,
        };
    }
}


function updateDeviceBarrierStatusRemote({ deviceUid, accessType, isBarrier, onUpdate, restrictExit }: { deviceUid: string, accessType?: ACCESS_TYPE, isBarrier: boolean, onUpdate: Function, restrictExit?: boolean }) {

    return async dispatch => {

        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverDevice = await deviceService.updateDevice({
                uid: deviceUid,
                ...(accessType !== undefined && { access_type: accessType }),
                is_barrier: isBarrier,
                ...(restrictExit !== undefined && { restrict_exit: restrictExit })
            });
            const localDevice = DeviceMapper.serverToLocal(serverDevice);
            dispatch(updateLocalDevice(localDevice));
            onUpdate && await onUpdate(localDevice);
        }
    };
}