import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from 'react-i18next';

import { Client } from "../_models/Client";
import { ValidationHelper } from "../../Common/_helpers/ValidationHelper";
import { ValidatedTextInput } from "../../Common/_components/ValidatedTextInput";

export function BaseClientForm({
    client = Client(),
    setIsAllValid = () => {},
    forceShowWarnings = false,

    setParams = () => {},
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [name, setName] =                     useState((client && client.name) || "");
    const [isNameValid, setIsNameValid] =       useState(false);

    const [cif, setCif] =                       useState((client && client.cif) || "");
    const [isCifValid, setIsCifValid] =         useState(false);

    const [email, setEmail] =                   useState((client && client.email) || "");
    const [isEmailValid, setIsEmailValid] =     useState(false);

    const [phone, setPhone] =                   useState((client && client.phone) || "");
    const [isPhoneValid, setIsPhoneValid] =     useState(false);

    const [address, setAddress] =               useState((client && client.address) || "");
    const [isAddressValid, setIsAddressValid] = useState(false);

    //LOCAL VARS
    const phoneValidationFns = useMemo(() =>
        [ValidationHelper.UiValidationFns.phone]
    , []);
    const emailValidationFns = useMemo(() =>
        [ValidationHelper.UiValidationFns.email]
    , []);

    //EFFECTS
    setIsAllValid(checkIsFormValid());

    useEffect(() => {
        setParams({
            name,
            cif,
            email,
            phone,
            address,
        });
    },[
        setParams,

        name,
        cif,
        email,
        phone,
        address,
    ]);

    function checkIsFormValid() {
        return (
            isNameValid
            && isCifValid
            && isEmailValid
            && isPhoneValid
            && isAddressValid
        );
    }

    //VIEW
    return (
        <div>
            <div className="mb-1"/>
            <ValidatedTextInput
                isRequired={true}

                value={name}
                setValue={setName}
                setIsValid={setIsNameValid}

                title={t("global_name")}
                placeholder={t("global_name")}

                forceShowWarnings={forceShowWarnings}
            />
            <div className="mt-4"/>
            <hr className="m-0 mb-1"/>
            <ValidatedTextInput
                value={cif}
                setValue={setCif}
                setIsValid={setIsCifValid}

                title={t("global_cif")}
                placeholder={t("global_cif")}

                forceShowWarnings={forceShowWarnings}
            />
            <div className="mt-4"/>
            <hr className="m-0 mb-1"/>
            <ValidatedTextInput
                warningFns={emailValidationFns}

                value={email}
                setValue={setEmail}
                setIsValid={setIsEmailValid}

                title={t("global_email")}
                placeholder={t("global_email")}

                forceShowWarnings={forceShowWarnings}
            />
            <div className="mt-4"/>
            <hr className="m-0 mb-1"/>
            <ValidatedTextInput
                warningFns={phoneValidationFns}

                value={phone}
                setValue={setPhone}
                setIsValid={setIsPhoneValid}

                title={t("global_phone")}
                placeholder={t("global_phone")}

                forceShowWarnings={forceShowWarnings}
            />
            <div className="mt-4"/>
            <hr className="m-0 mb-1"/>
            <ValidatedTextInput
                value={address}
                setValue={setAddress}
                setIsValid={setIsAddressValid}

                title={t("global_address")}
                placeholder={t("global_address")}

                forceShowWarnings={forceShowWarnings}
            />
        </div>
    );
}