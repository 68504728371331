import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import {
    FILTER_DISABLED_ALL,
} from '../_constants';
import { CustomDropdown } from '../_components/CustomDropdown';
import { ClockinActions } from '../_stores/ClockinStore/ClockinActions';
import { InvitationsHelper } from '../_helpers';

export function ClockinsFilters({
    users,
    filter,
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //ACTION HANDLERS
    function handleChangeUserFilter(filterValue) {
        dispatch(ClockinActions.changeFilter({
            user: filterValue,
        }));

        if (filterValue !== FILTER_DISABLED_ALL.key) {
            dispatch(ClockinActions.selectUserUid(filterValue));
        }
    }

    //VIEW
    return (
        <div className="
                d-flex
                no-gutters
                justify-content-between
                flex-wrap
                w-100
                mb-2
                mt-2
            ">
            {userFilter()}
        </div>
    );

    function userFilter() {

        return (
            <>
            <div className="mt-1"/>
            {sortedSelectList(
                t("common.user"),
                buildUsersOptions(),
                handleChangeUserFilter,
                filter.value.user || FILTER_DISABLED_ALL.key,
                "user-filter",
            )}
            </>
        );

        function buildUsersOptions() {
            const uiOptions = [disabledOption()];

            users.forEach(user => {
                let name = user.user.name;
                if (name === undefined || name === "") {
                    name = "-";
                } else {
                    if (user.user.lastName) {
                        name += " " + user.user.lastName;
                    }    
                }

                uiOptions.push(
                    CustomDropdown.buildOption(
                        user.uid,
                        InvitationsHelper.getUiUserName(name, user.user.email)
                    )
                )
            });

            var seen = {};
            const uniqueUiOptions = uiOptions.filter(function(item) {
                return seen.hasOwnProperty(item.value) ? false : (seen[item.value] = true);
            });

            return uniqueUiOptions;
        }
    }

    function sortedSelectList(listTitle, options, onChange, value, testid) {
        return (
            <CustomDropdown
                title={listTitle}
                testid={testid}
                options={options}
                onChange={onChange}
                value={value}
                sortAlphabetically={true}
            />
        );
    }
}

function disabledOption() {
    return CustomDropdown.buildOption(
        FILTER_DISABLED_ALL.key,
        FILTER_DISABLED_ALL.value,
    );
}