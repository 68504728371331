export const TRIGGERS_ACTION_TYPES = {
    GET_TRIGGERS_SUCCESS: "GET_TRIGGERS_SUCCESS",
};

export const TRIGGER_TYPES = {
    LOCATION: "localization_event",
    TIME: "time_event",
    DEVICE: "device_event",
    PROTECT: "protect_event",
};

export const TRIGGER_EFFECT_ACTION_TYPES = {
    DEVICE: 0,
    BRAIN: 1,
    PROTECT: 2,
    NOTIFICATION: 5,
};