export function Distributor({
    uid,
    name, 
    cif,
    phone,
    email, 
    avatar,
    notes,
} = {}) {
    return {
        uid,
        name, 
        cif,
        phone,
        email, 
        avatar,
        notes,
    };
}