import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import {
    MainPageWithButtons,
    ContentSpace,
    CapsuleButton,
    GenericTextArea,
    FileUploadComponent,
} from "../_components";
import { Distributor } from "../_models/Distributor";
import { BudgetsStore } from "../_reducers/budgetsRootReducer";
import { DistributorActions } from "../_actions/DistributorActions";
import { ValidationHelper } from "../../Everou/_helpers";
import { ValidatedTextInput } from "../../Common/_components/ValidatedTextInput";

export function DistributorModule() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let distributor = Distributor();
    distributor = useSelector(BudgetsStore.selectors.getDistributor);

    //LOCAL STATE
    const [forceShowWarnings, setForceShowWarnings] = useState(false);

    const phoneValidation = ValidationHelper.UiValidationFns.phone;
    const emailValidation = ValidationHelper.UiValidationFns.email;
    
    const [name, setName] = useState(distributor && distributor.name);
    const [isNameValid, setIsNameValid] = useState(false);
    const [cif, setCif] = useState(distributor && distributor.cif);
    const [isCifValid, setIsCifValid] = useState(false);
    const [email, setEmail] = useState(distributor && distributor.email);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [phone, setPhone] = useState(distributor && distributor.phone);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [notes, setNotes] = useState((distributor && distributor.notes) || "");
    
    const isFormValid = (
        isNameValid
        && isCifValid
        && isEmailValid
        && isPhoneValid
    )

    //VIEW
    return (
        <ContentSpace
            mainContent={editDistributorView()}
        />
    );

    function editDistributorView() {
        return (
            <MainPageWithButtons
                pageTitle={t("mod_distributor_title")}
                content={content()}
                buttons={buttons()}
            />
        );

        function buttons() {
            return [
                <CapsuleButton
                    onClick={handleSend}
                    text={t("global_save")}
                />,
            ];
        }

        function content() {
            return (
                <div>
                    <div className="mb-1"/>
                    <ValidatedTextInput
                        setValue={setName}
                        setIsValid={setIsNameValid}
                        value={name}
                        isRequired={true}
                        forceShowWarnings={forceShowWarnings}
                        
                        title={t("global_name")}
                        placeholder={t("global_name")}
                    />
                    <div className="mt-4"/>
                    <hr className="m-0 mb-1"/>
                    <ValidatedTextInput
                        setValue={setCif}
                        setIsValid={setIsCifValid}
                        value={cif}
                        isRequired={true}
                        forceShowWarnings={forceShowWarnings}

                        title={t("global_cif")}
                        placeholder={t("global_cif")}
                    />
                    <div className="mt-4"/>
                    <hr className="m-0 mb-1"/>
                    <ValidatedTextInput
                        setValue={setEmail}
                        setIsValid={setIsEmailValid}
                        value={email}
                        isRequired={true}
                        forceShowWarnings={forceShowWarnings}
                        warningFns={[emailValidation]}

                        title={t("global_email")}
                        placeholder={t("global_email")}
                    />
                    <div className="mt-4"/>
                    <hr className="m-0 mb-1"/>
                    <ValidatedTextInput
                        setValue={setPhone}
                        setIsValid={setIsPhoneValid}
                        value={phone}
                        isRequired={true}
                        forceShowWarnings={forceShowWarnings}
                        warningFns={[phoneValidation]}

                        title={t("global_phone")}
                        placeholder={t("global_phone")}
                    />
                    <div className="mt-4"/>
                    <hr className="m-0 mb-1"/>
                    {notesSection({
                        text: notes,
                        handleEditText: setNotes,
                        placeholder: t("mod_budgets_notes"),
                        
                        title: t("mod_budgets_notes"),
                    })}
                    <hr className="m-0 mb-1"/>
                    {avatarSection()}
                </div>
            );
        }

        function notesSection({ text, title, handleEditText, placeholder }) {
            return (
                <div>
                    <div className="font-weight-bold text-uppercase">
                        {title}
                    </div>
                    <div className="mt-1"/>
                    <GenericTextArea {...{ text, handleEditText, placeholder, }}/>
                </div>
            );
        }

        function avatarSection() {
            if (!distributor)
                return null;
            
            return (
                <div>
                    <div className="font-weight-bold text-uppercase">
                        {t("mod_distributor_avatar")}
                    </div>
                    {avatarImg()}
                    <div className="mt-2"/>
                    <FileUploadComponent
                        onUpload={handleUploadFile}
                    />
                </div>
            );

            function avatarImg() {
                if (!distributor.avatar)
                    return null;
    
                return (
                    <img
                        className="c-img-8rem"
                        alt="ditributor_avatar"
                        src={distributor.avatar}
                    />
                );
            }
        }

        //ACTION HANLDERS
        function handleSend() {
            if (!isFormValid) {
                setForceShowWarnings(true);
                return;
            }

            distributor
                ? dispatch(DistributorActions.updateDistributor({
                    uid: distributor.uid,
                    cif,
                    email,
                    name,
                    phone,
                    notes,
                }))
                : dispatch(DistributorActions.createDistributor({
                    cif,
                    email,
                    name,
                    phone,
                    notes,
                }))
            ;
        }

        function handleUploadFile(file) {
            dispatch(DistributorActions.uploadAvatar(file));
        }
    }
}