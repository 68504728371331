import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseLoginPage } from "./BaseLoginPage";
import { authenticationActions } from "../_actions";
import { Selectors } from "../_reducers/app.reducer";

export function ShowCodePage() {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const { email, code, secret } = useSelector(Selectors.getLoginUrlData);

    //INITIALIZATION
    useEffect(() => {
        initialization();

        async function initialization() {
            await dispatch(authenticationActions.validateCode(
                {
                    email,
                    code,
                    secret,
                }
            ))
        }
    }, [dispatch, email, code, secret]);

    //VIEW
    return (
        <BaseLoginPage
            content={codeOnlyContent()}
        />
    );

    function codeOnlyContent() {
        return (
            <div>
                <div>
                    {t("login.inputThisCodeInYourBrowser")}
                </div>
                <div className="mt-2"/>
                <div className="
                    p-1

                    text-center
                    text-dark

                    bg-light
                    rounded
                ">
                    {code}
                </div>
            </div>
        );
    }
}