export function OtherUser({
    uid,
    name,
    lastName,
    email,
    avatar,
} = {}) {
    return {
        uid,
        name,
        lastName,
        email,
        avatar,
    };
}