import React from "react";
import { useTranslation } from "react-i18next";

import { ContentSpace, MainPageWithButtons } from "../_components";
import { ProtectMessage } from "./ProtectMessage";

import protectErrorIcon from "../_assets/appIcons/icon_protect_error.svg";

export function ProtectNoBrainPage() {
    const { t } = useTranslation();

    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("protect.protect")}
                    content={content()}
                />
            }
        />    
    );

    function content() {
        return (
            <div className="
                d-flex
                no-gutters
                flex-column
                
                pt-2
            ">
                <ProtectMessage
                    icon={protectErrorIcon}
                    textA={t("protect.noBrainFound")}
                    textB={t("protect.addBrainToEnableProtect")}
                />
            </div>
        );
    }
}