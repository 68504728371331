import React from "react";

export function HelpModule() {

    return (
        <div className="
            w-100
            h-100
            d-flex

            justify-content-center
            align-items-center
        ">
            <iframe
                className="
                    col
                    col-lg-10
                    col-xl-8
                "
                src="https://www.youtube.com/embed/EaUcdUxh6Qk"
                height="650"
                
                title="video"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
            />
        </div>
    );
};