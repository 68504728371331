import { INVITATION_TYPES } from "../_constants";
import { i18n } from "../_translations/i18n";
import { Invitation } from "../_models/Invitation";
import { InvitationGroup } from "../_models/InvitationGroup";

export const InvitationGroupHelper = {
    canInvitationBeAddedToGroup
}

function canInvitationBeAddedToGroup(group = InvitationGroup(), allGroups = [] || [InvitationGroup()], invitation = Invitation()) {

    if(!invitation) return true;

    if (group.uid === invitation.invitationGroupUid) throw Error(i18n.t('mod_invitations_email_already_in_group'));
    if (group.invitation.type === INVITATION_TYPES.LOCATION) throw Error(i18n.t("mod_invitations_group_typeof_location_not_allowed_email"));
    if (invitation.type === INVITATION_TYPES.LOCATION) throw Error(i18n.t("mod_invitations_email_already_in_location"));

    const invitationSourceGroup = allGroups.find(({ uid }) => uid === invitation.invitationGroupUid) as any;
    if (invitationSourceGroup?.invitation?.rooms.some(({ uid: invitationRoomUid }) => (group.invitation.rooms as any[]).map(({ uid: invitationGroupRoomUid }) => invitationGroupRoomUid).includes(invitationRoomUid))) throw Error(i18n.t('mod_invitations_cannot_add_to_group_with_shared_rooms'));

    return true;

}