import { i18n } from '../_translations/i18n';

import {
    UI_LOG_TYPES_WITH_STATUS,
    UI_LOG_TYPES,
    LOG_ERROR_CODES,
    GLOBAL_POPUPS,
    DEVICE_TYPES
} from '../_constants';
import { devicesActions } from '.';
import { uiAlertActions } from './uiAlert.actions';
import { WebsocketMapper } from '../_mappers';
import { globalActions } from './global.actions';
import { websocketService } from '../_services';
import { Selectors } from '../_reducers/app.reducer';

export const websocketsActions = {
    connected,
    error,
    gpioOrderReceived,

    setUpWebsocket,
};

const UI_LOG_TYPES_NON_STATUS_LOGS_REPRESENTED = [
    UI_LOG_TYPES.VIBRATIONS,
];

function setUpWebsocket() {
    return (dispatch, getState) => {
        websocketService.setUp(
            Selectors.getMachineUid(getState()),
            Selectors.getApikey(getState()),
        );
    };
}

function connected() {
    return dispatch => {
        console.log("websocket connected");
    };
}

function error(error) {
    return dispatch => {
        console.error(error);
        dispatch(uiAlertActions.error(error));
    };
}

function gpioOrderReceived(websocketData) {
    return (dispatch, getState) => {
        const uiLogType = websocketData.log_ui_type;

        if (uiLogType === UI_LOG_TYPES.ERROR) {
            manageErrorLog(
                websocketData.device_data,
                Selectors.getDevicesLastAction(getState()),
                websocketData.action_uid,
                dispatch
            );
            return;
        }

        if (UI_LOG_TYPES_WITH_STATUS.includes(uiLogType)) {
            const updatedDevice = WebsocketMapper.serverToLocalStatus(websocketData);
            dispatch(devicesActions.updateLocalDevice(updatedDevice));
            return;
        }

        if (UI_LOG_TYPES_NON_STATUS_LOGS_REPRESENTED.includes(uiLogType)) {
            const updatedDevice = WebsocketMapper.serverToLocalNonStatus(websocketData);
            dispatch(devicesActions.updateLocalDevice(updatedDevice));
            return;
        }

        console.log("websocket received, nothing to update", websocketData);
    };

    function manageErrorLog(logDeviceData, lastAction, actionUid, dispatch) {

        if (lastAction.actionUid !== actionUid)
            return;

        switch (lastAction.deviceType) {

            case DEVICE_TYPES.GowayLock:
                manageGowayLockError(logDeviceData, lastAction, dispatch);
                return;

            default:
                return;
        }
    }

    function manageGowayLockError(logDeviceData, lastAction, dispatch) {
        if (logDeviceData.status === LOG_ERROR_CODES.ERROR_BATTERY_LOW) {
            if (lastAction.status !== 1)
                return;

            const batteryValue = logDeviceData.battery_raw_value;
            if (batteryValue <= 2170) {
                dispatch(
                    uiAlertActions.error(i18n.t("errors.batteryTooLowToOpen"))
                );
                return;
            }
            if (batteryValue <= 2253) {
                dispatch(
                    globalActions.showPopUp(
                        GLOBAL_POPUPS.EMERGENCY_OPEN,
                        { deviceUid: logDeviceData.uid }
                    )
                );
                return;
            }
        }
    }

}