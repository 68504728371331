import React, { useMemo, useState } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Icons } from "../_assets";
import { CapsuleButton } from "../_components";
import { TimeHelper } from "../_helpers";
import { Workday } from "../_modelsTS/Workday";
import { EditableTime } from "./EditableTime";
import { SingleClockin } from "./SingleClockin";

type SingleWorkdayProps = {
    workday: Workday;
    canEdit?: boolean;
    timezone: string;
    handleAddClockin: (date: Date) => void;
    handleDeleteClockin: () => void;
    handleUpdateClockin: () => void;
};

export function SingleWorkday({
    workday,
    canEdit,
    handleAddClockin,
    handleDeleteClockin,
    handleUpdateClockin,
    timezone,
}: SingleWorkdayProps) {
    // Get current day
    var today = new Date();
    var todayDateString =
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2);

    // Default isOpen
    let defaultIsOpen = false;
    if (new Date(todayDateString) === workday.day) {
        defaultIsOpen = true;
    }

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [isAdding, setIsAdding] = useState(false);
    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [time, setTime] = useState(
        new Date(TimeHelper.getStartOfLocalDayInTimezoneIso(workday.day, timezone)),
    );

    const currentIsoDay = TimeHelper.serverStringToIsoString(workday.day);
    const currentDayUi = TimeHelper.isoStringToUiString(
        currentIsoDay,
        TimeHelper.getTimeFormats().DATE_AND_WEEKDAY,
    );
    const workTimeUi =
        TimeHelper.minutesToTimeString(workday.workMinutes) || "0h";
    const breaksTimeUi =
        TimeHelper.minutesToTimeString(workday.breakMinutes) || "0h";

    const id = useMemo(() => uuidv4(), []);

    function localHandleAddClockin(timeDate) {
        handleAddClockin(timeDate);
        setIsAdding(false);
    }


    const decoratedOnClick = useAccordionButton(id, () => setIsOpen(!isOpen));

    //VIEW
    return (
        <div className="
        border
        rounded

        mb-1
    "
>
                <div
                    onClick={decoratedOnClick}
                    className={`${isOpen ? 'border-bottom' : ''} c-hover-240 py-1 cp d-flex align-items-center w-100 justify-content-between flex-wrap m-0`}
                >
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                        <img
                            alt={"arrow_img"}
                            src={Icons.arrowDown}
                            className={`
                                c-transition
                                ${!isOpen && "c-rotate-ccw-90"}
                            `}
                        />
                            <div className="c-text-capitalize-first font-weight-bold">
                                {currentDayUi}
                            </div>
                        </div>

                        {isValidText()}
                    </div>

                    <div
                        style={{marginLeft: 'auto'}}
                        className="
                        d-flex
                    justify-content-end
                    flex-wrap
                    "
                    >
                        {addedTime(t("mod_clockins_work_time"), workTimeUi)}
                        {addedTime(t("mod_clockins_break_time"), breaksTimeUi)}
                    </div>
                </div>
            <Accordion.Collapse eventKey={id}>
                <>
                    {clockinsSection()}
                    {/* <div className="border-top"/> */}
                    {addClockinSection()}
                </>
            </Accordion.Collapse>
        </div>
    );

    function addedTime(text, value) {
        return (
            <div className="ml-2 c-w-8-5rem flex-shrink-0 text-nowrap">
                {text}
                {":"}
                <span className="ml-2" />
                {value}
            </div>
        );
    }

    function isValidText() {
        if (workday.isValid) return;

        return (
            <div className="ml-2 text-danger">{t("mod_clockins_invalid")}</div>
        );
    }

    function clockinsSection() {
        return workday.clockins
            .sort((a, b) => (TimeHelper.isIsoABeforeB(a.date, b.date) ? -1 : 0))
            .map((clockin) => (
                <SingleClockin
                    key={clockin.uid}
                    {...{
                        clockin,
                        canEdit,
                        handleDeleteClockin,
                        handleUpdateClockin,
                        timezone,
                        localDay: workday.day,
                    }}
                />
            ));
    }

    function addClockinSection() {
        if (!canEdit) return;

        return (
            <div
                className="
                d-flex
                justify-content-center
                py-2
            "
            >
                {isAdding ? (
                    <EditableTime
                        time={time}
                        setTime={setTime}
                        handleConfirmChanges={localHandleAddClockin}
                        handleDiscardChanges={() => setIsAdding(false)}
                        timezone={timezone}
                        localDay={workday.day}
                    />
                ) : (
                    addButton()
                )}
            </div>
        );

        function addButton() {
            return (
                <CapsuleButton
                    textUppercase={false}
                    text={t("mod_clockins_add_clockin_to_day")}
                    size={"SMALL"}
                    onClick={() => setIsAdding(true)}
                />
            );
        }
    }
}
