export const GLOBAL_ACTION_TYPES = {
    GLOBAL_RESET_STATE: "GLOBAL_RESET_STATE",
    SET_APP_INITALIZED: "GLOBAL_SET_APP_INITALIZED",

    SET_PRESELECTED_LOCATION_UID: "SET_PRESELECTED_LOCATION_UID",
    SET_VALIDATED_LOCATION_UID: "SET_VALIDATED_LOCATION_UID",
};

export const EVEROU_MODULE_IDS = {
    DASHBOARD:      "DASHBOARD",
    DEVICES:        "DEVICES",
    SETTINGS:       "SETTINGS",
    ALERTS:         "ALERTS",
    INVITATIONS:    "INVITATIONS",
    INVITATIONS_GROUPS: "INVITATIONS_GROUPS",
    INVITATIONS_USERS:    "INVITATIONS_USERS",
    LOGS:           "LOGS",
    VIDEO:          "VIDEO",
    PROTECT:        "PROTECT",
    HELP:           "HELP",
    SUBSCRIPTIONS:  "SUBSCRIPTIONS",
    TRIGGERS:       "TRIGGERS",
    AUDIT_LOGS:     "AUDIT_LOGS",
    RESERVATIONS:   "RESERVATIONS",
    USER_MOBILES:   "USER_MOBILES",
    USER_LOGS:      "USER_LOGS",
    USERS_STATUS:   "USERS_STATUS",
    CLOCKINS:       "CLOCKINS",
    CLOCKINS_REPORTS: "CLOCKINS_REPORTS",
    CUSTOMIZATION:  "CUSTOMIZATION",
    BLUETOOTH:      "BLUETOOTH",
    BILLING:        "BILLING",
    DEVELOPERS:     "DEVELOPERS",
    DUMMY:          "DUMMY",
    OTA:            "OTA",
    INTEGRATIONS:   "INTEGRATIONS",
    BARRIERS:       "BARRIERS"
};