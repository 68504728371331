import { genericActions } from "./generic.actions";
import { mobilesService } from "../_services/mobiles.service";
import { UserMobilesMapper } from "../_mappers/UserMobilesMapper";
import { Selectors } from "../_reducers/app.reducer";
import { MobilesStore } from "../_stores/MobilesStore";

export const mobilesActions = {
    //PUBLIC
    getUserMobilesForCurrentLocation,
    selectUserMobiles: MobilesStore.actionSelectItem,

    //PRIVATE
    _listMobilesSuccess: MobilesStore.actionListItemsSuccess,
};

function getUserMobilesForCurrentLocation() {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverUserMobiles = await mobilesService.getMobilesFromLocation(
                Selectors.getSelectedLocationUid(getState()),
            );
            
            dispatch(mobilesActions._listMobilesSuccess(
                UserMobilesMapper.allServerToLocal(serverUserMobiles)
            ));
        }
    };
}