import { CSSProperties } from "react";
import { Accordion } from "react-bootstrap";

type StyleProps = {
    container?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
};

type ClassNameProps = {
    container?: string;
    header?: string;
    body?: string;
};

type CustomAccordionProps = {
    header: any;
    body?: any;
    eventKey?: number;
    onClick?: (...args: any) => void;
    className?: ClassNameProps;
    style?: StyleProps;
    onToggle?: (isOpen: boolean) => void,
    defaultOpened?: boolean;
};

export function CustomAccordion({
    header,
    body,
    eventKey = 0,
    onClick,
    style,
    className,
    onToggle, 
    defaultOpened = false
}: CustomAccordionProps) {

    if(!body) {
        return header;
    }

    return (
        <Accordion
            defaultActiveKey={defaultOpened ? `${eventKey}` : null}
            onSelect={(eventKey) => {
                    onToggle && onToggle(eventKey !== null)
                }
            }
        >
            <Accordion.Item
                style={style?.container ?? {}}
                className={className?.container ?? ""}
                eventKey={eventKey.toString()}
            >
                <Accordion.Header
                    onClick={onClick}
                    style={style?.header ?? {}}
                    className={className?.header ?? ""}
                    as="div"
                >
                    {header}
                </Accordion.Header>
                {body && (
                    <Accordion.Body
                        style={style?.body ?? {}}
                        className={className?.body ?? ""}
                    >
                        {body}
                    </Accordion.Body>
                )}
            </Accordion.Item>
        </Accordion>
    );
}
