import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { CapsuleButton, LogoHeader } from "../Common/_components";
import { Icons } from "../Common/_assets/icons/Icons";
import { WiredPopUpRenderer } from "../Common/PopUps/WiredPopUpRenderer";
import { WiredUserDropdown } from "../Everou/App/WiredUserDropdown";
import { WiredUiAlerts } from "../Common/UiAlerts/WiredUiAlerts";
import { WiredContentWithSidebar } from "../Common/WiredContent/WiredContentWithSidebar";
import { AppWrapper } from "../Common/_components/AppWrapper";
import { DevelopersModules } from "./DevelopersModules";
import { DevelopersModulesActions } from "./DevelopersModulesActions";
import { DevelopersPopUps } from "./DevelopersPopUps";
import { DevUserActions } from "./_actions/DevUserActions";
import { DevelopersStore } from "./DevelopersStore";
import { AppInitializationWrapper } from "../Common/_components/AppInitializationWrapper";
import { DevUser } from "./_models/DevUser";
import { DevelopersStoreSubscriptions } from "./DevelopersStoreSubscriptions";
import { DEVELOPERS_EXTERNAL_URLS } from "./_constants/DEVELOPERS_EXTERNAL_URLS";

const devUser = DevUser();

export function Developers() {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    let user = devUser;
    user = useSelector(DevelopersStore.selectors.getUser);

    //LOCAL STATE
    const [isInitialized, setIsInitialized] = useState(false);

    //INITIALIZATION
    useEffect(() => {
        initialization();
        
        async function initialization() {
            DevelopersStoreSubscriptions.subscribe();

            dispatch(DevelopersModulesActions.setUpModules());
            await dispatch(DevUserActions.getUser());
            setIsInitialized(true);
        }
    }, [dispatch]);
    
    //VIEW
    return (
        <AppWrapper>
            <WiredPopUpRenderer
                getComponentFn={DevelopersPopUps.getPopUp}
            />
            <LogoHeader
                content={headerContent()}
                logoSrc={Icons.everouIcon}
            />
            <WiredUiAlerts />
            <AppInitializationWrapper
                isInitialized={isInitialized}
                isSuccessful={!!user}
                failureMessage={t("mod_dev_not_developer_message")}
                content={
                    <WiredContentWithSidebar
                        getComponentForModuleId={DevelopersModules.getComponentForModuleId}
                        getModulesForIds={DevelopersModules.getModulesForIds}
                        selectModuleAction={DevelopersModulesActions.selectModule}
                    />
                }
            />
        </AppWrapper>
    );


    function headerContent() {
        return (
            <>
            {apiaryDocsLink()}
            {user && <WiredUserDropdown
                user={user}
            />}
            </>
        );

        function handleGoToApiDocs() {
            window.open(
                DEVELOPERS_EXTERNAL_URLS.API_DOCUMENTATION,
                "_blank",
            );
        }

        function apiaryDocsLink() {
            return (
                <CapsuleButton
                    text={t("mod_dev_go_to_api_docs")}
                    onClick={handleGoToApiDocs}
                    fillStyle={'FILLED'}
                    textUppercase={false}
                />
            );
        }
    }
}