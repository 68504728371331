import { i18n } from "../_translations";

import { genericActions } from "../_helpers/ActionErrorWrapper";

import { BudgetCollection } from "../_generics/BudgetCollection";
import { BudgetsService } from "../_services/BudgetsService";
import { BudgetMapper } from "../_mappers/BudgetMapper";
import { Budget } from "../_models/Budget";
import { EntriesActions } from "./EntriesActions";
import { DownloadHelper } from "../_helpers";
import { BudgetsUiAlertsActions } from "./BudgetsUiAlertsActions";
import { ClientActions } from "./ClientActions";
import { Client } from "../_models/Client";
import { BudgetsSecondaryViewStore } from "../_stores/BudgetsSecondaryViewStore";

export const BudgetsActions = {
    //PUBLIC
    createClientAndBudgetAndSelect,

    createBudget,
    listBudgets,
    updateBudget,
    deleteBudget,
    getBudgetAndEntries,
    getBudgetOnly,

    selectedBudget: BudgetCollection.actionSelectItem,
    changeSecondaryView: BudgetsSecondaryViewStore.actionSet,
    createAndSelectBudget,

    getPdf,
    sendPdf,
    
    //PRIVATE
    _updateBudgetSuccess:   BudgetCollection.actionUpdateItemSuccess,
    _getBudgetSuccess:      BudgetCollection.actionGetItemSuccess,
    _listBudgetsSuccess:    BudgetCollection.actionListItemsSuccess,
    _deleteBudgetSuccess:   BudgetCollection.actionDeleteItem,
};

function updateBudget(uid, notes, status, vatApplied) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined));

        async function asyncAction() {
            const serverBudget = await BudgetsService.updateBudget(
                uid,
                notes,
                status,
                vatApplied,
            );
            const budget = BudgetMapper.serverToLocal(serverBudget);
            dispatch(BudgetsActions._updateBudgetSuccess(budget));
            dispatch(EntriesActions.listEntriesSuccess(budget.entries));
            return budget;
        }
    };
}

function getBudgetAndEntries(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverBudget = await BudgetsService.getBudget(uid);
            const budget = BudgetMapper.serverToLocal(serverBudget);
            dispatch(BudgetsActions._getBudgetSuccess(budget));
            dispatch(EntriesActions.listEntriesSuccess(budget.entries));
        }
    };
}

function getBudgetOnly(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverBudget = await BudgetsService.getBudget(uid);
            const budget = BudgetMapper.serverToLocal(serverBudget);
            dispatch(BudgetsActions._getBudgetSuccess(budget));
        }
    };
}

function createBudget(clientUid, notes, rethrow = false) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            const serverBudget = await BudgetsService.createBudget(clientUid, notes);
            const budget = BudgetMapper.serverToLocal(serverBudget);
            dispatch(BudgetsActions._getBudgetSuccess(budget));
            return budget;
        }
    };
}

function listBudgets() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverBudgets = await BudgetsService.listBudgets();
            dispatch(BudgetsActions._listBudgetsSuccess(
                BudgetMapper.allServerToLocal(serverBudgets)
            ));
        }
    };
}

function deleteBudget(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await BudgetsService.deleteBudget(uid);
            dispatch(BudgetsActions._deleteBudgetSuccess(uid));
            dispatch(EntriesActions.deleteAllEntriesSuccess());
        }
    };
}

function createAndSelectBudget(clientUid, notes, rethrow = false) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            let budget = Budget();
            budget = await dispatch(BudgetsActions.createBudget(clientUid, notes, true));
            dispatch(BudgetsActions.selectedBudget(budget.uid));
        }
    };
}

function getPdf(budgetUid, download = true) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const pdfBlob = await BudgetsService.getPdfBlob(budgetUid);
            DownloadHelper.openOrDownloadBlob(pdfBlob, download, "BUDGET");
        }
    };
}

function sendPdf(budgetUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await BudgetsService.sendPdf(budgetUid);
            dispatch(BudgetsUiAlertsActions.showSuccessAlert(i18n.t("mod_budgets_send_success")));
        }
    };
}

function createClientAndBudgetAndSelect(clientParams) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            let client = Client();
            client = await dispatch(ClientActions.createClient(clientParams, true));
            await dispatch(BudgetsActions.createAndSelectBudget(client.uid, undefined, true));
        }
    };
}