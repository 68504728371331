import { i18n } from "../_translations/i18n";

export enum DEVICE_INFO_CELLS {
    STATUS = "STATUS",
    TOGGLE = "TOGGLE",

    TRISTATE_ON_OFF = "TRISTATE_ON_OFF",
    TRISTATE_IO = "TRISTATE_IO",

    TRISTATE_IO_INVERTED = "TRISTATE_IO_INVERTED",
    TRISTATE_IO_PAUSE = "TRISTATE_IO_PAUSE",
};
export enum ACCESS_TYPE {
    GARAGE = 0,
    DOOR = 1,
    ENTRY = 2,
    EXIT = 3,
    CONTACT = 4,
}

export enum ACCESS_EE_WV {
    NOTHING = 0,
    OPEN = 1,
    ASK = 2
}
export enum EXTENDER {
    CONTACT = 0,
    APPROACH = 1
}

export enum DEVICE_UPDATE_STATUS {
    EMPTY,
    INSTALLING,
    ERROR,
    DOWNLOADING,
    CANCELLED,
    OK,
    RESETTING,
    FIRST_TIME, 
    INSTALLING_BLE,
    ERROR_UNPACKING_TAR,
    ERROR_BLE_RESTORED_TO_PREVIOUS_SUCCESS,
    ERROR_BLE_RESTORED_TO_PREVIOUS_FAILED,
    ERROR_BLE_RESTORED_TO_PREVIOUS_NOT_FOUND,
    UNPACKING,
    ERROR_FLASH_SPACE,
    ERROR_FWRITE

}

export const DEVICE_UPDATE_REFRESH_INTERVAL = 3000;


export const DeviceStatusTranslations: { [key in DEVICE_UPDATE_STATUS]: string } = {
    [DEVICE_UPDATE_STATUS.EMPTY]: i18n.t( 'device_firmware_update_status_empty'),
    [DEVICE_UPDATE_STATUS.INSTALLING]: i18n.t('device_firmware_update_status_installing'),
    [DEVICE_UPDATE_STATUS.ERROR]: i18n.t('global_error'),
    [DEVICE_UPDATE_STATUS.DOWNLOADING]: i18n.t('device_firmware_update_status_downloading'),
    [DEVICE_UPDATE_STATUS.CANCELLED]: i18n.t('common.cancelled'),
    [DEVICE_UPDATE_STATUS.OK]: i18n.t('global_ok'),
    [DEVICE_UPDATE_STATUS.RESETTING]: i18n.t('device_firmware_update_status_reseting'),
    [DEVICE_UPDATE_STATUS.FIRST_TIME]: i18n.t('device_firmware_update_status_'),
    [DEVICE_UPDATE_STATUS.INSTALLING_BLE]: i18n.t('device_firmware_update_status_installing_ble'),
    [DEVICE_UPDATE_STATUS.ERROR_UNPACKING_TAR]: i18n.t('device_firmware_update_status_error_unpacking_tar'),
    [DEVICE_UPDATE_STATUS.ERROR_BLE_RESTORED_TO_PREVIOUS_SUCCESS]: i18n.t('device_firmware_update_status_error_ble_restored_to_previous_success'),
    [DEVICE_UPDATE_STATUS.ERROR_BLE_RESTORED_TO_PREVIOUS_FAILED]: i18n.t('device_firmware_update_status_error_ble_restored_to_previous_failed'),
    [DEVICE_UPDATE_STATUS.ERROR_BLE_RESTORED_TO_PREVIOUS_NOT_FOUND]: i18n.t('device_firmware_update_status_error_ble_restored_to_previous_not_found'),
    [DEVICE_UPDATE_STATUS.UNPACKING]: i18n.t('device_firmware_update_status_error_unpacking'), // Por implementar en los firmware, por el momento no llegan nunca, tampoco está en phrase
    [DEVICE_UPDATE_STATUS.ERROR_FLASH_SPACE]: i18n.t('device_firmware_update_status_error_flash_space'),
    [DEVICE_UPDATE_STATUS.ERROR_FWRITE]: i18n.t('device_firmware_update_status_error_fwrite')

}

export const hasNormalUpdateStatus = ({ updateStatus }) => {
    return [
        DEVICE_UPDATE_STATUS.EMPTY,
        DEVICE_UPDATE_STATUS.INSTALLING,
        DEVICE_UPDATE_STATUS.DOWNLOADING,
        DEVICE_UPDATE_STATUS.CANCELLED,
        DEVICE_UPDATE_STATUS.OK,
        DEVICE_UPDATE_STATUS.RESETTING,
        DEVICE_UPDATE_STATUS.FIRST_TIME,
        DEVICE_UPDATE_STATUS.INSTALLING_BLE,
        DEVICE_UPDATE_STATUS.ERROR_BLE_RESTORED_TO_PREVIOUS_SUCCESS,
        DEVICE_UPDATE_STATUS.UNPACKING,
    ].includes(updateStatus)
}

export const hasUndesiredUpdateStatus = ({ updateStatus }) => {
    return [
        DEVICE_UPDATE_STATUS.ERROR,
        DEVICE_UPDATE_STATUS.ERROR_UNPACKING_TAR,
        DEVICE_UPDATE_STATUS.ERROR_BLE_RESTORED_TO_PREVIOUS_FAILED,
        DEVICE_UPDATE_STATUS.ERROR_BLE_RESTORED_TO_PREVIOUS_NOT_FOUND,
        DEVICE_UPDATE_STATUS.ERROR_FLASH_SPACE,
        DEVICE_UPDATE_STATUS.ERROR_FWRITE].includes(updateStatus)
}


export const DEVICES_ACTION_TYPES = {
    GET_DEVICE_SUCCESS: 'GET_DEVICE_SUCCESS',

    SEND_ACTION_SUCCESS: 'DEVICES_SEND_ACTION_SUCCESS',

    DEVICES_GET_SUCCESS: 'DEVICES_GET_SUCCESS',
    
    DEVICES_UPDATE_SINGLE: "DEVICES_UPDATE_SINGLE",

    DEVICES_TOGGLE_SELECT_SINGLE: "DEVICES_TOGGLE_SELECT_SINGLE",

    SELECT_ALL_VISIBLE_DEVICES: "DEVICES_SELECT_ALL_VISIBLE_DEVICES",
    DEVICES_SHOW: "DEVICES_SHOW",

    TOGGLE_DEVICE_FROM_PROTECT: "TOGGLE_DEVICE_FROM_PROTECT",

    SELECT_DEVICE_CONFIG: "SELECT_DEVICE_CONFIG",

    SELECTED_DEVICE_UPDATE: "SELECTED_DEVICE_UPDATE",
    SELECTED_DEVICE_DISCARD: "SELECTED_DEVICE_DISCARD",
    SELECTED_DEVICE_SAVE_SUCCESS: "SELECTED_DEVICE_SAVE_SUCCESS",

    DEVICE_GET_FIRMWARE_VERSIONS_SUCCESS: "DEVICE_GET_FIRMWARE_VERSIONS_SUCCESS",

    DEVICE_REMOTE_OTA_UPDATE_STATUS: "DEVICE_REMOTE_OTA_UPDATE_STATUS"
};

export const DEVICES_SECONDARY_VIEWS = {
    LOGS: "DEVICES_LOGS",
    DEVICE_CONFIG: "DEVICE_CONFIG",
    FIRMWARE_VERSIONS: "FIRMWARE_VERSIONS",
    ROOM_CONFIG: "ROOM_CONFIG",
};

export const PROTECT_STATES = {
    OK: "OK",
    OBSOLETE: "STATE_OBSOLETE",
    WRONG_STATUS: "STATE_WRONG",
};

export const PROXIMITY_SIZES = {
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
    CUSTOM: "CUSTOM",
};

export const ACCESS_TYPES = {
    GARAGE: 0,
    DOOR: 1,
    ENTRY: 2,
    EXIT: 3,
    CONTACT: 4,
};

export const DEVICE_TYPES = {
    //ACTIVE
    None                    : 0,
    ControlAccess           : 1,
    Switcher                : 2,
    DoubleSwitcher          : 3,
    Lighting                : 5,
    Plug                    : 12,
    Roller                  : 15,
    GarageDoor              : 16,
    Brain                   : 17,
    DoublePlug              : 19,
    Flood                   : 22,
    Plug30                  : 24,
    Plug30Security          : 30,
    Security                : 31,
    PlugFlood               : 41,
    Movement                : 42,
    GowayLock               : 46,
    MovementSafeCooking     : 50,
    MovementSafeAccess      : 51,
    PlugSecurity            : 53,
    EasyAccess              : 55,
    EasyParking             : 57,
    EasyBaliza              : 58,
    EasyParkingBarreras     : 59,
    EasyAccessContact       : 60,
    Clockin                 : 61,
    Elevator                : 62,
    LIGHT_EFR32             : 63,
    EasyParkingBarrerasNFC  : 64,
    EasyAccessContactNFC    : 65,
    EasyAccessContactNFCWifi: 66,
    EasyParkingWifi         : 68,
    ClockinNFCWifi          : 69,
    EasyIntegrations        : 70,
    EnergySavingAC          : 71,
    
    //DEVELOPMENT
    MultiSwitcher           : 26,
    IVKey                   : 54,
    
    //LEGACY
    Climate                 : 9,
    Thermostat              : 11,
    EasyAccess4             : 72 // Easy access 4.0 
};

export const DEVICES_ACTIONS_FOR_DEVICE_TYPE = {
    GOWAY_LOCK: {
        CLOSE: 0,
        OPEN: 1,
        EMERGENCY_OPEN: 4,
    },
};

export const DEVICES_WITH_HIDDEN_STATUS = [
    DEVICE_TYPES.GarageDoor,
    DEVICE_TYPES.MovementSafeAccess,
    DEVICE_TYPES.EasyAccess,
    DEVICE_TYPES.EasyAccess4,
    DEVICE_TYPES.EasyParking,
    DEVICE_TYPES.EasyParkingWifi,
    DEVICE_TYPES.EasyBaliza,
    DEVICE_TYPES.EasyParkingBarreras,
    DEVICE_TYPES.EasyParkingBarrerasNFC,
    DEVICE_TYPES.EasyAccessContact,
    DEVICE_TYPES.EasyAccessContactNFC,
    DEVICE_TYPES.EasyAccessContactNFCWifi,
    DEVICE_TYPES.Clockin,
    DEVICE_TYPES.ClockinNFCWifi,
    DEVICE_TYPES.Elevator,
    DEVICE_TYPES.EasyIntegrations,
    DEVICE_TYPES.EnergySavingAC,
];

export function deviceTypeName(deviceType) {
    const NAME_FOR_TYPE = {
        [DEVICE_TYPES.MovementSafeAccess]:  i18n.t("global_device_type_movement_safe_access"),
        [DEVICE_TYPES.MovementSafeCooking]: i18n.t("global_device_type_energy_saving_movement"),
        [DEVICE_TYPES.Movement]:            i18n.t("global_device_type_movement"),
        [DEVICE_TYPES.Security]:            i18n.t("global_device_type_security"),
        [DEVICE_TYPES.Roller]:              i18n.t("global_device_type_roller_shade"),
        [DEVICE_TYPES.PlugFlood]:           i18n.t("global_device_type_flood"),
        [DEVICE_TYPES.Plug]:                i18n.t("global_device_type_plug"),
        [DEVICE_TYPES.Plug30]:              i18n.t("global_device_type_plug_30"),
        [DEVICE_TYPES.Lighting]:            i18n.t("global_device_type_lightning"),
        [DEVICE_TYPES.Switcher]:            i18n.t("global_device_type_switcher"),
        [DEVICE_TYPES.GowayLock]:           i18n.t("global_device_type_lock"),
        [DEVICE_TYPES.DoubleSwitcher]:      i18n.t("global_device_type_double_switcher"),
        [DEVICE_TYPES.MultiSwitcher]:       i18n.t("global_device_type_multi_switcher"),
        [DEVICE_TYPES.DoublePlug]:          i18n.t("global_device_type_double_plug"),
        [DEVICE_TYPES.Brain]:               i18n.t("global_device_type_brain"),
        [DEVICE_TYPES.Flood]:               i18n.t("global_device_type_plug_flood"),
        [DEVICE_TYPES.Plug30Security]:      i18n.t("global_device_type_plug_30_security"),
        [DEVICE_TYPES.EasyAccess]:          i18n.t("global_device_type_easy_access"),
        [DEVICE_TYPES.EasyParking]:         i18n.t("global_device_type_easy_parking"),
        [DEVICE_TYPES.EasyBaliza]:          i18n.t("global_device_type_easy_parking_baliza"),
        [DEVICE_TYPES.EasyParkingBarreras]: i18n.t("global_device_type_easy_parking_barreras"),
        [DEVICE_TYPES.EasyAccessContact]:   i18n.t("global_device_type_easy_access_contact"),
        [DEVICE_TYPES.PlugSecurity]:        i18n.t("global_device_type_plug_security"),
        [DEVICE_TYPES.Thermostat]:          i18n.t("global_device_type_thermostat"),
        [DEVICE_TYPES.Climate]:             i18n.t("global_device_type_climate"),
        [DEVICE_TYPES.GarageDoor]:          i18n.t("global_device_type_garage_door"),
        [DEVICE_TYPES.Clockin]:             i18n.t("global_device_type_easy_clockin"),
        [DEVICE_TYPES.Elevator]:            i18n.t("global_device_type_elevator"),
        [DEVICE_TYPES.LIGHT_EFR32]:         i18n.t("global_device_type_light_efr32"),
        [DEVICE_TYPES.EasyParkingBarrerasNFC]:      i18n.t("global_device_type_easy_parking_barreras_nfc"),
        [DEVICE_TYPES.EasyAccessContactNFC]:        i18n.t("global_device_type_easy_access_contact_nfc"),
        [DEVICE_TYPES.EasyAccessContactNFCWifi]:    i18n.t("global_device_type_easy_access_contact_nfc_wifi"),
        [DEVICE_TYPES.EasyParkingWifi]:             i18n.t("global_device_type_easy_parking_wifi"),
        [DEVICE_TYPES.ClockinNFCWifi]:              i18n.t("global_device_type_easy_clockin_nfc_wifi"),
        [DEVICE_TYPES.EasyIntegrations]:            i18n.t("global_device_type_easy_access_integrations"),
        [DEVICE_TYPES.EnergySavingAC]:              i18n.t("global_device_type_energy_saving_ac"),
        
        //DEVELOPMENT
        [DEVICE_TYPES.IVKey]:                       i18n.t("global_device_type_iv_key"),
        
        //LEGACY
        [DEVICE_TYPES.ControlAccess]:               i18n.t("Control Access"),
        [DEVICE_TYPES.EasyAccess4]:                 i18n.t('global_device_type_easy_access_legacy'),
    };

    return NAME_FOR_TYPE[deviceType] || "NOT FOUND";
}

export const DEVICE_CATEGORIES = {
    Undefined   : "UNDEFINED",
    Doors       : "DOORS",
    Climate     : "CLIMATE",
    Lights      : "LIGHTS",
    OnOff       : "ON_OFF",
    Security    : "SECURITY",
    DEVELOP     : "OTHER",
};

const deviceTypesInCategories = {
    [DEVICE_CATEGORIES.Security]: [
        DEVICE_TYPES.Security,
        DEVICE_TYPES.Flood,
        DEVICE_TYPES.ControlAccess,
        DEVICE_TYPES.Movement,
        DEVICE_TYPES.MovementSafeCooking,
        DEVICE_TYPES.Brain,
        DEVICE_TYPES.PlugFlood,
        DEVICE_TYPES.Plug30Security,
    ],
    [DEVICE_CATEGORIES.Doors]: [
        DEVICE_TYPES.GowayLock,
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.Roller,
        DEVICE_TYPES.MovementSafeAccess,

        DEVICE_TYPES.EasyAccess,
        DEVICE_TYPES.EasyAccess4,
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyBaliza,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.Clockin,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.Elevator,
        DEVICE_TYPES.EasyIntegrations,
    ],
    [DEVICE_CATEGORIES.Climate]: [
        DEVICE_TYPES.Thermostat,
        DEVICE_TYPES.Climate,
        DEVICE_TYPES.PlugSecurity,
        DEVICE_TYPES.EnergySavingAC,
    ],
    [DEVICE_CATEGORIES.Lights]: [
        DEVICE_TYPES.Lighting,
        DEVICE_TYPES.LIGHT_EFR32,
    ],
    [DEVICE_CATEGORIES.OnOff]: [
        DEVICE_TYPES.Switcher,
        DEVICE_TYPES.MultiSwitcher,
        DEVICE_TYPES.DoubleSwitcher,
        DEVICE_TYPES.Plug,
        DEVICE_TYPES.DoublePlug,
        DEVICE_TYPES.Plug30,
    ],
    [DEVICE_CATEGORIES.DEVELOP]: [
        DEVICE_TYPES.IVKey,
    ],
}

export function getTxSizesForDeviceType(deviceType) {
    return TX_SIZES_FOR_DEVICE_TYPE[deviceType];
}

export function getContactProximitySize() {
    return [
        TX_POWER.CONTACT_SMALL,
        TX_POWER.CONTACT_MEDIUM,
        TX_POWER.CONTACT_LARGE,
    ];
}

export function getExtenderProximitySize() {
    return [
        TX_POWER.EXTENDED_SMALL,
        TX_POWER.EXTENDED_MEDIUM,
        TX_POWER.EXTENDED_LARGE,
    ];
}

export const TX_POWER = {
    CONTACT_SMALL: 132,
    CONTACT_MEDIUM: 164,
    CONTACT_LARGE: 196,

    EXTENDED_SMALL: 100,
    EXTENDED_MEDIUM: 164,
    EXTENDED_LARGE: 255,
};

const TX_SIZES_FOR_DEVICE_TYPE = {
    [DEVICE_TYPES.GarageDoor]:                  [100, 164, 255],
    [DEVICE_TYPES.MovementSafeAccess]:          [100, 164, 255],

    [DEVICE_TYPES.EasyParking]:                 [121, 167, 255],
    [DEVICE_TYPES.EasyParkingWifi]:             [121, 167, 255],
    [DEVICE_TYPES.EasyIntegrations]:            [121, 167, 255],

    [DEVICE_TYPES.EasyBaliza]:                  [27, 50, 90],
    [DEVICE_TYPES.EasyAccessContact]:           [27, 50, 90],
    [DEVICE_TYPES.EasyAccessContactNFC]:        [27, 50, 90],   
    [DEVICE_TYPES.EasyAccessContactNFCWifi]:    [27, 50, 90],
    [DEVICE_TYPES.Clockin]:                     [27, 50, 90],
    [DEVICE_TYPES.ClockinNFCWifi]:              [27, 50, 90],
    [DEVICE_TYPES.LIGHT_EFR32]:                 [27, 50, 90],
    [DEVICE_TYPES.Elevator]:                    [27, 50, 90],

    [DEVICE_TYPES.EasyParkingBarreras]:         [121, 144, 167],
    [DEVICE_TYPES.EasyParkingBarrerasNFC]:      [121, 144, 167],
    
};

export function getTypesForDeviceCategory(deviceCategory) {
    return deviceTypesInCategories[deviceCategory];
}

export function getCategoryForDeviceType(deviceType) {
    for (const category in deviceTypesInCategories) {
        if (deviceTypesInCategories[category].includes(deviceType))
            return category;
    }

    return DEVICE_CATEGORIES.Undefined;
}

export function infoCellForDeviceType(deviceType): DEVICE_INFO_CELLS | undefined {
    switch (deviceType) {

        case DEVICE_TYPES.Movement:
        case DEVICE_TYPES.MovementSafeCooking:
        case DEVICE_TYPES.Security:
        case DEVICE_TYPES.MovementSafeAccess:
        case DEVICE_TYPES.Brain:
        case DEVICE_TYPES.Flood:
        case DEVICE_TYPES.ControlAccess:
        case DEVICE_TYPES.Thermostat:
            return DEVICE_INFO_CELLS.STATUS;

        case DEVICE_TYPES.GarageDoor:
        case DEVICE_TYPES.EasyParking:
        case DEVICE_TYPES.EasyParkingWifi:
        case DEVICE_TYPES.EasyParkingBarreras:
        case DEVICE_TYPES.EasyParkingBarrerasNFC:
        case DEVICE_TYPES.EasyAccessContact:
        case DEVICE_TYPES.EasyAccessContactNFC:
        case DEVICE_TYPES.EasyAccessContactNFCWifi:
        case DEVICE_TYPES.Elevator:
        case DEVICE_TYPES.LIGHT_EFR32:
        case DEVICE_TYPES.EasyIntegrations:
        case DEVICE_TYPES.EasyAccess4:
            return DEVICE_INFO_CELLS.TOGGLE;

        case DEVICE_TYPES.Roller:
            return DEVICE_INFO_CELLS.TRISTATE_IO_PAUSE;

        case DEVICE_TYPES.PlugFlood:
            return DEVICE_INFO_CELLS.TRISTATE_IO_INVERTED;
        
        case DEVICE_TYPES.GowayLock:
            return DEVICE_INFO_CELLS.TRISTATE_IO;

        case DEVICE_TYPES.Plug30Security:
        case DEVICE_TYPES.Plug:
        case DEVICE_TYPES.Plug30:
        case DEVICE_TYPES.Switcher:
        case DEVICE_TYPES.DoubleSwitcher:
        case DEVICE_TYPES.DoublePlug:
        case DEVICE_TYPES.MultiSwitcher:
        case DEVICE_TYPES.Lighting:
        case DEVICE_TYPES.PlugSecurity:
            return DEVICE_INFO_CELLS.TRISTATE_ON_OFF;
        
        case DEVICE_TYPES.Clockin:
        case DEVICE_TYPES.ClockinNFCWifi:
        case DEVICE_TYPES.EasyBaliza:
        default:
            return undefined;
    }

}

export const isEasyLock = device => device.type === DEVICE_TYPES.GowayLock;


export const DEVICE_TYPES_ABLE_TO_BARRIERS = [
    DEVICE_TYPES.EasyIntegrations,
    DEVICE_TYPES.EasyAccessContact,
    DEVICE_TYPES.EasyAccessContactNFC,
    DEVICE_TYPES.EasyAccessContactNFCWifi,
    DEVICE_TYPES.EasyParkingBarreras,
    DEVICE_TYPES.EasyParkingBarrerasNFC,
]