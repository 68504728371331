import { genericActions } from "../../_actions/generic.actions";
import { customizationService } from "../../_services/customization.service";
import { Selectors } from "../../_reducers/app.reducer";
import { LocationCustomizationMapper } from "../../_mappers/LocationCustomizationMapper";
import { BaseCustomizationStore } from "./BaseCustomizationStore";

export const CustomizationActions = {
    uploadLogo,
    uploadBackground,
    update,
    getLoginInfo,
    getLocationInfo,

    _setLocationCustomization: BaseCustomizationStore.actionSet,
    _setUploading: BaseCustomizationStore.actionRequest,
};

function uploadLogo(image) {
    return async (dispatch, getState) => {
        const locationUid = Selectors.getSelectedLocationUid(getState());
        dispatch(CustomizationActions._setUploading());
        await dispatch(baseUploadLogo(locationUid, image));
        await dispatch(getLocationInfo(locationUid));
    }
}

function baseUploadLogo(locationUid, image) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        async function asyncAction() {
            await customizationService.uploadLogo(locationUid, image);
        }
    };
}

function uploadBackground(image) {
    return async (dispatch, getState) => {
        const locationUid = Selectors.getSelectedLocationUid(getState());
        dispatch(CustomizationActions._setUploading());
        await dispatch(baseUploadBackground(locationUid, image));
        await dispatch(getLocationInfo(locationUid));
    }
}

function baseUploadBackground(locationUid, image) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        async function asyncAction() {
            await customizationService.uploadBackground(locationUid, image);
        }
    };
}

function update(accentColor, headerColor) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const locationUid = Selectors.getSelectedLocationUid(getState());
            const response = await customizationService.update(
                locationUid,
                accentColor,
                headerColor,
            );
            dispatch(CustomizationActions._setLocationCustomization(
                LocationCustomizationMapper.serverToLocal(response)
            ));
        }
    };
}

function getLoginInfo(subdomain) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            const response = await customizationService.getLoginInfo(subdomain);
            dispatch(CustomizationActions._setLocationCustomization(
                LocationCustomizationMapper.serverToLocal(response)
            ));
        }
    };
}

function getLocationInfo(locationUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            const response = await customizationService.getLocationInfo(locationUid);
            dispatch(CustomizationActions._setLocationCustomization(
                LocationCustomizationMapper.serverToLocal(response)
            ));
        }
    };
}