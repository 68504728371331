import { useEffect } from "react";
import { OrdersActions } from "../_actions/OrdersActions";
import { useSelector, useDispatch } from "react-redux";
import { HistoricOrder } from "../_modelsTS/Order";
import { Selectors } from "../_reducers/app.reducer";
import { SingleOrderView } from "./SingleOrderView";

type HistoricOrdersListViewProps = {
    historicOrders: HistoricOrder[]
}

export function HistoticOrdersListView({
    historicOrders,
}: HistoricOrdersListViewProps) {
    
    const dispatch = useDispatch();
    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    //INITIALIZATION
    useEffect(() => {
        dispatch(OrdersActions.listOrders(locationUid));
    }, [dispatch, locationUid]);

    if(!historicOrders.length) return null;

    return (
        <div className="c-grid c-grid--small">
            {historicOrders?.map((order, i) => (
                <SingleOrderView order={order} key={i} />
            ))}
        </div>
    ); 
}
