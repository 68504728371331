import { genericActions } from "./generic.actions";
import { userLogsService } from "../_services/userLogs.service";
import { FriendMapper } from "../_mappers/FriendMapper";
import { USER_LOGS_ACTION_TYPES } from "../_constants/userLogs.constants";
import { UserLogMapper } from "../_mappers/UserLogMapper";
import { UserLog } from "../_models/UserLog";
import { geocodingService } from "../_services/geocoding.service";
import { Selectors } from "../_reducers/app.reducer";
import { UserLogsCollection } from "../_stores/UserLogsCollection";
import { UserLogsDateFilterStore } from "../_stores/UserLogsDateFilterStore";
import { logsActions } from "./logs.actions";
import { APP_LOG_TYPES } from "../_constants";

export const userLogsActions = {
    getFriends,
    changeSelectedUserUid,

    getUserLogsFirstPage: () => logsActions.getFirstPage(APP_LOG_TYPES.USER),
    getUserLogsNextPage: (nextPage, isRequesting) => logsActions.getNextPage(nextPage, isRequesting, APP_LOG_TYPES.USER),
    getUserLogsPage,
    
    selectUserLog,
    resetSelectedUserLog,
    
    getUserLogAddress,

    setDateFilter: UserLogsDateFilterStore.actionSetBeginEnd,

    //LOCAL
    listLogsRequest: UserLogsCollection.actionListItemsRequest,
    listLogsSuccess: UserLogsCollection.actionListItemsSuccess,
};

function selectUserLog(uid, prevSelectedUserLog, logs = []) {
    return dispatch => {
        if (!logs.length || isAlreadySelected())
            return;

        const selectedUserLog = logs.find(log => log.uid === uid);
        dispatch(updateStoredUserLog(selectedUserLog));

        //
        function isAlreadySelected() {
            const prevSelectedUserLogUid = prevSelectedUserLog && prevSelectedUserLog.uid;
            return uid === prevSelectedUserLogUid;
        }
    }
}

function resetSelectedUserLog() {
    return dispatch => {
        dispatch(updateStoredUserLog(null));
    };
}

function updateStoredUserLog(selectedUserLog) {
    return {
        type: USER_LOGS_ACTION_TYPES.SELECT_USER_LOG,
        selectedUserLog,
    };
}

function getUserLogAddress(userLog = UserLog()) {
    return async dispatch => {

        await dispatch(genericActions.genericAsyncAction(
            asyncAction,
        ));

        async function asyncAction() {
            if (!userLog.latitude || !userLog.longitude)
                return;

            const serverAddress = await geocodingService.reverseGeocoding(
                userLog.latitude,
                userLog.longitude,
            );

            const adressString = serverAddress.road + ", " + (serverAddress.village || serverAddress.town);

            dispatch(updateStoredUserLog(
                { ...userLog,
                    local_address: adressString,
                },
            ));
        }
    };
}

function changeSelectedUserUid(selectedUserUid) {
    return dispatch => {
        dispatch(userLogsActions.resetSelectedUserLog());
        dispatch (
            {
                type: USER_LOGS_ACTION_TYPES.CHANGE_SELECTED_USER_UID,
                selectedUserUid,
            }
        );
    }
}

function getFriends() {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverFriends = await userLogsService.getFriends();
            const localFriends = FriendMapper.allServerToLocal(serverFriends);
            dispatch(success(localFriends));
        }
    };

    function success(friends) {
        return {
            type: USER_LOGS_ACTION_TYPES.GET_FRIENDS,
            friends,
        };
    }
}

function getUserLogsPage(page) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(userLogsActions.listLogsRequest(page));
            const { begin, end } = Selectors.getUserLogsDateFilter(getState());
            const { logs, next } = await userLogsService.getUserLogs({
                uid: uidLocalToServer(),
                begin,
                end,
                offset: page,
                pageSize: 25,
            });

            function uidLocalToServer() {
                const currentUserUid = Selectors.getUserUid(getState());
                const selectedUserUid = Selectors.getUserLogsSelectedUid(getState());

                return currentUserUid === selectedUserUid
                    ? undefined
                    : selectedUserUid
                ;
            }

            const localLogs = UserLogMapper.allServerToLocal(logs);
            
            dispatch(userLogsActions.listLogsSuccess(localLogs, next));

            return {
                localLogs,
                next,
            };
        }
    };
}