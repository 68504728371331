import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      version="1.1"
      viewBox="0 0 24 24"
    >
      <path d="M19 13H5v-2h14z"></path>
      <path fill="none" d="M0 0h24v24H0z"></path>
    </svg>
  );
}

export default Icon;