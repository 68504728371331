import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { ContentSpace, MainPageWithButtons } from "../_components";
import { documents } from "./documents";
import { DownloadHelper } from "../_helpers";

export function DocumentationModule() {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("mod_budgets_docs_title")}
                    content={content()}
                    size={'SMALL'}
                />
            }
        />
    );

    function content() {
        return (
            <div className="
                d-flex
                no-gutters

                flex-wrap
                justify-content-center

                ml-2 ml-sm-4
                mt-2 mt-sm-4
            ">
                {documents.map((document, index) =>
                    <SingleDocument
                        key={index}
                        {...{
                            fileUrl: document.fileUrl,
                            icon: document.icon,
                            fileName: document.name,
                        }}
                    />
                )}
            </div>
        );
    }
}

function SingleDocument({ icon, fileUrl, fileName } = {}) {

    //ACTION HANDLERS
    function handleDownloadDoc(fileUrl, fileName) {
        DownloadHelper.openLinkInNewWindow(fileUrl);
    }

    //LOCAL STATE
    const [isHover, setIsHover] = useState(false);
    
    //VIEW
    return (
        <div
            className="col-12"
        >
            <div
                onClick={() => handleDownloadDoc(fileUrl, fileName)}
                onMouseOver={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className="
                    mr-2 mr-sm-4
                    mb-2 mb-sm-4

                    position-relative
                "
            >
                <img
                    className="mw-100 rounded"
                    src={icon}
                    alt="doc_img"
                />

                {isHover &&
                    <div className="
                        position-absolute
                        c-bg-primary
                        h-100
                        w-100
                        rounded
                        c-position-overlay

                        c-opacity-25
                    " />
                }
            </div>
        </div>
    );
}