import React from 'react';

import { CapsuleButton } from '.';
import { Icons } from '../_assets';

export function InlineEditButtons({
    acceptButtonTestID,
    cancelButtonTestID,
    
    handleConfirmChanges = () => {},
    handleDiscardChanges = () => {},
    isFlipped,
    isEnabled = true,
} = {}) {

    return (
        <>
        {isFlipped ? cancelButton() : confirmButton()}
        <div className="ml-2"/>
        {isFlipped ? confirmButton() : cancelButton()}
        </>
    );

    function confirmButton() {
        return <CapsuleButton
            testId={acceptButtonTestID}
            isEnabled={isEnabled}
            icon={Icons.check}
            size='SMALL'
            onClick={handleConfirmChanges}
        />;
    }

    function cancelButton() {
        return <CapsuleButton
            testId={cancelButtonTestID}
            icon={Icons.cross}
            size={'SMALL'}
            style={`DANGER`}
            onClick={handleDiscardChanges}
        />;
    }
}