import { combineReducers } from 'redux';
import { CommonStore } from '../Everou/_reducers/common.reducer';
import { DevOauthStore } from './_stores/DevOauthStore';
import { DevRoomsStore } from "./_stores/DevRoomsStore";
import { DevTokensStore } from "./_stores/DevTokensStore";
import { DevUserStore } from './_stores/DevUserStore';

//ROOT STATE
let getRootState = state => state;

function setRootStateFn(getRootStateFn = state => state) {
    getRootState = getRootStateFn;
}

//REDUCER
const reducer = combineReducers({
    rooms: DevRoomsStore.reducerCollection,
    tokens: DevTokensStore.reducerCollection,
    user: DevUserStore.reducer,
    oauth: DevOauthStore.reducer,
});

//SELECTORS
DevRoomsStore.setRootStateFn(state  =>  getRootState(state).rooms);
DevTokensStore.setRootStateFn(state =>  getRootState(state).tokens);
DevUserStore.setRootStateFn(state   =>  getRootState(state).user);
DevOauthStore.setRootStateFn(state  =>  getRootState(state).oauth);

const selectors = {
    getRooms: DevRoomsStore.selectorGetItems,
    getTokens: DevTokensStore.selectorGetItems,
    getUser: DevUserStore.selectorGet,
    getOauth: DevOauthStore.selectorGet,
    ...CommonStore.selectors,
};

//EXPORT
export const DevelopersStore = {
    setRootStateFn,
    reducer,
    selectors,
};