import { TRIGGERS_ACTION_TYPES } from "../_constants/triggers.constants";

const initialState = {
    items: [],
};

export class TriggersSelectors {
    static getTriggers = state => state.items;
}

export function triggers(state = initialState, action) {
    switch (action.type) {

        case TRIGGERS_ACTION_TYPES.GET_TRIGGERS_SUCCESS:
            return { ...state,
                items: action.triggers,
            };

        default:
            return state;
    }
}