let rootState = state => state;

export class AuthenticationSelectors {
    static setRootState = fn => { rootState = fn };

    static getMachineUid        = state => rootState(state).machineUid;
    static getLoginUrlData      = state => rootState(state).url;
    static getCodeSent          = state => rootState(state).codeSent;
    static getIsNewUser         = state => rootState(state).isNewUser;
    static getVersion           = state => rootState(state).version;
    static getLaskApikeyUpdate  = state => rootState(state).lastApikeyUpdate;
    static getIsAuthCodeSent    = state => rootState(state).codeSent;
     
    static getEverouUser        = state => rootState(state).user;
    static getApikey            = state => rootState(state).user && rootState(state).user.apikey;
    static getUserUid           = state => rootState(state).user && rootState(state).user.uid;
    static getUserEmail         = state => rootState(state).user && rootState(state).user.email;

    static getLoginCode         = state => rootState(state).login && rootState(state).login.code;
    static getLoginCodeType     = state => rootState(state).login && rootState(state).login.codeType;
    static getLoginEmail        = state => rootState(state).login && rootState(state).login.email;
}