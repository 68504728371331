import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import accountIcon from '../_assets/appIcons/account_circle-grey-24px.svg';
import { MainPageWithButtons } from "../_components";
import { locationActions } from "../_actions";
import { LocationInfo } from "../_models/LocationInfo";
import { OtherUser } from "../_models/OtherUser";
import { Selectors } from "../_reducers/app.reducer";

export function ProtectUsers() {

    //LIBRARIES
    const { t } = useTranslation();

    //GLOBAL STATE
    const usersInside   = useSelector(Selectors.getUsersInside);
    let locationInfo    = LocationInfo();
    locationInfo        = useSelector(Selectors.getLocationInfo);

    const dispatch = useDispatch();

    //INITIALIZATION
    useEffect( () => {
        (
            async () => {
                await dispatch(locationActions.getUsersInLocation());
            }
        )();
    }, [dispatch]);

    //VIEW
    return (
        <MainPageWithButtons
            size={'SMALL'}
            pageTitle={t("common.usersInLocation")}
            content={buildUsersInside()}
        />
    );

    function buildUsersInside() {
        return (
            <div className="
                h-100
                d-flex
                no-gutters
                flex-column
            ">
                {contentLogic()}
            </div>
        );
    }

    function contentLogic() {
        if (!locationInfo.protectOnEnter && !locationInfo.protectOnExit)
            return enableOptionsMessage();
            
        return usersInsideList();
    }

    function enableOptionsMessage() {
        return (
            <div className="
                mt-2
                p-1

                text-center
                border
                rounded

                font-weight-bold
            ">
                {t("protect.enableArriveLeaveOption")}
            </div>
        );
    }

    function usersInsideList() {
        return(
            <div className="overflow-auto">
                {usersInside.map(createUser)}
            </div>
        );
    }
    
    function createUser(user = OtherUser()) {
        const avatar = user.avatar || accountIcon;

        return (
            <div
                key={user.uid}
                className="
                    d-flex
                    align-items-center
                    
                    mt-1
                    p-1
                    
                    border
                    rounded
                "
            >
                <img
                    className="
                        border
                        c-h-2rem
                        c-w-2rem
                        rounded-circle
                    "
                    src={avatar}
                    alt={"user avatar"}
                />
                <div className="ml-2">
                    { user.name }
                </div>
            </div>
        );
    }
} 