import { ModuleModel } from "../Everou/_models";

export const AppModulesFactory = {
    makeModules,
};

function makeModules({
    DEFAULT_MODULE,
    ALL_MODULES = [] || [ModuleModel()],
    componentForModuleDictionary = {},
    getEnabledModuleIds = () => {},
}) {
    return {
        getUrlForModule,
        getModulesForIds,
        getAllModules,
        getModuleIdForUrl,
        getComponentForModuleId,
        getModuleById,

        getEnabledModuleIds,

        DEFAULT_MODULE,
    };

    function getAllModules() {
        return ALL_MODULES;
    }

    function getUrlForModule(moduleId) {
        const module = ALL_MODULES.find(module => module.moduleId === moduleId);
        return module && module.url;
    }

    function getModuleIdForUrl(url) {
        const module = ALL_MODULES.find(module => module.url === url);
        return module?.moduleId;
    }

    function getModulesForIds(moduleIds = []) {
        return moduleIds.reduce((modules, id) => {
            const module = ALL_MODULES.find(module => module.moduleId === id);
            return module
                ? [...modules, module]
                : modules
            ;
        }, []);
    }

    function getComponentForModuleId(moduleId) {
        return componentForModuleDictionary[moduleId] || "div";
    }

    function getModuleById(moduleId) {
        return ALL_MODULES.find(module => module.moduleId === moduleId);
    }
}