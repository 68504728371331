import { TRIGGERS_ACTION_TYPES } from "../_constants/triggers.constants";
import { genericActions } from "./generic.actions";
import { TriggerMapper } from "../_mappers/TriggerMapper";
import { triggersService } from "../_services/triggers.service";
import { Selectors } from "../_reducers/app.reducer";

export const triggersActions = {
    getTriggers,
};

function getTriggers() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverTriggers = await triggersService.getTriggers(
                Selectors.getSelectedLocationUid(getState()),
            );
            const localTriggers = TriggerMapper.allServerToLocal(serverTriggers);
            dispatch(success(localTriggers));
        }
    };

    function success(triggers){
        return {
            type: TRIGGERS_ACTION_TYPES.GET_TRIGGERS_SUCCESS,
            triggers,
        };
    };
}