import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { invitationGroupActions } from "../_actions/invitationGroup.actions";

const TEST_IDS = {
    acceptButton: "add-pack-confirmation-button",
};

AddPackConfirmationGlobalPopUp.TEST_IDS = TEST_IDS;

export function AddPackConfirmationGlobalPopUp({
    cardCode,
    invitationGroupUid,
    invitationGroupName,
    locationUid,
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    return (
        <BaseAppGlobalPopUp
            acceptButtonTestID={TEST_IDS.acceptButton}

            title={""}
            body={body()}
            acceptButtonName={acceptButtonName()}
            cancelButtonName={t("common.dismiss")}
            onAccept={handleAssignPackToGroup}
        />
    );

    function body() {
        return t(
            "invitation_group_add_pack_confirmation_question_pw",
            { name: invitationGroupName },
        );
    }

    function acceptButtonName() {
        return t("global_accept");
    }

    //ACTION HANDLERS
    function handleAssignPackToGroup() {
        invitationGroupUid
            ? assignToExistingGroup()
            : assignToNewGroup()
        ;
    }

    function assignToExistingGroup() {
        dispatch(invitationGroupActions.associatePackToInvitationGroup(
            cardCode,
            invitationGroupUid,
        ));
    }

    function assignToNewGroup() {
        dispatch(invitationGroupActions.saveNewSelectedToServer(
            cardCode,
            locationUid,
        ));
    }
}