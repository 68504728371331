import React from "react";
import { useTranslation } from "react-i18next";

import { CapsuleButton } from ".";

export function FullHeightSettings({
    handleSaveSettings,
    edited,
    title,
    handleClose,
    content,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div className="
            col-12
            col-sm-11
            col-lg-6
            col-xl-5

            d-flex
            flex-column

            my-4

            p-3
            bg-white
            rounded border
        ">
            <div className="row align-items-center">
                <div className="col">
                    {saveButton()}
                </div>

                <div className="col-6 text-center c-ft-l">
                    {title}
                </div>
                
                <div className="col">
                    <div className="close btn"
                        onClick={handleClose}
                    >
                        <span>&times;</span>
                    </div>
                </div>
            </div>

            <hr className="p-0 m-0 my-1" />

            <div className="h-100 d-flex flex-column">
                {content}
            </div>
        </div>
    );

    function saveButton() {
        if (!edited)
            return;

        return (
            <CapsuleButton
                text={t("global_save")}
                onClick={handleSaveSettings}
                fillStyle={'FILLED'}
            />
        );
    }
}