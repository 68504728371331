import { barriersActions } from "../_stores/BarriersStore/BarriersActions";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CapsuleButton, CustomCard, MainPageWithButtons } from "../_components";
import { Barrier, BarrierUser } from "../_modelsTS/Barrier";
import { globalActions } from "../_actions";
import { GLOBAL_POPUPS } from "../_constants";

const BarriersUserCard = ({ user }: { user: BarrierUser }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const extractUser = useCallback(() => {
        dispatch(
            globalActions.showPopUp(GLOBAL_POPUPS.BASE_APP_GLOBAL, {
                title: t("mod_barriers_extract_user_title"),
                body: t("mod_barriers_extract_user_body", {
                    username: user.name,
                }),
                onAccept: () =>
                    dispatch(
                        barriersActions.updateInfo({ extractUser: user.uid }),
                    ),
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showExtractButton, setShowExtractButton] = useState(false);

    return (
        <CustomCard
            onMouseEnter={() => setShowExtractButton(true)}
            onMouseLeave={() => setShowExtractButton(false)}
            key={user.uid}
        >
            <div>
                <div className="d-flex justify-content-between">
                    <h3>{user.name}</h3>
                    {showExtractButton && (
                        <div onClick={extractUser} className="text-danger cp">
                            {t("global_remove")}
                        </div>
                    )}
                </div>
                <div>{user.email}</div>
            </div>
        </CustomCard>
    );
};

export function BarriersUsers({ barrier }: { barrier: Barrier }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const emptyBarrier = useCallback(() => {
        dispatch(
            globalActions.showPopUp(GLOBAL_POPUPS.BASE_APP_GLOBAL, {
                title: t("mod_barriers_set_empty"),
                body: t("mod_barriers_set_empty_confirm_body"),
                onAccept: () =>
                    dispatch(barriersActions.updateInfo({ reset: true })),
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = (
        <div className="c-default-grid">
            {barrier.usersInsideBarriers.map((user) => (
                <BarriersUserCard key={user.uid} user={user} />
            ))}
        </div>
    );

    return (
        <MainPageWithButtons
            buttons={barrier?.numUsersInsideBarriers > 0 ? [
                <CapsuleButton
                    style="DANGER"
                    size="SMALL"
                    onClick={emptyBarrier}
                    text={t("global_empty")}
                />,
            ] : []}
            pageTitle={
                barrier.numUsersInsideBarriers +
                " " +
                t("mod_barriers_user_inside")
            }
            size="MEDIUM"
            content={content}
        />
    );
}
