import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseAppGlobalPopUp } from './BaseAppGlobalPopUp';
import { protectActions } from "../_actions/protect.actions";

export function EnableProtectGlobalPopUp() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (
        <BaseAppGlobalPopUp
            title={t("protect.enableProtectQuestion")}
            body={t("protect.devicesIsIncorrectState")}
            onAccept={() => dispatch(protectActions.toggleProtectStatus())}
            acceptButtonName={t("common.enable")}
        />
    );
}