import momentWithLocales from 'moment-with-locales-es6';
import moment from 'moment-timezone';

import { i18n, LocalizationManager } from '../_translations/i18n';
import { WeekDaysEnum } from '../_models/Invitation';


setMomentLocale(LocalizationManager.getLocaleString());

function setMomentLocale(localeString) {
    momentWithLocales.locale(localeString);
    defineTzLocale(localeString);
    moment.locale(localeString);

    function defineTzLocale(locale) {
        doesLocaleExist(locale)
            ? moment.updateLocale(locale, momentWithLocales.localeData()._config)
            : moment.defineLocale(locale, momentWithLocales.localeData()._config)
        ;

        function doesLocaleExist(locale) {
            return moment.locales().some(existingLocale => existingLocale === locale);
        }
    }
}

export class TimeHelper {

    //JS DATE
    static isoDateToLocalJsDate(isoDate, timezone) {
        if (!isoDate)
            return null;
            
        const dateMoment = moment.utc(isoDate);
        timezone && dateMoment.tz(timezone);
        compensateBrowserLocalOffset(dateMoment);
        return new Date(dateMoment.toISOString());
    
        function compensateBrowserLocalOffset(inputMoment) {
            const browserOffset = new Date(inputMoment.toISOString()).getTimezoneOffset();
            inputMoment.utcOffset(-browserOffset, true);
        }
    }



    static TIME_FORMATS = {
        TIME: "HH:mm:ss",
        TIME_NO_SECONDS: "HH:mm",
    
        DATE_AND_WEEKDAY: "dddd DD/MM/YYYY",
        DATE_ONLY_DAY: "dddd",
        DATE: "DD/MM/YYYY HH:mm:ss",
        DATE_NO_TIME: "DD/MM/YYYY",
        DATE_NO_SECONDS: "DD/MM/YYYY HH:mm",
        YEAR: "YYYY",
        MONTH_NAME_DAY: "DD MMM",
    };
    //CLASS CONSTANTS
    static getTimeFormats() {
        return this.TIME_FORMATS;
    }



    static TIME_MEASURES = {
        WEEK: "week",
    };

    //TEST HELPERS
    static substractMinutesFromIsoDate(isoDate, minutes) {
        return (
            moment.utc(isoDate)
            .subtract(minutes, "m")
            .toISOString()
        );
    }

    static createNowMinusMinutesIsoDate(minutes) {
        return (
            moment.utc()
            .subtract(minutes, "m")
            .toISOString()
        );
    }

    //converters
    static parseDatePickerStringToIso(string) {
        return moment.utc(string, "DD/MM/yyyy HH:mm").toISOString();
    }

    static serverStringToIsoString(serverUtcString) {
        if (!serverUtcString)
            return;
        return moment.utc(serverUtcString).toISOString();
    }

    static localizeIsoDateToHtmlInput(isoDate, timezone) {
        return moment.tz(isoDate, timezone).format("HH:mm");
    }

    static localDayAndTimeToIso(localDay, hours, minutes, timezone) {
        return moment.tz(localDay, timezone)
            .startOf("day")
            .hours(hours)
            .minutes(minutes)
            .toISOString()
        ;
    }

    //duration fns
    static durationDaysToIso(days) {
        return moment.duration(days, "days").toISOString();
    }

    static durationMonthsToIso(months) {
        return moment.duration(months * 30, "days").toISOString();
    }

    static durationServerToUi(serverDuration) {
        const duration = moment.duration(serverDuration);
        const days = duration.asDays();

        if (days > 30) {
            const months = Math.round(duration.asMonths());
            return months + " " + i18n.t("global_months");
        }

        return days + " " + i18n.t("global_days");
    }

    //renderers
    static isoStringToLocalTimezoneFormat(isoString, timeFormat = this.getTimeFormats().DATE) {
        return (
            moment.utc(isoString)
            .tz(this.getCurrentTimezone())
            .format(timeFormat)
        );
    }

    static localizeIsoStringToFormat(isoString, timezone, timeFormat = this.getTimeFormats().DATE) {
        return (
            moment.utc(isoString)
            .tz(timezone)
            .format(timeFormat)
        );
    }

    static isoStringToUiString(isoString, timeFormat = this.getTimeFormats().DATE) {
        return moment.utc(isoString).format(timeFormat);
    }

    //creators
    static utcStringDayStart() {
        return moment.utc().startOf("day").toISOString();
    }

    static utcStringDayEnd() {
        return moment.utc().endOf("day").toISOString();
    }

    static getTodayIsoString() {
        return moment.utc().toISOString();
    }

    static getOneMonthAgoIsoString() {
        return (
            moment.utc()
            .subtract(1, "month")
            .toISOString()
        );
    }

    static getTomorrowIsoString() {
        return (
            moment.utc()
            .add(1, "day")
            .toISOString()
        );
    }

    static getNextDayIso(isoString) {
        return (
            moment.utc(isoString)
            .add(1, "day")
            .toISOString()
        );
    }

    static getPrevDayIso(isoString) {
        return (
            moment.utc(isoString)
            .subtract(1, "day")
            .toISOString()
        );
    }

    static getDaysAgoIso(days) {
        return (
            moment.utc()
            .subtract(days, "day")
            .toISOString()
        );
    }

    static getWeeksAgoIso(weeks) {
        return (
            moment.utc()
            .subtract(weeks, "week")
            .toISOString()
        );
    }

    static localizedStartOfTodayIsoString(timezone) {
        if (!timezone)
            throw new Error("NO TIMEZONE");

        return (
            moment.utc()
            .tz(timezone)
            .startOf("day")
            .toISOString()
        );
    }

    static localizedEndOfTodayIsoString(timezone) {
        if (!timezone)
            throw new Error("NO TIMEZONE");

        return (
            moment.utc()
            .tz(timezone)
            .endOf("day")
            .toISOString()
        );
    }

    static createNowPlus3DaysIso() {
        return (
            moment.utc()
            .add(3, 'd')
            .toISOString()
        );
    }

    //helpers
    static areSameDay(isoDateA, isoDateB) {
        return moment.utc(isoDateA)
            .isSame(moment.utc(isoDateB), "day")
        ;
    }

    static getCurrentTimezone() {
        return moment.tz.guess();
    }

    static minutesToTimeString(minutes) {
        return this.secondsToTimeString(minutes*60);
    }

    static secondsToTimeString(seconds) {
        if(!seconds) return '0h 0m';
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds - (hours * 3600)) / 60);
        seconds = seconds - (hours * 3600) - (minutes * 60);

        return (
            (hours ? (hours + "h ") : "")
            + (minutes ? (minutes + "m ") : "")
            + (seconds ? seconds + "s" : "")
        );
    }

    static getDayWeekdayInTimezone(isoStringDay, timezone) {
        return dayToLocalDay(getDay());

        ////
        function dayToLocalDay(dayNumber) {
            const dayToLocalDay = {
                1: WeekDaysEnum.MONDAY,
                2: WeekDaysEnum.TUESDAY,
                3: WeekDaysEnum.WEDNESDAY,
                4: WeekDaysEnum.THURSDAY,
                5: WeekDaysEnum.FRIDAY,
                6: WeekDaysEnum.SATURDAY,
                7: WeekDaysEnum.SUNDAY,
            };

            return dayToLocalDay[dayNumber];
        }

        function getDay() {
            return (
                moment.utc(isoStringDay)
                .tz(timezone)
                .isoWeekday()
            );
        }
    }

    //Takes LOCAL input and moves it to timezone keeping EXACT input date
    static getStartOfLocalDayInTimezoneIso(localDayIsoDate, timezone) {
        return (
            moment.utc(localDayIsoDate)
            .startOf("day")
            .tz(timezone, true)
            .toISOString()
        );
    }

    //Takes UTC input and calculates the corresponding local date start in timezone
    static getStartOfDayInTimezoneIso(isoDate, timezone) {
        return (
            moment.utc(isoDate)
            .tz(timezone)
            .startOf("day")
            .toISOString()
        );
    }

    static getEndOfDayInTimezoneIso(isoDate, timezone) {
        return (
            moment.utc(isoDate)
            .tz(timezone)
            .endOf("day")
            .toISOString()
        );
    }

    static getNextDayStartInTimezoneIso(baseDayIsoDate, timezone) {
        return (
            moment.utc(baseDayIsoDate)
            .add(1, "day")
            .startOf("day")
            .tz(timezone, true)
            .toISOString()
        );
    }

    static isBetweenIncludedDates(isoString, startIsoString, endIsoString) {
        return (
            moment.utc(isoString)
            .isBetween(
                startIsoString,
                endIsoString,
                "date",
                '[]',
            )
        );
    }

    static isIsoABeforeB(isoA, isoB) {
        return moment.utc(isoA).isBefore(moment.utc(isoB));
    }

    static isIsoAAfterB(isoA, isoB) {
        return moment.utc(isoA).isAfter(moment.utc(isoB));
    }

    static isBeforeToday(isoString) {
        return (
            moment.utc(isoString)
            .isBefore(
                moment.utc(),
                "day",
            )
        );
    }

    static isBeforeNowUtc(utcIsoString) {
        return (
            moment.utc(utcIsoString)
            .isBefore(
                moment().utc()
            )
        );
    }

    static copyMinutesToDestination(isoMinutes, isoDestination) {
        const minutes = moment.utc(isoMinutes).get("minute");
        const resultMoment = moment.utc(isoDestination).set("minute", minutes);
        return resultMoment.toISOString();
    }

    static removeTimezoneFromIsoString(isoString, timezone) {
        return (
            moment.utc(isoString)
            .tz(timezone, true)
            .toISOString()
        );
    }

    static absDifferenceInMinutes(isoStringA, isoStringB) {
        return (
            Math.abs(
                moment.utc(isoStringA).diff(
                    moment.utc(isoStringB),
                    "minutes",
                )
            )
        );
    }

    static addMinutesToIsoString(isoString, minutes) {
        return (
            moment.utc(isoString)
            .add(minutes, "minutes")
            .toISOString()
        );
    }

    static addTimeMeasureToIsoString(isoString, timeMeasure, amount) {
        return (
            moment.utc(isoString)
            .add(amount, timeMeasure)
            .toISOString()
        );
    }

    static substractTimeMeasureFromIsoString(isoString, timeMeasure, amount) {
        return (
            moment.utc(isoString)
            .subtract(amount, timeMeasure)
            .toISOString()
        );
    }

    static isIsoDateBeforeOneHourAgo(isoDate) {
        const oneHourAgo = moment.utc().subtract(1, "h");
        return moment.utc(isoDate).isBefore(oneHourAgo);
    }

    static havePassedDaysSinceIsoDate(isoDate, days) {
        const dayDifference = moment.utc().diff(moment.utc(isoDate), "days");
        return dayDifference >= days;
    }

    static getFirstDayOfWeekForIso(isoDate) {
        return moment.utc(isoDate)
        .startOf("week")
        .toISOString();
    }

    static getLastDayOfWeekForIso(isoDate) {
        return moment.utc(isoDate)
        .endOf("week")
        .toISOString();
    }

    static applyTimeToDate(timeIso, dateIso) {
        const timeMoment = moment.utc(timeIso);
        return moment.utc(dateIso)
            .millisecond(timeMoment.millisecond())
            .second(timeMoment.second())
            .minute(timeMoment.minute())
            .hour(timeMoment.hour())
            .toISOString()
        ;
    }

    static createIsoStringWithHourMinutes(localDay, hours, minutes) {
        return moment.utc(localDay)
            .startOf("day")
            .hours(hours)
            .minutes(minutes)
            .toISOString()
        ;
    }

    static getMinimumDate(isoDates = []) {
        return moment.min(
            isoDates.map(isoDate => moment.utc(isoDate))
        ).toISOString();
    }
}