import { PMS_INTEGRATIONS_ACTION_TYPES } from "../_actionTypes/PMS_INTEGRATIONS_ACTION_TYPES";

export interface PMSIntegrationsState {
    octorateUser: any;
}

const initialState: PMSIntegrationsState = {
    octorateUser: null,
};

let getRootState = state => state as PMSIntegrationsState;

export class PMSIntegrationsSelector {
    static getPMSOctorateUser = state => getRootState(state).octorateUser;
    
    static setRootStateFn = (rootStateFn = state => state) => getRootState = rootStateFn;
}

export function pmsIntegrations(state=initialState, action): PMSIntegrationsState {
    switch (action.type) {
        case PMS_INTEGRATIONS_ACTION_TYPES.GET_OCTORATE_USER_INFO_SUCCESS:
            return {
                ...state,
                octorateUser: action.userInfo
            };
    }
    return state;
}