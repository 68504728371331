import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const triggersService = {
    getTriggers,
};

function getTriggers(locationUid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.TRIGGER_LIST,
        {
            loc_uid: locationUid,
        },
    );
}