import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from "../_components";
import { ListedGroup } from "../_components/ListedGroup";
import { CircleAvatar } from "../_components/CircleAvatar";
import { USER_STATUS, USERS_STATUS_DATE_FILTER_PERIODS } from "../_constants/usersStatus.constants";
import { UserStatus } from "../_models/UserStatus";

import okStatusIcon from '../_assets/appIcons/icon_check.svg';
import notOkStatusIcon from '../_assets/appIcons/icon_error.svg';
import missingIcon from '../_assets/icons/device-icon-missing.png';
import { CustomDropdown } from "../_components/CustomDropdown";
import { WaitSpinner } from "../_components/WaitSpinner";
import { UsersStatusPieChart } from "./UsersStatusPieChart";
import { IsoStartEndDatePicker } from "../_components/IsoStartEndDatePicker";
import { TimeHelper } from "../_helpers";

export function UsersStatusList({
    handleChangeRangeStart,
    handleChangeRangeEnd,
    timezone,

    canSeeAllData = false,
    beginIso,
    endIso,

    usersStatus = [] || [UserStatus()],
    selectedUserUid,
    dateFilter,
    isRequestingUsersStatus = false,
    
    handleChangeSelectedUser,
    handleChangeDateFilter,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const data = useMemo(() =>
        buildGroupedData(usersStatus, t)
    , [usersStatus, t]);

    const pieData = useMemo(() =>
        data.map(PieDataMapper)
    , [data]);

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("mod_users_status_title")}
            content={content()}
            size={'SMALL'}
        />
    );

    function content() {
        return (
            <div className="h-100 d-flex flex-column">
                <div className="mt-2"/>
                {dateSelector()}
                <div className="mt-2"/>
                {pieChartSection()}
                <div className="mt-2"/>
                <div className="overflow-auto">
                    {isRequestingUsersStatus
                        ? <WaitSpinner />
                        : data.map(genericGroup)
                    }
                </div>
            </div>
        );

        function pieChartSection() {
            if (isRequestingUsersStatus)
                return null;
            
            if (!usersStatus.length) 
                return null;

            return <UsersStatusPieChart dataArray={pieData} />;
        }
    }

    function genericGroup(dataObj = UiUserStatusGroup()) {
        if (!dataObj.users.length)
            return null;

        return (
            <div key={dataObj.status}>
                <ListedGroup
                    headerText={dataObj.baseString}
                    elements={elements()}
                    color={dataObj.color}
                />
                <div className="mt-2"/>
            </div>
        );

        function elements() {
            return dataObj.users
                .sort((a, b) => a.user.email.localeCompare(b.user.email))
                .map(singleUserUi)
            ;
        }
    }

    function singleUserUi(userStatus = UserStatus()) {
        const user = userStatus.user;

        return (
            <div
                key={user.uid}
                onClick={() => handleChangeSelectedUser(user.uid)}
                className={`
                    px-2
                    py-1

                    d-flex
                    
                    justify-content-between
                    align-items-center
                    
                    cp

                    ${selectedUserUid === user.uid
                        ? "c-bg-primary-light-2"
                        : ("c-hover-bg-grey ")
                    }
                `}
            >
                <div className="d-flex align-items-center">
                    <CircleAvatar srcImg={user.avatar} names={[user.name, user.lastName]}/>
                    <span className="pl-2"/>
                    <div>
                        {user.email}
                    </div>
                </div>
                <img
                    alt="status_icon"
                    className="c-img-1-5rem"
                    src={iconForStatus(userStatus.status)}
                />
            </div>
        );
    }

    function dateSelector() {
        return canSeeAllData
            ? startEndDateSelector()
            : dateFilterDropdown()
        ;
    }

    function startEndDateSelector() {
        return (
            <IsoStartEndDatePicker
                endIsoDate={endIso}
                startIsoDate={beginIso}
                handleEndDateChange={handleChangeRangeEnd}
                handleStartDateChange={handleChangeRangeStart}
                timezone={timezone}
                maxDate={TimeHelper.getTodayIsoString()}
            />
        );
    }

    function dateFilterDropdown() {
        return (
            <CustomDropdown
                options={options()}
                value={dateFilter}
                onChange={handleChangeDateFilter}
                isSearchable={false}
            />
        );

        function options() {
            return Object.keys(USERS_STATUS_DATE_FILTER_PERIODS)
                .map(filterDatePeriod => 
                    CustomDropdown.buildOption(
                        filterDatePeriod,
                        uiStringForDatePeriod(filterDatePeriod),
                    )
                )
            ;
        }
    }

    //UI HERLPERS
    function uiStringForDatePeriod(datePeriod) {
        const uiStringForDate = {
            [USERS_STATUS_DATE_FILTER_PERIODS.TODAY]: t("global_today"),
            [USERS_STATUS_DATE_FILTER_PERIODS.LAST_WEEK]: t("global_last_week"),
            [USERS_STATUS_DATE_FILTER_PERIODS.LAST_2_WEEKS]: t("global_last_two_weeks"),
            [USERS_STATUS_DATE_FILTER_PERIODS.LAST_MONTH]: t("global_last_month"),
        };

        return uiStringForDate[datePeriod] || "UNDEFINED DATE PERIOD";
    }
}

function UiUserStatusGroup({
    color,
    status,
    users,
    baseString,
} = {}) {
    return {
        color,
        status,
        users,
        baseString,
    };
}

function PieDataMapper(input = UiUserStatusGroup()) {
    const number = input.users.length;
    return UsersStatusPieChart.DataObj({
        tag: `${input.baseString} - ${number}`,
        color: input.color,
        number,
    });
}

function iconForStatus(status) {
    switch (status) {
        case USER_STATUS.OK:
            return okStatusIcon;

        case USER_STATUS.CANCELLED_BY_USER:
        case USER_STATUS.MOBILE_NOT_OK:
        case USER_STATUS.USAGE_NOT_OK:
        case USER_STATUS.NO_USES:
            return notOkStatusIcon;

        default:
            return missingIcon;
    }
}

function buildGroupedData(usersStatus = [] || [UserStatus()], t) {

    const okUsers = [];
    const noUsesUsers = [];
    const usageNotOkUsers = [];
    const mobileNotOkUsers = [];
    const cancelledByUserUsers = [];

    usersStatus.forEach(userStatus => {
        switch (userStatus.status) {
            case USER_STATUS.OK:
                okUsers.push(userStatus);
                break;

            case USER_STATUS.USAGE_NOT_OK:
                usageNotOkUsers.push(userStatus);
                break;

            case USER_STATUS.NO_USES:
                noUsesUsers.push(userStatus);
                break;

            case USER_STATUS.MOBILE_NOT_OK:
                mobileNotOkUsers.push(userStatus);
                break;

            case USER_STATUS.CANCELLED_BY_USER:
                cancelledByUserUsers.push(userStatus);
                break;

            default:
                break;
        }
    });

    return [
        UiUserStatusGroup({
            status: USER_STATUS.MOBILE_NOT_OK,
            users: mobileNotOkUsers,
            baseString: t("mod_users_status_mobile_not_ok"),
            color: "rgba(255, 99, 132, 0.7)",
        }),
        UiUserStatusGroup({
            status: USER_STATUS.USAGE_NOT_OK,
            users: usageNotOkUsers,
            baseString: t("mod_users_status_usage_not_ok"),
            color: "rgba(255, 99, 132, 0.25)",
        }),
        UiUserStatusGroup({
            status: USER_STATUS.CANCELLED_BY_USER,
            users: cancelledByUserUsers,
            baseString: t("mod_users_status_cancelled_by_user"),
            color: "rgba(57, 62, 70, 0.2)",
        }),
        UiUserStatusGroup({
            status: USER_STATUS.NO_USES,
            users: noUsesUsers,
            baseString: t("mod_users_status_no_uses"),
            color: "rgba(255, 206, 86, 0.5)",
        }),
        UiUserStatusGroup({
            status: USER_STATUS.OK,
            users: okUsers,
            baseString: t("mod_users_status_ok"),
            color: "rgba(75, 192, 192, 0.5)",
        }),
    ];
}