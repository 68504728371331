import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ListedGroup, ContentSpace, MainPageWithButtons } from "../_components";
import { Selectors } from "../_reducers/app.reducer";
import { DeviceFunctionality } from "../_helpers/DeviceFunctionality";
import { SingleBleDevice } from "./SingleBleDevice";

// UNMANAGED BLE ERRORS
// "Connection failed for unknown reason."
// "GATT operation failed for unknown reason."
// "GATT Server is disconnected. Cannot retrieve services. (Re)connect first with `device.gatt.connect"
// Failed to execute 'readValue' on 'BluetoothRemoteGATTCharacteristic': GATT Server is disconnected. Cannot perform GATT operations. (Re)connect first with `device.gatt.connect`.
// DOMException: Failed to execute 'requestDevice' on 'Bluetooth': Must be handling a user gesture to show a permission request.
// DOMException: Bluetooth adapter not available.
// DOMException: GATT operation already in progress.
// Error on autoconnect: DOMException: No Services matching UUID 0000fff0-0000-1000-8000-00805f9b34fb found in Device.
// WRITE ERROR:  DOMException: GATT operation not permitted.

export function BluetoothModule() {

    //LIBRARIES
    const { t } = useTranslation();

    //GLOBAL STATE
    const devices = useSelector(Selectors.getSelectedLocationDevices);

    //LOCAL STATE
    const [isSendingActionToAnyDevice, setIsSendingActionToAnyDevice] = useState(false);

    //ACTION HANDLERS
    function globalSetUpFn() {
        setIsSendingActionToAnyDevice(true);
    }

    function globalCleanUpFn() {
        setIsSendingActionToAnyDevice(false);
    }

    const sortedDevices = useMemo(() => 
        devices
        .sort((a, b) => a.description.localeCompare(b.description))
        .filter(device => DeviceFunctionality.suportsBleActions(device.type))
    , [devices]);

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    size={'SMALL'}
                    pageTitle={t("mod_bluetooth_title")}
                    content={
                        <div>
                            <div className="mt-2"/>
                            <ListedGroup
                                headerText={t("global_devices")}
                                elements={devicesUi()}
                            />
                        </div>
                    }
                />
            }
        />
    );

    function devicesUi() {
        return sortedDevices.map(device =>
            <SingleBleDevice
                {...{
                    isSendingActionToAnyDevice,
                    device,
                    globalCleanUpFn,
                    globalSetUpFn,
                }}
            />
        );
    }
}