import React from 'react';
import { useTranslation } from 'react-i18next';

import { TimeHelper, DeviceHelper } from "../_helpers";
import { logIcon } from '../_assets';
import { PROTECT_UI_LOG_TYPES } from '../_constants';
import { LogModel } from '../_models/LogModel';

LogsTable.LogRowObject = ({
    deviceName,
    time,
    email,
    statusLabel,
    icon,
    logTypeName,
    isSmall,
} = {}) => ({
    deviceName,
    time,
    email,
    statusLabel,
    icon,
    logTypeName,
    isSmall,
});

LogsTable.TheadsRowsObject = ({
    theadTitles,
    logRowObjects = [] || [LogsTable.LogRowObject()],
} = {}) => ({
    theadTitles,
    logRowObjects,
});

export function LogsTable({ devices, logs, isSmall = false, timezone }) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return table(buildTheadRowObjects(), isSmall);

    //PRESENTATION MAPPERS
    function buildTheadRowObjects() {
        return LogsTable.TheadsRowsObject({
            theadTitles: theadTextsFn(),
            logRowObjects: mapLogsToRowObjects(),
        });

        function theadTextsFn() {
            let texts = [];
    
            if (!isSmall) {
                texts.push(
                    t("common.type"),
                    t("common.date"),
                );
            }
    
            texts.push(
                t("common.user"),
                t("common.device"),
                t("common.status"),
            );
    
            return texts;
        }
    
        function mapLogsToRowObjects() {
            return logs.map(mapLogToRowObject);
    
            function mapLogToRowObject(log = LogModel()) {   
                return LogsTable.LogRowObject({
                    deviceName: getLogDeviceName(
                        log.logType,
                        log.deviceUid,
                        devices,
                    ),
                    time: TimeHelper.localizeIsoStringToFormat(
                        log.datetime,
                        timezone,
                    ),
                    email: log.userEmail,
                    statusLabel: log.statusLabel,
                    icon: logIcon(
                        log.status,
                        DeviceHelper.getTypeForUidFromDevices(log.deviceUid, devices),
                    ),
                    logTypeName: log.logTypeName,
                    isSmall,
                });
            }
    
            function getLogDeviceName(logType, deviceUid, devices) {
                if (PROTECT_UI_LOG_TYPES.includes(logType))
                    return "-";
        
                const deviceName = DeviceHelper.getNameForUidFromDevices(deviceUid, devices);
        
                if (deviceName === undefined)
                    return t("common.deletedDevice");
                
                return deviceName;
            }
        }
    }

    //VIEW TABLE
    function table(theadRowObject = LogsTable.TheadsRowsObject(), isSmall = false) {
        return (
            <table className={`
                mb-0
                table
                c-table-fixed
                c-line-height-1rem
                ${isSmall ? "table-sm" : ""}
            `}>
                <thead>
                    <tr>
                        {theadRowObject.theadTitles.map(stickyThead)}
                    </tr>
                </thead>
                <tbody>
                    {theadRowObject.logRowObjects.map(logRowBuilder)}
                </tbody>
            </table>
        );
    }

    function stickyThead(text, index) {
        return (
            <th className="c-sticky-top c-bg-grey-230"
                scope="col"
                key={index}
            >
                {text}
            </th>
        );
    }
    
    function logRowBuilder(rowObject = LogsTable.LogRowObject(), index) {
        return (
            <tr
                data-testid={"log-row"}
                key={index}
            >

                {!rowObject.isSmall &&
                    <td>{rowObject.logTypeName}</td>
                }

                {!rowObject.isSmall &&
                    <td>
                        {rowObject.time}
                    </td>
                }

                <td className="text-truncate"
                    title={rowObject.email}
                >
                    {rowObject.email}
                </td>

                <td className="text-break">
                    {rowObject.deviceName}
                </td>

                <td className="
                    d-flex
                    no-gutters
                    justify-content-end
                    align-items-center

                    text-right
                ">
                    {rowObject.statusLabel}
                    <img
                        className="
                            flex-shrink-0

                            c-img-1-5rem
                            ml-1
                        "
                        src={rowObject.icon}
                        alt="log icon"
                    />
                </td>
            </tr>
        );
    }
}