import { DEVICE_TYPES } from "../_constants";

export const DeviceFunctionality = {
    hasAdvancedSettings,
    supportsNFC, 
    supportsTimer,
    supportsProximity,
    supportsArrivalExitOptions,
    supportsAccessLevels,
    supportsRemoteAccess,
    supportsAccessType,
    supportsBattery,
    supportsNetwork,
    supportsWlanNetwork,
    supportsEthernetNetwork,
    supportsRemainingUses,
    supportsProtect,
    supportsUnlimitedUsesSubscription,
    supportsActivity,
    supportsLostSignalAlert,
    supportsNFCListUpdate,
    supportsOTAUpdates,
    supportsCalibrationAlert,
    supportsRemoteOTA,
    suportsBleActions,
    supportsStreamMinutesRemaining
};

function supportsStreamMinutesRemaining({ type }) {
    return type === DEVICE_TYPES.Brain
}

function suportsBleActions(deviceType) {

    const DEVICES_WITH_BLE_ACTIONS = [
        DEVICE_TYPES.Switcher,
        DEVICE_TYPES.DoubleSwitcher,
        DEVICE_TYPES.Lighting,
    
        DEVICE_TYPES.Thermostat,
        DEVICE_TYPES.Plug,
        DEVICE_TYPES.Roller,
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.Brain,
        DEVICE_TYPES.DoublePlug,
        DEVICE_TYPES.Plug30,
        DEVICE_TYPES.MultiSwitcher,
        DEVICE_TYPES.Plug30Security,
        DEVICE_TYPES.PlugFlood,
        DEVICE_TYPES.GowayLock,
        DEVICE_TYPES.PlugSecurity,
    
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.Elevator,
        DEVICE_TYPES.LIGHT_EFR32,
        DEVICE_TYPES.EasyIntegrations,
    ];

    return DEVICES_WITH_BLE_ACTIONS.includes(deviceType);
}

function hasAdvancedSettings(deviceType) {
    return [
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyBaliza,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.Clockin,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.Elevator,
        DEVICE_TYPES.LIGHT_EFR32,
        DEVICE_TYPES.EasyIntegrations,
        DEVICE_TYPES.Brain,
        DEVICE_TYPES.GowayLock
    ].includes(deviceType);
}

function supportsTimer(deviceType) {
    return [
        //DEVICE_TYPES.GarageDoor,
        //DEVICE_TYPES.EasyParking,
        //DEVICE_TYPES.EasyParkingWifi,
        //DEVICE_TYPES.EasyParkingBarreras,
        //DEVICE_TYPES.EasyParkingBarrerasNFC,
        //DEVICE_TYPES.Elevator,
        DEVICE_TYPES.Lighting,
        DEVICE_TYPES.Switcher,
        DEVICE_TYPES.DoubleSwitcher,
        DEVICE_TYPES.MultiSwitcher,
        DEVICE_TYPES.Plug,
        DEVICE_TYPES.PlugSecurity,
        DEVICE_TYPES.DoublePlug,
        DEVICE_TYPES.Plug30,
        DEVICE_TYPES.Plug30Security,
        DEVICE_TYPES.GowayLock,
        DEVICE_TYPES.Roller,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.LIGHT_EFR32,
        DEVICE_TYPES.EasyIntegrations,
    ].includes(deviceType);
}

function supportsProximity(deviceType) {
    return [
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.MovementSafeAccess,
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyBaliza,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.Clockin,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.Elevator,
        DEVICE_TYPES.LIGHT_EFR32,
        DEVICE_TYPES.EasyIntegrations,
    ].includes(deviceType);
}

function supportsArrivalExitOptions(deviceType) {
    return [
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
    ].includes(deviceType);
}

function supportsAccessLevels(deviceType) {
    return [
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
    ].includes(deviceType);
}

function supportsRemoteAccess(deviceType) {
    return [
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.EasyIntegrations,
    ].includes(deviceType);
}

function supportsAccessType(deviceType) {
    return [
        DEVICE_TYPES.MovementSafeAccess,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyIntegrations
    ].includes(deviceType);
}

function supportsBattery(deviceType) {
    return [
        DEVICE_TYPES.Flood,
        DEVICE_TYPES.Security,
        DEVICE_TYPES.Movement,
        DEVICE_TYPES.GowayLock,
        DEVICE_TYPES.MovementSafeCooking,
        DEVICE_TYPES.MovementSafeAccess,

    ].includes(deviceType);
}


function supportsNetwork(deviceType) {
    return [
        DEVICE_TYPES.Brain,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.EasyIntegrations,
    ].includes(deviceType);
}

function supportsWlanNetwork(deviceType) {
    return [
        DEVICE_TYPES.Brain,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.EasyIntegrations,
    ].includes(deviceType);
}

function supportsEthernetNetwork(deviceType) {
    return [
        DEVICE_TYPES.Brain,
    ].includes(deviceType);
}

function supportsRemainingUses(deviceType) {
    const ALLOWED_DEVICES = [
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
    ];

    return ALLOWED_DEVICES.includes(deviceType);
}

function supportsNFC(deviceType) {
    return [
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.EasyIntegrations,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
    ].includes(deviceType);
}

function supportsProtect(deviceType) {
    const EXCLUDED_DEVICES = [
        DEVICE_TYPES.MovementSafeCooking,
        DEVICE_TYPES.MovementSafeAccess,
        DEVICE_TYPES.Flood,

        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyBaliza,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.Clockin,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.Elevator,
    ];

    return !EXCLUDED_DEVICES.includes(deviceType);
}

function supportsUnlimitedUsesSubscription(deviceType) {
    const SUPPORTED_DEVICES = [
        DEVICE_TYPES.GarageDoor,
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.EasyIntegrations,
    ];

    return SUPPORTED_DEVICES.includes(deviceType);
}

function supportsActivity(deviceType) {
    const EXCLUDED_DEVICES = [
        DEVICE_TYPES.EasyBaliza,
        DEVICE_TYPES.Clockin,
        DEVICE_TYPES.LIGHT_EFR32,
    ];

    return !EXCLUDED_DEVICES.includes(deviceType);
}


function supportsLostSignalAlert(deviceType) {
    const EXCLUDED_DEVICES = [
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyBaliza,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.EasyAccessContactNFC,
        DEVICE_TYPES.Clockin,
        DEVICE_TYPES.Elevator,
        DEVICE_TYPES.LIGHT_EFR32,
    ];

    return !EXCLUDED_DEVICES.includes(deviceType);
}

function supportsNFCListUpdate(deviceType) {
    const SUPPORTED_DEVICES = [
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContactNFC,
    ];

    return SUPPORTED_DEVICES.includes(deviceType); 
}

function supportsRemoteOTA({ type }) {
    return [
        DEVICE_TYPES.EasyParkingWifi,
        DEVICE_TYPES.EasyAccessContactNFCWifi,
        DEVICE_TYPES.ClockinNFCWifi,
        DEVICE_TYPES.EasyIntegrations,
    ].includes(type);
}

function supportsOTAUpdates(deviceType) {
    const SUPPORTED_DEVICES = [
        DEVICE_TYPES.EasyParking,
        DEVICE_TYPES.EasyBaliza,
        DEVICE_TYPES.EasyParkingBarreras,
        DEVICE_TYPES.EasyAccessContact,
        DEVICE_TYPES.Clockin,
        DEVICE_TYPES.Elevator,
        DEVICE_TYPES.LIGHT_EFR32,
        DEVICE_TYPES.EasyParkingBarrerasNFC,
        DEVICE_TYPES.EasyAccessContactNFC,
    ];

    return SUPPORTED_DEVICES.includes(deviceType); 
}

function supportsCalibrationAlert(deviceType) {
    const SUPPORTED_DEVICES = [
        DEVICE_TYPES.GowayLock,
    ];

    return SUPPORTED_DEVICES.includes(deviceType); 
}
