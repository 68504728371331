import { ROOM_TYPES } from '../_constants/room.constants';

import missingIcon from './icons/device-icon-missing.png';

import bedroomIcon from "./roomIcons/room-icon-dormitorio@3x.png";
import kidsBedroomIcon from "./roomIcons/room-icon-ninos@3x.png";
import kitchenIcon from "./roomIcons/room-icon-cocina@3x.png";
import livingRoomIcon from "./roomIcons/room-icon-salon@3x.png";
import bathroomIcon from "./roomIcons/room-icon-aseo@3x.png";
import officeIcon from "./roomIcons/room-icon-oficina@3x.png";
import gardenIcon from "./roomIcons/room-icon-jardin@3x.png";
import babyRoomIcon from "./roomIcons/room-icon-bebe@3x.png";
import terraceIcon from "./roomIcons/room-icon-terraza@3x.png";
import corridorIcon from "./roomIcons/room-icon-pasillo@3x.png";
import garajeIcon from "./roomIcons/room-icon-garaje@3x.png";
import laundryRoomIcon from "./roomIcons/room-icon-lavanderia@3x.png";
import warehouseIcon from "./roomIcons/room-icon-almacen@3x.png";
import diningRoomIcon from "./roomIcons/room-icon-comedor@3x.png";
import washroomIcon from "./roomIcons/room-icon-bano@3x.png";
import hallIcon from "./roomIcons/room-icon-entrada@3x.png";

import tennisIcon from "./roomIcons/icon_room_tennis.svg";
import poolIcon from "./roomIcons/icon_room_pool.svg";
import sportsIcon from "./roomIcons/icon_room_sports.svg";
import gymIcon from "./roomIcons/icon_room_gym.svg";
import commonAreasIcon from "./roomIcons/icon_room_common_areas.svg";

export function getRoomTypeIcon(roomType) {
    switch (roomType) {
        case ROOM_TYPES.BEDROOM:
            return bedroomIcon;

        case ROOM_TYPES.KIDS_BEDROOM:
            return kidsBedroomIcon;

        case ROOM_TYPES.KITCHEN:
            return kitchenIcon;

        case ROOM_TYPES.HALL:
            return hallIcon;

        case ROOM_TYPES.LIVING_ROOM:
            return livingRoomIcon;

        case ROOM_TYPES.TERRACE:
            return terraceIcon;

        case ROOM_TYPES.BATHROOM:
            return bathroomIcon;

        case ROOM_TYPES.CORRIDOR:
            return corridorIcon;

        case ROOM_TYPES.BABY_ROOM:
            return babyRoomIcon;

        case ROOM_TYPES.GARAGE:
            return garajeIcon;

        case ROOM_TYPES.OFFICE:
            return officeIcon;

        case ROOM_TYPES.LAUNDRY:
            return laundryRoomIcon;

        case ROOM_TYPES.GARDEN:
            return gardenIcon;

        case ROOM_TYPES.WHAREHOUSE:
            return warehouseIcon;

        case ROOM_TYPES.WASHROOM:
            return washroomIcon;

        case ROOM_TYPES.DINNING_ROOM:
            return diningRoomIcon;

        case ROOM_TYPES.SWIMMING_POOL:
            return poolIcon;

        case ROOM_TYPES.TENNIS_COURT:
            return tennisIcon;

        case ROOM_TYPES.GYM:
            return gymIcon;

        case ROOM_TYPES.SPORTS_AREA:
            return sportsIcon;

        case ROOM_TYPES.COMMON_AREAS:
            return commonAreasIcon;

        case ROOM_TYPES.NO_TYPE:
        default:
            return missingIcon;
    }
}