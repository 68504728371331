import { IsoRangeDatePicker } from '../_components/IsoRangeDatePicker';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Invitation } from '../_models/Invitation';
import { Selectors } from '../_reducers/app.reducer';
import { invitationsActions } from '../_actions';
import { TimeHelper } from '../_helpers/TimeHelper';
import { AccessSelectorComponent } from './_components/AccessSelectorComponent';

export function NewAccessLink({
    timezone,
    rooms,
    showAccessType
}) {
    let invitation = Invitation();
    invitation = useSelector(Selectors.getInvitationNewDraft);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(invitationsActions.changeBeginTime(TimeHelper.getTodayIsoString(), true))
        let random = (Math.random() + 1).toString(36).substring(8);
        let email = "anonymous.user-" + random + Date.now() + "@baintex.com";
        let emails = [email];
        dispatch(invitationsActions.setEmails(emails));
    }, [dispatch]);


    const content = (
        <div
            style={{
                gap: 24,
                gridTemplateColumns: "repeat(2, 400px)",
            }}
            className="m-auto mt-2 w-100 px-2"
        >
            <h3 className="text-left">{t("common.when")}</h3>
            {dateRangeSelector(
                timezone,
                invitation,
            )}
            <br />
            {showAccessType &&
                <AccessSelectorComponent
                    rooms={rooms}
                    invitation={invitation}
                    onChangeInvitationType={onChangeInvitationAccessType}
                    onSelectRoom={onSelectRoom}
                />
            }
                
        </div>
    );

    return content;

    function dateRangeSelector(timezone, invitation = Invitation()) {
        const { begin, end } = invitation;
        return (
            <div className="mt-1">
                <IsoRangeDatePicker
                    onChangeStart={handleStartDateChange}
                    onChangeEnd={handleEndDateChange}
                    timezone={timezone}
                    begin={begin}
                    end={end}
                    minDate={new Date()}
                />
            </div>
        );
    }

    function handleStartDateChange(localDateString) {
        if (localDateString === invitation.begin || localDateString === null)
            return;
        dispatch(
            invitationsActions.changeBeginTime(
                localDateString,
                invitation.newInvitation,
            ),
        );
    }

    function handleEndDateChange(localDateString) {
        if (localDateString === invitation.end)
            return;
        dispatch(
            invitationsActions.changeEndTime(
                localDateString,
                invitation.newInvitation,
            ),
        );
    }

    function onChangeInvitationAccessType(type) {
        dispatch(invitationsActions.changeInvitationType(type, true));
    }

    function onSelectRoom(elementUid) {
        dispatch(invitationsActions.changeObjectUid(elementUid, true));
    }
}