import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { MainPageWithButtons, CapsuleButton } from "../_components";
import { Client } from "../_models/Client";
import { CustomDropdown } from "../../Common/_components/CustomDropdown";
import { BudgetsActions } from "../_actions/BudgetsActions";
import { MinimalClientForm } from "../ModuleClients/MinimalClientForm";

export function NewBudgetView({
    clients = [] || [Client()],
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [clientUid, setClientUid] = useState(null);

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("mod_budgets_add_budget_title")}
            content={content()}
            size={'SMALL'}
        />
    );

    function content() {
        return (
            <div className="mt-2">
                {existingClientSection()}
                <div className="mt-4"/>
                {newCientSection()}
            </div>
        );
    }

    function noClientsMessage() {
        return (
            <div className="mt-2 text-center">
                {t("mod_budgets_add_budget_no_clients_message")}
            </div>
        );
    }

    function existingClientSection() {
        if (!clients.length)
            return noClientsMessage();

        return (
            <div>
                <div className="font-weight-bold text-uppercase">
                    {t("mod_clients_title")}
                </div>
                <hr className="m-0 my-1"/>
                <div className="
                    d-flex
                    justify-content-between
                ">
                    <div>
                        <CustomDropdown
                            options={makeOptions()}
                            title={t("global_client")}
                            onChange={setClientUid}
                            value={clientUid}
                            sortAlphabetically
                        />
                    </div>
                    <CapsuleButton
                        text={t("global_save")}
                        onClick={handleCreateBudget}
                    />
                </div>
            </div>
        );
    }
    
    function makeOptions() {
        return clients.map(client =>
            CustomDropdown.buildOption(
                client.uid,
                client.name,   
            )
        );
    }

    function newCientSection() {
        return (
            <div>
                <div className="font-weight-bold text-uppercase">
                    {t("mod_clients_add_client")}
                </div>
                <hr className="m-0"/>
                <MinimalClientForm
                    onSave={handleCreateClientAndBudget}
                />
            </div>
        );
    }

    //ACTION HANDLERS
    function handleCreateBudget() {
        if (!clientUid)
            return;

        dispatch(BudgetsActions.createAndSelectBudget(clientUid));
    }

    function handleCreateClientAndBudget(clientParams) {
        dispatch(BudgetsActions.createClientAndBudgetAndSelect(clientParams));
    }
}