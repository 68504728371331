import { i18n } from "../_translations";

export const BudgetsErrorMapper = {
    getMessageFromCode,
};

function getMessageFromCode(code) {
    switch (code) {
        case BUDGETS_ERROR_CODES._2000_USER_NOT_EMPLOYEE:
            return i18n.t("error_2000_user_not_employee");

        case BUDGETS_ERROR_CODES._2001_DISTRIBUTOR_NOT_EXIST:
            return i18n.t("error_2001_distributor_not_exist");

        case BUDGETS_ERROR_CODES._2002_CLIENT_NOT_EXIST:
            return i18n.t("error_2002_client_does_not_exist");

        case BUDGETS_ERROR_CODES._2003_BUDGET_NOT_EXIST:
            return i18n.t("error_2003_budget_does_not_exist");

        case BUDGETS_ERROR_CODES._2004_EMPLOYEE_NOT_OF_DISTRIBUTOR:
            return i18n.t("error_2004_employee_not_of_distributor");

        case BUDGETS_ERROR_CODES._2005_EMPLOYEE_ALREADY_HAS_DITRIBUTOR:
            return i18n.t("error_2005_employee_already_has_distributor");

        case BUDGETS_ERROR_CODES._2006_ENTRY_NOT_EXIST:
            return i18n.t("error_2006_entry_does_not_exist");

        case BUDGETS_ERROR_CODES._2007_PRODUCT_NOT_EXIST:
            return i18n.t("error_2007_product_does_not_exist");

        case BUDGETS_ERROR_CODES._2008_PHONE_IN_USE:
            return i18n.t("error_2008_phone_in_use");
            
        case BUDGETS_ERROR_CODES._2009_CLIENT_HAS_BUDGETS:
            return i18n.t("error_2009_client_has_budgets");

        default:
            return undefined;
    }
}

const BUDGETS_ERROR_CODES = {
    _2000_USER_NOT_EMPLOYEE:                2000,
    _2001_DISTRIBUTOR_NOT_EXIST:            2001,
    _2002_CLIENT_NOT_EXIST:                 2002,
    _2003_BUDGET_NOT_EXIST:                 2003,
    _2004_EMPLOYEE_NOT_OF_DISTRIBUTOR:      2004,
    _2005_EMPLOYEE_ALREADY_HAS_DITRIBUTOR:  2005,
    _2006_ENTRY_NOT_EXIST:                  2006,
    _2007_PRODUCT_NOT_EXIST:                2007,
    _2008_PHONE_IN_USE:                     2008,
    _2009_CLIENT_HAS_BUDGETS:               2009,
};