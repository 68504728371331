import { DASHBOARD_ACTION_TYPES, WIDGET_TYPES } from "../_constants/dashboard.constants";
import { dashboardService } from "../_services/dashboard.service";
import { WidgetGroupMapper } from "../_mappers/WidgetGroupMapper";
import { WidgetMapper } from "../_mappers/WidgetMapper";
import { appPermissions } from "../_constants/permissions.constants";
import { Selectors } from "../_reducers/app.reducer";
import { genericActions } from "./generic.actions";

export const dashboardActions = {
    showSettings,
    hideSettings,

    buildDefaultDashboard,
    getWidgetsFromSelectedGroup,
    addWidget,
    saveSelectedWidgetSettings,
    saveWidgetSettings,
    deleteWidget,
    
    saveTemporarySettings,

    removeDeletedDevicesFromWidget,

    getGroups,
    createGroup,
    saveGroupSettings,
    deleteGroup,
    selectGroup,
};

function removeDeletedDevicesFromWidget(widget) {
    return async (dispatch, getState) => {
        const locationPermission = Selectors.getPermissionSelectedLocation(getState());
        const devices = Selectors.getSelectedLocationDevices(getState());

        if (!appPermissions.canUserSeeAllDevices(locationPermission))
            return;

        const widgetDevicesUids = widget.settings.deviceUids;

        if (!widgetDevicesUids)
            return;

        const devicesToKeep = getDevicesToKeep();
        if (devicesToKeep.length === widgetDevicesUids.length)
            return;

        return await dispatch(dashboardActions.saveWidgetSettings(
            widget.widgetId,
            { deviceUids: devicesToKeep },    
        ));

        //
        function getDevicesToKeep() {
            const currentDevicesUids = devices.map(device => device.uid);
            const devicesToKeep = [];
    
            widgetDevicesUids.forEach(widgetDevice => {
                if (currentDevicesUids.includes(widgetDevice))
                    devicesToKeep.push(widgetDevice);
            });

            return devicesToKeep;
        }
    }
}

function buildDefaultDashboard() {
    return async (dispatch, getState) => {
        if (!appPermissions.canUserEditDashboard(Selectors.getPermissionSelectedLocation(getState())))
            return;

        await dispatch(dashboardActions.createGroup());
        const selectedDevices = getNumberOfDevices(
            Selectors.getSelectedLocationDevices(getState()),
            5,
        );
        await defaultWidgets(selectedDevices)
            .forEach(async widget => {
                await dispatch(dashboardActions.addWidget(widget.type, widget.settings))
            });
    };

    function getNumberOfDevices(devices, number) {
        const selectedDevices = [];
    
        for (let i=0; i < number; i++) {
            if (devices.length <= i)
                break;
    
            selectedDevices.push(devices[i].uid);
        }
    
        return selectedDevices;
    }

    function defaultWidgets(favDevicesUids) {
        return [
            {
                type: WIDGET_TYPES.DEVICES,
                settings: {
                    deviceUids: favDevicesUids,
                },
            },
            {
                type: WIDGET_TYPES.ALERTS,
            },
            {
                type: WIDGET_TYPES.LOGS,
            },
            {
                type: WIDGET_TYPES.INVITATIONS,
            },
        ];
    }
}

function getGroups() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const locationUid = Selectors.getSelectedLocationUid(getState());
            const serverGroups = await dashboardService.getGroups(locationUid);
            const localGroups = serverGroups.map(WidgetGroupMapper.serverToLocal);
            dispatch(success(localGroups));

            if (localGroups.length === 0)
                dispatch(dashboardActions.buildDefaultDashboard());
        }

    };

    function success(groups) {
        return {
            type: DASHBOARD_ACTION_TYPES.GET_GROUPS,
            groups,
        };
    }
}

function createGroup() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const { name, order } = getNewGroupNameAndOrder(
                Selectors.getDashboardGroups(getState()).length,
            );
            const locationUid = Selectors.getSelectedLocationUid(getState());
            await dashboardService.createGroup(
                locationUid,
                name,
                order
            );
            await dispatch(dashboardActions.getGroups());
            await dispatch(
                dashboardActions.selectGroup(
                    getLastGroupId(Selectors.getDashboardGroups(getState()))
                )
            );
        }
    };

    function getNewGroupNameAndOrder(numberOfGroups) {
        ++numberOfGroups;
        return {
            name: "Group " + numberOfGroups,
            order: numberOfGroups,
        };
    }

    function getLastGroupId(groups) {
        return groups[groups.length - 1].id;
    }
}

function selectGroup(groupId = null) {
    return {
        type: DASHBOARD_ACTION_TYPES.SELECT_GROUP,
        groupId,
    };
}

function getWidgetsFromSelectedGroup() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            const groupId = Selectors.getDashboardSelectedGroupId(getState());

            if (groupId === null)
                return dispatch(success([]));

            const requestId = Math.floor(Math.random() * 10000);
            getWidgetsFromSelectedGroup.lastRequestId = requestId;

            const serverWidgets = await dashboardService.getWidgetsFromGroup(groupId);

            if (requestId !== getWidgetsFromSelectedGroup.lastRequestId)
                return;

            const localWidgets = serverWidgets.map(WidgetMapper.serverToLocal);
            dispatch(success(localWidgets));
        }
    };

    function success(widgets) {
        return {
            type: DASHBOARD_ACTION_TYPES.GET_WIDGETS_SUCCESS,
            widgets,
        };
    }
}

function showSettings(componentName, props) {
    return {
        type: DASHBOARD_ACTION_TYPES.SHOW_SETTINGS,
        componentName,
        props,
    };
}

function hideSettings() {
    return {
        type: DASHBOARD_ACTION_TYPES.HIDE_SETTINGS,
    };
}

function addWidget(widgetType, settings) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            const groupId = Selectors.getDashboardSelectedGroupId(getState());
            await dashboardService.createWidget(groupId, widgetType, settings);
            await dispatch(dashboardActions.getWidgetsFromSelectedGroup());
        }
    };
}

function saveSelectedWidgetSettings() {
    return async (dispatch, getState) => {
        const { widgetId, settings: newSettings } = Selectors.getDashboardSettings(getState()).props;
        await dispatch(saveWidgetSettings(widgetId, newSettings));
    };
}

function saveWidgetSettings(widgetId, newSettings) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            await dashboardService.updateWidget(widgetId, newSettings);
            dispatch(dashboardActions.hideSettings());
            await dispatch(dashboardActions.getWidgetsFromSelectedGroup());
        }
    };
}

function saveGroupSettings() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            const { id, name } = Selectors.getDashboardSettings(getState()).props;
            await dashboardService.updateGroup(id, name);
            dispatch(dashboardActions.hideSettings());
            await dispatch(dashboardActions.getGroups());
        }
    };
}

function saveTemporarySettings(props) {
    return {
        type: DASHBOARD_ACTION_TYPES.SAVE_TEMPORARY_SETTINGS,
        props,
    };
}

function deleteWidget(widgetId) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            await dashboardService.deleteWidget(widgetId);
            await dispatch(dashboardActions.getWidgetsFromSelectedGroup());
        }
    };
}

function deleteGroup(id) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        
        async function asyncAction() {
            await dashboardService.deleteGroup(id);
            await dispatch(dashboardActions.getGroups());
            dispatch(dashboardActions.selectGroup(null));
            await dispatch(dashboardActions.getWidgetsFromSelectedGroup());
            dispatch(dashboardActions.hideSettings());
        }
    };
}