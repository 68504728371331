import React from 'react';
import { useTranslation } from 'react-i18next';

import { MainPageWithButtons } from '../_components';
import { DeviceLogsColumn } from './DeviceLogsColumn';

export function DeviceLogsPage() {
    
    //LIBRARIES
    const { t } = useTranslation();

    return (
        <MainPageWithButtons
            dataTestId={"devices-logs-view"}
            pageTitle={t("logs.logs")}
            content={<DeviceLogsColumn />}
            size={'UNLIMITED'}
        />
    );
}