import React from "react";

export function EmptyGlobalPopUp({
    content,
    className = ''
}) {
    return (
        <>
        <div className="
            vw-100
            vh-100

            position-absolute
            c-z-1060

            bg-dark
            c-opacity-75
        "/>

        <div className="
            w-100
            h-100

            d-flex
            flex-column

            position-absolute
            align-items-center

            c-z-1060

            p-2
        ">
            <div className="c-h-25"/>
            <div className={`
                ${className}
                mh-100
                px-3
                py-2

                c-min-w-20rem

                bg-light
                rounded
            `}>
                {content}
            </div>
        </div>
        </>
    );
}