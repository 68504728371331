import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseWidgetSettings } from "./BaseWidgetSettings";
import { dashboardActions } from "../_actions/dashboard.actions";
import { Selectors } from "../_reducers/app.reducer";

const BUTTON_TYPES = {
    ADD: "ADD",
    REMOVE: "REMOVE",
};

export function WidgetFavDevicesSettings({ widgetId, settings }) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const devices = useSelector(Selectors.getSelectedLocationDevices);
    
    //LOCAL STATE
    const selectedDevicesUids = settings.deviceUids;
    const selectedDevices = devices.filter(device => selectedDevicesUids.includes(device.uid));
    const unselectedDevices = devices.filter(device => !selectedDevicesUids.includes(device.uid));
    
    //ACTION HANDLERS
    function handleRemoveDevice(deviceToRemoveUid) {
        const settings = {
            deviceUids: selectedDevicesUids.filter(uid => uid !== deviceToRemoveUid),
        };
        dispatch(dashboardActions.saveTemporarySettings({ settings }));
    }

    function handleAddDevice(deviceToAddUid) {
        const settings = {
            deviceUids: [ ...selectedDevicesUids,
                deviceToAddUid,
            ],
        };
        dispatch(dashboardActions.saveTemporarySettings({ settings }));
    }

    //VIEW
    return ( 
        <BaseWidgetSettings
            widgetId={widgetId}
            title="Favorite Devices"
            content={content(selectedDevices, unselectedDevices)}
        />
    );

    function content(selectedDevices, unselectedDevices) {
        return(
            <>
                {selectableDevicesList(BUTTON_TYPES.REMOVE, selectedDevices)}
                <hr className="mt-3 m-0" />
                {selectableDevicesList(BUTTON_TYPES.ADD, unselectedDevices)}
            </>
        );
    }

    function selectableDevicesList(buttonType, devices) {
        const title = buttonType === BUTTON_TYPES.ADD ? "Available" : "Selected";

        return (
            <div className="
                h-100
                
                d-flex
                flex-column
            ">
                <div className="
                    font-weight-bold
                    c-ft-m
                    pl-1
                ">
                    {title}
                </div>
                
                <div className="
                    col

                    d-flex
                    flex-column
                    overflow-auto
                    
                    p-1
                    bg-light rounded border
                ">
                    {devices.map(device =>
                        singleDevice(device.description, device.uid, buttonType)
                    )}
                </div>
            </div>
        );
    }

    function singleDevice(deviceName, deviceUid, buttonType) {

        let uiButtonType = "c-btn-primary";
        let actionButtonName = t("global_add");
        let deviceAction = () => handleAddDevice(deviceUid);

        if (buttonType === BUTTON_TYPES.REMOVE) {
            uiButtonType = "btn-danger";
            actionButtonName = t("webapp_global_remove");
            deviceAction = () => handleRemoveDevice(deviceUid);
        }

        return (
            <div key={deviceUid} className="
                flex-shrink-0

                d-flex
                justify-content-between
                align-items-center

                p-1 m-1
                bg-white rounded border
            ">
                <div className="pl-2">
                    {deviceName}
                </div>

                <div
                    className={`
                        col-3
                        py-1
                        btn
                        ${uiButtonType}`}
                    onClick={deviceAction}
                >
                    {actionButtonName}
                </div>
            </div>
        )
    }
}