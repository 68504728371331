import { Workday } from "../_modelsTS/Workday";
import { ClockinMapper } from "./ClockinMapper";

export class WorkdayMapper {
    static allServerToLocal(serverWorkdays = []) {
        return serverWorkdays.map(this.serverToLocal);
    }
    
    static serverToLocal(serverWorkday): Workday {
        return {
            day: new Date(serverWorkday.day),
            isValid: serverWorkday.is_valid,
            clockins: ClockinMapper.allServerToLocal(serverWorkday.clockins),
            breakMinutes: serverWorkday.break_minutes,
            workMinutes: serverWorkday.work_minutes,
        };
    }
}