export function BridgeUser({
    apikey,
    name,
    email,
    isDistributor = false,
} = {}) {
    return {
        apikey,
        name,
        email,
        isDistributor,
    };
}