import {
    uiLogTypesForDeviceType,
    DEVICE_TYPES,
    UI_LOG_TYPES_NAMES,
    LOCAL_STATUS_FOR_UI_LOG_TYPE,
    UI_LOG_TYPES_WITH_STATUS,
} from "../_constants";

import { DeviceHelper } from ".";
import { DeviceLogsUiHelper } from "./DeviceLogsUiHelper";

export class LogsHelper {

    static hasVisibleDevicesChanged(prevDevices, newDevices) {
        const prevVisibleDevices = prevDevices.filter(device => device.show);
        const newVisibleDevices = newDevices.filter(device => device.show);

        if (prevVisibleDevices.length !== newVisibleDevices.length)
            return true;

        return false;
    }

    static getStatusLabelFromDevice(deviceType, status) {
        if (deviceType === DEVICE_TYPES.GarageDoor)
            return null;

        const serverUiLogTypes = uiLogTypesForDeviceType(deviceType);
        const serverUiLogType = serverUiLogTypes.find(serverLogType => 
            UI_LOG_TYPES_WITH_STATUS.includes(serverLogType)
        );

        if (serverUiLogType === undefined)
            return "Undefined status";
        
        return this.getStatusLabelFromLog(serverUiLogType, status);
    }

    static getStatusLabelFromLog(uiLogType, status) {
        const localLogStatus = this.getLocalStatus(uiLogType, status);
        return DeviceLogsUiHelper.getLocalStatusLabel(localLogStatus);
    }

    static getLocalStatus(uiLogType, serverStatus) {
        let localLogStatus = LOCAL_STATUS_FOR_UI_LOG_TYPE[uiLogType];

        if (localLogStatus === undefined)
            return "Undefined status";

        if (typeof localLogStatus === "string")
            return localLogStatus;

        localLogStatus = LOCAL_STATUS_FOR_UI_LOG_TYPE[uiLogType][serverStatus];
        return localLogStatus;
    }

    static getUiLogTypesFromDeviceTypes(devices) {
        const uniqueDeviceTypes = DeviceHelper.getUniqueDeviceTypesFromDevices(devices);
        const allServerUiLogTypes = uniqueDeviceTypes.reduce((logTypes, deviceType) =>
            [...logTypes, ...uiLogTypesForDeviceType(deviceType)]
        ,[]);
        const uniqueServerUiLogTypes = [...new Set(allServerUiLogTypes)];

        const uniqueServerUiLogTypesFiltered = uniqueServerUiLogTypes.filter(function (el) {
            return el !== null && el !== undefined;
        });

        return uniqueServerUiLogTypesFiltered;
    }

    static getStatusesForUiLogType(uiLogType) {
        return LOCAL_STATUS_FOR_UI_LOG_TYPE[uiLogType];
    }

    static getUiLogTypeName(uiLogType) {
        return UI_LOG_TYPES_NAMES[uiLogType] || "Undefined log type";
    }
}