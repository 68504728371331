import { useTranslation } from 'react-i18next';
import { RebootDeviceButton } from '../_components/wired/RebootDeviceButton';
import { DEVICE_TYPES, PROXIMITY_SIZES, ACCESS_TYPES, isEasyLock } from "../_constants";
import { ReadOnlyDataRow } from "../_components/ReadOnlyDataRow";
import { DeviceHelper, TimeHelper } from "../_helpers";
import { Device } from "../_models/Device";
import { DeviceFunctionality } from "../_helpers/DeviceFunctionality";
import ToolTipWrapper from "../_components/ToolTipWrapper";
import { SimpleToggle } from "../_components/SimpleToggle";
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { devicesActions } from '../_actions';
import { useError } from '../_hooks/useError';

export function DeviceAdvancedConfig({
    devices = [] || [Device()],
    device = Device(),
    canViewAdvancedConfig = false,
    className = ''
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const type = device.type;
    const dispatch = useDispatch();

    //LOCAL STATE
    const hasAdvancedSettings =         DeviceFunctionality.hasAdvancedSettings(type)
    const supportsRemoteAccess =        DeviceFunctionality.supportsRemoteAccess(type);
    const supportsTimer =               DeviceFunctionality.supportsTimer(type);
    const supportsProximity =           DeviceFunctionality.supportsProximity(type);
    const supportsArrivalExitOptions =  DeviceFunctionality.supportsArrivalExitOptions(type);
    const supportsAccessLevels =        DeviceFunctionality.supportsAccessLevels(type);
    const supportsAccessType =          DeviceFunctionality.supportsAccessType(type);
  
    const supportsWlanNetwork =         DeviceFunctionality.supportsWlanNetwork(type);
    const supportsEthernetNetwork =     DeviceFunctionality.supportsEthernetNetwork(type);
    const supportsRemoteOTA =           DeviceFunctionality.supportsRemoteOTA({type});
    const hasNFC =                      DeviceFunctionality.supportsNFC(type);

    const { errorMessage, setError } = useError();

    const [isRemote, setIsRemote] = useState(device?.remote);

    useEffect(() => setIsRemote(device?.remote), [device])    

    const toggleRemoteAccess = useCallback(async () => {
        const remote = isRemote;
        setIsRemote(!remote);
        try {
            await dispatch(devicesActions.updateDeviceRemoteAccess(device.uid, !remote))
        } catch {
            setError(t('mod_devices_unable_to_change_remote_access'));
            setIsRemote(remote);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device.uid, dispatch, isRemote]);

    //VIEW
    if (!canViewAdvancedConfig || !hasAdvancedSettings)
        return null;

    return (
        <div className={`${className} d-flex justify-content-between flex-column flex-grow-1`}>
            <div>
                {title()}
                <hr className="m-0"/>
                {advancedSettings()} 
            </div>
            {supportsRemoteOTA && rebootButtonSection()}
        </div>
    );

    function advancedSettings() {
        return (
            <div className='mt-2'>
                {supportsRemoteAccess && remoteAccess()}
                {supportsTimer && timerInfo()}
                {supportsProximity && proximity()}
                {supportsArrivalExitOptions && garageDoorArrivalExitOptions()}
                {supportsAccessLevels && accessLevel()}
                {supportsAccessType && accessType()}
                {supportsEthernetNetwork && eth_status()}
                {supportsWlanNetwork && wlan_status()}
                {supportsWlanNetwork && wlan_ssid()}
                {supportsWlanNetwork && wifi_rssi()}
                {hasNFC && syncronizedNfcCards()}
                {isEasyLock(device) && easyLockAdvancedConfig()}
                {<div className="text-right mt-1 mr-1 text-danger">{errorMessage}</div>}
            </div>
        );
    }

    function easyLockAdvancedConfig() {
        return (
            <>
                <ReadOnlyDataRow
                    data={<ToolTipWrapper tooltip={t('mod_device_calibration_power_explanation')}><div>{device.lockPower ?? t('common.notConfigured')}</div></ToolTipWrapper>}
                    name={t("mod_device_calibration_power")}
                />
                <ReadOnlyDataRow
                    data={device.isIrEnabled ? t("global_yes") : t("global_no")}
                    name={t("mod_device_ir_sensor")}
                />
                <ReadOnlyDataRow
                    data={device.knockTimeout ? device.knockTimeout + 's' : t('common.notConfigured')}
                    name={t("mod_device_knock_time")}
                />
            </>
        );
    }

    function rebootButtonSection() {
        return (
            <div className="pb-2 d-flex justify-content-end">
                {<RebootDeviceButton name={device.description} uid={device.uid} isEnabled={device.wlan_status === t("status.connected")} requiresConfirmation/>}
            </div>
        );
    }

    function title() {
        return (
            <div className="
                text-uppercase
                font-weight-bold
            ">
                {t("webapp_global_advanced")}
            </div>
        );
    }

    function remoteAccess() {
        if (device.remote === undefined)
            return "MISSING REMOTE DATA";

        return (
            <ReadOnlyDataRow
                className='pr-0'
                hasSeparators={false}
                name={t("webapp_device_remote_access")}
                data={<SimpleToggle handleChange={toggleRemoteAccess} checked={isRemote} size='SMALL' />}
            />
        );
    }

    function garageDoorArrivalExitOptions() {
        return (
            <>
            {arrivalElements()}
            {exitElements()}
            </>
        );

        function arrivalElements() {
            return (
                <>
                {onArrivalVehicle()}
                {onArrivalWalking()}
                </>
            );
        }

        function exitElements() {
            if (DeviceHelper.hasDeviceChildOfType(
                device,
                devices,
                DEVICE_TYPES.MovementSafeAccess,
            )) {
                return (
                    <>
                    {onExitVehicle()}
                    {onExitWalking()}
                    </>
                );
            }

            return null;
        }
    }

    function onArrivalWalking() {
        if (device.accessEntryWalk === undefined)
            return "MISSING WALK DATA";

        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_on_arrival_waking")}
            data={getOnArrivaExitlUiString(device.accessEntryWalk)}
        />;
    }

    function onArrivalVehicle() {
        if (device.accessEntryVehicle === undefined)
            return "MISSING VEHICLE DATA";

        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_on_arrival_vehicle")}
            data={getOnArrivaExitlUiString(device.accessEntryVehicle)}
        />;
    }

    function onExitWalking() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_on_exit_waking")}
            data={getOnArrivaExitlUiString(device.accessExitWalk)}
        />;
    }

    function onExitVehicle() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_on_exit_vehicle")}
            data={getOnArrivaExitlUiString(device.accessExitVehicle)}
        />;
    }

    function proximity() {
        if (device.extender === undefined
            || device.beacon === undefined
        )
            return "MISSING PROXIMITY DATA";

        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_proximity")}
            data={getString()}
        />;

        function getString() {
            if (!device.beacon)
                return t("webapp_global_disabled");

            return getUiStringForProximitySize(
                DeviceHelper.getProximitySize(device)
            )
        }
    }

    function accessLevel() {
        if (device.accessLevel === undefined)
            return "MISSING ACCESS LEVEL DATA";
        
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_access_level")}
            data={device.accessLevel + 1}
        />;
    }

    function accessType() {
        if (device.accessType === undefined)
            return "MISSING ACCESS TYPE DATA";

        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_access_type")}
            data={getUiStringForAccessType(device.accessType)}
        />;
    }

    function timerInfo() {
        if (device.timer === undefined)
            return "MISSING TIMER DATA";

        let text = t("webapp_device_turn_off_automatically");
        switch(device.type) {
            case DEVICE_TYPES.EasyAccess:
            case DEVICE_TYPES.EasyAccessContact:
            case DEVICE_TYPES.EasyAccessContactNFC:
            case DEVICE_TYPES.EasyAccessContactNFCWifi:
            case DEVICE_TYPES.EasyIntegrations:
                text = t("global_device_setting_opening_duration");
                break;
            default:
                break;
        }

        return <ReadOnlyDataRow
            hasSeparators={false}
            name={text}
            data={device.timer ? TimeHelper.secondsToTimeString(device.timer) : t('common.notConfigured')}
        />;
    }

    

    function wlan_ssid() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_wlan_ssid")}
            data={device.wlan_ssid}
        />;
    }

    function wifi_rssi() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_wifi_rssi")}
            data={device.wifi_rssi == undefined ? '--' :
                device.wifi_rssi < -81 ? 
                t('webapp_device_wifi_rssi_status_poor', {rssi:device.wifi_rssi}) : 
                device.wifi_rssi < -60 ? 
                t('webapp_device_wifi_rssi_status_normal', {rssi:device.wifi_rssi}) : 
                t('webapp_device_wifi_rssi_status_good', {rssi:device.wifi_rssi})
            }
        />;
    }

    function wlan_status() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_wlan_status")}
            data={device.wlan_status}
        />;
    }

    function eth_status() {
        return <ReadOnlyDataRow
            hasSeparators={false}
            name={t("webapp_device_eth_status")}
            data={device.eth_status}
        />;
    }

    function syncronizedNfcCards() {
        return (
            <ReadOnlyDataRow
                hasSeparators={false}
                name={t("mod_cards_nfc_cards")}
                data={
                    device?.isNfcCardsSynchronized
                        ? t("mod_cards_nfc_cards_all_synchronized")
                        : t("mod_cards_nfc_cards_to_synchronize")
                }
            />
        );
    }

    //STRING MAPPINGS
    function getOnArrivaExitlUiString(value) {
        const mapping = {
            0: t("webapp_global_nothing"),
            1: t("webapp_global_open"),
            2: t("webapp_global_ask"),
        };

        return mapping[value] || "UNDEFINED VALUE";
    }

    function getUiStringForProximitySize(proximitySize) {
        const mapping = {
            [PROXIMITY_SIZES.SMALL]: t("webapp_global_small"),
            [PROXIMITY_SIZES.MEDIUM]: t("webapp_global_medium"),
            [PROXIMITY_SIZES.LARGE]: t("webapp_global_large"),
            [PROXIMITY_SIZES.CUSTOM]: t("global_custom"),
        };
        
        return mapping[proximitySize] || "UNDEFINED PROXIMITY SIZE";
    }

    function getUiStringForAccessType(accessType) {
        const mapping = {
            [ACCESS_TYPES.GARAGE]: t("webapp_global_garage_door"),
            [ACCESS_TYPES.DOOR]: t("webapp_global_entry_door"),
            [ACCESS_TYPES.CONTACT]: t("webapp_global_contact"),
            [ACCESS_TYPES.ENTRY]: t("webapp_global_entry"),
            [ACCESS_TYPES.EXIT]: t("webapp_global_exit_door"),
        };

        return mapping[accessType] || "UNDEFINED ACCESS TYPE";
    }
}