import React, { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { InvitationsListView } from './InvitationsListView';
import { InvitationConfigView } from './InvitationConfigView';
import { ContentSpace } from '../_components';
import { appPermissions } from '../_constants/permissions.constants';
import { SubinvitationsListView } from './SubinvitationsListView';
import { INVITATIONS_SECONDARY_VIEWS } from '../_constants';
import { LocationInfo } from '../_models/LocationInfo';
import { Selectors } from '../_reducers/app.reducer';
import { InvitationsHelper } from "../_helpers";
import { LocationInvitationConfig } from "../_models/LocationInvitationConfig";
import { invitationsActions } from '../_actions';
import { NewInvitation } from './NewInvitation';
import { NewAccessLink } from './NewAccessLink';

export function InvitationsModule() {
    //LIBRARIES
    const dispatch = useDispatch();
    const store = useStore();

    //GLOBAL STATE
    const locationUid               = useSelector(Selectors.getSelectedLocationUid);
    const secondaryView             = useSelector(Selectors.getInvitationsModuleSecondaryView);
    const selectedInvitation        = useSelector(Selectors.getInvitationSelected);
    let locationInvitationConfig    = LocationInvitationConfig();
    locationInvitationConfig        = useSelector(Selectors.getLocationInvitationConfig);
    let locationInfo                = LocationInfo();
    locationInfo                    = useSelector(Selectors.getLocationInfo);

    //LOCAL STATE
    const locationPermission    = locationInfo.locationPermission;
    const timezone              = locationInfo.timezone;
    const isSuperguest          = locationInfo.isSuperguest;
    const rooms                 = locationInfo.rooms;

    const canCreateSuperguests  = locationInvitationConfig.canCreateSuperguests;
    const invitationsAllowed    = locationInvitationConfig.invitationsAllowed;
    const invitationsLeft       = locationInvitationConfig.invitationsLeft;


    //LOCAL STATE
    // Since it drives module selection, recalculate only on change location
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isSuperguestOnChangeLocation = useMemo(() => isSuperguest, [locationUid]);

    //ACTION HANDLERS
    const resetInvitationFn = useCallback(() => {
        const secondaryView = Selectors.getInvitationsModuleSecondaryView(store.getState());

        if (secondaryView === INVITATIONS_SECONDARY_VIEWS.INVITATION_GROUP_CONFIG)
            return;

      //  dispatch(invitationsActions.selectInvitation(null));
    }, [store]);


    const closeViewFn = useCallback(() => {
        dispatch(invitationsActions.selectInvitation(null))
    }, [dispatch]);


    //CLEAN UP
    useEffect(() => {
        return closeViewFn;
    }, [closeViewFn]);

    //VIEW
    return (
        <ContentSpace
            className='c-bg-white'
            mainContent={mainContent()}
            detailedContent={detailedContent()}
            onGoBack={closeViewFn}
            hasPadding={false}
        />
    );

    function mainContent() {
        return isSuperguestOnChangeLocation
            ? <SubinvitationsListView />
            : <InvitationsListView
                hasUnlimitedInvitations={InvitationsHelper.hasUnlimitedInvitations(locationInfo)}
                totalInvitations={invitationsAllowed}
                invitationsLeft={invitationsLeft}

                canInvite={appPermissions.canUserCreateInvitations(locationPermission)}
                canSeeRemainingInvitations={appPermissions.canUserSeeInvitations(locationPermission)}
                canBuyInvitations={locationInfo.canBuyInvitations && appPermissions.canUserManageInvitationGroups(locationPermission)}
                rooms={rooms}
                canAddEmailUsersToGroups={locationInfo.canAddUsersToGroup &&  appPermissions.canAddPaidInvitationsToGroups(locationPermission)}
            />
        ;
    }

    function detailedContent() {
        switch (secondaryView) {
            case INVITATIONS_SECONDARY_VIEWS.EDIT_INVITATION:
                return <InvitationConfigView
                    locationInfo={locationInfo}
                    timezone={timezone}
                    rooms={rooms}
                    invitation={selectedInvitation}
                    isSuperguest={isSuperguest}
                    cleanUpFn={resetInvitationFn}
                    canCreateSuperguests={canCreateSuperguests}
                />;

            default:
                return null;
        }
    }
}