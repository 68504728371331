import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CapsuleButton } from "./CapsuleButton";

export function InputCodeValidation({
    handleValidateCode,
    text,
    placeholder,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [code, setCode] = useState("");

    return (
        <div className="
            d-flex
            justify-content-between
            align-items-center

            my-2
        ">  
            {title()}
            <div className="
                d-flex
                justify-content-end
                align-items-center
            ">
                {inputField()}
                <div className="pl-2"/>
                {sendButton()}
            </div>
        </div>
    )

    function title() {
        return (
            <div className="text-uppercase">
                {text}
            </div>
        );
    }

    function inputField() {
        return (
            <input
                onChange={(e) => setCode(e.target.value)}
                type="text"
                value={code}
                placeholder={placeholder}
                className="
                    px-2
                    rounded
                    border
                    text-right
                "
            />
        );
    }

    function sendButton() {
        return (
            <CapsuleButton
                text={t("global_validate")}
                onClick={() => handleValidateCode(code)}
            />
        );
    }
}