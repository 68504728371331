import { useState } from "react";

/**
 * @param time in milliseconds
 */
export function useError(time = 4000): { errorMessage: string, setError: (message: string) => void, hasError: boolean, disableError: () => void } {

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [hasError, setHasError] = useState<boolean>(false);

    const setError = (message: string) => {
        setErrorMessage(message);
        setHasError(true);
        if(time) {
            setTimeout(() => setErrorMessage(''), time)
        }
    }

    const disableError = () => { setHasError(false); setErrorMessage(''); }

    return {
        errorMessage,
        setError,
        hasError,
        disableError
    } as const;

}

