import { DeviceTrigger, TriggerAction, LocationTrigger, Trigger, TimeTrigger } from "../_models/Triggers";
import { DeviceHelper, TimeHelper } from "../_helpers";
import { LOCAL_STATUS } from "../_constants";
import { Device } from "../_models/Device";
import { User } from "../_models/User";
import { LocationModel } from "../_models/LocationModel";
import { TimeStringHelper } from "../_helpers/TimeStringHelper";
import { i18n } from "../_translations/i18n";


export const TriggerDescriptionHelper = {
    deviceDescriptionA,
    deviceDescriptionB,
    notificationDescriptionB,

    locationDescriptionA,
    protectDescriptionA,
    protectDescriptionB,
    timeDescriptionA,
};

function timeDescriptionA(trigger = Trigger(), timezone) {

    const TIME_TYPES = {
        RECURRENT: 1,
        TEMPORARY: 2,
    };

    let parameters = TimeTrigger();
    parameters = trigger.parameters;

    switch (parameters.periodType) {
        case TIME_TYPES.TEMPORARY:
            return temporaryString();

        case TIME_TYPES.RECURRENT:
            return recurrentString();

        default:
            return "UNDEFINED TIME TYPE";
    }


    ////
    function temporaryString() {
        return `${i18n.t("common.onDate")} ${date()}`;

        ////
        function date() {
            return TimeHelper.localizeIsoStringToFormat(
                parameters.begin,
                timezone,
                TimeHelper.getTimeFormats().DATE_NO_SECONDS,
            );
        };
    }

    function recurrentString() {
        return `${daysOfWeek()} ${timeString()}`;

        ////
        function daysOfWeek() {
            return TimeStringHelper.daysOfWeekString(
                parameters,
            );
        }

        function timeString() {
            const SCHEDULE_EVENT_TYPES = {
                NONE: 0,
                DAWN: 1,
                DUSK: 2,
            };

            const time = TimeHelper.isoStringToUiString(
                parameters.begin,
                TimeHelper.getTimeFormats().TIME_NO_SECONDS,
            );

            switch (parameters.eventType) {
                case SCHEDULE_EVENT_TYPES.DAWN: {
                    return ` ${i18n.t("common.atDawn")} (${time})`;
                }
                case SCHEDULE_EVENT_TYPES.DUSK: {
                    return `${i18n.t("common.atDusk")} (${time})`;
                }
                default: {
                    return i18n.t("common.atHour") + " " + time;
                }
            }
        }
    }
}

function protectDescriptionA() {
    return i18n.t("triggers.whenProtectAlarmGoesOff");
}

function protectDescriptionB(trigger = Trigger()) {
    return `${action()} ${i18n.t("protect.protect")}`;

    function action() {
        const PROTECT_STATUS = {
            DISABLE:    0,
            ENABLE:     1,
            TOGGLE:     2,
        };

        switch (trigger.action.status) {
            case PROTECT_STATUS.DISABLE:
                return i18n.t("common.disable");
            case PROTECT_STATUS.ENABLE:
                return i18n.t("common.enable");
            case PROTECT_STATUS.TOGGLE:
                return i18n.t("common.invert");
            default:
                return "UNDEFINED PROTECT STATUS";
        }
    }
}

function locationDescriptionA(
    trigger = Trigger(),
    users = [User()],
    locations = [LocationModel()],
    devices = [Device()]
) {
    let locationTrigger = LocationTrigger();
    locationTrigger = trigger.parameters;
    
    const USER_TYPES = {
        ME:         0,
        OTHER:      1,
        SOMEONE:    2,
        NOBODY:     3,
    };

    const EVENT_TYPES = {
        LOCATION:   0,
        ROOM:       1,
        DEVICE:     2,
    };

    const ACTION_TYPES = {
        EXIT:               0,
        ENTER:              1,
        ARRIVE:             2,
        LEAVE:              3,
        ENTER_FIRST_TIME:   4,
        CONTACT:            5,
        DISCOVERY:          6,
    };

    return (
        `${i18n.t("triggers.when")} ${user()} ${action()} "${element()}"`
    );

    function element() {
        switch (locationTrigger.type) {
            case EVENT_TYPES.DEVICE:
                return device();
            case EVENT_TYPES.LOCATION:
                return location();

            default:
                return "undefined element type"
        } 
    }

    function device() {
        return DeviceHelper.getNameForUidFromDevices(locationTrigger.deviceUid, devices);
    }

    function location() {

        const location = locations.find(location =>
            location.uid === trigger.locationUid
        );

        if (!location)
            return;

        return location.name;
    }

    function user() {
        switch (locationTrigger.userType) {
            case USER_TYPES.ME:
                return i18n.t("triggers.you");

            case USER_TYPES.OTHER: {
                const user = users.find(user =>
                    user.uid === locationTrigger.userUid
                );

                return user && `${user.name} ${i18n.t("triggers.arriveTo")}`;
            }
            case USER_TYPES.SOMEONE:
                return i18n.t("triggers.someone");
            
            case USER_TYPES.NOBODY:
                return i18n.t("triggers.theLastUser");

            default:
                return "USER TYPE NOT DEFINED";
        }
    }

    function action() {
        switch (locationTrigger.userType) {
            case USER_TYPES.ME:
                return youAction();

            default:
                return thridPersonAction();
        }        

        function youAction() {
            switch (locationTrigger.actionType) {
                case ACTION_TYPES.ARRIVE:
                    return i18n.t("triggers.arriveTo");

                case ACTION_TYPES.LEAVE:
                    return i18n.t("triggers.leave");

                case ACTION_TYPES.ENTER_FIRST_TIME:
                    return i18n.t("triggers.aproachForTheFirstTime");

                case ACTION_TYPES.CONTACT:
                    return i18n.t("triggers.makeContactWith");

                case ACTION_TYPES.DISCOVERY:
                    return i18n.t("triggers.aproach");

                default:
                    return undefined + " " + locationTrigger.actionType;
            }
        }

        function thridPersonAction() {
            switch (locationTrigger.actionType) {
                case ACTION_TYPES.ARRIVE:
                    return i18n.t("triggers.arrivesTo");

                case ACTION_TYPES.LEAVE:
                    return i18n.t("triggers.leaves");

                case ACTION_TYPES.ENTER_FIRST_TIME:
                    return i18n.t("triggers.aproachesForTheFirstTime");

                case ACTION_TYPES.CONTACT:
                    return i18n.t("triggers.makesContactWith");

                case ACTION_TYPES.DISCOVERY:
                    return i18n.t("triggers.aproaches");

                default:
                    return undefined + " " + locationTrigger.actionType;
            }
        }
    }
}

function notificationDescriptionB(userName) {
    const name = userName ? userName : i18n.t("triggers.allAdmins");
    return `${i18n.t("triggers.notify")} ${name}`;
}

function deviceDescriptionA(parameters = DeviceTrigger(), devices = [Device()]) {
    return (
        `${i18n.t("triggers.when")} "${deviceName()}" ${deviceStatusDescriptionA(parameters.localStatus)}`
    );

    function deviceName() {
        return DeviceHelper.getNameForUidFromDevices(
            parameters.deviceUid,
            devices,
        );
    }
}

function deviceDescriptionB(action = TriggerAction(), devices = [Device()]) {
    return `"${deviceName()}" ${deviceDescription()}${delay()}`;

    function deviceName() {
        return DeviceHelper.getNameForUidFromDevices(
            action.deviceUid,
            devices,
        );
    }

    function deviceDescription() {
        if (action.toggle)
            return i18n.t("triggers.willToggleInvert");

        return deviceStatusDescriptionB(action.localStatus);
    }

    function delay() {
        if (!action.delay)
            return "";

        return ` ${i18n.t("common.in")} ${action.delay} ${i18n.t("common.seconds")}`;
    }
}

function deviceStatusDescriptionB(localStatus) {
    switch (localStatus) {
        case LOCAL_STATUS.LIGHT_OFF:
            return i18n.t("triggers.willTurnOff");
        case LOCAL_STATUS.LIGHT_ON:
            return i18n.t("triggers.willTurnOn");

        case LOCAL_STATUS.OFF:
            return i18n.t("triggers.willTurnOff");
        case LOCAL_STATUS.ON:
            return i18n.t("triggers.willTurnOn");

        case LOCAL_STATUS.ROLLER_STOPPED:
            return i18n.t("triggers.willStop");
        case LOCAL_STATUS.ROLLER_OPENED:
            return i18n.t("triggers.willOpen");
        case LOCAL_STATUS.ROLLER_CLOSED:
            return i18n.t("triggers.willClose");

        case LOCAL_STATUS.CLOSED:
            return i18n.t("triggers.willClose");
        case LOCAL_STATUS.OPEN:
            return i18n.t("triggers.willOpen");

        case LOCAL_STATUS.CLOSED_LOCKED:
            return i18n.t("triggers.willCloseAndLock");
        case LOCAL_STATUS.CLOSED_UNLOCKED:
            return i18n.t("triggers.willClose");
        case LOCAL_STATUS.OPENED_UNLOCKED:
            return i18n.t("triggers.willOpen");
        case LOCAL_STATUS.LATCH_REMOVED:
            return i18n.t("triggers.willRemoveTheLatch");

        case LOCAL_STATUS.OPEN_GARAGE_DOOR:
            return i18n.t("triggers.willToggle");

        case LOCAL_STATUS.BRAIN_SIREN:
            return i18n.t("triggers.alarmWillGoOff");

        case LOCAL_STATUS.BRAIN_RECORD_VIDEO:
            return i18n.t("triggers.willRecordVideo");

        default:
            return undefined;
    }
}

function deviceStatusDescriptionA(localStatus) {
    switch (localStatus) {
        case LOCAL_STATUS.LIGHT_OFF:
            return i18n.t("triggers.turnsOff");
        case LOCAL_STATUS.LIGHT_ON:
            return i18n.t("triggers.turnsOn");

        case LOCAL_STATUS.OFF:
            return i18n.t("triggers.turnsOff");
        case LOCAL_STATUS.ON:
            return i18n.t("triggers.turnsOn");

        case LOCAL_STATUS.ROLLER_STOPPED:
            return i18n.t("triggers.stops");
        case LOCAL_STATUS.ROLLER_OPENED:
            return i18n.t("triggers.opens");
        case LOCAL_STATUS.ROLLER_CLOSED:
            return i18n.t("triggers.closes");

        case LOCAL_STATUS.IDLE:
            return i18n.t("triggers.isIdle");

        case LOCAL_STATUS.FLOOD:
            return i18n.t("triggers.detectsFlood");

        case LOCAL_STATUS.CLOSED:
            return i18n.t("triggers.closes");
        case LOCAL_STATUS.OPEN:
            return i18n.t("triggers.opens");

        case LOCAL_STATUS.CLOSED_LOCKED:
            return i18n.t("triggers.closesAndLocks");
        case LOCAL_STATUS.CLOSED_UNLOCKED:
            return i18n.t("triggers.closes");
        case LOCAL_STATUS.OPENED_UNLOCKED:
            return i18n.t("triggers.opens");
        case LOCAL_STATUS.LATCH_REMOVED:
            return i18n.t("triggers.removesTheLatch");

        case LOCAL_STATUS.BUTTON_PRESSED:
            return i18n.t("triggers.isPressed");

        case LOCAL_STATUS.MOVEMENT_DETECTED:
            return i18n.t("triggers.detectsMovement");

        case LOCAL_STATUS.VIBRATIONS:
            return i18n.t("triggers.detectsVibrations");

        case LOCAL_STATUS.OPEN_GARAGE_DOOR:
            return i18n.t("triggers.toggles");

        default:
            return undefined;
    }
}