import { PMSCustomizationService } from "../_services/PMSCustomizationService";
import { genericActions } from "../../Everou/_actions/generic.actions";
import { PMSCustomizationTemplateStore } from "../_stores/PMSCustomizationTemplateStore";
import { LocationCustomizationMapper } from "../../Everou/_mappers/LocationCustomizationMapper";

export const PMSCustomizationActions = {
    getPMSCustomizationTemplate,
    update,
    uploadLogo,
    uploadBackground,

    _setUploading: PMSCustomizationTemplateStore.actionRequest,
};

function getPMSCustomizationTemplate() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(PMSCustomizationTemplateStore.actionSet(
                LocationCustomizationMapper.serverToLocal(
                    await PMSCustomizationService.get()
                )
            ));
        }
    };
}

function update(accentColor, headerColor) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const response = await PMSCustomizationService.update(
                accentColor,
                headerColor,
            );
            dispatch(PMSCustomizationTemplateStore.actionSet(
                LocationCustomizationMapper.serverToLocal(response)
            ));
        }
    };
}

function uploadLogo(image) {
    return async (dispatch, getState) => {
        dispatch(PMSCustomizationActions._setUploading());
        await dispatch(baseUploadLogo(image));
        await dispatch(getPMSCustomizationTemplate());
    }
}

function baseUploadLogo(image) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        async function asyncAction() {
            await PMSCustomizationService.uploadLogo(image);
        }
    };
}

function uploadBackground(image) {
    return async (dispatch, getState) => {
        dispatch(PMSCustomizationActions._setUploading());
        await dispatch(baseUploadBackground(image));
        await dispatch(getPMSCustomizationTemplate());
    }
}

function baseUploadBackground(image) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));
        async function asyncAction() {
            await PMSCustomizationService.uploadBackground(image);
        }
    };
}