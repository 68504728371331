import React from "react";

import { TextInputComponent, TextInputComponentProps } from "./TextInputComponent";
import { useValidationInput } from "../_hooks/useValidationInput";

const array = [];
const fn = () => {};

interface ValidatedTextInputProps extends TextInputComponentProps {
    setValue?: (...args: any) => void;
    setIsValid?: (...args: any) => void;
    warningFns?: ((...args: any) => void)[];
    forceShowWarnings?: boolean;
    placeholder?: string;
    isRequired?: boolean;
}

export function ValidatedTextInput({
    testID,
    isEditable,

    value,
    setValue = fn,
    setIsValid = fn,

    warningFns = array,
    forceShowWarnings = false,

    className = '',
    placeholder = '',
    type = 'text',

    isRequired = false,
}: ValidatedTextInputProps) {

    //LOCAL STATE
    const { handleSetWiredValue, WarningsComponent } = useValidationInput({
        isRequired,
        forceWarnings: forceShowWarnings,
        handleSetValue: setValue,
        value,
        setIsValid,
        warningFns,
    });

    //VIEW
    return (
        <TextInputComponent
            testID={testID}
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={handleSetWiredValue}
            type={type}
            isEditable={isEditable}
            WarningsComponent={WarningsComponent}
        />
    );
}