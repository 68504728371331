import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from "../_components";
import { Friend } from "../_models/Friend";
import { ListedGroup } from "../_components/ListedGroup";
import { CircleAvatar } from "../_components/CircleAvatar";
import { User } from "../_models/User";
import { userLogsActions } from "../_actions/userLogs.actions";

import { LOCATION_SHARE_STATUSES } from "../_constants/userLogs.constants";
import { UserLogsUiHelper } from "../_helpers/UserLogsUiHelper";
import { Selectors } from "../_reducers/app.reducer";

export function UserLogsMainPage() {
    
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const selectedUserUid   = useSelector(Selectors.getUserLogsSelectedUid);
    let friends             = [Friend()];
    friends                 = useSelector(Selectors.getFriends);
    let user                = User();
    user                    = useSelector(Selectors.getEverouUser);

    //ACTION HANDLERS
    function handleChangeSelectedUser(userUid) {
        dispatch(userLogsActions.changeSelectedUserUid(userUid));
    }

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("mod_positioning_title")}
            content={content()}
        />
    );

    function content() {
        return (
            <>
                <div className="mt-2"/>
                {myPositionButton()}
                {friendsContent()}
            </>
        );
    }

    function friendsContent() {
        if (!user.isSharePositionEnabled)
            return sharePositionDisabledMessage();

        return friends.length === 0
            ? noUsersSharingMessage()
            : userFriends()
        ;
    }

    function noUsersSharingMessage() {
        return (
            <div className="text-center mt-2">
                {t("mod_positioning_no_users_available")}
            </div>
        );
    }

    function sharePositionDisabledMessage() {
        return (
            <div className="text-center mt-2">
                {t("mod_positioning_share_position_disabled")}
            </div>
        );
    }

    function myPositionButton() {
        return (
            <div
                onClick={() => handleChangeSelectedUser(user.uid)}
                className={`
                    px-2
                    py-1
                    
                    c-cursor-default

                    rounded
                    border

                    ${selectedUserUid === user.uid
                        ? "c-bg-primary-light-2"
                        : "c-hover-bg-grey"
                    }
                `}
            >
                {t("mod_positioning_see_my_positions")}
            </div>
        );
    }

    function userFriends() {
        return (
            <div>
                <div className="mt-2"/>
                <ListedGroup
                    elements={getUiFriends()}
                />
            </div>
        );

        function getUiFriends() {
            return friends
                .map(EnrichedFriend)
                .map(enrichedFriend =>
                    <SingleFriend
                        friend={enrichedFriend}
                        handleChangeSelectedUser={handleChangeSelectedUser}
                        selectedUserUid={selectedUserUid}
                    />
                )
            ;
        }
    }
}

function EnrichedFriend(friend = Friend()) {
    return {
        ...friend,
        shareStatusString:
            UserLogsUiHelper.stringForShareStatus(
                calculateShareStatus(friend),
            )
        ,
    };

    function calculateShareStatus(friend = Friend()) {
        if (friend.shareDest && friend.shareFrom)
            return LOCATION_SHARE_STATUSES.BOTH_SHARE;

        if (!friend.shareDest && !friend.shareFrom)
            return LOCATION_SHARE_STATUSES.NO_SHARING;

        if (friend.shareDest && !friend.shareFrom)
            return LOCATION_SHARE_STATUSES.THEY_SHARE;

        if (!friend.shareDest && friend.shareFrom)
            return LOCATION_SHARE_STATUSES.YOU_SHARE;

        return "UNDEFINED_SHARING_STATE";
    }
}

function SingleFriend({
    friend = EnrichedFriend(),
    handleChangeSelectedUser = (uid) => {},
    selectedUserUid,
} = {}) {

    //VIEW
    return (
        <div
            key={friend.uid}
            onClick={() => handleChangeSelectedUser(friend.uid)}
            className={`
                px-2
                py-1

                d-flex
                align-items-center
                
                c-cursor-default

                ${selectedUserUid === friend.uid
                    ? "c-bg-primary-light-2"
                    : "c-hover-bg-grey"
                }
            `}
        >
            <CircleAvatar srcImg={friend.avatar} names={[friend.name, friend.lastName]} />
            <span className="pl-2"/>
            <div>
                <div>
                    {friend.email || friend.uid}
                </div>
                <div className="text-secondary">
                    {friend.shareStatusString}
                </div>
            </div>
        </div>
    );
}