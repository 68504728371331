import { Selectors } from "../../Everou/_reducers/app.reducer";
import { PMSLocationsActions } from "../_actions/PMSLocationsActions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CreateReservationInput } from "./_components/CreateReservationInput";
import { ReservationInput } from "./_components/ReservationInput";

export function PMSLocationReservations() {

    // LIBRARIES
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // GLOBAL STATE
    let reservations = [];
    reservations = useSelector(Selectors.getPMSLocationReservations);
    const locationUid = useSelector(Selectors.getPMSLocationSelectedUid);

    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSLocationsActions.getReservations());
    }, [dispatch, locationUid]);

    return (
        <div className="py-4 c-grid">
            {content()}
        </div>
    )

    function content() {
        if (!!!reservations || reservations.length == 0) {
            return (
                <div>
                    <span className="
                        w-100
                        pt-2
                        text-center
                    ">
                        {t('mod_integrations_reservations_not_yet')}
                    </span>
                    <CreateReservationInput />
                </div>
            )
        }

        return (
            <div>
                {reservations?.map(( reservation, index ) => 
                    <ReservationInput
                        reservation={reservation} />
                )}
                <CreateReservationInput />
            </div>
            
        );
    }

}