import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CapsuleButton, ContentSpace, MainPageWithButtons } from "../_components";
import { Selectors } from "../_reducers/app.reducer";
import { ClockinActions } from "../_stores/ClockinStore/ClockinActions";
import { CustomDropdown } from "../_components/CustomDropdown";
import { User } from "../_models/User";
import { IsoStartEndDatePicker } from "../_components/IsoStartEndDatePicker";
import { globalActions, locationActions } from "../_actions";
import { GLOBAL_POPUPS } from '../_constants';

const usersArray = [User()];

export function ClockinsReportsModule() {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    let users = usersArray;
    users = useSelector(Selectors.getUsers);

    //LOCAL STATE
    const [isRequesting, setIsRequesting] = useState(null);
    const [userUid, setUserUid] = useState(null);
    const [end, setEnd] = useState(null);
    const [begin, setBegin] = useState(null);
    const [allUsersReport, SetAllUsersReport] = useState(false);

    const isInfoReady = (!!userUid || allUsersReport) && !!begin && !!end;
    const isReady = isInfoReady && !isRequesting;

    //INITIALIZATION
    useEffect(() => {
        dispatch(locationActions.getLocationUsers(locationUid, true));
        let defaultUser = users[0];
        if (defaultUser !== undefined) setUserUid(defaultUser.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationUid]); // No se puede poner "users" para arreglar warning porque crea un bucle 

    //ACTION HANDLERS
    const actionWrapper = useCallback(async asyncFn => {
        setIsRequesting(true);
        await asyncFn();
        setIsRequesting(false);
    }, []);

    const handleViewPdf = useCallback(async () => {
        actionWrapper(() => dispatch(ClockinActions.openPdfReport(locationUid, userUid, begin, dateStringToEndOfDay(end), allUsersReport)))
    }, 
        [dispatch, actionWrapper, locationUid, userUid, begin, end, allUsersReport]
    );

    const handleDownloadPdf = useCallback(() => {
        actionWrapper(() => dispatch(ClockinActions.downloadPdfReport(locationUid, userUid, begin, dateStringToEndOfDay(end), allUsersReport)))
    }, 
        [dispatch, actionWrapper, locationUid, userUid, begin, end, allUsersReport]
    );

    const handleExport = () => dispatch(globalActions.showPopUp(GLOBAL_POPUPS.EXPORT_CLOCKINS, { locationUid: locationUid, begin: begin, end: dateStringToEndOfDay(end) }));

    function handleToggleAllUsersReport() {
        let updatedValue = !allUsersReport;
        SetAllUsersReport(updatedValue);
    }

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("global_report")}
                    buttons={getButtons()}
                    content={content()}
                    size={'MEDIUM'}
                />
            }
        />
    );

    function content() {
        return (
            <div className="mt-2">
                <div className="
                    flex-shrink-0

                    d-flex
                    justify-content-between
                ">
                    <CustomDropdown
                        title={t("global_user")}
                        value={userUid}
                        onChange={setUserUid}
                        sortAlphabetically
                        options={optionsFn()}
                        isReadOnly={allUsersReport}
                    />
                    
                    <div className="ml-2"/>

                    {allUsersReportToggle()}
                </div>

                <div className="mt-2"/>
                <IsoStartEndDatePicker
                    handleEndDateChange={setEnd}
                    handleStartDateChange={setBegin}
                    endIsoDate={end}
                    startIsoDate={begin}
                    dateOnly
                    />
            </div>
        );

        function optionsFn() {
            return users.map(user =>
                CustomDropdown.buildOption(user.uid, user.email)
            );
        }
    }

    function allUsersReportToggle() {
        return (
            <div
                onClick={() => handleToggleAllUsersReport()}
                className="
                    d-flex
                    align-items-center
                    cp
                "
            >
                <input
                    type="checkbox"
                    readOnly
                    checked={allUsersReport}
                />
                <div className="pl-1"/>
                <div>
                    {t("global_all")}
                </div>
            </div>
        );
    }

    function getButtons() {
        return [
            <CapsuleButton
                onClick={handleViewPdf}
                text={t("global_view_pdf")}
                isEnabled={isReady}
                fillStyle={'FILLED'}
            />,
            <CapsuleButton
                onClick={handleDownloadPdf}
                text={t("global_download")}
                isEnabled={isReady}
                fillStyle={'FILLED'}
            />,
            <CapsuleButton
                onClick={handleExport}
                text={t("global_export")}
                isEnabled={isReady}
                fillStyle={'FILLED'}
            />,
        ]
    }

    function dateStringToEndOfDay(dateString) {
        let date = new Date(dateString)
        let dateUTC = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999))
        return dateUTC.toISOString();
    }
}