import React from "react";

import { UserManager } from "./App/UserManager";
import { PMS } from "../PMS/PMS";

export function PMSEverouBridge() {

    //VIEW
    return (
        <UserManager>
            <PMS />
        </UserManager>
    );
}