import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleToggle } from "../_components";
import ToolTipWrapper from "../_components/ToolTipWrapper";

type CreatePublicUrlToggleType = {
    initialState?: boolean;
    onChange: (value: boolean) => void;
    boldedName?: boolean;
    readOnly?: boolean;
};

export default function CreatePublicUrlToggle({
    onChange,
    initialState,
    boldedName = true,
    readOnly = false
}: CreatePublicUrlToggleType) {

    const [state, setState] = useState(initialState);
    const { t } = useTranslation();

    useEffect(() => setState(initialState), [initialState])

    return (
        <div className={`${readOnly ? 'cna' : 'cp'} d-flex align-items-center justify-content-between`}>
            <ToolTipWrapper
                tooltip={t("invitation_creation_invitation_public_url_tooltip")}
            >
                <div className={`${boldedName ? 'font-weight-bold' : ''} position-relative`}>
                    {t("invitation_creation_invitation_public_url_info")}
                </div>
            </ToolTipWrapper>
            <div className="ml-2"></div>
            <SimpleToggle
                readOnly={readOnly}
                checked={state}
                handleChange={({ detail: { value } }) => {
                    setState(value);
                    onChange(value);
                }}
            ></SimpleToggle>
        </div>
    );
}
