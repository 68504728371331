import { Device } from "./Device";
import { UserStatusUsageData } from "./UserStatusUsageData";

export function UserStatusDeviceData({
    deviceInfo = Device(),
    usageData = UserStatusUsageData(),
} = {}) {
    return {
        deviceInfo,
        usageData,
    };
}