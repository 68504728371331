import { PROTECT_ACTION_TYPES } from "../_constants/protect.constants";

const initialState = {
    triggeredLogs: [],
};

export class ProtectSelectors {
    static getTriggeredLogs = state => state.triggeredLogs;
}

export function protect(state = initialState, action) {
    switch (action.type) {
        case PROTECT_ACTION_TYPES.GET_TRIGGERED_LOGS:
            return { ...state,
                triggeredLogs: action.triggeredLogs,
            };

        default:
            return state;
    }
}