import React from "react";
import { useTranslation } from "react-i18next";

import { MainPageWithButtons } from "../_components";
import { UserStatusData } from "../_models/UserStatusData";
import { UserStatusDeviceData } from "../_models/UserStatusDeviceData";
import { DevicesDataTable } from "./DevicesDataTable";
import { MobileDataComp } from "./MobileDataComp";
import { UserStatusMobile } from "../_models/UserStatusMobile";
import { Mobile } from "../_models/Mobile";
import { MobilesHelper } from "../_helpers/MobilesHelper";
import { UserStatusUsageData } from "../_models/UserStatusUsageData";

import okStatusIcon from '../_assets/appIcons/icon_check.svg';
import notOkStatusIcon from '../_assets/appIcons/icon_error.svg';
import noUsesIcon from '../_assets/appIcons/icon_error.svg';
import { WaitSpinner } from "../_components/WaitSpinner";
import { UserStatusLog } from "../_models/UserStatusLog";
import { UserStatusLogsTable } from "./UserStatusLogsTable";

export function UserStatusDataView({
    dateFilter,

    isRequestingUserStatusData = false,
    userStatusData = UserStatusData(),
    
    isRequestingUserStatusLogs = false,
    userStatusLogs = [] || [UserStatusLog()],

    timezone,
    canSeeAllData = false,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("mod_users_status_data_title")}
            content={content()}
        />
    );

    function content() {
        return (
            <div>
                {requestingMessage()}
                {data()}
            </div>
        );
    }

    function requestingMessage() {
        if (!isRequestingUserStatusData)
            return;

        return <WaitSpinner />;
    }

    function data() {
        if (!userStatusData)
            return;

        if (isRequestingUserStatusData)
            return;

        return (
            <div>
                <div className="mt-2" />
                {mobileData(userStatusData.mobileChanges)}
                <div className="mt-3" />
                <UserStatusLogsTable
                    canSeeAllData={canSeeAllData}
                    isRequestingUserStatusLogs={isRequestingUserStatusLogs}
                    userStatusLogs={userStatusLogs}
                    timezone={timezone}
                    dateFilter={dateFilter}
                />
                <div className="mt-3" />
                {devicesData(userStatusData.devicesData)}
            </div>
        );
    }

    function mobileData(mobile = UserStatusMobile()) {
        return (
            <MobileDataComp
                canSeeAllData={canSeeAllData}
                mobile={userStatusData.mobile}

                isUpToDate={mobile ? mobile.isUpToDate : false}
                isThereMobile={Boolean(userStatusData.mobileChanges)}
                changedPermissions={userStatusMobileToChangedPermissions(userStatusData.mobileChanges)}
                currentPermissions={mobileToCurrentPermissions(userStatusData.mobile)}
            />
        );

        function mobileToCurrentPermissions(mobile = Mobile()) {
            if (!mobile)
                return [];
                
            return [
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_activity_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.activityStatusPermission,
                    ),
                }),
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_battery_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.batteryPermission,
                    ),
                }),
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_ble_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.blePermission,
                    ),
                }),
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_location_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.locationPermission,
                    ),
                }),
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_location_service_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.locationServicePermission,
                    ),
                }),
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_notification_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.notificationsPermission,
                    ),
                }),
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_bluetooh_scan_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.bleScanPermission,
                    ),
                }),
                MobileDataComp.CurrentPermissionModel({
                    name: t("temp_webapp_mobile_background_location_permission"),
                    isCorrect: !MobilesHelper.isMobilePermissionStateInvalid(
                        mobile.backgroundLocationPermission,
                    ),
                }),
            ];
        }

        function userStatusMobileToChangedPermissions(mobile = UserStatusMobile()) {
            if (!mobile)
                return [];

            return [
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_activity_permission"),
                    hasBeenModified: (mobile.activityStatusPermissionChanges > 0),
                    timesModified: mobile.activityStatusPermissionChanges,
                }),
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_battery_permission"),
                    hasBeenModified: (mobile.batteryOptimizerPermissionChanges > 0),
                    timesModified: mobile.batteryOptimizerPermissionChanges,
                }),
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_ble_permission"),
                    hasBeenModified: (mobile.blePermissionChanges > 0),
                    timesModified: mobile.blePermissionChanges,
                }),
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_location_permission"),
                    hasBeenModified: (mobile.locationPermissionChanges > 0),
                    timesModified: mobile.locationPermissionChanges,
                }),
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_location_service_permission"),
                    hasBeenModified: (mobile.locationServicePermissionChanges > 0),
                    timesModified: mobile.locationServicePermissionChanges,
                }),
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_notification_permission"),
                    hasBeenModified: (mobile.notificationsPermissionChanges > 0),
                    timesModified: mobile.notificationsPermissionChanges,
                }),
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_bluetooh_scan_permission"),
                    hasBeenModified: (mobile.bleScanPermissionChanges > 0),
                    timesModified: mobile.bleScanPermissionChanges,
                }),
                MobileDataComp.ChangedPermissionModel({
                    name: t("temp_webapp_mobile_background_location_permission"),
                    hasBeenModified: (mobile.backgroundLocationPermissionChanges > 0),
                    timesModified: mobile.backgroundLocationPermissionChanges,
                }),
            ];
        }
    }


    function devicesData(devicesData = []) {
        if (!devicesData.length)
            return;

        return (
            <DevicesDataTable
                canSeeAllData={canSeeAllData}
                deviceDataRowModels={devicesDataToTableModels(userStatusData.devicesData)}
                theadModels={buildTheadModels()}
            />
        );

        function devicesDataToTableModels(devicesData = []) {
            return devicesData.map(deviceDataToTableModel);
    
            function deviceDataToTableModel(deviceData = UserStatusDeviceData()) {
                const hasNoUses =  doesDeviceHaveNoUses(deviceData.usageData);

                return DevicesDataTable.DeviceDataRowModel({
                    deviceName: deviceData.deviceInfo.description,
                    automaticOpen: deviceData.usageData.automaticOpen,
                    automaticExit: deviceData.usageData.automaticExit,
                    manualOpen: deviceData.usageData.manualOpen,
                    isOk: deviceData.usageData.isWorkingProperly,
                    statusImg: deviceStatusIcon(),
                    hasNoUses,
                });

                //
                function doesDeviceHaveNoUses(usageData = UserStatusUsageData()) {
                    return (
                        usageData.automaticExit === 0
                        && usageData.automaticOpen === 0
                        && usageData.manualOpen === 0
                    );
                }

                function deviceStatusIcon() {
                    if (!deviceData.usageData.isWorkingProperly)
                        return notOkStatusIcon;

                    if (hasNoUses)
                        return noUsesIcon;

                    return okStatusIcon;
                }
            }
        }

        function buildTheadModels() {
            return [
                DevicesDataTable.DeviceDataTheadModel({
                    text: t("mod_users_status_device_name"),
                }),
                DevicesDataTable.DeviceDataTheadModel({
                    text: t("mod_users_status_device_manual_openings"),
                    isVisible: canSeeAllData,
                }),
                DevicesDataTable.DeviceDataTheadModel({
                    text: t("mod_users_status_device_auto_enter"),
                    isVisible: canSeeAllData,
                }),
                DevicesDataTable.DeviceDataTheadModel({
                    text: t("mod_users_status_device_auto_exit"),
                    isVisible: canSeeAllData,
                }),
                DevicesDataTable.DeviceDataTheadModel({
                    text: t("mod_users_status_device_status"),
                }),
            ];
        };
    }
}