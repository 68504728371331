import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { MainPageWithButtons } from "../_components";
import { Subscription } from "../_models/Subscription";
import { subscriptionsActions } from "../_actions/subscriptions.actions";
import { globalActions } from "../_actions";
import { GLOBAL_POPUPS } from "../_constants";
import { CapsuleButton } from "../_components/CapsuleButton";
import { Selectors } from "../_reducers/app.reducer";
import { SubscriptionAddEditViewGeneric } from "./SubscriptionAddEditViewGeneric";

const testIDs = {
    root: "subscription-add-edit-view"
};

const fn = () => {};

SubscriptionAddEditView.testIDs = testIDs;

export function SubscriptionAddEditView({
    onGoBack = fn,
    mode = SubscriptionAddEditViewGeneric.MODES.EDIT,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    let selected = Subscription();
    selected = useSelector(Selectors.getSelectedSubscription);

    //VIEW
    if (!selected)
        return null;

    return <MainPageWithButtons
        dataTestId={testIDs.root}
        size={'SMALL'}
        pageTitle={t("common.details")}
        content={<SubscriptionAddEditViewGeneric {...{ onGoBack, mode }}/>}
        buttons={buttons()}
    />;
    
    function buttons() {
        if (selected.validated)
            return activateButtons();

        if (selected.edited)
            return editButtons();
    }

    function activateButtons() {
        return [
            <CapsuleButton
                onClick={handleActivate}
                text={t("common.activate")}
            />,
            <CapsuleButton
                style={`DANGER`}
                onClick={handleCancelActivation}
                text={t("common.cancel")}
            />
        ];
    }
    
    function editButtons() {
        return [
            <CapsuleButton
                onClick={handleSaveChanges}
                text={t("common.save")}
            />,
            <CapsuleButton
                onClick={handleDiscardChanges}
                text={t("common.discard")}
            />
        ];
    }

    //ACTION HANDLERS
    function handleDiscardChanges() {
        dispatch(subscriptionsActions.discardChanges());
    }

    function handleSaveChanges() {
        dispatch(globalActions.showPopUp(GLOBAL_POPUPS.EDIT_RESERVATIONS_SUBSCRIPTION));
    }

    function handleActivate() {
        dispatch(subscriptionsActions.activate());
    }

    function handleCancelActivation() {
        dispatch(subscriptionsActions.changeSecondaryView(null))
    }
}