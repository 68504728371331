import { PMSLocationDetailViewModes } from "../_constants/pmsLocationsViewMode.constants";
import { PMS_LOCATION_VIEW_MODE_ACTION_TYPES } from "../_constants/pmsLocationsViewMode.constants";

const initialState = {
    viewMode: PMSLocationDetailViewModes.ACTIVITY,
};

export function viewMode(state = initialState, action) {
    switch (action.type) {
        case PMS_LOCATION_VIEW_MODE_ACTION_TYPES.CHANGE_VIEW_MODE:
            if (!state.hydrated) {
                state = {
                    ...initialState,
                    ...state,
                    hydrated: true,
                };
            }
        
            return {
                ...state,
                viewMode: action.newViewMode,
            };
        
        default:
            return state;
    }
    
}