import { AuditLogsHelper } from "../_helpers/AuditLogsHelper";
import { LocalAuditPathModel } from "../_models/LocalAuditPathModel";
import { filterDisabled, FILTER_DISABLED_ALL, APP_LOG_TYPES } from "../_constants";
import { auditLogsService } from "../_services/auditLogs.service";
import { AuditLogMapper } from "../_mappers/AuditLogMapper";
import { genericActions } from "./generic.actions";
import { AUDIT_LOGS_ACTION_TYPES } from "../_constants/auditLogs.constants";
import { AuditFilterPresetMapper } from "../_mappers/AuditFilterPresetMapper";
import { AuditFilterPreset } from "../_models/AuditFilterPreset";
import { Selectors } from "../_reducers/app.reducer";
import { AuditLogsCollection } from "../_stores/AuditLogsCollection";
import { AuditLogsDateFilterStore } from "../_stores/AuditLogsDateFilterStore";
import { logsActions } from ".";

export const auditLogsActions = {
    //local
    selectFilterPreset,

    addPathFilter,
    removePathFilter,

    setFilterUser,
    changeFilterAuditActionPath,
    changeFilterAuditType,

    selectLog: AuditLogsCollection.actionSelectItem,
    listLogsRequest: AuditLogsCollection.actionListItemsRequest,
    listLogsSuccess: AuditLogsCollection.actionListItemsSuccess,

    setDateFilter: AuditLogsDateFilterStore.actionSetBeginEnd,
    
    //remote
    getAuditFirstPage: () => logsActions.getFirstPage(APP_LOG_TYPES.AUDIT),
    getAuditNextPage: (nextPage, isRequesting) => logsActions.getNextPage(nextPage, isRequesting, APP_LOG_TYPES.AUDIT),
    
    getAuditLogsPage,

    getAuditLogsFilterPresets,
    createAuditLogsFilterPreset,
    removeAuditLogsFilterPreset,
};

function getAuditLogsFilterPresets(locationUid) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!locationUid)
                return;

            const serverFilterPresets = await auditLogsService.getAuditLogsFilterPresets(
                locationUid,
            );

            const localFilterPresets = AuditFilterPresetMapper.allServerToLocal(
                serverFilterPresets,
            );
            dispatch(success(localFilterPresets));
        }
    }

    function success(filterPresets) {
        return {
            type: AUDIT_LOGS_ACTION_TYPES.GET_FILTER_PRESETS_SUCCESS,
            filterPresets,
        };
    }
}

function createAuditLogsFilterPreset(locationUid, filterPreset = AuditFilterPreset()) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!filterPreset.name || filterPreset.auditPaths.length === 0)
                return;

            const serverReadyPreset = AuditFilterPresetMapper.localToSever(
                filterPreset,
            );
            const serverFilterPreset = await auditLogsService.createAuditLogsFilterPreset(
                locationUid,
                serverReadyPreset.data,
            );

            const localFilterPreset = AuditFilterPresetMapper.serverToLocal(
                serverFilterPreset,
            );
            dispatch(success(localFilterPreset));
        }
    }

    function success(filterPreset) {
        return {
            type: AUDIT_LOGS_ACTION_TYPES.CREATE_FILTER_PRESET_SUCCESS,
            filterPreset,
        };
    }
}

function removeAuditLogsFilterPreset(locationUid, uid) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await auditLogsService.removeAuditLogsFilterPreset(
                locationUid,
                uid,
            );

            dispatch(success(uid));
        }
    }

    function success(uid) {
        return {
            type: AUDIT_LOGS_ACTION_TYPES.REMOVE_FILTER_PRESET_SUCCESS,
            uid,
        };
    }
}

function getAuditLogsPage(page) {
    return async (dispatch, getState) => {
        dispatch(auditLogsActions.listLogsRequest(page));
        const logsFilter = Selectors.getAuditLogsFilters(getState());
        const { begin, end } = Selectors.getAuditLogsDateFilter(getState());
        const { logs, next } = await auditLogsService.getAuditLogs(
            AuditLogMapper.parametersLocalToServer({
                localLocationUid: Selectors.getSelectedLocationUid(getState()),
                localOffset: page,
                localBegin: begin,
                localEnd: end,

                localUser: logsFilter.user,
                localCode: logsFilter.code,
                localType: logsFilter.type,
                localActionPath: logsFilter.actionPath,
                localPaths: logsFilter.paths,
            }),
        );

        const localLogs = AuditLogMapper.allServerToLocal(logs);

        dispatch(auditLogsActions.listLogsSuccess(localLogs, next));
        
        return {
            localLogs,
            next,
        };
    };
}

function setFilterUser(uid) {
    return changeGenericFilter({ user: uid });
}

function changeFilterAuditActionPath(actionPath) {
    return changeGenericFilter({ actionPath });
}

function changeFilterAuditType(type) {
    return changeGenericFilter({
        type,
        actionPath: filterDisabled.number,
    });
}

function addPathFilter(type, actionPath) {
    return (dispatch, getState) => {
        const newPath = AuditLogsHelper.getPath(
            type,
            actionPath,
        );

        const currentPaths = Selectors.getAuditLogsFilters(getState()).paths;
        
        if (!newPath)
            return;

        if (alredyExistsFn())
            return;

        const paths = [
            ...currentPaths,
            LocalAuditPathModel({
                type,
                actionPath,
                path: newPath,
            }),
        ];

        dispatch(updatePathsFilter(paths));
        dispatch(auditLogsActions.selectFilterPreset(
            FILTER_DISABLED_ALL.key,
        ));

        //
        function alredyExistsFn() {
            return currentPaths.some(path => path.path === newPath);
        }
    };
}

function removePathFilter(path) {
    return (dispatch, getState) => {

        const paths = Selectors.getAuditLogsFilters(getState())
            .paths.filter(currentPath =>
            currentPath.path !== path
        );
            
        dispatch(updatePathsFilter(paths));
        dispatch(auditLogsActions.selectFilterPreset(
            FILTER_DISABLED_ALL.key,
        ));
    };
}

function updatePathsFilter(paths) {
    return changeGenericFilter({ paths });
}

function changeGenericFilter(updatedObj) {
    return {
        type: AUDIT_LOGS_ACTION_TYPES.UPDATE_GENERIC_FILTER,
        updatedObj,
    };
}

function selectFilterPreset(uid, filterPresets = [] || [AuditFilterPreset()]) {
    return dispatch => {
        dispatch(selectPreset(uid));
        
        if (uid === FILTER_DISABLED_ALL.key)
            return;
            
        const filterPreset = filterPresets.find(preset => preset.uid === uid);
        dispatch(updatePathsFilter(filterPreset.auditPaths));
    };

    function selectPreset(uid) {
        return {
            type: AUDIT_LOGS_ACTION_TYPES.SELECT_FILTER_PRESET,
            uid,
        };
    }
}