import { useState, useCallback, useMemo } from "react";

export function useValidation() {

    const [isValidDictionary, setIsValidDictionary] = useState({});
    const [forceWarnings, setForceWarnings] = useState(false);

    const handleSetValid = useCallback((id, isValid) => {
        setIsValidDictionary(state => ({
            ...state,
            [id]: isValid,
        }));
    }, []);

    const isAllValid = useMemo(() => 
        !Object.values(isValidDictionary).includes(false)
    , [isValidDictionary]);

    return {
        handleSetValid,
        isAllValid,
        forceWarnings,
        setForceWarnings,
    };
}