export function PMSLocationInfo({
    uid,
    name,
    timezone,
    locationId,
    isProtectOn,
    protectAutoEnabledWithoutReservations,
    rooms,
    allowsCustomization,
    allowsProtect,
    hasProtectSubscription,
    invitationsAllowed,
    invitationsLeft,
    canCreateSuperguest,
    permission,
    isNotificationsActive,
    vacationRentalPlan,
    urlRecommendations,
    travelerDiscount,
    videoName,
    videoUrl,
    externalVideoUrl,
} = {}) {
    return {
        uid,
        name,
        timezone,
        locationId,
        isProtectOn,
        protectAutoEnabledWithoutReservations,
        rooms,
        allowsCustomization,
        allowsProtect,
        hasProtectSubscription,
        invitationsAllowed,
        invitationsLeft,
        canCreateSuperguest,
        permission,
        isNotificationsActive,
        vacationRentalPlan,
        urlRecommendations,
        travelerDiscount,
        videoName,
        videoUrl,
        externalVideoUrl
    };
}