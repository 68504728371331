import React from "react";
import { useTranslation } from "react-i18next";

import { UserStatusLog } from "../_models/UserStatusLog";
import { TimeHelper } from "../_helpers";

import okStatusIcon from '../_assets/appIcons/icon_check.svg';
import notOkStatusIcon from '../_assets/appIcons/icon_cross.svg';
import { USERS_STATUS_DATE_FILTER_PERIODS } from "../_constants/usersStatus.constants";

const USER_STATUS_LOG_TYPES = {
    PERMISSION_CHANGE:          24,
    BATTERY_PERMISSION_CHANGE:  25,
    CORRECT_PERMISSIONS:        26,
};

const STATUSES = {
    BLE:                        0,
    LOCATION:                   1,
    LOCATION_SERVICE:           2,
    NOTIFICATION_PERMISSION:    3,
    ACTIVITY_STATUS_PERMISSION: 4,
};

export function UserStatusLogsTable({
    canSeeAllData = false,

    isRequestingUserStatusLogs = false,
    userStatusLogs = [] || [UserStatusLog()],
    timezone,
    dateFilter,
}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    if (userStatusLogs.length === 0)
        return null;

    return (
        <div>
            <div className="font-weight-bold">
                {titleString()}
            </div>
            <hr className="m-0 mb-1"/>
            <div className="
                c-mh-10rem
                overflow-auto
                border
            ">
                {logsTable()}
            </div>
        </div>
    );

    function titleString() {
        let title = t("mod_positioning_activity_title")

        if (!canSeeAllData)
            title += " - " + periodsToUiPhrase(dateFilter);

        return title;
    }

    function logsTable() {
        return (
            <table className="w-100">
                <thead className="c-bg-grey-230">
                    <tr>
                        {stickyThead(
                            t("global_date")
                        )}
                        {stickyThead(
                            t("webapp_global_type")
                        )}
                        {stickyThead(
                            t("global_status")
                        )}
                    </tr>
                </thead>
                <tbody>
                    {userStatusLogs.map(logRow)}
                </tbody>
            </table>
        );
    }

    function stickyThead(name) {
        return(
            <th className="
                c-sticky-top
                c-bg-grey-230
            "
                scope="colX"
            >
                {name}
            </th>
        );
    }

    function logRow(userStatusLog, index) {
        const userStatusLogUi = mapLogToUi(userStatusLog, timezone);
        return (
            <tr
                key={index}
                className={`
                    border-top
                    ${userStatusLogUi.isStatusOk && "c-bg-light-green"}
                `
            }>
                <td>
                    {userStatusLogUi.dateString}
                </td>
                <td>
                    {userStatusLogUi.typeLabel}
                </td>
                <td>
                    <img
                        alt="log_status_img"
                        src={userStatusLogUi.isStatusOk
                            ? okStatusIcon
                            : notOkStatusIcon
                        }
                    />
                </td>
            </tr>
        );
    }

    function mapLogToUi(log, timezone) {
        return UserStatusLogUi({
            dateString: TimeHelper.localizeIsoStringToFormat(
                log.datetime,
                timezone,
            ),
            typeLabel: getUiLogTypeLabel(log.type, log.status),
            isStatusOk: getIsStatusOk(log.type),
        });
    
        function getIsStatusOk(type) {
            const okStatuses = [
                USER_STATUS_LOG_TYPES.CORRECT_PERMISSIONS,
            ];
        
            return okStatuses.includes(type);
        }
        
        function getUiLogTypeLabel(type, status) {
            switch (type) {
                case USER_STATUS_LOG_TYPES.CORRECT_PERMISSIONS:
                    return t("webapp_audit_system_ok");
        
                case USER_STATUS_LOG_TYPES.BATTERY_PERMISSION_CHANGE:
                    return t("temp_webapp_mobile_battery_permission");
        
                case USER_STATUS_LOG_TYPES.PERMISSION_CHANGE:
                    return mapStatusToTypeLabel(status);
        
                default:
                    return "UNKNOWN TYPE";
            }
        
            function mapStatusToTypeLabel(status) {
                switch (status) {
                    case STATUSES.ACTIVITY_STATUS_PERMISSION:
                        return t("temp_webapp_mobile_activity_permission");
        
                    case STATUSES.BLE:
                        return t("temp_webapp_mobile_ble_permission");
        
                    case STATUSES.LOCATION:
                        return t("temp_webapp_mobile_location_permission")
        
                    case STATUSES.LOCATION_SERVICE:
                        return t("temp_webapp_mobile_location_service_permission");
        
                    case STATUSES.NOTIFICATION_PERMISSION:
                        return t("temp_webapp_mobile_notification_permission");

                    default:
                        return "STATUS UNKNOWN";
                }
            }
        }
    }

    //HELPER FNS
    function periodsToUiPhrase(timeFilter) {
        const periodsToUi = {
            [USERS_STATUS_DATE_FILTER_PERIODS.TODAY]:
                t("mod_users_status_mobile_modified_last_day"),
            [USERS_STATUS_DATE_FILTER_PERIODS.LAST_WEEK]:
                t("mod_users_status_mobile_modified_last_week"),
            [USERS_STATUS_DATE_FILTER_PERIODS.LAST_2_WEEKS]:
                t("mod_users_status_mobile_modified_last_two_weeks"),
            [USERS_STATUS_DATE_FILTER_PERIODS.LAST_MONTH]:
                t("mod_users_status_mobile_modified_last_30_days"),
        };

        return periodsToUi[timeFilter] || "UDEFINED TIME PERIOD";
    }
}


function UserStatusLogUi({
    typeLabel,
    isStatusOk = false,
    dateString,
} = {}) {
    return {
        typeLabel,
        isStatusOk,
        dateString,
    };
}