
const serviceUuid = "0000fff0-0000-1000-8000-00805f9b34fb";
const characteristicUuid = "0000fff1-0000-1000-8000-00805f9b34fb";

export const BluetoothService = {
    //EVEROU SPECIFIC
    getDeviceFromBleCache,
    connectAndGetCharac,
    connectManuallyAndGetCharac,

    //BASIC WEB BLUETOOTH API
    isThereBluetooth,
    disconnectFromCharacteristic,
    scanAndSetUpCallback,
    setUpAdvertisementCallback,
    scanDevices,

    connectToDeviceRetry,
    connectToDevice,
    disconnect,
    getPrimaryService,
    getPrimaryServices,

    getNewDevice,
    getExistingDevices,
    getFirstExistingDevice,

    printCharacteristicDescriptors,
    printServerData,
    
    connectToDeviceAndGetService,

    subscribeAdvertisementsForDevice,
    unsubscribcribeAdvetisementsForDevice,

    getCharacteristic,
    addListenerToCharactistic,
    writeToCharacterictic,
    readCharacteristic,
};


//EVEROU SPECIFIC

function disconnectFromCharacteristic(characteristic) {
    const isConnected =
        characteristic
        && characteristic.service
        && characteristic.service.device
        && characteristic.service.device.gatt
        && characteristic.service.device.gatt.connected
    ;
    
    console.warn("DISCONNECT. Was connected =", isConnected);

    if (isConnected)
        characteristic.service.device.gatt.disconnect();
}

async function getDeviceFromBleCache(deviceBleId) {
    const bleDevices = await BluetoothService.getExistingDevices();
    return bleDevices.find(bleDevice => parseName(bleDevice.name) === deviceBleId);

    function parseName(name) {
        if (!name)
            return;

        const mac = name.slice(name.indexOf("(") + 1, name.indexOf(")"));
        const noDots = mac.split(":").join("");
        const last8 = noDots.slice(-8);
        return last8;
    }
}

async function connectManuallyAndGetCharac(bleUid, onRetry) {
    console.log("getting device MANUALLY");
    const bleDevice = await BluetoothService.getNewDevice([bleUid, serviceUuid]);
    console.log("got device MANUALLY", bleDevice);
    return await BluetoothService.connectAndGetCharac(bleDevice, onRetry);
}

async function connectAndGetCharac(bleDevice, onRetry) {
    return new Promise(async (resolve, reject) => {
        const timeout = setTimeout(
            () => reject("CONNECTION TIMEOUT"),
            12 * 1000,
        );

        try {
            const charac = await getCharac(bleDevice, onRetry);
            resolve(charac);
        } catch (e) {
            reject(e);
        } finally {
            clearTimeout(timeout);
        }
    });

    async function getCharac(bleDevice, onRetry = retryNum => {}) {
        console.log("getting server");
        const server = await BluetoothService.connectToDeviceRetry(bleDevice, onRetry);
        console.log("got server");

        console.log("getting service");
        const service = await BluetoothService.getPrimaryService(server, serviceUuid);
        console.log("got service");

        console.log("getting charac");
        const charac = await BluetoothService.getCharacteristic(service, characteristicUuid);
        console.log("got charac");

        return charac;
    }
}

//BASIC WEB BLUETOOTH API
function isThereBluetooth() {
    return !!navigator.bluetooth;
}

async function getPrimaryServices(server) {
    return await server.getPrimaryServices();
}

async function scanAndSetUpCallback(serviceUuid, onReceiveAdvertisement) {
    setUpAdvertisementCallback(onReceiveAdvertisement);
    return await scanDevices(serviceUuid);
}

async function setUpAdvertisementCallback(onReceiveAdvertisement) {
    navigator.bluetooth.addEventListener(
        'advertisementreceived',
        onReceiveAdvertisement,
    );
}

async function scanDevices(serviceUuid) {
    const options = serviceUuid
        ? { filters: [{ services: [serviceUuid] }] }
        : { acceptAllAdvertisements: true }
    ;
    return await navigator.bluetooth.requestLEScan(options);
}

async function readCharacteristic(characteristic) {
    return await characteristic.readValue();
}

async function getCharacteristic(service, characteristicUuid) {
    return await service.getCharacteristic(characteristicUuid);
}

async function writeToCharacterictic(characteristic, arrayBufferData) {
    return await characteristic.writeValue(arrayBufferData);
}

async function addListenerToCharactistic(characteristic, onChange = event => {}) {
    characteristic.addEventListener(
        "characteristicvaluechanged",
        onChange,
    );
    await characteristic.startNotifications();
}

async function disconnect(device) {
    await device.gatt.disconnect();
}

async function printCharacteristicDescriptors(characteristic) {
    const descriptors = await characteristic.getDescriptors();
    console.warn("descriptors", descriptors);
}

async function printServerData(server) {
    const services = await server.getPrimaryServices();
    console.warn("services", services);
    const characteristics = await services[0].getCharacteristics();
    console.warn("characteristics", characteristics);
}

async function getNewDevice(serviceUuids) {
    const optionsObj = serviceUuids
        ? { filters: [{ services: serviceUuids }] }
        : { acceptAllDevices: true }
    ;

    return await navigator.bluetooth.requestDevice(optionsObj);
}

async function getFirstExistingDevice() {
    const devices = await getExistingDevices();
    if (!devices.length) {
        console.warn("NO CACHED DEVICES");
        throw new Error("No cached devices");
    }
    return devices[0];
}

async function getExistingDevices() {
    if (!navigator.bluetooth.getDevices)
        return [];

    return await navigator.bluetooth.getDevices();
}

async function subscribeAdvertisementsForDevice(device, onReceiveAdvertisement = event => {}) {
    device.onadvertisementreceived = eventFn;
    await device.watchAdvertisements();

    function eventFn(event) {
        console.warn("Advertisement received.");
        device.onadvertisementreceived = null;
        onReceiveAdvertisement(event);
    }
}

function unsubscribcribeAdvetisementsForDevice(device) {
    console.warn("adv", device, device.onadvertisementreceived);
    device.onadvertisementreceived = null;

    // STILL NOT IMPLEMENTED BY CHROME
    // device.unwatchAdvertisements();
}

async function connectToDeviceAndGetService(device, serviceUuid, onDisconnectFn) {
    console.warn("device ", device);

    device.ongattserverdisconnected = onDisconnectFn;
    const server = await connectToDevice(device);
    const service = await getPrimaryService(server, serviceUuid);

    return {
        server,
        device,
        service,
    };
}

async function connectToDeviceRetry(
    bleDevice,
    onRetry = retryNum => {},
    maxRetries = 3,
) {
    let retry = 0;
    
    while (retry <= maxRetries) {
        try {
            if (retry !== 0) {
                console.warn("retrying", retry);
                onRetry(retry);
            }

            return await connectToDevice(bleDevice);
        } catch (e) {
            console.error("Connection error", e);
            retry ++;
        }
    }

    console.error("Max connect retries reached");
    throw new Error("Too many connect retries");
}

async function connectToDevice(device, onDisconnectFn = () => {}) {
    const server = await device.gatt.connect();
    device.ongattserverdisconnected = onDisconnectFn;
    return server;
}

async function getPrimaryService(server, serviceUuid) {
    return await server.getPrimaryService(serviceUuid);
}