export function InfoDocument({
    icon,
    fileUrl,
    name = "document",
} = {}) {
    return {
        icon,
        fileUrl,
        name,
    };
}