import { ServiceHelper2 } from "../_globalHelpers";
import { BUDGETS_API_URLS } from "../_constants/url.constants";

export const ClientsService = {
    listClients,
    getClient,
    createClient,
    updateClient,
    deleteClient,
};

async function getClient(uid) {
    return await ServiceHelper2.getRequest(
        BUDGETS_API_URLS.CLIENTS + "/" + uid,
    );
}

async function listClients() {
    const data = await ServiceHelper2.getRequest(
        BUDGETS_API_URLS.CLIENTS,
    );

    return data || [];
}

async function createClient({
    name,
    address,
    phone,
    email,
    cif,
}) {
    return await ServiceHelper2.postRequest(
        BUDGETS_API_URLS.CLIENTS,
        {
            name,
            address,
            phone,
            email,
            cif,
        },
    );
}

async function updateClient({
    uid,
    name,
    address,
    phone,
    email,
    cif,
}) {
    return await ServiceHelper2.putRequest(
        BUDGETS_API_URLS.CLIENTS + "/" + uid,
        {
            name,
            address,
            phone,
            email,
            cif,
        },
    );
}

async function deleteClient(uid) {
    await ServiceHelper2.deleteRequest(
        BUDGETS_API_URLS.CLIENTS + "/" + uid,
    );
}