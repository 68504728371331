import { i18n } from "../_translations/i18n";

export const ROOMS_ACTION_TYPES = {
    GET_ROOMS: "GET_ROOMS",
    CREATE_ROOM: "CREATE_ROOM",
    DELETE_ROOM: "DELETE_ROOM",
    UPDATE_ROOM: "UPDATE_ROOM",
}

export const ROOM_TYPES = {
    NO_TYPE:        0,
    BEDROOM:        1,
    KIDS_BEDROOM:   2,
    KITCHEN:        3,
    HALL:           4,
    LIVING_ROOM:    5,
    TERRACE:        6,
    BATHROOM:       7,
    CORRIDOR:       8,
    BABY_ROOM:      9,
    GARAGE:         10,
    OFFICE:         11,
    LAUNDRY:        12,
    GARDEN:         13,
    WHAREHOUSE:     14,
    WASHROOM:       15,
    DINNING_ROOM:   16,
    SWIMMING_POOL:  17,
    TENNIS_COURT:   18,
    GYM:            19,
    SPORTS_AREA:    20,
    COMMON_AREAS:   21,
};

export const ROOM_TYPES_NAMES = {
    [ROOM_TYPES.NO_TYPE]: i18n.t("general"),
    [ROOM_TYPES.BEDROOM]: i18n.t("room_bedroom"),
    [ROOM_TYPES.KIDS_BEDROOM]: i18n.t("room_kids_bedroom"),
    [ROOM_TYPES.KITCHEN]: i18n.t("room_kitchen"),
    [ROOM_TYPES.HALL]: i18n.t("room_hall"),
    [ROOM_TYPES.LIVING_ROOM]: i18n.t("room_living_room"),
    [ROOM_TYPES.TERRACE]: i18n.t("room_terrace"),
    [ROOM_TYPES.BATHROOM]: i18n.t("room_bathroom"),
    [ROOM_TYPES.CORRIDOR]: i18n.t("room_corridor"),
    [ROOM_TYPES.BABY_ROOM]: i18n.t("room_baby_room"),
    [ROOM_TYPES.GARAGE]: i18n.t("room_garage"),
    [ROOM_TYPES.OFFICE]: i18n.t("room_office"),
    [ROOM_TYPES.LAUNDRY]: i18n.t("room_laundry"),
    [ROOM_TYPES.GARDEN]: i18n.t("room_garden"),
    [ROOM_TYPES.WHAREHOUSE]: i18n.t("room_warehouse"),
    [ROOM_TYPES.WASHROOM]: i18n.t("room_toilet"),
    [ROOM_TYPES.DINNING_ROOM]: i18n.t("room_dining_room"),
    [ROOM_TYPES.SWIMMING_POOL]: i18n.t("room_swimming_pool"),
    [ROOM_TYPES.TENNIS_COURT]: i18n.t("room_tennis_court"),
    [ROOM_TYPES.GYM]: i18n.t("room_gym"),
    [ROOM_TYPES.SPORTS_AREA]: i18n.t("room_sports_area"),
    [ROOM_TYPES.COMMON_AREAS]: i18n.t("room_common_areas"),
};

export const ROOM_ERRORS = {
    ROOM_ALREADY_IN_GROUP: '675' 
}