import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContentSpace } from "../_components";

import { AuditDetails } from "./AuditDetails";
import { AuditMainPage } from "./AuditMainPage";
import { Selectors } from "../_reducers/app.reducer";
import { auditLogsActions } from "../_actions/auditLogs.actions";
import { locationActions } from "../_actions";

export function AuditLogsModule() {

    //LIBRARIES
    const dispatch = useDispatch();
    
    //GLOBAL STATE
    const selectedUid = useSelector(Selectors.getAuditLogsSelectedUid);
    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    //INITIALIZATION
    useEffect(() => {
        dispatch(locationActions.getLocationUsers(locationUid));
    }, [dispatch, locationUid]);

    //ACTION HANDLERS
    const handleGoBack = useCallback(() => 
        dispatch(auditLogsActions.selectLog(null))
    , [dispatch]);
    
    //VIEW
    return (
        <ContentSpace
            mainContent={<AuditMainPage />}
            detailedContent={<AuditDetails />}
            isDetailedFolioEnabled={!!selectedUid}
            onGoBack={handleGoBack}
        />
    );
}