export enum PRODUCT_TYPE {
    UNLIMITED_TIME = 1,
    LIMITED_TIME = 2,
    PROTECT_SUBSCRIPTION = 3
}

export interface ProductServer {
    name: string;
    description: string;
    price: number; // euros
    stripe_price_id: string;
    type: PRODUCT_TYPE
}

export interface Product {
    name: string;
    description: string;
    price: number; // euros
    stripePriceId: string;
    type: PRODUCT_TYPE
}