import { FILTER_ACTION_TYPES, deviceFilterModes } from '../_constants';
import { DeviceHelper } from '../_helpers';

const initialState = {
    isAllCategoriesSelected: true,
    categories: [],
    filterMode: deviceFilterModes.DEVICES_TYPES,

    deviceTypes: [],
    deviceCategories: [],
};


export function filter(state = initialState, action) {

    if (!state.hydrated) {
        state = {
            ...initialState,
            ...state,
            hydrated: true,
        };
    }

    switch (action.type) {
        case FILTER_ACTION_TYPES.CHANGE_FILTER_MODE:
            return { ...state,
                filterMode: action.newFilterMode,
                categories: action.categories.map(({ id, name, devicesAmount, type }) =>
                    ({
                        active: false,
                        id,
                        name,
                        devicesAmount, 
                        type
                    })
                ),
            };

        case FILTER_ACTION_TYPES.TOGGLE_CATEGORY:
            if (state.isAllCategoriesSelected) {
                return { ...state,
                    isAllCategoriesSelected: false,
                    categories: state.categories.map(category => 
                        category.id === action.filterCategoryId
                            ? { ...category, active: true }
                            : { ...category, active: false }
                    ),
                };
            }

            return { ...state,
                categories: state.categories.map(category => 
                    category.id === action.filterCategoryId
                        ? { ...category, active: !category.active }
                        : category
                ),
            };

        case FILTER_ACTION_TYPES.SELECT_ALL_CATEGORIES:
            return { ...state,
                isAllCategoriesSelected: true,
                categories: state.categories.map(category => 
                    ({ ...category,
                        active: true,
                    })
                ),
            };

        case FILTER_ACTION_TYPES.CREATE_FILTER_CATEGORIES: {
            return { ...state,
                deviceCategories: DeviceHelper.getAllFilterCategoriesFromDevices(
                    action.devices,
                    deviceFilterModes.CATEGORIES,
                ),
                deviceTypes: DeviceHelper.getAllFilterCategoriesFromDevices(
                    action.devices,
                    deviceFilterModes.DEVICES_TYPES,
                ),
            };

            
        }

        default:
            return state;
    }
}