import { InvitationsHelper, TimeHelper } from '../../_helpers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { globalActions, invitationsActions } from '../../_actions';
import { CaptionText, ReadOnlyDataRow, SimpleToggle } from '../../_components';
import { IsoRangeDatePicker } from '../../_components/IsoRangeDatePicker';
import { EXAMPLE_TIME_SLOTS, GLOBAL_POPUPS, INVITATION_PERIODS, LOCATION_PERMISSIONS } from '../../_constants';
import { Invitation, WeekDaysSlots } from '../../_models/Invitation';
import { TimeSlotsSelector } from '../TimeSlotsSelector';

type InvitationTimeScheduleProps = {
    invitation: any; // To be typed asap
    timezone: string;
}

export function InvitationTimeSchedule({
    invitation = Invitation(),
    timezone,
}: InvitationTimeScheduleProps) {

    
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    
    const isUnlimited = useMemo(() => {
        return InvitationsHelper.isUnlimited(invitation)
    }, [invitation])

    const timeSlotsVisible = useMemo(() => {
        if (invitation.invitationGroupUid !== null) return false;
        return InvitationsHelper.getTimeConfigEditable(invitation).timeSlots;
    }, [invitation]);
    
    const seeTimeSlotsWeekdays = useMemo(() => timeSlotsVisible && invitation.timeSlots, [invitation.timeSlots, timeSlotsVisible]);

    const dateRangeVisible = useMemo(() => {
        return InvitationsHelper.getTimeConfigVisible(invitation).dateRange;
    }, [invitation]);

    const dateRangeEditable = useMemo(() => {
        return InvitationsHelper.getTimeConfigEditable(invitation).dateRange;
    }, [invitation]);

    const isTimeRangeOpen = useMemo(
        () => !isUnlimited,
        [isUnlimited]
    );

    const isTimeSlotsOpen = useMemo(
        () => !!invitation.timeSlots,
        [invitation.timeSlots],
    );

    return (
        <div className="d-grid">
            <div>
                <h3 className="text-left">{t("common.when")}</h3>
                {
                    <div className="c-bd-primary c-bg-grey-250 c-rounded-big mt-2 pb-1 p-2">
                        {!dateRangeVisible && !timeSlotsVisible && (
                            <div className="c-primary text-center">
                                {t("invitations.always")}
                            </div>
                        )}
                        {dateRangeVisible && (
                            <>
                                <ReadOnlyDataRow
                                    className="mt-1"
                                    hasSeparators={false}
                                    name={
                                        <span className='d-flex align-items-center'>
                                            {t(
                                                "mod_invitations_set_date_range",
                                            )}
                                            {isUnlimited && (
                                                <CaptionText style={{ position: 'relative', top: 0.5 }} className='ml-2'>
                                                     {t("global_forever")}
                                                </CaptionText>
                                            )}
                                        </span>
                                    }
                                    data={
                                        <SimpleToggle
                                            size="SMALL"
                                            readOnly={!dateRangeEditable}
                                            handleChange={({
                                                detail: { value: opened },
                                            }) => {
                                                onSelectStartEndDate(opened);
                                            }}
                                            checked={isTimeRangeOpen}
                                        />
                                    }
                                />
                                {isTimeRangeOpen &&
                                    dateRangeSelector(
                                        timezone,
                                        invitation,
                                        dateRangeEditable,
                                    )}
                            </>
                        )}
                        {timeSlotsVisible && (
                            <>
                                <ReadOnlyDataRow
                                    className="mt-1"
                                    hasSeparators={false}
                                    name={t("mod_invitations_set_time_slots")}
                                    data={
                                        <SimpleToggle
                                            handleChange={({
                                                detail: { value: active },
                                            }) => {
                                                active
                                                    ? populateTimeSlotsOnInvitation()
                                                    : deleteAllTimeSlotsOnInvitation();
                                            }}
                                            size="SMALL"
                                            checked={isTimeSlotsOpen}
                                        />
                                    }
                                />
                                {seeTimeSlotsWeekdays && (
                                    <div className="mt-2">
                                        <TimeSlotsSelector
                                            onChange={onChangeTimeSlot}
                                            weekDaysTimeSlots={
                                                invitation.timeSlots! as WeekDaysSlots
                                            }
                                            isNew={invitation.newInvitation}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                }
            </div>
        </div>
    );

    function deleteAllTimeSlotsOnInvitation() {
        dispatch(
            invitation.newInvitation
                ? invitationsActions.deleteAllWeekdaysOnNew()
                : invitationsActions.deleteAllWeekdaysOnSelected(),
        );
    }

    function populateTimeSlotsOnInvitation() {
        dispatch(
            invitation.newInvitation
                ? invitationsActions.updateWeekdayTimeSlotsOnNew(
                      EXAMPLE_TIME_SLOTS,
                  )
                : invitationsActions.updateWeekdayTimeSlotsOnSelected(
                      EXAMPLE_TIME_SLOTS,
                  ),
        );
    }

    function dateRangeSelector(timezone, invitation = Invitation(), editable) {
        const { begin, end } = invitation;
        return (
            <div className="mt-1">
                <IsoRangeDatePicker
                    onChangeStart={handleStartDateChange}
                    onChangeEnd={handleEndDateChange}
                    timezone={timezone}
                    begin={begin}
                    end={end}
                    readOnly={!editable}
                    minDate={new Date()}
                />
            </div>
        );
    }

    function handleStartDateChange(localDateString) {
        if (localDateString === invitation.begin || localDateString === null)
            return;
        dispatch(
            invitationsActions.changeBeginTime(
                localDateString,
                invitation.newInvitation,
            ),
        );
    }

    function handleEndDateChange(localDateString) {
        if (localDateString === invitation.end)
            return;
        dispatch(
            invitationsActions.changeEndTime(
                localDateString,
                invitation.newInvitation,
            ),
        );
    }

    function onChangeTimeSlot(weekDays: WeekDaysSlots) {
        const dispatcher = invitation.newInvitation
            ? invitationsActions.updateWeekdayTimeSlotsOnNew
            : invitationsActions.updateWeekdayTimeSlotsOnSelected;
        dispatch(dispatcher(weekDays));
    }

    function onSelectStartEndDate(isSelected: boolean) {

        const deleteStartEndDate = () => {
            const dispatcher = invitation.newInvitation
                ? invitationsActions.updateNewLocally
                : invitationsActions.updateSelectedLocally;
            const permissionType = invitation.permissionType;

            dispatch(
                dispatcher({
                    periodType:
                        permissionType === LOCATION_PERMISSIONS.INSTALLER
                            ? INVITATION_PERIODS.TEMPORARY
                            : INVITATION_PERIODS.ALWAYS,
                    begin: isSelected ? TimeHelper.getTodayIsoString() : null,
                    end: null,
                    createPublicUrl: false,
                    publicUrl: null,
                }),
            );
        };

        if (invitation.createPublicUrl || invitation.publicUrl) {
            dispatch(
                globalActions.showPopUp(GLOBAL_POPUPS.GENERIC_NOTIFICATION, {
                    title: t('global_alert'),
                    body: t(
                        "mod_invitations_remove_start_end_date_on_invitation",
                    ),
                }),
            );
        } else {
            deleteStartEndDate();
        }
    }

}