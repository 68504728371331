//GENERIC IDS
export const GENERIC_IDS = {
    DEVICES:        "DEVICES",
    INVITATIONS:    "INVITATIONS",
    SUBSCRIPTIONS:  "SUBSCRIPTIONS",
    RESERVATIONS:   "RESERVATIONS",
    PROTECT:        "PROTECT",
    MOBILES:        "MOBILES",
    CLOCKINS:       "CLOCKINS",
    CLOCKINS_USERS: "CLOCKINS_USERS",
    WORKDAYS:       "WORKDAYS",
    AUDIT:          "AUDIT",
    USER_LOGS:      "USER_LOGS",
    DEVICE_LOGS:    "DEVICE_LOGS",
    MODULES:        "MODULES",
    USERS_STATUS:   "USERS_STATUS",

    PENDING_INVITATIONS: "PENDING_INVITATIONS",
};