import { i18n } from "../_translations/i18n";
import { API_ERROR_CODES } from "../_constants";

export const EverouErrorMapper = {
    getMessageFromCode,
};

function getMessageFromCode(code, info) {
    switch (code) {

        //100 CODES - GENERAL
        case 101:
            return `GENERIC ERROR ${code}: ${JSON.stringify(info)}`; 

        case 103:
            return i18n.t("errors.invalidPublicApikey");

        case 104:
            return i18n.t("errors.contentTypeNotAllowed");

        case 105:
            return i18n.t("errors.methodNotAllowed");

        case 106:
            return i18n.t("errors.notAcceptable");

        case 107:
            return i18n.t("errors.parseError");

        case 108:
            return i18n.t("errors.unsupportedMediaType");
        
        case 109:
            return i18n.t("errors.invalidPassword");

        case 110:
            return i18n.t("errors.invalidtimezone");

        case 111:
            return i18n.t("errors.invalidApiVersion");
        
        case 112:
            return i18n.t("errors.doNotHavePermission");

        case 113:
            return i18n.t("errors.loginCodeExpiredOrMaximumAttempos");

        case 114:
            return i18n.t("error_114_data_already_exists");

        // 200 CODES - DOES NOT EXIST
        case 201:
            return i18n.t("errors.locationDoesNotExist");

        case 202:
            return i18n.t("error_202_invalid_room");

        case 203:
            return i18n.t("errors.deviceDoesNotExist");

        case 204:
            return i18n.t("errors.brainDoesNotExist");

        case API_ERROR_CODES._205_USER_NOT_EXIST:
            return i18n.t("login.usernameOrPasswordIsIncorrectErr");

        case 206:
            return i18n.t("triggers.triggerDoesNotExistErr");

        case 207:
            return i18n.t("errors.invitationDoesNotExist");

        case 208:
            return i18n.t("errors.activeBrainVideoDoesNotExist");

        case 209:
            return i18n.t("errors.mobileDoesNotExist");

        case 210:
            return i18n.t("errors.deviceVersionDoesNotExist");

        case 211:
            return i18n.t("errors.securityZoneDoesNotExist");

        case 212:
            return i18n.t("errors.pushUidDoesNotExist");

        case 213:
            return i18n.t("errors.widgetGroupDoesNotExist");

        case 214:
            return i18n.t("errors.widgetDoesNotExist");
        
        case 215:
            return i18n.t("error_215_invalid_subscription");

        case 216:
            return i18n.t("subscriptions.cannotModifySuperguestInvitations");

        case 217:
            return i18n.t("subscriptions.cannotCreateInvitationsForSmallPeriod");

        case 218:
            return i18n.t("subscriptions.positionNotConfigured");

        case 219:
            return i18n.t("error_219_device_already_validated");

        case 220:
            return i18n.t("error_220_device_kit_not_exist");

        case 221:
            return i18n.t("error_221_payment_method_not_exist");

        case 222:
            return i18n.t("error_222_nfc_card_does_not_exist");
            
        case 223:
            return i18n.t("error_223_developer_account_does_not_exist");
            
        case 224:
            return i18n.t("error_224_developer_accounttoken_does_not_exist");

        // 500 CODES - OTHERS
        case 501:
            return i18n.t("errors.emailAlreadyExists");

        case 503:
            return i18n.t("errors.cannotRemoveRoomWithDevices");

        case 507:
            return i18n.t("errors.incorrectParametersForDeviceType");

        case 508:
            return i18n.t("errors.fileTooLarge");

        case 509:
            return i18n.t("errors.emailAlreadyChecked");

        case 510:
            return i18n.t("errors.invalidLogTypes");

        case 511:
            return i18n.t("errors.duplicateApikeyErr");

        case 512:
            return i18n.t("errors.ownerPermissionRequired");

        case 513:
            return i18n.t("errors.incorrectBluetoothKey");  

        case 514:
            return i18n.t("errors.noFileSupplied");

        case 515:
            return i18n.t("errors.duplicateRoomName");

        case 516:
            return i18n.t("errors.streamUsageExceeded");

        case 517:
            return i18n.t("errors.cannotUpdateDeviceLocationWithoutLinking");

        case 518:
            return i18n.t("errors.deviceTypeUpdateNotAllowed");

        case 519:
            return i18n.t("errors.deviceActionNotAllowed");

        case 520:
            return i18n.t("errors.triggerRestrictionError");

        case 521:
            return i18n.t("errors.devicesPerLocationLimitReached");

        case 522:
            return i18n.t("errors.noActiveBrainAvailable");

        case 523:
            return i18n.t("errors.duplicateOwnerName");

        case 524:
            return i18n.t("errors.brainAlreadyUpdated");

        case 525:
            return i18n.t("errors.deviceAlreadyLinkedInOtherLocation");
            
        case 526:
            return i18n.t("errors.cannotDeleteLastOwnerLocation");

        case 528:
            return i18n.t("errors.buttonTypeNotAllowed");

        case 529:
            return i18n.t("errors.beaconNotAllowed");

        case 530:
            return i18n.t("error_530_you_already_have_an_invitation");

        case 531:
            return i18n.t("errors.passwordTooSimple");

        case 532:
            return i18n.t("errors.cannotRemoveUserWithLocations");

        case 533:
            return i18n.t("errors.triggerLimitReached");

        case 534:
            return i18n.t("errors.deviceRemoteDisabled");

        case 535:
            return i18n.t("errors.emailNotChecked");

        case 536:
            return i18n.t("errors.invitationTransferLocationAlreadyExists");

        case 537:
            return i18n.t("errors.videoRecordingLimitReached");

        case 538:
            return i18n.t("errors.videoViewsLimitReached");

        case 539:
            return i18n.t("errors.anotherTriggerWithSameDeviceExists");

        case 540:
            return i18n.t("errors.triggerCausesCycle");

        case 541:
            return i18n.t("errors.deviceTypeCannotBeInhibition");

        case 542:
            return i18n.t("errors.deviceNotInBrainLocation");

        case 543:
            return i18n.t("errors.kitUidCannotBeEmpty");

        case 544:
            return i18n.t("errors.kitBrainUidCannotBeEmpty");

        case 546:
            return i18n.t("errors.deviceVersionNotCompatibleWithKit");

        case 547:
            return i18n.t("errors.deviceNotSold");

        case 548:
            return i18n.t("errors.locationHasDevices");

        case 550:
            return i18n.t("errors.triggerCannotBeEdited");

        case 551:
            return i18n.t("errors.deviceValidatorInDifferentLocation");

        case 552:   
            return i18n.t("errors.deviceValidatorIncorrectType");

        case 553:   
            return i18n.t("errors.onlySuperusersCanAcceptInvitation");

        case 554:   
            return i18n.t("errors.invitationHasNoEmail");

        case 555:   
            return i18n.t("errors.invitationStateIsNotRequested");

        case 556:   
            return i18n.t("errors.maximumUsesReachedByGarageDoor");

        case 557:   
            return i18n.t("errors.subscriptionNotActivated");

        case 558:
            return i18n.t("errors.subscriptionCancelled");

        case 559:
            return i18n.t("errors.subscriptionExpired");

        case 560:
            return i18n.t("errors.incorrectParametersForSubscriptionInformation");

        case 561:
            return i18n.t("errors.onlySATCanAcceptInvitation");

        case 562:
            return i18n.t("subscriptions.subscriptionForDeviceExistsErr");

        case 563:
            return i18n.t("errors.unableToActivateSubsInDifferentLocation");   


        case 564:
            return i18n.t("errors.notAllowedIfNotAdmin");   

        case 565:
            return i18n.t("errors.subinvitationsExceeded");

        case 566:
            return i18n.t("errors.emailsAlreadyInvited");


        case 567:
            return i18n.t("reservations.roomAlreadyConfiguredErr");

        case 568:
            return i18n.t("reservations.limitMinutesSmallerThanBlockErr");

        case 569:
            return i18n.t("errors.beginAfterThanEndErr");

        case 570:
            return i18n.t("reservations.blockMinutesBiggerTanConfigurationHoursErr");

        case 571:
            return i18n.t("reservations.limitMinutesBiggerThanConfigurationHoursErr");

        case 572:
            return i18n.t("reservations.reservationMustHaveAWeekDay");

        case 573:
            return i18n.t("errors.reservationNotComplyWithSchedule");

        case 574:
            return i18n.t("errors.unusedReservation");

        case 575:
            return i18n.t("errors.reservationDayNotAllowedErr");

        case 576:
            return i18n.t("errors.reservationDoesNotRespectBlockSize");

        case 577:
            return i18n.t("errors.reservationExceedsTimeLimit");

        case 578:
            return i18n.t("errors.reservationOverlapErr");
    
        case 579:
            return i18n.t("errors.reservationAnticipationTooLargeErr");

        case 580:
            return i18n.t("errors.reservationDoesNotExist");
        
        case 582:
            return i18n.t("error_582_already_inside_barriers");

        case 583:
            return i18n.t("error_583_already_outside_barriers");  

        case 584:
            return i18n.t("errors.reservationDoesNotMeetSlotTime");     

        case 585:
            return i18n.t("errors.alreadyHaveReservationInOtherRoomForThisTimeSlot");

        case 587:
            return i18n.t("error_587_subinvitations_without_count");

        case 588:
            return i18n.t("error_588_count_without_subinvitations_enabled");

        case 589:
            return i18n.t("error_589_invalid_invitation_group");

        case 590:
            return i18n.t("error_590_invalid_card");

        case 591:
            return i18n.t("error_591_used_card");

        case 592:
            return i18n.t("error_592_card_incompatible_with_group");

        case 593:
            return i18n.t("error_593_card_not_configured");

        case 594:
            return i18n.t("error_594_cannot_edit_device_room");

        case 595:
            return i18n.t("error_595_invalid_credentials_for_card");

        case 597:
            return i18n.t("error_597_invalid_login_code");
            
        case 598:
            return i18n.t("error_598_creating_trigger_without_brain");

        case 599:
            return i18n.t("error_599_invitations_with_subinvitations_cannot_have_duration");

        case 600:
            return i18n.t("error_600_begin_greater_than_end");

        case 601:
            return i18n.t("error_601_ble_version_not_available");

        case 602:
            return i18n.t("error_602_cannot_change_subinvitation_permission");

        case 603:
            return i18n.t("error_603_wrong_code");
        
        case 604:
            return i18n.t("error_604_user_without_locations");

        case 605:
            return i18n.t("error_605_recurrent_user_out_of_allowed_timeframe");

        case 606:
            return i18n.t("error_606_subscription_already_used_as_extension");
            
        case 607:
            return i18n.t("error_607_no_permission_edit_card_config");

        case 608:
            return i18n.t("error_608_reservation_config_time_wrong");

        case 609:
            return i18n.t("error_609_reservation_config_begin_end_required_if_not_all_day");

        case 611:
            return i18n.t("error_611_device_kit_preconfigured");

        case 612:
            return i18n.t("error_612_reservation_day_excluded");

        case 613:
            return i18n.t("error_613_subscription_transfer_to_different_device_type_disallowed");

        case 614:
            return i18n.t("error_614_clockin_does_not_exist");

        case 615:
            return i18n.t("error_615_clockin_modification_to_another_day_forbidden");
      
        case 616:
            return i18n.t("error_616_clockins_no_reset_time_configured");
  
        case 617:
            return i18n.t("error_617_subscription_activation_requires_uid");

        case 618:
            return i18n.t("error_618_subscription_only_accepts_locations");
   
        case 619:
            return i18n.t("error_619_subscription_only_accepts_devices");

        case 620:
            return i18n.t("error_620_another_clockin_same_time_exists");

        case 621:
            return i18n.t("error_621_invalid_or_expired_registration_code");

        case 622:
            return i18n.t("error_622_account_email_not_verified");
        
        case 623:
            return i18n.t("error_623_someone_with_invitation");

        case 624:
            return i18n.t("error_624_error_processing_payment");

        case 625:
            return i18n.t("error_625_automatic_payment_already_enabled");
        
        case 626:
            return i18n.t("error_626_user_has_no_authorized_payment_method");

        case 627:
            return i18n.t("error_627_error_cancelling_subscription");

        case 628:
            return i18n.t("error_628_location_has_active_subscriptions");

        case 629:
            return i18n.t("error_629_room_type_missing_rooms");

        case 630:
            return i18n.t("error_630_admin_invitation_cannot_have_subinvitations");
            
        case 631:
            return i18n.t("error_631_not_allowed_to_customize_location");

        case 632:
            return i18n.t("error_632_not_allowed_to_customize_room");

        case 633:
            return i18n.t("error_633_no_customization_for_subdomain");
    
        case 634:
            return i18n.t("error_634_error_retrieving_payment_methods");
    
        case 635:
            return i18n.t("error_635_no_configured_payment_method");

        case 636:
            return i18n.t("error_636_max_payment_methods_reached");

        case 637:
            return i18n.t("error_637_error_creating_intent");

        case 638:
            return i18n.t("error_638_invalid_intent_id");

        case 639:
            return i18n.t("error_639_incorrect_ble_packet");

        case 640:
            return i18n.t("error_640_incorrect_ble_origin_key");

        case 641:
            return i18n.t("error_641_future_clockins_not_allowed");

        case 642:
            return i18n.t("error_642_max_invitations_reached");
        
        case 643:
            return i18n.t("error_643_not_allowed_create_invitation");

        case 644:
            return i18n.t("error_644_cannot_add_unlimited_card_to_limited_group");

        case 645:
            return i18n.t("error_645_group_duration_exceeds_card_configuration");

        case 646:
            return i18n.t("error_646_invalid_card_type_for_group");

        case 647:
            return i18n.t("error_647_cannot_clockin_remotely");

        case 648:
            return i18n.t("error_648_nfc_card_already_validated");

        case 649:
            return i18n.t("error_649_no_internet_connection");

        case 650:
            return i18n.t("error_650_order_not_exist");

        case 652:
            return i18n.t("error_652_product_not");

        case 653:
            return i18n.t("error_653_cannot_generate_metered_bill");

        case 654:
            return i18n.t("error_654_cannot_get_subscription_items");

        case 655:
            return i18n.t("error_655_cannot_add_item_to_subscription");

        case 656:
            return i18n.t("error_656_cannot_add_usage_to_subscription");

        case 657:
            return i18n.t("error_657_developer_already_associated");

        case 658:
            return i18n.t("error_658_no_developer_account");
            
        case 659:
            return i18n.t("error_659_limited_time_group_with_subinvitations");

        case 660:
            return i18n.t("error_660_limited_time_group_without_duration");

        case 661:
            return i18n.t("error_661_group_has_invitations");

        case 662:
            return i18n.t("error_662_location_has_clockins");

        case 663:
            return i18n.t("error_663_unspecified_export_format");

        case 664:
            return i18n.t("error_664_maximum_range_of_download_is_12_months");

        case 665:
            return i18n.t("error_665_reservation_limit_reached");

        case 666:
            return i18n.t("error_666_clockins_made_in_invalid_minimum_time_period");

        case 667:
            return i18n.t("error_667_devices_dont_have_same_type");

        case 668:
            return i18n.t("error_668_invitation_duration_exceeds_max_allowed_by_invitation_group");

        case 696:
            return i18n.t("error_696_invalid_kross_booking_authentification_parameters");

        default:
            return undefined;
    }
}