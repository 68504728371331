import React, { useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';

import { useScript } from "../_hooks";
import { APPLE_CLIENT_ID } from "../_constants/url.constants";
import { HistoryHelper } from "../_helpers/history";
import { Icons } from "../_assets";
import { SimpleCapsuleButton } from "../../Common/_components/SimpleCapsuleButton";

export function AppleLoginModule({
    handleLoginSuccess = (token, email) => {},
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const appleAuthLibraryUrl = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    const [isLoginReady, setIsLoginReady] = useState(false);

    //INITIALIZATION
    const onLoad = useCallback(
        () => setIsLoginReady(true),
        [setIsLoginReady],
    );
    
    useScript(appleAuthLibraryUrl, onLoad);

    //ACTION HANDLERS
    async function handleLogin() {
        try {
            if (!isLoginReady)
                return;

            const AppleID = window.AppleID;
            if (!AppleID) {
                console.warn("NO APPLE ID");
                return;
            }

            AppleID.auth.init({
                clientId: APPLE_CLIENT_ID,
                usePopup: true,
                redirectURI: HistoryHelper.getCurrentUrlFullNoParams(),
                scope: "email name",
            });
            const response = await AppleID.auth.signIn();
            const appleAuthObj = AppleAuthObj(response);
            handleLoginSuccess(appleAuthObj.id_token, appleAuthObj.email);
        } catch (e) {
            console.error("APPLE LOGIN ERROR", e);
        }

        function AppleAuthObj(serverAuthObj) {
            return {
                email: serverAuthObj.user && serverAuthObj.user.email,
                id_token: serverAuthObj.authorization && serverAuthObj.authorization.id_token,
            };
        }
    }

    //VIEW
    return (
        <SimpleCapsuleButton onClick={handleLogin}>
            <div className="d-flex align-items-center">
                <img
                    src={Icons.apple}
                    alt="gloogle_icon"
                    className="c-img-1rem mb-1"
                />
                <div className="ml-2"/>
                <div className="mt-auto text-dark c-font-weight-medium ">
                    {t("login_with_apple")}
                </div>
            </div>

        </SimpleCapsuleButton>
    );
}