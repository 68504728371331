import React from "react";

export function SimpleDataBlock({ title, value }) {
    return (
        <div>
            <div className="
                my-1
            ">
                <span className="
                    text-uppercase
                    font-weight-bold
                ">
                    {title + ":"}
                </span>
                <span className="ml-2">
                    {value}
                </span>
            </div>
            <hr className="m-0"/>
        </div>
    );
}