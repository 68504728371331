import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReservationConfig } from "../_models/ReservationConfig";
import { TimeHelper } from "../_helpers";
import { MainPageWithButtons } from "../_components";
import { reservationActions } from "../_actions/reservation.actions";
import { WeekdaysModel } from "../_models/WeekdaysModel";
import { TimeStringHelper } from "../_helpers/TimeStringHelper";
import { slotBuilder } from "./SlotBuilder";
import { ReservationSlotModel } from "./ReservationSlotModel";
import { appPermissions } from "../_constants/permissions.constants";
import { IsoStringDatePicker } from "../_components/IsoStringDatePicker";
import { CapsuleButton } from "../_components/CapsuleButton";
import { Selectors } from "../_reducers/app.reducer";
import { User } from "../_models/User";
import { SingleSlot } from "./SingleSlot";

import prevIcon from "../_assets/appIcons/previous-icon.svg";
import nextIcon from "../_assets/appIcons/next-icon-24px.svg";
import { ReservationsHelper } from "../_helpers/ReservationsHelper";

const testIDs = {
    scheduleView: "reservations-schedule-view",
    settingsButton: "reservations-schedule-settings-button",
    nextDayButton: "reservations-schedule-next-day-button",
    prevDayButton: "reservations-schedule-prev-day-button",
    dateLabel: "reservations-schedule-date-label",
}

ReservationSchedule.testIDs = testIDs;

export function ReservationSchedule() {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [dateRange, setDateRange] = useState([]);
    const [slots, setSlots] = useState([]);

    //STORE DEPENDENCIES
    let reservationConfig   = ReservationConfig();
    reservationConfig       = useSelector(Selectors.getReservationSelected);

    const reservations      = useSelector(Selectors.getReservations);

    const permission        = useSelector(Selectors.getPermissionSelectedLocation);
    const timezone          = useSelector(Selectors.getTimezone);

    const selectedDate      = useSelector(Selectors.getReservationSelectedScheduleDate);

    let user        = User();
    user            = useSelector(Selectors.getEverouUser);
    const userUid   = user.uid;

    //LOCAL VARS
    const alreadyHasReservation = reservationConfig.pendingReservation !== null;
    const canEditReservations = appPermissions.canUserConfigureReservations(permission);


    //STATE UPDATES
    useEffect(() => {
        getReservations();
        setDateRange(getDateRange(canEditReservations));

        ////
        function getDateRange(canEditReservations) {
            const startDate = canEditReservations
                ? TimeHelper.getOneMonthAgoIsoString()
                : TimeHelper.getTodayIsoString()
            ;
    
            return [
                startDate,
                TimeHelper.getTomorrowIsoString(),
            ];
        }

        function getReservations() {
            dispatch(reservationActions.getReservations(
                reservationConfig.roomUid,
                selectedDate,
            ));
        }
    },
    [
        selectedDate,

        //STATIC DEPS
        dispatch,
        reservationConfig.roomUid,
        canEditReservations,
    ]);

    useEffect(() => {
        setSlots(
            slotBuilder(
                {
                    reservationConfig,
                    reservations,
                    timezone,
                    selectedDate,
                    userUid,
                }
            ),
        );
    },
    [
        reservations,
        reservationConfig,
        selectedDate,
        
        //STATIC DEPS
        timezone,
        userUid,
    ]);


    //VIEW
    return (
        <MainPageWithButtons
            dataTestId={testIDs.scheduleView}
            size={'SMALL'}
            pageTitle={t("common.schedule")}
            content={content()}
            buttons={[
                <CapsuleButton
                    testId={testIDs.settingsButton}
                    text={t("settings.settings")}
                    onClick={handleGoToSettings}
                    isVisible={canEditReservations}
                />
            ]}
        />
    );

    function content() {
        return (
            <div className="d-flex flex-column h-100">
                <div className="mt-2"/>
                <DateSelector
                    {...{
                        canEditReservations,
    
                        timezone,
                        selectedDate,
                        dateRange,
                    
                        handlePrevDay,
                        handleNextDay,
                        handleUpdateSelectedDate,
                    }}
                />
                <div className="overflow-auto h-100">
                    {schedule()}
                </div>
            </div>
        );
    }

    function schedule() {
        if (TimeHelper.isBeforeToday(selectedDate)) {
            if (slots.length === 0)
                return noReservationsForThisDate();

            return fullScheduleUi();
        }

        if(isDayExcluded(selectedDate))
            return excludedDayMessage();

        if (!isSelectedWeekdayAvailable())
            return dayNotAvailableMessage();

        return fullScheduleUi();
    }

    function isDayExcluded(isoDate) {
        return ReservationsHelper.isIsoDateExcluded(isoDate, timezone, reservationConfig);
    }

    function noReservationsForThisDate() {
        return (
            <div className="
                mt-2
                py-2
                text-center
                border
                bg-light

                c-text-capitalize-first
            ">
                {t("reservations.noReservationsWhereMadeThisDay")}
            </div>
        ); 
    }

    function isSelectedWeekdayAvailable() {
        return WeekdaysModel(reservationConfig)[
            TimeHelper.getDayWeekdayInTimezone(selectedDate, timezone)
        ];
    }

    function dayNotAvailableMessage() {
        const message = (
            <>
            <div className="c-text-capitalize-first">
                {t("common.dayOfWeekNotAvailable") + "."}
            </div>
            <div className="c-text-capitalize-first">
                {t("common.canBeBooked") + " " + setUpMessage(reservationConfig)}
            </div>
            </>
        );
        
        return genericMessage(message
        );
    }

    function excludedDayMessage() {
        return genericMessage(t("mod_reservations_excluded_day_message"));
    }

    function genericMessage(message) {
        return (
            <div className="
                mt-2
                text-center
                border
                bg-light
            ">
                {message}
            </div>
        );
    }

    function setUpMessage(configuration = ReservationConfig()) {
        return TimeStringHelper.buildReservationConfigString(
            configuration.allDay,
            configuration.begin,
            configuration.end,
            WeekdaysModel(configuration),
        );
    }

    function fullScheduleUi() {
        return (
            <div>
                <div>
                    {buildSlots(slots)}
                </div>
                <div className="mt-1"/>
                <div>
                    {t("reservations.scheduleUsesLocationTimezone")}
                </div>
            </div>
        );
    }

    function buildSlots(slots = [] || [ReservationSlotModel()]) {
        return slots.map((slot, i) =>
            <SingleSlot
                key={i}
                {...{
                    slot,
                    reservationConfig,
                    userUid,
                    canEditReservations,
                    handleCancelReservation,
                    handleViewReservations,
                    handleMakeReservation,
                    timezone,
                    alreadyHasReservation,
                }}
        />)
    }

    //STATE UPDATE HANDLERS
    function handleGoToSettings() {
        dispatch(reservationActions.selectSettingsView());
    }

    function handleNextDay() {
        handleUpdateSelectedDate(TimeHelper.getNextDayIso(selectedDate));
    }

    function handlePrevDay() {
        handleUpdateSelectedDate(TimeHelper.getPrevDayIso(selectedDate))
    }

    function handleUpdateSelectedDate(newDate) {
        if (!TimeHelper.isBetweenIncludedDates(newDate, dateRange[0], dateRange[1]))
            return;

        //setSelectedDate(newDate);
        dispatch(reservationActions.updateSelectedScheduleDate(newDate));
    }

    function handleMakeReservation(begin, end) {
        dispatch(reservationActions.makeReservation({
            begin,
            end,
            roomUid: reservationConfig.roomUid,
            userUid,
        }));
    }

    function handleCancelReservation(uid) {
        dispatch(reservationActions.cancelReservation(uid));
    }

    function handleViewReservations(slot) {
        dispatch(reservationActions.selectSlot(slot));
        dispatch(reservationActions.selectSlotManagementView());
    }
}


function DateSelector({
    canEditReservations,
    
    timezone,
    selectedDate,
    dateRange,

    handlePrevDay = () => {},
    handleNextDay = () => {},
    handleUpdateSelectedDate = () => {},
} = {}) {

    //VIEW
    return (
        <div className="
            d-flex
            justify-content-between
            align-items-center

            border

            p-1
        ">
            {prevDayArrow()}
            {selectedDateString()}
            {nextDayArrow()}
        </div>
    );

    function prevDayArrow() {
        if (TimeHelper.areSameDay(selectedDate, dateRange[0]))
            return <div/>;

        return arrowButton(prevIcon, handlePrevDay, testIDs.prevDayButton);
    }

    function nextDayArrow() {
        if (TimeHelper.areSameDay(selectedDate, dateRange[1]))
            return <div/>;

        return arrowButton(nextIcon, handleNextDay, testIDs.nextDayButton);
    }

    function selectedDateString() {
        return (
            <IsoStringDatePicker
                isoString={selectedDate}
                onChange={handleUpdateSelectedDate}
                timezone={timezone}
                customInputFn={timeSelectorInputFn}
                dateOnly={true}
                readOnly={!canEditReservations}
                minDate={dateRange[0]}
                maxDate={dateRange[1]}
            />
        );
    }

    function timeSelectorInputFn() {
        return (
            <div
                data-testid={testIDs.dateLabel}
                className={`
                    c-text-capitalize-first
                    font-weight-bold
                    ${style()}
                `}
            >
                {TimeHelper.localizeIsoStringToFormat(
                    selectedDate,
                    timezone,
                    TimeHelper.getTimeFormats().DATE_AND_WEEKDAY,
                )}
            </div>
        );

        function style() {
            if (!canEditReservations)
                return;

            return "border btn btn-primary";
        }
    }

    function arrowButton(icon, onClick, testID) {
        return (
            <img
                data-testid={testID}
                onClick={onClick}
                src={icon}
                alt="next icon"
                width="35"
                className="
                    rounded
                    c-hover-240
                    cp
                "
            />  
        );
    }
}