import React, { useMemo } from "react";

import { ModuleModel } from "../../Everou/_models";
import { UiSidebarButton } from "./UiSidebarButton";

const testIDs = {
    makeModuleTestID: moduleID => moduleID + "-sidebar-button",
};

function SidebarSectionModel({
    id,
    text = "NO TITLE",
    moduleIds = [],
    icon,
    SvgComponent,
} = {}) {
    return {
        id,
        text,
        moduleIds,
        icon,
        SvgComponent,
    };
}

SectionsSidebar.SidebarSectionModel = SidebarSectionModel;
SectionsSidebar.testIDs = testIDs;

export function SectionsSidebar({
    sections = [] || [SidebarSectionModel()],

    enabledModulesIds,
    selectedModuleId,
    handleSelectModule,
    getModuleById = () => {},
} = {}) {


    //ACTION HANDLERS
    function handleClickSection(section) {
        onClickSection(section, enabledModulesIds, handleSelectModule);
    }

    //LOCAL STATE
    const selectedSectionId = useMemo(() =>
        getSelectedSectionId(sections, selectedModuleId)
    , [sections, selectedModuleId]);

    //VIEW
    return sections
    .filter(section => isElementEnabled(section, enabledModulesIds))
    .map(uiElement);

    function isElementEnabled(element) {
        if (isSection(element))
            return isSectionEnabled(element, enabledModulesIds);
        
        return isModuleEnabled(element);
    }

    function isModuleEnabled(module = ModuleModel()) {
        return enabledModulesIds.includes(module.moduleId);
    }

    function uiElement(element) {
        if (isSection(element))
            return uiSection(element);

        let moduleModule = ModuleModel();
        moduleModule = element;
        return uiSingleModule(moduleModule.moduleId, false, true);
    }

    function uiSingleModule(moduleId, isMarked = false, isBold = false) {
        let module = ModuleModel();
        module = getModuleById(moduleId);
        return (
            <UiSidebarButton
                testID={testIDs.makeModuleTestID(moduleId)}

                key={moduleId}
                text={module.uiName}
                icon={module.icon}
                isSelected={moduleId === selectedModuleId}
                onClick={() => handleSelectModule(moduleId)}
                isMarked={isMarked}
                isBold={isBold}
                SvgComponent={module.SvgComponent}
            />
        );
    }
    
    function uiSection(section = SidebarSectionModel()) {
        const isSelected = section.id === selectedSectionId;
        const sectionEnabledModuleIds = section.moduleIds
            .filter(moduleId =>
                enabledModulesIds.includes(moduleId)
        );
        return (
            <div key={section.id}>
            <UiSidebarButton
                testID={testIDs.makeModuleTestID(section.id)}

                SvgComponent={section.SvgComponent}
                icon={section.icon}
                text={section.text}
                onClick={() => handleClickSection(section)}
                isMarked={isSelected}
                isBold
            />
            {modules()}
            </div>
        );

        function modules() {
            if (!(isSelected && section.moduleIds.length))
                return null;

            return sectionEnabledModuleIds.map(moduleId =>
                uiSingleModule(moduleId, true));
        }
    }
}

function isSectionEnabled(
    section = SidebarSectionModel(),
    enabledModules = [],
) {
    if (!section.moduleIds.length)
        return false;

    return isAnyModuleEnabled();

    function isAnyModuleEnabled() {
        return section.moduleIds.some(moduleId =>
            enabledModules.includes(moduleId)
        );
    }
}

function onClickSection(
    sidebarSection = SidebarSectionModel(),
    enabledModulesIds = [],
    selectModuleFn = moduleId => {},
) {
    const enabledSectionModuleIds = sidebarSection.moduleIds
        .filter(moduleId => enabledModulesIds.includes(moduleId))
    ;

    if (!enabledSectionModuleIds.length)
        return;

    selectModuleFn(enabledSectionModuleIds[0]);
}

function getSelectedSectionId(
    sections = [] || [SidebarSectionModel()],
    selectedModuleId,
) {
    const selectedSection = sections
    .filter(isSection)
    .find(section =>
        section.moduleIds.some(moduleId => moduleId === selectedModuleId)
    );
    return selectedSection && selectedSection.id;
}

function isSection(element) {
    let sidebarSection = SidebarSectionModel();
    sidebarSection = element;
    return !!sidebarSection.moduleIds;
}