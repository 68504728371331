import { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-scanner";
import { CapsuleButton } from "../_components/CapsuleButton";
import { globalActions } from "../_actions";
import { TextInputComponent } from "../_components";
import { EmptyGlobalPopUp } from "../_components";
import { Icons } from "../_assets";
import { useError } from "../_hooks/useError";

export function BaseInputCodeGlobalPopUp({
    inputTestID,
    buttonTestID,
    
    headerTitle,
    hint,
    inputTitle,
    placeholder,
    buttonText,
    hasMediaDeviceConnected = false,
    value = "",

    onChangeInput = () => {},
    onClickButton = () => {},
    handleClosePopUp = () => {},
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //LOCAL STATE
    const [stream, setStream] = useState(null);
    const [warnings, setWarnings] = useState([]);
    const [code, setCode] = useState('');
    const [allowedCamera, setAllowedCamera] = useState(false);
    const { errorMessage, setError } = useError();



    const activateCameraPermissions = async () => {
        let localStream;
        try {
            localStream = await navigator?.mediaDevices?.getUserMedia({ video: true });
            setStream(localStream)
        } catch (e) {
            setError(t('global_camera_inaccessible'));
            setAllowedCamera(false);
        }

        if (localStream) { setAllowedCamera(true); }
    }


    const setCodeFromScanner = useCallback((qrInput) => {
        if (!qrInput) {
            setCode("");
            onChangeInput('')
            return;
        }
        const link = qrInput?.text;
        const code = link.split("/").pop();
        onChangeInput(code);
        setCode(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleClickButton, onChangeInput]);

    useEffect(() => {
        onChangeInput(code);    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])
    
    const cameraInput = useMemo(() => {
    
        const cameraSize = { height: 320, width: 320 };
    
        return (
            <div style={cameraSize}>
                <QrReader
                    delay={0}
                    zoom={2}
                    style={{ ...cameraSize, objectFit: 'cover' }}
                    onError={(err) => console.log(err)}
                    onScan={setCodeFromScanner}
                />
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMediaDeviceConnected, code, allowedCamera, setCodeFromScanner]);

    //VIEW
    return <EmptyGlobalPopUp
        className="c-mw-22rem"
        content={content()}
    />;

    function content() {
        return (
            <div>
                {header()}
                <hr className="m-0"/>
                <div className="mt-2"/>
                {input()}
                <div className="mt-2"/>
                <div style={{ whiteSpace: 'normal', maxWidth: '320px' }} className="d-flex align-items-center mb-2">
                    {hasMediaDeviceConnected && !allowedCamera && <CapsuleButton classNameExtra="flex-shrink-0 mr-2"size="SMALL" onClick={activateCameraPermissions} icon={Icons.camera} />}
                    {hint}
                </div>
                {errorMessage && <div className="text-danger mb-2">{errorMessage}</div>}
                <hr className="m-0"/>
            </div>
        );
    }

    function header() {
        return (
            <div
                className="
                d-flex
                no-gutters
                align-items-center
            "
            >
                <div className="col"/>
                <div
                    className="
                    d-flex
                    no-gutters
                    justify-content-center
                    
                    c-new-ft-l
                    font-weight-bold
                "
                >
                    {headerTitle}
                </div>
                <div
                    onClick={handleClose}
                    className="
                        col
                        cp
                        close
                        text-right
                        align-self-start
                    "
                >
                    <span>&times;</span>
                </div>
            </div>
        );
    }



    function input() {
        return (
            <div className="c-grid c-grid--small">
                {hasMediaDeviceConnected && !code && allowedCamera && cameraInput}
                <div
                    className="
                    d-flex
                    align-items-center
                    py-2
                    "
                    >
                    <TextInputComponent
                        testID={inputTestID}
                        title={inputTitle}
                        placeholder={placeholder}
                        value={value || ""}
                        onChange={setCode}
                        warnings={warnings}
                    />
                    <div className="ml-2 flex-fill" />
                    <CapsuleButton
                        testId={buttonTestID}
                        text={buttonText}
                        onClick={handleClickButton}
                    />
                </div>
            </div>
        );
    }

    //ACTION HANDLERS
    function handleClose() {
        stream && stream.getVideoTracks()[0].stop();
        dispatch(globalActions.hidePopUp());
        handleClosePopUp();
    }

    function handleClickButton() {
        if (!isAllFieldsOk()) {
            setWarnings([t("global_warning_cannot_be_empty")]);
            return;
        }
            
        dispatch(globalActions.hidePopUp());
        onClickButton();
        return;

        function isAllFieldsOk() {
            return !!value;
        }
    }
}