export function GenericEditableObjectStoreFactory(instanceId, initialValue) {

    const ACTION_TYPES = {
        SET_OBJECT:     "SET_OBJECT_" + instanceId,
        UPDATE_OBJECT:  "UPDATE_OBJECT_" + instanceId,
    };

    //ACTIONS
    function setObject(object) {
        return {
            type: ACTION_TYPES.SET_OBJECT,
            object,
        };
    }

    function updateObject(object) {
        return {
            type: ACTION_TYPES.UPDATE_OBJECT,
            object,
        };
    }

    //SELECTORS
    function get(state) {
        return state.object;
    }

    function getIsEdited(state) {
        return state.isEdited;
    }

    //REDUCER
    const initialState = {
        object: initialValue,
        isEdited: false,
    };

    function reducer(state = initialState, action) {
        switch (action.type) {
            case ACTION_TYPES.SET_OBJECT:
                return {
                    ...state,
                    object: action.object,
                    isEdited: false,
                };

            case ACTION_TYPES.UPDATE_OBJECT: {
                return {
                    ...state,
                    object: {
                        ...state.object,
                        ...action.object,
                    },
                    isEdited: true,
                };
            }

            default:
                return state;
        }
    }

    return {
        reducer,
        actions: {
            setObject,
            updateObject,
        },
        selectors: {
            get,
            getIsEdited,
        },
        ACTION_TYPES,
    };
}