export function Product({
    uid,
    productType,
    name,
    description,
    price,
    photoUrl,
    isPriceEditable = false,
    isNameEditable = false,
} = {}) {
    return {
        isPriceEditable,
        uid,
        productType,
        name,
        description,
        price,
        photoUrl,
        isNameEditable,
    };
}