import { Subscription } from "../_models/Subscription";
import { TimeHelper } from "../_helpers";
import { SubscriptionUtils } from "../_constants/subscriptions.constants";

export class SubscriptionMapper {
    static allServerToLocal(serverSubscriptions) {
        return serverSubscriptions.map(this.serverToLocal);
    }

    static serverToLocal(serverSubscription) {
        const {
            uid,
            type,
            status,
            device_uid,
            code,
            begin,
            end,
            room_name,
            is_automated_payment,
            renewal_period,
            price,
            loc_uid
        } = serverSubscription;
        
        return Subscription({
            uid,
            type,
            typeLabel: SubscriptionUtils.getLabelForType(type),
            status,
            statusLabel: SubscriptionUtils.getLabelForStatus(status),
            deviceUid: device_uid,
            code,
            begin: TimeHelper.serverStringToIsoString(begin),
            end: TimeHelper.serverStringToIsoString(end),
            roomName: room_name,
            locationUid: loc_uid,
            price,
            isAutomatedPayment: is_automated_payment,
            renewalPeriod:      renewal_period,
        });
    }
}



