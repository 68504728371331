import { StoreManager } from "../Everou/StoreManager";
import { LocalStorageHelper } from "../Everou/_helpers";
import { CommonStore } from "../Everou/_reducers/common.reducer";

export const CommonStoreSubscriptions = {
    subscribe,
};

function subscribe() {
    StoreManager.subscribeFnToStore(persistCommonDataToLocalStorage);
}

function persistCommonDataToLocalStorage(getState) {
    LocalStorageHelper.combineState({
        common: {
            authentication: {
                lastApikeyUpdate:   CommonStore.selectors.getLaskApikeyUpdate(getState()),
                version:            CommonStore.selectors.getVersion(getState()),
                codeSent:           CommonStore.selectors.getIsAuthCodeSent(getState()),
                user:               CommonStore.selectors.getEverouUser(getState()),
                login: {
                    email:          CommonStore.selectors.getLoginEmail(getState()),
                },
            },
        },
    });
}