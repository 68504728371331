import { useCallback } from "react";
import { Icons } from "../_assets";
import { GLOBAL_POPUPS } from "../_constants";
import { popUpActions } from "../_stores/PopUpStore/popUpActions";
import { CapsuleButton } from "../_components";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Invitation } from "../_models/Invitation";
import { invitationsActions } from "../_actions";

type InvitationPublicUrlProps = {
    invitation: any;
    isEditable?: boolean;
};

export function InvitationPublicUrl({
    invitation = Invitation(),
    isEditable
}: InvitationPublicUrlProps) {

    const dispatch = useDispatch();
    const { t } = useTranslation();


    const handleCopyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(invitation.publicUrl);
    }, [invitation.publicUrl]);

    const handleDeleteInvitationPublicUrl = useCallback(() => {
        dispatch(invitationsActions.deleteInvitationPublicUrl(invitation));
    }, [dispatch, invitation]);

    const handleCreateNewPublicUrl = useCallback(() => {
        dispatch(invitationsActions.createInvitationPublicUrl(invitation));
    }, [dispatch, invitation]);

    const handleSendPublicUrlByEmail = useCallback(() => {
        dispatch(invitationsActions.mailInvitationPublicUrl(invitation));
    }, [dispatch, invitation]);


    const showConfirmPopUpAndDelete = useCallback(() => {
        dispatch(
            popUpActions.showPopUp(GLOBAL_POPUPS.BASE_APP_GLOBAL, {
                title: t("invitation_public_url_confirm_delete_title"),
                body: t("invitation_public_url_confirm_delete_description"),
                onCancel: handleDeleteInvitationPublicUrl,
                cancelButtonName: t("common.delete"),
                acceptButtonName: t("global_cancel"),
                limitWidth: true,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, invitation.publicUrl]);

    //VIEW
    return (
        <>
            <h3 className="mt-1 mb-2">{t("invitation_public_url")}</h3>

            <div className="d-flex align-items-center justify-content-between position-relative">
                <small style={{ maxWidth: '300px' }} className={"mr-2 w-75 c-line-height-1"}>
                    {invitation.publicUrl ? (
                        <a
                            className="d-block w-100 text-secondary"
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            }}
                            rel="noreferrer"
                            href={invitation.publicUrl}
                            target="_blank"
                        >
                            {invitation.publicUrl}
                        </a>
                    ) : (
                        t("invitation_link_generate_info")
                    )}
                </small>
                {isEditable && (
                    <div className="d-flex align-items-center">{buttons()}</div>
                )}
            </div>
        </>
    );




    function buttons() {
        if (!invitation.publicUrl) {
            return (
                <CapsuleButton
                    size="SMALL"
                    onClick={handleCreateNewPublicUrl}
                    icon={Icons.add}
                />
            );
        } else {

            return (
                <>
                    <CapsuleButton
                        size="SMALL"
                        // eslint-disable-next-line react/style-prop-object
                        style="DANGER"
                        onClick={showConfirmPopUpAndDelete}
                        icon={Icons.trash}
                        tooltip={t('common.delete')}
                        boostedToolTip
                        />
                    <div className="ml-2"></div>
                    <CapsuleButton
                        size="SMALL"
                        onClick={handleCopyToClipboard}
                        icon={Icons.copy}
                        tooltip={t('global_copy')}
                        boostedToolTip
                    />
                    <div className="ml-2"></div>
                    <CapsuleButton
                        size="SMALL"
                        onClick={handleSendPublicUrlByEmail}
                        // eslint-disable-next-line react/style-prop-object
                        style='INFO'
                        icon={Icons.email_send}
                        tooltip={t('common.send')}
                        boostedToolTip
                    />
                </>
            );
        }
    }
}
