import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const auditLogsService = {
    getAuditLogs,

    getAuditLogsFilterPresets,
    createAuditLogsFilterPreset,
    removeAuditLogsFilterPreset,
};

async function removeAuditLogsFilterPreset(locationUid, uid) {
    await ServiceHelper.postRequest(
        URL_CONSTANTS.AUDIT_LOGS_PRESET_REMOVE,
        {
            loc_uid: locationUid,
            uid,
        },
    );
}

async function createAuditLogsFilterPreset(locationUid, data) {
    const response = await ServiceHelper.postRequest(
        URL_CONSTANTS.AUDIT_LOGS_PRESET_CREATE,
        {
            loc_uid: locationUid,
            data,
        },
    );

    return {
        uid: response.uid,
        data: response.data,
    };
}

async function getAuditLogsFilterPresets(locationUid) {
    const response = await ServiceHelper.getRequest(
        URL_CONSTANTS.AUDIT_LOGS_PRESET_LIST,
        "?loc_uid=" + locationUid,
    );

    return [
        ...response,
    ];
}

async function getAuditLogs({
    locationUid,
    userUid,
    code,
    begin,
    end,
    offset,
    pageSize = 20,
    paths,
}) {
    const response = await ServiceHelper.postRequest(
        URL_CONSTANTS.AUDIT_LOGS_LIST,
        {
            loc_uid: locationUid,
            user_uid: userUid,
            code,
            begin,
            end,
            offset,
            page_size: pageSize, 
            paths,
        },
    );

    return {
        logs: response.logs,
        next: response.next,
    };
}