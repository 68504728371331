import { LocalAuditPathModel } from "./LocalAuditPathModel";

export function AuditFilterPreset({
    uid,
    name,
    auditPaths = [] || [LocalAuditPathModel()],
} = {}) {
    return {
        uid,
        name,
        auditPaths,
    };
}