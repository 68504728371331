import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { LocalizationManager } from '../_translations/i18n';

export function LocalizedDatePicker() {
    return <DatePicker {...{
        ...arguments[0],
        locale: LocalizationManager.getDateFnLocale(),
    }}/>;
}