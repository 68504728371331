import { Barrier, BarrierUpdate, BarrierUser, ServerBarrier, ServerBarrierUpdate } from "../_modelsTS/Barrier"

const barriersUserServerToLocal = (user: BarrierUser) => {
    return {
        name: !!user.name ? user.name : user.email.split('@')[0],
        uid: user.uid,
        email: user.email
    } as BarrierUser
}

const serverToLocal = ({ capacity, users_inside_barriers, num_users_inside_barriers }: ServerBarrier): Barrier => ({
    capacity,
    usersInsideBarriers: users_inside_barriers.map(barriersUserServerToLocal),
    numUsersInsideBarriers: num_users_inside_barriers
})

const localUpdateToServer = ({ capacity, reset, extractUser }: BarrierUpdate): ServerBarrierUpdate  => ({
    capacity,
    reset,
    extract_user: extractUser
})

export const BarrierMapper =  {
    serverToLocal,
    localUpdateToServer
}