import { User as UserTS } from '../_modelsTS/User';

export function User({
    uid,
    name,
    lastName,
    email,
    apikey,
    avatar,
    permissionType,
    isSat = false,
    isSharePositionEnabled,
    isSuperuser = false,
    isDistributor = false,
}: Partial<UserTS> = {}) {
    return {
        uid,
        name,
        lastName,
        email,
        apikey,
        avatar,
        isSat,
        permissionType,
        isSharePositionEnabled,
        isSuperuser,
        isDistributor,
    };
}