import { WorkdayHelper } from '../_helpers/WorkdayHelper';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { MainPageWithButtons } from "../../Common/_components/MainPageWithButtons";
import { CapsuleButton, IsoStringDatePicker } from '../_components';
import { InlineEditButtons } from '../_components/InlineEditButtons';
import { FILTER_DISABLED_ALL } from "../_constants";
import { appPermissions } from '../_constants/permissions.constants';
import { TimeHelper } from '../_helpers';
import { LocationInfo } from "../_models/LocationInfo";
import { Selectors } from '../_reducers/app.reducer';
import { ClockinActions } from '../_stores/ClockinStore/ClockinActions';
import { ClockinsDataSection } from './ClockinsDataSection';
import { ClockinsFilters } from "./ClockinsFilters";
import { UsersColumn } from './UsersColumn';
import { WeekSelector } from './WeekSelector';
import { AppIcons } from '../_assets/appIcons';

const locationInfoModel = LocationInfo();

export function ClockinsHistoryView() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationUid       = useSelector(Selectors.getSelectedLocationUid);
    const workdays          = useSelector(Selectors.getClockins);
    const { begin, end }    = useSelector(Selectors.getClockinsDateFilter);
    const permission        = useSelector(Selectors.getPermissionSelectedLocation);
    const selectedUserUid   = useSelector(Selectors.getClockinsSelectedUserUid);
    const myUserUid         = useSelector(Selectors.getUserUid);
    const timezone          = useSelector(Selectors.getTimezone);
    let locationInfo        = locationInfoModel;
    locationInfo            = useSelector(Selectors.getLocationInfo);
    
    //GLOBAL ACTION HANDLERS
    const handleAddClockin    =        date => dispatch(ClockinActions.createClockInForCurrentUser(date));
    const handleDeleteClockin =         uid => dispatch(ClockinActions.showDeletePopUp(uid));
    const handleUpdateClockin = (uid, date) => dispatch(ClockinActions.updateClockIn(uid, date));
    const handleSelectUser    =         uid => dispatch(ClockinActions.selectUserUid(uid));
    const handleCreateRemoteClockin =    () => dispatch(ClockinActions.createRemoteClockin(locationUid));
    const handleToggleAllowRemoteAccess = clockinUser =>
        dispatch(ClockinActions.toggleRemoteAccess(clockinUser, locationUid))
    ;
    const handleToggleFlexibleClockins = clockinUser =>
        dispatch(ClockinActions.toggleFlexibleClockins(clockinUser, locationUid))
    ;

    //LOCAL STATE
    const [date, setDate] = useState(TimeHelper.getTodayIsoString());
    const canManageClockins = appPermissions.canManageClockins(permission);
    const allowsRemoteClockins = locationInfo.allowsRemoteClockins;

    const newClockinUtcBegin = TimeHelper.removeTimezoneFromIsoString(begin, timezone);
    const newClockinUtcEnd = TimeHelper.getMinimumDate([
        TimeHelper.getTodayIsoString(),
        TimeHelper.removeTimezoneFromIsoString(end, timezone),
    ]);
    const newClockinInitialdate = TimeHelper.getMinimumDate([
        date,
        TimeHelper.getTodayIsoString(),
    ]);

    //INITIALIZATION
    useEffect(() => {
        dispatch(ClockinActions.selectUserUid(myUserUid));
    }, [dispatch, myUserUid]);

    useEffect(() => {
       dispatch(ClockinActions.listClockinUsers(locationUid, begin, end, canManageClockins));
    }, [dispatch, canManageClockins, begin, end, locationUid]);

    //EFFECTS
    useEffect(() => {
        if (!selectedUserUid)
            return;
        dispatch(ClockinActions.setDateFilter({
            begin: TimeHelper.getFirstDayOfWeekForIso(date),
            end: TimeHelper.getLastDayOfWeekForIso(date),
        }));
        dispatch(ClockinActions.refreshClockins());
    }, [dispatch, date, selectedUserUid]);

    //VIEW
    return <MainPageWithButtons
        content={content()}
        pageTitle={t("mod_clockins_title")}
        size={'LARGE'}
        buttons={getButtonsArr()}
    />;

    function getButtonsArr() {
        const buttonArray = [];

        if (allowsRemoteClockins)
            buttonArray.push(addRemoteClockinButton());

        return buttonArray;
    }


    function content() {
        return (
            <>
                <div className="
                    
                    d-flex
                    no-gutters
                    overflow-auto
                " style={{height: 'calc(100% - 47px)'}}>
                    {canManageClockins && <UsersSection
                        isVisible={canManageClockins}
                        handleSelectUser={handleSelectUser}
                        handleToggleAllowRemoteAccess={handleToggleAllowRemoteAccess}
                        handleToggleFlexibleClockins={handleToggleFlexibleClockins}
                        selectedUserUid={selectedUserUid}
                    />}
                    {ClockinsSection()}
                </div>
            </>
        );
    }

    function currentTotalClockinsTime() {
        const { workingHours, breakHours } =
            WorkdayHelper.getWorkingAndBreakHours(workdays);

            return (
            <div className="py-3" style={{display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(2,1fr)'}}>
                <div className='p-1 pr-3 d-flex' style={{ border: "2px solid #D5D7D8", borderRadius: '7px' }}>
                    <img src={AppIcons.time_work} alt="working hours" />
                    <div className='d-flex flex-column justify-content-center'>
                    <b>{t("mod_clockins_total_hours_worked")}:</b>
                    <div className='c-text-primary c-ft-ml c-font-weight-black c-line-height-1'>{workingHours ?? '0h 0m'}</div>
                    </div>
                </div>
                <div className='p-1 pl-3 d-flex justify-content-end' style={{ border: "2px solid #D5D7D8", borderRadius: '7px' }}>
                    <div className='d-flex flex-column justify-content-center align-items-end'>
                    <b>{t("mod_clockins_total_hours_rest")}:</b>
                    <div className='c-text-primary c-ft-ml c-font-weight-black c-line-height-1'>{breakHours ?? '0h 0m'}</div>
                    </div>
                    <img className="px-2" src={AppIcons.time_break} alt="break hours" />
                </div>
            </div>
        );
    }

    function ClockinsSection() {
        return (
            <div className="
                w-100
                h-100
                p-3
                d-flex
                flex-column
            ">
                <WeekSelector {...{
                    begin,
                    end,
                    date,
                    setDate,
                    maxEndDate: TimeHelper.getTodayIsoString(),
                }}/>

                <div className="border-top"/>

                {currentTotalClockinsTime()}

                {canManageClockins &&
                    <NewClockinSection
                        key={date}
                        {...{
                            utcBegin: newClockinUtcBegin,
                            utcEnd: newClockinUtcEnd,
                            handleAddClockin,
                            timezone,
                            initialDate: newClockinInitialdate,
                        }}
                    />
                }
                <div className="mt-3"/>
                <ClockinsDataSection
                    {...{
                        workdays,
                        canManageClockins,
                        timezone,
                        
                        handleAddClockin,
                        handleDeleteClockin,
                        handleUpdateClockin,
                    }}
                />
            </div>
        );
    }

    function addRemoteClockinButton() {
        return (
            <CapsuleButton
                onClick={handleCreateRemoteClockin}
                text={t("mod_clockins_clockin_now")}    
                size={'MEDIUM'}      
            />
        );
    }
}

function UsersSection({
    selectedUserUid,
    handleSelectUser = () => {},
    handleToggleAllowRemoteAccess,
    handleToggleFlexibleClockins,
} = {}) {


    const clockinUsers      = useSelector(Selectors.getClockinsUsers);
    const filter            = useSelector(Selectors.getClockinsFilter);
    const users = useMemo(() => filterClockinUsers(clockinUsers, filter),[clockinUsers, filter]) 

    return (
        <>
            <div className="
            c-w-22rem
            c-max-w-22rem
            d-flex
            flex-column
            ">
                <ClockinsFilters
                    filter={filter}
                    users={clockinUsers}
                />
                <div className="overflow-auto h-100">
                    <UsersColumn {...{
                        users,
                        selectedUid: selectedUserUid,
                        handleSelectUser,
                        handleToggleAllowRemoteAccess,
                        handleToggleFlexibleClockins,
                    }}
                    />
                </div>
            </div>
        <div className="mx-1 border-right"/>
        </>
    );

    function filterClockinUsers(clockinUsers, filter) {
        let clockinUsersFiltered = [...clockinUsers];
        if (filter.value.user !== FILTER_DISABLED_ALL.key) {
            clockinUsersFiltered = clockinUsersFiltered.filter(user => user.uid === filter.value.user);
        }

        return clockinUsersFiltered;
    }

}

function NewClockinSection({
    utcBegin,
    utcEnd,
    handleAddClockin = (date) => {},
    timezone,
    initialDate,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [newClockinDate, setNewClockinDate] = useState(initialDate);
    const [isEditing, setIsEditing] = useState(false);
    const [isDateValid, setIsDateValid] = useState(true);

    //ACTION HANDLERS
    function handleConfirmChanges() {
        handleAddClockin(newClockinDate);
        setNewClockinDate(initialDate);
        setIsEditing(false);
    }

    function handleDiscardChanges() {
        setIsEditing(false);
    }

    function handleDateChange(date) {
        setNewClockinDate(date);
        processInputString(date, utcBegin, utcEnd);
    }

    function onBlur() {
        processInputString(newClockinDate, utcBegin, utcEnd);
    }

    function handleRawDateChange(event) {
        try {
            const localResult = TimeHelper.parseDatePickerStringToIso(event.target.value);
            if (!localResult)
                throw new Error("Error parsing date");

            const isoResult = TimeHelper.removeTimezoneFromIsoString(localResult, timezone);
            processInputString(isoResult, utcBegin, utcEnd);
        } catch (e) {
            console.error("date string not valid");
            setIsDateValid(false);
        }
    }

    function processInputString(isoString, minDate, maxDate) {
        if (minDate && TimeHelper.isIsoABeforeB(isoString, minDate)) {
            console.error("Below min date");
            setIsDateValid(false);
            return;
        }

        if (maxDate && TimeHelper.isIsoABeforeB(maxDate, isoString)) {
            console.error("Over max date", maxDate, isoString);
            setIsDateValid(false);
            return;
        }
        setIsDateValid(true);
    }

    //VIEW
    return (
        <div className="
            w-100
            d-flex
            justify-content-end
            align-items-start
        ">
            {isEditing && !isDateValid && errorMessage()}

            <div className="d-flex">
                {isEditing
                    ? addClockInTimeSelector()
                    : addClockInButton()
                }
            </div>

        </div>
    );

    function errorMessage() {
        return (
            <div className="mr-2 text-danger">
                {t("mod_clockins_out_of_range_warning")}
            </div>
        );
    }

    function addClockInTimeSelector() {
        return (
            <>
            <IsoStringDatePicker
                onBlur={onBlur}

                minDate={utcBegin}
                maxDate={utcEnd}
                isoString={newClockinDate}
                onChange={handleDateChange}

                className={"border rounded"}
                placeholderText={t("mod_clockins_new_clock_in")}
                useDefaultInput

                timezone={timezone}
                onChangeRaw={handleRawDateChange}
            />
            <div className="ml-2"/>
            <InlineEditButtons
                {...{
                    handleConfirmChanges,
                    handleDiscardChanges,
                    isEnabled: isDateValid,
                }}
            />
            </>
        );
    }

    function addClockInButton() {
        return (
            <CapsuleButton
                onClick={() => setIsEditing(true)}
                text={t("mod_clockins_new_clock_in")}    
                size={'SMALL'}      
                textUppercase={false}  
            />
        );
    }
}