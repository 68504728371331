import { ServiceHelper2 } from "../../Common/_helpers/ServiceHelper2";
import { BUDGETS_API_URLS } from "../_constants/url.constants";

export const BudgetsService = {
    listBudgets,
    getBudget,
    createBudget,
    deleteBudget,
    updateBudget,
    
    getPdfBlob,
    sendPdf,
};

async function listBudgets(employeeUid) {
    return await ServiceHelper2.getRequest(
        BUDGETS_API_URLS.BUDGETS,
        {
            employee_uid: employeeUid,
        },
    );
}

async function getBudget(uid) {
    return await ServiceHelper2.getRequest(
        BUDGETS_API_URLS.BUDGETS + "/" + uid,
    );
}

async function createBudget(clientUid, notes) {
    return await ServiceHelper2.postRequest(
        BUDGETS_API_URLS.BUDGETS,
        {
            client_uid: clientUid,
            notes,
        },
    );
}

async function updateBudget(uid, notes, status, vatApplied) {
    return await ServiceHelper2.putRequest(
        BUDGETS_API_URLS.BUDGETS + "/" + uid,
        {
            notes,
            status,
            vat_applied: vatApplied,
        },
    );
}

async function deleteBudget(uid) {
    return await ServiceHelper2.deleteRequest(
        BUDGETS_API_URLS.BUDGETS + "/" + uid,
    );
}

async function getPdfBlob(budgetUid) {
    return await ServiceHelper2.getPdfBlobRequest(
        BUDGETS_API_URLS.PDF_BUDGETS + "/" + budgetUid,
    )
}

async function sendPdf(budgetUid) {
    await ServiceHelper2.postRequest(
        BUDGETS_API_URLS.SEND_BUDGET_PDF + "/" + budgetUid,
    );
}