import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import {
    CapsuleButton,
    ContentSpace,
    ListedGroup,
    MainPageWithButtons,
} from "../_components";
import { Selectors } from "../_reducers/app.reducer";
import { EverouDevelopersActions } from "../_actions/EverouDevelopersActions";
import { EmailInput } from "../../Common/_components/EmailInput";
import { EverouDeveloper } from "../_models/EverouDeveloper";
import { SimpleListElement } from "../../Common/_components/SimpleListElement";
import { Icons } from "../_assets";
import { popUpActions } from "../_stores/PopUpStore/popUpActions";
import { GLOBAL_POPUPS } from "../_constants";

export function DevelopersModule() {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const developers = useSelector(Selectors.getDevelopers);
    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    //LOCAL STATE
    const [email, setEmail] = useState("");

    //ACTION HANDLERS
    const handleAddDeveloper = useCallback(() => {
        dispatch(EverouDevelopersActions.add(locationUid, email));
    }, [dispatch, locationUid, email]);

    const handleRemoveDeveloper = useCallback(uid => {
        dispatch(popUpActions.showPopUp(GLOBAL_POPUPS.REMOVE_DEVELOPER, { locationUid, uid }));
    }, [dispatch, locationUid]);

    //INTIALIZATION
    useEffect(() => {
        dispatch(EverouDevelopersActions.list(locationUid));
    }, [dispatch, locationUid]);

    //VIEW 
    return (
        <ContentSpace
            mainContent={<MainPageWithButtons
                pageTitle={t("mod_developers_title")}
                content={content()}
            />}
        />
    );

    function content() {
        return (
            <div>
                <div className="mt-2" />
                {addDeveloperSection()}
                <div className="mt-2" />
                {developerList()}
            </div>
        );
    }

    function developerList() {
        return (
            <ListedGroup
                headerText={t("global_list")}
                elements={getElements()}
            />
        );

        function getElements() {
            if (!developers.length)
                return noDevelopers();

            return developers.map(SingleDeveloper);
        }

        function noDevelopers() {
            return [
                <SimpleListElement
                    text={
                        <div className="text-center">
                            {t("mod_developers_none_message")}
                        </div>
                    }
                /> 
            ];
        }

        function SingleDeveloper(developer = EverouDeveloper()) {
            return (
                <SimpleListElement
                    text={developerContent()}
                /> 
            );

            function developerContent() {
                return (
                    <div className="
                        d-flex
                        justify-content-between
                        align-items-center
                    ">
                        <div>
                            <div>
                                {developer.name}
                            </div>
                            <div>
                                {developer.email}
                            </div>
                        </div>
                        <div>
                            <CapsuleButton
                                icon={Icons.trash}
                                onClick={() => handleRemoveDeveloper(developer.uid)}
                                size={'SMALL'}
                                style={`DANGER`}
                            />
                        </div>
                    </div>
                );
            }
        }
    }

    function addDeveloperSection() {
        return (
            <div className="d-flex justify-content-end">
                <EmailInput {...{
                    email,
                    setEmail,
                    handleConfirm: handleAddDeveloper,
                    buttonName: t("global_add"),
                }} />
            </div>
        );
    }

}