import React from "react";
import { useDispatch } from 'react-redux';

import deleteIcon from "../_assets/appIcons/delete-icon.svg";

import optionsIcon from "../_assets/appIcons/settings-icon.svg";
import { dashboardActions } from "../_actions/dashboard.actions";
import { globalActions } from "../_actions";
import { GLOBAL_POPUPS, SETTINGS_WINDOWS } from "../_constants";

export function BaseWidget({
    widgetType,
    widgetId,
    settings,

    title,
    content,
    editable,

    widgetStyle,
}) {
    //LIBRARIES
    const dispatch = useDispatch();

    return (
        <div className="
            col-12
            col-sm-6
            col-md-6
            col-lg-4
            col-xl-3
            
            p-0
        ">
            <div className={`
                c-h-17rem
                d-flex
                flex-column

                m-2 p-2

                ${widgetStyle
                    ? widgetStyle
                    : "bg-light border rounded"
                }

                overflow-auto
            `}>
                <div className="
                    flex-shrink-0

                    d-flex
                    justify-content-between
                ">

                    {title &&
                    <div className="
                        c-ft-l
                        text-center
                        font-weight-light

                        align-self-start
                        mb-1 ml-1

                        text-capitalize
                    ">
                        {title}
                    </div>
                    }

                    {editable &&
                    <div className="
                        d-flex
                        align-items-center
                    ">
                        <img
                            className={`
                                c-img-1-5rem
                                mr-2
                                btn-light
                                rounded
                            `}
                            src={deleteIcon}
                            alt="options icon"
                            onClick={() => handleDeleteWidget(widgetId)}
                        />
                        {settings &&
                        <img
                            className={`
                                c-img-1-5rem
                                mr-2
                                btn-light
                                rounded
                            `}
                            src={optionsIcon}
                            alt="options icon"
                            onClick={() => handleClickSettings(widgetType, widgetId, settings)}
                        />
                        }
                    </div>
                    }
                </div>

                <div className="h-100">
                    {content}
                </div>
            </div>
        </div>
    );

    //ACTION HANDLERS
    function handleClickSettings(widgetType, widgetId, settings) {
        dispatch(
            dashboardActions.showSettings(
                SETTINGS_WINDOWS.WIDGET,
                {
                    widgetType,
                    widgetId,
                    settings,
                }
            )
        );
    }

    function handleDeleteWidget(widgetId) {
        dispatch(globalActions.showPopUp(
            GLOBAL_POPUPS.DELETE_WIDGET,
            { widgetId },
        ));
    }
}