import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContentSpace, } from "../_components";
import { BudgetsStore } from "../_reducers/budgetsRootReducer";
import { ProductsActions } from "../_actions/ProductsActions";
import { PRODUCTS_MODULE_VIEWS } from "./PRODUCTS_MODULE_VIEWS";
import { ProductDetailsView } from "./ProductDetailsView";
import { ProductsListView } from "./ProductsListView";

export function ProductsModule() {
    
    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const products              = useSelector(BudgetsStore.selectors.getProducts);
    const secondaryView         = useSelector(BudgetsStore.selectors.getProductsModuleSecondaryView);
    const selectedProduct       = useSelector(BudgetsStore.selectors.getSelectedProduct);
    const selectedProductUid    = useSelector(BudgetsStore.selectors.getSelectedProductUid);

    //ACTION HANDLERS
    const handleGoBack = useCallback(() => {
        dispatch(ProductsActions.changeSecondaryView(null));
    }, [dispatch]);

    const handleClickProduct = useCallback((uid) => {
        dispatch(ProductsActions.selectProduct(uid));
    }, [dispatch]);

    //SECONDARY VIEWS MANAGEMENT
    useEffect(() => {
        if (!selectedProduct)
            return;
        
        dispatch(ProductsActions.changeSecondaryView(PRODUCTS_MODULE_VIEWS.PRODUCT_DETAILS));
    }, [dispatch, selectedProduct]);

    //CLEAN UP
    useEffect(() => {
        return () => dispatch(ProductsActions.changeSecondaryView(null));
    }, [dispatch]);

    //VIEW
    return (
        <ContentSpace
            mainContent={<ProductsListView
                products={products}
                selectedProductUid={selectedProductUid}
                handleClickProduct={handleClickProduct}
            />}
            detailedContent={getSecondaryView()}
            onGoBack={handleGoBack}
        />
    );

    function getSecondaryView() {
        switch (secondaryView) {
            case PRODUCTS_MODULE_VIEWS.PRODUCT_DETAILS:
                return <ProductDetailsView
                    product={selectedProduct}
                />;
        
            default:
                return null;
        }
    }
}