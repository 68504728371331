import { Selectors } from "../../../Everou/_reducers/app.reducer";
import { Invitation } from "../../../Everou/_models/Invitation";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { PMSLocationsActions } from "../../_actions/PMSLocationsActions";
import { TimeHelper } from "../../../Everou/_helpers/TimeHelper";
import { IsoRangeDatePicker } from "../../../Everou/_components/IsoRangeDatePicker";
import { Selector } from "../../../Common/_components/Selector";
import { IntegrationCompany } from "../../../Everou/_modelsTS/Integration";


export function NewReservationInput({
    timezone,
    integrations
}) {
    let invitation = Invitation();
    invitation = useSelector(Selectors.getPMSReservationNewDraft);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const buildAvailablePMS = useCallback((availableIntegrations: any[] = []) => {
        return availableIntegrations.map((template) => {
            let label = "";
            if (template.company === IntegrationCompany.HOSTIFY)
                label = template.company + " - " + template.host_name;
            else if (template.company === IntegrationCompany.KROSS_BOOKING)
                label = template.company + " - " + template.room_name;
            else if (template.company === IntegrationCompany.SMOOBU)
                label = template.company + " - " + template.host_name;
            return ({ value: template, label: label, isDisabled: false });
        }) ?? [];
    }, []);
    const [availablePMS, setAvailablePMS] = useState(buildAvailablePMS(integrations));

    const onChangePMS = useCallback(selectedPMS => {
        dispatch(
            PMSLocationsActions.changeReservationGroup(
                selectedPMS.value.uid,
                selectedPMS.value.groups,
            ),
        );
    }, []);

    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSLocationsActions.changeReservationBeginTime(TimeHelper.getTodayIsoString(), true))
        let random = (Math.random() + 1).toString(36).substring(8);
        let email = "anonymous.user-" + random + Date.now() + "@baintex.com";
        let emails = [email];
        dispatch(PMSLocationsActions.setReservationEmail(emails));
    }, [dispatch]);

    useEffect(() => {
        setAvailablePMS(buildAvailablePMS(integrations));
    }, [integrations, buildAvailablePMS]);

    const content = (
        <div
            style={{
                gap: 24,
                gridTemplateColumns: "repeat(2, 400px)",
            }}
            className="m-auto mt-2 w-100 px-2"
        >
            <div className="c-min-w-40">
                <Selector
                    hideSelectedOptions={false}
                    placeholder={t("mod_pms_select_pms_placeholder")}
                    onChange={(selectedPMS: any) =>
                        onChangePMS(selectedPMS)
                    }
                    options={availablePMS}
                />
            </div>
            <br />
            <h3 className="text-left">{t("common.when")}</h3>
            {dateRangeSelector(
                timezone,
                invitation,
            )}
            <br />
                
        </div>
    );

    return content;

    function dateRangeSelector(timezone, invitation = Invitation()) {
        const { begin, end } = invitation;
        return (
            <div className="mt-1">
                <IsoRangeDatePicker
                    onChangeStart={handleStartDateChange}
                    onChangeEnd={handleEndDateChange}
                    timezone={timezone}
                    begin={begin}
                    end={end}
                    minDate={new Date()}
                />
            </div>
        );
    }

    function handleStartDateChange(localDateString) {
        if (localDateString === invitation.begin || localDateString === null)
            return;
        dispatch(
            PMSLocationsActions.changeReservationBeginTime(
                localDateString,
                invitation.newInvitation,
            ),
        );
    }

    function handleEndDateChange(localDateString) {
        console.log(localDateString);
        if (localDateString === invitation.end)
            return;
        dispatch(
            PMSLocationsActions.changeReservationEndTime(
                localDateString,
                invitation.newInvitation,
            ),
        );
    }
}