import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const reservationService = {
    getConfigurations,
    createReservationConfiguration,
    updateReservationConfig,
    deleteConfiguration,

    getReservations,
    createReservation,
    deleteReservation,
};

function deleteConfiguration(roomUid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.RESERVATION_CONFIGURATION_REMOVE,
        {
            room_uid: roomUid,
        },
    );
}

function createReservationConfiguration({
    roomUid,

    blockMinutes,
    limitMinutes,

    allDay,
    begin,
    end,

    monday,
    tuesday,
    thursday,
    wednesday,
    friday,
    saturday,
    sunday,

    excludedDays,

    numberOfUsers,
}) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.RESERVATION_CONFIGURATION_CREATE,
        {
            room_uid: roomUid,

            block_minutes: blockMinutes,
            limit_minutes: limitMinutes,

            all_day: allDay,
            begin,
            end,

            monday,
            tuesday,
            thursday,
            wednesday,
            friday,
            saturday,
            sunday,

            excluded_days: excludedDays,

            number_of_users: numberOfUsers,
        },
    );
}

function updateReservationConfig({
    roomUid,

    blockMinutes,
    limitMinutes,

    allDay,
    begin,
    end,

    monday,
    tuesday,
    thursday,
    wednesday,
    friday,
    saturday,
    sunday,

    excludedDays,

    numberOfUsers,
}) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.RESERVATION_CONFIGURATION_UPDATE,
        {
            room_uid: roomUid,

            block_minutes: blockMinutes,
            limit_minutes: limitMinutes,

            all_day: allDay,
            begin,
            end,

            monday,
            tuesday,
            thursday,
            wednesday,
            friday,
            saturday,
            sunday,
            
            excluded_days: excludedDays,
            
            number_of_users: numberOfUsers,
        },
    );
}

function getConfigurations(locationUid) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.RESERVATION_CONFIGURATION_LIST,
        "?loc_uid=" + locationUid,
    );
}

function getReservations(roomUid, begin, end) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.RESERVATION_LIST,
        "?room_uid=" + roomUid
        +"&begin=" + begin
        +"&end=" + end
        ,
    );
}

function createReservation({
    roomUid,
    userUid,
    begin,
    end,
}) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.RESERVATION_CREATE,
        {
            room_uid: roomUid,
            user_uid: userUid,
            begin,
            end,
        }
    );
}

function deleteReservation(uid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.RESERVATION_DELETE,
        {
            reservation_uid: uid,
        },
    );
}