import { USERS_STATUS_ACTION_TYPES } from "../_constants/usersStatus.constants";
import { USERS_STATUS_DATE_FILTER_PERIODS } from "../_constants/usersStatus.constants";

const initialState = {
    items: [],
    isRequestingUsersStatus: false,
    selectedUserUid: null,
    
    userStatusData: null,
    isRequestingUserStatusData: false,
    
    userStatusLogs: [],
    isRequestingUserStatusLogs: false,

    dateFilter: USERS_STATUS_DATE_FILTER_PERIODS.LAST_MONTH,
};

export function usersStatus(state = initialState, action) {
    switch (action.type) {
        case USERS_STATUS_ACTION_TYPES.GET_USERS_STATUS_SUCCESS:
            return { ...state,
                isRequestingUsersStatus: false,
                items: action.usersStatus,
            };
            
        case USERS_STATUS_ACTION_TYPES.GET_USER_STATUS_DATA_SUCCESS:
            return { ...state,
                userStatusData: action.userStatusData,
                isRequestingUserStatusData: false,
            };

        case USERS_STATUS_ACTION_TYPES.CHANGE_DATE_FILTER:
            return { ...state,
                dateFilter: action.dateFilter,
            };

        case USERS_STATUS_ACTION_TYPES.SELECT_USER:
            return { ...state,
                selectedUserUid: action.selectedUserUid,
            };

        case USERS_STATUS_ACTION_TYPES.REQUEST_USERS_STATUS:
            return { ...state,
                isRequestingUsersStatus: true,
            };

        case USERS_STATUS_ACTION_TYPES.REQUEST_USER_STATUS_DATA:
            return { ...state,
                isRequestingUserStatusData: true,
            };

        case USERS_STATUS_ACTION_TYPES.GET_USER_STATUS_LOGS_REQUEST:
            return { ...state,
                isRequestingUserStatusLogs: true,
            };
            
        case USERS_STATUS_ACTION_TYPES.GET_USER_STATUS_LOGS_SUCCESS:
            return { ...state,
                userStatusLogs: action.userStatusLogs,
                isRequestingUserStatusLogs: false,
            };

        default:
            return state;
    }
}