import React from 'react';
import { Link } from 'react-router-dom';

export function CustomLink({ url, text }) {
    return (
        <Link
            to={url}
            className="
                c-text-primary
                c-hover-text-whitesmoke
            "
        >
            {text}
        </Link>
    );
}