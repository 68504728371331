import { filterTypesToModes, LOCATION_INFO_ACTION_TYPES } from "../_constants";
import { locationService } from "../_services";
import { LocationInfoMapper } from "../_mappers/LocationInfoMapper";
import { LocationInfo } from "../_models/LocationInfo";
import { genericActions } from "./generic.actions";
import { LocationInvitationConfigMapper } from "../_mappers/LocationInvitationConfigMapper";
import { Selectors } from "../_reducers/app.reducer";
import { LOCATION_PERMISSIONS } from '../_constants/permissions.constants';
import { invitationsService } from '../_services/invitations.service';
import { InvitationsMapper } from '../_mappers/InvitationsMapper';
import { RoomsStore } from "../_stores/RoomStore";
import { filterActions } from "./filter.actions";

export const locationInfoActions = {
    getLocationInfo,
    getLocationInfoNoPersist,
    getCreationInvitation,
    updateCurrentLocationInvitationConfig,
    getLocationInvitationConfigData,
    updateLocationInfo,
    updateInterfaceType
};


function updateInterfaceType(interfaceType) {
    return async (dispatch, getState) => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));
        async function asyncAction() {
            const locationUid = Selectors.getSelectedLocationUid(getState());
                await locationService.updateInterfaceType({
                    loc_uid: locationUid,
                    interface_type: interfaceType,
                });
            
            dispatch(updateLocationInfo({ interfaceType }));
            return interfaceType;
        }
    };
}

function updateCurrentLocationInvitationConfig() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const locationInvitationConfig =
                await dispatch(getLocationInvitationConfigData(
                    Selectors.getSelectedLocationUid(getState()),
                    true,
                ))
            ;
            dispatch(updateLocationInfo(locationInvitationConfig));
        }
    };
}

function getLocationInvitationConfigData(locationUid, rethrow) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(
            asyncAction, undefined, rethrow,
        ));

        async function asyncAction() {
            const serverInvitationConfig =
                await locationService.getLocationInvitationConfig(locationUid)
            ;
            return LocationInvitationConfigMapper.serverToLocal(
                serverInvitationConfig,
            );
        }
    }
}

function getLocationInfo(locationUid) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!locationUid) {
                dispatch(updateLocationInfo(LocationInfo()));
                return;
            }

            const locationInfo = await dispatch(getLocationInfoNoPersist(locationUid, true));
            dispatch(updateLocationInfo(locationInfo)); 
            dispatch(filterActions.changeMode(filterTypesToModes[locationInfo.interfaceType]));
            dispatch(RoomsStore.actionListItemsSuccess(locationInfo.rooms));
            return locationInfo;
        }
    };
}

function updateLocationInfo(locationInfo) {
    return {
        type: LOCATION_INFO_ACTION_TYPES.UPDATE_LOCATION_INFO,
        locationInfo,
    };
}

function getLocationInfoNoPersist(locationUid, rethrow = false) {
    return async dispatch => {
        return await dispatch(genericActions.genericAsyncAction(asyncAction, undefined, rethrow));

        async function asyncAction() {
            if (!locationUid)
                return;

            const serverLocationInfo = await locationService.getLocationInfo(locationUid, false);
            return LocationInfoMapper.mapServerToLocal(serverLocationInfo);
        }
    }
}


function getCreationInvitation(invitationUid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (
                Selectors.getPermissionSelectedLocation(getState()) ===
                LOCATION_PERMISSIONS.OWNER
            )
                return;

            const serverInvitation = await invitationsService.getInvitation(
                invitationUid,
            );
            const invitationData =
                InvitationsMapper.mapServerToLocal(serverInvitation);
            dispatch(updateLocationInfo({ invitationData }));
        }
    };
    
}