import React from 'react';

import { StatusCell, SwitcherButton } from '../_components';
import { DEVICE_INFO_CELLS } from '../_constants/';
import { Device } from '../_models/Device';
import { SwitchOnOff } from "../_components/SwitchOnOff";
import { SwitchOnOffPause } from "../_components/SwitchOnOffPause";
import { SwitchIO } from "../_components/SwitchIO";
import { SwitchIOInverted } from "../_components/SwitchIOInverted";

export const DeviceUiHelper = {
    getUiButtonForDevice,
};

function getUiButtonForDevice(device = Device(), handleButton = (actionStatus, device) => {}) {

    const { cellType, status, remote } = device;
    const actionFn = actionStatus => handleButton(actionStatus, device);

    switch (cellType) {
        case DEVICE_INFO_CELLS.TOGGLE:
            return <SwitcherButton remote={remote} onClick={() => handleButton(1, device) } size='SMALL' />;
        case DEVICE_INFO_CELLS.TRISTATE_IO:
            return <SwitchIO
                remote={remote}
                deviceStatus={status}
                handleSendAction={actionFn}
            />;
        case DEVICE_INFO_CELLS.TRISTATE_IO_INVERTED:
            return <SwitchIOInverted
                remote={remote}
                deviceStatus={status}
                handleSendAction={actionFn}
            />;
        case DEVICE_INFO_CELLS.TRISTATE_ON_OFF:
            return <SwitchOnOff
                remote={remote}
                deviceStatus={status}
                handleSendAction={actionFn}
            />;
        case DEVICE_INFO_CELLS.TRISTATE_IO_PAUSE:
            return <SwitchOnOffPause
                remote={remote}
                deviceStatus={status}
                handleSendAction={actionFn}
            />;

        case DEVICE_INFO_CELLS.STATUS:
            return <StatusCell
                remote={remote}
                status={status}
                offStatus={0}
            />;

        default:
            return null;
    }
}