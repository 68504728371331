import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { LocationInfo } from "../_models/LocationInfo";
import { SimpleToggle, MainPageWithButtons } from "../_components";
import { protectActions } from "../_actions/protect.actions";
import { Device } from "../_models/Device";
import { DeviceHelper } from "../_helpers";
import { devicesActions } from "../_actions";
import { IsoStringDatePicker } from "../_components/IsoStringDatePicker";
import { ListedGroup } from "../_components/ListedGroup";
import { Selectors } from "../_reducers/app.reducer";

export function ProtectSettings() {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const locationInfo  = useSelector(Selectors.getLocationInfo);
    const devices       = useSelector(Selectors.getSelectedLocationDevices);

    function buttonTypes() {
        return {
            ADD: "ADD",
            REMOVE: "REMOVE",
        };
    };

    //VIEW
    return <MainPageWithButtons
        pageTitle={t("settings.settings")}
        content={content()}
    />

    function content() {
        return (
            <div
                className="
                    w-100
                    mh-100

                    h-100

                    d-flex
                    no-gutters
                    flex-column
                    
                    overflow-auto
                "
            >
                {locationInfo?.showProtectLocation && locationSettings(locationInfo)}
                {scheduleSettings(locationInfo)}
                {devicesFn(devices)}
            </div>
        );
    }

    function locationSettings(locationInfo) {
        return (
            <div className="mt-2">
                <div className="
                    font-weight-bold
                ">
                    {t("common.location")}
                </div>
                <hr className="m-0"/>

                {singleLineToggle(
                    t("protect.enableWhenEveryoneLeaves"),
                    locationInfo.protectOnExit,
                    () => handleToggleProtectOnExit(),
                )}
                {singleLineToggle(
                    t("protect.disableWhenSomeoneArrives"),
                    locationInfo.protectOnEnter,
                    () => handleToggleProtectOnEnter(),
                )}
                <p class="text-danger pt-2 p-1 help-block">{t('protect_location_settings_will_disabled_warning')}</p>
            </div>
        );
    }

    function scheduleSettings(locationInfo = LocationInfo()) {
        return (
            <div className="mt-2">
                <div className="
                    font-weight-bold
                ">
                    {t("protect.sleepSchedule")}
                </div>
                <hr className="m-0"/>

                {singleLineToggle(
                    t("protect.enableSchedule"),
                    locationInfo.protectScheduleEnabled,
                    () => handleToggleProtectSchedule(),
                )}

                {locationInfo.protectScheduleEnabled && <>
                    {singleLineDateSelector(
                        t("common.from"),
                        locationInfo.protectScheduleBegin,
                        (time) => handleupdateProtectScheduleBegin(time)
                    )}
                    {singleLineDateSelector(
                        t("common.to"),
                        locationInfo.protectScheduleEnd,
                        (time) => handleUpdateProtectScheduleEnd(time)
                    )}
                </>}
            </div>
        );
    }

    function devicesFn(devices) {
        const { protectDevices, nonProtectDevices } = DeviceHelper.filterProtectDevices(devices);

        return (
            <div className="
                mt-2
                h-100
                c-min-h-15rem

                d-flex
                no-gutters
                flex-column
            ">
                <div className="
                    font-weight-bold
                ">
                    {t("devices.devices")}
                </div>
                <hr className="m-0" />

                <div className="
                    d-flex
                    no-gutters

                    pt-1

                    overflow-auto
                ">
                    <div className="col">
                        {selectableDevicesList(
                            buttonTypes().REMOVE,
                            protectDevices,
                        )}
                    </div>
                    <div className="mr-2" />
                    <div className="col">
                        {selectableDevicesList(
                            buttonTypes().ADD,
                            nonProtectDevices,
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function selectableDevicesList(buttonType, devices) {
        const title = buttonType === buttonTypes().ADD
            ? t("common.notIncluded")
            : t("common.included")
        ;

        return (
            <ListedGroup
                isInnerScrollEnabled={true}
                headerText={title}
                elements={devices.map(device =>
                    singleDevice(device, buttonType)
                )}
            />
        );
    }

    function singleDevice(device = Device(), buttonType) {

        let uiButtonType = "c-btn-primary";
        let actionButtonName = "+";

        if (buttonType === buttonTypes().REMOVE) {
            uiButtonType = "btn-danger";
            actionButtonName = "-";
        }

        return (
            <div key={device.uid} className="
                flex-shrink-0

                d-flex
                justify-content-between
                align-items-center

                p-1
            ">
                <div className="pl-2">
                    {device.description}
                </div>

                <div
                    className={`
                        flex-shrink-0
                        rounded
                        c-cursor-default
                        c-ft-m
                        c-w-2rem
                        text-center
                        ${uiButtonType}`}
                    onClick={() => handleToggleDeviceFromProtect(device)}
                >
                    {actionButtonName}
                </div>
            </div>
        )
    }

    function singleLineDateSelector(name, value, onChange) {
        return (
            singleLine(
                name,
                <IsoStringDatePicker
                    isoString={value}
                    showTimeSelectOnly
                    onChange={onChange}
                />,
            )
        );
    }

    function singleLineToggle(name, enabled, handleChange) {
        return singleLine(
            name,
            <SimpleToggle
                handleChange={handleChange}
                checked={enabled}
                size={"MEDIUM"}
            />,
        );
    }

    function singleLine(name, rightElement) {
        return (
            <>
                <div className="
                    d-flex
                    no-gutters
                    justify-content-between
                    align-items-center

                    m-1
                ">
                    <div className="c-ft-sm text-capitalize">
                        {name}
                    </div>
                    {rightElement}
                </div>

            <hr className="m-0"/>
            </>
        );
    }

    //ACTION HANDLERS
    function handleToggleDeviceFromProtect(device) {
        dispatch(devicesActions.toggleDeviceFromProtect(device));
    }

    function handleToggleProtectOnEnter() {
        dispatch(protectActions.toggleProtectOnEnter());
    }

    function handleToggleProtectOnExit() {
        dispatch(protectActions.toggleProtectOnExit());
    }

    function handleToggleProtectSchedule() {
        dispatch(protectActions.toggleProtectSchedule());
    }

    function handleUpdateProtectScheduleEnd(time) {
        dispatch(protectActions.updateProtectScheduleEnd(time));
    }

    function handleupdateProtectScheduleBegin(time) {
        dispatch(protectActions.updateProtectScheduleBegin(time));
    }
}
