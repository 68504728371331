import React from "react";

import { SUBSCRIPTION_STATUS } from "../_constants/subscriptions.constants";
import { TimeHelper } from "../_helpers";
import { Subscription } from "../_models/Subscription";

export function SubscriptionStatusAndDuration({
    subscription = Subscription(),
    timezone,
    t,
}) {
    
    //VIEW
    if (subscription.status !== SUBSCRIPTION_STATUS.ACTIVE)
        return subscription.statusLabel;

    if (subscription.isAutomatedPayment
        && subscription.status === SUBSCRIPTION_STATUS.ACTIVE)
    {
        return (
            <div className="text-right">
                {subscription.statusLabel}
            </div>
        );
    }

    return (
        <div className="text-right">
            <span>
                {t("common.active") + " "}
            </span>
            <span className="text-lowercase">
                {t("common.until") + " "}
            </span>
            {TimeHelper.localizeIsoStringToFormat(
                subscription.end,
                timezone,
                TimeHelper.getTimeFormats().DATE_NO_SECONDS,
            )}
        </div>
    );
}