import { CircleAvatar } from "../_components/CircleAvatar";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import moreIcon from '../_assets/appIcons/icon_more_vert_white.svg';
import { LocationCustomization } from "../_models/LocationCustomization";
import { useSelector } from "react-redux";
import { Selectors } from "../../Everou/_reducers/app.reducer";


export function UserDropdown({
    onLogOut = () => {},
    onLogOutAll = () => {},
    user,
}) {

    let customization               = LocationCustomization();
    customization                   = useSelector(Selectors.getLocationCustomization);

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [isUserOpen, setIsUserOpen] = useState(false);

    //VIEW
    return (
        <div className="
            col-3

            d-flex
            no-gutters
            justify-content-end
            align-items-center

            cp

            text-light
        "
            onClick={() => setIsUserOpen(!isUserOpen)}
            onMouseLeave={() => setIsUserOpen(false)}
        >
            {userName()}
            {userAvatar()}
            <div className="ml-2"/>
            <img
                className="c-img-2rem"
                src={moreIcon}
                alt="dropdown icon"
            />
            {logOutDropDown()}
        </div>
    );

    function userName() {
        return (
            <div className="
                my-auto
                text-right
                text-truncate
                c-ft-line-height-1rem

                c-text-shadow
            ">
                <div className="
                    text-capitalize
                    c-font-weight-medium
                    d-block
                    text-truncate
                ">
                    {user.name}
                </div>
                <div className="
                    font-weight-light
                    d-block
                    text-truncate
                ">
                    {user.email}
                </div>
            </div>
        );
    }

    function userAvatar() {
        return (
            <CircleAvatar
                color="white"
                textColor={customization?.headerColor ?? "#202020"}
                names={[user.name, user.lastName]}
                className="flex-shrink-0 ml-2"
                style={{ height: "40", width: "40" }}
                srcImg={user.avatar}
            />
        );
    }

    function logOutDropDown() {
        return (
            <div className={`
                mt-0
                dropdown-menu
                dropdown-menu-right
                ${isUserOpen ? "show" : null}

                c-new-ft-m
            `}>
                <button
                    className="dropdown-item"
                    onClick={onLogOut}
                >
                    {t("header.logOut")}
                </button>

                <button
                    className="dropdown-item"
                    onClick={onLogOutAll}
                >
                    {t("header.logOutAllDevices")}
                </button>
            </div>
        );
    }
}