export const COMMON_POPUP_IDS = {
    GENERIC_NOTIFICATION:   "GENERIC_NOTIFICATION",
    LOGOUT:                 "LOGOUT",
    LOGOUT_ALL:             "LOGOUT_ALL",
    BASE_APP_GLOBAL:        "BASE_APP_GLOBAL"
};

export const GLOBAL_POPUPS = {
    ...COMMON_POPUP_IDS,

    ENABLE_PROTECT:                 "ENABLE_PROTECT",
    DELETE_WIDGET:                  "DELETE_WIDGET",
    DELETE_WIDGET_GROUP:            "DELETE_WIDGET_GROUP",
    DELETE_LOCATION:                "DELETE_LOCATION",
    DELETE_INVITATION:              "DELETE_INVITATION",
    ACCEPT_INVITATION:              "ACCEPT_INVITATION",
    DELETE_SUPERGUEST_INVITATION:   "DELETE_SUPERGUEST_INVITATION",
    DELETE_RESERVATION_CONFIG:      "DELETE_RESERVATION_CONFIG",
    UPDATE_RESERVATION_CONFIG:      "UPDATE_RESERVATION_CONFIG",
    ADD_LOCATION:                   "ADD_LOCATION",
    EMERGENCY_OPEN:                 "EMERGENCY_OPEN",
    EDIT_RESERVATIONS_SUBSCRIPTION: "EDIT_RESERVATIONS_SUBSCRIPTION",
    ADD_PACK_CONFIRMATION:          "ADD_PACK_CONFIRMATION",
    CARD_ALREADY_CONFIGURED:        "CARD_ALREADY_CONFIGURED",
    INPUT_CARD_CODE:                "INPUT_CARD_CODE",
    REMOVE_AUDIT_FILTER_PRESET:     "REMOVE_AUDIT_FILTER_PRESET",
    ADD_SUBSCRIPTION:               "ADD_SUBSCRIPTION",
    DELETE_CLOCKIN:                 "DELETE_CLOCKIN",
    ADD_NEW_PACK:                   "ADD_NEW_PACK",
    DELETE_PAYMENT_CARD:            "DELETE_PAYMENT_CARD",
    INVITATIONS_CHECKOUT:           "INVITATIONS_CHECKOUT",
    REMOVE_DEVELOPER:               "REMOVE_DEVELOPER",
    REMOVE_GROUP:                   "REMOVE_GROUP",
    EXPORT_CLOCKINS:                "EXPORT_CLOCKINS",
    PROTECT_SUSCRIPTION_CONFIRM:    "PROTECT_SUSCRIPTION_CONFIRM",
    REMOVE_START_END_DATE_INVITATION: "REMOVE_START_END_DATE_INVITATION",
    DELETE_RESERVATION:             "DELETE_RESERVATION",
};