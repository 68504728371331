export function Category({
    id,
    name,
    active = false,
    devicesAmount
} = {}) {
    return {
        id,
        name,
        active,
        devicesAmount
    };
}