import React, { useState, useEffect } from "react";
import Processor from "asciidoctor";

import "./asciidoctor.scss";
import "./clean.scss";

async function adocToHtml(adocFile) {
    const respose = await fetch(adocFile);
    const text = await respose.text();
    const asciidoctor = Processor();
    return asciidoctor.convert(
        text,
        {
            safe: "server",
            attributes:{
                showtitle: true,
                icons: "font",
            },
        },
    );
}

export function AdocContent({ adocFile } = {}) {

    //LOCAL STATE
    const [adocHtml, setAdocHtml] = useState("");

    //INITIALIZATION
    useEffect(() => {
        get();
        async function get() {
            const html = await adocToHtml(adocFile);
            setAdocHtml(html);
        }
    }, [adocFile]);

    //VIEW
    return (
        <div className="asciidocdiv"
            style={{ all: "initial" }}
            dangerouslySetInnerHTML={ { __html: adocHtml } }
        />
    );
}