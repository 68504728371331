import { i18n } from "../../Common/_translations";

export const PMSLocationDetailViewModes = {
    ACTIVITY        : "ACTIVITY",
    RESERVATIONS    : "RESERVATIONS",
    DEVICES         : "DEVICES",
    CONFIGURATION   : "CONFIGURATION",
};

export const viewModeNames = {
    [PMSLocationDetailViewModes.ACTIVITY]: i18n.t("global_activity"),
    [PMSLocationDetailViewModes.RESERVATIONS]: i18n.t("mod_integrations_reservations_title"),
    [PMSLocationDetailViewModes.DEVICES]: i18n.t("global_devices"),
    [PMSLocationDetailViewModes.CONFIGURATION]: i18n.t("global_settings"),
}

export const PMS_LOCATION_VIEW_MODE_ACTION_TYPES = {
    CHANGE_VIEW_MODE: "CHANGE_VIEW_MODE",
}