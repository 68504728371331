import { ServerIntegrationSend } from "../../Everou/_modelsTS/Integration"
import { ServiceHelper } from "../../Everou/_services/ServiceHelper"
import { PMS_API_URLS } from "../_constants/PMS_API_URLS"

export const PMSIntegrationsService = {
    list,
    create,
    deleteIntegration,
}

async function list() {
    return ServiceHelper.getRequest(PMS_API_URLS.INTEGRATIONS)
}

async function create(serverIntegration: ServerIntegrationSend) {
    return ServiceHelper.putRequest(PMS_API_URLS.INTEGRATIONS, { ...serverIntegration })
}

async function deleteIntegration(uid: string) {
    return ServiceHelper.deleteRequest(PMS_API_URLS.INTEGRATIONS, null, { uid });
}