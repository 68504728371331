import { useEffect } from "react";
import { CSSProperties, useState } from "react";
import { Accordion } from "react-bootstrap";

type ClassNameProps = {
    container?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
};

type CustomAccordionProps = {
    header?: any;
    body: any;
    eventKey?: number;
    onClick?: (...args: any) => void;
    style?: ClassNameProps;
    setOpen?: boolean;
    preventDefault?: boolean;
};

export function SingleAccordion({
    header,
    body,
    eventKey = 0,
    onClick,
    style,
    setOpen,
}: CustomAccordionProps) {

    const [activeKey, setActiveKey] = useState(setOpen ? 0 : 1);
    
    useEffect(() => { setActiveKey(setOpen ? 0 : 1) }, [setOpen])

    return (
            <Accordion style={style?.container} activeKey={activeKey.toString()}>
                <Accordion.Item
                    style={style?.container ?? {}}
                    eventKey={eventKey.toString()}
                >
                    {header && (
                        <Accordion.Header
                            onClick={onClick}
                            style={style?.header ?? {}}
                            as="div"
                        >
                            {header}
                        </Accordion.Header>
                    )}
                    <Accordion.Body as={"div"} style={style?.body ?? {}}>
                        {body}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
    );
}
