import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputBox } from "./InputBox";
import { Icons } from "../_assets";
import { CapsuleButton } from '../_components';
import { BaseLoginPage } from './BaseLoginPage';

export function EmailCodeInputPage({
    codeInputTestID,
    sendCodeButtonTestID,
    email,
    handleGoBack = () => {},
    handleLogin = () => {},
    handleRequestCode = () => {},
    handleEditEmail = email => {},
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL STATE
    const [emailCode, setEmailCode] = useState("");

    //LOCAL HANDLERS
    function handleChangeCode(e) {
        setEmailCode(e.target.value);
    }

    function handleSubmit() {
        if (email && emailCode) {
            handleLogin(email, emailCode);
            return;
        }

        if (email)
            handleRequestCode(email);
    }

    //VIEW
    return <BaseLoginPage content={content()}/>;

    function content() {
        return (
            <div>
                <form onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                    {emailInput()}
                    <div className="mt-2"/>
                    {codeInput()}

                    {/* input needed for enter key to work */}
                    <input type="submit" className="d-none"/>
                </form>

                <div className="mt-4"/>
                {sendButton()}
                <div className="mt-2"/>
                {goBackButton()}
            </div>
        );
    }

    function goBackButton() {
        return <CapsuleButton
            text={t("global_cancel")}
            onClick={handleGoBack}
            classNameExtra='w-100'
        />;
    }

    function emailInput() {
        return <InputBox
            value={email}

            imgSrc={Icons.at}
            placeholder={t("global_email")}
            handleChange={e => handleEditEmail(e.target.value)}
        />;
    }

    function codeInput() {
        return <InputBox
            inputTestID={codeInputTestID}
            value={emailCode}
            handleChange={handleChangeCode}

            imgSrc={Icons.lock}
            placeholder={t("global_code")}
        />;
    }

    function sendButton() {
        return <CapsuleButton
            testId={sendCodeButtonTestID}
            text={text()}
            onClick={handleSubmit}
            fillStyle={'FILLED'}
            classNameExtra='w-100'
        />;
 
        function text() {
            return emailCode
                ? t("login_login_button")
                : t("login_access_request_button")
            ;
        }
    }
}