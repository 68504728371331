import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ContentSpace} from "../_components";
import { BudgetsActions } from "../_actions/BudgetsActions";
import { BudgetsStore } from "../_reducers/budgetsRootReducer";
import { BUDGETS_MODULE_VIEWS } from "./BUDGETS_MODULE_VIEWS";
import { NewBudgetView } from "./NewBudgetView";
import { EditBudgetView } from "./EditBudgetView";
import { ClientActions } from "../_actions/ClientActions";
import { BudgetsListView } from "./BudgetsListView";

export function BudgetsModule() {

    //LIBRARIES
    const dispatch = useDispatch();

    //GLOBAL STATE
    const secondaryView     = useSelector(BudgetsStore.selectors.getBudgetsModuleSecondaryView);
    const budgets           = useSelector(BudgetsStore.selectors.getBudgets);
    const selectedBudget    = useSelector(BudgetsStore.selectors.getSelectedBudget);
    const selectedBudgetUid = useSelector(BudgetsStore.selectors.getSelectedBudgetUid);
    const clients           = useSelector(BudgetsStore.selectors.getClients);
    const products          = useSelector(BudgetsStore.selectors.getProducts);
    const entries           = useSelector(BudgetsStore.selectors.getEntries);

    //ACTION HANDLERS
    const handleGoBack = useCallback(() => 
        dispatch(BudgetsActions.changeSecondaryView(null))
    , [dispatch]);

    const handleCloseEditBudget = useCallback(() => 
        dispatch(BudgetsActions.selectedBudget(null))
    , [dispatch]);

    //CLEAN UP
    useEffect(() => {
        return () => dispatch(BudgetsActions.changeSecondaryView(null));
    }, [dispatch]);

    //INITIALIZATION
    useEffect(() => {
        dispatch(BudgetsActions.listBudgets());
        dispatch(ClientActions.listClients());
    }, [dispatch]);

    //SECONDARY VIEW MANAGEMENT
    useEffect(() => {
        if (!selectedBudgetUid)
            return;

        dispatch(BudgetsActions.getBudgetAndEntries(selectedBudgetUid));
        dispatch(BudgetsActions.changeSecondaryView(BUDGETS_MODULE_VIEWS.EDIT_BUDGET));
    }, [dispatch, selectedBudgetUid]);

    useEffect(() => {
        if (!selectedBudgetUid)
            return;

        dispatch(BudgetsActions.getBudgetOnly(selectedBudgetUid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, entries]);

    //VIEW
    return (
        <ContentSpace
            mainContent={<BudgetsListView {...{
                budgets,
                selectedBudgetUid,
                clients,
            }}/>}
            detailedContent={getSecondaryView()}
            onGoBack={handleGoBack}
            hasFullscreenMode={secondaryView === BUDGETS_MODULE_VIEWS.EDIT_BUDGET}
        />
    );

    function getSecondaryView() {
        switch (secondaryView) {

            case BUDGETS_MODULE_VIEWS.NEW_BUDGET:
                return <NewBudgetView
                    clients={clients}
                />;

            case BUDGETS_MODULE_VIEWS.EDIT_BUDGET:
                return <EditBudgetView
                    budget={selectedBudget}
                    onClose={handleCloseEditBudget}
                    products={products}
                    entries={entries}
                />;
            
            default:
                return null;
        }
    }
}