import React from 'react';

Title.SIZES = {
    BIG: "BIG",
    NORMAL: "NORMAL",
};

export function Title({ text, size = Title.SIZES.NORMAL, className = '' }) {
    className = `${className} font-weight-bold text-uppercase`;
    if(size === Title.SIZES.BIG) {
        className += "c-new-ft-l"
    }
    return <div className={className}>{text}</div>
        
}