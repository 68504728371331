import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import queryString from 'query-string';
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";

export function PMSOctorateRedirectPopup({
    props
}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        let params = queryString.parse(props.location.search);
        if (params.code !== undefined) {
            let code = params.code;
            dispatch(PMSIntegrationsActions.octorateToken(code));
            setTimeout(() => window.close(), 3000);
        }
    }, [dispatch]);

    return (
        <div>

        </div>
    );
}