import { LOCATION_ACTION_TYPES } from '../_constants';
import { locationService, invitationsService } from '../_services';
import { uiAlertActions } from '.';
import { invitationsActions } from './invitations.actions';
import { LocationMapper } from '../_mappers';
import { LOCATION_PERMISSIONS, appPermissions } from '../_constants/permissions.constants';
import { LocationModel } from '../_models/LocationModel';
import { i18n } from '../_translations/i18n';
import { genericActions } from './generic.actions';
import { UserMapper } from '../_mappersTS/UserMapper';
import { globalActions } from './global.actions';
import { Selectors } from '../_reducers/app.reducer';
import { locationInfoActions } from './locationInfo.actions';

export const locationActions = {
    //LOCAL
    stopAdding,
    add,
    selectLocation,
    validateLocation,
    selectLocationFromHeader,
    
    //REMOTE
    list,
    removeSelectedLocation,
    getUsersInLocation,
    getProductsInfo,
    getLocationUsers,
    createLocation,

    updateLocationName,
    updateCompanyName,
    updateCompanyContactEmail,
    updateCompanyLegalId,
    updateCompanyAdditionalInfo,
    updateBarrierMode,

    uploadVideo,
};

function updateLocationName(name, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.updateLocationName({
                uid: locationUid,
                name,
            });
            await dispatch(locationInfoActions.getLocationInfo(locationUid));
            await dispatch(locationActions.list())
        }
    }
}

function updateBarrierMode(barrierMode, locationUid) {
    return async dispatch => {
        return dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.updateBarrierMode({
                uid: locationUid,
                barrierMode
            });
            await dispatch(locationInfoActions.getLocationInfo(locationUid));
        }
    }
}

function updateCompanyName(companyName, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.updateCompanyName({
                uid: locationUid,
                companyName,
            });
            await dispatch(locationInfoActions.getLocationInfo(locationUid));
        }
    }
}

function updateCompanyContactEmail(companyContactEmail, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.updateCompanyContactEmail({
                uid: locationUid,
                companyContactEmail,
            });
            await dispatch(locationInfoActions.getLocationInfo(locationUid));
        }
    }
}

function getProductsInfo(uid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const productsInfo = await locationService.getProductsInfo({
                uid,
            });
            await dispatch(locationInfoActions.updateLocationInfo({ productsInfo }));
        }
    }
}

function updateCompanyLegalId(companyLegalId, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.updateCompanyLegalId({
                uid: locationUid,
                companyLegalId
            });
            await dispatch(locationInfoActions.getLocationInfo(locationUid));
        }
    }
}

function updateCompanyAdditionalInfo(additionalInfo, locationUid) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.updateCompanyAdditionalInfo({
                uid: locationUid,
                additionalInfo
            });
            await dispatch(locationInfoActions.getLocationInfo(locationUid));
        }
    }
}

function validateLocation(selectedLocationUid) {
    return (dispatch, getState) => {
        // LOCATIONS LIST IS EXPECTED TO BE UPDATED AT THIS POINT
        let locations = [LocationModel()];
        locations = Selectors.getLocations(getState());

        if (!locations.length) {
            dispatch(globalActions.setPreselectedLocationUid(null));
            dispatch(globalActions.setValidatedLocationUid(null));
            dispatch(locationActions.selectLocation(null));
            dispatch(locationInfoActions.getLocationInfo(null))
            return;
        }

        if (selectedLocationUid === null || !doesLocationExist()) {
            preselectAnotherLocationOrNull();
            return;
        }

        dispatch(globalActions.setValidatedLocationUid(selectedLocationUid));
        return;

        function doesLocationExist() {
            return locations.some(location => location.uid === selectedLocationUid);
        }

        function preselectAnotherLocationOrNull() {
            const location = getAnotherLocation();

            const uidToValidate = location ? location.uid : null;
            dispatch(globalActions.setPreselectedLocationUid(uidToValidate));
            return;

            function getAnotherLocation() {
                const ownerLocation =
                    locations
                    .find(location => location.userType === LOCATION_PERMISSIONS.OWNER)
                ;

                const installerLocation =
                    locations
                    .find(location => location.userType === LOCATION_PERMISSIONS.INSTALLER)
                ;

                const adminLocation =
                    locations
                    .find(location => location.userType === LOCATION_PERMISSIONS.ADMIN)
                ;

                const guestLocation =
                    locations
                    .find(location => location.userType === LOCATION_PERMISSIONS.GUEST)
                ;

                return ownerLocation
                    || installerLocation
                    || adminLocation
                    || guestLocation
                ;
            }
        }
   };
}

function add() {
    return dispatch => {
        dispatch(invitationsActions.clearValidatedInvitation());
        dispatch({
            type: LOCATION_ACTION_TYPES.LOCATIONS_ADD,
        });
    };
}

function stopAdding() {
    return {
        type: LOCATION_ACTION_TYPES.LOCATIONS_STOP_ADDING,
    };
}

function list() {
    return async dispatch => {
        try {
            const serverLocations = await locationService.list();
            const localLocations = LocationMapper.mapAllServerToLocal(serverLocations);
            dispatch(listLocationsSuccess(localLocations));
            return localLocations;
        } catch (error: any) {
            dispatch(listLocationsFailure(error.toString()));
            dispatch(uiAlertActions.error(error.toString()));
            return [];
        }
    };

    function listLocationsSuccess(locations) {
        return {
            type: LOCATION_ACTION_TYPES.LOCATIONS_LIST_SUCCESS,
            locations,
        };
    }

    function listLocationsFailure(error) {
        return {
            type: LOCATION_ACTION_TYPES.LOCATIONS_LIST_FAILURE,
            error,
        };
    }
}

function removeSelectedLocation() {
    return async (dispatch, getState) => {
        try {
            const locationUid = Selectors.getSelectedLocationUid(getState());
            const invitationUid = Selectors.getLocations(getState())
                .find(location => location.uid === locationUid)
                .invitationUid;

            const removeLocationServiceFn = isLocationOwner(invitationUid)
                ? () => locationService.removeLocation(locationUid)
                : () => invitationsService.cancelInvitation(invitationUid)
            ;
            await removeLocationServiceFn();
            
            dispatch(success(locationUid));
            dispatch(globalActions.setPreselectedLocationUid(null));
            dispatch(uiAlertActions.success(i18n.t("locations.locationRemoved")));
        } catch (error: any) {
            dispatch(uiAlertActions.error(error.toString()));
            console.error(error);
        }

        function isLocationOwner(invitationUid){
            return invitationUid === null;
        }

        function success(uid) {
            return {
                type: LOCATION_ACTION_TYPES.LOCATIONS_REMOVE_SUCCESS,
                uid,
            };
        }
    };
}

function selectLocationFromHeader(location = LocationModel()) {
    return dispatch => {
        dispatch(globalActions.setPreselectedLocationUid(location.uid));
    };
}

function selectLocation(locationUid) {
    return {
        type: LOCATION_ACTION_TYPES.LOCATIONS_SELECT,
        locationUid,
    };
}

function getUsersInLocation() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverUsers = await locationService.getUsersInLocation(Selectors.getSelectedLocationUid(getState()));
            const localUsers = UserMapper.allServerToLocal(serverUsers);
            dispatch({
                type:LOCATION_ACTION_TYPES.GET_USERS_IN_LOCATION_SUCCESS,
                usersInside: localUsers,
            });
        }
    }
}

function getLocationUsers(locationUid, includeClockinUsers = false) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const permission = Selectors.getPermissionSelectedLocation(getState());
            if (!appPermissions.canViewLocationUsers(permission)) {
                return;
            }
            const serverUsers = await locationService.getLocationUsers(
                locationUid || Selectors.getSelectedLocationUid(getState()),
                includeClockinUsers
            );
            const localUsers = UserMapper.allServerToLocal(serverUsers);
            dispatch({
                type:LOCATION_ACTION_TYPES.GET_LOCATION_USERS,
                users: localUsers,
            });
        }
    }
}

function createLocation(name) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverLocation = await locationService.create(name);
            dispatch(
                globalActions.setPreselectedLocationUid(serverLocation.loc_uid)
            );
        }
    }
}

function uploadVideo(video, locationUid) {
    return async dispatch => {
        return dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await locationService.uploadVideo({
                locationUid,
                video
            });
            await dispatch(locationInfoActions.getLocationInfo(locationUid));
        }
    }
}