import { genericActions } from "../_helpers/ActionErrorWrapper";

import { EntryCollection } from "../_generics/EntryCollection";
import { EntriesService } from "../_services/EntriesService";
import { EntryMapper } from "../_mappers/EntryMapper";

export const EntriesActions = {
    //PUBLIC
    createEntry,
    deleteEntry,
    updateEntry,
    listEntriesSuccess: EntryCollection.actionListItemsSuccess,
    deleteAllEntriesSuccess: EntryCollection.actionDeleteAllItemsSuccess,

    //PRIVATE
    _getEntrySuccess: EntryCollection.actionGetItemSuccess,
    _updateEntrySuccess: EntryCollection.actionUpdateItemSuccess,
    _deleteEntrySuccess: EntryCollection.actionDeleteItem,
};

function createEntry({
    budgetUid,
    amount,
    discount,
    productType,
    productPrice,
    productName,
} = {}) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!budgetUid) {
                console.warn("NO BUDGET SELECTED FOR ENTRY");
                return;
            }

            const serverEntry = await EntriesService.createEntry({
                budgetUid,
                amount,
                discount,
                productType,
                productPrice,
                productName,
            });

            dispatch(EntriesActions._getEntrySuccess(
                EntryMapper.serverToLocal(serverEntry)
            ));
        }
    };
}

function deleteEntry(uid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!uid) {
                console.warn("NO ENTRY SELECTED");
                return;
            }

            await EntriesService.deleteEntry(uid);
            dispatch(EntriesActions._deleteEntrySuccess(uid));
        }
    };
}

function updateEntry({
    uid,
    amount,
    discount,
    productType,
    productPrice,
    productName,
} = {}) {
    return async dispatch => {
        dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!uid) {
                console.warn("NO ENTRY SELECTED");
                return;
            }

            const serverEntry = await EntriesService.updateEntry({
                uid,
                amount,
                discount,
                productType,
                productPrice,
                productName,
            });

            dispatch(EntriesActions._updateEntrySuccess(
                EntryMapper.serverToLocal(serverEntry)
            ));
        }
    };
}