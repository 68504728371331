import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LogoHeader } from "./_components";
import { Icons } from "../Common/_assets/icons/Icons";
import { BudgetsStore } from "./_reducers/budgetsRootReducer";

import { BudgetUserActions } from "./_actions/BudgetUserActions";
import { BridgeUser } from "../Common/_models/BridgeUser";
import { BudgetsModules } from "./BudgetsModules";
import { BudgetsModulesActions } from "./_actions/BudgetsModulesActions";
import { DistributorActions } from "./_actions/DistributorActions";
import { ProductsActions } from "./_actions/ProductsActions";
import { BudgetsStoreSubscriber } from "./BudgetsStoreSubscriber";
import { WiredUserDropdown } from "../Everou/App/WiredUserDropdown";
import { WiredPopUpRenderer } from "../Common/PopUps/WiredPopUpRenderer";
import { WiredUiAlerts } from "../Common/UiAlerts/WiredUiAlerts";
import { WiredContentWithSidebar } from "../Common/WiredContent/WiredContentWithSidebar";
import { AppWrapper } from "../Common/_components/AppWrapper";
import { BudgetsPopUps } from "./BudgetsPopUps";
import { AppInitializationWrapper } from "../Common/_components/AppInitializationWrapper";

export function Budgets({
    bridgeUser = BridgeUser(),
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const distributor   = useSelector(BudgetsStore.selectors.getDistributor);
    const initialUrl    = useSelector(BudgetsStore.selectors.getInitialUrl);

    //LOCAL STATE
    const distributorUid = distributor && distributor.uid;
    const [isInitialized, setIsInitialized] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(false);

    //INITIALIZATION
    useEffect(() => {
        distributorValidation();

        async function distributorValidation() {
            BudgetsStoreSubscriber.subscribe();

            const budgetUser = await dispatch(BudgetUserActions.getBudgetUser(bridgeUser));
            const localDistributor = await dispatch(DistributorActions.getDistributor(budgetUser));
            const distributorUid = localDistributor && localDistributor.uid;

            if (!distributorUid)
                setIsFirstTime(true);

            if (distributorUid)
                dispatch(ProductsActions.listProducts());

            setIsInitialized(true);
        }

    }, [dispatch, bridgeUser]);

    useEffect(() => {
        if (!isInitialized)
            return;

        dispatch(BudgetsModulesActions.setUpModules(
            !!distributorUid,
            !isFirstTime && initialUrl,
        ));
    }, [dispatch, initialUrl,
        isInitialized, distributorUid, isFirstTime
    ]);

    //VIEW
    return (
        <AppWrapper>
            <WiredPopUpRenderer
                getComponentFn={BudgetsPopUps.getPopUp}
            />
            <LogoHeader
                logoSrc={Icons.everouIcon}
                content={
                    bridgeUser && <WiredUserDropdown
                        user={bridgeUser}
                    /> 
                }
            />
            <WiredUiAlerts />
            <AppInitializationWrapper
                failureMessage={t("bundgets_not_enabled")}
                isInitialized={isInitialized}
                isSuccessful={bridgeUser.isDistributor}
                content={
                    <WiredContentWithSidebar
                        getComponentForModuleId={BudgetsModules.getComponentForModuleId}
                        getModulesForIds={BudgetsModules.getModulesForIds}
                        selectModuleAction={BudgetsModulesActions.selectModuleId}
                    />
                }
            />
        </AppWrapper>
    );
}
