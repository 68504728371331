import React from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "./BaseAppGlobalPopUp";
import { invitationGroupActions } from "../_actions/invitationGroup.actions";

export function CardAlreadyConfiguredGlobalPopUp({
    invitationGroupName,
    invitationGroupUid,
}) {
    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    return (
        <BaseAppGlobalPopUp
            body={body()}
            acceptButtonName={t("invitation_group_list_existing_dialog_go_to_configuration")}
            cancelButtonName={t("global_ok")}
            onAccept={handleShowConfiguration}
        />
    );

    function body() {
        return t(
            "invitation_group_list_existing_dialog_question_pw",
            { name: invitationGroupName },
        );
    }

    //ACTION HANDLERS
    function handleShowConfiguration() {
        dispatch(
            invitationGroupActions.selectGroupByUid(
                invitationGroupUid
            )
        );
    }
}