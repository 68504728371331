import React from 'react';

export function StatusCell({
    status,
    offStatus,
} = {}) {
    return (
        <div className={`
            rounded-circle
            c-img-1rem
            c-outline-190

            ${status === offStatus
                ? "c-bg-grey-250"
                : "c-bg-primary"
            }
        `} />
    );
}