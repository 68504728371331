import React from "react";
import { connect } from 'react-redux';

import { PopUp } from "./PopUp";
import { dashboardActions } from "../_actions/dashboard.actions";
import { WIDGET_TYPES, WDIGET_TYPE_NAMES } from "../_constants/dashboard.constants";
import { FullHeightSettings } from "../_components/FullHeightSettings";

class WidgetAddSettings extends React.Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleAddWidget = this.handleAddWidget.bind(this);
    }

    render() {
        return ( 
            <PopUp
                handleClose={this.handleClose}
                content={
                    <FullHeightSettings
                        title={"Add widget"}
                        content={this.content()}
                        handleClose={this.handleClose}
                    />
                }
            />
        );
    }

    content() {
        return (
            <div className="d-flex flex-column">
                {this.widgetButtons()}
            </div>
        );
    }

    widgetButtons() {
        return Object.values(WIDGET_TYPES)
            .filter(type => type !== WIDGET_TYPES.ADD_WIDGET)
            .map(widgetType => this.singleWidgetButton(widgetType));
    }

    singleWidgetButton(widgetType) {
        return (
            <label
                key={widgetType}
                className="
                    btn-light
                    c-ft-m
                    px-1 py-3 my-1
                    border rounded
                    text-center
                    font-weight-light
                "
                onClick={() => this.handleAddWidget(widgetType)}
            >
                {WDIGET_TYPE_NAMES[widgetType]}
            </label>
        );
    }

    handleClose() {
        this.props.dispatch(dashboardActions.hideSettings());
    }

    handleAddWidget(widgetType) {
        this.props.dispatch(dashboardActions.addWidget(widgetType));
    }
}

const connectedWidgetAddSettings = connect()(WidgetAddSettings);
export { connectedWidgetAddSettings as WidgetAddSettings };