export const ServiceWorkerHelper = {
    registerWorkerAndSubscribePush,
    unsubscribePushAndWorker,
};

async function unsubscribePushAndWorker() {
    const workerContainer  = navigator.serviceWorker;

    if (!workerContainer)
        return;

    const registration = await workerContainer.getRegistration();

    if (!registration)
        return;

    await unsubscribePush(registration);
    await registration.unregister();

    ////
    async function unsubscribePush(registration) {
        const pushSubscription = await registration.pushManager.getSubscription();
        
        if (!pushSubscription)
            return;

        await pushSubscription.unsubscribe();
    }
}

async function registerWorkerAndSubscribePush(workerName, serverKey) {
    try {
        if (!await allChecksPass(serverKey)) {
            return;
        }

        const registration = await registerServiceWorker(workerName);

        if (!registration.showNotification) {
            console.warn("Notifications not supported");
            return;
        }

        const subscription = await getPushSubscription(registration, serverKey);
        return formatSubscription(subscription);
    } catch (error) {
        console.error(error.toString());
        return undefined;
    }

}

async function allChecksPass(serverKey) {
    if (process.env.NODE_ENV === "test")
        return false;

    if (!serverKey) {
        console.warn("No server key found");
        return false;
    }

    if (!navigator.serviceWorker) {
        console.warn("Service workers not supported");
        return false;
    }

    if (!window.PushManager) {
        console.warn("Push Manager not supported");
        return false;
    }

    if (Notification.permission === "default") {
        await Notification.requestPermission();
    }

    if (Notification.permission === "denied") {
        console.warn("Permission for notifications denied by user");
        return false;
    }

    return true;
}

async function registerServiceWorker(workerName) {
    return await navigator.serviceWorker.register(workerName);
}

async function getPushSubscription(registration, serverKey) {
    const existingSubscription = await registration.pushManager.getSubscription();
    if (existingSubscription !== null) {
        console.log("Already subscribed to push");
        return existingSubscription;
    }
    console.log("Not yet subscribed to push, subscribing");
    return await newPushSubscription(registration, serverKey);
}

async function newPushSubscription(registration, serverKey) {
    const options = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(serverKey),
    };
    return await registration.pushManager.subscribe(options);
}

function formatSubscription(subscription) {
    const { endpoint } = subscription.toJSON();
    const { p256dh, auth } = subscription.toJSON().keys;

    return {
        endpoint,
        keys: {
            p256dh,
            auth,
        },
    };
}

// This function is needed because Chrome doesn't accept a base64 encoded string
// as value for applicationServerKey in pushManager.subscribe yet
// https://bugs.chromium.org/p/chromium/issues/detail?id=802280
function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}