import { GLOBAL_POPUPS } from "../_constants";

import { DeleteWidgetGlobalPopUp } from "./DeleteWidgetGlobalPopUp";
import { DeleteGroupGlobalPopUp } from "./DeleteGroupGlobalPopUp";
import { DeleteLocationGlobalPopUp } from "./DeleteLocationGlobalPopUp";
import { DeleteInvitationGlobalPopUp } from "./DeleteInvitationGlobalPopUp";
import { LogOutGlobalPopUp } from "./LogOutGlobalPopUp";
import { LogOutAllGlobalPopUp } from "./LogOutAllGlobalPopUp";
import { EmergencyOpeningGlobalPopUp } from "./EmergencyOpeningGlobalPopUp";
import { EnableProtectGlobalPopUp } from "./EnableProtectGlobalPopUp";
import { DeleteSuperguestInvitationGlobalPopUp } from "./DeleteSuperguestInvitationGlobalPopUp";
import { DeleteResevationsConfigGlobalPopUp } from "./DeleteResevationsConfigGlobalPopUp";
import { UpdateReservationConfigGlobalPopUp } from "./UpdateReservationConfigGlobalPopUp";
import { EditReservationsSubscriptionGlobalPopUp } from "./EditReservationsSubscriptionGlobalPopUp";
import { AddPackConfirmationGlobalPopUp } from "./AddPackConfirmationGlobalPopUp";
import { CardAlreadyConfiguredGlobalPopUp } from "./CardAlreadyConfiguredGlobalPopUp";
import { InputCardCodeGlobalPopUp } from "./InputCardCodeGlobalPopUp";
import { BaseNotificationGlobalPopUp } from "./BaseNotificationGlobalPopUp";
import { RemoveAuditFilterPresetGlobalPopUp } from "./RemoveAuditFilterPresetGlobalPopUp";
import { AddSubscriptionPopUp } from "../SubscriptionsModule/AddSubscriptionPopUp";
import { AcceptInvitationPopUp } from "../LocationsModule/AcceptInvitationPopUp";
import { CreateLocationGlobalPopUp } from "../LocationsModule/CreateLocationGlobalPopUp";
import { DeleteClockinPopUp } from "../ClockinsModule/DeleteClockinPopUp";
import { AddPackWizardPopUp } from "../InvitationsModule/AddPackWizard/AddPackWizardPopUp";
import { RemoveCardPopUp } from "../PaymentsModule/RemoveCardPopUp";
import { InvitationsCheckOutPopUp } from "../InvitationsModule/InvitationsCheckOutPopUp";
import { ProtectSubscriptionConfirmPopUp } from "../ProtectModule/ProtectSubscriptionConfirmPopUp";
import { RemoveDeveloperPopUp } from "../DevelopersModules/RemoveDeveloperPopUp";
import { COMMON_POPUP_IDS } from "../_constants/GLOBAL_POPUPS";
import { RemoveGroupPopUp } from "../InvitationsModule/RemoveGroupPopUp";
import { ExportClockinsPopUp } from "./ExportClockinsPopUp";
import { BaseAppGlobalPopUp } from '../GlobalPopUps/BaseAppGlobalPopUp';
import { DeleteReservationGlobalPopUp } from "./DeleteReservationGlobalPopUp";

const COMMON_POPUP_COMPONENT_FOR_ID = {
    [GLOBAL_POPUPS.LOGOUT]:                         LogOutGlobalPopUp,
    [GLOBAL_POPUPS.LOGOUT_ALL]:                     LogOutAllGlobalPopUp,
    [GLOBAL_POPUPS.GENERIC_NOTIFICATION]:           BaseNotificationGlobalPopUp,
};

const EVEROU_POPUP_COMPONENT_FOR_ID = {
    ...COMMON_POPUP_COMPONENT_FOR_ID,

    [GLOBAL_POPUPS.DELETE_WIDGET]:                  DeleteWidgetGlobalPopUp,
    [GLOBAL_POPUPS.DELETE_WIDGET_GROUP]:            DeleteGroupGlobalPopUp,
    [GLOBAL_POPUPS.DELETE_LOCATION]:                DeleteLocationGlobalPopUp,
    [GLOBAL_POPUPS.DELETE_INVITATION]:              DeleteInvitationGlobalPopUp,
    [GLOBAL_POPUPS.DELETE_SUPERGUEST_INVITATION]:   DeleteSuperguestInvitationGlobalPopUp,
    [GLOBAL_POPUPS.DELETE_RESERVATION_CONFIG]:      DeleteResevationsConfigGlobalPopUp,
    [GLOBAL_POPUPS.ADD_LOCATION]:                   CreateLocationGlobalPopUp,
    [GLOBAL_POPUPS.EMERGENCY_OPEN]:                 EmergencyOpeningGlobalPopUp,
    [GLOBAL_POPUPS.ENABLE_PROTECT]:                 EnableProtectGlobalPopUp,
    [GLOBAL_POPUPS.UPDATE_RESERVATION_CONFIG]:      UpdateReservationConfigGlobalPopUp,
    [GLOBAL_POPUPS.EDIT_RESERVATIONS_SUBSCRIPTION]: EditReservationsSubscriptionGlobalPopUp,
    [GLOBAL_POPUPS.ADD_PACK_CONFIRMATION]:          AddPackConfirmationGlobalPopUp,
    [GLOBAL_POPUPS.CARD_ALREADY_CONFIGURED]:        CardAlreadyConfiguredGlobalPopUp,
    [GLOBAL_POPUPS.INPUT_CARD_CODE]:                InputCardCodeGlobalPopUp,
    [GLOBAL_POPUPS.REMOVE_AUDIT_FILTER_PRESET]:     RemoveAuditFilterPresetGlobalPopUp,
    [GLOBAL_POPUPS.ACCEPT_INVITATION]:              AcceptInvitationPopUp,
    [GLOBAL_POPUPS.ADD_SUBSCRIPTION]:               AddSubscriptionPopUp,
    [GLOBAL_POPUPS.DELETE_CLOCKIN]:                 DeleteClockinPopUp,
    [GLOBAL_POPUPS.ADD_NEW_PACK]:                   AddPackWizardPopUp,
    [GLOBAL_POPUPS.DELETE_PAYMENT_CARD]:            RemoveCardPopUp,
    [GLOBAL_POPUPS.INVITATIONS_CHECKOUT]:           InvitationsCheckOutPopUp,
    [GLOBAL_POPUPS.PROTECT_SUSCRIPTION_CONFIRM]:    ProtectSubscriptionConfirmPopUp,
    [GLOBAL_POPUPS.REMOVE_DEVELOPER]:               RemoveDeveloperPopUp,
    [GLOBAL_POPUPS.REMOVE_GROUP]:                   RemoveGroupPopUp,
    [GLOBAL_POPUPS.BASE_APP_GLOBAL]:                BaseAppGlobalPopUp,
    [GLOBAL_POPUPS.EXPORT_CLOCKINS]:                ExportClockinsPopUp,
    
};

const RENTAL_POPUP_COMPONENT_FOR_ID = {
    ...COMMON_POPUP_COMPONENT_FOR_ID,

    [GLOBAL_POPUPS.DELETE_RESERVATION]:             DeleteReservationGlobalPopUp,
}

export const CommonPopUps = {
    IDS: COMMON_POPUP_IDS,
    getPopUp: ID => COMMON_POPUP_COMPONENT_FOR_ID[ID],
};

export const EverouPopUps = {
    IDS: GLOBAL_POPUPS,
    getPopUp: ID => EVEROU_POPUP_COMPONENT_FOR_ID[ID],
};

export const RentalPopUps = {
    IDS: GLOBAL_POPUPS,
    getPopUp: ID => RENTAL_POPUP_COMPONENT_FOR_ID[ID],
};