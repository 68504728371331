import { URL_CONSTANTS } from '../_constants';
import { ServiceHelper } from './ServiceHelper';

export const locationService = {
    list,
    getLocationInfo,
    getLocationInvitationConfig,
    removeLocation,
    getUsersInLocation,
    getProductsInfo,
    getLocationUsers,
    create,
 
    updateLocationName,
    updateCompanyName,
    updateCompanyContactEmail,
    updateCompanyLegalId,
    updateCompanyAdditionalInfo,
    updateInterfaceType,
    updateBarrierMode,

    uploadVideo,
};



async function updateLocationName({ uid, name }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            name,
        },
    );
}

async function updateBarrierMode({ uid, barrierMode }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            barrier_mode: barrierMode,
        },
    );
}

async function updateCompanyName({ uid, companyName }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            company_name: companyName,
        },
    );
}

async function updateCompanyContactEmail({ uid, companyContactEmail }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            company_contact_email: companyContactEmail,
        },
    );
}

async function updateCompanyLegalId({ uid, companyLegalId }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            company_legal_id: companyLegalId,
        },
    );
}

async function updateInterfaceType({ loc_uid, interface_type }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_USER_UPDATE,
        {
            loc_uid,
            interface_type,
        },
    );
}

async function getProductsInfo({ uid }) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_PRODUCT_PRICES,
        {
            loc_uid: uid,
        },
    );
}

async function updateCompanyAdditionalInfo({ uid, additionalInfo }) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_UPDATE,
        {
            loc_uid: uid,
            company_additional_info: additionalInfo,
        },
    );
}

function list() {
    return ServiceHelper.getRequest(URL_CONSTANTS.LOCATION_LIST);
}

function removeLocation(uid) {
    const postBody = { loc_uid: uid };
    return ServiceHelper.postRequest(URL_CONSTANTS.LOCATION_REMOVE, postBody);
}

async function getLocationInfo(uid, useCache = true) {
    if (useCache
        && getLocationInfo.cache
        && getLocationInfo.cache.loc_uid === uid
    )   return getLocationInfo.cache;

    const parameters = "?loc_uid=" + uid;
    getLocationInfo.cache = await ServiceHelper.getRequest(URL_CONSTANTS.LOCATION_INFO, parameters);
    return getLocationInfo.cache;
}

async function getLocationInvitationConfig(locationUid) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_INVITATION_SETTINGS_INFO,
        { loc_uid: locationUid },
    );
}

async function getUsersInLocation(locationUid) {
    const response = await ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_USER_POSITION,
        "?loc_uid=" + locationUid,
    );

    return response.within_zone;
}

async function getLocationUsers(locationUid, includeClockinUsers = false) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.LOCATION_USERS,
        {
            loc_uid: locationUid,
            clockin_users: includeClockinUsers,
        }
    );
}

async function create(name) {
    const responseData = await ServiceHelper.postRequest(
        URL_CONSTANTS.LOCATION_CREATE,
        {
            name,
        },
    );

    return {
        loc_uid: responseData.loc_uid,
        name: responseData.name,
    };
}

async function uploadVideo(params) {
    await ServiceHelper.putVideoRequest(
        URL_CONSTANTS.LOCATION_VIDEO_UPLOAD + "/" + params.locationUid,
        params.video
    );
}