import React from "react";

import { CustomDropdown } from "../../_components/CustomDropdown";
import { Room } from "../../_models/Room";

export function RoomSelector({
    rooms = [Room()],
    selectedRoomUid,
    onSelectRoom = () => {}
} = {}) {
    
    return (
        <CustomDropdown
            options={buildUiRoomsList()}
            onChange={onSelectRoom}
            value={selectedRoomUid}
        />
    );

    function buildUiRoomsList() {
        return rooms.map(room =>
            CustomDropdown.buildOption(
                room.uid,
                room.name,
            )
        );
    }
}