import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { MainPageWithButtons, ListedGroup, CapsuleButton, SimpleListElement } from "../_components";
import { Client } from "../_models/Client";
import { ClientActions } from "../_actions/ClientActions";
import { CLIENTS_MODULE_UI_CONSTANTS } from "./ClientsModuleUIConstants";

export function ClientsListView({
    clients = [] || [Client()],
    secondaryView,
    selectedClientUid,
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VIEW
    return (
        <MainPageWithButtons
            pageTitle={t("mod_clients_title")}
            content={content()}
            buttons={createButtons()}
        />
    );

    function content() {
        return clients.length
            ? clientsList()
            : noClientsMessage()
        ;
    }

    function clientsList() {
        return (
            <div>
                <div className="mt-2"/>
                <ListedGroup
                    elements={clients.map(singleClient)}
                />
            </div>
        );

        function singleClient(client = Client()) {
            return (
                <SimpleListElement
                    isSelected={selectedClientUid === client.uid}
                    onClick={() => handleSelectClient(client.uid)}
                    text={client.name}
                />
            );
        }
    }

    function noClientsMessage() {
        return (
            <div className="text-center mt-2">
                {t("mod_clients_no_items_found")}
            </div>
        );
    }

    function createButtons() {
        return [
            <CapsuleButton
                onClick={handleCreateNewClient}
                text={t("mod_clients_add_client")}
                isSelected={secondaryView === CLIENTS_MODULE_UI_CONSTANTS.SECONDARY_VIEWS.NEW_CLIENT}
            />
        ];
    }

    //ACTION HANDLERS
    function handleCreateNewClient() {
        dispatch(ClientActions.changeSecondaryView(CLIENTS_MODULE_UI_CONSTANTS.SECONDARY_VIEWS.NEW_CLIENT));
    }

    function handleSelectClient(uid) {
        dispatch(ClientActions.selectClient(uid));
    }
}