import { InvitationsHelper } from "../../_helpers";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CapsuleButton, MainPageWithButtons } from "../../../Common/_components";
import { GroupNameComponent } from "../_components/GroupNameComponent";
import { useDispatch, useSelector } from "react-redux";
import { Selectors } from "../../_reducers/app.reducer";
import { appPermissions, LOCATION_PERMISSIONS } from "../../_constants/permissions.constants";
import { LocationInvitationConfig } from "../../_models/LocationInvitationConfig";
import { useValidation } from "../../_hooks/useValidation";
import { PermissionSelectorComponent2 } from "../_components/PermissionSelectorComponent2";
import { AccessSelectorWithRooms } from "../_components/AccessSelectorWithRooms";
import { CardPackList } from "../_components/CardPackList";
import { SubinvitationsSelectorComponent } from "../_components/SubinvitationsSelectorComponent";
import { GroupDurationRepresentation } from "../_components/GroupDurationRepresentation";
import { InvitationsGroupToggleList } from "./InvitationsGroupToggleList";
import { invitationGroupActions } from "../../_actions/invitationGroup.actions";
import * as _ from 'lodash';
import { INVITATION_TYPES } from "../../_constants";

export function InvitationGroupConfig() {

    const { t } = useTranslation();

    let locationInvitationConfig    = LocationInvitationConfig();
    locationInvitationConfig        = useSelector(Selectors.getLocationInvitationConfig);

    const selectedGroup = useSelector(Selectors.getSelectedInvitationGroup);
    const editableGroup = useSelector(Selectors.getEditableInvitationGroup);
    const [groupInvitations,setGroupInvitations] = useState([]);
    const permission = useSelector(Selectors.getPermissionSelectedLocation)
    const canCreateSuperguests = locationInvitationConfig.canCreateSuperguests;
    const canManageInvitationGroups = appPermissions.canUserManageInvitationGroups(permission);
    const isUnlimited = InvitationsHelper.isUnlimited(editableGroup?.invitation);
    const rooms = useSelector(Selectors.getRooms);

    const locationUid = useSelector(Selectors.getSelectedLocationUid)
    
    const dispatch = useDispatch();

    const isEdited = useMemo(() => !_.isEqual(selectedGroup, editableGroup), [selectedGroup, editableGroup])

    const handleDeleteGroup = () => {
        dispatch(invitationGroupActions.removeInvitationGroup(editableGroup.uid));
        dispatch(invitationGroupActions.selectInvitationGroup(null));
        dispatch(invitationGroupActions.changeSecondaryView(null));
    }

    const onSaveChanges = async () => {
        await dispatch(invitationGroupActions.saveEditableToServer());
        await dispatch(invitationGroupActions.getGroupsForLocation(locationUid));
    }

    const onDiscardChanges = () => {
        dispatch(invitationGroupActions.selectInvitationGroup(selectedGroup));
    };

    const canSave = useMemo(() => {
        if(!isEdited) return false;
        if(selectedGroup.invitation.type === INVITATION_TYPES.LOCATION) return isEdited;
        return isEdited && editableGroup.invitation.rooms.length > 0;
    }, [isEdited, selectedGroup?.invitation?.type, editableGroup?.invitation?.rooms?.length]);

    const [canBeRemoved, setCanBeRemoved] = useState(!editableGroup?.cardPacks?.length);

    useEffect(() => {
        setCanBeRemoved(!editableGroup?.cardPacks?.length && !groupInvitations.length);
    }, [editableGroup, groupInvitations]);

    const { handleSetValid, forceWarnings } =
        useValidation();

    const handleSetValidName = useCallback(
        (isValid) => {
            handleSetValid("NAME", isValid);
        },
        [handleSetValid],
    );
        const handleSetValidRooms = useCallback(
        (isValid) => {
            handleSetValid("ROOMS", isValid);
        },
        [handleSetValid],
    );

    if(!editableGroup?.invitation) return null;

    function isSubinvitationsDisabled(invitation) {
        return (
            !canCreateSuperguests ||
            !invitation.allowsSubinvitations ||
            invitation.permissionType !== LOCATION_PERMISSIONS.GUEST
        );
    }

    const content = (
        <div className="h-100 d-flex justify-content-between flex-column">
            <div className="py-4 c-grid">
                <GroupNameComponent
                    name={editableGroup?.name}
                    isEditable={canManageInvitationGroups}
                    setIsValid={handleSetValidName}
                    forceWarnings={forceWarnings}
                />

                {isUnlimited && (
                    <PermissionSelectorComponent2
                        permissionType={
                            editableGroup.invitation?.permissionType
                        }
                        isEditable={false}
                    />
                )}

                {isUnlimited && !!editableGroup?.duration && (
                    <GroupDurationRepresentation
                        duration={editableGroup.duration}
                    />
                )}
                {
                    <AccessSelectorWithRooms
                        isEditable={canManageInvitationGroups}
                        invitation={editableGroup.invitation}
                        rooms={rooms}
                        forceShowWarnings={forceWarnings}
                        setIsValid={handleSetValidRooms}
                    />
                }

                {isUnlimited &&
                    isSubinvitationsDisabled(editableGroup.invitation) && (
                        <SubinvitationsSelectorComponent
                            canCreateSuperguests={canCreateSuperguests}
                            invitation={editableGroup.invitation}
                            isEditable={false}
                            isNew={false}
                        />
                )}

                <InvitationsGroupToggleList group={editableGroup} onGetInvitations={setGroupInvitations} />

                {!!editableGroup?.cardPacks?.length && (
                    <div>
                        <CardPackList cardPacks={editableGroup.cardPacks} />
                    </div>
                )}
            </div>
            {canBeRemoved && deleteButton()}
        </div>
    );

    function deleteButton() {
        return (
            <div className="d-flex justify-content-end">
                <CapsuleButton
                    text={t("common.delete")}
                    onClick={handleDeleteGroup}
                    size={"SMALL"}
                    style={`DANGER`}
                />
            </div>
        );
    }
    
    function discardButton() {
        return <CapsuleButton onClick={onDiscardChanges}  isEnabled={isEdited} size="SMALL" text={t("common.discard")} />;
    }

    function saveButton() {
        return <CapsuleButton onClick={onSaveChanges} isEnabled={canSave} size="SMALL" text={t("global_save")} />;
    }

    return (
        <MainPageWithButtons
            size='SMALL'
            content={content}
            pageTitle={t("global_settings")}
            buttons={[saveButton(), discardButton()]}
        />
    );
}
