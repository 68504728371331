import { genericActions } from "../_helpers/ActionErrorWrapper";

import { ProductsService } from "../_services/ProductsSevice";
import { ProductMapper } from "../_mappers/ProductMapper";
import { ProductsCollection } from "../_generics/ProductsCollection";
import { ProductsSecondaryViewStore } from "../_stores/ProductsSecondaryViewStore";

export const ProductsActions = {
    listProducts,
    selectProduct: ProductsCollection.actionSelectItem,
    
    changeSecondaryView: ProductsSecondaryViewStore.actionSet,

    //PRIVATE
    _listProductsSuccess: ProductsCollection.actionListItemsSuccess,
};

function listProducts() {
    return async dispatch => {

        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverProducts = await ProductsService.listProducts();
            dispatch(ProductsActions._listProductsSuccess(
                ProductMapper.allServerToLocal(serverProducts),
            ));
        }
    };
}