import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Subscription } from "../_models/Subscription";
import { subscriptionsActions } from "../_actions/subscriptions.actions";
import { ReadOnlyDataRow } from "../_components/ReadOnlyDataRow";
import { Selectors } from "../_reducers/app.reducer";
import { DeviceSelectorLocal } from "./DeviceSelectorLocal";
import { DeviceSelectorGlobal } from "./DeviceSelectorGlobal";
import { SUBSCRIPTION_TYPES } from "../_constants/subscriptions.constants";
import { NewSubsConfig } from "./NewSubsConfig";
import { SubscriptionPaymentStatusSection } from "./SubscriptionPaymentStatusSection";
import { appPermissions } from "../_constants/permissions.constants";

const MODES = {
    ADD:    "ADD",
    EDIT:   "EDIT",
};
SubscriptionAddEditViewGeneric.MODES = MODES;

const fn = () => {};

export function SubscriptionAddEditViewGeneric({
    isAddingSubscriptionGlobally = false,
    mode = MODES.EDIT,
    onGoBack = fn,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //GLOBAL STATE
    const timezone      = useSelector(Selectors.getTimezone);
    let subscription    = Subscription();
    subscription        = useSelector(Selectors.getSelectedSubscription);
    const permission    = useSelector(Selectors.getPermissionSelectedLocation);
    const paymentMethod = useSelector(Selectors.getPaymentMethod);

    //LOCAL STATE
    const [isModifyingSubscription, setIsModifyingSubscription] = useState(false);
    const canManagePayments = appPermissions.canManageSubscriptionPayments(permission);

    //ACTION HANDLERS
    async function handleEnableRenewal() {
        setIsModifyingSubscription(true);
        await dispatch(subscriptionsActions.enableAutomatedPayment(subscription.code));
        setIsModifyingSubscription(false);
    }

    async function handleDisableRenewal() {
        setIsModifyingSubscription(true);
        await dispatch(subscriptionsActions.disableAutomatedPayment(
            subscription.code,
        ));
        setIsModifyingSubscription(false);
    }
    
    //CLEAN UP
    useEffect(() => {
        return () => {
            dispatch(subscriptionsActions.selectSubscription(null));
            onGoBack();
        };
    }, [dispatch, onGoBack]);

    //VIEW
    if (!subscription)
        return null;

    return (
        <div>
            {readOnlyRow(
                t("common.code"),
                subscription.code,
            )}
            {readOnlyRow(
                t("common.type"),
                subscription.typeLabel,
            )}
            {selectorComponent()}
            {mode === MODES.EDIT &&
                paymentSection()
            }
        </div>
    );

    function paymentSection() {
        return (
            <>
                <div className="mt-3"/>
                <SubscriptionPaymentStatusSection
                    hasPaymentMethod={!!paymentMethod}
                    canManagePayments={canManagePayments}
                    subscription={subscription}
                    timezone={timezone}

                    isModifyingSubscription={isModifyingSubscription}
                    setIsModifyingSubscription={setIsModifyingSubscription}
                    onEnableRenewal={handleEnableRenewal}
                    onDisableRenewal={handleDisableRenewal}
                />
            </>
        );
    }

    function selectorComponent() {
        switch (subscription.type) {
            case SUBSCRIPTION_TYPES.UNLIMITED_AND_RESERVATIONS:
                return unlimitedSelectors();

            case SUBSCRIPTION_TYPES.CLOCKINS:
                return clockinsSelectors();

            case SUBSCRIPTION_TYPES.PROTECT:
                return protectSelectors();
            
            default:
                return null;
        }
    }

    function protectSelectors() {
        return <NewSubsConfig
                subscription={subscription}
                scope={NewSubsConfig.SCOPES.GLOBAL}
                readOnly
            />;
    }

    function clockinsSelectors() {
        if (mode === MODES.EDIT)
            return clockinsViewSelector();

        return isAddingSubscriptionGlobally
            ? clockinsAddSelectorGlobal()
            : clockinsAddSelectorLocal()
        ;
        
        function clockinsViewSelector() {
            return null;
        }

        function clockinsAddSelectorGlobal() {
            return <NewSubsConfig
                subscription={subscription}
                scope={NewSubsConfig.SCOPES.GLOBAL}
            />;
        }

        function clockinsAddSelectorLocal() {
            return <NewSubsConfig
                subscription={subscription}
                scope={NewSubsConfig.SCOPES.LOCAL}
            />;
        }
    }

    function unlimitedSelectors() {
        return isAddingSubscriptionGlobally
            ? <DeviceSelectorGlobal selected={subscription}/>
            : <DeviceSelectorLocal selected={subscription}/>
        ;
    }

    function readOnlyRow(title, value) {
        return <ReadOnlyDataRow
            data={value}
            name={title}
        />;
    }
}