import moment from "moment";
import { spelledList } from "../../Common/_utils/utils";
import { weekdayUiValues, invitationPeriodUiValues, INVITATION_PERIODS, INVITATION_STATUS, INVITATION_TYPES, LOCAL_INVITATION_STATUS, LOCATION_PERMISSIONS, MAX_TIME_NO_SECONDS, NULL_MAIL, TIME_FORMAT, MIN_TIME_NO_SECONDS } from "../_constants";
import { Invitation, TimeSlot, WeekDaysEnum, WeekDaysSlots } from "../_models/Invitation";
import { LocationInfo } from "../_models/LocationInfo";
import { Room } from "../_models/Room";
import { i18n } from "../_translations/i18n";
import { TimeHelper } from "./TimeHelper";

export class InvitationsHelper {
    static localStatusToQueryFilter(localStatus) {

        const LOCAL_STATUS_NUM = {
            [LOCAL_INVITATION_STATUS.REQUESTED]: 1,
            [LOCAL_INVITATION_STATUS.ACTIVE]: 3,
            [LOCAL_INVITATION_STATUS.CANCELLED]: 4,
            [LOCAL_INVITATION_STATUS.EXPIRED]: 5,
            [LOCAL_INVITATION_STATUS.INACTIVE]: 7,
        }
        
        return LOCAL_STATUS_NUM[localStatus];
    }

    static localStatusFromServerStatus(serverStatus, enabled) {
        switch (serverStatus) {
            case INVITATION_STATUS.VALIDATED:
            case INVITATION_STATUS.REQUESTED:
                return LOCAL_INVITATION_STATUS.REQUESTED;

            case INVITATION_STATUS.CANCELLED:
                return LOCAL_INVITATION_STATUS.CANCELLED;

            case INVITATION_STATUS.EXPIRED:
            case INVITATION_STATUS.ACCEPTED_EXPIRED:
                return LOCAL_INVITATION_STATUS.EXPIRED;

            case INVITATION_STATUS.ACCEPTED: {
                if (!enabled) return LOCAL_INVITATION_STATUS.INACTIVE;

                return LOCAL_INVITATION_STATUS.ACTIVE;
            }

            default:
                return LOCAL_INVITATION_STATUS.UNDEFINED;
        }
    }

    static hasUnlimitedInvitations(locationInfo = LocationInfo()) {
        return locationInfo.invitationsAllowed === null;
    }

    static getUiUserName(userName, email, localStatus, isLongString = false) {
        if (email !== NULL_MAIL && userName !== null && userName !== undefined && userName !== "") {
            return userName.substring(0, 36) + " <" + email + ">";
        }

        if (email !== NULL_MAIL)
            return email;

        if (userName !== null && userName !== undefined && userName !== "")
            return userName;

        const userString = isLongString ? i18n.t("common.user") + " " : "";

        if (localStatus === LOCAL_INVITATION_STATUS.CANCELLED)
            return userString + i18n.t("invitations.neverAssigned");

        if (localStatus === LOCAL_INVITATION_STATUS.REQUESTED)
            return userString + i18n.t("invitations.assignedOnceAccepted");
    }

    static getRoomName(uid, rooms = [Room()]) {
        for (let i = 0; i < rooms.length; i++) {
            if (rooms[i].uid === uid) {
                return rooms[i].name;
            }
        }

        return "";
    }

    static canEditInvitationPermission(userPermission, invitation = Invitation()) {
        if (invitation.permissionType === LOCATION_PERMISSIONS.INSTALLER
            && userPermission === LOCATION_PERMISSIONS.ADMIN
        )   return false;

        return true;
    }

    static isInvitationEditable(invitation = Invitation()) {
        if (invitation.newInvitation)
            return true;
            
        if (!invitation.edited && invitation.allowsSubinvitations)
            return false;

        if (invitation.status === INVITATION_STATUS.CANCELLED)
            return false;

        if (invitation.status === INVITATION_STATUS.REQUESTED)
            return true;

        if (invitation.allowsSubinvitations)
            return false;

        return true;
    }


    static getAvailableInvitationPermissions(invitationType, locationPermission) {
        switch (invitationType) {
            case INVITATION_TYPES.ROOM:
                return [
                    LOCATION_PERMISSIONS.GUEST,
                ];
            
            case INVITATION_TYPES.LOCATION: {
                const permissions = [
                    LOCATION_PERMISSIONS.ADMIN,
                    LOCATION_PERMISSIONS.GUEST,
                ];

                if (locationPermission === LOCATION_PERMISSIONS.OWNER)
                    permissions.push(LOCATION_PERMISSIONS.INSTALLER)

                return permissions;
            }

            default:
                return [];
        }
    }

    static getAvailablePeriods(currentPermissionType, allowsSubinvitations) {

        if (allowsSubinvitations) {
            return [INVITATION_PERIODS.ALWAYS];
        }

        switch (currentPermissionType) {
            case LOCATION_PERMISSIONS.INSTALLER:
                return [
                    INVITATION_PERIODS.TEMPORARY,
                ];
            case LOCATION_PERMISSIONS.GUEST:
                return [INVITATION_PERIODS.ALWAYS,INVITATION_PERIODS.TEMPORARY];

            default:
                return [
                    INVITATION_PERIODS.ALWAYS,
                ];
        }
    }
    
    static getAvailableInvitationTypes(roomsOnly) {
        let availableInvitationTypes = [INVITATION_TYPES.ROOM];

        if (!roomsOnly)
            availableInvitationTypes = [INVITATION_TYPES.LOCATION, ...availableInvitationTypes];

        return availableInvitationTypes;
    }

    static sortAlphabetically(invitations = [Invitation()]) {
        invitations.sort((a, b) => a.email.localeCompare(b.email));
    }

    static getTemporaryAccessInfoLabel(invitation = Invitation()): string[] | null {

        const {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            timeSlots,
        } = invitation;

        if(!timeSlots) {

            const workDays = [monday,tuesday,wednesday, thursday,friday]
            const weekendDays = [saturday, sunday]
    
            if(workDays.every(Boolean) && !weekendDays.some(Boolean)) return [ i18n.t('common.workDays') ]
            if(!workDays.some(Boolean) && weekendDays.every(Boolean)) return [ i18n.t('common.weekends') ]
            if([...workDays, ...weekendDays].every(Boolean)) return [ i18n.t('common.everyday') ]
    
            const availableDays = [
                { monday },
                { tuesday },
                { wednesday },
                { thursday },
                { friday },
                { saturday },
                { sunday },
            ].filter((value) => Object.values(value).every(Boolean));
    
            if(!availableDays.length) return null;
    
            const parsedAvailableDays = availableDays
                .map((value) => Object.keys(value))
                .flat()
                .map((day) => i18n.t("common." + day));
    
            return [spelledList(parsedAvailableDays)];
        }

        return Object.entries(timeSlots as WeekDaysSlots).map(([day, slots]) => (weekdayUiValues[day] + ': ' + InvitationsHelper.timeRangesToString(slots)));
    }

    static isTemporary(invitation = Invitation()) {
        return invitation?.periodType === INVITATION_PERIODS.TEMPORARY; 
    }

    static isUnlimited({ begin, createdOn, end } = Invitation()) {
        return (moment(begin).isSame(moment(createdOn))) || (!begin && !end);

    }

    static getUiAvailablePeriods(isPeriodEditable, periodType, permissionType, allowsSubinvitations) {
            let availablePeriods = isPeriodEditable
                ? InvitationsHelper.getAvailablePeriods(
                    permissionType,
                    allowsSubinvitations,
                )
                : [INVITATION_PERIODS[periodType]]
            ;
            return availablePeriods.map(element => ({ label: invitationPeriodUiValues[element], value: element}));
        }

    static getTimeConfigEditable({ permissionType, allowsSubinvitations }) {

        if(allowsSubinvitations) return {
            dateRange: false,
            timeSlots: false,
        }

        return {
            dateRange: permissionType === LOCATION_PERMISSIONS.GUEST,
            timeSlots: permissionType === LOCATION_PERMISSIONS.GUEST
        }
    }

    static getTimeConfigVisible({ permissionType, allowsSubinvitations }) {
        if(allowsSubinvitations) return {
            dateRange: false,
            timeSlots: false,
        }

        return {
            dateRange: [LOCATION_PERMISSIONS.GUEST, LOCATION_PERMISSIONS.INSTALLER].includes(permissionType),
            timeSlots: permissionType === LOCATION_PERMISSIONS.GUEST
        }
    }

    static isAllDay(slots?: TimeSlot | TimeSlot[]) {
        if(!slots) return false;
        const allDay = ({ start, end }) => start.startsWith(MIN_TIME_NO_SECONDS) && end.startsWith(MAX_TIME_NO_SECONDS); 
        if(!Array.isArray(slots)) return allDay(slots);
        return slots.some(allDay);
    }

    static areAllDaysSelected(weekdaysSlots: WeekDaysSlots) {
        if (!weekdaysSlots) return false;
        return Object.values(weekdaysSlots).length === 7 && Object.values(weekdaysSlots).every(ranges => ranges?.length > 0);
    }

    static timeRangesToString(ranges?: TimeSlot[]) {
        if (!ranges?.length) return '';
        if (this.isAllDay(ranges[0])) return i18n.t('common.allDay');
        const mappedStrings = ranges.map(({ start, end }) => {
            const startHour = start.split(':')[0];
            const endHour = end.split(':')[0];
            const startMinute = start.split(':')[1];
            const endMinute = end.split(':')[1];
            start = startHour + ':' + startMinute;
            end = endHour + ':' + endMinute;
            return `${i18n.t('global_from_time')} ${start}h ${i18n.t('common.to')} ${end}h`;
        });
        return spelledList(mappedStrings).toLowerCase();
    }

    static getTimeSlotsFromRecurrentLegacy({ begin: start, end, monday, thursday, tuesday, wednesday, friday, saturday, sunday }) {

        if ([monday, tuesday, wednesday, thursday, friday, saturday, sunday].every(value => !value)) return null;

        start = TimeHelper.isoStringToUiString(start, TimeHelper.getTimeFormats().TIME);
        end = TimeHelper.isoStringToUiString(end, TimeHelper.getTimeFormats().TIME);

        const timeSlots: WeekDaysSlots = {
            ...(monday && { [WeekDaysEnum.MONDAY]: [{ start, end }] }),
            ...(tuesday && { [WeekDaysEnum.TUESDAY]: [{ start, end }] }),
            ...(wednesday && { [WeekDaysEnum.WEDNESDAY]: [{ start, end }] }),
            ...(thursday && { [WeekDaysEnum.THURSDAY]: [{ start, end }] }),
            ...(friday && { [WeekDaysEnum.FRIDAY]: [{ start, end }] }),
            ...(saturday && { [WeekDaysEnum.SATURDAY]: [{ start, end }] }),
            ...(sunday && { [WeekDaysEnum.SUNDAY]: [{ start, end }] })
        };
        
        return timeSlots;

    } 
    

    static hasOverlap({ start: startA, end: endA }, { start: startB, end: endB }) {
        startA = moment(startA, TIME_FORMAT);
        endA = moment(endA, TIME_FORMAT);
        startB = moment(startB, TIME_FORMAT);
        endB = moment(endB, TIME_FORMAT);
        return (startA.isBefore(endB) && endA.isAfter(startB)) || (startB.isBefore(endA) && endB.isAfter(startA));
    }

    static canAddSlot(timeSlots: TimeSlot[], slot: TimeSlot) {

        if (this.isAllDay(timeSlots)) throw new Error(i18n.t('mod_invitations_cannot_add_slot_to_all_day_invitation'));
        
        timeSlots?.forEach(({ start, end }) => {
            if (this.hasOverlap(slot, { start, end })) throw new Error(i18n.t('mod_invitations_cannot_add_overlapping_slot'));
        });

        const startMoment = moment(slot.start, TIME_FORMAT);
        const endMoment = moment(slot.end, TIME_FORMAT);
        if(startMoment.isAfter(endMoment) || startMoment.isSame(endMoment)) throw new Error(i18n.t('mod_invitations_start_end_crossed_error'));

        return true;
    }

   static orderTimeSlots(timeSlots: TimeSlot[]) {
        return timeSlots.sort((a, b) => a.start.localeCompare(b.start));
    }

}