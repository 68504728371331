import { invitationGroupActions } from "../../_actions/invitationGroup.actions";
import { Selectors } from "../../_reducers/app.reducer";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContentSpace } from "../../../Common/_components";
import { InvitationGroupsList } from "./InvitationGroupsList";
import { INVITATION_GROUP_SECONDARY_VIEWS } from "../../_constants/invitationsGroup.constants";
import { NewInvitationGroup } from "./NewInvitationGroup";
import { InvitationGroupConfig } from "./InvitationGroupConfig";

export function InvitationGroupModule() {

    const dispatch = useDispatch();
    const locationUid = useSelector(Selectors.getSelectedLocationUid);
    const secondaryView = useSelector(Selectors.getInvitationGroupsModuleSecondaryView);
    const [detailedContent, setDetailedContent] = useState<any>(null);
    
    const onGoBack = useCallback(() => {
        dispatch(invitationGroupActions.changeSecondaryView(null));
        dispatch(invitationGroupActions.selectInvitationGroup(null));
    }, [dispatch]);

    useEffect(() => {
        dispatch(invitationGroupActions.getGroupsForLocation(locationUid));
        onGoBack();
    }, [dispatch, locationUid, onGoBack]);


    useEffect(() => { 
         switch(secondaryView) {
             case INVITATION_GROUP_SECONDARY_VIEWS.NEW_INVITATION_GROUP:
                 setDetailedContent(<NewInvitationGroup />)
                 return
             case INVITATION_GROUP_SECONDARY_VIEWS.INVITATION_GROUP_CONFIG:
                 setDetailedContent(<InvitationGroupConfig />)
                 return

            default:
                setDetailedContent(null)
         }
    }, [secondaryView])

    return (
        <ContentSpace
            mainContent={<InvitationGroupsList />}
            onGoBack={onGoBack}
            detailedContent={detailedContent}
        />
    );

} 