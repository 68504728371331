import { i18n } from '../_translations/i18n';
import { INVITATION_GROUP_TYPES } from './invitationsGroup.constants';

export const cardTypeToGroupType = (type) => {
    if(type === INVITATION_CARD_TYPES.LIMITED) {
        return INVITATION_GROUP_TYPES.LIMITED
    }
    return INVITATION_GROUP_TYPES.UNLIMITED
}

export const INVITATION_CARD_ACTION_TYPES = {
    GET_CARD_SUCCESS: "GET_CARD_SUCCESS",
    REMOVE_SELECTED_CARD: "REMOVE_SELECTED_CARD"
};

export const INVITATION_CARD_TYPES = {
    UNLIMITED:  1,
    NFC:        2,
    LIMITED:    3,
};

export const CARD_STATES = {
    NOT_USED:   1,
    USED:       2,
};

export const CARD_INVITATION_STATES = {
    NEW:                            0,
    UPDATED_SUBINVITATIONS:         1,
    UPDATED_INVITATION_DURATION:    2,
};

export const NEW_GROUP_TYPE_FOR_CARD_TYPE = {
    [INVITATION_CARD_TYPES.UNLIMITED]:  INVITATION_GROUP_TYPES.UNLIMITED,
    [INVITATION_CARD_TYPES.NFC]:        INVITATION_GROUP_TYPES.UNLIMITED,
    [INVITATION_CARD_TYPES.LIMITED]:    INVITATION_GROUP_TYPES.LIMITED,
};

export const INVITATION_CARD_TYPES_TRANSLATIONS = {
    [INVITATION_CARD_TYPES.UNLIMITED]: i18n.t("mod_cards_unlimited_cards"),
    [INVITATION_CARD_TYPES.NFC]: i18n.t("mod_cards_nfc_cards"),
    [INVITATION_CARD_TYPES.LIMITED]: i18n.t("mod_cards_limited_cards"),
};

export const EXISTING_GROUP_TYPES_COMPATIBLE_WITH_CARD_TYPE = {
    [INVITATION_CARD_TYPES.UNLIMITED]: [
        INVITATION_GROUP_TYPES.UNLIMITED,
    ],
    [INVITATION_CARD_TYPES.NFC]: [
        INVITATION_GROUP_TYPES.UNLIMITED,
        INVITATION_GROUP_TYPES.LIMITED,
    ],
    [INVITATION_CARD_TYPES.LIMITED]: [
        INVITATION_GROUP_TYPES.LIMITED,
    ],
};