export const CardPackHelper = {
    getAmountOfCards,
};

function getAmountOfCards(cardPacks) {
    return cardPacks.reduce((acc, card) => {
        const { cardType, totalCards } = card;
        const key = cardType === 2 ? "totalNFCCards" : "totalUserCards";
        if (isNaN(acc[key])) {
            acc[key] = 0;
        }
        return { ...acc, [key]: acc[key] + totalCards };
    }, {});
}
