import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const PaymentMethodsService = {
    list,
    deleteMethod,
    createSetupIntent,
    confirmSetupIntent,
};

async function list(locationUid) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.PAYMENT_METHODS,
        { loc_uid: locationUid },
    );
}

async function deleteMethod(uid) {
    await ServiceHelper.deleteRequest(
        URL_CONSTANTS.PAYMENT_METHODS + "/" + uid,
    );
}

async function createSetupIntent(locationUid) {
    const data = await ServiceHelper.postRequest(
        URL_CONSTANTS.PAYMENT_METHODS_CREATE_SETUP_INTENT,
        { loc_uid: locationUid },
    );

    return {
        setupIntentId: data.setup_intent_id,
        setupIntentClientSecret: data.setup_intent_client_secret,
    };
}

async function confirmSetupIntent(setupIntentId, locationUid) {
    return await ServiceHelper.postRequest(
        URL_CONSTANTS.PAYMENT_METHODS_CONFIRM_SETUP_INTENT,
        {
            setup_intent_id: setupIntentId,
            loc_uid: locationUid,
        },
    );
}