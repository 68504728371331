import React from "react";

export function UiSidebarButton({
    testID,

    SvgComponent,
    icon,
    
    text,
    onClick = () => {},
    isSelected = false,
    isMarked = false,
    isBold = false,
} = {}) {
    return (
        <div
            data-testid={testID}
            onClick={onClick}
            className={`
                d-flex
                align-items-center
                cp
                py-2
                c-cursor-default
                text-capitalize
                ${isSelected
                    ? "c-text-primary-dark-1 c-bg-grey-225"
                    : "c-hover-220"
                }

                flex-shrink-0

                position-relative

                ${isMarked && "c-bg-grey-240"}
            `}
        >
            <div className={`
                h-100
                position-absolute
                ${(isSelected || isMarked) && "c-left-thick-border"}
            `}>
                {isSelected &&
                    <div className="c-arrow-right" />
                }
            </div>

            {iconFn()}
            <div className="ml-3"/>
            {textFn()}
        </div>
    );

    function iconFn() {
        if (SvgComponent)
            return <SvgComponent
                className="c-fill-40 c-img-1-5rem ml-3 flex-shrink-0"
            />;

        if (!icon) {
            return (
                <div className="
                    flex-shrink-0
                    ml-3
                    c-img-1-5rem
                " />
            );
        }

        return (
            <img
                className="
                    c-img-1-5rem ml-3
                    flex-shrink-0
                "
                src={icon}
                alt="sidebar icon"
            />
        );
    }

    function textFn() {
        return (
            <span className={`
                ${isBold || isSelected
                    ? "c-font-weight-medium"
                    : "c-font-weight-light c-text-110"
                }

                overflow-hidden
                text-nowrap
            `}>
                {text}
            </span>
        );
    }
}