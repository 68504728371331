import { ALERTS_ACTION_TYPES } from "../_constants";
import { AlertsHelper } from "../_helpers/AlertsHelper";

export const alertsActions = {
    getAlerts,

    //Exposed for testing
    updateAlerts,
};

function getAlerts(devices = []) {
    return updateAlerts(
        AlertsHelper.getAlertsFromDevices(devices)
    );
}

function updateAlerts(alerts) {
    return {
        type: ALERTS_ACTION_TYPES.ALERTS_UPDATE,
        alerts,
    };
}