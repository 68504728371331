import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import { MainPageWithButtons, TextInputComponent } from "../_components";
import { Product } from "../_models/Product";
import { ProductsActions } from "../_actions/ProductsActions";

export function ProductDetailsView({
    product = Product()
}) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //CLEAN UP
    useEffect(() => {
        return () => dispatch(ProductsActions.selectProduct(null));
    }, [dispatch]);

    //VIEW
    if (!product)
        return null;

    return (
        <MainPageWithButtons
            pageTitle={t("mod_products_title")}
            content={content()}
        />
    );

    function content() {
        return (
            <div>
                <div className="mt-2"/>
                <TextInputComponent
                    title={t("global_name")}
                    value={product.name}
                    isEditable={false}
                />
                <div className="mt-2"/>
                <TextInputComponent
                    title={t("global_price")}
                    value={product.price}
                    isEditable={false}
                />
                <div className="mt-2"/>
                <TextInputComponent
                    title={t("global_description")}
                    value={product.description}
                    isEditable={false}
                />
            </div>
        );
    }
}
