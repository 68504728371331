import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentSpace } from "../_components";
import { usersStatusActions } from "../_actions/usersStatus.actions";
import { UsersStatusList } from "./UsersStatusList";
import { UserStatusDataView } from "./UserStatusDataView";
import { appPermissions } from "../_constants/permissions.constants";
import { USERS_STATUS_DATE_FILTER_PERIODS } from "../_constants/usersStatus.constants";
import { Selectors } from "../_reducers/app.reducer";
import { User } from "../_models/User";

export function UsersStatusModule() {

    //LIBRARIES
    const dispatch = useDispatch(); 

    //GLOBAL STATE
    const locationUid                   = useSelector(Selectors.getSelectedLocationUid);
    const timezone                      = useSelector(Selectors.getTimezone);
    const permission                    = useSelector(Selectors.getPermissionSelectedLocation);

    const selectedUserUid               = useSelector(Selectors.getUsersStatusSelectedUserUid);
    const dateFilter                    = useSelector(Selectors.getUsersStatusDateFilter);

    const usersStatus                   = useSelector(Selectors.getUsersStatus);
    const isRequestingUsersStatus       = useSelector(Selectors.getUsersStatusIsRequesting);

    const userStatusData                = useSelector(Selectors.getUserStatusData);
    const isRequestingUserStatusData    = useSelector(Selectors.getUserStatusDataIsRequesting);
    
    const userStatusLogs                = useSelector(Selectors.getUserStatusLogs);
    const isRequestingUserStatusLogs    = useSelector(Selectors.getUserStatusLogsIsRequesting);

    const dateRanges                    = useSelector(Selectors.getUsersStatusDateRanges);

    let user = User();
    user = useSelector(Selectors.getEverouUser);
    const isSat = user.isSat;

    //LOCAL STATE
    const canSeeAllData = appPermissions.canUserSeeMobiles(permission, isSat);

    //ACTION HANDLERS
    const handleChangeSelectedUser = useCallback(uid => 
        dispatch(usersStatusActions.selectUserUid(uid))
    , [dispatch]);

    const handleGoBack = useCallback(() => 
        dispatch(usersStatusActions.selectUserUid(null))
    , [dispatch]);

    const handleChangeDateFilter = useCallback(dateFilter => 
        dispatch(usersStatusActions.changeDateFilter(dateFilter, timezone))
    , [dispatch, timezone]);

    const handleChangeRangeStart = useCallback(begin => 
        dispatch(usersStatusActions.changeDateRanges({ begin }))
    , [dispatch]);
    
    const handleChangeRangeEnd = useCallback(end => 
        dispatch(usersStatusActions.changeDateRanges({ end }))
    , [dispatch]);

    //INITIALIZATION
    useEffect(() => {
        dispatch(usersStatusActions.changeDateFilter(
            USERS_STATUS_DATE_FILTER_PERIODS.LAST_MONTH,
        ));
    }, [dispatch, locationUid]);

    //DATA FETCHING
    useEffect(() => {
        fetchUsersData();

        async function fetchUsersData() {
            if (!dateRanges.begin || !dateRanges.end)
                return;

            await dispatch(usersStatusActions.getUsersStatus({
                locationUid: locationUid,
                begin: dateRanges.begin,
                end: dateRanges.end,
            }));
        }

    }, [dispatch, dateRanges, locationUid]);

    useEffect(() => {
        if (!dateRanges.begin || !dateRanges.end)
            return;

        if (!selectedUserUid)
            return;

        dispatch(usersStatusActions.getUserStatusData({
            locationUid: locationUid,
            userUid: selectedUserUid,
            begin: dateRanges.begin,
            end: dateRanges.end,
        }));
        dispatch(usersStatusActions.getUserStatusLogs({
            locationUid: locationUid,
            userUid: selectedUserUid,
            begin: dateRanges.begin,
            end: dateRanges.end,
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedUserUid, dateRanges]);

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <UsersStatusList
                    canSeeAllData={canSeeAllData}

                    beginIso={dateRanges.begin}
                    endIso={dateRanges.end}
                    handleChangeRangeEnd={handleChangeRangeEnd}
                    handleChangeRangeStart={handleChangeRangeStart}
                    timezone={timezone}

                    dateFilter={dateFilter}
                    selectedUserUid={selectedUserUid}

                    usersStatus={usersStatus}
                    isRequestingUsersStatus={isRequestingUsersStatus}

                    handleChangeSelectedUser={handleChangeSelectedUser}
                    handleChangeDateFilter={handleChangeDateFilter}
                />
            }
            detailedContent={secondaryView()}
            onGoBack={handleGoBack}
        />
    );

    function secondaryView() {
        if (selectedUserUid) {
            return <UserStatusDataView
                canSeeAllData={canSeeAllData}
                dateFilter={dateFilter}
                
                isRequestingUserStatusData={isRequestingUserStatusData}
                userStatusData={userStatusData}

                isRequestingUserStatusLogs={isRequestingUserStatusLogs}
                userStatusLogs={userStatusLogs}

                timezone={timezone}
            />;
        }
    }
}