import "./styles.scss";
import { useEffect, useState } from "react";
import { Icons } from "../../_assets/icons/Icons";

type CustomNumberInputProps = {
    value: number;
    min: number;
    max?: number;
    testId?: string;
    onChange: (...args: any) => void
    onBlur?: (...args: any) => void
}

export function CustomNumberInput({ value, min, max, onChange, testId = '', onBlur }: CustomNumberInputProps) {

    const [counter, setCounter] = useState(value ?? min);
    useEffect(() => { setCounter(value ?? min) }, [value, min])

    const increment = () => {
        if (!(counter === max)) {
            const _counter = counter + 1;
            setCounter(_counter);
            onChange(_counter);
        }
    };

    const decrement = () => {
        if (!(counter === min)) {
            const _counter = counter - 1;
            setCounter(_counter);
            onChange(_counter);
        }
    };

    const handleChange = (e) => {
        const currentValue = e.target.value;
        if (isNaN(parseInt(currentValue))) {
            setCounter(currentValue);
        } else {
            let _counter: number;
            if (min && currentValue < min) {
                _counter = min;
            } else if (max && currentValue > max) {
                _counter = max;
            } else {
                _counter = parseInt(currentValue);
            }

            onChange(_counter);
            setCounter(_counter);
        }
    };

    return (
        <div className="custom-number-input d-flex c-bd-200 justify-content-between c-w-fit">
            {button("-", decrement)}
            <input
                data-testid={testId}
                type="number"
                min={min}
                max={max}
                onBlur={(ev) => { ev.preventDefault(); onBlur && onBlur(parseInt(ev.target.value)); }}
                className="d-flex input align-items-center justify-content-center c-w-fit"
                value={counter}
                onChange={handleChange}
            />
            {button("+", increment)}
        </div>
    );
    //- -> Em dash (large hyphen)
    function button(elem: "+" | "-", onClick: () => void) {
        const sideBorder = elem === "+" ? "border-left" : "border-right";
        const iconSvg = elem === "+" ? Icons.add : Icons.remove;

        let isDisabled = true;

        if(elem === '+') {
            isDisabled = max === undefined ? false : counter >= max;
        } 
        
        if(elem === '-') {
            isDisabled = min === undefined ? false : counter <= min;
        } 

        return (
                <button
                    onClick={onClick}
                    disabled={isDisabled}
                    className={`${sideBorder} button px-1 c-bg-primary cp d-flex align-items-center justify-content-center`}
                >
                    <svg width={24} height={24} fill={isDisabled ? 'black' : 'white'}>
                        <use xlinkHref={iconSvg+'#icon'} />
                    </svg>
                </button>
        );
    }
}
