import { OtherUser } from "../_models/OtherUser";

export class OtherUserMapper {
    static allServerToLocal(serverUsers) {
        return serverUsers.map(serverToLocal);
    }

    static serverToLocal(serverUser) {
        return serverToLocal(serverUser);
    }
}

function serverToLocal(serverUser) {
    const {
        name,
        uid,
        email,
        avatar,
    } = serverUser;

    return OtherUser({
        name,
        uid,
        email,
        avatar,
    });
}