import React from 'react';
import { useTranslation } from 'react-i18next';
import { TimeHelper } from '../_helpers';
import { IsoStringDatePicker } from '../_components';
import { Icons } from '../_assets';

export function WeekSelector({
    begin,
    end,
    date,
    setDate,
    maxEndDate,
} = {}) {
    //LIBRARIES
    const { t } = useTranslation();

    //LOCAL ACTION HANDLERS
    function handlePreviousWeek() {
        setDate(TimeHelper.substractTimeMeasureFromIsoString(
            date,
            TimeHelper.TIME_MEASURES.WEEK,
            1,
        ));
    }

    function handleNextWeek() {
        const newDate = TimeHelper.addTimeMeasureToIsoString(
            date,
            TimeHelper.TIME_MEASURES.WEEK,
            1,
        );

        setDate(TimeHelper.getMinimumDate([
            newDate,
            TimeHelper.getTodayIsoString(),
        ]));
    }

    const isMaxDateSelected = TimeHelper.areSameDay(date, maxEndDate)

    //VIEW
    return (
        <div className="
            d-flex
            justify-content-center
            align-items-center
            py-1
            c-cursor-default
        ">
            {arrow(Icons.previousIcon, handlePreviousWeek)}
            <div className="ml-2"/>
            <IsoStringDatePicker
                customInputFn={customInput}
                dateOnly
                onChange={setDate}
                isoString={date}
                maxDate={maxEndDate}
                className={'cp'}
            />
            <div className="ml-2"/>
            {!isMaxDateSelected
                && arrow(Icons.nextIcon, handleNextWeek)
            }
        </div>
    );

    function customInput() {
        return (
            <div className="
                px-2
                py-1

                c-hover-230
                font-weight-bold
                rounded
            ">
                <span>{t("global_week")}  </span>
                {uiDate(begin)}
                <span className="mx-1">-</span>
                {uiDate(end)}
            </div>
        );

        function uiDate(date) {
            return TimeHelper.isoStringToUiString(
                date,
                TimeHelper.getTimeFormats().DATE_NO_TIME,
            );
        }
    }

    function arrow(src, onClick) {
        return (
            <img
                className="
                    c-img-1-5rem
                    p-1
                    c-hover-230
                    rounded
                    cp
                "
                alt="prev_button"
                src={src}
                onClick={onClick}
            />
        );
    }
}