import { CustomizationActions } from "./CustomizationActions";
import { BaseCustomizationStore } from "./BaseCustomizationStore";

export const CustomizationStore = {
    reducer: BaseCustomizationStore.reducer,
    actions: {
        ...CustomizationActions,
    },
    selectors: {
        getStore: BaseCustomizationStore.selectorGetStore,
        getLocationCustomization: BaseCustomizationStore.selectorGet,
        getIsUploadingImage: BaseCustomizationStore.selectorGetIsRequesting,

        setRootStateFn: BaseCustomizationStore.setRootStateFn,
    },
};