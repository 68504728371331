import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const subscriptionsService = {
    getSubscriptions,
    updateSubscription,
    validate,
    activate,
    create,
    enableAutomatedPayment,
    disableAutomatedPayment,
};

function enableAutomatedPayment(subscriptionCode) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.SUBSCRIPTION_AUTOMATE_RENEWAL + "/" + subscriptionCode,
    );
}

function disableAutomatedPayment(subscriptionCode) {
    return ServiceHelper.deleteRequest(
        URL_CONSTANTS.SUBSCRIPTION_AUTOMATE_RENEWAL + "/" + subscriptionCode,
    );
}

function getSubscriptions(locationUid) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.SUBSCRIPTION_LIST,
        "?loc_uid=" + locationUid,
    );
}

function updateSubscription(code, deviceUid) {
    return ServiceHelper.postRequest(
        URL_CONSTANTS.SUBSCRIPTION_UPDATE,
        {
            code,
            device_uid: deviceUid,
        },
    );
}

function validate(code) {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.SUBSCRIPTION_INFO,
        "?code=" + code,
    );
}

async function activate({ code, deviceUid, locationUid }) {
    const responseData = await ServiceHelper.postRequest(
        URL_CONSTANTS.SUBSCRIPTION_ACTIVATE,
        {
            code,
            device_uid: deviceUid || undefined,
            loc_uid: locationUid || undefined,
        },
    );

    return {
        result: responseData.state,
        subscription: responseData.subscription,
    };
}


async function create({type, locationUid }) {
    return await ServiceHelper.postRequest(URL_CONSTANTS.SUBSCRIPTION_CREATE, {
        type,
        loc_uid: locationUid
    })
}