import { GenericMapperFactory } from "./MapperFactory";
import { Room } from "../_models/Room";

export const RoomMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverRoom) {
    return Room({
        name: serverRoom.name,
        type: serverRoom.type,
        uid: serverRoom.uid,
    })
}