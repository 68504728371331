import { Integration, IntegrationCompany, LocalEditableIntegration } from "../../../Everou/_modelsTS/Integration";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CapsuleButton, CustomNumberInput } from "../../../Everou/_components";
import { useDispatch, useSelector } from "react-redux";
import { integrationsActions } from "../../../Everou/_actions/integrations.actions";
import { Selectors } from "../../../Everou/_reducers/app.reducer";
import { Icons } from "../../../Everou/_assets";
import OctorateOauthPopup from "./OctorateOAuthPopup";
import CreatePublicUrlToggle from "../../../Everou/InvitationsModule/CreatePublicUrlToggle";
import { useError } from "../../../Everou/_hooks/useError";
import SendInvitationURLToggle from "../../../Everou/IntegrationsModule/components/SendInvitationURLToggle";
import TimePicker from "rc-time-picker";
import { TimeHelper } from "../../../Everou/_helpers";
import { APPLE_CLIENT_ID, MIN_TIME, OCTORATE_CLIENT_ID, OCTORATE_REDIRECT_URI, TIME_FORMAT } from "../../../Everou/_constants";
import moment from "moment";
import { PMSIntegrationsActions } from "../../_actions/PMSIntegrationsActions";

type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onChangeInputs?: (...args: any) => void | any;
    onToggleEditable: (...args: any) => void | any;
    onSetInitialStatus: (...args: any) => void | any;
    onDelete: (index: number) => void;
    index: number;
}

export function PMSOctorateIntegrationInput({ 
    integration, 
    onDelete, 
    index, 
    onToggleEditable,
}: IntegrationInputProps) {

    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    // GLOBAL STATE
    const [createPublicUrl, setCreatePublicUrl] = useState(integration.createPublicUrl);
    const [sendInvitationURL, setSendInvitationURL] = useState(integration.sendInvitationURL);
    const [daysForInvitationCreate, setDaysForInvitationCreate] = useState(integration.daysForInvitationCreate ?? 1);
    const { errorMessage, setError, hasError, disableError } = useError();

    const enableSendInvitationURLToggle = useMemo(() => {
        return createPublicUrl;
    }, [createPublicUrl]);

    const updateDaysForInvitationCreation = (daysForInvitationCreate : number) => {
        setDaysForInvitationCreate(daysForInvitationCreate);
    };

    const [checkIn, setCheckIn] = useState(integration.check_in ?? MIN_TIME);
    const [checkOut, setCheckOut] = useState(integration.check_out ?? MIN_TIME);
    const timeNoSecondsFormat = TimeHelper.getTimeFormats().TIME_NO_SECONDS;

    const getHourAndMinutesFormat = useCallback((time: string) => moment(time, timeNoSecondsFormat), []);

    const octorateUserInfo = useSelector(state => Selectors.getPMSOctorateUser(state));
    const [octorateEmail, setOctorateEmail] = useState(null);

    // INITIALIZATION
    useEffect(() => {
        setCreatePublicUrl(integration.createPublicUrl);
        setSendInvitationURL(integration.sendInvitationURL ?? true);
        setCheckIn(integration.check_in ?? '00:00');
        setCheckOut(integration.check_out ?? '00:00');

        if (integration.uid) {
            dispatch(PMSIntegrationsActions.getOctorateUserInfo(integration.uid));
        }
    }, [integration]);

    useEffect(() => {
        if (octorateUserInfo !== null)
            setOctorateEmail(octorateUserInfo.email);
    }, [octorateUserInfo]);

    const canSave = useMemo(() => {
        if(hasError) return false;

        return (sendInvitationURL !== integration.sendInvitationURL || 
            createPublicUrl !== integration.createPublicUrl ||
            checkIn !== integration.check_in ||
            checkOut !== integration.check_out ||
            daysForInvitationCreate !== integration.daysForInvitationCreate
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sendInvitationURL, 
        createPublicUrl, 
        hasError,
        checkIn,
        checkOut,
        daysForInvitationCreate
    ]);

    const onSave = useCallback(async () => {
        const editedIntegration: Integration = {
            company: integration.company,
            type: integration.type,
            uid: integration.uid,
            check_in: checkIn,
            check_out: checkOut,
            createPublicUrl,
            sendInvitationURL,
            daysForInvitationCreate
        }

        try {
            await dispatch(PMSIntegrationsActions.createIntegration(editedIntegration));
        } catch (err: any) {
            setError(err.message)
        }
    }, [
        dispatch, 
        t, 
        setError, 
        integration.company, 
        integration.type, 
        integration.uid, 
        createPublicUrl, 
        sendInvitationURL
    ]);

    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            { !!!integration.uid &&
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <div>
                        <p>Conectar con &nbsp;<strong>Octorate</strong></p>
                    </div>
                </div>
            }
            { !!!integration.uid && 
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                    <OctorateOauthPopup
                        clientId={OCTORATE_CLIENT_ID}
                        redirectUri={OCTORATE_REDIRECT_URI}
                    >
                        <CapsuleButton
                            text={t("login_login_button")}
                            size={'SMALL'}
                        />
                    </OctorateOauthPopup>
                    </div>
                </div>
            }
            { integration.uid && octorateEmail &&  
                <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="c-text-capitalize-first">
                        Conectado a Octorate mediante 
                    </div>
                    <b>{octorateEmail}</b>
                </div>
            }
            { integration.uid  && 
                <div>
                    <div className="mt-2">
                        <CreatePublicUrlToggle
                            boldedName={false}
                            initialState={createPublicUrl}
                            readOnly={!integration.editable}
                            onChange={setCreatePublicUrl}
                        />
                        { enableSendInvitationURLToggle &&
                            <div className="mt-2 mb-2 cp d-flex align-items-center justify-content-between">
                                <div>Días de antelación para crear el Link App</div>
                                <CustomNumberInput 
                                    value={daysForInvitationCreate}
                                    min={1}
                                    max={5}
                                    onChange={updateDaysForInvitationCreation}
                                />
                            </div>
                        }
                        { enableSendInvitationURLToggle && 
                            <div className="mt-2">
                                <SendInvitationURLToggle
                                    boldedName={false}
                                    initialState={sendInvitationURL}
                                    readOnly={!integration.editable}
                                    onChange={setSendInvitationURL}
                                />
                            </div>
                        }
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                        <div className="c-text-capitalize-first">
                            Check In
                        </div>
                        <TimePicker
                            disabled={!integration.editable}
                            showSecond={false}
                            minuteStep={5}
                            value={getHourAndMinutesFormat(checkIn)}
                            format={TIME_FORMAT}
                            onChange={(value) =>
                                setCheckIn(
                                    value
                                        ? value?.format(TIME_FORMAT)
                                        : MIN_TIME,
                                )
                            }
                            />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                        <div className="c-text-capitalize-first">
                            Check Out
                        </div>
                        <TimePicker
                            disabled={!integration.editable}
                            showSecond={false}
                            minuteStep={5}
                            value={getHourAndMinutesFormat(checkOut)}
                            format={TIME_FORMAT}
                            onChange={(value) =>
                                setCheckOut(
                                    value
                                        ? value?.format(TIME_FORMAT)
                                        : MIN_TIME,
                                )
                            }
                        />
                    </div>
                    <div className="mt-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            {integration.uid &&
                                <CapsuleButton
                                    size="SMALL"
                                    classNameExtra="mr-2"
                                    style={!integration.editable ? 'PRIMARY' : 'INFO'}
                                    onClick={() => onToggleEditable(index)}
                                    text={t(!integration.editable ?  "global_edit" : 'global_cancel')}
                                />
                            }
                            {integration.uid &&
                                <CapsuleButton
                                    onClick={() => onDelete(index)}
                                    classNameExtra="mr-2"
                                    // eslint-disable-next-line react/style-prop-object
                                    style="DANGER"
                                    size="SMALL"
                                    icon={Icons.trash}
                                />
                            }
                            <CapsuleButton
                                size="SMALL"
                                isEnabled={canSave}
                                onClick={onSave}
                                text={t("global_save")}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}