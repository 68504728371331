import { USER_LOG_TYPES } from "../_constants/userLogs.constants";

import missingIcon from './icons/device-icon-missing.png';

import positionIcon from "./appIcons/icon_user_log_position.png";

import arrivingIcon from "./appIcons/icon_user_log_arriving.png";
import leavingIcon from "./appIcons/icon_user_log_leaving.png";

import shareBeginIcon from "./appIcons/icon_user_log_sharing_on.png";
import shareEndIcon from "./appIcons/icon_user_log_sharing_off.png";

import walkingIcon from "./appIcons/icon_user_log_walk.png";
import vehicleIcon from "./appIcons/icon_user_log_vehicle.png";
import stillIcon from "./appIcons/icon_user_log_still.png";

export function getUserLogIcon(logType) {
    switch (logType) {
        case USER_LOG_TYPES.EVENT_POSITION:
            return positionIcon;

        case USER_LOG_TYPES.EVENT_LOCATION_ARRIVING:
            return arrivingIcon;

        case USER_LOG_TYPES.EVENT_LOCATION_LEAVING:
            return leavingIcon;

        case USER_LOG_TYPES.EVENT_BEGIN_SHARE_POSITION:
            return shareBeginIcon;

        case USER_LOG_TYPES.EVENT_END_SHARE_POSITION:
            return shareEndIcon;

        case USER_LOG_TYPES.EVENT_WALK_ENTER:
            return walkingIcon;

        case USER_LOG_TYPES.EVENT_VEHICLE_ENTER:
            return vehicleIcon;

        case USER_LOG_TYPES.EVENT_STILL_ENTER:
            return stillIcon;

        default:
            return missingIcon;
    }
}