import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { removeDuplicates } from '../../Common/_utils/utils';
import { invitationsActions } from '../_actions';
import { CapsuleButton, CaptionText, MainPageWithButtons, SegmentedControl } from '../_components';
import { INVITATION_PERIODS, INVITATION_STATUS, LOCAL_INVITATION_STATUS } from '../_constants/invitations.constants';
import { appPermissions, LOCATION_PERMISSIONS } from '../_constants/permissions.constants';
import { InvitationsHelper } from '../_helpers/InvitationsHelper';
import { useValidation } from "../_hooks/useValidation";
import { Invitation } from '../_models/Invitation';
import { InvitationGroup } from '../_models/InvitationGroup';
import { Selectors } from '../_reducers/app.reducer';
import { InvitationPublicUrl } from './InvitationPublicUrl';
import { AccessSelectorComponent } from "./_components/AccessSelectorComponent";
import { ComponentsWrapper } from "./_components/ComponentsWrapper";
import { EditInvitationButtonsComponent } from './_components/EditInvitationButtonsComponent';
import { InvitationTimeSchedule } from './_components/InvitationTimeSchedule';
import { PermissionSelectorComponent } from './_components/PermissionSelectorComponent';
import { SourceAndRecipientComponent } from './_components/SourceAndRecipientComponent';
import { SubinvitationsSelectorComponent } from './_components/SubinvitationsSelectorComponent';
import { ValidatedEmailsInput } from './_components/ValidatedEmailsInput';
import { isEqual } from 'lodash';

const testIDs = {
    root: "invitaton-config",
    sendInvitationButton: "invitaton-config-send-button",
};

InvitationConfigView.testIDs = testIDs;

export function InvitationConfigView({
    canCreateSuperguests,
    invitation = Invitation(),
    timezone,
    locationInfo,
    rooms,
    isSuperguest,
    cleanUpFn,
}) {    

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const locationPermission = locationInfo.locationPermission;
    const invitationFromList = useSelector(state => Selectors.getInvitationFromListByUid(state, invitation.uid));
    // const isEdited = !isEqual(invitation, invitationFromList);

    //LOCAL STATE
    const [invitationsLeft, setInvitationsLeft] = useState(locationInfo.invitationsLeft);

    function handleToggleActiveInvitation(status) {
        dispatch(invitationsActions.setEnabledServer(invitation.uid, status === 'ACTIVE'));
    }

   function handleDeleteInvitation() {
        dispatch(invitationsActions.deleteInvitationUi(invitation, isSuperguest));
    }


    const canUserDeleteInvitations = appPermissions.canUserDeleteInvitations(
        locationPermission,
        isSuperguest,
    );

    const onChangePermission = useCallback(
        (permission) => {
            dispatch(
                invitationsActions.changeInvitationPermission(
                    permission,
                    invitation?.publicUrl,
                    invitation.newInvitation,
                ),
            );
        },
        [dispatch, invitation.newInvitation, invitation?.publicUrl],
    );

    const {
        handleSetValid,
        isAllValid,
        forceWarnings,
        setForceWarnings,
    } = useValidation();

    //ACTION HANDLERS
    const handleChangeEmails = useCallback(emails => {

        emails = removeDuplicates(emails)
        if(invitationsLeft !== null) {
            emails = emails.slice(0, invitationsLeft);
            setInvitationsLeft(invitationsLeft - emails.length);
        }

        dispatch(invitationsActions.setEmails(emails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleSetValidEmails = useCallback(isValid => {
        handleSetValid("EMAILS", isValid);
    }, [handleSetValid]);


    const onSave = useCallback(() => {
        setForceWarnings(true);
    }, [setForceWarnings]);

    const handlePublicUrlOnCreation = useCallback((createPublicUrl) => 
        dispatch(
            invitationsActions.updateSelectedLocally({
                createPublicUrl 
            }),
        )
    , [dispatch]);

    const locationUid = useSelector(Selectors.getSelectedLocationUid);

    let group = InvitationGroup();
    group = useSelector((state) =>
        Selectors.getInvitationGroupByUid(
            state,
            locationUid,
            invitation.invitationGroupUid,
        ),
    );

    useEffect(() => {
        if ((invitation?.periodType !== INVITATION_PERIODS.TEMPORARY ||
            invitation?.permissionType === LOCATION_PERMISSIONS.INSTALLER)) {
            handlePublicUrlOnCreation(false);
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitation?.periodType, invitation?.permissionType, invitation?.newInvitation])

    
    //CLEAN UP
    useEffect(() => {
        return cleanUpFn;
    }, [cleanUpFn]);

    const canUserEdit = useMemo(() => appPermissions.canUserEditInvitations(
        locationPermission,
        isSuperguest,
    ), [locationPermission, isSuperguest]);

    const canUserPublicUrl = useMemo(() => appPermissions.canSeePublicUrl(
        locationPermission,
        isSuperguest,
    ), [locationPermission, isSuperguest]);


    const invitationPublicUrlSection = useMemo(() => {
        if(!canUserPublicUrl) return null;
        if (invitation?.localStatus === LOCAL_INVITATION_STATUS.CANCELLED) return
        if (invitation.publicUrl ||
            ((invitation.periodType === INVITATION_PERIODS.TEMPORARY && invitation.end) &&
             invitation.email)
        ) return <InvitationPublicUrl invitation={invitation} isEditable={canUserEdit} />

        return 
    }, [canUserEdit, canUserPublicUrl, invitation]);

    //VIEW
    if (!invitation)
        return null;

    let content;
    if (isSuperguest) {
        content = subinvitationView();
    }  else {
        content = normalInvitationView();
    }

    return (
        <MainPageWithButtons
            dataTestId={testIDs.root}
            pageTitle={t("invitations.invitation")}
            content={content}
            buttons={EditInvitationButtonsComponent({
                isEdited: invitation.edited,
                sendButtonTestID: testIDs.sendInvitationButton,
                invitation,
                isAllValid,
                onSaveExtra: onSave,
            })}
        />
    );

    function subinvitationView() {
        return (
            <div className="d-flex flex-column justify-content-between h-100">
                <ComponentsWrapper
                    componentsArray={[
                        emailsOrSourceRecipient(),
                        wiredTimeSelector(),
                        invitationPublicUrlSection,
                    ]}
                />
                {canUserDeleteInvitations && deleteButton()}
            </div>
        );
    }

    function groupRow() {
        return invitation.invitationGroupUid ? <div className="d-flex justify-content-between ">
            <h3>{t('global_group')}</h3>
            <div>{group?.name}</div>
        </div> : null; 
    }

    function subinvitationsComponent(isEditable) {
        if (invitation.newInvitation && canCreateSuperguests) {
            return (
                <SubinvitationsSelectorComponent
                    canCreateSuperguests={canCreateSuperguests}
                    invitation={invitation}
                    isEditable={isEditable && !invitation.isSubinvitation}
                    isNew={invitation.newInvitation}
                />
            );
        }
        if (!invitation.allowsSubinvitations) return null;
        return (
            <div className="d-flex justify-content-between">
                <div className="font-weight-bold">
                    {t("mod_invitations_subinvitations_allowed")}
                </div>
                <div>{invitation.subinvitations}</div>
            </div>
        );
    }

    function normalInvitationView() {
        
        const isEditable = canUserEdit && InvitationsHelper.isInvitationEditable(invitation);
        const isPermissionEditable = (
            InvitationsHelper.canEditInvitationPermission(locationPermission, invitation) && 
            isEditable && 
            !invitation.isSubinvitation
        );

        return (
            <div className="d-flex flex-column justify-content-between h-100">
                <ComponentsWrapper
                    componentsArray={[
                        emailsOrSourceRecipient(),
                        <AccessSelectorComponent
                            rooms={rooms}
                            invitation={invitation}
                            isReadOnly
                            onChangeInvitationType={onChangeInvitationType}
                            onSelectRoom={onSelectRoom}
                        />,
                        groupRow(),
                        <PermissionSelectorComponent
                            invitation={invitation}
                            locationPermission={locationPermission}
                            isEditable={isPermissionEditable}
                            onChangePermission={onChangePermission}
                        />,
                        subinvitationsComponent(isEditable),
                        isStatusSelectorVisible() && statusSelector(),
                        wiredTimeSelector(),
                        invitationPublicUrlSection,
                    ]}
                />
                {canUserDeleteInvitations && !invitation.newInvitation && deleteButton()}
            </div>
        );
        
        function isStatusSelectorVisible() {
            if (invitation.status === INVITATION_STATUS.ACCEPTED_EXPIRED)
                return false;

            return (
                invitation.localStatus === LOCAL_INVITATION_STATUS.ACTIVE
                || invitation.localStatus === LOCAL_INVITATION_STATUS.INACTIVE
            );
        }

        function statusSelector() {
            return (
                <div>
                    <h3>{t("global_status")}</h3>
                    <SegmentedControl
                        preventDefaultSelect
                        onSelect={handleToggleActiveInvitation}
                        defaultSelected={{ value: invitation.localStatus === LOCAL_INVITATION_STATUS.ACTIVE ? 'ACTIVE' : 'INACTIVE' }}
                        segments={[
                            { label: t("common.active"), value: 'ACTIVE' },
                            { label: t("common.inactive"), value: 'INACTIVE' },
                        ]}
                    />
                </div>
            );
        }

    }

    function deleteButton() {
        return (
            <div className="d-flex justify-content-end mt-2">
                <CapsuleButton
                    text={t('common.delete')}
                    onClick={handleDeleteInvitation}
                    size={"SMALL"}
                    style={`DANGER`}
                />
            </div>
        );
    }
    //WIRED COMPONENTS
    function emailsOrSourceRecipient() {
        return invitation.newInvitation ? (
            <div>
                <ValidatedEmailsInput
                    setIsValid={handleSetValidEmails}
                    forceWarnings={forceWarnings}
                    emails={invitation.emails}
                    handleChangeEmailsUnwired={handleChangeEmails}
                />
                {locationInfo.invitationsLeft !== null && (
                    <CaptionText className="mt-1 ml-1">
                        {t("mod_invitations_remaining")}: {invitationsLeft}
                    </CaptionText>
                )}
            </div>
        ) : (
            <SourceAndRecipientComponent invitation={invitation} />
        );
    }

    function wiredTimeSelector() {
        return (
            <InvitationTimeSchedule
                invitation={invitation}
                timezone={timezone}
            />
        );
    }

    //ACTION HANDLERS
    function onChangeInvitationType(type) {
        dispatch(invitationsActions.changeInvitationType(type));
    }

    function onSelectRoom(elementUid) {
        dispatch(invitationsActions.changeObjectUid(elementUid));
    }

}