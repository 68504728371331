import React, { forwardRef } from 'react';
import moment from 'moment-timezone';
import { i18n } from '../_translations/i18n';

import reservationsIcon from "../_assets/appIcons/icon_module_reservations.svg";
import { TimeHelper } from '../_helpers';
import { LocalizedDatePicker } from './LocalizedDatePicker';

function CustomDateInput({
    onClick = () => {},
    value,
    showTimeSelectOnly,
    text,
    readOnly
} = {}) {

    return (
        <div
            className={`
                border
                rounded

                c-cursor-default
                c-hover-240

                d-flex
                align-items-center
                justify-content-between
                ${readOnly ? 'cna' : 'cp'}
                pl-2
            `}
            onClick={onClick}
        >
            <div className="d-flex align-items-center">
                {textFn()}
                <div className="font-weight-bold">
                    {contentFn()}
                </div>
            </div>
            {image()}
        </div>
    );

    function image() {
        return (
            <div className="
                d-flex
                justify-content-center
                align-items-center
                c-img-1-5rem
            ">
                <img
                    className="c-img-1rem"
                    src={reservationsIcon}
                    alt={"date_selector_icon"}
                />
            </div>
        );
    }

    function textFn() {
        if (!text)
            return;
        return (
            <span className="mr-2 text-capitalize">
                {text}:
            </span>
        );
    }

    function contentFn() {
        if (!value) {
            return showTimeSelectOnly
                ? i18n.t("common.select")
                : i18n.t("common.selectDate")
            ;
        }

        return value;
    }
}

IsoStringDatePicker.popperPlacementOptions = () => {
    return {
        auto: 'auto',
        autoLeft:'auto-left',
        autoRight:'auto-right',
        bottom: 'bottom',
        bottomEnd: 'bottom-end',
        bottomStart: 'bottom-start',
        left: 'left',
        leftEnd: 'left-end',
        leftStart:'lec-ft-start',
        right: 'right',
        rightEnd: 'right-end',
        rightStart: 'right-start',
        top: 'top',
        topEnd: 'top-end',
        topStart: 'top-start',
    };
}

export function IsoStringDatePicker({
    text,

    isoString,
    showTimeSelectOnly,
    timezone,
    popperPlacement,
    readOnly,
    timeIntervals,
    selectsRange,
    dateOnly,
    customInputFn,

    minDate,
    maxDate,
    onChange: onChangeFn = (date) => {},

    useDefaultInput = false,
    className,

    container,
    placeholderText,
    onChangeRaw = event => {},
    onBlur,
}) {

    const timezoneOffset = timezone ? moment.utc().tz(timezone).utcOffset() : 0;

    //DONE TO CAPTURE REF SENT BY DATEPICKER AND AVOID WARNING
    const CustomInputHelper = forwardRef( (props, ref) => (
        <CustomDateInput
            {...props}
        />
    ));

    return (
            <LocalizedDatePicker
            selected={TimeHelper.isoDateToLocalJsDate(isoString, timezone)}
            onChange={handleOnChange}
            
            timeFormat="HH:mm"
            showTimeSelect={!dateOnly}
            showTimeSelectOnly={showTimeSelectOnly}
            timeIntervals={timeIntervals || 15}
            dateFormat={dateFormat(showTimeSelectOnly)}
            readOnly={readOnly}

            popperPlacement={popperPlacement}
            customInput={
                setCustomInput(
                    customInputFn,
                    showTimeSelectOnly,
                )
            }
            onChangeRaw={onChangeRaw}
            onBlur={onBlur}
            
            minDate={minDate && TimeHelper.isoDateToLocalJsDate(minDate, timezone)}
            maxDate={maxDate && TimeHelper.isoDateToLocalJsDate(maxDate, timezone)}
            selectsRange={selectsRange}
            className={className}
            popperContainer={container}
            placeholderText={placeholderText}
        />
    );

    function dateFormat(showTimeSelectOnly) {
        if (showTimeSelectOnly)
            return "HH:mm";

        if (dateOnly)
            return "dd/MM/yyyy";
        
        return "dd/MM/yyyy HH:mm";
    }

    function setCustomInput(customInputFn, showTimeSelectOnly) {
        if (customInputFn)
            return customInputFn();

        if (useDefaultInput)
            return;

        return <CustomInputHelper
            showTimeSelectOnly={showTimeSelectOnly}
        />;
    }

    function handleOnChange(localJsDate) {
        if (!localJsDate)
            return;

        return onChangeFn(localJsDateToIsoDate(localJsDate, timezoneOffset));
    }
}

//CONVERSION FNS


function localJsDateToIsoDate(localJsDate, timezoneOffset) {
    const offsetMoment = moment(localJsDate.toISOString());
    removeTimezoneOffset(offsetMoment);
    return offsetMoment.toISOString();

    function removeTimezoneOffset(inputMoment) {
        inputMoment.utcOffset(timezoneOffset, true);
    }
}