import { useEffect, useState } from 'react';

export function useScreenWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        function handleResize() {
            setWidth(window.innerWidth);
        }

        //Clean-up
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return width;
}