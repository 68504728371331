import React from "react";
import { useTranslation } from 'react-i18next';
import { PaymentMethodSelector } from "../_components";
import { TimeHelper } from "../_helpers";
import { CapsuleButton } from "../_components";
import { SUBSCRIPTION_STATUS, SubscriptionUtils } from "../_constants/subscriptions.constants";
import { Subscription } from "../_models/Subscription";
import { ReadOnlyDataRow } from "../_components/ReadOnlyDataRow";
import { WaitSpinner } from "../_components/WaitSpinner";

export function SubscriptionPaymentStatusSection({
    subscription = Subscription(),
    timezone,
    canManagePayments = false,
    hasPaymentMethod = false,

    onEnableRenewal,
    onDisableRenewal,

    isModifyingSubscription = false,
} = {}) {

    //LIBRARIES
    const { t } = useTranslation();

    //VIEW
    return (
        <div>
            {statusDataSection()}
            <div className="mt-3"/>
            {changeRenewalSection()}
        </div>
    );

    function statusDataSection() {
        return (
            <>
            <div className="font-weight-bold text-uppercase">
                {t("global_status")}
            </div>
            <div className="border-bottom" />
            {status()}
            {expirationRenovationDate()}
            {automaticRenovationStatus()}
            {subscription.isAutomatedPayment &&
                subscriptionData()
            }
            </>
        );

        function status() {
            return readOnlyRow(
                t("common.status"),
                subscription.statusLabel,
            );
        }

        function expirationRenovationDate() {
            return readOnlyRow(
                subscription.isAutomatedPayment
                    ? t("subscription_renovation_date")
                    : t("subscription_expiration_date")
                ,
                TimeHelper.localizeIsoStringToFormat(
                    subscription.end,
                    timezone,
                    TimeHelper.getTimeFormats().DATE_NO_SECONDS,
                )
            );
        }
    
        function automaticRenovationStatus() {
            return readOnlyRow(
                t("subscription_automatic_renovation"),
                subscription.isAutomatedPayment
                    ? t("global_enabled")
                    : t("global_disabled")
                ,
            );
        }
    }

    function subscriptionData() {
        return (
            <div>
                {readOnlyRow(
                    t("subscription_renovation_period"),
                    SubscriptionUtils.getTagForRenewalPeriod(subscription.renewalPeriod),
                )}
                {readOnlyRow(
                    t("global_price"),
                    subscription.price + "€",
                )}
            </div>
        );
    }

    function changeRenewalSection() {
        if (!canManagePayments)
            return;

        if (isModifyingSubscription)
            return processingWaitSpinner();

        return subscription.isAutomatedPayment
            ? disableAutomaticRenovation()
            : enableAutomaticRenovation()
        ;
    }

    function disableAutomaticRenovation() {
        return (
            <div className="d-flex justify-content-center">
                <CapsuleButton
                    text={t("subscription_disable_automatic_renovation")}
                    style={`DANGER`}
                    onClick={onDisableRenewal}
                />
            </div>
        );
    }

    function processingWaitSpinner() {
        return (
            <div className="
                d-flex
                flex-column
                align-items-center
                justify-content-center
            ">
                <div className="border rounded px-2 pb-2 bg-light">
                    <div>
                        <WaitSpinner />
                    </div>
                    <div className="c-font-weight-medium">
                        {t("global_processing")}...
                    </div>
                </div>

            </div>
        );
    }

    function enableAutomaticRenovation() {
        const text = subscription.status === SUBSCRIPTION_STATUS.ACTIVE
                ? t("subscription_enable_automatic_renovation")
                : t("subscription_reactivate")
                ;
        return (
            <div>
                <div className="font-weight-bold text-uppercase">
                    {text}
                </div>
                <div className="border-top"/>
                {subscriptionData()}
                <div className="mt-3" />
                {hasPaymentMethod
                    ? enableSubscriptionButton()
                    : addPaymentMethodMessage()
                    }
                <div className="mt-3"/>
                <PaymentMethodSelector />
            </div>
        );

        function addPaymentMethodMessage() {
            return (
                <div className="
                    border
                    rounded
                    py-1
                    c-bg-light-red

                    text-center
                    c-font-weight-medium
                ">
                    {t("mod_payments_add_method_to_enable_renewal")}
                </div>
            );
        }

        function enableSubscriptionButton() {
            return (
                <div className="d-flex justify-content-center">
                    <CapsuleButton
                        text={text}
                        fillStyle={'FILLED'}
                        onClick={onEnableRenewal}
                    />
                </div>
            );
        }
    }

    function readOnlyRow(title, value) {
        return <ReadOnlyDataRow
            data={value}
            name={title}
        />;
    }
}