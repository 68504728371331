import { TimeHelper } from "../_helpers/TimeHelper";
import { filterDisabled, nullValues } from "../_constants";
import { LogsHelper } from "../_helpers/LogsHelper";
import { LogModel } from "../_models/LogModel";
import { DeviceLogsUiHelper } from "../_helpers/DeviceLogsUiHelper";

export class LogsMapper {

    static mapAllServerToLocal(serverLogs) {
        return serverLogs.map(this.mapServerToLocal);
    }

    static mapServerToLocal(serverLog) {
        const localStatus = statusServerToLocal(serverLog.log_ui_type, serverLog.status);
        return LogModel({
            logId: serverLog.log_id,
            deviceUid: serverLog.uid,
            
            userName: userNameServerToLocal(serverLog.user_name),
            userEmail: userNameServerToLocal(serverLog.user_email),

            logType: serverLog.log_ui_type,
            logTypeName: logTypeNameServerToLocal(serverLog.log_ui_type),
            status: localStatus,
            statusLabel: DeviceLogsUiHelper.getLocalStatusLabel(localStatus),

            datetime: TimeHelper.serverStringToIsoString(serverLog.datetime),
        });
    }

    static mapLogFiltersLocalToServer(localUiLogType, uiStatus, uiUser) {
        return {
            serverUiLogType: logTypeLocalToServer(localUiLogType),
            serverStatus: statusLocalToServer(uiStatus),
            serverUser: userLocalToServer(uiUser),
        };
    }
}

function userNameServerToLocal(serverUserName) {
    let uiUserName = serverUserName;

    if (uiUserName === null)
        uiUserName = nullValues.user;

    return uiUserName;
}

function userLocalToServer(uiUser) {
    if (Number(uiUser) === filterDisabled.number)
        return undefined;

    if (uiUser === nullValues.user)
        return null;

    return uiUser;
}

function logTypeLocalToServer(localUiLogType) {
    if (Number(localUiLogType) === filterDisabled.number)
        return null;

    return localUiLogType;
}

function statusLocalToServer(uiStatus) {
    if (uiStatus === undefined || Number(uiStatus) === filterDisabled.number)
        return null;

    return uiStatus;
}


function statusServerToLocal(serverUiLogType, serverStatus) {
    return LogsHelper.getLocalStatus(serverUiLogType, serverStatus);
}

function logTypeNameServerToLocal(serverUiLogType) {
    return LogsHelper.getUiLogTypeName(serverUiLogType);
}