export function Device({
    uid,
    bluetoothId,

    description,
    loc_uid,
    room_uid,
    kit_uid,
    status,
    type,
    timer,
    battery,
    update,
    originalType,
    version,
    lastActivity,
    isControlAccessDisabled = false,

    location_name,
    room_name,

    category,
    cellType,
    statusLabel,

    notification,
    accessUsageCount,

    accessType,

    accessEntryWalk,
    accessEntryVehicle,
    accessExitWalk,
    accessExitVehicle,

    txPower,
    accessLevel,
    extender,
    remote,
    beacon,
    isBarrier,

    installationNotes,

    accessUsageNotification = false,
    
    updateVersion = null,
    isNfcCardsSynchronized = false,
    isCalibrated = false,

    wlan_ssid,
    wifi_rssi,
    wlan_status,
    eth_status,
    conn_status,

    localStatus,

    versionWifi,
    versionBle,

    // BRAIN ATTRS
    streamMinutesRemaining,

    //Firmware versions
    firmwareVersions,
    updating,
    updateStatus, 
    updatePercent,

    // GowayLock
    knockTimeout,
    lockPower,
    isIrEnabled,
    restrictExit
    
} = {}) {

    return {
        uid,
        bluetoothId,
        
        
        description,
        loc_uid,
        room_uid,
        kit_uid,
        status,
        type,
        timer,
        battery,
        update,
        originalType,
        version,
        lastActivity,
        isControlAccessDisabled,
        
        location_name,
        room_name,
        
        category,
        cellType,
        
        notification,
        accessUsageCount,
        
        installationNotes,
        
        accessType,
        
        accessEntryWalk,
        accessExitWalk,
        accessExitVehicle,
        accessEntryVehicle,
        
        txPower,
        accessLevel,
        extender,
        remote,
        beacon,
        isBarrier,
        
        accessUsageNotification,
        updateVersion,
        isNfcCardsSynchronized,
        isCalibrated,
        
        wlan_ssid,
        wifi_rssi,
        wlan_status,
        eth_status,
        conn_status,
        restrictExit,
        //LOCAL PARAMS
        statusLabel,
        show: true,
        selected: false,
        localStatus,
        
        versionWifi,
        versionBle,
        // BRAIN ATTRS
        streamMinutesRemaining,
        
        // Firmware version
        firmwareVersions,
        updating,
        updateStatus, 
        updatePercent,

        // GowayLock
        knockTimeout,
        lockPower,
        isIrEnabled
    };
}