import { Mobile } from "../_models/Mobile";
import { MOBILE_PERMISSION_STATES, MOBILE_TYPES } from "../_constants/mobiles.constants";

export class MobilesHelper {
    static isAnyMobileInIncorrectState(mobiles = [] || [Mobile()]) {
        return mobiles.some(mobile => {
            if (mobile.type === MOBILE_TYPES.WEB)
                return false;

            if (!mobile.isUpToDate)
                return true;

            if (isAnyPermissionWrong(mobile))
                return true;

            return false;
        })

        function isAnyPermissionWrong(mobile = Mobile()) {
                
            let permissionsToCheck = [
                mobile.activityStatusPermission,
                mobile.blePermission,
                mobile.locationPermission,
                mobile.notificationsPermission,
            ];

            if (mobile.type === MOBILE_TYPES.ANDROID) {
                permissionsToCheck.push(
                    mobile.locationServicePermission,
                    mobile.batteryPermission,
                );

            }

            return permissionsToCheck.some(MobilesHelper.isMobilePermissionStateInvalid);
        }
    }

    static isMobilePermissionStateInvalid(mobilePermissionState) {
        const invalidPermissionStates = [
            MOBILE_PERMISSION_STATES.DISABLED,
            MOBILE_PERMISSION_STATES.NOT_REQUESTED,
            MOBILE_PERMISSION_STATES.WHILE_USING_APP,
        ];

        return invalidPermissionStates.includes(mobilePermissionState);
    }
}