import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IsoStringDatePicker } from "./";
import { SimpleToggle } from "./";
import { DaysOfWeekSelector } from "./";
import { WeekdaysModel } from "../_models/WeekdaysModel";
import { CaptionText } from "../../Common/_components/CaptionText";

const testIDs = {
    allDayToggle: "time-selector-all-day-toggle",
};

WeekAndTimeSelector.testIDs = testIDs;

export function WeekAndTimeSelector({
    isEditable = true,

    allDay,
    begin,
    end,

    handleStartDateChange,
    handleEndDateChange,
    handleToggleAllDay,
    handleToggleWeekDay,

    weekdays = WeekdaysModel(),
    timeIntervals,

    setIsWeekValid,
}) {
    //LIBRARIES
    const { t } = useTranslation();


    const timeSelectors = useMemo(() => {

        if (allDay) return;

        return (
            <div className="d-flex justify-content-between w-100">
                {timeSelector(
                    t("common.from"),
                    begin,
                    handleStartDateChange,
                    !isEditable,
                    "mr-2",
                )}
                {timeSelector(
                    t("common.to"),
                    end,
                    handleEndDateChange,
                    !isEditable,
                )}
            </div>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDay, begin, end, handleEndDateChange, handleStartDateChange, isEditable, timeSelector]);

    //VIEW
    return (
        <div className="mt-3">
            <div>
                <DaysOfWeekSelector
                    weekdays={weekdays}
                    handleToggleWeekDay={handleToggleWeekDayLocal}
                    setIsValid={setIsWeekValid}
                />
            </div>
            <div className="mt-3">
                <div className="d-flex justify-content-between">
                    <h3>{t("common.allDay")}</h3>
                    <SimpleToggle
                        testID={testIDs.allDayToggle}
                        handleChange={handleToggleAllDay}
                        checked={allDay}
                        readOnly={!isEditable}
                    />
                </div>
                {timeSelectors}
            </div>
        </div>
    );

    function timeSelector(text, isoDate, onChange, readOnly, className = "") {
        return (
            <div className={`${className} w-100`}>
                <CaptionText className="text-capitalize">{text}</CaptionText>
                <div className="full-width-datepicker">
                    <IsoStringDatePicker
                        showTimeSelectOnly
                        isoString={isoDate}
                        onChange={onChange}
                        readOnly={readOnly}
                        timeIntervals={timeIntervals}
                    />
                </div>
            </div>
        );
    }

    //ACTION HANDLERS
    function handleToggleWeekDayLocal(weekDay) {
        if (!isEditable) return;
        handleToggleWeekDay(weekDay);
    }
}
