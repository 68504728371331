export function Mobile({
    type,
    appVersion,
    osVersion,
    modelVersion,
    
    activityStatusPermission,
    blePermission,
    locationPermission,
    locationServicePermission,
    notificationsPermission,
    batteryPermission,
    bleScanPermission,
    backgroundLocationPermission, 
    osVersionDescription,
    
    isActive = false,
    isUpToDate = false,
} = {}) {
    return {
        type,
        appVersion,
        osVersion,
        modelVersion,

        activityStatusPermission,
        blePermission,
        locationPermission,
        locationServicePermission,
        notificationsPermission,
        batteryPermission,
        bleScanPermission,
        backgroundLocationPermission, 
        osVersionDescription,

        isActive,
        isUpToDate,
    };
}