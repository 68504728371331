import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { CapsuleButton, ContentSpace, ListedGroup, MainPageWithButtons } from "../../Common/_components";
import { DevelopersStore } from "../DevelopersStore";
import { SimpleListElement } from "../../Common/_components/SimpleListElement";
import { DevToken } from "../_models/DevToken";
import { Icons } from "../../Common/_assets/icons/Icons";
import { DevTokensActions } from "../_actions/DevTokensActions";
import { DevPopUpActions } from "../_actions/DevPopUpActions";
import { DevelopersPopUps } from "../DevelopersPopUps";

export function DevTokensModule() {
    
    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //GLOBAL STATE
    const tokens = useSelector(DevelopersStore.selectors.getTokens);

    //ACTION HANDLERS
    function handleDeleteToken(uid) {
        dispatch(DevPopUpActions.show(
            DevelopersPopUps.IDS.REMOVE_TOKEN,
            { uid },
        ));
    }

    function handleCreateToken() {
        dispatch(DevTokensActions.add());
    }

    function handleCopyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    //INTIALIZATION
    useEffect(() => {
        dispatch(DevTokensActions.list());
    }, [dispatch]);

    //VIEW
    return (
        <ContentSpace
            mainContent={
                <MainPageWithButtons
                    pageTitle={t("mod_dev_tokens_title")}
                    content={content()}
                />
            }
        />
    );

    function content() {
        return (
            <div>
                <div className="mt-2"/>
                <div className="d-flex justify-content-end">
                    <CapsuleButton
                        text={t("global_add")}
                        onClick={handleCreateToken}
                        size={'SMALL'}
                    />
                </div>
                <div className="mt-2"/>
                <ListedGroup
                    headerText={"Tokens"}
                    elements={uiTokens()}
                />
            </div>
        );

        function uiTokens() {
            if (!tokens.length)
                return [
                    <div className="text-center my-2">
                        {t("mod_dev_tokens_none_found")}
                    </div>
                ];

            return tokens.map(token => 
                <SimpleListElement
                    text={singleUiToken(token)}
                />
            );
        }

        function singleUiToken(token = DevToken()) {
            return (
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <CapsuleButton
                            icon={Icons.copy}
                            onClick={() => handleCopyToClipboard(token.apikey)}
                            size={'SMALL'}
                        />
                        <div className="ml-2" />
                        <div className="bg-light border rounded px-1">
                            {token.apikey}
                        </div>
                    </div>
                    <div>
                        <CapsuleButton
                            icon={Icons.trash}
                            style={`DANGER`}
                            onClick={() => handleDeleteToken(token.uid)}
                            size={'SMALL'}
                        />
                    </div>
                </div>
            )
        }
    }
}