import { Selectors } from "../../_reducers/app.reducer";
import { ModulePresets } from "../../_modules/ModulePresets";
import { EverouModules } from "../../_modules/EverouModules";
import { ModulesActionsFactory } from "../../../Common/_stores/ModulesStore/ModulesActionsFactory";
import { EVEROU_MODULE_IDS } from "../../_constants";

const genericModulesActions = ModulesActionsFactory({
    defaultAppModule: EVEROU_MODULE_IDS.DEVICES,
});

export const EverouModulesActions = {
    validateCurrentModuleInNewLocation,
    selectModule: genericModulesActions.selectModule,
    buildModules: setUpModules,
    unselectAllModules: genericModulesActions.unselectAllModules,
    _setUpModulesGeneric: genericModulesActions.setUpModules,
    _forceSelectModule: genericModulesActions.forceSelectModule,
};

function validateCurrentModuleInNewLocation() {
    return (dispatch, getState) => {
        const { enabledModules } = dispatch(getEnabledModulesAndPreset());


        if (!enabledModules.includes(Selectors.getSelectedModuleId(getState())))
            dispatch(EverouModulesActions._forceSelectModule(null));
    }
}

function getEnabledModulesAndPreset() {
    return (dispatch, getState) => {
        const preset = ModulePresets.getPresetForSubdomainId(
            Selectors.getEntrypointHost(getState()),
        );

        const enabledModules = EverouModules.getEnabledModuleIds({
            presetId: preset && preset.id,
            reservationsConfigs: Selectors.getReservationConfigurations(getState()),
            locationInfo: Selectors.getLocationInfo(getState()),
            userObj: Selectors.getEverouUser(getState()),
        });

        return {
            enabledModules,
            preset,
        };
    };
}

function setUpModules() {
    return (dispatch, getState) => {
        const { enabledModules, preset } = dispatch(getEnabledModulesAndPreset());

        const selectedModuleId = getInitialModuleId();

        dispatch(EverouModulesActions._setUpModulesGeneric(
            enabledModules,
            selectedModuleId,
        ));
        return;

        function getInitialModuleId() {
            const urlModuleId = EverouModules.getModuleIdForUrl(
                Selectors.getInitialUrl(getState())
            );

            return Selectors.getSelectedModuleId(getState())
                || urlModuleId
                || preset?.defaultModule
            ;
        }
    }
}