import { ACCESS_TYPES } from "../_constants";
import { BARRIER_MODES } from "../_constants/barriers.constants";

export class LocationInfoHelper {

    static isBarriersActive(barrierMode, devices) {
        if(barrierMode === BARRIER_MODES.DISABLED) {
            return false;
        }
        
        if(barrierMode === BARRIER_MODES.ENABLED) {
            return true;
        }
        
        if(barrierMode === BARRIER_MODES.NOT_CONFIGURED) {
            const exitDevice = devices.find(device => device.isBarrier && device.accessType === ACCESS_TYPES.EXIT);
            const entryDevice = devices.find(device => device.isBarrier && device.accessType === ACCESS_TYPES.ENTRY);
            return !!exitDevice && !!entryDevice;
        }
    }

}