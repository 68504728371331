import React, { useCallback} from "react";
import { useDispatch } from "react-redux";

import { globalActions } from "../_actions";
import { UserDropdown } from "./UserDropdown";

export function WiredUserDropdown({
    user
}) {

    //LIBRARIES
    const dispatch = useDispatch();

    //ACTION HANDLERS
    const handleLogOut = useCallback(() => 
        dispatch(globalActions.showPopUpLogOut())
    , [dispatch]);

    const handleLogOutAll = useCallback(() =>
        dispatch(globalActions.showPopUpLogOutAll())
    , [dispatch]);

    return (
        <UserDropdown {...{
            user,
            onLogOut: handleLogOut,
            onLogOutAll: handleLogOutAll,
        }}/>
    );
}