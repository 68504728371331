import { MainPageWithButtons } from "../../Everou/_components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PMSLocationDetailSelector } from "./PMSLocationDetailSelector";
import { LocationInfo } from '../../Everou/_models/LocationInfo';
import { Selectors } from '../../Everou/_reducers/app.reducer';
import { useEffect } from "react";
import { PMSLocationDetailViewModes } from "../_constants/pmsLocationsViewMode.constants";
import { PMSLocationLogs } from "./PMSLocationLogs";
import { PMSLocationsActions } from "../_actions/PMSLocationsActions";
import { PMSLocationDevices } from "./PMSLocationDevices";
import { PMSLocationReservations } from "./PMSLocationReservations";
import { PMSLocationConfiguration } from "./PMSLocationConfiguration";

export function PMSLocationDetail() {

    // LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // GLOBAL STATE
    let locationInfo = LocationInfo();
    locationInfo = useSelector(Selectors.getPMSLocationInfo);
    const locationName = useSelector(Selectors.getPMSLocationSelectedName);
    const viewMode = useSelector(Selectors.getPMSLocationDetailViewMode);
    const locationUid = useSelector(Selectors.getPMSLocationSelectedUid);

    // LOCAL STATE


    // ACTION HANDLERS


    // INITIALIZATION
    useEffect(() => {
        dispatch(PMSLocationsActions.getLocationInfo());
    }, [dispatch, locationUid]);

    return (
        <MainPageWithButtons
            size={"LARGE"}
            pageTitle={locationName}
            content={content()}
            buttons={[<PMSLocationDetailSelector />]}
        />
    );

    function content() {
        switch (viewMode) {

            case PMSLocationDetailViewModes.DEVICES: {
                return (
                    <div className="d-flex flex-column justify-content-between h-100">
                        <PMSLocationDevices />
                    </div>
                )
            }

            case PMSLocationDetailViewModes.RESERVATIONS: {
                return (
                    <div className="d-flex flex-column justify-content-between h-100">
                        <PMSLocationReservations />
                    </div>
                )
            }

            case PMSLocationDetailViewModes.CONFIGURATION: {
                return (
                    <div className="d-flex flex-column justify-content-between h-100">
                        <PMSLocationConfiguration />
                    </div>
                )
            }
        }
        return (
            <div className="d-flex flex-column justify-content-between h-100">
                <PMSLocationLogs />
            </div>
        )
    }
}