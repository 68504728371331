import { GenericMapperFactory } from "../../Everou/_mappers/MapperFactory";
import { DevToken } from "../_models/DevToken";

export const DevTokenMapper = GenericMapperFactory(serverToLocal);

function serverToLocal(serverToken) {
    return DevToken({
        uid:    serverToken.uid,
        apikey: serverToken.apikey,
    });
}