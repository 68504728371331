import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { removeDuplicates } from '../../Common/_utils/utils';
import { invitationsActions } from '../_actions';
import { CaptionText } from '../_components';
import { INVITATION_PERIODS } from '../_constants';
import { LOCATION_PERMISSIONS } from '../_constants/permissions.constants';
import { useValidation } from "../_hooks/useValidation";
import { Invitation } from '../_models/Invitation';
import { Selectors } from '../_reducers/app.reducer';
import CreatePublicUrlToggle from './CreatePublicUrlToggle';
import { InvitationTimeSchedule } from './_components/InvitationTimeSchedule';
import { ValidatedEmailsInput } from './_components/ValidatedEmailsInput';

export function NewInvitationGroupTemporary({
    groupUid,
    timezone,
    locationInfo,
}) {
    const { handleSetValid, forceWarnings } = useValidation();

    let invitation = Invitation();
    invitation = useSelector(Selectors.getInvitationNewDraft);
    invitation.invitationGroupUid = groupUid;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [showPublicUrlCreationToggle, setShowPublicUrlCreationToggle] =
        useState(false);

    const [invitationsLeft, setInvitationsLeft] = useState(
        locationInfo.invitationsLeft,
    );

    const handleChangeEmails = useCallback(
        (emails) => {
            emails = removeDuplicates(emails);

            if(invitationsLeft !== null) {
                emails = emails.slice(0, locationInfo.invitationsLeft);
                setInvitationsLeft(locationInfo.invitationsLeft - emails.length);
            }

            dispatch(invitationsActions.setEmails(emails));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, invitationsLeft],
    );

    const handleSetValidEmails = useCallback(
        (isValid) => {
            handleSetValid("EMAILS", isValid);
        },
        [handleSetValid],
    );

    const handlePublicUrlOnCreation = useCallback(
        (createPublicUrl) =>
            dispatch(
                invitationsActions.updateNewLocally({
                    createPublicUrl,
                }),
            ),
        [dispatch],
    );

    const invitationPublicUrlCreationToggle = useMemo(() => {
        if (!showPublicUrlCreationToggle) {
            return null;
        }
        return (
            <CreatePublicUrlToggle
                onChange={(value) => handlePublicUrlOnCreation(value)}
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitation, showPublicUrlCreationToggle]);

    useEffect(() => {
        if (
            invitation?.periodType === INVITATION_PERIODS.ALWAYS ||
            invitation?.permissionType === LOCATION_PERMISSIONS.INSTALLER
        ) {
            setShowPublicUrlCreationToggle(false);
            handlePublicUrlOnCreation(false);
        } else {
            setShowPublicUrlCreationToggle(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        invitation?.periodType,
        invitation?.permissionType,
        invitation?.newInvitation,
    ]);

    const content = (
        <div
            style={{
                display: "grid",
                gap: 24,
                gridTemplateColumns: "repeat(2, 400px)",
            }}
            className="m-auto mt-2 w-100 px-2"
        >
            <div>
                <div>
                    <ValidatedEmailsInput
                        setIsValid={handleSetValidEmails}
                        forceWarnings={forceWarnings}
                        emails={invitation.emails}
                        handleChangeEmailsUnwired={handleChangeEmails}
                        disableInput={
                            !!invitation.emails?.length && invitationsLeft === 0
                        }
                    />
                    {invitationsLeft !== null ? (
                        <CaptionText className="mt-1 mr-1 text-right">
                            {t("mod_invitations_remaining")}: {invitationsLeft}
                        </CaptionText>
                    ) : (
                        <br />
                    )}
                </div>
                <br />
                {invitationPublicUrlCreationToggle}
            </div>
            <InvitationTimeSchedule
                invitation={invitation}
                timezone={timezone}
            />
        </div>
    );

    return content;
}