import React from "react";

export function SimpleButton({ onClick, text, buttonStyle, isVisible = true }) {
    
    if (!isVisible)
        return null;

    return (
        <div
            onClick={onClick}
            className={`
                d-flex
                align-items-center

                px-3
                text-center

                border
                rounded
                c-cursor-default

                ${buttonStyle}
            `}
        >
            {text}
        </div>
    );
}