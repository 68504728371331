import { i18n } from "../_translations/i18n";

export const INVITATION_GROUP_ACTION_TYPES = {
    GET_INVITATION_GROUPS_SUCCESS: "GET_INVITATION_GROUPS_SUCCESS",
    SELECT_INVITATION_GROUP: "SELECT_INVITATION_GROUP",
    UPDATE_SELECTED: "INVITATION_GROUP_UPDATE_SELECTED",
    PUSH_GROUP_TO_SELECTED: "PUSH_GROUP_TO_SELECTED",
};


export const INVITATION_GROUP_SECONDARY_VIEWS = {
    INVITATION_GROUP_CONFIG:    "INVITATION_GROUP_CONFIG",
    NEW_INVITATION_GROUP:       "NEW_INVITATION_GROUP"
}

export const INVITATION_GROUP_TYPES = {
    UNLIMITED:  1,
    LIMITED:    2,
};

export const INVITATION_GROUP_TYPES_TRANSLATED = {
    [INVITATION_GROUP_TYPES.LIMITED]: i18n.t("mod_invitations_group_type_limited"),
    [INVITATION_GROUP_TYPES.UNLIMITED]: i18n.t("mod_invitations_group_type_unlimited"),
}