import { DeviceHelper } from "./DeviceHelper";
import { PROTECT_STATES } from "../_constants";
import { Device } from "../_models/Device";

export class ProtectHelper {
    static setIncorrectDevices(devices = [Device()]) {
        const isThereBrain = DeviceHelper.isThereBrain(devices);
        const incorrectDevices = [];
    
        devices
        .forEach(device => {
            const protectState =
                DeviceHelper.getDeviceProtectState(
                    device,
                    isThereBrain,
                )
            ;
    
            if (protectState !== PROTECT_STATES.OK) {
                incorrectDevices.push(
                    { ...device,
                        protectState,
                    }
                )
            }
        })

        return incorrectDevices;
    }
}

