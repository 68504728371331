import { Integration, Reservation, ServerIntegration, ServerIntegrationSend, ServerReservation } from "../_modelsTS/Integration";

export class IntegrationMapper {
    static allServerToLocal(serverIntegrations: ServerIntegration[]) {
        return serverIntegrations.map(this.serverToLocal);
    }

    static serverToLocal({
        company, 
        integration_type, 
        invitation_group, 
        link, 
        create_public_url, 
        uid, 
        created_on,
        modified_on,
        host_id,
        apikey,
        hotel_id,
        host_name,
        room_id,
        room_name,
        check_in,
        check_out,
        username,
        send_invitation_url,
        send_invitation_url_from_pms,
        enable_protect_guest,
        days_for_invitation_create,
        }: ServerIntegration): Integration {
        return { 
            company, 
            uid,
            link, 
            type: integration_type, 
            groups: invitation_group, 
            createPublicUrl: create_public_url, 
            createdOn: created_on, 
            modifiedOn: modified_on,
            host_id,
            apikey,
            hotel_id,
            host_name,
            room_id,
            room_name,
            check_in,
            check_out,
            username,
            sendInvitationURL: send_invitation_url,
            sendInvitationURLFromPMS: send_invitation_url_from_pms,
            enableProtectGuest: enable_protect_guest,
            daysForInvitationCreate: days_for_invitation_create
        };
    }

    static localToServer({ 
        company, 
        link, 
        type, 
        groups, 
        createPublicUrl, 
        uid,
        host_id,
        apikey,
        handler_url,
        hotel_id,
        host_name,
        room_id,
        room_name,
        check_in,
        check_out,
        username,
        sendInvitationURL,
        sendInvitationURLFromPMS,
        enableProtectGuest,
        daysForInvitationCreate,
    }: Integration): ServerIntegrationSend {
        return {
            company,
            link,
            integration_type: type,
            ...(!!groups && {invitation_group: groups.map(({ id }) => id)}),
            create_public_url: createPublicUrl,
            ...(!!uid && { uid }),
            host_id,
            apikey,
            handler_url,
            hotel_id,
            host_name,
            room_id,
            room_name,
            check_in,
            check_out,
            username,
            send_invitation_url: sendInvitationURL,
            send_invitation_url_from_pms: sendInvitationURLFromPMS,
            enable_protect_guest: enableProtectGuest,
            days_for_invitation_create: daysForInvitationCreate
        }
    }
}
export class IntegrationReservationMapper {
    static allServerToLocal(serverIntegrations: ServerReservation[]) {
        return serverIntegrations.map(this.serverToLocal);
    }

    static serverToLocal({ status, reservation_end, reservation_id, reservation_start, email, uid, created_on, modified_on, id, integration }: ServerReservation): Reservation {
        return { id, integration, status, uid, email, reservationEnd: reservation_end, reservationStart: reservation_start, reservationId: reservation_id, createdOn: created_on, modifiedOn: modified_on };
    }

}

