import { ServiceHelper } from "../../Everou/_services/ServiceHelper";
import { PMS_API_URLS } from "../_constants/PMS_API_URLS";

export const PMSAvaibookService = {
    listAccommodations,
}

function listAccommodations(uid: string) {
    return ServiceHelper.getRequest(
        PMS_API_URLS.AVAIBOOK_ACCOMMODATIONS,
        {
            uid: uid
        }
    );
}