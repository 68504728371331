import { ALERTS_ACTION_TYPES } from "../_constants";

export function alerts(state = [], action) {
    switch (action.type) {
        case ALERTS_ACTION_TYPES.ALERTS_UPDATE:
            return action.alerts;

        default:
            return state;
    }
}