import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    globalActions,
    devicesActions,
    locationInfoActions,
    locationActions,
    invitationsActions,
} from "../_actions";
import { EverouModulesActions } from "../_stores/ModulesStore/EverouModulesActions";
import { CustomizationStore } from "../_stores/CustomizationStore/CustomizationStore";
import { WiredContentWithSectionsSidebar } from "./WiredContentWithSectionsSidebar";
import { everouSidebarSections } from "./everouSidebarSections";
import { EverouModules } from "../_modules/EverouModules";
import { LocationHelper } from "../_helpers/LocationHelper";
import { Selectors } from "../_reducers/app.reducer";
import { MyInvitationsStore } from "../_stores/MyInvitationsStore/MyInvitationsStore";
import { CustomizationActions } from "../_stores/CustomizationStore/CustomizationActions";
import { LocationInfo } from "../_models/LocationInfo";
import { NoAccessToLocation } from "../LocationsModule/NoAccessToLocation";
import { StoreManager } from '../StoreManager';
import { appPermissions } from "../_constants/permissions.constants";

const fn = () => {};

const testIDs = {
    root: "location-content",
};

AppContent.testIDs = testIDs;

export function AppContent({
    validatedLocationUid,
    setIsLoadingLocation = fn,
}) {

    // LIBRARIES
    const dispatch = useDispatch();
    
    // GLOBAL
    const locations = useSelector(Selectors.getLocations);

    const hasAccessToLocation = LocationHelper.hasAccessToLocation(locations, validatedLocationUid)

    //INITIALIZATION
    useEffect(() => {
        if (!validatedLocationUid)
            return;
        
        initialization();

        async function initialization() {

            dispatch(globalActions.resetGlobalState());
            
            if(hasAccessToLocation) {

                const locationInfo = await dispatch(locationInfoActions.getLocationInfo(validatedLocationUid));

                if(appPermissions.canMakePayments(locationInfo.locationPermission)) {   
                    await dispatch(locationActions.getProductsInfo(validatedLocationUid));
                }

                const devices = await dispatch(devicesActions.getDevicesFromLocation(validatedLocationUid));
                
                /*devices?.forEach(device => {
                    if (device?.updating) {
                        dispatch(devicesActions.refreshWhileNot('updating',device?.uid))
                    }
                })*/
                
                await dispatch(CustomizationStore.actions.getLocationInfo(validatedLocationUid));
                dispatch(EverouModulesActions.validateCurrentModuleInNewLocation());
                dispatch(locationActions.selectLocation(validatedLocationUid));
                dispatch(EverouModulesActions.buildModules());
                
            } else {

                const location = Selectors.getLocation(
                    StoreManager.getStore().getState(),
                    validatedLocationUid,
                );
                dispatch(locationInfoActions.updateLocationInfo(LocationInfo({ locationName: location?.name , timezone: location.timezone })));
                dispatch(CustomizationActions._setLocationCustomization(null));
                dispatch(locationActions.selectLocation(validatedLocationUid));
                dispatch(EverouModulesActions.unselectAllModules());

                await dispatch(invitationsActions.getMyInvitations()).then(
                    (myInvitations) => {
                        const currentMyInvitation = myInvitations?.find(
                            ({ locationUid }) =>
                            locationUid === validatedLocationUid,
                            );
                            if(currentMyInvitation){
                                dispatch(
                                    MyInvitationsStore.actionSelectItem(
                                        currentMyInvitation?.uid,
                                        ),
                                        );
                                    }
                                },
                    );
            }
            setIsLoadingLocation(false);

        }
    }, [dispatch, validatedLocationUid, hasAccessToLocation, setIsLoadingLocation]);

    return (hasAccessToLocation ? 
        <WiredContentWithSectionsSidebar
            testID={testIDs.root}
            sections={everouSidebarSections}
            getComponentForModuleId={EverouModules.getComponentForModuleId}
            getModuleById={EverouModules.getModuleById}
            selectModuleAction={EverouModulesActions.selectModule}
            hasAccessToLocation={hasAccessToLocation}
        /> : 
        <NoAccessToLocation /> 
    );
}