import { genericActions } from "./generic.actions";
import { LocationBillingDataStore } from "../_stores/LocationBillingDataStore";
import { LocationBillingDataService } from "../_services/LocationBillingDataService";
import { LocationBillingDataMapper } from "../_mappersTS/LocationBillingDataMapper";
import { uiAlertActions } from '../_actions';
import { i18n } from '../_translations/i18n';

export const LocationBillingDataActions = {
    //REMOTE
    getBillingData,
    deleteBillingData,
    updateBillingData,
};

function getBillingData(locationUid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(LocationBillingDataStore.actionListItemsRequest);

            const serverLocationBillingData = await LocationBillingDataService.getBillingData(locationUid);
            if (Object.values(serverLocationBillingData).some(value => !!value)) {
                dispatch(
                    LocationBillingDataStore.actionListItemsSuccess([
                        LocationBillingDataMapper.serverToLocal(
                            serverLocationBillingData,
                        ),
                    ]),
                );
            }
        }
    };
}

function updateBillingData(locationUid, name, taxNumber, streetAddress, postalCode, city, state, country, phoneNumber) {

    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const serverLocationBillingData = await LocationBillingDataService.updateBillingData(locationUid, name, taxNumber, streetAddress, postalCode, city, state, country, phoneNumber);

            dispatch(uiAlertActions.success(i18n.t("mod_billing_location_billing_data_saved")));

            dispatch(LocationBillingDataStore.actionListItemsSuccess(
                [LocationBillingDataMapper.serverToLocal(serverLocationBillingData)]
            ));
        };
    };
}

function deleteBillingData(locationUid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await LocationBillingDataService.deleteBillingData(locationUid);
            dispatch(LocationBillingDataStore.actionDeleteAllItemsSuccess());
        };
    };
}
