import { genericActions } from "../../_actions/generic.actions";
import { clockinService } from "../../_services/clockin.service";
import { ClockinStore } from "./ClockinStore";
import { Selectors } from "../../_reducers/app.reducer";
import { WorkdayMapper } from "../../_mappersTS/WorkdayMapper";
import { ClockinDateFilterStore } from "./ClockinDateFilterStore";
import { ClockinUserUidStore } from "./ClockinUserUidStore";
import { globalActions, uiAlertActions } from "../../_actions";
import { GLOBAL_POPUPS } from "../../_constants";
import { ClockinUserMapper } from "../../_mappers/ClockinUserMapper";
import { ClockinUsersStore } from "./ClockinUsersStore";
import { ClockinUser } from "../../_models/ClockinUser";
import { i18n } from "../../_translations/i18n";
import { DownloadHelper } from "../../../Budgets/_helpers";
import { ClockinsSecondaryViewStore } from "../ClockinsSecondaryViewStore";
import { appPermissions } from '../../_constants/permissions.constants';
import { ClockinFiltersStore } from "./ClockinFiltersStore";

export const ClockinActions = {
    //PUBLIC REMOTE
    createClockInForCurrentUser,
    refreshClockins,
    listClockins,
    listClockinUsers,
    createClockIn,
    createRemoteClockin,
    toggleFlexibleClockins,

    toggleRemoteAccess,
    setUserAllowsRemoteClockins,

    updateClockIn,
    deleteClockIn,

    openPdfReport,
    downloadPdfReport,
    downloadCSVReport,
    downloadExcelReport,

    //PUBLIC LOCAL
    selectUserUid: ClockinUserUidStore.actions.setUserUid,
    setDateFilter: ClockinDateFilterStore.actionSetBeginEnd,
    showDeletePopUp,
    changeSecondaryView: ClockinsSecondaryViewStore.actionSet,
    changeFilter: ClockinFiltersStore.actionSet,

    //LOCAL PRIVATE
    _updateUserSuccess: ClockinStore.actionUpdateItemSuccess,
    _listClockinsSuccess: ClockinStore.actionListItemsSuccess,
    _listClockinUsersSuccess: ClockinUsersStore.actionListItemsSuccess,
};

function openPdfReport(locationUid, userUid, begin, end, allUsersReport) {
    return getReportPdf(locationUid, userUid, begin, end, allUsersReport, false);
}

function downloadPdfReport(locationUid, userUid, begin, end, allUsersReport) {
    return getReportPdf(locationUid, userUid, begin, end, allUsersReport, true);
}

function getReportPdf(locationUid, userUid, begin, end, allUsersReport, isDownload) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const pdfBlob = (allUsersReport)
            ? await clockinService.getPdfBlobSummaryReport(locationUid, begin, end)
            : await clockinService.getPdfBlobUserReport(locationUid, userUid, begin, end);
            DownloadHelper.openOrDownloadBlob(pdfBlob, isDownload, i18n.t("global_report"));
        }
    };
}

function downloadCSVReport(locationUid, begin, end) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const isDownload = true;
            const csvBlob = await clockinService.getCSVBlobSummaryReport(locationUid, begin, end);
            DownloadHelper.openOrDownloadBlob(csvBlob, isDownload, i18n.t("global_export"));
        }
    };
}

function downloadExcelReport(locationUid, begin, end) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const isDownload = true;
            const excelBlob = await clockinService.getExcelBlobSummaryReport(locationUid, begin, end);
            DownloadHelper.openOrDownloadBlob(excelBlob, isDownload, i18n.t("global_export"));
        }
    };
}

function createRemoteClockin(locationUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await clockinService.createClockIn(locationUid);
            await dispatch(wiredListClockins());
            dispatch(uiAlertActions.success(
                i18n.t("mod_clockins_remote_clockin_successful"))
            );
        }
    };
}

function wiredListClockins() {
    return (dispatch, getState) => {
        const { begin, end } = Selectors.getClockinsDateFilter(getState());
        return dispatch(ClockinActions.listClockins(
            Selectors.getSelectedLocationUid(getState()),
            begin,
            end,
            Selectors.getClockinsSelectedUserUid(getState()),
        ));
    };
}

function toggleRemoteAccess(clockinUser = ClockinUser(), locationUid) {
    return setUserAllowsRemoteClockins(
        locationUid,
        clockinUser.uid,
        !clockinUser.settings.allowsRemoteClockins,
        clockinUser.settings.hasFlexibleClockins,
    )
}

function toggleFlexibleClockins(clockinUser = ClockinUser(), locationUid) {
    return setUserAllowsRemoteClockins(
        locationUid,
        clockinUser.uid,
        clockinUser.settings.allowsRemoteClockins,
        !clockinUser.settings.hasFlexibleClockins,
    )
}

function setUserAllowsRemoteClockins(locationUid, userUid, allowsRemoteClockins, hasFlexibleClockins) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            await clockinService.updateUserSettings(
                locationUid,
                userUid,
                allowsRemoteClockins,
                hasFlexibleClockins,
            );

            // Updating storage locally directly is not working. 
            // Previous state is equal to new state
            // const localSettings = ClockinUserSettingsMapper.serverToLocal(serverSettings);
            // dispatch(ClockinActions._updateUserSuccess({
            //     uid: userUid,
            //     settings: localSettings,
            // }));

            // Solución: Volvemos a listar los users del server 
            // para forzar la actualización de la vista correctamente
            const { begin, end }    = Selectors.getClockinsDateFilter(getState());
            const permission        = Selectors.getPermissionSelectedLocation(getState());
            const canManageClockins = appPermissions.canManageClockins(permission);
            dispatch(ClockinActions.listClockinUsers(locationUid, begin, end, canManageClockins));
        }
    }
}

function showDeletePopUp(uid) {
    return dispatch => {
        dispatch(globalActions.showPopUp(GLOBAL_POPUPS.DELETE_CLOCKIN, { uid }));
    };
}

//LIST
function listClockinUsers(locationUid, begin, end, canManageClockins) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            if (!canManageClockins || !begin || !end)
                return;
            
            const serverClockinUsers = await clockinService.listClockinUsers(
                locationUid,
                begin,
                end,
            );
            
            dispatch(ClockinActions._listClockinUsersSuccess(
                ClockinUserMapper.serverToLocal(serverClockinUsers).sort((userA, userB) => userA.user.name.localeCompare(userB.user.name))
            ));
        }
    }
}

function refreshClockins() {
    return async (dispatch, getState) => {
        const { begin, end } = Selectors.getClockinsDateFilter(getState());
        const userUid = Selectors.getClockinsSelectedUserUid(getState());
        const locationUid = Selectors.getSelectedLocationUid(getState());
        await dispatch(ClockinActions.listClockins(
            locationUid,
            begin,
            end,
            userUid,
        ));
    };
}

function listClockins(locationUid, begin, end, userUid) {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            if (!begin || !end)
                return;

            const serverWorkdays = await clockinService.listClockIns(
                locationUid,
                begin,
                end,
                userUid,
            );
            dispatch(ClockinActions._listClockinsSuccess(
                WorkdayMapper.allServerToLocal(serverWorkdays)
            ));
        }
    };
}

//EDIT
function createClockInForCurrentUser(date) {
    return async (dispatch, getState) => {
        const selectedUserUid = Selectors.getClockinsSelectedUserUid(getState());
        await dispatch(ClockinActions.createClockIn(selectedUserUid, date));
    };
}

function createClockIn(userUid, date) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            const locationUid = Selectors.getSelectedLocationUid(getState());

            await clockinService.createClockIn(locationUid, userUid, date);
            await dispatch(ClockinActions.refreshClockins());
            
            const { begin, end } = Selectors.getClockinsDateFilter(getState());
            const permission = Selectors.getPermissionSelectedLocation(getState());
            const canManageClockins = appPermissions.canManageClockins(permission);
            await dispatch(ClockinActions.listClockinUsers(locationUid, begin, end, canManageClockins));
        }
    };
}

function updateClockIn(uid, date, notes) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await clockinService.updateClockIn(uid, date, notes);
            await dispatch(ClockinActions.refreshClockins());

            const locationUid = Selectors.getSelectedLocationUid(getState());
            const { begin, end } = Selectors.getClockinsDateFilter(getState());
            const permission = Selectors.getPermissionSelectedLocation(getState());
            const canManageClockins = appPermissions.canManageClockins(permission);
            await dispatch(ClockinActions.listClockinUsers(locationUid, begin, end, canManageClockins));
        }
    };
}

function deleteClockIn(uid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await clockinService.deleteClockIn(uid);
            await dispatch(ClockinActions.refreshClockins());

            const locationUid = Selectors.getSelectedLocationUid(getState());
            const { begin, end } = Selectors.getClockinsDateFilter(getState());
            const permission = Selectors.getPermissionSelectedLocation(getState());
            const canManageClockins = appPermissions.canManageClockins(permission);
            await dispatch(ClockinActions.listClockinUsers(locationUid, begin, end, canManageClockins));
        }
    };
}