import {
    APP_LOG_TYPES,
    NO_NEXT_PAGE,
} from '../_constants';

import { genericActions } from './generic.actions';
import { auditLogsActions } from './auditLogs.actions';
import { protectActions } from './protect.actions';
import { userLogsActions } from './userLogs.actions';
import { Selectors } from '../_reducers/app.reducer';
import { deviceLogsActions } from './deviceLogs.actions';

export const logsActions = {
    getFirstPage: logType => getLogsPage(0, logType),
    getNextPage,
};

function getNextPage(nextPage = NO_NEXT_PAGE, isRequesting = false, logType) {
    return async dispatch => {
        if (isRequesting || nextPage === NO_NEXT_PAGE)
            return;

        await dispatch(getLogsPage(nextPage, logType));
    };
}

function getLogsPage(page = 0, logType) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {

            if (Selectors.getSelectedLocationUid(getState()) === null)
                return;

            switch (logType) {
                case APP_LOG_TYPES.AUDIT:
                    await dispatch(auditLogsActions.getAuditLogsPage(page));
                    return;

                case APP_LOG_TYPES.PROTECT:
                    await dispatch(protectActions.getProtectLogsPage(page));
                    return;

                case APP_LOG_TYPES.USER:
                    await dispatch(userLogsActions.getUserLogsPage(page));
                    return;

                case APP_LOG_TYPES.DEVICE:
                    await dispatch(deviceLogsActions.getDeviceLogsFromServer(page));
                    return;
                
                default:
                    return;
            }
        }
    };
}