import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { WeekdaysModel } from "../_models/WeekdaysModel";
import { weekdayUiValues, WEEKDAYS_STRING_TO_NUMBER } from "../_constants";
import { useError } from "../_hooks/useError";

const testIDs = {
    makeDayOfWeekId: (weekDay) => "week-day-select-" + weekDay,
};

DaysOfWeekSelector.testIDs = testIDs;

const fn = () => {};

export function DaysOfWeekSelector({
    weekdays = WeekdaysModel(),
    handleToggleWeekDay,
    setIsValid = fn,
} = {}) {

    const [beenSelected, setBeenSelected] = useState(false);
    const { errorMessage, setError, disableError } = useError(null);
    //LIBRARIES
    const { t } = useTranslation();

    useEffect(() => {
        const allDaysDisabled = !Object.values(weekdays).some(Boolean);
        if(beenSelected && allDaysDisabled) {
            setError(t('global_warning_select_one'));
        } else {
            disableError()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [beenSelected, weekdays])
    //CLEAR VALIDATION ON CLEAN UP
    useEffect(() => {
        return () => setIsValid(true);
    }, [setIsValid]);

    //VIEW
    return (
        <>
            <div className="d-flex mb-2 align-items-end">
                <h3 className="text-capitalize mb-0 mr-2">
                    {t("common.allowedDays")}
                </h3>
                <small style={{ lineHeight: 1.25 }} className="text-danger">
                    {beenSelected && errorMessage}
                </small>
            </div>
            <div
                style={{ marginLeft: "-4px" }}
                className="d-flex align-items-center flex-wrap"
            >
                {weekDayRows()}
            </div>
        </>
    );

    function weekDayRows() {
        return Object.entries(weekdays).map(([weekDay, isEnabled], index) => {
            return <div
                key={weekDay}
                data-testid={testIDs.makeDayOfWeekId(WEEKDAYS_STRING_TO_NUMBER[weekDay])}
                style={{ marginRight: 2}}
                className={`
                            p-1
                            c-hover-240
                            c-rounded
                            cp
                            ${isEnabled ? "table-active" : null}
                        `}
                onClick={() => {
                    handleToggleWeekDay(weekDay);
                    if (!beenSelected) {
                        setBeenSelected(true);
                    }
                }}
            >
                {weekdayUiValues[WEEKDAYS_STRING_TO_NUMBER[weekDay]]}
            </div>
        });
    }
}
