import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { BaseAppGlobalPopUp } from "../GlobalPopUps/BaseAppGlobalPopUp";
import { invitationGroupActions } from "../_actions/invitationGroup.actions";

export function RemoveGroupPopUp({ uid }) {

    //LIBRARIES
    const dispatch = useDispatch();
    const { t } = useTranslation();

    //ACTION HANDLERS
    const handleRemoveInvitationGroup = useCallback(() => {
        dispatch(invitationGroupActions.removeInvitationGroup(uid));
    }, [dispatch, uid]);

    //VIEW
    return (
        <BaseAppGlobalPopUp
            title={t("remove_group_pop_up_title")}
            body={body()}
            onCancel={handleRemoveInvitationGroup}
            cancelButtonName={t("webapp_global_remove")}
            acceptButtonName={t("global_cancel")}
            limitWidth
        />
    );

    function body() {
        return (
            <div>
                {t("remove_group_pop_up_body")}
            </div>
        );
    }
}