import React from "react";

export function AppWrapper({ children }) {
    return (
        <div className="
            vh-100
            
            d-flex
            flex-column
            overflow-hidden
        ">
            {children}
        </div>
    );
}