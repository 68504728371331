import { Integration, KrossBookingProperty, LocalEditableIntegration } from "../../_modelsTS/Integration";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatePublicUrlToggle from "../../InvitationsModule/CreatePublicUrlToggle";
import { CapsuleButton, Selector } from "../../_components";
import { useDispatch, useSelector } from "react-redux";
import { integrationsActions } from "../../_actions/integrations.actions";
import { Selectors } from "../../_reducers/app.reducer";
import { INVITATION_TYPES, MIN_TIME, TIME_FORMAT } from "../../_constants";
import { useError } from "../../_hooks/useError";
import { Icons } from "../../_assets";
import { IntegrationStore } from "../../_stores/IntegrationStore";
import { KrossBookingStore } from "../../_stores/KrossBookingStore";
import { krossBookingActions } from "../../_actions/krossBooking.actions";
import TimePicker from "rc-time-picker";
import { TimeHelper } from "../../_helpers";
import moment from "moment";
import SendInvitationURLToggle from "./SendInvitationURLToggle";

type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onChangeInputs?: (...args: any) => void | any;
    onToggleEditable: (...args: any) => void | any;
    onSetInitialStatus: (...args: any) => void | any;
    groups: { [key: number]: any[]; }; // To be typed asap
    onDelete: (index: number) => void;
    index: number;
    linkMode?: 'link' | 'ical';
}

export function KrossBookingIntegrationInput({ 
    integration, 
    onDelete, 
    index, 
    onSetInitialStatus, 
    groups, 
    linkMode = 'link', 
    onToggleEditable,
}: IntegrationInputProps) {

    // LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    // GLOBAL STATE
    const buildOptions = useCallback((availableGroups: any[] = []) => {
            return availableGroups.map((group) => ({ value: group.id, label: group.name, isDisabled: group.isDisabled })) ?? [];
    }, []);

    const buildPropertyOptions = useCallback((availableProperties: any[] = []) => {
        return availableProperties.map((property) => ({ value: property.id_property, label: property.name, isDisabled: false })) ?? [];
    }, []);

    const buildRoomOptions = useCallback((availableRooms: any[] = []) => {
        return availableRooms.map((room) => ({ value: room.id_room, label: room.number, isDisabled: false })) ?? [];
    }, []);

    const selectedIntegrationUid: string = useSelector(IntegrationStore.selectorGetSelectedId);

    const buildGroupGroupedOptions = useCallback((groups: { [key: number]: any[] }) => {
        return [
            {
                label: t("global_typeof_location"),
                options: buildOptions(groups[INVITATION_TYPES.LOCATION]),
            },
            {
                label: t("global_typeof_room"),
                options: buildOptions(groups[INVITATION_TYPES.ROOM]),
            },
        ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allGroups = useMemo(() => [...(groups[INVITATION_TYPES.LOCATION] ?? []), ...(groups[INVITATION_TYPES.ROOM]) ?? []], [groups]);
    const baseIntegration = useMemo(() => integration, [integration]);
    const [apikeyValue, setApikeyValue] = useState(integration.apikey ?? '');
    const [hotelIDValue, setHotelIDValue] = useState(integration.hotel_id ?? '');
    const [usernameValue, setUsernameValue] = useState(integration.username ?? '');
    const [availableGroups, setAvailableGroups] = useState(buildGroupGroupedOptions(groups));
    const integrationGroups = integration.groups || [];
    const [selectedGroupsLocal, setSelectedGroupsLocal] = useState(integrationGroups.map(({id}) => ({value: id})) ?? []);
    const [createPublicUrl, setCreatePublicUrl] = useState(integration.createPublicUrl);
    const [sendInvitationURL, setSendInvitationURL] = useState(integration.sendInvitationURL ?? true);
    const { errorMessage, setError, hasError, disableError } = useError();
    const locationUid = useSelector(Selectors.getSelectedLocationUid) ?? '';

    const onSeeReservations = useCallback(async (integrationUid) => {
        await dispatch(integrationsActions.listReservations(locationUid, integrationUid));
        dispatch(IntegrationStore.actionSelectItem(integrationUid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUid]);


    const onChangeGroups = useCallback(selectedGroups => {
        if(selectedGroups.length === 0) {
            setAvailableGroups(buildGroupGroupedOptions(groups));
        } else {
            const firstSelectedGroup = selectedGroups[0];
            const accessType = allGroups.find(({id}) => firstSelectedGroup.value === id)?.invitation.type;
            const isLocationType = accessType === INVITATION_TYPES.LOCATION;
            const availableGroups = { [INVITATION_TYPES.LOCATION]: groups[INVITATION_TYPES.LOCATION].map(group => ({ ...group, isDisabled: firstSelectedGroup.id !== group.id })),
                [INVITATION_TYPES.ROOM]: groups[INVITATION_TYPES.ROOM].map(group => ({...group, isDisabled: isLocationType}))
            }
            setAvailableGroups(buildGroupGroupedOptions(availableGroups));
        }
        setSelectedGroupsLocal(selectedGroups);
    }, [buildGroupGroupedOptions, groups, allGroups]);

    const properties = useSelector(KrossBookingStore.selectorGetItems);
    const selectedProperty : KrossBookingProperty = useSelector(KrossBookingStore.selectorGetSelectedItem) ?? {id:integration.host_id, name:integration.host_name};
    const [selectedPropertiesLocal, setSelectedPropertiesLocal] = useState({ value:integration.host_id, label:integration.host_name, isDisabled: false } ?? undefined);
    const [availableProperties, setAvailableProperties] = useState(buildPropertyOptions(properties));

    const onChangeProperty = useCallback(selectedProperty => {
        setSelectedPropertiesLocal(selectedProperty);
        dispatch(KrossBookingStore.actionSelectItem(selectedProperty.value));
        if (selectedProperty !== undefined) {
            dispatch(krossBookingActions.listRooms(integration.uid, selectedProperty.value));
        }
    }, [dispatch, integration.uid]);

    const [selectedRoomLocal, setSelectedRoomLocal] = useState({ value:integration.room_id, label:integration.room_name, isDisabled: false } ?? undefined);
    const [availableRooms, setAvailableRooms] = useState(buildRoomOptions(selectedProperty?.rooms));

    const onChangeRoom = useCallback(selectedRoom => {
        setSelectedRoomLocal(selectedRoom);
    }, []);

    const [checkIn, setCheckIn] = useState(integration.check_in ?? MIN_TIME);
    const [checkOut, setCheckOut] = useState(integration.check_out ?? MIN_TIME);
    const timeNoSecondsFormat = TimeHelper.getTimeFormats().TIME_NO_SECONDS;

    const getHourAndMinutesFormat = useCallback((time: string) => moment(time, timeNoSecondsFormat), []);

    // INITIALIZATION
    useEffect(() => {
        if (!!integration.uid) {
            dispatch(krossBookingActions.listProperties(integration.uid));
        }

    }, [dispatch, integration.uid]);

    useEffect(() => {
        setAvailableProperties(buildPropertyOptions(properties));
    }, [properties, buildPropertyOptions]);

    useEffect(() => {
        if (!!selectedProperty && !!selectedProperty.rooms) {
            setAvailableRooms(buildRoomOptions(selectedProperty.rooms));
        }
    }, [buildRoomOptions, selectedProperty]);

    useEffect(() => { 
        setApikeyValue(integration.apikey ?? '');
        setHotelIDValue(integration.hotel_id ?? '');
        setUsernameValue(integration.username ?? '');
        setCreatePublicUrl(integration.createPublicUrl);
        setSendInvitationURL(integration.sendInvitationURL ?? true);

        const selected = buildOptions(allGroups.filter(({ id }) => integrationGroups.map(({id}) => id).includes(id)));
        onChangeGroups(selected);
    }, [allGroups, buildOptions, groups, integration, onChangeGroups]);

    const canSave = useMemo(() => {
        if(hasError) return false;
        return (!!selectedProperty || !!selectedRoomLocal || !!sendInvitationURL || !!createPublicUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProperty, selectedRoomLocal, sendInvitationURL, createPublicUrl]);

    const canValidate = useMemo(() => {
        if(hasError) return false;
        return !!apikeyValue && !!hotelIDValue && !!usernameValue &&
            selectedGroupsLocal.length !== 0;
    }, [apikeyValue, hotelIDValue, usernameValue, hasError, selectedGroupsLocal]);

    const enableSendInvitationURLToggle = useMemo(() => {
        return createPublicUrl;
    }, [createPublicUrl]);
    
    const onSave = useCallback(async () => {
        const editedIntegration: Integration = {
            host_id: selectedPropertiesLocal.value,
            host_name: selectedPropertiesLocal.label,
            room_id: selectedRoomLocal.value,
            room_name: selectedRoomLocal.label,
            check_in: checkIn,
            check_out: checkOut,
            company: integration.company,
            createPublicUrl,
            sendInvitationURL,
            groups: selectedGroupsLocal.map(({ value }) => ({ id: value })),
            type: integration.type,
            uid: integration.uid
        }

        if(locationUid) {
            try {
                await dispatch(integrationsActions.createIntegration(locationUid, editedIntegration));
            } catch (err: any) {
                setError(err.message)
            }
        }
    }, [createPublicUrl, dispatch, setError, integration.company, integration.type, integration.uid, 
        locationUid, selectedGroupsLocal, t, selectedProperty?.id_property, selectedProperty?.name, selectedRoomLocal, selectedProperty?.rooms, sendInvitationURL]);

    const onValidate = useCallback(async () => {

        if(!apikeyValue) {
            setError(t('mod_integrations_link_required'));
            return;
        }
     
        const editedIntegration: Integration = {
            apikey: apikeyValue,
            hotel_id: hotelIDValue,
            username: usernameValue,
            company: integration.company,
            type: integration.type,
            uid: integration.uid,
            groups: selectedGroupsLocal.map(({ value }) => ({ id: value })),
            createPublicUrl,
            sendInvitationURL
        }
        if(locationUid) {
            try {
                await dispatch(integrationsActions.createIntegration(locationUid, editedIntegration));
            } catch (err: any) {
                setError(err.message)
            }
        }
    }, [dispatch, setError, integration.company, integration.type, integration.uid, apikeyValue, hotelIDValue, usernameValue, locationUid, selectedGroupsLocal, t, createPublicUrl, sendInvitationURL]);

    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            <div
                className="col-span-full"
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "3fr 2fr",
                }}
            >
                <div>API Key</div>
                <input
                    className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                    type="text"
                    readOnly={!integration.editable}
                    value={apikeyValue}
                    placeholder={"API Key"}
                    onChange={(e) => {
                        setApikeyValue(e.target.value?.trim());
                        /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                            setError(t('mod_integrations_already_have_integration_with_this_link'));
                        } else {
                            disableError();
                        }*/
                    }}
                />
            </div>
            <div
                className="col-span-full"
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "3fr 2fr",
                }}
            >
                <div>Hotel ID</div>
                <input
                    className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                    type="text"
                    readOnly={!integration.editable}
                    value={hotelIDValue}
                    placeholder={"Hotel ID"}
                    onChange={(e) => {
                        setHotelIDValue(e.target.value?.trim());
                        /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                            setError(t('mod_integrations_already_have_integration_with_this_link'));
                        } else {
                            disableError();
                        }*/
                    }}
                />
            </div>
            <div
                className="col-span-full"
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "3fr 2fr",
                }}
            >
                <div>User</div>
                <input
                    className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                    type="text"
                    readOnly={!integration.editable}
                    value={usernameValue}
                    placeholder={"User"}
                    onChange={(e) => {
                        setUsernameValue(e.target.value?.trim());
                        /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                            setError(t('mod_integrations_already_have_integration_with_this_link'));
                        } else {
                            disableError();
                        }*/
                    }}
                />
            </div>
            <div
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                    className="col-span-full d-flex justify-content-between"
                >
                <div className="c-min-w-40">
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_invitations_select_group")}
                        isMulti
                        value={selectedGroupsLocal}
                        onChange={(selectedGroups: any) =>
                            onChangeGroups(selectedGroups)
                        }
                        isDisabled={!integration.editable}
                        options={availableGroups}
                    />
                </div>
                <div>
                    <CreatePublicUrlToggle
                        boldedName={false}
                        initialState={createPublicUrl}
                        readOnly={!integration.editable}
                        onChange={setCreatePublicUrl}
                    />
                    { enableSendInvitationURLToggle && 
                        <div className="mt-1">
                            <SendInvitationURLToggle
                                boldedName={false}
                                initialState={sendInvitationURL}
                                readOnly={!integration.editable}
                                onChange={setSendInvitationURL}
                            />
                        </div>
                    }
                </div>
            </div>
            { integration.room_id === undefined && 
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <CapsuleButton
                            size="SMALL"
                            isEnabled={canValidate}
                            onClick={onValidate}
                            text={t("global_validate")}
                        />
                    </div>
                </div>
            }
            { integration.uid &&
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_property")}
                        value={selectedPropertiesLocal}
                        onChange={(selectedProperty: any) =>
                            onChangeProperty(selectedProperty)
                        }
                        isDisabled={availableProperties.length === 0}
                        options={availableProperties}
                        />
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_room")}
                        value={selectedRoomLocal}
                        onChange={(selectedRoom: any) =>
                            onChangeRoom(selectedRoom)
                        }
                        isDisabled={availableRooms.length === 0}
                        options={availableRooms}
                        />
                </div>
            }
            { integration.uid && 
                <div className="d-flex align-items-center justify-content-between pr-2">
                    <div className="c-text-capitalize-first mr-2">
                        Check In
                    </div>
                    <TimePicker
                            showSecond={false}
                            minuteStep={5}
                            value={getHourAndMinutesFormat(checkIn)}
                            format={TIME_FORMAT}
                            onChange={(value) =>
                                setCheckIn(
                                    value
                                        ? value?.format(TIME_FORMAT)
                                        : MIN_TIME,
                                )
                            }
                    />
                </div>
            }
            { integration.uid && 
                <div className="d-flex align-items-center justify-content-between pr-2">
                    <div className="c-text-capitalize-first mr-2">
                        Check Out
                    </div>
                    <TimePicker
                            showSecond={false}
                            minuteStep={5}
                            value={getHourAndMinutesFormat(checkOut)}
                            format={TIME_FORMAT}
                            onChange={(value) =>
                                setCheckOut(
                                    value
                                        ? value?.format(TIME_FORMAT)
                                        : MIN_TIME,
                                )
                            }
                    />
                </div>
            }
            { integration.uid &&
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        {integration.room_id &&
                            <CapsuleButton
                                isEnabled={integration.uid !== selectedIntegrationUid}
                                onClick={() => onSeeReservations(integration.uid)}
                                classNameExtra="mr-2"
                                // eslint-disable-next-line react/style-prop-object
                                size="SMALL"
                                text={t('mod_integrations_see_reservations')}
                            />
                        }
                    </div>
                    <div className="d-flex">
                        {
                            !!integration.room_id &&
                            <CapsuleButton
                                size="SMALL"
                                classNameExtra="mr-2"
                                style={!integration.editable ? 'PRIMARY' : 'INFO'}
                                onClick={() => onToggleEditable(index)}
                                text={t(!integration.editable ?  "global_edit" : 'global_cancel')}
                            />
                        }
                        {(integration.editable || !integration.uid) &&
                            <CapsuleButton
                                onClick={() => onDelete(index)}
                                classNameExtra="mr-2"
                                // eslint-disable-next-line react/style-prop-object
                                style="DANGER"
                                size="SMALL"
                                icon={Icons.trash}
                            />
                        }
                        <CapsuleButton
                            size="SMALL"
                            isEnabled={canSave}
                            onClick={onSave}
                            text={t("global_save")}
                        />
                    </div>
                </div>
            }
                    {errorMessage && (
                        <div className="text-danger mr-2">{errorMessage}</div>
                    )}
        </div>
    );
}