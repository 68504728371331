import { toast } from 'react-toastify';

export function useGlobalNotification() {

    const notify = (message: string, type: 'success' | 'error' | 'info' | 'warning' = 'info', onClose = () => {}) => {

        let toaster;
        
        switch(type) {
            case 'success':
                toaster = toast.success;
                break;
            case 'error':
                toaster = toast.error;
                break;
            case 'warning': 
                toaster = toast.warning;
                break;
            default:
                toaster = toast.info;
                break;
        }

        toaster(message, {
            style: {
                top: 48,
                left: 8
            },
            theme: 'colored',
            newestOnTop: true,
            autoclose: 4000,
            hideProgressBar: true,
            pauseOnHover: false,
            onClose: onClose
        });
    };
    
    return {
        notify,
    };

}