import { HostifyListing, Integration, LocalEditableIntegration } from "../../_modelsTS/Integration";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CreatePublicUrlToggle from "../../InvitationsModule/CreatePublicUrlToggle";
import { CapsuleButton, Selector } from "../../_components";
import { useDispatch, useSelector } from "react-redux";
import { integrationsActions } from "../../_actions/integrations.actions";
import { Selectors } from "../../_reducers/app.reducer";
import { INVITATION_TYPES, MIN_TIME, TIME_FORMAT } from "../../_constants";
import { useError } from "../../_hooks/useError";
import { Icons } from "../../_assets";
import { IntegrationStore } from "../../_stores/IntegrationStore";
import { HostifyStore } from "../../_stores/HostifyStore";
import SendInvitationURLToggle from "./SendInvitationURLToggle";
import { hostifyActions } from "../../_actions/hostify.actions";
import SendInvitationURLFromPMSToggle from "./SendInvitationURLFromPMSToggle";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { TimeHelper } from "../../_helpers";


type IntegrationInputProps = {
    integration: LocalEditableIntegration;
    onChangeInputs?: (...args: any) => void | any;
    onToggleEditable: (...args: any) => void | any;
    onSetInitialStatus: (...args: any) => void | any;
    groups: { [key: number]: any[]; }; // To be typed asap
    onDelete: (index: number) => void;
    index: number;
    linkMode?: 'link' | 'ical';
}

export function HostifyIntegrationInput({ integration, onDelete, index, onSetInitialStatus, groups, linkMode = 'link', onToggleEditable }: IntegrationInputProps) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const buildOptions = useCallback((availableGroups: any[] = []) => {
            return availableGroups.map((group) => ({ value: group.id, label: group.name, isDisabled: group.isDisabled })) ?? [];
    }, []);

    const integrations = useSelector(IntegrationStore.selectorGetItems);

    const selectedIntegrationUid: string = useSelector(IntegrationStore.selectorGetSelectedId);

    const buildGroupGroupedOptions = useCallback((groups: { [key: number]: any[] }) => {
        return [
            {
                label: t("global_typeof_location"),
                options: buildOptions(groups[INVITATION_TYPES.LOCATION]),
            },
            {
                label: t("global_typeof_room"),
                options: buildOptions(groups[INVITATION_TYPES.ROOM]),
            },
        ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allGroups = useMemo(() => [...(groups[INVITATION_TYPES.LOCATION] ?? []), ...(groups[INVITATION_TYPES.ROOM]) ?? []], [groups]);
    const baseIntegration = useMemo(() => integration, [integration]);
    const [apikeyValue, setApikeyValue] = useState(integration.apikey ?? '');
    const [hostIDValue, setHostIDValue] = useState(integration.host_id ?? '');
    const [availableGroups, setAvailableGroups] = useState(buildGroupGroupedOptions(groups));
    const integrationGroups = integration.groups || [];
    const [selectedGroupsLocal, setSelectedGroupsLocal] = useState(integrationGroups.map(({id}) => ({value: id})) ?? []);
    const [createPublicUrl, setCreatePublicUrl] = useState(integration.createPublicUrl);
    const [sendInvitationURL, setSendInvitationURL] = useState(integration.sendInvitationURL ?? true);
    const [sendInvitationURLFromPMS, setSendInvitationURLFromPMS] = useState(integration.sendInvitationURLFromPMS ?? true);
    const { errorMessage, setError, hasError, disableError } = useError();
    const locationUid = useSelector(Selectors.getSelectedLocationUid) ?? '';

    const buildPropertyOptions = useCallback((availableProperties: any[] = []) => {
        return availableProperties.map((property) => ({ value: property.id, label: property.nickname, isDisabled: false })) ?? [];
    }, []);
    
    const properties = useSelector(HostifyStore.selectorGetItems);
    const selectedProperty : HostifyListing = useSelector(HostifyStore.selectorGetSelectedItem) ?? {id:integration.host_id, name:integration.host_name};
    const [selectedPropertiesLocal, setSelectedPropertiesLocal] = useState({ value:integration.host_id, label:integration.host_name, isDisabled: false } ?? undefined);
    const [availableProperties, setAvailableProperties] = useState(buildPropertyOptions(properties));

    const onChangeProperty = useCallback(selectedProperty => {
        setSelectedPropertiesLocal(selectedProperty);
        dispatch(HostifyStore.actionSelectItem(selectedProperty.value));
        if (selectedProperty !== undefined) {
            dispatch(hostifyActions.listListings(integration.uid));
        }
    }, [dispatch, integration.uid]);

    const onSeeReservations = useCallback(async (integrationUid) => {
        await dispatch(integrationsActions.listReservations(locationUid, integrationUid));
        dispatch(IntegrationStore.actionSelectItem(integrationUid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUid]);


    const onChangeGroups = useCallback(selectedGroups => {
        if(selectedGroups.length === 0) {
            setAvailableGroups(buildGroupGroupedOptions(groups));
        } else {
            const firstSelectedGroup = selectedGroups[0];
            const accessType = allGroups.find(({id}) => firstSelectedGroup.value === id)?.invitation.type;
            const isLocationType = accessType === INVITATION_TYPES.LOCATION;
            const availableGroups = { [INVITATION_TYPES.LOCATION]: groups[INVITATION_TYPES.LOCATION].map(group => ({ ...group, isDisabled: firstSelectedGroup.id !== group.id })),
                [INVITATION_TYPES.ROOM]: groups[INVITATION_TYPES.ROOM].map(group => ({...group, isDisabled: isLocationType}))
            }
            setAvailableGroups(buildGroupGroupedOptions(availableGroups));
        }
        setSelectedGroupsLocal(selectedGroups);
    }, [buildGroupGroupedOptions, groups, allGroups]);

    const [checkIn, setCheckIn] = useState(integration.check_in ?? MIN_TIME);
    const [checkOut, setCheckOut] = useState(integration.check_out ?? MIN_TIME);
    const timeNoSecondsFormat = TimeHelper.getTimeFormats().TIME_NO_SECONDS;
    const getHourAndMinutesFormat = useCallback((time: string) => moment(time, timeNoSecondsFormat), []);

    useEffect(() => { 
        setApikeyValue(integration.apikey ?? '');
        setHostIDValue(integration.host_id ?? '');
        setCreatePublicUrl(integration.createPublicUrl);
        setSendInvitationURL(integration.sendInvitationURL ?? true);
        setSendInvitationURLFromPMS(integration.sendInvitationURLFromPMS ?? true);

        const selected = buildOptions(allGroups.filter(({ id }) => integrationGroups.map(({id}) => id).includes(id)));
        onChangeGroups(selected);
    }, [allGroups, buildOptions, groups, integration, onChangeGroups]);

    const enableSendInvitationURLToggle = useMemo(() => {
        return createPublicUrl;
    }, [createPublicUrl]);

    const canValidate = useMemo(() => {
        if(hasError) return false;
        return !!apikeyValue &&
            selectedGroupsLocal.length !== 0;
    }, [apikeyValue, hasError, selectedGroupsLocal]);

    const onValidate = useCallback(async () => {

        if(!apikeyValue) {
            setError(t('mod_integrations_link_required'));
            return;
        }
     
        const editedIntegration: Integration = {
            apikey: apikeyValue,
            company: integration.company,
            type: integration.type,
            uid: integration.uid,
            groups: selectedGroupsLocal.map(({ value }) => ({ id: value })),
            createPublicUrl,
            sendInvitationURL,
            sendInvitationURLFromPMS
        }
        if(locationUid) {
            try {
                await dispatch(integrationsActions.createIntegration(locationUid, editedIntegration));
            } catch (err: any) {
                setError(err.message)
            }
        }
    }, [dispatch, setError, integration.company, integration.type, integration.uid, apikeyValue, locationUid, selectedGroupsLocal, t, createPublicUrl, sendInvitationURL, sendInvitationURLFromPMS]);


    const canSave = useMemo(() => {

        if(hasError) return false;

        const baseGroups = baseIntegration.groups || [];

        const groupsWithoutDifferences = baseGroups.every(({id}) =>
                selectedGroupsLocal.map(({ value }) => value).includes(id),
            ) && baseGroups.length === selectedGroupsLocal.length;

        return (
            !!apikeyValue && 
            !!selectedProperty && 
            selectedGroupsLocal.length !== 0 && 
            (
                apikeyValue !== baseIntegration.apikey ||
                hostIDValue !== baseIntegration.host_id ||
                !groupsWithoutDifferences || 
                createPublicUrl !== integration.createPublicUrl || 
                sendInvitationURL !== integration.sendInvitationURL || 
                sendInvitationURLFromPMS !== integration.sendInvitationURLFromPMS ||
                checkIn !== integration.check_in ||
                checkOut !== integration.check_out
        ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseIntegration.groups, baseIntegration.link, createPublicUrl, integrations, integration.createPublicUrl, apikeyValue, hostIDValue, selectedGroupsLocal, 
        sendInvitationURL, selectedProperty, sendInvitationURLFromPMS, checkIn, checkOut]);
    

    const onSave = useCallback(async () => {

        const editedIntegration: Integration = {
            apikey: apikeyValue,
            host_id: selectedPropertiesLocal.value,
            host_name: selectedPropertiesLocal.label,
            company: integration.company,
            createPublicUrl,
            groups: selectedGroupsLocal.map(({ value }) => ({ id: value })),
            type: integration.type,
            uid: integration.uid,
            sendInvitationURL,
            sendInvitationURLFromPMS,
            check_in: checkIn,
            check_out: checkOut
        }
        if(locationUid) {
            try {
                await dispatch(integrationsActions.createIntegration(locationUid, editedIntegration));
            } catch (err: any) {
                setError(err.message)
            }
        }
    }, [createPublicUrl, dispatch, setError, integration.company, integration.type, 
        integration.uid, apikeyValue, hostIDValue, locationUid, selectedGroupsLocal, 
        t, sendInvitationURL, selectedProperty?.id, selectedProperty?.nickname, 
        sendInvitationURLFromPMS, checkIn, checkOut])

    useEffect(() => {
        if (!!integration.uid) {
            dispatch(hostifyActions.listListings(integration.uid));
        }

    }, [dispatch, integration.uid]);

    useEffect(() => {
        setAvailableProperties(buildPropertyOptions(properties));
    }, [properties, buildPropertyOptions]);

    return (
        <div className="c-grid c-grid--small w-100 c-hover-250 p-4 rounded-lg">
            <div
                className="col-span-full"
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "3fr 2fr",
                }}
            >
                <div>API Key</div>
                <input
                    className={`${hasError ? 'input-error' : ''} ${!integration.editable ? 'cna' : ''} col-span-full w-100 p-2 c-h-min`}
                    type="text"
                    readOnly={!integration.editable}
                    value={apikeyValue}
                    placeholder={"API Key"}
                    onChange={(e) => {
                        setApikeyValue(e.target.value?.trim());
                        /*if(IntegrationsHelper.checkIfLinkExists(e.target.value, integrations) && e.target.value !== integration.link) {
                            setError(t('mod_integrations_already_have_integration_with_this_link'));
                        } else {
                            disableError();
                        }*/
                    }}
                />
            </div>
            <div
                style={{
                    display: "grid",
                    gap: "12px",
                    gridTemplateColumns: "1fr 1fr",
                }}
                className="col-span-full d-flex justify-content-between"
            >
                <div className="c-min-w-40">

                <Selector
                    hideSelectedOptions={false}
                    placeholder={t("mod_invitations_select_group")}
                    isMulti
                    value={selectedGroupsLocal}
                    onChange={(selectedGroups: any) =>
                        onChangeGroups(selectedGroups)
                    }
                    isDisabled={!integration.editable}
                    options={availableGroups}
                    />
                    </div>
                    <div>
                        <CreatePublicUrlToggle
                            boldedName={false}
                            initialState={createPublicUrl}
                            readOnly={!integration.editable}
                            onChange={setCreatePublicUrl}
                        />
                        { enableSendInvitationURLToggle && 
                            <div className="mt-1">
                                <SendInvitationURLToggle
                                    boldedName={false}
                                    initialState={sendInvitationURL}
                                    readOnly={!integration.editable}
                                    onChange={setSendInvitationURL}
                                />
                            </div>
                        }
                        { enableSendInvitationURLToggle && 
                            <div className="mt-1">
                                <SendInvitationURLFromPMSToggle
                                    boldedName={false}
                                    initialState={sendInvitationURLFromPMS}
                                    readOnly={!integration.editable}
                                    onChange={setSendInvitationURLFromPMS}
                                />
                            </div>
                        }
                    </div>
            </div>
            { !!!integration.uid && 
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <CapsuleButton
                            size="SMALL"
                            isEnabled={canValidate}
                            onClick={onValidate}
                            text={t("global_validate")}
                        />
                    </div>
                </div>
            }

            { integration.uid &&
                <div
                    className="col-span-full"
                    style={{
                        display: "grid",
                        gap: "12px",
                        gridTemplateColumns: "3fr 2fr",
                    }}
                >
                    <Selector
                        hideSelectedOptions={false}
                        placeholder={t("mod_integrations_select_property")}
                        value={selectedPropertiesLocal}
                        onChange={(selectedProperty: any) =>
                            onChangeProperty(selectedProperty)
                        }
                        isDisabled={availableProperties.length === 0}
                        options={availableProperties}
                    />
                </div>
            }
            { integration.uid && 
                <div className="d-flex align-items-center justify-content-between pr-2">
                    <div className="c-text-capitalize-first mr-2">
                        Check In
                    </div>
                    <TimePicker
                            disabled={!integration.editable}
                            showSecond={false}
                            minuteStep={5}
                            value={getHourAndMinutesFormat(checkIn)}
                            format={TIME_FORMAT}
                            onChange={(value) =>
                                setCheckIn(
                                    value
                                        ? value?.format(TIME_FORMAT)
                                        : MIN_TIME,
                                )
                            }
                    />
                </div>
            }
            { integration.uid && 
                <div className="d-flex align-items-center justify-content-between pr-2">
                    <div className="c-text-capitalize-first mr-2">
                        Check Out
                    </div>
                    <TimePicker
                            disabled={!integration.editable}
                            showSecond={false}
                            minuteStep={5}
                            value={getHourAndMinutesFormat(checkOut)}
                            format={TIME_FORMAT}
                            onChange={(value) =>
                                setCheckOut(
                                    value
                                        ? value?.format(TIME_FORMAT)
                                        : MIN_TIME,
                                )
                            }
                    />
                </div>
            }

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {integration.host_id &&
                        <CapsuleButton
                            isEnabled={integration.uid !== selectedIntegrationUid}
                            onClick={() => onSeeReservations(integration.uid)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            size="SMALL"
                            text={t('mod_integrations_see_reservations')}
                        />
                    }
                </div>
                <div className="d-flex">
                    {
                        !!integration.uid &&
                        <CapsuleButton
                            size="SMALL"
                            classNameExtra="mr-2"
                            style={!integration.editable ? 'PRIMARY' : 'INFO'}
                            onClick={() => onToggleEditable(index)}
                            text={t(!integration.editable ?  "global_edit" : 'global_cancel')}
                        />
                    }
                    {integration.uid && (integration.editable || !integration.uid) &&
                        <CapsuleButton
                            onClick={() => onDelete(index)}
                            classNameExtra="mr-2"
                            // eslint-disable-next-line react/style-prop-object
                            style="DANGER"
                            size="SMALL"
                            icon={Icons.trash}
                        />
                    }
                    { integration.uid &&
                        <CapsuleButton
                            size="SMALL"
                            isEnabled={canSave}
                            onClick={onSave}
                            text={t("global_save")}
                        />
                    }
                </div>
            </div>
                    {errorMessage && (
                        <div className="text-danger mr-2">{errorMessage}</div>
                    )}
        </div>
    );
}