import { Reservation } from "../_models/Reservation"
import { TimeHelper } from "../_helpers";

export class ReservationMapper {
    static allServerToLocal(serverReservations) {
        return serverReservations.map(serverToLocal)
    }

    static serverToLocal(serverReservation) {
        return serverToLocal(serverReservation);
    }
}

function serverToLocal(serverReservation) {
    const {
        uid,

        end, 
        begin, 

        user_uid: userUid, 
        user_name: userName, 
        user_email: userEmail, 
        user_avatar: userAvatar, 

        room_uid: roomUid,

        number_of_users: numberOfUsers
    } = serverReservation;

    return Reservation({
        uid,

        begin: TimeHelper.serverStringToIsoString(begin),
        end: TimeHelper.serverStringToIsoString(end),

        userUid,
        userName,
        userEmail,
        userAvatar,

        roomUid,

        numberOfUsers,
    });
}