import { genericActions } from "../../Everou/_actions/generic.actions";
import { PMSUserMapper } from "../_mappers/PMSUserMapper";
import { PMSUserStore } from "../_stores/PMSUserStore";
import { PMSUserService } from "../_services/PMSUserService";
import { PMSLocationReservationsCollection } from "../_stores/PMSLocationReservationsCollection";
import { PMSReservationMapper } from "../_mappers/PMSReservationMapper";
import { PMS_USER_ACTION_TYPES } from "../_actionTypes/PMS_USER_ACTION_TYPES";
import { PMSChatMessagesCollection } from "../_stores/PMSChatMessagesCollection";
import { PMSChatMapper } from "../_mappers/PMSChatMapper";
import { PMSTagsCollection } from "../_stores/PMSTagsCollection";

export const PMSUserActions = {
    getUser,
    getReservations,
    reservationsRequest: PMSLocationReservationsCollection.actionListItemsRequest,
    reservationsSuccess: PMSLocationReservationsCollection.actionListItemsSuccess,
    changeChatFilter,
    getChat,
    chatRequest: PMSChatMessagesCollection.actionListItemsRequest,
    chatSuccess: PMSChatMessagesCollection.actionListItemsSuccess,
    postChat,
    getTags,
    tagsRequest: PMSTagsCollection.actionListItemsRequest,
    tagsSuccess: PMSTagsCollection.actionListItemsSuccess,
};

function getUser() {
    return async dispatch => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            dispatch(PMSUserStore.actionSet(
                PMSUserMapper.serverToLocal(
                    await PMSUserService.get()
                )
            ));
        }
    };
}

function getReservations() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await dispatch(loadReservations());
            return;
        }
        
    }
}

function loadReservations() {
    return async (dispatch, getState) => {
        dispatch(PMSUserActions.reservationsRequest());

        const serverReservations = await PMSUserService.reservations();
        const localReservations = PMSReservationMapper.allServerToLocal(serverReservations);
        dispatch(PMSUserActions.reservationsSuccess(localReservations));

        return localReservations;
    }
}

function changeChatFilter(filter) {
    return {
        type: PMS_USER_ACTION_TYPES.USER_CHANGE_FILTER,
        filter
    };
}

function getChat(uid) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await dispatch(loadChat(uid));
            return;
        }
        
    }
}

function loadChat(uid) {
    return async (dispatch, getState) => {
        dispatch(PMSUserActions.chatRequest());

        const serverChat = await PMSUserService.chat(uid);
        const localChat = PMSChatMapper.allServerToLocal(serverChat);
        dispatch(PMSUserActions.chatSuccess(localChat));

        return localChat;
    }
}

function postChat(uid, message) {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await PMSUserService.postChat(uid, message);
            dispatch(getChat(uid));
            return;
        }
        
    }
}

function getTags() {
    return async (dispatch, getState) => {
        await dispatch(genericActions.genericAsyncAction(asyncAction));

        async function asyncAction() {
            await dispatch(loadTags());
            return;
        }
        
    }
}

function loadTags() {
    return async (dispatch, getState) => {
        dispatch(PMSUserActions.tagsRequest());

        const tags = await PMSUserService.getTags();
        dispatch(PMSUserActions.tagsSuccess(tags));

        return tags;
    }
}