import { User } from "../_modelsTS/User";

export class UserMapper {

    static allServerToLocal(serverUsers: any[]) {
        return serverUsers?.map(this.serverToLocal) ?? [];
    }

    static serverToLocal(serverUser) {
        const {
            uid,
            name,
            last_name,
            email,
            apikey,
            avatar,
            clockin_additional_info,
            clockin_legal_id,
            permission_type,
            sat,
            share_position_enabled,
            superuser,
            is_distributor,
            is_nfc_card,
            nfc_card_data
        } = serverUser;


        return {
            uid,
            name,
            email,
            apikey,
            avatar,
            lastName: last_name,
            clocingAdditonalInfo: clockin_additional_info,
            clockinLegalId: clockin_legal_id,
            isSat: sat,
            permissionType: permission_type,
            isSharePositionEnabled: share_position_enabled,
            isSuperuser: superuser,
            isDistributor: is_distributor,
            isNFCCard: is_nfc_card,
            nfcCard: nfc_card_data
        } as User;
    }
}