import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const codeService = {
    getType,
};

async function getType(code) {
    const responseData = await ServiceHelper.getRequest(
        URL_CONSTANTS.CODE_TYPE,
        "?code=" + code,
    );

    return {
        type: responseData.type,
    };
}