import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const userLogsService = {
    getFriends,
    getUserLogs,
};

function getFriends() {
    return ServiceHelper.getRequest(
        URL_CONSTANTS.USER_FRIENDS,
    );
}

async function getUserLogs({
    uid,
    pageSize,
    offset,
    begin,
    end,
}) {
    const params = "?"
        + (uid !== undefined ? ("&uid=" + uid) : "")
        + "&page_size=" + pageSize
        + "&offset=" + offset
        + "&begin=" + begin
        + "&end=" + end
    ;
    const response = await ServiceHelper.getRequest(
        URL_CONSTANTS.USER_LOGS,
        params,
    );

    return {
        logs: response.logs,
        next: response.next,
    };
}