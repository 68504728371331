import { ServiceHelper } from "./ServiceHelper";
import { URL_CONSTANTS } from "../_constants";

export const NfcService = {
    getCardInfo,
};

async function getCardInfo(code) {
    return await ServiceHelper.getRequest(
        URL_CONSTANTS.NFC_INFO,
        {
            card_code: code,
        },
    );
}