import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import { MainPageWithButtons, CapsuleButton, RangeInputComponent } from "../_components";
import { Budget } from "../_models/Budget";
import { Entry } from "../_models/Entry";
import { NewEntry } from "./NewEntry";
import { EditableEntryRow } from "./EditableEntryRow";
import { BudgetsActions } from "../_actions/BudgetsActions";
import { BudgetsStore } from "../_reducers/budgetsRootReducer";
import { BudgetClientSection } from "./BudgetClientSection";
import { BudgetStatusDropdown } from "./BudgetStatusDropdown";
import { GenericTextArea } from "../../Common/_components/GenericTextArea";
import { BudgetsPopUpStore } from "../_stores/BudgetsPopUpStore";

export function EditBudgetView({
    products,
    budget = Budget(),
    onClose = () => {},
    entries = [] || [Entry()],
}) {

    //LIBRARIES
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //CLEAN UP
    useEffect(() => {
        return onClose;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNotes(budget.notes)
    }, [budget]);

    //GLOBAL STATE
    const clientUid = budget && budget.client && budget.client.uid;
    const client = useSelector(state => BudgetsStore.selectors.getClient(state, clientUid));

    //LOCAL STATE
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const [isSendingPdf, setIsSendingPdf] = useState(false);
    const [notes, setNotes] = useState((budget && budget.notes) || "");
    const [isNotesEdited, setIsNotesEdited] = useState(false);
    const isNameVisible = getIsNameVisible();
    
    function getIsNameVisible() {
        return entries.some(entry => entry.product.isNameEditable);
    }

    //LOCAL ACTIONS
    function handleEditNotes(notes) {
        setNotes(notes);
        setIsNotesEdited(true);
    }

    //ACTION HANDLERS
    async function handleViewPdf() {
        setIsDownloadingPdf(true);
        await dispatch(BudgetsActions.getPdf(budget.uid, false));
        setIsDownloadingPdf(false);
    }

    async function handleDownloadPdf() {
        setIsDownloadingPdf(true);
        await dispatch(BudgetsActions.getPdf(budget.uid));
        setIsDownloadingPdf(false);
    }

    function handleDeleteBudget() {
        dispatch(BudgetsPopUpStore.actionShowRemoveBudgetPopUp(budget.uid));
    }

    async function handleSendPdf() {
        setIsSendingPdf(true);
        await dispatch(BudgetsActions.sendPdf(budget.uid));
        setIsSendingPdf(false);
    }

    function handleSaveNotes() {
        dispatch(BudgetsActions.updateBudget(budget.uid, notes));
        setIsNotesEdited(false);
    }

    function handleChangeStatus(status) {
        dispatch(BudgetsActions.updateBudget(budget.uid, undefined, status))
    }

    function handleChangeVatApplied(vat) {
        dispatch(BudgetsActions.updateBudget(budget.uid, undefined, undefined, vat))
    }

    //VIEW
    if (!budget)
        return null;
    
    return (
        <MainPageWithButtons
            content={content()}
            pageTitle={t("mod_budgets_details_title")}
            size={'UNLIMITED'}
            buttons={buttons()}
        />
    );

    function content() {
        return (
            <div className="mt-2">
                {sectionTitle(t("mod_budgets_settings"))}
                <div className="mt-2"/>
                {budgetDataSection()}

                <div className="mt-1"/>
                {sectionTitle(t("global_client"))}
                <div className="mt-1"/>
                <BudgetClientSection client={client}/>

                <div className="mt-2"/>
                {sectionTitle(t("mod_budgets_entries"))}
                <div className="mt-2"/>
                {entriesSection()}
            </div>
        );
    }

    function sectionTitle(title) {
        return (
            <>
            <div className="font-weight-bold c-new-ft-l">
                {title}
            </div>
            <hr className="m-0"/>
            </>
        );
    }

    function budgetDataSection() {
        return (
            <div>
                <div className="d-flex">
                    <div>
                        <BudgetStatusDropdown 
                            status={budget.status} 
                            setStatus={handleChangeStatus}
                        />
                    </div>
                    <div className="ml-3"/>
                    {vatAppliedSection()}
                </div>
                <div className="mt-2"/>
                {notesSection()}
            </div>
        );
    }

    function vatAppliedSection() {
        return (
            <div className="d-flex align-items-center">
                <div>
                    {t("mod_budgets_vat_applied")}
                </div>
                <RangeInputComponent
                    value={budget.vatApplied || 0}
                    onChange={handleChangeVatApplied}
                    min={0}
                    max={1}
                    step={0.01}
                    isDecimalPercentage
                />
                <div className="pl-1"> % </div>
            </div>
        );
    }

    function notesSection() {
        return (
            <div>
                <div>
                    <GenericTextArea {...{
                        text: notes,
                        handleEditText: handleEditNotes,
                        placeholder: t("mod_budgets_notes"),
                    }}/>
                </div>
                <div className="d-flex justify-content-end">
                    <CapsuleButton
                        isVisible={isNotesEdited}
                        text={t("global_save")}
                        onClick={handleSaveNotes}
                    />
                </div>
            </div>
        );
    }

    function entriesSection() {
        return (
            <div>
                {newEntrySection()}
                <div className="mt-4"/>
                {existingEntriesSection()}
            </div>
        );
    }

    function existingEntriesSection() {
        if (!entries.length)
            return null;

        return (
            <table className="table table-bordered table-sm pl-3">
                <thead>
                    <tr className="text-uppercase">
                        <th>{t("global_type")}</th>
                        {isNameVisible &&
                            <th>{t("global_name")}</th>
                        }
                        <th>{t("global_amount")}</th>
                        <th>{t("mod_budgets_unit_gross_price")}</th>
                        <th>{t("global_discount")}</th>
                        <th>{t("mod_budgets_total_gross_price")}</th>
                        <th>{t("mod_budgets_total_vat")}</th>
                        <th>{t("mod_budgets_total_net_price")}</th>
                    </tr>
                </thead>
                <tbody className="text-right">
                    {entries.map(singleEntry)}
                    {summaryRow()}
                </tbody>
            </table>
        );

        function singleEntry(entry = Entry()) {
            return (
                <EditableEntryRow
                    key={entry.uid + entry.product.productType}
                    budgetUid={budget.uid} 
                    products={products}
                    entry={entry}
                    isNameVisible={isNameVisible}
                />
            );
        }
    }

    function summaryRow() {
        if (!entries.length)
            return;

        return (
            <tr>
                <td/>
                {isNameVisible && <td/>}
                <td/>
                <td/>
                <td/>
                <td>{budget.totalGrossPrice && budget.totalGrossPrice.toFixed(2)}</td>
                <td>{budget.totalVat && budget.totalVat.toFixed(2)}</td>
                <td>{budget.totalNetPrice && budget.totalNetPrice.toFixed(2)}</td>
            </tr>
        );
    }

    function newEntrySection() {
        return (
            <NewEntry
                budgetUid={budget.uid} 
                products={products}
            />
        );
    }

    function buttons() {
        return [
            <CapsuleButton
                onClick={handleDeleteBudget}
                text={t("webapp_global_remove")}
                style={`DANGER`}
            />,
            <CapsuleButton
                onClick={handleViewPdf}
                text={t("global_view_pdf")}
                isEnabled={!isDownloadingPdf}
                fillStyle={'FILLED'}
            />,
            <CapsuleButton
                onClick={handleDownloadPdf}
                text={t("global_download")}
                isEnabled={!isDownloadingPdf}
                fillStyle={'FILLED'}
            />,
            <CapsuleButton
                onClick={handleSendPdf}
                text={t("mod_budgets_send_button")}
                isEnabled={!isSendingPdf}
                fillStyle={'FILLED'}
            />,
        ];
    }
}